/**
 * @fileOverview
 * @name testSessionRankingCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('objectiveTestSessions.session.ranking', {
        url: '/ranking',
        template: require('assets/templates/review/testSessionRanking.html'),
        controller: 'testSessionRankingCtrl',
        bodyStyle: 'bg_img'
      });
      $stateProvider.state('tmSessions.objSession.ranking', {
        url: '/ranking',
        template: require('assets/templates/review/testSessionRanking.html'),
        controller: 'testSessionStatsCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('testSessionRankingCtrl', testSessionRankingCtrl);

  testSessionRankingCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal', 'oedTestUtils',
    'oedObjectiveTestSessionStats', 'oedTestSessionStat']
  function testSessionRankingCtrl($scope, $state, $stateParams, $q, $uibModal, oedTestUtils,
    oedObjectiveTestSessionStats, oedTestSessionStat) {

    $scope.currentState.title = '成绩分析';

    $scope.stats = oedTestSessionStat.queryByTestSessionId({
      sessionId: $stateParams.sessionId
    });
    $scope.statsForLabel = oedObjectiveTestSessionStats.queryBySessoinId({
      sessionId: $stateParams.sessionId
    });
    $scope.loading = $q.all([$scope.stats.$promise, $scope.statsForLabel.$promise]);
    $scope.currentLabels = [];

    $scope.loading.then(function() {
      var labels = oedTestUtils.getTestStatLabel($scope.statsForLabel);
      $scope.currentLabels = labels;
    });
    $scope.showDetailsForStudent = function(stu) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/review/testSessionReviewForStudentDialog.html'),
        controller: 'testSessionReviewForStudentDialogCtrl',
        size: 'lg',
        windowClass: 'modalCenter',
        resolve: {
          data: function() {
            return {
              sessionId: $stateParams.sessionId,
              userId: stu.stdId,
              studentName: stu.stdName
            };
          }
        }
      });

      modalInstance.result.then(function(dirty) {
        if (dirty) {
          $state.reload();
        }
      });
    };
  }
})();
