import * as angular from "angular";

(function() {
  angular.module('app.signIn')
    .controller('changePwdCtrl', changePwdCtrl);

  changePwdCtrl.$inject = ['$scope', '$uibModal', '$uibModalInstance', 'data', 'notificationService', 'oedUserInfo']
  function changePwdCtrl($scope, $uibModal, $uibModalInstance, data, notificationService, oedUserInfo) {

    if (data.newUser) {
      $scope.tip = '您的密码为默认密码，为了您的密码安全，请修改密码！'
    } else {
      $scope.tip = '您的密码过于简单，为了您的密码安全，请修改密码！'
    }

    $scope.changePassowrd = function(pwd) {
      $scope.showUpdatePasswordError = true;
      if ($scope.changePasswordForm.$invalid) {
        return;
      }

      if ($scope.changePasswordForm.$invalid) {
        return;
      }

      $scope.loading = oedUserInfo.updatePassowrdForce({username: data.username, oldPassword: data.oldPassword,
        newPassword: pwd.newPassword}).$promise.then((resp) => {
        if (resp.success) {
          $scope.updatePasswordMessage = null;
          notificationService.notify('info', '密码修改成功')
          $uibModalInstance.close()
        } else {
          $scope.updatePasswordMessage = resp.message;
        }
      }).catch((e) => {
        $scope.updatePasswordMessage = '密码修改失败，请重试！';
      });
    };
  }
})();
