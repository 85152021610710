
var questionDesignerFill = require('./questionDesignerFill.directive');

(function() {
  angular.module('app.directives.question.questionDesigner', [
    'app.directives.question.questionDesignerFill',
  ])
    .directive('questionDesiginer', questionDesiginer);

  function questionDesiginer() {
    return {
      restrict: 'EA',
      require: '^ngModel',
      scope: {
        questionModel: '=ngModel',
        testId: '=',
        isSub: '=',
        courseSections: '=',
        versionStamp: '=',
        scoreChanged: '&onScoreChanged'
      },
      template: require('assets/app2/directives/question/questionDesigner.html'),
      controller: questionDesiginerController,
    };
  }
})();

questionDesiginerController.$inject = ['$scope', '$timeout', '$log', 'oedConfig', 'oedMisc', 'oedTestUtils', 'dialogs', 'localStorageService']
function questionDesiginerController($scope, $timeout, $log, oedConfig, oedMisc, oedTestUtils, dialogs, localStorageService) {
  $scope.types = {
    'yesorno': '判断',
    'singlechoice': '单选',
    'multichoice': '多选',
    'connect': '匹配',
    'fill': '填空',
    'vote': '投票',
    'synthetic': '综合',
    'qa': '问答'
  };

  if ($scope.isSub) {
    _.unset($scope.types, 'synthetic');
  }

  $scope.questionModel = _.defaults($scope.questionModel, {
    type: 'singlechoice',
    point2: 1,
    choices: [],
    leftOpts: [],
    rightOpts: []
  });

  $scope.getChoiceName = oedTestUtils.getChoiceName;
  $scope.treeConfig = {
    core: {
      multiple: true,
      animation: true,
      error: function(error) {
        $log.error('treeCtrl: error from js tree - ' + angular.toJson(error));
      },
      check_callback: true,
      worker: true
    },
    types: {
      default: {
        icon: 'fa fa-book'
      },
      root: {
        icon: 'glyphicon glyphicon-cloud'
      }
    },
    version: 1,
    plugins: ['types', 'checkbox']
  };

  $scope.sectionTree = {
    treeData: []
  };

  $scope.getCurrentQuesType = function() {
    if ($scope.questionModel.type == 'singlevote' || $scope.questionModel.type == 'multivote') {
      return 'vote';
    } else {
      return $scope.questionModel.type;
    }
  };

  $scope.isMultiVote = false;

  $scope.setType = function(type) {
    if (type == $scope.getCurrentQuesType())
      return;
    function applyType(t) {
      //localStorageService.set('lastQuestionType', type);
      $scope.questionModel.answer = null;
      var prevType = $scope.questionModel.type;
      if (type == 'vote') {
        $scope.questionModel.type = $scope.isMultiVote ? 'multivote' : 'singlevote';
        $scope.questionModel.answer = '-1';
      } else {
        $scope.questionModel.type = type;
        if (type == 'qa') {
          $scope.questionModel.answer = '-1';
        }
      }
      $scope.choices = [];
      if (type != 'fill' && !$scope.questionModel.point2) {
        $scope.questionModel.point2 = localStorageService.get('lastQuestionPoint') || 1;
      }
      //
      // dont't remove the options if switch between singlechoice/multichoice
      if (_.size(_.without(['singlechoice', 'multichoice'], prevType, type)) !== 0) {
        $scope.questionModel.choices = [];
        if (type == 'singlechoice' || type == 'multichoice' || type == 'vote') {
          _.times(4, function() {
            $scope.questionModel.choices.push({
              content: ''
            });
          });
        }
      }
    }
    if (!_.isEmpty($scope.questionModel.answer)) {
      dialogs.confirm('确认修改问题类型',
        '修改题型将导致当前设置的答案丢失，是否确认题型修改？')
      .result.then(function() {
        applyType(type);
      });
    } else {
      applyType(type);
    }
  };

  $scope.addChoice = function() {
    $scope.questionModel.choices.push({
      content: ''
    });
    $scope.choices[$scope.questionModel.length - 1] = false;
  };
  $scope.removeChoice = function(idx) {
    $scope.questionModel.choices.splice(idx, 1);
    $scope.choices.splice(idx, 1);
  };

  $scope.showChoices = function() {
    return $scope.questionModel.type == 'singlechoice' ||
      $scope.questionModel.type == 'multichoice' ||
      $scope.questionModel.type == 'singlevote' ||
      $scope.questionModel.type == 'multivote';
  };

  $scope.isAnswer = function(idx) {
    if ($scope.questionModel.type == 'singlechoice') {
      return $scope.questionModel.answer == idx;
    }
    if ($scope.questionModel.type == 'multichoice') {
      return $scope.choices[idx];
    }
    return false;
  };

  $scope.choices = [];
  $scope.isSubQuestion = function() {
    return $scope.isSub;
  };
  $scope.choicesChanged = function() {
    var answers = [];
    _.each($scope.choices, function(v, idx) {
      if (v)
        answers.push(idx);
    });

    $scope.questionModel.answer = answers.join(',');
  };

  $scope.changeVoteType = function(ifChecked) {
    $scope.isMultiVote = ifChecked;
    if (ifChecked) {
      $scope.questionModel.type = 'multivote';
    } else {
      $scope.questionModel.type = 'singlevote';
    }
  };

  $scope.getTypeLabel = function(t) {
    switch (t) {
      case 'singlechoice':
        return '单选';
      case 'multichoice':
        return '多选';
      case 'fill':
        return '填空';
      case 'connect':
        return '匹配';
      case 'yesorno':
        return '判断';
      case 'singlevote':
        return '投票';
      case 'multivote':
        return '投票';
      case 'qa':
        return '问答';
    }
    return '';
  };

  $scope.addLeftOpt = function() {
    var opt = {
      content: '',
      connectsTo: []
    };

    $scope.questionModel.leftOpts.push(opt);
  };

  $scope.addRightOpt = function() {
    var opt = {
      content: '',
      uuid: _.uniqueId('right_opt_')
    };

    $scope.questionModel.rightOpts.push(opt);
  };

  $scope.getRightOptName = function(idx) {
    return idx + 1;
  };

  $scope.removeLeftOpt = function(idx) {
    $scope.questionModel.leftOpts.splice(idx, 1);
  };

  $scope.getLeftOptName = oedTestUtils.getChoiceName;

  $scope.removeRightOpt = function(idx) {
    var opt = $scope.questionModel.rightOpts[idx];
    $scope.questionModel.rightOpts.splice(idx, 1);
    _.each($scope.questionModel.leftOpts, function(lOpt) {
      lOpt.connectsTo = _.without(lOpt.connectsTo, opt);
    });
  };

  $scope.getRightOptNameByObject = function(opt) {
    return $scope.getRightOptName(_.indexOf($scope.questionModel.rightOpts, opt));
  };

  $scope.toggleShowAnswerDetails = function() {
    $scope.showAnswerDetails = !$scope.showAnswerDetails;
    if (!$scope.showAnswerDetails) {
      $scope.questionModel.answerDetails = null;
    }
  };

  $scope.preventDropdownDefault = function(event) {
    event.preventDefault();
    event.stopPropagation();
  };

  $scope.editorConfig = {
    serverUrl: oedConfig.url('ceditor/Test/') + $scope.testId
  };

  $scope.onTreeReady = function() {
    $scope.treeReady = true;
    var sections = null;
    if ($scope.questionModel.relatedSections) {
      sections = _.map($scope.questionModel.relatedSections, function(relSec) {
        return relSec.sectionId;
      });
    }
    $scope.treeInstance.jstree(true).select_node(sections, false, true);
  };

  $scope.onSelChanged = function() {
    var selectedNodes = $scope.treeInstance.jstree(true).get_selected();
    var curIds = _.map($scope.questionModel.relatedSections, function(sec) {
      return sec.sectionId;
    });

    if (_(curIds).xor(selectedNodes).isEmpty()) {
      return;
    }

    $scope.questionModel.relatedSections = _.map(selectedNodes, function(node) {
      var sec = $scope.sectionIdMap[node];

      return {
        sectionId: sec.id,
        sectionName: sec.name
      };
    });
  };

  $scope.$watch('questionModel', function(newVal, oldVal) {
    if ($scope.questionModel.type === 'multivote') {
      $scope.isMultiVote = true;
    } else if ($scope.questionModel.type === 'singlevote') {
      $scope.isMultiVote = false;
    }

    var sections;
    //
    // build choices array
    $scope.choices = [];
    if (newVal.type == 'multichoice') {
      var idxs = newVal.answer.split(',');
      _.each(idxs, function(v) {
        $scope.choices[parseInt(v, 10)] = true;
      });
    }

    $scope.showAnswerDetails = !!newVal.answerDetails;
    if ($scope.treeReady) {
      if ($scope.questionModel.relatedSections)
        sections = _.map($scope.questionModel.relatedSections, function(relSec) {
          return relSec.sectionId;
        });

      $scope.treeInstance.jstree(true).deselect_all(true);
      $scope.treeInstance.jstree(true).select_node(sections, true, true);
      $scope.treeConfig.version++;
    }
  });

  $scope.$watch('courseSections', function(newVal) {
    if (newVal) {
      newVal.$promise.then(function() {
        $scope.sectionIdMap = {};

        function visitSection(sec) {
          if (sec.id) $scope.sectionIdMap[sec.id] = sec;
          if (sec.child) {
            sec.child = _.sortBy(sec.child, ['sort']);
            _.each(sec.child, visitSection);
          }
        }
        _.each($scope.courseSections, visitSection);

        $scope.sectionTree.treeData = oedMisc.utils.convertSectionsToJsTreeModel($scope.sectionIdMap,
          $scope.courseSections);
        $scope.treeConfig.version++;
        _.each($scope.sectionTree.treeData, function(data) {
          data.state.opened = true;
        });
      });
    }
  });
}
