import * as _ from "lodash";
require("./examReview.scss");
require("assets/app/controllers/examination/examReview/examReviewNav/examReviewNavCtrl");
require("assets/app/controllers/examination/dialog/examQuesAnswerCtrl");
require("./examImageView");
require('assets/app/controllers/examination/examReview/examReviewQues/examReviewQuesCtrl');

import Promise = require("bluebird");
import {
  sortExamWhole,
  parseAnswers,
  sortAndFilterExamWhole,
  filterNoAnswerAndSaveIndex,
  openExamConfirmDialog,
  getExamQIndexMap,
} from "../examEdit/paper/paperUtils";
import {
  getResourceSrc
} from "app2/utils/resourceUtils";
import { createQuesUuidForExam } from "../examUtils";

angular.module("app.examination.examReview", [
  "app.examination.examReview.examReviewNav",
  "app.examination.dialog.examQuesAnswer",
  "app.examImageView",
  'app.examination.examReviewQuestion'
])
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state("examReview", {
      url: "/examReview?examId&relId&levelId&coursepathId",
      template: require("assets/app/controllers/examination/examReview/examReview.html"),
      controller: "examReviewCtrl",
      loadingCls: "blueloading",
      bodyStyle: "bged text-hidden",
    });
  }])
  .controller("examReviewCtrl", examReviewCtrl);

examReviewCtrl.$inject = [
  "$stateParams",
  "$scope",
  "oedExamination",
  "oedClassUser",
  "notificationService",
  "$state",
  '$uibModal',
];

const sbjtypes = {
  fill: true,
  qa: true
};

const regNumber = new RegExp('[^0-9.]')

function examReviewCtrl(
  $stateParams,
  $scope,
  oedExamination,
  oedClassUser,
  notificationService,
  $state,
  $uibModal,
) {
  $scope.examName = "";
  $scope.examWholeData = null;
  const examId = $stateParams.examId;

  $scope.onBackToPrePage = () => {
    $state.go("prepareLesson");
  };
  $scope.currentTag = 0; // 当前选中的答题
  $scope.currentNm = 0; // 当前选中的题目
  $scope.examStudents = []; // 该试卷的学生列表
  $scope.currentStudents = []; // 当前的学生列表(答过题的)
  $scope.currentQuestion = {}; // 当前的小题()
  $scope.currentReviews = []; // 当前批阅的题目列表
  $scope.currentImgSrc = null; // 当前图片.
  $scope.currentImgIndex = 0; // 当前图片.
  $scope.currentStdIndex = 0; // 当前学生Index
  $scope.parentQuestion = { reviewedStd: [] } // 外层题目
  $scope.ratio = 1 // 
  $scope.relId=$stateParams.relId
  $scope.levelId=$stateParams.levelId
  $scope.coursepathId=$stateParams.coursepathId
  function updateImg(index) {
    $scope.currentImgIndex = index;
    const res = _.get($scope.imgList, index);
    if (res) {
      $scope.currentImgSrc = getResourceSrc(res);
    } else {
      $scope.currentImgSrc = null;
    }
  }
  // 切换左侧导航的小题
  $scope.onClickQues = (partIndex: number, index: number) => {
    findPendingStd(partIndex, index);
  };

  // 查询该题是否还有待批
  function findQuesPendingStd(ques) {
    const quesAnswerMap = _.get($scope.examSessionAnswerMap, ques.id, {});
    return _.findKey(quesAnswerMap, (answer) => answer.correctDetails === "-1")
  }

  // 设置子题
  function updateChildQues(childIndex) {
    $scope.childIndex = childIndex;
    $scope.currentQuestion = $scope.currentReviews[$scope.childIndex];
  }

  // 更新一道题的进度
  function updateQuesProgress(ques) {
    let quesList = [ques]
    if (ques.type === 'synthetic') {
      quesList = ques.childQuestions
    }
    const stdList = _.keys(_.get($scope.examSessionAnswerMap, `${quesList[0].id}`, {}))
    const isPending = (stdId, q) => _.get($scope.examSessionAnswerMap, `${q.id}.${stdId}`, {}).correctDetails === '-1'
    ques.reviewedStd = _.filter(stdList, (stdId) => _.findIndex(quesList, q => isPending(stdId, q)) < 0)
    ques.reviewed = stdList.length === ques.reviewedStd.length
  }

  // 更新整个examWholeData
  function updateExamData() {
    $scope.examWholeNav = { ...$scope.examWholeNav }
  }

  // 更新一批阅题目
  function updateProgress() {
    $scope.examWholeData.examPartDetails.forEach((part) => {
      part.questionDetails.forEach((q) => {
        updateQuesProgress(q.question)
      })
    })
  }

  function autoFocus() {
    // ----- 自动定位焦点
    if ($scope.endEnable()) {
      if ($scope.lastAutoFocus) clearTimeout($scope.lastAutoFocus)
      $scope.lastAutoFocus = setTimeout(() => {
        const dom = document.getElementById(`exam_review_${$scope.currentQuestion.id}`);
        if (dom) dom.focus({ preventScroll: true });
      }, 300);
    }
  }


  // 设置当前题目和学生 并跟新设置当前待批题目和学生答案.
  const setCurrentQuestionAndStd = (
    partIndex: number,
    index: number,
    childIndex = 0,
    stdIndex = 0,
  ) => {
    const imageChange = $scope.currentTag !== partIndex || $scope.currentNm !== index || $scope.currentStdIndex !== stdIndex || !$scope.currentImgSrc
    $scope.currentTag = partIndex;
    $scope.currentNm = index;
    const quesWarper = _.get($scope.examWholeData, `examPartDetails.${partIndex}.questionDetails.${index}`, {})
    let question = _.get(quesWarper, `question`, {});
    $scope.parentQuestion = question;
    let reviewQuestions = [];
    if (question.type === 'synthetic') {
      reviewQuestions = question.childQuestions;
      question = question.childQuestions[childIndex];
    } else {
      reviewQuestions = [question];
    }

    $scope.currentStudents = quesWarper.answeredStd
    $scope.currentStdIndex = stdIndex;
    const studentId = $scope.currentStudents[stdIndex]
    $scope.currentStudentId = studentId;

    $scope.currentReviews = _.map(reviewQuestions, (q, index) => {
      const answer = _.get(
        $scope.examSessionAnswerMap,
        `${q.id}.${studentId}`, {}
      );
      return {
        ...q,
        stdAnswer: {
          ...answer,
          point: answer.correctDetails === "-1" ? "" : answer.point,
        },
      };
    });
    updateChildQues(_.findIndex($scope.currentReviews, (q) => _.get(q, "id") === question.id));
    $scope.imgList = _.get($scope.currentQuestion.stdAnswer, "answer.items", []);
    if (imageChange) updateImg(0);
    autoFocus()
  };

  // 1,获取试卷详情并排序存到$scope
  // 2,获取学生列表
  // 3,获取学生答案
  function getExamReviewData(session) {
    const examSession = session.examSession
    return oedExamination
      .getExamTestWhole({
        examId: examSession.examId
      })
      .$promise.then((examWhole) => {
        $scope.origExamWhole = createQuesUuidForExam(examWhole)
        $scope.examWhole = examWhole
        $scope.examName = _.get(examWhole, "relatedSections.0.name", "");
        return examWhole;
      })
      .then(() => oedClassUser.queryClassStudents({
        classId: examSession.classId,
      }).$promise)
      .then((students) => {
        $scope.examStudents = students;
      })
      .then(() => oedExamination.getExamSessionSbjAnswers({
        id: examSession.id,
      }).$promise)
      .then((answers) => {
        parseAnswers(answers);
        $scope.examSessionAnswers = answers;
        $scope.examSessionAnswerMap = _.mapValues(
          _.groupBy(answers, "questionId"),
          (quesAnswers) => _.keyBy(quesAnswers, "studentId")
        );
        const examWhole = sortAndFilterExamWhole($scope.examWhole, $scope.examSessionAnswerMap);
        $scope.examWholeData = filterNoAnswerAndSaveIndex(examWhole);
        $scope.examWholeNav = examWhole
        updateProgress()
        updateExamData()
        // 有学生答题 
        $scope.hasStdAnswer = _.size($scope.examWholeData.examPartDetails) > 0
        // $scope.hasStdAnswer = false
        if ($scope.hasStdAnswer) {
          $scope.currentImgSrc = null
          findPendingQuesAndStd();
        }
      });
  }

  // 设置当前的考试Session
  function setCurrentSession(session) {
    $scope.session = session
    $scope.currentSessionId = _.get(session, 'examSession.id')
  }

  // 获取考试Session数据及考试详情
  $scope.loading = oedExamination.getExamSessions({
    examId
  }).$promise.then((sessions) => {
    // 最新的在前面。过滤掉没结束的考试.
    $scope.examSessions = _.filter(_.sortBy(sessions, s => -s.examSession.startTime), s => s.examSession.exitTime > 946656000000)
    if (_.size(sessions)) {
      setCurrentSession(findPendingSession($scope.examSessions))
      return getExamReviewData($scope.session);
    }
    return notificationService.notify("error", "出错了,请稍后再试.");
  }).catch(e => notificationService.notify("error", "出错了,请稍后再试."))

  // 切换考试Session
  $scope.onClickSession = (session) => {
    if (session !== $scope.session) {
      $scope.loading = getExamReviewData(session)
        .then(() => setCurrentSession(session))
        .catch(() => notificationService.notify("error", "出错了,请稍后再试."))
    }
  }
  // 默认打分
  function pointIsDef(point) {
    return point === '' || point === undefined
  }

  // 判断当前题目是否需要批阅
  function isNeedReview() {
    const {
      correctDetails,
      point,
      questionId,
      studentId,
    } = $scope.currentQuestion.stdAnswer;
    const isReviewed = correctDetails !== "-1";
    const old = _.get($scope.examSessionAnswerMap, `${questionId}.${studentId}`, {});

    if (isReviewed && !pointIsDef(point) && _.toNumber(old.point) === _.toNumber(point)) {
      return false;
    }
    return true;
  }

  $scope.quesHasReviewd = () => {
    const { point } = $scope.currentQuestion.stdAnswer;
    return !pointIsDef(point)
  }


  // 检查非法数据 def 默认空是否合法
  function checkInputPoint(def = true) {
    const dom = document.getElementById(`exam_review_${$scope.currentQuestion.id}`)
    const point = _.get(dom, 'value')
    if (pointIsDef(point)) {
      return def
    }
    if (dom) {
      return inRange(_.toNumber(point), 0, $scope.currentQuestion.point2)
    }
    return false
  }

  // 监听input foucs 事件
  $scope.foucsReview = (childIndex) => {
    updateChildQues(childIndex);
  };

  function getMaxChild(nm: number, tag?: number) {
    if (tag === undefined) {
      tag = $scope.currentTag;
    }
    const ques = _.get($scope.examWholeData, `examPartDetails.${tag}.questionDetails.${nm}.question`, {});
    if (ques.type === 'synthetic' && $scope.endEnable()) {
      return _.size(ques.childQuestions) - 1;
    }
    return 0;
  }

  function getMaxStd(nm: number, tag?: number) {
    if (_.isUndefined(tag)) {
      tag = $scope.currentTag;
    }
    const stdList = _.get($scope.examWholeData, `examPartDetails.${tag}.questionDetails.${nm}.answeredStd`, []);
    return _.size(stdList) - 1
  }

  function getMaxNm(tag: number) {
    return (_.size(_.get($scope.examWholeData, `examPartDetails.${tag}.questionDetails`)) - 1);
  }

  function inRange(value, min, max) {
    return value >= min && value <= max
  }

  function switchImpl(next: boolean) {
    const addIndex = next ? 1 : -1;
    let {
      childIndex,
      currentStdIndex,
      currentNm,
      currentTag,
    } = $scope;
    const maxStd = _.size($scope.currentStudents) - 1;
    const maxChild = _.size($scope.currentReviews) - 1;
    const maxNm = _.size(_.get($scope.examWholeData, `examPartDetails.${currentTag}.questionDetails`, [])) - 1;
    const maxTag = _.size(_.get($scope.examWholeData, `examPartDetails`, [])) - 1;
    // 切换顺序 childIndex -> currentStdIndex -> currentNm -> currentTag
    if ($scope.endEnable() && inRange(childIndex + addIndex, 0, maxChild)) {
      childIndex += addIndex;
    } else if (inRange(currentStdIndex + addIndex, 0, maxStd)) {
      currentStdIndex += addIndex;
      childIndex = next ? 0 : maxChild;
    } else if (inRange(currentNm + addIndex, 0, maxNm)) {
      currentNm += addIndex;
      currentStdIndex = next ? 0 : getMaxStd(currentNm);
      childIndex = next ? 0 : getMaxChild(currentNm);
    } else if (inRange(currentTag + addIndex, 0, maxTag)) {
      currentTag += addIndex;
      currentNm = next ? 0 : getMaxNm(currentTag);
      currentStdIndex = next ? 0 : getMaxStd(currentNm, currentTag);
      childIndex = next ? 0 : getMaxChild(currentNm, currentTag);
    }
    if (!$scope.endEnable()) childIndex = 0
    setCurrentQuestionAndStd(currentTag, currentNm, childIndex, currentStdIndex);
  }

  // 批阅当前学生题目
  function reviewStdQues(useLoading = true) {
    const point = $scope.currentQuestion.stdAnswer.point;
    const defPoint = $scope.currentQuestion.point2;
    const newPoint = pointIsDef(point) ? defPoint : _.toNumber(`${point}`.trim())

   const reviewPromise = oedExamination.reviewStdExamQuestion({
      examSessionId: $scope.session.examSession.id,
      studentId: $scope.currentStudentId,
      questionId: $scope.currentQuestion.id,
      point: newPoint,
    }, {}).$promise
    if (useLoading) {
      $scope.loading = reviewPromise
    }
    return reviewPromise
  }

  // 替换Answer数据
  function updateAnswer(answer) {
    const oldStdAnswer = _.get($scope.examSessionAnswerMap, `${answer.questionId}.${answer.studentId}`, {})
    oldStdAnswer.point = _.round(answer.point, 1)
    oldStdAnswer.correctDetails = answer.correctDetails
  }

  // 切换 next: false 上一个 true 下一个
  $scope.switchAuto = (next: boolean) => {
    const checkSwitchDisable = next ? $scope.disableNext : $scope.disablePrev
    if (checkSwitchDisable()) return
    if (!checkInputPoint()) return
    const point = $scope.currentQuestion.stdAnswer.point;
    const defPoint = $scope.currentQuestion.point2;
    if (!isNeedReview()) {
      if (pointIsDef(point)) $scope.currentQuestion.stdAnswer.point = defPoint
      return switchImpl(next)
    }
    $scope.loading = reviewStdQues()
      .then((answer) => {
        updateAnswer(answer)
        updateQuesProgress($scope.parentQuestion)
        updateExamData()
        switchImpl(next);
      }).catch(e => notificationService.notify('error', '打分失败，请检查网络后再试。'));
  };


  function sessionEndEnable(session) {
    return _.get(session, 'examSession.status', '') === 'exited'
  }

  $scope.endEnable = () => {
    return sessionEndEnable($scope.session)
  }

  // 检查是否还有待批题目
  function checkHasPending() {
    return $scope.examWholeData.examPartDetails.some(part => {
      return part.questionDetails.some(q => !q.question.reviewed)
    })
  }


  // 结束批阅 : 只有批阅完成后才能结束,结束后不允许修改
  $scope.endReview = () => {
    if (!$scope.endEnable()) return
    if (checkHasPending()) return notificationService.notify('error', '还有题没有批阅完。')
    openExamConfirmDialog($uibModal, '是否结束阅卷并提交结果？', '结束阅卷', '确定', '取消', () => {
      $scope.loading = oedExamination.endReview({ id: $scope.session.examSession.id }, {}).$promise
        .then(session => {
          $scope.session.examSession.status = 'reviewed'
          $scope.session = { ...$scope.session }
        })
        .catch(e => notificationService.notify('error', '结束失败，请检查网络后再试。'))
    })

  };

  // 切换图片 : next:true 下一个; next:false 上一个
  $scope.switchImg = (next: boolean) => {
    const addNum = next ? 1 : -1;
    const max = _.size($scope.imgList) - 1;
    const nextIndex = Math.max(Math.min($scope.currentImgIndex + addNum, max), 0);
    updateImg(nextIndex);
  };

  // 键盘事件  'Enter' 或 '空格'
  $scope.onKeyUp = (event) => {
    if (event.keyCode == 13 || event.keyCode == 32) {
      $scope.switchAuto(true);
    }
  };

  // input失去焦点
  $scope.onInputBlur = (event) => {
    if (!$scope.endEnable()) return
    if (!checkInputPoint(false)) return
    if (!isNeedReview()) return
    reviewStdQues(false)
    .then(answer => {
      updateAnswer(answer)
      updateQuesProgress($scope.parentQuestion)
      updateExamData()
    })
  }

  // 输入change
  $scope.onInputChange = () => {
    const point = _.get($scope.currentQuestion, 'stdAnswer.point')
    let text = `${point}`.replace(regNumber, '')
    const dotIndex = text.indexOf('.')
    if (dotIndex >= 0 && text.length - dotIndex > 2) {
      text = text.substr(0, dotIndex + 2)
    }
    const maxPoint = _.get($scope.currentQuestion, 'point2')
    if (text.length && text !== '.' && !inRange(_.toNumber(text), 0, maxPoint)) {
      text = ''
    }
    _.set($scope.currentQuestion, 'stdAnswer.point', text)
  }

  // 下一个不可用
  $scope.disableNext = () => {
    // if(!$scope.endEnable()) return true
    const maxTag = _.size(_.get($scope.examWholeData, `examPartDetails`, [])) - 1;
    if (maxTag < 0) return true
    const maxNm = getMaxNm(maxTag)
    const maxChild = getMaxChild(maxNm, maxTag)
    const maxStd = getMaxStd(maxNm, maxTag)
    const {
      childIndex,
      currentStdIndex,
      currentNm,
      currentTag,
    } = $scope;
    return maxTag === currentTag && maxNm === currentNm && maxChild === childIndex && maxStd === currentStdIndex
  }

  // 上一个不可用
  $scope.disablePrev = () => {
    // if(!$scope.endEnable()) return true
    if (_.size(_.get($scope.examWholeData, `examPartDetails`, [])) === 0) return true
    const {
      childIndex,
      currentStdIndex,
      currentNm,
      currentTag,
    } = $scope;
    return currentTag + currentNm + currentStdIndex + childIndex === 0
  }

  $scope.HasAnswerDetails = () => {
    const ques = _.get($scope.examWholeData, `examPartDetails.${$scope.currentTag}.questionDetails.${$scope.currentNm}`, {});
    if (_.get(ques, 'question.type', '') === 'synthetic') {
      const childs = _.get(ques, 'question.childQuestions', [])
      const hasAnswerDetails = _.reduce(childs, (r, c) => {
        return r || _.get(c, 'answerDetails', '') != ''
      }, false)
      return hasAnswerDetails
    } else {
      return _.get(ques, 'question.answerDetails', '') != ''
    }
  }

  // 查看答案
  $scope.onShowAnswer = () => {
    const ques = _.get($scope.examWholeData, `examPartDetails.${$scope.currentTag}.questionDetails.${$scope.currentNm}`, {});
    if (!$scope.HasAnswerDetails()) {
      return
    }
    $uibModal.open({
      component: 'examQuesAnswer',
      size: 'center-md',
      resolve: {
        question: () => ques.question,
        indexLabel: () => `${ques.inExamIndex + 1}`
      }
    }).result.then(() => {
      autoFocus()
    })
  }

  // 查看题目
  $scope.onShowQuestion = () => {
    const ques = _.get($scope.examWholeData, `examPartDetails.${$scope.currentTag}.questionDetails.${$scope.currentNm}`, {});
    const modalInstance = $uibModal.open({
      template: require('assets/app/controllers/examination/examReview/examReviewQues/examReviewQues.html'),
      controller: 'examReviewQuesCtrl',
      size: 'center-md-lg',
      windowClass: 'oed-common-modal',
      resolve: {
        data: () => {
          return {
            examData: $scope.examWholeData,
            question: ques.question,
            questionTags: ques.questionTags,
            indexLabel: `${ques.inExamIndex + 1}`,
            relId: $scope.relId,
            levelId: $scope.levelId,
            coursepathId: $scope.coursepathId,
            indexMap: getExamQIndexMap($scope.origExamWhole),
          }
        }
      }
    })
    modalInstance.result.finally(() => {
      $scope.$resume()
    })
  }


  // 图片的放大缩小
  $scope.zoomTo = (out: boolean) => {
    const newRatio = out ? $scope.ratio + 0.2 : $scope.ratio - 0.2
    $scope.ratio = Math.min(Math.max(0.2, newRatio), 2)
  }

  // ----- 判断题目是否含待批并设置
  function hasPending(ques, partIndex, index, childIndex?) {
    const stdId = findQuesPendingStd(ques);
    if (stdId) {
      const answeredStd = _.get($scope.examWholeData, `examPartDetails.${partIndex}.questionDetails.${index}.answeredStd`, [])
      setCurrentQuestionAndStd(partIndex, index, childIndex, _.findIndex(answeredStd, id => id === stdId));
    }
    return !!stdId;
  }

  // ----- 当前指定题目下待批学生
  function findPendingStd(partIndex, index) {
    const ques = _.get($scope.examWholeData, `examPartDetails.${partIndex}.questionDetails.${index}.question`, {});
    let has = false;
    if (ques.type === 'synthetic') {
      has = ques.childQuestions.some((cQues, childIndex) => hasPending(cQues, partIndex, index, childIndex));
    } else {
      has = hasPending(ques, partIndex, index);
    }
    if (!has) {
      setCurrentQuestionAndStd(partIndex, index);
    }
  }

  // ----- 寻找当前试卷待批题目及学生 如果没有就使用第一个题
  function findPendingQuesAndStd() {
    const partDetails = $scope.examWholeData.examPartDetails;
    const has = partDetails.some((part, partIndex) =>
      part.questionDetails.some((ques, index) => {
        if (ques.question.type === 'synthetic') {
          return ques.question.childQuestions.some((cQues, childIndex) =>
            hasPending(cQues, partIndex, index, childIndex)
          );
        } else if (_.get(sbjtypes, ques.question.type, false)) {
          return hasPending(ques.question, partIndex, index);
        }
        return false;
      })
    );
    if (!has) {
      setCurrentQuestionAndStd(0, 0)
    }
  }


  function findPendingSession(sessions) {
    const session = _.find(sessions, sessionEndEnable)
    return session ? session : sessions[0]
  }


  // 输入框的提示文本
  $scope.getPlaceholder = (q) => {
    const point = `${q.point2}`
    let place = point
    for (let i = 0; i < point.length; i++) {
      place = `${place}  `
    }
    return place
  }

}
