import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-del-btn',
  template: require('./common-del-btn.component.html'),
  styles: [require('./common-del-btn.component.scss')]
})
export class CommonDelBtnComponent implements OnInit {

  @Output() private action = new EventEmitter<any>();
  @Input() private text: string;

  constructor() { }

  ngOnInit() { }

  private onDel = ($event: Event) => {
    $event.stopPropagation()
    this.action.emit()
  }
  private onContent = ($event: Event) => $event.stopPropagation()
}
