import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { UserWithGroupInfo } from 'app/models/user';
import { LegacyAppService } from 'app/core/legacy-app.service';
import { getStdAvatarImgSrc } from 'app/utils/userinfo-utils';

@Component({
  selector: 'app-std-avatar',
  template: require('./std-avatar.component.html'),
  styles: [require('./std-avatar.component.scss')]
})
export class StdAvatarComponent implements OnInit {
  @Input() student: UserWithGroupInfo;
  @Input() notGrouping: boolean;
  @Input() showSetGroup: boolean;

  @Output() onSetGroupLeader = new EventEmitter<{
    student: UserWithGroupInfo;
    role: number;
  }>();

  constructor() { }

  ngOnInit() {
  }

  public getStdAvatarImgSrc() {
    if (!this.student) {
      return null
    }

    return getStdAvatarImgSrc(this.student)
  }

  public showGroupControl() {
    return !this.notGrouping && this.showSetGroup
  }

  public isLeader() {
    return this.student.groupInfo && this.student.groupInfo.role
  }

  public setGroupLeader() {
    const role = _.get(this.student.groupInfo, 'role', 0) as number

    this.onSetGroupLeader.emit({
      student: this.student,
      role,
    })
  }
}
