import * as _ from 'lodash'

userInfoController.$inject =['$scope', 'userInfo', 'oedUserInfoUtils', '$state']

function userInfoController($scope, userInfo, oedUserInfoUtils, $state) {
  $scope.userInfo = userInfo.get();
  $scope.userInfoService = userInfo;
  userInfo.then(item => _.indexOf(item.roles, 'student') >= 0)
    .then(i => $scope.isStudent = i);


  $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl;

  const getSettingsUrl = () => {
    if (_.indexOf(_.get($scope.userInfo, 'roles', []), 'student') >= 0) {
      return 'student.personalSettings'
    }

    return 'manage.personalSettings'
  }

  $scope.gotoSettings = () => {
    $state.go(getSettingsUrl())
  }
}
export const userInfo = {
  template: <string>require('assets/app2/directives/userInfo.directive.html'),
  controller: userInfoController,
}
angular.module('app.directives.userInfo', ['app.utils.userInfoUtils'])
.component('userInfo', userInfo);
