(function() {
  angular.module('app.manage')
    .controller('setSchoolAdminsDialogCtrl', setSchoolAdminsDialogCtrl);

  setSchoolAdminsDialogCtrl.$inject = ['$scope', '$uibModalInstance', 'notificationService', 'oedSchool', 'school']
  function setSchoolAdminsDialogCtrl($scope, $uibModalInstance, notificationService, oedSchool, school) {

    $scope.schoolInfo = {
      schoolId: school.id,
      admins: []
    };

    if (_.size(school.admins)) {
      $scope.schoolInfo.admins = angular.copy(school.admins);
    }
    var t;
    $scope.searchAdmin = function(e) {
      clearTimeout(t);
      t = setTimeout(function () {
        $scope.adminList = [];
        oedSchool.searchAdminList({
          schoolId: $scope.schoolInfo.schoolId,
          name: e.target.value,
          start: 0,
          count: 100
        }).$promise.then(res => {
          _.forEach(res, item => {
            if (!_.includes(_.map($scope.schoolInfo.admins, 'uid').join(','), item.uid)) {
              $scope.adminList.push(item);
            }
          });
        })
      }, 1000)
    };
    $scope.resetAdmins = function (selectItem) {
      $scope.schoolInfo.admins.push(selectItem);
      document.getElementById('selectValu').childNodes[0].lastChild.value = '';
      $scope.adminList = [];
      if ($scope.schoolInfo.admins.length) {
        $scope.isAdminNull = false;
      }
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.setAdmin = function() {
      if (!_.size($scope.schoolInfo.admins)) {
        $scope.isAdminNull = true;
        return;
      } else {
        $scope.isAdminNull = false;
      }
      var adminIds = _.map($scope.schoolInfo.admins, 'uid');

      $scope.loading = oedSchool.setSchoolAdmins(
        {schoolId: $scope.schoolInfo.schoolId}, adminIds
      ).$promise.then(function(resp) {
        school.uids = adminIds.join(',');
        school.admins = $scope.schoolInfo.admins;
        $uibModalInstance.close('success');
      }, function (error) {
        notificationService.notify('error', '设置管理员失败，请联系锐学堂技术支持!')
      });
    };
  }
})();
