import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-oed-confirm',
  template: require('./oed-confirm.component.html'),
  styles: [require('./oed-confirm.component.scss')]
})
export class OedConfirmComponent implements OnInit {
  @Input() public title: string
  @Input() public message: string
  @Output() public action = new EventEmitter<void>();

  constructor(public bsModalRef: BsModalRef) { }

  public ngOnInit() {
  }

  public ok() {
    this.action.emit()
    this.bsModalRef.hide()
  }

  public close() {
    this.bsModalRef.hide()
  }

}
