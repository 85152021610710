import * as _ from 'lodash';

const echarts = require('echarts');
const colors = ['#ff8c8c', '#ffa74f', '#3ba9d9'];

angular.module('app.directives.smallData.selfData', [])
  .component('selfData', {
    bindings: {
      smallData: '<',
    },
    controller: selfDataCtrl,
    template: <string> require('./selfData.html'),
  });

selfDataCtrl.$inject = ['$window']
function selfDataCtrl($window: any) {
  this.$onInit = function() {
    this.buildData();
  }

  this.config = {
    dataLoaded: true,
    height: 300,
    theme: 'light',
    width: $window.innerWidth * 0.25,
  };

  this.getData = (data: Array<any>) => {
    const res: Array<any> = [];
    let idx = 0;
    _.forEach(data, (d: any) => {
      res.push({
        itemStyle: {
          normal: {
            color: colors[idx],
          },
        },
        name: '',
        value: d,
      });
      idx++;
    });
    return res;
  }

  this.buildCount = function() {
    const obj = _.meanBy(this.smallData.objSessions, (s: any) => s.analysisViews / s.onlineStu).toFixed(1);
    const sbj = _.meanBy(this.smallData.sbjSessions, (s: any) => s.analysisViews / s.onlineStu).toFixed(1);
    const pre = _.meanBy(this.smallData.preSessions, (s: any) => s.totalViews / s.onlineStu).toFixed(1);
    const countData = this.getData([pre, sbj, obj]);
    this.countOption = {
      grid: {
        bottom: '20%',
        left: 50,
        right: 15,
        top: '30%',
      },
      series: [{
        barMaxWidth: '30',
        data: countData,
        name: '',
        type: 'bar',
      }],
      title: {
        bottom: 0,
        left: 'center',
        text: '自主学习次数',
        textStyle: {
          color: '#000000',
          fontSize: 16,
          fontWeight: 'normal',
        },
      },
      xAxis: [{
        axisLine: {
          symbol: ['none', 'arrow'],
          symbolSize: [5, 10],
        },
        axisTick: {
          show: false,
        },
        data: ['展示', '主观', '客观'],
        splitLine: {
          show: false,
        },
        type: 'category',
      }],
      yAxis: [{
        axisLine: {
          symbol: ['none', 'arrow'],
          symbolSize: [5, 10],
        },
        axisTick: {
          show: false,
        },
        name: '次数',
        splitLine: {
          show: false,
        },
        type: 'value',
      }],
    };
  }

  this.buildTime = function() {
    const obj = (_.meanBy(this.smallData.objSessions, (s: any) => s.analysisDuration / s.onlineStu) / 1000).toFixed(0);
    const sbj = (_.meanBy(this.smallData.sbjSessions, (s: any) => s.analysisDuration / s.onlineStu) / 1000).toFixed(0);
    const pre = (_.meanBy(this.smallData.preSessions, (s: any) => s.totalDuration / s.onlineStu) / 1000).toFixed(0);
    const timeData = this.getData([pre, sbj, obj]);
    this.timeOption = {
      grid: {
        bottom: '20%',
        left: 50,
        right: 15,
        top: '30%',
      },
      series: [{
        barMaxWidth: '30',
        data: timeData,
        name: '',
        type: 'bar',
      }],
      title: {
        bottom: 0,
        left: 'center',
        text: '自主学习时间',
        textStyle: {
          color: '#000000',
          fontSize: 16,
          fontWeight: 'normal',
        },
      },
      xAxis: [{
        axisLine: {
          symbol: ['none', 'arrow'],
          symbolSize: [5, 10],
        },
        axisTick: {
          show: false,
        },
        data: ['展示', '主观', '客观'],
        splitLine: {
          show: false,
        },
        type: 'category',
      }],
      yAxis: [{
        axisLine: {
          symbol: ['none', 'arrow'],
          symbolSize: [5, 10],
        },
        axisTick: {
          show: false,
        },
        minInterval: 1,
        name: '时间(秒)',
        splitLine: {
          show: false,
        },
        type: 'value',
      }],
    };
  }

  this.buildData = function() {
    this.buildTime();
    this.buildCount();
  }
}
