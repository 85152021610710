import {OfficeEditorTipComponent} from "../../../../src/app/office-editor/office-editor-tip/office-editor-tip.component";

(function() {
  const _ = require('lodash');
  const resourceUtils = require('app2/utils/resourceUtils');
  const nobookUtils = require('app2/utils/nobookUtils');
  const url = require('url');
  const nobookHost = 'school.nobook.com';

  const officeSuffix = resourceUtils.officeSuffix
  angular.module('app.prepareCourse.resourcePreview', ['bootstrapLightbox', 'app.utils'])
    .factory('lbHttp', ['$http', '$uibModal', 'notificationService', 'oedPresentResource', 'oedDialogService', function($http, $uibModal, notificationService, oedPresentResource, oedDialogService) {
      return {
        headReq: headReq,
        openM: openM,
        downloadResource:downloadResource
      }

      function openM() {
        oedDialogService.openModalWithComponent('OfficeEditorTipComponent', {}, ()=> {}, {class: 'office-editor-modal-center'})
      }
      function headReq(url, imgUrl) {
        $http.head(url).then(() => {
          window.open(url)
        }).catch((e) => {
          notificationService.notify('info', '转码中，请稍后重试')
        })
      }
      function downloadResource(res) {
        oedPresentResource.downloadrecord({preResId:res.resourceId,preResItemId:res.id}, {}).$promise.then(res => {});
      }
    }])
    .config(['LightboxProvider', 'oedConfig', 'oedAccountUtilsProvider', 'lbHttpProvider', function(LightboxProvider, oedConfig, oedAccountUtilsProvider, lbHttpProvider) {
      'use strict';
      const uid = oedAccountUtilsProvider.$get().getUid();
      //
      //TODO: 把oedConfig放到其他模块中
      var basePath = oedConfig.base();
      var imgSrcPath = _.template(basePath + 'resource/uuid/<%=res.md5%>/img_src');
      var imgSrcPathID = _.template(basePath + 'resource/view/img_src/<%=res.id%>');
      var imgDownloadPath = _.template(basePath + 'resource/view/img_src/<%=res.id %>/raw/<%=res.id+res.suffix%>');
      var boardContentPath = _.template(basePath + 'resource/board/<%=res.id %>/content');
      var boardCommentPath = _.template(basePath + 'resource/view/img_src/<%=res.id %>');

      const imageBaseUrl = 'http://s-pic.rayclass.com';
      const videoBaseUrl = 'http://s-v.rayclass.com';

      const isExternalResource = (res) => {
        return res.isExternal && _.isEmpty(res.resourceUuid)
      }

      var getResProp = function(r, name) {
        if (_.has(r, 'flResource')) {
          return _.get(r, 'flResource.' + name);
        }

        return _.get(r, name);
      };

      function getResUUID(res) {
        return resourceUtils.extractResourceUUID(res) || resourceUtils.extractResourceUUID(res.flResource);
      }

      function getImgSrcPath(res, name) {

        if (res.isTalcloud) {
          return res.downloadUrl
        }

        const uuid = getResUUID(res);
        if (_.isEmpty(uuid)) {
          const resId = _.get(res, 'flResourceId', res.id)
          return imgSrcPathID({
            res: {
              id: resId,
            }
          });
        }
        return '/ray/resource/uuid/' + uuid + '/raw64/' + name;
      }

      LightboxProvider.getImageUrl = function(res, isDownload, preview, imgUrl, edit, dismiss,downloadResource) {
        if (!res)
          return null;

        if (edit) {
          if (resourceUtils.editableSuffix.some(suffix => res.flResource.suffix === suffix)) {
            dismiss(res)
          } else {
            lbHttpProvider.$get().openM()
          }

          return
        }
        if (preview) {
          if (!imgUrl) {
            return
          }

          if (!imgUrl.startsWith('http') && officeSuffix.some((suffix) => res.resourceName.endsWith(suffix)) && !res.isExternal) {
            const previewUrl = '/ray/resource/uuid/' + res.resourceUuid + '/pdf'
            lbHttpProvider.$get().headReq(previewUrl, imgUrl)
          } else {
            window.open('/#/previewer?src=' + imgUrl);
          }

          return;
        }

        function b64EncodeUnicode(str) {
          return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
            return String.fromCharCode('0x' + p1);
          }));
        }

        var src = null;
        var prop = isExternalResource(res) ? 'externalResourceType' : 'fileType';
        var fileType = getResProp(res, prop);
        if (res.isTalcloud) {
          fileType = res.fileType
        }
        if (fileType == 'boardContent') {
          return boardContentPath({
            res: res
          });
        } else if (fileType == 'boardComment') {
          return boardCommentPath({
            res: res
          });
        }

        if (isDownload) {
          if (isExternalResource(res)) {
            return res.externalResourceUrl
          }

          if (res.$isTalAreaRes) {
            return res.previewUrl
          }

          if (res.isTalcloud) {
            return res.downloadUrl
          }

          if (res.webUrl && url.parse(res.webUrl).hostname == nobookHost) {
            return nobookUtils.buildRedirectUrl(res.webUrl, uid.$$state.value)
          }

          if (res.webUrl) {
            return res.webUrl;
          }
          if (downloadResource == 'downloadResource' ) {
            lbHttpProvider.$get().downloadResource(res);
          }
          var name = res.resourceName || '';
          var suffix = getResProp(res, 'suffix');
          if (suffix && !name.endsWith(suffix)) {
            name = name + '.' + suffix;
          }
          name = b64EncodeUnicode(encodeURIComponent(name));
          return getImgSrcPath(res, name);
        }

        if (res.$isTalAreaRes) {
          return res.previewUrl
        }

        if (res.resourceName && officeSuffix.some((suffix) => res.resourceName.endsWith(suffix)) && !isExternalResource(res)) {
          return '/ray/resource/uuid/' + res.resourceUuid + '/pdf'
        }

        // 微课的资源地址为 http://www.rayclass.com/ray/resource/uuid/src
        // 展示资源的地址为 /ray/resource/uuid/src
        function replaceRayclassWithCdn(url, baseurl) {
          if ( process.env.NAME === 'beta') {
            return url;
          }
          if (url.search(/https?(:*)(\/\/)\w*.rayclass.com/) > -1) {
            return _.replace(url, /https?(:*)(\/\/)\w*.rayclass.com/, baseurl)
          }
          if (_.startsWith(url, 'http://') || _.startsWith(url, 'https://')) {
            return url;
          }
          return baseurl + url;
        }

        if (res.$isTalAreaRes) {
          return res.previewUrl
        }

        if (fileType == 'image' || fileType == 'audio' || fileType == 'video') {
          if (isExternalResource(res)) {
            return res.externalResourceUrl
          }

          if (res.isTalcloud) {
            return res.fileUrl
          }

          // 这个地址是 /ray/resouce/
          src = getResProp(res, 'imageSrcHttpPath');
          if (!src) {
            return getImgSrcPath(res);
          }

          if (fileType == 'image') {
            return replaceRayclassWithCdn(src, imageBaseUrl);
          }

          if (fileType == 'audio' || fileType == 'video') {
            return replaceRayclassWithCdn(src, videoBaseUrl);
          }

          return src;
        }

        if (res.isTalcloud) {
          return res.fileUrl
        }

        if (isExternalResource(res)) {
          return res.externalResourceSnapshotUrl
        }
        return getResProp(res, 'imageThumbHttpPath');
      };

      LightboxProvider.getOpenImageUrl = function(res) {
        var fileType = getResProp(res, 'fileType');
        if (res.isTalcloud) {
          fileType = res.fileType
        }
        if (fileType == 'boardContent') {
          return boardContentPath({
            res: res
          });
        } else if (fileType == 'boardComment') {
          return boardCommentPath({
            res: res
          });
        }

        if (isExternalResource(res)) {
          return res.externalResourceUrl
        }

        if (res.isTalcloud) {
          return res.downloadUrl
        }

        if (res.webUrl && url.parse(res.webUrl).hostname == nobookHost) {
          return nobookUtils.buildRedirectUrl(res.webUrl, uid.$$state.value)
        }

        if (res.webUrl) {
          return res.webUrl;
        }
        // TODO prodider download url for external resource url
        var src = getResProp(res, 'imageSrcHttpPath');
        if (!src) {
          src = imgDownloadPath({
            res: res
          });
        }

        return src;
      };

      LightboxProvider.getImageCaption = function(res, cfg) {
        if (!cfg) {
          return res.resourceName;
        }
        if (!res) {
          return '下载原文件';
        }

        if (cfg == 'download') {
          if (res.webUrl) {
            return '打开原网页';
          }
        }

        return '下载原文件';
      };

      LightboxProvider.isVideo = function(r) {
        var prop = isExternalResource(r) ? 'externalResourceType' : 'fileType';
        var resType = getResProp(r, prop);
        if (r.isTalcloud) {
          resType = r.fileType
        }
        if (r.$isTalAreaRes) {
          resType = r.fileType
        }
        return resType == 'audio' || resType == 'video';
      };

      LightboxProvider.isZlwh = function (image) {
        if (typeof image === 'object' && image && image.flResource && image.flResource.source) {
          return image.flResource.source === 'zlwh';
        }
        return false;
      };
      //
      // hack Lightbox方法
      // 如果第二个参数为true, 则返回是否文字资源
      LightboxProvider.isSharedVideo = function(r, opt, checkOffice, editButton) {
        if (r.$isTalAreaRes && r.fileType === 'office') {
          return true
        }

        if (checkOffice) {
          if (r.resourceName && officeSuffix.some((suffix) => r.resourceName.endsWith(suffix)) && !r.isExternal) {
            return true
          } else if (r.$isTalAreaRes && r.fileType === 'office') {
            return true
          } else {
            return false
          }
        }

        if (editButton) {
          if (r.$$fromEdit && r.resourceName && officeSuffix.some((suffix) => r.resourceName.endsWith(suffix)) && !r.isExternal) {
            return true
          } else {
            return false
          }
        }

        if (opt) {
          if (_.get(r, 'thirdParty', '') === 'weike_yueyi') {
            return true
          }

          var prop = isExternalResource(r) ? 'externalResourceType' : 'fileType';
          return getResProp(r, prop) == 'text';
        }
        //
        // 始终返回false, 不支持youtube之类的视频
        var suffixProp = isExternalResource(r) ? 'externalResourceSuffix' : 'suffix';
        var suffix = getResProp(r, suffixProp)
        return suffix == 'm3u8';
      };
    }])
    .controller('resourcePreviewCtrl', [resourcePreviewCtrl]);

  function resourcePreviewCtrl() {
    console.log($('my_video_1'))
  }
})();
