import angular = require('angular');
import _ = require('lodash');

require('assets/styles/oedTable.scss');

angular.module('app.review.preresPreviewer', [])
  .controller('preresPreviewerCtrl', preresPreviewerCtrl);

preresPreviewerCtrl.$inject = ['$scope', 'data', '$uibModalInstance',
  'oedObjectiveTest', '$q']
function preresPreviewerCtrl(
  $scope: any, data: any, $uibModalInstance: any,
  oedObjectiveTest: any, $q: any) {

  $scope.preResId = data.preResId;
  $scope.name = data.name;
  $scope.close = () => {
    $uibModalInstance.close();
  };
}
