var messages = require('app2/utils/messages');

(function() {
  'use strict';

  angular.module('app.manage.searchIntegral', [])
    .controller('searchIntegralCtrl', searchIntegralCtrl);

  searchIntegralCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'notificationService',
    'oedAccountUtils',
    'oedUserInfo',
    'data',
    'oedStudentGroupScore'
  ]
  function searchIntegralCtrl(
    $scope,
    $uibModalInstance,
    notificationService,
    oedAccountUtils,
    oedUserInfo,
    data,
    oedStudentGroupScore
  ) {

    $scope.record = {
      taskListId: data.taskListId,
      classes: [],
      startDate: moment().startOf('months')._d,
      dueDate: moment().endOf('day')._d
    };
    $scope.students = data.students
    $scope.groups = data.groups
    $scope.curGroupIdx = data.curGroupIdx
    $scope.stdMap = _.keyBy($scope.students, 'uid')
    $scope.group = $scope.groups[$scope.curGroupIdx]
    $scope.sg = $scope.group.subGroups[0]

    $scope.getSubGroupName = (index) => {
      const subNames = _.get($scope.group, 'subGroupInfo', []);
      let curSub;
      _.forEach(subNames, (o) => {
        if (o.subGroupIdx === index) {
          curSub = o;
        }
      });
      const curName = _.get(curSub, 'subName');
      if (curName) {
        return `${curName}`;
      }
      return `第${(index + 1)}组`
    }

    $scope.integral = 0
    $scope.groupScore = 0
    $scope.subGroupName = $scope.getSubGroupName(0)
    $scope.closeDialog = () => $uibModalInstance.dismiss()

    function computedGroupScore(index) {
      $scope.groupScore = 0
      if ($scope.scoreList) {
        _.forEach($scope.scoreList[index], (item) => {
          $scope.groupScore += item.score
        })
      }
    }

    function getCurrentMonthsScore() {
      const start = moment($scope.record.startDate).startOf('day').format('x')
      const end = moment($scope.record.dueDate).endOf('day').format('x')
      if ($scope.sg && $scope.sg[0]) {
        const data = {
          id: $scope.sg[0].groupId,
          start: start,
          end: end
        }
        return oedStudentGroupScore.getScoreBySometime(data).$promise.then((res) => {
          $scope.scoreList = _.groupBy(res, 'subGroupIdx');
          if ($scope.group.subGroups && $scope.group.subGroups[0]) {
            computedGroupScore($scope.sg[0].subGroupIdx)
          }
        })
      }
    }

    $scope.switchGroup = (sg, index) => {
      _.forEach($scope.group.subGroups, (item) => {
        item.$$isSelect = false
      })
      $scope.sg = sg
      $scope.sg.$$isSelect = true
      $scope.subGroupName = $scope.getSubGroupName(index)
      getCurrentMonthsScore()
      computedGroupScore($scope.group.subGroups[index][0].subGroupIdx)
    }

    getCurrentMonthsScore()
    $scope.changeIntegral = () => {
      if ($scope.sg && $scope.sg[0]) {
        const data = {
          groupId: $scope.sg[0].groupId,
          subGroupIdx: $scope.sg[0].subGroupIdx,
          score: $scope.integral
        }
        return oedStudentGroupScore.setScoreWithGroup(data).$promise.then((res) => {
          $scope.integral = 0
          getCurrentMonthsScore($scope.sg[0].subGroupIdx)
        })
      }
    }

    $scope.getStdAvatarImgSrc = function(std) {
      if ('female' == std.gender) {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_f1.png';
        } else {
          return 'Assets/images2/stu_avatar_f2.png';
        }
      } else {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_m1.png';
        } else {
          return 'Assets/images2/stu_avatar_m2.png';
        }
      }
    }

    $scope.validStd = (uid) => {
      return _.hasIn($scope.stdMap, uid);
    }

    _.forEach($scope.group.subGroups, (item, index) => {
      if (index === 0) {
        item.$$isSelect = true
      } else {
        item.$$isSelect = false
      }
    })

    $scope.openDueDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.dueDateOpened = true;
    };

    $scope.openStartDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startDateOpened = true;
    };

    $scope.searchClick = () => {
      if (!$scope.record.startDate || !$scope.record.dueDate) {
        return notificationService.notify('info', '请填写时间')
      }
      var startDate = moment($scope.record.startDate).startOf('day').valueOf();
      var dueDate = moment($scope.record.dueDate).endOf('day').valueOf();
      if (startDate > dueDate) {
        return notificationService.notify('info', '结束时间不能早于开始时间')
      }

      const start = moment($scope.record.startDate).startOf('day').format('x')
      const end = moment($scope.record.dueDate).endOf('day').format('x')

      if ($scope.sg && $scope.sg[0]) {
        const data = {
          id: $scope.sg[0].groupId,
          start: start,
          end: end
        }
        $scope.loading = oedStudentGroupScore.getScoreBySometime(data).$promise.then((res) => {
          $scope.scoreList = _.groupBy(res, 'subGroupIdx');
          if ($scope.group.subGroups && $scope.group.subGroups[0]) {
            computedGroupScore($scope.sg[0].subGroupIdx)
          }
        })
      }
    }
  }
})();

