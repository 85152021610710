import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TutorialComponent } from './tutorial/tutorial.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { postLoginStates } from './tutorial.state';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

@NgModule({
  declarations: [TutorialComponent],
  imports: [
    CommonModule,
    TypeaheadModule.forRoot(),
    UIRouterUpgradeModule.forChild({ states: postLoginStates })
  ],
  entryComponents: [TutorialComponent]
})
export class TutorialModule { }
