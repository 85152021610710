import { Component, EventEmitter, OnDestroy, OnInit, Input, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Map } from 'core-js'

import * as _ from 'lodash'

interface ConnectListItems {
  name: string;
  value: any;
}

@Component({
  selector: 'app-connect-option-picker',
  template: require('./connect-option-picker.component.html'),
  styles: [require('./connect-option-picker.component.scss')]
})
export class ConnectOptionPickerComponent implements OnChanges, OnDestroy, OnInit {
  @Input() items
  @Input() selectedItems

  @Output() selectedItemsChange = new EventEmitter<any[]>()

  listItems: ConnectListItems[]  = []
  listSelectedItems: ConnectListItems[] = []

  constructor() { }

  ngOnChanges(changesObj: SimpleChanges) {
    if (_.has(changesObj, 'items')) {
      this.updateOptions()
    }
    if (_.has(changesObj, 'selectedItems')) {
      this.updateSelection()
    }
  }


  ngOnInit() {
  }

  ngOnDestroy() {
  }

  private updateOptions() {
    this.listItems = _.map(this.items, (item, idx) => {
      return {
        name: '' + (idx + 1),
        value: item,
      }
    })
  }

  private updateSelection() {
    const itemMap = _.reduce(this.listItems, (m, item) => {
      m.set(item.value, item)
      return m
    }, new Map())
    this.listSelectedItems = _.chain(this.selectedItems).map((item) => {
      return itemMap.get(item)
    }).filter().value()
  }

  handleChange() {
    const selected = _.map(this.listSelectedItems, 'value')
    this.selectedItemsChange.emit(selected)
  }

  compareItem(a: ConnectListItems, b: ConnectListItems) {
    return a.value === b.value
  }
}
