(function() {
  'use strict';

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.testSessionSubmitDetails', {
        url: '/origTaskList/:origTaskListId/publishRecord/:publishRecordId/test/:testId/testSession/:testSessionId/' +
        'boardSession/:boardSessionId/progress',
        template: require('assets/templates/homework/testSessionSubmitDetails.html'),
        controller: 'testSessionSubmitDetailsCtrl',
        bodyStyle: 'bged'
      });
    }])
    .controller('testSessionSubmitDetailsCtrl', testSessionSubmitDetailsCtrl)
    .directive('testSessionSubmitDetails', testSessionSubmitDetails);

  function testSessionSubmitDetails($document, $timeout) {
    return {
      restrict: 'E',
      scope: {
        origTaskListId: '<',
        publishRecordId: '<',
        testSessionId: '<',
        testId: '<',
        boardSessionId: '<',
        isFromNewPrepareReport: '<',
        returnReportList: '&'
      },
      template: require('assets/templates/homework/testSessionSubmitDetails.html'),
      controller: testSessionSubmitDetailsCtrl
    };
  }

  testSessionSubmitDetailsCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', 'oedObjectiveTestSessionStats',
    '$uibModal', 'userInfo', 'oedTaskList', 'oedSubjectiveTest',
    'oedCourse', 'dialogs', 'oedTaskListPublishRecord', 'oedBoardSession']
  function testSessionSubmitDetailsCtrl($scope, $state, $stateParams, $q, oedObjectiveTestSessionStats,
                                  $uibModal, userInfo, oedTaskList, oedSubjectiveTest,
                                  oedCourse, dialogs, oedTaskListPublishRecord, oedBoardSession) {

    var origTaskListId = $scope.isFromNewPrepareReport ? $scope.origTaskListId : _.parseInt($stateParams.origTaskListId);
    var publishRecordId = $scope.isFromNewPrepareReport ? $scope.publishRecordId : _.parseInt($stateParams.publishRecordId);
    var testId = $scope.isFromNewPrepareReport ? $scope.testId : _.parseInt($stateParams.testId);
    var testSessionId = $scope.isFromNewPrepareReport ? $scope.testSessionId : _.parseInt($stateParams.testSessionId);
    var boardSessionId = $scope.isFromNewPrepareReport ? $scope.boardSessionId : _.parseInt($stateParams.boardSessionId);
    $scope.testSessionId = testSessionId;
    $scope.recordId = publishRecordId;
    $scope.origTestId = testId;
    $scope.boardSessionId = boardSessionId;
    $scope.origTaskListId = origTaskListId;
    $scope.activeTabIndex = 1;
    $scope.tabs = [{index: 0, text: '题目和解析'},
      {index: 1, text: '完成情况'},
      {index: 2, text: '统计表格'}];

    $scope.boardSession = oedBoardSession.get({
      id: $scope.boardSessionId
    });

    $scope.publishRecords = oedTaskList.queryPublishInfo({
      id: $scope.origTaskListId
    });

    $scope.loadTestSessionSubmitDetails = function() {
      $scope.currentTest = oedSubjectiveTest.get({
        id: testId
      });
      var getOrigTaskListPublishInfoQuery = oedTaskListPublishRecord.queryPublishInfo({
        id: publishRecordId
      });

      return getOrigTaskListPublishInfoQuery.$promise.then(function(publishInfo) {
        $scope.publishInfo = publishInfo;
        $scope.publishRecord = {
          id: publishRecordId
        };
      });
    };

    $scope.loading = $q.all([$scope.loadTestSessionSubmitDetails(), $scope.publishRecords.$promise, $scope.boardSession.$promise]).then(() => {
      try {
        if ($scope.boardSession) {
          $scope.boardSession.baseBoardContent = JSON.parse($scope.boardSession.baseBoardContent);
        }
      } catch (ex) {} // eslint-disable-line
      $scope.publishRecordOfSelectedClass = _.find($scope.publishRecords.publishRecords, function(record) {
        return record.classId === $scope.publishInfo.cls.id;
      });
      $scope.loaded = true;
    });
  }
})();
