import * as angular from 'angular';
import 'app2/utils/fileUtils';
import _ = require('lodash');

angular.module('app.directives.review.preres.preResItemsPreviewer', [])
.component('preResItemsPreviewer', {
  bindings: {
    preResId: '<',
    preResSession: '<',
    showItemSummary: '&',
  },
  controller: preResItemsPreviewerCtrl,
  template: <string> require('./preResItemsPreviewer.html'),
});

preResItemsPreviewerCtrl.$inject = ['oedPresentResource', 'oedCloudPresentResItem', '$uibModal', 'oedPreResSession', 'oedPreResItemSession', '$q', 'oedPresentResourceItem', 'oedCloudPresentRes']
function preResItemsPreviewerCtrl(
  oedPresentResource: any, oedCloudPresentResItem: any, $uibModal: any,
  oedPreResSession: any, oedPreResItemSession: any, $q: any, oedPresentResourceItem: any, oedCloudPresentRes: any) {
  const ctrl = this;

  this.$onInit = () => {
    if (!ctrl.preResId) {
      const preResItemId = ctrl.preResSession.preResItemId
      return oedPresentResourceItem.get({
        id: preResItemId,
      }).$promise.then((item) => {
        ctrl.preResItem = item
        return oedCloudPresentResItem.getByResItemId({
          id: item.id,
        }).$promise.then((ci: any) => {
          item.cloudRes = ci;
          ctrl.resource = _.assign({}, {
            items: [item],
          })
        });
      })
    }

    const resource = oedPresentResource.get({
      id: ctrl.preResId,
    });

    function filterItems() {
      if (ctrl.preResSession.preResItemId) {
        resource.items = _.filter(resource.items, (item: any) => {
          return item.id === ctrl.preResSession.preResItemId;
        })
      } else {
        const excludeIds = _.keyBy(_.split(ctrl.preResSession.excludePreResItemIds, ','));
        resource.items = _.filter(resource.items, (item: any) => {
          return !_.has(excludeIds, item.id);
        })
      }
    }

    ctrl.loading = resource.$promise.then(() => {
      return oedCloudPresentRes.queryByPresentResId({
        id: resource.id
      }).$promise
    }).then((cloudRes) => {
      if (ctrl.preResSession) {
        filterItems();
      }
      _.map(resource.items, (item: any) => {
        return oedCloudPresentResItem.getByResItemId({
          id: item.id,
        }).$promise.then((ci: any) => {
          item.cloudRes = ci;
          return item;
        });
      });
      resource.$cloudRes = cloudRes
      ctrl.resource = resource;
    });
  }
}
