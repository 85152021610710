import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { BoardContentInteractsMDTOView, LegacyPairDTO, BoardContentCommentDTO } from 'app/models/sbj-test-session';
import { UserDTO } from 'app/models/user';
import { RayApiService } from 'app/core/ray-api.service';
import { UrlBuilderService } from 'app/shared/url-builder.service';
import { NotificationService } from 'app/shared/notification/notification.service';

type SortType = 'bravosCount' | 'viewsCount' | 'commentsCount' | 'flagsCount' | 'time'

@Component({
  selector: 'app-sbj-std-content-tool',
  template: require('./sbj-std-content-tool.component.html'),
  styles: [require('./sbj-std-content-tool.component.scss')]
})
export class SbjStdContentToolComponent implements OnInit {
  @Input() boardSessionId: number;
  @Input() interacts: BoardContentInteractsMDTOView[];
  @Input() interactsSort: BoardContentInteractsMDTOView[];

  @Input() isAllSelected: boolean;
  @Output() isAllSelectedChange = new EventEmitter<boolean>();
  sortType: SortType;
  desc = false;

  countInteract = {
    bravosCount: (interact: BoardContentInteractsMDTOView) => {
      return _.size(interact.bravosList);
    },
    commentsCount: (interact: BoardContentInteractsMDTOView) => {
      const rankComments: any = _.filter(_.map(interact.commentsList, 'right.commentBody.rank'), (rank: any) => {
        return !_.isUndefined(rank);
      })

      return _(interact.commentsList).filter(this.isVisibleComment).size() - _.size(rankComments)
    },
    flagsCount: (interact: BoardContentInteractsMDTOView) => {
      return _.size(interact.flagsList);
    },
    time: (interact: BoardContentInteractsMDTOView) => {
      return interact.contentList[0].submitTime;
    },
    viewsCount: (interact: BoardContentInteractsMDTOView) => {
      return _.size(interact.viewsList);
    },
  }

  constructor(private notificationService: NotificationService,
              private rayApi: RayApiService,
              private urlBuilder: UrlBuilderService) { }

  ngOnInit() {
  }

  public toggleSortType (t: SortType) {
    this.desc = this.sortType === t ? !this.desc : true;
    this.sortType = t;
    this.sortData();
  }

  public sortData () {
    this.interacts.sort((a: any, b: any) => {
      const aCount = this.countInteract[this.sortType](a);
      const bCount = this.countInteract[this.sortType](b);
      if (this.desc) {
        return bCount - aCount;
      }
      return aCount - bCount;
    });
  }

  public isVisibleComment(c: LegacyPairDTO<UserDTO, BoardContentCommentDTO>) {
    const cmt = c.right;
    return !_.isEmpty(cmt.comment) || !!cmt.imgResId || !!cmt.imgResUuid;
  }

  public allSelectChanged() {
    _.each(this.interacts, (it) => {
      it.selected = this.isAllSelected;
    })
  }

  downloadSelected () {
    const selectedItems = _(this.interacts).filter((it) => {
      return !!it.selected;
    });
    if (selectedItems.isEmpty()) {
      return this.notificationService.notify('info', '没有选择作品, 请选择要下载的学生后再点击下载按钮')
    }

    const selectedStdIds = selectedItems.map('student.studentId').filter(_.isNumber).value();
    const downloadUrl = this.rayApi.apiB('boardsession/' + this.boardSessionId + '/download');
    const url = this.urlBuilder.buildUrl(downloadUrl, {
      studentsIds: selectedStdIds,
    })
    window.open(url,_.get(window, '_link_open_type'));
  }

}
