import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { InlineEditorConfig } from 'app/editing/inline-ue-editor/inline-editor';
import * as _ from 'lodash'

import { getChoiceName } from 'app2/utils/testUtils2';
import { LegacyAppService } from 'app/core/legacy-app.service';

@Component({
  selector: 'app-multi-choice-designer',
  template: require('./multi-choice-designer.component.html'),
  styles: [require('./multi-choice-designer.component.scss')]
})
export class MultiChoiceDesignerComponent implements OnInit {
  @Input() content
  @Input() editorConfig: InlineEditorConfig
  @Input() resDelShowType: boolean
  @Input() isThreeDegrees: boolean

  @Output() addChoice = new EventEmitter<any>()
  @Output() showResAndDelClick = new EventEmitter<any>()

  choices = []
  dialogs = null
  inEditor = false
  curIndex = -1

  constructor(legacyApp: LegacyAppService) {
    this.dialogs = legacyApp.getDialogs()
  }

  ngOnInit() {
  }

  ngOnChanges(changesObj: SimpleChange) {
    if (_.has(changesObj, 'content')) {
      console.log('model change')
      const idxs = _.split(this.content.answer, ',');
      _.each(idxs, (v) => {
        this.choices[parseInt(v, 10)] = true;
      })
    }
  }

  public getChoiceName(idx: number) {
    return getChoiceName(idx)
  }

  doRemoveChoice(idx: number) {
    this.content.choices.splice(idx, 1)
    this.choices.splice(idx, 1)
    this.updateAnswer()
  }

  removeChoice(idx: number) {
    if (!this.content.choices[idx].content) {
      this.doRemoveChoice(idx);
    } else {
      const dlg = this.dialogs.confirm('确定删除？', '此选项已设置内容，真的要删除吗？');
      dlg.result.then((btn) => {
        this.doRemoveChoice(idx);
      }, (btn) => {});
    }
  }

  toggleAnswer(idx: number) {
    this.choices[idx] = !this.choices[idx];
    this.updateAnswer()
  }

  updateAnswer() {
    const answers = [];
    _.each(this.choices, (v, idx) => {
      if (v)
        answers.push(idx);
    });

    this.content.answer = answers.join(',');
  }

  isAnswer(idx: number) {
    return !!this.choices[idx]
  }

  enterEditor(index: number) {
    this.curIndex = index;
    this.inEditor = true;
  }

  leaveEditor() {
    this.inEditor = false;
  }

  onAddChoice() {
    this.addChoice.emit(0)
    return false
  }

  public onShowResAndDel(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.showResAndDelClick.emit(true)
  }
}
