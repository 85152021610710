angular.module('app.examination.addTestPaper',[])
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('addTestPaperToPath', {
      url: '/addTestPaperToPath/:pathId?index',
      template: require('assets/templates/examination/editInfo.html'),
      controller: 'addTestPaperCtrl',
      loadingCls: 'blueloading',
      bodyStyle: 'bged'
    });
  }])
  .controller('addTestPaperCtrl', addTestPaperCtrl);

addTestPaperCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', '$log', 'dialogs',
  'userInfo', 'oedConfig', 'oedCourse', 'oedLoading', 'oedSection', 'oedUnitItem',
  'oedCourseUtils', 'oedCoursePath', 'oedOem', 'oedExamination']
function addTestPaperCtrl(
  $scope, $state, $stateParams, $uibModal, $log, dialogs,
  userInfo, oedConfig, oedCourse, oedLoading, oedSection, oedUnitItem,
  oedCourseUtils, oedCoursePath, oedOem, oedExamination) {

  $scope.sort = Number($stateParams.index);
  // 新建考试的参数
  $scope.course = {
    coursepathId: _.parseInt($stateParams.pathId),
    relatedSections: [],
    sectionMode: 'outline',
    sort: $scope.sort,
    duration: 120
  };

  $scope.adding = true;
  if ($scope.course.coursepathId) {
    oedCoursePath.get({id: $scope.course.coursepathId}).$promise.then(function(c) {
      $scope.coursePath = c;
    });
  }

  $scope.treeConfig = {
    core: {
      multiple: true,
      animation: true,
      error: (err) => {
        $log.error('treeCtrl: error from js tree - ' + angular.toJson(err));
      },
      check_callback: true,
      worker: true
    },
    types: {
      default: {
        icon: 'fa fa-book'
      },
      root: {
        icon: 'glyphicon glyphicon-cloud'
      }
    },
    version: 1,
    plugins: ['types', 'checkbox']
  };

  const convertToJsTreeModel = function(sections) {
    const allNodes = [];

    function addAllNodes(sec) {
      const id = sec.id || 0;
      let parentId = (_.has(sec, 'parentId') && sec.parentId !== null) ? sec.parentId : '0';
      if (!_.has($scope.sectionIdMap, parentId)) {
        parentId = '#';
      }

      const name = sec.name || sec.gradeName;

      allNodes.push({
        id: id,
        parent: parentId,
        text: name,
        state: {
          opened: true
        }
      });
      _.each(sec.child, addAllNodes);
    }

    if (_.size(sections) === 1) { //只有一个年级的情况, 忽略掉年级这个node{
      _.each(sections[0].child, addAllNodes);
    } else {
      _.each(sections, addAllNodes);
    }

    return allNodes;
  };

  $scope.sectionTree = {
    treeData: []
  };

  $scope.onSelChanged = function() {
    $scope.$apply(function() {
      const selectedNodes = $scope.treeInstance.jstree(true).get_selected();
      const ids = _(selectedNodes).map(function(node) {
        return _.parseInt(node, 10);
      }).filter().value();

      $scope.loading = oedCourseUtils.loadSectionNamesById(ids).then(function(name) {
        $scope.selectedSectionNames = name || '';
        if (_.isEmpty($scope.course.name)) {
          $scope.course.name = name;
        }
      });
    });
  };

  userInfo.then((info) => {
    $scope.availableSections = oedUnitItem.queryAvailableBySchoolAndCoursePath({
      schoolId: info.schoolId,
      coursePathId: $scope.course.coursepathId
    });

    $scope.canStar = info.canStar;
    $scope.uid = info.uid;
    $scope.course.teacherId = Number(info.uid);

    $scope.availableSections.$promise.then(function() {
      $scope.sectionIdMap = {};

      function visitSection(sec) {
        if (sec.id) {
          $scope.sectionIdMap[sec.id] = sec
        }
        if (sec.child) {
          sec.child = _.sortBy(sec.child, ['sort']);
          _.each(sec.child, visitSection);
        }
      }
      _.each($scope.availableSections, visitSection);

      $scope.sectionTree.treeData = convertToJsTreeModel($scope.availableSections);
      $scope.treeConfig.version++;

    });
  })

  $scope.onTreeReady = () => {
    if ($scope.course.relatedSections) {
      const sections = _.map($scope.course.relatedSections, function(relSec) {
        return relSec.sectionId;
      });
      $scope.treeInstance.jstree(true).select_node(sections);
    }
  };

  $scope.shouldApplyModelChanges = () => {
    return true;
  };

  $scope.preventDropdownDefault = function(event) {
    event.preventDefault();
    event.stopPropagation();
  };
  $scope.gotoNext = () => {
    $scope.showValidationError = true;
    const selectedNode = $scope.treeInstance.jstree(true).get_selected();
    $scope.course.relatedSections = _.map(selectedNode, (secId) => {
      return _.parseInt(secId);
    });

    if (_.isEmpty($scope.course.relatedSections)) {
      dialogs.error('无法保存', '当前课程没有关联章节, 请关联章节后再继续');
      return;
    }
    oedExamination.addTestPaper($scope.course).$promise.then((res) => {
      $state.go('editObjectTestPaper', {
        id: res.id,
        coursepathId: res.coursepathId
      }, {
        reload: true,
        location: 'replace'
      });
    });
  };
}
