require('assets/app/controllers/examination/examSummary/examSummary.scss');
import { getExamQuesTotal, getExamTestTotal } from 'assets/app/controllers/examination/examEdit/paper/paperUtils'
import * as _ from 'lodash'

examSummaryCtrl.$inject = ['$uibModal', '$scope']
function examSummaryCtrl($uibModal, $scope) {
  const ctrl = this
  ctrl.partIndex = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十二', '十三', '十四', '十五'];

  ctrl.$onChanges = (changeObj: any) => {
    if (_.has(changeObj, 'examData')) {
      ctrl.showSummary = !_.isEmpty(ctrl.examData)
      ctrl.name = _.get(ctrl.examData, 'examWithTags.exam.name', '')
      ctrl.duration = _.get(ctrl.examData, 'examWithTags.exam.duration', '')
      ctrl.examPartDetails = _.get(ctrl.examData, 'examPartDetails', '')
      ctrl.totalScore = getExamTestTotal(ctrl.examPartDetails)
    }
  };

  ctrl.getPartScore = (index: number) => {
    return getExamQuesTotal(ctrl.examPartDetails, index)
  }

  ctrl.editSummary = () => {
    const modalInstance = $uibModal.open({
      template: require('assets/app/controllers/examination/dialog/editSummaryEdit.html'),
      controller: 'editSummaryEditCtrl',
      size: 'exam-summary',
      windowClass: 'oed-common-modal',
      resolve: {
        data: () => {
          return {
            name: `${ctrl.name}`,
            duration: ctrl.duration,
            doChange: (name, duration, deferred) => ctrl.updateExamWithTags({ name, duration, deferred })
          }
        }
      }
    })
    modalInstance.result.finally(() => {
      $scope.$resume()
    })
  }
}

export const examSummary = {
  bindings: {
    examData: '<',
    isCanEdit: '<',
    updateExamWithTags: '&',
  },
  template: <string>require('./examSummary.html'),
  controller: examSummaryCtrl,
}

angular.module('app.examination.editObjectTestPaper.examSummary', [])
  .component('examSummary', examSummary);
