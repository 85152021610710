import {  animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: '[new-prepare-notify-component]',
  styles: [`
    :host {
      color: #000!important;
      background-color: transparent!important;
      position: relative;
      overflow: hidden;
      margin: -20px 0 6px;
      font-size: 16px;
      pointer-events: all;
      cursor: pointer;
      box-shadow: none!important;
      border: none!important;
    }
    .message-info {
      background: #ffffff;
      border-radius: 10px!important;
      box-shadow: 0 3px 10px rgba(0,0,0,0.15);
      -o-box-shadow: 0 3px 10px rgba(0,0,0,0.1);
      -ms-box-shadow: 0 3px 10px rgba(0,0,0,0.1);
      -moz-box-shadow: 0 3px 10px rgba(0,0,0,0.1);
      -webkit-box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    }
    .arrow-point-left {
      position: absolute;
      left: 0;
      top: 50%;
      display: inline-block;
      margin-top: -3px;
      margin-left: -10px;
      border: 5px solid #fff;
      border-top-color: transparent;
      border-left-color: transparent;
      border-bottom-color: transparent;
      width: 5px;
      height: 5px;
    }
    .arrow-point-right {
      position: absolute;
      right: 0;
      top: 50%;
      display: inline-block;
      margin-top: -3px;
      margin-right: -10px;
      border: 5px solid #fff;
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      width: 5px;
      height: 5px;
    }
    .btn-size {
      font-size: 24px!important;
    }
    .btn-color {
      color: #869919!important;
    }
    .text-grey {
      color: #cccccc!important;
    }
  `],
  template: `
  <div class="position-relative">
    <div class="arrow-point-left vertical-middle" *ngIf="options.pointPosition == 'leftArrow'"></div>
    <div class="arrow-point-right vertical-middle" *ngIf="options.pointPosition == 'rightArrow'"></div>
    <div class="vertical-middle padding-md message-info">
      <i class="fa fa-check-circle btn-color btn-size vertical-middle margin-right-md"></i>
      <div *ngIf="message" role="alert" aria-live="polite" class="vertical-middle"
           [class]="options.messageClass" [attr.aria-label]="message"
           style="min-width: 270px; display: inline-block; margin-top: 2px;">
        {{ message }}
      </div>
      <i class="fo-x pull-right margin-left-md vertical-middle btn-size text-grey"></i>
    </div>
  </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0, display: 'none' })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}')
      )
    ])
  ],
  preserveWhitespaces: false
})
export class NewPrepareNotify extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastr: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }
}
