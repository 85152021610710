import {SelectedLevelSubject, XuersiBooksDTO,
  XuersiLevel, XuersiSubject} from 'app2/integration/xuersi/models'
import {getQuesType} from 'app2/integration/xuersi/xuersiUtils'
import Promise = require('bluebird')
import $ = require('jquery')
import _ = require('lodash')

const blank = '_____'
const difficultymap = {
  1: '容易',
  2: '普通',
  3: '困难',
}

const aCode = 'a'.charCodeAt(0)
function buildDefaultChoices(options: any) {
  if (_.isEmpty(options)) {
    return
  }
  const sortKeys = _(options).keys().sortBy().value()
  return _.map(sortKeys, (key) => {
    const value = _.get(options, key, '')
    return {
      content: `<p>${_.isEmpty(value) ? '' : value}</p>`,
    }
  })
}

const baseCharCode = 'A'.charCodeAt(0);
function parseChoiceAnswers(answers: any): string {
  const index = _.get(answers, 'items', 'A').charCodeAt(0) - baseCharCode
  return index.toString()
}

function singleChoiceDataExtractor(q: any) {
  //
  // 选项数只能从答案中反推...
  const answer = parseChoiceAnswers(_.get(q, 'question.body.answer'))

  return {
    answer: _.chain(answer).toNumber().isNumber().value() ? answer : 0,
    choices: buildDefaultChoices(_.get(q, 'question.body.stem.options')),
    type: 'singlechoice',
  }
}

const fillQuesStemConvertToRay = (q: any) => {
  const typeName = getQuesType(q)
  if ('填空' === typeName) {
    let stem = _.get(q, 'question.body.stem.body', '')
    stem = _.replace(stem, /<u>(&nbsp;)*<\/u>/g, blank)
    q.question.body.stem.body = stem
  }
}

function fillQuestionExtractor(answerType: string, q: any) {
  fillQuesStemConvertToRay(q)
  const content = _.get(q, 'question.body.stem.body', '')
  const audioTag = `<audio class='${audioCss}' ${audioDefault} src='${q.audioFileUrl}'></audio>`

  // 如果答案为空，则手动批改
  const answer = _.get(q, 'question.body.answer.items')
  if (_.isEmpty(answer)) {
    answerType = 'manual-score'
  }

  const answerStr = JSON.stringify({
    answer: _.map(answer, (an: any) => [_.get(an, 'value')]),
    answerPoints: _.map(answer, () => 1),
    answerTypes: _.map(answer, () => answerType),
  })
  return {
    answer: answerStr,
    point2: _.size(answer),
    question: _.isEmpty(q.audioFileUrl) ? `<p>${content}</p>` : `<p>${audioTag}${content}</p>`,
    type: 'fill',
  }
}

function qaDataExtractor(q: any) {
  return {
    answer: '-1',
    answerDetails: (_.chain(q).get('question.body.analysis.detail', '') as any).values().join('\n').value(), // tslint:disable-line
    type: 'qa',
  }
}

function answerDetailsExtractor(q: any) {
  const answerDetails = (_.chain(q).get('question.body.analysis.detail', '') as any).values().join('\n').value() // tslint:disable-line
  return {
    answerDetails,
  }
}

function charCodeToInt(c: any) {
  return c.toUpperCase().charCodeAt(0) - baseCharCode
}

function parseMultiChoiceAnswers(q: any) {
  return (_.chain(q).get('question.body.answer.items', '') as any).values() // tslint:disable-line
  .map(charCodeToInt).join(',').value()
}

function multiChoiceQuestionExtractor(q: any) {
  // 选项数只能从答案中反推...
  const answer = parseMultiChoiceAnswers(q)

  return {
    answer,
    choices: buildDefaultChoices(_.get(q, 'question.body.stem.options')),
    type: 'multichoice',
  }
}

function parseConnectAnswer(q: any) {
  const answers = _.get(q, 'question.body.answer.items', '')
  if (_.isEmpty(answers)) {
    return ''
  }
  const answerStrs = []
  _.forIn(answers, (v, k) => {
    answerStrs.push(`${(parseInt(k, 10) - 1)}:${charCodeToInt(v)}`)
  })
  return _.join(answerStrs, ',')
}

function buildConnectOpts(options: any) {
  const sortKeys = _(options).keys().sortBy().value()
  return _.map(sortKeys, (key) => {
    const value = _.get(options, key, '')
    return {
      content: `<p>${_.isEmpty(value) ? '' : value}</p>`,
    }
  })
}

function connectQuestionExtractor(q: any) {
  const answer = parseConnectAnswer(q)

  return {
    answer,
    leftOpts: buildConnectOpts(_.get(q, 'question.body.stem.options.left', '')),
    rightOpts: buildConnectOpts(_.get(q, 'question.body.stem.options.right', '')),
    type: 'connect',
  }
}

const rightAnswerFlag = 'TW对√'
function parseYesornoAnswer(q: any) {
  const answer = _.get(q, 'question.body.answer.items', '')
  return _.includes(rightAnswerFlag, answer) ? '1' : '0'
}

function yesOrNoQuestionExtractor(q: any) {
  const answer = parseYesornoAnswer(q)
  return {
    answer,
    type: 'yesorno',
  }
}

function syntheticQuestionExtractor(q: any) {
  const childQuestions = _.map(_.get(q, 'question.body.questions', []), (s: any) => {
    const q: any = {}
    _.set(q, 'question.body', s)
    _.set(q, 'question.type', s.type)
    return {
      ...convertXuersiQuestionToRayQ(q),
    }
  })
  return {
    childQuestions,
    type: 'synthetic',
  }
}

const simpleQaExtractor = [qaDataExtractor]
/* tslint:disable: object-literal-sort-keys */
const convertConfigs = {
  单选: [answerDetailsExtractor, singleChoiceDataExtractor],
  多选: [answerDetailsExtractor, multiChoiceQuestionExtractor],
  // 多选多: [answerDetailsExtractor, multiChoiceQuestionExtractor],
  填空: [answerDetailsExtractor, _.partial(fillQuestionExtractor, 'manual-score')],
  判断: [answerDetailsExtractor, yesOrNoQuestionExtractor],
  配对: [answerDetailsExtractor, connectQuestionExtractor],
  // 排序
  解答: simpleQaExtractor,
  // 完形填空: [answerDetailsExtractor, yesOrNoQuestionExtractor],
  // 语音跟读
  // 口语题
  // 连词成句
  复合: [answerDetailsExtractor, syntheticQuestionExtractor],
}

function constructOptions(q) {
  const options = _.get(q, 'question.body.stem.options')
  if (_.isEmpty(options)) {
    return ''
  }
  return _.reduce(options, (result, value, key) => {
    const str = _.replace(value, '         ', '______')
    return result + '<p class="ot-content-oral-p">' + key + '. ' + str + '</p>'
  }, '')
}

const audioCss = 'edui-upload-audio  vjs-default-skin audio-js'
const audioDefault = `controls="" preload="none" width="auto" data-setup="{}"`
function commonDataExtractor(qId, q: any) {
  const content = _.get(q, 'question.body.stem.body')
  const fromThirdParty = 'talcloud2'
  const fromThirdPartyId = qId
  const difficulty = _.get(q, 'question.difficulty', 1)
  const audioTag = `<audio class='${audioCss}' ${audioDefault} src='${q.audioFileUrl}'></audio>`
  const options = constructOptions(q)
  const questionBody = _.isEmpty(q.audioFileUrl) ? `<p>${content}</p>${options}` : `<p>${audioTag}${content}</p>`
  return {
    $sourceType: 'talcloud2',
    fromThirdParty,
    fromThirdPartyId,
    point2: 1,
    difficulty,
    difficultName: difficultymap[difficulty],
    question: questionBody,
  }
}

export const convertXuersiQuestionToRayQ = (q: any, qId = '') => {
  const commQ = commonDataExtractor(qId, q)
  let conveters: any
  const typeName = getQuesType(q)
  if (_.has(convertConfigs, typeName)) {
    conveters = _.flatten([_.get(convertConfigs, typeName)])
  } else {
    conveters = simpleQaExtractor
    console.warn(`question transformation for ${typeName} not implemented`)
  }
  const qData: any = _.map(conveters, (c: any) => c(q))
  const allData = (<any> _.spread)(_.defaults, 1)({}, qData)

  const options = _.get(q, 'question.body.stem.options')
  if (!_.isEmpty(options)) {
    const ret = {
      ...commQ,
      ...allData,
    }
    ret.question = commQ.question
    return ret
  }

  return {
    ...commQ,
    ...allData,
  }
}
