require('assets/styles/handWriting.scss');
import * as drawPlayManager from 'app2/directives/drawPlay/drawPlayManager.js'
import * as angular from "angular";

angular.module('app.review')
  .controller('handWritingPreviewDioalog', handWritingPreviewDioalog);

handWritingPreviewDioalog.$inject = ['$scope', 'data', '$uibModalInstance', 'wirtingPad', '$q', 'notificationService', '$http', 'oedConfig']
function handWritingPreviewDioalog($scope, data, $uibModalInstance, wirtingPad, $q, notificationService, $http, oedConfig) {

  // 最多只有4张图，index不能超过3
  $scope.currentIndex = _.get(data, 'curIdx', '')
  $scope.pageIndex = $scope.currentIndex
  $scope.studentId = data.studentId
  $scope.isSyncing = true
  $scope.count = _.size(_.get(data, 'handWritingList', [])) - 1

  function loadWirtingPadData() {
    $scope.isSyncing = true
    wirtingPad.getWirtingPadInfo({
      sessionId: data.testSessionId,
      stuId: data.studentId
    }).$promise.then(function (info) {
      const uuid = _.get(info, 'md5', '')
      var req = {
        method: 'GET',
        url: oedConfig.url_b(`writingPad/resource/uuid/${uuid}/src?inline=true`),
      }
      $http(req).then(function (r) {
        // 分割原始数据
        $scope.rowDrawData = _.map(r.data.split('\n'), d => {
          const items = d.split(',')
          if (items.length == 4) {
            return { x: _.toNumber(items[0]), y: _.toNumber(items[1]), page: _.toNumber(items[2]), st: _.toNumber(items[3]) }
          } else {
            return {}
          }
        })
        // 去掉最后一行的空行
        const lastRowData = $scope.rowDrawData[$scope.rowDrawData.length - 1]
        if (_.isEmpty(lastRowData)) {
          $scope.rowDrawData.pop()
        }
        getCanvasHeight()
        $scope.isSyncing = false
        // initCanvas()
      });
    }).catch((e) => {
      console.log('load hand writing', e);
      notificationService.notify('error', '加载笔迹失败')
    });
  }

  function getCanvasHeight() {
    const content = document.getElementById(`draw_${data.studentId}`)
    $scope.drawHeight = (_.get(content, 'offsetHeight', 0)) - 50
  }

  function loadHeader(index) {
    $scope.header = _.get(data, `handWritingList.${index}.resourceName`, '')
  }

  function loadCurRes(index) {
    $scope.currentRes = _.get(data, `handWritingList.${index}`, {})
  }

  this.$onInit = () => {
    loadHeader($scope.currentIndex)
    loadCurRes($scope.currentIndex)
    $scope.loading = $q.all([loadWirtingPadData()])
  }

  $scope.close = function () {
    if ($scope.isPlaying) {
      drawPlayManager.stop($scope.holder)
    }
    $uibModalInstance.dismiss($scope.dirty)
  };

  $scope.prevImage = function () {
    if ($scope.currentIndex <= 0) return
    $scope.currentIndex--
    loadHeader($scope.currentIndex)
    loadCurRes($scope.currentIndex)
    $scope.pageIndex = $scope.currentIndex
    loadWirtingPadData()
  }

  $scope.nextImage = function () {
    if ($scope.currentIndex >= $scope.count) return
    $scope.currentIndex++
    loadHeader($scope.currentIndex)
    loadCurRes($scope.currentIndex)
    $scope.pageIndex = $scope.currentIndex
    loadWirtingPadData()
  }
}
