(function() {
  'use strict';

  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentTaskDetails.res.objectiveTest', {
        url: '/objectiveTest/:origTestId',
        template: require('assets/templates/student/studentTaskDetailsObjectiveTest.html'),
        controller: 'studentTaskDetailsObjectiveTestCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('studentTaskDetailsObjectiveTestCtrl', studentTaskDetailsObjectiveTestCtrl);

  studentTaskDetailsObjectiveTestCtrl.$inject = ['$rootScope', '$scope', '$state', '$stateParams',
    '$q', 'dialogs', 'userInfo', 'oedCoursePath', 'oedTaskList', 'oedTask', 'oedTaskListPublishRecord',
    'oedTaskTest', 'oedObjectiveTest', 'oedTestSession', 'oedTestSessionStudent', 'oedTestAnswer',
    'oedTestSessionStatForReview', 'oedTestUtils']
  function studentTaskDetailsObjectiveTestCtrl($rootScope, $scope, $state, $stateParams,
    $q, dialogs, userInfo, oedCoursePath, oedTaskList, oedTask, oedTaskListPublishRecord,
    oedTaskTest, oedObjectiveTest, oedTestSession, oedTestSessionStudent, oedTestAnswer,
    oedTestSessionStatForReview, oedTestUtils) {

    var recordId = _.parseInt($stateParams.recordId);
    var taskId = _.parseInt($stateParams.taskId);
    var origTestId = _.parseInt($stateParams.origTestId);

    $scope.taskId = taskId;

    var startTime = moment();

    $scope.testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
      recordId: recordId,
      taskId: taskId,
      origTestId: origTestId
    });

    function compare(int1, int2) {
      return int1 - int2;
    }

    function confirmSubmitWithoutAnswer(questionsWithoutAnswer) {
      if (_.isEmpty(questionsWithoutAnswer)) {
        return $q.when(true);
      } else {
        var quesIdxs = _(questionsWithoutAnswer).map(function(q) {
          return q.index + 1;
        }).value().sort(compare).join(',');
        var tmpl = _.template('题目 <%= quesIdxs %> 没有回答， 确定要提交答案吗?');
        var dlg = dialogs.confirm('确定提交', tmpl({
          quesIdxs: quesIdxs
        }));

        return dlg.result;
      }
    }

    function submitAnswer(uid, avgTime) {
      var testSessionId = $scope.testSession.id;
      var questionAnswers = _($scope.test.questions).filter(function(q) {
        return !_.isEmpty(q.answer);
      }).map(function(q) {
        return {
          testSessionId: testSessionId,
          questionId: q.id,
          answer: q.answer,
          timeSpent: avgTime,
          studentId: uid,
          notifyTeacher: false
        };
      }).value();

      var curIdx = 0;

      function doSubmitAnswer() {
        if (curIdx >= _.size(questionAnswers)) {
          return $q.when('done');
        }

        var answer = questionAnswers[curIdx];
        var submit = oedTestAnswer.submitAnswer(answer);
        curIdx++;
        return submit.$promise.then(function() {
          $rootScope.$broadcast('readObjectTest', {
            taskId: taskId,
            objId: origTestId
          });
        }).then(doSubmitAnswer);
      }

      return doSubmitAnswer();
    }

    function joinTestSessionIfNecessary(uid) {
      var tsStd = oedTestSessionStudent.queryByTestSessionAndStudentId({
        sessionId: $scope.testSession.id,
        studentId: uid
      });

      return tsStd.$promise.catch(function(err) {
        if (err.status == 404) {
          return oedTestSessionStudent.save({
            testSessionId: $scope.testSession.id,
            studentId: uid
          }).$promise;
        }
        return err;
      });
    }

    userInfo.then(function(info) {
      var uid = _.parseInt(info.uid);
      $scope.submitAnswer = function() {
        $scope.isTaskListCompleted = false;
        var dueDate = $scope.record.dueDate;
        if (dueDate && dueDate - Date.now() <= 0) {
          $scope.isTaskListCompleted = true;
          dialogs.error('作业已结束', '此作业已经结束， 无法提交试卷');
          return;
        }

        var questionsWithoutAnswer = _($scope.test.questions).map(function(q, idx) {
          return {
            hasAnswer: !_.isEmpty(q.answer),
            index: idx
          };
        }).filter(function(q) {
          return !q.hasAnswer;
        }).value();

        confirmSubmitWithoutAnswer(questionsWithoutAnswer).then(function() {
          var endTime = moment();
          var totalTime = endTime.diff(startTime);
          var avgTime = totalTime;
          if (_.size($scope.test.questions) > 0) {
            avgTime = totalTime / _.size($scope.test.questions);
          }
          //
          // 保留整数
          avgTime = _.parseInt(avgTime.toFixed(0));

          $scope.loading = joinTestSessionIfNecessary(uid).then(function() {
            return submitAnswer(uid, avgTime);
          }).then(function() {
            return oedTestSession.submitTest({
              studentId: uid,
              testSessionId: $scope.testSession.id
            }, {}).$promise;
          }).then(function() {
            $state.go('.', {}, {
              reload: true
            });
          });
        });
      };

      $scope.loading = $q.all([$scope.testSession.$promise, $scope.record.$promise]).then(function() {
        $scope.isTaskListCompleted = false;
        var dueDate = $scope.record.dueDate;
        if (dueDate && dueDate - Date.now() <= 0) {
          $scope.isTaskListCompleted = true;
        }

        return oedTestSessionStudent.queryByTestSessionAndStudentId({
          sessionId: $scope.testSession.id,
          studentId: uid
        }).$promise.catch(function() {
          return {
            submitTime: 0
          };
        });
      }).then(function(tsStd) {
        $scope.submited = tsStd.submitTime > 0;
        if ($scope.submited) {
          $scope.dataForReview = oedTestSessionStatForReview.queryByTestSessionAndStudentId({
            sessionId: $scope.testSession.id,
            userId: uid
          });
          return $scope.dataForReview.$promise.then(function(r) {
            $scope.test = r.test;
            $scope.currentState.subTitle = $scope.test.name + '(' + $scope.test.questions.length + ')';
          });
        } else {
          $scope.test = oedObjectiveTest.getTestWithoutAnswer({
            id: $scope.testSession.testId
          });
          $scope.test.$promise.then(function(test) {
            $scope.currentState.subTitle = $scope.test.name + '(' + $scope.test.questions.length + ')';
          });
          return $scope.test.$promise;
        }
      }).finally(function() {
        $scope.loaded = true;
      });
    });
    $scope.isAnswerCorrect = function(q) {
      if (_.isEmpty(q.answer) || _.isEmpty(q.answer.answer))
        return '错误';
      return q.answer.correct ? '正确' : '错误';
    };
    $scope.getChoiceName = oedTestUtils.getChoiceName;
    $scope.getAnswerText = oedTestUtils.getAnswerText;

    $scope.getCorrectRate = function(question) {
      var correct = question.quesStat.countAnsweredCorrectAndHesitate;
      var hesitate = question.quesStat.countAnsweredCorrectNotHesitate;
      var total = question.quesStat.countAnsweredTotal;
      if (total !== 0) {
        return (correct + hesitate) * 100 / total;
      }
      return 0;
    };

    $scope.loading = $scope.testSession.$promise;

  }
})();
