(function() {

    angular.module('app.review')
      .controller('testInteractDetailsViewerCtrl', testInteractDetailsViewerCtrl);
      testInteractDetailsViewerCtrl.$inject = ['$scope', '$uibModalInstance', '$timeout', 'notificationService', 'userInfo',
      'oedBoardContentComment', 'data', 'Lightbox', 'oedTestUtils',
      'oedConfig', 'oedUrlBuilder', 'dialogs','oedTestSessionStatForReview','oedTestAnswer','$uibModal']
    function testInteractDetailsViewerCtrl($scope, $uibModalInstance, $timeout, notificationService, userInfo,
                                       oedBoardContentComment, data, Lightbox, oedTestUtils,
                                       oedConfig, oedUrlBuilder, dialogs,oedTestSessionStatForReview,oedTestAnswer,$uibModal) {
      var curIdx = data.index || 0;
      $scope.curIndex = data.index || 0;
      $scope.dataObj = data;
      $scope.$tIndex = _.findIndex($scope.dataObj.titleList,item=>{
        return item == $scope.dataObj.titleIndex;
      });
      // $scope.contents = data.interacts[curIdx].contentList;
      $scope.titleList = data.titleList;
      $scope.selectDataArr = [{name:'该学生全对',type:1},{name:'该学生全错',type:2},{name:'该题所有学生全对',type:3},{name:'该题所有学生全错',type:4}];
      $scope.deleteComment = function(comment) {
        dialogs.confirm('删除', '确定要删除评论吗?').result.then(function() {
          $scope.loading = oedTestSessionStatForReview.deleteComment({
            id: _.get(comment, 'id')
          },{}).$promise.then(() => {
            _.remove($scope.dataObj.commentInfoData.commentsList, (c) => {
              return c.id === _.get(comment, 'id')
            })
          })
        });
      }
      function loadCommentData() {
        oedTestSessionStatForReview.getInteractByQuestionId({
          testSessionId: $scope.dataObj.sessionId,
          studentId: $scope.dataObj.curStu.uid,
          questionId: $scope.dataObj.questionId,
        }).$promise.then(res => {
          $scope.dataObj.commentInfoData = res;
          _.each($scope.dataObj.commentInfoData.commentsList,item =>{
            item.commentResource = JSON.parse(item.commentBody);
          })
        })
      }
      loadCommentData();
      $scope.changeSelect = (type) => {
        if( type == 1) {
          oedTestSessionStatForReview.batchUnReviewQuestin({testSessionId : data.sessionId,
            studentId : $scope.dataObj.curStu.uid, type : 1},{}).$promise.then(res => {
              if (res && res.length != 0){
                _.each(res, item => {
                  if(item.id == ($scope.dataObj.answer && $scope.dataObj.answer.id)) {
                    $scope.dataObj.answer.correctDetails = 1;
                  }
                  _.each($scope.dataObj.quesReviewDTOList, item1 => {
                    if(item.id == (item1.answer && item1.answer.id)){
                      item1.answer = item;
                      item1.answer.answerItems = JSON.parse(item.answer);
                    }
                  })
                })
                notificationService.notify('info','评判成功');
              } else {
                const  dlg = dialogs.confirm('整体批阅', '所有的题目已阅,是否要继续将题目批为全对?');
                dlg.result.then(r=> {
                  oedTestSessionStatForReview.batchTest({testSessionId : data.sessionId,
                    studentId : $scope.dataObj.curStu.uid, type : 1},{}).$promise.then(r=>{
                      _.each(r, item => {
                        _.each($scope.dataObj.quesReviewDTOList, item1 => {
                          if(item.id == (item1.answer && item1.answer.id)){
                            item1.answer = item;
                            item1.answer.answerItems = JSON.parse(item.answer);
                          }
                        })
                      });
                      $scope.dataObj.answer.correctDetails = 1;
                      notificationService.notify('info','评判成功');
                    }).catch(()=>{
                      notificationService.notify('error','评判失败');
                    })
                })
              }
            }).catch(()=>{
              notificationService.notify('error','评判失败');
            })
        } else if ( type == 2) {
          oedTestSessionStatForReview.batchUnReviewQuestin({testSessionId : data.sessionId,
            studentId : $scope.dataObj.curStu.uid, type : 0},{}).$promise.then(res => {
              if (res && res.length != 0){
                _.each(res, item => {
                  if(item.id == ($scope.dataObj.answer && $scope.dataObj.answer.id)) {
                    $scope.dataObj.answer.correctDetails = 0;
                  }
                  _.each($scope.dataObj.quesReviewDTOList, item1 => {
                    if(item.id == (item1.answer && item1.answer.id)){
                      item1.answer = item;
                      item1.answer.answerItems = JSON.parse(item.answer);
                    }
                  })
                });
                notificationService.notify('info','评判成功');
                $scope.dataObj.answer.correctDetails = 0;
              } else {
                const  dlg = dialogs.confirm('整体批阅', '所有的题目已阅,是否要继续将题目批为全错?');
                dlg.result.then(r=> {
                  oedTestSessionStatForReview.batchTest({testSessionId : data.sessionId,
                    studentId : $scope.dataObj.curStu.uid, type : 0},{}).$promise.then(r=>{
                      _.each(r, item => {
                        _.each($scope.dataObj.quesReviewDTOList, item1 => {
                          if(item.id == (item1.answer && item1.answer.id)){
                            item1.answer = item;
                            item1.answer.answerItems = JSON.parse(item.answer);
                          }
                        })
                      });
                      $scope.dataObj.answer.correctDetails = 0;
                      notificationService.notify('info','评判成功');
                    }).catch(()=>{
                      notificationService.notify('error','评判失败');
                    })
                })
              }
            }).catch(()=>{
              notificationService.notify('error','评判失败');
            })
        } else if ( type == 3) {
          oedTestSessionStatForReview.batchUnReviewQuestinForAll({testSessionId : $scope.dataObj.sessionId,
            questionId : $scope.dataObj.questionId, type : 1},{}).$promise.then(r => {
              if (r && r.length != 0) {
                const findCurStuData = _.filter(r,(item)=> {
                  return item.studentId == $scope.dataObj.curStu.uid;
                });
                if (findCurStuData.length > 0) {
                  findCurStuData[0].answerItems = JSON.parse(findCurStuData[0].answer);
                  $scope.dataObj.quesReviewDTOList[$scope.$tIndex].answer = findCurStuData[0];
                  $scope.dataObj.answer.correctDetails = 1;
                }
              } else {
                const  dlg = dialogs.confirm('整体批阅', '所有的题目已阅,是否要继续将题目批为全对?');
                dlg.result.then(r => {
                  oedTestSessionStatForReview.batchTestforAll({testSessionId : $scope.dataObj.sessionId,
                    questionId : $scope.dataObj.questionId, type : 1},{}).$promise.then(r=>{
                      const findCurStuData = _.filter(r,(item)=> {
                        return item.studentId == $scope.dataObj.curStu.uid;
                      });
                      if (findCurStuData.length > 0) {
                        findCurStuData[0].answerItems = JSON.parse(findCurStuData[0].answer);
                        $scope.dataObj.quesReviewDTOList[$scope.$tIndex].answer = findCurStuData[0];
                        $scope.dataObj.answer.correctDetails = 1;
                      }
                      notificationService.notify('info','评判成功');
                    }).catch(()=>{
                      notificationService.notify('error','评判失败');
                    })
                })
              }
            })
        } else {
          oedTestSessionStatForReview.batchUnReviewQuestinForAll({testSessionId : $scope.dataObj.sessionId,
            questionId : $scope.dataObj.questionId, type : 0},{}).$promise.then(r => {
              if (r && r.length != 0) {
                const findCurStuData = _.filter(r,(item)=> {
                  return item.studentId == $scope.dataObj.curStu.uid;
                });
                if (findCurStuData.length > 0) {
                  findCurStuData[0].answerItems = JSON.parse(findCurStuData[0].answer);
                  $scope.dataObj.quesReviewDTOList[$scope.$tIndex].answer = findCurStuData[0];
                  $scope.dataObj.answer.correctDetails = 0;
                }
              } else {
                const  dlg = dialogs.confirm('整体批阅', '所有的题目已阅,是否要继续将题目批为全错?');
                dlg.result.then(r => {
                  oedTestSessionStatForReview.batchTestforAll({testSessionId : $scope.dataObj.sessionId,
                    questionId : $scope.dataObj.questionId, type : 0},{}).$promise.then(r=>{
                      const findCurStuData = _.filter(r,(item)=> {
                        return item.studentId == $scope.dataObj.curStu.uid;
                      });
                      if (findCurStuData.length > 0) {
                        findCurStuData[0].answerItems = JSON.parse(findCurStuData[0].answer);
                        $scope.dataObj.quesReviewDTOList[$scope.$tIndex].answer = findCurStuData[0];
                        $scope.dataObj.answer.correctDetails = 0;
                      }
                      notificationService.notify('info','评判成功');
                    }).catch(()=>{
                      notificationService.notify('error','评判失败');
                    })
                })
              }
            })
        }
      }
      $scope.getQuestionStatus = (index) => {
        const data = $scope.dataObj.quesReviewDTOList[index];
        if(data.answer) {
          const answer = data.answer;
          if(answer.correctDetails == -1) {
            return {
              color:'white'
            }
          } else if (answer.correctDetails == 1) {
            return {
              color:'#7ed321'
            }
          } else if(answer.correctDetails == 2){
            return {
              color:'yellow'
            }
          } else {
            return {
              color: '#F37565'
            }
          }
        } else {
          return {
            color:'white'
          }
        }
      }
      $scope.checkAnswerStatus = (index) => {
        const data = $scope.dataObj.quesReviewDTOList[index];
        const items = data.answer ? data.answer.answerItems.items : '';
        if(!items) {
          return false;
        } else {
          const wrongAnswerData = _.some(items,item=> item.correct == 0 || item.correct == 2);
          if(!wrongAnswerData){
            return false;
          } else {
           const arr = _.sortBy(items, item=> -item.submitIndex);
           const maxSubmitIndex = arr[0].submitIndex;
           const needCheckData = _.filter(items,item => {
             return maxSubmitIndex!= 0 && item.submitIndex == maxSubmitIndex && (item.correct == -1 || item.correct === undefined)
           });
           if (needCheckData.length > 0) {
             return true;
           } else {
             return false;
           }
          }
        }
      }
      // $scope.rebuildResource = function() {
      //   $scope.allRes = [];
      //   $scope.curIndex = 0;
      //   _.forEach($scope.contents, (c) => {
      //      //TODO 不明白什么case
      //     if (!_.isEmpty(c.boardContentThumbnail) || !!c.id && c.resourceUUID) {
      //       $scope.allRes.push({
      //         resourceUUID: c.contentUuid,
      //         fileType: 'image',
      //         contentId: c.id
      //       });
      //     }
      //     if (!_.isEmpty(c.contentBody)) {
      //       let contentBody = oedTestUtils.parseJson(c.contentBody, {});
      //       if (_.size(contentBody.resources) != 0) {
      //         $scope.allRes = _.concat($scope.allRes, contentBody.resources);
      //       }
      //       if (!_.isEmpty(contentBody.content)) {
      //         $scope.allRes.push({
      //           fileType: 'text',
      //           text: contentBody.content,
      //           textTrimed: _.truncate(contentBody.content, {
      //             length: 30
      //           })
      //         });
      //       }
      //     }
      //   });
      // };
      // $scope.rebuildResource();
      $scope.resClick = function(res) {
        $scope.curIndex = _.indexOf($scope.dataObj.answerItems, res);
      };
      $scope.changeTitleIndex = ($index,res) => {
        $scope.$tIndex  = $index;
        $scope.dataObj.titleIndex = res;
        const data = $scope.dataObj.quesReviewDTOList[$index];
        $scope.dataObj.questionId = data.question.id;
        $scope.dataObj.answer = data.answer;
        $scope.dataObj.answerItems = data.answer?data.answer.answerItems.items:[];
        loadCommentData();
      }
      $scope.openMedia = function(res) {
        Lightbox.openModal([res], 0);
      };
      // $scope.interact = data.interacts[curIdx];
      // const boardSessionId = _.get($scope.contents, '0.boardSessionId');
      // const content = _.get($scope.contents, '0.id');
      // $scope.sortCommentList = function() {
      //   const commentsList = $scope.interact.commentsList;
      //   const teacherComments = _.filter(commentsList, isTeacherComment);
      //   _.remove(commentsList, isTeacherComment);
      //   $scope.interact.commentsList = _.concat(teacherComments, commentsList);
      // };
      function isTeacherComment(comment) {
        return comment.commenterInfo.roles === 'teacher' || _.has(_.keyBy(comment.commenterInfo.roles), 'teacher');
      }
      $scope.isTeacherComment = function(comment) {
        return isTeacherComment(comment);
      };
      // $scope.sortCommentList();
      // var countInteract = {
      //   bravosCount: function(interact) {
      //     return _.size(interact.bravosList);
      //   },
      //   commentsCount: function(interact) {
      //     return _(interact.commentsList).filter($scope.isVisibleComment).size();
      //   },
      //   viewsCount: function(interact) {
      //     return _.size(interact.viewsList);
      //   },
      //   flagsCount: function(interact) {
      //     return _.size(interact.flagsList);
      //   },
      //   time: function(interact) {
      //     return interact.contentList[0].submitTime;
      //   }
      // };
      // $scope.getCountByType = function(interact, type) {
      //   return countInteract[type](interact);
      // };
      $scope.getViewUsers = function() {
        return _.map($scope.dataObj.commentInfoData.viewsList, (v) => {
          return v.viewerInfo.name;
        }).join(',');
      };
      $scope.getBravoUsers = function() {
        return _.map($scope.dataObj.commentInfoData.bravosList, function(v) {
          return v.braverInfo.name;
        }).join(',');
      };
      $scope.close = function() {
        $uibModalInstance.close($scope.interact);
      };
      userInfo.then(function(info) {
        $scope.user = info;
      });
      $scope.batchScore = (num) => {
        if(!$scope.dataObj.answer) {
          return notificationService.notify('error','该学生此题未作答');
        }
        return oedTestAnswer.reviewQaAnswer({
          testSessionId: $scope.dataObj.sessionId,
          studentId: $scope.dataObj.curStu.uid,
          questionId: $scope.dataObj.questionId,
          correctDetails: num
        }, {
          testSessionId: $scope.dataObj.sessionId,
          studentId: $scope.dataObj.curStu.uid,
          questionId: $scope.dataObj.questionId,
          correctDetails: num
        }).$promise.then(function(answer) {
          answer.answerItems = JSON.parse(answer.answer);
          $scope.dataObj.answer = answer;
          $scope.dataObj.answerItems = answer.answerItems.items;
          _.each($scope.dataObj.quesReviewDTOList, item1 => {
            if(answer.questionId == (item1.answer && item1.answer.questionId)){
              item1.answer = answer;
            }
          })
        }).catch(function() {
          notificationService.notify('error', '评判失败')
        });
      }
      $scope.toggleBravo = function() {
          $scope.loading = oedTestSessionStatForReview.bravo({
            testSessionId: $scope.dataObj.sessionId,
            studentId: $scope.dataObj.curStu.uid,
            questionId: $scope.dataObj.questionId,
          }, {}).$promise.then((res) => {
            $scope.dataObj.commentInfoData.bravosList = res;
          });
      };
      $scope.replaceAnswerItemByComment = (answerJson) => {
        const arr = [];
        _.each(answerJson.items, item=> {
          const commentData = _.filter(answerJson.comment, r => r.relatedResourceId == item.id);
          if (commentData.length > 0) {
            arr.push(commentData[0]);
          } else {
            arr.push(item);
          }
        });
        answerJson.items = arr;
        return answerJson;
      }
      $scope.nextStudent = function() {
        const curStuIndex = _.findIndex($scope.dataObj.stuList,(item) => {
          return item.uid === $scope.dataObj.curStu.uid
        });
        if (curStuIndex + 1 === $scope.dataObj.stuList.length) {
          return notificationService.notify('info', '已经是最后一个学生');
        } else {
          const nextStudentId  = $scope.dataObj.stuList[curStuIndex + 1].uid;
          oedTestSessionStatForReview.queryByTestSessionAndStudentId({
            sessionId: $scope.dataObj.sessionId,
            userId: nextStudentId
          }).$promise.then(r => {
            _.each(r.quesReviewDTOList, item => {
              if (item.answer) {
                const handleData = $scope.replaceAnswerItemByComment(JSON.parse(item.answer.answer));
                item.answer.answerItems = handleData;
              }
            });
            $scope.dataObj.curStu = $scope.dataObj.stuList[curStuIndex + 1];
            $scope.dataObj.quesReviewDTOList = r.quesReviewDTOList;
            const answer = r.quesReviewDTOList[$scope.$tIndex].answer;
            $scope.dataObj.answerItems = answer?answer.answerItems.items:[];
            $scope.dataObj.answer = answer;
            loadCommentData();
          })
        }
      };
      $scope.prevStudent = function() {
        const curStuIndex = _.findIndex($scope.dataObj.stuList,(item) => {
          return item.uid === $scope.dataObj.curStu.uid
        });
        if (curStuIndex === 0) {
          return notificationService.notify('info', '已经是第一个学生');
        } else {
          const prevStudentId  = $scope.dataObj.stuList[curStuIndex - 1].uid;
          oedTestSessionStatForReview.queryByTestSessionAndStudentId({
            sessionId: $scope.dataObj.sessionId,
            userId: prevStudentId
          }).$promise.then(r => {
            _.each(r.quesReviewDTOList, item => {
              if (item.answer) {
                const handleData = $scope.replaceAnswerItemByComment(JSON.parse(item.answer.answer));
                item.answer.answerItems = handleData;
              }
            });
            $scope.dataObj.curStu = $scope.dataObj.stuList[curStuIndex - 1];
            $scope.dataObj.quesReviewDTOList = r.quesReviewDTOList;
            const answer = r.quesReviewDTOList[$scope.$tIndex].answer;
            $scope.dataObj.answerItems = answer?answer.answerItems.items:[];
            $scope.dataObj.answer = answer;
            loadCommentData();
          })
        }
      };
      function setInteractIndex(idx) {
        $scope.interact = data.interacts[idx];
        $scope.contents = data.interacts[idx].contentList;
        $scope.rebuildResource();
        $scope.sortCommentList();
      }
      $scope.isVisibleComment = function(cmt) {
        return !_.isEmpty(cmt.comment) || !_.isEmpty(cmt.commentBody.content) ||
          !_.isEmpty(cmt.commentBody.resources) || !!cmt.imgResId || !!cmt.imgResUuid;
      };
      $scope.comment = {
      };
      $scope.submitComment = function(cmt) {
        if (_.isEmpty($scope.comment.content) && _.isEmpty($scope.comment.resources)) {
          notificationService.notify('error', '内容为空, 请填写内容后再提交')
          return;
        }
        var commentBody = angular.toJson($scope.comment);
        $scope.addCommentLoading = oedTestSessionStatForReview.comment({
          testSessionId: $scope.dataObj.sessionId,
          studentId: $scope.dataObj.curStu.uid,
          questionId: $scope.dataObj.questionId,
          commentBody:commentBody
        }).$promise.then(res => {
          res.commentResource = JSON.parse(res.commentBody);
          $scope.comment = {};
          $scope.dataObj.commentInfoData.commentsList.push(res);
          $scope.showAddCommentPane = false;
        });
      };
      $scope.cancelEditor = function() {
        $scope.showAddCommentPane = false;
      };
      $scope.toggleAddCommentPanel = function() {
        $scope.showAddCommentPane = !$scope.showAddCommentPane;
      };
      $scope.flagsExist = function(flag) {
        return !_.isEmpty(flag);
      };
      // $scope.downloadCurrBoard = function() {
      //   let downloadUrl = oedConfig.url_b('boardsession/' + boardSessionId + '/download');
      //   let url = oedUrlBuilder(downloadUrl, {
      //     studentsIds: $scope.interact.owner.uid
      //   });
      //   window.open(url);
      // }
    }
  })();