import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-office-editor-dialog',
  template: require('./office-editor-dialog.component.html'),
  styles: [require('./office-editor-dialog.component.scss')]
})
export class OfficeEditorDialogComponent implements OnInit {
  @Output() public action = new EventEmitter<any>();
  private data: any
  private targetPath: SafeUrl
  constructor(public bsModalRef: BsModalRef, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.targetPath = this.sanitizer.bypassSecurityTrustResourceUrl('/ray/api/a/wopi/resItem/' + this.data.res.id);
  }
  
  public cancel = () => {
    this.action.emit(this.data)
    this.bsModalRef.hide()
  }
}
