import * as angular from 'angular';
require('./importIngDialog.scss');
angular.module('app.newPrepareCourse2.importIngDialog',
[]).component('importIngDialog', {
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: importIngDialogCtrl,
  template: <string> require('./importIngDialog.html'),
})

function importIngDialogCtrl() {
  this.cancel = () => {
    this.modalInstance.close(true);
  }
}
