import * as _ from 'lodash'
require('assets/app2/directives/question/questionDesigner2.directive')

angular.module('app.directives.question.quesDecorate', [
  'app.directives.question.questionDesigner2'
])
  .component('quesDecorate', {
    bindings: {
      isExercise: '=',
      isExerciseChild: '=',
      cloudRes: '=',
      homeWork: '=',
      questionModel: '=ngModel',
      testId: '=',
      qIndex: '=',
      inEditMode: '=',
      onEnterEditing: '&',
      onLeaveEditing: '&',
      onDeleteClicked: '&',
      canMoveUp: '=',
      canMoveDown: '=',
      onMoveUpClicked: '&',
      onMoveDownClicked: '&',
      onMovePlaceClicked: '&',
      onEditQuestionNumber: '&',
      isSub: '=',
      scoreChanged: '&onScoreChanged',
      parentIndex: '=',
      treeData: '=',
      sectionIdMap: '=',
      showSections: '=',
      rightWider: '=',
      isThreeDegrees: '=',
      editorConfig: '<',
      homeworkEdit: '=',
      forbidEdit: '=',
      currentTest: '=',
      isModalDialogEdit: '<',
      isFromNewPrepare: '<',
      typeText: '=',
      score: '=',
    },
    controller: quesDecorate,
    template: <string>require('./quesDecorate.html'),
  })

quesDecorate.$inject = []
function quesDecorate() { }
