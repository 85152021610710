import { Component, Input, OnInit, SimpleChanges, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { LessonDTO } from '../models/lesson'
import { LessonMessageService } from '../services/lesson-message.service';
import { LessonService } from '../services/lesson.service'
import { MaterialService } from '../material.service';
import { UnitItemDTO } from '../models/material';
import { Subscription } from 'rxjs';
import { TeachingPatternDataService } from '../teaching-pattern-data.service';

@Component({
  selector: 'app-lesson-design',
  template: require('./lesson-design.component.html'),
  styles: [require('./lesson-design.component.scss')],
  providers: [TeachingPatternDataService]
})
export class LessonDesignComponent implements OnInit, OnDestroy {

  @Input() public sectionId: number
  @Input() public coursepathId: number
  private loading = false
  private name: string
  private subs = new Subscription()
  private tableViewType = false

  private selectedCourse: LessonDTO
  constructor(
    private lessonService: LessonService,
    private materialService: MaterialService,
    private lessonMessageService: LessonMessageService,
    ) {
      const loadingSub = this.lessonMessageService.loadingAction$.subscribe((loading: boolean) => {
        this.loading = loading
      })
      this.subs.add(loadingSub)
    }
  public ngOnInit() {
    this.queryCourseInfo()
    this.getUnitItem()
  }

  public ngOnChanges(changes: SimpleChanges) {
  }

  public ngOnDestroy() {
    this.subs.unsubscribe()
  }

  public queryCourseInfo() {
  }

  public onCourseSelected(data: LessonDTO) {
    this.selectedCourse = {
      ...data,
    }
  }

  private getUnitItem() {
    this.materialService.getUnitItemById(this.sectionId).then((res: UnitItemDTO) => {
      this.name = res.name
    })
  }

  private switchViewType(tableViewType: boolean) {
    this.tableViewType = tableViewType
  }
}
