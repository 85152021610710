import {TrainDesignComponent} from 'app/online-train/train-design/train-design.component';
import {TrainDirsComponent} from 'app/online-train/train-design/train-dirs/train-dirs.component';
import {TrainContentComponent} from 'app/online-train/train-design/train-content/train-content.component';
import {TrainContentQaEditorComponent} from 'app/online-train/train-design/train-content-qa-editor/train-content-qa-editor.component';
import {TrainParadigmsComponent} from 'app/online-train/train-design/train-paradigms/train-paradigms.component';
import {TrainExcellentComponent} from 'app/online-train/train-design/train-excellent/train-excellent.component';
import {TrainGoodCourseContentComponent} from 'app/online-train/train-design/train-good-course-content/train-good-course-content.component';

function resolveParams($transition$: any) {
  const parentId = $transition$.params().parentId
  const editable = $transition$.params().editable
  const dirs = $transition$.params().dirs
  const prefix = $transition$.params().prefix
  const subNavs = $transition$.params().subNavs
  return {parentId, editable, dirs, prefix, subNavs}
}

function resolveContentParams($transition$: any) {
  const parentId = $transition$.params().parentId
  const editable = $transition$.params().editable
  const dirs = $transition$.params().dirs
  const prefix = $transition$.params().prefix
  const other = $transition$.params().other
  return {parentId, editable, dirs, prefix, other}
}

function resolveGoodCourseContentParams($transition$: any) {
  const parentId = $transition$.params().parentId
  const levels = $transition$.params().levels
  const subjects = $transition$.params().subjects
  const grades = $transition$.params().grades
  const editable = $transition$.params().editable
  const prefix = $transition$.params().prefix
  return {parentId, levels, subjects, grades, editable, prefix}
}

function resolveRootParams($transition$: any) {
  const dirId = $transition$.params().dirId
  return {dirId}
}

resolveRootParams.$inject = ['$transition$']
resolveParams.$inject = ['$transition$']
resolveContentParams.$inject = ['$transition$']
resolveGoodCourseContentParams.$inject = ['$transition$']

export const onlineTrainStates = [
  {
    name: 'onlineTrain',
    url: '/onlineTrain',
    component: TrainDesignComponent,
    resolve: {
      params: resolveRootParams
    }
  },
  {
    name: 'onlineTrain.concepts',
    url: '/concepts',
    component: TrainDirsComponent,
    params: {editable: false, dirs: null, prefix: 'concepts', parentId: 2, subNavs: []},
    resolve: {
      params: resolveParams
    }
  },
  {
    name: 'onlineTrain.features',
    url: '/features',
    component: TrainDirsComponent,
    params: {editable: false, dirs: null, prefix: 'features', parentId: 3, subNavs: []},
    resolve: {
      params: resolveParams
    }
  },
  {
    name: 'onlineTrain.scenarios',
    url: '/scenarios',
    component: TrainDirsComponent,
    params: {editable: false, dirs: null, prefix: 'scenarios', parentId: 4, subNavs: []},
    resolve: {
      params: resolveParams
    }
  },
  {
    name: 'onlineTrain.excellent',
    url: '/excellent',
    component: TrainExcellentComponent,
    params: {editable: false, dirs: null, prefix: 'excellent', parentId: 5, subNavs: []},
    resolve: {
      params: resolveParams
    }
  },
  {
    name: 'onlineTrain.paradigms',
    url: '/paradigms',
    component: TrainParadigmsComponent,
    params: {editable: false, dirs: null, prefix: 'paradigms', parentId: 6, subNavs: []},
    resolve: {
      params: resolveParams
    }
  },
  {
    name: 'onlineTrain.questions',
    url: '/questions',
    component: TrainDirsComponent,
    params: {editable: false, dirs: null, prefix: 'questions', parentId: 7, subNavs: []},
    resolve: {
      params: resolveParams
    }
  },
  {
    name: 'onlineTrain.goodCourseContent',
    url: '/excellent/:parentId/content',
    component: TrainGoodCourseContentComponent,
    params: {levels: [], subjects: [], grades: [], editable: false},
    resolve: {
      params: resolveGoodCourseContentParams
    }
  },
  {
    name: 'onlineTrain.qacontent',
    url: '/questions/:parentId/qacontent',
    component: TrainContentQaEditorComponent,
    params: {editable: false, dirs: null, other: null},
    resolve: {
      params: resolveContentParams
    }
  },
  {
    name: 'onlineTrain.content',
    url: '/:prefix/:parentId/content',
    component: TrainContentComponent,
    params: {editable: false, dirs: null},
    resolve: {
      params: resolveContentParams
    }
  },
]
