import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';
import { Observable } from 'rxjs';
declare let window: any;
declare let ActiveXObject: any;
import * as _ from 'lodash';

angular.module('app.services.communicate', [])
  .service('communicateService', () => {
    const _sendMessage = new Subject<any>();
    const _sendMessage1 = new Subject<any>();
    const _sendMessage2 = new Subject<any>();
    const _sendMessage3 = new Subject<any>();
    const _sendMessage4 = new Subject<any>();
    const _sendMessage5 = new Subject<any>();
    const _sendMessage6 = new Subject<any>();
    const _sendMessage7 = new Subject<any>();
    return {
      sendMessage,
      getMessage,
      fullScreen,
      sendDragMessage,
      getDragMessage,
      sendExpandMessage,
      getExpandMessage,
      sendSelectBtnTypeMessage,
      getSelectBtnTypeMessage,
      getTeachingPatternId,
      sendTeachingPatternId,
      sendSelectModuleMessage,
      getSelectModuleMessage,
      getDragMessageToRight,
      sendDragMessageToRight,
      sendModulesNum,
      getModulesNum,
    };
    /** * 向其他组件发送信息 * @param message 需要发送的信息 * @returns {Observavle<any>} */
    function sendMessage(message: any): Observable <any> {
      _sendMessage.next(message);
      return;
    }
    /** *订阅其他组件发送来的消息 * @returns {Observalue<any>} */
    function getMessage(): Observable <any> {
      return _sendMessage.asObservable();
    }
    function sendDragMessage(message: any): Observable <any> {
      _sendMessage1.next(message);
      return;
    }
    /** *订阅其他组件发送来的消息 * @returns {Observalue<any>} */
    function getDragMessage(): Observable <any> {
      return _sendMessage1.asObservable();
    }
    function sendExpandMessage(message: any): Observable <any> {
      _sendMessage2.next(message);
      return;
    }
    function getExpandMessage(): Observable <any> {
      return _sendMessage2.asObservable();
    }
    function sendDragMessageToRight(message: any): Observable <any> {
      _sendMessage3.next(message);
      return;
    }
    function getDragMessageToRight(): Observable <any> {
      return _sendMessage3.asObservable();
    }
    function sendSelectBtnTypeMessage(message: any): Observable <any> {
      _sendMessage4.next(message);
      return;
    }
    function getSelectBtnTypeMessage(): Observable <any> {
      return _sendMessage4.asObservable();
    }
    function sendTeachingPatternId(message: any): Observable <any> {
      _sendMessage5.next(message);
      return;
    }
    function getTeachingPatternId(): Observable <any> {
      return _sendMessage5.asObservable();
    }
    function sendSelectModuleMessage(message: any): Observable <any> {
      _sendMessage6.next(message);
      return;
    }
    function getSelectModuleMessage(): Observable <any> {
      return _sendMessage6.asObservable();
    }
    function sendModulesNum(message: any): Observable <any> {
      _sendMessage7.next(message);
      return;
    }
    function getModulesNum(): Observable <any> {
      return _sendMessage7.asObservable();
    }
    function fullScreen(id) {
      const el: any = document.getElementById(id);
      const rfs = el.requestFullScreen || el.webkitRequestFullScreen || el.mozRequestFullScreen || el.msRequestFullScreen;
      if (typeof rfs !== 'undefined' && rfs) {
        rfs.call(el);
      } else if (typeof window.ActiveXObject !== 'undefined') {
        // for IE，这里其实就是模拟了按下键盘的F11，使浏览器全屏
        const wscript = new ActiveXObject('WScript.Shell');
        if (wscript != null) {
          wscript.SendKeys('{F11}');
        }
      }
    }
  });
