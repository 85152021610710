import { Injectable } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { NotificationService } from 'app/shared/notification/notification.service';
// tslint:disable-next-line:max-line-length
import { EditTeachingModuleComponent } from 'app/lesson/lesson-design/edit-teaching-module/edit-teaching-module.component'; 
import * as Promise from 'bluebird';
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { TeachingModuleDTO, UpdateModuleItemDTO } from '../models/lesson';
import { LessonMessageService } from './lesson-message.service';
import { LessonService } from './lesson.service';
import { TeachingPatternService } from './teaching-pattern.service';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TeachingModuleService {

  private bsModalRef: BsModalRef
  private config = {
    animated: true,
    keyboard: true,
  };

  constructor(
    private teachingPatternService: TeachingPatternService,
    private lessonMessageService: LessonMessageService,
    private modalService: BsModalService,
    private oedDialogService: OedDialogService,
    private lessonService: LessonService,
    private notifyService: NotificationService
  ) { }

  public loadModuleDetails(moduleId: number) {
    return this.teachingPatternService.loadModuleData(moduleId)
    .then((teachingModule) => {
      const testIds = _.chain(teachingModule)
      .get('tests').flatten().map('objectiveTestId').value()
      const preResIds = _.chain(teachingModule)
      .get('presentResources').flatten().map('presentResourceId').value()
      return Promise.all([
          this.teachingPatternService.getPreResTestByIds(preResIds),
          this.teachingPatternService.getTestByIds(testIds),
      ]).then((value) => {
          const objAndSubjTests = _.partition(value[1], {type: 'objective'})
          const updateModuleDTO: UpdateModuleItemDTO = {
              id: moduleId,
              presentResources: value[0],
              objectiveTests: objAndSubjTests[0],
              subjectiveTests: objAndSubjTests[1],
          }
          return updateModuleDTO
      })
    })
  }

  public updateTeachingModule(updateModuleDTO: UpdateModuleItemDTO) {
    return this.teachingPatternService.updateModuleItemOrder(updateModuleDTO)
  }

  public editModuleInfo(disable: boolean, tmodule: TeachingModuleDTO) {
    if (disable) {
      this.lessonMessageService.sendSaveRecommendCourseAction()
      return of(tmodule)
    }
    const initialState = {
      module: tmodule,
    }
    this.bsModalRef = this.modalService.show(EditTeachingModuleComponent, {
      ...this.config,
      initialState,
    });
    return this.bsModalRef.content.onSave
  }

  public deleteModule(disable: boolean, tmodule: TeachingModuleDTO) {
    if (disable) {
      this.lessonMessageService.sendSaveRecommendCourseAction()
      return of(tmodule)
    }
    this.oedDialogService.openModalWithComponent('OedConfirmComponent', {
      title: '确定删除?',
      message: `确定要删除教学环节"${tmodule.name}"?`,
    }, () => {
      this.delete(tmodule)
    }, {
      class: 'modal-sm',
    })
  }

  private delete(tmodule: TeachingModuleDTO) {
    this.lessonService.deleteModule(tmodule.id).subscribe(() => {
      this.lessonMessageService.sendReloadPatternAction()
    }, (error) => {
      console.error(error)
      this.notifyService.notify('info', '删除失败')
    })
  }

}
