import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { TestSessionService } from 'app/review/test-session.service';
import { LoadingService } from 'app/shared/loading/loading.service';
import { finalize, flatMap, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TestSessionStudentDTOViewProgress } from 'app/models/obj-test-session';
import { NotificationService } from 'app/shared/notification/notification.service';

interface TestSessionStudentDTOViewProgressWithReviewStatus extends TestSessionStudentDTOViewProgress {
  pendingReview?: boolean;
  reviewed?: boolean;
}

interface ShowStudentDetailsData {
  std: TestSessionStudentDTOViewProgressWithReviewStatus;
  finishStuList?: TestSessionStudentDTOViewProgressWithReviewStatus[];
  isView?: boolean;
}

@Component({
  selector: 'app-obj-completion-details',
  template: require('./obj-completion-details.component.html'),
  styles: [require('./obj-completion-details.component.scss')]
})
export class ObjCompletionDetailsComponent implements OnInit {
  @Input() classId: number;
  @Input() fromClassSession: boolean;
  @Input() testSessionId: number;
  @Input() pubStudents: number[];

  @Output() showDetailsForStudent = new EventEmitter<ShowStudentDetailsData>();

  loadingName = 'obj-completion-details'
  studentsOfFinish: TestSessionStudentDTOViewProgressWithReviewStatus[] = [];
  studentsOfUnfinish: TestSessionStudentDTOViewProgressWithReviewStatus[] = [];
  studentsOfUnJoin: TestSessionStudentDTOViewProgressWithReviewStatus[] = [];

  constructor(private testSessionService: TestSessionService,
              private loadingService: LoadingService,
              private notifyService: NotificationService) { }

  ngOnInit() {
    const students$ = this.testSessionService.queryStdListFromClassSession(this.classId, this.testSessionId)
    this.loadingService.show(false, this.loadingName)

    const pubStdsById = _.keyBy(this.pubStudents)

    students$.pipe(
      flatMap((allStds) => {
        const newStds = _.isEmpty(this.pubStudents) ? allStds : _.map(allStds, (stds) => {
          return _.filter(stds, (std) => _.has(pubStdsById, std.studentId))
        })
        this.studentsOfFinish = newStds[0] || []
        this.studentsOfUnfinish = this.fromClassSession ? newStds[1] : _.concat(newStds[1], newStds[2]);
        this.studentsOfUnJoin = newStds[2];
        return this.loadFillQuesStatus(_.concat(newStds[0], newStds[1], newStds[2]))
      }), finalize(() => this.loadingService.hide(this.loadingName)))
      .subscribe(() => {}, (e) => {
        console.log('error', e)
        this.notifyService.error('加载数据失败, 请刷新页面或者联系技术支持')
      })
  }

  loadFillQuesStatus(stdList: TestSessionStudentDTOViewProgressWithReviewStatus[]) {
    return this.testSessionService.getFillQuestionStatus([this.testSessionId], _.map(stdList, 'uid'))
      .pipe(map((states) => {
        const statesMap = _.keyBy(states, 'studentId');
        _.forEach(_.union(this.studentsOfFinish, this.studentsOfUnfinish), (s) => {
          if (s.uid in statesMap) {
            s.pendingReview = _.get(statesMap[s.uid], 'pendingReview', false);
            s.reviewed = _.get(statesMap[s.uid], 'reviewed', false);
          }
        })
      }))
  }

  public onShowDetailsForStudent(std: TestSessionStudentDTOViewProgressWithReviewStatus, isFinished) {
    const data = isFinished ? {
      std,
      finishStuList: this.studentsOfFinish,
      isView: true,
    } : {
      std,
    }
    this.showDetailsForStudent.emit(data)
  }
}
