import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ExamPartDetailDTO, QuestionDetailDTO } from '../models/exam';
import numberToCw = require('number-to-chinese-words');
import _ = require('lodash');

@Component({
  selector: 'app-part-info',
  template: require('./part-info.component.html'),
  styles: [require('./part-info.component.scss')]
})
export class PartInfoComponent implements OnInit {

  @Input() partDetail: ExamPartDetailDTO
  @Input() index: number
  @Input() sortMap: object

  @Output() modelChanged = new EventEmitter<any>()

  partSort: string
  partName: string
  partScore: number
  partQuestions: QuestionDetailDTO[]

  constructor() { }

  ngOnInit() {
    this.partSort = numberToCw.toWords(this.index + 1)
  }

  ngOnChanges(changes) {
    if (_.has(changes, 'partDetail')) {
      this.partDetail = _.get(changes, 'partDetail.currentValue', {})
    }
    this.partName = _.get(this.partDetail, 'part.name', '')
    this.partQuestions = _.get(this.partDetail, 'questionDetails', [])
    this.calcScore();
  }

  handleQuesModelChaned() {
    this.modelChanged.emit()
  }

  calcScore() {
    return _.reduce(this.partQuestions, function(r, d) {
      const p = _.get(d, 'question.point2', 0)
      return r + p 
    }, 0) 
  }

}
