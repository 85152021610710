import * as angular from 'angular';

angular.module('app.changePassword.talChangePwd',
[]).component('talChangePwd', {
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: talChangePwdCtrl,
  template: <string> require('./changePasswordForTal.html'),
})

talChangePwdCtrl.$inject = ['$sce']
function talChangePwdCtrl($sce) {
  const ctrl = this
  this.ok = () => {
    this.modalInstance.close(true);
  }

  this.close = () => {
    this.modalInstance.close();
  }

  this.$onInit = () => {
    ctrl.webUrl = $sce.trustAsResourceUrl(this.resolve.data.webUrl);
  };
}
