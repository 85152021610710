(function() {
  'use strict';

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.taskLists.forStatus', {
        url: '/:status',
        template: require('assets/templates/homework/taskListsForStatus.html'),
        controller: 'taskListsForStatusCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('taskListsForStatusCtrl', taskListsForStatusCtrl);

  taskListsForStatusCtrl.$inject = ['$scope', '$q', '$uibModal', 'userInfo', 'oedTaskList', 'oedCourse',
    'oedCoursePath', 'dialogs', 'oedTaskListPublishRecord', '$stateParams']
  function taskListsForStatusCtrl($scope, $q, $uibModal, userInfo, oedTaskList, oedCourse,
    oedCoursePath, dialogs, oedTaskListPublishRecord, $stateParams) {
    var status = $stateParams.status;

    $scope.pageSize = 10;

    var filters = [$scope.filterAll].concat($scope.filters);

    var matchedFilter = _.find(filters, function(r) {
      return r.value == status;
    });

    $scope.$parent.selectedFilter = matchedFilter;

    // 入口
    userInfo.then(function(user) {
      $scope.teacherId = user.uid;
      $scope.loading = $scope.loadCoursePaths();
      $scope.selectFilter(matchedFilter);
    });

    $scope.selectFilterAll = function() {
      $scope.selectFilter($scope.filterAll);
      $scope.selectedFilter = $scope.filterAll;
    };

    $scope.hoverTaskList = function(item) {
      item.visibility = 'visible';
    };

    $scope.hoverOutOfTaskList = function(item) {
      item.visibility = 'hidden';
    };

    $scope.getRemainingDays = function(publishRecord) {
      if (!publishRecord || !publishRecord.dueDate) {
        return 0;
      }

      var dueDate = publishRecord.dueDate;
      return parseInt((dueDate - Date.now()) / (24 * 3600 * 1000)) + 1;
    };

    $scope.getStartingDays = function(publishRecord) {
      if (!publishRecord || !publishRecord.startDate) {
        return 0;
      }

      var startDate = publishRecord.startDate;
      return parseInt((startDate - Date.now()) / (24 * 3600 * 1000)) + 1;
    };

    $scope.deleteOrigTaskList = function(taskList, $event) {
      $event.stopPropagation();
      var dlg = dialogs.confirm('确定删除作业', '确定取消所有已发布班级的作业, 并且从作业列表中移除该作业?');
      dlg.result.then(function(btn) {
        $scope.loading = $q.all(_.map(taskList.publishInfo.publishRecords, function(pr) {
          return oedTaskListPublishRecord.delete({
            id: pr.id
          }).$promise;
        })).then(function() {
          oedTaskList.delete({
            id: taskList.id
          }).$promise.then(function() {
            $scope.loadCurrentVisibleOrigTaskLists();
          });
        });
      });
    };

    $scope.selectFilter = function(filter) {
      if (filter == $scope.selectedFilter) {
        return;
      }
      $scope.selectedFilter = filter;

      return $scope.loadCurrentVisibleOrigTaskListsCount().then(function() {
        $scope.curPage = 1;
        return $scope.loadCurrentVisibleOrigTaskLists();
      });

    };

    $scope.selectCoursePath = function(coursePath) {
      if ($scope.selectCoursePath == coursePath) {
        return;
      }
      $scope.selectedCoursePath = coursePath;

      return $scope.loadCurrentVisibleOrigTaskListsCount().then(function() {
        $scope.curPage = 1;
        return $scope.loadCurrentVisibleOrigTaskLists();
      });

    };

    $scope.onPageChange = function(curPage) {
      $scope.loading = $scope.loadCurrentVisibleOrigTaskLists();
    };

    $scope.loadCoursePaths = function() {
      var queryCoursePaths = oedTaskList.queryCoursePathsByTeacherId({
        teacherId: $scope.teacherId
      });

      return queryCoursePaths.$promise.then(function(coursePaths) {
        // 获取course path list
        $scope.coursePathList = _.orderBy(_.uniqBy(coursePaths, 'id'), 'createDate', 'asc');
        // 添加个特殊的"全部任务"的course path并选中
        var pseudoCoursePath = {
          id: 0,
          name: '全部作业'
        };
        $scope.coursePathList.splice(0, 0, pseudoCoursePath);
        // 选中"全部任务"
        return $scope.selectCoursePath(pseudoCoursePath);
      });
    };

    $scope.loadCurrentVisibleOrigTaskListsCount = function() {
      var queryOrigTaskListCount = oedTaskList.queryCountByTeacherId({
        teacherId: $scope.teacherId,
        coursePathId: $scope.selectedCoursePath.id,
        status: $scope.selectedFilter.value
      });

      return queryOrigTaskListCount.$promise.then(function(c) {
        $scope.totalVisibleTaskListCount = c.count;
      });
    };

    $scope.getCoursePathName = function(taskList) {
      var courseId = taskList.courseId;
      var course = $scope.cachedCoursePath[courseId];
      if (course && course[0])
        return course[0].name;
    };

    $scope.cachedCoursePath = {};

    $scope.loadCurrentVisibleOrigTaskLists = function() {
      var queryOrigTaskLists = oedTaskList.queryByTeacherId({
        teacherId: $scope.teacherId,
        coursePathId: $scope.selectedCoursePath.id,
        startIndex: $scope.pageSize * ($scope.curPage - 1),
        count: $scope.pageSize,
        status: $scope.selectedFilter.value
      });

      return queryOrigTaskLists.$promise.then(function(origTaskLists) {
        var loadPubInfo = _.map(origTaskLists, function(origTaskList) {
          return loadOrigTaskListPublishInfo(origTaskList);
        });

        var loadCoursePath = _.map(origTaskLists, function(origTaskList) {

          if (!_.has($scope.cachedCoursePath, origTaskList.courseId)) {
            $scope.cachedCoursePath[origTaskList.courseId] = oedTaskList.queryCoursePathsByCourseId({
              courseId: origTaskList.courseId
            });
            return $scope.cachedCoursePath[origTaskList.courseId].$promise;
          }

        });
        $scope.visibleOrigTaskLists = origTaskLists;

        return $q.all([loadPubInfo, loadCoursePath]);
      });
    };

    function loadOrigTaskListPublishInfo(origTaskList) {
      var loadPublishInfoQuery = oedTaskList.queryPublishInfo({
        id: origTaskList.id
      });

      return loadPublishInfoQuery.$promise.then(function(publishInfo) {
        origTaskList.publishInfo = publishInfo;
      });
    }

    $scope.isOrigTaskListPublished = function(origTaskList) {
      if (!origTaskList || !origTaskList.publishInfo || !origTaskList.publishInfo.publishRecords) {
        return false;
      }

      return _.size(origTaskList.publishInfo.publishRecords) > 0;
    };

    $scope.isOrigTaskListOverdue = function(origTaskList) {
      if (!origTaskList || !origTaskList.publishInfo || !origTaskList.publishInfo.publishRecords) {
        return false;
      }

      // 如果有一个publish record未设dueDate,则认为还在进行中
      if (_.some(origTaskList.publishInfo.publishRecords, function(r) {
          return !r.dueDate;
        })) {
        return false;
      }

      var lastDueDate = _.max(_.map(origTaskList.publishInfo.publishRecords, 'dueDate'));

      return lastDueDate && (lastDueDate != -Infinity) && (lastDueDate <= Date.now());
    };

    $scope.isOrigTaskListNotStarted = function(origTaskList) {
      if (!origTaskList || !origTaskList.publishInfo || !origTaskList.publishInfo.publishRecords) {
        return false;
      }

      // 如果有一个publish record未设startDate,则认为已经开始
      if (_.some(origTaskList.publishInfo.publishRecords, function(r) {
          return !r.startDate;
        })) {
        return false;
      }

      var minStartDate = _.min(_.map(origTaskList.publishInfo.publishRecords, 'startDate'));

      return minStartDate && (minStartDate != Infinity) && (minStartDate > Date.now());
    };

    $scope.getOrigTaskListLastPublishDate = function(origTaskList) {
      if (!origTaskList || !origTaskList.publishInfo || !origTaskList.publishInfo.publishRecords) {
        return false;
      }

      return origTaskList.publishInfo.taskList.createDate;
    };

    $scope.getOrigTaskListReviewProgress = function(origTaskList) {
      if (!origTaskList ||
        !origTaskList.publishInfo ||
        !origTaskList.publishInfo.totalStudentCount ||
        !origTaskList.publishInfo.totalReviewedStdCount) {
        return 0;
      }

      return (origTaskList.publishInfo.totalReviewedStdCount * 100 /
        origTaskList.publishInfo.totalStudentCount).toFixed(0);
    };

    $scope.getOrigTaskListSubmitProgress = function(origTaskList) {
      if (!origTaskList ||
        !origTaskList.publishInfo ||
        !origTaskList.publishInfo.totalStudentCount ||
        !origTaskList.publishInfo.totalSubmittedStdCount) {
        return 0;
      }

      return (origTaskList.publishInfo.totalSubmittedStdCount * 100 /
        origTaskList.publishInfo.totalStudentCount).toFixed(0);
    };

    $scope.publishOrigTaskList = function(origTaskList) {
      var existingClsIds = _.map(origTaskList.publishInfo.publishedClasses, function(cls) {
        return cls.id;
      });

      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/publishTaskList.html'),
        controller: 'publishTaskListCtrl',
        size: 'md',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          data: function() {
            return {
              taskListId: origTaskList.id,
              existingClassIds: existingClsIds
            };
          }
        }
      });
      modalInstance.result.then(function(r) {
        $scope.loading = $scope.loadCurrentVisibleOrigTaskListsCount();
      });
    };
  }
})();
