// import { TalSubjectDTO } from 'app2/integration/talcloud/models'
// import * as _ from 'lodash'
require('./anHuiPlatformLevelSubjectDialog.ctrl')

angular.module('app.prepareCourse2.cloud.core.anHuiPlatformLevelSubject', [
  'app.prepareCourse2.cloud.core.anHuiPlatformLevelSubjectDialog',
])
  .component('anHuiPlatformLevelSubject', {
    bindings: {
      onObjSelected: '&',
      phaseList: '<',
      phaseObject: '<',
      gradeObjList: '<',
      gradeObjOfphase: '<',
      subjectObjList: '<',
      subjectObjOfphase: '<'
    },
    controller: anHuiPlatformLevelSubjectCtrl,
    template:  <string> require('./anHuiPlatformLevelSubject.html'),
  })

anHuiPlatformLevelSubjectCtrl.$inject = ['$uibModal']
function anHuiPlatformLevelSubjectCtrl($uibModal: any) {
  this.doObjSelect = () => {
    const modalInstance = $uibModal.open({
      controller: 'anHuiPlatformLevelSubjectDialogCtrl',
      controllerAs: '$ctrl',
      resolve: {
        data: () => {
          return {
            phaseList: this.phaseList,
            phaseObject: this.phaseObject,
            gradeObjList: this.gradeObjList,
            gradeObjOfphase: this.gradeObjOfphase,
            subjectObjList: this.subjectObjList,
            subjectObjOfphase: this.subjectObjOfphase
          }
        }
      },
      size: 'md',
      template: require('./anHuiPlatformLevelSubjectDialog.html'),
      windowClass: 'cs-material-select-dalog',
    })

    modalInstance.result.then((objects: any) => {
      const {phase, grade, subject} = objects
      this.onObjSelected({
        phase, grade, subject
      })
    })
  }
}
