import * as angular from 'angular';
import Promise = require('bluebird')
import * as _ from 'lodash';
import * as moment from 'moment'

angular.module('app.prepareCourse2.editPresentRes', [])
.component('editPresentRes', {
  bindings: {
    courseId: '<',
    resId: '<',
    resource: '<',
    currentRes: '<',
    cancelDownload: '<',
    resourceList: '=',
    setCurrentRes: '&',
    updateRes: '&',
    importFromLibrary2: '&',
    closeClicked: '&',
    isFromNewPrepare: '<',
    sourceFrom: '<',
    memoryHandleId: '<',
    isEffective: '<',
    currentModuleId : '<',
  },
  controller: editPresentResCtrl,
  template: <string>require('./editPresentRes.html'),
});

editPresentResCtrl.$inject = [
  '$scope',
  '$log',
  '$uibModal',
  '$q',
  'notificationService',
  'dialogs',
  'Lightbox',
  'oedConfig',
  'oedPresentResource',
  'oedPresentResourceItem',
  'oedUnitItem',
  'oedCloudPresentResItem',
  'oedPresentResourceSection',
  'oedMisc',
  'oedCourse',
  'oedOem',
  'communicateService',
  'oedNewPrepareCourse',
  'localStorageService',
  'cloundApi',
  'oedCloudPresentRes',
  'oedDialogService'
]

function editPresentResCtrl(
  $scope,
  $log,
  $uibModal,
  $q,
  notificationService,
  dialogs,
  Lightbox,
  oedConfig,
  oedPresentResource,
  oedPresentResourceItem,
  oedUnitItem,
  oedCloudPresentResItem,
  oedPresentResourceSection,
  oedMisc,
  oedCourse,
  oedOem,
  communicateService,
  oedNewPrepareCourse,
  localStorageService,
  cloundApi,
  oedCloudPresentRes,
  oedDialogService
) {
  const ctrl = this
  ctrl.selectedReses = []
  ctrl.sectionTree = {treeData: []}
  ctrl.sectionIdMap = {}
  ctrl.selectedReses = []
  ctrl.enableManage = oedOem.curOem() !== 'futureSchool'

  $scope.$watch('$ctrl.resource', (newVal, oldVal) => {
    if (ctrl.sourceFrom) {
      ctrl.selectedReses = []
      ctrl.sectionTree = {treeData: []}
      ctrl.sectionIdMap = {}
    }
  })

  $scope.$watch('$ctrl.showInput', (newVal, oldVal) => {
    if (newVal) {
      setTimeout(() => {
        document.getElementById('currentName').focus();
      })
    }
  })
  ctrl.gotoEdit = () => {
    event.stopPropagation();
    event.preventDefault();
    if (ctrl.sourceFrom === 'right') {
      ctrl.leftIndex = localStorageService.get('memoryIndex');
      const modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/selectModule.html'),
        controller: 'selectedModuleCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter selectModule',
        resolve: {
          data: () => ({
            index: ctrl.leftIndex,
            courseId: ctrl.courseId
          })
        }
      });
      modalInstance.result.then((r: any) => {
        oedNewPrepareCourse.copyPackage({
          courseId: ctrl.courseId,
          moduleId: r,
          packageId: ctrl.resId,
        }, {}).$promise.then((res: any) => {
          ctrl.memoryHandleId = res.id;
          ctrl.canEdit = true;
          communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
        });
      })
    }
  }
  ctrl.selectResourceItem = (item: any, index: any) => {
    ctrl.memoryHandleId = item.id;
    if (!ctrl.resource.resDetail) {
      ctrl.resource.resDetail = _.cloneDeep(ctrl.resource);
      ctrl.resource.resoureType = 'presentPackage';
    }
    if (!item.resDetail) {
      item.resDetail = ctrl.resource.resDetail;
      item.resoureType = 'singleResource';
      item.resIndex = index;
    }
    item.resDetail.type = 'presentresource';
    if (ctrl.sourceFrom === 'right') {
      communicateService.sendDragMessageToRight({memoryHandleId: ctrl.memoryHandleId, data: item });
    } else {
      communicateService.sendDragMessage({ needfresh: false, memoryHandleId: ctrl.memoryHandleId, data: item });
    }
  }
  ctrl.useResource = () => {
    event.stopPropagation();
    event.preventDefault();
    oedNewPrepareCourse.copyPackage({
      courseId: ctrl.courseId,
      moduleId: ctrl.currentModuleId || -1,
      packageId: ctrl.resId,
    }, {}).$promise.then((res: any) => {
      ctrl.memoryHandleId = res.id;
      ctrl.canEdit = true;
      communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId,
        isFromUseResource: true, presentPackageId: ctrl.memoryHandleId});
    });
  };
  ctrl.$onChanges = (changesObj: any) => {
    if (ctrl.sourceFrom === 'right') {
      ctrl.canEdit = false;
    } else {
      ctrl.canEdit = true;
    }
    if (_.has(changesObj, 'courseId')) {
      if (ctrl.courseId) {
        ctrl.getCourseSection()
      }
    }
    if (_.has(changesObj, 'resId')) {
      if (ctrl.resId) {
        ctrl.getResourceList()
      }
    }
    if (_.has(changesObj, 'currentRes')) {
      if (ctrl.currentRes) {
        oedPresentResource.queryByCourseIncEmpty({
          course_id: ctrl.courseId
        }).$promise.then((res: any) => {
          ctrl.resources = res;
          oedCloudPresentRes.queryByResIds({
            resIds: _.map(ctrl.resources, 'id')
          }).$promise.then((allSharedReses: any) => {
            if (!ctrl.currentRes) {
              return;
            }
            ctrl.sharedResources = _.keyBy(allSharedReses, 'resourceId');
            if (ctrl.sharedResources[ctrl.currentRes.id]) {
              ctrl.sharedResourceId = ctrl.sharedResources[ctrl.currentRes.id].id;
            } else {
              ctrl.sharedResourceId = null;
            }
          });
        });
        ctrl.rename = ctrl.currentRes.name
        ctrl.isExitName = false
      }
    }
  }

  ctrl.saveTitle = () => {
    ctrl.showInput = false;
    ctrl.isExitName = false
    if (!ctrl.rename) {
      ctrl.nullName = true
      return;
    } else {
      ctrl.nullName = false
    }
    ctrl.existingNames = _(ctrl.resourceList).filter((r) => {
      return r.id !== ctrl.currentRes.id;
    }).map('name').value();

    if (ctrl.existingNames.indexOf(ctrl.rename) > -1) {
      ctrl.isExitName = true
      ctrl.rename = ctrl.currentRes.name
    }

    if(!ctrl.rename) {
      return notificationService.notify('info', '请填写展示资源名称')
    }

    if (!ctrl.isExitName && _.size(ctrl.rename) > 0) {
      ctrl.loading = oedPresentResource.save({id: ctrl.currentRes.id, name: ctrl.rename}).$promise.then(() => {
        ctrl.currentRes.name = ctrl.rename
        notificationService.notify('info', '保存成功')
      })
    }
  }

  ctrl.activeName = function (e) {
    if (_.size(ctrl.rename) > 40) {
      ctrl.rename = ctrl.rename.substr(0, 40);
    }
  }

  ctrl.deleteRes = function($event, res) {
    const dlg = dialogs.confirm('确定删除?', '确定要删除展示资源: "' + res.name + '"吗');
    dlg.result.then(function() {
      ctrl.loading = $q(function(resolve, reject) {
        res.$delete(function() {
          if (res === ctrl.currentRes) {
            ctrl.resourceList = _.filter(ctrl.resourceList, (item) => {
              return item.id !== res.id
            })
            if (_.size(ctrl.resourceList) > 0) {
              ctrl.setCurrentRes({t: ctrl.resourceList[0], res: ctrl.resourceList})
              _.remove(ctrl.resourceList, res);
            } else {
              const res = new oedPresentResource({
                name: '展示资源1',
                courseId: ctrl.courseId,
                isEmpty: false,
              });
              res.$save().then((t) => {
                ctrl.resourceList.push(t)
                ctrl.setCurrentRes({t: t, res: ctrl.resourceList})
              })
            }
          }
          resolve();
        }, function() {
          reject();
        })
      })
    })
  }

  ctrl.getResourceList = () => {
    return oedPresentResource.get({id: ctrl.resId}).$promise.then((res) => {
      const loadCloudItems = _.map(res.items, (item) => {
        return oedCloudPresentResItem.getByResItemId({
          id: item.id
        }).$promise.then((ci) => {
          item.cloudRes = ci;
          return item;
        })
      })
      ctrl.resource = res
      return $q.all(loadCloudItems)
    }).then(() => {
      ctrl.getResections()
    })
  }

  ctrl.onShowSections = (open) => {
    if (open) {
      return;
    }

    let selectedNodes = _.map(ctrl.treeInstance.jstree(true).get_selected(), function(s) {
      return _.parseInt(s);
    });

    const curIds = _.map(ctrl.resSections, function(sec) {
      return sec.sectionId;
    });

    if (_.isEmpty(selectedNodes)) {
      selectedNodes = _.map(ctrl.course.relatedSections, 'sectionId');
      updateTreeSelection();
    }

    if (_(curIds).xor(selectedNodes).isEmpty()) {
      return;
    }

    //
    // to save new ids
    ctrl.resSections = oedPresentResourceSection.updatePresentResourceSections({
      resId: ctrl.resId
    }, selectedNodes);

    ctrl.loading = ctrl.resSections.$promise.then(function(secs) {});
  };

  ctrl.getCourseSection = () => {
    return oedCourse.get({id: ctrl.courseId}).$promise.then((res) => {
      ctrl.course = res
      return oedUnitItem.queryAvailableBySchoolAndCourse({schoolId: 0,courseId: ctrl.courseId}).$promise.then((res) => {
        ctrl.courseSections = res
        function visitSection(sec) {
          if (sec.id) ctrl.sectionIdMap[sec.id] = sec;
          if (sec.child) {
            sec.child = _.sortBy(sec.child, ['sort']);
            _.each(sec.child, visitSection);
          }
        }
        _.each(ctrl.courseSections, visitSection);

        ctrl.sectionTree.treeData = oedMisc.utils.convertSectionsToJsTreeModel(ctrl.sectionIdMap, ctrl.courseSections);
        ctrl.treeConfig.version++;
        _.each(ctrl.sectionTree.treeData, function(data) {
          data.state.opened = true;
        });
      })
    })
  }

  ctrl.getResections = () => {
    return oedPresentResourceSection.queryByPresentResId({resId: ctrl.resId}).$promise.then((res) => {
      ctrl.resSections = res
      updateTreeSelection()
    })
  }

  const treeLoading = $q.defer();

  ctrl.loadResources = () => {
    ctrl.resource = oedPresentResource.get({
      id: ctrl.resId
    });
    const loadDetails = ctrl.resource.$promise.then(function(res) {
      const loadCloudItems = _.map(res.items, function(item) {
        return oedCloudPresentResItem.getByResItemId({
          id: item.id
        }).$promise.then(function(ci) {
          item.cloudRes = ci;
          return item;
        });
      });
      return loadCloudItems;
    });
  }

  let lastDragStop = null;
  ctrl.sortableOptions = {
    stop: function(event) {
      lastDragStop = moment();
      const items = _.map(ctrl.resource.items, function(item) {
        return {
          id: item.id
        };
      });
      const res = new oedPresentResource({
        id: ctrl.resource.id,
        items: items
      });
      ctrl.loading = res.$updateItemOrder();
      if(ctrl.sourceFrom) {
        communicateService.sendDragMessage({needfresh: true});
      }
    }
  };
  ctrl.toggleUpload = function() {
    if (!ctrl.currentRes) return;
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/resourceUploader.html'),
      controller: 'uploadResourceCtrl',
      size: 'md',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        resourceId: function() {
          return ctrl.currentRes.id;
        },
        url: function() {
          return oedConfig.url('presentresource/' + ctrl.currentRes.id + '/uploadresource');
        },
        isPrepareLesson: false,
        moduleId: null,
        packageId: null
      }
    });
    modalInstance.result.then(function(r) {
      if (r.uploaded && r.uploaded > 0) {
        ctrl.getResourceList()
        if(ctrl.sourceFrom) {
          communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
        }
      }
    });
  }

  ctrl.toggleSelect = (item) => {
    const idx = _.indexOf(ctrl.selectedReses, item);
    if (idx >= 0) {
      ctrl.selectedReses.splice(idx, 1);
    } else {
      ctrl.selectedReses.push(item);
    }
  };
  ctrl.isSelected = function(item) {
    return _.indexOf(ctrl.selectedReses, item) >= 0;
  };
  ctrl.deleteSelected = function() {
    if (ctrl.sourceFrom === 'right') {
      return;
    }
    let message = '确定要删除展示资源吗（共' + _.size(ctrl.selectedReses) + '项）？：';
    const deleteNum = _.size(ctrl.selectedReses);
    _.each(ctrl.selectedReses, function(res) {
      message = message + '<br>' + res.resourceName;
    });
    const dlg = dialogs.confirm('确定删除?', message);
    dlg.result.then(function() {
      ctrl.loading = $q(function(resolve, reject) {
        const items = ctrl.selectedReses;
        const promises = _.map(items, function(item) {
          return oedPresentResourceItem.delete({
            id: item.id
          }).$promise;
        });
        $q.all(promises).finally(function() {
          ctrl.selectedReses = [];
          ctrl.getResourceList();
          if (ctrl.sourceFrom) {
            // if (ctrl.currentRes.items.length === deleteNum) {
            //   oedPresentResource.delete({id: ctrl.currentRes.id}).$promise.then((data: any) => {
            //     communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
            //     communicateService.sendMessage({sourceFrom: 'left', data: null});
            //   })
            // } else {
              communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
            // }
          }
        });
      });
    });
  };
  ctrl.getItemName = function(item) {
    if (_.get(item, 'resourceName'))
      return _.get(item, 'resourceName');
    if (_.get(item, 'flResource.name'))
      return _.get(item, 'flResource.name');
    return '';
  };
  ctrl.showPreview = function(reses, idx, event) {
    event.stopPropagation();
    event.preventDefault();
    const now = moment();
    if (!!lastDragStop && now.diff(lastDragStop) < 100) {
      return;
    }
    Lightbox.openModal(reses, idx);
  };
  ctrl.addUrlItem = function() {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addWebUrlItem.html'),
      controller: 'addWebUrlItemCtrl',
      size: 'md',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        data: function() {
          return {
            resourceId: ctrl.resId
          };
        }
      }
    });
    modalInstance.result.then(function(r) {
      if (r) {
        ctrl.getResourceList();
        if(ctrl.sourceFrom) {
          communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
        }
      }
    });
  };
  ctrl.openCloudDrive = () => {
    oedDialogService.openModalWithComponent('CloudDriveDialogComponent',
      {
        isPrepareLesson: true,
      }, (params)=> {
        if (params.type === 'myFile') {
          cloundApi.importCloudDriveItem({
            courseId: ctrl.courseId,
            moduleId: ctrl.currentModuleId,
            presentResourceId: ctrl.resId}, params.res).$promise.then((r: any) => {
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
          })
        } else {
          cloundApi.importMicroLessonItem({
            courseId: ctrl.courseId,
            moduleId: ctrl.currentModuleId,
            presentResourceId: ctrl.resId}, params.res).$promise.then((r: any) => {
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
          })
        }
      },
      {class: 'cl-library-dialog2 modal-cloud-drive'})
  }
  ctrl.treeConfig = {
    core: {
      multiple: true,
      animation: true,
      error: function(error) {
        $log.error('treeCtrl: error from js tree - ' + angular.toJson(error));
      },
      check_callback: true,
      worker: true
    },
    types: {
      default: {
        icon: 'fa fa-book'
      },
      root: {
        icon: 'glyphicon glyphicon-cloud'
      }
    },
    version: 1,
    plugins: ['types', 'checkbox']
  };
  const fileTypes = {
    audio: {
      iconClass: 'oed-file-type-new fa fa-file-audio-o',
      tooltip: '音频'
    },
    file: {
      iconClass: 'oed-file-type-new fa fa-file-o',
      tooltip: '文件'
    },
    image: {
      iconClass: 'oed-file-type-new fa fa-file-photo-o',
      tooltip: '图片'
    },
    link: {
      iconClass: 'oed-file-type-new fa fa-link',
      tooltip: '链接'
    },
    office: {
      iconClass: 'oed-file-type-new fa fa-file-word-o',
      tooltip: '文档'
    },
    video: {
      iconClass: 'oed-file-type-new fa fa-file-video-o',
      tooltip: '视频'
    },
    unknown: {
      iconClass: 'oed-file-type-new fa fa-file-o',
      tooltip: '其他文档'
    }
  };
  ctrl.getFileTypeIconClass = function(type, url) {
    if (!fileTypes[type]) return null;
    if (url) type = 'link';
    return fileTypes[type].iconClass;
  };
  ctrl.getSelectedSectionNames = function() {
    const defaultText = '选择知识点';
    if (!ctrl.treeInstance) {
      return defaultText;
    }
    return '';
  };
  ctrl.onTreeReady = function() {
    treeLoading.resolve();
  };
  function updateTreeSelection() {
    const sections = _.map(ctrl.resSections, function(relSec) {
      return relSec.sectionId;
    });
    ctrl.treeInstance.jstree(true).select_node(sections, true, true);
  }

  ctrl.showShowCloudResNo = () => {
    return !ctrl.isEmptyResource(ctrl.resource) && _.isNumber(ctrl.sharedResourceId);
  };
  ctrl.isEmptyResource = (res) => {
    if (!res) {
      return true;
    }
    return _.get(res, 'isEmpty', false) || _.isEmpty(_.get(res, 'items'));
  };
  ctrl.addMicroLessonRecord = function() {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addMicroLessonRecordDialog.html'),
      controller: 'addMicroLessonRecordCtrl',
      size: 'lg',
      windowClass: 'new-lib-style',
      resolve: {
        data: function() {
          return {
            presentresourceId: ctrl.currentRes.id
          };
        }
      }
    });
    modalInstance.result.then(function(r) {
      if (r) {
        ctrl.getResourceList()
        if(ctrl.sourceFrom) {
          communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
        }
      }
    });
  };
}
