/* vendor css */
require('vendor/angular-notify/dist/angular-notify.css');
require('vendor/bootstrap/dist/css/bootstrap.min.css');
require('vendor/fontawesome/css/font-awesome.css');
require('vendor/angular-busy/dist/angular-busy.css');
require('vendor/angular-dialog-service/dist/dialogs.min.css');
require('vendor/ng-tags-input/ng-tags-input.min.css');
require('vendor/jstree/dist/themes/default/style.css');
require('vendor/nvd3/build/nv.d3.css');
require('vendor/angular-loading-bar/build/loading-bar.css');

require('ui-select/dist/select.css');
require('ngx-toastr/toastr.css');
require('vendor/jquery-ui/themes/ui-lightness/jquery-ui.min.css');
require('vendor/angular-bootstrap-lightbox/dist/angular-bootstrap-lightbox.css');
require('../../node_modules/angular-ui-tree/dist/angular-ui-tree.min.css')
require('angularjs-slider/dist/rzslider.css');
require('vendor/ng-img-crop/compile/minified/ng-img-crop.css');
require('vendor/bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css');
require('vendor/ng-tags-input/ng-tags-input.bootstrap.css');

require('animate.css');

require('vendor/swiper/dist/css/swiper.min.css');
require('vendor/angular-xeditable/dist/css/xeditable.css');

require('@ng-select/ng-select/themes/default.theme.css')

