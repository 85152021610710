var bluebird = require('bluebird');
require('app2/utils/taskUtils');
require('app2/utils/homeworkGroupUtils');

angular.module('app.directives.homework.homeworkSubjEditor', [])
  .directive('homeworkSubjEditor', homeworkSubjEditor);

  homeworkSubjEditor.$inject = ['$document', '$timeout']
function homeworkSubjEditor($document, $timeout) {
  return {
    restrict: 'E',
    scope: {
      editorConfig: '=',
      groups: '=',
      group: '=',
      onSaveGroup: '&',
      createTasksFromLibrary: '&'
    },
    template: require('assets/app2/directives/homework/homeworkSubjEditor.html')
  };
}
