(function() {
  'use strict';

  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentTaskDetails.comments', {
        url: '/comments',
        template: require('assets/templates/student/studentTaskComments.html'),
        controller: 'studentTaskCommentsCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('studentTaskCommentsCtrl', studentTaskCommentsCtrl);

  studentTaskCommentsCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$timeout',
    'Lightbox', 'notificationService', 'oedConfig', 'userInfo', 'oedTaskListPublishRecordComment', 'oedUserInfo', 'oedUserInfoUtils']
  function studentTaskCommentsCtrl($scope, $state, $stateParams, $q, $timeout,
    Lightbox, notificationService, oedConfig, userInfo, oedTaskListPublishRecordComment, oedUserInfo, oedUserInfoUtils) {

    var recordId = _.parseInt($stateParams.recordId);

    $scope.basePath = oedConfig.base();
    $scope.userInfoMap = {};

    $scope.$on('$destroy', function() {
      $timeout.cancel($scope.updateTimer);
      $scope.isDone = true;
    });

    var updateInterval = 10 * 1000;
    var lastUpdate = null;

    $scope.userInfoMap = {};

    function readComment(comment) {
      var c = _.omit(comment, 'commentBody');
      var body = angular.fromJson(comment.commentBody);
      c.body = body;
      return c;
    }

    userInfo.then(function(info) {
      $scope.userInfoMap[info.uid] = info;
      $scope.uid = info.uid;

      function updateComments() {
        var comments = oedTaskListPublishRecordComment.queryByRecordAndStudentId({
          recordId: recordId,
          studentId: info.uid,
          lastUpdate: lastUpdate
        });

        return comments.$promise.then(function(cmts) {
          var newComments = _.map(cmts, readComment);
          $scope.comments = $scope.comments || [];
          $scope.comments = $scope.comments.concat(newComments);

          var userIdMap = {};

          //获取学生和老师的用户信息
          function getUserInfo(uid) {
            if (_.has($scope.userInfoMap[uid])) {
              return;
            }

            $scope.userInfoMap[uid] = oedUserInfo.getByUid({
              id: uid
            });
          }

          _.each(cmts, function(cmt) {
            getUserInfo(cmt.uid);
          });

          if (_.size(cmts) > 0) {
            lastUpdate = _.last(cmts).commentDate;
          }

          return cmts;
        });
      }

      function scheduleUpdateComments() {
        if ($scope.isDone) {
          console.debug('scope is done, will not update');
          return;
        }

        var t = $timeout(function() {
          var update = updateComments();
          update.finally(scheduleUpdateComments);
        }, updateInterval);

        $scope.updateTimer = t;
      }

      var studentId = _.parseInt(info.uid);
      $scope.loading = updateComments().then(function(cmts) {
        if (_.size(cmts) > 0) {
          lastUpdate = _.last(cmts).commentDate;
        } else {
          lastUpdate = 0;
        }
        $scope.comments = _.map(cmts, readComment);
        scheduleUpdateComments();
      });

      $scope.addComment = function() {
        if (_.isEmpty($scope.currentComment.content) && _.isEmpty($scope.currentComment.content.resources)) {
          return notificationService.notify('error', '内容为空, 请填写内容后再提交')
        }
        //
        // 取消更新
        $timeout.cancel($scope.updateTimer);

        var now = moment();
        var commentBody = angular.toJson($scope.currentComment.content);
        var commentToAdd = new oedTaskListPublishRecordComment({
          publishRecordId: recordId,
          uid: studentId,
          studentId: studentId,
          commentDate: now.valueOf(),
          commentBody: commentBody
        });

        $scope.loading = commentToAdd.$save().then(function(c) {
          $scope.currentComment.content = {};
          return updateComments();
        }).finally(function() {
          //
          // 提交完毕后重新进行更新
          scheduleUpdateComments();
        });
      };
    });

    $scope.currentComment = {
      content: {}
    };

    $scope.previewResource = function(reses, idx) {
      Lightbox.openModal(reses, idx);
    };

    $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl;
  }
})();
