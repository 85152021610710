
// const uiRouter = require('@uirouter/angularjs').default
const transitionHook = require('@uirouter/angularjs/release/stateEvents')
const _ = require('lodash')

angular.module('oed.configure', ['bootstrapLightbox', 'ui.router.state.events'])
  .constant('oedMisc', {
    utils: {
      convertSectionsToJsTreeModel: function(sectionIdMap, sections) {
        var allNodes = []
        function addAllNodes(sec) {
          var id = sec.id || 0;
          var parentId = (_.has(sec, 'parentId') && sec.parentId != null) ? sec.parentId : '0';
          if (!_.has(sectionIdMap, parentId)) {
            parentId = '#';
          }

          var name = sec.name || sec.gradeName;

          allNodes.push({
            id: id,
            parent: parentId,
            text: name,
            state: {
              opened: false
            }
          });
          _.each(sec.child, addAllNodes);
        }

        if (_.size(sections) == 1) { //只有一个年级的情况, 忽略掉年级这个node{
          _.each(sections[0].child, addAllNodes);
        } else {
          _.each(sections, addAllNodes);
        }

        return allNodes;
      }
    }
  })
  .config(['$urlRouterProvider', '$locationProvider', '$httpProvider', '$uibModalProvider',
           '$translateProvider', '$compileProvider', 'dialogsProvider',
           'localStorageServiceProvider', 'LightboxProvider',
           'uibDatepickerPopupConfig', 'oedMisc', '$sceDelegateProvider', '$urlServiceProvider',
          function($urlRouterProvider, $locationProvider, $httpProvider, $uibModalProvider,
    $translateProvider, $compileProvider, dialogsProvider,
    localStorageServiceProvider, LightboxProvider,
    uibDatepickerPopupConfig, oedMisc, $sceDelegateProvider, $urlServiceProvider) {
    $locationProvider.hashPrefix('');
    $urlServiceProvider.deferIntercept()

    localStorageServiceProvider.setPrefix('com.rayclass');
    //
    // 定义编译表达式, 把 data:image 类型的链接也认为是安全的链接
    $compileProvider.imgSrcSanitizationWhitelist(/^\s*(https?|ftp|file|blob):|^\s*data:image\//);
    $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|ftp|mailto|tel|file):|^\s*data:image\//);
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'http://file.xinjiaoxue.cn:81/**',
      'https://www.rayclass.com/**',
      'http://www.xiwang.com/**',
      'http://101.201.52.153:18111/**',
      'http://res.zlwhedu.com/wanxiao/**'
    ]);
    LightboxProvider.templateUrl = 'Assets/templates/utils/imagePreviewDialog.html';
    var links = window.document.getElementsByTagName('link');

    _.assign(uibDatepickerPopupConfig, {
      currentText: '今天',
      clearText: '清空',
      doneText: '完成'
    });

    //================================================
    // Add an interceptor for AJAX errors
    //================================================
    $httpProvider.interceptors.push(['$q', '$location', '$injector', 'localStorageService', '$rootScope', 'notificationService',
      function($q, $location, $injector, localStorageService, $rootScope, notificationService) {
      return {
        request: function(config) {
          config.headers.oedEnv = process.env.NAME;
          var now = _.now();
          if (_.includes(config.url, 'signin')) {
            // localStorageService.set('bigdata_session_id', now);
            localStorageService.set('bigdata_last_report_timestamp', now);
          } else if (!_.includes(config.url, 'version_oedweb')) {
            // var sessionId = localStorageService.get('bigdata_session_id');
            // var lastUpdate = localStorageService.get('bigdata_last_report_timestamp');
            // if (!_.isNumber(lastUpdate) || now - lastUpdate > 300 * 1000) {
            //   localStorageService.set('bigdata_session_id', now);
            //   localStorageService.set('bigdata_last_report_timestamp', now);
            //   $rootScope.$emit('bigdata_report', {
            //     session_id: sessionId
            //   });
            // } else if (now - lastUpdate > 60 * 1000 && now - lastUpdate < 180 * 1000) {
            //   $rootScope.$emit('bigdata_report', {
            //     session_id: sessionId
            //   });
            //   localStorageService.set('bigdata_last_report_timestamp', now);
            // }
            // if (now - lastUpdate > 60 * 1000) {
            //   $rootScope.$emit('bigdata_report', {
            //     // session_id: sessionId
            //   });
            //   localStorageService.set('bigdata_last_report_timestamp', now);
            // }
          }
          return config;
        },
        responseError: function(response) {
          const reqUrl = _.get(response, 'config.url', '')
          if (response.status === 401 && !_.startsWith(reqUrl, '/ray/api/b/thirdParty')) {
            //
            // don't have prev state, should redirect to signin page
            var url = $location.url();
            if (!oedMisc.hasPrevState) {
              if (url != '/signup' && !_.startsWith(url, '/signin') && !_.startsWith(url, '/anno/') &&
                !_.startsWith(url, '/activate')) {
                $location.url('/signin/');
              }
            } else {
              if (url != '/signup' && !_.startsWith(url, '/signin') && !_.startsWith(url, '/anno/') &&
                !_.startsWith(url, '/activate')) {
                $location.url('/sessionTimeout');
              }
            }
          } else {
            var msg = '';
            var addDetails = true;
            switch (response.status) {
              case 0:
                msg = '服务器无响应, 请稍后再试 ';
                addDetails = false;
                break;
              case 500:
                msg = '服务器内部错误, 请联系锐学堂技术支持!';
                break;
            }
            if (msg) {
              // if(addDetails){
              //     msg += ' 当前路径: ' + $location.url();
              //     msg += ' 请求路径: ' + response.config.url;
              // }
              notificationService.notify('error', msg, '', 10000)
            }
          }
          return $q.reject(response);
        }
      };
    }]);

    $urlRouterProvider.otherwise('/');

    _.assign($uibModalProvider.options, {
      backdrop: 'static',
      backdropClass: 'backdrop'
    });

    dialogsProvider.setSize('sm');
    dialogsProvider.useClass('oedmodal oedSelectModal');

    $translateProvider.preferredLanguage('zh-CN');
  }])
