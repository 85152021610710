require('assets/app/controllers/examination/examNav/examNav.scss');
import * as _ from 'lodash'

examNavCtrl.$inject = []
function examNavCtrl() {
  this.partIndex = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];
  this.kPoint = false;
  this.$onChanges = (changeObj: any) => {
    if (_.has(changeObj, 'parts')) { // eslint-disable-line
    }
  };
}

export const examNav = {
  bindings: {
    parts: '<',
  },
  controller: examNavCtrl,
  template: <string>require('./examNav.html'),
}

angular.module('app.examination.editObjectTestPaper.examNav',[])
  .component('examNav', examNav)
