angular.module('app.manage.changeSubject', [])
  .controller('changeSubjectCtrl', changeSubjectCtrl);

changeSubjectCtrl.$inject = ['$scope', '$uibModalInstance', 'id', 'oedCourseManage']
function changeSubjectCtrl($scope, $uibModalInstance, id, oedCourseManage) {
  $scope.selectSub = '0';
  $scope.selectId = id;
  $scope.subjectList = [
    {
      id: -1,
      subjectName: '全部学科'
    }
  ];
  oedCourseManage.getSubjectList().$promise.then((data) => {
    _.forEach(data, (item,index) => {
        $scope.subjectList[index + 1] = item;
      if(item.id === $scope.selectId){
        $scope.selectSub = '' + (index + 1);
      }
    })
  });
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
  $scope.setSubject = function (sel, id) {
    $scope.selectSub = '' + sel;
    $scope.selectId = id;
    $scope.changeSubject();
  }
  $scope.isSubject = function (sel) {
    return $scope.selectSub === '' + sel;
  }

  $scope.changeSubject = function () {
      $uibModalInstance.close({success: true, id: $scope.selectId});
  }
}
