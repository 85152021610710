import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { zip } from 'rxjs';
import {
  DifficultType, ExamType, PaperType, ProvinceDTO,
  QuestionType, SelectedFilters, TalApiResponse, YearDTO, DocumentTypeDTO } from '../models/talcloud';
import { TalApiService } from '../services/tal-api.service';
import { FilterQuestionTypeComponent } from '../filter-question-type/filter-question-type.component';

@Component({
  selector: 'app-filter-container',
  template: require('./filter-container.component.html'),
  styles: [require('./filter-container.component.scss')],
  providers: [ TalApiService, FilterQuestionTypeComponent ],
})
export class FilterContainerComponent implements OnInit, OnChanges {

  @Input() public filterType: string
  @Input() public isShowPaperType: any
  @Input() public ruanyunQuestionTypes: any
  @Input() public ruanyunPaperTypes: any
  @Input() public ruanyunYears: any
  @Input() public ruanyunProvinces: any
  @Input() public stage: number
  @Input() public subjectId: number
  @Input() public selectedLevelId: number
  @Input() public selectedProvince: any
  @Input() public talAreaPhaseId: any
  @Input() public talAreaSubjectId: any
  @Input() public cleanFilter: any
  @Input() public defaultQuesTypeIdx: number
  @Output() public filterCondChange = new EventEmitter<SelectedFilters>()
  @Output() public selectQuestionTypes = new EventEmitter()
  @Output() public provinceSelected = new EventEmitter()
  @Output() public yearSelected = new EventEmitter()
  @Output() public talAreaMaterialTypeSelected = new EventEmitter()
  @Output() public selectDifficultTypes = new EventEmitter()
  @Output() public ruanyunPaperTypeSelected = new EventEmitter()
  @Output() public talAreaPaperTypeSelected = new EventEmitter()
  @Output() public talAreaPaperFormatSelected = new EventEmitter()
  @Output() public talAreaGradeSelected = new EventEmitter()
  @Output() public talAreaPaperTypeLoaded = new EventEmitter()

  @Output() public talAreaShengxueProvinceSelected = new EventEmitter()
  @Output() public talAreaShengxueYearSelected = new EventEmitter()
  @Output() public talAreaShengxueTypeSelected = new EventEmitter()
  @Output() public talAreaShengxueTypeLoaded = new EventEmitter()
  @Output() public talAreaShengxueProvinceLoaded = new EventEmitter()

  @Output() public talAreaQuestionTypeSelected = new EventEmitter()
  @Output() public talAreaQuestionDiffSelected = new EventEmitter()
  @Output() public talAreaExamTypeSelected = new EventEmitter()

  private quesTypes: QuestionType[]
  private difficults: DifficultType[]
  private ruanyunDifficults: any
  private examTypes: ExamType[]
  private yearTypes: YearDTO[]
  private provinceTypes: ProvinceDTO[]
  private provinceDefaultQuesTypeIdx: number
  private paperTypes: PaperType[]
  private allPaperTypes: PaperType[]
  private documentTypes: DocumentTypeDTO[]
  private talAreaDocumentMaterialTypes: any
  private talAreaPaperTypes: any
  private talAreaPaperFormats: any
  private talAreaGrades: any

  private talAreaShengxueTypes: any
  private talAreaShengxueProvinces: any
  private talAreaShengxueYears: any

  private talAreaQuestionTypes: any
  private talAreaQuestionDiffs: any
  private talAreaExamTypes: any

  private newLibSuffixFilter: any

  private selectedFilters: SelectedFilters = {
    type: '-1',
    difficult: '-1',
    examType: '-1',
    year: '-1',
    province: '-1',
    paperType: '-1',
  }

  private nonQuesFilters: SelectedFilters = {
    type: null,
    difficult: null,
    examType: null,
  }

  private nonPaperFilters: SelectedFilters = {
    year: null,
    province: null,
    paperType: null,
  }

  constructor(
    private talApiService: TalApiService
  ) {
    this.ruanyunDifficults = [
      {
        type: 0,
        text: '全部',
        sort: 0,
      },
      {
        type: 1,
        text: '容易',
        sort: 1,
      },
      {
        type: 2,
        text: '较易',
        sort: 2,
      },
      {
        type: 3,
        text: '一般',
        sort: 3,
      },
      {
        type: 4,
        text: '较难',
        sort: 4,
      },
      {
        type: 5,
        text: '困难',
        sort: 5,
      },
    ];
    this.difficults = this.talApiService.getDifficults()
    this.examTypes = this.talApiService.getExamTypes()
    this.yearTypes = this.talApiService.getYears()
    this.documentTypes = this.talApiService.getDocumentTypes()
  }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'filterType') || _.has(changes, 'stage') || _.has(changes, 'subjectId')) {
      if ((this.filterType === 'question' || this.filterType === 'knowledge') &&
      this.stage && this.subjectId) {
        this.queryFilterDataOfQues()
      }
      if (this.filterType === 'paper' || this.filterType === 'typeTest') {
        this.queryFilterDataOfPaper(this.filterType)
      }
      if (this.filterType === 'topicquestion' || this.filterType === 'topictest') {
        // oedCloudTag.getTagsByCategory({
        //   name: 'year',
        // }).$promise.then((result: any) => {
        //   this.years = _.concat([{
        //     $isAll: true,
        //     name: '所有年份',
        //   }], _(result).sortBy((v: any) => _.parseInt(v.name)).value())
        //   return this.years
        // })
      }
      if (this.filterType === 'document' || this.filterType === 'topicquestion'
      || this.filterType === 'topictest' || this.filterType === 'ruanyun') {
        this.queryFilterDataOfDocument()
      }
      if (this.filterType === 'tal-area-document-material') {
        this.talApiService.getTalAreaDocMaterialTypes('resources_type')
        .subscribe((value: any) => {
          this.talAreaDocumentMaterialTypes = this.combineWithAll(value)
        })
      }
      if (this.filterType === 'res-newlib-suffix-type') {
        this.newLibSuffixFilter = [{text: '全部', type: 0}, {text: '微课', type: 1}, {text: '课件', type: 2}, {text: '教学图库', type: 3}, {text: '影音资源', type: 4}]
      }
      if (this.filterType === 'tal-area-paper') {
        this.talApiService.getTalAreaDocMaterialTypes('paper_type')
        .subscribe((value: any) => {
          this.talAreaPaperTypes = this.combineWithAll(value)
          this.talAreaPaperTypeLoaded.emit(value)
        })
      }
      if (this.filterType === 'tal-area-shengxuekao') {
        zip(
          this.talApiService.getTalAreaDocMaterialTypes('shengkaoxue_type'),
          this.talApiService.getTalAreaDocMaterialTypes('province'),
        )
        .subscribe((value: [any, any]) => {
          this.talAreaShengxueTypes = this.combineWithAll(value[0])
          this.talAreaShengxueProvinces = this.combineWithAll(value[1])
          this.talAreaShengxueYears = this.talApiService.getTalAreaShengxueYears()
          this.talAreaShengxueTypeLoaded.emit(value[0])
          this.talAreaShengxueProvinceLoaded.emit(value[1])
        })
      }
      if (this.filterType === 'tal-area-objective') {
        zip(
          this.talApiService.getTalAreaDocMaterialTypes('question_type'), // 题类
          this.talApiService.getTalAreaDocMaterialTypes('question_difficulty'), // 难度
        )
        .subscribe((value: [any, any]) => {
          this.talAreaExamTypes = this.combineWithAll(value[0])
          this.talAreaQuestionDiffs = this.combineWithAll(value[1])
        })
      }
    }
    if (_.has(changes, 'talAreaPhaseId') || _.has(changes, 'talAreaSubjectId')) {
      if (this.talAreaPhaseId && this.talAreaSubjectId) {
        this.talApiService.getTalAreaPhaseSubjectQuesPatterns(this.talAreaPhaseId, this.talAreaSubjectId) // 题型
        .subscribe((value: any) => {
          this.talAreaQuestionTypes = this.combineWithAll(_.get(value, 'data', []))
        })
      }
    }

    if (_.has(changes, 'selectedLevelId')) {
      this.talApiService.getTalAreaGradeList(this.selectedLevelId).subscribe((value) => {
        this.talAreaGrades = this.combineWithAll(value)
      })
    }
  }

  private combineWithAll = (value) => {
    const data = _.map(value, (v) => {
      return {
        ...v,
        type: v.id,
        text: v.name,
      }
    })
    return [
      {
        text: '全部',
        sort: 0,
      },
      ...data,
    ]
  }

  private selectQuestionType = (questionType: string[]) => {
    this.selectQuestionTypes.emit(questionType);
  }

  private selectDifficultType = (diffcultTypes: any) => {
    this.selectDifficultTypes.emit(_.get(diffcultTypes, "type"));
  }

  private selectYear = (year: YearDTO) => {
    this.yearSelected.emit(year);
  }

  private selectTalAreaDocMaterialType = (type: any) => {
    this.talAreaMaterialTypeSelected.emit(type);
  }

  private selectNewLibSuffixFilter = (type: any) => {
    this.selectQuestionTypes.emit(type);
  }

  private selectTalAreaGrade = (type: any) => {
    this.talAreaGradeSelected.emit(type);
  }

  private selectTalAreaPaperType = (type: any) => {
    this.talAreaPaperTypeSelected.emit(type);
  }

  private selectTalAreaPaperFormat = (type: any) => {
    this.talAreaPaperFormatSelected.emit(type);
  }

  private selectTalShengxueType = (type: any) => {
    this.talAreaShengxueTypeSelected.emit(type);
  }

  private selectTalShengxueProvince = (type: any) => {
    this.talAreaShengxueProvinceSelected.emit(type);
  }

  private selectTalShengxueYear = (type: any) => {
    this.talAreaShengxueYearSelected.emit(type);
  }

  private selectProvince = (province: ProvinceDTO) => {
    this.provinceSelected.emit(province);
  }

  private selectRuanyunPaperTypes = (paperType: any) => {
    this.ruanyunPaperTypeSelected.emit(paperType);
  }

  private selectTalAreaQuesType = (type: any) => {
    this.talAreaQuestionTypeSelected.emit(type)
  }

  private selectTalAreaQuesDiff = (type: any) => {
    this.talAreaQuestionDiffSelected.emit(type)
  }

  private selectTalAreaExamType = (type: any) => {
    this.talAreaExamTypeSelected.emit(type)
  }

  private queryFilterDataOfDocument() {
    this.talApiService.getOedProvinces()
    .subscribe((value: ProvinceDTO[]) => {
      this.provinceTypes = value
      this.provinceDefaultQuesTypeIdx = _.get(_.find(this.provinceTypes, p => p.name === this.selectedProvince.name), 'sort', 0)
    })
  }

  private queryFilterDataOfPaper(type) {
    if(type === 'paper') {
      this.talApiService.getPaperTypes()
        .subscribe((value: PaperType[]) => {
          // 同步教材中试卷类型进行过滤， 只接收试卷类型为"全部"，"单元测试"，"同步测试"。
          this.paperTypes = _.filter(value, (item) => {
            return item.typeId === "-1" || item.typeId === 1 || item.typeId === 14
          });
        })
    } else if(type === 'typeTest'){
      zip(
        this.talApiService.getPaperTypes(),
        this.talApiService.getProvinces(),
      )
        .subscribe((value: [PaperType[], ProvinceDTO[]]) => {
          this.allPaperTypes = value[0];
          this.provinceTypes = value[1]
        })
    }
  }

  private queryFilterDataOfQues() {
    this.talApiService.getQuestionTypes(this.stage, this.subjectId)
    .subscribe((types: QuestionType[]) => {
      this.quesTypes = types
    })
  }

  private selectQuesType(quesType: QuestionType) {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...this.nonPaperFilters,
      type: quesType.type,
    }
    this.filterCondChange.emit(this.selectedFilters)
  }

  private selectDiffType(diffType: DifficultType) {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...this.nonPaperFilters,
      difficult: diffType.difficult,
    }
    this.filterCondChange.emit(this.selectedFilters)
  }

  private selectExamType(examType: ExamType) {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...this.nonPaperFilters,
      examType: examType.examType,
    }
    this.filterCondChange.emit(this.selectedFilters)
  }

  private selectYearType(year: YearDTO) {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...this.nonQuesFilters,
      year: year.id,
    }
    this.filterCondChange.emit(this.selectedFilters)
  }

  private selectProvinceType(province: ProvinceDTO) {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...this.nonQuesFilters,
      province: province.id,
      documentAreaId: province.id,
    }
    this.filterCondChange.emit(this.selectedFilters)
  }

  private selectPaperType(paperType: PaperType) {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...this.nonQuesFilters,
      paperType: paperType.typeId,
    }
    this.filterCondChange.emit(this.selectedFilters)
  }

  private selectDocumentType(documentType: DocumentTypeDTO) {
    this.selectedFilters = {
      ...this.selectedFilters,
      ...this.nonQuesFilters,
      ...this.nonPaperFilters,
      documentType: documentType.type,
    }
    this.filterCondChange.emit(this.selectedFilters)
  }

}
