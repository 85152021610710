import angular = require('angular');
import 'app2/directives/homework/homeworkTaskPreviewer.directive';
import * as _ from 'lodash';
require('assets/styles/homework.css');

angular.module('app.homework.homeworkLibraryPreviewerCtrl', [
  'app.directives.homework.homeworkTaskPreviewer',
])
  .controller('homeworkLibraryPreviewerCtrl', homeworkLibraryPreviewerCtrl);

homeworkLibraryPreviewerCtrl.$inject = ['$uibModalInstance', 'data']
function homeworkLibraryPreviewerCtrl($uibModalInstance: any, data: any) {
  const statTypes = [{
    name: '客观试卷',
    value: 'cloudTest',
  }, {
    name: '主观活动',
    value: 'cloudSubjTest',
  }, {
    name: '展示资源',
    value: 'cloudPresentRes',
  }];

  const stats = _.countBy(data, '$type');

  this.stats = _(statTypes).filter((t) => _.has(stats, t.value)).map((t) => ({
    count: stats[t.value],
    name: t.name,
  })).value();

  this.close = () => $uibModalInstance.dismiss('cancel');

  this.data = _.map(data, (d: any) => {
    switch (d.$type) {
    case 'cloudTest':
      return _.assign({}, d.test, {
        $type: 'objectiveTest',
      });
    case 'cloudSubjTest':
      return _.assign({}, d.test, {
        $type: 'subjectiveTest',
      });
    case 'cloudPresentRes':
      return _.assign({}, d.res, {
        $type: 'presentResource',
      });
    default:
      console.log(`unsupported type: ${d.$type}`);
      return null;
    }
  });

  this.onRemoveRes = (idx: number, event: any) => {
    event.preventDefault();
    event.stopPropagation();

    this.data.splice(idx, 1);
    data.splice(idx, 1);
  };

  this.getSubTitleText = () => {
    if (!this.stats) {
      return '';
    }

    const stat = _.map(this.stats, (s: any) => {
      return `${s.name} : ${s.count}`;
    }).join(' ');
    return stat;
  };

  this.importRes = () => {
    return $uibModalInstance.close({
      action: 'doImport',
    });
  };
}
