import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import moment = require('moment');
import { AppStateService } from 'app/core/app-state.service'

@Pipe({
  name: 'randomApk'
})
export class RandomApkPipe implements PipeTransform {
  constructor(private userInfo: AppStateService) { }
  transform(url: any, args?: any): any {
      const counter = moment(this.userInfo.counter).format('YYYYMMDDHHmm');
      if (_.endsWith(url, '.apk')) {
        url = url.substr(0, url.length - 4) + '_' + counter + '.apk';
      }
      return url;
  }
}
