require('assets/app/controllers/examination/examCreate/createExamPaper.scss');
require('assets/app/controllers/examination/examSummary/examSummaryCtrl');
require('assets/app/controllers/examination/examNav/examNavCtrl');
require('assets/app/controllers/examination/examPartsEditor/examPartsEditorCtrl');
require('assets/app/controllers/examination/dialog/deletePartCtrl');
require('assets/app/controllers/examination/examCreate/header/examCreateHeaderCtrl');
require('assets/app/controllers/examination/examEdit/generating/examEditGeneratingCtrl');
require('assets/app/controllers/examination/examEdit/generating/questionType/questionTypeCtrl');
require('assets/app/controllers/examination/examEdit/statistics/examEditStatCtrl');
require('assets/app/controllers/examination/examEdit/recommend/examEditRecommendCtrl');
require('assets/app/controllers/examination/examEdit/paper/examEditPaperCtrl');
require('assets/app/controllers/examination/examNm/examNmCtrl');
import { checkExamIsSaved, openExamConfirmDialog } from 'assets/app/controllers/examination/examEdit/paper/paperUtils'
import { getRyKnowledget } from "app2/integration/ruanyun/ruanyunUtils";

import * as _ from 'lodash'
import { createQuesUuidForExam, willSaveExam, willSaveExamWithConfirm2, DialogOpt, SaveExamInjectOpt } from '../examUtils';

angular.module('app.examination.createExamPaper', [
  'app.examination.editObjectTestPaper.examSummary',
  'app.examination.editObjectTestPaper.examNav',
  'app.examination.editObjectTestPaper.examPartsEditor',
  'app.examination.editObjectTestPaper.deletePart',
  'app.examination.examCreat.header',
  'app.examination.examEdit.generating',
  'app.examination.examEdit.generating.questionType',
  'app.examination.examEdit.statistics',
  'app.examination.examEdit.paper',
  'app.examination.examEdit.recommend',
  'app.examination.examEdit.examNm',
]).config(['$stateProvider', ($stateProvider) => {
  $stateProvider.state('createExamPaper', {
    url: '/createExamPaper?coursepathId&sectionId&sectionParentId&levelId&relId&sectionRootId',
    template: require('assets/app/controllers/examination/examCreate/createExamPaper.html'),
    controller: 'createExamPaperCtrl',
    loadingCls: 'blueloading',
    bodyStyle: 'bged text-hidden'
  });
}]).controller('createExamPaperCtrl', createExamPaperCtrl);

export enum ExamEditPage { GroupPage, RecommendPage }

createExamPaperCtrl.$inject = ['$stateParams', '$scope', 'oedExamination', '$location', '$anchorScroll',
  'userInfo', 'notificationService', 'oedUnitItem', 'ruanyunResource', 'dialogs', '$state', '$uibModal', 'examApi']
function createExamPaperCtrl($stateParams, $scope, oedExamination, $location, $anchorScroll,
  userInfo, notificationService, oedUnitItem, ruanyunResource, dialogs, $state, $uibModal,
  examApi,
) {
  $scope.examId = -1
  $scope.isShowTab = true
  $scope.isExamUpdate = false
  $scope.generatSelectedSize = 0
  $scope.isShowRecommend = true
  $scope.sectionId = $stateParams.sectionId;
  $scope.currentPage = ExamEditPage.GroupPage
  $scope.coursepathId = $stateParams.coursepathId
  $scope.levelId = $stateParams.levelId
  $scope.relId = $stateParams.relId
  $scope.sectionRootId = $stateParams.sectionRootId
  // 如果没有sectionParentId，则统一使用sectionId
  $scope.sectionParentId = _.isEmpty($stateParams.sectionParentId) ? $stateParams.sectionId : $stateParams.sectionParentId;

  const injOpt: SaveExamInjectOpt  = {
    notificationService, 
    $uibModal, 
    oedExamination, 
    loading: $scope.loading
  }

  $scope.loading = oedExamination.getCoursePath({ id: $scope.coursepathId }).$promise
    .then((coursepath) => {
      if (_.isEmpty(coursepath) || !coursepath.gradeId || !coursepath.subjectId || !coursepath.versionId) {
        notificationService.notify('error', '获取章节信息失败');
        return
      }
      getRyKnowledget(examApi, coursepath.subjectId, $scope.levelId)
      .then((kps) => {
        $scope.knowledgePoints = kps
      })
    })

  $scope.saveExamTest = () => {
    willSaveExam($scope.examData, notificationService, $uibModal, oedExamination, $scope.loading, (data) => {
      const {examId, examData} = data
      $scope.examId = examId
      $scope.examData = examData
      $scope.isShowTab = false
      $scope.isShowRecommend = false
    })
  }

  $scope.cloneExamTest = () => {
    console.log('cloneExamTest')
  }

  $scope.init = () => {
    $location.hash(`exam_paper_header`);
    $anchorScroll();
    loadData()
  };
  $scope.init();

  $scope.onSetRequestData = (requestData: any) => {
    if (_.isEmpty(requestData)) {
      notificationService.notify('info', '获取组卷信息失败')
      return
    } 
    $scope.examData = requestData
    $scope.isShowTab = false
    $scope.isShowRecommend = false
    $scope.currentPage = ExamEditPage.RecommendPage
  }

  function treeToArray(childs: any[]) {
    return _.flatten(_.map(childs, (child: any) => _.concat(child, _.flatten(treeToArray(child.child)))))
  }

  function loadData() {
    loadSectionParent()
  }

  function loadSectionParent() {
    $scope.loading = userInfo.then((info) => {
      $scope.info = info;
      $scope.isResearcher = _.findIndex(_.get(info, 'roles', []), (r) => { return r === 'researcher' }) !== -1;
      oedUnitItem.get({ id: $scope.sectionParentId }).$promise
        .then((sectionParent: any) => {
          // 考试获取题目的信息统一用sectionParent来获取
          $scope.sectionParentName = _.get(sectionParent, 'name', '')
          oedUnitItem.get({ id: $scope.sectionId }).$promise
            .then((seciton: any) => {
              $scope.examName = _.get(seciton, 'name', '')
            })
        })
    })
  }

  $scope.updateExamDataByExam = (examData: any) => {
    $scope.examData = examData
  }

  $scope.updateVisibleLetters = (letters) => {
    $scope.letters = letters
    $scope.currentTag = []
    $scope.currentNm = []
    if (_.size(letters) > 0) {
      $scope.previous = $scope.letters
    }
    if (_.size($scope.previous) > 0) {
      $scope.currentTag = _.split($scope.previous[0], '_')[1]
      $scope.currentNm = _.split($scope.previous[0], '_')[2]
    }
  }

  $scope.updateCurExamById = (id: number) => {
    $scope.loading = oedExamination.getExamTestWhole({ examId: id }).$promise
    .then((res) => {
      $scope.examId = _.get(res, 'examWithTags.exam.id', '')
      $scope.examData = createQuesUuidForExam(res)
    })
  }

  $scope.toggleCurrentPage = (page: number) => {
    if ($scope.currentPage === page) {
      return
    }
    if (page === ExamEditPage.RecommendPage && $scope.generatSelectedSize > 0) {
      // 智能组卷做了选择之后，切换到推荐试卷
      openExamConfirmDialog($uibModal, '您做的编辑将丢失，是否继续？', '切换', '是', '否', () => $scope.currentPage = page)
      return
    }
    const isSaved = checkExamIsSaved($scope.examData)
    if (!isSaved && $scope.isExamUpdate && page === ExamEditPage.GroupPage) {
      const dialogOpt: DialogOpt = {
        yesAction: (data) => {
          const {examId} = data
          $scope.examId = examId
          $scope.examData = {}
          $scope.currentPage = page
        },
        noAction: () => {
          $scope.examData = {}
          $scope.currentPage = page
        },
        title: '返回'
      }
      willSaveExamWithConfirm2($scope.examdata)(dialogOpt)(injOpt)
      return
    }
    if (!isSaved && page === ExamEditPage.RecommendPage) {
      // 之前的推荐试卷未保存，再切回推荐试卷，清空当前试卷重新加载一遍推荐试卷
      $scope.examData = {}
    }
    $scope.currentPage = page
  }

  $scope.onCheckCurExamDeleted = (examList: any[], index: number) => {
    $scope.isExamUpdate = false
    if (_.size(examList) <= 1) {
      return
    }
    const deleteExamId = _.get(examList, `${index}.id`)
    if (deleteExamId == $scope.examId) {
      // 如果删除了当前选中的试卷，统计和编辑试卷就置空
      $scope.examData = {}
    }
  }

  $scope.onExamIsUpdate = (update: boolean) => {
    $scope.isExamUpdate = update
  }

  $scope.onJumpToPage = (page: string) => {
    const isSaved = checkExamIsSaved($scope.examData)
    if (!isSaved) {
      if ($scope.isShowRecommend ) {
        $state.go(page)
        return
      }
      const dialogOpt: DialogOpt = {
        yesAction: (data) => {
          const {examId, examData} = data
          $scope.examId = examId
          $scope.examData = examData
          $state.go(page)
        },
        noAction: () => {
          $state.go(page)
        },
        title: '返回',
      }
      willSaveExamWithConfirm2($scope.examdata)(dialogOpt)(injOpt)

      return
    }
    $state.go(page)
  }

  $scope.onCreatGroupPaper = () => {
    const isSaved = checkExamIsSaved($scope.examData)
    if (isSaved || $scope.currentPage == 0) {
      $scope.toggleCurrentPage(0)
      return
    }
    const dialogOpt: DialogOpt = {
      yesAction: (data) => {
        const {examId, examData} = data
        $scope.examId = examId
        $scope.examData = examData
        $scope.toggleCurrentPage(0)
      },
      title: '返回',
    }
    willSaveExamWithConfirm2($scope.examdata)(dialogOpt)(injOpt)

  }

  $scope.onBackToPrePage = () => {
    $scope.onJumpToPage('prepareLesson')
  }

  $scope.toggleExamTest = (id: number) => {
    const isSaved = checkExamIsSaved($scope.examData)
    if (!isSaved && $scope.isExamUpdate) {
      const dialogOpt: DialogOpt = {
        yesAction: (data) => {
          const {examId, examData} = data
          $scope.examId = examId
          $scope.examData = examData
          getExamWholeById(id)
        },
        noAction: () => {
          getExamWholeById(id)
        },
        title: '返回',
      }
      willSaveExamWithConfirm2($scope.examdata)(dialogOpt)(injOpt)

      return
    }
    getExamWholeById(id)
  }

  function getExamWholeById(examId: number) {
    $scope.loading = oedExamination.getExamTestWhole({ examId }).$promise
      .then((res) => {
        $scope.examId = _.get(res, 'examWithTags.exam.id', '')
        $scope.examData = createQuesUuidForExam(clearExamDataInfo(res))
      })
  }

  function clearExamDataInfo(data) {
    delete data.relatedSections
    delete data.$promise
    delete data.$resolved
    const exam = _.get(data, 'examWithTags.exam', {})
    const isResearcher = checkIsResearcher()
    if (!_.isEmpty(exam)) {
      delete exam.id
      delete exam.lastUpdateTime
      delete exam.recycled
      delete exam.deleted
      delete exam.isCopy
      _.set(exam, 'coursepathId', _.parseInt($scope.coursepathId))
      const teacherId = _.parseInt(_.get($scope.info, 'uid', ''))
      _.set(exam, 'teacherId', teacherId)
      data.examWithTags.exam.relatedSections = [_.parseInt($scope.sectionId)]
    }
    const tags = _.get(data, 'examWithTags.tags', {})
    if (!isResearcher) {
      _.remove(tags, tag => {
        const tagName = _.get(tag, 'tagName', '')
        return tagName == 'recommend'
      })
    }
    const examPartDetails = _.get(data, 'examPartDetails', [])
    if (_.size(examPartDetails) > 0) {
      _.forEach(examPartDetails, pd => {
        const part = _.get(pd, 'part', {})
        delete part.id
        delete part.examId
        delete part.createTime
        delete part.lastUpdateTime
        delete part.deleted
        delete part.isCopy
        const questionDetails = _.get(pd, 'questionDetails', [])
        if (_.size(questionDetails) > 0) {
          _.forEach(questionDetails, qd => {
            delete qd.relatedOutlines
            const question = _.get(qd, 'question', {})
            delete question.id
          })
        }
      })
    }
    return data
  }

  function checkIsResearcher() {
    const roles = _.get($scope.info, 'roles', [])
    for (let i = 0; i < roles.length; i++) {
      if (roles[i] == 'researcher') {
        return true
      }
    }
    return false
  }

  $scope.updateSelectSize = (size: number) => {
    $scope.generatSelectedSize = size
  }
}
