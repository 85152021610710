import { Injectable } from '@angular/core';
import {RayApiService} from 'app/core/ray-api.service';
import {HttpClient} from '@angular/common/http';
import {CloudDriveItem, CountResult} from 'app/models/cloudDrive';
import * as _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class CloudDriveService {
  public capacityLimit = 1024 * 1024 * 1024 * 1
  public pageSize = 30
  
  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService) { }
              
  public bytesToSize(bytes) {
    const thresh = 1024;
    if (bytes < thresh) return bytes + ' B';
    const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (bytes >= thresh);
    return bytes.toFixed(1) + units[u];
  }
  
  public b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(_.parseInt('0x' + p1));
    }));
  }
  
  public getTypeUrl(type) {
    if(!type){
      return;
    }
    switch (type) {
      case 'doc':
      case 'docx':
        return "fa-file-word-o";
        break;
      case 'pdf':
        return "fa-file-pdf-o";
        break;
      case 'JPG':
      case 'jpg':
      case 'JPEG':
      case 'jpeg':
      case 'PNG':
      case 'png':
        return 'fa-file-photo-o';
        break;
      case 'mp4':
        return 'fa-file-video-o';
        break;
      case 'mp3':
        return 'fa-file-audio-o';
        break;
      case 'txt':
        return  'fa-file-text-o';
        break;
      case 'ppt':
      case 'pptx':
        return  'fa-file-powerpoint-o';
        break;
      case 'xls':
      case 'xlsx':
        return  'fa-file-excel-o';
        break;
      case 'zip':
        return "fa-file-archive-o"
        break;
      default:
        return 'fa-file-o';
        break;
    }
  }
  
  public queryAllDirs() {
    return this.httpClient.get<CloudDriveItem[]>(this.rayApi.apiB(`cloudDrive/allDirs`))
  }
  
  public createRootDir(params) {
    return this.httpClient.post<CloudDriveItem>(this.rayApi.apiB(`cloudDrive/createRootDir`), params)
  }
  
  public getFullPath(id: number) {
    return this.httpClient.get<CloudDriveItem[]>(this.rayApi.apiB(`cloudDrive/${id}/fullPath`))
  }
  
  public getFileByPath(id: number, page: number, pageSize: number, sortType?: string) {
    return this.httpClient.get<CloudDriveItem[]>(this.rayApi.apiB(`cloudDrive/${id}/items?page=${page}&pageSize=${pageSize}&sortType=${sortType}`))
  }
  
  public getFileTotalCount(id: number) {
    return this.httpClient.get<CountResult>(this.rayApi.apiB(`cloudDrive/${id}/count`))
  }
  
  public totalSize_microLesson() {
    return this.httpClient.get<any>(this.rayApi.apiB(`cloudDrive/microLesson/totalSize`))
  }
  
  public totalSize() {
    return this.httpClient.get<any>(this.rayApi.apiB(`cloudDrive/totalSize`))
  }
  
  public updateFile(id, params) {
    return this.httpClient.post<CloudDriveItem>(this.rayApi.apiB(`cloudDrive/${id}/update`), params)
  }
  
  public createContent(params) {
    return this.httpClient.post<CloudDriveItem>(this.rayApi.apiB(`cloudDrive/createContent`), params)
  }
  
  public createDir(params) {
    return this.httpClient.post<CloudDriveItem>(this.rayApi.apiB(`cloudDrive/createDir`), params)
  }
  
  public recycle(ids) {
    return this.httpClient.post<any>(this.rayApi.apiB(`cloudDrive/recycle`), ids)
  }
  
  public moveFile(newParentId, params) {
    return this.httpClient.post<any>(this.rayApi.apiB(`cloudDrive/moveTo/${newParentId}`), params)
  }
  
  public copyFile(newParentId, params) {
    return this.httpClient.post<any>(this.rayApi.apiB(`cloudDrive/copyTo/${newParentId}`), params)
  }
  
  public getMicroLessonTotalCount() {
    return this.httpClient.get<any>(this.rayApi.apiB(`cloudDrive/microLesson/count`))
  }
  
  public microLesson(page: number, pageSize: number, sortType?: string) {
    return this.httpClient.get<any>(this.rayApi.apiB(`cloudDrive/microLesson/query?page=${page}&pageSize=${pageSize}&sortType=${sortType}`))
  }
  
  public microLessonRecycle(ids) {
    return this.httpClient.post<any>(this.rayApi.apiB(`cloudDrive/microLesson/recycle`), ids)
  }
  
  public clearRecycleBin() {
    return this.httpClient.post<any>(this.rayApi.apiB('cloudDrive/clearRecycleBin'), {})
  }
  
  public clearRecycleBinMicroLesson() {
    return this.httpClient.post<any>(this.rayApi.apiB('cloudDrive/microLesson/clearRecycleBin'), {})
  }
  
  public recycleBin(page: number, pageSize: number, sortType?: string) {
    return this.httpClient.get<any>(this.rayApi.apiB(`cloudDrive/recycleBin?page=${page}&pageSize=${pageSize}&sortType=${sortType}`))
  }
  
  public recycleBinMicroLesson(page: number, pageSize: number, sortType?: string) {
    return this.httpClient.get<any>(this.rayApi.apiB(`cloudDrive/microLesson/recycleBin?page=${page}&pageSize=${pageSize}&sortType=${sortType}`))
  }
  
  public getFileCountByRecycBin() {
    return this.httpClient.get<any>(this.rayApi.apiB(`cloudDrive/recycleBin/count`))
  }
  
  public getMicroLessonCountByRecycBin() {
    return this.httpClient.get<any>(this.rayApi.apiB(`cloudDrive/microLesson/recycleBin/count`))
  }
  
  public restoreCourse(ids) {
    return this.httpClient.post<any>(this.rayApi.apiB('cloudDrive/microLesson/restore'), ids)
  }
  
  public restoreFile(id) {
    return this.httpClient.post<any>(this.rayApi.apiB(`cloudDrive/${id}/restore`), {})
  }
  
  public restoreAgain(id, params) {
    return this.httpClient.post<any>(this.rayApi.apiB(`cloudDrive/${id}/restoreAgain`), params)
  }
  
  public deleteFilesComplete(ids) {
    return this.httpClient.post<any>(this.rayApi.apiB('cloudDrive/delete'), ids)
  }
  
  public deleteCoursesComplete(ids) {
    return this.httpClient.post<any>(this.rayApi.apiB('cloudDrive/microLesson/delete'), ids)
  }
}
