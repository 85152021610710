
import * as _ from 'lodash'

angular.module('app.prepareCourse2.cloud.presentResource.talArea.talAreaLevelSubjectDialog', [
])
.controller('oedTalAreaLevelSubjectDialogCtrl', oedTalAreaLevelSubjectDialogCtrl)

oedTalAreaLevelSubjectDialogCtrl.$inject = ['$uibModalInstance', 'data', '$scope']
function oedTalAreaLevelSubjectDialogCtrl($uibModalInstance: any, data: any, $scope: any) {

  $scope.levels = data.levels
  $scope.selectedLevel = data.selectedLevel
  $scope.selectedSubject = data.selectedSubject
  $scope.getSubjectFuc = data.getSubjectFuc

  $scope.onSelectLevel = (l) => {
    $scope.tempSelectedLevel = l
    $scope.curTabIdx = _.findIndex($scope.levels, l);
    $scope.getSubjectFuc(l.id).then((s) => {
      $scope.subjects = s
    })
  }

  const init = () => {
    $scope.onSelectLevel($scope.selectedLevel)
  }

  $scope.isSubjectSelected = (s) => {
    return _.get($scope.selectedSubject, 'id') === _.get(s, 'id')
    && _.get($scope.tempSelectedLevel, 'id') === _.get($scope.selectedLevel, 'id')
  }

  init();

  $scope.onLevelSubjectSelected = (l, s) => {
    $uibModalInstance.close({
      selectedLevel: l,
      selectedSubject: s,
    })
  }
  $scope.onClose = () => $uibModalInstance.dismiss('cancel')
}
