import {
  AfterViewChecked,
  ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChange,
  ViewChild
} from '@angular/core';
import {CloudDriveService} from 'app/cloud-drive/cloud-drive.service';
import {ResourceService} from 'app/core/resource.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import * as _ from 'lodash'
import {getHttpCDN} from 'app2/utils/getHttpCDN';
import {NotificationService} from 'app/shared/notification/notification.service';
import {finalize, flatMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {CloudDriveItem, CountResult} from 'app/models/cloudDrive';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {BIG_FILE_SIZE} from 'app2/utils/constants';
import {OedDialogService} from 'app/core/oed-dialog.service';
import {ErrorDialogComponent} from 'app/cloud-drive/error-dialog/error-dialog.component';
import {DirDialogComponent} from 'app/cloud-drive/dir-dialog/dir-dialog.component';

@Component({
  selector: 'app-my-file',
  template: require('./my-file.component.html'),
  styles: [require('./my-file.component.scss')]
})
export class MyFileComponent implements OnInit {
  @Input() fileArr
  @Input() fullPath
  @Input() fileCount
  @Input() fileSize
  @Input() isPrepareLesson
  @Output() loadingDrive = new EventEmitter<boolean>();
  @Output() refreshDriveSize = new EventEmitter<CountResult>();
  @Output() importRes = new EventEmitter<any>();
  
  changeStyleObj = {}
  checkAll = false
  selectTotalNum = 0
  isSelectMode = false
  selectedSort = ''
  sortDateAsc = false
  sortSizeAsc = false
  sortNameAsc = false
  sortType = undefined
  // todo, test this 判断顶层窗口是否为iframe(配合桌面端使用)
  topWindowIsIframe = window.top === window.self;
  linkOpenType = this.topWindowIsIframe ? '_blank' : '_self';
  viewMode = '1'
  creatNewDir = false
  page = 0;
  pageNum = 0;
  handledFiles = []
  domHeightObj={};
  uploadComplete= false;//上传状态
  defaultExpand = true;//默认展开
  uploader: FileUploader;
  uploadUrl = '/ray/api/a/ceditor/uploadresource'
  uploadNum = 0
  fileResPair = []
  canAppend = true

  constructor(public bsModalRef: BsModalRef,
              public cloudDriveService: CloudDriveService,
              public resourceService: ResourceService,
              public oedDialogService: OedDialogService,
              public notifyService: NotificationService) {
    this.uploader = new FileUploader({
      url: this.uploadUrl,
    });
  
    this.uploader.onProgressItem = (item, progress) => {
      if (progress < 100) {
        console.log(progress);
      }
      item.onComplete = (res) => {
        item.progress = 110
        this.fileResPair.push({item: item, res: JSON.parse(res)})
      }
    };
    
    this.uploader.onCompleteAll = () => {
      forkJoin(_.map(this.handledFiles, (f) => {
        const pair = _.find(this.fileResPair, (p: any) => p.item === f)
        if (_.isEmpty(pair)) {
          return of({})
        }
        const res = pair.res
        const requestObj = {
          "name":res.suffix ? res.resourceName + '.' + res.suffix : res.resourceName,
          "parentDirId":this.fullPath[this.fullPath.length - 1].id,
          "resourceUuid":res.md5,
          "sort":0
        }
        return this.cloudDriveService.createContent(requestObj);
      })).pipe(
        flatMap((contents) => {
          this.uploadComplete = true;
          const id = this.fullPath[this.fullPath.length-1].id;
          this.loadingDrive.emit(true)
          return this.getPathData(id);
        }),
        finalize(() => {
          this.uploadComplete = true
          this.loadingDrive.emit(false)
        })
      ).subscribe()
    }
    
    this.uploader.onAfterAddingAll = (items: any) => {
      let totalSize = this.fileSize;
      _.forEach(items, (item: FileItem)=> {
        totalSize = totalSize + item.file.size;
      });
      if(totalSize >= this.cloudDriveService.capacityLimit){
        return this.notifyService.notify('error', '内存已满');
      } else if(this.hasBigFile(_.map(items, (item) => item.file))) {
        this.oedDialogService.openModalWithComponent2('ErrorDialogComponent', {
          title: '无法上传',
          msg: '无法上传超过500M的文件，请检查。'
          }, {
            class: 'oedSelectModal error-modal-center',
          }).subscribe()
      } else {
        this.handledFiles = items;
        this.uploadNum =  this.handledFiles.length;
        this.uploadComplete = false;
        this.uploader.uploadAll()
      }
    }
  }

  ngOnInit() {
  }
  
  public ngOnChanges(changes: SimpleChange) {
    if(_.has(changes, 'fileArr')) {
      this.fileArr = _.get(changes, 'fileArr.currentValue')
    }
  
    if(_.has(changes, 'fullPath')) {
      this.fullPath = _.get(changes, 'fullPath.currentValue')
    }
  
    if(_.has(changes, 'fileCount')) {
      this.fileCount = _.get(changes, 'fileCount.currentValue')
    }
  
    if(_.has(changes, 'fileSize')) {
      this.fileSize = _.get(changes, 'fileSize.currentValue')
    }
  
    if(_.has(changes, 'isPrepareLesson')) {
      this.isPrepareLesson = _.get(changes, 'isPrepareLesson.currentValue')
    }
  }
  
  public changeExpandStatus() {
    this.defaultExpand = !this.defaultExpand;
    if(this.defaultExpand){
      this.domHeightObj = {
        'height':'280px'
      }
    }else{
      this.domHeightObj = {
        'height':'60px'
      }
    }
  }
  
  public close = ()=>{
    this.handledFiles = [];
  }
  
  public showPathEllipsis() {
    return _.slice(this.fullPath, 1).length >= 6
  }
  
  public backTopPath() {
    const id = this.fullPath[this.fullPath.length - 2].id;
    this.getFileByPath(id);
  }
  
  public getFileByPath(id) {
    this.loadingDrive.emit(true)
    this.getPathData(id).pipe(finalize(()=>this.loadingDrive.emit(false))).subscribe();
  }
  
  public getPathBySlice(idx) {
    return _.slice(this.fullPath, idx)
  }
  
  public createFolder() {
    if (this.creatNewDir) {
      return
    }
    
    if(this.fullPath.length >= 21) {
      return this.notifyService.notify('error', '系统最大支持20级目录');
    }
  
    this.creatNewDir = true;
    setTimeout(() => {
      $('.creatInput').focus();
      $('.creatInput').val("新建文件夹");
      $('.creatInput').off('blur').blur((event: any) => {
        const text = event.currentTarget.value;
        if (!text) {
          this.notifyService.notify('error', '请输入名称');
          $('.creatInput').focus();
          return;
        }
        const requestObj = {
          name: text,
          parentDirId: this.fullPath.length > 0 ? this.fullPath[this.fullPath.length - 1].id : '1',
          sort: 0
        }
        
        this.loadingDrive.emit(true)
        this.cloudDriveService.createDir(requestObj).pipe(
          flatMap((res: any) => {
              res.$$checked = false;
              res.$$canEditName = false;
              if (this.selectedSort) {
                const id = this.fullPath[this.fullPath.length - 1].id;
                return this.getPathData(id);
              } else {
                this.fileArr.unshift(res);
                return of({})
              }
          }),
          finalize(() => {
            this.loadingDrive.emit(false)
            this.creatNewDir = false;
            this.notifyService.notify('info', '新建成功');
          })
        ).subscribe()
      })
    }, 0)
  }
  
  public importPreResourceItem() {
    const list = _.filter(this.fileArr, (data: any) => {
      return data.$$checked;
    }).map(function (item: any) {
      return { resourceUuid: item.resourceUuid, name: item.name }
    });
    if (list.length == 0) {
      return this.notifyService.notify('error', '请选择导入的文件');
    }
    const isSelectDir = _.filter(this.fileArr, (data: any) => {
      return data.$$checked && data.itemType == 'dir';
    })
    if (isSelectDir.length > 0) {
      return this.notifyService.notify('error', '选择的文件中包含文件夹');
    }
    this.importRes.emit({ res: list, type:'myFile'})
  }
  
  public changeSelectAllStatus() {
    this.checkAll = !this.checkAll;
    
    _.forEach(this.fileArr, (element: any) => {
      if (this.isPrepareLesson) {
        if(element.itemType != 'dir') {
          element.$$checked =  this.checkAll;
        }
      } else {
        element.$$checked =  this.checkAll;
      }
    })
    
    if(this.checkAll){
      this.selectTotalNum = _.filter(this.fileArr,(r: any) => {
        return r.$$checked;
      }).length;
      this.isSelectMode = true;
    }else{
      this.isSelectMode = false;
      this.selectTotalNum = 0;
    }
    if (this.selectTotalNum > 0) {
      this.changeStyleObj = {
        'color': 'white',
        'background': '#93bd44'
      }
    } else {
      this.changeStyleObj = {
        'background':'#e5e5e5',
        'color': 'darkgray'
      }
    }
  }
  
  public downLoadFile(item) {
    let uuid = "";
    let name = "";
    if(this.isSelectMode){
      if(item){
        return;
      }
      const list  = _.filter(this.fileArr,(data: any)=>{
        return  data.$$checked;
      });
      if(list.length == 0){
        return this.notifyService.notify('error', '请选择需要下载的文件');
      }
      if(list.length > 1 ){
        return;
      }
      if(list[0]['itemType'] == "dir"){
        return this.notifyService.notify('error', '文件夹不支持下载');
      }
      uuid = list[0]['resourceUuid'];
      name = list[0]['name'];
    }else{
      uuid = item['resourceUuid'];
      name = item['name'];
    }
    name = this.cloudDriveService.b64EncodeUnicode(encodeURIComponent(name));
    const url = getHttpCDN(`/ray/resource/uuid/${uuid}/raw64/` + name);
    window.open(url, this.linkOpenType);
  }
  
  public moveFile() {
    const idList  = _.filter(this.fileArr,(data: any)=>{
      return  data.$$checked;
    }).map(function(item){
      return item["id"];
    });
    
    this.oedDialogService.openModalWithComponent2('DirDialogComponent', {
      fileNum: this.selectTotalNum,
      type: 'move',
      idList: idList
    }, {
      class: 'move-file-box',
    }).pipe(
      flatMap(() => {
        const id = this.fullPath[this.fullPath.length-1].id;
        this.loadingDrive.emit(true)
        return this.getPathData(id);
      }),
      flatMap(() => {
        this.loadingDrive.emit(false)
        return of({})
      })
    ).subscribe()
  }
  
  public copyFile() {
    const idList  = _.filter(this.fileArr,(data: any)=>{
      return  data.$$checked;
    }).map(function(item){
      return item["id"];
    });
  
    this.oedDialogService.openModalWithComponent2('DirDialogComponent', {
      fileNum: this.selectTotalNum,
      type: 'copy',
      idList: idList
    }, {
      class: 'move-file-box',
    }).pipe(
      flatMap(() => {
        const id = this.fullPath[this.fullPath.length-1].id;
        this.loadingDrive.emit(true)
        return this.getPathData(id);
      }),
      flatMap(() => {
        this.loadingDrive.emit(false)
        return of({})
      })
    ).subscribe()
  }
  
  public deleteFile() {
    const idList  = _.filter(this.fileArr,(data: any)=>{
      return  data.$$checked;
    }).map(function(item){
      return item["id"];
    });
    
    this.loadingDrive.emit(true)
    this.cloudDriveService.recycle(idList).pipe(
      flatMap(() => {
        const id = this.fullPath[this.fullPath.length-1].id;
        return this.getPathData(id);
      }),
      finalize(() => this.loadingDrive.emit(false))
    ).subscribe(() => {}, (error) => {
      if(error.status == 400){
        this.notifyService.notify('error', '删除文件失败')
      }
    })
  }
  
  public rename() {
    const filecheck  = _.filter(this.fileArr,(data: any) => {
      return  data.$$checked;
    });
    if (filecheck.length > 1) {
      return this.notifyService.notify('error', '重命名操作只能单个文件');
    } else {
      filecheck[0]['$$canEditName'] = true;
      setTimeout(() => {
        $('.editInput').focus();
        $('.editInput').blur((data1: any) => {
          const text = data1.currentTarget.value;
          if (text === filecheck[0]['name']) {
            filecheck[0]['$$canEditName'] = false;
            return;
          } else {
            const oldName = filecheck[0]['name']
            filecheck[0]['name'] = text;
            this.loadingDrive.emit(true)
            this.cloudDriveService.updateFile(filecheck[0]['id'], filecheck[0])
              .pipe(finalize(() => this.loadingDrive.emit(false)))
              .subscribe(() => {
                  filecheck[0]['$$canEditName'] = false;
                }, (error) => {
                  const errCode = _.get(error, 'status', 500);
                  if (errCode === 400) {
                    if (_.get(error, 'data', '') === 'cloudDrive/nameExceedMaxLength') {
                      this.notifyService.notify('error', '重命名失败，请保证文件名长度小于512！')
                    } else {
                      this.notifyService.notify('error', '重命名失败，目录已存在同名文件！')
                    }
                  } else {
                    this.notifyService.notify('error', '重命名失败！')
                  }
                  filecheck[0]['$$canEditName'] = false
                  filecheck[0]['name'] = oldName
                })
          }
        });
      }, 0);
    }
  }
  
  public sortByName(source) {
    this.selectedSort = 'name';
    if(source != "refresh"){
      this.sortNameAsc = !this.sortNameAsc;
    }
    if(this.sortNameAsc){
      this.sortType = 'nameDown'
    }else{
      this.sortType = 'nameUp'
    }
    
    this.getFileByPath(this.fullPath[this.fullPath.length - 1].id)
  }
  
  public sortByDate(source) {
    this.selectedSort = 'date';
    if (source !== 'refresh') {
      this.sortDateAsc = !this.sortDateAsc;
    }
    if (this.sortDateAsc) {
      this.sortType = 'dateDown'
    } else {
      this.sortType = 'dateUp'
    }
    
    this.getFileByPath(this.fullPath[this.fullPath.length - 1].id)
  }
  
  public sortBySize(source) {
    this.selectedSort = 'size';
    if (source !== 'refresh') {
      this.sortSizeAsc = !this.sortSizeAsc;
    }
    if (this.sortSizeAsc) {
      this.sortType = 'sizeDown'
    } else {
      this.sortType = 'sizeUp'
    }
    
    this.getFileByPath(this.fullPath[this.fullPath.length - 1].id)
  }
  
  public hasFile() {
    return !_.isEmpty(this.fileArr)
  }
  
  public changeViewMode(type) {
    this.viewMode = type;
  }
  
  public myPagingFunction() {
    this.pageNum = Math.ceil(this.fileCount/this.cloudDriveService.pageSize);
    if(this.canAppend){
      if(this.page + 1 < this.pageNum) {
        this.page++;
        this.canAppend = false;//防止请求异步导致多次append
        const id = this.fullPath[this.fullPath.length - 1].id;
        this.loadingDrive.emit(true)
        this.getPathData(id,'append').pipe(finalize(() => {
          this.loadingDrive.emit(false)
        })).subscribe();
      } else {
        return;
      }
    }
  }
  
  public changeSelectSingleStatus(item) {
    item.$$checked = !item.$$checked;
    const filecheck  = _.filter(this.fileArr,(data: any)=>{
      return  data.$$checked;
    });
    if(filecheck.length>0){
      this.changeStyleObj = {
        'color': 'white',
        'background': '#93bd44'
      }
      this.selectTotalNum = filecheck.length;
      this.isSelectMode = true;
    }else{
      this.changeStyleObj = {
        'background':'#e5e5e5',
        'color': 'darkgray'
      }
      this.isSelectMode = false;
      this.selectTotalNum = 0;
    }
    if(filecheck.length ==  this.fileArr.length){
      this.checkAll = true;
    }else{
      this.checkAll = false;
    }
  }
  
  public selectFile(item) {
    if(item.itemType == 'dir'){
      this.loadingDrive.emit(true)
      this.getPathData(item.id).subscribe(()=>{}, ()=>{}, () => this.loadingDrive.emit(false));
    }else{
      this.downLoadFile(item);
    }
  }
  
  public getTypeUrl(type) {
    return this.cloudDriveService.getTypeUrl(type)
  }
  
  public onScroll() {
    this.pageNum = Math.ceil(this.fileCount/this.cloudDriveService.pageSize);
    if(this.page + 1 < this.pageNum){
      this.page++;
      const id = this.fullPath[this.fullPath.length-1].id;
      this.loadingDrive.emit(true)
      this.getPathData(id,'append')
        .pipe(finalize(() => this.loadingDrive.emit(false)))
        .subscribe();
    }
  }
  
  public getPathData(id, type?: string) {
    if(type != "append"){
      this.page= 0;
      this.checkAll = false;
      this.isSelectMode = false;
    }
    
    return forkJoin(this.cloudDriveService.getFullPath(id), this.cloudDriveService.totalSize()).pipe(
      flatMap((res) => {
        this.fullPath = res[0];
        this.refreshDriveSize.emit(res[1])
        return this.cloudDriveService.getFileByPath(id, this.page, this.cloudDriveService.pageSize, this.sortType)
      }),
      flatMap((res) => {
        if (type === 'append') {
          this.fileArr = this.fileArr.concat(res);
        } else {
          this.fileArr = res;
        }
  
        // 路径改变需重新初始化绑定自定义属性
        _.forEach(res, (item: any) => {
          if (type === 'append' && this.checkAll) {
            if(this.isPrepareLesson){
              if(item.itemType != 'dir') {
                item.$$checked = true;
              }
            } else {
              item.$$checked = true;
            }
          } else {
            item.$$checked = false;
          }
          item.$$canEditName = false;
          if (item.itemType === 'dir') {
            item.originSize = 0;
          }
        });
  
        this.selectTotalNum = this.fileArr.filter((r) => {return r.$$checked}).length;
        // (拿文件信息)
        const needHandleArr = _.filter(this.fileArr, (data: any) => {
          return data.itemType === 'file';
        });
        
        if (!_.isEmpty(needHandleArr)) {
          return forkJoin(_.map(needHandleArr, (r: any) => {return this.resourceService.getResourceInfoByUuid(r.resourceUuid)}))
        } else {
          return of([])
        }
      }),
      flatMap((reses) => {
        if (!_.isEmpty(reses)) {
          const needHandleArr: any = _.filter(this.fileArr,(item: any) => {return item.itemType === 'file'})
          _.zipWith(needHandleArr, reses, (a: any, b: any) => {
            a.suffix = b.suffix
            a.size = this.cloudDriveService.bytesToSize(b.size)
            a.originSize = b.size
            return a
          })
        }
        this.canAppend = true;
        return of([])
      })
    )
  }
  
  private hasBigFile(files){
    for(const file of files){
      if(file.size >= BIG_FILE_SIZE){
        return true;
      }
    }
    return false;
  }
}
