import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TutorialComponent } from './tutorial/tutorial.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

export const postLoginStates = [{
  name: 'tutorial',
  url: '/anno/tutorial',
  bodyStyle: 'newstyle',
  component: TutorialComponent,
}]
