require('assets/app/controllers/examination/examPaper/examPaper.scss');
require('assets/app/controllers/examination/examSummary/examSummaryCtrl');
require('assets/app/controllers/examination/examNav/examNavCtrl');
require('assets/app/controllers/examination/examPartsEditor/examPartsEditorCtrl');
require('assets/app/controllers/examination/dialog/deletePartCtrl');
require('assets/app/controllers/examination/examEdit/statistics/examEditStatCtrl');
require('assets/app/controllers/examination/examEdit/recommend/examEditRecommendCtrl');
require('assets/app/controllers/examination/examEdit/paper/examEditPaperCtrl');
require('assets/app/controllers/examination/examNm/examNmCtrl');
import { getRyKnowledget } from "app2/integration/ruanyun/ruanyunUtils";

import * as _ from 'lodash'
import { createQuesUuidForExam, willSaveExam, checkExamTable, checkCanSaveImpl } from '../examUtils';

angular.module('app.examination.examPaper', [
  'app.examination.editObjectTestPaper.examSummary',
  'app.examination.editObjectTestPaper.examNav',
  'app.examination.editObjectTestPaper.examPartsEditor',
  'app.examination.editObjectTestPaper.deletePart',
  'app.examination.examEdit.statistics',
  'app.examination.examEdit.paper',
  'app.examination.examEdit.recommend',
  'app.examination.examEdit.examNm',
]).config(['$stateProvider', ($stateProvider) => {
  $stateProvider.state('examPaper', {
    url: '/examPaper?coursepathId&sectionId&sectionParentId&examId&sectionRootId&levelId&relId',
    template: require('assets/app/controllers/examination/examPaper/examPaper.html'),
    controller: 'examPaper',
    loadingCls: 'blueloading',
    bodyStyle: 'bged text-hidden'
  });
}]).controller('examPaper', examPaper);

export enum ExamEditPage { GroupPage, RecommendPage }

examPaper.$inject = ['$stateParams', '$scope', 'oedExamination', '$location', '$anchorScroll',
  'userInfo', 'notificationService', 'oedUnitItem', 'ruanyunResource', '$state',
  '$uibModal', 'examApi'
]
function examPaper($stateParams, $scope, oedExamination, $location, $anchorScroll,
  userInfo, notificationService, oedUnitItem, ruanyunResource, $state,
  $uibModal, examApi
) {
  $scope.isExamUpdate = false
  $scope.currentPage = ExamEditPage.RecommendPage;
  $scope.coursepathId = $stateParams.coursepathId;
  // 如果没有sectionParentId，则统一使用sectionId
  $scope.sectionParentId = _.isEmpty($stateParams.sectionParentId) ? $stateParams.sectionId : $stateParams.sectionParentId;
  $scope.sectionId = $stateParams.sectionId;
  $scope.examId = $stateParams.examId;
  $scope.sectionRootId = $stateParams.sectionRootId
  $scope.levelId = $stateParams.levelId
  $scope.relId = $stateParams.relId

  $scope.loading = oedExamination.getCoursePath({ id: $scope.coursepathId }).$promise
    .then((coursepath) => {
      if (_.isEmpty(coursepath) || !coursepath.gradeId || !coursepath.subjectId || !coursepath.versionId) {
        notificationService.notify('error', '获取章节信息失败');
        return
      }
      getRyKnowledget(examApi, coursepath.subjectId, $scope.levelId).then((kps) => {
        $scope.knowledgePoints = kps
        $scope.updateCurExamById($scope.examId, false)
      })
    })

  $scope.saveExamTest = () => {
    willSaveExam($scope.examData, notificationService, $uibModal, oedExamination, $scope.loading, (data) => {
      const {examId, examData} = data
      $scope.examId = examId
      $scope.examData = examData
      $scope.isShowTab = false
      $scope.isShowRecommend = false
    })
  }

  $scope.init = function () {
    $location.hash(`exam_paper_header`);
    $anchorScroll()
    loadSectionParent()
    loadCanEdit()
  };
  $scope.init();

  function loadCanEdit() {
    $scope.loading = oedExamination.getExamSessions({ examId: $scope.examId }).$promise
      .then(sessions => $scope.isCanEdit = _.size(sessions) === 0)
  }

  function loadSectionParent() {
    $scope.loading = userInfo.then((info) => {
      $scope.info = info;
      $scope.isResearcher = _.findIndex(_.get(info, 'roles', []), (r) => { return r === 'researcher' }) !== -1;
      oedUnitItem.get({ id: $scope.sectionParentId }).$promise
        .then((sectionParent: any) => {
          // 考试获取题目的信息统一用sectionParent来获取
          $scope.sectionParentName = _.get(sectionParent, 'name', '')
          oedUnitItem.get({ id: $scope.sectionId }).$promise
            .then((seciton: any) => {
              $scope.examName = _.get(seciton, 'name', '')
            })
        })
    })
  }

  $scope.updateExamDataByExam = (examData: any) => {
    $scope.examData = examData
  }

  $scope.updateVisibleLetters = (letters) => {
    $scope.letters = letters
    $scope.currentTag = []
    $scope.currentNm = []
    if (_.size(letters) > 0) {
      $scope.previous = $scope.letters
    }
    if (_.size($scope.previous) > 0) {
      $scope.currentTag = _.split($scope.previous[0], '_')[1]
      $scope.currentNm = _.split($scope.previous[0], '_')[2]
    }
  }

  $scope.updateCurExamById = (id: number) => {
    $scope.loading = oedExamination.getExamTestWhole({ examId: id }).$promise
      .then((res) => {
        $scope.examId = _.get(res, 'examWithTags.exam.id', '')
        $scope.examData = createQuesUuidForExam(res)
      })
  }

  $scope.toggleCurrentPage = (page: number) => {
    $scope.currentPage = page
  }

  $scope.onExamIsUpdate = (update: boolean) => {
    $scope.isExamUpdate = update
  }

  $scope.onJumpToPage = (page: string) => {
    const r = checkCanSaveImpl($scope.examData, notificationService)
    if (!r) {
      return
    }
    $state.go(page)
  }

  $scope.onBackToPrePage = () => {
    checkExamTable($scope.examData, $uibModal).then(() => {
      $scope.onJumpToPage('prepareLesson')
    })
  }
}
