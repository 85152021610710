/**
 * @fileOverview
 * @name oedNewSearchBar.directive.ts
 */
require('assets/styles/cloud.scss')

angular.module('app.directives.cloud.oedNewSearchBar', [])
.component('oedNewSearchBar', {
  bindings: {
    doSearch: '&',
    queryText: '<',
  },
  controller: oedNewSearchBar,
  template: <string> require('./oedNewSearchBar.html'),
})
oedNewSearchBar.$inject = []
function oedNewSearchBar() {
  this.queryText = ''
  this.$onChanges = (changeObj: any) => {
    if (_.has(changeObj, 'queryText') && !_.get(changeObj, 'queryText.currentValue') && !_.isEmpty(_.get(changeObj, 'queryText.previousValue'))) {
      this.queryText = ''
    }
  }
}
