import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';

import * as _ from 'lodash'

import { getChoiceName } from 'app2/utils/testUtils2'
import { LegacyAppService } from 'app/core/legacy-app.service';
@Component({
  selector: 'app-vote-designer',
  template: require('./vote-designer.component.html'),
  styles: [require('./vote-designer.component.scss')]
})
export class VoteDesignerComponent implements OnInit {

  @Input() content;
  @Input() editorConfig;

  @Output() ngModelChange = new EventEmitter<any>()
  @Output() addChoice = new EventEmitter<boolean>()
  @Output() showResAndDelClick = new EventEmitter<boolean>()

  inEditor = false;
  curIndex = -1
  private dialogs: any

  constructor(legacyApp: LegacyAppService) {
    this.dialogs = legacyApp.getDialogs()
  }

  ngOnInit() {
  }

  ngOnChanges(changeObj: SimpleChange) {
  }

  public getChoiceName(idx: number) {
    return getChoiceName(idx)
  }

  public isAnswer(idx: number) {
    return this.content.answer === '' + idx;
  }

  public setAnswer(idx: number) {
    this.content.answer = '' + idx;
  }

  public doRemoveChoice(idx: number) {
    this.content.choices.splice(idx, 1);
    if (!_.isUndefined(this.content.answer) &&
      this.content.answer >= _.size(this.content.choices)) {
        this.content.answer = _.toString(_.size(this.content.choices) - 1);
      if (this.content.answer < 0) {
        this.content.answer = undefined;
      }
    }
  }

  public removeChoice(idx: number) {
    if (!this.content.choices[idx].content) {
      this.doRemoveChoice(idx);
    } else {
      const dlg = this.dialogs.confirm('确定删除？', '此选项已设置内容，真的要删除吗？');
      dlg.result.then((btn) => {
        this.doRemoveChoice(idx);
      }, (btn) => { });
    }
  }

  public enterEditor(index) {
    this.curIndex = index;
    this.inEditor = true;
  }
  public leaveEditor() {
    this.inEditor = false;
  }

  public onAddChoice() {
    this.addChoice.emit(true)
    return false
  }

  public onShowResAndDel(event: MouseEvent) {
    event.preventDefault()
    event.stopPropagation()
    this.showResAndDelClick.emit(true)
  }
}
