import { NgModule, NgZone } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { UpgradeModule } from '@angular/upgrade/static';

import { UIRouter, UrlService } from '@uirouter/core';

import { AppModule } from './app.module'

import { downgradeModules } from './downgrades'

downgradeModules ()

platformBrowserDynamic().bootstrapModule(AppModule).then((platformRef) => {
  const urlService: UrlService = platformRef.injector.get(UIRouter).urlService;
  function startUIRouter() {
    urlService.listen()
    urlService.sync();
  }
  const ngZone: NgZone = platformRef.injector.get(NgZone);
  ngZone.run(startUIRouter);
})
