(function() {
  angular.module('app.directives')
    .directive('styleToClientHeight', function() {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.css(attrs.cssType, document.documentElement.clientHeight - attrs.relativeToDocument);
          scope.$on('resize', function() {
            element.css(attrs.cssType, document.documentElement.clientHeight - attrs.relativeToDocument);
          });
        },
      };
    });
})();
