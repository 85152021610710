import * as _ from 'lodash'
require('assets/app/controllers/examination/examEdit/recommend/examEditRecommend.scss')
import { checkExamIsSaved, openExamConfirmDialog } from 'assets/app/controllers/examination/examEdit/paper/paperUtils'

angular.module('app.examination.examEdit.recommend', [])
  .component('examEditRecommend', {
    bindings: {
      examData: '<',
      sectionId: '<',
      toggleCurrentPage: '&',
      toggleExamTest: '&',
      onCheckCurExamDeleted: '&',
      saveExamTest: '&',
      onCreatGroupPaper: '&',
      isRecommend: '<',
    },
    controller: examEditRecommendCtrl,
    template: <string>require('./examEditRecommend.html'),
  })

examEditRecommendCtrl.$inject = ['oedExamination', 'dialogs', 'notificationService', '$uibModal']
function examEditRecommendCtrl(oedExamination, dialogs, notificationService, $uibModal) {

  const ctrl = this
  /*
   *  isInit变量存在的原因是
   *  1、未组卷时，进到编辑界面，默认选中列表中第一个试卷
   *  2、删除当前选中的试卷时，需要置空统计和编辑模块
   *  注：目前首次进入和非首次进入行为不统一，所以增加isInit来判断，后续如果统一显示或者不显示则可去除
   */
  ctrl.isInit = false
  ctrl.$onInit = () => {
    ctrl.isInit = true
    loadingData()
  };

  ctrl.$onChanges = (changeObj: any) => {
    if (_.has(changeObj, 'examData')) {
      ctrl.isInit = false
      ctrl.examId = _.get(changeObj, 'examData.currentValue.examWithTags.exam.id', '')
      ctrl.examName = _.get(changeObj, 'examData.currentValue.examWithTags.exam.name', '')
      const preExamId = _.get(changeObj, 'examData.previousValue.examWithTags.exam.id', '')
      const curExamName = _.get(changeObj, 'examData.currentValue.examWithTags.exam.name', '')
      const preExamName = _.get(changeObj, 'examData.previousValue.examWithTags.exam.name', '')
      ctrl.isSaved = checkExamIsSaved(ctrl.examData)
      if ((ctrl.examId != '' && ctrl.examId != preExamId) || curExamName != preExamName) {
        loadingData()
      }
    }
  };

  ctrl.recommendList = []
  function loadingData() {
    ctrl.loading = oedExamination.getRecommendList({ sectionId: ctrl.sectionId }).$promise
      .then((res) => {
        ctrl.recommendList = onNameDeduplication(res)
        loadDefaultRes()
      })
  }

  function loadDefaultRes() {
    if (_.isEmpty(ctrl.examData) && ctrl.isInit) {
      // 未组卷时，默认选中列表中第一个试卷
      const examId = _.get(ctrl.recommendList, '0.id', -1)
      if (examId == -1) {
        return
      }
      ctrl.toggleExamTest({ id: examId })
    }
  }

  function onNameDeduplication(data: any[]) {
    //为列表中同名的试卷名称按顺序加上数据
    const tempNameMap = {}
    _.forEach(data, r => {
      const curName = _.get(r, 'name', '')
      const curNameIdx = _.get(tempNameMap, curName, 0)
      if (curNameIdx == 0) {
        tempNameMap[curName] = 1
      } else {
        tempNameMap[curName] = curNameIdx + 1
        _.set(r, 'name', `${curName}${curNameIdx}`)
      }
    })
    return data
  }

  ctrl.isSelect = (e: any) => {
    const curResId = _.get(e, 'id', '')
    const createTime = _.get(e, 'createTime', 0)
    const curCreateTime = _.get(ctrl.examData, 'examWithTags.exam.createTime', 0)
    if (_.isEmpty(ctrl.examData) && ctrl.isInit) {
      // 未组卷时，默认选中列表中第一个试卷
      return curResId == _.get(ctrl.recommendList, '0.id', -1)
    }
    return (curResId == ctrl.examId) || (createTime != 0 && createTime === curCreateTime)
  }

  ctrl.onRecommendItem = (index: number) => {
    const examId = _.get(ctrl.recommendList, `${index}.id`, -1)
    if (examId == ctrl.examId) {
      return
    }
    ctrl.toggleExamTest({ id: examId })
  }

  ctrl.onItemDelete = (index: number) => {
    event.stopPropagation();
    const onYes = () => {
      const examId = _.get(ctrl.recommendList, `${index}.id`, -1)
      ctrl.loading = oedExamination.deleteExamTest({ examId: examId }).$promise
        .then((res) => {
          ctrl.onCheckCurExamDeleted({ examList: ctrl.recommendList, index: index })
          loadingData()
          notificationService.notify('info', '已将试卷移入回收站')
        })
    }
    openExamConfirmDialog($uibModal, '是否将该试卷移入回收站？', '删除试卷', '是', '否', onYes)
  }
}


