import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {RayApiService} from 'app/core/ray-api.service';
import {Observable} from 'rxjs';
import {SchoolNewPrepareLessonState, UserNewPrepareLessonState} from 'app/lesson/models/upgrade';

@Injectable({
  providedIn: 'root'
})
export class PreferenceService {

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService) { }

  public getUserNewPrepareLessonState(uid: string): Observable<UserNewPrepareLessonState> {
    return this.httpClient.get<UserNewPrepareLessonState>(
      this.rayApi.apiB(`userPreference/uid/${uid}/newPrepareLessonState`))
  }

  public updateUserNewPrepareLessonState(state: UserNewPrepareLessonState): Observable<UserNewPrepareLessonState> {
    return this.httpClient.post<UserNewPrepareLessonState>(
      this.rayApi.apiB(`userPreference/updateNewPrepareLessonState`), state)
  }

  public getSchoolNewPrepareLessonState(schoolId: string): Observable<SchoolNewPrepareLessonState> {
    return this.httpClient.get<SchoolNewPrepareLessonState>(
      this.rayApi.apiB(`schoolPreference/schoolId/${schoolId}`))
  }

  public updateSchoolNewPrepareLessonState(state: SchoolNewPrepareLessonState): Observable<void> {
    return this.httpClient.post<void>(
      this.rayApi.apiB('schoolPreference/prepareLessonState'), state)
  }
}
