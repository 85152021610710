import Promise = require('bluebird')
import $ = require('jquery')
import _ = require('lodash')

const blank = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'

export const convertQuesToQaType = (q: any) => {
  return {
    ...q,
    answer: '-1',
    type: 'qa',
    question: convertQuestionBody(q)
  }
}

const connectQuesExtrator = (q) => {
  const question = _.get(q, 'question', '')
  const leftOpt = _.get(q, 'leftOpts', [])
  const leftOptTemp = buildItems(leftOpt, false, true, 'left-opt-prefix')
  const rightOpt = _.get(q, 'rightOpts', [])
  const rightOptTemp = buildItems(rightOpt, true, true, 'right-opt-prefix')
  const stem = question + `<p>${blank}</p>` +
  `<div class="connect-container">${buildOptContainer('left-container margin-right-sm', '左侧', leftOptTemp)}
  ${buildOptContainer('right-container border-left padding-left-sm', '右侧', rightOptTemp)}</div>`
  return stem
}

const buildOptContainer = (cls, tagName, leftOptTemp) => {
  return `<div class="${cls}">
  <p>${tagName}</p>
  ${leftOptTemp}
  </div>`
}

const syntheticQuesExtrator = (q) => {
  const childQueses = _.get(q, 'childQuestions', [])
  const question = _.get(q, 'question', '')
  const pBlank = `<p>${blank}</p><p>${blank}</p><p>${blank}</p>`
  const childQuesStems = _.chain(childQueses).map(convertQuestionBody).join(pBlank).value()
  return question + pBlank + childQuesStems
}

const yesornoQuesExtractor = (q) => {
  const question = _.get(q, 'question', '')
  const choices = [{content: '<p>正确</p>'}, {content: '<p></p><p>错误</p>'}]
  return buildChoicesTemp(choices, question)
}

const choiceQuesExtractor = (q) => {
  const question = _.get(q, 'question', '')
  const choices = _.get(q, 'choices', [])
  return buildChoicesTemp(choices, question)
}

const buildChoicesTemp = (choices, stem) => {
  const choiceTemple = buildItems(choices)
  return $('<div></div>').wrap('<p/>').append(stem).append(`<p>${blank}</p>`).append(choiceTemple).parent().html()
}

const buildItems = (choices, digitLabel: any = false, hideRadio: any = false, prefix: any = undefined) => {
  const choiceTemps = []
  _.forIn(choices, (v, k) => {
    const lable = digitLabel ? parseInt(k, 10) + 1 : buildCharcode(parseInt(k, 10))
    const pClass = _.isUndefined(prefix) ? undefined : prefix + '-' + parseInt(k, 10)
    choiceTemps.push(buildChoiceTemp(lable, _.get(v, 'content', ''), hideRadio, pClass))
  })
  return `${_.join(choiceTemps, '')}`
}

const buildCharcode = (position) => {
  return String.fromCharCode(parseInt(position, 10) + 65)
}

const buildChoiceTemp = (label, content, hideRadio: any = false, prefix: any = undefined) => {
  const radio = hideRadio ? '' : '<input type="radio" class="pull-left margin-right-sm"/>'
  let $contentStr = $('<div></div>').append(content).html()
  $contentStr = trimEmptyPreTagP($contentStr)
  $contentStr = addLableInOneLine(label, $contentStr, radio, prefix)
  return $contentStr
}

const addLableInOneLine = (label, content, radio, prefix: any = undefined) => {
  prefix = _.isUndefined(prefix) ? '' : prefix
  if (_.startsWith(content, '<p>')) {
    content = $(content).prepend('&nbsp;&nbsp;').prepend(label)
    .prepend(radio).addClass(prefix).wrap('<p/>').parent().html()
  } else {
    content = $(content).wrap('<p/>').wrap('<p/>').parent().prepend('&nbsp;&nbsp;')
    .prepend(label).prepend(radio).addClass(prefix).parent().html()
  }
  return content
}

const trimEmptyPreTagP = (content) => {
  if (_.startsWith(content, '<p></p>')) {
    content = _.replace(content, '<p></p>', '')
  }
  return content
}

const simpleQuesExtractor = (q) => {
  return _.get(q, 'question', '');
}

const convertQuestionBody = (q) => {
  const type = _.get(q, 'type', '')
  let convert: any
  if (_.has(convertConfig, type)) {
    convert = _.get(convertConfig, type)
  } else {
    convert = simpleQuesExtractor
  }
  return convert(q)
}

const convertConfig = {
  singlechoice: choiceQuesExtractor,
  qa: simpleQuesExtractor,
  yesorno: yesornoQuesExtractor,
  multichoice: choiceQuesExtractor,
  fill: simpleQuesExtractor,
  singlevote: choiceQuesExtractor,
  multivote: choiceQuesExtractor,
  synthetic: syntheticQuesExtrator,
  connect: connectQuesExtrator,
}
