import * as angular from 'angular';
import * as _ from 'lodash';
const preResViewLogUtils: any = require('app2/utils/preResViewLogUtils');

angular.module('app.directives.review.preres.preresCompletionDetails', [])
.component('preresCompletionDetails', {
  bindings: {
    classId: '<',
    classSessionId: '<',
    fromClassSession: '<',
    isPreresItemSession: '<',
    preResId: '<',
    preResSession: '<',
    preResSessionId: '<',
    publishRecordId: '<',
    pubStudentIdSet: '<',
  },
  controller: preresCompletionDetailsCtrl,
  template: <string> require('./preresCompletionDetails.html'),
});

preresCompletionDetailsCtrl.$inject = ['oedTaskListPublishRecord', 'oedViewLog', 'oedPreResSessionStudent', '$uibModal', '$q', 'oedClassUser', 'oedUserInfo', 'oedPreResItemSession']
function preresCompletionDetailsCtrl(
  oedTaskListPublishRecord: any, oedViewLog: any,
  oedPreResSessionStudent: any, $uibModal: any,
  $q: any, oedClassUser: any, oedUserInfo: any,
  oedPreResItemSession: any) {
  const ctrl = this;

  const queryClassStds = () => {
    return oedClassUser.queryClassStudents({classId: ctrl.classId})
  }

  const queryItemSessionStds = () => {
    return oedPreResItemSession.queryByClassSessionId({
      classSessionId: ctrl.classSessionId,
      preResItemSessionId: ctrl.preResSessionId,
    })
  }
  const queryPreresSessionStds = () => {
    return oedPreResSessionStudent.queryByPreResSessionId({
      sessionId: ctrl.preResSessionId,
    })
  }
  const loadStudents = () => {
    ctrl.students = ctrl.isPreresItemSession ? queryItemSessionStds() : queryPreresSessionStds()
    ctrl.classStds = queryClassStds()
    return $q.all([ctrl.students.$promise, ctrl.classStds.$promise]).then(() => {
      const partOnlineStds = _.partition(ctrl.students, 'wasOnline')
      ctrl.wasOnlineStds = partOnlineStds[0]
      ctrl.unJoinStds = partOnlineStds[1]
    })
  }

  this.$onInit = () => {
    ctrl.loading = loadStudents().then(() => {
      return $q.all([loadSubmitStudents(), loadPreResViewLogBySessionId()])
    }).then(() => {
      partitionStudents()
      calculateStdDuration()
    });
  }

  function loadPreResViewLogBySessionId() {
    return oedViewLog.queryViewLogBySessionId({
      sessionId: ctrl.preResSessionId,
    }).$promise.then((viewLogs: any) => {
      ctrl.viewLogsByUid = _.groupBy(viewLogs, 'uid');
    });
  }

  function loadSubmitStudents() {
    const map = _.keyBy(ctrl.students, 'studentId');
    const mapClassStdsByUid: any = _.keyBy(ctrl.classStds, 'uid');
    return $q.all(_.map(ctrl.students, (std: any) => {
      std.uid = std.studentId;
      if (_.get(map[std.uid], 'submitTime', 0) !== 0) {
        std.submitProgress = '100';
      }
      if (_.has(mapClassStdsByUid, std.studentId)) {
        std.name = mapClassStdsByUid[std.studentId].name;
        std.gender = mapClassStdsByUid[std.studentId].gender;
      } else { // 该学生可能已经被移出班级
        return oedUserInfo.getByUid({
          id: std.uid,
        }).$promise.then((info: any) => {
          std.name = info.name
          std.gender = info.gender
        })
      }
    }))
  }

  function calculateStdDuration() {
    _.each(ctrl.studentsOfFinish, (std: any) => {
      if (ctrl.isPreresItemSession) {
        std.duration = preResViewLogUtils.timeDiff(ctrl.preResSession);
      } else {
        std.duration = preResViewLogUtils.minutesOnSession(ctrl.viewLogsByUid[std.uid]);
      }
    })
  }

  function filterPubStudents() {
    return _.filter(ctrl.students, (s) => _.includes(ctrl.pubStudentIdSet, s.studentId))
  }

  function partitionStudents() {
    if (ctrl.isPreresItemSession) {
      ctrl.studentsOfFinish = ctrl.wasOnlineStds
      ctrl.studentsOfUnfinish = []
      ctrl.studentsOfUnJoin = ctrl.unJoinStds
    } else {
      const stdsToPart = ctrl.fromClassSession ? ctrl.wasOnlineStds : filterPubStudents()
      const part = _.partition(stdsToPart, partStds)
      ctrl.studentsOfFinish = part[0]
      ctrl.studentsOfUnfinish = part[1]
      ctrl.studentsOfUnJoin = ctrl.unJoinStds
    }
  }

  function partStds(std: any) {
    if (ctrl.fromClassSession) {
      return _.has(ctrl.viewLogsByUid, std.uid);
    } else {
      return std.submitProgress && std.submitProgress === '100';
    }
  }

  ctrl.showDetailsForStudent = (std: any) => {
    $uibModal.open({
      controller: 'preResReviewStudentDetailsCtrl',
      resolve: {
        data: () => {
          return {
            isPreresItemSession: ctrl.isPreresItemSession,
            preResId: ctrl.preResId,
            preResSession: ctrl.preResSession,
            preResSessionId: ctrl.preResSessionId,
            student: std,
          };
        },
      },
      size: 'lg',
      template: require('assets/app2/resource/preResReviewStudentDetails.html'),
      windowClass: 'oedmodal modalCenter',
    });
  }

}
