import * as _ from 'lodash'
require('assets/app/controllers/examination/examQuesNav/examQuesNav.scss')
import { getExamQuesTotal, convertQuesNumber } from '../examEdit/paper/paperUtils';

angular.module('app.examination.examQuesNav', [])
  .component('examQuesNav', {
    bindings: {
      examPartDetails: '<',
      currentTag: '<',
      currentNm: '<',
      onClickQues: '&',
      class: '@',
    },
    controller: examQuesNavCtrl,
    template: <string>require('./examQuesNav.html'),
  })

examQuesNavCtrl.$inject = []
function examQuesNavCtrl() {
  const ctrl = this;

  ctrl.getQuesNumber = (outerIndex, index) => {
    return convertQuesNumber(ctrl.examPartDetails, outerIndex, index)
  }

  ctrl.onClickQuestion = (part, ques) => {
    if (_.size(ques.answeredStd)) {
      ctrl.onClickQues({outerIndex: part.indexFilter, index: ques.indexFilter })
    }
  }

  this.partIndex = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十']

  ctrl.getPartTitle = (index: number) => {
    const part = ctrl.examPartDetails[index]
    const partIndex = this.partIndex[part.indexBase]
    const partTypeText = _.get(part, 'part.name', '')
    return `${partIndex}、${partTypeText}\xa0（共${_.size(_.get(part, 'questionDetails', []))}小题，总分${getExamQuesTotal(ctrl.examPartDetails, index)}分）`
  }

}
