import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NotificationService} from 'app/shared/notification/notification.service';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import * as _ from 'lodash';
import {OnlineTrainingDirectoryDTO} from 'app/online-train/models/online-train';
import {HttpEventType, HttpRequest} from '@angular/common/http';
import {TrainMassageService} from 'app/online-train/train-massage.service';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-train-content-editor-component',
  template: require('./train-content-editor-component.component.html'),
  styles: [require('./train-content-editor-component.component.scss')]
})
export class TrainContentEditorComponentComponent implements OnInit {
  @Input() public itemData
  @Input() public itemType
  @Input() public editType
  @Output() public action = new EventEmitter<any>()

  private title
  private dataClone
  private progress = 0
  private files = []
  private fileNumText = ''
  private resources
  constructor(public bsModalRef: BsModalRef,
              private notifyService: NotificationService,
              private onlineTrainService: OnlineTrainService,
              private trainMessage: TrainMassageService) { }

  ngOnInit() {
    this.setTitle()
    this.setDataClone()
  }

  public close() {
    this.bsModalRef.hide()
  }

  public ok() {
    if (_.size(this.files) > 20) {
      this.notifyService.notify('info', '一次最多上传20个文件')
      return
    }
    if (this.progress > 0) {
      this.notifyService.notify('info', '文件正在上传中')
      return
    }
    if (_.isEmpty(this.dataClone.name)) {
      this.notifyService.notify('info', '标题不能为空')
      return
    }

    if (this.editType === this.onlineTrainService.ADD) {
      const resources = _(this.files).filter((f: any) => !_.isEmpty(f.$$resource) && f.$$resource.id > 0)
        .map((f) => f.$$resource).value()
      let sort = this.dataClone.sort
      const items = _.map(resources, (r) => {
        const item = _.clone(this.dataClone)
        item.resourceId = r.id
        item.sort = sort++
        return item
      })
      this.action.emit(items)
    } else {
      const resources = _(this.files).filter((f: any) => !_.isEmpty(f.$$resource) && f.$$resource.id > 0)
        .map((f) => f.$$resource).value()
      if (!_.isEmpty(resources)) {
        this.dataClone.resourceId = resources[0].id
      }

      this.action.emit(this.dataClone)
    }

    this.bsModalRef.hide()
  }

  public fileChange(event) {
    if (_.size(event.target.files) > 20) {
      this.notifyService.notify('info', '一次最多上传20个文件')
      return
    }

    this.files = _.sortBy(event.target.files, (f) => this.extractFileNum(f.name))
    const req = _.map(this.files, (f) => {
      const formData = new FormData();
      formData.append('file', f);
      return this.onlineTrainService.uploadFile(formData).pipe(map((res) => {
        if (res && res.body && res.body.id && res.body.filepath) {
          f.$$resource = res.body
        }
      }))
    })
    this.trainMessage.sendLoadingAction(true)
    forkJoin(...req).subscribe(() => {
      console.log(this.files)
    }, (err) => {console.log(err)}, () => this.trainMessage.sendLoadingAction(false))
  }

  public getFileNumText() {
    return _.size(this.files) > 0 ? `--共${_.size(this.files)}个文件` : ''
  }

  public removeFile(file) {
    this.files = _.filter(this.files, (f) => f !== file)
  }

  public typeContent1() {
    return this.itemType === this.onlineTrainService.CONTENT1
            || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO
  }

  public typeGoodCourseVideo() {
    return this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO
  }

  public typeContent2() {
    return this.itemType === this.onlineTrainService.CONTENT2
            || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_OTHER
  }

  public typeAdd() {
    return this.editType === this.onlineTrainService.ADD
  }

  public typeContentQa() {
    return this.itemType === this.onlineTrainService.CONTENTQA
  }

  private setTitle() {
    this.title = '新增资源'
  }

  private setDataClone() {
    this.dataClone = _.clone(this.itemData)
  }

  private extractFileNum(str) {
    const idxOfDot = str.indexOf('.')
    let name = str
    if (idxOfDot > 0) {
      name = str.substring(0, idxOfDot)

      let i = name.length - 1
      let num = ''
      for (; i >= 0; i--) {
        if (name.charAt(i) >= '0' && name.charAt(i) <= '9') {
          num += name.charAt(i)
        } else {
          break;
        }
      }
      return _.parseInt(num.split('').reverse().join(''))
    }
    return 0
  }
}
