import * as angular from 'angular';

angular.module('app.prepareCourse2.warningDialog',
[]).component('warningDialog', {
  bindings: {
    modalInstance: '<',
  },
  controller: warningDialogCtrl,
  template: <string> require('./warningDialog.html'),
})

function warningDialogCtrl() {
  this.confirmClick = () => {
    this.modalInstance.close('save');
  }

  this.cancelClick = () => {
    this.modalInstance.close('discarded');
  }
}
