/**
 * @fileOverview review页面的课程列表
 * @name coursePathsCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('review', {
        url: '/review',
        template: require('assets/templates/review/coursePaths.html'),
        controller: 'coursePathsCtrl',
        bodyStyle: 'bged2'
      });
    }])
    .controller('coursePathsCtrl', coursePathsCtrl);

  coursePathsCtrl.$inject = ['$scope', '$state', '$uibModal', '$animate', '$q', 'dialogs', 'resize',
    'userInfo', 'oedCoursePath', 'oedCourse', 'localStorageService', '$window']
  function coursePathsCtrl($scope, $state, $uibModal, $animate, $q, dialogs, resize,
    userInfo, oedCoursePath, oedCourse, localStorageService, $window) {
    $scope.showCompleted = false;
    $scope.status = {
      $initLoad: true,
      $jump: true,
    };
    // 判断顶层窗口是否为iframe(配合桌面端使用)
    $scope.topWindowIsIframe = $window.top === $window.self;
    if (process.env.NAME === 'beta') {
      $scope.showGoBigData = false;
    } else {
      $scope.showGoBigData = true;
    }
    $scope.$on('$destroy', function() {
      $scope.isDone = true;
    });

    function updateCurrentCoursePath(replace) {
      var curCpId = $state.params.cpId || localStorageService.get('lastReviewCoursePathId');
      $scope.loading = $scope.coursePaths.$promise.then(function(cp) {
        $scope.loadingCp = false;
        var curCp = null;
        if (!_.isEmpty($scope.coursePaths)) {
          curCp = curCpId ? _.find($scope.coursePaths, function(cp) {
            return cp.id == curCpId;
          }) : $scope.coursePaths[0];
          curCp = curCp || $scope.coursePaths[0];
        }
        if (!$scope.status.$initLoad || ($scope.status.$initLoad && $scope.status.$jump)) {
          $scope.setCurrentCP(curCp, replace);
          $scope.status.$initLoad = false
          $scope.status.$jump = false
        }
      });
    }

    userInfo.then(function(info) {
      $scope.userInfo = info;
      function updateCoursePath() {
        $scope.loadingCp = true;
        $scope.coursePaths = oedCoursePath.queryFilter({
          userId: _.parseInt(info.uid),
          onGoing: true,
          unStarted: false,
          ended: $scope.showCompleted
        });

        updateCurrentCoursePath(true);
        return $scope.coursePaths.$promise;
      }

      $scope.loading = $q(function(resolve, reject) {
        userInfo.then(function(info) {
          updateCoursePath().finally(resolve);
        });
      });
    });

    $scope.setCurrentCP = function(cp, replace) {
      if ($scope.isDone) {
        return;
      }

      var cpId = cp ? cp.id : null;

      var opt = replace ? {
        location: 'replace'
      } : null;

      $scope.labelIndex = _.findIndex($scope.coursePaths, function(cp) {
        return cp.id == cpId;
      });
      ensureLabelVisibiltiy();
      setSwiperSideBtnVisible($scope.labelIndex);

      $state.go('review.classSessions', {
        cpId: cpId
      }, opt);
    };

    $scope.getSectionNames = function(secs) {
      return _.map(secs, function(sec) {
        return sec.sectionName;
      }).join(',');
    };

    //增加coursePaths的滚动
    $scope.labelIndex = 0;
    var labelWidth = 197; // 每个标签的宽度 每次要移动的宽度为197px

    $scope.getLableListStyle = function() {
      var width2 = _.isEmpty($scope.coursePaths) ? '1200' : (_.size($scope.coursePaths) * labelWidth);
      if (width2 < 1200)
        width2 = 1200;
      return {
        width: '' + width2 + 'px'
      };
    };

    $scope.getLableStyle = function() {
      var labelOffset = '-' + ($scope.labelIndex * labelWidth) + 'px';
      return {
        left: labelOffset
      };
    };

    $scope.labelMaxCount = function() {
      var width2 = $('#gundong2').width();
      return Math.floor(width2 / labelWidth);
    };

    $scope.labelNext = function() {
      var labelCount = $scope.labelMaxCount();
      if ($scope.labelIndex + labelCount >= _.size($scope.coursePaths))
        return;
      $scope.labelIndex++;
    };

    $scope.labelPrev = function() {
      if ($scope.labelIndex <= 0) {
        return;
      } else {
        $scope.labelIndex--;
      }
    };
    $scope.swiper = {};

    function ensureLabelVisibiltiy() {
      if ($scope.labelIndex < 0) {
        $scope.labelIndex = 0;
      }

      var labelCount = $scope.labelMaxCount();
      if ($scope.labelIndex + labelCount >= _.size($scope.coursePaths)) {
        $scope.labelIndex = _.size($scope.coursePaths) - labelCount;
      }
    }

    $scope.currentPagerIndex = 0;
    $scope.displaySwiperSideLeft = false;
    $scope.displaySwiperSideRight = true;
    // $scope.noNeedDigest = false;
    $scope.swiperParams = {
      slidesPerView: 'auto',
      mousewheelControl: true,
      onSlideChangeStart: () => {

        $scope.currentPagerIndex = $scope.swiper.activeIndex;
        setSwiperSideBtnVisible($scope.currentPagerIndex);
        // if (!$scope.noNeedDigest) $scope.$digest();
        // $scope.noNeedDigest = false;
      }
    }

    function setSwiperSideBtnVisible(pageIndex) {
      if (isNaN(pageIndex)) return
      if (isNaN($scope.maxIndex)) {
        $scope.maxIndex = _.size($scope.coursePaths) - $scope.labelMaxCount();
      }
      if (pageIndex >= $scope.maxIndex) {
        $scope.displaySwiperSideRight = false;
      } else {
        $scope.displaySwiperSideRight = true;
      }
      if (pageIndex <= 0) {
        $scope.displaySwiperSideLeft = false;
      } else {
        $scope.displaySwiperSideLeft = true;
      }
    }
    $scope.scrollToTop = function() {
      // $scope.noNeedDigest = true;
      $scope.swiper.slideTo(0);
    }
    $scope.scrollToBottom = function() {
      // $scope.noNeedDigest = true;
      if (isNaN($scope.maxIndex)) {
        $scope.maxIndex = _.size($scope.coursePaths) - $scope.labelMaxCount();
      }
      $scope.swiper.slideTo($scope.maxIndex);
    }
    $scope.cantUpper = false;
    $scope.cantDowner = false;
    $scope.changingCourseOrder = 0;
    $scope.onDisplayOrderChange = function(index) {
      $scope.changingCourseOrder = index;
      if (index <= 0) {
        // $scope.changingCourseOrder = 0;
        $scope.cantUpper = true;
      } else {
        $scope.cantUpper = false;
      }
      if ((index + 1) >= _.size($scope.coursePaths)) {
        // $scope.changingCourseOrder = $scope.coursePaths - 1;
        $scope.cantDowner = true;
      } else {
        $scope.cantDowner = false;
      }
    };
    $scope.orderUpperOnce = function(cp, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if ($index <= 0) return;
      $scope.changingCourseOrder = $index;
      var newOrders = _.map($scope.coursePaths, function(c, index) {
        if (c.id == cp.id) {
          return _.assign(c, {sort: index * 2});
        }
        return _.assign(c, {sort: index * 2 + 3});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCoursePath.updateCoursePathOrder(items).$promise.then(() => {
        $scope.coursePaths = _.sortBy(newOrders, 'sort');
        // $scope.noNeedDigest = true;
        $scope.swiper.slidePrev();
        $scope.changingCourseOrder --;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    };
    $scope.orderDownOnce = function(cp, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if (($index + 1) >= _.size($scope.coursePaths)) return;
      $scope.changingCourseOrder = $index;
      var newOrders = _.map($scope.coursePaths, function(c, index) {
        if (c.id == cp.id) {
          return _.assign(c, {sort: index * 2 + 3});
        }
        return _.assign(c, {sort: index * 2});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCoursePath.updateCoursePathOrder(items).$promise.then(() => {
        $scope.coursePaths = _.sortBy(newOrders, 'sort');
        // $scope.noNeedDigest = true;
        $scope.swiper.slideNext();
        $scope.changingCourseOrder ++;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    };
    $scope.onChangeOrderToggle = function(open) {
      if (!open) {
        $scope.changingCourseOrder = -1;
      }
    };
  }
})();
