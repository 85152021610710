import * as _ from 'lodash'
require('assets/app/controllers/examination/examEdit/header/examEditHeader.scss');

angular.module('app.examination.examEdit.header', [])
  .component('examEditHeader', {
    bindings: {
      isShowTab: '<',
      examName: '<',
      onBackToPrePage: '&',
      toggleCurrentPage: '&',
    },
    controller: examEditHeaderCtrl,
    template: <string>require('./examEditHeader.html'),
  })

examEditHeaderCtrl.$inject = []
function examEditHeaderCtrl() {
  const ctrl = this
}
