import {Component, Input, OnInit} from '@angular/core';
import { StateService } from '@uirouter/core'
import * as _ from 'lodash';
import {UserInfoService} from 'app/core/user-info.service';
import {finalize} from 'rxjs/operators';
import {AppStateService} from 'app/core/app-state.service';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import {OnlineTrainingDirectoryDTO} from 'app/online-train/models/online-train';
import {flatMap} from 'rxjs/operators';
import {Subscription} from 'rxjs';
import {TrainMassageService} from 'app/online-train/train-massage.service';
import {LegacyAppService} from 'app/core/legacy-app.service';
import { getHttpCDN } from 'app2/utils/getHttpCDN';

@Component({
  selector: 'app-train-design',
  template: require('./train-design.component.html'),
  styles: [require('./train-design.component.scss')],
})
export class TrainDesignComponent implements OnInit {
  @Input() public params: any
  private dirSelectedId
  private userName
  private dirs: OnlineTrainingDirectoryDTO[]
  private dirSelected
  private editable = false
  private canStar = false
  private itemType
  private loading = false
  private subs = new Subscription()
  private links = [
    {
      label: '河南省教育厅',
      link: 'http://jyt.henan.gov.cn/'
    },
    {
      label: '山西省教育科学研究院',
      link: 'http://www.sxsjky.com/'
    },
    {
      label: '山西省教育厅',
      link: 'http://jyt.shanxi.gov.cn/'
    },
    {
      label: '河北省教育厅',
      link: 'http://jyt.hebei.gov.cn/'
    },
    {
      label: '山东省教育厅',
      link: 'http://edu.shandong.gov.cn/'
    },
    {
      label: '北京市教育委员会',
      link: 'http://jw.beijing.gov.cn/'
    },
    {
      label: '北京市教育科学研究院',
      link: 'http://www.bjesr.cn/'
    },
    {
      label: '四川省教育厅',
      link: 'http://edu.sc.gov.cn/'
    },
    {
      label: '广州市教育厅',
      link: 'http://edu.gd.gov.cn/'
    },
    {
      label: '辽宁省教育厅',
      link: 'http://jyt.ln.gov.cn/'
    },
    {
      label: '内蒙古自治区教育厅',
      link: 'http://jyt.nmg.gov.cn/'
    }
  ]
  private paperLinks = [
    {
      label: '中国知网',
      link: 'https://www.cnki.net/'
    },
    {
      label: '万方数据知识服务平台',
      link: 'http://www.wanfangdata.com.cn/index.html'
    },
    {
      label: '学术网',
      link: 'https://www.360xueshuwang.com/'
    },
  ]
  constructor(private state: StateService,
              private userInfo: AppStateService,
              private onlineTrainService: OnlineTrainService,
              private lagecyApp: LegacyAppService,
              private trainMessage: TrainMassageService) {
    const loadingSub = this.trainMessage.loadingAction$.subscribe((loading: boolean) => {
      this.loading = loading
    })
    this.subs.add(loadingSub)
  }

  ngOnInit() {
    this.dirSelectedId = this.getSelectedId()
    this.userInfo.then((info) => {
      this.userName = info.name
      this.canStar = info.canStar
    })

    this.loadData()
  }

  public ngOnDestroy() {
    this.subs.unsubscribe()
  }

  public goto(id) {
    this.dirSelected = _.find(this.dirs, (dir) => dir.id === id)
    const metaData = _.find(this.onlineTrainService.lvl1MetaDatas, (data) => data.id === id)

    this.state.go(`onlineTrain.${metaData.prefix}`, {
      parentId: id,
      editable: this.editable,
      dirs: this.dirs,
      prefix: metaData.prefix
    });
  }

  public logout() {
    this.state.go('signout')
  }

  public showEditBtn() {
    return this.canStar
  }

  public toggleEdit() {
    this.editable = !this.editable
    this.goto(this.dirSelected.id)
  }

  public getDataToAdd() {
    return {
      dirId: this.dirSelected.dirId,
      name: '',
      sort: 0,
      deleted: false
    }
  }

  public downloadFile() {
    let name = '智慧课堂使用教程.docx'
    name = this.b64EncodeUnicode(encodeURIComponent(name))
    const httpCDN = getHttpCDN();
    let url = getHttpCDN(`/ray/resource/uuid/ee4f8621f83822741f61ecf0d2af1777/raw64/` + name);

    if (this.lagecyApp.getOem().curOem() === 'hal2') {
      url = getHttpCDN(`/ray/resource/uuid/625509f941d627d1f04a0a85e1836e6e/raw64/` + name);
    }
    window.open(url, '_blank')
  }

  public onChangeDir(val: number) {
    console.log(val)
  }

  public isCurrent(dir) {
    const curPath = window.location.href
    const metaData = this.onlineTrainService.lvl1MetaDatasMap[dir.id]
    return curPath.indexOf(metaData.prefix) >= 0
  }

  private b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(_.parseInt('0x' + p1));
    }));
  }

  private getSelectedId() {
    const curPath = window.location.href
    const metaData = _.find(this.onlineTrainService.lvl1MetaDatas,
      (data: any) => _.includes(curPath, data.prefix))
    let id = 2

    if (!_.isEmpty(metaData)) {
      id = metaData.id
    }

    return id
  }
  private initData(dirs: OnlineTrainingDirectoryDTO[]) {
    this.dirs = _.filter(dirs, (d: OnlineTrainingDirectoryDTO) => d.id > 1 && d.dirId === 1)
    this.dirSelected = _.find(this.dirs, (dir) => dir.id === this.dirSelectedId)
    this.dirSelected = this.dirSelected ? this.dirSelected : this.dirs[0]
    this.itemType = this.onlineTrainService.DIR

    if (this.state.$current.name === 'onlineTrain') {
      this.goto(this.dirSelected.id)
    }
  }

  private loadData() {
    this.trainMessage.sendLoadingAction(true)
    this.onlineTrainService.getAllDirs().subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
      this.initData(dirs)
    }, (err) => {console.log(err)}, () => {this.trainMessage.sendLoadingAction(false)})
  }
}
