import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-restore-dialog',
  template: require('./restore-dialog.component.html'),
  styles: [require('./restore-dialog.component.scss')]
})
export class RestoreDialogComponent implements OnInit {
  @Output() public action = new EventEmitter<any>();
  private title: string
  private msg: string
  
  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit() {
  }
  
  public jump() {
    this.action.emit('jump')
    this.bsModalRef.hide()
  }
  public ok() {
    this.action.emit('ok')
    this.bsModalRef.hide()
  }
  public cancel() {
    this.action.emit('cancel')
    this.bsModalRef.hide()
  }
}
