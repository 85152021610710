
import _ = require('lodash')

angular.module('app.directives.cloud.ruanyunListItemObjTest', [
])
.component('ruanyunListItemObjTest', {
  bindings: {
    onPreview: '&',
    paper: '<',
  },
  controller: ruanyunListItemObjTest,
  template: <string> require('./ruanyunListItemObjTest.html'),
})

function ruanyunListItemObjTest() {
  this.mapObj = {
    3:'期末考试',
    4:'月考',
    6:'练习',
    7:'调研',
    9:'期中考试',
    11:'会考',
    13:'模拟考试',
    14:'联考',
    15:'开学考试',
    16:'竞赛',
    18:'真题',
  }

  this.handpaperTypeData = (questionType) => {
    const arr = [];
    _.forEach(questionType,item => {
      arr.push(this.mapObj[item]);
    });
    return arr.join(",")
  }
}
