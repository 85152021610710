(function() {
  angular.module('app.prepareCourse')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareCourse.offlineResource', {
        url: '/offlineResource',
        template: require('assets/templates/prepareCourse/offlineResource.html'),
        controller: 'offlineResourceCtrl',
        loadingCls: 'blueloading'
      });
    }])
    .controller('offlineResourceCtrl', offlineResourceCtrl)
    .controller('addOfflineResourceCtrl', addOfflineResourceCtrl)
    .controller('editOfflineResourceCtrl', editOfflineResourceCtrl);

  offlineResourceCtrl.$inject = ['$scope', '$q', '$state', '$stateParams', '$uibModal', 'Upload',
    '$http', 'Lightbox', 'oedConfig', 'oedCourse', 'oedLoading', 'oedOfflineResource', 'oedOfflineResourceItem',
    'oedCloudOfflineRes', 'oedOem']
  function offlineResourceCtrl($scope, $q, $state, $stateParams, $uibModal, Upload,
    $http, Lightbox, oedConfig, oedCourse, oedLoading, oedOfflineResource, oedOfflineResourceItem,
    oedCloudOfflineRes, oedOem) {
    $scope.currentState.title = '下发资源';
    var courseId = $stateParams.courseId;

    $scope.selectedReses = {};
    $scope.enableManage = oedOem.curOem() != 'futureSchool';

    function getResources() {
      $scope.resources = oedOfflineResource.queryByCourse({
        course_id: courseId
      });

      $scope.loading = $q(function(resolve, reject) {
        $scope.resources.$promise.then(function() {
          var selected = null;
          if (_.size($scope.resources) > 0) {
            if ($scope.currentRes) {
              selected = _.find($scope.resources, function(res) {
                return res.id == $scope.currentRes.id;
              });
            } else {
              selected = $scope.resources[0];
            }

            $scope.setCurrentRes(selected);
            return null;
          } else { //no resources
            var res = new oedOfflineResource({
              name: '下发资源一',
              courseId: courseId
            });
            return res.$save();
          }
        }).then(function(newres) {
          if (newres) {
            $state.go('prepareCourse.offlineResource', {}, {
              reload: true
            });
          } else {
            // populate shared res map
            var sharedResources = oedCloudOfflineRes.queryByResIds({
              resIds: _.map($scope.resources, 'id')
            });
            sharedResources.$promise.then(function(allSharedReses) {
              $scope.sharedResources = _.keyBy(allSharedReses, 'resourceId');
            });
          }
          resolve();
        }).catch(reject);
      });
    }

    getResources();

    $scope.setCurrentRes = function(t) {
      if ($scope.currentRes == t) return;
      $scope.currentRes = t;
      $scope.selectedReses = {};

      if (!t) return;

      $scope.selectedReses = {};
      $scope.loading = $q(function(resolve, reject) {
        t.$get(function() {
          resolve();
        });
      });
    };

    $scope.basePath = oedConfig.base();

    $scope.addResource = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/addResourceDialog.html'),
        controller: 'addOfflineResourceCtrl',
        size: 'sm',
        windowClass: 'modalCenter',
        resolve: {
          courseId: function() {
            return courseId;
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r.promise) {
          r.promise.then(function() {
            $scope.resources.push(r.resource);
            $scope.setCurrentRes(r.resource);
          });
        }
      });
    };

    $scope.toggleUpload = function() {
      if (!$scope.currentRes) return;

      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/resourceUploader.html'),
        controller: 'uploadResourceCtrl',
        windowClass: 'modalCenter',
        size: 'md',
        resolve: {
          resourceId: function() {
            return $scope.currentRes.id;
          },
          url: function() {
            return oedConfig.url('offlineresource/' + $scope.currentRes.id + '/uploadresource');
          },
          isPrepareLesson:false,
          moduleId:null,
          packageId:null
        }
      });

      modalInstance.result.then(function(r) {
        if (r.uploaded && r.uploaded > 0)
          getResources();
      });

    };

    $scope.toggleSelect = function(item) {
      if (_.has($scope.selectedReses, item.id)) {
        delete $scope.selectedReses[item.id];
      } else {
        $scope.selectedReses[item.id] = true;
      }
    };

    $scope.isSelected = function(item) {
      return $scope.selectedReses[item.id];
    };

    $scope.deleteSelected = function() {
      var items = $scope.selectedReses;
      var promises = _.map(items, function(item, id) {
        return oedOfflineResourceItem.delete({
          id: id
        }).$promise;
      });

      $q.all(promises).finally(function() {
        getResources();
      });
    };

    $scope.hasNotPublishedItem = function() {
      if (!$scope.currentRes) return false;
      var publishedStatus = {};
      _.each($scope.currentRes.items, function(item) {
        publishedStatus[item.id] = item.published;
      });

      if (_.find($scope.selectedReses, function(v, resId) {
          return !publishedStatus[resId];
        })) {
        return true;
      }

      return false;
    };

    $scope.getItemName = function(item) {
      if (item.resourceName)
        return item.resourceName;

      if (item.flResource.name)
        return item.flResource.name;
      return '';
    };

    var getIdsFromSel = function(sel) {
      return _.map($scope.selectedReses, function(v, k) {
        return k;
      });
    };
    $scope.publishSelected = function() {
      if (_.isEmpty($scope.selectedReses))
        return;

      var ids = getIdsFromSel($scope.selectedReses);
      //
      // mark these resource as published
      //
      $http.post(oedConfig.url('offlineresource/publishresitems'), {
        ids: ids
      }).success(function() {
        $scope.currentRes.$get();
      });
    };

    $scope.cancelPublish = function() {
      if (_.isEmpty($scope.selectedReses))
        return;

      var ids = getIdsFromSel($scope.selectedReses);
      //
      // mark these resource as published
      //
      $http.post(oedConfig.url('offlineresource/cancelpublishresitems'), {
        ids: ids
      }).success(function() {
        $scope.currentRes.$get();
      });
    };

    $scope.fabu = function() {
      if (_.isEmpty($scope.selectedReses))
        return;
    };

    $scope.deleteRes = function(res) {
      $scope.loading = $q(function(resolve, reject) {
        res.$delete(function() {
          if (res == $scope.currentRes)
            $state.go('.', {}, {
              reload: true
            });
          else {
            _.remove($scope.resources, res);
          }
          resolve();
        }, function() {
          reject();
        });
      });
    };

    $scope.editRes = function(res) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/editPresentResourceDialog.html'),
        controller: 'editPresentResourceCtrl',
        size: 'sm',
        windowClass: 'modalCenter',
        resolve: {
          resource: function() {
            return res;
          }
        }
      });
    };

    $scope.editRes = function(res) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/editOfflineResourceDialog.html'),
        controller: 'editOfflineResourceCtrl',
        size: 'sm',
        resolve: {
          resource: function() {
            return res;
          }
        }
      });
    };

    $scope.showPreview = function(reses, idx) {
      Lightbox.openModal(reses, idx);
    };

    $scope.toggleShareRes = function(res) {
      if (res.id in $scope.sharedResources) {
        $scope.sharedResources[res.id].$delete();
        delete $scope.sharedResources[res.id];
      } else {
        var sharedRes = new oedCloudOfflineRes({
          resourceId: res.id
        });
        sharedRes.$save();
        $scope.sharedResources[res.id] = sharedRes;
      }
    };
  }

  addOfflineResourceCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedOfflineResource', 'courseId']
  function addOfflineResourceCtrl($scope, $q, $uibModalInstance, oedOfflineResource, courseId) {
    $scope.res = {};

    $scope.ok = function() {
      var res = new oedOfflineResource({
        name: $scope.res.name,
        courseId: courseId
      });
      var p = $q(function(resolve, reject) {
        res.$save().finally(function() {
          resolve();
        });
      });
      $uibModalInstance.close({
        resource: res,
        promise: p
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }

  editOfflineResourceCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedOfflineResource', 'resource']
  function editOfflineResourceCtrl($scope, $q, $uibModalInstance, oedOfflineResource, resource) {
    $scope.res = {
      name: resource.name
    };

    $scope.ok = function() {
      _.assign(resource, $scope.res);
      $scope.loading = oedOfflineResource.save({
        id: resource.id,
        name: resource.name
      }).$promise;
      $scope.loading.finally(function() {
        $uibModalInstance.close({});
      });

    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
