import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-little-tip',
  template: require('./little-tip.component.html'),
  styles: [require('./little-tip.component.scss')]
})
export class LittleTipComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
  }

  public close() {
    this.bsModalRef.hide()
  }
}
