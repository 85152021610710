import * as _ from 'lodash';

const echarts: any = require('echarts');

angular.module('app.directives.smallData.objData', [])
  .component('objData', {
    bindings: {
      objData: '<',
    },
    controller: objDataCtrl,
    template: <string> require('./objData.html'),
  });

objDataCtrl.$inject = ['$window']
function objDataCtrl($window: any) {
  this.currentObj = {};
  this.$onChanges = function(changes: any) {
    this.objData = changes.objData.currentValue;
    this.objSessions = this.objData;
    this.buildData();
  }

  this.config = {
    dataLoaded: true,
    height: 300,
    theme: 'light',
    width: $window.innerWidth * 0.5,
  };

  this.showObj = function(obj = {}) {
    this.currentObj = obj;
    if (this.showAvg()) {
      this.objSessions = this.objData;
    } else {
      this.objSessions = [obj];
    }
    this.buildData();
  }
  this.showAvg = function() {
    return !_.isNumber(this.currentObj.testSessionId);
  }

  this.buildData = function() {
    const questions = _.flatten(_.map(this.objSessions, (session: any) => this.getTestQuestions(session.test)));
    this.totalQues = _.size(questions);
    const answers =  _.flatten(_.map(this.objSessions, (session: any) => session.answers));
    const ansGrp = _.groupBy(answers, (s: any) => s.questionId);
    this.dc = _.mean(_.map(this.objSessions, (session: any) => this.getDc(session))).toFixed(2);
    const scoreDc: Array<any> = [];
    const qIds = _.map(questions, (q: any) => q.id);
    _.forEach(ansGrp, (value, key) => {
      const stu = _.size(_.uniqBy(value, (s: any) => s.studentId));
      const ques = _.find(questions, (q: any) => q.id === _.parseInt(key));
      if (!_.isEmpty(ques) && _.includes(qIds, ques.id)) {
        scoreDc.push(1 - _.sumBy(value, (v: any) => v.point2) / (stu * ques.point2));
      }
    });
    const points = _.values(scoreDc);
    const data = this.getObjData([
      this.getRangeCount(points, 0.2),
      this.getRangeCount(points, 0.4),
      this.getRangeCount(points, 0.6),
      this.getRangeCount(points, 0.8),
      this.getRangeCount(points, 1) + this.getCount(points, 1),
    ]);
    this.renderChart(data);
  }

  this.getRangeCount = (numbers: Array<number>, target: number) => {
    return _.size(_.filter(numbers, (n: any) => n < target && n >= target - 0.2));
  }

  this.getCount = (numbers: Array<number>, target: number) => {
    return _.size(_.filter(numbers, (n: any) => n === target));
  }

  // 这里计算单题的难度系数，如果计算整卷的，会导致和柱状图不匹配。比如一个人答一道题，题目不同
  this.getDc = function(session: any) {
    const questions = this.getTestQuestions(session.test);
    const ansGrp = _.groupBy(session.answers, (s: any) => s.questionId);
    const dcs = _.compact(_.map(ansGrp, (value, key) => {
      const point = _.sumBy(value, (q: any) => q.point2);
      const ques = _.find(questions, (q: any) => q.id === _.parseInt(key));
      const stu = _.size(_.uniq(_.map(value, (s: any) => s.studentId)));
      if (_.isEmpty(ques)) {
        return null;
      }
      return 1 - point / (stu * ques.point2);
    }));
    return _.isEmpty(dcs) ? 0 : _.mean(dcs);
  }

  this.getTestQuestions = function(test: any) {
    return _.compact(_.flatten(_.map(test.questions, (q: any) => {
      if (!_.isEmpty(q.childQuestions)) {
        return this.getQuestion(q.childQuestions);
      }
      return this.getQuestion([q]);
    })));
  }

  this.getQuestion = (questions: Array<any>) => {
    return _.filter(questions, (q: any) => q.type !== 'singlevote' && q.type !== 'multivote');
  }

  this.getObjData = (data: Array<number>) => {
    const res: Array<any> = [];
    const lower = ['#5cc7ed', '#25b5e6', '#238bb8', '#614f6b', '#a31720'];
    const upper = ['#49bfeb', '#02a9e0', '#436f94', '#803447', '#bd0003'];
    let idx = 0;
    _.forEach(data, (d: any) => {
      res.push({
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 1, 1, 1, [{
              color: lower[idx],
              offset: 0,
            }, {
              color: upper[idx],
              offset: 1,
            }]),
          },
        },
        name: '',
        value: d,
      });
      idx++;
    });
    return res;
  }
  this.renderChart = function(d: Array<any>) {
    this.option = {
      grid: {
        bottom: '20%',
        left: 30,
        right: '20%',
      },
      series: [{
        barGap: '20%',
        barMaxWidth: '30',
        data: d,
        type: 'bar',
      }],
      xAxis: {
        axisLine: {
          symbol: ['none', 'arrow'],
          symbolSize: [5, 10],
        },
        axisTick: {
          show: false,
        },
        data: ['易', '偏易', '中性', '偏难', '难'],
        type: 'category',
      },
      yAxis: {
        axisLine: {
          symbol: ['none', 'arrow'],
          symbolSize: [5, 10],
        },
        axisTick: {
          show: false,
        },
        minInterval: 1,
        name: '题目数',
        splitLine: {
          show: false,
        },
        type: 'value',
      },
    };
  }
}
