import {Component, Input, NgModule, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-full-loading',
  template: require('./full-loading.component.html'),
  styles: [require('./full-loading.component.scss')],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({top: '2%'}),
        animate('0.3s', style({top: '15%'}))
      ]),
      transition(':leave', [
        animate('0.2s', style({top: '2%'}))
      ]),
    ]),
  ]
})
export class FullLoadingComponent implements OnInit {
  @Input() public show = true;
  public showLoading = true;
  private needTimeOut = true;
  constructor() {
  }

  public ngOnInit() {
    this.showLoading = this.show;
  }

  public ngAfterViewChecked() {
    if (this.showLoading !== this.show && this.needTimeOut) {
      this.needTimeOut = false;
      setTimeout(() => {
        this.needTimeOut = true;
        this.showLoading = this.show
      }, 300);
    }
  }
}
