import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfficeEditorTipComponent } from './office-editor-tip/office-editor-tip.component';
import { OfficeUploadTipComponent } from './office-upload-tip/office-upload-tip.component';
import { OfficeEditorDialogComponent } from './office-editor-dialog/office-editor-dialog.component';

@NgModule({
  declarations: [OfficeEditorTipComponent, OfficeUploadTipComponent, OfficeEditorDialogComponent],
  imports: [
    CommonModule
  ],
  entryComponents: [OfficeEditorTipComponent, OfficeUploadTipComponent, OfficeEditorDialogComponent],
})
export class OfficeEditorModule { }
