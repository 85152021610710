import * as angular from 'angular';
import Promise = require('bluebird')
import * as _ from 'lodash';
import { $injector } from '@uirouter/core';
import { choiceCategory, yesornoCategory } from '../../utils/prepareCourseUtils'
require('assets/styles/quickPrepare.scss')

angular.module('app.prepareCourse.qObjectTestPreview')
.component('qObjectTestPreview', {
    controller: qObjectTestPreviewCtrl,
    bindings: {
      isReviewDialog: '<',
      imageRes: '<',
      childQuestionList: '<',
      quesReviewList: '<',
      extraInfo: '<',
      from: '<',
      reviewQaClear: '&',
      reviewQa: '&',
      submitQaPoint: '&',
      isFromNewPrepare: '<'
    },
    template: <string> require('./qObjectTestPreview.html'),
  })

  qObjectTestPreviewCtrl.$inject = ['Lightbox', '$uibModal']
  function qObjectTestPreviewCtrl(Lightbox, $uibModal) {
    const ctrl = this

    ctrl.$onInit = () => {
      ctrl.questionType = _.get(ctrl.childQuestionList, '0.type', '')
      ctrl.curQuestionReview = _.isNil(ctrl.quesReviewList) ? {} : ctrl.quesReviewList[0]
      ctrl.answer = _.get(ctrl.quesReviewList, '0.answer', {})
      ctrl.testSessionId = _.get(ctrl.answer, 'testSessionId', '')
      ctrl.studentId = _.get(ctrl.answer, 'studentId', '')
      ctrl.handwritingList = ctrl.getHandWritingList(ctrl.answer)
    }

    ctrl.getHandWritingList = (answer) => {
      if (_.get(answer, 'answer', '') != '') {
        const content = JSON.parse(_.get(answer, 'answer', ''));
        return _.get(content, 'items', [])
      }
      return []
    }

    ctrl.getQuestionReview = () => {
      if (ctrl.quesReviewList) {
        return ctrl.quesReviewList[0]
      }
      return {}
    }

    ctrl.onWritingRes = (res) => {
      const idx = _.findIndex(ctrl.handwritingList, res)
      $uibModal.open({
        template: require('assets/templates/review/handWritingPreviewDioalog.html'),
        controllerAs: '$ctrl',
        controller: 'handWritingPreviewDioalog',
        resolve: {
          data: {
            testSessionId: ctrl.testSessionId,
            studentId: ctrl.studentId,
            handWritingList: ctrl.handwritingList,
            curIdx: idx
          }
        },
        size: 'lg',
        windowClass: 'ques-detail-model modalCenter disable-overflow',
      });
    }

    ctrl.stringToNumber = (str) => {
      return _.toNumber(str)
    }
    ctrl.choiceCategory = choiceCategory
    ctrl.yesornoCategory = yesornoCategory

    ctrl.$onChanges = (changesObj: any) => {
      if (_.has(changesObj, 'quesReviewList')) {
        ctrl.quesReviewList = this.quesReviewList
        ctrl.curQuestionReview = _.isNil(this.quesReviewList) ? {} : ctrl.quesReviewList[0]
      }
    }

    ctrl.queryConditionsWithAnswer = (child, quesReview, index) => {
      return child &&
             child.length > 0 &&
             quesReview &&
             quesReview.length > 0 &&
             child[index] &&
             child[index]['answer'] &&
             quesReview[index] &&
             quesReview[index]['answer'] &&
             quesReview[index]['answer']['answer']
    }

    // 对错符号显示
    ctrl.rightOrWrongSignDisplay = (index) => {
      if (ctrl.childQuestionList[index].type !== 'singlevote') {
        if (ctrl.queryConditionsWithAnswer(ctrl.childQuestionList, ctrl.quesReviewList, index) &&
          ctrl.childQuestionList[index]['answer'] === ctrl.quesReviewList[index]['answer']['answer']
        ) {
          return true
        }
      // singlevote 答题了就判为对 未答题的判错
      } else {
        if (ctrl.quesReviewList[index]['answer'] && ctrl.quesReviewList[index]['answer']['answer']) {
          return true
        }
      }
      return false
    }
    // 显示正确答案
    ctrl.correctAnswerHighlighted = (answer, index) => {
      return _.parseInt(answer) === index
    }
    ctrl.singlevoteAnswerHighlighted = (index, outerIndex) => {
      if (ctrl.quesReviewList && ctrl.quesReviewList[outerIndex]['answer']
        && ctrl.quesReviewList[outerIndex]['answer']['answer']
        && _.parseInt(ctrl.quesReviewList[outerIndex]['answer']['answer']) === index) {
        return true
      } else {
        return false
      }
    }
    // 显示错误答案
    ctrl.wrongAnswerHighlighted = (index, outerIndex) => {
      if (ctrl.childQuestionList[outerIndex].type !== 'singlevote' &&
          ctrl.queryConditionsWithAnswer(ctrl.childQuestionList, ctrl.quesReviewList, outerIndex) &&
        _.parseInt(ctrl.quesReviewList[outerIndex]['answer']['answer']) === index &&
        !ctrl.rightOrWrongSignDisplay(outerIndex)
      ) {
        return true
      }
      return false
    }
  }
