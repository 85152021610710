import { Injectable } from '@angular/core';
import * as _ from 'lodash'

import { SchoolService } from './school.service'

@Injectable({
  providedIn: 'root'
})
export class SchoolFeatureService {
  public enabledFeatures: { [key: string]: string } = {}
  public _schoolId = -1

  constructor(private schoolService: SchoolService) { }

  public parseFeatureString(str: string): string[] {
    if (_.isEmpty(str)) {
      return ['all'];
    }

    const parts = _.split(str, ',');
    return <any>_.chain(parts).map(_.trim).filter().value();
  }

  public updateFeatureForSchool(sId: number) {
    return this.schoolService.getSchool(sId)
      .then((schoolInfo) => {
        this.updateFeatures(schoolInfo.enabledFeatures, schoolInfo.id);
      })
  }

  /**
   * getSchool
   */
  public getSchool(id: number) {
    return this.schoolService.getSchool(id)
  }
  public updateFeatures(featureStr: string, sId: number) {
    const featureList = this.parseFeatureString(featureStr);
    this.enabledFeatures = _.keyBy(featureList);
    this._schoolId = sId;
  }

  public isFeatureSupported(feature: string) {
    if (_.has(this.enabledFeatures, 'all')) {
      return true;
    }

    return _.has(this.enabledFeatures, feature);
  }

  public schoolId() {
    return this._schoolId
  }
}
