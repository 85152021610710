// import { TalSubjectDTO } from 'app2/integration/talcloud/models'
// import * as _ from 'lodash'
require('app2/prepareCourse/cloud/core/talcloudLevelSubjectDialog.ctrl')

angular.module('app.prepareCourse2.cloud.core.talLevelSubject', [
  'app.prepareCourse2.cloud.core.talcloudLevelSubjectDialog',
])
  .component('oedTalLevelSubject', {
    bindings: {
      onSubjectSelected: '&',
      selectedSubject: '<',
      stages: '<',
      subjects: '<',
      fromTalcloud2: '<',
    },
    controller: oedTalLevelSubjectCtrl,
    template:  <string> require('./talLevelSubject.html'),
  })

oedTalLevelSubjectCtrl.$inject = ['$uibModal']
function oedTalLevelSubjectCtrl($uibModal: any) {
  this.$onChanges = (changeObj: any) => {
    if (this.fromTalcloud2 && _.has(changeObj, 'selectedSubject')) {
      this.selectedSubject = _.get(changeObj, 'selectedSubject.currentValue')
      if (this.selectedSubject) {
        this.selectedSubject.stageName = this.selectedSubject.grade_level_name
        this.selectedSubject.subjectName = this.selectedSubject.subject_name
        this.selectedSubject.stage = this.selectedSubject.grade_level_id
        this.selectedSubject.subjectId = this.selectedSubject.subject_id
      }
    }
    if (this.fromTalcloud2 && _.has(changeObj, 'stages')) {
      this.stages = _.get(changeObj, 'stages.currentValue')
      if (this.stages) {
        _.each(this.stages, (s) => {
          s.stage = s.id
          s.stageName = s.name
        })
      }
    }
    if (this.fromTalcloud2 && _.has(changeObj, 'subjects')) {
      this.subjects = _.get(changeObj, 'subjects.currentValue')
      if (this.subjects) {
        _.each(this.subjects, (s) => {
          s.subjectId = s.id
          s.subjectName = s.name
        })
      }
    }
  }

  this.doSubjectSelect = () => {
    const modalInstance = $uibModal.open({
      controller: 'oedTalcloudLevelSubjectDialogCtrl',
      controllerAs: '$ctrl',
      resolve: {
        data: () => ({
          selectedSubject: this.selectedSubject,
          stages: this.stages,
          subjects: this.subjects,
          fromTalcloud2: this.fromTalcloud2
        }),
      },
      size: 'md',
      template: require('app2/prepareCourse/cloud/core/talcloudLevelSubjectDialog.html'),
      windowClass: 'cs-material-select-dalog',
    })

    modalInstance.result.then((subject: any) => {
      if (this.fromTalcloud2) {
        subject.grade_level_id = subject.stage
        subject.grade_level_name = subject.stageName
        subject.subject_id = subject.subjectId
        subject.subject_name = subject.subjectName
      }
      this.onSubjectSelected({
        subj: subject,
      })
    })
  }
}
