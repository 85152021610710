import { Injectable } from '@angular/core';
import { Transition } from '@uirouter/core';
import * as _ from 'lodash';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LessonDTO, UserInfoDTO } from '../models/lesson';
import { LessonService } from './lesson.service';

@Injectable()
export class CourseNavService {

  constructor(
    public lessonService: LessonService,
    public trans: Transition,
  ) { }

  public loadCourseData(sectionId: number, coursepathId: number, newCourse: LessonDTO = null):
  Observable<{allCourse: LessonDTO[]; lastCourse: LessonDTO}> {
    return forkJoin([
      this.lessonService.getRecommendCoursesBySectionId(sectionId),
      this.lessonService.getCoursesBySectionId(sectionId, _.toNumber(coursepathId)),
      this.lessonService.getUserInfo(),
    ])
    .pipe(
      map((data: [LessonDTO[], LessonDTO[], UserInfoDTO]) => {
      let recommendCourses: LessonDTO[] = _.map(data[0], (d) => {
        return {
          ...d,
          isRecommend: true,
          $$isNew: false,
          $$isCreateCourse: false,
        }
      })
      let mineCourses: LessonDTO[] = _.map(data[1], (d) => {
        return {
          ...d,
          isRecommend: false,
        }
      })
      const userInfo: UserInfoDTO = data[2]
      if (_.isEmpty(recommendCourses)) {
        recommendCourses = [
          this.lessonService.createDummyTmpCourse(sectionId, '推荐课程1'),
        ]
      }
      const courseIdFromTrans = this.trans.params().courseId
      let allCourse = [...recommendCourses, ...mineCourses]
      let lastCourse = null
      if (data[2].canStar && mineCourses.length === 0) {
        lastCourse = null
      } else if (data[2].canStar) {
        lastCourse = mineCourses[0]
      } else {
        lastCourse = _.find(allCourse, (c: LessonDTO) => c.id === _.toNumber(courseIdFromTrans))
        || recommendCourses[0]
      }
      if (newCourse) {
        _.remove(mineCourses, _.last(mineCourses))
        mineCourses = [
          ...mineCourses,
          newCourse,
        ]
        lastCourse = newCourse
      }
      allCourse = [...recommendCourses, ...mineCourses]
      return {
        allCourse,
        lastCourse,
        userInfo
      }
    }))
  }

}
