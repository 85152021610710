(function() {
  require('assets/app/controllers/newPrepareCourse/ownerPrepareCtrl')

  angular.module('app.newPrepareCourse', [
    'app.newPrepareCourse.ownerPrepare'
  ])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('newPrepareCourse', {
        url: '/newPrepareCourse/:courseId',
        template: require('assets/templates/newPrepareCourse.html'),
        controller: 'newPrepareCourseCtrl',
        loadingCls: 'blueloading',
        resolve: {
          curCourse: ['$stateParams', 'oedCourse', function($stateParams, oedCourse) {
            return oedCourse.get({
              id: $stateParams.courseId
            });
          }]
        }
      });
    }])
    .controller('newPrepareCourseCtrl', newPrepareCourseCtrl)

  newPrepareCourseCtrl.$inject = ['$scope', '$state', '$stateParams', 'userInfo', 'oedCourse', 'localStorageService','communicateService',
    '$uibModal', 'oedDialogService']
  function newPrepareCourseCtrl($scope, $state, $stateParams, userInfo, oedCourse, localStorageService,communicateService, $uibModal, oedDialogService) {
    $scope.isPreLesson = localStorageService.get('isPreLesson');
    $scope.courseId = $stateParams.courseId;
     if ($scope.courseId) {
      var curCpId = localStorageService.get('lastCoursePathId');
      var curCpMap = localStorageService.get('lastCoursePathCourseMap', curCpId);
      if (!curCpMap) {
        curCpMap = {};
      }
      curCpMap[curCpId] = $scope.courseId;
      localStorageService.set('lastCoursePathCourseMap', curCpMap);
    }
    $scope.subTitle = {};
    $scope.course = oedCourse.get({
      id: $scope.courseId
    });
    $scope.userInfo = userInfo.get();

    $scope.currentState = {};
    $scope.shouldBeActive = function(states) {
      if (_.isArray(states)) {
        return _.findIndex(states, function(st) {
          return $state.includes(st);
        }) >= 0;
      }

      return $state.includes(states);
    };
    $scope.editCourseName = () => {
      const modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/editPreCourseNameDialog.html'),
        controller: 'editCourseNameCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          data: () => ({
            course: $scope.courseCard,
          })
        }
      });
    }
    $scope.back = () => {
      const id = localStorageService.get('lastCoursePathId');
      $state.go('prepareLesson.newCourseList', {
        cpId: id,
      });
    }
    $scope.openCloudDrive = ()=>{
      oedDialogService.openModalWithComponent('CloudDriveDialogComponent',
        {}, ()=> {},
        {class: 'cl-library-dialog2 modal-cloud-drive'})
    }
  }
})();
