/**
 * @fileOverview class session列表
 * @name classSessionsCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('review.classSessions', {
        url: '/:cpId',
        template: require('assets/templates/review/classSessions.html'),
        controller: 'classSessionsCtrl',
        bodyStyle: 'bged2'
      });
    }])
    .controller('classSessionsCtrl', classSessionsCtrl);

  classSessionsCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', '$animate', '$q',
    'dialogs', 'resize', 'userInfo', 'oedCoursePath', 'oedClassSession', 'oedCourse', 'oedUserClass', 'localStorageService']
  function classSessionsCtrl($scope, $state, $stateParams, $uibModal, $animate, $q,
    dialogs, resize, userInfo, oedCoursePath, oedClassSession, oedCourse, oedUserClass, localStorageService) {
    $scope.classSessions = [];
    var cpId = $stateParams.cpId;
    $scope.cpId = cpId;
    $scope.selectedClasses = [];
    $scope.allClasses = [];
    $scope.startDate = new Date('2015-09-01');
    $scope.dueDate = new Date();
    var localCpId = localStorageService.get('lastReviewCoursePathId');
    var now = new Date(); //当前日期
    var nowYear = now.getFullYear(); //当前年
    var nowMonth = now.getMonth(); //当前月
    var nowDay = now.getDate(); //当前月
    // 今年开学日期（9月1号）
    var startD = new Date(nowYear,9,1)
    // 今天的日期
    var endD = new Date(nowYear,nowMonth,nowDay)
    var monthStartDate = new Date();
    if(startD>endD){
      //没开学,开始日期显示去年9-01'
      monthStartDate = new Date(nowYear-1+'-09-1');
    }else {
      //已开学,开始日期显示今年9-01
      monthStartDate = new Date(nowYear+'-09-1');
    }
    if (localCpId === $scope.cpId) {
      $scope.selectedClasses = localStorageService.get('filterSelectedClasses') ? JSON.parse(localStorageService.get('filterSelectedClasses')) : [];
      $scope.startDate = localStorageService.get('filterStartDate') ? new Date(localStorageService.get('filterStartDate')) : monthStartDate;
      $scope.dueDate = localStorageService.get('filterDueDate') ? new Date(localStorageService.get('filterDueDate')) : new Date();
    } else {
      $scope.selectedClasses = [];
      $scope.startDate = monthStartDate;
      $scope.dueDate = new Date();
      localStorageService.set('filterSelectedClasses', null);
      localStorageService.set('filterStartDate', null);
      localStorageService.set('filterDueDate', null);
    }

    $scope.getDate = function(timestamp) {
      var date = new Date(timestamp);
      return moment(date).format('YYYY.MM.DD')
    };

    $scope.getClassNames = function() {
      var clsName = '';
      _.forEach($scope.selectedClasses, item => {
        clsName += item.levelName + item.gradeName + item.className + ' ';
      });
      return clsName;
    }

    $scope.filterClassSessions = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/app2/review/filterClassSessions.html'),
        controller: 'filterClassSessionsCtrl',
        windowClass: 'oed-common-modal manage-modal center',
        size: 'md',
        resolve: {
          data: function() {
            return {
              cpId: $scope.cpId,
              selectedClasses: $scope.selectedClasses,
              startDate: $scope.startDate,
              dueDate: $scope.dueDate,
            };
          }
        }
      });

      modalInstance.result.then(function(r) {
        var selectedClasses = _.get(r, 'selectedClasses');
        if (selectedClasses && !_.isEmpty(selectedClasses)) {
          $scope.selectedClasses = r.selectedClasses;
          $scope.startDate = r.startDate;
          $scope.dueDate = r.dueDate;
          localStorageService.set('filterSelectedClasses', JSON.stringify($scope.selectedClasses));
          localStorageService.set('filterStartDate', $scope.startDate);
          localStorageService.set('filterDueDate', $scope.dueDate);
          getClassSessions();
        }
      });
    };

    function getClassSessions() {
      const startDate = _.parseInt($scope.startDate.getTime()/1000);
      const dueDate = _.parseInt(($scope.dueDate.getTime() + 24 * 60 * 60 * 1000 - 1000)/1000);
      const classIds = _.map($scope.selectedClasses, 'id').join(',');
      $scope.classSessions = oedClassSession.queryByCoursePathId({
        pathId: cpId,
        classIds: classIds? classIds : -1,
        start: startDate,
        end: dueDate
      });

      $scope.classSessions.$promise.then(function() {
        $scope.ensureLastItemVisible();
        focusLastSelectedCourse(true);

        $scope.courses = _.reduce($scope.classSessions, function(total, v) {
          if (!_.has(total, v.courseId)) {
            total[v.courseId] = oedCourse.get({
              id: v.courseId
            });
          }
          return total;
        }, {});

        var promises = _.map(_.values($scope.courses), function(cs) {
          return cs.$promise;
        });

        return promises;
      });
      $scope.loading = $scope.classSessions.$promise;
    }

    $scope.status.$jump = false
    if (cpId) {
      localStorageService.set('lastReviewCoursePathId', cpId);

      $scope.loading = oedUserClass.queryClassByCpId({
        cpId: $scope.cpId
      }).$promise.then((allClasses) => {
        $scope.allClasses = allClasses;
        $scope.selectedClasses = _.size($scope.selectedClasses) ? $scope.selectedClasses : allClasses;
        getClassSessions();
      });
    }

    $scope.viewIndex = 0;

    var boxWidth = 271; //hard code here

    $scope.getCourseListStyle = function() {
      var width = _.isEmpty($scope.classSessions) ? '1200' : (_.size($scope.classSessions) * boxWidth);
      if (width < 1200)
        width = 1200;

      return {
        width: '' + width + 'px'
      };
    };

    $scope.getCourseStyle = function() {
      var offset = '-' + ($scope.viewIndex * boxWidth) + 'px';
      return {
        left: offset
      };
    };

    $scope.computeMaxCourseDisplayCount = function() {
      var width = $('#gundong').width();
      return Math.floor(width / boxWidth);
    };

    $scope.$on('resize', function() {
      $scope.computeMaxCourseDisplayCount();
    });

    $scope.next = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount();
      if ($scope.viewIndex + maxCount >= _.size($scope.classSessions))
        return;
      $scope.viewIndex++;
    };

    $scope.ensureLastItemVisible = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount();
      var totalCount = _.size($scope.classSessions);
      $scope.viewIndex = totalCount - maxCount > 0 ? totalCount - maxCount : 0;
    };

    $scope.prev = function() {
      if ($scope.viewIndex <= 0)
        return;

      $scope.viewIndex--;
    };

    function focusLastSelectedCourse(replace) {
      var curClassSessionMap = localStorageService.get('lastReviewClassSessionMap');
      var focusClassSessionId;
      if (curClassSessionMap) {
        var curCpId = localStorageService.get('lastReviewCoursePathId');
        focusClassSessionId = curClassSessionMap[curCpId];
      }
      var focusClassSession = null;
      if (!_.isEmpty($scope.classSessions)) {
        focusClassSession = focusClassSessionId ? _.find($scope.classSessions, function(classsession) {
          return classsession.id == focusClassSessionId;
        }) : $scope.classSessions[(_.size($scope.classSessions) - 1)];
        focusClassSession = focusClassSession || $scope.classSessions[(_.size($scope.classSessions) - 1)];
      }
      $scope.setFocusCourse(focusClassSession, replace);
    }

    $scope.setFocusCourse = function(classsession, replace) {
      if ($scope.isDone) {
        return;
      }

      var classSessionId = classsession ? classsession.id : null;

      $scope.viewIndex = _.findIndex($scope.classSessions, function(classsession) {
        return classsession.id == classSessionId;
      });

      ensureLabelVisibiltiy();
    };

    function ensureLabelVisibiltiy() {
      var labelCount = $scope.computeMaxCourseDisplayCount();
      if (_.size($scope.classSessions) < labelCount) {
        $('#gundong .swiper-wrapper').css('transform', 'none');
      }
      if ($scope.viewIndex < 0) {
        $scope.viewIndex = (_.size($scope.classSessions) - 1);
      }
      if ($scope.viewIndex + labelCount >= _.size($scope.classSessions)) {
        $scope.viewIndex = _.size($scope.classSessions);
      }
    }
  }
})();
