(function() {
  angular.module('app.directives.imageOnLoad', [])
    .directive('imageOnLoad', function() {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          element.bind('load', function(event) {
            scope.$apply(attrs.imageOnLoad);
          });
        },
      };
    });
})();

