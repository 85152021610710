import { Component, Input, OnInit, OnChanges, SimpleChanges,
  ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { TestOrPresentDTO, UpdateModuleItemDTO } from 'app/lesson/models/lesson';
import { TeachingModuleService } from 'app/lesson/services/teaching-module.service';
import * as _ from 'lodash'

@Component({
  selector: 'app-res-move',
  template: require('./res-move.component.html'),
  styles: [require('./res-move.component.scss')],
  encapsulation: ViewEncapsulation.None,
})
export class ResMoveComponent implements OnInit, OnChanges {

  @Input() public moduleDetail: {updateModuleDTO: UpdateModuleItemDTO; allModuleTestIds: number[]}
  @Input() public test: TestOrPresentDTO
  @Input() public testType: string
  @Output() public reload = new EventEmitter<UpdateModuleItemDTO>()

  private updateModuleDTO: UpdateModuleItemDTO
  private allModuleTestIds: number[]
  private moduleName: string
  private importTestAction = {
    obj: {
      importTest: () => {
        const objectiveTests = [
          ...this.updateModuleDTO.objectiveTests,
          {
              ...this.test,
              sort: _.size(this.updateModuleDTO.objectiveTests) + 1,
          }
        ]
        this.updateModuleDTO = {
            ...this.updateModuleDTO,
            objectiveTests,
        }
      },
      removeTest: () => {
        const objectiveTests = [
          ...this.updateModuleDTO.objectiveTests,
        ]
        _.remove(objectiveTests, (t) => t.id === this.test.id)
        this.updateModuleDTO = {
            ...this.updateModuleDTO,
            objectiveTests,
        }
      }
    },
    preRes: {
      importTest: () => {
        const presentResources = [
          ...this.updateModuleDTO.presentResources,
          {
              ...this.test,
              sort: _.size(this.updateModuleDTO.presentResources) + 1,
          }
        ]
        this.updateModuleDTO = {
            ...this.updateModuleDTO,
            presentResources,
        }
      },
      removeTest: () => {
        const presentResources = [
          ...this.updateModuleDTO.presentResources,
        ]
        _.remove(presentResources, (t) => t.id === this.test.id)
        this.updateModuleDTO = {
            ...this.updateModuleDTO,
            presentResources,
        }
      }
    },
    sbj: {
      importTest: () => {
        const subjectiveTests = [
          ...this.updateModuleDTO.subjectiveTests,
          {
              ...this.test,
              sort: _.size(this.updateModuleDTO.subjectiveTests) + 1,
          }
        ]
        this.updateModuleDTO = {
            ...this.updateModuleDTO,
            subjectiveTests,
        }
      },
      removeTest: () => {
        const subjectiveTests = [
          ...this.updateModuleDTO.subjectiveTests,
        ]
        _.remove(subjectiveTests, (t) => t.id === this.test.id)
        this.updateModuleDTO = {
            ...this.updateModuleDTO,
            subjectiveTests,
        }
      }
    }
  }
  constructor(
    private teachingModuleService: TeachingModuleService,
  ) { }

  public ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'moduleDetail')) {
        this.updateModuleDTO = _.get(this.moduleDetail, 'updateModuleDTO', {}) as UpdateModuleItemDTO
        this.allModuleTestIds = _.get(this.moduleDetail, 'allModuleTestIds', [])
        this.moduleName = _.get(this.moduleDetail, 'moduleName', '')
    }
  }

  public ngOnInit() {
  }

  public isImportedIntoModule() {
    return _.includes(this.allModuleTestIds, _.get(this.test, 'id', -1))
  }

  public importTestIntoModule($event) {
    $event.stopPropagation();
    this.importTestAction[this.testType].importTest()
    this.reload.emit({
      ...this.updateModuleDTO,
      $$action: 'add',
    })
  }

  public removeTestIntoModule($event) {
    $event.stopPropagation();
    this.importTestAction[this.testType].removeTest()
    this.reload.emit({
      ...this.updateModuleDTO,
      $$action: 'remove',
    })
  }

}
