import { Component, OnInit, EventEmitter, Output, Input} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { NotificationService } from 'app/shared/notification/notification.service';
import { OedExerciseService } from '../../oed-exercise.service'

@Component({
  selector: 'app-creat-exercise-book-dialog',
  template: require('./creat-exercise-book-dialog.component.html'),
  styles: [require('./creat-exercise-book-dialog.component.scss')]
})
export class CreatExerciseBookDialogComponent implements OnInit {
  exerciseBookName = '';
  @Output() public action = new EventEmitter<any>()
  @Input() private subjectId: string;
  @Input() private versionId: string;
  @Input() private levelId: string;

  constructor( public bsModalRef: BsModalRef,
    private notifyService: NotificationService,
    private oedExerciseService: OedExerciseService,) { }

  ngOnInit() {
  }

  public close() {
    this.bsModalRef.hide()
  }

  public ok() {
    if (!_.isEmpty(this.exerciseBookName)){
      this.oedExerciseService.createExeriseBook({
        name: this.exerciseBookName,
        subjectId: this.subjectId,
        versionId: this.versionId,
        levelId: this.levelId,
      }).subscribe((result) => {
        this.action.emit(result);
        this.bsModalRef.hide();
      });
    }
  }
}
