import { LoadingService } from './../../../../shared/loading/loading.service';
import { OedExerciseService } from './../../oed-exercise.service';
import { validateQuestion } from './../ex-dialog-utils';
import { Component, OnInit, Input, Output, EventEmitter, Inject, forwardRef, OnChanges, SimpleChanges } from '@angular/core';
import _ = require('lodash');
import { BsModalRef } from 'ngx-bootstrap/modal';
import { getQuestionTypeLabel } from '../ex-mark-edit-dialog/edit-ques-utils';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-ex-ques-edit-dialog',
  template: require('./ex-ques-edit-dialog.component.html'),
  styles: [require('./ex-ques-edit-dialog.component.scss')]
})
export class ExQuesEditDialogComponent implements OnInit {
  @Input() private question: any
  @Input() private syncQues: any
  @Input() private score: number
  @Output() private action = new EventEmitter<any>()

  private cloudRes: any
  private currentTest: any
  private typeText: string
  private type: string

  private showSync: boolean
  private syncScore = 0
  private syncQuestion = ''
  private syncAnswerDetails = ''

  constructor(
    private bsModalRef: BsModalRef,
    private notifyService: NotificationService,
    private oedExerciseService: OedExerciseService,
    private loadingService: LoadingService,
    @Inject(forwardRef(() => OedDialogService)) private oedDialogService: OedDialogService,
  ) { }

  ngOnInit() {
    this.buildQuesData()
    if (!_.isEmpty(this.syncQues)) {
      this.showSync = true
      this.loadSyncQues()
    }
  }

  private loadSyncQues = () => {
    const quesId = _.get(this.syncQues, 'question.id', 0)
    this.oedExerciseService.getQuesByQuesId(quesId)
    .pipe(this.loadingService.withLoading())
    .subscribe((res) => {
      const childQuestions = _.get(res, 'childQuestions', [])
      const filterCurQues = _.filter(childQuestions, child => child.id != _.get(this.question, 'id', 0))
      const childrenPointList = _.map(filterCurQues, r => r.point2)
      if (this.question.type == 'fill') {
        // 如果是填空题，这里的syncScore指的是除了当前题目之外其他子题分数的和
        this.syncScore = _.sum(childrenPointList)
      } else {
        this.syncScore = _.sum(childrenPointList) + this.score
      }
      this.syncQuestion = _.get(res, 'question', '')
      this.replaceSyncQuesStemRes()
      this.syncAnswerDetails = _.get(res, 'answerDetails', '')
    }, e => {
      this.notifyService.notify('error', '加载题目失败，请稍后再试')
      console.error('create and save ques request error: ', e)
    })
  }

  private replaceSyncQuesStemRes = () => {
    if (!_.includes(this.syncQuestion, '<img')) return
    const reg = /<img/g
    const replaceStr = '<img style="max-width: 100%;"'
    this.syncQuestion = this.syncQuestion.replace(reg, replaceStr)
  }

  private onClose = () => {
    const valid = this.checkValid()
    if (!valid) return
    const type = _.get(this.question, 'type', '')
    if (type === 'synthetic') {
      this.buildSyncQues()
    }
    if (type === 'connect') {
      this.clearTempInfo()
    }
    this.action.emit(this.question)
    this.bsModalRef.hide()
  }
  
  private clearTempInfo = () => {
    let leftOpts = _.get(this.question, 'leftOpts', [])
    leftOpts = _.map(leftOpts, lo => ({ content: _.get(lo, 'content', '')}))
    _.set(this.question, 'leftOpts', leftOpts)
  }

  private buildSyncQues = () => {
    // 如果编辑的是综合题
    // 返回时应该将类型从qa改回synthetic
    // 并且清除含有选项的字段
    _.set(this.question, 'type', 'synthetic')
    _.set(this.question, 'choices', [])
    _.set(this.question, 'leftOpts', [])
    _.set(this.question, 'rightOpts', [])
  }

  private checkValid = () => {
    const validResult = validateQuestion(this.question)
    if (!validResult.valid) {
      this.notifyService.notify('error', validResult.message)
      return false
    }
    return true
  }

  private buildQuesData = () => {
    // 如果编辑的是综合题，是需要创建一道没有子题的综合题
    // 因为直接把当前题目当做综合题来编辑的话，必须添加一道子题才可以保存
    // 所以编辑的时候将其当做问答题来处理，这样可以不用添加子题
    const type = _.get(this.question, 'type', '')
    if (type === 'synthetic') {
      _.set(this.question, 'type', 'qa')
    }
    this.score = _.get(this.question, 'point2', 0)
    this.type = _.get(this.question, 'type', 0)
    this.typeText = getQuestionTypeLabel(type)
    this.cloudRes = { id: 1 }
    this.currentTest = { questions: this.question }
  }
}
