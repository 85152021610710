import { Injectable } from '@angular/core';
import {RayApiService} from 'app/core/ray-api.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  constructor(private httpClient: HttpClient, private rayApi: RayApiService) { }
  
  public getResourceById(id) {
    return this.httpClient.get<any>(this.rayApi.apiA(`resource/${id}`));
  }
  public getResourceInfoByUuid(uuid: string) {
    return this.httpClient.get<any>(this.rayApi.apiB(`resource/uuid/${uuid}`));
  }
}
