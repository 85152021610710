import {Component, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CloudDriveService} from 'app/cloud-drive/cloud-drive.service';
import * as _ from 'lodash'
import {KEYS} from 'angular-tree-component';
import {NotificationService} from 'app/shared/notification/notification.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-dir-dialog',
  template: require('./dir-dialog.component.html'),
  styles: [require('./dir-dialog.component.scss')]
})
export class DirDialogComponent implements OnInit {
  private type: string
  private fileNum: number
  private idList: number[]
  private tempArr = []
  private data: any
  private selectedSection: any
  private loading
  private options = {
    actionMapping: {
      keys: {
        [KEYS.ENTER]:null,
        [KEYS.LEFT]: null,
        [KEYS.UP]: null,
        [KEYS.RIGHT]: null,
        [KEYS.DOWN]: null,
        [KEYS.ENTER]: null,
        [KEYS.SPACE]: null,
        [KEYS.CONTEXT_MENU]: null
      }
    },
  }
  private cloneData: any
  private action = new EventEmitter<any>()
  
  @ViewChild('tree') tree
  
  constructor(public bsModalRef: BsModalRef,
              public notifyService: NotificationService,
              public cloudDriveService: CloudDriveService) { }

  ngOnInit() {
    this.loading = true
    this.cloudDriveService.queryAllDirs().pipe(finalize(() => {
      this.loading = false
    })).subscribe(res => {
      if (res.length > 0) {
        this.data = this.listToTree(res);
        this.cloneData = _.cloneDeep(this.data);
        setTimeout(() => {
          this.tree.treeModel.expandAll();
        }, 0);
      }
    })
  }
  
  public listToTree=(list)=> {
    const map = {};
    let node, i;
    list.push({
      id: 1,
      name: 'my file',
      children: []
    });
    for (i = 0; i < list.length; i += 1) {
      map[list[i].id] = i;
      list[i].children = [];
    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (_.isNumber(node.parentDirId)) {
        if(map[node.parentDirId]>=0){
          list[map[node.parentDirId]].children.push(node);
        }
      }
    }
    return list[list.length - 1].children;
  };

  public onNodeActive(event) {
    this.selectedSection = event.node.data
  }
  
  private findTargetData(data, item){
    _.each(data, (r: any) => {
      if(r.id === item) {
        this.tempArr.push(r);
      } else {
        if(r.children) {
          this.findTargetData(r.children, item)
        }
      }
    })
  }
  
  private getNodeDepth(data, nodeId, depth) {
    _.each(data, (r: any) => {
      if(r.id === nodeId) {
        r.depth = depth
      } else {
        if(r.children) {
          this.getNodeDepth(r.children, nodeId, depth + 1)
        }
      }
    })
  }
  
  private getMaxFloor (treeData) {
    let max = 0;
    function each (data, floor) {
      data.forEach(e => {
        e.floor = floor;
        if (floor > max) {
          max = floor;
        }
        if (e.children.length > 0) {
          each(e.children, floor + 1);
        }
      })
    }
    each(treeData,1);
    return max;
  }
  
  public toggleNode(node) {
    if (node.isExpanded) {
      node.collapseAll()
    } else {
      node.expandAll()
    }
  }
  
  public getItemClass(node) {
    if (node.isExpanded) {
      return 'fa fo-minus-circle'
    } else {
      return 'fa fo-plus-circle'
    }
    
  }
  
  public selectNode(node) {
    this.selectedSection = node
  }
  
  public isSelected(node) {
    if (_.isEmpty(node.data) || _.isEmpty(this.selectedSection)) {
      return false
    }
    return node.data.id === this.selectedSection.id
  }
  
  public ok() {
    if(!this.selectedSection){
      return this.bsModalRef.hide();
    }
    _.each(this.idList, item => {
      this.findTargetData(this.cloneData, item);
    })

    const arr = [];
    _.each(this.tempArr, r => {
      arr.push(this.getMaxFloor([r]));
    })
    
    this.getNodeDepth(this.cloneData, this.selectedSection.id, 0)
    
    const num = Math.max.apply(this,arr);
    if(num + this.selectedSection.depth > 20) {
      this.tempArr = [];
      if(this.type == "move"){
        return this.notifyService.notify('error', '移动文件目录超过20层，移动失败');
      }else{
        return this.notifyService.notify('error', '复制文件目录超过20层，复制失败');
      }
    }
    if(this.type == "move"){
      this.cloudDriveService.moveFile(this.selectedSection.id, this.idList)
        .subscribe(() => {
          this.action.emit(true)
          this.bsModalRef.hide()
        }, () => {
          this.notifyService.notify('error', '移动文件失败')
        })
      
    } else {
      this.cloudDriveService.copyFile(this.selectedSection.id, this.idList)
        .subscribe(() => {
          this.action.emit(true)
          this.bsModalRef.hide()
        }, () => {
          this.notifyService.notify('error', '复制文件失败')
        })
    }
  }
  
  public cancel() {
    this.bsModalRef.hide()
  }
}
