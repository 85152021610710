(function() {
  'use strict';

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.taskListPubDetailsSummary', {
        url: '/taskList/:origTaskListId',
        template: require('assets/templates/homework/taskListPubDetailsSummary.html'),
        controller: 'taskListPubDetailsSummaryCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('taskListPubDetailsSummaryCtrl', taskListPubDetailsSummaryCtrl);

  taskListPubDetailsSummaryCtrl.$inject = ['$scope', '$state', '$stateParams', '$q',
    '$uibModal', 'userInfo', 'oedTaskList', 'oedZhongZhiSchool']
  function taskListPubDetailsSummaryCtrl($scope, $state, $stateParams, $q,
    $uibModal, userInfo, oedTaskList, oedZhongZhiSchool) {

    var origTaskListId = _.parseInt($stateParams.origTaskListId);
    $scope.origTaskListId = origTaskListId;
    $scope.selectedClass = {};
    $scope.loadTaskListPublishDetails = function() {
      var getOrigTaskListQuery = oedTaskList.get({
        id: origTaskListId
      });

      return getOrigTaskListQuery.$promise.then(function(originalTaskList) {
        $scope.originalTaskList = originalTaskList;
        var getOrigTaskListPublishInfoQuery = oedTaskList.queryPublishInfo({
          id: originalTaskList.id
        });

        return getOrigTaskListPublishInfoQuery.$promise.then(function(publishInfo) {
          $scope.originalTaskList.publishInfo = publishInfo;
          $scope.classes = publishInfo.publishedClasses;
          $scope.publishRecords = publishInfo.publishRecords;

          if (!_.isEmpty($scope.classes)) {
            _.forEach($scope.classes, function(cls) {
              oedTaskList.getClassInfoById({
                classId: cls.id
              }).$promise.then(function(result) {
                return oedZhongZhiSchool.className(result).then((clsName) => {
                  cls.fullClassName = clsName
                })
              });
            });
          }
          if (!_.isEmpty($scope.classes)) {
            if ($state.is('homework.taskListPubDetailsSummary'))
              $scope.selectClass($scope.classes[0]);
          }
        });
      });
    };

    $scope.selectClass = function(cls) {
      if (cls.id == $scope.selectedClass.id)
        return;
      $scope.selectedClass = cls;
      $state.go('homework.taskListPubDetailsSummary.forClass', {
        classId: cls.id
      }, {
        location: 'replace'
      });
    };
    $scope.loadingReadyForChild = $scope.loadTaskListPublishDetails();
    $scope.loading = $scope.loadingReadyForChild;
  }
})();
