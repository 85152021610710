var utils = require('app2/utils/index');

(function() {
  angular.module('app.filters.imgSrc', ['app.utils']).filter('imgSrc', imgThumbUrl);

  imgThumbUrl.$inject = ['oedConfig']
  function imgThumbUrl(oedConfig) {
    return function(resId) {
      if (!resId) {
        return '';
      }

      var base = oedConfig.base();
      return base + 'resource/view/img_src/' + resId;
    };
  }
})();
