/**
 * @fileOverview
 * @name reviewSubjectiveTestPeerReviewStudentCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.reviewSubjectiveTestPeerReview.student', {
        url: '/student/:studentId',
        template: require('assets/templates/homework/reviewSubjectiveTestPeerReviewStudent.html'),
        controller: 'studentSubjectiveTestPeerReviewlStudentCtrl',
        bodyStyle: 'bg_img'
      });
    }]);
})();
