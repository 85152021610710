import * as _ from 'lodash'

const JuniorLanguage = [
  '单选题',
  '多选题',
  '现代文阅读',
  '诗歌鉴赏',
  '文言文阅读',
  '填空题',
  '简答题',
  '判断题',
  '语言表达',
  '默写',
  '作文',
]

const JuniorMath = [
  '填空题',
  '单选题',
  '判断题',
  '计算题',
  '解决问题',
  '解答题',
]

const JuniorEnglish = [
  '听力选择',
  '听力判断',
  '听力排序',
  '听力填空',
  '单项选择',
  '填空题',
  '单词拼写',
  '选词填空',
  '连线题',
  '匹配题',
  '判断题',
  '排序题',
  '句型转换',
  '连词成句',
  '改错',
  '补全对话',
  '补全对话（填空）',
  '翻译',
  '完形填空',
  '阅读理解',
  '任务型阅读',
  '阅读判断',
  '书面表达',
]

const MiddlePhysical = [
  '单选题',
  '不定项选择题',
  '填空题',
  '作图题',
  '实验题',
  '简答题',
  '计算题',
]

// 文档里不知道类型的 七选五
// 文档中没有的 连词成句 选词填空（短文）
const MiddlEnglish = [
  '听力选择',
  '单项选择',
  '填空题',
  '单词拼写',
  '选词填空',
  '句型转换',
  '连词成句',
  '补全对话',
  '补全对话(填空)',
  '短文填空',
  '选词填空(短文)',
  '语法填空',
  '翻译',
  '七选五',
  '配对阅读',
  '完形填空',
  '阅读判断',
  '阅读理解',
  '任务型阅读(简答)',
  '任务型阅读(综合)',
  '任务型阅读(表格)',
  '书面表达',
]

const MiddleLanguage = [
  '单选题',
  '名著导读',
  '默写',
  '现代文阅读',
  '诗歌鉴赏',
  '文言文阅读',
  '语言表达',
  '作文',
]

const levelList = {
  '一': 1,
  '二': 2,
  '三': 3,
  '四': 4,
  '五': 5,
  '六': 6,
  '七': 7,
  '八': 8,
  '九': 9,
}

/**
 * 不清楚要做什么
 * @param levelNum
 * @param subjectName 
 */
function getSubjectRank(levelNum: number, subjectName: string) {
  const isJunior = levelNum <= 6
  const isMiddle = levelNum > 6
  switch (subjectName) {
    case '语文':
      if (isJunior) return JuniorLanguage
      if (isMiddle) return MiddleLanguage
      break
    case '数学':
      return JuniorMath
    case '英语':
      if (isJunior) return JuniorEnglish
      if (isMiddle) return MiddlEnglish
      break
    case '物理':
      return MiddlePhysical
    default:
      return []
  }
}

export function sortQuesTypeList(coursepath: any, nomalList: any[]) {
  const levelName = _.get(coursepath, 'gradeName', '').substring(0, 1)
  const levelNum = _.get(levelList, levelName, 0)
  if (levelNum === 0) {
    return nomalList
  }
  const subjectName = _.get(coursepath, 'subjectName', '')
  const sbjRank = getSubjectRank(levelNum, subjectName)
  if (sbjRank.length <= 0) {
    return nomalList
  }
  const uncontainType = _.remove(nomalList, t => sbjRank.indexOf(t.categoryName) < 0)
  let sortedQuesTypeList = nomalList.sort((a, b) => sbjRank.indexOf(a.categoryName) - sbjRank.indexOf(b.categoryName))
  if (uncontainType.length > 0) {
    //  排序文档中没有的软云题目类型一律放在最后
    sortedQuesTypeList = sortedQuesTypeList.concat(uncontainType)
  }
  return sortedQuesTypeList
}
