import { Component, OnChanges, OnInit,
         Input, EventEmitter, Output,
  ViewChild, SimpleChanges, ComponentFactoryResolver, ApplicationRef, ElementRef, Injector, KeyValueDiffers, ComponentFactory, KeyValueDifferFactory, KeyValueDiffer, OnDestroy, KeyValueChanges, SimpleChange } from '@angular/core';
import { Map } from 'core-js'
import * as _ from 'lodash'
import { FillAnswerItemComponent } from '../fill-answer-item/fill-answer-item.component';
import { ComponentRef } from '@angular/core/src/render3';
import { finalize, distinctUntilChanged } from 'rxjs/operators';
import { Subscription, merge } from 'rxjs';

import { convertFillAnswerStringToArray, convertFillAnswerStringToPlainArray,
         convertFillAnswerTypesToArray, convertFillAnswerPointsToArray} from 'app2/utils/testUtils2'
import { LegacyAppService } from 'app/core/legacy-app.service';
import { maxFillAnswerLength } from './fill-designer.config';
import { NotificationService } from 'app/shared/notification/notification.service';

const maxLength = 200

interface FillItemComponentData {
  component: ComponentRef<FillAnswerItemComponent>;
  differ: KeyValueDiffer<string, any>;
  subscriptions: Subscription[];
}

@Component({
  selector: 'app-fill-answer-designer',
  template: require('./fill-answer-designer.component.html'),
  styles: [require('./fill-answer-designer.component.scss')]
})
export class FillAnswerDesignerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() content: string
  @Input() answer: string
  answerCached: string = null
  @Input() simpleText: boolean
  @Input() disableChangeAnswerType: boolean
  @Input() disableChangePoint: boolean

  //
  // 总分变化的事件
  @Output() onPointChange = new EventEmitter<number>()
  @Output() answerChange = new EventEmitter<string>()

  answers: string[][] = []
  answerTypes: string[] = []
  answerPoints: number[] = []

  @ViewChild('container' ) container: ElementRef

  componentFactory: ComponentFactory<FillAnswerItemComponent>
  differFactory: KeyValueDifferFactory

  createdComponents: FillItemComponentData[] = []

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private app: ApplicationRef, private injector: Injector,
    differs: KeyValueDiffers,
    private notifyService: NotificationService,
    legacyApp: LegacyAppService
  ) {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(FillAnswerItemComponent)

    this.differFactory = differs.find({})
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.has(changes, 'answer')) {
      if (this.answerCached !== changes.answer.currentValue) {
        this.updateAnswerData(this.answer)
      }
    }

    if (_.has(changes, 'content')) {
      this.updateComponents(this.content, changes.content.previousValue)
    }
  }

  ngOnDestroy() {
    this.clear()
  }

  clear () {
    for (const d of this.createdComponents) {
      d.component.destroy()
      d.subscriptions.forEach((sub) => sub.unsubscribe())
    }
    this.createdComponents = []
  }

  updateComponents(html: string, oldHtml: string) {
    this.updateAnswerTemplate(html, oldHtml)
    let idx = 0;
    const replaceTpl = `<div> <app-fill-answer-item </app-fill-answer-item> </div>`

    const htmlWithFillTag = _.replace(this.content, /__+/g, (text, group) => {
      this.answers[idx] = this.answers[idx] || [];
      this.answerTypes[idx] = this.answerTypes[idx] || this.disableChangeAnswerType ? 'manual-score' : 'exact-match';
      this.answerPoints[idx] = this.answerPoints[idx] || 1;
      idx++
      return replaceTpl
    })

    // 保证答案数量和选项数一致
    if (idx != _.size(this.answers)) {
      this.answers = _.slice(this.answers, 0, idx);
    }
    if (idx != _.size(this.answerTypes)) {
      this.answerTypes = _.slice(this.answerTypes, 0, idx);
    }
    if (idx != _.size(this.answerPoints)) {
      this.answerPoints = _.slice(this.answerPoints, 0, idx);
    }

    this.updateComponentByHtml(htmlWithFillTag)
  }

  updateComponentByHtml(html: string) {
    this.clear()

    const el = this.createElementFromHTML(html)
    this.container.nativeElement.innerHTML = ''
    this.container.nativeElement.append(el)
    const tags = el.querySelectorAll('app-fill-answer-item')

    tags.forEach((tag, idx) => {
      this.initAnswerItem(tag, idx)
    })
  }

  initAnswerItem(el: Node, idx: number) {
    const cmp = this.componentFactory.create(this.injector, [], el) as ComponentRef<FillAnswerItemComponent>

    const instance = cmp.instance
    const differ = this.differFactory.create<string, any>()
    const data = {
      answerType: this.answerTypes[idx],
      answerItems: this.answers[idx],
      answerPoint: this.answerPoints[idx],
    }
    instance.answerType = data.answerType
    instance.answerItems = data.answerItems
    instance.answerPoint = data.answerPoint

    const changes = this.differToChange(differ.diff(data), true)
    this.app.attachView(cmp.hostView);
    instance.ngOnChanges(changes)

    const pointChange = instance.answerPointChange.pipe(distinctUntilChanged())
    const subPoint = pointChange.subscribe((point) => {
      this.handleAnswerPointChange(idx, point)
      this.handleDataChange()
    })

    const subItem = instance.answerItemsChange.subscribe((items) => {
      this.answers = [...this.answers]
      this.answers[idx] = items
      this.handleDataChange()
    })
    const subType = instance.answerTypeChange.pipe(distinctUntilChanged())
      .subscribe((answerType) => {
        this.answerTypes = [...this.answerTypes]
        this.answerTypes[idx] = answerType
        this.handleDataChange()
    })

    const initSub = instance.onInit.subscribe(() => {
      this.handleDataChange()
    })

    cmp.instance.disableChangeAnswerType = this.disableChangeAnswerType
    cmp.instance.disableChangePoint = this.disableChangePoint

    cmp.instance.onValidationError.subscribe((error) => {
      this.notifyService.notify('info', '答案应唯一, 且内容应在1至${maxFillAnswerLength}个字符之间')
    }, (e) => {
      console.log('error', e)
    })

    this.createdComponents = [...this.createdComponents, {
      component: cmp,
      differ,
      subscriptions: [subItem, subType, subPoint, initSub],
    }]
  }

  createElementFromHTML(htmlString) {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    // Change this to div.childNodes to support multiple top-level nodes
    return div
  }

  updateAnswerTemplate(newContent: string, oldCntent: string) {
    if (_.isEmpty(newContent) || _.isEmpty(oldCntent)) {
      return
    }
    const tempNewVal = newContent
    const tempOldVal = oldCntent
    const countTagOfoldVal = (tempOldVal.match(/__+/g) || []).length;
    if (countTagOfoldVal > 0) {
      const countTagOfnewVal = (tempNewVal.match(/__+/g) || []).length;
      if (countTagOfnewVal != countTagOfoldVal) {
        const countTagOfDiff = countTagOfnewVal - countTagOfoldVal;
        let arrayOfNewVal = tempNewVal.split(/__+/);
        let arrayOfOldVal = tempOldVal.split(/__+/);
        arrayOfNewVal = _.filter(arrayOfNewVal, function(an) {
          return !_.isEmpty(an);
        });
        arrayOfOldVal = _.filter(arrayOfOldVal, function(ao) {
          return !_.isEmpty(ao);
        });
        const insert = countTagOfnewVal > countTagOfoldVal;
        for (let index = 0; index < arrayOfNewVal.length; index ++) {
          const sn = arrayOfNewVal[index].replace(/_/g, '');
          const so = arrayOfOldVal[index].replace(/_/g, '');
          if (sn != so) {
            if (insert) {
              this.insertAnswers(index, countTagOfDiff);
            }  else {
              this.deleteAnswers(index, countTagOfDiff);
            }
            break;
          }
        }
      }
    }
  }

  insertAnswers (index, insertCount) {
    const answers = _.map(Array(insertCount), () => []);
    const answersParams = _.concat([index, 0], answers);
    this.answers =  [...this.answers]
    answers.splice.apply(this.answers, answersParams);

    const answerTypes = _.fill(Array(insertCount), this.disableChangeAnswerType ? 'manual-score' : 'exact-match');
    const typeParams = _.concat([index, 0], answerTypes);
    this.answerTypes = [...this.answerTypes]
    this.answerTypes.splice.apply(this.answerTypes, typeParams); // eslint-disable-line

    const answerPoints = _.fill(Array(insertCount), 1);
    const pointParams = _.concat([index, 0], answerPoints);
    this.answerPoints = [...this.answerPoints]
    this.answerPoints.splice.apply(this.answerPoints, pointParams); // eslint-disable-line
    this.updateAnswer()
  }

  deleteAnswers (index, deleteCount) {
    const count = Math.abs(deleteCount);
    this.answers = [...this.answers]
    this.answerTypes = [...this.answerTypes]
    this.answerPoints = [...this.answerPoints]

    this.answers.splice(index, count);
    this.answerTypes.splice(index, count);
    this.answerPoints.splice(index, count);
    this.updateAnswer()
  }

  private differToChange(changes: KeyValueChanges<string, any>, firstChange: boolean) {
    const data: SimpleChanges = {}
    changes.forEachChangedItem((r) => {
      data[r.key] = new SimpleChange(r.previousValue, r.currentValue, firstChange)
    })
    return data
  }

  updateAnswerData(answer: string) {
    //
    // TODO: 优化代码, 这几个convert可以合并成一个, 每一个方法都需要parseJSON, 没必要
    // 读取 'answer'
    this.answers = convertFillAnswerStringToPlainArray(answer)

    // 读取 'answerTypes'
    this.answerTypes = convertFillAnswerTypesToArray(answer, _.size(this.answers));

    // 读取 'answerPoints'
    this.answerPoints = convertFillAnswerPointsToArray(
      answer,
      _.size(this.answers)
    )

    this.updateDataBinding()
  }

  getDataByIdx(idx: number) {
    return {
      answerType: this.answerTypes[idx],
      answerItems: this.answers[idx],
      answerPoint: this.answerPoints[idx],
    }
  }

  updateDataBinding() {
    this.createdComponents.forEach(({component, differ}, idx) => {
      const data = this.getDataByIdx(idx)
      _.assign(component.instance, data)
      const diffs = differ.diff(data)
      const changes = this.differToChange(diffs, false)
      if (!_.isEmpty(changes)) {
        component.instance.ngOnChanges(changes)
      }
    })
  }

  handleAnswerPointChange(idx: number, point: number) {
    console.log('change', point)
    this.answerPoints[idx] = point || 1
    const total = _.sum(this.answerPoints)
    this.onPointChange.emit(total)
  }

  handleDataChange() {
    this.updateAnswer()
  }

  toIntArray(array) {
    return _.map(array, item => _.parseInt(item));
  }

  updateAnswer() {
    const newAnswer = angular.toJson({
      answer: this.answers,
      answerTypes: this.answerTypes,
      answerPoints: this.toIntArray(this.answerPoints),
      answerPoints2: this.answerPoints
    })
    this.answer = newAnswer
    this.answerCached = newAnswer
    this.answerChange.emit(newAnswer)
  }
}
