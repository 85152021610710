import * as angular from 'angular';

require('assets/styles/qaPointBox.scss');

angular.module('app.review.qaAnswerPoint', [])
.controller('qaAnswerPointCtrl', qaAnswerPointCtrl);

qaAnswerPointCtrl.$inject = ['oedTestUtils', 'data', '$scope', '$uibModalInstance']
function qaAnswerPointCtrl(oedTestUtils: any, data: any, $scope: any, $uibModalInstance: any) {
  $scope.submit = data.submit;
  $scope.ngModel = data.ques;
  setTimeout(function () {
      document.getElementById('questionPoint').focus();
  }, 50);
  $scope.close = () => {
    $uibModalInstance.close();
  };

}
