import * as _ from 'lodash'
require('assets/app/controllers/examination/examReport/examReportChart/studentChart/studentChart.scss')
import { getBarColorByRate } from 'assets/app/controllers/examination/examReport/examReportChart/examChartUtils'

angular.module('app.examination.examReport.chart.student', [])
  .component('studentChart', {
    bindings: {
      examSearch: '<',
    },
    controller: studentChartCtrl,
    template: < string > require('./studentChart.html'),
  })

studentChartCtrl.$inject = []
function studentChartCtrl() {
  const ctrl = this

  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'examSearch')) {
      loadStudentData()
      loadAveScore()
    }
  }

  function loadStudentData() {
    const studentData = _.get(ctrl.examSearch, 'studentPartData.0.data.studentsData', [])
    ctrl.chartData = _.sortBy(studentData, d => -_.get(d, 'gainedScore', 0))
    ctrl.totalScore = _.get(studentData, '0.totalScore', 100)
  }

  function loadAveScore() {
    const scoreList = _.map(ctrl.chartData, d => _.get(d, 'gainedScore', 0))
    ctrl.averageScore = _.round((scoreList.reduce((pre, cur) => cur += pre)) / _.size(scoreList), 1)
  }

  ctrl.getBarColor = (rate: any) => getBarColorByRate(rate)

  ctrl.getAveLinePosition = () => {
    const maxWidth = document.getElementsByClassName('header-class-tab')[0].clientWidth
    // 左padding、右padding、姓名、分数
    const barWidth = maxWidth - 75 - 200 - 75 - 80
    // 平均分所占宽度
    const aveWidth = (ctrl.averageScore / ctrl.totalScore) * barWidth
    // 距离右边距的距离：平均分所占bar剩余的宽度 + 右padding - ((标签宽度 / 2) + 平均线)
    const distanceRightWidth = (barWidth - aveWidth) + 200 - 48
    return { 'right': distanceRightWidth }
  }

  ctrl.getStudentScore = (score: number) => _.round(score, 1)
}
