(function() {
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.manageCourses', {
        url: '/manageCourses?type',
        template: require('assets/templates/manage/manageCourses.html'),
        controller: 'manageCoursesCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('manageCoursesCtrl', manageCoursesCtrl)
    .controller('createCourseCtrl', createCourseCtrl);

  manageCoursesCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', 'dialogs',
    'oedConfig', 'oedSchool', 'userInfo', 'oedUserInfo', 'oedCoursePath', 'notificationService'];
  function manageCoursesCtrl($scope, $state, $stateParams, $uibModal, dialogs,
    oedConfig, oedSchool, userInfo, oedUserInfo, oedCoursePath, notificationService) {
    const type = $stateParams.type;
    $scope.currentState.title = '我的课程';

    $scope.pageSize = 20;
    $scope.curPage = $stateParams.page || 1;
    $scope.name = $stateParams.query;
    $scope.courseType = 'activeCourse';

    const loadCoursePath = (all, onGoing, archive, isDelete) => {
      const query = {
        userId: _.parseInt($scope.userinfo.uid),
        all: all,
        onGoing: onGoing,
        unStarted: true,
        ended: true,
        archived: archive,
        delete: isDelete
      };
      $scope.coursePaths = oedCoursePath.queryFilter(query);
      return $scope.coursePaths.$promise;
    };

    const showEmptyGuideTip = () => {
      if ($scope.destoried) {
        return;
      }
      $uibModal.open({
        template: require('assets/app2/manage/emptyCoursePathGuide.html'),
        controller: 'emptyCoursePathGuideCtrl',
        size: 'sm',
        windowClass: 'oed-common-modal tip-modal',
        resolve: {
          type: function() {
            return type;
          },
        }
      });
    };

    $scope.$on('$destroy', function() {
      $scope.destoried = true;
    });

    const showPrepareLessonGuideTip = (cp) => {
      const dlg = $uibModal.open({
        template: require('assets/app2/manage/prepareLessonGuide.html'),
        controller: 'prepareLessonGuideCtrl',
        size: 'sm',
        windowClass: 'oed-common-modal center',
        resolve: {
          type: function() {
            return type;
          },
        }
      });

      dlg.result.then((data) => {
        if (type === 'examination') {
          $state.go('examination.testPaperList', {cpId: cp.id});
        } else if (_.get(data, 'toNewUrl', '') === 'resCenter') {
          $state.go('resCenter');
        } else {
          if(data.toNewUrl === 'prepareLesson2') {
            $state.go('prepareLesson2', {cpId: cp.id});
          } else {
            $state.go('prepareLesson.courseList', {cpId: cp.id});
          }

        }
      });
    }

    userInfo.then(function(info) {
      $scope.userinfo = info;
      loadCoursePath(false, true, false).then(() => {
        if (_.isEmpty($scope.coursePaths)) {
          showEmptyGuideTip()
        }
      });
    });

    $scope.selectCourseScope = function() {
      if ($scope.courseType == 'activeCourse') {
        loadCoursePath(false, true, false, false);
      }
      if ($scope.courseType == 'archiveCourse') {
        loadCoursePath(false, true, true, false);
      }
      if ($scope.courseType == 'deleteCourse') {
        loadCoursePath(false, true, true, true);
      }
    };

    $scope.addCourse = function(existingCourses) {
      var cp = {};
      var dlg = $uibModal.open({
        template: require('assets/templates/manage/createCourseDialog.html'),
        controller: 'createCourseCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          coursePath: function() {
            return cp;
          },
          existing: function() {
            return existingCourses;
          },
          copy: null
        }
      });

      dlg.result.then(function(data) {
        loadCoursePath(false, true, false).then(() => {
          $scope.courseType = 'activeCourse';
          showPrepareLessonGuideTip(data.cp);
        })
      });
    };
    $scope.copyCourse = function(existingCourses) {
      var cp = {};
      var dlg = $uibModal.open({
        template: require('assets/templates/manage/createCourseDialog.html'),
        controller: 'createCourseCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          coursePath: function() {
            return existingCourses;
          },
          existing: function() {
            return null;
          },
          copy: function() {
            return true;
          }
        }
      });

      dlg.result.then(function(cp) {
        $scope.courseType = 'activeCourse';
        loadCoursePath(false, true, false)
      });
    };

    $scope.addExistingCourse = function() {
      var allCoursePaths = oedCoursePath.queryFilter({
        userId: _.parseInt($scope.userinfo.uid),
        all: true,
        onGoing: true,
        unStarted: true,
        ended: true
      });

      $scope.loading = allCoursePaths.$promise.then(function() {
        if (allCoursePaths.length === 0) {
          return notificationService.notify('info', '您还没有任何课程，请点击"新建课程"创建')
        }
        $scope.addCourse(allCoursePaths);
      });
    };

    $scope.hoverCourse = function(item, obj) {
      item.visibility = 'visible';
    };

    $scope.hoverOutOfCourse = function(item, obj) {
      item.visibility = 'hidden';
    };
    $scope.editCourse = function(cp) {
      var dlg = $uibModal.open({
        template: require('assets/templates/manage/createCourseDialog.html'),
        controller: 'createCourseCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          coursePath: function() {
            return cp;
          },
          existing: null,
          copy: null
        }
      });

      dlg.result.then(function(newCp) {
        if (newCp.type) {
          if (newCp.type == 'ok') {
            _.assign(cp, newCp.cp);
          } else if (newCp.type == 'archive') {
            $scope.archiveCourse(newCp.cp);
          } else if (newCp.type == 'unarchive') {
            $scope.unArchiveCourse(newCp.cp);
          } else if (newCp.type == 'copy') {
            $scope.copyCourse(newCp.cp);
          } else if (newCp.type == 'delete') {
            $scope.deleteCourse(newCp.cp);
          }
        }
      });

    };

    $scope.importContentsFromExisting = function(cp) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/manage/importFromCoursePathsDialog.html'),
        controller: 'importFromCoursePathsDialogCtrl',
        size: 'md',
        resolve: {
          coursePath: function() {
            return cp;
          }
        }
      });
    };

    $scope.now = moment();

    $scope.isOnGoing = function(cp) {
      var dtStart = moment(cp.startDate);
      var dtEnd = moment(cp.endDate);

      return dtStart.isBefore($scope.now) && $scope.now.isBefore(dtEnd);
    };

    $scope.isUnStarted = function(cp) {
      var dtStart = moment(cp.startDate);
      return $scope.now.isBefore(dtStart);
    };

    $scope.isEnded = function(cp) {
      var dtEnd = moment(cp.endDate);
      return dtEnd.isBefore($scope.now);
    };

    $scope.canStart = function(cp) {
      return $scope.isUnStarted(cp);
    };

    $scope.canEnd = function(cp) {
      return $scope.isOnGoing(cp);
    };

    $scope.startCourse = function(cp) {
      var dlg = dialogs.confirm('开始课程', '你确定要手动开始此课程吗？');
      dlg.result.then(function() {
        cp.$startCourse();
      });
    };

    $scope.endCourse = function(cp) {
      var dlg = dialogs.confirm('结束课程', '你确定要手动结束此课程吗？');
      dlg.result.then(function() {
        cp.$endCourse();
      });
    };

    $scope.hoverCourseList = function(cp) {
      cp.showBtn = true;
    };

    $scope.hoverOutOfCourseList = function(cp) {
      cp.showBtn = false;
    };

    $scope.deleteCourse = function(cp) {
      var toDeleteObj = _.find($scope.coursePaths, function(o) {
        return o.id === cp.id;
      });
      var dlg = dialogs.confirm('删除课程', '你确定要删除此课程吗？');
      dlg.result.then(function() {
        toDeleteObj.$delete().then(function() {
          _.remove($scope.coursePaths, (coursePath) => {
            return coursePath.id == cp.id;
          });
        });
      });
    };

    $scope.archiveCourse = function(cp) {
      var dlg = dialogs.confirm('归档课程', '你确定要归档此课程吗？');
      dlg.result.then(function() {
        $scope.loading = oedCoursePath.manageCoursePathArchive({
          id: cp.id,
          flag: 1
        }).$promise.then(function() {
          notificationService.notify('info', '归档成功')
            _.remove($scope.coursePaths, (coursePath) => {
              return coursePath.id == cp.id;
            });
          }).catch(function() {
            $scope.joinErrorMessage = '发生未知错误';
          });
      });
    };

    $scope.unArchiveCourse = function(cp) {
      var dlg = dialogs.confirm('取消归档', '你确定要取消此课程的归档吗？');
      dlg.result.then(function() {
        $scope.loading = oedCoursePath.manageCoursePathArchive({
          id: cp.id,
          flag: 0
        }).$promise.then(function() {
          notificationService.notify('info', '取消归档成功')
          $state.go('manage.manageCourses', {}, {reload: true});
        }).catch(function() {
          $scope.joinErrorMessage = '发生未知错误';
        });
      });
    };
    $scope.getTeachingMaterialName = function(cp) {
      if (!cp.versionName || !cp.gradeName) {
        return '';
      }

      return cp.versionName + cp.gradeName;
    };

    $scope.finalDeteleCourse = function (cp) {
      var dlg = dialogs.confirm('确定彻底删除?', '确定要彻底删除课程: "' + cp.name + '"吗?');
      dlg.result.then(function(btn) {
        $scope.loading = oedCoursePath.finalDeleteOrRecoveryCoursePath({
          id: cp.id,
          flag: true
        }).$promise.then(function(res) {
          $scope.selectCourseScope();
          notificationService.notify('info', '删除成功');
        }).catch(function() {
          $scope.joinErrorMessage = '发生未知错误';
        });
      });
    };
    $scope.recoveryDeleteCourse = function (cp) {
      var dlg = dialogs.confirm('确定恢复?', '确定要恢复课程: "' + cp.name + '"吗?');
      dlg.result.then(function(btn) {
        $scope.loading = oedCoursePath.finalDeleteOrRecoveryCoursePath({
          id: cp.id,
          flag: false
        }).$promise.then(function(res) {
          $scope.selectCourseScope();
          notificationService.notify('info', '恢复成功');
        }).catch(function() {
          $scope.joinErrorMessage = '发生未知错误';
        });
      });
    };
  }

  createCourseCtrl.$inject = ['$scope', 'userInfo', '$uibModalInstance', '$q', 'dialogs', 'oedUserClass',
    'oedSubjects', 'oedTeachingMaterialVersions', 'oedCoursePath', 'coursePath', 'existing', 'copy', '$window']
  function createCourseCtrl($scope, userInfo, $uibModalInstance, $q, dialogs, oedUserClass,
    oedSubjects, oedTeachingMaterialVersions, oedCoursePath, coursePath, existing, copy, $window) {
    $scope.subjects = [];
    $scope.classes = [];
    $scope.globalVersions = oedCoursePath.getAllVersions();
    $scope.globalLgvs = oedCoursePath.getAllLgvs();
    $scope.globalBooks = oedCoursePath.getAllBooks();
    $scope.editionList = []; //版本list
    // 判断顶层窗口是否为iframe(配合桌面端使用)
    $scope.topWindowIsIframe = $window.top === $window.self;

    if (existing !== null) {
      $scope.allCoursePaths = existing;
      $scope.fromExisting = true;
    } else {
      $scope.fromExisting = false;
    }

    //
    // make a copy
    $scope.courseInfo = _.assign({}, coursePath);

    if (copy !== null) {
      $scope.copyMode = true;
      $scope.courseInfo.oldname = $scope.courseInfo.name;
      $scope.courseInfo.name = $scope.courseInfo.name + '2';
    } else {
      $scope.copyMode = false;
    }

    $scope.courseInfo.startDate = moment($scope.courseInfo.startDate).toDate();
    var endDate = $scope.courseInfo.endDate ?
      moment($scope.courseInfo.endDate).toDate() :
      moment($scope.courseInfo.startDate).add(5, 'months').toDate();
    $scope.courseInfo.endDate = endDate;

    $scope.$watch('courseInfo.endDate', function(newVal) {
      $scope.startDateOpt = {
        maxDate: newVal
      };
    });

    $scope.$watch('courseInfo.startDate', function(newVal) {
      $scope.endDateOpt = {
        minDate: newVal
      };
    });

    userInfo.then(function(info) {
      $scope.subjects = oedSubjects.queryBySchoolId({
        schoolId: info.schoolId
      });

      if ($scope.courseInfo.subjectId) {
        $scope.subjects.$promise.then(function(subjects) {
          $scope.courseInfo.subject = _.find(subjects, function(sub) {
            return sub.id == $scope.courseInfo.subjectId;
          });

          $scope.versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
            schoolId: info.schoolId,
            subjectId: $scope.courseInfo.subject.id
          });

          $scope.versions.$promise.then(function(versions) {
            $scope.courseInfo.teachingMaterial = _.find(versions, function(ver) {
              return ver.gradeId == $scope.courseInfo.gradeId &&
                ver.versionId == $scope.courseInfo.versionId &&
                ver.subjectId == $scope.courseInfo.subjectId;
            });
          });

          return $scope.versions.$promise;
        });
      }

      $scope.classes = oedUserClass.queryByUser({
        userId: info.uid
      });

      $scope.isEdit = function() {
        return !!$scope.courseInfo.id;
      };

      $scope.isCopyMode = function() {
        return !!$scope.copyMode;
      };

      $scope.isArchive = function() {
        return !!$scope.courseInfo.isArchive;
      };

      $scope.isCopied = function() {
        return $scope.courseInfo.copyFromCp && $scope.courseInfo.copyFromCp.id;
      };

      $scope.isSelected = function() {
        return $scope.courseInfo.copyFromCp !== null && $scope.courseInfo.copyFromCp !== undefined;
      };

      $scope.classes.$promise.then(function(classes) {
        $scope.courseInfo.classess = _($scope.courseInfo.classIds).map(function(clsId) {
          return _.find(classes, function(cls) {
            return cls.id == clsId;
          });
        }).reject(function(v) {
          return !v;
        }).value();
      });

      $scope.loading = $q.all([$scope.subjects.$promise, $scope.classes.$promise]);

      $scope.onSubjectSelected = function(item, model) {
        $scope.courseInfo.teachingMaterial = null;
        $scope.courseInfo.edition = null;
        $scope.versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
          schoolId: info.schoolId,
          subjectId: item.id
        });
        $scope.loading = $scope.versions.$promise;
        $scope.reloadVersion(item)
        $scope.courseInfo.name = ''
      };
      // 加载对应学科版本方法
      $scope.reloadVersion = function(subJect) {
        var t = subJect;
        const glvs = _.uniq(_.map(_.filter($scope.globalLgvs, s => s.subjectId === t.id), t => t.versionId));
        $scope.editionList = _.filter($scope.globalVersions, s => _.includes(glvs, s.versionId));
      };
      // 选择-版本onchange方法
      $scope.onEditionSelected = function(item, model){
        $scope.courseInfo.teachingMaterial = null;
        var t = $scope.courseInfo.edition;
        const glvs = _.uniq(_.map(_.filter($scope.globalLgvs, s => s.subjectId === $scope.courseInfo.subject.id && s.versionId === t.versionId), t => t.gradeId));
        let versions = _.filter($scope.globalBooks, s => _.includes(glvs, s.gradeId));
        groupByOrd(versions)
        $scope.courseInfo.name = ''
      }
      // 分组排序
      var groupByOrd = function(versions) {
        // 正常
        var normalList = []
        // 选修
        var electiveList = []
        // 必修
        var compulsoryList = []
        // 选择性必修
        var optionalCompulsoryList = []
        // 其他
        var oList = []
        var patten = /[一｜二｜三｜四｜五｜六｜七｜八｜九]/g
        // 正常 patten
        var normaPatten = /^[一｜二｜三｜四｜五｜六｜七｜八｜九]/
        // 选修 patten
        var electivePatten = /^选修/
        // 必修 patten
        var compulsoryPatten = /^必修/
        // 必修 patten
        var optionalCompulsoryPatten = /^选择性必修/
        var myMap = new Map();
        myMap.set('一', 1);
        myMap.set('二', 2);
        myMap.set('三', 3);
        myMap.set('四', 4);
        myMap.set('五', 5);
        myMap.set('六', 6);
        myMap.set('七', 7);
        myMap.set('八', 8);
        myMap.set('九', 9);
        // 进行分组
        versions.forEach(item => {
          if (normaPatten.test(item.gradeName)) {
            normalList.push(item)
          } else if (optionalCompulsoryPatten.test(item.gradeName)){
            optionalCompulsoryList.push(item)
          } else if (electivePatten.test(item.gradeName)){
            electiveList.push(item)
          } else if (compulsoryPatten.test(item.gradeName)){
            compulsoryList.push(item)
          } else {
            oList.push(item)
          }
        });
        // 分别排序
        normalList.sort((n1,n2)=>{
          // n2 是后面的值 n1 是前面的值
          let head1 = myMap.get(n1.gradeName.match(patten)? n1.gradeName.match(patten)[0]:0);
          let head2 = myMap.get(n2.gradeName.match(patten)? n2.gradeName.match(patten)[0]:0);
          if(head1 !== head2){
            return head1 - head2
          } else {
            let sw = n2.gradeName.search('上') !== -1
            if(sw){
              return 0
            }
            return -1
          }
        })
        electiveList.sort((n1,n2)=>{
          let head1 = myMap.get(n1.gradeName.match(patten)? n1.gradeName.match(patten)[0]:0);
          let head2 = myMap.get(n2.gradeName.match(patten)? n2.gradeName.match(patten)[0]:0);
          return head1 - head2
        })
        compulsoryList.sort((n1,n2)=>{
          let head1 = myMap.get(n1.gradeName.match(patten)? n1.gradeName.match(patten)[0]:0);
          let head2 = myMap.get(n2.gradeName.match(patten)? n2.gradeName.match(patten)[0]:0);
          return head1 - head2
        })
        oList.sort((n1,n2)=>{
          let head1 = myMap.get(n1.gradeName.match(patten)? n1.gradeName.match(patten)[0]:0);
          let head2 = myMap.get(n2.gradeName.match(patten)? n2.gradeName.match(patten)[0]:0);
          return head1 - head2
        })
        $scope.versions = [...normalList,...oList, ...compulsoryList, ...electiveList,...optionalCompulsoryList];
      }

      $scope.onCoursePathSelected = function(cp, model) {
        if (_.isEmpty($scope.courseInfo.name)) {
          $scope.courseInfo.name = cp.name;
        }

        $scope.courseInfo.subject = _.find($scope.subjects, function(sub) {
          return sub.id == cp.subjectId;
        });

        $scope.versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
          schoolId: info.schoolId,
          subjectId: $scope.courseInfo.subject.id
        });

        $scope.versions.$promise.then(function(versions) {
          $scope.courseInfo.teachingMaterial = _.find(versions, function(ver) {
            return ver.gradeId == cp.gradeId && ver.versionId == cp.versionId && ver.subjectId == cp.subjectId;
          });
        });

        $scope.loading = $scope.versions.$promise;
      };

    }); //userInfo.then

    var courseNameTmpl = _.template('<%=version.versionName%> <%= version.name%>');

    $scope.onVersionSelected = function(item, model) {
      $scope.courseInfo.name = $scope.courseInfo.edition.versionName+' '+$scope.courseInfo.subject.subjectName+$scope.courseInfo.teachingMaterial.gradeName
    };

    $scope.getTeachingMaterialList = function(val) {
      return _.filter($scope.versions, function(v) {});
    };

    $scope.clearCoursePath = function(event) {
      event.stopPropagation();
      $scope.courseInfo.copyFromCp = null;
    };

    var checkTeachingMaterialChanged = function() {
      if ($scope.isEdit()) {
        var oldCp = _(coursePath);
        var tmProps = ['gradeId', 'subjectId', 'versionId'];
        var newInfo = {};
        if ($scope.courseInfo.subject) {
          newInfo.subjectId = $scope.courseInfo.subject.id;
        }

        if ($scope.courseInfo.teachingMaterial) {
          var tm = $scope.courseInfo.teachingMaterial;
          newInfo.gradeId = tm.gradeId;
          newInfo.versionId = tm.versionId;
        }

        if (!oldCp.pick(tmProps).isEqual(newInfo)) {
          return dialogs.confirm('修改教材', '改教材后备课的章节关联信息将被重置，是否确定修改?').result;
        }
      }

      return true;
    };
    $scope.deleteCourse = function() {
      $uibModalInstance.close({cp: $scope.courseInfo, type: 'delete'});
    };
    $scope.archiveCourse = function() {
      $uibModalInstance.close({cp: $scope.courseInfo, type: 'archive'});
    };
    $scope.unArchiveCourse = function() {
      $uibModalInstance.close({cp: $scope.courseInfo, type: 'unarchive'});
    };
    $scope.copyCourse = function() {
      $uibModalInstance.close({cp: $scope.courseInfo, type: 'copy'});
    };
    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.createCourseForm.$invalid) {
        return;
      }
      $q.when(checkTeachingMaterialChanged()).then(function() {
        var courseInfo;
        if ($scope.copyMode) {
          courseInfo = _.pick($scope.courseInfo, ['name']);
        } else {
          courseInfo = _.pick($scope.courseInfo, ['id', 'name']);
        }
        if ($scope.courseInfo.subject) {
          courseInfo.subjectId = $scope.courseInfo.subject.id;
        }

        if ($scope.courseInfo.copyFromCp) {
          courseInfo.copyFromCpId = $scope.courseInfo.copyFromCp.id;
        }

        //
        // 转换成开始日期的0点
        courseInfo.startDate = moment(moment($scope.courseInfo.startDate).format('YYYYMMDD'), 'YYYYMMDD').toDate();
        //
        // 转换成结束日期的最后一秒
        // 这里需要服务器时区和浏览器时区一致
        courseInfo.endDate = moment(moment($scope.courseInfo.endDate)
          .format('YYYYMMDD 23:59:59'),
          'YYYYMMDD hh:mm:ss').toDate();

        if ($scope.courseInfo.teachingMaterial) {
          var tm = $scope.courseInfo.teachingMaterial;
          courseInfo.gradeId = tm.gradeId;
          courseInfo.versionId = $scope.courseInfo.edition.versionId;
        }

        courseInfo.classIds = _.map($scope.courseInfo.classess, function(cls) {
          return cls.id;
        });

        var cp = new oedCoursePath(courseInfo);
        $scope.loading = cp.$save();
        $scope.loading.then(function(cp) {
          $uibModalInstance.close({cp, type: 'ok'});
        });
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.now = moment();
    $scope.groupByStatus = function(item) {
      if (item.isUnStarted($scope.now)) {
        return '未开始';
      }
      if (item.isOnGoing($scope.now)) {
        return '进行中';
      }

      return '已完成';
    };
    $scope.groupByArchive = function(item) {
      if (item.isArchive == 1) {
        return '已归档';
      } else {
        return '进行中';
      }
    };

    $scope.openStart = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startOpened = true;
      $scope.endOpened = false;
    };

    $scope.openEnd = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startOpened = false;
      $scope.endOpened = true;
    };

    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };
  }
})();
