import { Component, Input, OnInit } from '@angular/core';
import { TeachingModuleService } from 'app/lesson/services/teaching-module.service';
import { TeachingModuleDTO } from '../../models/lesson';

@Component({
  selector: 'app-module-header',
  template: require('./module-header.component.html'),
  styles: [require('./module-header.component.scss')]
})
export class ModuleHeaderComponent implements OnInit {

  @Input() public module: TeachingModuleDTO
  @Input() public disable: boolean
  @Input() public courseId: number
  constructor(
    private teachingModuleService: TeachingModuleService,
  ) { }

  public ngOnInit() { }

  private editModuleInfo() {
    this.teachingModuleService.editModuleInfo(this.disable, this.module)
    .subscribe((teachingModule: TeachingModuleDTO) => {
      this.module = teachingModule
    });
  }

  private deleteModule() {
    this.teachingModuleService.deleteModule(this.disable, this.module)
  }

}
