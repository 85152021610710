import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-office-upload-tip',
  template: require('./office-upload-tip.component.html'),
  styles: [require('./office-upload-tip.component.scss')]
})
export class OfficeUploadTipComponent implements OnInit {
  @Output() public action = new EventEmitter<any>();
  private files: any
  
  constructor(public bsModalRef: BsModalRef) { }
  
  ngOnInit() {
  }
  
  public ok = () => {
    this.action.emit(this.files)
    this.bsModalRef.hide()
  }
  
  public close = () => {
    this.bsModalRef.hide()
  }

}
