export function getQuestionTypeLabel(type) {
  switch (type) {
    case 'singlechoice' :
      return '单选';
    case 'multichoice':
      return '多选';
    case 'fill':
      return '填空';
    case 'connect':
      return '匹配';
    case 'yesorno':
      return '判断';
    case 'singlevote':
      return '投票';
    case 'multivote':
      return '投票';
    case 'synthetic':
      return '综合';
    case 'qa':
      return '问答';
  }
  return '';
}
