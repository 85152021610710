import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {
  OnlineTrainingContentDTO, OnlineTrainingContentLinkDTO,
  OnlineTrainingDirectoryDTO
} from 'app/online-train/models/online-train';
import * as _ from 'lodash';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import {concat, forkJoin, Observable, of, zip} from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { StateService } from '@uirouter/core'
import {TrainMassageService} from 'app/online-train/train-massage.service';
import {AppStateService} from 'app/core/app-state.service';

@Component({
  selector: 'app-train-content',
  template: require('./train-content.component.html'),
  styles: [require('./train-content.component.scss')]
})
export class TrainContentComponent implements OnInit {
  @Input() public params: any
  private dirs: OnlineTrainingDirectoryDTO[]
  private parentId
  private editable
  private contentNav: OnlineTrainingDirectoryDTO[]
  private dirTree

  private dataToAdd1: OnlineTrainingContentDTO
  private itemType1
  private itemType2
  private linkToAdd: OnlineTrainingContentLinkDTO
  private itemTypeLink

  private content1: OnlineTrainingContentDTO[]
  private content2: OnlineTrainingContentDTO[]
  private contents: OnlineTrainingContentDTO[]
  private links: OnlineTrainingContentLinkDTO[]

  private canStar
  constructor(private onlineTrainService: OnlineTrainService,
              private state: StateService,
              private userInfo: AppStateService,
              private trainMessage: TrainMassageService) { }

  ngOnInit() {
    this.parentId = _.parseInt(this.params.parentId)
    this.editable = this.params.editable
    this.dirs = this.params.dirs
    this.contentNav = []
    if (!_.isEmpty(this.dirs)) {
      this.buildNav()
    } else {
      this.onlineTrainService.getAllDirs().subscribe((dirs) => {
        this.dirs = _.filter(dirs, (dir) => dir.id > 1)
        this.buildNav()
      }, (err) => {console.log(err)})
    }

    this.userInfo.then((info) => {
      this.canStar = info.canStar
    })

    this.dataToAdd1 = {
      dirId: this.parentId,
      resourceId: -1,
      name: '',
      sort: 1,
      deleted: false
    }

    this.itemType1 = this.onlineTrainService.CONTENT1

    this.itemType2 = this.onlineTrainService.CONTENT2

    this.linkToAdd = {
      dirId: this.parentId,
      linkId: -1,
      sort: 0,
      deleted: false
    }
    this.itemTypeLink = this.onlineTrainService.CONTENTLINK

    this.loadData().subscribe(() => {
    }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
  }

  public content2Empty() {
    return _.isEmpty(this.content2)
  }
  public onEdit1(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      if (_.isEmpty(params.item) || params.item[0]['sort'] !== 1) {
        return
      }
      this.onlineTrainService.addContent(params.item[0])
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      this.onlineTrainService.updateContent(params.item)
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
    } else if (params.editType === this.onlineTrainService.DELETE) {
      this.onlineTrainService.deleteContent(params.item.id)
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
        if (_.isEmpty(this.getContent1())) {
          this.content1 = null
        }
      }, (e) => {console.log(e)}, () => this.trainMessage.sendLoadingAction(false))
    }
  }

  public onEdit2(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      if (_.isEmpty(params.item) || params.item[0]['sort'] === 1) {
        return
      }
      const requestsOfAdd = _.map(params.item, (item) => this.onlineTrainService.addContent(item))
      forkJoin(...requestsOfAdd)
        .pipe(
          flatMap(() => {
            if (!_.isEmpty(this.content2) && params.item[0].name !== this.content2[0].name) {
              const contentsToUpdate = _(this.content2).filter((c: any) => c.name !== params.item[0].name)
                                                      .map((c) => {
                                                        c.name = params.item[0].name
                                                        return this.onlineTrainService.updateContent(c)
                                                      }).value()
              return forkJoin(...contentsToUpdate)
            } else {
              return of(0)
            }
          }),
          flatMap(() => {
            return this.onlineTrainService.getContentsByDirId(this.parentId)
          }),
          flatMap((contents) => {
            this.contents = contents
            if (_.isEmpty(this.contents)) {
              return of([])
            } else {
              const req = _.map(this.contents, (c) => this.onlineTrainService.getResource(c.resourceId))
              return forkJoin(...req)
            }
          }),
          map((reses) => {
            if (!_.isEmpty(reses)) {
              this.onlineTrainService.enhanceContent(this.contents, reses)
              this.content2 = this.getContent2()
            }
            return of(0)
          })
          ).subscribe()
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      this.onlineTrainService.updateContent(params.item)
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
    } else if (params.editType === this.onlineTrainService.DELETE) {
      this.onlineTrainService.deleteContent(params.item.id)
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
        if (_.isEmpty(this.getContent2())) {
          this.content2 = null
        }
      }, (e) => {console.log(e)}, () => this.trainMessage.sendLoadingAction(false))
    } else if (params.editType === this.onlineTrainService.UP) {
      this.up(params.item)
    } else if (params.editType === this.onlineTrainService.DOWN) {
      this.down(params.item)
    }
  }

  public onEditLink(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      const datas = this.buildLinks(params.linkDirIds)
      if (_.isEmpty(datas)) {
        return of(0)
      }

      const requests = _.map(datas, (data) => this.onlineTrainService.addContentLink(data))
      zip(...requests).pipe(flatMap(() => this.loadData())).subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
    } else if (params.editType === this.onlineTrainService.DELETE) {
      this.onlineTrainService.deleteContentLink(params.item.id)
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
    }
  }

  public showAddEdit(num) {
    if (num === 1) {
      return this.editable && _.isEmpty(this.getContent1())
    }

    if (num === 2) {
      return this.editable
    }
  }

  public goto(id) {
    const prefix = this.findPrefix(id)
    this.state.go('onlineTrain.content', {
      parentId: id,
      editable: this.editable,
      dirs: this.dirs,
      prefix});
  }

  public getLinkText(link) {
    const dir = _.find(this.dirs, (dir) => dir.id === link.linkId)
    return _.isEmpty(dir) ? '' : dir.name
  }

  public getDataToAddForContent2() {
    const data = {
      dirId: this.parentId,
      resourceId: -1,
      name: '',
      sort: 2,
      deleted: false
    }

    if (!_.isEmpty(this.content2)) {
      data.name = this.content2[0].name
    }
    return data
  }

  public textType(content) {
    return this.onlineTrainService.textType(content.$$resource)
  }

  public imgType(content) {
    return this.onlineTrainService.imgType(content.$$resource)
  }

  public showEditBtn() {
    return this.canStar
  }

  public toggleEdit() {
    this.editable = !this.editable
  }

  private getContent1() {
    return _.filter(this.contents, (c) => c['sort'] === 1)
  }

  private getContent2() {
    return _.sortBy(_.filter(this.contents, (c) => c['sort'] > 1), (c) => c.sort)
  }

  private buildNav() {
    this.dirTree = this.onlineTrainService.buildDirTree(this.dirs)
    let parentDir = _.find(this.dirs, (dir) => dir.id === this.parentId)
    while (parentDir && parentDir.id && parentDir.id > 1) {
      this.contentNav.push(parentDir)
      parentDir = _.find(this.dirs, (dir) => dir.id === parentDir.dirId)
    }

    this.contentNav = _.reverse(this.contentNav)
  }

  private findPrefix(id) {
    let parentDir = _.find(this.dirs, (dir) => dir.id === id)
    while (parentDir && parentDir.id && parentDir.dirId > 1) {
      parentDir = _.find(this.dirs, (dir) => dir.id === parentDir.dirId)
    }

    return this.onlineTrainService.lvl1MetaDatasMap[parentDir.id].prefix
  }

  private loadData() {
    this.trainMessage.sendLoadingAction(true)
    return forkJoin(this.onlineTrainService.getContentsByDirId(this.parentId),
      this.onlineTrainService.getContentLinksByDirId(this.parentId),
      )
      .pipe(
      flatMap((v) => {
        this.contents = v[0]
        this.links = v[1]
        if (_.isEmpty(this.contents)) {
          return of([])
        } else {
          const req = _.map(this.contents, (c) => this.onlineTrainService.getResource(c.resourceId))
          return forkJoin(...req)
        }
      }),
      map((reses) => {
        if (!_.isEmpty(reses)) {
          this.onlineTrainService.enhanceContent(this.contents, reses)
          this.content1 = this.getContent1()
          this.content2 = this.getContent2()
        }
        return of(0)
      })
    )
  }

  private buildLinks(linkDirIds) {
    const leafDirs = _.filter(linkDirIds, (id) => _.isEmpty(this.dirTree[id]))
    const linksToAdd = _.filter(leafDirs, (id) => _.isEmpty(_.find(this.links, (link) => link.linkId === id)))
    return _.map(linksToAdd, (linkId) => {
      const data = _.clone(this.linkToAdd)
      data.linkId = linkId

      const maxSortLink = _.maxBy(this.links, (link) => link['sort'])
      data['sort'] = maxSortLink ? maxSortLink['sort'] + 1 : 0
      return data
    })
  }

  private up(item) {
    const idx = _.findIndex(this.content2, item)
    if (idx <= 0) {
      return
    }

    const item1 = this.content2[idx]
    const item2 = this.content2[idx - 1]
    this.onlineTrainService.swapSort(item1, item2)

    this.trainMessage.sendLoadingAction(true)
    forkJoin(this.onlineTrainService.updateContent(item1), this.onlineTrainService.updateContent(item2))
      .subscribe(() => this.onlineTrainService.swap(idx, idx - 1, this.content2),
        () => this.onlineTrainService.swapSort(item1, item2), () => this.trainMessage.sendLoadingAction(false))
  }

  private down(item) {
    const idx = _.findIndex(this.content2, item)
    if (idx >= _.size(this.content2) - 1) {
      return
    }

    const item1 = this.content2[idx]
    const item2 = this.content2[idx + 1]
    this.onlineTrainService.swapSort(item1, item2)
    this.trainMessage.sendLoadingAction(true)
    forkJoin(this.onlineTrainService.updateContent(item1), this.onlineTrainService.updateContent(item2))
      .subscribe(() => this.onlineTrainService.swap(idx, idx + 1, this.content2),
        () => this.onlineTrainService.swapSort(item1, item2), () => this.trainMessage.sendLoadingAction(false))
  }
}
