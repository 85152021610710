require('assets/app2/directives/question/questionPreviewer.directive')
angular.module('app.directives.question.exerciseQuestionPreviewer', [                                                                                                                                              
'app.directives.question.questionPreviewer'
])
  .component('exerciseQuestionPreviewer', {
    bindings: {
        questionModel: '=',
        qHidePoint: '=',
        qCloudRes: '=',
        qIndex: '=',
        parentIndex: '=',
        qReadonly: '=',
        scoreChanged: '&onScoreChanged',
        qShowAnswer: '=',
        qHideDifficult: '<',
        qShowAnswerDetails: '=',
        qShowCloudResId: '=',
        qShowCloudTestLink: '=',
        onImportClicked: '&',
        onGoToTestClicked: '&',
        ruanyun: '<',
        isExercise: '=',
        exKnowledgePoint: '=',
        exDifficult: '=',
        isNewVersion: '=',
    },
    controller: exerciseQuestionPreviewer,
    template: <string>require('./exerciseQuestionPreviewer.html'),
  })
  exerciseQuestionPreviewer.$inject = []
function exerciseQuestionPreviewer() {}
