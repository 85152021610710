import * as _ from 'lodash'
require('assets/app/controllers/examination/examReport/examReportChart/questionChart/questionChart.scss')
import { getExamQuesTotal, addTagForExamWhole } from 'assets/app/controllers/examination/examEdit/paper/paperUtils'
import { getBarColorByRate } from 'assets/app/controllers/examination/examReport/examReportChart/examChartUtils'

angular.module('app.examination.examReport.chart.question', [])
  .component('questionChart', {
    bindings: {
      examWhole: '<',
      examSearch: '<',
    },
    controller: questionChartCtrl,
    template: < string > require('./questionChart.html'),
  })

questionChartCtrl.$inject = []
function questionChartCtrl() {
  const ctrl = this
  const partIndex = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'examSearch')) {
      loadQuesChartData()
    }
  }

  function loadQuesChartData() {
    loadQuesPartData()
    loadQuesDataByTag()
  }

  function loadQuesPartData() {
    const questionPartData = _.get(ctrl.examSearch, 'questionPartData', [])
    const examPartDetails = _.get(ctrl.examWhole, 'examPartDetails', [])
    const details = _.map(examPartDetails, (d, i) => {
      _.set(d, 'part.score', getExamQuesTotal(examPartDetails, i))
      return d.part
    })
    const detailsMap = _.keyBy(details, detail => detail.id)
    const quesDataNotSort = _.map(questionPartData, data => {
      const partId = _.get(data, 'data.partId', 0)
      const scoreRate = _.get(data, 'data.scoreRate', 0)
      const detail = _.get(detailsMap, partId, {})
      if (_.isEmpty(detail)) {
        return {}
      }
      return {
        id: partId,
        sort: detail.sort,
        name: detail.name,
        score: detail.score,
        scoreRate,
        averageScore: _.round(detail.score * scoreRate, 1)
      }
    })
    ctrl.quesPartData = _.sortBy(quesDataNotSort, q => q.sort)
  }

  function loadQuesDataByTag() {
    ctrl.objQuesData = []
    ctrl.sbjQuesData = []
    const questionPartData = _.get(ctrl.examSearch, 'questionPartData', [])
    const partData = _.map(questionPartData, d => d.data.questionsData)
    const quesSearchDataMap = _.keyBy(_.flatten(partData), q => q.questionId)
    const examWholeWithTag = addTagForExamWhole(ctrl.examWhole)
    const examPartDetails = _.get(examWholeWithTag, 'examPartDetails', [])
    _.forEach(examPartDetails, (pd, pdIdx) => {
      _.forEach(_.get(pd, 'questionDetails', []), (qd, qdIdx) => {
        const isSync = _.get(qd, 'question.type', '') === 'synthetic'
        if (isSync) {
          _.forEach(_.get(qd, 'question.childQuestions', []), (child, childIdx) => {
            const quesNum = _.get(child, 'quesNum', '')
            setQuesData(quesNum, child, quesSearchDataMap)
          })
        } else {
          const quesNum = _.get(qd, 'question.quesNum', '')
          setQuesData(quesNum, _.get(qd, 'question', {}), quesSearchDataMap)
        }
      })
    })
    buildObjLabels()
    buildSbjLabels()
  }

  function buildObjLabels() {
    const choiceLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    ctrl.objLabels = []
    const allChoice = getAllChoice(ctrl.objQuesData)
    const sortedAllChoice = _.sortBy(_.sortBy(allChoice), c => c.length)
    ctrl.objLabels = _.map(sortedAllChoice, (choice, index) => {
      const label = _.map(_.split(choice, ','), item => choiceLabels[item]).join('')
      return { choice, label, index }
    })
  }

  function buildSbjLabels() {
    ctrl.sbjLabels = []
    const allChoice = getAllChoice(ctrl.sbjQuesData)
    ctrl.sbjLabels = _.sortBy(_.map(allChoice, c => Number(c)))
  }

  function getAllChoice(quesData: any[]) {
    const choices = _.map(quesData, data => {
      let scoreDetails = _.get(data, 'scoreDetails', [])
      scoreDetails = _.map(scoreDetails, detail => {
        return _.get(detail, 'score', '')
      })
      // 这个单独push是将选择的正确，但是没人选的选项添加进来，主观题不需要所以不加c
      if (!_.get(data, 'isSbj', false)) {
        scoreDetails.push(_.get(data, 'answer', ''))
      }
      return scoreDetails
    })
    return _.uniq(_.flatten(choices))
  }

  function setQuesData(quesNum: string, ques: any, dataMap: any) {
    const score = _.get(ques, 'point2', 0)
    const quesSearchData = _.get(dataMap, ques.id, {})
    const scoreRate = _.get(quesSearchData, 'scoreRate', 0)
    const scoreDetails = _.get(quesSearchData, 'scoreDetails', [])
    const answer = _.get(ques, 'answer', '')
    const quesType = _.get(ques, 'type', '')
    const choiceCount = _.size(_.get(ques, 'choices', 0))
    const isSbj = ques.isSbj
    const quesChartData = { quesNum, score, scoreRate, scoreDetails, answer, quesType, choiceCount, isSbj }
    if (ques.isSbj) {
      ctrl.sbjQuesData.push(quesChartData)
    } else if (quesType != 'connect') {
      ctrl.objQuesData.push(quesChartData)
    }
  }

  ctrl.getQuesName = (ques: any, index: number) => `${partIndex[index]}、${ques.name}`

  ctrl.getQuesBarRate = (rate: number) => `${_.round(rate * 100, 1)}%`

  ctrl.getBarColor = (rate: number) => getBarColorByRate(rate)

  ctrl.checkShowRightBtn = (label: any, quesData: any) => {
     return _.get(label, 'choice', '') === _.get(quesData, 'answer', '')
  }

  ctrl.getSbjChoiceCount = (label: any, quesData: any, index: number) => {
    const scoreDetails = _.get(quesData, 'scoreDetails', [])
    const maxScore = _.get(quesData, 'score', '')
    if (maxScore < label) {
      return '-'
    }
    for (let i = 0; i < scoreDetails.length; i++) {
      const detail = scoreDetails[i]
      const score = Number(_.get(detail, 'score', ''))
      if (score === label) {
        return `${_.get(detail, 'count', 0)}人`
      }
    }
    return '0人'
  }

  ctrl.getObjChoiceCount = (label: any, quesData: any, index: number) => {
    const scoreDetails = _.get(quesData, 'scoreDetails', [])
    for (let i = 0; i < scoreDetails.length; i++) {
      const detail = scoreDetails[i]
      const choice = _.get(detail, 'score', '')
      const choiceIndex = _.get(label, 'choice', '') === choice ? _.get(label, 'index', -1) : -1
      if (choiceIndex === index) {
        return `${_.get(detail, 'count', '')}人`
      }
    }
    if (_.get(quesData, 'quesType', '') === 'yesorno' && index > 1) {
      return '-'
    }
    if (_.get(quesData, 'quesType', '') === 'singlechoice' && index > (_.get(quesData, 'choiceCount', '') - 1)) {
      return '-'
    }
    return '0人'
  }
}
