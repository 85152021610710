/**
 * @fileOverview
 * @name studentSubjectiveTestPeerReviewAllStudentsCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentSubjectiveTestPeerReview.allStudentDetails', {
        url: '/allStudents',
        template: require('assets/templates/student/studentSubjectiveTestPeerReviewAllStudents.html'),
        controller: 'studentSubjectiveTestPeerReviewAllStudentsCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('studentSubjectiveTestPeerReviewAllStudentsCtrl', studentSubjectiveTestPeerReviewAllStudentsCtrl);

  studentSubjectiveTestPeerReviewAllStudentsCtrl.$inject = ['$scope', '$state', '$stateParams',
    '$q', 'dialogs', 'notify', 'Lightbox', 'userInfo', 'oedConfig', 'oedCoursePath', 'oedTaskList',
    'oedTask', 'oedTaskListPublishRecord', 'oedTaskTest', 'oedSubjectiveTest', 'oedTestSession',
    'oedTestSessionStudent', 'oedBoardSession', 'oedBoardContent', 'oedBoardContentComment',
    'oedSubjectiveTestSessionStats', 'oedUserInfo', 'oedBoardSessionStudent', 'oedUserInfoUtils', 'notificationService']
  function studentSubjectiveTestPeerReviewAllStudentsCtrl($scope, $state, $stateParams,
    $q, dialogs, notify, Lightbox, userInfo, oedConfig, oedCoursePath, oedTaskList,
    oedTask, oedTaskListPublishRecord, oedTaskTest, oedSubjectiveTest, oedTestSession,
    oedTestSessionStudent, oedBoardSession, oedBoardContent, oedBoardContentComment,
    oedSubjectiveTestSessionStats, oedUserInfo, oedBoardSessionStudent, oedUserInfoUtils, notificationService) {
    //
    // 注意这个controller也被教师端的view使用

    //
    // 学生id => 作品信息 的map
    $scope.allStudentInfo = {};

    $scope.answer = {
      commentContent: {}
    };

    function readContent(content) {
      var c = _.omit(content, 'contentBody');
      var body = {};
      if (content.contentBody) {
        body = angular.fromJson(content.contentBody);
      }
      c.body = body;

      var resItems = [];

      if (c.body.content) {
        resItems = [{
          fileType: 'text',
          text: c.body.content,
          textTrimed: _.truncate(c.body.content, {
            length: 30
          })
        }];
      }

      if (c.boardContentThumbnail) {
        resItems = resItems.concat({
          fileType: 'boardContent',
          id: c.id
        });
      }

      if (_.size(body.resources) > 0) {
        resItems = resItems.concat(body.resources);
      }

      c.resItems = resItems;
      return c;
    }

    function readComment(cmt) {
      var c = _.omit(cmt, 'commentBody');

      var body = {};
      if (cmt.commentBody) {
        body = angular.fromJson(cmt.commentBody);
      }
      c.body = body;

      c.body.resources = c.body.resources || [];
      // pad端添加的文字评论
      if (cmt.comment) {
        c.body.content = cmt.comment;
      }

      // pad端添加的图片评论
      if (cmt.imgResId) {
        c.body.resources = c.body.resources.concat({
          fileType: 'boardComment',
          id: cmt.imgResId
        });
      }

      if (!_.has($scope.userInfoMap, cmt.userId)) {
        $scope.userInfoMap[cmt.userId] = oedUserInfo.getByUid({
          id: cmt.userId
        });
      }

      return c;
    }

    function updateStats(studentId) {
      var stats = oedSubjectiveTestSessionStats.queryBySessionAndUserId({
        boardSessionId: $scope.boardSessionId,
        userId: studentId,
        includeBoardContent: false,
        includeBoardThumbnail: true,
        includeViewedBoards: false,
        includeBravoedBoards: false,
        includeCommentedBoards: false,
        includeBoardViews: true,
        includeBoardBravos: true,
        includeBoardComments: true,
        sortBy: 'bravosCount'
      });

      return stats.$promise.then(function(stats) {
        $scope.allStudentInfo[studentId].stats = stats;

        var contents = _.get(stats, 'board.contentList', []);
        if (!_.isEmpty(contents)) {
          $scope.isDetailsCollapsed = true;
        }
        stats.boardContents = _.map(contents, readContent);
        stats.boardComments = _.map(_.get(stats, 'board.commentsList', []), c => readComment(c.right));
        return stats;
      });
    }

    //
    // loading 来自parent scope
    $scope.loading.then(function() {
      //
      // 加载每个学生的作品和统计
      _.each($scope.students, function(stu) {
        var stats = updateStats(stu.uid);

        var loadBoardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
          sessionId: $scope.boardSessionId,
          studentId: stu.uid
        }).$promise;

        $scope.allStudentInfo[stu.uid] = $scope.allStudentInfo[stu.uid] || {};
        $scope.allStudentInfo[stu.uid].loading = $q.all([
            stats, loadBoardStudent
          ])
          .then(function(allInfo) {
            var contents = allInfo[0].boardContents;
            var cmts = allInfo[0].boardComments;
            var stats = allInfo[0];
            var boardStudent = allInfo[1];

            _.assign($scope.allStudentInfo[stu.uid], {
              boardContents: contents,
              boardComments: cmts.reverse(),
              stats: stats,
              boardStudent: boardStudent
            });
          });
      });
    });

    $scope.getStudentData = function(studentId) {
      return $scope.allStudentInfo[studentId];
    };

    function getAllContentRes(studentId) {
      var allContents = _.reduce($scope.allStudentInfo[studentId].boardContents, function(total, c) {
        return total.concat(c.resItems);
      }, []);

      return allContents;
    }

    userInfo.then(function(info) {
      $scope.uid = _.parseInt(info.uid);

      $scope.isSelfBravoed = function(studentId) {
        if (!_.get($scope.allStudentInfo, studentId + '.stats.board')) {
          return false;
        }

        return _.findIndex($scope.allStudentInfo[studentId].stats.board.bravosList, function(bravo) {
          return bravo.key.uid == $scope.uid;
        }) >= 0;
      };

      $scope.previewContentResource = function(studentId, curRes) {
        var allRes = getAllContentRes(studentId);
        var idx = _.findIndex(allRes, curRes);

        oedBoardContentComment.markReadBoardContent({
          boardContentId: $scope.allStudentInfo[studentId].boardContents[0].id,
          userId: $scope.uid
        }, {}).$promise.then(_.bind(updateStats, null, studentId));

        Lightbox.openModal(allRes, idx);
      };

      $scope.toggleBravo = function(studentId) {
        $scope.loading = oedBoardContentComment.toggleBravo({
          boardSessionId: $scope.boardSessionId,
          ownerId: studentId,
          userId: $scope.uid
        }, {}).$promise.then(_.bind(updateStats, null, studentId));
      };
    });

    $scope.openAddCommentPanel = function(studentId) {
      $scope.currentStudentId = studentId;

      $scope.showAddAnswerPane = false;
      $scope.showAddCommentPane = true;
    };

    $scope.cancelEditor = function() {
      $scope.showAddAnswerPane = false;
      $scope.showAddCommentPane = false;
    };

    $scope.submitComment = function(comment) {
      var curStudentId = $scope.currentStudentId;

      if (_.isEmpty($scope.allStudentInfo[curStudentId].boardContents)) {
        return notificationService.notify('error', '还没有提交作品, 不能添加评论')
      }

      var c = _.pick(comment, ['content', 'resources']);
      if (_.isEmpty(c.content) && _.isEmpty(c.resources)) {
        return notificationService.notify('error', '请填写评论内容')
      }

      var body = angular.toJson(c);

      var cmt = {
        boardContentId: $scope.allStudentInfo[curStudentId].boardContents[0].id,
        userId: $scope.uid,
        commentBody: body,
        createTime: moment().valueOf()
      };

      var cmtToAdd = oedBoardContentComment.save(cmt);
      $scope.loading = cmtToAdd.$promise.then(function(cmt) {
        $scope.answer.commentContent = {};
        $scope.showAddCommentPane = false;
        $scope.allStudentInfo[curStudentId].boardComments.unshift(readComment(cmt));

        $scope.allStudentInfo[curStudentId].boardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
          sessionId: $scope.boardSessionId,
          studentId: curStudentId
        });

        return $scope.boardStudent.$promise.then(_.bind(updateStats, null, curStudentId));
      });
    };

    $scope.submitCommentTeacher = function(comment) {
      var curStudentId = $scope.currentStudentId;
      if (_.isEmpty($scope.allStudentInfo[curStudentId].boardContents)) {
        return notificationService.notify('error', '该学生还没有提交作品, 不能添加评论')
      }

      var c = _.pick(comment, ['content', 'resources']);
      if (_.isEmpty(c.content) && _.isEmpty(c.resources)) {
        c = undefined;
      }

      if (_.isUndefined(comment.rank) && _.isUndefined(c)) {
        return notificationService.notify('error', '请填写评论内容')
      }

      c = c || {};
      c.rank = comment.rank;

      var body = angular.toJson(c);

      var cmt = {
        rank: comment.rank,
        comment: {
          boardContentId: $scope.allStudentInfo[curStudentId].boardContents[0].id,
          userId: $scope.uid,
          commentBody: body,
          createTime: moment().valueOf()
        }
      };

      var cmtToAdd = oedBoardContentComment.createCommentWithRank(cmt);
      $scope.loading = cmtToAdd.$promise.then(function(cmt) {
        $scope.answer.commentContent = {};
        $scope.showAddCommentPane = false;
        $scope.allStudentInfo[curStudentId].boardComments.unshift(readComment(cmt.comment));

        const boardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
          sessionId: $scope.boardSessionId,
          studentId: curStudentId
        });
        $scope.allStudentInfo[curStudentId].boardStudent = boardStudent

        return boardStudent.$promise.then(_.bind(updateStats, null, curStudentId));
      });
    };
  }
})();
