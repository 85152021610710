import angular = require('angular');
angular.module('app.preview.fullScreenPreviewer', [
]).config(['$stateProvider', ($stateProvider) => {
  $stateProvider.state('fullScreenPreviewer', {
    controller: 'fullScreenPreviewerCtrl',
    loadingCls: 'blueloading',
    template: require('./fullScreenPreviewer.html'),
    url: '/previewer?src',
  });
}]).controller('fullScreenPreviewerCtrl', fullScreenPreviewerCtrl)


fullScreenPreviewerCtrl.$inject = ['$stateParams', '$scope']
function fullScreenPreviewerCtrl($stateParams: any, $scope: any) {
  $scope.src = $stateParams.src
}
