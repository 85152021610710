import * as angular from 'angular';
import * as _ from 'lodash';

import Promise = require('bluebird');
const boardUtils: any = require('app2/utils/boardUtils');
const resourceUtils: any = require('app2/utils/resourceUtils');
require('app2/prepareCourse/cloud/subjectiveTest/subjectiveTestLibraryDialog.ctrl')
require('./newSubjectiveEditDialog.scss')
const {confirmAndDownloadSubjectiveTest, importSubjectTestModel} = require('app2/utils/testUtils2')

angular.module('app.newPrepareCourse2.newSubjectiveEditDialog',
  []).controller('newSubjectiveEditDialogCtrl', newSubjectiveEditDialogCtrl)

newSubjectiveEditDialogCtrl.$inject = [
  '$scope',
  'data',
  '$uibModalInstance',
  '$q',
  '$uibModal',
  'notificationService',
  'oedConfig',
  'oedCoursePath',
  'oedUnitItem',
  'oedSubjectiveTest',
  'localStorageService',
  'oedMisc',
  '$log',
  'oedFileUtils',
  'Upload',
  'dialogs',
  '$timeout',
  'oedCloudQuestion',
  'oedCourse',
  'Lightbox',
  'oedTestUtils',
  'oedCloudSubjectiveTest',
  'oedCourseUtils'
]
function newSubjectiveEditDialogCtrl(
  $scope,
  data: any,
  $uibModalInstance,
  $q,
  $uibModal,
  notificationService,
  oedConfig,
  oedCoursePath,
  oedUnitItem,
  oedSubjectiveTest,
  localStorageService,
  oedMisc,
  $log,
  oedFileUtils,
  Upload,
  dialogs,
  $timeout,
  oedCloudQuestion,
  oedCourse,
  Lightbox,
  oedTestUtils,
  oedCloudSubjectiveTest,
  oedCourseUtils
) {

  $scope.titleName = data.currentSubj.title;
  $scope.courseId = data.courseId;
  $scope.cancelClick = () => {
    $uibModalInstance.close(false);
  }
  $scope.$on('saveObjectiveTest', (e) => {
    if (!$scope.originalTest) {
      return;
    }

    //
    // 自动保存
    if (!angular.equals($scope.currentTest, $scope.originalTest) || $scope.dirty) {
      $scope.saveTest(true, true, true);
    }
  });

  $scope.versionStamp = 0;
  $scope.testId = data.currentSubj.id;
  $scope.currentTest = oedSubjectiveTest.get({
    id: $scope.testId
  });

  $scope.editorConfig = {
    serverUrl: oedConfig.url('ceditor/Test/') + $scope.testId
  };

  if ($scope.courseId) {
    if ($scope.courseId) {
      $scope.course = oedCourse.get({
        id: $scope.courseId
      });
      $scope.tests = oedSubjectiveTest.queryByCourseIncEmpty({
        course_id: $scope.courseId
      });
      $scope.courseSections = oedUnitItem.queryAvailableBySchoolAndCourse({
        schoolId: 0,
        courseId: $scope.courseId
      });
    }
  }
  const updateResourceIds = () => {
    const question = $scope.currentTest.question;
    question.resourceIds = _.map(question.resources, (res: any) => {
      return res.id;
    });

    //
    // 不再为问题保存resourceId
    question.resourceId = null;
    question.resource = null;
  };

  $scope.sortableOptions = {
    start: (event) => {
      //
      // 开始拖动前记住每个res对应的positionDetails
      _.forEach($scope.currentTest.question.resources, (res, idx) => {
        res.$posDetails = _.get($scope.currentTest.question, `$resourcePosition[${idx}]`);
      });
    },
    stop: (event) => {
      updateResourceIds();
      //
      // 结束后恢复positionDetails
      $scope.currentTest.question.$resourcePosition = _.map(
        $scope.currentTest.question.resources,
        (res) => res.$posDetails);
      //
      // 去掉临时数据
      $scope.currentTest.question.resources = _.map(
        $scope.currentTest.question.resources,
        (res) => _.omit(res, '$posDetails'));
      $scope.currentTest.isSameAsOrig = false;
    }
  };

  $scope.courseSections = oedUnitItem.queryAvailableBySchoolAndCourse({
    schoolId: 0,
    courseId: $scope.courseId
  });
  $scope.currentTest.$promise.then(() => {
    $scope.layoutType.name = 'auto';
    $scope.deserializePosition();
    $scope.currentZindex = _($scope.currentTest.question.$resourcePosition).map((p: any) => {
      return _.parseInt(p.zindex);
    }).max();
  });
  $scope.loading = $q.all([$scope.currentTest.$promise, $scope.courseSections.$promise]);

  $scope.currentTest.$promise.then((test) => {
    $scope.originalTest = angular.copy(test);
  });
  $scope.deserializePosition = () => {
    const tmpPosition = $scope.currentTest.question.resourcePosition;
    if (!_.isEmpty(tmpPosition) && !_.isArray(tmpPosition)) {
      try {
        $scope.currentTest.question.$resourcePosition = JSON.parse(tmpPosition);
        $scope.layoutType.name = 'manual';
      } catch (e) {
        notificationService.notify('error', '加载位置信息失败, 转换为自动布局!')
        $scope.currentTest.question.$resourcePosition = null;
        $scope.layoutType.name = 'auto';
      }
    }
  };
  $scope.loading.then(() => {
    $scope.sectionIdMap = {};

    function visitSection(sec) {
      if (sec.id) {
        $scope.sectionIdMap[sec.id] = sec;
      }
      if (sec.child) {
        sec.child = _.sortBy(sec.child, ['sort']);
        _.each(sec.child, visitSection);
      }
    }
    _.each($scope.courseSections, visitSection);

    $scope.sectionTree.treeData = oedMisc.utils.convertSectionsToJsTreeModel($scope.sectionIdMap,
      $scope.courseSections);
    $scope.treeConfig.version++;
    _.each($scope.sectionTree.treeData, (item: any) => {
      item.state.opened = true;
    });
  });
  $scope.viewMode = 'file';
  // 白板布局类型
  $scope.layoutType = {};
  // 图片图层的前缀，用来在directive中操作不同图层
  $scope.boardImagePrefix = 'bmg';
  // 图片的前缀
  $scope.imagePrefix = 'zmg';
  // 当前的图层zindex
  $scope.currentZindex = 0;
  // 用来跟踪图片加载完成数目
  $scope.loadedCount = 0;
  // 用来跟踪图片缩略图加载完成数目
  $scope.thumbLoadedCount = 0;
  // 操作后的图片，成为目前的焦点
  $scope.lastActiveId = null;

  // 第一次加载图片时，需要等所有图片加载完成后才能显示
  // 用来绑定哪个tab是active的，白板图片清空时，应该默认选中附件模式
  $scope.activeTabIndex = 0;

  // 图片默认占画布的最大比例
  $scope.maxScaleToBoard = 0.8;

  $scope.canShowPreview = false;

  $scope.setCanPreview = (status) => {
    $scope.canShowPreview = status;
  };

  $scope.boardClicked = (event) => {
    const isBase = angular.element(event.target).hasClass('white-board-bg');
    if (isBase) {
      $scope.lastActiveId = null;
    }
  };
  $scope.switchMode = (index) => {
    if ($scope.activeTabIndex === index) {
      return;
    }
    $scope.activeTabIndex = index;
    if (typeof $scope.loadImagePosition === 'function' && !$scope.isAutoLayout() && index === 0) {
      $scope.loadImagePosition(true);
    }
    if (index === 0) {
      $scope.viewMode = 'file';
    } else {
      $scope.viewMode = 'edit';
    }
  };
  $scope.isEditMode = () => {
    return $scope.viewMode === 'edit';
  };
  $scope.imageThumbLoaded = (index) => {
    $scope.thumbLoadedCount = $scope.thumbLoadedCount + 1;
  };

  // 是否组自动布局
  $scope.isAutoLayout = () => {
    return $scope.layoutType.name === 'auto';
  };

  //
  // TODO: convert types to an array to keep it's order
  $scope.types = [
    {
      value: 'subjective_blankboard',
      displayName: '白板'
    },
  ];

  $scope.treeConfig = {
    core: {
      multiple: true,
      animation: true,
      error: (error) => {
        $log.error('treeCtrl: error from js tree - ' + angular.toJson(error));
      },
      check_callback: true,
      worker: true
    },
    types: {
      default: {
        icon: 'fa fa-book'
      },
      root: {
        icon: 'glyphicon glyphicon-cloud'
      }
    },
    version: 1,
    plugins: ['types', 'checkbox']
  };
  $scope.sectionTree = {
    treeData: []
  };

  $scope.uploading = 0;

  const uploadUrl = oedConfig.url('ceditor/uploadresource');

  $scope.uploadFile = (files) => {
    if (_.isEmpty(files)) {
      return null;
    }

    const filesToUpload = _(files).each((f) => {
      const validateResults = oedFileUtils.validateFileUpload(f, {
        fileType: 'image'
      });
      f.validateResults = validateResults;
    }).filter((f) => {
      const info = _.keyBy(f.validateResults, 'level');
      return !_.has(info, 'error');
    });

    const results = _(files).map('validateResults').flatten().value();
    oedFileUtils.notifyValidationReporter(results);
    if (_.isEmpty(filesToUpload)) {
      return null;
    }

    $scope.uploading += _.size(files);
    $scope.uploadprogress = 0;
    const total = _.size(files);
    const progresses = _.fill([], 0, 0, _.size(files));
    const uploadFrom = $scope.viewMode;

    $scope.loading = $q.all(_.map(filesToUpload, (fileItem, idx) => {
      return $q((resolve, reject) => {
        Upload.upload({
          url: uploadUrl,
          file: fileItem
        }).progress((evt) => {
          const progressPercentage = Math.floor(100.0 * evt.loaded / evt.total);
          progresses[idx] = progressPercentage;

          $scope.uploadprogress = Math.floor(_.sum(progresses) / total);
        }).success((resData, status, headers, config) => {
          resolve(resData);
        }).error(() => {
          notificationService.notify('error', '文件上传失败!')
          resolve(null);
        }).finally(() => {
          $scope.uploading--;
        });
      });
    })).then((datas: any) => _.filter(datas, (d: any) => !!d))
      .then(computeFilesLayout).then((fileAndPoses: any) => {
        const question = $scope.currentTest.question;
        question.resources = question.resources || [];

        question.resources = question.resources.concat(_.map(fileAndPoses, 'file'));
        const maxZIndex = _(question.$resourcePosition).map('zindex').max() || 0;

        const defaultPos = {
          isFixedPosition: 0
        };

        const newPositions = _.map(fileAndPoses, (v, idx) => _.assign({}, defaultPos, {
          zindex: maxZIndex + idx
        }, v.pos));
        question.$resourcePosition = _.concat(question.$resourcePosition || [], newPositions);

        updateResourceIds();
        $scope.currentTest.isSameAsOrig = false;
      });

    return $scope.loading;
  };

  function computeFilesLayout(files) {
    if ($scope.isAutoLayout()) {
      return Promise.resolve(_.map(files, (f: any) => ({
        file: f
      })));
    }

    return loadImageAndCenter(files);
  }

  function loadImageAndCenter(files) {
    const imgUrls = _.map(files, resourceUtils.getResourceSrc);

    return resourceUtils.loadImagesByUrls(imgUrls)
      .then((imgs: any) => _.map(imgs, (img: any) => computeCenterPosInfo(img.width, img.height)))
      .then((allPoses: any) => _.zipWith(files, allPoses, (file, pos) => ({
        file, pos
      })));
  }

  function computeCenterPosInfo(width, height) {
    return boardUtils.scaleAndCenter(width, height);
  }

  $scope.removeResource = (idx) => {
    const dlg = dialogs.confirm('确定删除?', '确定要删除当前图片吗');
    dlg.result.then((btn) => {
      $scope.loadedCount = $scope.loadedCount - 1;
      $scope.currentTest.question.resources.splice(idx, 1);
      if ($scope.currentTest.question.resources.length === 0) {
        $scope.viewMode = 'file';
        $scope.activeTabIndex = 0;
        $scope.currentTest.question.$resourcePosition = null;
      }
      if (!_.isEmpty($scope.currentTest.question.$resourcePosition)) {
        $scope.currentTest.question.$resourcePosition.splice(idx, 1);
      }
      updateResourceIds();
    }, (btn) => {});
  };

  $scope.toggleShowAnswerDetails = () => {
    $scope.showAnswerDetails = !$scope.shouldShowAnswerDetails();
    if (!$scope.showAnswerDetails) {
      $scope.currentTest.question.answerDetails = '';
    }
  };

  $scope.onAnswerTypeChanged = () => {
    if ($scope.answerType !== 'subjective_blankboard') {
      $scope.removeRes();
    }
  };

  $scope.shouldShowAnswerDetails = () => {
    if (!$scope.currentTest || !$scope.currentTest.question) {
      return false;
    }

    return $scope.showAnswerDetails || $scope.currentTest.question.answerDetails;
  };

  function editToViewModel(ques) {
    const newModel = angular.copy(ques);

    return newModel;
  }

  function toEditModel(ques) {
    const newModel = angular.copy(ques);

    return newModel;
  }

  $scope.clearQuestion = () => {
    $scope.showAnswerDetails = false;
    _.assign($scope.currentTest, {
      question: {
        question: '',
        evaluateType: 'point',
        relatedSections: $scope.course.relatedSections,
        point2: 10,
        type: 'subjective_blankboard',
        answerDetails: '',
        resource: null,
        resourceId: null
      }
    });
    $scope.loadedCount = 0;
    $scope.viewMode = 'file';
    $scope.currentTest.question.$resourcePosition = null;
    $scope.currentTest.question.resourcePosition = null;
    $scope.activeTabIndex = 0;

    resetSection();
  };

  function resetSection() {
    const sections = _.map($scope.course.relatedSections, (relSec) => {
      return relSec.sectionId;
    });
    $scope.treeInstance.jstree(true).deselect_all(true);
    $scope.treeInstance.jstree(true).select_node(sections, true, true);
  }

  $scope.saveTest = (shouldNotify, updateSameAsOrigin, useDefaultSections) => {
    $scope.versionStamp++;
    $scope.loading = $timeout(() => {
      return $scope.saveTestWithContent(shouldNotify, updateSameAsOrigin, useDefaultSections);
    });

    return $scope.loading;
  };

  $scope.saveTestWithContent = (shouldNotify, updateSameAsOrigin, useDefaultSections) => {
    const curQuestionIndex = _.indexOf($scope.currentTest.questions, $scope.currentQuestion);
    if (_.isEmpty($scope.currentTest.question.relatedSections)) {
      if (useDefaultSections) {
        $scope.currentTest.question.relatedSections = $scope.course.relatedSections;
        resetSection();
      } else {
        dialogs.error('无法保存', '当前题目没有关联章节, 请关联章节后再保存');
        return;
      }
    }
    if ($scope.isAutoLayout()) {
      $scope.currentTest.question.resourcePosition = null;
    } else {
      const pos = _.filter($scope.currentTest.question.$resourcePosition);
      if (_.size(pos) !== _.size($scope.currentTest.question.$resourcePosition)) {
        $scope.currentTest.question.$resourcePosition = pos
      }
      if (!_.isEmpty(pos)) {
        const resSize = _.size($scope.currentTest.question.resources);
        if (resSize < _.size(pos)) {
          $scope.currentTest.question.$resourcePosition = _.slice(pos, 0, resSize);
        }
      }

      $scope.currentTest.question.resourcePosition = JSON.stringify($scope.currentTest.question.$resourcePosition);
    }
    const newTest = angular.copy($scope.currentTest);
    newTest.question = _.omit(newTest.question, ['resource', 'resources']);
    if (updateSameAsOrigin && newTest.isSameAsOrig) {
      newTest.isSameAsOrig = angular.equals($scope.currentTest, $scope.originalTest);
    }
    if (angular.equals($scope.currentTest, $scope.originalTest)) {
      return;
    }

    const notifySec = shouldNotify ? notificationService : () => {};

    $scope.loading = newTest.$save()
      .then((test) => {
        $scope.dirty = false;
        notificationService.notify('info', '活动保存成功');
        $uibModalInstance.close(test);
        if (!_.isEmpty($scope.currentTest.question.resourcePosition)) {
          test.question.$resourcePosition = JSON.parse(test.question.resourcePosition);
          $scope.currentZindex = _(test.question.$resourcePosition).map((p) => {
            return p.zindex;
          }).max();
          $scope.layoutType.name = 'manual';
        } else {
          $scope.layoutType.name = 'auto';
        }
        $scope.loadedCount = 0;
        $scope.currentTest = test;
        $scope.originalTest = angular.copy(test);
      }).catch((error) => {
        const errCode = _.get(error, 'status', 500);
        if (errCode === 401 || errCode === 403) {
          return notifySec.notify('error', '您的登录状态已改变，请重新登录!')
        }
        notifySec.notify('error', '活动保存失败，请联系锐学堂技术支持!')
      });

    return $scope.loading;
  };

  $scope.onTreeReady = () => {
    if (!$scope.currentTest.question) {
      return;
    }

    const sections = _.map($scope.currentTest.question.relatedSections, (relSec) => {
      return relSec.sectionId;
    });
    $scope.treeInstance.jstree(true).select_node(sections, true, true);
  };

  $scope.onSelChanged = () => {
    const selectedNodes = $scope.treeInstance.jstree(true).get_selected();
    const curIds = _.map($scope.currentTest.question.relatedSections, (sec) => {
      return sec.sectionId;
    });

    if (_(curIds).xor(selectedNodes).isEmpty()) {
      return;
    }

    $scope.currentTest.question.relatedSections = _.map(selectedNodes, (node) => {
      const sec = $scope.sectionIdMap[node];

      return {
        sectionId: sec.id,
        sectionName: sec.name
      };
    });
  };

  $scope.preventDropdownDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  function doImportTest(res, deferred) {
    confirmAndDownloadSubjectiveTest(res, deferred, {
      dialogs, oedCloudQuestion, oedSubjectiveTest,
    }).then((subTestAndCq) => {
      const {cloudQuestion, subTest} = subTestAndCq

      const newTest = importSubjectTestModel($scope.currentTest, subTest, cloudQuestion)
      if (_.isEmpty(subTest.question.positionDetails)) {
        $scope.layoutType.name = 'auto';
      } else {
        $scope.layoutType.name = 'manual';
      }

      if (_.isEmpty($scope.currentTest.question.resources)) {
        $scope.switchMode(0);
      }

      $scope.saveTest(false, false, true).then(() => {
        $scope.dirty = false;
        deferred.resolve(1);
      });
    }).catch((m) => {
      deferred.reject(m);
    });

    return deferred.promise;
  }

  $scope.importFromLibrary = () => {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/importFromLibraryDialog.html'),
      controller: 'importFromLibraryCtrl',
      size: 'lg',
      windowClass: 'shouldHideOnPreview cl-library-dialog',
      resolve: {
        resourceDef: () => {
          return {
            name: '主观活动',
            course: $scope.course,
            res: oedCloudSubjectiveTest,
            canImportMultiple: false,
            queryCountURL: oedConfig.url('cloudsubjectivetest/querybysection/count'),
            itemTemplateUrl: 'Assets/templates/prepareCourse/cloudSubjectiveTestItem.html',
            shouldDismissParent: false,
            viewType: 'subjectiveTest',
            queryRes: (queryOpt, viewType) => {
              return oedTestUtils.queryCloudSubjTest(queryOpt);
            },
            queryResById: (queryOpt) => {
              const resId = oedCourseUtils.getCloudResTypeAndId(_.trim(queryOpt.queryId));
              const kind = _.get(resId, 'kind', '');
              if (kind !== 'subjectiveTest') {
                return $q.resolve(null);
              }

              queryOpt = _.assign({}, queryOpt, {
                queryId: resId.id
              });

              const cloudTest = oedCloudSubjectiveTest.queryByCloudTestOrPreRedId(queryOpt);
              return cloudTest.$promise.then((item) => {
                item.cloudResource = _.cloneDeep(item);
                return oedTestUtils.loadCloudSubjTestDetails(item);
              });
            },
            doPreview: (res) => {
              const def = this;
              const modalInstance1 = $uibModal.open({
                template: require('assets/templates/prepareCourse/cloudSubjectiveTestPreviewer.html'),
                controller: 'cloudSubjectiveTestPreviewerCtrl',
                size: 'lg',
                resolve: {
                  cloudTest: () => {
                    return res;
                  },
                  resourceDef: () => {
                    return def;
                  }
                },
                windowClass: 'cl-library-preview'
              });

              return modalInstance1.result;
            },
            importFromLibraryByResId: (vt, dataRes) => {
              const def = this;
              const modalInstance2 = $uibModal.open({
                template: require('assets/templates/prepareCourse/importFromLibraryByResIdDialog.html'),
                controller: 'importFromLibraryByResIdCtrl',
                windowClass: 'shouldHideOnPreview cl-library-dialog cl-import-by-res-dialog',
                size: 'lg',
                resolve: {
                  resourceDef: () => {
                    return def;
                  },
                  queryData: () => {
                    return dataRes;
                  }
                }
              });
              return modalInstance2.result;
            },
            doImport: (res, deferred) => {
              return doImportTest(res, deferred);
            },
            defaultSortType: 'use_count',
            extraSortTypes: [{
              name: '使用人数',
              value: 'use_count'
            }]
          };
        }
      }
    });
  };

  $scope.importFromLibrary2 = () => {
    const modalInstance = $uibModal.open({
      template: require('app2/prepareCourse/cloud/subjectiveTest/subjectiveTestLibraryDialog.html'),
      controller: 'subjectiveTestLibraryDialogCtrl',
      controllerAs: '$ctrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          course: $scope.course,
          doImport: (res, deferred) => {
            return doImportTest([res], deferred)
          },
        })
      },
    })
  }

  $scope.previewResource = (reses, idx) => {
    const resWithName = _.map(reses, (res, i) => {
      return _.defaults({}, res, {
        resourceName: '第' + (i + 1) + '张'
      });
    });
    Lightbox.openModal(resWithName, idx);
  };

  $scope.showShowCloudTestNo = () => {
    return !$scope.isEmptyResource($scope.currentTest) && _.isNumber($scope.currentCloudTestId);
  };

  $scope.isEmptyResource = (res) => {
    if (!res) {
      return true;
    }

    return _.get(res, 'isEmpty', false) ||
      (_.isEmpty(_.get(res, 'question.question')) &&
        _.isEmpty(_.get(res, 'question.resources')));
  };

  $scope.dirty = false;
  $scope.layoutChange = () => $scope.dirty = true;

  $scope.onDisableAutoLayout = (positions) => {
    $scope.layoutType.name = 'manual';
    $scope.currentTest.question.$resourcePosition = positions;
    $scope.dirty = true;
  };

  $scope.onPositionsChange = (positions) => {
    $scope.currentTest.question.$resourcePosition = positions;
    $scope.dirty = true;
  };

  $scope.onResourcesChange = (resources, positions) => {
    $scope.dirty = true;
    $scope.currentTest.question.resources = resources;
    $scope.currentTest.question.$resourcePosition = positions;
    updateResourceIds();
    if (_.isEmpty(resources)) {
      $scope.switchMode(0);
    }
  };

  $scope.onConfirmRemoveRes =  (idx)  => dialogs.confirm('确定删除?', '确定要删除当前图片吗').result;

  $scope.handlePaste = (e) => {
    const images = []
    for (let i = 0 ; i < e.originalEvent.clipboardData.items.length ; i++) {
      const item = e.originalEvent.clipboardData.items[i];
      if (item.type.indexOf('image') !== -1) {
        images.push(item.getAsFile())
      }
    }
    if (!_.isEmpty(images)) {
      $scope.uploadFile(images)
    }
  }

  $scope.sortableOptions = {
    axis: 'y',
    stop: (event) => {
      const newOrders = _.map($scope.tests, (m: any, index) => {
        return {
          id: m.id,
          sort: index
        };
      });
      $scope.loading = oedCourse.updateTestOrder(newOrders).$promise.then().catch(() => {
        notificationService.notify('error', '保存失败')
      });
    }
  };
}
