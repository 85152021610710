import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash'
import { LegacyAppService } from 'app/core/legacy-app.service';
import { getChoiceName, optLabels } from 'app2/utils/testUtils2';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-connect-designer',
  template: require('./connect-designer.component.html'),
  styles: [require('./connect-designer.component.scss')]
})
export class ConnectDesignerComponent implements OnChanges, OnInit {
  @Input() content
  @Input() isSub
  @Input() resDelShowType
  @Input() isThreeDegrees
  @Input() editorConfig

  @Output() contentChange = new EventEmitter<any>()
  @Output() showResAndDelClick = new EventEmitter<any>()

  dialogs: any = null

  constructor(
    legacyApp: LegacyAppService,
    private notifyService: NotificationService
  ) {
    this.dialogs = legacyApp.getDialogs()
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'content')) {
      this.initConnectsToArray(this.content)
    }
  }

  trackFn(idx: number) {
    return idx
  }

  addLeftOpt() {
    if (_.size(this.content.leftOpts) >= 26) {
      this.notifyService.notify('info', '选项个数不能超过26个!')
      return false
    }
    const opt = {
      content: '',
      $$connectsTo: []
    };

    this.content.leftOpts = [...this.content.leftOpts, opt]
    return false
  }

  addRightOpt () {
    const opt = {
      content: '',
      uuid: _.uniqueId('right_opt_')
    }

    this.content.rightOpts = [...this.content.rightOpts, opt]
    return false
  }

  getLeftOptName(idx: number) {
    return getChoiceName(idx)
  }

  getRightOptName (idx: number) {
    return idx + 1;
  }

  doRemoveLeftOpt = function(idx) {
    this.content.leftOpts.splice(idx, 1);
    this.updateAnswer();
  };

  removeLeftOpt(idx: number) {
    if (!this.content.leftOpts[idx].content) {
      this.doRemoveLeftOpt(idx);
    } else {
      const dlg = this.dialogs.confirm('确定删除？', '此选项已设置内容，真的要删除吗？');
      dlg.result.then((btn) => {
        this.doRemoveLeftOpt(idx);
      }, (btn) => {});
    }
  }

  doRemoveRightOpt (idx: number) {
    const opt = this.content.rightOpts[idx];
    const newOpts = [...this.content.rightOpts]
    newOpts.splice(idx, 1)
    this.content.rightOpts = newOpts

    _.each(this.content.leftOpts, (lOpt) => {
      lOpt.$$connectsTo = _.without(lOpt.$$connectsTo, opt);
    });

    this.updateAnswer();
  }

  removeRightOpt(idx: number) {
    //console.log(this.content.rightOpts)
    if (!this.content.rightOpts[idx].content) {
      this.doRemoveRightOpt(idx);
    } else {
      const dlg = this.dialogs.confirm('确定删除？', '此选项已设置内容，真的要删除吗？');
      dlg.result.then((btn) => {
        this.doRemoveRightOpt(idx);
      }, (btn) => {});
    }
  }

  getRightOptNameByObject = (opt) => {
    return this.getRightOptName(_.indexOf(this.content.rightOpts, opt));
  };

  updateAnswer () {
    //
    // 设置答案时更新答案字符串
    const ques = this.content;
    const answer = _.reduce(ques.leftOpts, function(ret, opt, idxLeft) {
      const choices = [];
      _.each(opt.$$connectsTo, function(conn) {
        const choiceIdx = _.indexOf(ques.rightOpts, conn);
        choices.push(choiceIdx);
      });

      choices.sort(function(a, b) {
        return a - b;
      });

      if (_.isEmpty(choices)) {
        return ret;
      }
      const choicesStr = _.reduce(choices, function(str, idxRight) {
        const choice = '' + idxLeft + ':' + idxRight;
        if (_.isEmpty(str)) {
          return choice;
        }
        return str + ',' + choice;
      }, '');

      if (_.isEmpty(ret)) {
        return choicesStr;
      }
      return ret + ',' + choicesStr;
    }, '');

    if (_.isEmpty(answer) && !_.isEmpty(ques.answer)) {
      ques.answer = answer;
    }
    if (!_.isEmpty(answer)) {
      ques.answer = answer;
    }
  }

  //
  // 初始化各选项的答案数组
  initConnectsToArray(question: any) {
    if (_.isEmpty(question.answer) || _.isEmpty(question.leftOpts) || _.isEmpty(question.rightOpts)) {
      return;
    }

    _.forEach(question.rightOpts, (o) => {
      _.defaults(o, {
        uuid: _.uniqueId('right_opt_')
      });
    });

    const answers = question.answer.split(',');
    _.each(answers, function(aws) {
      aws = _.trim(aws);
      if (_.isEmpty(aws)) {
        return;
      }
      const parts = aws.split(':');
      if (_.size(parts) != 2) {
        console.log('error parsing answer: ' + aws);
        return;
      }
      const idx = _.parseInt(parts[0]);
      let choiceIdx = _.parseInt(parts[1]);
      //
      // in case the format is the old format like: 0 A, 1 B
      const optLbIdx = _.indexOf(optLabels, parts[1]);
      if (optLbIdx >= 0)
        choiceIdx = optLbIdx;

      question.leftOpts = question.leftOpts || [];
      question.leftOpts[idx].$$connectsTo = question.leftOpts[idx].$$connectsTo || [];
      question.leftOpts[idx].$$connectsTo.push(question.rightOpts[choiceIdx]);
    })
  }

  handleChange(items) {
    this.updateAnswer()
  }
}
