import * as _ from 'lodash'

function mParseInt(i: string) {
    const r = _.parseInt(i)
    return _.isNaN(r) ? 0 : r
}

function backParseChoice(choices) {
    return _.chain(choices).map((c, i) => {
      const charCode = String.fromCharCode(65 + mParseInt('' + i))
      const html: any = $(`<span>${charCode}: ${_.get(c, 'content', '')}</span>`)
      return html.html()
    }).join('<br/>').value()
  }

export function convertChoiceToQa(question, choices) {
    const quesBody = `${question} <br/> ${backParseChoice(choices)}`
    return {
      question: quesBody,
      answer: '-1',
      type: 'qa',
    }
}

export function convertFillToQa(question) {
    return {
        question: question,
        answer: '-1',
        type: 'qa',
    }
}

export function convertSyntheticToQa(question) {
    return {
        question: question,
        answer: '-1',
        type: 'qa',
    }
}
