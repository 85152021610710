import { Component, OnInit, Input, Output, EventEmitter, OnChanges,
  SimpleChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import * as _ from 'lodash';
import { Subscription, merge } from 'rxjs';
import { mapTo, startWith } from 'rxjs/operators'
import { DragulaService, dragula } from 'ng2-dragula';
import { LoadingService } from 'app/shared/loading/loading.service';
import { StudentGroupService } from 'app/classroom/student-group.service';
import { finalize } from 'rxjs/operators';
import { NotificationService } from 'app/shared/notification/notification.service';
import { StudentGroupWithSubGroupDTO, StdGroupStudentDTO } from 'app/models/student-group';
import * as autoScroll from 'dom-autoscroller'

@Component({
  selector: 'app-group-editor',
  template: require('./group-editor.component.html'),
  styles: [require('./group-editor.component.scss')]
})
export class GroupEditorComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() disableManage: boolean;
  @Input() group: StudentGroupWithSubGroupDTO;
  @Input() students: any;
  @Input() isEditable: boolean;
  @Input() curGroupIdx: number;
  @Input() editorName: string;

  @Output() onDeleteSubGroup = new EventEmitter<any>();
  @Output() onGroupChanged = new EventEmitter<StudentGroupWithSubGroupDTO>();
  @Output() onSetGroupLeader = new EventEmitter<any>();


  validGroupData: StudentGroupWithSubGroupDTO;

  loadingName = 'group-editor'
  dragSubs = new Subscription();

  stdMap: _.Dictionary<any>;
  currentEditingGroupName = '';
  unGroupedStudents: StdGroupStudentDTO[];
  connectsTo: string[];

  constructor(private loadingService: LoadingService,
              private studentGroupService: StudentGroupService,
              private notifyService: NotificationService,
              private dragulaService: DragulaService) { }

  STUDENT_GROUP = 'STUDENT_GROUP';
  isDragging = false;

  ngOnInit() {
    this.stdMap = _.keyBy(this.students, 'uid');
    this.initSubs();
  }

  initSubs() {
    const dropSub = this.dragulaService.dropModel(this.STUDENT_GROUP)
    .subscribe(({ target, source, sourceModel, targetModel, el}) => {
      const targetIndex = _.toNumber(target.getAttribute('data-idx'))
      const sourceIndex = _.toNumber(source.getAttribute('data-idx'))

      const subGroups = _.get(this.validGroupData, 'subGroups', [])

      const newSubGroups =  _.map(subGroups, (model, idx) => {
        if (idx === targetIndex) {
          return _.filter(targetModel)
        } else if (idx === sourceIndex) {
          return _.filter(sourceModel)
        } else {
          return model 
        }
      })

      this.validGroupData = {
        ...this.validGroupData,
        subGroups: newSubGroups,
      }

      el.remove()

      this.onGroupChanged.emit(this.validGroupData)
    })

    const dragStart$ = this.dragulaService.drag(this.STUDENT_GROUP).pipe(mapTo(true));
    const dragEnd$ = this.dragulaService.dragend(this.STUDENT_GROUP).pipe(mapTo(false));

    const draggingSub = merge(dragStart$, dragEnd$).pipe(startWith(false)).subscribe(d => {
      this.isDragging = d
    })

    this.dragSubs.add(dropSub)
    this.dragSubs.add(draggingSub)
  }

  ngAfterViewInit() {
    this.resetAutoScroll()
  }
  
  resetAutoScroll() {
    // const noneGroup = document.querySelector(`#${this.getNonGroupName()}`)
    // const subGroup = this.validGroupData.subGroups.map((s, i) => {
    //   return document.querySelector(`#${this.getGroupDndName(i)}`)
    // })
    // dragula([
    //   ...subGroup,
    //   noneGroup,
    // ]);
    autoScroll([
      window,
    ], {
      margin: 20,
      maxSpeed: 9,
      scrollWhenOutside: true,
      autoScroll: () => {
        return this.isDragging;
      }
    })
  }

  getNonGroupName() {
    return this.editorName + '-nonGroup'
  }

  getGroupDndName(idx: number) {
    return this.editorName + `-group-${idx}`
  }

  getDragGroupName() {
    return 'studentGroup_' + this.editorName
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!_.chain(changes).pick(['group', 'students']).isEmpty().value()) {
      this.updateData()
    }
  }

  ngOnDestroy(): void {
    this.dragSubs.unsubscribe();
  }

  updateData () {
    this.stdMap = _.keyBy(this.students, 'uid')
    const allStudentsInGroup = _.chain(this.group.subGroups).flatten().map('studentId').keyBy().value();
    let arr = [];
    this.validGroupData = {
      ...this.group,
    }
    this.validGroupData.subGroups = _.map(this.group.subGroups, (item) => {
      const validStds = item.filter((it) => _.has(this.stdMap, it.studentId))
      arr = arr.concat(validStds);
      return validStds
    })

    _.forEach(this.students, (item) => {
      _.find(arr, function (a) {
        if (a.studentId === item.uid) {
          return item.groupInfo = a;
        }
      })
    })
    this.validGroupData.subGroupInfo = []
    this.validGroupData.subGroupInfo.length = _.size(this.group.subGroups)
    for (const info of this.group.subGroupInfo) {
      const { subGroupIdx } = info
      this.validGroupData.subGroupInfo[subGroupIdx] = info
    }
    this.validGroupData.subGroupInfo = _.map(this.validGroupData.subGroupInfo, (v, idx) => {
      if (!v) {
        return {
          groupId: this.group.id,
          subName: '',
          subGroupIdx: idx,
        }
      }
      return v
    })

    this.unGroupedStudents = _.chain(this.students)
      .filter((std) => _.has(this.stdMap, std.uid) && !_.has(allStudentsInGroup, std.uid))
      .map((std) => ({
        groupId: this.group.id,
        sort: -1,
        studentId: std.uid,
        subGroupIdx: -1,
      })).value();
  }

  setGroupLeader (event) {
    this.onSetGroupLeader.emit(event);
  }

  validStd ({uid}: any) {
    return _.hasIn(this.stdMap, uid);
  }

  addSubGroup () {
    this.validGroupData.subGroupCount++
    this.validGroupData.subGroups.push([])
    this.validGroupData.subGroupInfo.push({
      groupId: this.group.id,
      subName: '',
      subGroupIdx: this.validGroupData.subGroups.length - 1,
    })
    this.onGroupChanged.emit(this.validGroupData)
  }

  getSubGroupName (index: number) {
    const subNames = _.get(this.validGroupData, 'subGroupInfo', []);
    let curSub;
    _.forEach(subNames, (o) => {
      if (o.subGroupIdx === index) {
        curSub = o;
      }
    });
    const curName = _.get(curSub, 'subName');
    if (curName) {
      return `${curName}`;
    }
    return `第${(index + 1)}组`
  }

  updateGroupName($event, idx: number) {
    this.loadingService.show(false, this.loadingName)
    this.studentGroupService.setSubGroupName(this.group.id, idx, $event)
      .pipe(finalize(() => this.loadingService.hide(this.loadingName)))
      .subscribe(() => {
        this.onGroupChanged.emit(this.validGroupData)
      }, (e) => {
        console.log('error', e)
      })
  }

  onDeleteSubGroupClicked (subGroupIdx: number) {
    if (this.isEditable) {
      this.onDeleteSubGroup.emit({
        index: subGroupIdx,
        names: _.get(this.validGroupData, 'subGroupInfo', []),
      })
    } else {
      return this.notifyService.notify('info', '本分组方案内有小组积分记录，暂不支持删除小组')
    }
  }

}
