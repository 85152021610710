require('assets/styles/oedTable.scss');
angular.module('app.directives.review.objective.votingTestStatTable', [])
  .directive('votingTestStatTable', votingTestStatTable);

votingTestStatTable.$inject = ['$document', '$timeout']
function votingTestStatTable($document, $timeout) {
  return {
    restrict: 'E',
    scope: {
      testSessionId: '<',
      showDetailsForStudent: '&',
      isQuickCreateObjTest: '<',
      students: '<',
      objTest: '<'
    },
    template: require('./votingTestStatTable.html'),
    controller: votingTestStatTableCtrl
  };
}

votingTestStatTableCtrl.$inject = [
  '$q', '$scope', '$state', '$uibModal', 'oedTestUtils',
  'oedTestSessionStat', 'oedTestSession', 'oedObjectiveTestSessionStats']
function votingTestStatTableCtrl(
  $q,
  $scope,
  $state,
  $uibModal,
  oedTestUtils,
  oedTestSessionStat,
  oedTestSession,
  oedObjectiveTestSessionStats
) {

  // const status = oedTestSession.getRemoteVotingStat({
  //   testSessionId: $scope.testSessionId
  // })

  $scope.remoteVotingDetails = oedTestSession.getRemoteVotingAnswerRank({
    testSessionId: $scope.testSessionId
  })

  $scope.summary = oedTestSessionStat.queryByTestSessionId({
    sessionId: $scope.testSessionId
  });

  function getName(data) {
    if (data.name && data.uid) {
      return data.name
    } else if (!data.name && !data.uid && data.deviceUUID) {
      return data.deviceUUID
    } else {
      return '-'
    }
  }

  function getStudentId(data) {
    if (data.name && data.uid) {
      return data.uid
    } else {
      return '-'
    }
  }

  function getCorrectRate(data) {
    if (data.correctCount && data.questionCount && parseFloat(data.questionCount) !== 0) {
      const num = parseFloat(data.correctCount) / parseFloat(data.questionCount)
      return num.toFixed(2) * 100 + '%'
    }
    return 0 + '%'
  }

  $scope.reverse = true;
  $scope.propertyId = '';
  $scope.ordersList = ['desc', 'asc']
  $scope.sortBy = function(propertyId) {
    $scope.reverse = ($scope.propertyId === propertyId) ? !$scope.reverse : true;
    $scope.propertyId = '' + propertyId;
    const order = $scope.reverse ? 'desc' : 'asc'
    $scope.orderByList = [$scope.propertyId]
    $scope.ordersList = [order, 'asc']
    $scope.records = _.orderBy($scope.targetDetails, $scope.orderByList, $scope.ordersList)
  };
  $scope.propertysStatic = [
    {id: 'studentId', name: '学生ID', checked: true, fuc: function(data) {
      return getStudentId(data);
    }, key: 'stdId', class: 't-info'},
    {id: 'studentName', name: '姓名', checked: true, fuc: function(data) {
      return getName(data);
    }, key: 'stdName', class: 't-info'},
    {id: 'sort', name: '班级名次', checked: true, fuc: function(data) {
      return data.sort;
    }, key: 'sort', class: 't-danger'},
    {id: 'totalScore', name: '总成绩', checked: true, fuc: function(data) {
      return Number(data.totalScore).toFixed(1);
    }, key: 'totalScore', class: 't-warning'},
    {id: 'correctCount', name: '正确率', checked: true, fuc: function(data) {
      return getCorrectRate(data);
    }, key: 'correctCount'},
  ];
  $scope.propertys = [
    {id: 'studentId', name: '学生ID', checked: true, class: 't-info'},
    {id: 'studentName', name: '姓名', checked: true, class: 't-info'},
    {id: 'sort', name: '班级名次', checked: true, class: 't-danger'},
    {id: 'totalScore', name: '总成绩', checked: true, class: 't-warning'},
    {id: 'correctCount', name: '正确率', checked: true},
  ];

  $scope.loading = $scope.remoteVotingDetails.$promise.then((res1) => {
    $scope.questions = [];
    $scope.type = _.map($scope.objTest.questions[0].childQuestions, 'id')
    _.forEach($scope.type, r => {
      const obj = {
        quesId: r
      }
      $scope.questions.push(obj);
    });
    let idx = 0;
    _.forEach($scope.questions, function(t, index) {
      if (index > 0) {
        idx++;
      }
      $scope.propertys.push({id: '' + t.quesId, name: '题' + ('' + _.parseInt(idx + 1)), checked: true});
    })

    // 总分相同时班级名次相同
    $scope.targetDetails = _.reverse(_.sortBy(res1, 'totalScore', 'name'))
    let tempSort = 1
    for(let i=0; i<_.size($scope.targetDetails); i++) {
      if (i === 0 || _.parseInt($scope.targetDetails[i].totalScore) === _.parseInt($scope.targetDetails[i - 1].totalScore)) {
        $scope.targetDetails[i].sort = tempSort
      } else if (i > 0 && _.parseInt($scope.targetDetails[i].totalScore) !== _.parseInt($scope.targetDetails[i - 1].totalScore)) {
        tempSort = tempSort + 1
        $scope.targetDetails[i].sort = tempSort
      }
      _.forEach($scope.targetDetails[i].floatScoreMap, function(t) {
        $scope.targetDetails[i][t.questionId] = t.score
      })
    }
    $scope.targetDetails = _.filter(_.sortBy($scope.targetDetails, 'sort', 'deviceUUID', 'uid'), r => _.size(r.floatScoreMap) > 0)
  })
  .then(() => {$scope.sortBy('totalScore')});

  $scope.onCheckboxItemClicked = function(cp, event) {
    event.stopPropagation();
    cp.checked = !cp.checked;
  };
}
