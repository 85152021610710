import { manageStates } from './../../../manage-routing.state';
import { flatMap, map, pairwise, timeout, finalize } from 'rxjs/operators';
import { Component, OnInit, Inject, forwardRef, Output, EventEmitter, Input } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { OedExerciseService } from '../../oed-exercise.service';
import { NotificationService } from 'app/shared/notification/notification.service';
import { LoadingService } from 'app/shared/loading/loading.service';
import { dirListToTree, replaceFormulaRes, buildDefOpenMap } from '../ex-dialog-utils';
import { of } from 'rxjs';

@Component({
  selector: 'app-ex-mark-dialog',
  template: require('./ex-mark-dialog.component.html'),
  styles: [require('./ex-mark-dialog.component.scss')]
})
export class ExMarkDialogComponent implements OnInit {
  // 0: 忽略
  // 1: 保存
  @Output() public action = new EventEmitter<any>()
  @Input() private nodeData: any

  private origNodeId: number
  private nodeLevel: number
  private nodeName: string
  private allDirsList: any[]
  private isSaved: boolean
  private hasParentLevel: boolean

  private currentNode: any
  private parentNode: any
  private parentNodeName: string

  constructor(
    private bsModalRef: BsModalRef,
    private loadingService: LoadingService,
    private notifyService: NotificationService,
    private oedExerciseService: OedExerciseService,
    @Inject(forwardRef(() => OedDialogService)) private oedDialogService: OedDialogService,
  ) { }

  ngOnInit() {
    this.initData()
    this.loadData()
  }

  private initData = () => {
    this.origNodeId = _.get(this.nodeData, 'origNode.id', 0)
    this.nodeLevel = _.get(this.nodeData, 'origNode.jsonStr.nodeLevel', 1)
    this.nodeName = _.get(this.nodeData, 'origNode.jsonStr.nodeName', '')

    this.isSaved = _.get(this.nodeData, 'origNode.status', '') === 'saved'
    if (this.isSaved) {
      this.currentNode = _.get(this.nodeData, 'node', {})
      this.nodeName = _.get(this.currentNode, 'name', '')
      this.nodeLevel = _.get(this.currentNode, 'nodeLevel', '')
    }
  }

  private loadData = () => {
    this.loadAllDirs()
      .pipe(this.loadingService.withLoading())
      .subscribe(
        () => {
          if (this.isSaved) {
            this.parentNode = _.find(this.allDirsList, dir => _.get(dir, 'id', 0) === _.get(this.currentNode, 'parentId', 0))
          } else {
            this.parentNode = _.find(this.allDirsList, dir => _.get(dir, 'origId', 0) === _.get(this.nodeData, 'origNode.parentId', 0))
          }
          this.parentNodeName = _.get(this.parentNode, 'name', '')
          this.checkHasParentNode()
        }, e => {
          this.notifyService.notify('error', '加载目录失败，请稍后再试')
          console.error('ignore origNode request error: ', e)
        }
      )
  }

  private checkHasParentNode = () => {
    this.hasParentLevel = _.findIndex(this.allDirsList, dir => _.get(dir, 'nodeLevel', 0) === (this.nodeLevel - 1)) > -1
    return this.hasParentLevel
  }

  private loadAllDirs = () => {
    const exBookId = _.get(this.nodeData, 'origNode.exerciseBookId', 0)
    return this.oedExerciseService.getAllDirs(exBookId)
      .pipe(flatMap((res: any[]) => {
        this.allDirsList = res
        return of(1)
      }))
  }

  private doTransferFormula = () => {
    return this.oedExerciseService.formulaByOrigNodeId(this.origNodeId)
      .pipe(
        timeout(20000),
        flatMap(res => {
          const formulaResMap = _.keyBy(res, r => _.get(r, 'formula', ''))
          this.nodeName = replaceFormulaRes(this.nodeName, formulaResMap)
          return of(1)
        })
      )
  }

  private onIgnore = () => {
    this.oedExerciseService.ignoreOrigNode(this.origNodeId)
      .pipe(this.loadingService.withLoading())
      .subscribe(() => {
        this.action.emit({ ignored: true })
        this.bsModalRef.hide()
      }, e => console.error('ignore origNode request error: ', e))
  }

  private showCreatePaperDialog = () => {
    const nodeId = _.get(this.currentNode, 'id', 0)
    this.onClose()
    this.oedDialogService.openModalWithComponent2(
      'ExCreatePaperDialogComponent',
      { nodeId, nodeName: this.nodeName, testName: this.nodeName },
      { class: 'ex-dialog-common' }
    ).subscribe(data => {
      this.action.emit({
        testPaper: data,
        node: this.currentNode
      })
    })
  }

  private onCreatePaper = () => {
    this.buildSaveNodeRequest().pipe(this.loadingService.withLoading())
      .subscribe(() => {
        this.doSaveCallBack()
        this.showCreatePaperDialog()
      })
  }

  private callCreateAndSaveNode = () => {
    const node = this.buildRequesNode()
    if (_.isEmpty(node)) return
    return this.oedExerciseService.createNode(node)
      .pipe(flatMap((res) => {
        this.currentNode = res
        return this.oedExerciseService.saveOrigNode(this.origNodeId)
      }))
  }

  private buildUpdateBody = (node: any) => {
    let parentId = _.get(this.parentNode, 'id', 0)
    if (this.nodeLevel === 1) {
      parentId = 1
    }
    return {
      ...node,
      name: this.nodeName,
      parentId,
      nodeLevel: this.nodeLevel
    }
  }

  private callUpdate = (updateBody: any) => {
    return this.oedExerciseService.updateNode(updateBody)
  }

  private checkIgnoreNode = () => {
    return this.oedExerciseService.getNodeByOrigId(this.origNodeId)
      .pipe(flatMap((res: any[]) => {
        const hasNodes = _.size(res) > 0
        if (hasNodes) {
          return this.oedExerciseService.saveOrigNode(this.origNodeId).pipe(flatMap(() => {
            const node = _.find(res, n => _.get(n, 'origId', 0) === this.origNodeId)
            this.currentNode = node
            const updateBody = this.buildUpdateBody(node)
            return this.callUpdate(updateBody)
          }))
        } else {
          return this.callCreateAndSaveNode()
        }
      }))
  }

  private buildSaveNodeRequest = () => {
    // 这里分三种
    // 1.当前状态如果为ignored，先判断是否有node，如果有则保存再更新，如果没有则创建
    // 2.当前状态如果为saved，则直接调用更新
    // 3.如果无状态，则调用创建
    const status = _.get(this.nodeData, 'origNode.status', '')
    if (status === 'ignored') {
      return this.checkIgnoreNode()
    }
    if (status === 'saved') {
      const updateBody = this.buildUpdateBody(this.currentNode)
      return this.callUpdate(updateBody)
    }
    return this.callCreateAndSaveNode()
  }

  private onSave = () => {
    this.buildSaveNodeRequest().pipe(this.loadingService.withLoading())
      .subscribe(() => {
        this.doSaveCallBack()
      }, e => {
        this.notifyService.notify('error', '保存失败，请稍后再试')
        console.error('create and save origNode request error: ', e)
      })
  }

  private buildRequesNode = () => {
    const nodeType = _.get(this.nodeData, 'origNode.jsonStr.nodeType', '') === 'chapter' ? 'dir' : ''
    const exerciseBookId = _.get(this.nodeData, 'origNode.exerciseBookId', 0)
    const nodeParentId = _.get(this.nodeData, 'origNode.parentId', 1)
    let parentId = _.get(this.parentNode, 'id', 1)
    if (this.nodeLevel === 1) {
      parentId = 1
    }
    return {
      name: this.nodeName,
      nodeLevel: this.nodeLevel,
      nodeType,
      exerciseBookId,
      origId: this.origNodeId,
      parentId
    }
  }

  private getChecked = (level: number) => level === this.nodeLevel
  private getDisable = (level: number) => this.isSaved && level != this.nodeLevel

  private onLevelBtn = (level: number) => {
    if (level === this.nodeLevel) return
    this.nodeLevel = level
    const hasParent = this.checkHasParentNode()
    if (hasParent) {
      this.parentNode = {}
      this.parentNodeName = ''
    }
  }

  private onMenuBtn = () => {
    if (_.size(this.allDirsList) <= 0) {
      this.notifyService.notify('info', '当前目录无数据')
      return
    }
    const treeData = dirListToTree(this.allDirsList)
    const defOpenMap = buildDefOpenMap(this.allDirsList, [this.parentNode])
    this.oedDialogService.openModalWithComponent2(
      'ExChoiceItemDialogComponent',
      {
        title: '选择目录',
        treeData,
        maxSelectedCount: 1,
        maxLevel: this.nodeLevel - 1,
        selectItems: [this.parentNode],
        defOpenMap,
        selectOne: true,
      },
      { class: 'ex-dialog-common' }
    ).subscribe(res => {
      this.parentNode = res[0]
      this.parentNodeName = _.get(this.parentNode, 'name', '')
    })
  }

  private checkBtnDisable = () => {
    return this.nodeName === '' || (this.nodeLevel != 1 && _.isEmpty(this.parentNode))
  }

  private doSaveCallBack = () => {
    if (!_.isEmpty(this.currentNode)) {
      _.set(this.currentNode, 'name', this.nodeName)
    }
    this.action.emit({
      node: this.currentNode,
      saved: true
    })
    this.bsModalRef.hide()
  }

  private onClose = () => {
    const status = _.get(this.nodeData, 'origNode.status', '')
    this.action.emit({
      node: this.currentNode,
      saved: status === 'saved',
      ignored: status === 'ignored',
    })
    this.bsModalRef.hide()
  }
}
