(function() {
  const resourceUtils = require('app2/utils/resourceUtils')
  angular.module('app.prepareCourse')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareCourse.presentResource.editPresentRes', {
        url: '/editRes',
        template: require('assets/templates/prepareCourse/editPresentRes.html'),
        controller: 'editPresentResCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'bg_img text-hidden'
      });
    }])
    .controller('editPresentResCtrl', editPresentResCtrl);

  editPresentResCtrl.$inject = ['$scope', '$state', '$stateParams', '$log', '$uibModal', '$q',
    '$timeout', 'Upload', 'dialogs', 'Lightbox', 'oedConfig', 'oedPresentResource', 'oedPresentResourceItem',
    'oedUnitItem', 'oedCloudPresentResItem', 'oedPresentResourceSection', 'oedMisc', 'oedOem', 'oedDialogService']
  function editPresentResCtrl($scope, $state, $stateParams, $log, $uibModal, $q,
    $timeout, Upload, dialogs, Lightbox, oedConfig, oedPresentResource, oedPresentResourceItem,
    oedUnitItem, oedCloudPresentResItem, oedPresentResourceSection, oedMisc, oedOem, oedDialogService) {

    var resId = _.parseInt($stateParams.resId);
    $scope.enableManage = oedOem.curOem() != 'futureSchool'

    $scope.$on('presentResource.changed', function(event, args) {
      if (args.resId == resId) {
        loadResources();
      }
    });

    $scope.courseSections = oedUnitItem.queryAvailableBySchoolAndCourse({
      schoolId: 0, //schoolId 无用, 传一个dummy的值
      courseId: $stateParams.courseId
    });

    $scope.resSections = oedPresentResourceSection.queryByPresentResId({
      resId: resId
    });

    var treeLoading = $q.defer();

    function loadResources() {
      $scope.resource = oedPresentResource.get({
        id: resId
      });

      var loadDetails = $scope.resource.$promise.then(function(res) {
        var loadCloudItems = _.map(res.items, function(item) {
          return oedCloudPresentResItem.getByResItemId({
            id: item.id
          }).$promise.then(function(ci) {
            item.cloudRes = ci;
            return item;
          });
        });

        return loadCloudItems;
      });

      $scope.loading = $q.all([loadDetails, $scope.resSections.$promise, treeLoading.promise]);
      return $scope.loading;
    }

    $scope.loading = $q.all([loadResources(), $scope.courseSections.$promise]);

    $scope.sectionTree = {
      treeData: []
    };

    $scope.sectionIdMap = {};
    $scope.courseSections.$promise.then(function() {

      function visitSection(sec) {
        if (sec.id) $scope.sectionIdMap[sec.id] = sec;
        if (sec.child) {
          sec.child = _.sortBy(sec.child, ['sort']);
          _.each(sec.child, visitSection);
        }
      }
      _.each($scope.courseSections, visitSection);

      $scope.sectionTree.treeData = oedMisc.utils.convertSectionsToJsTreeModel($scope.sectionIdMap,
        $scope.courseSections);
      $scope.treeConfig.version++;
      _.each($scope.sectionTree.treeData, function(data) {
        data.state.opened = true;
      });
    });

    var lastDragStop = null;
    $scope.sortableOptions = {
      stop: function(event) {
        lastDragStop = moment();

        var items = _.map($scope.resource.items, function(item) {
          return {
            id: item.id
          };
        });

        var res = new oedPresentResource({
          id: $scope.resource.id,
          items: items
        });

        $scope.loading = res.$updateItemOrder();
      }
    };

    $scope.toggleUpload = function() {
      if (!$scope.currentRes) return;

      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/resourceUploader.html'),
        controller: 'uploadResourceCtrl',
        size: 'md',
        windowClass: 'oed-common-modal modalCenter',
        resolve: {
          resourceId: function() {
            return $scope.currentRes.id;
          },
          url: function() {
            return oedConfig.url('presentresource/' + $scope.currentRes.id + '/uploadresource');
          },
          isPrepareLesson:false,
          moduleId:null,
          packageId:null
        }
      });

      modalInstance.result.then(function(r) {
        if (r.uploaded && r.uploaded > 0) {
          loadResources();
        }
      });
    };
    $scope.toggleSelect = function(item) {
      var idx = _.indexOf($scope.selectedReses, item);
      if (idx >= 0) {
        $scope.selectedReses.splice(idx, 1);
      } else {
        $scope.selectedReses.push(item);
      }
    };

    $scope.isSelected = function(item) {
      return _.indexOf($scope.selectedReses, item) >= 0;
    };

    $scope.deleteSelected = function() {
      var message = '确定要删除展示资源吗（共' + _.size($scope.selectedReses) + '项）？：';
      _.each($scope.selectedReses, function(res) {
        message = message + '<br>' + res.resourceName;
      });
      var dlg = dialogs.confirm('确定删除?', message);

      dlg.result.then(function() {
        $scope.loading = $q(function(resolve, reject) {
          var items = $scope.selectedReses;
          var promises = _.map(items, function(item) {
            return oedPresentResourceItem.delete({
              id: item.id
            }).$promise;
          });

          $q.all(promises).catch(() => reject()).finally(function() {
            $scope.selectedReses = [];
            loadResources().then(() => resolve());
          });
        });
      });
    };

    $scope.getItemName = function(item) {
      if (_.get(item, 'resourceName'))
        return _.get(item, 'resourceName');

      if (_.get(item, 'flResource.name'))
        return _.get(item, 'flResource.name');
      return '';
    };
    $scope.showPreview = function(reses, idx, event) {
      event.stopPropagation();
      event.preventDefault();

      var now = moment();
      if (!!lastDragStop && now.diff(lastDragStop) < 100) {
        return;
      }

      _.forEach(reses, res => res.$$fromEdit = true)
      const modalInstance = Lightbox.openModal(reses, idx);

      modalInstance.result.then(function(res) {if (res) {
          if (resourceUtils.editableSuffix.some(suffix => res.flResource.suffix === suffix)) {
            oedDialogService.openModalWithComponent('OfficeEditorDialogComponent',
              {data: {res}}, ()=> {$scope.loading = $timeout(loadResources, 3000)},
              {class: 'modal-full-screen cl-library-dialog2 padding-lg'})
          }
        }})
    };

    $scope.addUrlItem = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/addWebUrlItem.html'),
        controller: 'addWebUrlItemCtrl',
        size: 'md',
        windowClass: 'oed-common-modal modalCenter',
        resolve: {
          data: function() {
            return {
              resourceId: resId
            };
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r) {
          loadResources();
        }
      });
    };

    $scope.treeConfig = {
      core: {
        multiple: true,
        animation: true,
        error: function(error) {
          $log.error('treeCtrl: error from js tree - ' + angular.toJson(error));
        },
        check_callback: true,
        worker: true
      },
      types: {
        default: {
          icon: 'fa fa-book'
        },
        root: {
          icon: 'glyphicon glyphicon-cloud'
        }
      },
      version: 1,
      plugins: ['types', 'checkbox']
    };

    var fileTypes = {
      audio: {
        iconClass: 'oed-file-type-new fa fa-file-audio-o',
        tooltip: '音频'
      },
      file: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '文件'
      },
      image: {
        iconClass: 'oed-file-type-new fa fa-file-photo-o',
        tooltip: '图片'
      },
      link: {
        iconClass: 'oed-file-type-new fa fa-link',
        tooltip: '链接'
      },
      office: {
        iconClass: 'oed-file-type-new fa fa-file-word-o',
        tooltip: '文档'
      },
      video: {
        iconClass: 'oed-file-type-new fa fa-file-video-o',
        tooltip: '视频'
      },
      unknown: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '其他文档'
      }
    };

    $scope.getFileTypeIconClass = function(type, url) {
      if (!fileTypes[type]) return null;
      if (url) type = 'link';
      return fileTypes[type].iconClass;
    };

    $scope.getSelectedSectionNames = function() {
      var defaultText = '选择知识点';
      if (!$scope.treeInstance) {
        return defaultText;
      }
      return '';
    };

    $scope.onTreeReady = function() {
      treeLoading.resolve();
    };

    function updateTreeSelection() {
      var sections = _.map($scope.resSections, function(relSec) {
        return relSec.sectionId;
      });

      $scope.treeInstance.jstree(true).select_node(sections, true, true);
    }
    $q.all([treeLoading.promise, $scope.resSections.$promise]).then(updateTreeSelection);

    $scope.onShowSections = function(open) {
      if (open) {
        return;
      }

      var selectedNodes = _.map($scope.treeInstance.jstree(true).get_selected(), function(s) {
        return _.parseInt(s);
      });

      var curIds = _.map($scope.resSections, function(sec) {
        return sec.sectionId;
      });

      if (_.isEmpty(selectedNodes)) {
        selectedNodes = _.map($scope.course.relatedSections, 'sectionId');
        updateTreeSelection();
      }

      if (_(curIds).xor(selectedNodes).isEmpty()) {
        return;
      }

      //
      // to save new ids
      $scope.resSections = oedPresentResourceSection.updatePresentResourceSections({
        resId: resId
      }, selectedNodes);

      $scope.loading = $scope.resSections.$promise.then(function(secs) {});
    };

    $scope.showShowCloudResNo = function() {
      return !$scope.isEmptyResource($scope.resource) && _.isNumber($scope.sharedResourceId);
    };

    $scope.isEmptyResource = function(res) {
      if (!res) {
        return true;
      }

      return _.get(res, 'isEmpty', false) || _.isEmpty(_.get(res, 'items'));
    };

    $scope.addMicroLessonRecord = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/addMicroLessonRecordDialog.html'),
        controller: 'addMicroLessonRecordCtrl',
        size: 'lg',
        windowClass: 'new-lib-style fixedModal',
        resolve: {
          data: function() {
            return {
              presentresourceId: $scope.currentRes.id
            };
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r) {
          loadResources();
        }
      });
    };
  }
})();
