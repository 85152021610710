require('assets/app/controllers/examination/examEdit/editObjectTestPaper.scss');
require('assets/app/controllers/examination/examSummary/examSummaryCtrl');
require('assets/app/controllers/examination/examNav/examNavCtrl');
require('assets/app/controllers/examination/examPartsEditor/examPartsEditorCtrl');
require('assets/app/controllers/examination/dialog/editSummaryEditCtrl');
require('assets/app/controllers/examination/dialog/deletePartCtrl');
require('assets/app/controllers/examination/examEdit/header/examEditHeaderCtrl');
require('assets/app/controllers/examination/examEdit/generating/examEditGeneratingCtrl');
require('assets/app/controllers/examination/examEdit/generating/questionType/questionTypeCtrl');
require('assets/app/controllers/examination/examEdit/statistics/examEditStatCtrl');
require('assets/app/controllers/examination/examEdit/recommend/examEditRecommendCtrl');
require('assets/app/controllers/examination/examEdit/paper/examEditPaperCtrl');
require('assets/app/controllers/examination/examNm/examNmCtrl');
import { checkExamIsSaved } from 'assets/app/controllers/examination/examEdit/paper/paperUtils'
import { getRyKnowledget } from "app2/integration/ruanyun/ruanyunUtils";

import * as _ from 'lodash'
import { createQuesUuidForExam, willSaveExam, checkExamTable, willSaveExamWithConfirm2, SaveExamInjectOpt, DialogOpt } from '../examUtils';

angular.module('app.examination.editObjectTestPaper', [
  'app.examination.editObjectTestPaper.examSummary',
  'app.examination.editObjectTestPaper.examNav',
  'app.examination.editObjectTestPaper.examPartsEditor',
  'app.examination.editObjectTestPaper.editSummaryEdit',
  'app.examination.editObjectTestPaper.deletePart',
  'app.examination.examEdit.header',
  'app.examination.examEdit.generating',
  'app.examination.examEdit.generating.questionType',
  'app.examination.examEdit.statistics',
  'app.examination.examEdit.paper',
  'app.examination.examEdit.recommend',
  'app.examination.examEdit.examNm',
]).config(['$stateProvider', ($stateProvider) => {
  $stateProvider.state('editObjectTestPaper', {
    url: '/editObjectTestPaper?coursepathId&sectionId&sectionParentId&sectionRootId&levelId&relId',
    template: require('assets/app/controllers/examination/examEdit/editObjectTestPaper.html'),
    controller: 'editObjectTestPaperCtrl',
    loadingCls: 'blueloading',
    bodyStyle: 'bged text-hidden'
  });
}]).controller('editObjectTestPaperCtrl', editObjectTestPaperCtrl);

export enum ExamEditPage { GroupPage, RecommendPage }

editObjectTestPaperCtrl.$inject = ['$stateParams', '$scope', 'oedExamination', '$location', '$anchorScroll',
  'userInfo', 'notificationService', 'oedUnitItem', 'ruanyunResource', 'dialogs', '$state', '$uibModal', 'examApi']
function editObjectTestPaperCtrl($stateParams, $scope, oedExamination, $location, $anchorScroll,
  userInfo, notificationService, oedUnitItem, ruanyunResource, dialogs, $state, $uibModal, examApi
) {
  $scope.examId = -1
  $scope.isExamUpdate = false
  $scope.currentPage = ExamEditPage.GroupPage;
  $scope.coursepathId = $stateParams.coursepathId;
  // 如果没有sectionParentId，则统一使用sectionId
  $scope.sectionParentId = _.isEmpty($stateParams.sectionParentId) ? $stateParams.sectionId : $stateParams.sectionParentId;
  $scope.sectionId = $stateParams.sectionId;
  $scope.sectionRootId = $stateParams.sectionRootId
  $scope.levelId = $stateParams.levelId
  $scope.relId = $stateParams.relId

  const injOpt: SaveExamInjectOpt  = {
    notificationService, 
    $uibModal, 
    oedExamination, 
    loading: $scope.loading
  }

  $scope.loading = oedExamination.getCoursePath({ id: $scope.coursepathId }).$promise
    .then((coursepath) => {
      if (_.isEmpty(coursepath) || !coursepath.gradeId || !coursepath.subjectId || !coursepath.versionId) {
        notificationService.notify('error', '获取章节信息失败');
        return
      }
      getRyKnowledget(examApi, coursepath.subjectId, $scope.levelId)
      .then((kps) => {
        $scope.knowledgePoints = kps
      })
    })

  $scope.saveExamTest = () => {
    willSaveExam($scope.examData, notificationService, $uibModal, oedExamination, $scope.loading, (data) => {
      const {examId, examData} = data
      $scope.examId = examId
      $scope.examData = examData
    })
  }

  $scope.cloneExamTest = () => {
    console.log('cloneExamTest')
  }

  $scope.init = function () {
    $location.hash(`exam_paper_header`);
    $anchorScroll();
    loadData()
  };
  $scope.init();

  $scope.onSetRequestData = (requestData: any) => {
    if (_.isEmpty(requestData)) {
      notificationService.notify('info', '获取组卷信息失败')
      return
    }
    $scope.currentPage = ExamEditPage.RecommendPage
    $scope.examData = requestData
  };

  function treeToArray(childs: any[]) {
    return _.flatten(_.map(childs, (child: any) => _.concat(child, _.flatten(treeToArray(child.child)))))
  }

  function loadData() {
    loadSectionParent()
  }

  function loadSectionParent() {
    $scope.loading = userInfo.then((info) => {
      $scope.info = info;
      $scope.isResearcher = _.findIndex(_.get(info, 'roles', []), (r) => { return r === 'researcher' }) !== -1;
      oedUnitItem.get({ id: $scope.sectionParentId }).$promise
        .then((sectionParent: any) => {
          // 考试获取题目的信息统一用sectionParent来获取
          $scope.sectionParentName = _.get(sectionParent, 'name', '')
          oedUnitItem.get({ id: $scope.sectionId }).$promise
            .then((seciton: any) => {
              $scope.examName = _.get(seciton, 'name', '')
            })
        })
    })
  }

  $scope.updateExamDataByExam = (examData: any) => {
    $scope.examData = examData
  }

  $scope.updateVisibleLetters = (letters) => {
    $scope.letters = letters
    $scope.currentTag = []
    $scope.currentNm = []
    if (_.size(letters) > 0) {
      $scope.previous = $scope.letters
    }
    if (_.size($scope.previous) > 0) {
      $scope.currentTag = _.split($scope.previous[0], '_')[1]
      $scope.currentNm = _.split($scope.previous[0], '_')[2]
    }
  }

  $scope.updateCurExamById = (id: number) => {
    const isSaved = checkExamIsSaved($scope.examData)
    if (isSaved) {
      getExamWholeById(id)
      return
    }
    getExamWholeById(id)
  }

  $scope.toggleExamTest = (id: number) => {
    const isSaved = checkExamIsSaved($scope.examData)
    if (!isSaved) {
      const dialogOpt: DialogOpt = {
        yesAction: (data) => {
          const {examId, examData} = data
          $scope.examId = examId
          $scope.examData = examData
          getExamWholeById(id)
        },
        noAction: () => {
          getExamWholeById(id)
        }
      }
      willSaveExamWithConfirm2($scope.examdata)(dialogOpt)(injOpt)

      return
    }
    getExamWholeById(id)
  }

  function getExamWholeById(examId: number) {
    $scope.loading = oedExamination.getExamTestWhole({ examId }).$promise
      .then((res) => {
        $scope.examId = _.get(res, 'examWithTags.exam.id', '')
        $scope.examData = createQuesUuidForExam(res)
      })
  }

  $scope.toggleCurrentPage = (page: number) => {
    if (page == ExamEditPage.RecommendPage) {
      // 如果从智能组卷点预览进入编辑，都应该清空当前并且重新加载
      $scope.examData = {}
    }
    $scope.currentPage = page
  }

  $scope.onCheckCurExamDeleted = (examList: any[], index: number) => {
    $scope.isExamUpdate = false
    if (_.size(examList) <= 1) {
      return
    }
    const deleteExamId = _.get(examList, `${index}.id`)
    if (deleteExamId == $scope.examId) {
      // 如果删除了当前选中的试卷，统计和编辑试卷就置空
      $scope.examData = {}
    }
  }

  $scope.onExamIsUpdate = (update: boolean) => {
    $scope.isExamUpdate = update
  }

  $scope.onJumpToPage = (page: string) => {
    const isSaved = checkExamIsSaved($scope.examData)
    if (!isSaved && $scope.currentPage == ExamEditPage.RecommendPage) {
      const dialogOpt: DialogOpt = {
        yesAction: (data) => {
          const {examId, examData} = data
          $scope.examId = examId
          $scope.examData = examData
          $state.go(page)
        },
        noAction: () => {
          $state.go(page)
        },
        title: '返回',
      }
      willSaveExamWithConfirm2($scope.examdata)(dialogOpt)(injOpt)

      return
    }
    checkExamTable($scope.examData, $uibModal).then(() => {
      $state.go(page)
    })
  }

  $scope.onCreatGroupPaper = () => {
    const isSaved = checkExamIsSaved($scope.examData)
    if (isSaved || $scope.currentPage == 0) {
      $scope.toggleCurrentPage(0)
      return
    }
    const dialogOpt: DialogOpt = {
      yesAction: (data) => {
        const {examId, examData} = data
        $scope.examId = examId
        $scope.examData = examData
        $scope.toggleCurrentPage(0)
      },
      noAction: () => {
        $scope.toggleCurrentPage(0)
      }
    }
    willSaveExamWithConfirm2($scope.examdata)(dialogOpt)(injOpt)

  }

  $scope.onBackToPrePage = () => {
    $scope.onJumpToPage('resCenter')
  }
}
