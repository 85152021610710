import { Directive, ElementRef, Injector, SimpleChanges, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'oed-question-designer'
})
export class QuestionDesignerlDirective extends UpgradeComponent {
  @Input() isExercise: boolean
  @Input() typeText: string
  @Input() questionModel: any
  @Input() cloudRes: any
  @Input() currentTest: any
  @Input() score: number

  constructor(elementRef: ElementRef, injector: Injector) {
    super('quesDecorate', elementRef, injector);
  }
}
