(function() {
  angular.module('app.prepareCourse') //声明angular模块
    .controller('addTaskCtrl', addTaskCtrl);

  addTaskCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedTask', 'data']
  function addTaskCtrl($scope, $q, $uibModalInstance, oedTask, data) {
    $scope.isEdit = !!data.isEdit;

    $scope.task = new oedTask({
      id: data.id,
      taskListId: data.taskListId,
      sort: data.sort,
      name: data.name,
      description: data.description
    });

    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.addTaskForm.$invalid) {
        return;
      }

      var task = $scope.task;
      $scope.loading = task.$save().then(function() {
        $uibModalInstance.close({
          task: task
        });
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
