import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import {AppStateService} from 'app/core/app-state.service';
import { LegacyAppService } from 'app/core/legacy-app.service';
import { MaterialService } from 'app/lesson/material.service';
import { UnitItemDTO } from 'app/lesson/models/material';
import {PreferenceService} from 'app/lesson/services/preference.service';
import * as _ from 'lodash';

/**
 * 这里是章节信息
 */
@Component({
  selector: 'app-section-info',
  template: require('./section-info.component.html'),
  styles: [require('./section-info.component.scss')]
})
export class SectionInfoComponent implements OnInit {

  @Input() public sectionId: number
  private name: string

  constructor(
    private materialService: MaterialService,
    private legacyApp: LegacyAppService,
    private userInfo: AppStateService,
    private preferenceService: PreferenceService) { }

  public ngOnInit() {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (_.has(changes, 'sectionId')) {
      this.getUnitItem()
    }
  }

  private getUnitItem() {
    this.materialService.getUnitItemById(this.sectionId).then((res: UnitItemDTO) => {
      this.name = res.name
    })
  }

  private onUseOld() {
    this.legacyApp.getLocalStorage().set('prepareLessonUseNewVersion')
    this.userInfo.then((info) => {
      this.preferenceService
          .updateUserNewPrepareLessonState({uid: Number(info.uid), value: '0'})
          .subscribe((next) => {
          }, (error) => {
          })
    })
  }
}
