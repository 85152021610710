import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JoinSchoolComponent } from './join-school/join-school.component';
import { SharedModule } from 'app/shared/shared.module';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { postLoginStates } from './post-login-routing.state';
import { FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { MenuModule } from '../menu/menu.module'

@NgModule({
  declarations: [JoinSchoolComponent],
  imports: [
    CommonModule,
    FormsModule,
    TypeaheadModule.forRoot(),
    SharedModule,
    UIRouterUpgradeModule.forChild({ states: postLoginStates, }),
    MenuModule,
  ]
})
export class PostLoginModule { }
