import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdjustScoreComponentComponent } from './adjust-score-component/adjust-score-component.component';
import { PartInfoComponent } from './part-info/part-info.component';
import { PartQuesInfoComponent } from './part-ques-info/part-ques-info.component';
import { EditQuesScoreComponent } from './edit-ques-score/edit-ques-score.component';
import { UtilsModule } from 'app/utils/utils.module';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormsModule } from '@angular/forms';
import { ExamQuestionDesignComponent } from './exam-question-design/exam-question-design.component';
import { CourseEditingModule } from 'app/course-editing/course-editing.module';
import { QuestionTypeSwitcherComponent } from './question-type-switcher/question-type-switcher.component';
import { ClickHookComComponent } from './click-hook-com/click-hook-com.component';

@NgModule({
  declarations: [AdjustScoreComponentComponent, PartInfoComponent, PartQuesInfoComponent,
    EditQuesScoreComponent, ExamQuestionDesignComponent, QuestionTypeSwitcherComponent, ClickHookComComponent],
  entryComponents: [AdjustScoreComponentComponent, ExamQuestionDesignComponent, QuestionTypeSwitcherComponent,
    ClickHookComComponent],
  imports: [
    CommonModule,
    UtilsModule,
    FormsModule,
    CourseEditingModule,
    TooltipModule.forRoot(),
  ]
})
export class ExamModule { }
