(function() {
  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentTaskDetails.res', {
        url: '/task/:taskId/res',
        template: '<ui-view/>',
        abstract: true
      });
    }]);
})();
