import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UeditorComponent } from './ueditor/ueditor.component';
import { InlineUeEditorComponent } from './inline-ue-editor/inline-ue-editor.component';
import { SharedModule } from 'app/shared/shared.module';
import { UtilsModule } from 'app/utils/utils.module';
import { UiTreeComponent } from './ui-tree/ui-tree.component';

@NgModule({
  declarations: [UeditorComponent, InlineUeEditorComponent, UiTreeComponent],
  entryComponents: [UeditorComponent, UiTreeComponent],
  exports: [UeditorComponent, InlineUeEditorComponent, UiTreeComponent],
  imports: [
    CommonModule,
    UtilsModule,
  ]
})
export class EditingModule { }
