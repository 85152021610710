import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {OnlineTrainingDirectoryDTO} from 'app/online-train/models/online-train';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import { StateService } from '@uirouter/core'
import * as _ from 'lodash';
import {flatMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {OedDialogService} from 'app/core/oed-dialog.service';
import {TrainResourceViewerComponent} from 'app/online-train/train-design/train-resource-viewer/train-resource-viewer.component';
import {TrainMassageService} from 'app/online-train/train-massage.service';

@Component({
  selector: 'app-train-qa',
  template: require('./train-qa.component.html'),
  styles: [require('./train-qa.component.scss')]
})
export class TrainQAComponent implements OnInit {
  @Input() public dirs: OnlineTrainingDirectoryDTO[]
  @Input() public parentId
  @Input() public editable
  @Input() public prefix
  @Input() public subNavs
  @Output() public onEdit = new EventEmitter<any>()

  private selectedDir
  private allDirs: OnlineTrainingDirectoryDTO[]
  private dirTree
  private itemType
  private itemTypeQA
  private qas
  constructor(private onlineTrainService: OnlineTrainService,
              private oedDialogService: OedDialogService,
              private trainMessage: TrainMassageService,
              private state: StateService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'parentId')) {
      this.parentId = changes.parentId.currentValue
    }
    if (_.has(changes, 'dirs')) {
      this.dirs = changes.dirs.currentValue
    }
    if (_.has(changes, 'prefix')) {
      this.prefix = changes.prefix.currentValue
    }
    if (_.has(changes, 'subNavs')) {
      this.subNavs = changes.subNavs.currentValue
    }

    if (this.parentId > 0) {
      this.loadData()
    }
  }

  public onSelectDir(dir) {
    this.trainMessage.sendLoadingAction(true)
    this.selectDir(dir).subscribe(() => {}, () => {}, () => this.trainMessage.sendLoadingAction(false))
  }

  public getDirDataToAdd() {
    return {
      dirId: this.parentId,
      name: '',
      sort: 0,
      deleted: false
    }
  }

  public getQaDataToAdd() {
    return {
      question: '',
      answer: '',
      dirId: this.getParentIdForAdd(),
      deleted: false
    }
  }

  public onEditDir(params) {
    this.onEdit.emit(params)
  }

  public onEditQa(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      this.addQa(params)
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      this.updateQa(params)
    } else if (params.editType === this.onlineTrainService.DELETE) {
      this.deleteQa(params)
    }
  }

  public getQ(qa) {
    const idx = _.findIndex(this.qas, qa)
    const prefix = `Q${idx + 1}: `
    return prefix + qa.question
  }

  public getA(qa) {
    return `A: ${qa.answer}`
  }

  public onSelect(content) {
    //
    this.oedDialogService.openModalWithComponent2('TrainResourceViewerComponent', {
      url: content.$$url,
      webUrl:  content.$$webUrl,
      resource: content.$$resource
    }, {
      class: 'modal-lg',
    })
  }

  public resourceEdit(qa) {
    this.state.go('onlineTrain.qacontent', {
      parentId: qa.id,
      editable: this.editable,
      dirs: this.allDirs,
      prefix: this.prefix,
      other: qa
    });
  }

  public videoType(content) {
    return this.onlineTrainService.videoType(content.$$resource)
  }

  public imgTextType(content) {
    return this.onlineTrainService.imgTextType(content.$$resource)
  }

  private selectDir(dir) {
    this.selectedDir = dir
    return this.loadQasByDirId(dir.id)
  }

  private getParentIdForAdd() {
    if (_.isEmpty(this.dirs)) {
      return this.parentId
    } else if (_.get(this.selectedDir, 'id', -1) > 0) {
      return this.selectedDir.id
    }
  }
  private addQa(params) {
    this.onlineTrainService.addQa(params.item).pipe(
      flatMap(() => this.onlineTrainService.getAllDirs()),
      flatMap((dirs) => this.initData(dirs))
    ).subscribe(() => {}, (err) => {console.log(err)})
  }

  private updateQa(params) {
    this.onlineTrainService.updateQa(params.item).pipe(
      flatMap(() => this.onlineTrainService.getAllDirs()),
      flatMap((dirs) => this.initData(dirs))
    ).subscribe(() => {}, (err) => {console.log(err)})
  }

  private deleteQa(params) {
    this.onlineTrainService.deleteQa(params.item.id).pipe(
      flatMap(() => this.onlineTrainService.getAllDirs()),
      flatMap((dirs) => this.initData(dirs))
    ).subscribe(() => {
    }, (err) => {console.log(err)})
  }

  private initData(dirs: OnlineTrainingDirectoryDTO[]) {
    this.allDirs = _.filter(dirs, (d: OnlineTrainingDirectoryDTO) => d.id > 1)
    this.dirTree = this.onlineTrainService.buildDirTree(this.allDirs)
    this.dirs = this.dirTree[this.parentId]
    this.itemType = this.onlineTrainService.DIR
    this.itemTypeQA = this.onlineTrainService.QA

    if (!_.isEmpty(this.dirs)) {
      let selectDir = this.selectDirFromSub(this.dirs)
      if (_.isEmpty(selectDir)) {
        selectDir = this.dirs[0]
      }
      return this.selectDir(selectDir)
    } else {
      return this.loadQasByDirId(this.parentId)
    }
  }

  private selectDirFromSub(dirs) {
    if (_.isEmpty(this.subNavs)) {
      return null
    }

    return _.find(dirs, (dir) => !_.isEmpty(_.find(this.subNavs, (d) => d.id === dir.id)))
  }

  private loadData() {
    this.trainMessage.sendLoadingAction(true)
    this.onlineTrainService.getAllDirs().pipe(
      flatMap((dirs: OnlineTrainingDirectoryDTO[]) => {
        return this.initData(dirs)
      })).subscribe(() => {}, () => {}, () => this.trainMessage.sendLoadingAction(false))
  }

  private loadQasByDirId(dirId) {
    return this.onlineTrainService.getQasByDirId(dirId).pipe(
      flatMap((qas) => {
        this.qas = qas
        const req = _.map(this.qas, (qa) => this.loadContentsByQaId(qa))
        if (_.isEmpty(req)) {
          return of([])
        } else {
          return forkJoin(...req)
        }
      }),
      map((contents) => {
        return of(contents)
      })
    )
  }

  private loadContentsByQaId(qa) {
    return this.onlineTrainService.getContentsByQaId(qa.id).pipe(
      flatMap((contents) => {
        qa.contents = contents
        if (_.isEmpty(contents)) {
          return of([])
        } else {
          const req = _.map(contents, (content) => this.onlineTrainService.getResource(content.resourceId))
          return forkJoin(...req)
        }
      }),
      map((reses) => {
        if (!_.isEmpty(reses)) {
          this.onlineTrainService.enhanceContent(qa.contents, reses)
        }
        return of(qa.contents)
      })
    )
  }
}
