import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { RayApiService } from 'app/core/ray-api.service';
import { ClassroomService } from 'app/shared/classroom.service';
import { HttpClient } from '@angular/common/http';
import { TestSessionStatByUserDTO2, TestAnswerDTO, TestSessionDetailsDTO, TestSessionStatDTO, TestSessionStudentDTOView, TestSessionStudentDTOViewProgress, RowDTO, TestSessionStatByQuesNewDTO, TestSessionStudentFillQuesStateDTO } from 'app/models/obj-test-session';
import { zip, of, from, onErrorResumeNext, Observable } from 'rxjs';
import { map, flatMap, toArray } from 'rxjs/operators';
import { UserInfoService } from 'app/core/user-info.service';
import { MyInfoDTO } from 'app/models/user';
import { OEDSchoolClassStudentDTO } from 'app/models/classroom';

@Injectable({
  providedIn: 'root'
})
export class TestSessionService {

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService,
              private classroomService: ClassroomService,
              private userInfoService: UserInfoService) { }

  public getQuesAnswerStatByUser({ sessionId, userId }: {
    sessionId: number; userId: number;
  }) {
    return this.httpClient.get<TestSessionStatByUserDTO2>(
      this.rayApi.apiA('testsession/statbyuser2'), {
        params: {
          sessionId: '' + sessionId,
          userId: '' + userId,
        }
      })
  }

  public getStudentAnsersByDevice(testSessionId: number, deviceId: string) {
    return this.httpClient.post<TestAnswerDTO[]>(this.rayApi.apiB(`testsession/${testSessionId}/device/answers`), {
      deviceId,
    })
  }

  public getDetails(testSessionId: number) {
    return this.httpClient.get<TestSessionDetailsDTO>(this.rayApi.apiA('testsession/details'), {
      params: {
        sessionId: '' + testSessionId,
      }
    })
  }

  public getTestSessionStat(sessionId: number) {
    return this.httpClient.get<TestSessionStatDTO>(this.rayApi.apiA('testsession/statoftimeandrank'), {
      params: {
        sessionId: '' + sessionId,
      }
    })
  }

  /**
   * 获取上课后的查询下学生完成与未完成列表
   * @param id classId
   * @param testSessionId testSessionId
   * @return [] => 0: finishedStds, 1: unFinishedStds, 2: unJoinStds
   */
  public queryStdListFromClassSession(classId: number, testSessionId: number): Observable<TestSessionStudentDTOViewProgress[][]> {
    const testSessionDetails$: Observable<TestSessionDetailsDTO> = this.getDetails(testSessionId)
    const classStds$ = this.classroomService.queryClassStudents(classId)
    const summary$ = this.getTestSessionStat(testSessionId)

    return zip(testSessionDetails$, classStds$, summary$)
      .pipe(flatMap(([tsDetails, stds, summary]) => {
        const mapClassStdsByUid = _.keyBy(stds, 'uid');
        const allStdIds: number[] = _.map(tsDetails.students, 'studentId')
        const [, noExistStdIds] = _.partition(allStdIds, (uid) => _.has(mapClassStdsByUid, uid))
        //
        // 加载不存在的学生信息(学生被移出了班级)
        return from(noExistStdIds)
          .pipe(flatMap((stdId) => this.userInfoService.getByUid(stdId)),
                toArray(),
                map((nonExistInfos: MyInfoDTO[]) => {
                  const nonExistInfoMap = _.keyBy(nonExistInfos, 'uid')
                  const newStds = _.chain(tsDetails.students).map((std) => {
                    if (_.has(mapClassStdsByUid, std.studentId)) {
                      const info = mapClassStdsByUid[std.studentId]
                      return {
                        ...std,
                        uid: std.studentId,
                        name: info.name,
                        gender: info.gender,
                      }
                    }
                    if (_.has(nonExistInfoMap, std.studentId)) {
                      const info = nonExistInfoMap[std.studentId]
                      return {
                        ...std,
                        uid: std.studentId,
                        name: info.name,
                        gender: info.gender,
                      }
                    }
                    return null // 学生信息不存在，忽略此类学生
                  }).filter().value()
                  return newStds as TestSessionStudentDTOView[]
                }), map((newStds) => {
                  const stdIdMap = _.keyBy(summary.statOfRank.rows, 'stdId')
                  const [ stdSubmitted, stdNoProgress ] = this.partitionSubmitStudents(newStds, stdIdMap)
                  return this.partitionUnJoinStds(stdSubmitted, stdNoProgress)
                }))
      }))
  }

  /**
   * 提交的学生的submitTime不为null
   * @param students 全部学生
   * @param map 参与答题的学生
   */
  private partitionSubmitStudents(students: TestSessionStudentDTOView[], stdIdMap: {[key: string]: RowDTO }): TestSessionStudentDTOViewProgress[][] {
    return _.chain(students).map((s) => {
      let submitProgress = 0
      if (s.uid in stdIdMap && !_.isNil(s.submitTime)) {
        submitProgress = 100;
      }
      return {
        ...s,
        submitProgress,
      }
    }).partition(s => {
      return s.submitProgress === 100;
    }).value()
  }

  /**
   * @param stds 全部学生
   * @return 提交的学生-未完成的学生-未参与的学生
   */
  private partitionUnJoinStds(finishedStds: TestSessionStudentDTOViewProgress[], unsubmitStds: TestSessionStudentDTOViewProgress[]): TestSessionStudentDTOViewProgress[][]  {
    const part = _.partition(unsubmitStds, (s) => {
      return s.wasOnline;
    })
    const unFinishedStds = part[0]
    const unJoinStds = part[1]
    return [finishedStds, unFinishedStds, unJoinStds]
  }

  public getStatByQuestionsWithRevise(sessionId: number) {
    return this.httpClient.get<TestSessionStatByQuesNewDTO[]>(this.rayApi.apiA('testsession/statByQuesWithRevise'), {
      params: {
        sessionId: '' + sessionId,
      }
    })
  }

  public getFillQuestionStatus(testSessionIds: number[], uids: number[]) {
    return this.httpClient.post<TestSessionStudentFillQuesStateDTO[]>(this.rayApi.apiA('testsession/stdFillQuesStates'), {
      testSessionIdList: testSessionIds,
      userIdList: uids,
    })
  }
}
