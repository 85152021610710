import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {OnlineTrainingDirectoryDTO} from 'app/online-train/models/online-train';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import {AppStateService} from 'app/core/app-state.service';
import { StateService } from '@uirouter/core'
import {flatMap} from 'rxjs/operators';
import {TrainMassageService} from 'app/online-train/train-massage.service';

@Component({
  selector: 'app-train-dirs',
  template: require('./train-dirs.component.html'),
  styles: [require('./train-dirs.component.scss')]
})
export class TrainDirsComponent implements OnInit {
  @Input() public params: any

  private parentId
  private editable
  private allDirs: OnlineTrainingDirectoryDTO[]
  private dirTree
  private dirs: OnlineTrainingDirectoryDTO[]
  private dirsLvl2: OnlineTrainingDirectoryDTO[]
  private selectedDirLvl2
  private dirsLvl3: OnlineTrainingDirectoryDTO[]
  private selectedDirLvl3
  private dirsLvl4: OnlineTrainingDirectoryDTO[]
  private lvl4ParentId
  private itemType
  private prefix
  private subNavs
  constructor(private state: StateService,
              private onlineTrainService: OnlineTrainService,
              private trainMassage: TrainMassageService) { }

  ngOnInit() {
    this.parentId = _.parseInt(this.params.parentId)
    this.editable = this.params.editable
    this.prefix = this.params.prefix
    this.subNavs = this.params.subNavs

    this.loadData()
  }

  public selectLvl2(dir) {
    this.selectedDirLvl2 = dir
    this.initDataOfLvl3(this.selectedDirLvl2.id)
  }

  public selectLvl3(dir) {
    if (_.isEmpty(dir)) {
      this.dirsLvl4 = []
      this.lvl4ParentId = 0
      return
    }
    this.selectedDirLvl3 = dir
    const lvl4Parent = this.selectedDirLvl3.id
    this.dirsLvl4 = this.dirTree[lvl4Parent]
    this.lvl4ParentId = lvl4Parent
  }

  public getDataToAddLvl3() {
    return {
      dirId: this.selectedDirLvl2 && this.selectedDirLvl2.id ? this.selectedDirLvl2.id : this.parentId,
      name: '',
      sort: 0,
      deleted: false
    }
  }

  public showLvl3() {
    return this.prefix !== this.onlineTrainService.lvl1MetaDatas[0].prefix
  }

  public showQA() {
    if (this.lvl4ParentId > 0) {
      return this.prefix === this.onlineTrainService.lvl1MetaDatas[5].prefix
    } else {
      return true
    }
  }

  public onEdit(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      this.addDir(params)
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      this.updateDir(params)
    } else if (params.editType === this.onlineTrainService.DELETE) {
      this.deleteDir(params)
    }
  }

  private initData(dirs: OnlineTrainingDirectoryDTO[]) {
    this.allDirs = _.filter(dirs, (d: OnlineTrainingDirectoryDTO) => d.id > 1)
    this.dirTree = this.onlineTrainService.buildDirTree(this.allDirs)
    if (this.prefix === this.onlineTrainService.lvl1MetaDatas[0].prefix) {
      this.initDataOfLvl4(this.parentId)
    } else if (this.prefix === this.onlineTrainService.lvl1MetaDatas[1].prefix) {
      this.initDataOfLvl2(this.parentId)
    } else if (this.prefix === this.onlineTrainService.lvl1MetaDatas[2].prefix
              || this.prefix === this.onlineTrainService.lvl1MetaDatas[5].prefix) {
      this.initDataOfLvl3(this.parentId)
    }

    this.itemType = this.onlineTrainService.DIR
  }

  private initDataOfLvl4(parentId) {
    this.dirsLvl4 = this.dirTree[parentId]
    this.lvl4ParentId = parentId
  }

  private initDataOfLvl2(parentId) {
    this.dirsLvl2 = this.dirTree[parentId]
    let selectDir = this.selectDirFromSub(this.dirsLvl2)
    if (_.isEmpty(selectDir)) {
      selectDir = this.dirsLvl2[0]
    }

    this.selectLvl2(selectDir)
  }

  private initDataOfLvl3(parentId) {
    this.dirsLvl3 = this.dirTree[parentId]
    let selectDir = this.selectDirFromSub(this.dirsLvl3)
    if (_.isEmpty(selectDir)) {
      selectDir = this.dirsLvl3[0]
    }
    this.selectLvl3(selectDir)
  }

  private selectDirFromSub(dirs) {
    if (_.isEmpty(this.subNavs)) {
      return null
    }

    return _.find(dirs, (dir) => !_.isEmpty(_.find(this.subNavs, (d) => d.id === dir.id)))
  }

  private addDir(params) {
    this.onlineTrainService.addDir(params.item).pipe(
      (flatMap(() => this.onlineTrainService.getAllDirs()))
    ).subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
      this.initData(dirs)
    }, (err) => {console.log(err)})
  }

  private updateDir(params) {
    this.onlineTrainService.updateDir(params.item).pipe(
      (flatMap(() => this.onlineTrainService.getAllDirs()))
    ).subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
      this.initData(dirs)
    }, (err) => {console.log(err)})
  }

  private deleteDir(params) {
    this.onlineTrainService.deleteDir(params.item.id).pipe(
      (flatMap(() => this.onlineTrainService.getAllDirs()))
    ).subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
      this.initData(dirs)
    }, (err) => {console.log(err)})
  }

  private loadData() {
    this.trainMassage.sendLoadingAction(true)
    this.onlineTrainService.getAllDirs().subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
      this.initData(dirs)
    }, (err) => {console.log(err)}, () => this.trainMassage.sendLoadingAction(false))
  }
}
