(function() {
  angular.module('app.prepareCourse')
    .controller('publishTaskListCtrl', publishTaskListCtrl);

  publishTaskListCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'userInfo',
    'oedUserClass', 'oedTaskListPublishRecord', 'data']
  function publishTaskListCtrl($scope, $q, $uibModalInstance, userInfo,
    oedUserClass, oedTaskListPublishRecord, data) {
    $scope.record = {
      taskListId: data.taskListId,
      classes: [],
      startDate: new Date(),
      dueDate: new Date()
    };

    $scope.publishedClassess = _.reduce(data.existingClassIds, function(total, clsId) {
      total[clsId] = true;
      return total;
    }, {});

    $scope.classes = [];

    userInfo.then(function(info) {
      $scope.classes = oedUserClass.queryByUser({
        userId: info.uid
      });

      $scope.loading = $scope.classes.$promise.then(function(clses) {
        $scope.classes = _.filter(clses, function(cls) {
          return !_.has($scope.publishedClassess, cls.id);
        });
      });
    });

    $scope.openDueDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.dueDateOpened = true;
    };

    $scope.openStartDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startDateOpened = true;
    };

    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.publishForm.$invalid) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '选择的日期不正确，请检查。';
        return;
      }

      var clsIds = _.map($scope.record.classes, function(cls) {
        return cls.id;
      });

      var startDate = moment($scope.record.startDate).startOf('day').valueOf();
      var dueDate = moment($scope.record.dueDate).endOf('day').valueOf();
      if (clsIds.length === 0) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '请选择班级';
        return;
      } else if (startDate >= dueDate) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '结束时间不能早于开始时间';
        return;
      }

      $scope.loading = oedTaskListPublishRecord.createRecords({
        taskListId: data.taskListId,
        classIds: clsIds,
        //默认为 xxxx年xx月xx日 23:59:59
        dueDate: dueDate,
        startDate: startDate
      }).$promise.then(function(records) {
        $uibModalInstance.close({
          records: records
        });
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
