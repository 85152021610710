import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RayApiService } from 'app/core/ray-api.service';
import { OEDSchoolClassStudentDTO } from 'app/models/classroom';

@Injectable({
  providedIn: 'root'
})
export class ClassroomService {

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService) { }

  public queryClassStudents(classId: number) {
    return this.httpClient.get<OEDSchoolClassStudentDTO[]>(this.rayApi.apiA(`classroom/${classId}/students`))
  }
}
