require('assets/app/controllers/examination/examReport/examReportChart/examReportChart.scss')
require('assets/app/controllers/examination/examReport/examReportChart/knowledgePointChart/knowledgePointChartCtrl')
require('assets/app/controllers/examination/examReport/examReportChart/overallChart/overallChartCtrl')
require('assets/app/controllers/examination/examReport/examReportChart/qualityChart/qualityChartCtrl')
require('assets/app/controllers/examination/examReport/examReportChart/questionChart/questionChartCtrl')
require('assets/app/controllers/examination/examReport/examReportChart/studentChart/studentChartCtrl')

angular.module('app.examination.examReport.chart', [
  'app.examination.examReport.chart.knowledgePoint',
  'app.examination.examReport.chart.overall',
  'app.examination.examReport.chart.quality',
  'app.examination.examReport.chart.question',
  'app.examination.examReport.chart.student'
])
  .component('examReportChart', {
    bindings: {
      selectedTab: '<',
      examSearch: '<',
      examWhole: '<',
      isCalculating: '<',
    },
    controller: examReportChartCtrl,
    template: < string > require('./examReportChart.html'),
  })

examReportChartCtrl.$inject = []

function examReportChartCtrl() {
  const ctrl = this

  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'examWhole')) {
      buildQuesMap()
    }
    if (_.has(changesObj, 'examSearch')) {
      buildShowChartCondition()
    }
  }

  function buildShowChartCondition() {
    ctrl.isDataEmpty = _.isEmpty(_.get(ctrl.examSearch, 'studentPartData.0.data.studentsData', []))
    ctrl.isShowChart = !ctrl.isCalculating && !ctrl.isDataEmpty
  }

  function buildQuesMap() {
    ctrl.wholeQuesMap = {}
    _.forEach(_.get(ctrl.examWhole, 'examPartDetails', []), pd => {
      _.forEach(_.get(pd, 'questionDetails', []), qd => {
        const isSync = _.get(qd, 'question.type', '') === 'synthetic'
        if (isSync) {
          _.forEach(_.get(qd, 'question.childQuestions', []), (child, childIdx) => {
            const quesId = _.get(child, 'id', 0)
            child['isChildQues'] = true
            ctrl.wholeQuesMap[quesId] = child
          })
        }
        const quesId = _.get(qd, 'question.id', 0)
        ctrl.wholeQuesMap[quesId] = _.get(qd, 'question', {})
      })
    })
  }
}
