(function() {
  'use strict';

  var _ = require('lodash');

  angular.module('app.prepareCourse2.resItemsPreviewerForMicroLesson', [])
  .controller('resItemsPreviewerForMicroLessonCtrl', resItemsPreviewerForMicroLessonCtrl);

  resItemsPreviewerForMicroLessonCtrl.$inject = ['$q', '$uibModalInstance', 'Lightbox', 'notificationService',
    'data', 'oedPresentResource']
  function resItemsPreviewerForMicroLessonCtrl($q, $uibModalInstance, Lightbox, notificationService,
  data, oedPresentResource) {
    //jshint validthis: true
    var ctrl = this;
    ctrl.items = data.items;
    ctrl.presentresourceId = data.presentresourceId;

    ctrl.importRes = function() {
      var defered = $q.defer();
      ctrl.loading = defered.promise;
      importResourceItems(defered, ctrl.items);
    };

    ctrl.removeRes = function(idx, event) {
      event.preventDefault();
      event.stopPropagation();

      ctrl.items.splice(idx, 1);
    };

    ctrl.showPreview = function(res, event) {
      event.preventDefault();
      event.stopPropagation();

      var allReses = _.map(data.items, 'res');

      var idx = _.findIndex(ctrl.items, res);
      if (idx < 0) {
        idx = 0;
      }

      Lightbox.openModal(allReses, idx);
    };

    ctrl.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    ctrl.getResourceName = function(item) {
      return item.resourceName + '.' + item.res.suffix;
    };

    function importResourceItems(defered, records) {
      var hasDuplicated = false;
      var importPromise = _.map(records, importResourceItem);

      return $q.all(importPromise)
        .then(function(results) {
          for (var i = 0; i < results.length; i++) {
            if (!results[i].success && results[i].code === 409) {
              hasDuplicated = true;
            }
          }
        })
        .finally(function(r) {
          if (hasDuplicated) {
            notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
          }
          $uibModalInstance.close({
            imported: true
          });
        });
    }

    function importResourceItem(record) {
      var defered = $q.defer();
      oedPresentResource.importItemFromMicroLessonRecord({
        id: ctrl.presentresourceId,
        record_id: record.id
      }).$promise
      .then(function() {
        defered.resolve({
          success: true,
          code: 200
        });
      })
      .catch(function(error) {
        defered.resolve({
          success: false,
          code: error.status
        });
      });
      return defered.promise;
    }

  }
})();
