export function isGoodTest(submitRate: any) {
  const value = submitRate * 100;
  if (value >= 80) {
    return true;
  } else {
    return false;
  }
}

export function isBadTest(submitRate: any) {
  const value = submitRate * 100;
  if (value < 30) {
    return true;
  } else {
    return false;
  }
}

export function getSummaryImage(prefix: string, submitRate: any) {
  const value = submitRate * 100;
  if (value < 1) {
    return 'Assets/images2/' + prefix + '_icon_0.png';
  } else if (value >= 1 && value < 30) {
    return 'Assets/images2/' + prefix + '_icon_20.png';
  } else if (value >= 30 && value < 50) {
    return 'Assets/images2/' + prefix + '_icon_40.png';
  } else if (value >= 50 && value < 70) {
    return 'Assets/images2/' + prefix + '_icon_60.png';
  } else if (value >= 70 && value < 90) {
    return 'Assets/images2/' + prefix + '_icon_80.png';
  } else if (value >= 99) {
    return 'Assets/images2/' + prefix + '_icon_100.png';
  } else {
    return 'Assets/images2/' + prefix + '_icon_0.png';
  }
}
