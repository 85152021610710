/**
 * @fileOverview
 * @name testSessionStatsCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('objectiveTestSessions.session.stats', {
        url: '/stats',
        template: require('assets/templates/review/testSessionStats.html'),
        controller: 'testSessionStatsCtrl',
        bodyStyle: 'bg_img'
      });
      $stateProvider.state('tmSessions.objSession.stats', {
        url: '/stats',
        template: require('assets/templates/review/testSessionStats.html'),
        controller: 'testSessionStatsCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('testSessionStatsCtrl', testSessionStatsCtrl);

  testSessionStatsCtrl.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$uibModal',
    '$animate', '$q', 'dialogs', 'resize', 'userInfo', 'oedCoursePath', 'oedClassSession', 'oedCourse',
    'oedTestSession', 'oedObjectiveTest', 'oedObjectiveTestSessionStats', 'oedTestUtils']
  function testSessionStatsCtrl($scope, $state, $stateParams, $timeout, $uibModal,
    $animate, $q, dialogs, resize, userInfo, oedCoursePath, oedClassSession, oedCourse,
    oedTestSession, oedObjectiveTest, oedObjectiveTestSessionStats, oedTestUtils) {

    $scope.currentState.title = '答题统计';

    $scope.isBlock = false;
    $scope.toggleFold = function() {
      $scope.isBlock = !$scope.isBlock;
      //
      // trick, resize 层不会trigger resize事件, 手动触发
      // 要确保delay长于动画时间
      $timeout(function() {
        window.dispatchEvent(new Event('resize'));
      }, 250);
    };

    $scope.isBlock2 = false;
    $scope.toggleFold2 = function() {
      $scope.isBlock2 = !$scope.isBlock2;
      //
      // trick, resize 层不会trigger resize事件, 手动触发
      // 要确保delay长于动画时间
      $timeout(function() {
        window.dispatchEvent(new Event('resize'));
      }, 250);
    };

    $scope.stats = oedObjectiveTestSessionStats.queryBySessoinId({
      sessionId: $stateParams.sessionId
    });

    $scope.currentLabels = [];
    $scope.stats.$promise.then(function(stats) {
      $scope.overall = {
        total: stats.length,
        numStudents: stats[0].countAnsweredTotal
      };
      var labels = oedTestUtils.getTestStatLabel(stats);
      $scope.currentLabels = labels;

      $scope.barChartOptions.chart.height = 80 + _.size(stats) * 60;

      var maxChoiceCountForVote = 0;
      $scope.hasReviewLabel = _.findIndex(stats, (st) => {
        return !_.isEmpty(getReviewStatus(st));
      }) >= 0;

      for (var k = 0; k < stats.length; k++) {
        var type = stats[k].question.type;
        if (type == 'singlevote' || type == 'multivote') {
          maxChoiceCountForVote = Math.max(maxChoiceCountForVote, stats[k].question.choices.length);
        }
      }

      for (var j = 0; j < maxChoiceCountForVote; j++) {
        var colorStr = (j % 2 == 1) ? '#469e24' : '#A5E14D';
        $scope.data.push({
          key: '选项' + String.fromCharCode(65 + j),
          color: colorStr,
          values: []
        });
      }

      for (var idx = 0; idx < stats.length; idx++) {
        var stat = stats[idx];
        var ques = stat.question;
        var quesType = ques.type;

        if (quesType == 'singlevote' || quesType == 'multivote') {
          // 设正确/犹豫/错误为0
          for (var i = 0; i < $scope.data.length; i++) {
            if (i < 3) {
              $scope.data[i].values.push({
                label: '第' + labels[idx] + '题',
                value: 0
              });
            } else if (i - 3 < ques.choices.length) {
              var count = 0.01;
              if ((i - 3).toString() in stat.choiceAnswers) {
                count = stat.choiceAnswers[(i - 3).toString()].length;
              }
              $scope.data[i].values.push({
                label: '第' + labels[idx] + '题',
                value: count
              });
            } else {
              $scope.data[i].values.push({
                label: '第' + labels[idx] + '题',
                value: 0
              });
            }
          }
        } else {
          // value 给个最小值 0.01，不然如果只有一个填空/问答题回答且为 "待批" 的情况下，
          // 用户没办法点击柱子打开学生列表。同时界面上也可以和投票题保持一致。
          $scope.data[0].values.push({
            label: getReviewStatus(stat) + '第' + labels[idx] + '题',
            value: _.max([stat.countAnsweredCorrectNotHesitate, 0.01])
          });

          $scope.data[1].values.push({
            label: getReviewStatus(stat) + '第' + labels[idx] + '题',
            value: _.max([stat.countAnsweredCorrectAndHesitate + stat.countAnsweredCorrectAndWrong, 0.01])
          });

          $scope.data[2].values.push({
            label: getReviewStatus(stat) + '第' + labels[idx] + '题',
            value: _.max([stat.countAnsweredWrong, 0.01])
          });

          for (var ii = 3; ii < $scope.data.length; ii++) {
            $scope.data[ii].values.push({
              label: getReviewStatus(stat) + '第' + labels[idx] + '题',
              value: 0
            });
          }
        }
      }
    });

    function getReviewStatus(stat) {
      if (stat.question.type === 'fill' && stat.needReview && stat.countAnsweredTotal > 0) {
        if (stat.anyBlankReviewed && !stat.anyBlankPendingReview) {
          return '(已批) ';
        } else if (stat.anyBlankPendingReview || stat.qaAnyAnswerPendingReview) {
          return '(待批)  ';
        }
      }

      if (stat.question.type === 'qa' && stat.countAnsweredTotal > 0) {
        if (stat.qaAnyAnswerPendingReview) {
          return '(待批)  ';
        } else {
          return '(已批) ';
        }
      }

      return '';
    }

    $scope.loading = $scope.stats.$promise;

    $scope.data = [{
      'key': '正确',
      values: [],
      color: '#469e24' //color - optional: choose your own line color.
    }, {
      key: '犹豫/半对',
      color: '#f0c46e',
      values: []
    }, {
      key: '错误',
      color: '#a70d0d',
      values: []
    }];

    $scope.barChartOptions = {
      'chart': {
        type: 'multiBarHorizontalChart',
        height: 100,
        showControls: false,
        showValues: true,
        stacked: true,
        noData: '没有数据',
        groupSpacing: 0.4,
        valueFormat: d3.format('d'),
        'transitionDuration': 500,
        'xAxis': {
          'showMaxMin': false
        },
        x: function(d) {
          return d.label;
        },
        y: function(d) {
          return d.value;
        },
        yAxis: {
          tickFormat: function(d) {
            return d < 1 ? 0 : d3.format('d')(d);
          }
        },
        callback: function(chart) {
          d3.selectAll('.test-stats svg').style('overflow', 'visible');
        },
        multibar: {
          dispatch: {
            elementClick: function(e) {
              var idx = e.index; // 点击的题目的index
              var modalInstance = $uibModal.open({
                template: require('assets/templates/review/questionAnswerDetails.html'),
                controller: 'questionAnswerDetailsCtrl',
                size: 'lg',
                windowClass: 'question-details-modal modalCenter',
                resolve: {
                  data: function() {
                    return {
                      sessionId: $stateParams.sessionId,
                      questionId: $scope.stats[idx].questionId,
                      index: $scope.currentLabels[idx]
                    };
                  }
                }
              });

              modalInstance.result.then(function(dirty) {
                if (dirty) {
                  $state.reload();
                }
              });
            }
          }
        }
      }
    };
  }
})();
