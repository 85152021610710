
import { UserInfoComponent } from './user-info/user-info.component';

/**
 * This state allows the user to set their application preferences
 */
export const sharedState = {
  name: 'ng2test',
  url: '/ng2test',
  component: UserInfoComponent,
}
