(function() {
  angular.module('app.manage')
    .controller('editClassInfoDialogCtrl', editClassInfoDialogCtrl);

  editClassInfoDialogCtrl.$inject = ['$uibModalInstance', '$scope', '$http', 'notificationService', 'curClass']
  function editClassInfoDialogCtrl($uibModalInstance, $scope, $http, notificationService, curClass) {
    $scope.classInfo = angular.copy(curClass);
    $scope.manageAble = !$scope.classInfo.disableManage;
    var clsName = $scope.classInfo.className;
    $scope.maxYear = new Date().getFullYear() + 10;

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.ok = function(info) {
      $scope.showFormError = true;
      if ($scope.updateForm.$invalid) {
        return;
      }
      $scope.classInfo.disableManage = !$scope.manageAble;
      $scope.loading = $scope.classInfo.$save().then(function() {
        notificationService.notify('info', '班级信息更新成功')

        _.assign(curClass, $scope.classInfo);

        $uibModalInstance.close($scope.classInfo);
      }).catch(function(err) {
        var msg = '更新失败, 发生未知错误';
        if (err.status == 409) {
          msg = '更新失败, 已经有同名的班级存在';
        }
        notificationService.notify('error', msg)
      });
    };
  }
})();
