import * as _ from 'lodash'

angular.module('app.prepareCourse2.cloud.core.anHuiPlatformLevelSubjectSelector', [
])
  .component('anHuiPlatformLevelSubjectSelector', {
    bindings: {
      onClose: '&',
      onEnsure: '&',
      oriData: '<',
    },
    controller: anHuiPlatformLevelSubjectSelectorCtrl,
    template:  <string> require('./anHuiPlatformLevelSubjectSelector.html'),
  })

anHuiPlatformLevelSubjectSelectorCtrl.$inject = ['anHuiResource', '$q']
function anHuiPlatformLevelSubjectSelectorCtrl(anHuiResource: any, $q: any) {
  const sbjs = ['全部学科', '语文', '数学', '英语', '道德与法制', '音乐', '美术', '科学', '书法', '信息技术', '综合实践'];
  const res_key = 'ykgGIYdX';
  const res_secret = 'c300be09275f43d2b22789ceda8dd762';

  const allGrade = {
    code: '',
    name: '全部年级'
  }
  const allSubject = {
    code: '',
    name: '全部学科'
  }
  this.$onInit = () => {
    this.init(this.oriData)
  }
  this.init = (data) => {
    this.phaseList = data.phaseList;
    this.phaseObject = data.phaseObject;
    this.gradeObjList = data.gradeObjList;
    this.gradeObjOfphase = data.gradeObjOfphase;
    this.subjectObjList = _.filter(data.subjectObjList, o => _.indexOf(sbjs, _.get(o, 'name')) >= 0);
    this.subjectObjOfphase = data.subjectObjOfphase
    this.curTabIdx = 0
    this.onSelectStage()
  }

  this.onSelectStage = () => {
    const index = _.findIndex(this.phaseList, (v: any) => {
      return _.get(v, 'code', 'x') === _.get(this.phaseObject, 'code')
    })
    this.curTabIdx = index + 1
  }

  this.isSubjectSelected = (s: any) => {
    if (!this.subjectObjOfphase || !s) {
      return false
    }
    return _.get(this.subjectObjOfphase, 'code', 'x') == _.get(s, 'code')
  }

  this.isGradeSelected = (s: any) => {
    if (!this.gradeObjOfphase || !s) {
      return false
    }
    return _.get(this.gradeObjOfphase, 'code', 'x') == _.get(s, 'code')
  }

  this.sure = () => {
    this.onEnsure({phase: this.phaseObject, grade: this.gradeObjOfphase, subject: this.subjectObjOfphase})
  }
  this.selectPhase = (obj) => {
    if (_.isEmpty(obj)) {
      return;
    }
    const listGrade = anHuiResource.listGrade({}, {
      res_key,
      res_secret,
      gradeQuery: `${JSON.stringify({phase: `${_.get(obj, 'code')}`})}`
    }).$promise
    const listSubject = anHuiResource.listSubject({}, {
      res_key,
      res_secret,
      subjectQuery: `${JSON.stringify({phase: `${_.get(obj, 'code')}`})}`
    }).$promise
    return $q.all([listGrade, listSubject]).then(res => {
      const gradeObjList = _.concat(allGrade, _.get(res[0], 'obj'))
      const subjectObjList = _.concat(allSubject, _.get(res[1], 'obj'))
      let gradeObj, subjectObj
      if (_.isEmpty(gradeObj)) {
        gradeObj = _.get(res, '0.obj.0', {})
      }
      if (_.isEmpty(subjectObj)) {
        subjectObj = _.get(res, '1.obj.0', {})
      }
      this.phaseObject = obj;
      this.gradeObjList = gradeObjList;
      this.gradeObjOfphase = gradeObj;
      this.subjectObjList = _.filter(subjectObjList, o => _.indexOf(sbjs, _.get(o, 'name')) >= 0);
      this.subjectObjOfphase = subjectObj
    })
    .catch(e => {
      console.error(e)
    });
  }
	this.gradeSelected = (obj) => {
    if (_.isEmpty(obj)) {
      return;
    }
    this.gradeObjOfphase = obj
  }
	this.subjectSelected = (obj) => {
    if (_.isEmpty(obj)) {
      return;
    }
    this.subjectObjOfphase = obj
  }
}
