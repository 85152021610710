(function() {
  angular.module('app.postLogin.createSchool', ['ui.router', 'ui.select', 'ngCookies'])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('schoolPenddingApprove', {
        url: '/anno/schoolPendingApprove',
        template: require('assets/templates/postLogin/schoolPendingApprove.html'),
        controller: 'schoolPendingApproveCtrl',
        bodyStyle: 'newstyle'
      });
      $stateProvider.state('joinSchoolRequestNeedApprove', {
        url: '/anno/joinSchoolRequestNeedApprove',
        template: require('assets/templates/postLogin/joinSchoolRequestNeedApprove.html'),
        bodyStyle: 'newstyle'
      });
    }])
    .controller('createSchoolCtrl', createSchoolCtrl)
    .controller('schoolPendingApproveCtrl', schoolPendingApproveCtrl);

  createSchoolCtrl.$inject = ['$scope', '$state', '$uibModalInstance', 'oedCity', 'oedSchool', 'idNo']
  function createSchoolCtrl($scope, $state, $uibModalInstance, oedCity, oedSchool, idNo) {
    $scope.region = {};
    $scope.schoolInfo = {};
    $scope.provinces = oedCity.queryAll();

    $scope.provinces.$promise.then(function(provinces) {
      $scope.region.province = provinces[0];
      $scope.provinceSelected(provinces[0]);
    });

    $scope.provinceSelected = function(item, model) {
      $scope.region.city = null;
      $scope.cities = oedCity.querySubCities({
        id: item.id
      });

      $scope.cities.$promise.then(function(subcities) {
        if (subcities.length > 0) {
          $scope.region.city = subcities[0];
          $scope.citySelected(subcities[0]);
        }
      });
    };

    $scope.citySelected = function(item, model) {
      $scope.region.district = null;
      $scope.districts = oedCity.querySubCities({
        id: item.id
      });

      $scope.districts.$promise.then(function(subcities) {
        if (subcities.length > 0) {
          $scope.region.district = subcities[0];
        }
      });
    };

    //
    // TODO: make schooltypes configurable?
    $scope.schoolTypes = ['小学', '中学', '初中', '高中', '中小学', '中职', '其他'];
    $scope.schoolInfo.schoolType = $scope.schoolTypes[0];

    $scope.createSchool = function() {
      $scope.showValidationError = true;
      //
      // validate input
      if ($scope.createSchoolForm.$invalid) {
        return;
      }
      //
      //
      var region = $scope.region;
      var school = new oedSchool(_.assign({}, $scope.schoolInfo, {
        province: region.province.id,
        city: region.city.id,
        zone: region.district.id,
        idNo: idNo
      }));
      $scope.loading = school.$save().then(function(resp) {
        if (!resp.success) {
          //
          // 提示原因
          $scope.message = resp.message;
        } else {
          //
          // 创建成功, 转到学校审核中的界面
          $scope.message = '';
          $uibModalInstance.close(resp);
        }
      }).catch(function(resp) {
        $scope.message = '出现错误, 请检查你的输入';
        //
        // 出现401会重定向到登录界面
        // 在这里要把模态对话框关掉
        if (resp.status == 401) {
          $uibModalInstance.dismiss('cancel');
        }
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }

  schoolPendingApproveCtrl.$inject = ['$scope', '$state']
  function schoolPendingApproveCtrl($scope, $state) {}
})();
