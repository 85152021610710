UE.registerUI('oAudio', function(editor, uiname){
  function creatInsertStrAudio(url,width,height,id,align,classname,type){
    var me = editor;
    var str;
    switch (type){
    case 'image':
      str = '<img ' + (id ? 'id="' + id+'"' : '') + ' width="37px" height="37px" _url="'+url+'" class="' + classname.replace(/\baudio-js\b/, '') + '"'  +
        ' src="' + me.options.UEDITOR_HOME_URL+'themes/default/images/spacer.gif" style="background:url('+me.options.UEDITOR_HOME_URL+'themes/default/images/music-logo.png) no-repeat center center; border:1px solid gray;'+(align ? 'float:' + align + ';': '')+'" />'
      break;
    case 'embed':
      str = '<embed type="application/x-shockwave-flash" class="' + classname + '" pluginspage="http://www.macromedia.com/go/getflashplayer"' +
        ' src="' +  utils.html(url) + '" width="' + width  + '" height="' + height  + '"'  + (align ? ' style="float:' + align + '"': '') +
        ' wmode="transparent" play="true" loop="false" menu="false" allowscriptaccess="never" allowfullscreen="true" >';
      break;
    case 'audio':
      var ext = url.substr(url.lastIndexOf('.') + 1);
      str = '<audio' + (id ? ' id="' + id + '"' : '') + ' class="' + classname + ' audio-js" ' + (align ? ' style="float:' + align + '"': '') +
        ' controls preload="none" width="' + width + '" height="' + height + '" src="' + url + '" data-setup="{}">' +
        '</audio>';
      break;

    }
    return str;
  }

  editor.registerCommand('insertaudio', {
        execCommand: function(cmd, videoObjs, type) {
              videoObjs = _.isArray(videoObjs)?videoObjs:[videoObjs];
            var html = [],id = 'tmpVedio', cl;
            for(var i=0,vi,len = videoObjs.length;i<len;i++){
                vi = videoObjs[i];
                cl = (type == 'upload' ? 'edui-upload-audio audio-js vjs-default-skin':'edui-faked-audio');
                html.push(creatInsertStrAudio( vi.url, 50, 20, id + i, null, cl, 'image'));
            }
            editor.execCommand("inserthtml",html.join(""),true);
            var rng = this.selection.getRange();
            for(var i= 0,len=videoObjs.length;i<len;i++){
                var img = this.document.getElementById('tmpVedio'+i);
                $(img).removeAttr('id');
                rng.selectNode(img).select();
                //me.execCommand('imagefloat',videoObjs[i].align)
            }
        },
		queryCommandState : function(){
            var img = editor.selection.getRange().getClosedNode(),
                flag = img && (img.className == "edui-faked-audio" || img.className.indexOf("edui-upload-audio")!=-1);
            return flag ? 1 : 0;
        }
    });


    // 创建dialog
    var kfDialog = new UE.ui.Dialog({

        // 指定弹出层路径
        iframeUrl: editor.options.UEDITOR_HOME_URL + 'oAudio/audio.html',
        // 编辑器实例
        editor: editor,
        // dialog 名称
        name: uiname,
        // dialog 标题
        title: '插入音频',

        // dialog 外围 css
        cssRules: 'width:600px; height: 386px;',

        //如果给出了buttons就代表dialog有确定和取消
        buttons:[
            {
                className:'edui-okbutton',
                label:'确定',
                onclick:function () {
                    kfDialog.close(true);
                }
            },
            {
                className:'edui-cancelbutton',
                label:'取消',
                onclick:function () {
                    kfDialog.close(false);
                }
            }
        ]});

    editor.ready(function(){

    });

    var iconUrl = editor.options.UEDITOR_HOME_URL + 'oAudio/music-icon.png';
    var tmpLink = document.createElement('a');
    tmpLink.href = iconUrl;
    tmpLink.href = tmpLink.href;
    iconUrl = tmpLink.href;

    var kfBtn = new UE.ui.Button({
        name:'插入' + uiname,
        title:'插入音频-' + uiname,
        //需要添加的额外样式，指定icon图标
        cssRules :'background: url("' + iconUrl + '") !important',
        onclick:function () {
            //渲染dialog
            kfDialog.render();
            kfDialog.open();
        }
    }, editor);

    //当点到编辑内容上时，按钮要做的状态反射
    editor.addListener('selectionchange', function () {
        var state = editor.queryCommandState(uiname);
        if (state == -1) {
            kfBtn.setDisabled(true);
            kfBtn.setChecked(false);
        } else {
            kfBtn.setDisabled(false);
            kfBtn.setChecked(state);
        }
    });

    return kfBtn;


});

