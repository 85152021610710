import * as _ from 'lodash'
require('assets/app/controllers/examination/examEdit/paper/examQuesPreviewer/examQuesPreviewer.scss')
import { getQuestionTypeText } from '../paperUtils'

angular.module('app.examination.examEdit.paper.quesPreviewer', [])
  .component('examQuesPreviewer', {
    bindings: {
      question: '=ngModel',
      qIndex: '<',
      partIndex: '<',
      partSize: '<',
      childQuesMove: '&',
      outerIndex: '<',
      importFromLibrary: '&',
      isSync: '<',
      intelligentReplace: '&',
      onScoreChange: '&',
      getQuestionNumber: '&',
      getQuesKnowledgePoints: '&',
      childQuesCustomizeMove: '&',
      parentNum: '<',
      parentPartIdx: '<',
      onDelQues: '&',
      isCanEdit: '<',
      hideAnalyze: '<',
      qLabel: '<',
      editingUuid: '<',
      updateParentQuestion: '&',
      partId: '<',
      quesSort: '<',
      updateExamData: '&',
      refreshExamDataFromServer: '&',
      checkEdittingQuesValid: '&',
      changeEditingUuid: '&',
      hideScoreInput: '<',
      forReview: '<',
      getQIndex: '&',
    },
    controller: examQuesPreviewerCtrl,
    template: <string>require('./examQuesPreviewer.html'),
  })

examQuesPreviewerCtrl.$inject = ['oedTestUtils', 'cloudQuestionService', '$element', 'notificationService', 'oedConfig',
'oedObjectiveQuestion', 'oedExamination']
function examQuesPreviewerCtrl(oedTestUtils, cloudQuestionService, $element, notificationService, oedConfig,
  oedObjectiveQuestion, oedExamination) {

  const ctrl = this;
  ctrl.quesAnswerExpand = false
  ctrl.willIgnoreClickEvent = () => {
    return !ctrl.checkEdittingQuesValid()
  }

  ctrl.handleEnterEditing = () => {
  }

  ctrl.handleLeaveEditing = () => {
    if (ctrl.isSync) {
      return
    }
    if (!ctrl.checkEdittingQuesValid()) {
      return;
    }
    if (!ctrl.partId) {
      ctrl.changeEditingUuid({
        uuid: ''
      })
      return;
    }
    if (!ctrl.question.id) {
      return ctrl.createQuestion()
    }
    return ctrl.saveQuestion()
  }

  ctrl.showKp = () => {
    return ctrl.getQuesKnowledgePoints({partIdx: ctrl.partIndex, quesIdx: ctrl.qIndex}) != '无'
  }

  /**
   * TODO 整理下创建题目后，更新试卷的逻辑
   */
  ctrl.createQuestion = () => {
    return oedExamination.createExamQuestion(
      {id: ctrl.partId},
      [{
        question: ctrl.question,
        sort: ctrl.quesSort,
        relatedOutlines: []
      }]
    ).$promise.then((data) => {
      // ctrl.refreshExamDataFromServer()
      ctrl.question.id = data[_.size(data) - 1].questionId
      ctrl.changeEditingUuid({
        uuid: ''
      })
      notificationService.notify('info', '保存成功')
    }).catch((e) => {
      console.error('create part question error', e)
      notificationService.notify('info', '保存失败，请刷新后重试')
    })
  }

  ctrl.saveQuestion = () => {
    return oedObjectiveQuestion.save(ctrl.question)
      .$promise.then(() => {
        console.log('save question success')
        ctrl.changeEditingUuid({
          uuid: ''
        })
        notificationService.notify('info', '保存成功')
      }).catch((e) => {
        console.log('save question error', e)
        notificationService.notify('info', '保存失败，请刷新后重试')
      })
  }

  ctrl.onQuestionChanged = (data) => {
  }

  ctrl.getCanEditQType = () => {
    return ctrl.isCanEdit && _.get(ctrl.question, 'uuid', 'uuid') === ctrl.editingUuid
  }

  ctrl.editorConfig = {
    serverUrl: oedConfig.url('ceditor/Test/') + 0
  };

  ctrl.$onChanges = (changes) => {
    if (!_.isNil(ctrl.partSize)) {
      buildQuesIndexList()
    }
    if (_.get(ctrl.question, 'point2', -1) != -1) {
      ctrl.beforeChangeScore = _.get(ctrl.question, 'point2', -1)
    }
  }

  function buildQuesIndexList() {
    ctrl.quesIdxList = []
    for (let i = 0; i < ctrl.partSize; i++) {
      const quesItemNum = ctrl.getQuestionNumber({partIdx: ctrl.partIndex, quesIdx: i})
      ctrl.quesIdxList.push(quesItemNum)
    }
  }

  ctrl.onScoreBlur = (partIdx, quesIdx) => {
    const isScoreAvailable = ctrl.onCheckAvailable()
    if (!isScoreAvailable) {
      _.set(ctrl.question, 'point2', ctrl.beforeChangeScore)
      return
    }
    ctrl.beforeChangeScore = _.get(ctrl.question, 'point2', -1)
    ctrl.onScoreChange({
      partIdx,
      quesIdx,
      score: _.get(ctrl.question, 'point2', 0),
      parentParIdx: ctrl.parentPartIdx
    })
  }

  ctrl.onCheckAvailable = () => {
    const point = _.get(ctrl.question, 'point2', 0)
    if (point <= 0) {
      notificationService.notify('error', '分数必须大于0')
      return false
    }
    if (ctrl.isSyncQues && point % 1 != 0) {
      notificationService.notify('error', '综合题小题的分数仅支持为1的倍数', '', 3000)
      return false
    }
    if (!ctrl.isSyncQues && point % 0.5 != 0) {
      notificationService.notify('error', '小题分数仅支持为0.5的倍数', '', 3000)
      return false
    }
    return true
  }

  ctrl.getChoiceName = oedTestUtils.getChoiceName

  ctrl.isSyncQues = () => {
    return this.isSync == 'isSync'
  }

  ctrl.answerExpand = () => {
    ctrl.quesAnswerExpand = !ctrl.quesAnswerExpand
  }

  this.isAnswer = (idx: number) => {
    const answerList = _.get(this.question, 'answer', '').split(",")
    return _.findIndex(answerList, a => a == idx) >= 0;
  }

  this.isChildQuestionFromCloudRes = function () {
    return this.qShowAnswer && !_.isNil(this.parentIndex);
  };

  this.getTypeLabel = (t: string) => getQuestionTypeText(t)

  this.parsingNoorOff = false;
  this.parsingSwitch = function () {
    this.parsingNoorOff = !this.parsingNoorOff;
  };
  this.getAnswer = function () {
    const t = this.ngModel.type;
    let answers;
    if (t == 'singlechoice') {
      if (!this.ngModel.answer) return '';
      return this.getChoiceName(this.ngModel.answer);
    } else if (t == 'multichoice') {
      return this.ngModel.answer;

    } else if (t == 'yesorno') {
      if (_.isNull(this.ngModel.answer) || _.isUndefined(this.ngModel.answer))
        return '';
      return this.ngModel.answer ? '正确' : '错误';
    } else if (t == 'connect') { } // eslint-disable-line

    return this.ngModel.answer;
  };

  ctrl.getLeftOptName = oedTestUtils.getChoiceName;

  ctrl.getRightOptName = function (idx) {
    return idx + 1;
  };

  if (this.qShowCloudResId && !this.cloudRes) {
    this.$watch('ngModel.id', function (newId) {
      this.cloudRes = null;
      if (newId) {
        cloudQuestionService.getCloudResByQuestionId(newId).then((cq) => {
          if (_.get(this.ngModel, 'id') === newId) {
            this.cloudRes = cq
          }
        })
      }
    });
  }

  if (this.qShowCloudTestLink) {
    this.$watch('ngModel.id', function (newId) {
      this.cloudTest = null;
      if (newId) {
        cloudQuestionService.getCloudTestByQuestionId(newId).then((cq) => {
          if (_.get(this.ngModel, 'id') === newId) {
            this.cloudTest = cq
          }
        })
      }
    });
  }

  function captureClicked(evt) {
    if (!evt.target) {
      return
    }

    const isChild = $element.find(event.target).length > 0;
    if (!isChild) {
      return;
    }

    if ($(event.target).attr('target') === '_self') {
      $(event.target).attr('target', '_blank')
    }
  }

  $element[0].addEventListener('click', captureClicked, true);
}
