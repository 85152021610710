import angular = require('angular');
import _ = require('lodash');

require('assets/styles/oedTable.scss');

angular.module('app.review.sbjTestPreviewer', [])
  .controller('sbjTestPreviewerCtrl', sbjTestPreviewerCtrl);

sbjTestPreviewerCtrl.$inject = ['$scope', 'data', '$uibModalInstance',
  'oedObjectiveTest', '$q']
function sbjTestPreviewerCtrl(
  $scope: any, data: any, $uibModalInstance: any,
  oedObjectiveTest: any, $q: any) {

  $scope.sbjTestId = data.testId;
  $scope.name = data.name;
  $scope.close = () => {
    $uibModalInstance.close();
  };
}
