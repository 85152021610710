var inlineUeEditor = require('app2/directives/editor/inlineUeEditor.directive');

(function() {
  'use strict';
  angular.module('app.directives.question.design.voteDesigner', [
    'app.directives.editor.inlineUeEditor'
  ])
    .component('voteDesigner', {
      bindings: {
        questionModel: '=ngModel',
        editorConfig: '<',
        addChoice: '&',
        showResAndDelClick: '&',
        resDelShowType: '=',
        isThreeDegrees: '=',
        forbidEdit: '='
      },
      controller: voteDesigner,
      template: require('assets/app2/directives/question/design/voteDesigner.html')
    });
  voteDesigner.$inject = ['oedTestUtils', 'dialogs']
  function voteDesigner(oedTestUtils, dialogs) {
    //
    // jshint validthis: true
    var ctrl = this;

    ctrl.getChoiceName = oedTestUtils.getChoiceName;
    ctrl.isAnswer = function(idx) {
      return ctrl.questionModel.answer === '' + idx;
    };

    ctrl.setAnswer = function(idx) {
      ctrl.questionModel.answer = '' + idx;
    };

    ctrl.doRemoveChoice = function(idx) {
      ctrl.questionModel.choices.splice(idx, 1);
      if (!_.isUndefined(ctrl.questionModel.answer) &&
          ctrl.questionModel.answer >= _.size(ctrl.questionModel.choices)) {
        ctrl.questionModel.answer = _.size(ctrl.questionModel.choices) - 1;
        if (ctrl.questionModel.answer < 0) {
          ctrl.questionModel.answer = undefined;
        }
      }
    };

    ctrl.removeChoice = function(idx) {
      if (!ctrl.questionModel.choices[idx].content) {
        ctrl.doRemoveChoice(idx);
      } else {
        var dlg = dialogs.confirm('确定删除？', '此选项已设置内容，真的要删除吗？');
        dlg.result.then(function(btn) {
          ctrl.doRemoveChoice(idx);
        }, function(btn) {});
      }
    };

    ctrl.inEditor = false;
    ctrl.enterEditor = function(index) {
      ctrl.index = index;
      ctrl.inEditor = true;
    };
    ctrl.leaveEditor = function() {
      ctrl.inEditor = false;
    };
  }
})();
