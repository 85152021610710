import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CreateTeachingModuleDTO, LessonDTO } from 'app/lesson/models/lesson';
import { LessonService } from 'app/lesson/services/lesson.service';
import * as _ from 'lodash'
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-edit-course',
  template: require('./edit-course.component.html'),
  styles: [require('./edit-course.component.scss')]
})
export class EditCourseComponent implements OnInit {

  @Input() public course: LessonDTO
  public courseClone: LessonDTO
  @Output() public action = new EventEmitter<LessonDTO>()
  
  constructor(
    public bsModalRef: BsModalRef,
    public lessonService: LessonService,
    private notifyService: NotificationService,
    public lessonMessageService: LessonMessageService
    ) { }

  public ngOnInit() {
    this.courseClone = _.clone(this.course)
  }

  public ok() {
    if (_.isEmpty(this.courseClone.courseName)) {
      this.notifyService.notify('info', '课程名称不能为空')
    } else {
      this.lessonService.createOrUpdateCourseInfo(this.courseClone.id, this.courseClone).subscribe((data: LessonDTO) => {
        this.action.emit(data)
        this.bsModalRef.hide()
      }, (error) => {
        console.log(error)
        this.notifyService.notify('info', '教学环节名称不能为空')
      })
    }
  }

  public close() {
    this.bsModalRef.hide()
  }

}
