import { Component, OnInit, Input, OnChanges, SimpleChange, Output, EventEmitter, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-ex-tree-item',
  template: require('./ex-tree-item.component.html'),
  styles: [require('./ex-tree-item.component.scss')]
})
export class ExTreeItemComponent implements OnInit, OnChanges {
  @Input() private item: any
  @Input() private deep: number
  @Input() private isShow: boolean
  @Input() private maxLevel: number
  @Input() private selectMap: any
  @Input() private defOpenMap: any
  @Output() private doSelect = new EventEmitter<any[]>()

  // 这里的level相当于deep
  private level: number
  private isExpand: boolean
  private isSelected: boolean
  private children: any[]
  private hasChild: boolean
  private showNextLevel: boolean
  private showExpand: boolean
  private showClose: boolean
  private diasbleSelcet: boolean

  constructor() { }

  ngOnInit() {
    this.refreshSelectedBtn()
    this.isExpand = !_.isEmpty(_.get(this.defOpenMap, _.get(this.item, 'id', 0), {}))
    this.level = _.get(this.item, 'nodeLevel', 0)
    this.children = _.get(this.item, 'children', [])
    this.hasChild = _.size(this.children) > 0
    this.diasbleSelcet = this.level < this.maxLevel
    this.refreshOpenBtn()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.has(changes, 'selectMap')) {
      this.refreshSelectedBtn()
    }
  }

  private refreshSelectedBtn = () => {
    const id = _.get(this.item, 'id', 0)
    this.isSelected = !_.isEmpty(_.get(this.selectMap, id, {}))
  }

  private refreshOpenBtn = () => {
    if (_.isNil(this.maxLevel)) {
      this.showNextLevel = this.hasChild && this.isExpand
      this.showExpand = this.hasChild && !this.isExpand
      this.showClose = this.hasChild && this.isExpand
    } else {
      this.showNextLevel = this.hasChild && this.isExpand && this.level < this.maxLevel
      this.showExpand = this.hasChild && !this.isExpand && this.level < this.maxLevel
      this.showClose = this.hasChild && this.isExpand && this.level < this.maxLevel
    }
  }

  private hideChild = (children: any[]) => {
    if (_.size(children) <= 0) return
    _.forEach(children, child => {
      _.set(child, 'isExpand', false)
      const children = _.get(child, 'children', [])
      this.hideChild(children)
    })
  }

  private onExpand = () => {
    this.isExpand = !this.isExpand
    this.refreshOpenBtn()
    if (!this.isExpand) {
      // 如果跨级关闭，则需关闭展开的所有child
      this.hideChild(this.children)
    }
  }

  private onSelectBtn = () => {
    if (this.diasbleSelcet) return
    this.doSelect.emit(this.item)
  }
  private onSelect = (item: any) => this.doSelect.emit(item)
}
