import { downgradeComponent,  downgradeInjectable, UpgradeModule } from '@angular/upgrade/static';

import { AppStateService } from './app/core/app-state.service'
import { OedDialogService } from './app/core/oed-dialog.service'
import { SchoolFeatureService } from './app/core/school-feature.service'

import { OedNotifyService } from './app/core/oed-notify.service'
import { LessonDesignComponent } from './app/lesson/lesson-design/lesson-design.component'
import { SectionListComponent } from './app/lesson/section-list/section-list.component'
import { ReportErrorComponent } from './app/ruanyun/report-error/report-error.component'

import { CloudQuestionService } from 'app/cloud/cloud-question.service';
import { ConnectDesignerComponent } from 'app/course-editing/connect-designer/connect-designer.component';
import { FillDesignerComponent } from 'app/course-editing/fill-designer/fill-designer.component';
import { MultiChoiceDesignerComponent } from 'app/course-editing/multi-choice-designer/multi-choice-designer.component';
import { QaDesignerComponent } from 'app/course-editing/qa-designer/qa-designer.component';
import { QuestionDesignerComponent } from 'app/course-editing/question-designer/question-designer.component';
// tslint:disable-next-line:max-line-length
import { SingleChoiceDesignerComponent } from 'app/course-editing/single-choice-designer/single-choice-designer.component';
import { SyntheticDesignerComponent } from 'app/course-editing/synthetic-designer/synthetic-designer.component';
import { VoteDesignerComponent } from 'app/course-editing/vote-designer/vote-designer.component';
import { YesOrNoDesignerComponent } from 'app/course-editing/yes-or-no-designer/yes-or-no-designer.component';
import { UeditorComponent } from 'app/editing/ueditor/ueditor.component'
import { UiTreeComponent } from 'app/editing/ui-tree/ui-tree.component';
import { ResMoveComponent } from 'app/lesson/lesson-design/res-move/res-move.component';
import { MaterialService } from 'app/lesson/material.service';
import {PreferenceService} from 'app/lesson/services/preference.service';
import { TeachingModuleService } from 'app/lesson/services/teaching-module.service';
import { TeachingPatternService } from 'app/lesson/services/teaching-pattern.service';
import { ImagePreviewerComponent } from 'app/preview/image-previewer/image-previewer.component';
import { FilterContainerComponent } from 'app/talcloud/filter-container/filter-container.component';
import { TalresPaperComponent } from 'app/talcloud/talres-paper/talres-paper.component'
import { NotificationService } from 'app/shared/notification/notification.service';
import { NgNextHolderComponent } from 'app/shared/ng-next-holder/ng-next-holder.component';
import {TrainDesignComponent} from 'app/online-train/train-design/train-design.component';
import {OfficeEditorTipComponent} from 'app/office-editor/office-editor-tip/office-editor-tip.component';
import {OfficeUploadTipComponent} from 'app/office-editor/office-upload-tip/office-upload-tip.component';
import {OfficeEditorDialogComponent} from 'app/office-editor/office-editor-dialog/office-editor-dialog.component';
import {CloudDriveDialogComponent} from 'app/cloud-drive/cloud-drive-dialog/cloud-drive-dialog.component';
import { StdAvatarComponent } from 'app/manage/std-avatar/std-avatar.component';
import { StdInfoComponent } from 'app/review/std-info/std-info.component';
import { SbjCompletionDetailsComponent } from 'app/review/sbj-completion-details/sbj-completion-details.component';
import { SbjTestStatTableComponent } from 'app/review/sbj-test-stat-table/sbj-test-stat-table.component';
import { OedTaskService } from 'app/core/oed-task.service';
import { SbjCompletionPreviewerComponent } from 'app/review/sbj-completion-previewer/sbj-completion-previewer.component';
import { GroupEditorComponent } from 'app/manage/group-editor/group-editor.component';
import { Nvd3Component } from 'app/charts/nvd3/nvd3.component';
import { ObjChartSummaryComponent } from 'app/review/obj-chart-summary/obj-chart-summary.component';
import { NewPaginationComponent } from 'app/shared/new-pagination/new-pagination.component';
import { ExerciseBookInResLib } from 'app/manage/exercise-book/exercise-book-in-res-lib/exercise-book-in-res-lib.component';
import { PreLoginContactMenuComponent } from 'app/menu/pre-login-contact-menu/pre-login-contact-menu.component'
import { ProductionDownloadComponent } from 'app/menu/production-download/production-download.component'
import { ObjTestStatTableComponent } from 'app/review/obj-test-stat-table/obj-test-stat-table.component';
import { PostLoginContactMenuComponent } from 'app/menu/post-login-contact-menu/post-login-contact-menu.component';
import { ObjCompletionDetailsComponent } from 'app/review/obj-completion-details/obj-completion-details.component';
import { ObjCompletionPreviewerComponent } from 'app/review/obj-completion-previewer/obj-completion-previewer.component';
import { ExamQuestionDesignComponent } from 'app/exam/exam-question-design/exam-question-design.component';
import { QuestionTypeSwitcherComponent } from 'app/exam/question-type-switcher/question-type-switcher.component';
import { ClickHookComComponent } from 'app/exam/click-hook-com/click-hook-com.component';
import {SubjectSelectorComponent} from 'app/cloud/subject-selector/subject-selector.component';

export function downgradeModules() {
  angular.module('oedweb.next', [])
    .service('userInfo', downgradeInjectable(AppStateService))
    .service('schoolFeatureService', downgradeInjectable(SchoolFeatureService))
    .service('oedDialogService', downgradeInjectable(OedDialogService))
    .service('oedNotifyService', downgradeInjectable(OedNotifyService))
    .service('cloudQuestionService', downgradeInjectable(CloudQuestionService))
    .service('materialService', downgradeInjectable(MaterialService))
    .service('preferenceService', downgradeInjectable(PreferenceService))
    .service('notificationService', downgradeInjectable(NotificationService))
    .service('teachingPatternService', downgradeInjectable(TeachingPatternService))
    .service('teachingModuleService', downgradeInjectable(TeachingModuleService))
    .service('oedTaskService', downgradeInjectable(OedTaskService))
    .directive('reportError', downgradeComponent({component: ReportErrorComponent}))
    .directive('appUeditor', downgradeComponent({component: UeditorComponent}))
    .directive('appNgNextHolder', downgradeComponent({component: NgNextHolderComponent}))
    .directive('appSectionList', downgradeComponent({component: SectionListComponent}))
    .directive('appLessonDesign', downgradeComponent({component: LessonDesignComponent}))
    .directive('appImagePreviewer', downgradeComponent({component: ImagePreviewerComponent}))
    .directive('appSingleChoiceDesigner', downgradeComponent({component: SingleChoiceDesignerComponent}))
    .directive('appMultiChoiceDesigner', downgradeComponent({component: MultiChoiceDesignerComponent}))
    .directive('appConnectDesigner', downgradeComponent({component: ConnectDesignerComponent}))
    .directive('appFillDesigner', downgradeComponent({component: FillDesignerComponent}))
    .directive('appYesOrNoDesigner', downgradeComponent({component: YesOrNoDesignerComponent}))
    .directive('appVoteDesigner', downgradeComponent({component: VoteDesignerComponent}))
    .directive('appQaDesigner', downgradeComponent({component: QaDesignerComponent}))
    .directive('appQuestionDesigner', downgradeComponent({component: QuestionDesignerComponent}))
    .directive('appSyntheticDesigner', downgradeComponent({component: SyntheticDesignerComponent}))
    .directive('appUiTree', downgradeComponent({component: UiTreeComponent}))
    .directive('appResMove', downgradeComponent({component: ResMoveComponent}))
    .directive('appTalresPaper', downgradeComponent({component: TalresPaperComponent}))
    .directive('appFilterContainer', downgradeComponent({component: FilterContainerComponent}))
    .directive('appTrainDesign', downgradeComponent({component: TrainDesignComponent}))
    .directive('appOfficeEditorTip', downgradeComponent({component: OfficeEditorTipComponent}))
    .directive('appOfficeUploadTip', downgradeComponent({component: OfficeUploadTipComponent}))
    .directive('appOfficeEditorDialog', downgradeComponent({component: OfficeEditorDialogComponent}))
    .directive('appCloudDriveDialog', downgradeComponent({component: CloudDriveDialogComponent}))
    .directive('appStdAvatar', downgradeComponent({component: StdAvatarComponent}))
    .directive('appStdInfo', downgradeComponent({component: StdInfoComponent}))
    .directive('appSbjCompletionDetails', downgradeComponent({component: SbjCompletionDetailsComponent}))
    .directive('appSbjTestStatTable', downgradeComponent({component: SbjTestStatTableComponent}))
    .directive('appSbjCompletionPreviewer', downgradeComponent({component: SbjCompletionPreviewerComponent}))
    .directive('appGroupEditor', downgradeComponent({component: GroupEditorComponent}))
    .directive('appNvd3', downgradeComponent({component: Nvd3Component}))
    .directive('appObjChartSummary', downgradeComponent({component: ObjChartSummaryComponent}))
    .directive('appNewPagination', downgradeComponent({component: NewPaginationComponent}))
    .directive('exerciseBookInResLib', downgradeComponent({component: ExerciseBookInResLib}))
    .directive('appPreLoginContactMenu', downgradeComponent({component: PreLoginContactMenuComponent}))
    .directive('appProductionDownload', downgradeComponent({component: ProductionDownloadComponent}))
    .directive('appObjTestStatTable', downgradeComponent({component: ObjTestStatTableComponent}))
    .directive('appPostLoginContactMenu', downgradeComponent({component: PostLoginContactMenuComponent}))
    .directive('appObjCompletionDetails', downgradeComponent({component: ObjCompletionDetailsComponent}))
    .directive('appObjCompletionPreviewer', downgradeComponent({component: ObjCompletionPreviewerComponent}))
    .directive('appExamQuestionDesigner', downgradeComponent({component: ExamQuestionDesignComponent}))
    .directive('appQuestionTypeSwitcher', downgradeComponent({component: QuestionTypeSwitcherComponent}))
    .directive('appClickHookComponent', downgradeComponent({component: ClickHookComComponent}))
    .directive('appSubjectSelector', downgradeComponent({component: SubjectSelectorComponent}))
}
