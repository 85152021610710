import * as angular from 'angular';
import Promise = require('bluebird');
import * as _ from 'lodash';

angular.module('app.service.sbjSessionService', [])
.factory('sbjSessionService', ['oedSubjectiveTestSessionStats', 'oedTaskListPublishRecord', '$q',
  'oedClassUser', 'oedUserInfo', (
  oedSubjectiveTestSessionStats: any, oedTaskListPublishRecord: any, $q: any,
  oedClassUser: any, oedUserInfo: any) => {

  return {
    getSbjSessionStat: querySbjSessionStat,
  };

  /**
   * 获取SubjectiveTestSessionStats
   * @param id boardSessionId
   */
  function querySbjSessionStat(id: number) {
    return oedSubjectiveTestSessionStats.queryBySessoinId({
      boardSessionId: id,
      includeBoardContent: false,
      includeBoardThumbnail: true, /** 拿thumbnail 用于区分是否真的有content, 没有的话就用contentBody 中的内容 */
      includeBravos: true,
      includeComments: true,
      includeFlags: true,
      includeRatings: true,
      includeViews: true,
      sortBy: 'bravosCount',
    }).$promise.then((stats: any) => {
      _.map(stats.interacts, (interact: any) => {
        interact.commentsList = _.map(interact.commentsList, (cmt: any) => {
          cmt.value.commentBody = parseComment(cmt.value.commentBody);
          return cmt;
        });

        return interact;
      });
      return stats;
    });
  }

  function parseComment(commentBody: any) {
    try {
      return JSON.parse(commentBody);
    } catch (ex) {
      return {};
    }
  }
}]);
