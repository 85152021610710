require('assets/app/controllers/examination/examPartsEditor/examPartsEditor.scss');
require('assets/app/controllers/examination/examPartsEditor/addQuestionCtrl');
import * as _ from 'lodash'

examPartsEditorCtrl.$inject = ['oedObjectiveTest', 'oedCourseUtils', '$anchorScroll', '$location', 'dialogs']
function examPartsEditorCtrl(oedObjectiveTest, oedCourseUtils, $anchorScroll, $location, dialogs) {
  this.partIndex = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十二', '十三', '十四', '十五'];
  // 添加一题
  this.addQuestion = () => {
    const newQ = oedCourseUtils.defaultQuestion();
    let maxSort = _.get(_.maxBy(this.part.questionDetails, (detail: any) => {
      return detail.question.sort;
    }), 'question.sort');
    if (_.isUndefined(maxSort) || _.isNaN(maxSort) || !_.isNumber(maxSort)) {
      maxSort = 0;
    }
    maxSort++;
    newQ.sort = maxSort;
    this.editingQuestion = newQ;
    this.part.questionDetails.push({
      question: newQ,
      relatedOutlines: []
    });
    // 重新计算大题的分值以及数量
    // let details = this.part.questionDetails;
    // details.push({
    //   question: newQ,
    //   relatedOutlines: []
    // });
    // this.part = {
    //   ...this.part,
    //   questionDetails: details,
    //   questionNum: _.size(details),
    //   score: _.sumBy(details, 'question.point2'),
    // };
    // this.onExamPartsUpdate({part: this.part});
    scrollToQuestion(this.part, this.editingQuestion);
  };

  this.onQuestionUpdate = (ques, part, isDelete) => {
    this.onExamPartsUpdate({part});
    // _.remove(this.part.questionDetails, (d: any) => d.question.id === ques.id);
    // let details = this.part.questionDetails;
    // details.push({
    //   question: ques,
    //   relatedOutlines: []
    // });
    // this.part = {
    //   ...this.part,
    //   questionDetails: details,
    //   questionNum: _.size(details),
    //   score: _.sumBy(details, 'question.point2'),
    // }
  };

  function scrollToQuestion(part, question) {
    $location.hash(`question_${part.id}_${question.sort}`);
    $anchorScroll();
  }
}

export const examPartsEditor = {
  bindings: {
    part: '<',
    onExamPartsUpdate: '&'
  },
  template: <string>require('./examPartsEditor.html'),
  controller: examPartsEditorCtrl,
}

angular.module('app.examination.editObjectTestPaper.examPartsEditor',[
  'app.examination.editObjectTestPaper.examPartsEditor.addQuestion'
]).component('examPartsEditor', examPartsEditor);
