import { v4 as uuidv4 } from 'uuid'
import _ = require('lodash');
import { ExamDataDTO, ExamPartDetailDTO, QuestionDTO, QuestionDetailDTO } from 'app/exam/models/exam';
import { checkExamQuesHasTable, openExamConfirmDialog, getExamQIndexMap } from './examEdit/paper/paperUtils';

export const createDefaultQuestion = (localStorageService: any, isChild = false) => {
  let qType = localStorageService.get('lastQuestionType') || 'singlechoice';
  if (isChild && qType === 'synthetic') {
    qType = 'singlechoice'
  } else if (qType == 'vote') {
    qType = 'singlevote'
  }
  let choices = [{}, {}, {}, {}];
  if (qType !== 'singlechoice' && qType != 'multichoice' && qType != 'singlevote' && qType != 'multivote') {
    choices = [];
  }

  let answer = ''

  if (qType === 'singlevote' || qType === 'multivote' || qType === 'qa') {
    answer = '-1'
  }

  const qPoint = localStorageService.get('lastQuestionPoint') || 1;

  return {
    answer,
    question: '',
    type: qType,
    point2: qType == 'synthetic' ? 0 : qPoint,
    choices: choices,
    leftOpts: [],
    rightOpts: [],
    needPic: false,
    answerDetails: null,
    uuid: uuidv4(),
  };
}

const checkSynQuesValid = (question, notificationService, addSub) => {
  const childs = _.get(question, 'childQuestions', [])
  if (_.size(childs) <= 0 && !addSub) {
    notificationService.notify('error', '当前综合题没有子题，请设置子题后再保存!')
    return false
  }
  return _.reduce(childs, (r, c) => {
    return r && checkNewQuesIsValid(c, notificationService, addSub)
  }, true)
}

export const checkNewQuesIsValid = (question, notificationService, addSub = false) => {
  const type = _.get(question, 'type', '')
  const answer = _.toString(_.get(question, 'answer', ''))
  switch (type) {
    case 'synthetic':
      return checkSynQuesValid(question, notificationService, addSub)
    case 'fill':
      if (_.isEmpty(answer)) {
        notificationService.notify('error', '填空题至少需要一个空!')
        return false
      }
      return true
    default: {
      if (_.isEmpty(answer)) {
        notificationService.notify('error', '请为当前题目设置答案!')
        return false
      }
      return true
    }
  }
}

export function checkExamInEditting(edittingUuid: string, examData: ExamDataDTO, notificationService: any) {
  const parts = _.get(examData, 'examPartDetails', [])
  const ques = _.flatMapDeep(parts, (p: ExamPartDetailDTO) => {
    return _.map(p.questionDetails, 'question')
  })
  for (const q of ques) {
    if (!checkNewQuesIsValid(q, notificationService)) {
      return false 
    }
    continue
  }
  return true
}

export function generateLocalQuestion(question: QuestionDTO) {
  if (question.type === 'synthetic') {
    let nq = _.omit(question, 'id')
    nq = {
      ...nq,
      childQuestions: _.map(nq.childQuestions, (c: QuestionDTO) => {
        return {
          ..._.omit(c, 'id'),
          uuid: uuidv4(),
        }
      })
    }
    return nq
  } else {
    return {
      ..._.omit(question, 'id'),
      uuid: uuidv4(),
    }
  }
}

export function geneQuesUuid(question: QuestionDTO) {
  if (question.type === 'synthetic') {
    return {
      ...question,
      childQuestions: _.map(question.childQuestions, (c: QuestionDTO) => {
        return {
          ...c,
          uuid: uuidv4(),
        }
      })
    }
  } else {
    return {
      ...question,
      uuid: uuidv4(),
    }
  }
}
export function createQuesUuidForExam(examData: ExamDataDTO) {
  const ned = {
    ...examData,
    examPartDetails: _.map(examData.examPartDetails, (pd: ExamPartDetailDTO) => {
      return {
        ...pd,
        questionDetails: _.map(pd.questionDetails, (d: QuestionDetailDTO) => {
          return {
            ...d,
            question: geneQuesUuid(d.question)
          }
        })
      }
    })
  }
  return ned
}

function doChcekExamQuesBeforeSave(q: QuestionDTO, notificationService: any, qIndexMap = {}, examId) {
  const point = _.get(q, 'point2', -1)
  const indexKey = _.get(q, 'id', _.get(q, 'uuid'))
  const qtype = _.get(q, 'type', '')
  if (point <= 0) {
    notificationService.notify('error', `当前试卷第${qIndexMap[indexKey]}题分数分配失败，请修改后保存`, '', 3000)
    return false
  }
  if (qtype === 'synthetic') {
    const childs = _.get(q, 'childQuestions', [])
    if (_.isEmpty(childs)) {
      notificationService.notify('error', `当前试卷的第${qIndexMap[indexKey]}题为非法题目，请将其替换或者删除后保存`, '', 3000)
      return false
    }
    let childTotalScore = 0

    for (const c of childs) {
      const cp = _.get(c, 'point2', 0)
      if (cp <= 0) {
        notificationService.notify('error', `当前试卷第${qIndexMap[indexKey]}题分数分配失败，请修改后保存`, '', 3000)
        return false
      }
      childTotalScore += cp
    }

    if (childTotalScore != point) {
      notificationService.notify('error', `当前试卷第${qIndexMap[indexKey]}题分数分配失败，请修改后保存`, '', 3000)
      return false
    }
  }
  if (examId && !_.get(q, 'id', null)) {
    return false
  }
  return true
}

export function checkCanSaveImpl(examData: ExamDataDTO, notificationService) {
  const qIndexMap = getExamQIndexMap(examData)
  const examId = _.get(examData, 'examWithTags.exam.id', null)
  const partDetailss: ExamPartDetailDTO[] = _.get(examData, 'examPartDetails',  [])
  

  if (_.isEmpty(partDetailss)) {
    notificationService.notify('error', `试卷至少需要一道大题`, '', 3000)
    return false
  }

  for(const pd of partDetailss) {
    const partQuestions: QuestionDetailDTO[] = _.get(pd, 'questionDetails', [])
    if (_.isEmpty(partQuestions)) {
      notificationService.notify('error', `有大题内容为空，无法保存。请检查后再试`, '', 3000)
      return false
    }
    const queses: QuestionDTO[] = _.map(partQuestions, 'question')
    for (const q of queses) {
      const r = doChcekExamQuesBeforeSave(q, notificationService, qIndexMap, examId)
      if (!r) {
        return false
      }
    }
  }
  return true
}

function checkCanSave(examData: ExamDataDTO, notificationService) {
  const examId = _.get(examData, 'examWithTags.exam.id', null)

  const r = checkCanSaveImpl(examData, notificationService)
  if (!r) {
    return false
  }

  const isSaved = examId && examId != -1
  if (isSaved) {
    notificationService.notify('info', '试卷已保存')
    return false
  }
  return true
}

export function checkExamTable(examData: ExamDataDTO, $uibModal) {
  return checkExamQuesHasTable(examData, $uibModal)
}

export interface SaveExamInjectOpt {
  notificationService: any;
  $uibModal: any;
  oedExamination: any;
  loading: any;
}

export interface DialogOpt {
  yesAction: any;
  noAction?: any;
  title?: string;
}

export function willSaveExamWithConfirm2(examData) {
  return (dialogOpt: DialogOpt) => {
    const { yesAction, noAction, title = '切换试卷' } = dialogOpt
    return (opt: SaveExamInjectOpt) => {
      const { notificationService, $uibModal, oedExamination } = opt
      let { loading } = opt
      return checkExamTable(examData, $uibModal).then(() => {
        const onYes = () => {
          const isCanSave = checkCanSave(examData, notificationService)
          if (isCanSave) {
            loading = doSaveExam(oedExamination, examData, notificationService).then((data) => {
              if (yesAction) {
                yesAction(data)
              }
            })
          }
        }
        openExamConfirmDialog($uibModal, '当前试卷未保存，是否保存？', title, '是', '否', onYes, () => {
          if (noAction) {
            noAction()
          }
        })
      }).catch((error) => {
        console.error('save error', error)
      })
    }
  }
}

export function willSaveExam(examData, notificationService, $uibModal, oedExamination, loading, yesAction) {
  return checkExamTable(examData, $uibModal).then(() => {
    const isCanSave = checkCanSave(examData, notificationService)
    if (isCanSave) {
      loading = doSaveExam(oedExamination, examData, notificationService).then((data) => {
        if (yesAction) {
          yesAction(data)
        }
      })
    }
  }).catch((error) => {
    console.error('save error', error)
  })
}

function doSaveExam(oedExamination, examData, notificationService) {
  return oedExamination.saveExamTest(examData).$promise.then((res) => {
    if (!_.isEmpty(res)) {
      notificationService.notify('info', '试卷保存成功')
      return {
        examId: _.get(res, 'examWithTags.exam.id', ''),
        examData: createQuesUuidForExam(res)
      }
    }
    return {}
  }).catch((e) => {
    const message = _.get(e, 'data.message', '')
    if (message === 'exam/exceedMaxQuestionCount') {
      notificationService.notify('error', '题目总数不能超过105道题，请删除一些后再试。')
      return
    }
    if (message === 'examWhole/examPartQuestionEmpty') {
      notificationService.notify('error', '有大题内容为空，无法保存。请检查后再试。')
      return
    }
    notificationService.notify('error', '保存试卷出错，请稍后再试')
  })
}