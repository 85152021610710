import { Injectable, OnDestroy } from '@angular/core';
import * as bluebird from 'bluebird'
import * as _ from 'lodash'
import { Subject } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { UserInfoService } from './user-info.service';

import { StateService } from '@uirouter/core'

import { SchoolFeatureService } from 'app/core/school-feature.service'
import { UserData, UserDTO } from 'app/models/user.d.ts'

import { LegacyAppService } from 'app/core/legacy-app.service'
import { MaintenanceService } from './maintenance.service';

@Injectable({
  providedIn: 'root'
})
export class AppStateService implements OnDestroy {

  public ready = false
  public userData: UserData = null
  public userDataSubject = new Subject<UserData>()

  public userInfo: UserDTO | {} = {}
  public promise: Promise<UserDTO> = null
  public counter = 0

  constructor(private userInfoService: UserInfoService,
              private schoolFeatureService: SchoolFeatureService,
              private state: StateService,
              private legacyApp: LegacyAppService,
              private maintenanceService: MaintenanceService) {
    this.updateCounter()
    userInfoService.currentUserInfo()
      .pipe(finalize(() => {
        this.ready = true
        this.userDataSubject.next(this.userData)
        this.userDataSubject.complete()
      })).subscribe(
      (info) => this.userData = this.getUserData(info),
      (e) => {
      })
    maintenanceService.start()
  }

  public clearUserData() {
    this.userData = null
  }

  public isReady() {
    return this.ready
  }

  public setUser(user: UserDTO) {
    this.userData = this.getUserData(user)
  }

  public doLogout() {
    this.clearUserData()
    return this.userInfoService
      .logout()
  }

  public logout() {
    this.clearUserData();
    this.doLogout()
      .pipe(finalize(() => {
        //
        // TODO: fixit
        // this.router.navigate(['pre', 'login']);
      })).subscribe();

  }

  public update() {
    if (this.promise) {
      return this.promise;
    } // already updating

    this.promise = this.userInfoService.currentUserInfo()
      .pipe(finalize(() => {
        this.promise = null
      })).toPromise()
      .then((info) => {
        _.forEach(_.keys(this.userInfo), (k) => delete this.userInfo[k])
        _.assign(this.userInfo, info)

        this.legacyApp.getLocalStorage().set('bigdata_uid', _.parseInt(info.uid as any));
        if (info.schoolId === '-1' &&
        this.state.$current.name !== 'joinSchool' && this.state.$current.name !== 'signout') {
          this.state.go('joinSchool');
        }
        const schoolId = _.parseInt(info.schoolId)
        if (schoolId >= 0 && this.schoolFeatureService.schoolId() !== schoolId) {
          return this.schoolFeatureService.updateFeatureForSchool(schoolId)
            .then(() => info);
        }
        return info;
      })
    return this.promise;
  }

  public get() {
    return this.userInfo
  }

  public then(fn: any): Promise<UserDTO> {
    if (this.promise) {
      return this.promise.then(fn)
    } else if (!_.has(this.userInfo, 'uid')) {
      //
      // 首次update时userInfo为通过new创建出来的
      // 没有对应的$promise属性
      // 所以使用update()返回的promise
      return this.update().then(fn);
    } else {
      //
      // 已经有userInfo
      return bluebird.resolve(this.userInfo).then(fn)
    }
  }

  private getUserData(user: UserDTO | null): UserData {
    if (user == null) {
      this.userData = null
      return
    }
    _.forEach(_.keys(this.userInfo), (k) => delete this.userInfo[k])
    _.assign(this.userInfo, user)

    const roles = _(user.roles).split(',').keyBy().value()
    return {
      user,
      roles,
    }
  }

  public ngOnDestroy(): void {
    this.maintenanceService.stop()
  }

  private updateCounter() {
    this.counter = new Date().getTime();
  }}
