import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RayApiService } from 'app/core/ray-api.service';

@Injectable({
  providedIn: 'root'
})
export class OedExerciseService {

  constructor(
    private httpClient: HttpClient,
    private rayApi: RayApiService
  ) { }
  // 创建习题册
  public createExeriseBook(params) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exerciseBook/create`), params);
  }
  // 获取当前学校的所有习题册
  public getCurrentSchoolExerciseBook(schoolId: string) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/school/${schoolId}/exerciseBooks`));
  }
  // 获取当前所有习题册
  public getExerciseBook(subjectId: number, versionId: number, levelId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/exerciseBooks?subjectId=${subjectId}&versionId=${versionId}&levelId=${levelId}`));
  }
  // 删除习题册
  public deleteExeriseBook(id: number) {
    return this.httpClient.delete<any>(this.rayApi.apiB(`exerciseBook/${id}`));
  }
  // 导入json文件
  public importJson(exeriseBookId: number, resourceId: number) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exerciseBook/${exeriseBookId}/origFile/${resourceId}`), { id: exeriseBookId, resourceId });
  }
  // 获取习题册下json文件
  public getJsonInExeriseBook(exeriseBookId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/${exeriseBookId}/allNodes`));
  }
  // 获取学校列表
  public getSchoolList() {
    return this.httpClient.get<any>(this.rayApi.apiB(`school/locations`));
  }
  // 获取专区列表
  public getTopicList() {
    return this.httpClient.get<any>(this.rayApi.apiB(`tag/topic`));
  }

  public saveOrigNode(origNodeId: number) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exerciseBook/origNode/${origNodeId}/save`), {});
  }

  public ignoreOrigNode(origNodeId: number) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exerciseBook/origNode/${origNodeId}/ignore`), {});
  }

  public createNode(node: any) {
    return this.httpClient.post<any>(this.rayApi.apiB('exerciseBook/node/create'), node);
  }

  public getAllDirs(exBookId: any) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/${exBookId}/allDirs`));
  }
  // 习题册导入学校
  public importSchool(exeriseBookId: number, params: any) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exerciseBook/${exeriseBookId}/position`), params);
  }
  // 获取习题册位置列表
  public getExeriseBookPositionList(exeriseBookId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/${exeriseBookId}/positions`));
  }
  // 删除习题册学校
  public deleteExeriseBookSchool(id: number) {
    return this.httpClient.delete<any>(this.rayApi.apiB(`exerciseBook/position/${id}`));
  }

  public createTest(body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB('exerciseBook/node/createTest'), body);
  }

  public getTestsByNodeId(nodeId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/node/${nodeId}/tests`));
  }

  public getTestsByExBookId(exBookId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/${exBookId}/tests`));
  }

  public deleteTest(nodeId: number, testId: number) {
    return this.httpClient.delete<any>(this.rayApi.apiB(`exerciseBook/node/${nodeId}/test/${testId}`));
  }

  public createNodeAndQuestion(node: any) {
    return this.httpClient.post<any>(this.rayApi.apiB('exerciseBook/node/createNodeAndQuestion'), node);
  }

  public getKps(subjectId: number, levelId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`knowledgePoint/ry/subject/${subjectId}/level/${levelId}`));
  }

  public updateNode(body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB('exerciseBook/node/update'), body);
  }

  public updateQuestion(nodeId: number, questionId: number, body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exerciseBook/node/${nodeId}/question/${questionId}`), body);
  }

  public getQuestionByNodeId(nodeId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/node/${nodeId}/questions`));
  }

  public getQuesInfoByNodeId(nodeId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/node/${nodeId}/question`));
  }

  public formulaByOrigNodeId(origNodeId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/origNode/${origNodeId}/formulas`));
  }

  public formulaToPng(body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exerciseBook/formulaToPng`), body);
  }

  public resCreateTasks(body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB('resDownloadTask/createTasks'), body);
  }

  public resCheckTasks(body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB('resDownloadTask/checkTasks'), body);
  }

  public getTestPaperQuestions(testId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`objectivetest/${testId}/questionDetail`));
  }

  // 发布习题册
  public releaseExercise(exBookId: number) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exerciseBook/${exBookId}/publish`), {});
  }

  public getSynQuestions(exBookId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/${exBookId}/synQuestions`));
  }

  public getNodeByOrigId(origNodeId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/origNode/${origNodeId}/node`));
  }

  public getQuesByQuesId(quesId: number) {
    return this.httpClient.get<any>(this.rayApi.apiB(`exerciseBook/question/${quesId}`));
  }

  public getExbkTest(body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exbk/cloudtest/query`), body);
  }

  public getExbkQues(body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exbk/cloudquestion/query`), body);
  }

  public getTestCount(body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exbk/cloudtest/count`), body);
  }

  public getQuesCount(body: any) {
    return this.httpClient.post<any>(this.rayApi.apiB(`exbk/cloudtestquestion/count`), body);
  }
}
