/**
 * Created by ryoushikaoru on 2019/3/4.
 */
import * as _ from 'lodash';
require('./../cloud/objectiveTestLibrary.ctrl');
const {doImportQuestions} = require('app2/utils/testUtils2');
const {confirmAndDownloadSubjectiveTest, importSubjectTestModel} = require('app2/utils/testUtils2');
require('./subjectiveTestList.scss')
require('assets/styles/testimport.scss');

angular.module('app.prepareCourse2.subjectiveTestList', [])
    .component('subjectiveTestList', {
        bindings: {
            moduleId: '<',
            moduleName: '<',
            courseId: '<',
            currentTest: '<',
            tests: '<',
            maxSort: '=',
            existingNames: '=',
            setCurrentTest: '&',
            status: '<',
            importedTests: '<',
            onImportTestChange: '&',
        },
        controller: subjectiveTestListCtrl,
        template: <string>require('./subjectiveTestList.html'),
    });

subjectiveTestListCtrl.$inject = [
  '$uibModal',
  'localStorageService',
  '$state',
  'dialogs',
  'oedObjectiveTest',
  'oedCourse',
  '$q',
  'notificationService',
  'oedCloudQuestion',
  'oedSubjectiveTest',
  'teachingModuleService'
];
function subjectiveTestListCtrl(
  $uibModal,
  localStorageService,
  $state,
  dialogs,
  oedObjectiveTest,
  oedCourse,
  $q,
  notificationService,
  oedCloudQuestion,
  oedSubjectiveTest,
  teachingModuleService
) {
    this.$onChanges = (changesObj: any) => {
        if (_.has(changesObj, 'courseId')) {
            this.courseId = _.parseInt(this.courseId);
            if (this.courseId) {
                this.course = oedCourse.get({
                    id: this.courseId
                });
            }
        }
        if (_.has(changesObj, 'status')) {
            this.status = this.status || {}
        }
        if (_.has(changesObj, 'moduleId')) {
            if (this.moduleId) {
                this.loadModuleRes()
            }
        }
    };

    this.loadModuleRes = () => {
        this.loading = teachingModuleService.loadModuleDetails(this.moduleId).then((updateModuleDTO) => {
            const allModuleTestIds = _.map(updateModuleDTO.subjectiveTests, 'id')
            this.moduleDetails = {
                ...this.moduleDetails,
                updateModuleDTO,
                allModuleTestIds,
                moduleName: this.moduleName,
            }
        })
    }

    this.reload = (updateModuleDTO, test) => {
        let newTestIds = this.importedTests
        if (updateModuleDTO.$$action === 'add') {
          newTestIds = [...newTestIds, test.id]
        } else {
          newTestIds = _.remove(newTestIds, test.id)
        }
  
        this.onImportTestChange({
          newTests: newTestIds,
        })
        this.loading = teachingModuleService.updateTeachingModule(updateModuleDTO).then(() => {
            return this.loadModuleRes()
        })
    }

    this.setName = function(sort) {
        const str = '主观活动' + (sort);
        if (this.existingNames.indexOf(str) > -1) {
            sort = sort + 1;
            this.setName(sort);
        } else {
            this.newSubjectiveName = str;
        }
        return this.newSubjectiveName;
    };
    this.addActive = function(tests) {
        tests.$promise.then((res: any) => {
            this.maxSort = _.max(_.map(res, 'sort'));
            this.existingNames = _.map(res, 'name');
            if (isNaN(this.maxSort) || !this.tests.length) {
                this.existingNames = [];
                this.maxSort = 1;
            } else {
                this.maxSort = this.maxSort + 1;
            }
            this.setName(this.maxSort);
            this.status.loading = new oedSubjectiveTest({
                name: this.newSubjectiveName,
                courseId: _.parseInt(this.courseId),
                sort: this.maxSort
            }).$save().then((r: any) => {
              notificationService.notify('info', '新建活动成功')
              localStorageService.set('currentName', r.name);
              this.testId = r.id;
              this.tests = oedSubjectiveTest.queryByCourseIncEmpty({
                  course_id: this.courseId
              });
              this.setCurrentTest({t: r,  test: this.tests});
            }).catch((error) => {
                const errCode = _.get(error, 'status', 500);
                if (errCode === 401 || errCode === 403) {
                  return notificationService.notify('error', '您的登录状态已改变，请重新登录!')
                }
                notificationService.notify('error', '新建活动失败，请联系锐学堂技术支持!')
            });
        });
    };
}
