import { Injectable } from '@angular/core';
import * as _ from 'lodash'
import { forkJoin, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { ModuleResDTO, TeachingModuleDTO,
  TeachingPatternDTO, TestOrPresentDTO, UpdateModuleItemDTO, LessonDTO } from '../models/lesson';
import { LessonMessageService } from './lesson-message.service';
import { LessonService } from './lesson.service';

@Injectable({
  providedIn: 'root'
})
export class TeachingPatternService {

  constructor(
    private lessonService: LessonService,
    private lessonMessageService: LessonMessageService,
  ) { }

  public loadModuleData(id: number): Promise<TeachingModuleDTO> {
    return this.lessonService.getModule(id).toPromise()
  }

  public getPreResTestByIds(ids: number[]): Promise<TestOrPresentDTO[]> {
    return this.lessonService.getPreResByIds(ids).toPromise()
  }

  public getTestByIds(ids: number[]): Promise<TestOrPresentDTO[]> {
    return this.lessonService.getTestByIds(ids).toPromise()
  }

  public updateModuleItemOrder(updateModuleDTO: UpdateModuleItemDTO) {
    return this.lessonService.updateModuleItemOrder(updateModuleDTO).toPromise()
  }

  public updateModuleItem(tests: TestOrPresentDTO[], moduleId: number): Observable<UpdateModuleItemDTO> {
    const orders = this.calcOrder(tests)
    const updateModuleDTO: UpdateModuleItemDTO = {
      id: moduleId,
      presentResources: orders[0],
      objectiveTests: orders[1],
      subjectiveTests: orders[2],
    }
    return this.lessonService.updateModuleItemOrder(updateModuleDTO)
  }

  public deleteModuleData(res: TestOrPresentDTO, tmodule: TeachingModuleDTO): TeachingModuleDTO {
    if (res.type === 'presentresource') {
      tmodule = {
        ...tmodule,
        presentResources: _.chain(tmodule).get('presentResources')
        .filter((p: ModuleResDTO) => p.presentResourceId !== res.id).value()
      }
    } else {
      tmodule = {
        ...tmodule,
        tests: _.chain(tmodule).get('tests')
        .filter((t: ModuleResDTO) => t.objectiveTestId !== res.id).value()
      }
    }
    return tmodule
  }

  public loadPatternDetails(courseId: number, sectionId: number, isCanStar?: boolean):
  Observable<{course: LessonDTO; pattern: TeachingPatternDTO; tests: TestOrPresentDTO[]}> {
    let selectedCourse: LessonDTO
    let selectedPattern: TeachingPatternDTO
    this.lessonMessageService.sendLoadingAction(true)
    return of(courseId).pipe(
      flatMap(() => {
        if (courseId !== -1) {
          return this.lessonService.getCourseInfo(courseId)
        } else {
          const recommendCourse = this.lessonService.createDummyTmpCourse(sectionId, '推荐课程1')
          return of(recommendCourse)
        }
      }),
      flatMap((course: LessonDTO) => {
        selectedCourse = course
        if (courseId !== -1) {
          return this.lessonService.getTeachingPatternByCourseId(courseId)
        } else {
          return this.createDummyPattern(courseId, isCanStar)
        }
      }),
      flatMap((p: TeachingPatternDTO) => {
        const modules = _.chain(p).get('modules', []).orderBy('sort', 'asc').value()
        selectedPattern = {
          ...p,
          modules,
        }
        if (courseId !== -1) {
          return forkJoin(
            this.lessonService.loadPreResOfCourse(courseId),
            this.lessonService.loadObjOfCourse(courseId),
            this.lessonService.loadSbjOfCourse(courseId),
          )
        } else {
          return of([])
        }
      }),
      map((value: [TestOrPresentDTO[], TestOrPresentDTO[],  TestOrPresentDTO[]]) => {
        const prereses = (_.chain(value) as any).get('0', []).map((v: TestOrPresentDTO) => {
          return {
            ...v,
            type: 'presentresource',
          }}).value()
        const testDTOs = [
          ..._.flatten([..._.get(value, '1', []), ..._.get(value, '2', []), ...prereses])
        ]
        return {
          course: selectedCourse,
          pattern: selectedPattern,
          tests: testDTOs,
        }
      })
    )
  }

  public createDummyPattern(courseId: number, isCanStar: boolean) {
    return Observable.create((observer) => {
      let modules = []
      if (!isCanStar) {
        modules = [
          ...this.lessonService.createDummyModules()
        ]
      }
      const p: TeachingPatternDTO = {
        name: '教学设计',
        modules,
      }
      observer.next(p)
      observer.complete()
    })
  }

  private filterData(tests: TestOrPresentDTO[], resType: string) {
    return _.chain(tests).filter((t) => t.type === resType).map((t) =>  {
      return  {
        id: t.id,
        sort: t.sort,
      }
    }).value()
  }

  private calcOrder(tests: TestOrPresentDTO[]) {
    const pOrder = this.filterData(tests, 'presentresource')
    const oOrder = this.filterData(tests, 'objective')
    const sOrder = this.filterData(tests, 'subjective')
    return [pOrder, oOrder, sOrder]
  }

}
