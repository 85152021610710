import {Component, OnInit, EventEmitter, Input, Output, OnDestroy, HostListener,ElementRef} from '@angular/core';
import * as _ from 'lodash';
import { TestSessionService } from '../test-session.service';
import { zip, Subscription } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { TestSessionStatDTO, TestSessionStatByQuesNewDTO } from 'app/models/obj-test-session';
import { getTestStatLabel } from 'app2/utils/testUtils2';
import { LoadingService } from 'app/shared/loading/loading.service';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-obj-test-stat-table',
  template: require('./obj-test-stat-table.component.html'),
  styles: [require('./obj-test-stat-table.component.scss')]
})
export class ObjTestStatTableComponent implements OnInit, OnDestroy {
  @Input() testSessionId: number;
  @Input() isQuickCreateObjTest: boolean;
  @Output() showDetailsForStudent = new EventEmitter<any>();
  @HostListener('scroll', ['$event']) public scrolled($event: Event) {
  }
  reverse = true;
  propertyId = '';
  orderByList = [this.propertyId, 'duration']
  ordersList = ['desc', 'asc']
  records: any;

  propertys = [
    {
      id: 'studentId', name: '学生ID', checked: true, class: 't-info', isStatic: true,
      key: 'stdId',
      func: (data: any) => {
        return data.stdId;
      }
    },
    { id: 'studentName', name: '姓名', checked: true, class: 't-info', isStatic: true,
      key: 'stdName',
      func: (data: any) => {
        return data.stdName;
      }
    },
    { id: 'stdRank', name: '班级名次', checked: true, class: 't-danger', isStatic: true,
      key: 'stdRank',
      func: (data) => {
        return data.stdRank;
      }
    },
    { id: 'totalPoint2', name: '总成绩', checked: true, class: 't-warning', isStatic: true,
      key: 'totalPoint2',
      func: (data) => {
        return Number(data.totalPoint2).toFixed(1);
      }
    },
    { id: 'duration', name: '答题用时', checked: true, isStatic: true,
      key: 'duration',
      func: (data: any) => {
      return _.isNil(data.duration) ? '-' : this.getFormatDuration(data.duration);
      },
    },
    { id: 'correctRate', name: '正确率', checked: true, isStatic: true,
      key: 'correctRate',
      func: (data: any) => {
        return data.correctRate + '%';
      },
    },
  ] as any

  checkedStaticProperties = []
  checkedProperties = this.propertys

  questions = [] as any;
  statsForLabel: TestSessionStatByQuesNewDTO[];
  qusetionType: any;
  summary: TestSessionStatDTO;
  currentLabels: any[];
  qType: any;
  loadingName = 'obj-test-stat-table';
  checkedQProps: any[];
  subs$: Subscription;

  constructor(private testSessionService: TestSessionService,
              private loadingService: LoadingService,
              private notifyService: NotificationService,
              private el: ElementRef) { }

  ngOnInit() {
    const summary$ = this.testSessionService.getTestSessionStat(this.testSessionId)
    const statsForLabel$ = this.testSessionService.getStatByQuestionsWithRevise(this.testSessionId)
    this.loadingService.show(false, this.loadingName)
    this.subs$ = zip(summary$, statsForLabel$)
      .pipe(map(([summary, statsForLabel]) => {
        this.questions = [];
        this.summary = summary
        this.statsForLabel = statsForLabel

        _.forEach(this.summary.statOfRank.rows, (row) => {
          _.forEach(_.keys(row.quesPointMap), (keyOfMap) => {
            row[keyOfMap] = row.quesPointMap[keyOfMap]
          })
        })

        this.qusetionType = _.zipWith(this.summary.statOfRank.sortedQuesGroupId, this.summary.statOfRank.sortedQuesId, (groupId, quesId) => {
          return {groupId, quesId}
        })

        this.qType =_.groupBy(this.qusetionType, (item) => {
          if(item.groupId === 0) {
            return  item.quesId;
          } else {
            return  item.groupId;
          }
        });
        _.forEach(this.qType, (item) => {
          this.questions.push(item);
        });
        this.currentLabels = getTestStatLabel(this.statsForLabel);
        let idx = 0;
        _.forEach(this.questions, (o, index) => {
          if(_.size(o) > 1) {
            _.forEach(o, (item, i: number) => {
              if (i > 0) {
                idx++;
              }
              if (this.isQuickCreateObjTest) {
                this.propertys.push({id: '' + item.quesId, name: '题' + ('' + (idx + 1)), checked: true});
              } else {
                this.propertys.push({id: '' + item.quesId, name: '题' + ('' + this.currentLabels[index + idx]), checked: true});
              }
            })
          } else {
            if (this.isQuickCreateObjTest) {
              this.propertys.push({id: '' + o[0].quesId, name: '题' + ('' + (idx + 1)), checked: true});
            } else {
              this.propertys.push({id: '' + o[0].quesId, name: '题' + ('' + this.currentLabels[index + idx]), checked: true});
            }
          }
        })
      }), map(() => {
        this.sortBy('totalPoint2')
        this.updateCheckedProps()
      }), finalize(() => this.loadingService.hide(this.loadingName)))
      .subscribe(() => {}, (e) => {
        console.log('error', e)
        this.notifyService.error('加载数据失败, 请刷新页面或者联系技术支持')
      })
  }

	ngOnDestroy(): void {
    this.subs$.unsubscribe()
	}

  sortBy(propertyId: string) {
    this.reverse = (this.propertyId === propertyId) ? !this.reverse : true;
    this.propertyId = '' + propertyId;
    const order = this.reverse ? 'desc' : 'asc'
    if (propertyId === 'duration') {
      this.orderByList = ['duration']
      this.ordersList = [order]
    }
    this.orderByList = [this.propertyId, 'duration']
    this.ordersList = [order, 'asc']
    this.records = _.orderBy(this.summary.statOfRank.rows, this.orderByList, this.ordersList as any)
  }

  getFormatDuration(duration: number) {
    const durationInSec = duration / 1000;
    const minute = _.toInteger(durationInSec / 60)
    const second = _.toInteger(durationInSec % 60)

    const minuteStr = minute + '分'
    const secondStr = second + '秒'

    if (minute === 0) {
      return secondStr
    }

    return minuteStr + secondStr;
  }

  onCheckboxItemClicked (cp, event) {
    event.stopPropagation();
    cp.checked = !cp.checked;
    this.updateCheckedProps()
  }

  updateCheckedProps() {
    this.checkedProperties = _.filter(this.propertys, 'checked')
    const [staticProps, qProps] = _.partition(this.checkedProperties, 'isStatic')
    this.checkedStaticProperties = staticProps
    this.checkedQProps = qProps
  }

  getStuPoint(stu: any, ques: any) {
    return stu.quesPointMap2[ques.id]
  }

  isUnAnswer(stu: any, ques: any) {
    return !stu.quesPointMap2[ques.id] && stu.quesPointMap2[ques.id] !== 0
  }

  onShowDetailsForStudent(stu: any) {
    const data = {
        std:stu,
        finishStuList: this.records
    }
    this.showDetailsForStudent.emit(data)
  }
  handleScroll(){
    const scrollTop = this.el.nativeElement.querySelector('#tbl').scrollTop
    this.el.nativeElement.querySelector('#thead').style.transform = 'translateY(' + scrollTop + 'px)';
  }
}
