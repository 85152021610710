(function() {
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.teachingMaterial', {
        url: '/teachingMaterial',
        template: require('assets/templates/manage/teachingMaterial.html'),
        controller: 'teachingMaterialCtrl'
      });

      $stateProvider.state('manage.teachingMaterial.edit', {
        url: '/teachingMaterial/:materialId',
        //TODO: fix it
        template: '', //require('assets/templates/manage/editTeachingMaterial.html'),
        controller: 'editTeachingMaterialCtrl'
      });
    }])
    .controller('teachingMaterialCtrl', teachingMaterialCtrl);

  teachingMaterialCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal', 'userInfo', 'notificationService', 'dialogs',
    '$timeout', 'oedCoursePath', 'oedTeachingMaterialVersions', 'oedUnitItem']
  function teachingMaterialCtrl($scope, $state, $stateParams, $q, $uibModal, userInfo, notificationService, dialogs,
                                $timeout, oedCoursePath, oedTeachingMaterialVersions, oedUnitItem) {
    $scope.currentState.title = '教材管理';
    let schoolId = 0;
    $scope.reloadAll = function() {
      $scope.globalLevels = oedCoursePath.getAllLevels();
      $scope.globalSubjects = oedCoursePath.getAllSubjects();
      $scope.globalVersions = oedCoursePath.getAllVersions();
      $scope.globalBooks = oedCoursePath.getAllBooks();
      $scope.globalLgvs = oedCoursePath.getAllLgvs();
      return $q.all([$scope.globalLevels.$promise, $scope.globalSubjects.$promise,
        $scope.globalVersions.$promise, $scope.globalBooks.$promise, $scope.globalLgvs.$promise]);
    };
    userInfo.then(function(info) {
      schoolId = info.schoolId;
      $scope.user = info;
      $scope.reloadAll().then(function(res) {
        if (!_.isEmpty($scope.globalLevels)) {
          $scope.setCurrentLevel($scope.globalLevels[0]);
        }
      });
    });
    $scope.isSystemAdmin = function() {
      return $scope.user && _.includes($scope.user.roles, 'system');
    };
    $scope.editSubject = function() {
      $scope.dialog = $uibModal.open({
        template: require('assets/templates/manage/editSubjectDialog.html'),
        scope: $scope,
        size: 'md'
      });
    };
    $scope.editVersion = function() {
      $scope.dialog = $uibModal.open({
        template: require('assets/templates/manage/editVersionDialog.html'),
        scope: $scope,
        size: 'md'
      });
    };
    $scope.ok = function() {
      $scope.dialog.close({});
    };

    $scope.cancel = function() {
      $scope.dialog.dismiss('cancel');
    };
    $scope.selectDialogSubject = function(item) {
      $scope.currentDialogSubject = item;
    };
    $scope.isFirst = function(type, item) {
      const array = $scope.getItemsByType(type);
      return _.size(array) === 0 || item === array[0];
    };
    $scope.isLast = function(type, item) {
      const array = $scope.getItemsByType(type);
      const size = _.size(array);
      return size === 0 || item === array[size - 1];
    };
    $scope.getItemsByType = function(type) {
      if (type === 'subjects')
        return $scope.globalSubjects;
      else if (type === 'versions')
        return $scope.globalVersions;
      else if (type === 'books')
        return $scope.globalBooks;
    };
    $scope.setItemsByType = function(type, items) {
      if (type === 'subjects')
        $scope.globalSubjects = items;
      else if (type === 'versions')
        $scope.globalVersions = items;
      else if (type === 'books')
        $scope.globalBooks = items;
    };
    $scope.saveItemsByType = function(type, item) {
      if (type === 'subjects')
        return $scope.saveSubject(item);
      else if (type === 'versions')
        return $scope.saveVersion(item);
      else if (type === 'books')
        return $scope.saveBook(item);
    };
    $scope.up = function(type, item) {
      const array = $scope.getItemsByType(type);
      const idx = _.findIndex(array, item);
      const previous = array[idx - 1];
      const curSort = item.sort;
      item.sort = previous.sort;
      previous.sort = curSort;
      $scope.setItemsByType(type, _.sortBy(array, 'sort'));
      $scope.saveItemsByType(type, item);
    };
    $scope.down = function(type, item) {
      const array = $scope.getItemsByType(type);
      const idx = _.findIndex(array, item);
      const next = array[idx + 1];
      const curSort = item.sort;
      item.sort = next.sort;
      next.sort = curSort;
      $scope.setItemsByType(type, _.sortBy(array, 'sort'));
      $scope.saveItemsByType(type, item);
    };
    $scope.saveSubject = function(cur) {
      let allSbj = [];
      allSbj = _.concat(allSbj, $scope.globalSubjects);
      _.each(allSbj, (val, idx) => val.sort = idx);
      return oedTeachingMaterialVersions.updateSubjects(allSbj).$promise.then(res => {
        $scope.globalSubjects = res;
        $scope.currentDialogSubject = cur;
        $scope.reloadSubject();
      });
    };
    $scope.addSubject = function(cur) {
      return oedTeachingMaterialVersions.addSubject(cur).$promise.then(res => {
        $scope.globalSubjects.push(res);
        $scope.currentSubject = res;
        return res;
      });
    };
    $scope.reloadSubject = function() {
      const t = $scope.currentLevel;
      const glvs = _.uniq(_.map(_.filter($scope.globalLgvs, s => s.levelId === t.levelId), t => t.subjectId));
      $scope.subjects = _.filter($scope.globalSubjects, s => _.includes(glvs, s.id));
    };
    $scope.reloadVersion = function() {
      const t = $scope.currentSubject;
      const glvs = _.uniq(_.map(_.filter($scope.globalLgvs, s => s.levelId === $scope.currentLevel.levelId &&
        s.subjectId === t.id), t => t.versionId));
      $scope.versions = _.filter($scope.globalVersions, s => _.includes(glvs, s.versionId));
    };
    $scope.reloadBook = function() {
      const t = $scope.currentVersion;
      const glvs = _.uniq(_.map(_.filter($scope.globalLgvs, s => s.levelId === $scope.currentLevel.levelId &&
        s.subjectId === $scope.currentSubject.id && s.versionId === t.versionId), t => t.gradeId));
      $scope.books = _.filter($scope.globalBooks, s => _.includes(glvs, s.gradeId));
    };
    $scope.saveVersion = function(cur) {
      let allVer = [];
      allVer = _.concat(allVer, $scope.globalVersions);
      _.each(allVer, (val, idx) => val.sort = idx);
      return oedTeachingMaterialVersions.updateVersions(allVer).$promise.then(res => {
        $scope.globalVersions = allVer;
        $scope.currentDialogVersion = cur;
        $scope.reloadVersion();
      });
    };
    $scope.addVersion = function(cur) {
      return oedTeachingMaterialVersions.addVersion(cur).$promise.then(res => {
        $scope.globalVersions.push(res);
        $scope.currentVersion = res;
        return res;
      });
    };
    $scope.saveBook = function(cur) {
      let allBook = [];
      allBook = _.concat(allBook, $scope.globalBooks);
      _.each(allBook, (val, idx) => val.sort = idx);
      return oedTeachingMaterialVersions.updateBooks(allBook).$promise.then(res => {
        $scope.globalBooks = allBook;
        $scope.currentDialogBook = cur;
        $scope.reloadBook();
      });
    };
    $scope.addBook = function(cur) {
      return oedTeachingMaterialVersions.addBook(cur).$promise.then(res => {
        $scope.globalBooks.push(res);
        $scope.currentBook = res;
        return res;
      });
    };
    $scope.addBookNew = function(cur) {
      return oedTeachingMaterialVersions.addBookNew(cur).$promise.then(res => {
        $scope.globalBooks.push(res);
        $scope.currentBook = res;
        return res;
      });
    };
    $scope.setCurrentLevel = function(t) {
      $scope.currentLevel = t;
      $scope.reloadSubject();
      if (!_.isEmpty($scope.subjects)) {
        $scope.setCurrentSubject($scope.subjects[0]);
      }
    };
    $scope.setCurrentSubject = function(t) {
      $scope.currentSubject = t;
      $scope.reloadVersion();
      if (!_.isEmpty($scope.versions)) {
        $scope.setCurrentVersion($scope.versions[0]);
      }
    };
    $scope.setCurrentVersion = function(t) {
      $scope.currentVersion = t;
      $scope.reloadBook();
    };
    $scope.syncStatus = {};
    $scope.showSections = function(book) {
      $scope.units = oedUnitItem.queryByVersionSubjectIdAndGrade({
        versionId: $scope.currentVersion.versionId,
        subjectId: $scope.currentSubject.id,
        gradeId: book.gradeId
      });
      $scope.units.$promise.then(res => {
        if (!_.isEmpty($scope.units) && !_.isEmpty($scope.units[0].child))
          $scope.syncStatus = oedTeachingMaterialVersions.getBookSync({
            id: $scope.units[0].child[0].relId
          });
      });
      $scope.currentBook = book;
      $scope.dialog = $uibModal.open({
        template: require('assets/templates/manage/materialSectionsDialog.html'),
        scope: $scope,
        windowClass: 'modalCenter',
        size: 'lg'
      });
    };
    $scope.doGenerateKey = function(units) {
      _.forEach(units, u => {
        u.key = _.replace(u.name, /(^\d*(一|二|三|四|五|六|七|八|九|十|、)*\s*\.*\d*\s*)|(\s*)$/g, '');
        $scope.doGenerateKey(u.child);
      });
    };
    $scope.saveKey = function() {
      $scope.keyToSave = [];
      $scope.collectKey($scope.units[0].child);
      oedTeachingMaterialVersions.saveSection($scope.keyToSave);
    };
    $scope.syncKey = function() {
      $scope.loading = oedTeachingMaterialVersions.syncBook({
        id: $scope.units[0].child[0].relId
      }).$promise.then(res => {
        $scope.cancel();
        notificationService.notify('info', '正在后台同步中')
      });
    };
    $scope.collectKey = function(units) {
      _.forEach(units, u => {
        $scope.keyToSave.push(u);
        $scope.collectKey(u.child);
      });
    };
    $scope.generateKey = function() {
      $scope.doGenerateKey($scope.units[0].child);
      $scope.saveKey();
    };
    $scope.refreshBookInfo = function(type, $select) {
      const search = $select.search;
      let list = angular.copy($select.items);
      list = list.filter(function(item) {
        return item.id !== -1;
      });
      if (!search) {
        $select.items = list;
      } else {
        const userInputItem = {};
        if (type === 'subjects')
          userInputItem.subjectName = search;
        else if (type === 'versions')
          userInputItem.versionName = search;
        else if (type === 'books')
          userInputItem.gradeName = search;
        $select.items = [userInputItem].concat(list);
        $select.selected = userInputItem;
      }
    };
    $scope.addBookClick = function() {
      $scope.bookInfo = {
        level: $scope.currentLevel,
        subject: $scope.currentSubject,
        version: $scope.currentVersion
      };
      $scope.showValidationError = false;
      $scope.dialog = $uibModal.open({
        template: require('assets/templates/manage/addBookDialog.html'),
        scope: $scope,
        windowClass: 'modalCenter',
        size: 'md'
      });
    };
    $scope.onLevelSelected = function(item) {
      $scope.bookInfo.level = item;
    };
    $scope.onSubjectSelected = function(item) {
      $scope.bookInfo.subject = item;
    };
    $scope.onVersionSelected = function(item) {
      $scope.bookInfo.version = item;
    };
    $scope.onGradeSelected = function(item) {
      $scope.bookInfo.grade = item;
    };
    $scope.confirmAddBook = function() {
      $scope.showValidationError = true;
      if (!_.isEmpty($scope.bookInfo.level) && !_.isEmpty($scope.bookInfo.subject) &&
        !_.isEmpty($scope.bookInfo.version) && !_.isEmpty($scope.bookInfo.grade)) {
        let sbjDone = Promise.resolve($scope.bookInfo.subject);
        let verDone = Promise.resolve($scope.bookInfo.version);
        let bookDone = Promise.resolve($scope.bookInfo.grade);
        const needCreateSbj = !_.has($scope.bookInfo.subject, 'id');
        const needCreateVer = !_.has($scope.bookInfo.version, 'versionId');
        const needCreateGd = !_.has($scope.bookInfo.grade, 'gradeId');
        $scope.currentLevel = $scope.bookInfo.level;
        if (needCreateSbj) {
          $scope.bookInfo.subject.sort = _.maxBy($scope.globalSubjects, 'sort').sort + 1;
          sbjDone = $scope.addSubject($scope.bookInfo.subject);
        }
        if (needCreateVer) {
          $scope.bookInfo.version.sort = _.maxBy($scope.globalVersions, 'sort').sort + 1;
          $scope.bookInfo.version.source = 'oed';
          verDone = $scope.addVersion($scope.bookInfo.version);
        }
        if (needCreateGd) {
          $scope.bookInfo.grade.gradeNum = 0;
          $scope.bookInfo.grade.sort = _.maxBy($scope.globalBooks, 'sort').sort + 1;
          $scope.bookInfo.grade.subjectId = needCreateSbj ? $scope.currentSubject.id : $scope.bookInfo.subject.id;
          $scope.bookInfo.grade.levelId = $scope.bookInfo.level.levelId;
          $scope.bookInfo.grade.versionId = needCreateVer ?
            $scope.currentVersion.versionId : $scope.bookInfo.version.versionId;
          bookDone = $scope.addBookNew($scope.bookInfo.grade);
        }
        $q.all([sbjDone, verDone, bookDone]).then(res => {
          oedTeachingMaterialVersions.saveLgvs({
            levelId: $scope.bookInfo.level.levelId,
            gradeId: res[2].gradeId,
            versionId: res[1].versionId,
            subjectId: res[0].id
          }).$promise.then(res => {
            notificationService.notify('info', '添加教材成功')
            $scope.cancel();
            $scope.reloadAll().then(function(res) {
              if (!needCreateSbj)
                $scope.currentSubject = $scope.bookInfo.subject;
              if (!needCreateVer)
                $scope.currentVersion = $scope.bookInfo.version;
              $scope.reloadSubject();
              $scope.reloadVersion();
              $scope.reloadBook();
            });
          });
        });
      }
    };
    $scope.editBook = function() {
      $scope.dialog = $uibModal.open({
        template: require('assets/templates/manage/editBookDialog.html'),
        scope: $scope,
        size: 'md'
      });
    };
    $scope.selectDialogBook = function(item) {
      $scope.currentDialogBook = item;
    };
    $scope.selectDialogVersion = function(item) {
      $scope.currentDialogVersion = item;
    };
    $scope.isFirstSection = function(parent, child) {
      return !_.isEmpty(parent.child) && parent.child[0].id === child.id;
    };
    $scope.isLastSection = function(parent, child) {
      return !_.isEmpty(parent.child) && parent.child[_.size(parent.child) - 1].id === child.id;
    };
    $scope.newSection = {};
    $scope.doAddSection = function() {
      if (!_.isEmpty($scope.newSection.name)) {
        $scope.newSection.depth = 1;
        const lgvs = _.find($scope.globalLgvs, s => s.levelId === $scope.currentLevel.levelId &&
          s.subjectId === $scope.currentSubject.id && s.versionId === $scope.currentVersion.versionId &&
          s.gradeId === $scope.currentBook.gradeId);
        $scope.newSection.relId = lgvs.relId;
        if (_.isEmpty($scope.units) || _.isEmpty($scope.units[0].child))
          $scope.newSection.sort = 0;
        else
          $scope.newSection.sort = _.maxBy($scope.units[0].child, 'sort').sort + 1;
        oedTeachingMaterialVersions.saveSection([$scope.newSection]).$promise.then(r => {
          $scope.newSection = {};
          if (_.isEmpty($scope.units)) {
            $scope.units = [];
            $scope.units[0] = {
              child: [],
              gradeId: $scope.currentBook.gradeId,
              gradeName: $scope.currentBook.gradeName
            };
          }
          $scope.units[0].child.push(r[0]);
        });
      }
    };
    $scope.selectDialogSection = function(item) {
      $scope.currentDialogSection = item;
    };
    $scope.addSection = function() {
      $scope.currentDialogSection = $scope.newSection;
      $timeout(function() {
        angular.element('#addSectionBtn').triggerHandler('click');
      }, 0);
    };
    $scope.newSubSection = {};
    $scope.addSubSection = function(parent) {
      $scope.currentDialogSection = $scope.newSubSection;
      $timeout(function() {
        angular.element('#addSubSectionBtn' + parent.id).triggerHandler('click');
      }, 0);
    };
    $scope.form = {};
    $scope.showInput = function(formId) {
      $scope.form['addSubSectionForm' + formId].$show();
    };
    $scope.hideInput = function(formId) {
      $scope.form['addSubSectionForm' + formId].$hide();
    };
    $scope.deleteSection = function(parent, child) {
      if (child.id === $scope.units[0].child[0].id && _.size($scope.units[0].child) === 1) {
        return notificationService.notify('error', '教材至少需要包含一个章节')
      }
      let newId = $scope.units[0].child[0].id;
      if (newId === child.id)
        newId = $scope.units[0].child[1].id;
      oedCoursePath.getBySectionId({
        id: child.id
      }).$promise.then(res => {
        const names = _.map(res, r => r.courseName);
        let msg = '当前章节尚未被使用，确定删除吗?';
        if (!_.isEmpty(names))
          msg = '以下课程关联了本章节，删除之后，对应课程将被自动关联到该教材的第一章节，确定删除吗?<br>' + _.join(names, '<br>');
        const dlg = dialogs.confirm('删除章节', msg);
        dlg.result.then(r => {
          oedTeachingMaterialVersions.deleteSection({
            id: child.id,
            withId: newId
          }).$promise.then(r => {
            _.remove(parent.child, c => c === child);
          });
        }, r => {});
      });
    };
    $scope.saveSection = function(sec) {
      oedTeachingMaterialVersions.saveSection([sec]);
    };
    $scope.doAddSubSection = function(parent) {
      if (_.isEmpty($scope.newSubSection.name)) {
        $scope.hideInput(parent.id);
        return;
      }
      const lgvs = _.find($scope.globalLgvs, s => s.levelId === $scope.currentLevel.levelId &&
        s.subjectId === $scope.currentSubject.id && s.versionId === $scope.currentVersion.versionId &&
        s.gradeId === $scope.currentBook.gradeId);
      $scope.newSubSection.relId = lgvs.relId;
      $scope.newSubSection.parentId = parent.id;
      $scope.newSubSection.depth = parent.depth + 1;
      if (_.isEmpty(parent.child))
        $scope.newSubSection.sort = 0;
      else
        $scope.newSubSection.sort = _.maxBy(parent.child, 'sort').sort + 1;
      oedTeachingMaterialVersions.saveSection([$scope.newSubSection]).$promise.then(r => {
        $scope.newSubSection.id = r[0].id;
        if (_.isEmpty(parent.child)) {
          parent.child = [_.cloneDeep($scope.newSubSection)];
        } else {
          parent.child.push(_.cloneDeep($scope.newSubSection));
        }
        $scope.hideInput(parent.id);
        $scope.newSubSection = {};
      });
    };
    $scope.setExamSection = function(item, bool) {
      item.itemType = bool;
      var arr = [];
      var str = bool? '设置' : '取消';
      arr.push(item);
      oedTeachingMaterialVersions.setExamSection(arr).$promise.then(res => {
        notificationService.notify('info', str + '成功')
      }).catch(function () {
        notificationService.notify('info', str + '失败')
      })
    };
    $scope.upSection = function(parent, item) {
      const idx = _.findIndex(parent.child, item);
      const previous = parent.child[idx - 1];
      const curSort = item.sort;
      item.sort = previous.sort;
      previous.sort = curSort;
      oedTeachingMaterialVersions.saveSection([previous, item]).$promise.then(res => {
        parent.child[idx - 1] = item;
        parent.child[idx] = previous;
      });
    };
    $scope.downSection = function(parent, item) {
      const idx = _.findIndex(parent.child, item);
      const next = parent.child[idx + 1];
      const curSort = item.sort;
      item.sort = next.sort;
      next.sort = curSort;
      oedTeachingMaterialVersions.saveSection([next, item]).$promise.then(res => {
        parent.child[idx + 1] = item;
        parent.child[idx] = next;
      });
    };
    $scope.deleteBook = function(book, $event) {
      $event.stopPropagation();
      if (book.gradeName === '其他') {
        return notificationService.notify('info', '特殊教材无法删除')
      }
      const lgvs = _.find($scope.globalLgvs, s => s.levelId === $scope.currentLevel.levelId &&
      s.subjectId === $scope.currentSubject.id && s.versionId === $scope.currentVersion.versionId &&
      s.gradeId === book.gradeId);
      oedCoursePath.getByRelId({
        id: lgvs.relId
      }).$promise.then(res => {
        const names = _.map(res, r => r.name);
        let msg = '当前教材尚未被使用，确定删除吗?';
        if (!_.isEmpty(names))
          msg = '以下课程属于本教材，删除教材之后，课程将被自动关联到"其他"教材，确定删除吗?<br>' + _.join(names, '<br>');
        const dlg = dialogs.confirm('删除教材', msg);
        dlg.result.then(r => {
          oedTeachingMaterialVersions.deleteBook({
            id: lgvs.relId
          }).$promise.then(res => {
            $scope.reloadAll().then(function(res) {
              notificationService.notify('info', '删除成功')
              $scope.cancel();
              $scope.reloadSubject();
              $scope.reloadVersion();
              $scope.reloadBook();
            });
          });
        }, r => {});
      });
    };
    $scope.deleteVersion = function(ver) {
      if (ver.versionName === '其他') {
        return notificationService.notify('info', '特殊版本无法删除')
      }
      const toUpdated = _.filter($scope.globalLgvs, s => s.versionId === ver.versionId);
      const deleted = _.map(_.map(toUpdated, s => s.gradeId), gid => {
        return _.find($scope.globalBooks, s => s.gradeId == gid).gradeName;
      });
      const msg = '以下教材属于本版本，删除版本之后，教材将被自动关联到"其他"版本，确定删除吗?<br>' + _.join(deleted, '<br>');
      const dlg = dialogs.confirm('删除版本', msg);
      dlg.result.then(r => {
        oedTeachingMaterialVersions.deleteVersion({
          id: ver.versionId
        }).$promise.then(res => {
          $scope.reloadAll().then(function(res) {
            notificationService.notify('info', '删除成功')
            $scope.cancel();
            $scope.reloadSubject();
            $scope.reloadVersion();
            $scope.reloadBook();
          });
        }, r => {});
      });
    };
    $scope.deleteSubject = function(sbj) {
      if (sbj.subjectName === '其他') {
        return notificationService.notify('info', '特殊学科无法删除')
      }
      const lgvs = _.groupBy(_.filter($scope.globalLgvs, s => s.subjectId === sbj.id), s => s.versionId);
      const deleted = [];
      _.each(lgvs, (value, key) => {
        const version = _.find($scope.globalVersions, v => v.versionId == key);
        const grades = _.map(value, v => {
          return _.find($scope.globalBooks, s => s.gradeId == v.gradeId).gradeName;
        });
        deleted.push(version.versionName + ': ' + _.join(grades, ', '));
      });
      const msg = '以下教材属于本学科，删除学科之后，教材将被自动关联到"其他"学科，确定删除吗?<br>' + _.join(deleted, '<br>');
      const dlg = dialogs.confirm('删除学科', msg);
      dlg.result.then(r => {
        oedTeachingMaterialVersions.deleteSubject({
          id: sbj.id
        }).$promise.then(res => {
          $scope.reloadAll().then(function(res) {
            notificationService.notify('info', '删除成功')
            $scope.cancel();
            $scope.reloadSubject();
            $scope.reloadVersion();
            $scope.reloadBook();
          });
        }, r => {});
      });
    };
  }
})();
