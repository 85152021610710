(function() {
  angular.module('app.manage')
    .controller('importFromCoursePathsDialogCtrl', importFromCoursePathsDialogCtrl);

  importFromCoursePathsDialogCtrl.$inject = ['$uibModalInstance', '$scope', '$http',
    'notificationService', 'oedCoursePath', 'coursePath']
  function importFromCoursePathsDialogCtrl($uibModalInstance, $scope, $http,
    notificationService, oedCoursePath, coursePath) {
    $scope.cp = coursePath;

    $scope.importInfo = {};

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.ok = function() {
      $scope.showFormError = true;
      if ($scope.importFromCourseForm.$invalid) {
        return;
      }

      var cpIds = _.map($scope.importInfo.coursePaths, function(cp) {
        return cp.id;
      });

      $scope.loading = oedCoursePath.importContentFromExisting({
        id: coursePath.id,
        cpIds: cpIds.join(',')
      }).$promise.then(function() {
        notificationService.notify('info', '导入课程信息成功')

        $uibModalInstance.close($scope.schoolInfo);
      });
    };

    $scope.now = moment();

    $scope.groupByStatus = function(item) {
      if (item.isUnStarted($scope.now)) {
        return '未开始';
      }
      if (item.isOnGoing($scope.now)) {
        return '进行中';
      }

      return '已完成';
    };

    $scope.allCoursePaths = [];

    $scope.loading = oedCoursePath.queryCoursePathsByUserAndSubject({
      userId: coursePath.teacherId,
      subjectId: coursePath.subjectId
    }).$promise.then(function(cps) {
      $scope.allCoursePaths = _.filter(cps, function(cp) {
        return cp.id != coursePath.id;
      });
    });
  }
})();
