(function() {
  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('signinStudent', {
        url: '/anno/signinStudent',
        template: require('assets/templates/signinStudent.html'),
        controller: 'signInStudent',
        loadingCls: 'homeloading',
        bodyStyle: 'newstyle'
      });
    }]).controller('signInStudent', signInStudent);

  signInStudent.$inject = ['$scope', '$http', '$cookies',
    '$location', '$state', 'oedConfig', 'oedUserClass', 'userInfo']
  function signInStudent($scope, $http, $cookies,
    $location, $state, oedConfig, oedUserClass, userInfo) {
    $state.go('signin');
  }
})();
