import * as angular from 'angular';
import * as _ from 'lodash';

import Promise = require('bluebird');
import {
  defaultChildQuestionList,
  isQuickWayToCreatePrepareCourse} from '../../prepareCourse/utils/prepareCourseUtils';
const boardUtils: any = require('app2/utils/boardUtils');
require('./newObjectiveComponent.scss')
const {confirmAndDownloadSubjectiveTest, importSubjectTestModel} = require('app2/utils/testUtils2')
const {doImportQuestions} = require('app2/utils/testUtils2')
const {getResourceUrlByUUID} = require('app2/utils/resourceUtils')
const {convertQuesToQaType} = require('app2/integration/questionUtils')
const moment = require('moment');
const autoScrollTo = require('app2/directives/dom/autoScrollTo.directive');
const testUtils = require('app2/utils/testUtils')

export const newObjectiveComponent = {
  bindings: {
    courseId: '<',
    currentTest: '<',
    cloudTests: '<'
  },
  controller: newObjectiveComponentCtrl,
  template: <string>require('./newObjectiveComponent.html'),
}

angular.module('app.newPrepareCourse2.newObjectiveComponent', [])
  .component('newObjectiveComponent', newObjectiveComponent);

newObjectiveComponentCtrl.$inject = [
  '$scope', '$state', '$stateParams', '$q', '$timeout', '$log',
  '$uibModal', '$http', 'notify', 'Upload', 'oedObjectiveTest', 'oedObjectiveQuestion',
  'oedUnitItem', 'oedTestUtils', 'oedCourseUtils', 'oedCloudTest', 'oedConfig',
  'oedCloudQuestion', 'talcloudResource', '$window', 'userInfo',
  'oedCourse', 'oedObjectiveTestStats', 'oedUserInfo', 'oedSubjects', 'oedMaterialVersion',
  'oedGrade', 'oedLevel', 'oedLevelGradeVersionSubjects', 'oedMisc', 'dialogs', 'localStorageService',
  'cloudQuestionService', 'notificationService', 'oedFileUtils', 'oedResource', '$interval','communicateService'
];
function newObjectiveComponentCtrl(
  $scope, $state, $stateParams, $q, $timeout, $log,
  $uibModal, $http, notify, Upload, oedObjectiveTest, oedObjectiveQuestion,
  oedUnitItem, oedTestUtils, oedCourseUtils, oedCloudTest, oedConfig,
  oedCloudQuestion, talcloudResource, $window, userInfo,
  oedCourse, oedObjectiveTestStats, oedUserInfo, oedSubjects, oedMaterialVersion,
  oedGrade, oedLevel, oedLevelGradeVersionSubjects, oedMisc, dialogs, localStorageService,
  cloudQuestionService, notificationService, oedFileUtils, oedResource, $interval,communicateService
) {
  const ctrl = this;
  ctrl.defaultFontSize = 12;
  ctrl.isFullScreen = false;
  ctrl.onClickedOutside = () => {
    console.log('Clicked outside:', event);
  }
  userInfo.then((info) => {
    ctrl.userInfo = info;
  });
  ctrl.enlargeFontSize = () => {
    if (ctrl.defaultFontSize < 30) {
      ctrl.defaultFontSize += 2;
      localStorageService.set('defaultSize_' + ctrl.userInfo.uid, ctrl.defaultFontSize);
    }
  }
  ctrl.narrowFontSize = () => {
    if (ctrl.defaultFontSize > 12) {
      ctrl.defaultFontSize -= 2;
      localStorageService.set('defaultSize_' + ctrl.userInfo.uid, ctrl.defaultFontSize);
    }
  }
  ctrl.setfullScreen = () => {
    ctrl.isFullScreen = !ctrl.isFullScreen;
    communicateService.sendExpandMessage({isFullScreen: ctrl.isFullScreen});
  }
  ctrl.downloadTest = (id) => {
    window.open(`/ray/resource/view/pageMarked/pdf/test/${id}/pdf/pageMarked`, '_blank');
  }
  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'courseId')) {
      ctrl.courseId = _.parseInt(ctrl.courseId);
      ctrl.course = oedCourse.get({
        id: $stateParams.courseId
      });
      ctrl.cloudQuestions = {};

      ctrl.courseSections = oedUnitItem.queryAvailableBySchoolAndCourse({
        schoolId: 0,
        courseId: ctrl.courseId
      });
    }
    if (_.has(changesObj, 'currentTest')) {
      ctrl.testId = ctrl.currentTest.id;
      if (ctrl.isQuickCreate()) {
        const elem = $('#quick-prepare-wrapper');
        window.document.documentElement.removeEventListener('click', captureDocumentClick, true);
        window.document.documentElement.addEventListener('click', captureDocumentClick, true);
        if (document.getElementById('quick-prepare-wrapper')) {
          document.getElementById('quick-prepare-wrapper').addEventListener('click', captureClicked, true);
        }
      }
      ctrl.currentTest = oedObjectiveTest.get({
        id: ctrl.testId
      });
      ctrl.isExitName = false;
      ctrl.nullName = false;
      if (ctrl.currentTest) {
        ctrl.getInfo();
      }
    }
    if (_.has(changesObj, 'cloudTests')) {
      ctrl.showShowCloudTestNo = () => {
        if (ctrl.cloudTests) {
          ctrl.currentCloudTestId =  _.get(ctrl.cloudTests[0], 'id');
        }
        return !ctrl.isEmptyResource(ctrl.currentTest) && _.isNumber(ctrl.currentCloudTestId);
      };
    }
  };
  $scope.$on('$destroy', () => {
    if (!ctrl.originalTest) {
      return;
    }
    //
    // 自动保存
    if (!angular.equals(ctrl.currentTest, ctrl.originalTest) && ctrl.isQuickCreate() && ctrl.inEditing) {
      const arr = _.map(ctrl.currentTest.questions[0].childQuestions, 'answer')
      if (_.indexOf(arr, '-1') > -1 || _.indexOf(arr, '') > -1) {
        return;
      } else {
        ctrl.saveTest();
      }
    }
    window.document.documentElement.removeEventListener('click', captureDocumentClick, true);
  });
  function captureClicked(evt) {
    if (!ctrl.inEditing) {
      ctrl.inEditing = true;
      window.document.documentElement.removeEventListener('click', captureDocumentClick, true);
      window.document.documentElement.addEventListener('click', captureDocumentClick, true);
    }
  }
  function captureDocumentClick(event) {
    const elem = $('#quick-prepare-wrapper');
    const isChild = elem.find(event.target).length > 0;
    if (isChild) {
      return;
    }
    if (!event.target) {
      return;
    }
    //
    // 当ueditor对话框打开时会有一个edui-mask的层
    // 当点击这个层的时候应当忽略
    const el = $(event.target);
    if (el.hasClass('edui-mask')) {
      return;
    }

    // 如果点击的是ueditor弹出的对话框中的按钮或提示信息按钮的话
    // 也应当忽略掉点击事件
    const cls = '.ueditor, .edui-dialog, .edui-shadow, .edui-default, .cg-notify-message, .oedSelectModal,' +
      '.cg-notify-message, .modal-dialog, .select-label2, .batch-delete, .delete-parent-question';
    const edDialog = el.closest(cls);
    if (edDialog.length > 0) {
      if (!edDialog.hasClass('modal-edit-test') && !el.parents().hasClass('oed-allow-click')) {
        return;
      }
    }
    if (!angular.equals(ctrl.currentTest, ctrl.originalTest)) {
      const arr = _.map(ctrl.currentTest.questions[0].childQuestions, 'answer')
      if (_.indexOf(arr, '-1') > -1 || _.indexOf(arr, '') > -1) {
        window.event.stopPropagation();
        window.event.preventDefault();
        return notificationService.notify('info', '请设置答案')
      } else {
        ctrl.saveTest();
      }
      ctrl.inEditing = false;
      if (document.getElementById('quick-prepare-wrapper')) {
        document.getElementById('quick-prepare-wrapper').removeEventListener('click', captureClicked, true);
        document.getElementById('quick-prepare-wrapper').addEventListener('click', captureClicked, true);
      }
      window.document.documentElement.removeEventListener('click', captureDocumentClick, true);
    }
  }

  ctrl.isEmptyResource = (test) => {
    if (!test) {
      return true;
    }
    return _.get(test, 'isEmpty', false) || _.isEmpty(_.get(test, 'questions'));
  };

  ctrl.childQuestionList = [];
  let tmpList = [];
  ctrl.choiceCategory = {0: 'A', 1: 'B', 2: 'C', 3: 'D', 4: 'E', 5: 'F', 6: 'G', 7: 'H', 8: 'I', 9: 'J'}
  ctrl.yesornoCategory = {0: '是', 1: '否'}
  ctrl.openMutualEvaluation = false;
  ctrl.mutualEvaluationMessage = '互评已关';
  ctrl.getInfo = () => {
    const cloudTests = oedCloudTest.queryByTestIds({
      testIds: [ctrl.testId]
    });
    ctrl.sectionIdMap = {};
    ctrl.courseSections.$promise.then((secs) => {
      const sectionIdMap = {};

      function visitSection(sec) {
        if (sec.id) {
          sectionIdMap[sec.id] = sec;
        }
        if (sec.child) {
          sec.child = _.sortBy(sec.child, ['sort']);
          _.each(sec.child, visitSection);
        }
      }
      _.each(secs, visitSection);

      ctrl.sectionIdMap = sectionIdMap;
      ctrl.treeData = oedMisc.utils.convertSectionsToJsTreeModel(sectionIdMap, secs);
      _.each(ctrl.treeData, (item) => {
        item.state.opened = true;
      });
    });
    ctrl.currentState = {
      loading: null
    };
    ctrl.currentState.loading = $q.all([ctrl.currentTest.$promise,
      ctrl.course.$promise,
      ctrl.courseSections.$promise,
      cloudTests.$promise
    ]);
    const loadTesDetails = loadTestDetail()
  }

  function loadTestDetail() {
    return ctrl.currentTest.$promise.then((test) => {
      ctrl.originalTest = angular.copy(test);
      if (isQuickWayToCreatePrepareCourse(test)) {
        ctrl.childQuestionList = test.questions[0].childQuestions;
        tmpList = _.cloneDeep(test.questions[0].childQuestions)
      }
      return loadTestCloudQuestions(test.questions)
    })
  }

  ctrl.isEmptyResource = (test) => {
    if (!test) {
      return true;
    }

    return _.get(test, 'isEmpty', false) || _.isEmpty(_.get(test, 'questions'));
  };

  ctrl.togglePrint = () => {
    if (!ctrl.currentTest) {
      return;
    }
    if (!angular.equals(ctrl.currentTest, ctrl.originalTest) || ctrl.isQuickCreate()) {
      ctrl.saveTest();
    }
    $window.open('/ray/api/a/course/export/' + ctrl.courseId + '/test/' + ctrl.currentTest.id, _.get($window, '_link_open_type'));
  };

  ctrl.toggleUpload = () => {
    if (!ctrl.currentTest) {
      return;
    }

    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/wordUploader.html'),
      controller: 'uploadResCtrl',
      size: 'md',
      windowClass: 'modalCenter',
      resolve: {
        resourceNeeded: () => {
          return {
            url: oedConfig.url('objectivetest/' + ctrl.currentTest.id + '/uploadresource'),
            showRules: () => {
              const modalInstance1 = $uibModal.open({
                template: require('assets/templates/prepareCourse/wordUploaderRulesDialog.html'),
                controller: 'wordUploaderRulesCtrl',
                size: 'md',
                resolve: {
                  test: () => {
                    return 'ab';
                  },
                  data: () => {
                    return {
                      isDaoxuean: false
                    }
                  }
                },
              });
              return modalInstance1.result;
            }
          };
        }
      }
    });
    modalInstance.result.then((r) => {
      const relSections = _.map(ctrl.course.relatedSections, (sec) => {
        return _.pick(sec, ['sectionId', 'sectionName']);
      });
      const initIdx = ctrl.currentTest.questions.length;
      if (r.respondData) {
        _.each(r.respondData, (docData) => {
          if (docData.questions && docData.questions.length > 0) {
            const startIdx = ctrl.currentTest.questions.length;

            _.each(docData.questions, (question) => {
              question.relatedSections = relSections;
            });
            ctrl.currentTest.questions = _.concat(ctrl.currentTest.questions, docData.questions);
          }
        });

        if (ctrl.currentTest.questions.length > initIdx) {
          ctrl.saveTest();
        }
      }
    });
  };

  ctrl.saveQuickPrepareCourseQuestion = (t, replace, needNotify) => {
    if (_.has(ctrl.currentTest.questions[0], 'id')) {
      const arr = _.map(ctrl.currentTest.questions[0].childQuestions, 'answer')
      if (_.indexOf(arr, '-1') > -1 || _.indexOf(arr, '') > -1) {
        // return;
        return notificationService.notify('info', '请设置答案')
      } else {
        return oedObjectiveQuestion.get({id: ctrl.currentTest.questions[0].id}).$promise.then((oldQuestion) => {
          const oldChildQuestions = oldQuestion.childQuestions;
          const newChildQuestions = ctrl.currentTest.questions[0].childQuestions;
          const needDeleted = _.filter(oldChildQuestions, (q: any) =>
            _.isEmpty(_.filter(newChildQuestions, (nq: any) => nq.id && nq.id === q.id))
          );

          const childs = _.map(needDeleted, (r: any) => {
            if (r.id) {
              return oedObjectiveQuestion.removeFromTest({
                testId: -1,
                questionId: r.id
              }).$promise
            }
          });

          if (!_.isEmpty(childs)) {
            return $q.all(childs)
          }
        }).then(() => {
          _.forEach(ctrl.currentTest.questions[0].childQuestions,(item, index) => {
            if(!item.question){
              item.question = `问题:${index + 1}`
            }
          })
          oedObjectiveQuestion.save(ctrl.currentTest.questions[0]).$promise.then((newQuestion) => {
            ctrl.currentTest.questions[0] = newQuestion
            return oedObjectiveTest.save(angular.copy(ctrl.currentTest)).$promise.then((res) => {
              if (res.questions && res.questions.length === 1) {
                ctrl.childQuestionList = res.questions[0].childQuestions
              }
              if (!_.isEqual(tmpList, res.questions[0].childQuestions) || needNotify) {
                notificationService.notify('info', '试卷保存成功')
                ctrl.originalTest = angular.copy(res);
              }
              if (t) {
                ctrl.stateGoPath(t, replace)
              }
            })
          })
        })
      }
    }
  }

  ctrl.setCurrentTest = (t, replace) => {
    if (ctrl.isQuickCreate()) {
      if (ctrl.childQuestionList.length === 0) {
        return notificationService.notify('info', '请设置答案后再保存')
      }
      return ctrl.saveQuickPrepareCourseQuestion(t, replace, false)
    }

    if (ctrl.isDone) {
      return;
    }

    if (_.get(ctrl.currentTest, 'id') === _.get(t, 'id')) {
      return;
    }
    ctrl.stateGoPath(t, replace)
  };

  ctrl.stateGoPath = (t, replace) => {
    const opt = replace ? {
      location: 'replace'
    } : null;

    $state.go('prepareCourse.objectiveTest.designTest2', {
      testId: t.id
    }, opt);
  }

  ctrl.saveTest = (noAlert) => {
    const testDisplayType = ctrl.currentTest.testDisplayType;
    if (ctrl.isQuickCreate()) {
      if (ctrl.childQuestionList.length > 0) {
        ctrl.saveQuickPrepareCourseQuestion('', '', true)
      } else {
        return notificationService.notify('info', '请设置答案后再保存')
      }
    } else {
      //
      // 点保存按钮时有可能要保存正在编辑的问题
      // 这种情况下应当等问题保存完毕后才保存整个试卷
      ctrl.currentState.loading = $q.when(ctrl.currentState.loading).then(() => {
        const hasNewQuestion = _.findIndex(ctrl.currentTest.questions, (q) => {
          return !_.get(q, 'id');
        }) >= 0;
        let save = null;
        //
        // 当有新问题时保存需要更新测试的Model(因为会同时创建问题)
        if (hasNewQuestion) {
          save = ctrl.currentTest.$save().then((t) => {
            return loadTestCloudQuestions(t.questions).then(() => t);
          });
        } else {
          save = oedObjectiveTest.save(angular.copy(ctrl.currentTest)).$promise;
        }
        return save.then((test: any) => {
          test.testDisplayType = testDisplayType;
          ctrl.currentTest = test;
          if (!noAlert && noAlert !== 'scroll') {
            notificationService.notify('info', '试卷保存成功')
          }
        }).catch((error) => {
          const errCode = _.get(error, 'status', 500);
          if (errCode === 401 || errCode === 403) {
            return notificationService.notify('info', '您的登录状态已改变，请重新登录!')
          }
          notificationService.notify('error', '试卷保存失败，请联系锐学堂技术支持!')
        });
      })
      if (noAlert === 'scroll') {
        return ctrl.currentState.loading;
      }
    }
  };

  ctrl.saveQuestion = (ques) => {
    if (ques.type !== 'synthetic' && ques.type !== 'fill') {
      localStorageService.set('lastQuestionPoint', ques.point2);
    } else {
      localStorageService.set('lastQuestionPoint', 1);
    }
    let p;
    if (_.has(ques, 'id')) {
      p = oedObjectiveQuestion.save(ques).$promise;
    } else {
      p = oedObjectiveQuestion.createForTest({
        question: ques,
        testId: ctrl.currentTest.id
      }).$promise.then((q) => {
        ctrl.childQuestionList = q.childQuestions
        return loadCloudQuestionByQId(q.id).then(() => q);
      });
    }

    ctrl.currentState.loading = p.then((q) => {
      let original = ques;
      if (_.has(ques, 'id')) {
        original = _.find(ctrl.currentTest.questions, (qu: any) => {
          return qu.id === ques.id;
        });
      }
      q.label = ques.label;
      if (_.has(ques, 'manualSort')) {
        q.manualSort = ques.manualSort;
      }
      const idx = _.findIndex(ctrl.currentTest.questions, original);
      ctrl.currentTest.questions[idx] = q;
      ctrl.currentPage = ctrl.getCurQuesPage(q);
      const curPageCurIdx = _.findIndex(ctrl.questionGroup[ctrl.currentPage], (qu: any) => {
        return q.id === qu.id;
      });
      ctrl.scrollAnchor = {
        relativeTo: '.ot-question-list',
        to: curPageCurIdx + 1,
        stamp: _.uniqueId('qscroll_')
      };
      notificationService.notify('info', '保存成功')
    }).catch(() => {
      notificationService.notify('error', '保存失败')
    });
    return p;
  };
  ctrl.deleteQuestion = (question, idx) => {
    //
    // 删除新问题
    if (!_.has(question, 'id')) {
      return;
    }
    ctrl.currentState.loading = oedObjectiveQuestion.removeFromTest({
      testId: ctrl.currentTest.id,
      questionId: question.id
    }).$promise.then((res: any) => {
      if (_.findIndex(ctrl.currentTest.questions, (q: any) => {
        return q.manualSort;
      }) !== -1) {
        _.forEach(ctrl.currentTest.questions, (item, index) => {
          if (item.manualSort) {
            ctrl.reSort(index + 1, item.manualSort);
          }
        });
      } else {
        _.forEach(ctrl.currentTest.questions, (item, index) => {
          item.label = index + 1;
        });
      }
      ctrl.saveTest('scroll').then(() => {
        if (!_.isEmpty(ctrl.currentTest.questions)) {
          const afterQues = ctrl.currentTest.questions[idx];
          ctrl.currentPage = ctrl.getCurQuesPage(afterQues);
          const curPageCurIdx = _.findIndex(ctrl.questionGroup[ctrl.currentPage], (q: any) => {
            return q.id === afterQues.id;
          });
          ctrl.scrollAnchor = {
            relativeTo: '.ot-question-list',
            to: curPageCurIdx,
            stamp: _.uniqueId('qscroll_')
          };
        }
      });
    });
  };
  // 批量删除
  ctrl.selectedQuestions = {};
  ctrl.toggleSelect = (item) => {
    if (_.has(ctrl.selectedQuestions, item.id)) {
      ctrl.selectedQuestions = _.omit(ctrl.selectedQuestions, item.id);
    } else {
      ctrl.selectedQuestions[item.id] = true;
    }
  };
  ctrl.isSelected = (item) => {
    return _.has(ctrl.selectedQuestions, item.id);
  };
  ctrl.hasSelected = () => {
    return !_.isEmpty(ctrl.selectedQuestions);
  };
  ctrl.deleteSelected = () => {
    const message = '确定要删除客观题吗（共' + _.size(ctrl.selectedQuestions) + '题）？';
    const dlg = dialogs.confirm('确定删除?', message);
    dlg.result.then(() => {
      ctrl.currentTest.questions = _.filter(ctrl.currentTest.questions, (q) => {
        return !ctrl.isSelected(q);
      });
      ctrl.saveTest();
      ctrl.selectedQuestions = {};
    });
  };
  ctrl.errorForEmpty = false;
  ctrl.onEnterEditQuestion = (question, event) => {
    ctrl.currentQuestion = angular.copy(question);
    ctrl.editingQuestion = question;
  };
  const expTable = new RegExp('<table>');
  const regExpBr = /^(<p><br\/><\/p>)+|(<p><br\/><\/p>)+$/g;
  ctrl.eachTrimEnter = (x) => {
    if (regExpBr.test(x) && x !== '<p><br/></p>') {
      return x.replace(regExpBr, '');
    } else {
      return x;
    }
  };
  ctrl.onLeaveEditQuestion = (question, event) => {
    const idx = _.findIndex(ctrl.currentTest.questions, question);
    ctrl.errorAnchor = (idxx: any) => {
      ctrl.scrollAnchor = {
        relativeTo: '.ot-question-list',
        to: idxx - ctrl.getCurPageFirstQuesIdx(),
        stamp: _.uniqueId('qscroll_')
      };
    };
    let ret = oedTestUtils.validateQuestion(question);
    if (question.type === 'synthetic') {
      const syntheticTable = [];
      const rets = _.map(question.childQuestions, (q) => {
        const tmpQ = angular.copy(q);
        syntheticTable.push(tmpQ);
        tmpQ.isSub = true;
        const tmp = oedTestUtils.validateQuestion(tmpQ);
        return tmp.valid ? null : tmp;
      });
      const result = _.find(rets, (r) => {
        return r !== null && !r.valid;
      });
      if (!_.isUndefined(result)) {
        ret = result;
      }
      if (_.size(question.childQuestions) === 0) {
        ret = {
          valid: false,
          message: '当前综合题没有子题， 请设置子题后再保存'
        };
      }
      // 综合题子题题干不能插入表格
      const synTableQues = _.find(syntheticTable, (c) => expTable.test(c.question));
      if (synTableQues !== undefined) {
        ctrl.errorForEmpty = true;
        notificationService.notify('error', '综合题子题题干暂不支持插入表格')
        ctrl.errorAnchor(idx);
        return false;
      }
      const synAnswerDetails = _.find(syntheticTable, (c) => expTable.test(c.answerDetails));
      if (synAnswerDetails !== undefined) {
        ctrl.errorForEmpty = true;
        notificationService.notify('error', '综合题子题解析暂不支持插入表格')
        ctrl.errorAnchor(idx);
        return false;
      }
      // 综合题子题选项
      const syntheticChoices = [];
      const synChoicesContent = _.find(syntheticTable, (x) => {
        if (x.type === 'singlechoice' || x.type === 'multichoice' ||
          x.type === 'singlevote' || x.type === 'multivote') {
          _.each(x.choices, (c) => {
            syntheticChoices.push(c.content);
          });
        }
        return expTable.test(_.join(syntheticChoices, ''));
      });
      if (synChoicesContent !== undefined) {
        ctrl.errorForEmpty = true;
        notificationService.notify('error', '综合题子题选项中暂不支持插入表格')
        ctrl.errorAnchor(idx);
        return false;
      }
      // 综合题leftOpts、rightOpts
      const syntheticLeftOpts = [];
      const syntheticRightOpts = [];
      const synLeftOpts = _.find(syntheticTable, (x) => {
        if (x.type === 'connect') {
          _.each(x.leftOpts, (c) => {
            syntheticLeftOpts.push(c.content);
          });
        }
        return expTable.test(_.join(syntheticLeftOpts, ''));
      });
      const synRightOpts = _.find(syntheticTable, (x) => {
        if (x.type === 'connect') {
          _.each(x.rightOpts, (c) => {
            syntheticRightOpts.push(c.content);
          });
        }
        return expTable.test(_.join(syntheticRightOpts, ''));
      });
      if (synLeftOpts !== undefined || synRightOpts !== undefined) {
        ctrl.errorForEmpty = true;
        notificationService.notify('error', '综合题匹配题选项中暂不支持插入表格')
        ctrl.errorAnchor(idx);
        return false;
      }
      // 综合题子题题干、解析、选项去除多余回车
      _.forEach(question.childQuestions, (c) => {
        c.question = ctrl.eachTrimEnter(c.question);
        c.answerDetails = ctrl.eachTrimEnter(c.answerDetails);
        if (c.type === 'singlechoice' || c.type === 'multichoice' ||
          c.type === 'singlevote' || c.type === 'multivote') {
          _.forEach(c.choices, (opt) => {
            opt.content = ctrl.eachTrimEnter(opt.content);
          });
        }
        if (c.type === 'connect') {
          _.forEach(c.leftOpts, (opt) => {
            opt.content = ctrl.eachTrimEnter(opt.content);
          });
          _.forEach(c.rightOpts, (opt) => {
            opt.content = ctrl.eachTrimEnter(opt.content);
          });
        }
      });
    }
    if (ret && !ret.valid) {
      ctrl.errorForEmpty = true;
      notificationService.notify('error', ret.message)
      ctrl.errorAnchor(idx);
      // ctrl.$apply();
      return false;
    } else {
      if (expTable.test(question.question)) {
        ctrl.errorForEmpty = true;
        notificationService.notify('error', '题干中暂不支持插入表格')
        ctrl.errorAnchor(idx);
        return false;
      }
      if (expTable.test(question.answerDetails)) {
        ctrl.errorForEmpty = true;
        notificationService.notify('error', '解析中暂不支持插入表格')
        ctrl.errorAnchor(idx);
        return false;
      }
      // 去除题干和解析中多余的回车
      question.question = ctrl.eachTrimEnter(question.question);
      question.answerDetails = ctrl.eachTrimEnter(question.answerDetails);
      // 单选、多选、投票中的choices出入表格的处理
      if (question.type === 'singlechoice' || question.type === 'multichoice' ||
        question.type === 'singlevote' || question.type === 'multivote') {
        const tableQues = _.find(question.choices, (c) => expTable.test(c.content));
        if (tableQues !== undefined) {
          ctrl.errorForEmpty = true;
          notificationService.notify('error', '选项中暂不支持插入表格')
          ctrl.errorAnchor(idx);
          return false;
        }
        // 去除选择题选项中多余的回车
        _.forEach(question.choices, (c) => {
          c.content = ctrl.eachTrimEnter(c.content);
        });
      }
      // 当表格的leftOpts或rightOpts中粘贴了表格时候的处理
      if (question.type === 'connect') {
        const connectLeftOpts = _.find(question.leftOpts, (c) => expTable.test(c.content));
        const connectRightOpts = _.find(question.rightOpts, (c) => expTable.test(c.content));
        if (connectLeftOpts !== undefined || connectRightOpts !== undefined) {
          ctrl.errorForEmpty = true;
          notificationService.notify('error', '匹配题中暂不支持插入表格')
          ctrl.errorAnchor(idx);
          return false;
        }
        // 去除leftOpt和rightOpt多余的回车
        _.forEach(question.leftOpts, (c) => {
          c.content = ctrl.eachTrimEnter(c.content);
        });
        _.forEach(question.rightOpts, (c) => {
          c.content = ctrl.eachTrimEnter(c.content);
        });
      }
    }
    //
    // 当前问题已经修改时要保存测试
    if (!angular.equals(ctrl.currentQuestion, question)) {
      ctrl.saveQuestion(question);
    }

    ctrl.currentQuestion = null;
    ctrl.editingQuestion = null;

    return true;
  };

  ctrl.backCurrentQuestion = () => {
    if (ctrl.errorForEmpty) {
      ctrl.errorForEmpty = false;
    }
  };

  ctrl.onDeleteQuestionClicked = (question) => {
    const dlg = dialogs.confirm('确定删除？', '确定删除此题吗？');
    dlg.result.then((btn) => {
      const idx = _.findIndex(ctrl.currentTest.questions, question);
      const delIdx = idx === _.size(ctrl.currentTest.questions) - 1 ? idx - 1 : idx;
      ctrl.currentTest.questions.splice(idx, 1);
      // question.manualSort = 0;
      ctrl.deleteQuestion(question, delIdx);
      if (question === ctrl.currentQuestion) {
        ctrl.currentQuestion = null;
      }
      if (ctrl.isSelected(question)) {
        ctrl.selectedQuestions = _.omit(ctrl.selectedQuestions, question.id);
      }

      // 如果删除的题目是最后一题，且该题为最后一页的唯一一道题，则切到前一页
      ctrl.isLastOneQuestion(false, question);
    }, (btn) => {});
  };

  ctrl.isLastOneQuestion = (isSynthetic, question) => {
    let curPageCurIdx = 0;
    if (isSynthetic) {
      setTimeout(() => {
        const curSynPage = ctrl.getCurQuesPage(question)
        if (curSynPage < ctrl.currentPage) {
          ctrl.switchToPrevPage();
          curPageCurIdx = _.size(ctrl.questionGroup[ctrl.currentPage]);
          ctrl.scrollAnchor = {
            relativeTo: '.ot-question-list',
            to: curPageCurIdx,
            stamp: _.uniqueId('qscroll_')
          };
        }
      }, 50);
    } else {
      const lastPageSize = _.size(ctrl.questionGroup[_.size(ctrl.questionGroup) - 1]);
      if (lastPageSize === 1 && ctrl.currentPage === _.size(ctrl.questionGroup) - 1) {
        ctrl.switchToPrevPage();
        curPageCurIdx = _.size(ctrl.questionGroup[ctrl.currentPage]) - 1;
        ctrl.scrollAnchor = {
          relativeTo: '.ot-question-list',
          to: curPageCurIdx,
          stamp: _.uniqueId('qscroll_')
        };
      }
    }
  }

  ctrl.canMoveUp = (question: any) => {
    // 刚刚添加，尚未保存的题目，禁止上移
    if (!_.has(question, 'id')) {
      return false;
    }
    const idx = _.findIndex(ctrl.currentTest.questions, (q: any) => {
      return q.id === question.id;
    });
    return idx > 0;
  };

  ctrl.canMoveDown = (question) => {
    const idx = _.findIndex(ctrl.currentTest.questions, (q: any) => {
      return q.id === question.id;
    });
    // 下面的一题不能是刚刚添加且尚未保存的题目
    return idx >= 0 && idx < (_.size(ctrl.currentTest.questions) - 1) &&
      _.has(_.get(ctrl.currentTest.questions, idx + 1, {}), 'id');
  };

  ctrl.onMoveUpClicked = (question) => {
    const curIdx = _.findIndex(ctrl.currentTest.questions, (q: any) => {
      return q.id === question.id;
    });
    if (curIdx <= 0) {
      return;
    }

    const temp = ctrl.currentTest.questions[curIdx - 1];
    ctrl.currentTest.questions[curIdx - 1] = question;
    ctrl.currentTest.questions[curIdx] = temp;

    if (curIdx === ctrl.getCurPageFirstQuesIdx()) {
      ctrl.switchToPrevPage();
    }
    ctrl.saveTest('scroll').then(() => {
      ctrl.currentPage = ctrl.getCurQuesPage(question);
      const curPageCurIdx = _.findIndex(ctrl.questionGroup[ctrl.currentPage], (q: any) => {
        return q.id === question.id;
      });
      ctrl.scrollAnchor = {
        relativeTo: '.ot-question-list',
        to: curPageCurIdx,
        stamp: _.uniqueId('qscroll_')
      };
    });

  };
  ctrl.onMovePlaceClicked = (question, placeIndex) => {
    const curIdx = _.findIndex(ctrl.currentTest.questions, (q: any) => {
      return q.id === question.id;
    });

    if (curIdx < 0 || placeIndex === curIdx) {
      return;
    }
    const temp = ctrl.currentTest.questions[placeIndex];
    let durCount;
    if (placeIndex > curIdx) {
      durCount = placeIndex - curIdx;
      for (let i = 0; i < durCount; i++) {
        ctrl.currentTest.questions[curIdx + i] = ctrl.currentTest.questions[curIdx + i + 1];
      }
      ctrl.currentTest.questions[curIdx + durCount - 1] = temp;
      ctrl.currentTest.questions[placeIndex] = question;
    } else {
      durCount = curIdx - placeIndex;
      for (let i = 0; i < durCount - 1; i++) {
        ctrl.currentTest.questions[curIdx - i] = ctrl.currentTest.questions[curIdx - i - 1];
      }
      ctrl.currentTest.questions[curIdx - durCount + 1] = temp;
      ctrl.currentTest.questions[placeIndex] = question;
    }
    ctrl.saveTest('scroll').then(() => {
      ctrl.currentPage = ctrl.getCurQuesPage(question);
      const curPageCurIdx = _.findIndex(ctrl.questionGroup[ctrl.currentPage], (q: any) => {
        return q.id === question.id;
      });
      ctrl.scrollAnchor = {
        relativeTo: '.ot-question-list',
        to: curPageCurIdx,
        stamp: _.uniqueId('qscroll_')
      };
    });
  };

  ctrl.onMoveDownClicked = (question) => {
    const curIdx = _.findIndex(ctrl.currentTest.questions, (q: any) => {
      return q.id === question.id;
    });

    if (curIdx < 0 || curIdx >= (_.size(ctrl.currentTest.questions) - 1)) {
      return;
    }

    const temp = ctrl.currentTest.questions[curIdx + 1];
    ctrl.currentTest.questions[curIdx + 1] = question;
    ctrl.currentTest.questions[curIdx] = temp;

    if (curIdx === _.findIndex(ctrl.currentTest.questions, (q: any) => {
      return q.id === ctrl.getCurPageLastQues().id;
    })) {
      ctrl.switchToNextPage();
    }

    ctrl.saveTest('scroll').then(() => {
      ctrl.currentPage = ctrl.getCurQuesPage(question);
      const curPageCurIdx = _.findIndex(ctrl.questionGroup[ctrl.currentPage], (q: any) => {
        return q.id === question.id;
      });
      ctrl.scrollAnchor = {
        relativeTo: '.ot-question-list',
        to: curPageCurIdx,
        stamp: _.uniqueId('qscroll_')
      };
    });
  };
  // 客观试卷题号
  ctrl.onEditQuestionNumber = (ques) => {
    const dlg = $uibModal.open({
      template: require('app2/directives/question/editQuestionNumber.html'),
      controller: 'editQuestionNumberCtrl',
      size: 'sm',
      windowClass: 'editQuestionNumber',
      resolve: {
        data: () => {
          return {
            question: ques,
            testId: ctrl.testId,
          };
        }
      }
    });

    dlg.result.then((res) => {
      if (res.manualSort) {
        const currentIndex = _.findIndex(ctrl.currentTest.questions, (q: any) => {
          return q.id === ques.id;
        });
        ctrl.currentTest.questions[currentIndex].manualSort = _.parseInt(res.manualSort);
        ctrl.currentTest.questions[currentIndex].label = _.parseInt(res.manualSort);
      } else {
        const currentIndex = _.findIndex(ctrl.currentTest.questions, (q: any) => {
          return q.id === ques.id;
        });
        ctrl.currentTest.questions[currentIndex].manualSort = 0;
        ctrl.currentTest.questions[currentIndex].label = currentIndex > 0 ?
          ctrl.currentTest.questions[currentIndex - 1].label + 1 : 1;
        ctrl.reSort(currentIndex + 1, ques.label);
      }

      if (res.type === 'new') {
        return;
      }
      _.forEach(ctrl.currentTest.questions, (item: any, index) => {
        if (item.manualSort) {
          ctrl.reSort(index + 1, item.manualSort);
        }
      });
    });
  };

  ctrl.reSort = (index, sort) => {
    const arr = ctrl.currentTest.questions.slice(index);
    if (_.size(arr)) {
      _.forEach(arr, (item, i) => {
        if (!item.manualSort) {
          item.label = sort + i + 1;
        }
      });
    }
  }

  ctrl.getCurPageFirstQuesIdx = () => {
    return _.findIndex(ctrl.currentTest.questions, (q: any) => {
      return q.id === ctrl.getCurPageFirstQues().id;
    })
  }

  ctrl.getCurPageFirstQues = () => {
    return ctrl.questionGroup[ctrl.currentPage][0];
  }

  ctrl.getCurPageLastQues = () => {
    const curPageLastQuesIdx = _.size(ctrl.questionGroup[ctrl.currentPage]) - 1;
    const curPageLastQues = ctrl.questionGroup[ctrl.currentPage][curPageLastQuesIdx];
    return curPageLastQues;
  }

  ctrl.getAllQuestionSize = () => {
    let questionSize = 0;
    _.forEach(ctrl.currentTest.questions, (question) => {
      if (_.size(question.childQuestions) > 0) {
        questionSize += question.childQuestions.length;
      } else {
        questionSize ++;
      }
    })
    return questionSize;
  }

  ctrl.pageSize = 30;
  ctrl.questionGroup = [];
  ctrl.currentPage = 0;

  ctrl.getQuestionGroup = () => {
    let index = 0;
    let length = 0;
    const groupList = [];
    let itemList = [];
    const allQuestionLength = ctrl.getAllQuestionSize();

    _.forEach(ctrl.currentTest.questions, (question) => {
      if (_.size(question.childQuestions) > 0) {
        if (_.size(question.childQuestions) >= ctrl.pageSize) {
          if (itemList.length > 0) {
            groupList.push(itemList);
            itemList = [];
            itemList.push(question);
            groupList.push(itemList);
            itemList = [];
            index = 0;
            length += question.childQuestions.length;
          } else {
            itemList.push(question);
            groupList.push(itemList);
            itemList = [];
            index = 0;
            length += question.childQuestions.length;
          }
        } else {
          index += question.childQuestions.length;
          length += question.childQuestions.length;
          if (index > ctrl.pageSize) {
            groupList.push(itemList);
            itemList = [];
            index = 0;
            itemList.push(question);
            index += question.childQuestions.length;
          } else {
            itemList.push(question);
          }
        }
      } else {
        itemList.push(question);
        index ++;
        length ++;
      }

      if (index === ctrl.pageSize) {
        groupList.push(itemList);
        itemList = [];
        index = 0;
      } else if (length === allQuestionLength) {
        if (itemList.length > 0) {
          groupList.push(itemList);
        }
      }
    })
    ctrl.questionGroup = groupList;
    return groupList;
  }

  ctrl.showPaging = () => {
    return _.size(ctrl.questionGroup) > 1;
  };

  ctrl.showAddQues = () => {
    return ctrl.currentPage === (_.size(ctrl.questionGroup) - 1)
  };

  ctrl.isPrevPageBtnEnabled = () => {
    return ctrl.currentPage > 0;
  };

  ctrl.isNextPageBtnEnabled = () => {
    return ctrl.currentPage < _.size(ctrl.questionGroup) - 1;
  };

  ctrl.getPrevPageBtnClass = () => {
    return ctrl.isPrevPageBtnEnabled() ?
      'cursor-pointer ot-page-navigator-btn-enabled pre-hover' :
      'ot-page-navigator-btn-disabled';
  };

  ctrl.getNextPageBtnClass = () => {
    return ctrl.isNextPageBtnEnabled() ?
      'cursor-pointer ot-page-navigator-btn-enabled next-hover' :
      'ot-page-navigator-btn-disabled';
  };

  ctrl.switchToPrevPage = () => {
    if (!ctrl.isPrevPageBtnEnabled()) {
      return;
    }
    ctrl.currentPage --;
  };

  ctrl.switchToNextPage = () => {
    if (!ctrl.isNextPageBtnEnabled()) {
      return;
    }
    ctrl.currentPage ++;
  };

  ctrl.switchToLastPage = () => {
    ctrl.currentPage = (_.size(ctrl.questionGroup) - 1)
  };

  ctrl.getQuesIndex = (question) => {
    return question.label;
    // return _.findIndex(ctrl.currentTest.questions, function(q) { return q.id === question.id;});
  };

  ctrl.isNewQuestion = (question) => {
    return !_.get(question, 'id');
  };

  ctrl.addQuestion = () => {
    const newQ = oedCourseUtils.defaultQuestion(ctrl.course.relatedSections);
    let maxSort = _.get(_.maxBy(ctrl.currentTest.questions, 'sort'), 'sort');
    if (_.isUndefined(maxSort) || _.isNaN(maxSort) || !_.isNumber(maxSort)) {
      maxSort = 0;
    }

    maxSort++;
    if (!_.size(ctrl.currentTest.questions)) {
      newQ.label = 1;
    } else {
      newQ.label = ctrl.currentTest.questions[_.size(ctrl.currentTest.questions) - 1].label + 1;
    }
    newQ.sort = _.max([maxSort, _.size(ctrl.currentTest.questions)]);
    ctrl.editingQuestion = newQ;
    ctrl.currentTest.questions.push(newQ);
    // 跳转到新增题目
    ctrl.scrollToNewQuestion();
  }

  ctrl.scrollToNewQuestion = () => {
    ctrl.getQuestionGroup();
    ctrl.switchToLastPage();
    const curPageCurIdx = _.size(ctrl.questionGroup[ctrl.currentPage]);
    ctrl.scrollAnchor = {
      relativeTo: '.ot-question-list',
      to: curPageCurIdx  - 1,
      stamp: _.uniqueId('qscroll_')
    };
  }

  ctrl.addChildQues = (question) => {
    const curSynPage = ctrl.getCurQuesPage(question);
    if (curSynPage > ctrl.currentPage) {
      ctrl.switchToNextPage();
    }
  }

  ctrl.getCurQuesPage = (question) => {
    ctrl.getQuestionGroup();
    const curSynPage = _.findIndex(ctrl.questionGroup, (group: any) => {
      return _.findIndex(group, (ques: any) => {
        return ques.id === question.id;
      }) > -1;
    })
    return curSynPage;
  }

  ctrl.editTest = ($event, test) => {
    $event.stopPropagation();
    $event.preventDefault();
    const isCurrent = test.id === ctrl.currentTest.id;
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/editTestDialog.html'),
      controller: 'editTestCtrl',
      size: 'sm',
      windowClass: 'modalCenter',
      resolve: {
        test: () => {
          return test;
        },
        existingTests: () => {
          return ctrl.tests;
        },
        isPrepareLesson: false
      }
    });

    if (isCurrent) {
      modalInstance.result.then(() => {
        ctrl.currentTest.name = test.name;
      });
    }
  };

  const showCannotSortMessage = _.debounce(() => {
    notificationService.notify('info', '当前编辑的问题无效, 请编辑后再排序')
  }, 500);

  function getQuestionIds(questions) {
    return _.map(questions, (q) => {
      return _.get(q, 'id', -1);
    });
  }

  ctrl.isAllQuestionValid = (questions) => {
    const inValid = _.findIndex(questions, (ques: any) => {
      if (ques.type === 'synthetic') {
        return ctrl.isAllQuestionValid(ques.childQuestions);
      }
      return !oedTestUtils.validateQuestion(ques).valid;
    });
    return inValid < 0;
  };

  const computeQuestionHash = oedTestUtils.computeQuestionHash;

  function downloadTestsById(cloudTestIds) {
    if (_.isEmpty(cloudTestIds)) {
      return Promise.resolve()
    }
    return $q.all(_.map(cloudTestIds, (tId) => {
      return oedObjectiveTest.getDownloadByCloudRes({
        res_id: tId
      }).$promise;
    }));
  }

  function importQuestions(questions, deferred) {
    return doImportQuestions(questions, deferred, {
      notify,
      oedCloudQuestion,
      talcloudResource,
      oedObjectiveQuestion,
      oedObjectiveTest,
      userInfo,
      cloudQuestionService,
      questionOp: {
        get: () => ctrl.currentTest.questions,
        set: (qs) => ctrl.currentTest.questions = qs
      },
      relatedSections: ctrl.course.relatedSections,
      state: ctrl.currentState,
      notificationService,
    }).then((quesToAdd: any) => {
      if (!_.isEmpty(quesToAdd)) {
        ctrl.currentTest.isSameAsOrig = false;
        ctrl.scrollToNewQuestion();
      }
      return ctrl.saveTest(true);
    }).then((r: any) => deferred.resolve(r))
      .catch((e: any) => deferred.reject(e))
  }

  ctrl.importFromLibrary2 = () => {
    // $scope.$suspend()
    const modalInstance = $uibModal.open({
      template: require('../../prepareCourse/cloud/objectiveTestLibrary.html'),
      controller: 'objectiveTestLibraryCtrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2 ',
      resolve: {
        data: () => ({
          course: ctrl.course,
          curIsBlankTest: ctrl.blankTest(),
          doImport: importQuestions,
          importQuickTest: importQuickTests,
          curIsQuickTest: curIsQuickTests
        })
      },
    })

    modalInstance.result.finally(() => {
      // $scope.$resume()
    })
  }

  ctrl.switchMode = (m) => {
    if (m === 'question') {
      return $q.when(ctrl.currentState.loading).then(() => {
        oedTestUtils.switchEditMode(ctrl.testId, m);
      });
    }

    return true;
  };

  ctrl.showQuesitonError = (q) => {
    return ctrl.errorForEmpty && ctrl.editingQuestion === q;
  };

  function calcNextMaxSort() {
    ctrl.maxSort = _.max(_.map(ctrl.tests, 'sort'));
    if (isNaN(ctrl.maxSort)) {
      ctrl.maxSort = 1;
    } else {
      ctrl.maxSort = ctrl.maxSort + 1;
    }
  }

  function curIsQuickTests() {
    return isQuickWayToCreatePrepareCourse(ctrl.currentTest)
  }

  function importQuickTests(res, deferred) {
    ctrl.currentState.loading = deferred.promise
    const test = res.test
    if (!isQuickWayToCreatePrepareCourse(test) || !ctrl.blankTest(ctrl.currentTest)) {
      deferred.reject()
      return
    }

    const q = _.assign({}, test.questions[0])
    q.id = null
    return oedObjectiveQuestion.createForTest({
      question: q,
      testId: ctrl.currentTest.id
    }).$promise.then(() => {
      ctrl.currentTest = oedObjectiveTest.get({
        id: ctrl.currentTest.id
      });

      return loadTestDetail()
    }).then(() => deferred.resolve())
      .catch((e: any) => deferred.reject(e));
  }

  function loadTestCloudQuestions(questions) {
    const quesIds = _(questions).map('id').filter().value();
    ctrl.cloudQuestions = {};

    if (_.isEmpty(quesIds)) {
      return $q.resolve({});
    }

    if (questions &&
      questions.length === 1 &&
      questions[0].type === 'synthetic' &&
      _.isEmpty(questions[0]['rawBody']['resourceUUIDs']) &&
      questions[0]['resourceUUIDs']
    ) {
      ctrl.imageRes = _.uniq(questions[0]['resourceUUIDs'])
    }

    return oedCloudQuestion.queryByQuestionIds({
      ids: quesIds
    }).$promise.then((cqs) => {
      ctrl.cloudQuestions = _.keyBy(cqs, 'resourceId');
      return ctrl.cloudQuestions;
    });
  }

  function loadCloudQuestionByQId(qId) {
    return oedCloudQuestion.getByQuestionId({
      id: qId
    }).$promise.then((cq) => {
      ctrl.cloudQuestions[cq.resourceId] = cq;
    });
  }

  // TODO: convert types to an array to keep it's order
  ctrl.types = {
    singlechoice: '单选',
    multichoice: '多选',
    yesorno: '判断',
    fill: '填空',
    connect: '匹配',
    vote: '投票',
    synthetic: '综合',
    qa: '问答'
  };

  ctrl.getCountOfType = (type) => {
    if (!ctrl.currentTest) {
      return 0;
    }

    if (type === 'vote') {
      const voteSize = _.sumBy(ctrl.currentTest.questions, (q: any) => {
        return (q.type === 'singlevote' || q.type === 'multivote') ? 1 : 0;
      });

      return voteSize;
    } else {
      return _.size(_.filter(ctrl.currentTest.questions, (q: any) => {
        return q.type === type;
      }));
    }
  };

  ctrl.getScoreOfType = (type) => {
    if (!ctrl.currentTest) {
      return 0;
    }

    if (type === 'vote') {
      const singlevoteScore =  _.reduce(_.filter(ctrl.currentTest.questions, (q: any) => {
        return q.type === 'singlevote';
      }), (acc, q: any) => {
        return acc + (q.point2 || 0);
      }, 0);

      const multivoteScore =  _.reduce(_.filter(ctrl.currentTest.questions, (q: any) => {
        return q.type === 'multivote';
      }), (acc, q: any) => {
        return acc + (q.point2 || 0);
      }, 0);

      return singlevoteScore + multivoteScore;
    } else {
      return _.reduce(_.filter(ctrl.currentTest.questions, (q: any) => {
        return q.type === type;
      }), (acc, q: any) => {
        return acc + (q.point2 || 0);
      }, 0);
    }
  };

  ctrl.getTotalScore = () => {
    if (!ctrl.currentTest) {
      return 0;
    }
    return _.reduce(ctrl.currentTest.questions, (acc, q: any) => {
      return acc + (q.point2 || 0);
    }, 0);
  };

  ctrl.getTotalCount = () => {
    if (!ctrl.currentTest) {
      return 0;
    }
    return _.size(ctrl.currentTest.questions);
  };

  ctrl.toggleSt = () => {
    if (ctrl.stShow) {
      ctrl.stShow = false;
    } else {
      ctrl.stShow = true;
    }
  };

  ctrl.getStWidth = () => {
    return {
      width: ctrl.stShow
    };
  }

  ctrl.sortableOptions = {
    axis: 'y',
    stop: ($event) => {
      const newOrders = _.map(ctrl.tests, (m: any, index) => {
        return {
          id: m.id,
          sort: index
        };
      });
      ctrl.loading = oedCourse.updateTestOrder(newOrders).$promise.then().catch(() => {
        notificationService.notify('error', '保存失败')
      });
    }
  };
  ctrl.addGame = () => {
    if (_.isEmpty(ctrl.currentTest.questions)) {
      return notificationService.notify('info', '请先添加题目')
    }
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addTestPaperGame.html'),
      controller: 'addTestPaperGameCtrl',
      size: 'md',
      windowClass: 'oedmodal',
      resolve: {
        curTest: () => {
          return ctrl.currentTest;
        }
      }
    });

    return modalInstance.result.then((r) => {
      if (!_.isEmpty(r)) {
        _.set(ctrl.currentTest, 'writingPadBody', _.get(r, 'writingPadBody'));
        ctrl.saveTest();
      }
    });
  };
  ctrl.uploadFileTestPaper = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    // let isCurrent = test.id == ctrl.currentTest.id;
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addTestPaperUnderPic.html'),
      controller: 'addTestPaperUnderPicCtrl',
      size: 'md',
      windowClass: 'oedmodal',
      resolve: {
        curTest: () => {
          return ctrl.currentTest;
        }
      }
    });

    return modalInstance.result.then((r) => {
      if (!_.isEmpty(r)) {
        _.set(ctrl.currentTest, 'writingPadBody', _.get(r, 'writingPadBody'));
        ctrl.saveTest();
      }
    });
  };

  ctrl.convertAllQuesToQaType = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();

    ctrl.loading = Promise.resolve(doConvert())
      .then(ctrl.saveTest())
  }

  ctrl.isQuickCreate = () => {
    return isQuickWayToCreatePrepareCourse(ctrl.currentTest)
  }

  ctrl.blankTest = () => {
    return _.isEmpty(ctrl.currentTest.questions)
  }

  ctrl.resLibDisabled = () => {
    // 非空试卷，并且是答题卡试卷，禁止点击
    return  !ctrl.blankTest() && ctrl.isQuickCreate()
  }
  function doConvert() {
    ctrl.currentTest.questions = _.map(ctrl.currentTest.questions, convertQuesToQaType)
  }

  function convertAndCreatTest(testId, nextStep, cancelInstance) {
    ctrl.loading = oedResource.pdf2jpg({id: testId}, {}).$promise.then((imageReses) => {
      if (!nextStep) {
        return
      }

      if (imageReses && imageReses.length > 0) {
        // create test
        ctrl.progressMsg = '正在生成试卷…（4/4）'
        return quickCreateTest(imageReses).then(() => {
          cancelInstance.close()
        })
      } else {
        cancelInstance.close()
        notificationService.notify('error', '文件转码失败，请检查文件格式')
      }
    })
  }

  ctrl.uploadCreateTest = (uploadFile) => {
    ctrl.testFile = null
    validateWordFileType(uploadFile)

    if (!_.isEmpty(uploadFile.validateResults)) {
      notificationService.notify(uploadFile.validateResults[0].level,
        uploadFile.validateResults[0].message, '', 5 * 1000);
      return
    }

    let nextStep = true
    let cancelInstance = null
    // create resource
    return $q((resolve, reject) => {
      cancelInstance = $uibModal.open({
        template: require('app2/prepareCourse/promptDialog/promptDialog.html'),
        controller: 'promptDialogCtrl',
        size: 'qp-edit',
        windowClass: 'shouldHideOnPreview cl-library-dialog2',
        scope: ctrl,
        resolve: {
          data: (() => {})
        }
      })
      cancelInstance.result.then(() => {
        nextStep = false
      })

      ctrl.progressMsg = '正在上传文件... (1/4)'
      Upload.upload({
        url: oedConfig.url_b('resource/create'),
        file: uploadFile
      }).progress((evt) => {
      }).success((datas, status, headers, config) => {
        resolve(datas);
      }).error(() => {
        resolve(null);
      })
    }).then((res) => {
      if (!nextStep) {
        return
      }

      ctrl.progressMsg = '正在转码... (2/4)'
      if (res.suffix === 'pdf') {
        return convertAndCreatTest(res.id, nextStep, cancelInstance)
      } else {
        let cancel = false
        const ret = $interval(() => {
          const url = '/ray/api/b/resource/' + res.id
          if (!nextStep) {
            return
          }
          return $http.get(url).then((res1) => {
            if (res1.data.transFlag !== 1) {
              return
            }
            if (!cancel) {
              $interval.cancel(ret)
              cancel = true
              if (!nextStep) {
                return
              }
              let pdf2jpgSuccess = false
              let retry = 3
              ctrl.progressMsg = '正在生成图片…（3/4）'
              const successCall = (imgReses) => {
                pdf2jpgSuccess = true
                if (!nextStep) {
                  return
                }
                if (imgReses) {
                  // create test
                  ctrl.progressMsg = '正在生成试卷…（4/4）'
                  return quickCreateTest(imgReses).then(() => {
                    cancelInstance.close()
                  })
                }
              }
              const failCall = () => {
                retry--
                if (retry > 0 && !pdf2jpgSuccess) {
                  ctrl.loading = oedResource.pdf2jpg({id: res.id}, {}).$promise.then(successCall, failCall)
                }

                if (retry <= 0 && !pdf2jpgSuccess) {
                  notificationService.notify('error', '试卷转码失败，请稍后重试', '', 5 * 1000)
                }
              }

              ctrl.loading = oedResource.pdf2jpg({id: res.id}, {}).$promise.then(successCall, failCall)
            }

          }).catch(() => {
            return
          })
        }, 1500, 100)
        ret.then(() => {
          notificationService.notify('error', '生成试卷失败，请稍后重试', '', 5 * 1000)
          cancelInstance.close()
        })
      }
    })
  }

  ctrl.openAnswerEditorDialog = () => {
    const modalInstance = $uibModal.open({
      template: require('app2/prepareCourse/quickCreate/editor/answerEditor.html'),
      controller: 'answerEditorCtrl',
      size: 'qp-edit',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          childQuestionList: ctrl.childQuestionList
        })
      }
    })

    modalInstance.result.then((q) => {
      if (q) {
        ctrl.childQuestionList = q
        ctrl.currentTest.questions[0].childQuestions = q
      }
    })
  }

  ctrl.setAnswer = (outerIndex, index) => {
    ctrl.childQuestionList[outerIndex].answer = _.toString(index)
    ctrl.currentTest.questions[0].childQuestions = ctrl.childQuestionList
  }

  ctrl.stringToNumber = (str) => {
    return _.toNumber(str)
  }
  ctrl.changeStatus = () => {
    ctrl.openMutualEvaluation = !ctrl.openMutualEvaluation;
    if (ctrl.openMutualEvaluation) {
      ctrl.mutualEvaluationMessage = '互评已开';
    } else {
      ctrl.mutualEvaluationMessage = '互评已关';
    }
  }
  function quickCreateTest(imageReses) {
    function createSyntheticQuestion(test) {
      const relSections = _.map(ctrl.course.relatedSections, (sec) => {
        return _.pick(sec, ['sectionId', 'sectionName']);
      });

      const obj = {
        answer: '',
        question: '',
        type: 'synthetic',
        point2: 0,
        choices: [],
        leftOpts: [],
        rightOpts: [],
        resourceUUIDs: _.map(imageReses, (image: any) => {
          _.get(image, 'md5')
        }),
        relatedSections: relSections,
        needPic: false,
        answerDetails: null
      }

      return oedObjectiveQuestion.createForTest({
        question: obj,
        testId: test.id
      }).$promise.then((q: any) => {
        q.childQuestions = defaultChildQuestionList;
        return oedObjectiveQuestion.save(q).$promise.then((r) => {
          return loadCloudQuestionByQId(q.id).then(() => q);
        })
      })
    }

    return createSyntheticQuestion(ctrl.currentTest).then(() => {
      ctrl.currentTest = oedObjectiveTest.get({
        id: ctrl.testId
      });

      return loadTestDetail()
    })
  }

  function validateWordFileType(f) {
    const validateResults = oedFileUtils.validateFileUpload(f, {
      allowedSuffixes: ['doc', 'docx', 'pdf']
    });
    f.validateResults = validateResults;
    f.progressOpacity = 0;
  }
}
