import * as angular from 'angular';
import 'app2/utils/fileUtils';
import _ = require('lodash');

angular.module('app.directives.review.preres.preResItemPreviewer', [])
.component('preResItemPreviewer', {
  bindings: {
    duration: '<',
    index: '<',
    item: '<',
    onPreviewItem: '&',
  },
  controller: preResItemPreviewerCtrl,
  template: <string> require('./preResItemPreviewer.html'),
});

preResItemPreviewerCtrl.$inject = ['oedFileUtils', 'oedOem']
function preResItemPreviewerCtrl(oedFileUtils: any, oedOem) {
  const getResFileTypeIncludeExternal = (res: any) => {
    if (res.isExternal) {
      return _.get(res, 'externalResourceType', 'unknown');
    }
    return _.get(res, 'flResource.fileType', 'unknown');
  }

  this.enableManage = oedOem.curOem() != 'futureSchool'

  this.getResTypeClass = (res: any) => {
    if (!res) {
      return null;
    }
    return oedFileUtils.getFileTypeIconClass(res.webUrl ? 'link' : getResFileTypeIncludeExternal(res));
  };

  this.getResTypeTooltip = (res: any) => {
    if (!res) {
      return null;
    }
    return oedFileUtils.getFileTypeTooltip(res.webUrl ? 'link' : getResFileTypeIncludeExternal(res));
  };
}
