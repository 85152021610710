(function() {
  'use strict';

  angular.module('app.directives.cloud.reportResource', [])
    .controller('reportResource', reportResource);

  reportResource.$inject = ['$scope', '$uibModalInstance', 'data', 'oedReportResource', 'notificationService']
  function reportResource($scope, $uibModalInstance, data, oedReportResource, notificationService) {
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.resourceId = data.resourceId;
    $scope.resType = data.resType;
    $scope.description = '';
    $scope.reportTypes = [{
      type: 1,
      content: '低俗色情',
      checked: false
    }, {
      type: 2,
      content: '垃圾广告',
      checked: false
    }, {
      type: 3,
      content: '辱骂攻击',
      checked: false
    }, {
      type: 4,
      content: '内容低俗无意义',
      checked: false
    }, {
      type: 5,
      content: '其他违法信息',
      checked: false
    }, {
      type: 6,
      content: '抄袭我的内容',
      checked: false
    }, {
      type: 7,
      content: '暴露我的隐私',
      checked: false
    }];
    $scope.showValidationError = false;

    $scope.ok = function() {
      $scope.checkedItems = _.filter($scope.reportTypes, function (rt) {
        return rt.checked;
      });
      if (!_.size($scope.checkedItems)) {
        $scope.showValidationError = true;
        return;
      }
      const obj = {
        reportType: $scope.resType,
        reason: $scope.checkedItems.map(item => item.content).join()
      };
      if ($scope.resType === 2) {
        obj.presentResourceItemId = $scope.resourceId;
      } else {
        obj.presentResourceId = $scope.resourceId;
      }
      if ($scope.description !== '') {
        obj.description = $scope.description;
      }
      oedReportResource.reportResource(obj).$promise.then(res => {
        notificationService.notify('info', '您的举报已提交，我们将及时审查');
        $uibModalInstance.dismiss('cancel');
      })
    };
  }
})();
