import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { QuestionDTO } from '../models/exam';
import { LegacyAppService } from 'app/core/legacy-app.service';
import _ = require('lodash');

@Component({
  selector: 'app-exam-question-design',
  template: require('./exam-question-design.component.html'),
  styles: [require('./exam-question-design.component.scss')]
})
export class ExamQuestionDesignComponent implements OnInit {

  @Input() question: QuestionDTO
  @Input() editorConfig: any
  @Input() editingUuid: any

  @Output() enterEditing = new EventEmitter<any>()
  @Output() leaveEditing = new EventEmitter<any>()
  constructor(private legacyApp: LegacyAppService,) { }

  ngOnInit() {
    
  }

  handleEnterEditing() {
    this.enterEditing.emit()
  }

  handleLeaveEditing() {
    this.leaveEditing.emit()
  }

  getQuestionUuid() {
    return _.get(this.question, 'uuid', '')
  }

  addChoice() {
    if (_.size(this.question.choices) >= 26) {
      this.legacyApp.getNotify()({
        message: '选项个数不能超过26个!'
      });
      return
    }
    this.question.choices = [...this.question.choices, {
      content: ''
    }]
  }

}
