import * as _ from 'lodash'
require('assets/app/controllers/examination/examReport/examReportChart/knowledgePointChart/knowledgePointChart.scss')
import { getBarColorByRate } from 'assets/app/controllers/examination/examReport/examReportChart/examChartUtils'

angular.module('app.examination.examReport.chart.knowledgePoint', [])
  .component('knowledgePointChart', {
    bindings: {
      examSearch: '<',
      wholeQuesMap: '<',
    },
    controller: knowledgePointChartCtrl,
    template: < string > require('./knowledgePointChart.html'),
  })


knowledgePointChartCtrl.$inject = []
function knowledgePointChartCtrl() {
  const ctrl = this

  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'examSearch')) {
      loadKpData()
    }
  }

  function loadKpData() {
    const kpData = _.get(ctrl.examSearch, 'knowledgeData.0.data', [])
    ctrl.kpChartData = []
    let unknowKpChartData = {}
    _.forEach(kpData, data => {
      const tagId = _.get(data, 'tagId', 0)
      const kpName = tagId > 0 ? _.get(data, 'tagName', '未命名知识点') : '未标知识点'
      const quesNums = getQuesNumByIds(_.get(data, 'questionIdList', []))
      const incQuesNum = `包含试题${quesNums}`
      const maxScore = _.get(data, 'totalScore', '')
      const scoreRate = _.get(data, 'scoreRate', '')
      const classScoreInfo = `班平均${_.round(scoreRate * maxScore, 1)}分/满分${maxScore}分`
      const chartData = { tagId, kpName, incQuesNum, scoreRate, classScoreInfo }
      if (tagId === 0) {
        unknowKpChartData = chartData
      } else {
        ctrl.kpChartData.push(chartData)
      }
    })
    ctrl.kpChartData = _.sortBy(ctrl.kpChartData, d => _.get(d, 'scoreRate', 0))
    if (!_.isEmpty(unknowKpChartData)) {
      ctrl.kpChartData.push(unknowKpChartData)
    }
    ctrl.kpChartData = _.sortBy(ctrl.kpChartData, d => -_.get(d, 'scoreRate', 0))
  }

  function getQuesNumByIds(ids: number[]) {
    const sortedIds = _.sortBy(ids, id => {
      const ques = _.get(ctrl.wholeQuesMap, id, {})
      return _.get(ques, 'quesNum', '')
    })
    const nums = _.map(sortedIds, id => _.get(ctrl.wholeQuesMap, `${id}.quesNum`, ''))
    return nums.join('、')
  }

  ctrl.getScoreRateText = (rate: any) => {
    return `${_.round(rate * 100, 1)}%`
  }

  ctrl.getBarColor = (data: any) => {
    const tagId = _.get(data, 'tagId', '')
    const rate = _.get(data, 'scoreRate', '')
    if (tagId === 0) {
      return {'background-color': '#AAAAAA', 'width': `${rate * 100}%`}
    }
    return getBarColorByRate(rate)
  }
}
