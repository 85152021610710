import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges  } from '@angular/core';
import * as _ from 'lodash';
import { AppStateService } from 'app/core/app-state.service';
import { forkJoin, of } from 'rxjs';
import { flatMap, finalize } from 'rxjs/operators';
import { LevelsAllDTO, SubjectsAllDTO, VersionsAllDTO, LgvsAllDTO, ExerciseDTO } from '../../manage';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { LegacyAppService } from 'app/core/legacy-app.service';
import { CoursePathService } from '../../../core/course-path.service';
import { OedExerciseService } from '../oed-exercise.service'
import { UserData, UserDTO } from 'app/models/user.d.ts'
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'exercise-book-in-res-lib',
  template: require('./exercise-book-in-res-lib.component.html'),
  styles: [require('./exercise-book-in-res-lib.component.scss')]
})
export class ExerciseBookInResLib implements OnInit {
  globalLevels: LevelsAllDTO[];
  globalSubjects: SubjectsAllDTO[];
  globalVersions: VersionsAllDTO[];
  globalLgvs: LgvsAllDTO[];
  loading = true;
  user: UserDTO;
  currentLevel: LevelsAllDTO;
  currentSubject: SubjectsAllDTO;
  currentVersion: VersionsAllDTO;
  currentLevelId: number;
  currentSubjectId: number;
  currentVersionId: number;
  subjects: SubjectsAllDTO[];
  versions: VersionsAllDTO[];
  uibModal: any;
  localStorage: any;
  initData = true;
  exerciseBookAll: ExerciseDTO[];
  currentExerciseBook: ExerciseDTO;
  @Output() private setPaperMode = new EventEmitter();
  @Output() private onDeleteQuestion = new EventEmitter();
  @Output() private onAddToResBox = new EventEmitter();
  @Output() private onDeleteTest = new EventEmitter();
  @Output() private onAddTestToResBox = new EventEmitter();
  @Input() resboxHolder: any;
  @Input() paperMode: any;
  @Input() material: any;
  constructor(
    private userInfo: AppStateService,
    private coursePath: CoursePathService,
    public oedNotifyService: NotificationService,
    private oedDialogService: OedDialogService,
    private oedExerciseService: OedExerciseService,
    private legacyApp: LegacyAppService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

  }

  onChangeBook({book}) {
    this.currentExerciseBook = book
  }

  ngOnInit() {
    this.getCurrentSchoolExerciseBook()
  }
  public getCurrentSchoolExerciseBook() {
    this.userInfo.then((info) => {
      this.user = info;
      this.oedExerciseService.getCurrentSchoolExerciseBook(this.user.schoolId).subscribe((data) => {
        if (_.isEmpty(data)) {
          this.oedNotifyService.notify('error', '您的学校还没有导入过习题册，请先导入数据');
        } else {
          // 根据学科版本匹配,学科版本相同获取创建时间最晚的一个
          this.exerciseBookAll = _.orderBy(data, 'createTime', 'desc');
          const sameSubjectAndVersionBooks = _(this.exerciseBookAll).map(i => {
            i.weight = i.subjectId === this.material.subjectId ? (i.versionId === this.material.versionId ? 2 : 1) : 0
            return i;
          }).filter(i => i.weight > 0).sortBy(['weight', 'desc'], ['createTime', 'desc']).head()
          if (!_.isEmpty(sameSubjectAndVersionBooks)) {
            this.currentExerciseBook = sameSubjectAndVersionBooks;
          }
        }
      })
    });
  }

  public getData() {
    this.localStorage = this.legacyApp.getLocalStorage();
    const lastLevelData = this.localStorage.get('ex_current_level');
    this.userInfo.then((info) => {
      this.user = info;
      return forkJoin(this.coursePath.getAllLevels(),this.coursePath.getAllSubjects(),this.coursePath.getAllVersions(),
        this.coursePath.getAllLgvs(),)
        .pipe(
          flatMap((values) => {
            this.globalLevels = values[0];
            this.globalSubjects = values[1];
            this.globalVersions = values[2];
            this.globalLgvs = values[3];
            this.setCurrentLevel(lastLevelData || this.globalLevels[0]);
            this.initData = false;
            return of([])
          }),
          finalize(() => {
            this.loading = false
          })
        ).subscribe();
    });
  }

  public setCurrentLevel (t) {
    this.localStorage.set('ex_current_level', t);
    this.currentLevel = t;
    this.currentLevelId = _.get(this.currentLevel, 'levelId');
    this.reloadSubject();
    if (!_.isEmpty(this.subjects) && this.initData) {
      this.setCurrentSubject(this.localStorage.get('ex_current_subject') || this.subjects[0]);
    } else {
      this.setCurrentSubject(this.subjects[0]);
    }
  }
  public setCurrentSubject(t) {
    this.localStorage.set('ex_current_subject', t);
    this.currentSubject = t;
    this.currentSubjectId = _.get(this.currentSubject, 'id');
    this.reloadVersion();
    if (!_.isEmpty(this.versions) && this.initData) {
      this.setCurrentVersion(this.localStorage.get('ex_current_version') || this.versions[0]);
    } else {
      this.setCurrentVersion(this.versions[0]);
    }
  }
  public setCurrentVersion (t) {
    this.localStorage.set('ex_current_version', t);
    this.currentVersion = t;
    this.currentVersionId = _.get(this.currentVersion, 'versionId');

    this.getCurrentExerciseBook()
  }

  public getCurrentExerciseBook() {
    this.oedExerciseService.getExerciseBook(
      this.currentSubjectId, this.currentVersionId, this.currentLevelId
    ).subscribe((data) => {
      this.exerciseBookAll = _.orderBy(data, 'createTime', 'desc');
      this.currentExerciseBook = _.head(this.exerciseBookAll)
    })
  }
  public reloadSubject () {
    const t = this.currentLevel;
    const glvs = _.uniq(_.map(_.filter(this.globalLgvs, s => s.levelId === t.levelId), t => t.subjectId));
    this.subjects = _.filter(this.globalSubjects, s => _.includes(glvs, s.id));
  }

  public reloadVersion () {
    const t = this.currentSubject;
    const glvs = _.uniq(_.map(_.filter(this.globalLgvs, s => s.levelId === this.currentLevel.levelId &&
      s.subjectId === t.id), t => t.versionId));
    this.versions = _.filter(this.globalVersions, s => _.includes(glvs, s.versionId));
  }

  private onSelectWetherPaper(isPaperMode) {
    console.log('onSelectWetherPaper:', isPaperMode)
    this.setPaperMode.emit({isPaperMode})
  }

  private doAddToResBox = (params) => this.onAddToResBox.emit(params)
  private doDeleteQuestion = (q) => this.onDeleteQuestion.emit(q)
  private doAddTestToResBox = (params) => this.onAddTestToResBox.emit(params)
  private doDeleteTest = (t) => this.onDeleteTest.emit(t)
}
