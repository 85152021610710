import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CreateTeachingModuleDTO } from 'app/lesson/models/lesson';
import { BsModalRef } from 'ngx-bootstrap/modal';
import _ = require('lodash');
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-create-module',
  template: require('./create-module.component.html'),
  styles: [require('./create-module.component.scss')]
})
export class CreateModuleComponent implements OnInit {
  @Output() public action = new EventEmitter<CreateTeachingModuleDTO>()
  private name: string
  private note: string
  constructor(
    public bsModalRef: BsModalRef,
    public lessonMessageService: LessonMessageService,
    private notifyService: NotificationService
    ) { }


  public ngOnInit() {
  }

  public ok() {
    if (_.isEmpty(this.name)) {
      this.notifyService.notify('error', '教学环节名称不能为空')
    } else {
      this.action.emit({
        name: this.name,
        note: this.note,
        teachingPatternId: undefined,
      })
      this.bsModalRef.hide()
    }
  }

  public close() {
    this.bsModalRef.hide()
  }

}
