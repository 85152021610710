import * as angular from 'angular';
import * as _ from 'lodash';

angular.module('app.directives.review.objective.votingCompletionDetails', [])
.component('votingCompletionDetails', {
  bindings: {
    classId: '<',
    fromClassSession: '<',
    showDetailsForStudent: '&',
    testSessionId: '<',
    students: '<',
    objTest: '<'
  },
  controller: votingCompletionDetailsCtrl,
  template: <string> require('./votingCompletionDetails.html'),
});

votingCompletionDetailsCtrl.$inject = ['$q', 'objSessionService']
function votingCompletionDetailsCtrl(
  $q: any,
  objSessionService: any
) {
  const ctrl = this;
  this.$onInit = () => {
    /**
     * 用答题器答题的学生完成状态查询
     */
    const remote = objSessionService.getStdListFromClassSessionWithRemoteVoting(ctrl.testSessionId)

    return remote.then((res: any) => {
      ctrl.studentsOfFinish = res.finishUsers
      ctrl.studentsOfUnfinish = res.unFinishUsers
      ctrl.studentsOfUnJoin = res.unLoginUsers
    })
  }
}
