import angular = require('angular');

angular.module('app.common.infoDialog', [])
  .controller('infoDialogCtrl', infoDialogCtrl);

interface IConfirmDialogInput { // eslint-disable-line
  title?: string;
  message?: string;
  yesText?: string;
}

infoDialogCtrl.$inject = ['$uibModalInstance', 'data']
function infoDialogCtrl($uibModalInstance: any, data: IConfirmDialogInput) {
  data = data || {};

  this.title = data.title || '确认?';
  this.msg = data.message || '确定关闭吗?';
  this.yesText = data.yesText || '是';

  this.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };

  this.yes = () => {
    $uibModalInstance.close('yes');
  };

  this.no = () => {
    $uibModalInstance.dismiss('no');
  };
}
