(function() {
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.reviewSchoolCreation', {
        url: '/reviewSchoolCreation',
        template: require('assets/templates/manage/reviewSchoolCreation.html'),
        controller: 'reviewSchoolCreationCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('reviewSchoolCreationCtrl', reviewSchoolCreationCtrl);

  reviewSchoolCreationCtrl.$inject = ['$scope', '$state', '$stateParams', 'dialogs', 'notificationService', 'oedSchoolRequest']
  function reviewSchoolCreationCtrl($scope, $state, $stateParams, dialogs, notificationService, oedSchoolRequest) {
    $scope.currentState.title = '学校审核';
    $scope.schools = oedSchoolRequest.queryPendingApprovals();

    $scope.approve = function(school) {
      var dlg = dialogs.confirm('批准学校', '确定批准此学校申请吗？');
      dlg.result.then(function() {
        $scope.loading = school.$approve();
        $scope.loading.then(function() {
          notificationService.notify('info', '学校信息更新成功')
          _.remove($scope.schools, school);
        });
      });
    };

    $scope.reject = function(school) {
      var dlg = dialogs.confirm('拒绝学校', '确定拒绝此学校申请吗？');
      dlg.result.then(function() {
        $scope.loading = school.$reject();
        $scope.loading.then(function() {
          notificationService.notify('info', '学校信息更新成功')
          _.remove($scope.schools, school);
        });
      });
    };
  }
})();
