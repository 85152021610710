import * as angular from 'angular';
import _ = require('lodash');

import preResViewLogUtils = require('app2/utils/preResViewLogUtils');

angular.module('app.directives.review.preres.preResItemDetails', [])
.component('preResItemDetails', {
  bindings: {
    classId: '<',
    classSessionId: '<',
    isPreresItemSession: '<',
    item: '<',
    preResSession: '<',
    preResSessionId: '<',
    publishRecordId: '<',
  },
  controller: preResItemDetailsCtrl,
  template: <string> require('./preResItemDetails.html'),
});

preResItemDetailsCtrl.$inject = ['oedPreResItemSession', 'oedViewLog', 'oedUserInfo', '$q', 'oedClassUser', 'oedPreResSessionStudent']
function preResItemDetailsCtrl(
  oedPreResItemSession: any, oedViewLog: any, oedUserInfo: any,
  $q: any, oedClassUser: any, oedPreResSessionStudent: any) {
  const ctrl = this;

  let loadData = () => {}

  this.$onInit = () => {
    const isPreresItemSession = angular.isDefined(ctrl.isPreresItemSession) && ctrl.isPreresItemSession === 'true';
    const classStds = oedClassUser.queryClassStudents({classId: ctrl.classId})

    ctrl.students = isPreresItemSession ?
      oedPreResItemSession.queryByClassSessionId({
        classSessionId: ctrl.classSessionId,
        preResItemSessionId: ctrl.preResSessionId,
      }) :
      oedPreResSessionStudent.queryByPreResSessionId({
        sessionId: ctrl.preResSessionId,
      })

    const viewLogByPreResItemId = oedViewLog.queryViewLogByPreResItemId({
      itemId: ctrl.item.id,
      sessionId: ctrl.preResSessionId,
    });

    loadData = () => $q.all([ctrl.students.$promise, viewLogByPreResItemId.$promise]).then(() => {
      return loadStdsDetails().then(() => { partitionStudents() });
    })

    ctrl.loading = loadData();

    const partitionStudents = () => {
      if (isPreresItemSession) {
        ctrl.viewedStds = ctrl.students;
        ctrl.unviewedStds = [];
        _.each(ctrl.viewedStds, (std: any) => {
          std.duration = preResViewLogUtils.timeDiff(ctrl.preResSession);
        })
      } else {
        const viewedStdLogs = _.groupBy(viewLogByPreResItemId, 'uid');
        const part = _.partition(ctrl.students, (std: any) => {
          const has: boolean = _.has(viewedStdLogs, std.uid);
          if (has) {
            std.duration = preResViewLogUtils.durationSpentOnItem(viewedStdLogs[std.uid]);
          }
          return has;
        });
        ctrl.viewedStds = part[0];
        ctrl.unviewedStds = part[1];
      }
    }

    const loadStdsDetails = () => {
      const map = _.keyBy(ctrl.students, 'studentId');
      const mapClassStdsByUid: any = _.keyBy(classStds, 'uid');
      return $q.all(_.map(ctrl.students, (std: any) => {
        std.uid = std.studentId;
        if (_.has(mapClassStdsByUid, std.studentId)) {
          std.name = mapClassStdsByUid[std.studentId].name;
          std.gender = mapClassStdsByUid[std.studentId].gender;
        } else { // 该学生可能已经被移出班级
          return oedUserInfo.getByUid({
            id: std.uid,
          }).$promise.then((info: any) => {
            std.name = info.name
            std.gender = info.gender
          })
        }
      }))
    }
  }

  ctrl.$onChanges = (changeobj: any) => {
    if (_.has(changeobj, 'item')) {
      ctrl.item = changeobj.item.currentValue;
      if (!_.isNil(ctrl.item)) {
        loadData();
      }
    }
  }
}
