(function() {
  angular.module('app.signOut', ['ui.router', 'ngCookies'])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('signout', {
        url: '/signout',
        template: require('assets/templates/signout.html'),
        controller: 'signOutCtrl',
        bodyStyle: 'signout'
      });
    }])
    .controller('signOutCtrl', signOutCtrl);

  signOutCtrl.$inject = ['$http', '$state', '$q', '$timeout', 'oedConfig', 'localStorageService']
  function signOutCtrl($http, $state, $q, $timeout, oedConfig, localStorageService) {

    var signOutUrl = oedConfig.url('login/logout');

    $http.get(signOutUrl)
      .finally(function() {
        localStorageService.set('bigdata_uid', null);
        // localStorageService.set('bigdata_session_id', null);
        localStorageService.set('bigdata_last_report_timestamp', null);
        localStorageService.set('filterSelectedClasses', null);
        localStorageService.set('filterStartDate', null);
        localStorageService.set('filterDueDate', null);
        localStorageService.set('newParpreCourse_session_id',null);
        localStorageService.set('pre_type',null);
        $state.go('signin');
      });
  }
})();

