import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Nvd3Component } from './nvd3/nvd3.component';

@NgModule({
  declarations: [Nvd3Component],
  entryComponents: [Nvd3Component],
  exports: [Nvd3Component],
  imports: [
    CommonModule
  ]
})
export class ChartsModule { }
