import angular = require('angular');
import 'app2/utils/courseUtils';
import _ = require('lodash');

angular.module('app.directives.question.subjQuestionPreviewer', [
]).component('subjQuestionPreviewer', {
  bindings: {
    question: '<',
  },
  controller: subjQuestionPreviewerCtrl,
  template: <string> require('./subjQuestionPreviewer.html'),
});

subjQuestionPreviewerCtrl.$inject = ['oedCourseUtils']
function subjQuestionPreviewerCtrl(oedCourseUtils: any) {

  this.$onInit = () => {
    const secIds = _.get(this.question, 'relatedSections', []).map((v: any) => v.sectionId)
    oedCourseUtils.loadSectionNamesById(secIds).then((name: any) => this.relatedSections = name);
  }

  this.getTypeLabel = (type: string) => {
    if (type === 'subjective_blankboard') {
      return '白板';
    }
    return '!!!';
  };
}
