
var _ = require('lodash');
var questionPreviewerFill = require('./questionPreviewerFill.directive');

(function() {
  angular.module('app.directives.question.questionPreviewer', [
    'app.directives.question.questionPreviewerFill'
  ])
    .directive('questionPreviewer', questionPreviewer);

  function questionPreviewer() {
    return {
      restrict: 'EA',
      require: '^ngModel',
      scope: {
        ngModel: '=',
        qHidePoint: '<',
        qCloudRes: '=',
        qIndex: '=',
        parentIndex: '=',
        qReadonly: '=',
        scoreChanged: '&onScoreChanged',
        qShowAnswer: '=',
        qHideDifficult: '<',
        qShowAnswerDetails: '=',
        qShowCloudResId: '=',
        qShowCloudTestLink: '=',
        onImportClicked: '&',
        onGoToTestClicked: '&',
        ruanyun: '<',
        isExercise: '=',
        exKnowledgePoint: '=',
        exDifficult: '=',
        isNewVersion: '=',
        starReadOnly: '='
      },
      template: require('assets/app2/directives/question/questionPreviewer.html'),
      controller: questionPreviewrCtrl,
    };
  }
  questionPreviewrCtrl.$inject = [
    '$scope', '$element', 'oedTestUtils',
    'oedCloudObjectiveQuestion', 'oedCloudTest', 'oedCloudQuestionStats', 'cloudQuestionService']

  function questionPreviewrCtrl($scope, $element, oedTestUtils, oedCloudObjectiveQuestion, oedCloudTest, oedCloudQuestionStats, cloudQuestionService) {
    $scope.getAnswerText = oedTestUtils.getAnswerText;
    $scope.getChoiceName = oedTestUtils.getChoiceName;

    if ($scope.qShowCloudResId && _.has($scope, 'qCloudRes.id')) {
      cloudQuestionService.getStatByCloudId($scope.qCloudRes.id).then((stat) => $scope.stats = stat)
    }
    $scope.isChildQuestionFromCloudRes = function() {
      return $scope.qShowAnswer && !_.isNil($scope.parentIndex);
    };
    $scope.getTypeLabel = function(t) {
      switch (t) {
      case 'singlechoice':
        return '单选';
      case 'multichoice':
        return '多选';
      case 'fill':
        return '填空';
      case 'connect':
        return '匹配';
      case 'yesorno':
        return '判断';
      case 'singlevote':
        return '投票';
      case 'multivote':
        return '投票';
      case 'synthetic':
        return '综合';
      case 'qa':
        return '问答';
      }
      return '';
    };
    $scope.parsingNoorOff = false;
    $scope.parsingSwitch = function() {
      $scope.parsingNoorOff = !$scope.parsingNoorOff;
    };
    $scope.getAnswer = function() {
      var t = $scope.ngModel.type;
      var answers;
      if (t == 'singlechoice') {
        if (!$scope.ngModel.answer) return '';
        return $scope.getChoiceName($scope.ngModel.answer);
      } else if (t == 'multichoice') {
        return $scope.ngModel.answer;

      } else if (t == 'yesorno') {
        if (_.isNull($scope.ngModel.answer) || _.isUndefined($scope.ngModel.answer))
          return '';
        return $scope.ngModel.answer ? '正确' : '错误';
      } else if (t == 'connect') {} // eslint-disable-line

      return $scope.ngModel.answer;
    };

    $scope.showChoices = function() {
      return $scope.ngModel.type == 'singlechoice' || $scope.ngModel.type == 'multichoice';
    };

    $scope.getQuestionNumber = function() {
      if ($scope.parentIndex >= 0)
        if ($scope.ngModel.label) {
          return ($scope.ngModel.label) + '.' + ($scope.qIndex + 1);
        } else {
          return ($scope.parentIndex + 1) + '.' + ($scope.qIndex + 1);
        }
      else
        return $scope.qIndex + 1;
    };

    $scope.getLeftOptName = oedTestUtils.getChoiceName;

    $scope.getRightOptName = function(idx) {
      return idx + 1;
    };

    $scope.getRelatedSectionNames = function() {
      var secs = _.get($scope, 'ngModel.relatedSections', []);
      if (_.isEmpty(secs)) {
        return '无';
      }

      return _(secs).map(function(sec) {
        return sec.sectionName;
      }).join(', ');
    };

    if ($scope.qShowCloudResId && !$scope.cloudRes) {
      $scope.$watch('ngModel.id', function(newId) {
        $scope.cloudRes = null;
        if (newId) {
          cloudQuestionService.getCloudResByQuestionId(newId).then((cq) => {
            if (_.get($scope.ngModel, 'id') === newId) {
              $scope.cloudRes = cq
            }
          })
        }
      });
    }

    if ($scope.qShowCloudTestLink) {
      $scope.$watch('ngModel.id', function(newId) {
        $scope.cloudTest = null;
        if (newId) {
          cloudQuestionService.getCloudTestByQuestionId(newId).then((cq) => {
            if (_.get($scope.ngModel, 'id') === newId) {
              $scope.cloudTest = cq
            }
          })
        }
      });
    }

    function captureClicked(evt) {
      if (!evt.target) {
        return
      }

      var isChild = $element.find(event.target).length > 0;
      if (!isChild) {
        return;
      }

      if ($(event.target).attr('target') === '_self') {
        $(event.target).attr('target', '_blank')
      }
    }

    $element[0].addEventListener('click', captureClicked, true);
  }
})();
