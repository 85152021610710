(function() {
  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.reviewStudentTaskDetails', {
        url: '/tasklistrecord/:recordId/studentDetails/:studentId',
        template: require('assets/templates/homework/reviewStudentTaskDetails.html'),
        controller: 'reviewStudentTaskDetails',
        bodyStyle: 'bg_img'
      });

    }]).controller('reviewStudentTaskDetails', reviewStudentTaskDetails);

  reviewStudentTaskDetails.$inject = ['$scope', '$state', '$stateParams', 'oedUserInfo', '$q',
    'userInfo', 'oedCoursePath', 'oedTaskList', 'oedTask', 'oedTaskListPublishRecord',
    'oedTaskTest', 'oedTaskPresentResource', 'oedPresentResource', 'oedObjectiveTest', 'oedSubjectiveTest',
    'oedTestSession', 'oedTestSessionStudent', 'oedTaskListPublishRecordComment',
    'oedPreResSession', 'oedPreResSessionStudent']
  function reviewStudentTaskDetails($scope, $state, $stateParams, oedUserInfo, $q,
    userInfo, oedCoursePath, oedTaskList, oedTask, oedTaskListPublishRecord,
    oedTaskTest, oedTaskPresentResource, oedPresentResource, oedObjectiveTest, oedSubjectiveTest,
    oedTestSession, oedTestSessionStudent, oedTaskListPublishRecordComment,
    oedPreResSession, oedPreResSessionStudent) {
    $scope.currentState = {};
    var recordId = _.parseInt($stateParams.recordId);
    $scope.recordId = recordId;
    $scope.presentResIdMap = {};
    $scope.testIdMap = {};
    $scope.taskIdMap = {};
    var studentId = _.parseInt($stateParams.studentId);

    $scope.student = oedUserInfo.getByUid({
      id: studentId
    });

    $scope.record = oedTaskListPublishRecord.get({
      id: recordId
    });

    $scope.allStudents = oedTaskListPublishRecord.queryStudentsInfo({
      id: recordId
    });

    $scope.studentsSubmitCount = oedTaskListPublishRecord.queryStudentsSubmitTaskCount({
      id: recordId
    });

    $q.all([$scope.allStudents.$promise, $scope.studentsSubmitCount.$promise]).then(function() {
      var map = _.keyBy($scope.studentsSubmitCount, 'uid');
      _.forEach($scope.allStudents, function(s) {
        if (_.has(map, s.uid)) {
          var matched = map[s.uid];
          s.totalTaskCount = matched.totalTaskCount;
          s.submitTaskCount = matched.submitTaskCount;
          s.submitProgress = 0;
          if (s.totalTaskCount > 0) {
            s.submitProgress = (s.submitTaskCount * 100 / s.totalTaskCount).toFixed(0);
          }
        }
      });
      var part = _.partition($scope.allStudents, function(s) {
        return s.submitProgress && s.submitProgress == '100';
      });
      var studentsOfFinish = part[0];
      var studentsOfUnfinish = part[1];

      var idx = _.findIndex(studentsOfFinish, function(t) {
        return t.uid == studentId;
      });
      if (idx != -1) {
        if (idx > 0) {
          $scope.prevStudent = studentsOfFinish[idx - 1];
        }
        if (idx < _.size(studentsOfFinish) - 1) {
          $scope.nextStudent = studentsOfFinish[idx + 1];
        }
      } else {
        idx = _.findIndex(studentsOfUnfinish, function(t) {
          return t.uid == studentId;
        });
        if (idx > 0) {
          $scope.prevStudent = studentsOfUnfinish[idx - 1];
        }
        if (idx < _.size(studentsOfUnfinish) - 1) {
          $scope.nextStudent = studentsOfUnfinish[idx + 1];
        }
      }
    });

    $scope.readPresentResource = {};
    $scope.readTests = {};

    $scope.$on('homework.addComment', function() {
      $scope.commented = true;
    });

    function getTaskFirstResource(task) {
      var taskRes = $scope.taskResources[task.id];
      if (!_.isEmpty(taskRes.presentResources)) {
        return taskRes.presentResources[0];
      }

      if (!_.isEmpty(taskRes.objectiveTests)) {
        return taskRes.objectiveTests[0];
      }

      if (!_.isEmpty(taskRes.subjectiveTests)) {
        return taskRes.subjectiveTests[0];
      }
      return null;
    }

    function gotoResourceDetails(res, task) {
      if (res.constructor == oedTaskPresentResource) {
        $state.go('homework.reviewStudentTaskDetails.res.presentResource', {
          taskId: task.id,
          resId: res.resId
        }, {
          location: 'replace'
        });
        return;
      }

      var taskRes = $scope.taskResources[task.id];
      if (res.constructor == oedTaskTest) {
        if (_.findIndex(taskRes.objectiveTests, function(ot) {
            return ot.id === res.id;
          }) >= 0) {
          $state.go('homework.reviewStudentTaskDetails.res.objectiveTest', {
            taskId: task.id,
            origTestId: res.testId
          }, {
            location: 'replace'
          });
          return;
        }

        $state.go('homework.reviewStudentTaskDetails.res.subjectiveTest', {
          taskId: task.id,
          origTestId: res.testId
        }, {
          location: 'replace'
        });
      }
    }

    $scope.recordPromise = $scope.record.$promise.then(function(record) {
      var taskListId = record.taskListId;
      $scope.taskList = oedTaskList.get({
        id: taskListId
      });

      $scope.taskList.$promise.then(function(r) {
        $scope.currentState.title = r.name.length > 6 ? r.name.substring(0, 6) + '...' : r.name;
      });

      $scope.tasks = oedTask.queryByTaskListId({
        id: taskListId
      });

      var loadingTasks = $scope.tasks.$promise.then(function(tasks) {
        if (!_.isEmpty(tasks)) {
          $scope.taskId = tasks[0].id;
        }
        return tasks;
      }).then(loadTaskResources);

      var courseResPromise = $scope.taskList.$promise.then(function(tl) {
        $scope.publishInfo = oedTaskList.queryPublishInfo({
          id: tl.id
        });

        var courseId = tl.courseId;
        $scope.presentResources = oedPresentResource.queryByTaskListId({
          id: taskListId
        });

        $scope.objAndSbjTests = oedObjectiveTest.queryByTaskListId({
          id: taskListId
        });

        return $q.all([$scope.publishInfo,
          $scope.presentResources.$promise,
          $scope.objAndSbjTests.$promise
        ]).then(function() {
          $scope.presentResources.$promise.then(function() {
            $scope.presentResIdMap = _.reduce($scope.presentResources, function(t, p) {
              t[p.id] = p;
              return t;
            }, {});
          });

          function convertTestToIdMap(tests, v) {
            var testIdMap = _.reduce(tests, function(total, t) {
              total[t.id] = t;
              return total;
            }, v);
            return testIdMap;
          }
          var testIdMap = {};

          testIdMap = convertTestToIdMap($scope.objAndSbjTests, testIdMap);
          $scope.testIdMap = testIdMap;

        });
      });

      return $q.all([loadingTasks, courseResPromise]);
    });

    $scope.isTaskListOverdue = function() {
      if (!$scope.publishInfo || !$scope.publishInfo.lastDueDate) {
        return false;
      }

      return $scope.publishInfo.lastDueDate <= Date.now();
    };

    $scope.getPresentResById = function(id) {
      return $scope.presentResIdMap[id];
    };

    $scope.getTestById = function(id) {
      return $scope.testIdMap[id];
    };

    function loadTaskResources(tasks) {
      if (_.isEmpty(tasks)) {
        $scope.taskIdMap = {};
        $scope.taskResources = {};
        return 0;
      }

      $scope.taskIdMap = _.reduce(tasks, function(total, t) {
        total[t.id] = t;
        return total;
      }, {});

      $scope.taskResources = {};
      var loadingTasks = _.map(tasks, function(t) {
        var objTests = oedTaskTest.queryTaskObjectiveTestByTaskId({
          id: t.id
        });

        var subjTests = oedTaskTest.queryTaskSubjectiveByTaskId({
          id: t.id
        });

        var preReses = oedTaskPresentResource.queryByTaskId({
          id: t.id
        });

        $scope.taskResources[t.id] = {
          objectiveTests: objTests,
          subjectiveTests: subjTests,
          presentResources: preReses,
          allTests: [],
          allReses: []
        };

        $scope.readPresentResource[t.id] = {};
        $scope.readTests[t.id] = {};

        var loadPreReses = preReses.$promise.then(function(reses) {
          return $q.all(_.map(reses, function(res) {
            var preResSession = oedPreResSession.getPreResSessionByTaskListRecordAndPreResId({
              recordId: recordId,
              taskId: res.taskId,
              preResId: res.resId
            });

            return preResSession.$promise.then(function(session) {
              oedPreResSessionStudent.queryByPreResSessionAndStudentId({
                sessionId: session.id,
                studentId: studentId
              }).$promise.then(function(preResSessionStudent) {
                if (!_.isNull(_.get(preResSessionStudent, 'submitTime', null))) {
                  $scope.readPresentResource[t.id][res.id] = true;
                }
              }).catch(function(err) {

              });
            });
          }));
        });

        var loadAllTests = $q.all([objTests.$promise, subjTests.$promise]).then(function(ot, st) {
          var allTests = objTests.concat(subjTests);
          allTests.sort(function(a, b) {
            return a.sort - b.sort;
          });

          $scope.taskResources[t.id].allTests = allTests;

          return $q.all(_.map(allTests, function(test) {
            return oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
              recordId: recordId,
              taskId: t.id,
              origTestId: test.testId
            }).$promise.then(function(testSession) {
              return oedTestSessionStudent.queryByTestSessionAndStudentId({
                sessionId: testSession.id,
                studentId: studentId
              }).$promise.then(function() {
                $scope.readTests[t.id][test.id] = true;
              }).catch(function() {
                return 0;
              });
            });
          }));
        });

        return $q.all([loadAllTests, loadPreReses]);
      });

      return $q.all(loadingTasks).then(function() {
        var taskId = _.parseInt($state.params.taskId);
        if (!taskId && $state.current.name != 'homework.reviewStudentTaskDetails.comments') {
          var task = _.find(tasks, function(t) {
            return !!getTaskFirstResource(t);
          });
          if (task) {
            var res = getTaskFirstResource(task);
            gotoResourceDetails(res, task);
          }
        }
      });
    }

    $scope.getPresentResById = function(id) {
      return $scope.presentResIdMap[id];
    };

    $scope.getTestById = function(id) {
      return $scope.testIdMap[id];
    };

    $scope.getTaskById = function(id) {
      return $scope.taskIdMap[id];
    };

    var comments = oedTaskListPublishRecordComment.queryByRecordAndStudentId({
      recordId: recordId,
      studentId: studentId
    }).$promise.then(function(cmts) {
      if (_.size(cmts) > 0) {
        $scope.commented = true;
      }
    });

    $scope.getName = function(id) {
      var task = $scope.getTaskById(id);
      if (!task) {
        return '';
      }

      var name = $scope.getTaskById(id).name;
      return name.length >= 6 ? name.substring(6) : name;
    };

    $scope.loading = $q.all([$scope.recordPromise, comments.$promise]);
  }
})();
