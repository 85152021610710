(function() {

  angular.module('app.student', ['ui.router', 'ngCookies'])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentforExport', {
        url: '/anno/student/reviewexport/:studentId/:courseId/:start/:end',
        template: require('assets/templates/student/reviewExport.html'),
        controller: 'reviewExportCtrl'
      });
    }]).controller('reviewExportCtrl', reviewExportCtrl);

  reviewExportCtrl.$inject = ['$q', '$scope', '$stateParams', '$timeout', 'oedWrongBook', 'oedTestUtils',
    'oedObjectiveQuestion', 'moment', 'oedTestSessionStatForReview']
  function reviewExportCtrl($q, $scope, $stateParams, $timeout, oedWrongBook, oedTestUtils,
                            oedObjectiveQuestion, moment, oedTestSessionStatForReview) {
    const end = moment($stateParams.end, 'YYYYMMDD').add(1, 'day').valueOf();
    $scope.wrongBooks = oedWrongBook.getWrongBook({
      coursePathId: $stateParams.courseId,
      studentId: $stateParams.studentId,
      start: moment($stateParams.start, 'YYYYMMDD').valueOf(),
      end: end
    });
    $scope.wrongBooks.$promise.then(function(result) {
      const sessionIds = _.uniq(_.map(result, r => r.sessionId));
      const details = _.map(sessionIds, id => {
        return oedTestSessionStatForReview.queryByTestSessionAndStudentId({
          sessionId: id,
          userId: $stateParams.studentId
        }).$promise;
      });
      $q.all(details).then(function(result) {
        $scope.details = result;
        $scope.questions = _.map($scope.wrongBooks, r => $scope.findQuesReviewDetail(r.sessionId, r.questionId));
        const parentIds = _.uniq(_.filter(_.map($scope.questions,
            q => q.question.parentQuestionId), id => !_.isNull(id)));
        const parents = _.map(parentIds, pid => {
          return oedObjectiveQuestion.get({
            id: pid
          }).$promise;
        });
        $q.all(parents).then(ps => {
          $scope.parents = ps;
          $scope.currentLabels = oedTestUtils.getTestStatLabel($scope.questions);
          $timeout(function() {
            window.questionsLoaded = true;
          }, 1000);
        });
      });
    });
    $scope.findQuesReviewDetail = function(sessionId, quesId) {
      const review = _.find($scope.details, r => r.testSessionId === sessionId);
      return _.find(review.quesReviewDTOList, r => r.question.id === quesId);
    };

    $scope.currentLabels = [];
    $scope.getLabel = function(index) {
      if (_.size($scope.currentLabels) > index)
        return $scope.currentLabels[index];
      return '';
    };
    $scope.isFirstChild = function(index) {
      if (_.isEmpty($scope.currentLabels))
        return false;
      return _.endsWith($scope.currentLabels[index], '.1');
    };
    $scope.getParentIndex = function(index) {
      if (_.size($scope.currentLabels) > index) {
        var num = _.split($scope.currentLabels[index], '.', 1)[0] - 1;
        return num;
      }
    };
    $scope.getParent = function(child) {
      return _.find($scope.parents, p => p.id === child.parentQuestionId);
    };
    $scope.getRelatedSectionNames = function(child) {
      const question = $scope.getParent(child);
      if (_.isEmpty(question.relatedSections)) {
        return '无';
      }
      return _(question.relatedSections).map(function(sec) {
        return sec.sectionName;
      }).join(', ');
    };
    $scope.getChoiceName = oedTestUtils.getChoiceName;
    $scope.getAnswerText = oedTestUtils.getAnswerText;

    $scope.getCorrectRate = function(question) {
      var correct = question.quesStat.countAnsweredCorrectAndHesitate;
      var hesitate = question.quesStat.countAnsweredCorrectNotHesitate;
      var total = question.quesStat.countAnsweredTotal;
      if (total !== 0) {
        return (correct + hesitate) * 100 / total;
      }
      return 0;
    };
  }
})();
