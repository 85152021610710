
/* global -Promise */
import {Base64} from 'js-base64/base64'
const Promise = require('bluebird');
require('assets/styles/login.scss')
require('app2/common/infoDialog.ctrl')
require('app2/changePassword/changePasswordForTal.ctrl')

angular.module('app.signIn', ['ngCookies', 'oedweb.next', 'app.common.infoDialog',
    'app.changePassword.talChangePwd'])
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('signin', {
      url: '/signin/:token',
      params: {
        token: ''
      },
      template: require('assets/templates/signin.html'),
      controller: 'signInCtrl',
      loadingCls: 'homeloading',
      bodyStyle: 'newstyle'
    });
    $stateProvider.state('sessionTimeout', {
      url: '/sessionTimeout',
      onEnter: stateProviderOnEnter,
    });
  }])
  .controller('signInCtrl', signInCtrl);

stateProviderOnEnter.$inject = ['$stateParams', '$state', '$uibModal', '$resource']
function stateProviderOnEnter($stateParams, $state, $uibModal, $resource) {
  $uibModal.open({
    template: require('assets/templates/sessionTimeout.html'),
    controller: stateProviderController,
  }).result.finally(function() {
    $state.go('signin');
  });
}

stateProviderController.$inject = ['$scope']
function stateProviderController($scope) {
  $scope.ok = function() {
    $scope.$dismiss();
  };
}

signInCtrl.$inject = ['$scope', '$http', '$cookies', '$location',
  '$state', '$stateParams', '$q', '$window', 'oedUserInfo', 'notify',
  'oedConfig', 'oedUserClass', 'oedServerInfo', 'oedSchool', 'oedLoginToken', '$interval',
  'schoolFeatureService', 'oedCoursePath', 'oedOem', 'notificationService', '$uibModal', 'localStorageService']
function signInCtrl($scope, $http, $cookies, $location,
                    $state, $stateParams, $q, $window, oedUserInfo, notify,
                    oedConfig, oedUserClass, oedServerInfo, oedSchool, oedLoginToken, $interval,
                    schoolFeatureService, oedCoursePath, oedOem, notificationService, $uibModal, localStorageService) {

  var currentServerInfo = oedServerInfo.current();
  const failLogin = 'failLogin'
  const minute = 60 * 1000

  $scope.status = {}

  $scope.enableManage = oedOem.curOem() != 'hal2'

  $scope.loginMethods = [
    {
      name: '系统账号',
      isDefault: true,
    }, {
      name: '通州云平台账号'
    },
  ]

  $scope.getDownloadUrl = function(apk) {
    var env = '';
    if (process.env.NAME === 'beta') {
      env = 'Beta';
    }
    return '/download/' + apk + env + '.apk';
  }

  $scope.selectedLoginMethod = 0

  function tryTokenLogin() {
    var token = $stateParams['token'];
    if (_.isEmpty(token)) {
      return $q.resolve();
    }
    return $http.post(oedConfig.url('login/tokenlogin'), {
      loginName: token
    }).catch(function() {
      console.log('error while trying token login');
      //
      // 忽略token login的错误
    });
  }

  const checkCoursePathIsEmpty = (uid) => {
    const query = {
      userId: _.parseInt(uid),
      all: false,
      onGoing: true,
      unStarted: true,
      ended: true,
      archived: false
    };
    return oedCoursePath.queryFilter(query).$promise.then((coursePaths) => {
      return _.isEmpty(coursePaths);
    });
  }

  const doAferLogin = (user) => {
    if (_.includes(user.roles, 'student')) {
      $state.go('studentHome');
      return $q.resolve();
    }
    // if (!user.hasReviewByAdmin) {
    //   return;
    // }
    return checkCoursePathIsEmpty(user.uid).then((isEmpty) => {
      if (isEmpty) {
        $state.go('manage.manageCourses');
      } else {
        $state.go('home');
      }
    })
  }

  $scope.loading = tryTokenLogin().then(function() {
    return $http.get(oedConfig.url('login/userinfo'))
      .then(function(resp) {
        var data = resp.data;
        if (data.uid && data.schoolId > 0) {
          return checkShouldRedirectToSchoolServer(data, data);
        }

        return data.data;
      }).then(function(resp) {
        if (resp.uid) {
          return doAferLogin(resp);
        }
      }).catch(function(resp) {
        //
        // not logged in
      });
  });

  $scope.canLogin = true;
  $scope.needCaptcha = false;

  $scope.reSendEmail = function() {
    var sendEmail = oedUserInfo.sendSignupEmail({
      id: $scope.user.uid
    });

    $scope.loading = sendEmail.$promise.then(function(resp) {
      if (!resp.success) {
        notificationService.notify('error', _.get(resp, 'message', '邮件发送失败'))
      } else {
        notificationService.notify('info', '邮件发送成功')
      }
    });
  };

  $scope.needEmailActivite = function() {
    return $scope.errorType == 'login/needEmailActivation';
  };

  $scope.checkModel = () => {
    const msg = '为了防止教师账号密码泄漏，近期我们将对教师密码安全等级进行升级，我们将针对教师密码为6个0的账号，统一重置为“姓名拼音首字母+0000”，系统将在2019年11月29日晚20:00开始进行升级，请各位教师尽快自行修改个人密码。'

    const modalInstance = $uibModal.open({
      template: require('app2/common/infoDialog.html'),
      controllerAs: '$ctrl',
      controller: 'infoDialogCtrl',
      resolve: {
        data: function() {
          return {
            message: msg,
            title: '新通知',
            yesText: '我知道了',
          };
        }
      },
      windowClass: 'infoConfirm modalCenter',
    });

    modalInstance.result.then(() => {
      $scope.siginInOperation()
    })
  }

  const changePasswordForTalModel = (urlForChangePwd) => {
    const modalInstance = $uibModal.open({
      component: 'talChangePwd',
      size: 'lg',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        data: () => {
          return {
            webUrl: urlForChangePwd
          }
        }
      }
    });

    return modalInstance.result.then(() => {
      return null
    })
  }

  const changePasswordModel = (msg) => {
    const modalInstance = $uibModal.open({
      template: require('app2/changePassword/changePassword.html'),
      controller: 'changePwdCtrl',
      size: 'md',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        data: function() {
          return {
            username: $scope.login.loginName,
            oldPassword: $scope.login.password,
            newUser: msg.indexOf('6-20') < 0
          };
        }
      },
    });

    return modalInstance.result.then(() => {
      return null
    })
  }

  let checkLoginLock = null
  $scope.siginInOperation = () => {
    $scope.canLogin = false;

    if (_.size(localStorageService.get(failLogin)) >= 5) {
      if (checkLoginLock) {
        return
      }
      if (getLockTimeLeft() > 0) {
        $scope.showAlert = true;
        $scope.message = getLockMessage('')
        checkLoginLock = $interval(function() {
          doCheckLoginLock()
        }, 10 * 1000);
        return
      }

      clearFailLogin()
    }
    // 密码加密处理
    $scope.login.password = Base64.encode($scope.login.password);
    $scope.loading = $http.post(oedConfig.url('login?decoderPwd=true'),
                                _.assign({
                                  loginBbs: false,
                                  changePwdAsSimple: true,
                                  changePwdForTal: true,
                                }, $scope.login)
                               )
      .then(function(resp) {
        $scope.login.password = Base64.decode($scope.login.password);
        var data = resp.data;
        if (data.success) {
          oedUserInfo.getUserPreference({uid:data.user.uid}).$promise.then(res=>{
            if(res.value && res.value == 0) {
              localStorageService.set("isPreLesson", false);
            }else{
              localStorageService.set("isPreLesson", true);
            }
          })
          oedUserInfo.getZlwhResourcePreference({schoolId:data.user.schoolId}).$promise.then(res=>{
            if(res.value && res.value == 1) {
              localStorageService.set('isBuyZlCourse', true);
            }else{
              localStorageService.set('isBuyZlCourse', false);
            }
          })
          // 删除现有js
          $("script[id='_bxtj']").remove();
          // 定义学校站点
          var schoolSiteMap = {
            '981491': '4026bc4d798e3c6d01799c2a68d60b12',
            '981689': '4026bc4d798e3c6d01799c2a68d60b12',
            '981783': '4026bc4d7968c27601798340686905c3',
            '981918': '4026bc4d7aadd9a8017aae030b8201b1',
            '981548': '4026bc4d7ab3e457017ab8a031fb06fc'
          }
          // 添加北京市智慧校园云服务平台js
          var src = 'http://bjedures.bjedu.cn/bjjw_logdb/bxlog.js?user=' + data.user.uid + '&id=' + schoolSiteMap[data.user.schoolId]
          console.log('data.user.uid', data.user.uid)
          console.log('data.user.schoolId', data.user.schoolId)
          console.log('src', src)
          var _s = document.createElement('script');
          _s.setAttribute('type','text/javascript');
          _s.setAttribute('id','_bxtj');
          _s.setAttribute('async',true);
          _s.setAttribute('src',src);
          var body = document.getElementsByTagName('body')
          body[0].appendChild(_s)
          // check should redirect to target server server
          return checkShouldRedirectToSchoolServer(data, data.user);
        } else if (data.errorType === 'login/invalidPassword') {
          $scope.showAlert = false;
          return changePasswordModel(data.message)
        } else if (data.errorType === 'login/talForceChangePwd') {
          $scope.showAlert = false;
          return changePasswordForTalModel(data.flago)
        } else {
          $scope.showAlert = true;
          addNewFailLogin(Date.now())
          $scope.message = getLockMessage(data.message + ', ')
          $scope.codeVer++;

          // Get user info
          $scope.user = data.user;
          $scope.errorType = data.errorType;

          tryToCheckLoginLock()
          //
          // handle error
          return null;
        }
      }).then(function(resp) {
        //
        // 表示登录失败
        if (!resp) {
          return $q.resolve();
        }

        clearFailLogin()
        $scope.showAlert = !resp.success;
        $scope.needCaptcha = resp.needRecaptcha;
        return doAferLogin(resp.user);
      }).catch(function(reason) {
        if (_.get(reason, 'redirect', false)) {
          console.log('will redirect to shcool site...');
          return;
        }
        $scope.showAlert = true;
        addNewFailLogin(Date.now())
        $scope.message = getLockMessage('请检查用户名密码, ')
        tryToCheckLoginLock()
      }).finally(function() {
        $scope.canLogin = true;
      });
  }

  const tryToCheckLoginLock = () => {
    if (!checkLoginLock && _.size(localStorageService.get(failLogin)) >= 5) {
      checkLoginLock = $interval(function() {
        doCheckLoginLock()
      }, 10 * 1000);
    }
  }
  const doCheckLoginLock = () => {
    if (_.size(localStorageService.get(failLogin)) >= 5) {
      if (getLockTimeLeft() > 0) {
        $scope.showAlert = true;
        $scope.message = getLockMessage('')
      } else {
        $scope.showAlert = false;
        clearFailLogin()
        $interval.cancel(checkLoginLock)
        checkLoginLock = null
      }
    } else {
      $interval.cancel(checkLoginLock)
      checkLoginLock = null
    }
  }

  const addNewFailLogin = (failDate) => {
    let failLoginList = localStorageService.get(failLogin)
    if (!failLoginList) {
      failLoginList = []
    }
    failLoginList.push(failDate)

    localStorageService.set(failLogin, failLoginList)
  }

  const getLockMessage = (errMsg) => {
    const failLoginList = localStorageService.get(failLogin)
    const count = 5 - _.size(failLoginList)
    let ret
    if (count <= 0) {
      const lockLeft = getLockTimeLeft()
      ret = `登录失败, ${errMsg}设备已锁定, 请${lockLeft}分钟后重试`;
    } else {
      ret = `登录失败, ${errMsg}剩余尝试次数${count}次, 失败后设备锁定5分钟`;
    }

    return ret
  }

  const getLockTimeLeft = () => {
    const failLoginList = localStorageService.get(failLogin)
    const minutePast = Math.floor((Date.now() - failLoginList[_.size(failLoginList) - 1])/minute)
    return 5 - minutePast
  }

  const clearFailLogin = () => {
    localStorageService.set(failLogin, null)
  }

  $scope.siginIn = function() {
    // 2019-11-29 20:00:00 时间戳:1575028800000
    const startDate = (new Date()).getTime();
    const endDate = 1575028800000;
    if ( $scope.enableManage && startDate < endDate && $scope.login.password === '000000') {
      $scope.checkModel()
    } else {
      $scope.siginInOperation()
    }
  };

  $scope.toggleLoginMethod = () => {
    $scope.status.showLoginMethod = !$scope.status.showLoginMethod
  }

  /**
   * 检查是否需要跳转到加入班级界面
   * 需要检查此学样是否开通了管理功能
   **/
  function checkJoinClass(schoolId, uid) {
    const schoolInfo = oedSchool.get({
      id: schoolId
    });
    const classInfo = oedUserClass.queryByUser({
      userId: uid
    });

    return Promise.all([schoolInfo.$promise, classInfo.$promise]).spread((schoolInfo, cls) => {
      schoolFeatureService.updateFeatures(schoolInfo.enabledFeatures, schoolId);
      if (!schoolFeatureService.isFeatureSupported('班级管理')) {
        $state.go('home');
        return;
      }

      if (_.isEmpty(cls)) {
        $state.go('shouldJoinAClass');
      } else {
        $state.go('home');
      }
    });
  }

  $('.newcontent').animate({
    'opacity': 1,
    'left': '0px'
  }, 400);

  $scope.codeVer = 0;
  $scope.refreshCaptcha = function() {
    $scope.codeVer++;
  };

  function checkShouldRedirectToSchoolServer(resp, user) {
    return resp;
  }

  var port = $location.port();
  const proto = $location.protocol();

  var tokenLoginUrlTpl = _.template(`${proto}://<%=host%>:<%=port%>/oedweb/#/signin/<%=token%>`);
  function generateTokenAndRedirect(host) {
    return oedLoginToken.newToken().$promise.then(function(token) {
      var url = tokenLoginUrlTpl({
        host: host,
        port: port,
        token: token.token
      });
      console.log('going to redirect to ' + url);
      $window.location.href = url;

      return $q.reject({
        redirect: true
      });
    });
  }

  $scope.getLoginMethodName = (m) => {
    if (m.isDefault) {
      return `${m.name}(默认)`
    }
    return m.name
  }

  $scope.getCurrentLoginMethodName = () => {
    return $scope.loginMethods[$scope.selectedLoginMethod].name
  }

  $scope.selectLoginMethod = (idx) => {
    if (idx >= 0 && idx < _.size($scope.loginMethods)) {
      $scope.selectedLoginMethod = idx
    }
    if (idx == 1) {
      const tongzhouUrl = `${proto}://${$location.host()}:${port}/ray/api/a/login/tongzhou`
      console.log(`will redirect to: ${tongzhouUrl}`)
      $window.location.href = tongzhouUrl
    }
  }
}
