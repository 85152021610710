import { Directive, ElementRef, Injector, Output, Input, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'oed-exercise-book-selector'
})
export class ExerciseBookSelector extends UpgradeComponent {
  @Input() public exerciseList: any;
  @Input() public exerciseBookId: string;
  @Input() public material: any;
  @Output() public onSelect: EventEmitter<any>;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('exerciseSelector', elementRef, injector);
  }
}
