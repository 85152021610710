import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { OedDialogService } from 'app/core/oed-dialog.service'
import { OedUibmodalService } from 'app/core/oed-uibmodal.service';
import { CreateTeachingModuleDTO, ImportedTempCourseDTO,
  LessonDTO, NavOptDTO, UserInfoDTO } from 'app/lesson/models/lesson.d'
import { CourseNavService } from 'app/lesson/services/course-nav.service';
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { LessonService } from 'app/lesson/services/lesson.service'
import { RecomendCourseService } from 'app/lesson/services/recomend-course.service';
import * as Promise from 'bluebird';
import * as _ from 'lodash';
import { forkJoin, Observable, Subscriber, Subject, Subscription } from 'rxjs';
import { flatMap, mergeMap, min, throttleTime } from 'rxjs/operators';
import { NotificationService } from 'app/shared/notification/notification.service';

/**
 * 这里是课程 推荐课程/我的课程，根据章节id获取
 */
@Component({
  selector: 'app-design-nav',
  template: require('./design-nav.component.html'),
  styles: [require('./design-nav.component.scss')],
  providers: [ CourseNavService ]
})
export class DesignNavComponent implements OnInit, OnDestroy {
  @Output() public onCourseSelected = new EventEmitter<LessonDTO>()
  @Output() public switchViewType = new EventEmitter<boolean>()
  @Input() public sectionId: number
  @Input() public coursepathId: number

  private recommendCourses: LessonDTO[] = []
  private mineCourses: LessonDTO[]
  private opts: NavOptDTO[]
  private userInfo: UserInfoDTO
  private selectedCourse: LessonDTO
  private selectedCourseIndex: number // 这个是当前选中的course的index
  private selectedIndex = 0 // 这个index是作为滚动的index，为当前最左边的元素
  private perViewCount = 0
  private subs = new Subscription()
  private allCourses: LessonDTO[]
  private tableViewTypeSelected = false
  private isTableSelectedLast = false
  private createCourseSubject = new Subject<number>()

  constructor(
    private lessonService: LessonService,
    private oedDialogService: OedDialogService,
    private oedUibModalService: OedUibmodalService,
    private lessonMessageService: LessonMessageService,
    private recommendCourseService: RecomendCourseService,
    private stateService: StateService,
    private trans: Transition,
    private courseNavService: CourseNavService,
    private notifyService: NotificationService
  ) {
    this.opts = [
      {
        text: '导入课程',
        type: 'import_course',
        doAction: () => this.doImportAction(),
      },
      {
        text: '新建课程',
        type: 'create_course',
        doAction: () => this.createCourse(),
      },
    ]
    const addCourseSub = this.lessonMessageService.addCourseAction$.subscribe((data: LessonDTO) => {
      const lessonData = {
        ...data,
        $$isNew: true
      }
      this.reloadData(lessonData)
    })
    const saveCourseSub = this.lessonMessageService.saveRecommendCourseAction$
    .subscribe(() => this.saveRecommendCourse())
    this.subs.add(addCourseSub)
    this.subs.add(saveCourseSub)
  }

  public ngOnDestroy() {
    this.subs.unsubscribe()
    this.createCourseSubject.unsubscribe()
  }

  private createCourse() {
    this.createCourseSubject.next(1)
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'sectionId')) {
      const current = this.stateService.$current
      this.isTableSelectedLast = current.includes['lessonDesign.recommendTableTeachingPattern'] ||
      current.includes['lessonDesign.mineTableTeachingPattern']
      this.reloadData()
    }
  }

  public ngOnInit() {
    this.createCourseSubject.asObservable()
    .pipe(throttleTime(1000))
    .subscribe(val => 
      this.recommendCourseService.createCourseWithModule(
        this.sectionId,
        _.toNumber(this.coursepathId),
        this.mineCourses, false, this.isCanStar()))
  }

  public doImportAction() {
    this.oedUibModalService.openCourseImportModal(this.selectedCourse, (res: any, deferred: any) => {
      const courseId = _.get(res, '0.course.id', -1)
      if (courseId === -1) {
        return Promise.resolve()
      }
      const importDTO: ImportedTempCourseDTO = {
        existingCourseId: courseId,
        coursepathId: this.coursepathId,
        relatedSections: [{
          sectionId: this.sectionId,
        }],
      }
      return this.lessonService.saveTmpCourse(importDTO).pipe(
        flatMap(() => {
          return this.lessonService.getCoursesBySectionId(this.sectionId, _.toNumber(this.coursepathId))
        })).toPromise().then((mineCourses: LessonDTO[]) => {
          const last = _.last(mineCourses)
          this.lessonMessageService.sendAddCourseAction(last)
        })
        .catch(() => {
        })
    })
  }

  public reloadData(newCourse: LessonDTO = null, deleted = false) {
      this.courseNavService.loadCourseData(this.sectionId, _.toNumber(this.coursepathId), newCourse)
      .subscribe((data: {allCourse: LessonDTO[]; lastCourse: LessonDTO; userInfo: UserInfoDTO}) => {
        this.allCourses = data.allCourse
        const courses = _.partition(this.allCourses, {isRecommend: true})
        this.recommendCourses = courses[0]
        this.mineCourses = courses[1]
        this.gotoCourse(deleted ? this.getNextSelectedCourseIfDeleted() : data.lastCourse)
        this.userInfo = data.userInfo
      })
  }

  public getNextSelectedCourseIfDeleted() {
    if (this.isCanStar()) {
      const index = this.selectedCourseIndex === 0 ? 0 : this.selectedCourseIndex - 1
      return _.get(this.mineCourses, index, this.recommendCourses[0])
    } else {
      return _.get(this.allCourses, this.selectedCourseIndex - 1, this.recommendCourses[0])
    }
  }

  public isCanStar() {
    if (!_.isEmpty(this.userInfo)) {
      return this.userInfo.canStar
    }
    return false
  }

  public gotoCourse(data: LessonDTO, viewType = '') {
    if (data) {
      if (this.isCanStar()) {
        this.selectedCourseIndex = _.findIndex(this.mineCourses, (c) => c.id === data.id)
      } else {
        this.selectedCourseIndex = _.findIndex(this.allCourses, (c) => c.id === data.id)
      }
      this.selectedIndex =  this.selectedCourseIndex - this.perViewCount + 2
      this.selectedIndex = _.max([this.selectedIndex, 0])
      this.selectedCourse = data
      let name = data.isRecommend ? 'lessonDesign.recommendTeachingPattern' : 'lessonDesign.mineTeachingPattern'
      if (viewType === 'table' || this.isTableSelectedLast) {
        name = data.isRecommend ? 'lessonDesign.recommendTableTeachingPattern' : 'lessonDesign.mineTableTeachingPattern'
      }
      this.stateService.go(name, {
        sectionId: this.sectionId,
        coursepathId: this.coursepathId,
        courseId: data.id,
      }, {
        location: 'replace'
      });
      this.onCourseSelected.emit({
        ...data,
      })
      this.tableViewTypeSelected = viewType === 'table' || this.isTableSelectedLast
      this.switchViewType.emit(this.tableViewTypeSelected)
      this.isTableSelectedLast = false
    }
  }

  public saveTmpCourse() {
    this.recommendCourseService.openConfirmModal(
      this.selectedCourse, _.toNumber(this.coursepathId),
      this.sectionId, this.mineCourses)
  }

  public createNewCourse() {
    if (this.selectedCourse.isRecommend) {
      this.saveRecommendCourse()
      return
    }

    this.createModule()
  }

  public deleteCourseContent(course: LessonDTO) {
    if (course.isRecommend) {
      this.saveRecommendCourse()
    } else {
      this.deleteCourse()
    }
  }

  public saveRecommendCourse() {
    this.recommendCourseService.openConfirmModal(
      this.selectedCourse, _.toNumber(this.coursepathId),
      this.sectionId, this.mineCourses,
      '是否保存？',
      `您需要先把"${this.selectedCourse.courseName}"保存为我的课程，才能继续编辑，是否保存？`)
  }

  public deleteCourse() {
    this.oedDialogService.openModalWithComponent('OedConfirmComponent', {
      title: '确定删除?',
      message: `确定要删除课程 "${this.selectedCourse.courseName}"?`,
    }, () => {
      this.delete()
    }, {
      class: 'modal-sm',
    })
  }

  public mineCourseIsEmpty() {
    return _.isEmpty(this.mineCourses)
  }

  public createModule() {
    const action = this.oedDialogService
    .openModalWithComponent2('CreateModuleComponent') as Observable<CreateTeachingModuleDTO>
    action.subscribe((createDTO: CreateTeachingModuleDTO) => {
      this.lessonService.sendCreateTeachingModuleAction(createDTO)
    }, (error) => {
      console.log(error)
      this.notifyService.notify('info', '创建教学环节失败')
    })
  }

  public next() {
    this.selectedIndex = this.selectedIndex + 1
    this.selectedIndex = _.min([this.selectedIndex,
      _.size(this.allCourses) + _.size(this.opts) - 1])
  }

  public prev() {
    this.selectedIndex = this.selectedIndex - 1
    this.selectedIndex = _.max([this.selectedIndex, 0])
  }

  public itemCountToFix(count: number) {
    this.perViewCount = count
  }

  public isPrevVisible() {
    return this.selectedIndex > 0
  }

  public isNextVisible() {
    return this.selectedIndex + this.perViewCount <
     _.size(this.allCourses) + _.size(this.opts)
  }

  public getAllCourseCount() {
    return _.size(this.allCourses) + _.size(this.opts)
  }

  public getOptNavId(i: number) {
    return _.size(this.allCourses) + i
  }

  private delete() {
    this.lessonService.deleteCourse(this.selectedCourse.id).subscribe(() => {
      this.reloadData(null, true)
    }, (error) => {
      console.error(error)
      this.notifyService.notify('info', '删除失败')
    })
  }

  private toTableView() {
    this.gotoCourse(this.selectedCourse, 'table')
  }
  private toCardView() {
    this.gotoCourse(this.selectedCourse, 'card')
  }
}
