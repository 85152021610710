/**
 * @fileOverview 定义module app.review
 * @name reviewCtrl.js
 * @author pangwa
 * @license
 */

require('app2/review/objTestPreviewer.ctrl');
require('app2/review/sbjTestPreviewer.ctrl');
require('app2/review/preresPreviewer.ctrl');
require('app2/review/filterClassSessions.ctrl');
(function() {
  angular.module('app.review', ['ui.router', 'ngCookies',
  'app.review.objTestPreviewer',
  'app.review.sbjTestPreviewer',
  'app.review.preresPreviewer',
  'app.review.filterClassSessions']);
})();
