(function() {
  require('assets/styles/classroom.scss');

  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.myClasses', {
        url: '/myClasses',
        template: require('assets/templates/manage/myClasses.html'),
        controller: 'myClassesCtrl',
        bodyStyle: 'bg_img'
      });
      $stateProvider.state('manage.editClassInfo', {
        url: '/class/:classId',
        template: require('assets/templates/manage/classInfo.html'),
        controller: 'editClassInfoCtrl',
        bodyStyle: 'bg_img',
        onEnter: editClassInfoOnEnter,
      });
      //$stateProvider.state('manage.joinClass', {
      //    url: '/joinClass',
      //    template: require('assets/templates/manage/joinClass.html'),
      //    controller: 'joinClassCtrl'
      //});
    }])
    .controller('myClassesCtrl', myClassesCtrl)
    .controller('editClassInfoCtrl', editClassInfoCtrl)
    .controller('joinClassCtrl', joinClassCtrl);

    editClassInfoOnEnter.$inject = ['$stateParams', '$state', 'oedClassroom', 'userInfo']
    function editClassInfoOnEnter($stateParams, $state, oedClassroom, userInfo) {
      userInfo.then((info) => {
        const schoolId = info.schoolId;
        const role = info.roles;
        if (!_.includes(role, 'system')) {
          oedClassroom.get({
            id: $stateParams.classId
          }).$promise.then((res) => {
            if (schoolId !== res.schoolId.toString()) {
              $state.go('noPermission');
            }
          }).catch(() => {
            $state.go('noPermission');
          });
        }
      });
    }

  myClassesCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal',
    'userInfo', 'oedLoading', 'oedUserClass', 'oedClassroom', 'notificationService', 'dialogs', 'oedOem', 'oedSchool', 'oedZhongZhiSchool']
  function myClassesCtrl(
    $scope,
    $state,
    $stateParams,
    $q,
    $uibModal,
    userInfo,
    oedLoading,
    oedUserClass,
    oedClassroom,
    notificationService,
    dialogs,
    oedOem,
    oedSchool,
    oedZhongZhiSchool
  ) {
    $scope.currentState.title = '我的班级';
    $scope.pageSize = 20;
    $scope.info = {};
    $scope.classType = 'myClass';
    $scope.enableManage = oedOem.curOem() != 'hal2'
    $scope.selectClassScope = function() {
      // if ($scope.classType == 'schoolClass') {
      //   $scope.showAll = true;
      // } else {
      //   $scope.showAll = false;
      // }
      // if ($scope.classType == 'archiveClass') {
      //   console.log('archiveClass');
      // }
      // if ($scope.classType == 'deleteClass') {
      //   console.log('deleteClass');
      // }
    };
    $scope.joinExistingClass = function() {
      $uibModal.open({
        template: require('assets/templates/manage/joinOrCreateClassDialog.html'),
        controller: 'joinClassCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {}
      });
    };
    $scope.createNewClass = function() {
      $uibModal.open({
        template: require('assets/templates/manage/createClassDialog.html'),
        controller: 'joinClassCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {}
      });
    };

    userInfo.then(function(info) {
      $scope.userInfo = info;
      oedSchool.getSchoolAdminsBySchoolIds([_.parseInt(info.schoolId)]).$promise.then(res => {
        $scope.uids = _.map(res[_.parseInt(info.schoolId)], 'uid').join(',');
      });
      _.assign($scope.info, _.pick(info, ['name', 'uid', 'isSchoolAdmin']));
      $scope.$watch('classType', function(newVal, oldVal) {
        $scope.curPage = 1;
        if (newVal == 'schoolClass') {
          $scope.classes = oedUserClass.queryAllBySchool({
            schoolId: info.schoolId,
            queryType: 1
          });
        } else if ($scope.classType == 'myClass') {
          $scope.classes = oedUserClass.queryByUser({
            userId: info.uid
          });
        } else if ($scope.classType == 'archiveClass') {
          $scope.classes = oedUserClass.queryAllBySchool({
            schoolId: info.schoolId,
            queryType: 3
          });
        } else if ($scope.classType == 'deleteClass') {
          $scope.classes = oedUserClass.queryAllBySchool({
            schoolId: info.schoolId,
            queryType: 2
          });
        }
        $scope.oedZhongZhi= oedZhongZhiSchool.isZhongZhiSchool();
        $scope.loading = $q.all([$scope.classes.$promise, $scope.oedZhongZhi]).then(function(data) {
          $scope.totalItems = _.size($scope.classes);
          updateShownClasses();
          $scope.isZhongZhiSchool = data[1];
        });
      });
    });

    function updateShownClasses() {
      var startIdx = $scope.pageSize * ($scope.curPage - 1);
      $scope.classes = _.orderBy($scope.classes, 'classTeacher', 'desc');
      $scope.visibleClasses = $scope.classes.slice(startIdx, startIdx + $scope.pageSize);
    }

    $scope.hoverClassList = function(cls) {
      cls.showBtn = true;
    };

    $scope.hoverOutOfClassList = function(cls) {
      cls.showBtn = false;
    };

    $scope.goToClassInfo = function(cls) {
      $state.go('manage.editClassInfo', {
        classId: cls.id
      });
    };

    $scope.onPageChange = function(page) {
      $scope.curPage = page;
      updateShownClasses();
    };

    $scope.isSystem = function() {
      if (!$scope.userInfo || !$scope.userInfo.roles)
        return false;

      return $scope.userInfo.roles.indexOf('system') >= 0;
    };

    $scope.isAdmin = function() {
      if (!$scope.userInfo || !$scope.userInfo.roles)
        return false;

      return $scope.userInfo.roles.indexOf('admin') >= 0;
    };

    $scope.isSchoolAdmin = function() {
      if (!$scope.userInfo || !$scope.userInfo.roles)
        return false;
      return _.includes($scope.uids, _.parseInt($scope.userInfo.uid));
    };

    $scope.recoveryDeleteClass = function (cls) {
      var dlg = dialogs.confirm('确定恢复?', '确定要恢复班级: "' + cls.levelName + cls.gradeName + cls.className + '"吗?');
      dlg.result.then(function(btn) {
        if ($scope.classType == 'deleteClass') {
          $scope.loading = oedClassroom.deleteOrRecoveryClass({
            classId: cls.id,
            deleteType: false
          }).$promise.then(function() {
            notificationService.notify('info', '成功恢复班级');
            $scope.classes = oedUserClass.queryAllBySchool({
              schoolId: $scope.userInfo.schoolId,
              queryType: 2
            });
            $scope.loading = $scope.classes.$promise.then(function() {
              $scope.totalItems = _.size($scope.classes);
              updateShownClasses();
            });
          }).catch(function() {
            $scope.joinErrorMessage = '发生未知错误';
          });
        } else if ($scope.classType == 'archiveClass') {
          $scope.loading = oedClassroom.recoveryClassFromArchive({
            classId: cls.id,
            archive: false
          },{
            classId: cls.id,
            archive: false
          }).$promise.then(function() {
            notificationService.notify('info', '成功恢复班级');
            $scope.classes = oedUserClass.queryAllBySchool({
              schoolId: $scope.userInfo.schoolId,
              queryType: 3
            });
            $scope.loading = $scope.classes.$promise.then(function() {
              $scope.totalItems = _.size($scope.classes);
              updateShownClasses();
            });
          }).catch(function() {
            $scope.joinErrorMessage = '发生未知错误';
          });
        }
      });
    };
  }

  editClassInfoCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$http', '$uibModal', 'Upload',
    'dialogs', 'notificationService', 'userInfo', 'oedUserInfo', 'oedClassUser', 'oedConfig',
    'oedClassroom', 'oedSubjects', 'oedClassTeacherSubjects', 'oedOem',
    'oedStudentGroup', 'oedStudentGroupScore', 'oedSchool', 'oedUserInfoUtils', 'oedZhongZhiSchool']
  function editClassInfoCtrl(
    $scope, $state, $stateParams, $q, $http, $uibModal, Upload,
    dialogs, notificationService, userInfo, oedUserInfo, oedClassUser, oedConfig,
    oedClassroom, oedSubjects, oedClassTeacherSubjects, oedOem,
    oedStudentGroup, oedStudentGroupScore, oedSchool, oedUserInfoUtils, oedZhongZhiSchool) {
    $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl;
    const classId = $stateParams.classId;
    $scope.classId = $stateParams.classId;
    $scope.currentState.title = '我的班级';
    $scope.enableManage = oedOem.curOem() != 'hal2'
    $scope.classInfo = oedClassroom.get({
      id: classId
    });

    $scope.deleteClass = function(cls, $event) {
      $event.stopPropagation();
      dialogs.confirm('删除班级', '确定要删除: ' + cls.levelName + cls.gradeName + cls.className + '吗?').result.then(function() {
        var code = cls.classCode;
        $scope.loading = oedClassroom.deleteOrRecoveryClass({
          classId: cls.id,
          deleteType: true
        }).$promise.then(function() {
          notificationService.notify('info', '成功删除班级')
          $state.go('manage.myClasses');
        }).catch(function() {
          $scope.joinErrorMessage = '发生未知错误';
        });
        // $scope.loading = oedClassroom['delete']({
        //   id: cls.id
        // }).$promise.then(function() {
        //   notificationService.notify('info', '成功删除班级')
        //   $state.go('manage.myClasses');
        // }).catch(function() {
        //   $scope.joinErrorMessage = '发生未知错误';
        // });
      });
    };

    $scope.curGroupIdx = -1;

    $scope.groups = oedStudentGroup.queryByClass({
      classId: classId
    });

    $scope.setGroupLeader = function ({student, role}) {
      oedStudentGroup.setGroupLeader({
        groupId: student.groupInfo.groupId,
        studentId: student.groupInfo.studentId,
        subGroupIdx: student.groupInfo.subGroupIdx,
        role: role ? 0 : 1
      }).$promise.then((res) => {
        return oedStudentGroup.queryByClass({
          classId: classId
        }).$promise.then((gps) => {
          $scope.groups = gps
        })
      });
    }

    $scope.getGroupButtonText = function() {
      if ($scope.curGroupIdx < 0) {
        return '全部学生';
      }

      return $scope.getGroupName($scope.curGroupIdx);
    };

    $scope.switchToGroup = function(index) {
      const group = $scope.groups[index]
      getCurrentYearScore(group)
      $scope.curGroupIdx = index
    };

    $scope.getGroupName = function(index) {
      const group = $scope.groups[index]
      if (group.groupName) {
        return group.groupName;
      } else {
        return '分组方案' + (index + 1);
      }
    };

    $scope.onDeleteGroup = function(idx, event) {
      const message = '确定删除' + $scope.getGroupName(idx) + '？';
      dialogs.confirm('删除分组方案', message)
        .result.then(() => {
          $scope.groups[idx].$delete().then(() => {
            $scope.groups.splice(idx, 1)
            if ($scope.curGroupIdx == idx) {
              $scope.curGroupIdx = -1
            } else if ($scope.curGroupIdx > idx) {
              $scope.curGroupIdx--
            }
          })
        })
    };

    $scope.onUpdateGroupName = function(groupIdx) {
      const group = $scope.groups[groupIdx]
      const modalInstance = $uibModal.open({
        template: require('assets/app2/manage/updateGroupName.html'),
        controller: 'updateGroupNameCtrl',
        size: 'sm',
        windowClass: 'signupModal',
        resolve: {
          config: function() {
            return {
              group: group,
              curIndex: groupIdx
            }
          }
        }
      });

      modalInstance.result.then((ret) => {
        $scope.groups[groupIdx] = ret.group
      })
    }

    $scope.onCreateGroup = function() {
      const modalInstance = $uibModal.open({
        template: require('assets/app2/manage/createGroup.html'),
        controller: 'createGroupCtrl',
        size: 'sm',
        windowClass: 'signupModal',
        resolve: {
          config: function() {
            return {
              classId: _.parseInt(classId),
              subGroupCount: 4
            }
          }
        }
      });

      modalInstance.result.then((ret) => {
        $scope.groups.push(ret.group)
        $scope.curGroupIdx = $scope.groups.length - 1
        $scope.isEditable = true
      })
    }

    function getCurrentYearScore(group) {
      const start = moment().startOf('years').format('x')
      const end = moment().endOf('years').format('x')
      if (group && group.id) {
        const data = {
          id: group.id,
          start: start,
          end: end
        }
        return oedStudentGroupScore.getScoreBySometime(data).$promise.then((res) => {
          if (res.length > 0) {
            $scope.isEditable = false
          } else {
            $scope.isEditable = true
          }
        })
      }
    }

    $scope.autoGroup = function(groupIdx) {
      if ($scope.isEditable) {
        const group = $scope.groups[groupIdx]
        const modalInstance = $uibModal.open({
          template: require('assets/app2/manage/createGroup.html'),
          controller: 'createGroupCtrl',
          size: 'sm',
          windowClass: 'signupModal',
          resolve: {
            config: function() {
              return {
                group: group,
                classId: _.parseInt(classId),
                subGroupCount: group.subGroupCount,
                groupName: group.groupName
              }
            }
          }
        });
        modalInstance.result.then((ret) => {
          $scope.groups[groupIdx] = ret.group
        })
      } else {
        return notificationService.notify('info', '本分组方案内有小组积分记录，暂不支持自动分组')
      }
    }

    $scope.onGroupUpdated = function(g) {
      saveGroup($scope.curGroupIdx, g)
    }

    function saveGroup(groupIdx, g) {
      $scope.loading = oedStudentGroup.save(g).$promise.then((newG) => {
        /**
         * 更新后要更新对应数据
         */
        $scope.groups[groupIdx] = newG
        return newG;
      });
    }
    function deleteChosenGroup(groupIdx, group, names, idx) {
      const newNames = [];
      _.forEach(names, function(obj, index) {
        if (obj.subGroupIdx < idx) {
          newNames.push(obj);
        } else if (obj.subGroupIdx > idx) {
          newNames.push(_.assign(obj, {subGroupIdx: obj.subGroupIdx - 1}));
        }
      });
      const reqParams = {id: _.get(group, 'id')};
      const reqBody = {group, names: newNames};
      $scope.loading = oedStudentGroup.deleteChosenGroup(reqParams, reqBody).$promise.then((newG) => {
        /**
         * 更新后要更新对应数据
         */
        $scope.groups[groupIdx] = newG
        return newG;
      });
    }
    $scope.onDeleteSubGroup = function({index, names}) {
      if ($scope.curGroupIdx < 0 || index < 0) {
        console.log('should not happen');
        return;
      }
      const message = '确定删除第' + (index + 1) + '小组？';
      dialogs.confirm('删除小组', message)
        .result.then(() => {
          const g = $scope.groups[$scope.curGroupIdx];
          g.subGroups.splice(index, 1);
          g.subGroupCount = g.subGroupCount - 1;
          deleteChosenGroup($scope.curGroupIdx, g, names, index)
        });
    }

    $scope.zoomQRCode = function(cls) {
      var classCode = cls.classCode;
      var modalInstance = $uibModal.open({
        template: require('assets/app2/manage/zoomQRCode.html'),
        controller: 'zoomQRCodeCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          data: function() {
            return classCode;
          }
        }
      });
    };

    $scope.isSystemAdmin = function() {
      if (_.includes($scope.info.roles, 'system')) {
        return true;
      } else {
        return false;
      }
    };

    $scope.isDisableManage = function(uid) {
      if($scope.isSystemAdmin() || $scope.info.isSchoolAdmin || $scope.classInfo.uid === $scope.info.uid) {
        return false;
      } else if (!$scope.classInfo.disableManage) {
        return !$scope.isInClass(uid);
      } else {
        return true;
      }
    };

    $scope.getStdAvatarImgSrc = function(std) {
      if ('female' == std.gender) {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_f1.png';
        } else {
          return 'Assets/images2/stu_avatar_f2.png';
        }
      } else {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_m1.png';
        } else {
          return 'Assets/images2/stu_avatar_m2.png';
        }
      }
    };

    $scope.subjects = [];
    $scope.info = {};
    $q.all([userInfo, $scope.classInfo.$promise]).then(function(data) {
      const info = data[0];
      _.assign($scope.info, _.pick(info, ['name', 'uid', 'isSchoolAdmin', 'roles']));

      $scope.subjects = oedSubjects.queryBySchoolId({
        schoolId: info.schoolId
      });

      oedSchool.getSchoolAdminsBySchoolIds([_.parseInt(info.schoolId)]).$promise.then(res => {
        $scope.uids = _.map(res[_.parseInt(info.schoolId)], 'uid').join(',');
      });

      $scope.schoolInfo = oedSchool.get({
        id: info.schoolId
      });

      oedZhongZhiSchool.isZhongZhiSchool().then((res) => {
        $scope.isZhongZhiSchool = res;
        if($scope.isZhongZhiSchool) {
          $scope.currentState.titleTwo = data[1].rxnd +'级'+ data[1].className
        } else {
          $scope.currentState.titleTwo = data[1].levelName + data[1].gradeName + data[1].className
        }
      })

      $scope.loading = $q.all([$scope.loading, $scope.subjects.$promise]).then(function() {
        var newSubjects = [];
        _.each($scope.info.subjects, function(sub) {
          var idx = _.findIndex($scope.subjects, function(s) {
            return s.id == sub.subjectId;
          });
          if (idx >= 0) {
            newSubjects.push($scope.subjects[idx]);
          }
        });
        $scope.info.subjects = newSubjects;
      });

      $scope.setClassSubject = function(subjects) {
        var subjectIds = _.map(subjects, function(sub) {
          return sub.id;
        });
        $scope.loading = oedClassTeacherSubjects.save({
          classId: classId,
          teacherId: info.uid,
          subjectIds: subjectIds
        }).$promise;
      };

      $scope.isClassAdmin = function(uid) {
        if (angular.isDefined(uid)) {
          return $scope.classInfo.uid == uid;
        } else {
          return $scope.classInfo.uid == info.uid;
        }
      };

      $scope.isSchoolAdmin = function(uid) {
        let uidArr = _.isEmpty($scope.uids) ? [] : $scope.uids.split(',');
        let ids = _.map(uidArr, i => _.parseInt(i));
        if (angular.isDefined(uid)) {
          return _.includes(ids, _.parseInt(uid));
        } else {
          return _.includes(ids, _.parseInt(info.uid));
        }
      };

      $scope.isCanManageStu = (uid) => {
        if (($scope.isClassAdmin() || $scope.isSchoolAdmin() || $scope.isSystemAdmin()) && $scope.enableManage) {
          return true;
        } else if ($scope.isInClass(uid) && !$scope.classInfo.disableManage && $scope.enableManage) {
          return true;
        } else {
          return false;
        }
      };

      $scope.sortableOptions.disabled = !$scope.isCanManageStu(info.uid);
    });

    $scope.sortableOptions = {
      disabled: false,
      stop: function(event) {
        var stus = _.map($scope.students, function(m, index) {
          return {
            id: m.uid,
            sort: index
          };
        });

        var res = new oedClassUser({
          classId: _.parseInt(classId),
          items: stus
        });

        $scope.loading = res.$updateItemOrder();
      }
    };
    $scope.hoverTeacher = function(item) {
      item.visibility = 'visible';
    };

    $scope.hoverOutOfTeacher = function(item) {
      item.visibility = 'hidden';
    };

    function reload() {
      var loadStudent = oedClassUser.queryClassStudents({
        classId: classId
      }).$promise.then(function(result) {
        $scope.students = result;
        $scope.studentsCount = _.size(result);
      });

      var loadTeacher = oedClassUser.queryClassTeachers({
        classId: classId
      }).$promise.then(function(result) {
        $scope.teachers = result;
        $scope.teachersCount = _.size(result);
      });

      $scope.loading = $q.all([$scope.loading, loadStudent, loadTeacher]);
    }

    reload();

    var tmplRemoveStu = _.template('确定要把"<%= name %>"从班级中移除吗?');
    $scope.removeStudent = function(stu, idx) {
      dialogs.confirm('移除学生', tmplRemoveStu(stu)).result.then(function() {
        $scope.loading = oedClassUser.delete({
          classId: classId,
          uid: stu.uid
        }).$promise.then(function() {
          $scope.students.splice(idx, 1);
        });
      });
    };
    var tmplRemoveTeacher = _.template('确定要把教师"<%= name %>"从班级中移除吗?');

    $scope.removeTeacher = function(tea, idx) {
      dialogs.confirm('移除教师', tmplRemoveStu(tea)).result.then(function() {
        $scope.loading = oedClassUser.delete({
          classId: classId,
          uid: tea.uid
        }).$promise.then(function() {
          $scope.teachers.splice(idx, 1);
        });
      });
    };

    $scope.importStudents = function(files) {
      if (_.isEmpty(files)) return;
      var url = oedConfig.url('classroom/' + classId + '/importstudents');
      $scope.loading = Upload.upload({
        url: url,
        file: files[0]
      }).then(function(resp) {
        var content = resp.data;
        if (_.isEmpty(content.errorList)) {
          $scope.importError = false;
          notificationService.notify('info', '学生导入成功')

          reload();
        } else {
          $scope.importError = true;
          $scope.emptyDataErrors = _.filter(content.errorList, function(err) {
            return err.type == 'emptyData';
          });

          $scope.idErrors = _.filter(content.errorList, function(err) {
            return err.type == 'alreadyAdded';
          });

          $scope.schoolNameErrors = _.filter(content.errorList, function(err) {
            return err.type == 'duplicateSchoolName';
          });
          $scope.nameChangedErrors = _.filter(content.errorList, function(err) {
            return err.type == 'nameChanged';
          });
        }
      });
    };

    $scope.setClassAdmin = function(tea) {
      var dlg = dialogs.confirm('转让班主任', '确定设置班主任为: "' + tea.name + '" 吗?');
      dlg.result.then(function() {
        oedClassroom.setClassAdmin({
          classId: classId,
          teacherId: tea.uid
        }).$promise.then(function() {
          $state.go('.', {}, {
            reload: true
          });
        });
      });
    };

    $scope.resetPassword = function(stu) {
      dialogs.confirm('重置密码', `确定重置学生${stu.name}的密码吗？`).result.then(function() {
        $scope.loading = oedUserInfo.resetPassword({
          id: stu.uid,
          resetThirdParty: oedOem.curOem() === 'hal2',
        });
        $scope.loading.$promise.then(function(resp) {
          let message = `重置学生:${stu.name}的密码失败,原因: ${resp.message}`
          if (resp.success) {
            message = `已将"${stu}"的密码重置为"${resp.data}"`
          }
          notificationService.notify('info', message)
        });
      });
    };

    $scope.isInClass = function(uid) {
      return _.findIndex($scope.teachers, function(tea) {
        return tea.uid == uid;
      }) >= 0;
    };

    $scope.getClassDisplayName = function() {
      var cls = $scope.classInfo;
      return cls.levelName + cls.gradeName + cls.className;
    };

    $scope.joinClass = function(cls) {
      dialogs.confirm('加入班级', '确定要加入: ' + $scope.getClassDisplayName() + '吗?').result.then(function() {
        var code = cls.classCode;
        $scope.loading = $http.get(oedConfig.url('classroom/joinbycode/' + code)).then(function(data) {
          const resp = data.data
          notificationService.notify('info', '成功加入班级')
          $scope.teachers = oedClassUser.queryClassTeachers({
            classId: classId
          });
          return $scope.teachers.$promise;
        }).catch(function() {
          $scope.joinErrorMessage = '发生未知错误';
        });
      });
    };

    $scope.removeClass = function(cls) {
      dialogs.confirm('删除班级', '确定要删除: ' + $scope.getClassDisplayName() + '吗?').result.then(function() {
        var code = cls.classCode;
        $scope.loading = oedClassroom['delete']({
          id: cls.id
        }).$promise.then(function() {
          notificationService.notify('info', '成功删除班级')
          $state.go('manage.myClasses');
        }).catch(function() {
          $scope.joinErrorMessage = '发生未知错误';
        });
      });
    };

    $scope.leaveClass = function(cls) {
      dialogs.confirm('离开班级', '确定要离开: ' + $scope.getClassDisplayName() + '吗?').result.then(function() {
        var code = cls.classCode;
        $scope.loading = oedClassroom.removeUserFromClass({
          classId: cls.id,
          uid: $scope.info.uid
        }).$promise.then(function() {
          notificationService.notify('info', '成功离开班级')
          $scope.teachers = oedClassUser.queryClassTeachers({
            classId: classId
          });
          return $scope.teachers.$promise;
        }).catch(function() {
          $scope.joinErrorMessage = '发生未知错误';
        });
      });
    };

    $scope.editClass = function(cls) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/manage/editClassInfoDialog.html'),
        controller: 'editClassInfoDialogCtrl',
        size: 'sm',
        windowClass: 'oed-common-modal manage-modal center',
        resolve: {
          curClass: function() {
            return cls;
          }
        }
      });
      modalInstance.result.then(function (res) {
        $scope.currentState.titleTwo = res.levelName + res.gradeName + res.className;

      })
    };

    $scope.editStudentInfo = function(stu, idx) {
      var modalInstance = $uibModal.open({
        template: require('assets/app2/manage/editStudentInfo.html'),
        controller: 'editStudentInfoCtrl',
        size: 'md',
        windowClass: 'oed-common-modal manage-modal center',
        resolve: {
          data: function() {
            return {
              student: stu
            };
          }
        }
      });

      modalInstance.result.then(function(info) {
        _.assign(stu, _.pick(info, ['name', 'idNo', 'gender','writingPadId']));
      });
    };

    $scope.addStudent = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/app2/manage/addStudent.html'),
        controller: 'addStudentCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          data: function() {
            return {
              classId: classId
            };
          }
        }
      });

      modalInstance.result.then(function(info) {
        var loadStudent = oedClassUser.queryClassStudents({
          classId: classId
        }).$promise.then(function(result) {
          $scope.students = result;
          $scope.studentsCount = _.size(result);
        });

        $scope.loading = loadStudent;
      });
    };

    $scope.addExistingStudent = function(addStudent = true) {
      var modalInstance = $uibModal.open({
        template: require('assets/app2/manage/addExistingStudent.html'),
        controller: 'addExistingStudentCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          data: function() {
            return {
              classId: classId
            };
          },
          role: function () {
            return {
              name: addStudent ? 'student' : 'teacher'
            }
          }
        }
      });

      modalInstance.result.then(function(info) {
        let loadStudent
        if (addStudent) {
          loadStudent = oedClassUser.queryClassStudents({
            classId: classId
          }).$promise.then(function(result) {
            $scope.students = result;
            $scope.studentsCount = _.size(result);
          });
        } else {
          loadStudent = oedClassUser.queryClassTeachers({
            classId: classId
          }).$promise.then(function(result) {
            $scope.teachers = result;
            $scope.teachersCount = _.size(result);
          });
        }
        $scope.loading = loadStudent;
      });

    };

    $scope.integralManagement = (id) => {
      const modalInstance = $uibModal.open({
        template: require('assets/app2/manage/integralManagement.html'),
        controller: 'integralManagementCtrl',
        size: 'lg',
        windowClass: 'shouldHideOnPreview cl-library-dialog2 modalCenter',
        resolve: {
          data: () => ({
            students: $scope.students,
            groups: $scope.groups,
            curGroupIdx: $scope.curGroupIdx,
            doRefresh: () => {
              getCurrentYearScore($scope.groups[$scope.curGroupIdx])
            }
          })
        }
      })
    }

  }

  joinClassCtrl.$inject = ['$scope', '$state', '$q', '$http', 'userInfo', 'oedConfig', 'oedUserClass',
    'oedSchoolLevel', 'oedSchoolLevelGrade', 'oedClassroom', '$uibModalInstance', '$uibModal', 'oedZhongZhiSchool']
  function joinClassCtrl($scope, $state, $q, $http, userInfo, oedConfig, oedUserClass,
    oedSchoolLevel, oedSchoolLevelGrade, oedClassroom, $uibModalInstance, $uibModal, oedZhongZhiSchool) {
    if ($scope.currentState)
      $scope.currentState.title = '我的班级';
    const ZhongZhiLevelName = oedZhongZhiSchool.levelName();
    // overwrite 继承来的属性
    $scope.loading = null;
    $scope.datePicker = {
      opened: false
    };
    $scope.newClass = {};
    $scope.selClass = {};
    $scope.cancel = function(result) {
      $uibModalInstance.close(result);
    };

    $scope.dateOpt = {
      minMode: 'year'
    };

    $scope.joinType = 'joinByName';
    $scope.selectJoinType = function() {
      $scope.joinErrorMessage = null;
    };
    $scope.open = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.datePicker.opened = true;
    };
    userInfo.then(function(info) {
      oedZhongZhiSchool.isZhongZhiSchool().then((res) => {
        $scope.isZhongZhiSchool = res;
        $scope.levels = oedSchoolLevel.querySchoolLevels({
          schoolId: info.schoolId
        });
        $scope.levels.$promise.then(function(lvls) {
          const levelId = _.get(_.find(lvls, item => item.levelName === ZhongZhiLevelName), 'id')
          $scope.newClass.levelName = $scope.isZhongZhiSchool ? ZhongZhiLevelName: lvls[0].levelName;
          $scope.selClass.levelName = $scope.isZhongZhiSchool ? ZhongZhiLevelName: lvls[0].levelName;

          if($scope.isZhongZhiSchool) {
            $scope.selClass.level = {
              levelName: ZhongZhiLevelName,
              id: levelId
            }

            $scope.newClass.level = {
              levelName: ZhongZhiLevelName,
              id: levelId
            }
          }

          $scope.createClass = function(cls) {
            $scope.showCreateErrors = true;
            if ($scope.createClassForm.$invalid) {
              return;
            }
            
            if($scope.isZhongZhiSchool) {
              cls.level = {
                id: levelId,
                levelName: ZhongZhiLevelName,
              }
            }
    
            $scope.loading = oedClassroom.queryClassByDetails({
              schoolId: info.schoolId,
              rxnd: cls.year.getFullYear(),
              levelId: cls.level.id,
              name: cls.className + '班'
            }).$promise.then(function(resp) {
              if (resp.classId !== undefined) {
                $scope.ceateErrorMessage = '班级已存在，';
                $scope.createErrorCausedByExisting = true;
                $scope.conflictClass = resp;
              } else {
                $scope.ceateErrorMessage = null;
                $scope.createErrorCausedByExisting = false;
                return oedClassroom.save({
                  schoolId: info.schoolId,
                  levelName: cls.level.levelName,
                  rxnd: cls.year.getFullYear(),
                  className: cls.className + '班'
                }).$promise.then(function(resp) {
                  $scope.ceateErrorMessage = null;
                  $scope.cancel();
                  $state.go('manage.editClassInfo', {
                    classId: resp.id
                  });
                }).catch(function(resp) {
                  if (resp.status == 400) {
                    $scope.ceateErrorMessage = '创建班级失败, 请检查输入!';
                  } else {
                    $scope.ceateErrorMessage = '创建班级失败, 发生未知错误!';
                  }
                });
              }
            }).catch(function(resp) {
              if (resp.status == 400) {
                $scope.ceateErrorMessage = '创建班级失败, 请检查输入!';
              } else {
                $scope.ceateErrorMessage = '创建班级失败, 发生未知错误!';
              }
            });
          };

        });
        
      })
      $scope.$watch('selClass.level', function(newVal) {
        if (!newVal) return;
        //
        // load grade list
        $scope.grades = oedSchoolLevelGrade.queryByLevelId({
          schoolId: info.schoolId,
          levelId: newVal.id
        });
        $scope.joinClassLoading = $scope.grades.$promise.then(function(grades) {
          var newClass = {
            gradeName: '创建班级',
            id: -1
          };
          if (_.isEmpty($scope.grades)) {
            $scope.grades = [];
          } else {
            $scope.selClass.grade = null;
          }
        });
      });
      $scope.selectClassName = function() {
        //$scope.selClass.curClass = JSON.parse($scope.selClass.curClassAsString);
        if ($scope.selClass.curClass.id == -1) {
          $uibModal.open({
            template: require('assets/templates/manage/createClassDialog.html'),
            controller: 'joinClassCtrl',
            size: 'md',
            windowClass: 'signupModal',
            resolve: {}
          });
          $scope.cancel();
        }
      };
      $scope.$watch('selClass.grade', function(newVal) {
        $scope.classes = [];
        if (!newVal) return;
        if (newVal.id == -1) {
          $uibModal.open({
            template: require('assets/templates/manage/createClassDialog.html'),
            controller: 'joinClassCtrl',
            size: 'md',
            windowClass: 'signupModal',
            resolve: {}
          });
          $scope.cancel();
        }
        $scope.classes = oedClassroom.queryByLevelAndGradeId({
          schoolId: info.schoolId,
          levelId: $scope.selClass.level.id,
          gradeId: $scope.selClass.grade.id
        });

        $scope.joinClassLoading = $scope.classes.$promise.then(function(classes) {
          //$scope.selClass.curClass = classes[0];
          if (_.isEmpty($scope.classes)) {
            $scope.classes = [];
          }
        });
      });
    });

    $scope.joinClassByCode = function(code, fromId) {
      if (!fromId && $scope.joinClassByCodeForm.$invalid) {
        $scope.showJoinErrorMsg = true;
        return;
      }

      $http.get(oedConfig.url('classroom/joinbycode/' + code)).then(function(data) {
        const resp = data.data
        if (!resp.joined) {
          $scope.joinErrorMessage = resp.message;
        } else {
          $scope.cancel();
          $state.go('manage.editClassInfo', {
            classId: resp.classId
          });
        }
      }).catch(function() {
        $scope.joinErrorMessage = '发生未知错误';
      });
    };

    $scope.joinClassById = function(cls) {
      if (!cls || $scope.joinClassbyIdForm.$invalid) {
        $scope.showJoinErrorMsg = true;
        return;
      }
      $scope.joinClassByCode(cls.classCode, true);
    };

    $scope.joinClassFromCreating = function() {
      if ($scope.conflictClass !== undefined) {
        $http.get(oedConfig.url('classroom/joinbycode/' + $scope.conflictClass.classCode)).then(function(data) {
          const resp = data.data
          if (!resp.joined) {
            $scope.createErrorCausedByExisting = false;
            $scope.ceateErrorMessage = resp.message;
          } else {
            $scope.cancel();
            $state.go('manage.editClassInfo', {
              classId: resp.classId
            });
          }
        }).catch(function() {
          $scope.createErrorCausedByExisting = false;
          $scope.ceateErrorMessage = '发生未知错误';
        });
      }
    };
  }
})();
