(function () {
    var messages = require('app2/utils/messages');
    var moment = require('moment');
    var _ = require('lodash');
    var resItemPreview = require('app2/directives/cloud/resItemPreview.directive');
    require('assets/styles/prepareLesson.scss')

    angular.module('app.prepareCourse')
        .controller('previewIntelligentPaperCtrl', previewIntelligentPaperCtrl);

    previewIntelligentPaperCtrl.$inject = ['$scope', '$q', 'oedConfig', '$uibModalInstance', 'userInfo', 'data', 'ids', 'oedExamination']
    function previewIntelligentPaperCtrl($scope, $q, oedConfig, $uibModalInstance, userInfo, data, ids, oedExamination) {
        $scope.basePath = oedConfig.base();

        $scope.onSelChanged = function(changes) {
        }
        $scope.close = function() {
            $uibModalInstance.close({import: false});
        }
        userInfo.then(function(user) {
            $scope.userInfo = user;
        });
        $scope.types = {
            'singlechoice': '单选',
            'multichoice': '多选',
            'yesorno': '判断',
            'fill': '填空',
            'connect': '匹配',
            'vote': '投票',
            'synthetic': '综合',
            'qa': '问答'
          };
        $scope.doImportPaper = function() {
            $scope.loading = $q(function(resolve, reject) {
                const addParams = {
                    coursepathId: Number(ids.coursepathId),
                    duration: 120,
                    sort: 1,
                    name: '推荐试卷',
                    relatedSections: [Number(ids.sectionId)],
                    sectionMode: 'outline',
                    teacherId: Number($scope.userInfo.uid)
                }
                oedExamination.addTestPaper(addParams).$promise
                .then(exam => {
                    $scope.exam = exam
                    var keys = _.uniq(_.map(data, 'type'))
                    const parts = _.map(keys, (key, index) => {
                        return {
                            name: _.get($scope.types, key),
                            examId: $scope.exam.id,
                            questionDetails: [],
                            score: 0,
                            sort: index,
                            questionNumber: 0
                        }
                    })
                    const newPartParams = {
                        duration: 120,
                        id: $scope.exam.id,
                        name: $scope.exam.name,
                        parts,
                        sectionIds: [Number(ids.sectionId)]
                    }
                    return oedExamination.updateParts(newPartParams).$promise
                })
                .then(newPart => {
                    $scope.exam = newPart
                    var parts = $scope.exam.parts
                    var keys = _.uniq(_.map(data, 'type'))
                    const inserts = _.map(keys, key => {
                        var dataInType = _.filter(data, d => d.type === key)
                        const id = _.get(_.findLast(parts, p => p.part.name === _.get($scope.types, key)), 'part.id');
                        const dataFormatted = _.map(dataInType, (dt, idx) => {
                            return {question: dt, relatedOutlines: [], sort: idx}
                        })
                        return oedExamination.createExamQuestion({id}, dataFormatted).$promise;
                    })
                    return $q.all(inserts);
                })
                .then(final => {
                    $uibModalInstance.close({import: true})
                })
                .catch(error => {
                    console.error(error);
                })
            });
        }

        $scope.quesionts = data
    }

})();
