(function() {

  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentTasks.courseTasks', {
        url: '/:coursePathId/tasks',
        template: require('assets/templates/student/studentCourseTasks.html'),
        controller: 'studentCourseTasksCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('studentCourseTasksCtrl', studentCourseTasksCtrl);

  studentCourseTasksCtrl.$inject = ['$scope', '$stateParams', '$q', 'userInfo', 'oedCoursePath',
    'oedTaskList', 'oedTaskListPublishRecord']
  function studentCourseTasksCtrl($scope, $stateParams, $q, userInfo, oedCoursePath,
    oedTaskList, oedTaskListPublishRecord) {
    $scope.coursePathId = _.parseInt($stateParams.coursePathId);
    $scope.currentCoursePath.id = $scope.coursePathId;
    // $scope.curState.coursePathId = $scope.coursePathId;

    // modules:
    //  - $scope.coursePathId: Long
    //  - $scope.userId: Long
    //
    //  - $scope.coursePath:  CoursePathDTO
    //  - $scope.totalPublishRecordCount: Int
    //  - $scope.visiblePublishRecords:  Seq[TaskListPublishRecordDTO]
    //  --              .pubInfo:  PubInfoOfStudent
    //  ---                     .taskList:  TaskListDTO
    //  ---                     .taskCount: Int
    //  ---                     .isReviewed:  Boolean
    //  ---                     .submitTaskCount: Int
    //  ---                     .submitProgress: Int (derived)
    //

    // 入口
    userInfo.then(function(user) {
      $scope.userId = user.uid;

      $scope.loading = $q.all([$scope.loadCoursePath(), $scope.loadPublishRecordCount()]).then(function() {
        $scope.curPage = 1;
        $scope.loadVisiblePublishRecords();
      });
    });

    $scope.selectFilter = function(filter) {
      if (filter == $scope.curState.selectedFilter) {
        return;
      }
      $scope.curState.selectedFilter = filter;

      return $scope.loadPublishRecordCount().then(function() {
        $scope.curPage = 1;
        return $scope.loadVisiblePublishRecords();
      });

    };

    $scope.onPageChange = function(curPage) {
      $scope.loading = $scope.loadVisiblePublishRecords();
    };

    $scope.loadVisiblePublishRecords = function() {
      var queryPublishRecords = oedTaskListPublishRecord.queryByCoursePathAndUid({
        coursePathId: $scope.coursePathId,
        uid: $scope.userId,
        startIndex: $scope.pageSize * ($scope.curPage - 1),
        count: $scope.pageSize,
        status: $scope.curState.selectedFilter.value
      });

      return queryPublishRecords.$promise.then(function(records) {
        $scope.visiblePublishRecords = records;

        var loadPubInfo = _.map(records, function(r) {
          return $scope.loadPublishRecordPubInfo(r);
        });

        return $q.all(loadPubInfo);
      });
    };

    $scope.loadCoursePath = function() {
      if ($scope.coursePathId === 0) {
        $scope.coursePath = {
          id: 0,
          name: '全部作业'
        };

        return $scope.coursePath;
      } else {
        var queryCoursePath = oedCoursePath.get({
          id: $scope.coursePathId
        });

        return queryCoursePath.$promise.then(function(cp) {
          $scope.coursePath = cp;
          return $scope.coursePath;
        });
      }
    };

    $scope.loadPublishRecordCount = function() {
      var queryPublishRecordCount = oedTaskListPublishRecord.queryCountByCoursePathAndUid({
        coursePathId: $scope.coursePathId,
        uid: $scope.userId,
        status: $scope.curState.selectedFilter.value
      });

      return queryPublishRecordCount.$promise.then(function(c) {
        $scope.totalPublishRecordCount = c.count;
        return $scope.totalPublishRecordCount;
      });
    };

    $scope.loadPublishRecordPubInfo = function(record) {
      var queryPubInfo = oedTaskListPublishRecord.queryStudentPublishInfo({
        id: record.id,
        stdId: $scope.userId
      });

      return queryPubInfo.$promise.then(function(pubInfo) {
        record.pubInfo = pubInfo;

        pubInfo.submitProgress = 0;
        if (pubInfo.taskCount > 0) {
          pubInfo.submitProgress = (pubInfo.submitTaskCount * 100 / pubInfo.taskCount).toFixed(0);
        }
      });
    };

    $scope.isPublishRecordDueDateEmpty = function(record) {
      return !record.dueDate;
    };

    $scope.isPublishRecordDone = function(record) {
      if (!_.isEmpty(record.pubInfo))
        return record.pubInfo.submitTaskCount == record.pubInfo.taskCount;
      else
        return false;
    };

    $scope.isPublishRecordFinished = function(record) {
      if (!record.dueDate) {
        return false;
      }

      return record.dueDate <= Date.now();
    };

    $scope.getRemainingDays = function(record) {
      if (!record.dueDate) {
        return 0;
      }

      return parseInt((record.dueDate - Date.now()) / (24 * 60 * 60 * 1000)) + 1;
    };
  }
})();
