/**
 * @fileOverview 这两个directive是为了修复Chrome的一个网络bug,
 *  就是video元素在被移除时网络连接会始终处于未完成的状态, 这会导致
 *  所有的后续请求被排队无法完成
 *  TODO: 等此bug修复后移除这个fix
 *  https://bugs.chromium.org/p/chromium/issues/detail?id=597166
 * @name videoFix.js
 * @author pangwa
 * @license
 */
(function() {
  var videoFix = function(scope, element, attrs) {
    scope.$on('$destroy', function() {
      //
      // 当scope被移除时(video 元素将被销毁) 设置src为空
      // 这会导致当前下载中的连接中止
      element.attr('src', '');
    });
  };

  angular.module('app.directives', [])
    .directive('video', function() {
      return {
        restrict: 'E',
        link: videoFix
      };
    })
    .directive('audio', function() {
      return {
        restrict: 'E',
        link: videoFix
      };
    });
})();
