import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild, OnDestroy, OnChanges } from '@angular/core'
import { LessonDTO, TestOrPresentDTO } from 'app/lesson/models/lesson.d'
import { ModuleDropEventHandler, ModuleItemDragService } from 'app/lesson/module-item-drag.service';
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { LessonService } from 'app/lesson/services/lesson.service'
import * as _ from 'lodash'
import { DragulaService } from 'ng2-dragula'
import { forkJoin, Subscription} from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-item-resource',
  template: require('./item-resource.component.html'),
  styles: [require('./item-resource.component.scss')]
})
export class ItemResourceComponent implements OnInit, ModuleDropEventHandler, OnDestroy, OnChanges {
  @Input() public courseId: number
  public isShow = true;
  public subs = new Subscription()
  @ViewChild('itemContainer') public itemsContainer: ElementRef;

  public testDTOs: TestOrPresentDTO[]
  constructor(
    public lessonService: LessonService,
    private lessonMessageService: LessonMessageService,
    private moduleItemDrag: ModuleItemDragService
    ) {
      const reloadSub = this.lessonMessageService.reloadCourseResesActionAction$
      .subscribe((tests) => {
        this.testDTOs = [
          ...tests,
        ]
      })
      this.subs.add(reloadSub)
    }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
  }

  public ngAfterViewInit() {
    this.moduleItemDrag.register(this)
  }

  public ngOnDestroy() {
    this.moduleItemDrag.unregister(this)
    this.subs.unsubscribe()
  }

  public container() {
    return this.itemsContainer.nativeElement
  }
  public accepts(el: any, source: any, handle: any, sibling: any) {
    return false
  }

  public onDrop(el: any, target: any): boolean {
    return false
  }
}
