import Promise = require('bluebird')
import angular = require('angular');
import * as _ from 'lodash';

require('assets/styles/course.css');
const { weikyueyiValidVersionNames } = require('app2/prepareCourse/cloud/utils/libraryUtils')

angular.module('app.directives.course.exerciseSelector', [])
  .component('exerciseSelector', {
    bindings: {
      material: '<',
      exerciseList: '<',
      exerciseBookId: '<',
      onSelect: '&'
    },
    controller: exerciseSelector,
    template: <any>require('./exerciseSelector.html'),
  })

exerciseSelector.$inject = ['oedCoursePath']
function exerciseSelector(oedCoursePath: any) {

  const ctrl = this;

  ctrl.isOpen = false
  ctrl.dynamicPopover = { template: 'popoverTemplateExercise' }

  ctrl.subjectList = []
  ctrl.allVersions = []
  ctrl.versionList = []
  ctrl.bookList = []

  ctrl.currentSbj = {}
  ctrl.currentVer = {}
  ctrl.currentBook = {}

  this.$onChanges = (changeObj: any) => {
    if (_.has(changeObj, 'exerciseList')) {
      initData()
    }
  }

  function initData() {
    Promise.all([
      oedCoursePath.getAllSubjects().$promise,
      oedCoursePath.getAllVersions().$promise,
      oedCoursePath.getAllLgvs().$promise,
    ]).then(([sbj, ver, lgv]) => {
      const exerciseSbjIds = _.chain(ctrl.exerciseList).map('subjectId').uniq().value()
      const exerciseVerIds = _.chain(ctrl.exerciseList).map('versionId').uniq().value()
      ctrl.subjectList = _.filter(sbj, s => _.includes(exerciseSbjIds, s.id))
      ctrl.allVersions = _.filter(ver, v => _.includes(exerciseVerIds, v.versionId))
      ctrl.allLgvs = lgv
      onDefSelected()
    })
  }

  function onDefSelected() {
    const selectedSbjId = _.get(ctrl.material, 'subjectId', 0)
    ctrl.currentSbj = _.find(ctrl.subjectList, s => s.id === selectedSbjId)
    if (!ctrl.currentSbj) {
      ctrl.currentSbj = ctrl.subjectList[0]
    }
    loadVer()
  }

  ctrl.onSbjClicked = (sbj) => {
    ctrl.currentSbj = sbj
    loadVer()
  }

  function loadVer() {
    const selectedVerId = _.get(ctrl.material, 'versionId', 0)
    const glvs = _.chain(ctrl.allLgvs)
      .filter(l => _.get(l, 'subjectId', '') === _.get(ctrl.currentSbj, 'id', ''))
      .map(r => r.versionId)
      .uniq()
      .value()
    ctrl.versionList = _.filter(ctrl.allVersions, r => _.includes(glvs, r.versionId))
    ctrl.currentVer = _.find(ctrl.versionList, v => v.versionId === selectedVerId)
    if (!ctrl.currentVer) {
      ctrl.currentVer = ctrl.versionList[0]
    }
    ctrl.onVersionClicked(ctrl.currentVer)
  }

  ctrl.onVersionClicked = (ver) => {
    ctrl.currentVer = ver
    ctrl.bookList = _.filter(ctrl.exerciseList, e => (e.subjectId === ctrl.currentSbj.id) && (e.versionId === ctrl.currentVer.versionId))
    ctrl.currentBook = _.head(ctrl.bookList)
  }

  ctrl.onBookClicked = (book) => ctrl.currentBook = book

  ctrl.confirm = () => {
    ctrl.onSelect({ book: ctrl.currentBook })
    ctrl.isOpen = false
  }

  ctrl.close = () => ctrl.isOpen = false

  ctrl.tryToOpen = () => {
    if (!ctrl.isOpen) ctrl.isOpen = true
    else ctrl.isOpen = false
  }
}
