import * as _ from 'lodash'
require('assets/app/controllers/recycleBinDialog/rePreCard/rePreCard.scss');

angular.module('app.recycle.rePreCard', [])
  .component('rePreCard', {
    bindings: {
      data: '<',
      onRecoveryPre: '&',
      onDeletePre: '&',
    },
    controller: rePreCardCtrl,
    template: < string > require('./rePreCard.html'),
  })

rePreCardCtrl.$inject = ['dialogs', 'oedCourse', 'notificationService']
function rePreCardCtrl(dialogs, oedCourse, notificationService) {
  const ctrl = this


}
