import * as angular from 'angular';
import * as testIconUtils from 'app2/utils/testIconUtils';
import Promise = require('bluebird');
import * as _ from 'lodash';
const dateUtils: any = require('app2/utils/dateUtils');

angular.module('app.directives.review.preres.preresCompletionPreviewer', [])
.component('preresCompletionPreviewer', {
  bindings: {
    classSessionId: '<',
    endTime: '<',
    isPreresItemSession: '<',
    preResSessionId: '<',
    showOnlyTestName: '<',
    startTime: '<',
    testName: '<',
    pubStudentCount: '<',
  },
  controller: preresCompletionPreviewerCtrl,
  template: <string> require('./preresCompletionPreviewer.html'),
});

preresCompletionPreviewerCtrl.$inject = ['oedObjectiveTestSessionStats', 'oedViewLog', 'oedPreResSessionStudent', 'oedPreResItemSession']
function preresCompletionPreviewerCtrl(
  oedObjectiveTestSessionStats: any, oedViewLog: any,
  oedPreResSessionStudent: any, oedPreResItemSession: any) {
  const ctrl = this;

  const loadData = () => {
    loadStudents().then(() => {
      return loadSubmitCount()
    }).then(() => {
      calculateCompleteRate()
    })
  }
  const queryItemSessionStds = () => {
    return oedPreResItemSession.queryByClassSessionId({
      classSessionId: ctrl.classSessionId,
      preResItemSessionId: ctrl.preResSessionId,
    })
  }
  const queryPreresSessionStds = () => {
    return oedPreResSessionStudent.queryByPreResSessionId({
      sessionId: ctrl.preResSessionId,
    })
  }
  const stdCount = (students: any) => {
    ctrl.totalStudentCount = ctrl.classSessionId ? _.size(students) : ctrl.pubStudentCount
    ctrl.onlineStudentCount = _.reduce(students, (sum: any, std: any) => {
      if (std.wasOnline) {
        sum ++
      }
      return sum
    }, 0)
  }
  const loadStudents = () => {
    const students = ctrl.isPreresItemSession ? queryItemSessionStds() : queryPreresSessionStds()
    return students.$promise.then(() => stdCount(students))
  }
  const loadSubmitCount = () => {
    if (_.isUndefined(ctrl.classSessionId)) {
      return oedObjectiveTestSessionStats.getHotCountOfPresentResource({
        sessionId: ctrl.preResSessionId,
      }).$promise.then((summary: any) => {
        ctrl.totalSubmit = summary.totalSubmit;
      })
    } else {
      if (ctrl.isPreresItemSession) {
        return Promise.resolve(ctrl.totalSubmit = ctrl.onlineStudentCount);
      } else {
        return oedViewLog.queryViewLogBySessionId({
          sessionId: ctrl.preResSessionId,
        }).$promise.then((logs: any) => {
          ctrl.totalSubmit = _.size(_.groupBy(logs, 'uid'));
        })
      }
    }
  }

  ctrl.$onInit = () => {
    ctrl.format = ctrl.classSessionId ? 'HH:mm' : 'MM月dd号';
    loadData();
  }

  ctrl.$onChanges = (changeobj: any) => {
    let load = false;
    if (_.has(changeobj, 'preResSessionId')) {
      ctrl.preResSessionId = changeobj.preResSessionId.currentValue;
      if (!_.isNil(ctrl.preResSessionId)) {
        load = true;
      }
    }
    if (_.has(changeobj, 'isPreresItemSession')) {
      ctrl.isPreresItemSession = changeobj.isPreresItemSession.currentValue;
      if (!_.isNil(ctrl.isPreresItemSession)) {
        load = true;
      }
    }

    if (load) {
      loadData();
    }
  }

  function calculateCompleteRate() {
    const totalStudent = ctrl.classSessionId ? ctrl.onlineStudentCount : ctrl.totalStudentCount
    if (totalStudent === 0) {
      ctrl.submitRate = 0
    } else {
      ctrl.submitRate = ctrl.totalSubmit * 1.0 / totalStudent
    }
    ctrl.submitRateInPercentage = isNaN(ctrl.submitRate) ? 0 : (ctrl.submitRate * 100).toFixed(0);
  }

  this.getSummaryImage = (prefix: string) => {
    return testIconUtils.getSummaryImage(prefix, ctrl.submitRate);
  };

  this.isGoodTest = () => {
    return testIconUtils.isGoodTest(ctrl.submitRate);
  };

  this.isBadTest = () => {
    return testIconUtils.isBadTest(ctrl.submitRate);
  };

  ctrl.getSessionDuration = () => {
    return dateUtils.sessionDuration(ctrl.startTime, ctrl.endTime, ctrl.classSessionId);
  };
}
