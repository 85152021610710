(function() {
  var resourceUtils = require('app2/utils/resourceUtils');

  angular.module('app.prepareCourse')
    .controller('cloudTeachingPatternPreviewerCtrl', cloudTeachingPatternPreviewerCtrl);

  cloudTeachingPatternPreviewerCtrl.$inject = ['$scope', '$uibModal', '$uibModalInstance',
    '$q', 'notificationService', 'oedTeachingPattern', 'cloudRes', 'resourceDef', 'oedPresentResource', 'Lightbox']
  function cloudTeachingPatternPreviewerCtrl($scope, $uibModal, $uibModalInstance,
    $q, notificationService, oedTeachingPattern, cloudRes, resourceDef, oedPresentResource, Lightbox) {
    $scope.cloudRes = cloudRes;
    $scope.teachingPattern = {};
    $scope.moduleReses = {};

    $scope.objectiveTests = {};
    $scope.subjectiveTests = {};
    $scope.presentResources = {};

    $scope.presentResourcesPkgIds = [];
    $scope.presentResourcesPkgItems = {};
    $scope.presentResourcesOpenedItem = {};

    $scope.getResIcon = function(res) {
      if (res['__type'] == 'objectivetest')
        return 'keguan_icon';
      if (res['__type'] == 'subjectivetest')
        return 'zhuguan_icon';
      if (res['__type'] == 'presentresource')
        return 'zhanshi_icon';

      return '??';
    };

    const teachingPattern = oedTeachingPattern.getPreviewByCloudRes({
      res_id: cloudRes.id
    });
    teachingPattern.$promise.then(function(pattern) {
      const moduleReses = {};
      let presentResourcesPkgIds = [];
      _.each(pattern.modules, function(m) {
        _.each(m.objectiveTests, function(test) {
          test['__type'] = 'objectivetest';
        });

        _.each(m.subjectiveTests, function(test) {
          test['__type'] = 'subjectivetest';
        });

        _.each(m.presentResources, function(res) {
          res['__type'] = 'presentresource';
        });


        moduleReses[m.id] = [].concat(m.objectiveTests)
          .concat(m.subjectiveTests)
          .concat(m.presentResources);

        presentResourcesPkgIds = presentResourcesPkgIds.concat(_.map(m.presentResources, 'id'))
      });
      $scope.moduleReses = moduleReses;
      $scope.presentResourcesPkgIds = presentResourcesPkgIds;
      $scope.teachingPattern = pattern;
      return pattern;
    }).then(function() {
      return Promise.all(_.map($scope.presentResourcesPkgIds, id => oedPresentResource.get({
        id
      }).$promise))
    }).then(function(data) {
      const presentResourcesPkgItems = {};
      _.forEach(data, item => {
        _.set(presentResourcesPkgItems, `${item.id}`, item.items)
      });

      $scope.presentResourcesPkgItems = presentResourcesPkgItems;
    });

    $scope.loading = teachingPattern.$promise;

    $scope.close = function() {
      $uibModalInstance.close({});
    };
    // 自身宽度 + margin-right&left
    var boxWidth = 230 + 30; //hard code here

    $scope.getShaftDivStyle = function() {
      var width = _.isEmpty($scope.teachingPattern.modules) ?
        '1200' : (_.size($scope.teachingPattern.modules) * boxWidth);
      if (width < 1200)
        width = 1200;
      var offset = '-' + ($scope.viewIndex * boxWidth) + 'px';
      return {
        width: '' + width + 'px',
        left: offset
      };
    };
    $scope.viewIndex = 0;
    $scope.computeMaxCourseDisplayCount = function() {
      var width = $('#shaftArea').outerWidth();
      return Math.floor(width / boxWidth);
    };
    $scope.$on('resize', function() {
      $scope.computeMaxCourseDisplayCount();
    });
    $scope.next = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount();
      if ($scope.viewIndex + maxCount >= _.size($scope.teachingPattern.modules))
        return;
      $scope.viewIndex++;
    };

    $scope.prev = function() {
      if ($scope.viewIndex <= 0)
        return;

      $scope.viewIndex--;
    };
    function previewObjectiveTest(res) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/cloudTestPreviewer.html'),
        controller: 'cloudTestPreviewerCtrl',
        size: 'almost-full-screen oed-big-win',
        resolve: {
          cloudTest: function() {
            return res;
          },
          resBox: null,
          resourceDef: null
        },
        windowClass: 'cl-preview-readonly-new cl-library-preview2'
      });

      return modalInstance.result;
    }

    function previewSubjectiveTest(res) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/cloudSubjectiveTestPreviewer.html'),
        controller: 'cloudSubjectiveTestPreviewerCtrl',
        size: 'almost-full-screen oed-big-win',
        resolve: {
          cloudTest: function() {
            return res;
          },
          resourceDef: null
        },
        windowClass: 'cl-preview-readonly cl-library-preview2'
      });

      return modalInstance.result;
    }

    function previewPresentRes(res) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/cloudPresentResPreviewer.html'),
        controller: 'cloudPresentResPreviewerCtrl',
        size: 'almost-full-screen oed-big-win',
        resolve: {
          cloudRes: function() {
            return res;
          },
          currentRes: function() {
            return res;
          },
          resourceDef: null
        },
        windowClass: 'cl-preview-readonly cl-library-preview3'
      });

      return modalInstance.result;
    }

    var resPreviewHandler = {
      objectivetest: previewObjectiveTest,
      subjectivetest: previewSubjectiveTest,
      presentresource: previewPresentRes
    };

    $scope.isOpenedRes = function(res) {
      if (_.get($scope.presentResourcesOpenedItem, `${res.id}`)) {
        return false
      }
      return true
    }
    $scope.openResPkg = function(res, $event) {
      $event.stopPropagation();
      $event.preventDefault();
      if (_.get($scope.presentResourcesOpenedItem, `${res.id}`)) {
        delete $scope.presentResourcesOpenedItem[res.id]
      } else {
        _.set($scope.presentResourcesOpenedItem, `${res.id}`, true)
      }
    }

    $scope.previewSingleItem = function(resId, idx) {
      const resList = $scope.presentResourcesPkgItems[resId]
      Lightbox.openModal(resList, idx);
    }

    $scope.getResTypeClass = function(res) {
      const type = res.flResource.suffix
      let realExtension;
      switch (type ? type.toLowerCase() : type) {
        case 'ppt':
        case 'pptx':
        case 'pdf':
          realExtension = 'fa-file-powerpoint-o'
          break;
        case 'doc':
        case 'docx':
        case 'txt':
          realExtension = 'fa-file-word-o'
          break;
        case 'xls':
        case 'xlsx':
          realExtension = 'fa-file-excel-o'
          break;
        // case 'xls':
        // case 'xlsx':
        //   realExtension = 'excel'
        //   break;
        case 'mp4':
        case 'flv':
        case 'rmvb':
        case 'wmv':
        case 'rm':
        case 'avi':
        case 'mpg':
          realExtension = 'fa-file-video-o'
          break;
        case 'mp3':
        case 'wma':
        case 'wav':
        case 'amr':
        case 'm4a':
          realExtension = 'fa-file-audio-o'
          break;
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'bmp':
          realExtension = 'fa-file-image-o'
          break;
        default:
          realExtension = 'fa-file-o'
          break;
      }
      return realExtension;
    }

    $scope.isSbj = function(res) {
      if (res.type === 'subjective') {
        return true
      }
      return false
    }

    $scope.isObj = function(res) {
      if (res.type === 'objective') {
        return true
      }
      return false
    }

    $scope.isRes = function(res) {
      if (res.type === 'presentresource') {
        return true
      }
      return false
    }

    $scope.previewItem = function(res) {
      var dlgs = $('.shouldHideOnPreviewItem');
      dlgs.css('display', 'none');
      resPreviewHandler[res['__type']](res).finally(function() {
        dlgs.css('display', 'block');
      });
    };

    $scope.importRes = function() {
      var deferred = $q.defer();
      $scope.loading = deferred.promise;
      deferred.promise.then(function() {
        notificationService.notify('info', '资源导入成功')

        $uibModalInstance.close('close');
      }, null, function(update) {
        $scope.progressMsg = update;
      }).finally(function() {
        $scope.progressMsg = '';
      });

      resourceDef.doImport([cloudRes], deferred);
    };

    var titleTpl = _.template('<%=testName%> (<%=username%>)');
    $scope.getTitleText = function() {
      var resName = _.get($scope.cloudRes, 'name', '');
      var displayName = resourceUtils.isDefaultTeachingPatternName(resName) ?
          _.get($scope.cloudRes, 'courseName', '') : resName;

      return titleTpl({
        testName: displayName,
        username: _.get($scope.cloudRes, 'userName', '')
      });
    };

    var subTitleTpl = _.template('<%=lastUpdateDate%> 下载: <%=downloads%> 浏览: <%=views%>');
    $scope.getSubTitleText = function() {
      return subTitleTpl({
        lastUpdateDate: moment(_.get($scope.cloudRes, 'lastUpdatedDate')).format('YYYY-MM-DD'),
        downloads: _.get($scope.cloudRes, 'downloads', 0),
        views: _.get($scope.cloudRes, 'views', 0)
      });
    };

  }
})();
