import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { finalize, map, flatMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { LoadingService } from 'app/shared/loading/loading.service';
import { SbjSessionService } from '../sbj-session.service';
import { BoardContentInteractsMDTO, BoardContentCommentBodyDTO, BoardSessionStudentDTOView } from 'app/models/sbj-test-session';
import { LegacyAppService } from 'app/core/legacy-app.service';
import { Subscription } from 'rxjs';


interface BoardContentInteractsMDTOView extends BoardContentInteractsMDTO<BoardContentCommentBodyDTO> {
  selected?: boolean;
}

@Component({
  selector: 'app-sbj-completion-details',
  template: require('./sbj-completion-details.component.html'),
  styles: [require('./sbj-completion-details.component.scss')]
})
export class SbjCompletionDetailsComponent implements OnInit, OnDestroy {
  @Input() boardSessionId: number;
  @Input() classId: number;
  @Input() fromClassSession: boolean;
  @Input() pubStudentIdSet: number[];

  loadingName = 'sbj-completion-details'

  interacts: BoardContentInteractsMDTOView[];
  interactsSort: BoardContentInteractsMDTOView[];
  studentsOfFinish: BoardSessionStudentDTOView[];
  studentsOfUnfinish: BoardSessionStudentDTOView[];
  studentsOfUnJoin: BoardSessionStudentDTOView[];
  isAllSelected: boolean;
  subs$: Subscription;

  constructor(private loadingService: LoadingService,
              private sbjSessionService: SbjSessionService,
              private legacyAppService: LegacyAppService) { }
  ngOnInit() {
    this.loadingService.show(false, this.loadingName)

    this.subs$ = this.sbjSessionService.getSessionStat(this.boardSessionId)
      .pipe(map((stats) => {
        this.interacts = _.clone(stats.interacts)
        this.interactsSort = _.clone(stats.interacts)
      }), flatMap(() => this.loadStudents()),
            finalize(() => this.loadingService.hide(this.loadingName)))
      .subscribe(() => {}, e => {
        console.log('error', e)
      })
  }

  ngOnDestroy(): void {
    if (this.subs$) {
      this.subs$.unsubscribe()
    }
  }

  loadStudents() {
    return this.sbjSessionService.queryBoardSessionStdList(this.boardSessionId, this.classId, this.interacts)
      .pipe(map(([stdList0, stdList1, stdList2]) => {
        const allStudent = _.concat(stdList0, stdList1, stdList2)
        const stdInfo = _.reduce(allStudent, (sum, std) => {
          return {
            ...sum,
            [std.id]: std.role,
          }
        }, {})

        _.forEach(this.interacts, (item) => {
          item.student.role = stdInfo[item.student.id];
        })
        const filterPubStudents = () => {
          return _.chain(stdList1).concat(stdList2).filter(s => _.includes(this.pubStudentIdSet, s.studentId)).value()
        }

        this.studentsOfFinish = stdList0
        this.studentsOfUnfinish = this.fromClassSession ? stdList1 : filterPubStudents()
        this.studentsOfUnJoin = stdList2
      }))
  }

  public toggleSelect(interact: BoardContentInteractsMDTOView) {
    interact.selected = !interact.selected;
    const selectStatus = _.partition(this.interacts, (it) => {
      return !!it.selected;
    })

    this.isAllSelected = _.size(selectStatus[0]) === _.size(this.interacts);
  }

  public showDetailsForStudent (uid: number) {
    const uibModal = this.legacyAppService.getUibModal()
    const modalInstance = uibModal.open({
      controller: 'interactDetailsViewerCtrl',
      resolve: {
        data: () => {
          return {
            index: this.findStdIndex(uid),
            interacts: this.interacts,
          };
        },
      },
      size: 'fullwidth without-bg',
      template: require('assets/templates/review/interactDetailsViewer.html'),
      windowClass: 'oedmodal modal-without-bg modalCenter',
    });

    modalInstance.result.then((interact: any) => {
      const index = _.findIndex(this.interacts, (it: any) => {
        return it.owner.uid === interact.owner.uid
      })
      this.interacts.splice(index, 1, interact);
      this.interactsSort = _.clone(this.interacts);
    })
  }

  public findStdIndex(uid: number) {
    return _.findIndex(this.interacts, (interact) => {
      return interact.owner.uid === uid;
    })
  }
}
