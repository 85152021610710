import * as _ from 'lodash';
// import { communicateService } from '../../services/communicate.service';
const {importPresentResourceItems} = require('app2/utils/resourceUtils')

import Promise = require('bluebird');
const boardUtils: any = require('app2/utils/boardUtils');
const resourceUtils: any = require('app2/utils/resourceUtils');
const {confirmAndDownloadSubjectiveTest, importSubjectTestModel} = require('app2/utils/testUtils2')
require('./newPrepareRight.scss')
const {doImportQuestions} = require('app2/utils/testUtils2')
export const newPrepareRight = {
  bindings: {
    courseId: '<',
    sectionId: '<',
    classTime: '<',
    setLoading: '&',
  },
  controller: newPrepareRightCtrl,
  template: <string>require('./newPrepareRight.html'),
}

angular.module('app.newPrepareCourse2.newPrepareRight', ['ui.tree'])
  .component('newPrepareRight', newPrepareRight);

newPrepareRightCtrl.$inject = [
  '$scope',
  '$rootScope',
  '$q',
  '$log',
  '$uibModal',
  '$timeout',
  'notify',
  'dialogs',
  'Upload',
  'Lightbox',
  'userInfo',
  'oedUserInfo',
  'oedCourse',
  'localStorageService',
  'notificationService',
  'oedTeachingPattern',
  'oedTest',
  'oedObjectiveTest',
  'oedSubjectiveTest',
  'oedPresentResource',
  'oedPickedCourse',
  'oedBusinessCourse',
  'communicateService',
  'oedNewPrepareCourse',
  'oedTeachingModule',
  'oedCloudPresentResItem',
  'oedPresentResourceItem',
  'hlsResource',
  'oedPreResItem',
  'oedResDownloadTask',
  'oedCloudQuestion',
  'talcloudResource',
  'oedObjectiveQuestion',
  'cloudQuestionService',
];
function newPrepareRightCtrl(
  $scope,
  $rootScope,
  $q,
  $log,
  $uibModal,
  $timeout,
  notify,
  dialogs,
  Upload,
  Lightbox,
  userInfo,
  oedUserInfo,
  oedCourse,
  localStorageService,
  notificationService,
  oedTeachingPattern,
  oedTest,
  oedObjectiveTest,
  oedSubjectiveTest,
  oedPresentResource,
  oedPickedCourse,
  oedBusinessCourse,
  communicateService,
  oedNewPrepareCourse,
  oedTeachingModule,
  oedCloudPresentResItem,
  oedPresentResourceItem,
  hlsResource,
  oedPreResItem,
  oedResDownloadTask,
  oedCloudQuestion,
  talcloudResource,
  oedObjectiveQuestion,
  cloudQuestionService,
) {
  const ctrl = this;
  ctrl.loaded = true;
  ctrl.tabs = [{index: 0, text: '精品课程'},
    {index: 1, text: '按业务'},
    {index: 2, text: '按功能'},
  ];
  ctrl.activeTabIndex = 0;
  ctrl.objectives = {};
  ctrl.subjectives = {};
  ctrl.resources = {};
  ctrl.teachingDesigns = {};
  ctrl.teachingDesignList = [];
  ctrl.resourceList = [];

  ctrl.selectBtnType = 'teaching';
  ctrl.title = '推荐教学设计';
  ctrl.selectResourceIndex = 0;
  ctrl.selectResourceList = [];
  ctrl.currentResourceList = [];
  ctrl.presentResourceList = [];
  ctrl.presentResourceAndTypes = [];
  ctrl.selectPresentResourceIndex = 0;
  ctrl.currentPresentResourceList = [];
  ctrl.objectiveList = [];
  ctrl.subjectiveList = [];
  ctrl.resourceAndTypes = [];
  ctrl.pickedCourses = {};
  ctrl.BusinessCourses = [];
  ctrl.getMessageScription = null;
  ctrl.dragScription = null;
  ctrl.selectBtnTypeMessage = null;
  ctrl.modulesNumMessage = null;
  ctrl.selectModuleMessage = null;
  ctrl.hideDom = false;
  ctrl.expandScription = null;
  ctrl.expandPanel = false;
  // ctrl.isBuyZlCourse = localStorageService.get('isBuyZlCourse');
  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'courseId') && _.has(changesObj, 'sectionId')) {
      const loading = userInfo.then(function(user) {
        ctrl.userInfo = user;
        return oedUserInfo.getZlwhResourcePreference({schoolId:ctrl.userInfo.schoolId}).$promise.then(res=>{
          ctrl.isBuyZlCourse = res.value && res.value == 1 ? true : false;
          if (ctrl.isBuyZlCourse) {
            return $q.all([loadPickedCourses(), loadBusinessCourses()]);
          } else {
            return $q.all([loadFunctionClasses()]);
          }
        })
      });
      // if (ctrl.isBuyZlCourse) {
      //   loading = $q.all([loadPickedCourses(), loadBusinessCourses()]);
      // } else {
      //   loading = $q.all([loadFunctionClasses()]);
      // }
      ctrl.setLoading({loading})
    }
  };
  ctrl.$onInit = () => {
    // userInfo.then((user) => {
    //   ctrl.userInfo = user;
    // });
    oedCourse.get({
      id: ctrl.courseId
    }).$promise.then((r: any) => {
      ctrl.course = r;
    });
    ctrl.teachingPattern = oedTeachingPattern.getByCourse2({
      courseId: ctrl.courseId
    });
    ctrl.modulesNumMessage = communicateService.getModulesNum().subscribe((res: any) => {
      ctrl.importModuleIndex = res.num > 0 ? res.num : 0;
    });
    ctrl.selectModuleMessage = communicateService.getSelectModuleMessage().subscribe((res: any) => {
      ctrl.currentModule = res.module;
    });
    ctrl.selectBtnTypeMessage = communicateService.getSelectBtnTypeMessage().subscribe((res: any) => {
      ctrl.selectBtnType = res.type;
      switch (ctrl.selectBtnType) {
        case 'objective':
          ctrl.title = '推荐习题';
          ctrl.currentResourceList = ctrl.objectiveList;
          break;
        case 'subjective':
          ctrl.title = '推荐活动';
          ctrl.currentResourceList = ctrl.subjectiveList;
          break;
        case 'present':
          ctrl.title = '推荐资源';
          if (!ctrl.isBuyZlCourse) {
            ctrl.presentResourceAndTypes = [];
            getPresentResource();
          }
          ctrl.currentPresentResourceList = ctrl.presentResourceAndTypes.length > 0 ? ctrl.presentResourceAndTypes[ctrl.selectPresentResourceIndex].resource : [];
          break;
        case 'teaching':
          ctrl.title = '推荐教学设计';
          break;
      }
    })
  };
  ctrl.getList = (list) => {
    if (_.size(list)) {
      _.forEach(list, (l: any) => {
        l.nodes = _.orderBy(l.nodes, 'sort', 'asc');
      })
    }
    return list;
  }
  ctrl.changePanel = () => {
    ctrl.expandPanel = !ctrl.expandPanel;
  }
  ctrl.moreResource = () => {
    if (ctrl.selectBtnType === 'objective') {
      const modalInstance = $uibModal.open({
        template: require('app2/prepareCourse/cloud/objectiveTestLibrary.html'),
        controller: 'objectiveTestLibraryCtrl',
        size: 'full-screen',
        windowClass: 'shouldHideOnPreview cl-library-dialog2',
        resolve: {
          data: () => ({
            course: ctrl.course,
            curIsBlankTest: true,
            doImport: importQuestions,
            importQuickTest: importQuickTests,
          })
        },
      })
    } else if (ctrl.selectBtnType === 'subjective') {
        const modalInstance = $uibModal.open({
          template: require('app2/prepareCourse/cloud/subjectiveTest/subjectiveTestLibraryDialog.html'),
          controller: 'subjectiveTestLibraryDialogCtrl',
          controllerAs: '$ctrl',
          size: 'full-screen',
          windowClass: 'shouldHideOnPreview cl-library-dialog2',
          resolve: {
            data: () => ({
              course: ctrl.course,
              doImport: (res, deferred) => {
                return doImportTest([res], deferred)
              },
            })
          },
        })
    } else if (ctrl.selectBtnType === 'present') {
      const modalInstance = $uibModal.open({
        template: require('app2/prepareCourse/cloud/presentResource/presentResourceLibraryDialog.html'),
        controller: 'presentResourceLibraryDialogCtrl',
        controllerAs: '$ctrl',
        size: 'full-screen',
        windowClass: 'shouldHideOnPreview cl-library-dialog2',
        resolve: {
          data: () => ({
            course: ctrl.course,
            doImport: (res, deferred) => ctrl.doImportResources(res, deferred),
          })
        },
      })
    } else if (ctrl.selectBtnType === 'teaching') {
      const modalInstance = $uibModal.open({
        component: 'teachingDesignLibrary',
        size: 'full-screen',
        windowClass: 'shouldHideOnPreview cl-library-dialog2',
        resolve: {
          data: () => ({
            course: ctrl.course,
            currentTeachingPattern: ctrl.teachingPattern,
            doImport: doImportTp,
          })
        },
      })
    }
  }
  function doImportTp(res, deferred) {
    if (_.isEmpty(res)) {
      deferred.resolve(0);
    } else {
      let curImportIdx = 0;
      let selModId;
      const doImportCloudTp = () => {
        if (curImportIdx >= _.size(res)) {
          if (selModId) {
            communicateService.sendDragMessage({needfresh: true, selectModuleId: selModId});
          }
          return 0;
        }

        const r = res[curImportIdx];
        deferred.notify('正在导入第' + (curImportIdx + 1) + '个教学设计');

        const importTeachingPattern = oedTeachingPattern.getPreviewByCloudRes({
          res_id: r.id
        });
        return importTeachingPattern.$promise.then((tp: any) => {
          if (_.size(tp.modules)) {
            return oedTeachingPattern.importTeachingModulesFromResource({
              teachingpatternId: ctrl.teachingPattern.id,
              moduleIds: _.map(tp.modules, 'id').join(','),
              index: ctrl.importModuleIndex
            }).$promise.then((impMod: any) => {
              selModId = impMod.modules[ctrl.importModuleIndex].id
              curImportIdx++;
              return doImportCloudTp();
            });
          }
          curImportIdx++;
          return doImportCloudTp();
        })
      };

      doImportCloudTp().then(() => {
        deferred.resolve(0);
      }).catch(deferred.reject);
    }

    return deferred.promise;
  }
  ctrl.doImportResources = (res, deferred) => {
    ctrl.importResourceItems(deferred, res);
    return deferred.promise;
  }
  ctrl.importResourceItems = (deferred, res) => {
    if (res && res.length > 0) {
      oedPresentResource.createResourcePackge({ moduleId: ctrl.currentModule ?
        ctrl.currentModule.id : -1,             tpId: ctrl.teachingPattern.id }, {}).$promise.then((r: any) => {
        return r;
      }).then((data: any) => {
        ctrl.addItemForPackage(deferred, res, data.id);
      })
    }
  }
  ctrl.addItemForPackage = (deferred, res, packgeId) => {
    oedPresentResource.get({ id: packgeId }).$promise.then((x: any) => {
      let downloadOpts = null;
      const opts = {
        canceled: false,
        hlsResource,
        hasDuplicated: false,
        oedCloudPresentResItem,
        oedPreResItem, oedPresentResourceItem,
        oedPresentResource,
        oedResDownloadTask,
        uid: ctrl.userInfo.uid,
        notify,
        notificationService
      }
      downloadOpts = opts
      importPresentResourceItems(x, res, deferred, opts).then((r) =>  {
        $rootScope.$broadcast('presentResource.changed', {
          resId:  x.id
        });
        return  x.$get();
      }).then(() => {
        deferred.resolve();
      }).catch((m) =>  {
        deferred.reject(m);
      }).finally((r) => {
        downloadOpts = null
        if (opts.hasDuplicated) {
          notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
        }
        ctrl.memoryHandleId = x.id;
        communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId,
                                            isFromUseResource: true, presentPackageId: ctrl.memoryHandleId});
      });
    })
  }
  function doImportTest(res, deferred) {
    if (res && res.length > 0) {
      return oedSubjectiveTest.getDownloadByCloudRes({
        res_id: res[0].id,
      }).$promise.then((subTest: any) => {
        return oedCloudQuestion.getByQuestionId({
          id: subTest.question.id,
        }).$promise.then((cloudQuestion: any) => {
          oedSubjectiveTest.createSubjPackge({moduleId: ctrl.currentModule ?
            ctrl.currentModule.id : - 1,      tpId : ctrl.teachingPattern.id}, {}).$promise.then((r: any) => {
            const newTest = importSubjectTestModel(r, subTest, cloudQuestion)
            newTest.$save().then(() => {
              ctrl.memoryHandleId = r.id;
              communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
              deferred.resolve();
            })
          })
        })
        })
      // return confirmAndDownloadSubjectiveTest(res, deferred, {
      //   dialogs, oedCloudQuestion, oedSubjectiveTest,
      // }).then((subTestAndCq) => {
      //   oedSubjectiveTest.createSubjPackge({moduleId: ctrl.currentModule ?
      //     ctrl.currentModule.id : - 1,      tpId : ctrl.teachingPattern.id}, {}).$promise.then((r: any) => {
      //     const {cloudQuestion, subTest} = subTestAndCq
      //     const newTest = importSubjectTestModel(r, subTest, cloudQuestion)
      //     newTest.$save().then(() => {
      //       ctrl.memoryHandleId = r.id;
      //       communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
      //       deferred.resolve();
      //     })
      //   })
      // }).catch((m) => {
      //   deferred.reject(m);
      // });
    }
    return deferred.promise;
  }

  function importQuickTests(res, deferred) {
    const test = res.test
    const q = _.assign({}, test.questions[0])
    q.id = null
    oedObjectiveTest.createObjPackge({moduleId: ctrl.currentModule ?
        ctrl.currentModule.id : -1,     tpId : ctrl.teachingPattern.id}, {}).$promise.then((r: any) => {
      ctrl.memoryHandleId = r.id;
      return oedObjectiveQuestion.createForTest({
        question: q,
        testId: r.id
      }).$promise.then(() => {
        return oedObjectiveTest.get({
          id: r.id
        }).$promise.then((test) => {
          return oedObjectiveTest.save(angular.copy(test)).$promise.then(() => {
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
            notificationService.notify('info', '试卷保存成功')
          });
        });
      }).then(() => deferred.resolve())
        .catch((e: any) => deferred.reject(e));
    });
  }
  function importQuestions(questions, deferred) {
    if (questions && questions.length > 0) {
      oedObjectiveTest.createObjPackge({moduleId: ctrl.currentModule ?
        ctrl.currentModule.id : -1,     tpId : ctrl.teachingPattern.id}, {}).$promise.then((r: any) => {
        ctrl.memoryHandleId = r.id;
        return doImportQuestions(questions, deferred, {
          notify,
          oedCloudQuestion,
          talcloudResource,
          oedObjectiveQuestion,
          oedObjectiveTest,
          userInfo,
          cloudQuestionService,
          questionOp: {
            get: () => r.questions,
            set: (qs) => r.questions = qs
          },
          relatedSections: ctrl.course.relatedSections || [],
          state: {loading: Promise.resolve()},
          notificationService,
        }).then((quesToAdd: any) => {
          return oedObjectiveTest.save(angular.copy(r)).$promise.then(() => {
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
            notificationService.notify('info', '试卷保存成功')
          });
        }).then((res: any) => deferred.resolve(res))
          .catch((e: any) => deferred.reject(e))
      })
    }
  }
  ctrl.selectResourceType = (index) => {
    ctrl.selectResourceIndex = index;
    ctrl.isInteraction = (ctrl.resourceAndTypes[index].type === 'teaching');
    ctrl.selectResourceList = ctrl.resourceAndTypes[index].type === 'teaching' ?
      ctrl.getList(ctrl.resourceAndTypes[index].resource) : ctrl.resourceAndTypes[index].resource;
  }
  ctrl.selectPresentResourceType = (index) => {
    ctrl.selectPresentResourceIndex = index;
    ctrl.currentPresentResourceList = ctrl.presentResourceAndTypes[ctrl.selectPresentResourceIndex].resource;
  }
  ctrl.getMessageScription = communicateService.getMessage().subscribe((res: any) => {
    if (res.sourceFrom === 'left') {
      if (jQuery('.selected-item') && jQuery('.selected-item')[0]) {
        jQuery('.selected-item').removeClass('selected-item');
      }
    }
  });
  ctrl.expandScription = communicateService.getExpandMessage().subscribe((res: any) => {
    ctrl.hideDom = res.isFullScreen;
  })
  ctrl.dragScription = communicateService.getDragMessageToRight().subscribe((res: any) => {
    if (jQuery('.selected-item') && jQuery('.selected-item')[0]) {
      jQuery('.selected-item').removeClass('selected-item');
    }
    jQuery('#' + res.memoryHandleId).addClass('selected-item');
    if (res.isReturnPresentPackage) {
      ctrl.memoryPackageId = res.data.id;
      let selPack;
      const obj = _.find(ctrl.selectResourceList, (sr: any) => {
        return _.find(sr.nodes, (n: any) => {
          return selPack = _.find(n.nodes, (r: any) => {
            return r.id === ctrl.memoryPackageId;
          })
        })
      })
      communicateService.sendMessage({sourceFrom: 'right', data: selPack, isTeaching: true});
    } else if (res.isChangeResource) {
      ctrl.memoryPackageId = res.data.id;
      let selRes;
      const obj = _.find(ctrl.selectResourceList, (sr: any) => {
        return _.find(sr.nodes, (n: any) => {
          return _.find(n.nodes, (p: any) => {
            return selRes = _.find(p.nodes, (r: any) => {
              return r.id === res.resId;
            })
          })
        })
      })
      communicateService.sendMessage({sourceFrom: 'right', data: selRes, isTeaching: true});
    } else if (res.data.resoureType ===  'singleResource') {
      ctrl.memoryPackageId = res.data.resDetail.id;
      communicateService.sendMessage({sourceFrom: 'right', data: res.data, isTeaching: true});
    }
  })
  ctrl.$onDestroy = () => {
    ctrl.getMessageScription.unsubscribe();
    ctrl.expandScription.unsubscribe();
    ctrl.dragScription.unsubscribe();
    ctrl.selectBtnTypeMessage.unsubscribe();
    ctrl.modulesNumMessage.unsubscribe();
    ctrl.selectModuleMessage.unsubscribe();
  }
  ctrl.handleCollapsed = (node) => {
    if (ctrl.memoryPackageId) {
      if (node.resoureType ===  'presentPackage' && node.id === ctrl.memoryPackageId) {
        node.collapsed = true;
        ctrl.memoryPackageId = ''; // 必须清空  要不然toggle死循环
      }
    } else {
      ctrl.memoryPackageId = '';
      return node.collapsed
    }
  }
  ctrl.selectItem = (item, isTeaching) => {
    event.stopPropagation();
    event.preventDefault();
    if (item.resoureType && item.resoureType === 'presentPackage') {
      item.collapsed = true;
    }
    if (jQuery('.selected-item') && jQuery('.selected-item')[0]) {
      jQuery('.selected-item').removeClass('selected-item');
    }
    jQuery(event.currentTarget).addClass('selected-item');
    ctrl.selectResource(item, isTeaching);
  }
  ctrl.selectResource = (res, isTeaching) => {
    communicateService.sendMessage({sourceFrom: 'right', data: res, isTeaching});
  };
  ctrl.getTypeUrl = (type) => {
    switch (type ? type.toLowerCase() : type) {
      case 'doc':
      case 'docx':
        return 'fa-file-word-o';
        break;
      case 'pdf':
        return 'fa-file-pdf-o';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        return 'fa-file-photo-o';
        break;
      case 'mp4':
      case 'flv':
      case 'rmvb':
      case 'wmv':
      case 'rm':
      case 'avi':
      case 'mpg':
        return 'fa-file-video-o';
        break;
      case 'mp3':
      case 'wma':
      case 'wav':
      case 'amr':
      case 'm4a':
        return 'fa-file-audio-o';
        break;
      case 'txt':
        return  'fa-file-text-o';
        break;
      case 'ppt':
      case 'pptx':
        return  'fa-file-powerpoint-o';
        break;
      case 'xls':
      case 'xlsx':
        return  'fa-file-excel-o';
        break;
      case 'zip':
        return 'fa-file-archive-o'
        break;
      default:
        return 'fa-file-o';
        break;
    }
  };
  ctrl.toggle = (node) => {
    node.collapsed = !node.collapsed;
  };
  ctrl.handleAutoScroll = (num) => {
    if (ctrl.autoScroll) {
      const topNum =  jQuery('.new-prepare-left-data').scrollTop();
      jQuery('.new-prepare-left-data').scrollTop(topNum + num);
      setTimeout(() => {
        ctrl.handleAutoScroll(num);
      }, 50);
    }
  }
  ctrl.treeOption2 = {
    accept: (sourceNodeScope, destNodesScope, destIndex) => {
      return false;
    },
    dragStart: () => {
      if (jQuery('.set-office-style') && jQuery('.set-office-style')[0]) {
        jQuery('.set-office-style').css('pointer-events', 'none');
      }
    },
    dragStop: () => {
      if (jQuery('.set-office-style') && jQuery('.set-office-style')[0]) {
        jQuery('.set-office-style').css('pointer-events', 'auto');
      }
      ctrl.autoScroll = false;
    },
    dragMove: (e) => {
      if (e.pos.nowY  + 30 >= window.innerHeight) {
        ctrl.autoScroll = true;
        ctrl.handleAutoScroll(3);
      } else if (e.pos.nowY < 180) {
        ctrl.autoScroll = true;
        ctrl.handleAutoScroll(-3);
      } else {
        ctrl.autoScroll = false;
      }
    },
    beforeDrop: (e) => {
      if ($('.angular-ui-tree-placeholder') && $('.angular-ui-tree-placeholder')[0] && e.pos.nowX >= 350) {
        e.source.nodeScope.$$apply = false;
      }
    },
    dropped: (e) => {
      const dest = e.dest.nodesScope;
      if (!dest.$nodeScope) { // 说明是把右侧资源拖到左侧教学设计下module层级
        const sourceData = e.source.nodeScope.$modelValue;
        if (!sourceData.tpId) {
          return;
        }
        if (sourceData.resoureType === 'singleResource' || sourceData.resoureType === 'zhonglian') {
          oedNewPrepareCourse.copySingleResourceByIndex({
            courseId: parseFloat(ctrl.courseId),
            teachingModuleId: -1,
            presentResourceId: -1,
            resourceId: sourceData.resourceId,
            resourceName: sourceData.resourceName,
            createResource: true,
            index: e.dest.index
          }).$promise.then((res: any) => {
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: res.presentResourceDTO.id,
                                                isFromUseResource: true, presentPackageId: res.presentResourceDTO.id});
          })
        } else if (sourceData.resoureType === 'objective') {
            oedNewPrepareCourse.copyObjectiveTestByIndex({
              courseId: parseFloat(ctrl.courseId),
              teachingModuleId: -1,
              testId: sourceData.id,
              index: e.dest.index
            }, {}).$promise.then((res: any) => {
              communicateService.sendDragMessage({needfresh: true, memoryHandleId : res.testDTO.id});
            })
        } else if (sourceData.resoureType === 'subjective') {
            oedNewPrepareCourse.copySubjectiveByIndex({
              courseId: parseFloat(ctrl.courseId),
              teachingModuleId: -1,
              testId: sourceData.id,
              index: e.dest.index
            }, {}).$promise.then((res: any) => {
              communicateService.sendDragMessage({needfresh: true, memoryHandleId : res.subjectiveTestDTO.id});
            })
        }
      } else {
        const item = e.source.nodeScope.$modelValue;
        const targetItem = e.dest.nodesScope.item;
        const value = e.dest.nodesScope.$nodeScope ? e.dest.nodesScope.$nodeScope.$modelValue : null;
        if (!targetItem) {
          return;
        }
        const tempLiIndex = _.findIndex(targetItem.treeData, (treeItem: any) => {
          return treeItem.resoureType === 'tempLi';
        });
        if (tempLiIndex >= 0) {
          targetItem.treeData.splice(tempLiIndex, 1);
          targetItem.treeData.push({ resoureType: 'tempLi'});
        }
        if (item && item.handleDrag === 'resolve') {
          if (item.resoureType === 'singleResource' || item.resoureType === 'zhonglian') {
            if (item.needCreatePackage) {
              oedNewPrepareCourse.copySingleResource({
                courseId: parseFloat(ctrl.courseId),
                teachingModuleId: item.memoryModuleId,
                presentResourceId: -1,
                resourceId: item.resourceId,
                resourceName: item.resourceName,
                createResource: true
              }).$promise.then((resource: any) => {
                let indexNum = null;
                _.remove(targetItem.treeData, (n: any, index) => {
                  if (n.id && (n.id === item.id)) {
                    indexNum = index;
                    return n.id === item.id;
                  }
                });
                const packageDto = resource.presentResourceDTO;
                packageDto.resDetail = _.cloneDeep(packageDto);
                packageDto.resoureType = 'presentPackage';
                packageDto.type = 'presentresource';
                packageDto.isShown = true;
                packageDto.collapsed = false;
                packageDto.items[0].resDetail = packageDto.resDetail;
                packageDto.items[0].resoureType = 'singleResource';
                packageDto.items[0].resIndex = 0;
                packageDto.moduleId = item.memoryModuleId;
                targetItem.treeData.splice(indexNum, 0, packageDto);
                targetItem.presentResources.push(resource.teachingModulePresentResourceDTO);
                item.memoryModuleId = '';
                item.needCreatePackage = false;
                ctrl.sortLeftTreeData(targetItem.treeData, targetItem.id, packageDto);
              }).catch(() => {
                communicateService.sendDragMessage({needfresh: true});
              });
            } else {
              oedNewPrepareCourse.copySingleResource({
                courseId: parseFloat(ctrl.courseId),
                teachingModuleId: targetItem.id ? targetItem.id : -1,
                presentResourceId: value.id ? value.id : -1,
                resourceId: item.resourceId,
                resourceName: item.resourceName,
                createResource: true
              }).$promise.then((resource: any) => {
                let indexNum = null;
                _.remove(value.items, (n: any, index) => {
                  if (n.id === item.id) {
                    indexNum = index;
                    return n.id === item.id;
                  }
                });
                const resItemDto = resource.presentResourceItemDTO;
                resItemDto.resoureType = 'singleResource';
                resItemDto.resIndex = indexNum;
                resItemDto.resDetail = value;
                resItemDto.moduleId = targetItem.id ? targetItem.id : -1;
                value.items.splice(indexNum, 0, resItemDto);
                const cloneNode = _.cloneDeep(value);
                _.each(value.items, (n: any, index) => {
                  n.resDetail = cloneNode;
                  n.resIndex = index;
                });
                value.resDetail = cloneNode;
                const  itemsIdArr = value.items.map((r: any) => {
                  return {id: r.id}
                });
                const requestObj = {
                  id: value.id,
                  items: itemsIdArr
                };
                oedPresentResource.updateItemOrder({id: value.id}, requestObj).$promise.then((res: any) => {
                  communicateService.sendDragMessage({
                    needfresh: false, memoryHandleId : resItemDto.id, data: resItemDto
                  });
                })
              }).catch(() => {
                communicateService.sendDragMessage({needfresh: true});
              });
            }
          } else if (item.resoureType === 'objective') {
            oedNewPrepareCourse.copyObjectiveTest({
              courseId: parseFloat(ctrl.courseId),
              teachingModuleId: targetItem.id ? targetItem.id : -1,
              testId: item.id,
            }, {}).$promise.then((res: any) => {
              targetItem.tests.push(res.tmTestDTO);
              ctrl.handleDataBeforeSort(targetItem.treeData, res.testDTO, item.id, targetItem.id ? targetItem.id : -1);
              ctrl.sortLeftTreeData(targetItem.treeData, targetItem.id, res.testDTO);
            }).catch(() => {
              communicateService.sendDragMessage({needfresh: true});
            });
          } else if (item.resoureType === 'subjective') {
            oedNewPrepareCourse.copySubjective({
              courseId: parseFloat(ctrl.courseId),
              teachingModuleId:  targetItem.id ? targetItem.id : -1,
              testId: item.id,
            }, {}).$promise.then((res: any) => {
              targetItem.tests.push(res.tmTestDTO);
              ctrl.handleDataBeforeSort(targetItem.treeData, res.subjectiveTestDTO, item.id, targetItem.id ? targetItem.id : -1);
              ctrl.sortLeftTreeData(targetItem.treeData, targetItem.id, res.subjectiveTestDTO);
            }).catch(() => {
              communicateService.sendDragMessage({needfresh: true});
            });
          } else if (item.resoureType === 'presentPackage') {
            oedNewPrepareCourse.copyPackageByIndex({
              courseId: parseFloat(ctrl.courseId),
              moduleId: targetItem.id,
              packageId:  item.id,
              index: e.dest.index
            }, {}).$promise.then((res: any) => {
              communicateService.sendDragMessage({needfresh: true, memoryHandleId: res.id});
            })
          }
        } else {
          communicateService.sendDragMessage({needfresh: true});
        }
      }
    }
  }
  ctrl.treeOption3 = {
    accept: (sourceNodeScope, destNodesScope, destIndex) => {
      return false;
    },
    dragStart: () => {
      if (jQuery('.set-office-style') && jQuery('.set-office-style')[0]) {
        jQuery('.set-office-style').css('pointer-events', 'none');
      }
    },
    dragStop: () => {
      if (jQuery('.set-office-style') && jQuery('.set-office-style')[0]) {
        jQuery('.set-office-style').css('pointer-events', 'auto');
      }
      ctrl.autoScroll = false;
    },
    dragMove: (e) => {
      if (e.pos.nowY  + 30 >= window.innerHeight) {
        ctrl.autoScroll = true;
        ctrl.handleAutoScroll(3);
      } else if (e.pos.nowY < 180) {
        ctrl.autoScroll = true;
        ctrl.handleAutoScroll(-3);
      } else {
        ctrl.autoScroll = false;
      }
    },
    beforeDrop: (e) => {
      if ($('.angular-ui-tree-placeholder') && $('.angular-ui-tree-placeholder')[0] && e.pos.nowX >= 350) {
        e.source.nodeScope.$$apply = false;
      }
    },
    dropped: (e) => {
      const dest = e.dest.nodesScope;
      if (!dest.$nodeScope) { // 说明是把右侧资源拖到左侧教学设计下module层级
        const sourceData = e.source.nodeScope.$modelValue;
        if (!sourceData.tpId) {
          return;
        }
        if (sourceData.resoureType === 'module') {
          oedNewPrepareCourse.dragAllModule({
            tpId: sourceData.tpId,
            moduleId: [sourceData.id],
            index: e.dest.index
          }).$promise.then((res: any) => {
            communicateService.sendDragMessage({needfresh: true,  selectModuleId: res.modules[e.dest.index].id});
          })
        } else if (sourceData.resoureType === 'allmodule') {
          let moduleIdArr = [];
          if (sourceData.nodes) {
            moduleIdArr = _.map(sourceData.nodes, (item: any) => item.id);
          }
          oedNewPrepareCourse.dragAllModule({
            tpId: sourceData.tpId,
            moduleId: moduleIdArr,
            index: e.dest.index
          }).$promise.then((res: any) => {
            communicateService.sendDragMessage({needfresh: true, selectModuleId: res.modules[e.dest.index].id});
          })
        } else if (sourceData.resoureType === 'presentPackage') {
          oedNewPrepareCourse.copyPackageByIndex({
            courseId: parseFloat(ctrl.courseId),
            moduleId: -1,
            packageId: sourceData.id,
            index: e.dest.index
          }, {}).$promise.then((res: any) => {
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: res.id,
                                                isFromUseResource: true, presentPackageId: res.id});
          })
        } else if (sourceData.resoureType === 'singleResource') {
          oedNewPrepareCourse.copySingleResourceByIndex({
            courseId: parseFloat(ctrl.courseId),
            teachingModuleId: -1,
            presentResourceId: -1,
            resourceId: sourceData.resourceId,
            resourceName: sourceData.resourceName,
            createResource: true,
            index: e.dest.index
          }).$promise.then((res: any) => {
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: res.presentResourceDTO.id,
                                                isFromUseResource: true, presentPackageId: res.presentResourceDTO.id});
          })
        } else if (sourceData.resoureType === 'objective') {
            oedNewPrepareCourse.copyObjectiveTestByIndex({
              courseId: parseFloat(ctrl.courseId),
              teachingModuleId: -1,
              testId: sourceData.id,
              index: e.dest.index
            }, {}).$promise.then((res: any) => {
              communicateService.sendDragMessage({needfresh: true, memoryHandleId : res.testDTO.id});
            })
        } else if (sourceData.resoureType === 'subjective') {
            oedNewPrepareCourse.copySubjectiveByIndex({
              courseId: parseFloat(ctrl.courseId),
              teachingModuleId: -1,
              testId: sourceData.id,
              index: e.dest.index
            }, {}).$promise.then((res: any) => {
              communicateService.sendDragMessage({needfresh: true, memoryHandleId : res.subjectiveTestDTO.id});
            })
        }
      } else {
        const item = e.source.nodeScope.$modelValue;
        const targetItem = e.dest.nodesScope.item;
        const value = e.dest.nodesScope.$nodeScope ? e.dest.nodesScope.$nodeScope.$modelValue : null;
        if (!targetItem) {
          return;
        }
        const tempLiIndex = _.findIndex(targetItem.treeData, (treeItem: any) => {
          return treeItem.resoureType === 'tempLi';
        });
        if (tempLiIndex >= 0) {
          targetItem.treeData.splice(tempLiIndex, 1);
          targetItem.treeData.push({ resoureType: 'tempLi'});
        }
        if (item && item.handleDrag === 'resolve') {
          if (item.resoureType === 'singleResource' || item.resoureType === 'zhonglian') {
            if (item.needCreatePackage) {
              oedNewPrepareCourse.copySingleResource({
                courseId: parseFloat(ctrl.courseId),
                teachingModuleId: item.memoryModuleId,
                presentResourceId: -1,
                resourceId: item.resourceId,
                resourceName: item.resourceName,
                createResource: true
              }).$promise.then((resource: any) => {
                item.memoryModuleId = '';
                item.needCreatePackage = false;
                let indexNum = null;
                _.remove(targetItem.treeData, (n: any, index) => {
                  if (n.id && (n.id === item.id)) {
                    indexNum = index;
                    return n.id === item.id;
                  }
                });
                const packageDto = resource.presentResourceDTO;
                packageDto.resDetail = _.cloneDeep(packageDto);
                packageDto.resoureType = 'presentPackage';
                packageDto.type = 'presentresource';
                packageDto.isShown = true;
                packageDto.collapsed = false;
                packageDto.items[0].resDetail = packageDto.resDetail;
                packageDto.items[0].resoureType = 'singleResource';
                packageDto.items[0].resIndex = 0;
                targetItem.treeData.splice(indexNum, 0, packageDto);
                targetItem.presentResources.push(resource.teachingModulePresentResourceDTO);
                ctrl.sortLeftTreeData(targetItem.treeData, targetItem.id, packageDto);
              }).catch(() => {
                communicateService.sendDragMessage({needfresh: true});
              });
            } else {
              oedNewPrepareCourse.copySingleResource({
                courseId: parseFloat(ctrl.courseId),
                teachingModuleId: targetItem.id ? targetItem.id : -1,
                presentResourceId: value.id ? value.id : -1,
                resourceId: item.resourceId,
                resourceName: item.resourceName,
                createResource: true
              }).$promise.then((resource: any) => {
                let indexNum = null;
                _.remove(value.items, (n: any, index) => {
                  if (n.id === item.id) {
                    indexNum = index;
                    return n.id === item.id;
                  }
                });
                const resItemDto = resource.presentResourceItemDTO;
                resItemDto.resoureType = 'singleResource';
                resItemDto.resIndex = indexNum;
                resItemDto.resDetail = value;
                value.items.splice(indexNum, 0, resItemDto);
                const  itemsIdArr = value.items.map((r: any) => {
                  return {id: r.id}
                });
                const requestObj = {
                  id: value.id,
                  items: itemsIdArr
                };
                oedPresentResource.updateItemOrder({id: value.id}, requestObj).$promise.then((res: any) => {
                  communicateService.sendDragMessage({
                    needfresh: false, memoryHandleId : resItemDto.id, data: resItemDto
                  });
                })
              }).catch(() => {
                communicateService.sendDragMessage({needfresh: true});
              });
            }
          } else if (item.resoureType === 'objective') {
            oedNewPrepareCourse.copyObjectiveTest({
              courseId: parseFloat(ctrl.courseId),
              teachingModuleId: targetItem.id ? targetItem.id : -1,
              testId: item.id,
            }, {}).$promise.then((res: any) => {
              targetItem.tests.push(res.tmTestDTO);
              ctrl.handleDataBeforeSort(targetItem.treeData, res.testDTO, item.id, targetItem.id ? targetItem.id : -1);
              ctrl.sortLeftTreeData(targetItem.treeData, targetItem.id, res.testDTO);
            }).catch(() => {
              communicateService.sendDragMessage({needfresh: true});
            });
          } else if (item.resoureType === 'subjective') {
            oedNewPrepareCourse.copySubjective({
              courseId: parseFloat(ctrl.courseId),
              teachingModuleId:  targetItem.id ? targetItem.id : -1,
              testId: item.id,
            }, {}).$promise.then((res: any) => {
              targetItem.tests.push(res.tmTestDTO);
              ctrl.handleDataBeforeSort(targetItem.treeData, res.subjectiveTestDTO, item.id, targetItem.id ? targetItem.id : -1);
              ctrl.sortLeftTreeData(targetItem.treeData, targetItem.id, res.subjectiveTestDTO);
            }).catch(() => {
              communicateService.sendDragMessage({needfresh: true});
            });
          } else if (item.resoureType === 'presentPackage') {
            oedNewPrepareCourse.copyPackageByIndex({
              courseId: parseFloat(ctrl.courseId),
              moduleId: targetItem.id,
              packageId:  item.id,
              index: e.dest.index
            }, {}).$promise.then((res: any) => {
              communicateService.sendDragMessage({needfresh: true, memoryHandleId: res.id,
                                                  isFromUseResource: true, presentPackageId: res.id});
            })
          }
        } else {
          communicateService.sendDragMessage({needfresh: true});
        }
      }
    }
  }
  ctrl.handleDataBeforeSort = (handleTreeData, res, sourceId, moduleId) => {
    let indexNum = null;
    _.remove(handleTreeData, (n: any, index) => {
      if (n.id === sourceId) {
        indexNum = index;
        return n.id === sourceId;
      }
    });
    res.resDetail = _.cloneDeep(res);
    res.isShown = true;
    res.resoureType = res.type;
    res.moduleId = moduleId;
    handleTreeData.splice(indexNum, 0, res);
  }
  ctrl.sortLeftTreeData = (handleTreeData, moduleId, handleData) => {
    _.each(handleTreeData, (res, index) => {
      res.sort = index;
    });
    const objectiveTest = _.filter(handleTreeData, (obj: any) => {
      return obj.resoureType === 'objective'
    }).map((r: any) => {
      return {id: r.id, sort: r.sort}
    });
    const presentResource = _.filter(handleTreeData, (obj: any) => {
      return obj.resoureType === 'presentPackage'
    }).map((r: any) => {
      return {id: r.id, sort: r.sort}
    });
    const subjectiveTest = _.filter(handleTreeData, (obj: any) => {
      return obj.resoureType === 'subjective'
    }).map((r: any) => {
      return {id: r.id, sort: r.sort}
    });
    const requestObj = {
      id: moduleId,
      objectiveTests: objectiveTest,
      presentResources: presentResource,
      subjectiveTests: subjectiveTest
    }
    oedTeachingModule.newUpdateItemOrder({id: moduleId}, requestObj).$promise.then((x: any) => {
      communicateService.sendDragMessage({
        needfresh: false, memoryHandleId : handleData.id, data: handleData
      });
    })
  }
  function loadFunctionClasses() {
    ctrl.teachingDesigns = {
      id: 'resource',
      layer: 1,
      title: '教学设计',
      collapsed: true,
      nodes: []
    };
    ctrl.subjectiveList = [];
    ctrl.objectiveList = [];
    ctrl.presentResourceList = [];
    return oedTeachingPattern.findAllTemplate({id: ctrl.courseId, type: 1}).$promise.then((tp: any) => {
      const result1 = _.map(tp.sectionTemplates, (st: any) => {
        const template = {
          id: st.id,
          title: st.name,
          layer: 1,
          collapsed: true,
          nodes: [],
          resoureType: 'allmodule',
          form: 'right',
        };
        if (_.size(st.modules)) {
          _.map(st.modules, (m: any, index: any) => {
            const teachDesignObj = {
              id: m.id,
              title: m.name,
              iconClass: 'fo-teaching tea-des-color',
              collapsed: false,
              nodes: [],
              resoureType: 'module',
              form: 'right',
              sort: m.sort
            };
            const resIds = _(m.presentResources).map('id').uniq().value();
            const reses = _.isEmpty(resIds) ? [] : oedPresentResource.getByIds({
              ids: resIds
            }).$promise.then((resItem: any) => {
              const arr = [];
              _.each(resItem, (rm: any) => {
                arr.push(oedPresentResource.get({ id: rm.id }).$promise);
              });
              return $q.all(arr).then((n: any) => {
                return n;
              })
            });
            return $q.all([reses]).then((arr) => {
              _.forEach(m.objectiveTests, (item: any) => {
                const itemObj = {
                  id: item.id,
                  iconClass: 'fo-obj obj-color',
                  resDetail: item,
                  resoureType: 'objective',
                  collapsed: false,
                  title: item.name,
                  type: 'objective',
                  form: 'right',
                  name: item.name,
                  sort: item.sort
                };
                teachDesignObj.nodes.push(itemObj);
              });
              _.forEach(m.subjectiveTests, (item: any) => {
                const itemObj = {
                  id: item.id,
                  iconClass: 'fo-subj subj-color',
                  resDetail: item,
                  resoureType: 'subjective',
                  title: item.name,
                  collapsed: false,
                  type: 'subjective',
                  form: 'right',
                  name: item.name,
                  sort: item.sort
                };
                teachDesignObj.nodes.push(itemObj);
              });
              _.forEach(arr[0], (resObj: any) => {
                if (resObj.items.length > 0) {
                  const rObj = getResourceData(resObj);
                  rObj.sort = _.find(m.presentResources, (pr: any) => pr.id === resObj.id).sort;
                  teachDesignObj.nodes.push(rObj);
                }
              })
              teachDesignObj.nodes = _.orderBy(teachDesignObj.nodes, 'sort', 'asc');
              if (_.size(teachDesignObj.nodes)) {
                template.nodes.push(teachDesignObj);
                template.nodes = _.orderBy(template.nodes, 'sort', 'asc');
              }
            })
          });
          ctrl.teachingDesigns.nodes.push(template)
        }
      })
      if (_.size(ctrl.teachingDesigns.nodes)) {
        ctrl.resourceAndTypes.push({
          title: '互动课型',
          type: 'teaching',
          resource: ctrl.teachingDesigns.nodes});
      }
      if (!_.size(ctrl.selectResourceList)) {
        ctrl.isInteraction = true;
        ctrl.selectResourceList = ctrl.teachingDesigns.nodes;
      }
      ctrl.teachingDesignList.push(ctrl.teachingDesigns);
      const modules = _(tp.sectionTemplates).map('modules').flatten().uniq().value();
      const courseIds = _(tp.sectionTemplates).map('courseId').flatten().uniq().value();
      ctrl.resources = {
        id: 'resource',
        layer: 1,
        title: '展示资源',
        collapsed: true,
        nodes: []
      };
      let resources = [];
      let subjectives = [];
      let objectives = [];
      const results2 = _.map(courseIds, (id: any, index) => {
        const oedPresentResourceArr = oedPresentResource.queryByCourse({
          course_id: id
        }).$promise;
        const oedSubjectiveTestArr = oedSubjectiveTest.queryByCourse({
          course_id: id
        }).$promise;
        const oedObjectiveTestArr = oedObjectiveTest.queryByCourse({
          course_id: id
        }).$promise;
        return $q.all([oedPresentResourceArr,oedSubjectiveTestArr,oedObjectiveTestArr]).then(res => {
          resources = _.concat(resources, res[0]);
          const singleResources = _.map(res[0], (item: any) => {
            return oedPresentResource.get({id: item.id}).$promise.then((res: any) => {
              if (res.items.length > 0) {
                ctrl.resources.nodes.push(getResourceData(res));
              }
            });
          });
          Promise.all(singleResources).then((a: any) => {
            if ((index + 1) === _.size(courseIds)) {
              ctrl.resourceList.push(ctrl.resources);
              ctrl.presentResourceList = ctrl.resources.nodes;
              getPresentResource();
              if (ctrl.selectBtnType == 'present') {
                ctrl.currentPresentResourceList = ctrl.presentResourceAndTypes.length > 0 ? ctrl.presentResourceAndTypes[ctrl.selectPresentResourceIndex].resource : [];
              }
            }
          });
          subjectives = _.concat(subjectives, res[1]);
          if ((index + 1) === _.size(courseIds)) {
            ctrl.subjectives = getDataByType('subjective', subjectives, 1);
            if (ctrl.subjectives !== null) {
              ctrl.resourceList.push(ctrl.subjectives);
              ctrl.subjectiveList = ctrl.subjectives.nodes;
              if (ctrl.selectBtnType == 'subjective') {
                ctrl.currentResourceList = ctrl.subjectiveList;
              }
            }
          }
          objectives = _.concat(objectives, res[2]);
          if ((index + 1) === _.size(courseIds)) {
            ctrl.objectives = getDataByType('objective', objectives, 1);
            if (ctrl.objectives !== null) {
              ctrl.resourceList.push(ctrl.objectives);
              ctrl.objectiveList = ctrl.objectives.nodes;
              if (ctrl.selectBtnType == 'objective') {
                ctrl.currentResourceList = ctrl.objectiveList;
              }
            }
          }
        })
        // oedPresentResource.queryByCourse({
        //   course_id: id
        // }).$promise.then((resource: any) => {
        //   resources = _.concat(resources, resource);
        //   _.forEach(resource, (item: any) => {
        //     oedPresentResource.get({id: item.id}).$promise.then((res: any) => {
        //       if (res.items.length > 0) {
        //         ctrl.resources.nodes.push(getResourceData(res));
        //       }
        //     });
        //   });
        //   if ((index + 1) === _.size(courseIds)) {
        //     ctrl.resourceList.push(ctrl.resources);
        //     ctrl.presentResourceList = ctrl.resources.nodes;
        //   }
        // });
        // oedSubjectiveTest.queryByCourse({
        //   course_id: id
        // }).$promise.then((subjective: any) => {
        //   subjectives = _.concat(subjectives, subjective);
        //   if ((index + 1) === _.size(courseIds)) {
        //     ctrl.subjectives = getDataByType('subjective', subjectives, 1);
        //     if (ctrl.subjectives !== null) {
        //       ctrl.resourceList.push(ctrl.subjectives);
        //       ctrl.subjectiveList = ctrl.subjectives.nodes;

        //     }
        //   }
        // });
        // oedObjectiveTest.queryByCourse({
        //   course_id: id
        // }).$promise.then((objective: any) => {
        //   objectives = _.concat(objectives, objective);
        //   if ((index + 1) === _.size(courseIds)) {
        //     ctrl.objectives = getDataByType('objective', objectives, 1);
        //     if (ctrl.objectives !== null) {
        //       ctrl.resourceList.push(ctrl.objectives);
        //       ctrl.objectiveList = ctrl.objectives.nodes;
        //     }
        //   }
        // });
      });
      return Promise.all(_.concat(result1, results2));
    })
  }

  function getDataByType(tp, data, lay) {
    if (!_.size(data)) {
      return null;
    }
    const obj: any = {
      id: tp,
      layer: lay,
      collapsed: true,
      title: tp === 'objective' ? '客观试卷' : '主观活动',
      nodes: []
    };
    _.forEach(data, (item: any) => {
      const itemObj = {
        id: item.id,
        iconClass: tp === 'objective' ? 'fo-obj obj-color' : 'fo-subj subj-color',
        resoureType: tp,
        resDetail: item,
        collapsed: false,
        title: item.name,
        type: tp === 'objective' ? 'objective' : 'subjective',
        form: 'right',
        name: item.name
      };
      obj.nodes.push(itemObj);
    });
    return obj;
  }

  function getResourceData(itemRes) {
    const itemObj: any = {
      id: itemRes.id,
      iconClass: 'fo-present-package res-color',
      title: itemRes.name,
      resoureType: 'presentPackage',
      resDetail: itemRes,
      collapsed: false,
      nodes: [],
      type: 'presentresource',
      form: 'right'
    };
    itemRes.resoureType = 'presentresource';
    itemRes.type = 'presentresource';
    _.forEach(itemRes.items, (re: any, index) => {
      const reObj = {
        id: re.id,
        suffix: re.flResource.suffix,
        title: re.resourceName,
        resoureType: 'singleResource',
        resDetail: itemRes,
        resIndex: index,
        collapsed: false,
        type: re.flResource.suffix,
        form: 'right',
        resourceName: re.resourceName,
        flResource: {suffix: re.flResource.suffix},
        resourceId: re.flResourceId
      };
      itemObj.nodes.push(reObj);
    });
    return itemObj;
  }

  function loadPickedCourses() {
    const pickedCourse = oedPickedCourse.getZhonglianPickedCourse({
      sectionId: ctrl.sectionId,
      classTime: ctrl.classTime
    });
    // const pickedCourse = oedPickedCourse.getPickedCourse({
    //   sectionId: ctrl.sectionId,
    //   classTime: ctrl.classTime
    // });
   return pickedCourse.$promise.then((res: any) => {
      if (_.size(res.analysis.nodes)) {
        _.map(res.analysis.nodes, (n: any) => {
          n.id = n.resExtraId;
          n.resoureType = 'zhonglian';
          n.title = n.title + '.' + n.suffix;
          n.form = 'right';
          n.isLessonType = true;
          n.resourceName = n.title;
          n.flResource = {suffix: n.suffix};
          n.resourceId = n.flResourceId;
        });
        res.analysis.collapsed = true;
        ctrl.resourceAndTypes.push({
          title: '教材教法分析',
          type: 'analysis',
          resource: res.analysis.nodes});
        ctrl.selectResourceList = res.analysis.nodes;
        ctrl.isInteraction = false;
        res.analysis = [res.analysis];
      }
      if (_.size(res.classTypes)) {
        _.map(res.classTypes, (item: any, index: any) => {
          item.collapsed = true;
          item.iconClass = 'fo-teaching tea-des-color';
          _.map(item.nodes, (node: any) => {
            node.layer = 2;
            node.collapsed = true;
            _.map(node.nodes, (child: any) => {
              child.layer = 3;
              child.collapsed = false;
              _.map(child.nodes, (n: any) => {
                n.layer = 4;
                n.id = n.resExtraId;
                n.resoureType = 'zhonglian';
                n.isLessonType = true;
                n.title = n.classType + '-' + n.title + '.' + n.suffix;
                n.form = 'right';
                n.resourceName = n.title;
                n.flResource = {suffix: n.suffix};
                n.resourceId = n.flResourceId;
              })
            })
            // _.map(node.nodes, (n: any) => {
            //   n.layer = 4;
            //   n.id = n.resExtraId;
            //   n.resoureType = 'zhonglian';
            //   n.isLessonType = true;
            //   n.title = n.classType + '-' + n.title + '.' + n.suffix;
            //   n.form = 'right';
            //   n.resourceName = n.title;
            //   n.flResource = {suffix: n.suffix};
            //   n.resourceId = n.flResourceId;
            // })
          })
          ctrl.resourceAndTypes.push({
            title: _.replace(item.title, '版', '课型'),
            type: 'classTypes',
            resource: item.nodes});
          if (!_.size(ctrl.selectResourceList) && index === 0) {
            ctrl.selectResourceList = item.nodes;
            ctrl.isInteraction = false;
          }
        });
      }
      ctrl.pickedCourses = res;
      return loadFunctionClasses();
    });
  }

  function loadBusinessCourses() {
    const businessCourse = oedBusinessCourse.getBusinessCourse({
      sectionId: ctrl.sectionId
    });
   return businessCourse.$promise.then((res: any) => {
      ctrl.presentResourceAndTypes = [];
      if (_.size(res)) {
        let videoArr = [];
        let imgArr = [];
        _.map(res, (item: any) => {
          item.layer = 1;
          item.collapsed = false;
          item.title = item.title === '微课视频' ? '微课' : item.title;
          _.map(item.nodes, (node: any) => {
            node.id = node.resExtraId;
            node.resoureType = 'zhonglian';
            node.title = node.title + '.' + node.suffix;
            node.type = node.suffix;
            node.form = 'right';
            node.resourceName = node.title;
            node.flResource = {suffix: node.suffix};
            node.resourceId = node.flResourceId;
          })
          if (_.includes(item.title, '影音')) {
            videoArr = _.concat(videoArr, item.nodes)
          } else if (_.includes(item.title, '图片')) {
            imgArr = _.concat(imgArr, item.nodes)
          } else {
            ctrl.presentResourceAndTypes.push({
              title: item.title,
              resource: item.nodes});
          }
        });
        if (_.size(videoArr)) {
          ctrl.presentResourceAndTypes.push({title: '影音资源', resource: videoArr})
        }
        if (_.size(imgArr)) {
          ctrl.presentResourceAndTypes.push({title: '教学图库', resource: imgArr})
        }
        ctrl.BusinessCourses = res;
      }
      return oedCloudPresentResItem.getMicroLessonByCourseId({courseId: ctrl.courseId}).$promise.then((les: any) => {
        _.forEach(les, (r: any) => {
          const reObj = {
            id: r.id,
            suffix: r.flResource.suffix,
            title: r.resourceName,
            resoureType: 'singleResource',
            isWeiKe: true,
            resDetail: r,
            collapsed: false,
            type: r.flResource.suffix,
            form: 'right',
            resourceName: r.resourceName,
            flResource: {suffix: r.flResource.suffix},
            resourceId: r.flResourceId
          };
          const lessonIndex = _.findIndex(ctrl.presentResourceAndTypes, (b: any) => b.title === '微课');
          if (lessonIndex > -1) {
            ctrl.presentResourceAndTypes[lessonIndex].resource.push(reObj);
          } else {
            ctrl.presentResourceAndTypes.push({title: '微课', resource: [reObj]})
          }
        })
      })
    })
  }
  ctrl.getResType = (type: any) => {
    if (!type) {
      return null
    }
    let resourceType;
    switch (type ? type.toLowerCase() : type) {
      case 'ppt':
      case 'doc':
      case 'pptx':
      case 'docx':
        resourceType = 'office'
        break;
      case 'xls':
        resourceType = 'excel'
        break;
      case 'mp4':
      case 'flv':
      case 'rmvb':
      case 'wmv':
      case 'rm':
      case 'avi':
        resourceType = 'video'
        break;
      case 'mp3':
        resourceType = 'audio'
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
        resourceType = 'image'
        break;
      default:
        resourceType = 'unknown'
        break;
    }
    return resourceType;
  }
  function getPresentResource() {
    let resources = [];
    _.map(ctrl.presentResourceList, (p: any) => {
      resources = _.concat(resources, p.nodes);
    });
    resources = _.uniqBy(resources, 'id');
    _.forEach(resources, (r: any) => {
      if (ctrl.getResType(r.flResource.suffix) === 'image') {
        const imgIndex = _.findIndex(ctrl.presentResourceAndTypes, (b: any) => b.title === '教学图库');
        if (imgIndex > -1) {
          ctrl.presentResourceAndTypes[imgIndex].resource.push(r);
        } else {
          ctrl.presentResourceAndTypes.push({title: '教学图库', resource: [r]})
        }
      } else if (ctrl.getResType(r.flResource.suffix) === 'video' || ctrl.getResType(r.flResource.suffix) === 'audio') {
        const dioIndex = _.findIndex(ctrl.presentResourceAndTypes, (b: any) => b.title === '影音资源');
        if (dioIndex > -1) {
          ctrl.presentResourceAndTypes[dioIndex].resource.push(r);
        } else {
          ctrl.presentResourceAndTypes.push({title: '影音资源', resource: [r]})
        }
      }
    })
    ctrl.presentResourceAndTypes = sortPresentResource(_.uniq(ctrl.presentResourceAndTypes));
  }
  function sortPresentResource(res) {
    const arr1 = [];
    const arr2 = [];
    const arr3 = [];
    _.forEach(res, (r: any) => {
      if (r.title === '微课') {
        arr1.push(r);
      } else if (r.title !== '教学图库' && r.title !== '影音资源') {
        arr2.push(r);
      } else {
        arr3.push(r);
      }
    });
    return _.concat(arr1, arr2, arr3);
  }
}
