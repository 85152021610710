import { flatMap } from 'rxjs/operators';
import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { OedExerciseService } from '../oed-exercise.service'
import { LoadingService } from 'app/shared/loading/loading.service';
import * as _ from 'lodash';
import { NodeDTO } from '../../manage'
import { dirListToTree, buildDefOpenMap } from '../../exercise-book/dialog/ex-dialog-utils';
import { forEach } from 'angular';
import { forkJoin, of, from } from 'rxjs';
import { AppStateService } from 'app/core/app-state.service';

@Component({
  selector: 'app-preview-json',
  template: require('./preview-json.component.html'),
  styles: [require('./preview-json.component.scss')]
})
export class PreviewJsonComponent implements OnInit, OnChanges {
  jsonHasEdit: boolean;
  jsonHasPaperOrQuestion = true;
  isSelectPapre = true;
  paperList: any;
  questionsList: NodeDTO[];
  currentPapersList: any;
  currentQuestionsList: any;
  hasQuestions: boolean;
  treeData: any;
  allDirsList: any
  selectDirId: any
  private expandItems = {}
  private curUserInfo
  private selectedSortOrder
  private curSelectedNode
  private testSizeInLibary
  private quesSizeInLibary

  @Output() private hasEdit = new EventEmitter();
  @Output() private changeBook = new EventEmitter();
  @Output() private doSelectWetherPaper = new EventEmitter();
  @Output() private onDeleteQuestion = new EventEmitter();
  @Output() private onAddToResBox = new EventEmitter();
  @Output() private onAddTestToResBox = new EventEmitter();
  @Output() private onDeleteTest = new EventEmitter();
  @Input() resboxHolder: any;
  @Input() exerciseBookId: any;
  @Input() exerciseBookName: any;
  @Input() exerciseBookList: any;
  @Input() paperMode: any;
  @Input() inLibary: boolean;
  @Input() material: any;

  constructor(
    private loadingService: LoadingService,
    public oedExerciseService: OedExerciseService,
    private userInfo: AppStateService,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.has(changes, 'paperMode')) {
      const isInPaperMode = this.paperMode === 'test'
      if (this.isSelectPapre === isInPaperMode) {
        return
      } else {
        this.isSelectPapre = isInPaperMode
      }
    }
    this.getInitData();
  }

  ngOnInit() {
    if (this.paperMode) {
      this.isSelectPapre = this.paperMode === 'question'
    }
    this.getInitData();
  }

  onSelectExercise(book) {
    this.changeBook.emit(book);
  }

  getInitData() {
    forkJoin(
      this.userInfo,
      this.oedExerciseService.getAllDirs(this.exerciseBookId)
    )
      .pipe(this.loadingService.withLoading())
      .subscribe(([info, data]) => {
        this.curUserInfo = info
        // 判断有没有生成目录
        if (_.isEmpty(data[0])) {
          this.jsonHasEdit = false
          this.hasEdit.emit(false);
        } else {
          this.jsonHasEdit = true
          this.hasEdit.emit(true);
        }
        this.allDirsList = data;
        this.treeData = dirListToTree(this.allDirsList);
        let selected = this.allDirsList[0]
        if (selected.children && selected.children.length > 0) {
          const selectedId = window.localStorage.getItem('exercise-book-' + this.exerciseBookId)
          if (selectedId) {
            const section = _.find(data, s => s.id === parseInt(selectedId))
            if (section) {
              selected = section
            }
          } else {
            selected = selected.children[0]
          }
        }
        this.buildExpandChildren(this.allDirsList[0])
        this.selectCurrentDir(selected);
      })
  }

  private buildExpandChildren = (selectedItem: any) => {
    const children = _.get(selectedItem, 'children', [])
    if (_.size(children) <= 0) {
      return
    }
    for (const c of children) {
      _.set(this.expandItems, `${c.id}`, c)
      this.buildExpandChildren(c)
    }
  }

  // 选择试卷
  selectPaper() {
    if (this.isSelectPapre) return
    this.isSelectPapre = true;
    this.doSelectWetherPaper.emit(true)
    if (this.inLibary) {
      const body = this.buildQueryCountBody()
      this.loadTestForLibary(body)
    }
  }

  // 选中单题
  selectQuestions() {
    if (!this.isSelectPapre) return
    this.isSelectPapre = false;
    this.doSelectWetherPaper.emit(false)
    if (this.inLibary) {
      const body = this.buildQueryCountBody()
      this.loadQuesForLibary(body)
    }
  }

  // 根据当前目录切换试卷或者题目
  selectCurrentDir(item) {
    const id = _.get(item, 'id');
    if (this.selectDirId === id) {
      return;
    }
    this.selectDirId = id;
    if (_.isNumber(this.exerciseBookId) && _.isNumber(this.selectDirId)) {
      window.localStorage.setItem('exercise-book-' + this.exerciseBookId, this.selectDirId + '')
    }
    if (this.inLibary) {
      this.selectCurrentDirForLibary(item)
      return
    }
    return forkJoin(this.oedExerciseService.getTestsByNodeId(this.selectDirId),
      this.oedExerciseService.getQuestionByNodeId(this.selectDirId))
      .pipe(this.loadingService.withLoading())
      .subscribe((data) => {
        this.currentPapersList = data[0];
        this.currentQuestionsList = data[1];
      })
  }

  private selectCurrentDirForLibary = (item: any) => {
    this.curSelectedNode = item
    this.selectedSortOrder = { name: '引用量', value: 'downloads' }
    const body = this.buildQueryCountBody()
    this.loadResource(body)
  }

  private buildQueryCountBody = (option = { page: 0 }) => {
    return {
      countPerPage: 10,
      curPage: option.page,
      desc: true,
      sortType: _.get(this.selectedSortOrder, 'value', 'downloads'),
      schoolId: parseInt(_.get(this.curUserInfo, 'schoolId', 0)),
      nodeIds: [_.get(this.curSelectedNode, 'id', 0)]
    }
  }

  private loadTestForLibary = (body: any) => {
    this.oedExerciseService.getTestCount(body)
      .pipe(
        flatMap(count => {
          this.testSizeInLibary = count
          if (count > 0) {
            return this.oedExerciseService.getExbkTest(body)
          } else {
            return of({})
          }
        }),
        this.loadingService.withLoading()
      )
      .subscribe((test: any[]) => {
        this.currentPapersList = _.isEmpty(test) ? [] : test
      })
  }

  private loadQuesForLibary = (body: any) => {
    this.oedExerciseService.getQuesCount(body)
      .pipe(
        flatMap(count => {
          this.quesSizeInLibary = count
          if (count > 0) {
            return this.loadQuesByRequestBody(body)
          } else {
            return of([])
          }
        }),
        this.loadingService.withLoading()
      )
      .subscribe((questions: any[]) => {
        this.currentQuestionsList = _.isEmpty(questions) ? [] : _.map(questions, q => ({ question: q }))
      })
  }

  private loadQuesByRequestBody = (body: any) => {
    return this.oedExerciseService.getExbkQues(body)
      .pipe(flatMap(test => {
        if (_.size(test) > 0) {
          const resourceIds = _.map(test, r => r.resourceId)
          const requests = _.map(resourceIds, id => this.oedExerciseService.getQuesByQuesId(id))
          return forkJoin(requests)
        }
        return of({})
      }))
  }

  private loadResource = (body: any) => {
    if (this.isSelectPapre) {
      this.loadTestForLibary(body)
    } else {
      this.loadQuesForLibary(body)
    }
  }

  private pageChangedInLibary = (event) => {
    const body = this.buildQueryCountBody({ page: event.page - 1 })
    this.loadResource(body)
  }

  private doAddToResBox = (params) => this.onAddToResBox.emit(params)
  private doDeleteQuestion = (q) => this.onDeleteQuestion.emit(q)
  private doAddTestToResBox = (params) => this.onAddTestToResBox.emit(params)
  private doDeleteTest = (t) => this.onDeleteTest.emit(t)

  private doSortOrderChange = ($event: any) => {
    this.selectedSortOrder = _.get($event, 'order', {})
    const body = this.buildQueryCountBody()
    this.loadResource(body)
  }

  private selectQuestionTypes = (types: any) => {
    const body = this.buildQueryCountBody()
    _.set(body, 'questionTypes', types)
    this.loadResource(body)
  }
}
