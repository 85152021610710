import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TestSessionService } from '../test-session.service';
import { TestSessionDetailsDTO } from 'app/models/obj-test-session';
import { sessionDuration } from 'app2/utils/dateUtils';
import { isGoodTest, isBadTest, getSummaryImage } from 'app2/utils/testIconUtils';


@Component({
  selector: 'app-obj-completion-previewer',
  template: require('./obj-completion-previewer.component.html'),
  styles: [require('./obj-completion-previewer.component.scss')]
})
export class ObjCompletionPreviewerComponent implements OnInit, OnChanges {
  @Input() endTime: number;
  @Input() fromClassSession: boolean;
  @Input() showOnlyTestName: boolean;
  @Input() startTime: number;
  @Input() testSessionId: number;
  @Input() pubStudents: number[];

  format = 'HH:mm';
  testName: string;
  totalStudentCount: number;
    onlineStudentCount: any;
    submitRate: number;
    totalSubmit: number;
    submitRateInPercentage: string;
    subs$: Subscription;

  constructor(private testSessionService: TestSessionService) { }

  ngOnInit() {
    this.format = this.fromClassSession ? 'HH:mm' : 'MM月dd号';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.has(changes, 'testSessionId')) {
      if (!_.isUndefined(this.testSessionId)) {
        this.loadData();
      }
    }
  }

  loadData () {
    const details$ = this.testSessionService.getDetails(this.testSessionId)
    if (this.subs$) {
      this.subs$.unsubscribe()
    }

    this.subs$ = details$.pipe(map((detail) => {
        this.testName = detail.test.name
        this.totalStudentCount = this.fromClassSession ? _.size(detail.students) : _.size(this.pubStudents);
        this.calcOnlineStdsCount(detail)
        this.calcSubmitRate(detail)
    })).subscribe(() => {}, (e) => {
      console.log('error', e)
    })
  }

  calcOnlineStdsCount (detail: TestSessionDetailsDTO) {
    this.onlineStudentCount = _.reduce(detail.students, (sum: any, std: any) => {
      if (std.wasOnline) {
        sum++;
      }
      return sum;
    }, 0);
  }

  calcSubmitRate (detail: TestSessionDetailsDTO) {
    const allCount = this.fromClassSession ? this.onlineStudentCount : this.totalStudentCount
    if (allCount === 0) {
      this.submitRate = 0;
    } else {
      const part = _.partition(detail.students, (s: any) => {
        return _.isNil(s.submitTime)
      })
      this.totalSubmit = _.size(part[1])
      this.submitRate = this.totalSubmit * 1.0 / allCount;
    }
    this.submitRateInPercentage = (this.submitRate * 100).toFixed(0);
  }

  isGoodTest () {
    return isGoodTest(this.submitRate);
  }

  isBadTest () {
    return isBadTest(this.submitRate);
  }

  getSummaryImage (prefix: any) {
    return getSummaryImage(prefix, this.submitRate);
  }

  getSessionDuration () {
    return sessionDuration(this.startTime, this.endTime, this.fromClassSession);
  }
}
