import * as angular from 'angular';
import 'app2/utils/fileUtils';
import _ = require('lodash');
const resourceUtils: any = require('app2/utils/resourceUtils');
const nobookUtils: any = require('app2/utils/nobookUtils');
const url: any = require('url');
const nobookHost = 'school.nobook.com';

angular.module('app.directives.resource.preResItemPlayer', [])
.component('preResItemPlayer', {
  bindings: {
    images: '<',
    index: '=',
  },
  controller: preResItemPlayerCtrl,
  template: <string> require('./preResItemPlayer.html'),
});

preResItemPlayerCtrl.$inject = ['oedConfig', 'userInfo', '$window']
function preResItemPlayerCtrl(oedConfig: any, userInfo: any, $window: any) {
  const ctrl = this;
  // 判断顶层窗口是否为iframe(配合桌面端使用)
  ctrl.topWindowIsIframe = $window.top === $window.self;
  ctrl.linkOpenType = ctrl.topWindowIsIframe ? '_blank' : '_self';
  const isExternalResource = (res: any) => {
    return res.isExternal && _.isEmpty(res.resourceUUID)
  }

  const imageBaseUrl = 'http://s-pic.rayclass.com';
  const videoBaseUrl = 'http://s-v.rayclass.com';

  this.$onInit = () => {
    updateImageInfo();

    const basePath = oedConfig.base();
    const imgSrcPathID = _.template(basePath + 'resource/view/img_src/<%=res.id%>');
    const imgDownloadPath = _.template(basePath + 'resource/view/img_src/<%=res.id %>/raw/<%=res.id+res.suffix%>');
    const boardContentPath = _.template(basePath + 'resource/board/<%=res.id %>/content');
    const boardCommentPath = _.template(basePath + 'resource/view/img_src/<%=res.id %>');

    function getResProp(r: any, name: any) {
      if (_.has(r, 'flResource')) {
        return _.get(r, 'flResource.' + name);
      }
      return _.get(r, name);
    }

    ctrl.getResUUID = (res: any) => {
      return resourceUtils.extractResourceUUID(res) || resourceUtils.extractResourceUUID(res.flResource);
    }

    function getImgSrcPath(res: any, name: any) {
      const uuid = ctrl.getResUUID(res);
      if (_.isEmpty(uuid)) {
        const resId = _.get(res, 'flResourceId', res.id)
        return imgSrcPathID({
          res: {
            id: resId,
          },
        });
      }
      return '/ray/resource/uuid/' + uuid + '/raw64/' + name;
    }

    ctrl.getImageUrlForDownload = (res: any) => {
      const imageUrl = getImageUrl(res, true);
      $window.open(imageUrl, _.get($window, '_link_open_type'));
    }

    function getImageUrl(resItem: any, isDownload: any) {
      if (!resItem) {
        return null;
      }

      function b64EncodeUnicode(str: any) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match: any, p1: any) => {
          return String.fromCharCode(Number('0x' + p1));
        }));
      }

      let src = null;
      const prop = isExternalResource(resItem) ? 'externalResourceType' : 'fileType';
      const fileType = getResProp(resItem, prop);
      if (fileType === 'boardContent') {
        return boardContentPath({
          res: resItem,
        });
      } else if (fileType === 'boardComment') {
        return boardCommentPath({
          res: resItem,
        });
      }

      if (isDownload) {

        if (isExternalResource(resItem)) {
          return resItem.externalResourceUrl
        }

        if (resItem.webUrl && url.parse(resItem.webUrl).hostname === nobookHost) {
          return nobookUtils.buildRedirectUrl(resItem.webUrl, userInfo.userInfo.uid)
        }

        if (resItem.webUrl) {
          return resItem.webUrl;
        }

        let name = resItem.resourceName || '';
        const suffix = getResProp(resItem, 'suffix');
        if (suffix && !name.endsWith(suffix)) {
          name = name + '.' + suffix;
        }
        name = b64EncodeUnicode(encodeURIComponent(name));
        return getImgSrcPath(resItem, name);
      }

      if (fileType === 'image' || fileType === 'audio' || fileType === 'video') {
        if (isExternalResource(resItem)) {
          return resItem.externalResourceUrl
        }
        src = getResProp(resItem, 'imageSrcHttpPath');
        if (!src) {
          return getImgSrcPath(resItem, '');
        }
        return src;
      }
      return getResProp(resItem, 'imageThumbHttpPath');
    }

    ctrl.getOpenImageUrl = (resItem: any) => {
      const fileType = getResProp(resItem, 'fileType');
      if (fileType === 'boardContent') {
        return boardContentPath({
          res: resItem,
        });
      } else if (fileType === 'boardComment') {
        return boardCommentPath({
          res: resItem,
        });
      }

      if (isExternalResource(resItem)) {
        return resItem.externalResourceUrl
      }

      if (resItem.webUrl && url.parse(resItem.webUrl).hostname === nobookHost) {
        return nobookUtils.buildRedirectUrl(resItem.webUrl, userInfo.userInfo.uid)
      }

      if (resItem.webUrl) {
        return resItem.webUrl;
      }

      let src = getResProp(resItem, 'imageSrcHttpPath');
      if (!src) {
        src = imgDownloadPath({
          res: resItem,
        });
      }

      return src;
    };

    ctrl.getImageCaption = (res: any, cfg: any) => {
      if (!cfg) {
        return res.resourceName;
      }
      if (!res) {
        return '下载原文件';
      }

      if (cfg === 'download') {
        if (res.webUrl) {
          return '打开原网页';
        }
      }

      return '下载原文件';
    };

    ctrl.isVideo = (r: any) => {
      const prop = isExternalResource(r) ? 'externalResourceType' : 'fileType';
      const resType = getResProp(r, prop);
      return resType === 'audio' || resType === 'video';
    };

    ctrl.isZlwh = (image: any) => {
      if (typeof image === 'object' && image && image.flResource && image.flResource.source) {
        return image.flResource.source === 'zlwh';
      }
      return false;
    };

    //
    // hack Lightbox方法
    // 如果第二个参数为true, 则返回是否文字资源
    ctrl.isSharedVideo = (r: any, opt: any) => {
      if (opt) {
        const prop = isExternalResource(r) ? 'externalResourceType' : 'fileType';
        return getResProp(r, prop) === 'text';
      }
      //
      // 始终返回false, 不支持youtube之类的视频
      return false;
    };

    ctrl.prevImage = () => {
      if (ctrl.index !== 0) {
        ctrl.index --;
      } else {
        ctrl.index = ctrl.images.length - 1;
      }
      updateImageInfo();
    }

    ctrl.nextImage = () => {
      if (ctrl.index !== ctrl.images.length - 1) {
        ctrl.index ++;
      } else {
        ctrl.index = 0;
      }
      updateImageInfo();
    }

    function updateImageInfo() {
      ctrl.image = ctrl.images[ctrl.index];
      ctrl.imageUrl = getImageUrl(ctrl.image, false);
      if (process.env.NAME !== 'beta') {
        const fp = _.get(ctrl.image, 'flResource.fileType');
        if (fp === 'video' || fp === 'audio') {
          ctrl.imageUrl = videoBaseUrl + getImageUrl(ctrl.image, false);
        }
        if (fp === 'image') {
          ctrl.imageUrl = imageBaseUrl + getImageUrl(ctrl.image, false);
        }
      }
    }
  }
}
