import Promise = require('bluebird')
import angular = require('angular');
import * as bluebird from 'bluebird'
import _ = require('lodash');

require('assets/styles/oedTable.scss');

angular.module('app.review.objOverAllStatTable', [])
  .controller('objOverAllStatTableCtrl', objOverAllStatTableCtrl);

objOverAllStatTableCtrl.$inject = ['$scope', 'data', '$uibModalInstance',
  'oedTmSession', 'oedClassUser', '$q',
  'oedTaskListPublishRecord', 'oedTaskListStudent', 'oedUserInfo']
function objOverAllStatTableCtrl(
  $scope: any, data: any, $uibModalInstance: any,
  oedTmSession: any, oedClassUser: any, $q: any,
  oedTaskListPublishRecord: any, oedTaskListStudent: any,
  oedUserInfo: any
) {
  $scope.data = data;
  $scope.close = () => {
    $uibModalInstance.close();
  };

  $scope.reverse = true;
  $scope.propertyId = 'count';
  $scope.sortBy = (propertyId: any) => {
    $scope.reverse = ($scope.propertyId === propertyId) ? !$scope.reverse : true;
    $scope.propertyId = propertyId;
  };
  $scope.propertys = [
    {id: 'studentId', name : '学生ID'},
    {id: 'studentName', name : '姓名'},
    {id: 'startTime', name : '开始答题时间'},
    {id: 'submitTime', name : '完成时间'},
    {id: 'count', name : '作业完成率'},
  ];

  $scope.deleteStudents = {}
  $scope.loading = $q.all([loadDetails(), loadClassUser()]).then(() => {
    const deleteStuIds = []
    _.each($scope.rows, (row: any) => {
      const student = _.get($scope.stdMap, `${row.studentId}`, {})
      if (_.isEmpty(student)) {
        deleteStuIds.push(row.studentId)
      }
    })
    Promise.mapSeries(deleteStuIds, (id: number) => {
      return oedUserInfo.getByUid({ id }).$promise.then((s: any) => {
        $scope.deleteStudents[id] = s
      })
    })
      .then(() => {
        _.each($scope.rows, (row: any) => {
          let fullName = _.get($scope.stdMap, `${row.studentId}.name`, '')
          if (fullName == '') {
            fullName = _.get($scope.deleteStudents, `${row.studentId}.name`, '')
          }
          row.fullStdName = fullName;
          row.studentName = _.truncate(fullName, {
            length: 7,
          });
        });
      })
  });

  function loadClassUser() {
    return oedClassUser.queryClassStudents({
      classId: data.classId,
    }).$promise.then((students: any) => {
      $scope.stdMap = _.keyBy(students, 'uid');
    });
  }

  function loadDetails() {
    if (_.isUndefined(data.publishRecordId)) {
      return loadTmDetails();
    } else {
      return loadPubDetails();
    }
  }

  function loadPubDetails() {
    return oedTaskListPublishRecord.get({id: data.publishRecordId}).$promise
      .then((record: any) => bluebird.all([oedTmSession.getPubDetails({
      pubRecordId: data.publishRecordId,
    }).$promise, oedTaskListStudent.getStudentsByTaskList({
      id: record.taskListId
    })]).spread((detail: any, tlStudents: any) => {
      const tlStudentsById = _.keyBy(tlStudents, 'studentId')
      $scope.rows = _.chain(detail.listStd).map((row: any) => {
        const submitTime = _.get(tlStudentsById[row.studentId], 'submitDate', row.submitTime)
        return {
          ...row,
          submitTime,
        }
      }).value()
    }))
  }

  function loadTmDetails() {
    return oedTmSession.getDetails({
      tmSessionId: data.tmSessionId,
    }).$promise.then((detail: any) => {
      $scope.rows = detail.listStd;
    });
  }

}
