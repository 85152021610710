import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-office-editor-tip',
  template: require('./office-editor-tip.component.html'),
  styles: [require('./office-editor-tip.component.scss')]
})
export class OfficeEditorTipComponent implements OnInit {
  @Output() public action = new EventEmitter<string>();

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }
  
  public close = () => {
    this.bsModalRef.hide()
  }
}
