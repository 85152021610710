import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LessonDTO, TestOrPresentDTO } from '../models/lesson';
import { load } from '@angular/core/src/render3';

@Injectable({
  providedIn: 'root'
})
export class LessonMessageService {

  public reloadPatternActionSource = new Subject<void>();
  public reloadPatternAction$ = this.reloadPatternActionSource.asObservable();

  public reloadCourseResesActionSource = new Subject<TestOrPresentDTO[]>();
  public reloadCourseResesActionAction$ = this.reloadCourseResesActionSource.asObservable();

  public addCourseActionSource = new Subject<LessonDTO>();
  public addCourseAction$ = this.addCourseActionSource.asObservable();

  public saveRecommendCourseActionSource = new Subject<void>();
  public saveRecommendCourseAction$ = this.saveRecommendCourseActionSource.asObservable();

  public loadingActionSource = new Subject<boolean>();
  public loadingAction$ = this.loadingActionSource.asObservable();

  public getTeachingModulesIfNull = new Subject<boolean>();
  public getTeachingModulesNull$ = this.getTeachingModulesIfNull.asObservable();


  constructor() { }

  public sendReloadPatternAction() {
    this.reloadPatternActionSource.next()
  }

  public sendReloadCourseResesAction(tests: TestOrPresentDTO[]) {
    this.reloadCourseResesActionSource.next(tests)
  }

  public sendAddCourseAction(data: LessonDTO) {
    this.addCourseActionSource.next(data)
  }

  public sendSaveRecommendCourseAction() {
    this.saveRecommendCourseActionSource.next()
  }

  public sendLoadingAction(loading: boolean) {
    this.loadingActionSource.next(loading)
  }

  public sendTeachingModulesIfNull(modules: boolean) {
    this.getTeachingModulesIfNull.next(modules);
  }
}
