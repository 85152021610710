(function() {
  angular.module('app.prepareCourse') //声明angular模块
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareCourse.taskDesign', {
        url: '/task/:taskListId/design',
        template: require('assets/templates/prepareCourse/taskDesign.html'),
        controller: 'taskDesignCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'bg_img'
      });
    }]).controller('taskDesignCtrl', taskDesignCtrl);

  taskDesignCtrl.$inject = ['$scope', '$q', '$state', '$stateParams', '$uibModal', 'Upload',
    '$http', 'dialogs', 'resize', 'notify', 'oedCourse', 'oedPresentResource', 'oedObjectiveTest', 'oedSubjectiveTest',
    'oedTaskList', 'oedTask', 'oedTaskTest', 'oedTaskPresentResource', 'oedTaskListPublishRecord', 'oedClassroom']
  function taskDesignCtrl($scope, $q, $state, $stateParams, $uibModal, Upload,
    $http, dialogs, resize, notify, oedCourse, oedPresentResource, oedObjectiveTest, oedSubjectiveTest,
    oedTaskList, oedTask, oedTaskTest, oedTaskPresentResource, oedTaskListPublishRecord, oedClassroom) {
    $scope.currentState.title = '课后作业设计';
    var taskListId = _.parseInt($stateParams.taskListId);
    $scope.taskListId = taskListId;
    var courseId = _.parseInt($stateParams.courseId);
    $scope.isShow = false;
    $scope.toggleBtn = function() {
      $scope.isShow = !$scope.isShow;
    };

    $scope.$on('$destroy', function() {
      $scope.subTitle.name = null;
    });
    $scope.hiddenOk = false;
    $scope.hidden = function() {
      $scope.hiddenOk = !$scope.hiddenOk;
    };

    $scope.$on('resize', function() {
      $scope.setTaskDesHeight();
    });

    var getType = function(res) {
      if (res.constructor == oedObjectiveTest)
        return 'oedObjectiveTest';
      if (res.constructor == oedSubjectiveTest)
        return 'oedSubjectiveTest';
      if (res.constructor == oedPresentResource)
        return 'oedPresentResource';
      return '??';
    };

    $scope.getResIcon = function(res) {
      if (!res) {
        return '??';
      }
      if (res.constructor == oedObjectiveTest)
        return 'keguan_icon';
      if (res.constructor == oedSubjectiveTest)
        return 'zhuguan_icon';
      if (res.constructor == oedPresentResource)
        return 'zhanshi_icon';
      return '??';
    };

    $scope.resourceFilters = [{
      title: '全部素材',
      resources: function() {
        return $scope.allResources;
      },
      //对应图标和选中状态图标
      iconImage: ['source_icon01.png', 'source_icon01_hover.png']
    }, {
      title: '客观试卷',
      resources: function() {
        return $scope.objectiveTests;
      },
      iconImage: ['source_icon03.png', 'source_icon03_hover.png']
    }, {
      title: '主观试卷',
      resources: function() {
        return $scope.subjectiveTests;
      },
      iconImage: ['source_icon04.png', 'source_icon04_hover.png']
    }, {
      title: '展示资源',
      resources: function() {
        return $scope.presentResources;
      },
      iconImage: ['source_icon05.png', 'source_icon05_hover.png']
    }];

    $scope.setFilter = function(idx) {
      $scope.currentFilterIdx = idx;
      $scope.curResources = $scope.resourceFilters[idx].resources();
    };

    $scope.sortResource = function(a, b) {
      if (a.testId) {
        if (b.resId)
          return 1;
        else if (a.$$type == 'objective' && b.$$type == 'subjective')
          return -1;
        else if (a.$$type == 'subjective' && b.$$type == 'objective')
          return 1;
        return a.sort - b.sort;
      } else {
        if (b.testId)
          return -1;
        return a.sort - b.sort;
      }
    };

    $scope.getPresentResById = function(id) {
      return $scope.presentResIdMap[id];
    };

    $scope.getTestById = function(id) {
      return $scope.testIdMap[id];
    };

    var loadTaskResources = function(tasks) {
      if (_.isEmpty(tasks)) {
        $scope.taskResources = {};
        return 0;
      }

      $scope.taskResources = {};
      var loadingTasks = _.map(tasks, function(t) {
        var objTests = oedTaskTest.queryTaskObjectiveTestByTaskId({
          id: t.id
        });
        var subjTests = oedTaskTest.queryTaskSubjectiveByTaskId({
          id: t.id
        });

        var preReses = oedTaskPresentResource.queryByTaskId({
          id: t.id
        });

        $scope.taskResources[t.id] = {
          objectiveTests: objTests,
          subjectiveTests: subjTests,
          presentResources: preReses,
          allTests: [],
          all: []
        };

        $q.all([objTests.$promise, subjTests.$promise, preReses.$promise]).then(function(ot, st) {
          _.map(subjTests, function(t) {
            t.$$type = 'subjective';
          });

          _.map(objTests, function(t) {
            t.$$type = 'objective';
          });

          _.map(preReses, function(t) {
            t.$$type = 'present';
          });

          var allTests = objTests.concat(subjTests);
          $scope.taskResources[t.id].allTests = allTests;
          var all = preReses.concat(allTests).sort($scope.sortResource);
          $scope.taskResources[t.id].all = all;
        });

        return $q.all([objTests.$promise, subjTests.$promise, preReses.$promise]);
      });

      return $q.all(loadingTasks);
    };

    $scope.onDropItemToAllResource = function(evt, item) {
      var idx = _.parseInt($(evt.target).attr('data-task-index'));
      var task = $scope.tasks[idx];
      var reses = $scope.taskResources[task.id].all;
      var newReses = _.filter(reses, function(r) {
        return r.constructor != oedTaskPresentResource && r.constructor != oedTaskTest;
      });

      var origReses = _.filter(reses, function(r) {
        return r.constructor == oedTaskPresentResource || r.constructor == oedTaskTest;
      });

      var maxSort = _.maxBy(origReses, function(r) {
        if (newReses[0].$$type == r.$$type) { //试题
          return r.sort || 0;
        } else {
          return 0;
        }
      });

      var nextSort = !maxSort ? 1 : maxSort.sort + 1;
      var resToCreate = _.map(newReses).map(function(t) {
        var res = null;
        if (t.type) {
          res = new oedTaskTest({
            taskId: task.id,
            testId: t.id,
            sort: nextSort++,
            $$type: t.type
          });
        } else {
          res = new oedTaskPresentResource({
            taskId: task.id,
            resId: t.id,
            sort: nextSort++,
            $$type: 'present'
          });
        }

        return res;
      });

      origReses = origReses.concat(resToCreate);
      origReses.sort($scope.sortResource);
      $scope.taskResources[task.id].all = origReses;

      var saving = _.map(resToCreate, function(r) {
        return r.$save();
      });

      $scope.loading = $q.all(saving);
    };

    $scope.onDropItemToPresentResource = function(evt, item) {
      var idx = _.parseInt($(evt.target).attr('data-task-index'));
      var task = $scope.tasks[idx];
      var reses = $scope.taskResources[task.id].presentResources;
      var newReses = _.filter(reses, function(r) {
        return r.constructor != oedTaskPresentResource;
      });

      var origReses = _.filter(reses, function(r) {
        return r.constructor == oedTaskPresentResource;
      });

      var maxSort = _.maxBy(origReses, function(r) {
        return r.sort || 0;
      });
      var nextSort = maxSort < 0 ? 0 : maxSort.sort + 1;

      var resToCreate = _.map(newReses).map(function(r) {
        var res = new oedTaskPresentResource({
          taskId: task.id,
          resId: r.id,
          sort: nextSort++
        });
        origReses.push(res);
        return res.$save();
      });

      $scope.taskResources[task.id].presentResources = origReses;
      $scope.loading = $q.all(resToCreate);
    };

    $scope.presentResDropOptions = {
      accept: function(el) {
        if (!el.hasClass('res-item')) {
          return false;
        }
        var idx = el.attr('data-cur-index');
        var item = $scope.curResources[idx];
        if (item.constructor != oedPresentResource) {
          return false;
        }

        var taskIdx = $(this).attr('data-task-index');
        var curTask = $scope.tasks[taskIdx];

        return _.findIndex($scope.taskResources[curTask.id].presentResources, function(p) {
          return p.resId == item.id;
        }) < 0;
      },
      activeClass: 'drop-active'
    };

    $scope.onDropItemToTest = function(evt, item) {
      var idx = _.parseInt($(evt.target).attr('data-task-index'));
      var task = $scope.tasks[idx];
      var tests = $scope.taskResources[task.id].allTests;
      var newTests = _.filter(tests, function(t) {
        return t.constructor != oedTaskTest;
      });

      var origTests = _.filter(tests, function(t) {
        return t.constructor == oedTaskTest;
      });

      var maxSort = _.maxBy(origTests, function(r) {
        return r.sort || 0;
      });

      var nextSort = maxSort < 0 ? 0 : maxSort.sort + 1;

      var testsToCreate = _.map(newTests).map(function(t) {
        var test = new oedTaskTest({
          taskId: task.id,
          testId: t.id,
          sort: nextSort++
        });

        origTests.push(test);
        return test.$save();
      });

      $scope.taskResources[task.id].allTests = origTests;
      $scope.loading = $q.all(tests);
    };

    $scope.testDropOptions = {
      accept: function(el) {
        if (!el.hasClass('res-item')) {
          return false;
        }

        var idx = el.attr('data-cur-index');
        var item = $scope.curResources[idx];
        if (item.constructor != oedObjectiveTest && item.constructor != oedSubjectiveTest) {
          return false;
        }

        var taskIdx = $(this).attr('data-task-index');
        var curTask = $scope.tasks[taskIdx];

        return _.findIndex($scope.taskResources[curTask.id].allTests, function(p) {
          return p.testId == item.id;
        }) < 0;
      },
      activeClass: 'drop-active'
    };

    $scope.allResDropOptions = {
      accept: function(el) {
        if (!el.hasClass('res-item')) {
          return false;
        }

        var idx = el.attr('data-cur-index');
        var item = $scope.curResources[idx];
        if (item.constructor != oedObjectiveTest &&
          item.constructor != oedSubjectiveTest &&
          item.constructor != oedPresentResource) {
          return false;
        }

        var taskIdx = $(this).attr('data-task-index');
        var curTask = $scope.tasks[taskIdx];

        return _.findIndex($scope.taskResources[curTask.id].all, function(p) {
          if (item.type)
            return p.testId == item.id;
          else
            return p.resId == item.id;
        }) < 0;
      },
      activeClass: 'drop-active'
    };

    var reloadData = function() {
      $scope.presentResources = oedPresentResource.queryByCourse({
        course_id: courseId
      });

      $scope.objectiveTests = oedObjectiveTest.queryByCourse({
        course_id: courseId
      });

      $scope.subjectiveTests = oedSubjectiveTest.queryByCourse({
        course_id: courseId
      });

      $scope.taskList = oedTaskList.get({
        id: taskListId
      });

      $scope.tasks = oedTask.queryByTaskListId({
        id: taskListId
      });

      $scope.loading = $q.all([$scope.publishRecords.$promise,
        $scope.taskList.$promise,
        $scope.tasks.$promise,
        $scope.presentResources.$promise,
        $scope.objectiveTests.$promise,
        $scope.subjectiveTests.$promise
      ]);

      $scope.loading.then(function() {
        $scope.allResources = $scope.objectiveTests.concat($scope.subjectiveTests).concat($scope.presentResources);
        $scope.setFilter($scope.currentFilterIdx);
        $scope.subTitle.name = $scope.taskList.name;
        $scope.presentResIdMap = _.reduce($scope.presentResources, function(t, p) {
          t[p.id] = p;
          return t;
        }, {});

        function convertTestToIdMap(tests, v) {
          var testIdMap = _.reduce(tests, function(total, t) {
            total[t.id] = t;
            return total;
          }, v);
          return testIdMap;
        }
        var testIdMap = {};

        testIdMap = convertTestToIdMap($scope.objectiveTests, testIdMap);
        testIdMap = convertTestToIdMap($scope.subjectiveTests, testIdMap);
        $scope.testIdMap = testIdMap;

        //
        // 子任务列表为空，创建一个,需要有name否则API报错
        // 设置默认 name和description
        if (_.isEmpty($scope.tasks)) {
          var task = new oedTask({
            taskListId: taskListId,
            name: '子任务1',
            description: '',
            sort: 0
          });
          $scope.tasks.push(task);
          return task.$save().then(function() {
            return reloadData();
          });
        }

        return loadTaskResources($scope.tasks);
      });
    };

    $scope.addTask = function() {
      var maxSort = _.maxBy($scope.tasks, function(t) {
        return t.sort;
      });

      maxSort = maxSort < 0 ? 0 : maxSort.sort + 1;

      $scope.task = new oedTask({
        taskListId: taskListId,
        sort: maxSort,
        name: '子任务' + ($scope.tasks.length + 1),
        description: ''
      });

      $scope.task.$save().then(function(data) {
        reloadData();
      });
    };

    $scope.deleteTask = function(task) {
      var dlg = dialogs.confirm('确定删除?', '确定要删除子任务: "' + task.name + '"吗');
      dlg.result.then(function(btn) {
        task.$delete().then(function() {
          reloadData();
        });
      });
    };

    $scope.editTask = function(task) {
      var newTask = new oedTask({
        isEdit: true,
        id: task.id,
        taskListId: taskListId,
        sort: task.sort,
        name: task.name,
        description: task.description
      });
      newTask.$save();
    };

    $scope.editTaskList = function() {
      $scope.taskList.$save();
      $scope.subTitle.name = $scope.taskList.name;
    };

    //编辑任务名称和表述
    $scope.editTaskListTitle = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/addTaskList.html'),
        controller: 'addTaskListCtrl',
        size: 'sm',
        windowClass: 'oedmodal',
        resolve: {
          data: function() {
            return {
              courseId: _.parseInt($stateParams.courseId),
              taskList: $scope.taskList
            };
          }
        }
      });

    };

    function reloadPublishRecords() {
      //获取发布历史记录
      $scope.publishRecords = oedTaskListPublishRecord.queryByTaskListId({
        id: taskListId
      });

      //通过发布历史记录对应的班级Id,去获取对应的班级信息,进行mapping
      return $scope.publishRecords.$promise.then(function(records) {
        //获取每个班级的任务完成情况
        var publishInfo = {};
        var completions = _.map(records, function(r) {
          var info = oedTaskListPublishRecord.queryPublishInfo({
            id: r.id
          });

          info.$promise.then(function(data) {
            var percentage = 0;
            if (!data.totalStudentCount) {
              percentage = 0;
            } else {
              percentage = Math.round(data.totalSubmittedStdCount / data.totalStudentCount * 100);
            }

            info.completePercentage = percentage + '%';
          });

          publishInfo[r.classId] = info;
          return info.$promise;
        });

        $q.all(completions).then(function() {
          //最终历史记录绑定的数据源
          $scope.publishRecordDetails = {};

          function visitPublishRecords(r) {
            //如果record的publishedId相同,说明属于同一个publishRecordDetails,否则新建一个,并且进行初始化
            var publishedId = r.publishId;
            if (!$scope.publishRecordDetails[publishedId]) {
              $scope.publishRecordDetails[publishedId] = {
                publishRecord: r,
                publishInfo: []
              };
            }
            //保存classInfo
            $scope.publishRecordDetails[publishedId].publishInfo.push(publishInfo[r.classId]);
          }

          _.each($scope.publishRecords, visitPublishRecords);
          $scope.isShow = $scope.pubRecordExists();
        });
      });
    }

    reloadPublishRecords();
    reloadData();
    $scope.setFilter(0);

    $scope.doPublish = function() {
      var existingClsIds = _.map($scope.publishRecords, function(record) {
        return record.classId;
      });

      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/publishTaskList.html'),
        controller: 'publishTaskListCtrl',
        size: 'md',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          data: function() {
            return {
              taskListId: taskListId,
              existingClassIds: existingClsIds
            };
          }
        }
      });
      modalInstance.result.then(function(r) {
        $scope.loading = reloadPublishRecords();
      });
    };

    $scope.cancelRecord = function(info) {
      var dlg = dialogs.confirm('确定取消发布', '确定取消 "' + info.cls.className + '"的作业?');
      dlg.result.then(function(btn) {
        var publishedRecord = _.find($scope.publishRecords, function(r) {
          return r.classId == info.cls.id;
        });

        publishedRecord.$delete().then(function() {
          $scope.loading = reloadPublishRecords();
        });
      });
    };

    $scope.cancelAllRecord = function(info) {
      var dlg = dialogs.confirm('确定取消发布', '确定取消所有已发布班级的作业?');
      dlg.result.then(function(btn) {
        var promises = _.map($scope.publishRecords, function(record) {
          return record.$delete();
        });
        $q.all(promises).then(function() {
          $scope.loading = reloadPublishRecords();
        });
      });
    };

    $scope.goToReview = function() {
      $state.go('homework.taskListPubDetails', {
        taskListId: taskListId
      }, {
        replace: true
      });
    };

    $scope.pubRecordExists = function() {
      return !_.isEmpty($scope.publishRecordDetails);
    };

    $scope.removeTaskRes = function(task, res, $index) {
      var dlg = dialogs.confirm('确定移除?', '确定要把此素材从子任务中移除?');
      if (res.testId) {
        dlg.result.then(function() {
          $scope.loading = oedTaskTest.delete({
            id: res.id
          }).$promise.then(function() {
            $scope.taskResources[task.id].all.splice($index, 1);
          });
        });
      } else {
        dlg.result.then(function() {
          $scope.loading = oedTaskPresentResource.delete({
            id: res.id
          }).$promise.then(function() {
            $scope.taskResources[task.id].all.splice($index, 1);
          });
        });
      }
    };

    /**$scope.removeTaskRes = function (task, res, $index) {
        var dlg = dialogs.confirm('确定移除?','确定要把此素材从子任务中移除? "');
        dlg.result.then(function () {
            $scope.loading = oedTaskPresentResource.delete({
                id: res.id
            }).$promise.then(function () {
                $scope.taskResources[task.id].presentResources.splice($index, 1);
            });
        });
    };**/

    $scope.removeTaskTest = function(task, test, $index) {
      var dlg = dialogs.confirm('确定移除?', '确定要把此素材从子任务中移除? "');
      dlg.result.then(function() {
        $scope.loading = oedTaskTest.delete({
          id: test.id
        }).$promise.then(function() {
          $scope.taskResources[task.id].allTests.splice($index, 1);
        });
      });
    };

    $scope.deleteTaskList = function() {
      var dlg = dialogs.confirm('确定删除任务', '确定取消所有已发布班级的任务,并且从任务列表中移除该任务?');
      dlg.result.then(function(btn) {
        var promises = _.map($scope.publishRecords, function(record) {
          return record.$delete();
        });
        $q.all(promises).then(function() {
          oedTaskList.delete({
            id: taskListId
          }).$promise.then(function() {
            $state.go('prepareCourse.taskList', {}, {
              location: 'replace'
            });
          });
        });
      });
    };

    var goToResourceHandler = {
      oedObjectiveTest: function(res) {
        $state.go('prepareCourse.objectiveTest', {
          testId: res.id
        });
      },
      oedSubjectiveTest: function(res) {
        $state.go('prepareCourse.subjectiveTest', {
          testId: res.id
        });
      },
      oedPresentResource: function(res) {
        $state.go('prepareCourse.presentResource', {
          resId: res.id
        });
      }
    };

    $scope.gotoResource = function(res) {
      var type = getType(res);
      if (!_.has(goToResourceHandler, type)) {
        console.error('unknow type!');
        return '';
      }

      return goToResourceHandler[type](res);
    };
  }
})();
