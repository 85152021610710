import { Component, OnInit, Input, Output, EventEmitter, SimpleChange } from '@angular/core';
import { QuestionDetailDTO, QuestionDTO } from '../models/exam';
import _ = require('lodash');

@Component({
  selector: 'app-part-ques-info',
  template: require('./part-ques-info.component.html'),
  styles: [require('./part-ques-info.component.scss')]
})
export class PartQuesInfoComponent implements OnInit {

  @Input() partQuestion: QuestionDetailDTO
  @Input() sort: number
  @Input() sortMap: object
  @Output() quesModelChaned = new EventEmitter<any>()

  question: QuestionDTO
  childQuestions: QuestionDTO[] = []
  constructor() { }

  ngOnInit() {
    this.getQues()
  }

  ngChanges(changes: SimpleChange) {
    if (_.has(changes, 'partQuestion')) {
      this.partQuestion = _.get(changes, 'partQuestion', {})
    }
    this.getQues()
  }

  getQues() {
    this.question = _.get(this.partQuestion, 'question', {})
    if (this.isSynthetic()) {
      this.childQuestions = _.get(this.question, 'childQuestions', [])
    }
  }

  isSynthetic() {
    return _.get(this.question, 'type', '') === 'synthetic'
  }

  getChildSort(i) {
    return `${this.sort}.${i + 1}`
  }

  handleScoreChanged() {
    if (this.isSynthetic()) {
      this.question.point2 = _.reduce(this.question.childQuestions, (r, d) => {
        return r + d.point2
      }, 0)
      this.question.point = Math.floor(this.question.point2)
    }
    this.quesModelChaned.emit()
  }

}
