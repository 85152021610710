import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash'
import { resPrefixes } from 'app2/utils/testUtils2';

@Pipe({
  name: 'toUniqResId'
})
export class ToUniqResIdPipe implements PipeTransform {

  transform(value: any, kind: string): any {
    if (!value) {
      return '（尚未上传到资源库）'
    }

    return _.get(resPrefixes, kind, '') + value;
  }
}
