import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error-dialog',
  template: require('./error-dialog.component.html'),
  styles: [require('./error-dialog.component.scss')]
})
export class ErrorDialogComponent implements OnInit {
  @Output() public action = new EventEmitter<any>();
  private title: string
  private msg: string
  private btnText: string

  constructor(public bsModalRef: BsModalRef,) { }

  ngOnInit() {
    this.btnText = this.btnText || '确认'
  }
  
  public close() {
    this.action.emit()
    this.bsModalRef.hide()
  }
  
  public cancel() {
    this.bsModalRef.hide()
  }

}
