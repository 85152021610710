import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { PreferenceService } from 'app/lesson/services/preference.service';
import { DragulaModule } from 'ng2-dragula';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { ElemScroolDirective } from './directives/elem-scrool.directive';
import { ElemWidthDirective } from './directives/elem-width.directive';
import { OedObjectiveTestDesignDirective } from './directives/oed-objective-test-design.component'
import { OedPrepareLessonDirective } from './directives/oed-prepare-lesson.component';
import { OEdexamSummary } from './directives/edit-object-test-paper.component';
import { CreateModuleComponent } from './lesson-design/create-module/create-module.component';
import { DesignNavComponent } from './lesson-design/design-nav/design-nav.component';
import { DesignSeparateComponent } from './lesson-design/design-separate/design-separate.component';
import { DesignTipComponent } from './lesson-design/design-tip/design-tip.component';
import { EditCourseComponent } from './lesson-design/edit-course/edit-course.component';
import { EditTeachingModuleComponent } from './lesson-design/edit-teaching-module/edit-teaching-module.component';
import { ItemResourceComponent } from './lesson-design/item-resource/item-resource.component';
import { LessonDesignComponent } from './lesson-design/lesson-design.component';
import { LittleTipComponent } from './lesson-design/little-tip/little-tip.component';
import { ModuleDropDownComponent } from './lesson-design/module-drop-down/module-drop-down.component';
import { SectionExamsComponent } from './lesson-design/section-exams/section-exams.component';
import { TeachingModuleComponent } from './lesson-design/teaching-module/teaching-module.component';
import { ModuleItemComponent } from './lesson-design/module-item/module-item.component';
import { ModuleTableComponent } from './lesson-design/module-table/module-table.component';
import { ModuleHeaderComponent } from './lesson-design/module-header/module-header.component';
import { NavNameComponent } from './lesson-design/nav-name/nav-name.component';
import { PatternTableComponent } from './lesson-design/pattern-table/pattern-table.component';
import { ResMoveComponent } from './lesson-design/res-move/res-move.component';
import { SectionInfoComponent } from './lesson-design/section-info/section-info.component';
import { TeachingPatternComponent } from './lesson-design/teaching-pattern/teaching-pattern.component'
import { lessonStates } from './lesson.state'
import { ModuleTohwComponent } from './module-tohw/module-tohw.component';
import { SectionCardComponent } from './section-card/section-card.component';
import { SectionListComponent } from './section-list/section-list.component'
import { LessonService } from './services/lesson.service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { OEdExamPartsEditor } from './directives/examPartsEditor.component'
import { OEdExamNav } from './directives/OEdExamNav.component'
import { IntelligentCreatePaperComponent } from './lesson-design/intelligent-create-paper/intelligent-create-paper.component';
import { IntelligentPaperPanelComponent } from './lesson-design/intelligent-create-paper/intelligent-paper-panel/intelligent-paper-panel.component';
import { OedCounterComponent } from './directives/oed-counter/oed-counter.component'
import { CloudResBoxComponent } from './directives/cloud-res-box/cloud-res-box.component';
import { SectionExamsPaperComponent } from './lesson-design/section-exams/section-exams-paper/section-exams-paper.component';
import { MenuModule } from '../menu/menu.module';

@NgModule({
  declarations: [
    SectionListComponent, SectionCardComponent, LessonDesignComponent,
    SectionInfoComponent, DesignNavComponent, DesignSeparateComponent,
    TeachingPatternComponent, TeachingModuleComponent, ModuleItemComponent,
    ModuleHeaderComponent, EditTeachingModuleComponent, ModuleDropDownComponent,
    OedPrepareLessonDirective,
    OEdexamSummary,
    OedObjectiveTestDesignDirective,
    OEdExamPartsEditor,
    OEdExamNav,
    ItemResourceComponent,
    CreateModuleComponent,
    ModuleTohwComponent,
    EditCourseComponent,
    LittleTipComponent,
    ElemScroolDirective,
    ElemWidthDirective,
    NavNameComponent,
    ResMoveComponent,
    PatternTableComponent,
    DesignTipComponent,
    ModuleTableComponent,
    IntelligentCreatePaperComponent,
    SectionExamsComponent,
    IntelligentPaperPanelComponent,
    OedCounterComponent,
    CloudResBoxComponent,
    SectionExamsPaperComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    CoreModule,
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    AlertModule.forRoot(),
    TooltipModule,
    BsDropdownModule,
    DragulaModule,
    UIRouterUpgradeModule.forChild({ states: lessonStates }),
    MenuModule,
  ],
  exports: [LessonDesignComponent, OedPrepareLessonDirective, OEdexamSummary, OedObjectiveTestDesignDirective, SectionExamsComponent, IntelligentPaperPanelComponent, OEdExamPartsEditor, OEdExamNav],
  entryComponents: [
    SectionListComponent, EditTeachingModuleComponent,
    CreateModuleComponent, EditCourseComponent, LittleTipComponent,
    ResMoveComponent,
    IntelligentCreatePaperComponent
  ],
  providers: [LessonService, PreferenceService]
})
export class LessonModule { }
