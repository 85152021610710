(function() {
  angular.module('app.prepareCourse')
    .controller('editSubjectiveTestCtrl', editSubjectiveTestCtrl);

  editSubjectiveTestCtrl.$inject = ['$scope', '$q', '$state', '$uibModalInstance',
    'oedObjectiveTest', 'test', 'existingTests','oedSubjectiveTest','isPrepareLesson']
  function editSubjectiveTestCtrl($scope, $q, $state, $uibModalInstance,
    oedObjectiveTest, test, existingTests,oedSubjectiveTest,isPrepareLesson) {
    $scope.exam = {
      name: test.name
    };

    $scope.existingNames = _(existingTests).filter(function(t) {
      return t.id != test.id;
    }).map('name').value();

    $scope.ok = function() {
      if(isPrepareLesson){
        test.name =  $scope.exam.name;
        $scope.loading = oedSubjectiveTest.save(test).$promise;
        $scope.loading.finally(function() {
          $uibModalInstance.close({});
        });
      }else{
        $scope.showValidationError = true;
        if ($scope.editTestForm.$invalid) {
          return;
        }
        _.assign(test, $scope.exam);
        $scope.loading = $q(function(resolve, reject) {
          test.$save().finally(function() {
            resolve();
            $uibModalInstance.close({});
          });
        });
      }
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
