(function() {
  'use strict';

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.reviewStudentTaskDetails.res.objectiveTest', {
        url: '/objectiveTest/:origTestId',
        template: require('assets/templates/homework/reviewStudentTaskDetailsObjectiveTest.html'),
        controller: 'reviewStudentTaskDetailsObjectiveTestCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('reviewStudentTaskDetailsObjectiveTestCtrl', reviewStudentTaskDetailsObjectiveTestCtrl);

  reviewStudentTaskDetailsObjectiveTestCtrl.$inject = ['$scope', '$state', '$stateParams',
    '$q', '$uibModal', 'dialogs', 'userInfo', 'oedCoursePath', 'oedTaskList', 'oedTask',
    'oedTaskListPublishRecord', 'oedTaskTest', 'oedObjectiveTest', 'oedTestSession', 'oedTestSessionStudent',
    'oedTestAnswer', 'oedTestSessionStatForReview', 'oedObjectiveTestSessionStats', 'oedUserInfo', 'oedTestUtils']
  function reviewStudentTaskDetailsObjectiveTestCtrl($scope, $state, $stateParams,
    $q, $uibModal, dialogs, userInfo, oedCoursePath, oedTaskList, oedTask,
    oedTaskListPublishRecord, oedTaskTest, oedObjectiveTest, oedTestSession, oedTestSessionStudent,
    oedTestAnswer, oedTestSessionStatForReview, oedObjectiveTestSessionStats, oedUserInfo, oedTestUtils) {
    var recordId = _.parseInt($stateParams.recordId);
    var origTestId = _.parseInt($stateParams.origTestId);
    var studentId = _.parseInt($stateParams.studentId);
    $scope.studentId = studentId;
    $scope.origTestId = origTestId;
    $scope.recordId = recordId;

    function compare(int1, int2) {
      return int1 - int2;
    }

    $scope.loading = userInfo.then(function(info) {
      var uid = studentId;
      return $scope.recordPromise.then(function() {
        $scope.testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
          recordId: recordId,
          taskId: $scope.taskId,
          origTestId: origTestId
        });
        return $scope.testSession.$promise;
      }).then(function(ts) {
        var loadTsStu = oedTestSessionStudent.queryByTestSessionAndStudentId({
          sessionId: $scope.testSession.id,
          studentId: uid
        }).$promise.catch(function() {
          return {
            submitTime: 0
          };
        });

        var loadStats = loadTestSessionStats(ts);
        return $q.all([loadTsStu, loadStats]);
      }).then(function(ret) {
        var tsStd = ret[0];
        $scope.submited = tsStd.submitTime > 0;
        if ($scope.submited) {
          $scope.dataForReview = oedTestSessionStatForReview.queryByTestSessionAndStudentId({
            sessionId: $scope.testSession.id,
            userId: uid
          });
          return $scope.dataForReview.$promise.then(function(r) {
            $scope.test = r.test;
            $scope.currentState.subTitle = $scope.test.name + '(' + $scope.test.questions.length + ')';
          });
        } else {
          $scope.test = oedObjectiveTest.getTestWithoutAnswer({
            id: $scope.testSession.testId
          });
          $scope.test.$promise.then(function(test) {
            $scope.currentState.subTitle = $scope.test.name + '(' + $scope.test.questions.length + ')';
          });
          return $scope.test.$promise;
        }
      }).finally(function() {
        $scope.loaded = true;
      });
    });

    $scope.getChoiceName = oedTestUtils.getChoiceName;
    $scope.getAnswerText = oedTestUtils.getAnswerText;

    $scope.getCorrectRate = function(question) {
      var correct = question.quesStat.countAnsweredCorrectAndHesitate;
      var hesitate = question.quesStat.countAnsweredCorrectNotHesitate;
      var total = question.quesStat.countAnsweredTotal;
      if (total !== 0) {
        return (correct + hesitate) * 100 / total;
      }
      return 0;
    };
    $scope.currentLabels = [];
    $scope.getLabel = function(index) {
      if (_.size($scope.currentLabels) > index)
        return $scope.currentLabels[index];
      return '';
    };
    function loadTestSessionStats(ts) {
      $scope.stats = oedObjectiveTestSessionStats.queryBySessoinId({
        sessionId: ts.id
      });

      var loadStats = $scope.stats.$promise.then(function(stats) {
        if (_.isEmpty(stats)) {
          return;
        }
        $scope.overall = {
          total: stats.length,
          numStudents: stats[0].countAnsweredTotal
        };

        $scope.barChartOptions.chart.height = 30 + _.size(stats) * 60;
        var labels = oedTestUtils.getTestStatLabel(stats);
        $scope.currentLabels = labels;
        $scope.data[0].values = _.map(stats, function(stat, idx) {
          return {
            label: '第' + labels[idx] + '题',
            value: stat.countAnsweredCorrectNotHesitate
          };
        });

        $scope.data[1].values = _.map(stats, function(stat, idx) {
          return {
            label: '第' + labels[idx] + '题',
            value: stat.countAnsweredCorrectAndHesitate
          };
        });

        $scope.data[2].values = _.map(stats, function(stat, idx) {
          return {
            label: '第' + labels[idx] + '题',
            value: stat.countAnsweredWrong
          };
        });
      });
      return loadStats;
    }

    $scope.data = [{
      'key': '正确',
      values: [],
      color: '#469e24' //color - optional: choose your own line color.
    }, {
      key: '犹豫',
      color: '#c78615',
      values: []
    }, {
      key: '错误',
      color: '#a70d0d',
      values: []
    }];

    $scope.barChartOptions = {
      'chart': {
        type: 'multiBarHorizontalChart',
        height: 100,
        showControls: false,
        showValues: true,
        stacked: true,
        noData: '没有数据',
        groupSpacing: 0.4,
        valueFormat: d3.format('d'),
        'transitionDuration': 500,
        'xAxis': {
          'showMaxMin': false
        },
        x: function(d) {
          return d.label;
        },
        y: function(d) {
          return d.value;
        },
        yAxis: {
          tickFormat: function(d) {
            return d3.format('d')(d);
          }
        },
        multibar: {
          dispatch: {
            elementClick: function(e) {
              var idx = e.index; // 点击的题目的index
              var modalInstance = $uibModal.open({
                template: require('assets/templates/review/questionAnswerDetails.html'),
                controller: 'questionAnswerDetailsCtrl',
                size: 'lg',
                windowClass: 'question-details-modal modalCenter',
                resolve: {
                  data: function() {
                    return {
                      sessionId: $scope.testSession.id,
                      questionId: $scope.stats[idx].questionId,
                      index: $scope.currentLabels[idx]
                    };
                  }
                }
              });
            }
          }
        }
      }
    };

    $scope.getStdAvatarImgSrc = function(std) {
      if ('female' == std.gender) {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_f1.png';
        } else {
          return 'Assets/images2/stu_avatar_f2.png';
        }
      } else {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_m1.png';
        } else {
          return 'Assets/images2/stu_avatar_m2.png';
        }
      }
    };
  }
})();
