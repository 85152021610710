import * as angular from 'angular';
require('assets/styles/review.scss');

angular.module('app.directives.checkStudentAnswer', [])
  .component('checkStudentAnswer', {
  bindings: {
    curIndex: '<',
    allResources: '<',
    downloadCurrBoard: '&',
    isObjTest:'<',
  },
  controller: checkStudentAnswerCtrl,
  template: require('./checkStudentAnswer.html'),
});
checkStudentAnswerCtrl.$inject = ['Lightbox', '$window', '$scope', '$document','$element'];

function checkStudentAnswerCtrl(Lightbox, $window, $scope, $document,$element) {
  // 判断顶层窗口是否为iframe(配合桌面端使用)
  this.topWindowIsIframe = $window.top === $window.self;
  this.linkOpenType = this.topWindowIsIframe ? '_blank' : '_self';

  this.resClick = (allresource,res) => {
    this.curIndex = _.indexOf(allresource, res);
  };

  this.openMedia = (all, index) => {
    Lightbox.openModal(all, index);
  };

  // 图片旋转
  this.rotateNum = 0;
  this.rotate = (num) => {
    if( num > 0){
      this.rotateNum = this.rotateNum + 90;
    } else {
      this.rotateNum = this.rotateNum - 90;
    }
    $('.student_pre_anwer_image_viewer', $element).css({
      transform: 'rotate(' + this.rotateNum + 'deg) scale(1, 1)'
    });
  };
  // 图片缩小放大
  this.ratio = 1;
  this.firstInit = false;
  this.getInitImageData = () => {
    const dom = $('.student_pre_anwer_image_viewer', $element)[0];
    if(dom) {
      this.imageWidth = dom.offsetWidth;
      this.imageHeight = dom.offsetHeight;
      this.naturalWidth  = dom.naturalWidth;
      this.naturalHeight = dom.naturalHeight;
    } else {
      this.imageWidth = 0;
      this.imageHeight = 0;
    }
  };

  this.zoomTo = (num) => {
    this.ratio = this.ratio + num;
    if(!this.firstInit) {
      this.getInitImageData();
    }
    const newWidth = this.imageWidth * this.ratio;
    const newHeight = this.imageHeight * this.ratio;
    if((newWidth > this.naturalWidth && newHeight > this.naturalHeight) || (newWidth < 0 || newHeight < 0)){
      this.ratio = this.ratio - num;
      return;
    }
    $('.student_pre_anwer_image_viewer', $element).css({
      width: newWidth + "px",
      height: newHeight + "px"
    });
    this.firstInit = true;
  };
  // 图片拖拽
  this.x = 0;
  this.y = 0;
  const that = this;
  this.firstMove = true;
  this.imageStartLeft = 0;
  let drag;

  // 鼠标按下事件
  this.imageMouseDown= ($event) => {
    $event.preventDefault();
    if(this.firstMove) {
      drag = $('.student_pre_anwer_image_viewer', $element)[0]
      const parentNode = $('.interact-board-height', $element)[0];
      const parentWidth = parentNode.offsetWidth;
      if(drag.offsetWidth < parentWidth) {
        this.imageStartLeft = drag.offsetLeft;
      }
    }
    this.startX = $event.clientX ;
    this.diffY = $event.clientY - drag.offsetTop;
    $document.on('mousemove', mousemove);
    $document.on('mouseup', mouseup);
  };

  // 拖拽事件
  function mousemove($event) {
    $event.preventDefault();
    that.x = $event.clientX - that.startX + that.imageStartLeft;
    that.y = $event.clientY - that.diffY;
    $('.student_pre_anwer_image_viewer', $element)
      .css({
      "margin-top": that.y + 'px',
      "margin-left": that.x + 'px',
      "margin-right": 'auto',
      "margin-bottom": 'auto',
      transition: 'margin 0s'
    });
  }

  // 鼠标放开事件
  function mouseup() {
    that.imageStartLeft = that.x;
    that.firstMove = false;
    $document.off('mousemove', mousemove);
    $document.off('mouseup', mouseup);
    $('.student_pre_anwer_image_viewer', $element)
      .css({ transition: 'all .6s' });
  }


  // 监听切换
  $scope.$watch('$ctrl.curIndex', (newValue,oldValue) => {
    if(newValue !== undefined && oldValue !== undefined && newValue !== oldValue) {
      // 切换图片旋转初始化
      this.rotateNum = 0;
      // 切换图片放大缩小初始化
      this.ratio = 1;
      // 获取图片高宽信息初始化
      this.firstInit = false;
      // 切换图片拖拽初始化
      this.x = 0;
      this.y = 0;
      this.firstMove = true;
      this.imageStartLeft = 0;
      $('.student_pre_anwer_image_viewer', $element).removeAttr("style");
    }
  });

}

