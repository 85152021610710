import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, ViewChild, ElementRef} from '@angular/core';
import { NodeStyleService } from './node-style.service'
import * as _ from 'lodash';
import { OedDialogService } from 'app/core/oed-dialog.service'
import { OedExerciseService } from '../oed-exercise.service'
import { LoadingService } from 'app/shared/loading/loading.service';

@Component({
  selector: 'app-edit-json',
  template: require('./edit-json.component.html'),
  styles: [require('./edit-json.component.scss')]
})
export class EditJsonComponent implements OnInit {
  @Input() jsonObject: any;
  @Input() subjectId: any;
  @Input() levelId: number;
  @Input() exerciseBookId: any;
  @Input() illegalNodeIdList: any;
  @Output() private action = new EventEmitter<boolean>();
  @ViewChild('top2') top: ElementRef;
  totalItems: number;
  // 每页节点数
  countPerPage: any = 50;
  // 最大页数
  maxSize: any = 10;
  // 当前页码
  currentPage: any = 1;
  // 当前展示json文本
  currentJsonObject: any;
  isHiddenIgnore = false;
  isHiddenFinish = false;
  illegalIdList: any;
  private preQuesOptions: any;
  private preNodeId: number;


  constructor( private oedDialogService: OedDialogService,
              private nodeStyleService: NodeStyleService,
              private oedExerciseService: OedExerciseService,
              private loadingService: LoadingService,) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'jsonObject')) {
      const firstNode = _.get(this.jsonObject, '0.origNode.jsonStr');
      if(typeof firstNode === 'string') {
        _.map(this.jsonObject, item => {
          item.origNode.jsonStr = JSON.parse(item.origNode.jsonStr);
          return item;
        })
      }
      this.totalItems = _.size(this.jsonObject);
      this.getCurrentJsonData();
    }
  }
  

  public getTypeClass(node) {
    const nodeBtType = this.nodeStyleService.getBtType(node);
    return nodeBtType == '' ? '' : 'BT ' + nodeBtType; 
  }

  public editText(item) {
    console.log('item: ', item)
    const nodeType = _.get(item, 'origNode.jsonStr.nodeType');
    if (nodeType === 'chapter') {
      this.showMarkDialog(item)
    } else if (nodeType === 'question' || nodeType === 'synQuestion') {
      this.showQuesEditDialog(item)
    } else {
      console.error('unkonw item', item)
    }
    
  }

  private showMarkDialog = (item: any) => {
    this.oedDialogService.openModalWithComponent2(
      'ExMarkDialogComponent', 
      { nodeData: item, },
      { class: 'ex-dialog-common' }
    ).subscribe((res: any) => {
      const node = _.get(res, 'node', {})
      const saved = _.get(res, 'saved', false)
      const ignored = _.get(res, 'ignored', false)
      const testPaper = _.get(res, 'testPaper', false)
      this.preNodeId = _.get(res, 'node.id', {})
      if (saved) {
        item['origNode']['status'] = 'saved'
        item['node'] = node
      }
      if(ignored) {
        item['origNode']['status'] = 'ignored'
      }
      if(testPaper) {
        this.action.emit(testPaper);
      }
    })
  }

  private showQuesEditDialog = (item: any) => {
    this.oedDialogService.openModalWithComponent2(
      'ExMarkEditDialogComponent',
      {
        nodeData: item,
        subjectId: this.subjectId,
        levelId: this.levelId,
        preQuesOptions: this.preQuesOptions,
        preNodeId: this.preNodeId
      },
      { class: 'ex-dialog-common' }
    ).subscribe((res: any) => {
      const node = _.get(res, 'node', {})
      const saved = _.get(res, 'saved', false)
      const ignored = _.get(res, 'ignored', false)
      this.preQuesOptions = _.get(res, 'quesOptions', {})
      this.preNodeId = _.get(res, 'nodeId', 0)
      if (saved) {
        item['origNode']['status'] = 'saved';
        item['node'] = node;
        this.changeErrorStatus(item, 'saved');
      }
      if(ignored) {
        item['origNode']['status'] = 'ignored';
        this.changeErrorStatus(item, 'ignored');
      }
    })
  }

  public nodeContentType(item) {
    return item['origNode']['jsonStr']['nodeType'];
  }

  public nodeContentName(item) {
    return item['origNode']['jsonStr']['nodeName'];
  }

  public nodeContentBody(item) {
    return item['origNode']['jsonStr']['content']['body'];
  }

  public nodeContentResource(item) {
    return item['origNode']['jsonStr']['content']['source'];
  }

  public nodeContentSerialNumber(item) {
    return item['origNode']['jsonStr']["content"]["serial_number"];
  }

  public nodeState(item) {
    const status = item['origNode']['status'];
    const nodeType = item['origNode']['jsonStr']['nodeType'];
    const isHiddenIgnore = this.isHiddenIgnore && (status === 'ignored' || nodeType === 'paragraph');
    const isHiddenFinish = this.isHiddenFinish && status === 'saved';
    if(isHiddenIgnore || isHiddenFinish ) {
        return 'json-hidden'
    } else {
      return status;
    }
  }

  public errorState(item) {
    const isError = _.some(this.illegalNodeIdList, id => Number(id) === _.get(item, 'node.id'));
    return isError ? 'errorNode': '';
  }

  public changeErrorStatus (item, type) {
    if(!_.isEmpty(this.illegalNodeIdList)) {
      const path = type === 'ignored' ? 'node.id' : 'node.parentId';
      const index = _.findIndex(this.illegalNodeIdList, id => Number(id) === _.get(item, path));
      const length = index === -1 ? 0 : 1;
      this.illegalNodeIdList.splice(index, length);  
    }
  }

  public pageChanged(event){
    this.currentPage = event.page;
    this.getCurrentJsonData();
    this.top.nativeElement.scrollIntoView({
      behavior: 'smooth', block: 'start', inline: 'start' 
    });
  }

  public getCurrentJsonData() {
    this.currentJsonObject = _.slice(this.jsonObject, (this.currentPage - 1)*this.countPerPage, this.countPerPage*this.currentPage);
  }

  public hiddenIgnore() {
    this.isHiddenIgnore = !this.isHiddenIgnore;
  }

  public hiddenFinish() {
    this.isHiddenFinish = !this.isHiddenFinish;
  }

}
