import { Component, OnInit } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service'

@Component({
  selector: 'app-post-login-contact-menu',
  template: require('./post-login-contact-menu.component.html'),
  styles: [require('./post-login-contact-menu.component.scss')]
})
export class PostLoginContactMenuComponent implements OnInit {
  // 判断顶层窗口是否为iframe(配合桌面端使用)
  topWindowIsIframe = window.top === window.self;
  constructor(private oedDialogService: OedDialogService,) { }

  ngOnInit() {}

  openCloudDrive(){
    this.oedDialogService.openModalWithComponent('CloudDriveDialogComponent',
        {}, ()=> {},
    {class: 'cl-library-dialog2 modal-cloud-drive'})
  }
}

