interface DownloadResource {
  isExternal?: boolean;
  externalResourceUrl?: string;
  resourceName: string;
  flResource?: any;
}

export function providerDownUrl(res: DownloadResource) {

  if (res.isExternal) {
    return res.externalResourceUrl
  }

  function b64EncodeUnicode(str: string) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
      return String.fromCharCode(Number('0x' + p1));
    }))
  }

  const name = b64EncodeUnicode(encodeURIComponent(res.resourceName))
  const url = '/ray/resource/uuid/' + res.flResource.md5 + '/raw64/' + name
  return url
}
