import * as _ from 'lodash';

import Promise = require('bluebird');
const boardUtils: any = require('app2/utils/boardUtils');
const resourceUtils: any = require('app2/utils/resourceUtils');
const {confirmAndDownloadSubjectiveTest, importSubjectTestModel} = require('app2/utils/testUtils2')
require('./treeTemplate.scss')
export const treeTemplate = {
  bindings: {
    node: '<',
    collapsed: '<',
    treeType: '<',
    index: '<',
    selectResource: '&'
  },
  controller: treeTemplateCtrl,
  template: <string>require('./treeTemplate.html'),
}

angular.module('app.newPrepareCourse2.treeTemplate', [])
  .component('treeTemplate', treeTemplate);

treeTemplateCtrl.$inject = [
  '$scope',
  '$q',
  'communicateService',
  'localStorageService'
];
function treeTemplateCtrl(
  $scope,
  $q,
  communicateService,
  localStorageService
) {
  const ctrl = this;
  ctrl.getTypeUrl = (type) => {
    if (!type) {
      return;
    }
    switch (type) {
      case 'doc':
      case 'docx':
        return 'fa-file-word-o';
        break;
      case 'pdf':
        return 'fa-file-pdf-o';
        break;
      case 'JPG':
      case 'jpg':
      case 'JPEG':
      case 'jpeg':
      case 'PNG':
      case 'png':
        return 'fa-file-photo-o';
        break;
      case 'mp4':
        return 'fa-file-video-o';
        break;
      case 'mp3':
        return 'fa-file-audio-o';
        break;
      case 'txt':
        return  'fa-file-text-o';
        break;
      case 'ppt':
      case 'pptx':
        return  'fa-file-powerpoint-o';
        break;
      case 'xls':
      case 'xlsx':
        return  'fa-file-excel-o';
        break;
      case 'zip':
        return 'fa-file-archive-o'
        break;
      default:
        return 'fa-file-o';
        break;
    }
  };

  ctrl.toggle = () => {
    ctrl.collapsed = !ctrl.collapsed;
  };
  ctrl.selectItem = (item) => {
    event.stopPropagation();
    event.preventDefault();
    if (jQuery('.selected-item') && jQuery('.selected-item')[0]) {
      jQuery('.selected-item').removeClass('selected-item');
    }
    jQuery(event.currentTarget).addClass('selected-item');
    ctrl.selectResource({res: item});
  }
}
