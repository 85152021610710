import { Directive, ElementRef, Injector, Output, Input, EventEmitter } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'oed-query-result-header'
})
export class ExerciseBookQueryHeader extends UpgradeComponent {
  @Input() public selectedSortOrder: any
  @Output() public onSortOrderChanged: EventEmitter<any>;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('oedQueryResultHeader', elementRef, injector);
  }
}
