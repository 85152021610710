import * as _ from 'lodash'

export function getOverallRate(rate: number, scores: any[], total: number) {
  const passScore = total * rate
  const passNum = _.size(_.filter(scores, s => s >= passScore))
  return `${_.round((passNum / _.size(scores)) * 100, 1)}%`
}

export function getBarColorByRate(rate: number) {
  if (rate < 0.6) {
    return {'background-color': '#F37565', 'width': `${rate * 100}%`}
  }
  if (rate >= 0.6 && rate < 0.85) {
   return {'background-color': '#F6BE59', 'width': `${rate * 100}%`}
  }
  if (rate >= 0.85 && rate < 1) {
   return {'background-color': '#91C46C', 'width': `${rate * 100}%`}
  }
  if (rate == 1) {
   return {'background-color': '#91C46C', 'width': `${rate * 100}%`, 'border-radius': '6px',}
  }
}

