import { Component, OnInit, Input } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { LessonDTO } from 'app/lesson/models/lesson';
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-design-tip',
  template: require('./design-tip.component.html'),
  styles: [require('./design-tip.component.scss')]
})
export class DesignTipComponent implements OnInit {

  @Input() selectedCourse: LessonDTO
  @Input() recommendCourses: LessonDTO[]
  @Input() mineCourses: LessonDTO[]
  @Input() isCanStar: boolean

  private modulesIsNull: boolean
  private subs = new Subscription()

  constructor(
    private oedDialogService: OedDialogService,
    private lessonMessageService: LessonMessageService,
  ) {
    const getModulesIfNull = this.lessonMessageService.getTeachingModulesNull$.subscribe(r => this.modulesIsNull = r)
    this.subs.add(getModulesIfNull)
   }

  ngOnInit() {
  }

  public ngOnDestroy() {
    this.subs.unsubscribe()
  }

  public isTeachingModulesOfNull(recomendOrMineCourse: LessonDTO[]) {
    if (this.selectedCourse.id === -1) {
      return true
    }
    if (!_.isEmpty(_.find(recomendOrMineCourse, (r) => {
      return this.selectedCourse.id === r.id
    }))) {
      return this.modulesIsNull
    }
  }

  private showLittleTip() {
    this.oedDialogService.openModalWithComponent2('LittleTipComponent', {}, {class: 'modal-tip'})
  }

  public mineCourseIsEmpty() {
    return _.isEmpty(this.mineCourses)
  }
}
