/**
 * 树形结构相关的算法
 */

import _ = require('lodash')

export function findNode(tree: any, pred: (node: any) => boolean, childFun: any = 'child'): any {
  if (pred(tree)) {
    return tree
  }
  const children: any[] = _.isFunction(childFun) ? childFun(tree) : _.get(tree, childFun)
  if (_.isEmpty(children)) {
    return null
  }
  for (const c of children) {
    const p = findNode(c, pred, childFun)
    if (p) {
      return p
    }
  }
  return null
}

export function overTree(tree: any, iterFun: (node: any) => boolean, childFun: any = 'child') {
  if (!iterFun(tree)) {
    return false
  }
  const children: any[] = _.isFunction(childFun) ? childFun(tree) : _.get(tree, childFun)
  if (_.isEmpty(children)) {
    return true
  }
  for (const c of children) {
    const next = overTree(c, iterFun, childFun)
    if (!next) {
      return false
    }
  }
  return true
}
