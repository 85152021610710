const drawPlayMap = {};
const xMaxValue = 10920; // 10920: 15444;
const initData = { subStd: {} };
window.drawPlayData = initData
window.drawPlayMap = drawPlayMap

function tempId (id) {
  return `${id}_temp`
}

// 保存订阅时获取到的点数据. 同时将temp中的数据追加到其后面.
export function setStdData (id, data) {
  initData[id] = {}
  const std = initData[id]
  for (let i = 0; i < data.length; i++) {
    const p = data[i]
    if (!std[p.page]) std[p.page] = []
    std[p.page].push(p)
  }
  const temp = initData[tempId(id)] || {}
  for (const page of _.keys(temp)) {
    const stdPageTemp = temp[page]
    if (!stdPageTemp.length) continue
    if (!std[page]) std[page] = []
    // 点数据的重叠过滤.根据时间戳.
    if (std[page].length === 0 || stdPageTemp[0].st > std[page][0].st) {
      std[page] = std[page].concat(stdPageTemp)
    } else {
      const stLast = std[page][0].st
      std[page] = std[page].concat(_.filter(stdPageTemp, p => p.st > stLast))
    }
  }
  delete initData[tempId(id)]
}

// 删除学生page数据,不传page就是全删除.
export function deleteStdData (id, page) {
  if (page) {
    if (stdPageData(id, page))  {
      delete initData[id][page];
    }
    if (stdPageData(tempId(id), page)) {
      delete initData[tempId(id)][page]
    }
  } else if (id) {
    delete initData[tempId(id)]
    delete initData[id];
    delete initData.subStd[id]
  } else {
    for (const k of _.keys(initData)) {
      if (k === 'subStd') {
        for (const subK of _.keys(initData.subStd)) {
          delete initData.subStd[subK]
        }
      } else {
        delete initData[k]
      }
    }
  }
}

export function getStdData (id, page) {
  return stdPageData(id, page);
}

function stdPageData (id, page) {
  return _.get(initData, `${id}.${page}`);
}


// 获取页数列表
export function getStdPages (id) {
  return _.keys(initData[id] || {})
}

// 销毁笔迹播放器
export function destroy (holder) {
  if (_.isEmpty(holder)) return;
  if (holder.playInterval) {
    clearInterval(holder.playInterval);
  }
  if (holder.changeSizeTimeout) {
    clearTimeout(holder.changeSizeTimeout);
  }
  holder.setData([])
  delete drawPlayMap[holder.id];
}

// 初始化笔迹播放器 返回holder canvas的一些配置。
export function initDrawPlay (option) {
  const { id = 'drawPlay', ctx, } = option;
  settingCanvas(ctx);
  drawPlayMap[id] = createHolder(option);
  return drawPlayMap[id];
}

// 创建播放的holder。 存储数据及状态。
function createHolder (option) {
  const holder = {};
  holder.speed = 1, // 播放速度。
  holder.data = option.data,
  holder.ctx = option.ctx,
  holder.onChange = option.onChange,
  holder.width = option.width,
  holder.height = option.height,
  holder.index = 0;// 记录绘制到哪个点数据了 （该index还未绘制）。
  holder.needMove = true;// 是否需要抬笔。
  holder.status = 0;// 0：初始状态  1：播放中  2：暂停
  holder.progress = 0; // 播放进度
  holder.updateTS = () => {
    holder.startTS = _.get(holder.data, '0.st', 0); // 开始时间
    holder.endTS = _.get(holder.data, `${_.size(holder.data) - 1}.st`, 0); // 结束时间
    holder.duration = holder.endTS - holder.startTS;// 总时间
    if (!holder.cTime) holder.cTime = holder.startTS; // 当前播放到的时间
  };
  holder.setData = (data) => {
    holder.data = data;// 点数据
    holder.updateTS();
  };
  holder.setData(option.data || []);// demo
  holder.isPlaying = () => holder.status === 1;
  holder.isPause = () => holder.status === 2;
  holder.setPlaying = () => holder.status = 1;
  holder.setPause = () => holder.status = 2;
  holder.scaleRatio = holder.width / xMaxValue; // 缩放比例。
  // holder.play = play;
  holder.stroke = () => {
    holder.ctx.clearRect(0, 0, holder.width, holder.height);
    holder.ctx.stroke();
  };  // 刷新绘制的点到屏幕上。
  // 更新播放进度。
  holder.updateProgress = () => holder.progress = _.toInteger((holder.cTime - holder.startTS) * 100 / holder.duration);
  // 通知更新的方法。
  if (holder.onChange) {
    holder.change = holder.onChange;
  } else {
    holder.change = () => {};
  }
  return holder;
}

// 配置canvas
function settingCanvas (ctx) {
  const color = '#222222';
  ctx.fillStyle = '#ffffff';
  ctx.shadowColor = color;
  ctx.shadowBlur = 1;
  ctx.strokeStyle = color;
  ctx.lineWidth = 1;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';
}

// 切换播放进度。0 -> 100
export function seekTo (holder, position) {
  if (_.isEmpty(holder)) return;
  seekToImpl(holder, position);
}

// 切换进度的具体实现
function seekToImpl (holder, position) {
  holder.ctx.beginPath();
  const frameCutTime = holder.startTS + _.toInteger(holder.duration * position / 100);
  let seekIndex = 0;
  for (let i = 0; i < holder.data.length; i++) {
    if (holder.data[i].st > frameCutTime) break;
    drawPointImpl(holder, i);
    seekIndex = i;
  }
  holder.index = seekIndex + 1;
  holder.stroke();
  holder.cTime = frameCutTime;
  holder.updateProgress();
  holder.change();
}

// 绘制一个点数据的实现。 包括抬起
function drawPointImpl (holder, index) {
  const x0 = holder.data[index].x
  const y0 = holder.data[index].y
  if (x0 === 0 && y0 === 0) {
    holder.needMove = true;
    return;
  }
  const x = x0 * holder.scaleRatio;
  const y = y0 * holder.scaleRatio;
  if (holder.needMove) {
    holder.ctx.moveTo(x, y);
    holder.needMove = false;
  } else {
    // holder.ctx.quadraticCurveTo(x, y);
    holder.ctx.lineTo(x, y);
  }
}

// 暂停播放
export function pause (holder) {
  if (!holder) return;
  if (holder.isPlaying()) holder.setPause();
}

// 停止播放
export function stop (holder) {
  if (!holder) return;
  holder.status = 0;
  if (holder.playInterval) {
    clearInterval(holder.playInterval);
    holder.playInterval = undefined;
  }
}

// 重新开始播放 或 恢复播放。
export function play (holder) {
  if (!holder) return;
  if (holder.isPlaying()) return;
  if (holder.isPause()) {
    holder.setPlaying();
    if (holder.progress != 100) return
  }
  if (holder.progress === 100) resetPlay(holder);
  holder.setPlaying();
  const intervalTime = 1000 / 24;
  holder.playInterval = setInterval(() => {
    if (!holder.isPlaying()) return;
    const frameCutTS = intervalTime * holder.speed + holder.cTime;
    const size = holder.data.length;
    let frameCount = 0;
    let needStop;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const nextIndex = holder.index + frameCount;
      needStop = nextIndex >= size;
      if (needStop) break;
      const nTS = holder.data[nextIndex].st;
      if (nTS > frameCutTS) break;
      drawPointImpl(holder, nextIndex);
      frameCount++;
    }

    if (frameCount > 0) holder.stroke();
    holder.cTime = frameCutTS;
    holder.index += frameCount;
    holder.updateProgress();
    if (needStop) {
      stop(holder)
      holder.progress = 100
    }
    holder.change();
  }, intervalTime);
}

// 重置播放状态 清理计时器。
function resetPlay (holder) {
  holder.index = 0;
  holder.status = 0;
  holder.progress = 0;
  holder.cTime = holder.startTS;
  holder.ctx.beginPath();
  holder.stroke();
  if (holder.playInterval) {
    clearInterval(holder.playInterval);
    holder.playInterval = undefined;
  }
}

function  fttInt (i) {
  return i > 9 ? `${i}` : `0${i}`
}
/* 格式化时间 */
export function timeFtt (time) {
  const s = _.toInteger(time/1000)
  const m = _.toInteger(s / 60)
  const se = _.toInteger(s % 60)
  return `${fttInt(m)}:${fttInt(se)}`
}

