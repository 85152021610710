require('assets/app2/directives/question/syntheticPreviewer.directive')
angular.module('app.directives.question.exerciseSyntheticPreviewer', [                                                                                                                                              
'app.directives.question.syntheticPreviewer'
])
  .component('exerciseSyntheticPreviewer', {
    bindings: {
        questionModel: '=',
        qHidePoint: '<',
        qCloudRes: '=',
        qIndex: '=',
        parentIndex: '=',
        qReadonly: '=',
        scoreChanged: '&onScoreChanged',
        qShowAnswer: '=',
        qHideDifficult: '<',
        qShowAnswerDetails: '=',
        qShowCloudResId: '=',
        qShowCloudTestLink: '=',
        onImportClicked: '&',
        onGoToTestClicked: '&',
        ruanyun: '<',
        isExercise: '=',  
        exKnowledgePoint: '=',
        exDifficult: '=',
        isNewVersion: '=',
    },
    controller: exerciseSyntheticPreviewer,
    template: <string>require('./exerciseSyntheticPreviewer.html'),
  })
  exerciseSyntheticPreviewer.$inject = []
function exerciseSyntheticPreviewer() {}
