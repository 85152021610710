/**
 * @fileOverview
 * @name utils.js
 * @author pangwa
 * @license
 */
var courseUtils = require('./courseUtils');
var fileUtils = require('./fileUtils');
var testUtils = require('./testUtils');
var versionChecker = require('./versionChecker');
var urlBuilder = require('./urlBuilder');
var userInfoUtils = require('./userInfoUtils');
var accountUtils = require('./accountUtils');
var homeworkGroupUtils = require('./homeworkGroupUtils');
var oedZhongZhiSchool = require('./zhongZhiSchool');

(function() {
  angular.module('app.utils', [
    'app.utils.homeworkGroupUtils',
    'app.utils.courseUtils',
    'app.utils.fileUtils',
    'app.utils.testUtils',
    'app.utils.versionChecker',
    'app.utils.userInfoUtils',
    'app.utils.urlBuilder',
    'app.utils.oedZhongZhiSchool',
    'app.utils.accountUtils'
  ])
    .constant('oedConfig', {
      url: function(u) {
        return '/ray/api/a/' + u;
      },
      url_b: function(u) {
        return '/ray/api/b/' + u;
      },
      url_c: function(u) {
        return '/ray/api/c/' + u;
      },
      path: function(u) {
        return '/ray/static/oed/' + u;
      },
      base: function() {
        return '/ray/';
      },
      newBase: function(u) {
        return '/ray/' + u;
      },
      hal_writing: function(u) {
        return '/tal/' + u;
      }
    })
    .constant('oedMisc', {
      utils: {
        convertSectionsToJsTreeModel: function(sectionIdMap, sections) {
          var allNodes = [];

          function addAllNodes(sec) {
            var id = sec.id || 0;
            var parentId = (_.has(sec, 'parentId') && sec.parentId !== null) ? sec.parentId : '0';
            if (!_.has(sectionIdMap, parentId)) {
              parentId = '#';
            }

            var name = sec.name || sec.gradeName;

            allNodes.push({
              id: id,
              parent: parentId,
              text: name,
              state: {
                opened: false
              }
            });
            _.each(sec.child, addAllNodes);
          }

          if (_.size(sections) == 1) { //只有一个年级的情况, 忽略掉年级这个node{
            _.each(sections[0].child, addAllNodes);
          } else {
            _.each(sections, addAllNodes);
          }

          return allNodes;
        }
      }
    }).constant('oedOem', {
      curOem: function() {
        return ''
      }
    });

})();
