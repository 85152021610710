(function() {
  'use strict';

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.taskLists', {
        url: '/taskLists',
        template: require('assets/templates/homework/taskLists.html'),
        controller: 'taskListsCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('taskListsCtrl', taskListsCtrl);

  taskListsCtrl.$inject = ['$scope', '$q', '$uibModal', 'userInfo', '$state']
  function taskListsCtrl($scope, $q, $uibModal, userInfo, $state) {

    $scope.filters = [{
      name: '已发布',
      value: 'inprogress'
    }, {
      name: '待发布',
      value: 'unpublished'
    }, {
      name: '已完成',
      value: 'finished'
    }];
    $scope.selectedFilter = {};
    $scope.filterAll = {
      name: '所有',
      value: 'all'
    };

    // 入口
    userInfo.then(function(user) {
      $scope.teacherId = user.uid;
      if ($state.is('homework.taskLists'))
        $scope.selectFilter($scope.filters[0]);
    });

    $scope.selectFilterAll = function() {
      $scope.selectFilter($scope.filterAll);
    };

    $scope.selectFilter = function(filter) {
      if (filter == $scope.selectedFilter) {
        return;
      }
      $scope.selectedFilter = filter;
      $state.go('homework.taskLists.forStatus', {
        status: filter.value
      }, {
        location: 'replace'
      });
    };
  }
})();
