import * as angular from 'angular';
import 'app2/directives/question/subjQuestionPreviewer.directive';
import 'app2/directives/resource/presentItemsPreviewer.directive';

angular.module('app.directives.homework.homeworkTaskPreviewer', [
  'app.directives.question.subjQuestionPreviewer',
  'app.directives.resource.presentItemsPreviewer',
]).component('homeworkTaskPreviewer', {
  bindings: {
    onRemoveRes: '&',
    resources: '<',
  },
  controller: homeworkTaskPreviewerCtrl,
  template: <string> require('./homeworkTaskPreviewer.html'),
});

homeworkTaskPreviewerCtrl.$inject = []
function homeworkTaskPreviewerCtrl() {
  this.getResName = (res: any) => res.name;
}
