import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { QuestionDTO } from '../models/exam';
import { LegacyAppService } from 'app/core/legacy-app.service';
import _ = require('lodash');

const questionTypes = {
  'yesorno': '判断',
  'singlechoice': '单选',
  'multichoice': '多选',
  'connect': '匹配',
  'fill': '填空',
  'vote': '投票',
  'synthetic': '综合',
  'qa': '问答'
}

const subQuestionTypes = _.omit(questionTypes, 'synthetic')

const questionTypeList = Object.keys(questionTypes)
const subQuestionTypeList = _.filter(questionTypeList, (v) => v !== 'synthetic')

@Component({
  selector: 'app-question-type-switch',
  template: require('./question-type-switcher.component.html'),
  styles: [require('./question-type-switcher.component.scss')]
})
export class QuestionTypeSwitcherComponent implements OnInit {

  @Input() isSub: any = false
  @Input() questionModel: QuestionDTO
  @Output() questionChanged = new EventEmitter<QuestionDTO>()

  choices = []

  constructor(private legacyApp: LegacyAppService) { }

  ngOnInit() {
    console.log('questionModel', this.questionModel)
  }

  isSubQuestion() {
    return !_.isUndefined(this.isSub) && this.isSub
  }

  getTypes() {
    return this.isSubQuestion() ? subQuestionTypes : questionTypes
  }

  getQuestionTypeList() {
    return this.isSubQuestion() ? subQuestionTypeList : questionTypeList
  }

  getOrigQuesType() {
    return _.get(this.questionModel, 'type', '')
  }

  getCurrentQuesType() {
    const t = this.getOrigQuesType()
    if (t == 'singlevote' || t == 'multivote') {
      return 'vote';
    } else {
      return t;
    }
  }

  setType (type: string) {
    if (type === this.getCurrentQuesType())
      return;

    const applyType = (t) => {
      const localStorageService = this.legacyApp.getLocalStorage()
      localStorageService.set('lastQuestionType', type);
      _.set(this.questionModel, 'answer', null)
      const prevType = this.getOrigQuesType();
      if (type == 'vote') {
        _.set(this.questionModel, 'type', this.isMultiVote ? 'multivote' : 'singlevote')
        _.set(this.questionModel, 'answer', '-1')
      } else {
        _.set(this.questionModel, 'type', type)
        if (type == 'qa') {
          _.set(this.questionModel, 'answer', '-1')
        }
      }

      this.choices = [];
      if (type != 'fill' && this.questionModel && !this.questionModel.point2) {
        this.questionModel.point2 = localStorageService.get('lastQuestionPoint') || 1;
      }
      //
      // don't remove the options if switch between singlechoice/multichoice
      if (_.size(_.without(['singlechoice', 'multichoice'], prevType, type)) !== 0) {
        _.set(this.questionModel, 'choices', [])
        if (type == 'singlechoice' || type == 'multichoice' || type == 'vote') {
          _.times(4, () => {
            this.questionModel.choices.push({
              content: ''
            });
          });
        }
      }

      this.questionChanged.emit(this.questionModel)
    }

    if (this.questionModel && !_.isEmpty(this.questionModel.answer)) {
      this.legacyApp.getDialogs().confirm('确认修改问题类型',
                      '修改题型将导致当前设置的答案丢失，是否确认题型修改？')
        .result.then(() => {
          applyType(type)
        })
    } else {
      applyType(type);
    }
  }

  get isMultiVote() {
    const t = this.getOrigQuesType()
    if (t === 'singlevote' || t === 'multivote') {
      return t === 'multivote';
    }

    return false
  }

  set isMultiVote(val: boolean) {
    if (val) {
      this.questionModel.type = 'multivote'
    } else {
      this.questionModel.type = 'singlevote'
    }
    this.questionChanged.emit(this.questionModel)
  }

}
