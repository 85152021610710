const Promise = require('bluebird');
require('app2/utils/taskUtils');
require('app2/utils/homeworkGroupUtils');
const {createTestIfNeeded} = require('./homeworkUtils')

angular.module('app.directives.homework.homeworkObjEditor', [])
  .directive('homeworkObjEditor', homeworkObjEditor);

homeworkObjEditor.$inject = ['$document', '$timeout']
function homeworkObjEditor($document, $timeout) {
  return {
    restrict: 'E',
    scope: {
      group: '=',
      groups: '=',
      testId: '=',
      onSaveGroup: '&',
      createTasksFromLibrary: '&'
    },
    template: require('assets/app2/directives/homework/homeworkObjEditor.html'),
    controller: homeworkObjEditorCtrl
  };
}
homeworkObjEditorCtrl.$inject = [
  'dialogs', 'oedObjectiveTest', '$q', 'oedCourseUtils', '$scope',
  'notificationService', 'oedTestUtils', '$location', '$anchorScroll']
function homeworkObjEditorCtrl(dialogs, oedObjectiveTest, $q, oedCourseUtils, $scope,
  notificationService, oedTestUtils, $location, $anchorScroll) {

  /**
   * 添加一题前先判断是否需要先生成一道客观试卷
   * @param {*} test
   */
  $scope.addObjQuestion = function(group) {
    if ($scope.saving) {
      $scope.pendingAdd = true;
      return;
    }
    $scope.pendingAdd = false;
    createObjTestIfNeed(group.$test).then(function(test) {
      addDefaultQues(group, test).then(function(newQ) {
        $scope.editingQuestion = newQ;
        scrollToQuestion(group.sort, newQ.sort);
      });
    });
  }

  function addDefaultQues(group, test) {
    group.$test = test;
    group.$test.questions = group.$test.questions || [];
    group.$type = 'objectiveTest';
    var newQ = oedCourseUtils.defaultQuestion();
    var maxSort = _.get(_.maxBy(group.$test.questions, 'sort'), 'sort');
    if (_.isUndefined(maxSort) || _.isNaN(maxSort) || !_.isNumber(maxSort)) {
      maxSort = 0;
    }

    maxSort++;
    newQ.sort = _.max([maxSort, _.size(group.$test.questions)]);
    group.$test.questions.push(newQ);
    return Promise.resolve(newQ);
  }

  function createObjTestIfNeed(test) {
    return createTestIfNeeded(test, oedObjectiveTest)
  }

  $scope.getQuesIndex = function(group, question) {
    return _.findIndex(group.$test.questions, function(q) { return q.id === question.id;}) + 1;
  };

  $scope.errorForEmpty = false;
  $scope.showQuesitonError = function(q) {
    return $scope.errorForEmpty && $scope.editingQuestion === q;
  };

  $scope.backCurrentQuestion = function() {
    if ($scope.errorForEmpty) {
      $scope.errorForEmpty = false;
    }
  };

  $scope.selectedResources = {};

  $scope.hasObjQuesSelected = function() {
    return !_.isEmpty($scope.selectedResources);
  };

  $scope.isSelected = function(item) {
    return _.has($scope.selectedResources, item.id);
  };

  $scope.toggleSelect = function(item, group) {
    if (_.has($scope.selectedResources, item.id)) {
      $scope.selectedResources = _.omit($scope.selectedResources, item.id);
    } else {
      $scope.selectedResources[item.id] = {
        groupId: group.$test.id,
        id: item.id,
        selected: true,
        type: group.$type
      };
    }
  };

  $scope.deleteObjQuesSelected = function(group) {
    var message = '确定要删除客观题吗（共' + _.size($scope.selectedResources) + '题）？';
    var dlg = dialogs.confirm('确定删除?', message);
    dlg.result.then(function() {
      group.$test.questions = _.filter(group.$test.questions, function(q) {
        return !$scope.isSelected(q);
      });
      $scope.onSaveGroup({group: group});
      $scope.selectedResources = {};
    });
  };
  $scope.saveSyn = function(group, ques){
    $scope.onSaveGroup({group: group}).then(() => {
      scrollToQuestion(group.sort, ques.sort);
    });
  }

  $scope.onDeleteQuestionClicked = function(group, question) {
    var dlg = dialogs.confirm('确定删除？', '确定删除此题吗？');
    dlg.result.then(function(btn) {
      var idx = _.findIndex(group.$test.questions, question);
      group.$test.questions.splice(idx, 1);
      $scope.onSaveGroup({group: group}).then(() => {
        if (!_.isEmpty(group.$test.questions)){
          scrollToQuestion(group.sort, question.sort);
        }
      });
      if (question === $scope.currentQuestion) {
        $scope.currentQuestion = null;
      }
      if ($scope.isSelected(question)) {
        $scope.selectedResources = _.omit($scope.selectedResources, question.id);
      }
    }, function(btn) {});
  };

  $scope.canMoveUp = function(group, question) {
    // 刚刚添加，尚未保存的题目，禁止上移
    if (!_.has(question, 'id')) {
      return false;
    }
    var idx = _.findIndex(group.$test.questions, function(q) { return q.id === question.id;});
    return idx > 0;
  };

  $scope.canMoveDown = function(group, question) {
    if (!_.has(question, 'id')) {
      return false;
    }
    var idx = _.findIndex(group.$test.questions, function(q) { return q.id === question.id;});
    // 下面的一题不能是刚刚添加且尚未保存的题目
    return idx >= 0 && idx < (_.size(group.$test.questions) - 1) &&
      _.has(_.get(group.$test.questions, idx + 1, {}), 'id');
  };

  $scope.onMoveUpClicked = function(group, question) {
    var curIdx = _.findIndex(group.$test.questions, function(q) { return q.id === question.id;});
    if (curIdx <= 0) {
      return;
    }

    var tempSort = group.$test.questions[curIdx - 1].sort;
    group.$test.questions[curIdx - 1].sort = question.sort;
    group.$test.questions[curIdx].sort = tempSort;
    group.$test.questions = _.orderBy(group.$test.questions, 'sort', 'asc');
    $scope.onSaveGroup({group: group}).then(() => {
      scrollToQuestion(group.sort, tempSort);
    });
  };

  $scope.onMovePlaceClicked = function(group, question, placeIndex) {
    var curIdx = _.findIndex(group.$test.questions, function(q) { return q.id === question.id;});
    if (curIdx < 0 || placeIndex === curIdx) {
      return;
    }
    var tempSort = group.$test.questions[placeIndex].sort;
    var durCount;
    if (placeIndex > curIdx) {
      durCount = placeIndex - curIdx;
      for(let i = 0; i < durCount; i++) {
        group.$test.questions[curIdx + i + 1].sort = group.$test.questions[curIdx + i].sort;
      }
      group.$test.questions[curIdx].sort = tempSort;
    } else {
      durCount = curIdx - placeIndex;
      for(let i = 0; i < durCount - 1; i++) {
        group.$test.questions[placeIndex + i].sort = group.$test.questions[placeIndex + i + 1].sort;
      }
      group.$test.questions[curIdx].sort = tempSort;
    }
    group.$test.questions = _.orderBy(group.$test.questions, 'sort', 'asc');
    $scope.onSaveGroup({group: group}).then(() => {
      scrollToQuestion(group.sort, tempSort);
    });
  };

  $scope.onMoveDownClicked = function(group, question) {
    var curIdx = _.findIndex(group.$test.questions, function(q) { return q.id === question.id;});
    if (curIdx < 0 || curIdx >= (_.size(group.$test.questions) - 1)) {
      return;
    }

    var tempSort = group.$test.questions[curIdx + 1].sort;
    group.$test.questions[curIdx + 1].sort = question.sort;
    group.$test.questions[curIdx].sort = tempSort;
    group.$test.questions = _.orderBy(group.$test.questions, 'sort', 'asc');

    $scope.onSaveGroup({group: group}).then(() => {
      scrollToQuestion(group.sort, tempSort);
    });
  };

  $scope.isNewQuestion = function(question) {
    return !_.get(question, 'id');
  };

  $scope.onEnterEditQuestion = function(question, event) {
    $scope.currentQuestion = angular.copy(question);
    $scope.editingQuestion = question;
  };

  const expTable = new RegExp('<table>');
  const regExpBr = /^(<p><br\/><\/p>)+|(<p><br\/><\/p>)+$/g;
  $scope.eachTrimEnter = function(x) {
    if (regExpBr.test(x) && x != '<p><br/></p>') {
      return x.replace(regExpBr, '');
    } else {
      return x;
    }
  };

  function scrollToQuestion(groupSort, quesSort) {
    setTimeout(function () {
      $location.hash(`question_${groupSort}_${quesSort}`);
      $anchorScroll();
    }, 0)
  }

  $scope.onLeaveEditQuestion = function(group, question, event) {
    var idx = _.findIndex(group.$test.questions, question);
    var ret = oedTestUtils.validateQuestion(question, false);
    if (question.type === 'synthetic') {
      const syntheticTable = [];
      var rets = _.map(question.childQuestions, function(q) {
        var tmpQ = angular.copy(q);
        syntheticTable.push(tmpQ);
        tmpQ.isSub = true;
        var tmp = oedTestUtils.validateQuestion(tmpQ, false);
        return tmp.valid ? null : tmp;
      });
      var result = _.find(rets, function(r) {
        return r !== null && !r.valid;
      });
      if (!_.isUndefined(result))
        ret = result;
      if (_.size(question.childQuestions) === 0) {
        ret = {
          valid: false,
          message: '当前综合题没有子题， 请设置子题后再保存'
        };
      }
      //综合题子题题干不能插入表格
      const synTableQues = _.find(syntheticTable, (c) => expTable.test(c.question));
      if (synTableQues !== undefined) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '综合题子题题干暂不支持插入表格')
        scrollToQuestion(group.sort, question.sort);
        return false;
      }
      const synAnswerDetails = _.find(syntheticTable, (c) => expTable.test(c.answerDetails));
      if (synAnswerDetails !== undefined) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '综合题子题解析暂不支持插入表格')
        scrollToQuestion(group.sort, question.sort);
        return false;
      }
      //综合题子题选项
      const syntheticChoices = [];
      const synChoicesContent = _.find(syntheticTable, function(x) {
        if (x.type === 'singlechoice' || x.type === 'multichoice' ||
          x.type === 'singlevote' || x.type === 'multivote') {
          _.each(x.choices, function(c) {
            syntheticChoices.push(c.content);
          });
        }
        return expTable.test(syntheticChoices);
      });
      if (synChoicesContent !== undefined) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '综合题子题选项中暂不支持插入表格')
        scrollToQuestion(group.sort, question.sort);
        return false;
      }
      //综合题leftOpts、rightOpts
      const syntheticLeftOpts = [];
      const syntheticRightOpts = [];
      const synLeftOpts = _.find(syntheticTable, function(x) {
        if (x.type === 'connect') {
          _.each(x.leftOpts, function(c) {
            syntheticLeftOpts.push(c.content);
          });
        }
        return expTable.test(syntheticLeftOpts);
      });
      const synRightOpts = _.find(syntheticTable, function(x) {
        if (x.type === 'connect') {
          _.each(x.rightOpts, function(c) {
            syntheticRightOpts.push(c.content);
          });
        }
        return expTable.test(syntheticRightOpts);
      });
      if (synLeftOpts !== undefined || synRightOpts !== undefined) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '综合题匹配题选项中暂不支持插入表格')
        scrollToQuestion(group.sort, question.sort);
        return false;
      }
      //综合题子题题干、解析、选项去除多余回车
      _.forEach(question.childQuestions, function(c) {
        c.question = $scope.eachTrimEnter(c.question);
        c.answerDetails = $scope.eachTrimEnter(c.answerDetails);
        if (c.type === 'singlechoice' || c.type === 'multichoice' ||
          c.type === 'singlevote' || c.type === 'multivote') {
          _.forEach(c.choices, function(opt) {
            opt.content = $scope.eachTrimEnter(opt.content);
          });
        }
        if (c.type === 'connect') {
          _.forEach(c.leftOpts, function(opt) {
            opt.content = $scope.eachTrimEnter(opt.content);
          });
          _.forEach(c.rightOpts, function(opt) {
            opt.content = $scope.eachTrimEnter(opt.content);
          });
        }
      });
      scrollToQuestion(group.sort, question.sort);
    }
    if (ret && !ret.valid) {
      $scope.errorForEmpty = true;
      notificationService.notify('error', ret.message)
      scrollToQuestion(group.sort, question.sort);
      // $scope.$apply();
      return false;
    } else {
      if (expTable.test(question.question)) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '题干中暂不支持插入表格')
        scrollToQuestion(group.sort, question.sort);
        return false;
      }
      if (expTable.test(question.answerDetails)) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '解析中暂不支持插入表格')
        scrollToQuestion(group.sort, question.sort);
        return false;
      }
      //去除题干和解析中多余的回车
      question.question = $scope.eachTrimEnter(question.question);
      question.answerDetails = $scope.eachTrimEnter(question.answerDetails);
      //单选、多选、投票中的choices出入表格的处理
      if (question.type === 'singlechoice' || question.type === 'multichoice' ||
        question.type === 'singlevote' || question.type === 'multivote') {
        const tableQues = _.find(question.choices, (c) => expTable.test(c.content));
        if (tableQues !== undefined) {
          $scope.errorForEmpty = true;
          notificationService.notify('error', '选项中暂不支持插入表格')
          scrollToQuestion(group.sort, question.sort);
          return false;
        }
        //去除选择题选项中多余的回车
        _.forEach(question.choices, function(c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
      }
      //当表格的leftOpts或rightOpts中粘贴了表格时候的处理
      if (question.type === 'connect') {
        const connectLeftOpts = _.find(question.leftOpts, (c) => expTable.test(c.content));
        const connectRightOpts = _.find(question.rightOpts, (c) => expTable.test(c.content));
        if (connectLeftOpts !== undefined || connectRightOpts !== undefined) {
          $scope.errorForEmpty = true;
          notificationService.notify('error', '匹配题中暂不支持插入表格')
          scrollToQuestion(group.sort, question.sort);
          return false;
        }
        //去除leftOpt和rightOpt多余的回车
        _.forEach(question.leftOpts, function(c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
        _.forEach(question.rightOpts, function(c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
      }
    }
    //
    // 当前问题已经修改时要保存测试
    if (!angular.equals($scope.currentQuestion, question)) {
      $scope.saving = true;
      $scope.onSaveGroup({group: group}).then(() => {
        $scope.saving = false;
        scrollToQuestion(group.sort, question.sort);
        if ($scope.pendingAdd) {
          $scope.addObjQuestion(group);
          $scope.pendingAdd = false;
        }
      });
    }

    $scope.currentQuestion = null;
    $scope.editingQuestion = null;

    return true;
  }

}
