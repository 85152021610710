/**
 * @fileOverview
 * @name randomAPKFilter.js
 * @author pangwa
 * @license
 */
var userInfo = require('app2/utils/userInfoUtils');

(function() {
  angular.module('app.filters.randomAPK', ['app.utils.userInfoUtils']).filter('randomAPK', randomAPK);

  randomAPK.$inject = ['userInfo']
  function randomAPK(userInfo) {
    return function(url, props) {
      var counter = moment(userInfo.counter).format('YYYYMMDDHHmm');
      if (_.endsWith(url, '.apk')) {
        url = url.substr(0, url.length - 4) + '_' + counter + '.apk';
      }
      return url;
    };
  }

})();
