const moment = require('moment');
angular.module('app.manage.courseManagement', [])
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('manage.courseManagement', {
      url: '/courseManagement',
      template: require('assets/templates/manage/courseManagement.html'),
      controller: 'courseManagementCtrl',
      bodyStyle: 'bg_img'
    });
  }])
  .controller('courseManagementCtrl', courseManagementCtrl);

courseManagementCtrl.$inject = ['oedConfig', '$http', '$scope', '$state', '$stateParams', '$uibModal',
  'notificationService', 'oedCourseManage', 'userInfo']
function courseManagementCtrl(
  oedConfig, $http, $scope, $state, $stateParams,$uibModal,
  notificationService, oedCourseManage, userInfo) {
  $scope.currentState.title = '课程管理';
  const pageSize = 16;
  $scope.pageNum = 1;
  $scope.courseList = [];
  $scope.pageSize = pageSize;
  $scope.userInfoService = userInfo;
  $scope.selectSubId = -1;
  $scope.selectTeaId = -1;

  $scope.loading = getCourseList($scope.pageNum, pageSize, $scope.selectTeaId, $scope.selectSubId);

  function getCounts(teacherId, subjectId) {
    getCountNum(oedConfig.url(`classsession/countOfUser/${teacherId}/${subjectId}`)).then(item => {
      $scope.totalNums = item.data;
      $scope.numPages = $scope.totalNums / pageSize;
    })
  }

  function getCountNum(url, opt) {
    const countReq = $http({
      url: url,
      method: 'GET',
      params: opt
    });

    return countReq;
  }

  function getCourseList(pageNum, pageSize, teacherId, subjectId){
    getCounts(teacherId, subjectId);
    $scope.courseList.length = 0;
    oedCourseManage.getCourseListById({pageNum: pageNum, pageSize: pageSize, teacherId: teacherId, subjectId:subjectId}).$promise.then((arr) => {
      if (arr.length !== 0) {
        _.forEach(arr, (itemArr,index) => {
          oedCourseManage.getCourseDetails({id: itemArr.id}).$promise.then((item) => {
            item.id = itemArr.id;
            item.startTime = moment(item.startTime).format('YYYY/MM/DD  HH:mm');
            $scope.courseList[index] = item;
          });
        })
      }
    });
  }

  $scope.onPageChange = function(pageNum) {
    $scope.loading =getCourseList(pageNum, pageSize, $scope.selectTeaId, $scope.selectSubId);
  };

  $scope.selectSubject = function() {
    const modalselectSubject = $uibModal.open({
      template: require('assets/templates/manage/changeSubject.html'),
      controller: 'changeSubjectCtrl',
      size:'vail',
      windowClass: 'oedchangesub',
      resolve: {
        id: function() {
          return $scope.selectSubId;
        }
      }
    });
    modalselectSubject.result.then(function(r) {
      if (r.success) {
        $scope.selectSubId = r.id;
        getCourseList($scope.pageNum, pageSize, $scope.selectTeaId, $scope.selectSubId);
      } else{
        notificationService.notify('info', '选择失败')
      }
    });
  }

  $scope.selectTeacher = function() {
    const modalselectTeacher = $uibModal.open({
      template: require('assets/templates/manage/changeTeacher.html'),
      controller: 'changeTeacherCtrl',
      size:'vail',
      windowClass: 'oedchangesub',
      resolve: {
        id: function() {
          return $scope.selectTeaId;
        }
      }
    });
    modalselectTeacher.result.then(function(r) {
      if (r.success) {
        $scope.selectTeaId = r.id;
        getCourseList($scope.pageNum, pageSize, $scope.selectTeaId, $scope.selectSubId);
      } else{
        notificationService.notify('info', '选择失败')
      }
    });
  }

  $scope.changeToInVaild = function changeStateInVaild(course) {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/manage/changeState.html'),
      controller: 'changeStateCtrl',
      size:'vail',
      resolve: {
        id: function() {
          return course.id;
        },
        state: function() {
          return course.manualInvalid;
        },
        course: function() {
          return course;
        }
      }
    });

    modalInstance.result.then(function(r) {
      if (r.success) {
        notificationService.notify('info', '修改成功')
      } else{
        notificationService.notify('info', '修改失败')
      }
    });
  }
}

