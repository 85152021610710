import * as angular from 'angular';
import * as _ from 'lodash';
import { defaultOption } from '../../utils/prepareCourseUtils'

angular.module('app.prepareCourse2.answerEditor', [])
       .controller('answerEditorCtrl', answerEditorCtrl)

answerEditorCtrl.$inject = [
  '$q',
  '$scope',
  'data',
  '$uibModalInstance',
  'notificationService',
  '$uibModal',
  'oedObjectiveQuestion'
]
function answerEditorCtrl(
  $q,
  $scope,
  data: any,
  $uibModalInstance,
  notificationService,
  $uibModal,
  oedObjectiveQuestion
) {

  $scope.target = _.cloneDeep(data.childQuestionList)

  $scope.switchQuestionDefaultOption = (name) => {
    $scope.questionNm = defaultOption[name].questionNm
    $scope.optionNm = defaultOption[name].optionNm
    $scope.scoreNm = defaultOption[name].scoreNm
  }

  if (data.childQuestionList &&
      data.childQuestionList.length > 0 &&
      data.childQuestionList[0] &&
      data.childQuestionList[0].choices.length > 0
  ) {
    $scope.questionNm = data.childQuestionList.length
    $scope.optionNm = data.childQuestionList[0].choices.length
    $scope.scoreNm = data.childQuestionList[0].point2
    $scope.type = data.childQuestionList[0].type
    if ($scope.type === 'singlechoice') {
      $scope.isSelected = true
    } else {
      $scope.isSelected = false
    }
  } else {
    $scope.type = 'singlechoice'
    if ($scope.type === 'singlechoice') {
      $scope.isSelected = true
      $scope.switchQuestionDefaultOption('singlechoice')
    } else {
      $scope.isSelected = false
      $scope.switchQuestionDefaultOption('yesorno')
    }
  }
  $scope.currentType = $scope.type


  $scope.computeNnmber = (operate: string, name: string) => {
    const num = angular.element(document.getElementById(name))[0]['value']
    if (num) {
      $scope[name] = _.parseInt(num)
    } else {
      $scope[name] = 0
    }

    if (operate === 'add') {
      $scope[name] = $scope[name] + 1
      if (name === 'questionNm' && $scope[name] + 1 > 200) {
        $scope[name] = 200
        return notificationService.notify('error', '题目数量最多为200')
      }
      if (name === 'optionNm' && $scope[name] + 1 > 10) {
        $scope[name] = 10
        return notificationService.notify('error', '选项数量最多为10')
      }
      if (name === 'scoreNm' && $scope[name] + 1 > 10) {
        $scope[name] = 10
        return notificationService.notify('error', '每题分数最多为10')
      }
    } else if (operate === 'sub') {
      if ($scope[name] - 1 > 0) {
        $scope[name] = $scope[name] - 1
        if (name === 'questionNm' && $scope[name] <= 0) {
          return notificationService.notify('error', '请输入正确题量数')
        }
        if (name === 'optionNm' && $scope[name] <= 0) {
          return notificationService.notify('error', '请输入正确选项数')
        }
        if (name === 'scoreNm' && $scope[name] <= 0) {
          return notificationService.notify('error', '请输入正确题分数')
        }
      }
    }
  }

  $scope.confirmClick = () => {
    if ($scope.questionNm > 200) {
      return notificationService.notify('error', '题目数量最多为200')
    } else if ($scope.questionNm <= 0 || $scope.questionNm === undefined) {
      return notificationService.notify('error', '请输入正确题量数')
    } else if ($scope.optionNm > 10) {
      return notificationService.notify('error', '选项数量最多为10')
    } else if ($scope.optionNm <= 0 || $scope.optionNm === undefined) {
      return notificationService.notify('error', '请输入正确选项数')
    } else if ($scope.scoreNm > 10) {
      return notificationService.notify('error', '每题分数最多为10')
    } else if ($scope.scoreNm <= 0 || $scope.scoreNm === undefined) {
      return notificationService.notify('error', '请输入正确题分数')
    } else {
      $scope.valueChangedWithValid(defaultChildQuestion(), false)
    }
  }

  $scope.valueChangedWithValid = (c, cancel) => {
    // 第一次设置答案
    if ($scope.target.length === 0) {
      if (cancel && c && c[0].type === $scope.currentType &&
        c.length === defaultOption[c[0].type].questionNm &&
        c[0].choices.length === defaultOption[c[0].type].optionNm &&
        c[0].point2 === defaultOption[c[0].type].scoreNm
        ) {
        $uibModalInstance.close()
      } else if (
        !cancel && c && c[0].type === $scope.currentType &&
        c.length === defaultOption[c[0].type].questionNm &&
        c[0].choices.length === defaultOption[c[0].type].optionNm &&
        c[0].point2 === defaultOption[c[0].type].scoreNm
      ) {
        $uibModalInstance.close(c)
      } else {
        $scope.openWarningDialog(c)
      }
      // 非 第一次设置答案
    } else {
      if (
        $scope.questionNm === data.childQuestionList.length &&
        $scope.optionNm === data.childQuestionList[0].choices.length &&
        $scope.scoreNm === data.childQuestionList[0].point2 &&
        $scope.type === data.childQuestionList[0].type
      ) {
        $uibModalInstance.close(c);
      } else {
        $scope.openWarningDialog(c)
      }
    }
  }

  $scope.changeFirstDigit = (name) => {
    $scope[name] = _.parseInt($scope[name]) < 1 ? 1 : _.parseInt($scope[name]);
  }

  $scope.switchSelect = (str: string) =>  {

    if (data.childQuestionList.length > 0 && str === data.childQuestionList[0].type) {
      $scope.questionNm = data.childQuestionList.length
      $scope.optionNm = data.childQuestionList[0].choices.length
      $scope.scoreNm = data.childQuestionList[0].point2
      $scope.target = data.childQuestionList
    } else {
      $scope.questionNm = defaultOption[str].questionNm
      $scope.optionNm = defaultOption[str].optionNm
      $scope.scoreNm = defaultOption[str].scoreNm
      $scope.target = []
    }

    if (str === 'singlechoice') {
      $scope.isSelected = true
      $scope.type = 'singlechoice'
    } else {
      $scope.isSelected = false
      $scope.type = 'yesorno'
    }
  }

  function defaultChildQuestion() {

    $scope.qChoice = []
    $scope.qOption = []

    if ($scope.type === 'singlechoice') {
      for (let i=0; i<$scope.optionNm; i ++) {
        const obj = {}
        $scope.qOption.push({...obj})
      }
    } else {
      $scope.qOption = [{}, {}]
    }

    const q = {
      answer: '-1',
      answerDetails: null,
      choices: [],
      leftOpts: [],
      needPic: false,
      point: 0,
      point2: 0,
      question: '',
      rightOpts: [],
      type: $scope.type
    }

    // 编辑答案
    if ($scope.target.length > 0) {
      if ($scope.questionNm >= $scope.target.length) {
        const arr1 = []
        for (let index = 0; index < $scope.questionNm - $scope.target.length; index++) {
          arr1.push({...q})
        }
        $scope.qChoice =  _.concat($scope.target, arr1)
        $scope.qChoice = $scope.updateValue($scope.qChoice, $scope.scoreNm, $scope.qOption)
      } else {
        $scope.qChoice = $scope.target.slice(0, $scope.questionNm)
        $scope.qChoice = $scope.updateValue($scope.qChoice, $scope.scoreNm, $scope.qOption)
      }
    // 新设置答案
    } else {
      for (let i=0; i<$scope.questionNm; i ++) {
        $scope.qChoice.push({...q})
      }
      $scope.qChoice = $scope.updateValue($scope.qChoice, $scope.scoreNm, $scope.qOption)
    }
    return $scope.qChoice
  }

  $scope.updateValue = (arr, point, choices) => {
    _.forEach(arr, (r) => {
      r.point = point
      r.point2 = point
      r.choices = choices
    })
    if (data.childQuestionList.length > 0 && data.childQuestionList[0].choices.length > $scope.optionNm) {
      const a = _.filter(arr, r => _.parseInt(r.answer) >= $scope.optionNm)
      _.map(a, (r2) => r2.answer = '-1')
    }
    return arr
  }

  $scope.openWarningDialog = (c) => {
    $scope.isHidden = true
    const modalInstance = $uibModal.open({
      template: require('app2/prepareCourse/quickCreate/editor/editrorWarningDialog.html'),
      controller: 'editrorWarningDialogCtrl',
      size: 'qp-edit',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          childQuestionList: c
        })
      }
    })
    modalInstance.result.then((q) => {
      if (q === 'edit') {
        $scope.isHidden = false
      } else if (q === 'save') {
        $uibModalInstance.close(c);
      } else if (q === 'discarded') {
        $uibModalInstance.close(data.childQuestionList);
      }
    })
  }

  $scope.cancelClick = () => {
    $scope.valueChangedWithValid(defaultChildQuestion(), true)
  }
}
