import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService, Transition } from '@uirouter/core';
import { AppStateService } from 'app/core/app-state.service';
import { NotificationService } from 'app/shared/notification/notification.service';
import { UserDTO } from 'app/models/user';
import { from, Observable, Observer, of } from 'rxjs'
import { finalize, switchMap } from 'rxjs/operators';
import * as _ from 'lodash'
import { SchoolDTO } from 'app/models/school';
import { SchoolService } from 'app/core/school.service';
import { TypeaheadMatch, TypeaheadDirective } from 'ngx-bootstrap/typeahead';
import { LegacyAppService } from 'app/core/legacy-app.service';

@Component({
  selector: 'app-join-school',
  template: require('./join-school.component.html') as string,
  styles: [require('./join-school.component.scss')]
})
export class JoinSchoolComponent implements OnInit {
  loading = true
  typeaheadLoading = false
  search = ''
  selectedSchool: SchoolDTO = null
  errorMessage = ''
  info: UserDTO = null
  schools$: Observable<SchoolDTO[]>

  @ViewChild('typeahead') typeahead: TypeaheadDirective;

  constructor(private stateService: StateService,
              private legacyApp: LegacyAppService,
              private appState: AppStateService,
              private notify: NotificationService,
              private schoolService: SchoolService) { }

  ngOnInit() {
    from(this.appState.then((v) => v))
      .pipe(finalize(() => {
        this.loading =false
      })).subscribe((ui) => {
        this.info = ui
        const sId = _.parseInt(ui.schoolId)
        if (sId >= 0) {
          this.stateService.go('home')
        }
      }, () => {
        this.notify.error('加载失败, 请重试')
      })

    this.schools$ = new Observable((observer: Observer<string>) => {
      observer.next(this.search);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          return this.schoolService.querySchool(query)
        }
        return of([])
      })
    );
  }

  joinSchool() {
    this.errorMessage = null
    if (!this.selectedSchool || this.selectedSchool.id < 0) {
      this.errorMessage = '该学校不存在, 请重新选择';
      return
    }

    this.loading = true
    this.schoolService.joinSchool(this.selectedSchool.id, this.info.idNo)
      .pipe(finalize(() => this.loading = false))
      .subscribe((result) => {
        if(!result.success) {
          this.errorMessage = result.message || ''
          return
        }
        if (!result.needApprove) {
          this.stateService.go('manage.manageCourses')
          return
        }

        this.loading = true
        this.appState.doLogout()
          .pipe(finalize(() => {
            this.loading= false
            const localStorage = this.legacyApp.getLocalStorage()
            localStorage.set('bigdata_uid', null);
            localStorage.set('bigdata_last_report_timestamp', null);
            localStorage.set('newParpreCourse_session_id',null);
            localStorage.set('pre_type',null);
            this.notify.error('您的加入学校请求需要通过审核，请联系学校管理员审核您的请求!', null, 5000)
            this.stateService.go('signin')
          }))
          .subscribe(() => {}, () => {})
      }, (e) => {
        this.errorMessage = '发生未知错误, 请检查输入或联系技术支持'
      })
  }

  onSelect(m: TypeaheadMatch) {
    this.selectedSchool = m.item
  }

  changeTypeaheadLoading(e: boolean): void {
    this.typeaheadLoading = e
  }

  highlight(match: TypeaheadMatch, query: Array<string>): string {
    query = query.filter((value) => value.trim().length > 0);
    match = new TypeaheadMatch(match.item, match.item.schoolName, match.isHeader());
    return this.typeahead._container.highlight(match, query)
  }
}
