import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CountResult} from 'app/models/cloudDrive';
import {NotificationService} from 'app/shared/notification/notification.service';
import {LegacyAppService} from 'app/core/legacy-app.service';
import {CloudDriveService} from 'app/cloud-drive/cloud-drive.service';
import {ResourceService} from 'app/core/resource.service';
import {OedDialogService} from 'app/core/oed-dialog.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {finalize, flatMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {forkJoin, of} from 'rxjs';
import {getHttpCDN} from 'app2/utils/getHttpCDN';

@Component({
  selector: 'app-my-course',
  template: require('./my-course.component.html'),
  styles: [require('./my-course.component.scss')]
})
export class MyCourseComponent implements OnInit {
  @Input() isPrepareLesson
  @Output() loadingDrive = new EventEmitter<boolean>();
  @Output() importRes = new EventEmitter<any>();
  
  fileArr = []
  checkAll = false;//全选状态
  isSelectMode = false;//是否是选择模式
  selectTotalNum = 0;//选择模式选择的文件个数
  viewMode = '1';//1代表列表视图 2代表缩略图
  page = 0;
  pageNum = 0;
  fileCount = 0;
  changeStyleObj = {}
  sortDateAsc = false;
  sortSizeAsc = false;
  sortNameAsc = false;
  selectedSort = '';
  sortType = undefined
  canAppend = true
  // 判断顶层窗口是否为iframe(配合桌面端使用)
  topWindowIsIframe = window.top === window.self;
  linkOpenType = this.topWindowIsIframe ? '_blank' : '_self';
  
  constructor(public bsModalRef: BsModalRef,
              public cloudDriveService: CloudDriveService,
              public resourceService: ResourceService,
              public legacyAppService: LegacyAppService,
              public oedDialogService: OedDialogService,
              public cdr: ChangeDetectorRef,
              public notifyService: NotificationService) { }

  ngOnInit() {
  }
  
  ngAfterViewInit() {
    this.loadingDrive.emit(true)
    this.cloudDriveService.getMicroLessonTotalCount().pipe(
      flatMap((data) => {
        this.fileCount = data.count
        return this.getListData('')
      }),
      finalize(() => {
        this.loadingDrive.emit(false)
      })).subscribe()
  }
  
  // 全选操作
  public changeSelectAllStatus() {
    this.checkAll = !this.checkAll;
    if(this.checkAll){
      this.selectTotalNum = this.fileArr.length;
      this.isSelectMode = true;
    }else{
      this.isSelectMode = false;
      this.selectTotalNum = 0;
    }
    _.each(this.fileArr, element => {
      element.$$checked =  this.checkAll;
    });
    
    if (this.selectTotalNum > 0) {
      this.changeStyleObj = {
        'color': 'white',
        'background': '#93bd44'
      }
    } else {
      this.changeStyleObj = {
        'background':'#e5e5e5',
        'color': 'darkgray'
      }
    }
  }
  
  //文件选择操作并判断是否全选选中
  public changeSelectSingleStatus(item) {
    item.$$checked = !item.$$checked;
    const filecheck = _.filter(this.fileArr, (data: any) => {
      return data.$$checked;
    });
    if (filecheck.length > 0) {
      this.changeStyleObj = {
        'color': 'white',
        'background': '#93bd44'
      }
      this.selectTotalNum = filecheck.length;
      this.isSelectMode = true;
    } else {
      this.changeStyleObj = {
        'background':'#e5e5e5',
        'color': 'darkgray'
      }
      this.isSelectMode = false;
      this.selectTotalNum = 0;
    }
    if (filecheck.length == this.fileArr.length) {
      this.checkAll = true;
    } else {
      this.checkAll = false;
    }
  }
  
  public downLoadFile(item) {
    let uuid, name;
    if(this.isSelectMode){
      if(item){
        return;
      }
      const list = _.filter(this.fileArr, (data: any) => {
        return data.$$checked;
      });
      if (list.length == 0) {
        return this.notifyService.notify('error', '请选择需要下载的文件');
      }
      if (list.length > 1) {
        return;
      }
      uuid = list[0]['md5'];
      name = list[0]['resourceName'];
    } else {
      uuid = item['md5'];
      name = item['resourceName'];
    }
    name = this.cloudDriveService.b64EncodeUnicode(encodeURIComponent(name));
    const url = getHttpCDN(`/ray/resource/uuid/${uuid}/raw64/` + name);
    window.open(url, this.linkOpenType);
  }
  
  //文件加入回收站
  public deleteFile() {
    const idList  = _.filter(this.fileArr,(data: any)=>{
      return  data.$$checked;
    }).map(function(item){
      return item["id"];
    })
    
    if(idList.length == 0){
      return this.notifyService.notify('error', '请选择需要删除的文件');
    }
    
    this.loadingDrive.emit(true)
    this.cloudDriveService.microLessonRecycle(idList).pipe(
      flatMap(() => {
        return this.getListData('')
      }),
      finalize(() => this.loadingDrive.emit(false))
    ).subscribe(() => {}, (error) => {
      if(error.status == 400){
        this.notifyService.notify('error', '删除文件失败')
      }
    })
  }
  
  public myPagingFunction() {
    this.pageNum = Math.ceil(this.fileCount/this.cloudDriveService.pageSize);
    if(this.canAppend) {
      if(this.page + 1 < this.pageNum) {
        this.page++;
        this.canAppend = false;//防止请求异步导致多次append
        this.loadingDrive.emit(true)
        this.getListData('append').pipe(finalize(() => this.loadingDrive.emit(false))).subscribe();
      } else {
        return;
      }
    }
  }
  
  //改变查看模式
  public changeViewMode(type) {
    this.viewMode = type;
  }
  
  public sortByDate(source) {
    this.selectedSort = 'date';
    if (source !== 'refresh') {
      this.sortDateAsc = !this.sortDateAsc;
    }
    if (this.sortDateAsc) {
      this.sortType = 'dateDown'
    } else {
      this.sortType = 'dateUp'
    }
  
    this.loadingDrive.emit(true)
    this.getListData('').pipe(finalize(() => this.loadingDrive.emit(false))).subscribe()
  }
  
  public sortBySize(source) {
    this.selectedSort = 'size';
    if (source !== 'refresh') {
      this.sortSizeAsc = !this.sortSizeAsc;
    }
    if (this.sortSizeAsc) {
      this.sortType = 'sizeDown'
    } else {
      this.sortType = 'sizeUp'
    }
  
    this.loadingDrive.emit(true)
    this.getListData('').pipe(finalize(() => this.loadingDrive.emit(false))).subscribe()
  }
  
  public sortByName(source) {
    this.selectedSort = 'name';
    if (source !== 'refresh') {
      this.sortNameAsc = !this.sortNameAsc;
    }
    if (this.sortNameAsc) {
      this.sortType = 'nameDown'
    } else {
      this.sortType = 'nameUp'
    }
    
    this.loadingDrive.emit(true)
    this.getListData('').pipe(finalize(() => this.loadingDrive.emit(false))).subscribe()
  }
  
  public importPreResourceItem = () => {
    const list = _.filter(this.fileArr, (data: any) => {
      return data.$$checked;
    }).map(function (item: any) {
      return { resourceId: item.resourceId, resourceName: item.resourceName }
    });
    if (list.length == 0) {
      return this.notifyService.notify('error', '请选择导入的文件');
    }
    this.importRes.emit({ res: list, type:'myLesson'});
  }
  
  private getListData(type) {
    if(type != "append"){
      this.page = 0;
      this.checkAll = false;
      this.isSelectMode = false;
    }
    
    return this.cloudDriveService.microLesson(this.page, this.cloudDriveService.pageSize, this.sortType)
      .pipe(
        flatMap((res) => {
          if(type == "append"){
            this.fileArr =  this.fileArr.concat(res);
          }else{
            this.fileArr = res;
          }
  
          _.forEach(this.fileArr, (item: any) => {
            if(type == "append" && this.checkAll){
              if(this.isPrepareLesson){
                if(item.itemType != 'dir') {
                  item.$$checked = true;
                }
              } else {
                item.$$checked = true;
              }
            }else{
              item.$$checked = false;
            }
          });
  
          this.selectTotalNum = this.fileArr.filter(r=>{return r.$$checked}).length;
          return forkJoin(_.map(this.fileArr, (r: any) => {return this.resourceService.getResourceById(r.resourceId)}))
        }),
        flatMap((val) => {
          for (let index = 0; index < val.length; index++) {
            this.fileArr[index].size = this.cloudDriveService.bytesToSize(val[index].size);
            this.fileArr[index].originSize = val[index].size;
            this.fileArr[index].md5 = val[index].md5;
            this.fileArr[index].suffix = val[index].suffix
            this.fileArr[index].imageThumbHttpPath = val[index].imageThumbHttpPath;
          }
          this.canAppend = true;
          
          return of({})
        })
      )
  }
}
