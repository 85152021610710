import 'app2/directives/homework/checkStudentAnswer.directive';

(function() {

  angular.module('app.review')
    .controller('objStudentAnswerViewCtrl', objStudentAnswerViewCtrl);

  objStudentAnswerViewCtrl.$inject = ['$scope', '$window', 'data','$uibModalInstance'];
  function objStudentAnswerViewCtrl($scope, $window, data, $uibModalInstance) {

    $scope.curIdx = data.index || 0;
    $scope.allResources = data.allResources;
    $scope.close = function() {
      $uibModalInstance.close();
    };
    // 判断顶层窗口是否为iframe(配合桌面端使用)
    $scope.topWindowIsIframe = $window.top === $window.self;
    $scope.linkOpenType = $scope.topWindowIsIframe ? '_blank' : '_self';
 }
})();
