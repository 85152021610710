import angular = require('angular');
import * as _ from 'lodash';
import { TalSubjectDTO, TalVersionDTO, TalBookDTO } from 'app2/integration/talcloud/models'

require('assets/styles/course.css');
const {weikyueyiValidVersionNames} = require('app2/prepareCourse/cloud/utils/libraryUtils')

angular.module('app.directives.course.talAreaMaterialSelector', [])
  .component('talAreaMaterialSelector', {
    bindings: {
      onSubjectSelected: '&',
      selectedSubject: '<',
      currentVersion: '<',
      currentBook: '<',
      books: '<',
      /**
       * miniMode的意思是只选择学段和学科两个，非miniMode则选择学段学科版本册别一共四个
       */
      miniMode: '<',
      versions: '<',
      talAreaSubjects: '<',
      onMaterialSelected: '&',
      onSelectedMaterialData: '&',
      onSelectedLevel: '&',
      onSelectedSbj: '&',
      levels: '<',
    },
    controller: talAreaMaterialSelector,
    template: <any> require('./talAreaMaterialSelector.html'),
  })

talAreaMaterialSelector.$inject = ['$timeout', 'oedTeachingMaterialVersions', 'talcloudResource', '$q', 'notificationService']
function talAreaMaterialSelector($timeout: any, oedTeachingMaterialVersions: any, talcloudResource: any, $q: any, notificationService: any) {
  const ctrl = this;
  ctrl.isOpen = false
  ctrl.dynamicPopover = {template: 'popoverTemplateTal'}
  this.$onChanges = (changeObj: any) => {
    console.log('')
    if (!ctrl.currBook && ctrl.currentBook) {
      ctrl.currBook = ctrl.currentBook
    }
    if (!ctrl.currSbj && ctrl.selectedSubject) {
      ctrl.currSbj = ctrl.selectedSubject
      ctrl.onSbjClicked(ctrl.currSbj)
    }
    if (!ctrl.currVersion && ctrl.currentVersion) {
      ctrl.currVersion = ctrl.currentVersion
    }
    if (!ctrl.currLvl && !_.isEmpty(ctrl.levels)) {
      ctrl.currLvl = _.head(ctrl.levels)
    }
    if (ctrl.currSbj && ctrl.talAreaSubjects) {
      if (!_.find(ctrl.talAreaSubjects, s => s.id === ctrl.currSbj.id)) {
        ctrl.currSbj = _.head(ctrl.talAreaSubjects)
        ctrl.onSbjClicked(ctrl.currSbj)
      }
    }
    if (ctrl.currVersion && ctrl.versions) {
      if (!_.find(ctrl.versions, s => s.id === ctrl.currVersion.id)) {
        ctrl.currVersion = _.head(ctrl.versions)
        ctrl.onVersionClicked(ctrl.currVersion)
      }
    }

  };

  ctrl.onLevelClicked = (lvl) => {
    ctrl.currLvl = lvl
    ctrl.onSelectedLevel({level: lvl})
  }
  ctrl.confirm = () => {
    if (!ctrl.miniMode) {

      ctrl.onSelectedMaterialData({
        subj: ctrl.currSbj,
        item: {
          selectedBook: ctrl.currBook,
          selectedVersion: ctrl.currVersion,
        }
      })
    } else {
      ctrl.onSubjectSelected({
        sbj: ctrl.currSbj,
      })
    }
    ctrl.isOpen = false
  }

  ctrl.onStgClicked = (stg, init = false) => {
    ctrl.currLvl = stg
    ctrl.loading = talcloudResource.querySubjectByStage({
      stage: stg.stage,
    }).$promise.then((subjects: TalSubjectDTO[]) => {
      ctrl.subjects = subjects
      if (!init) {
        ctrl.currSbj = _.head(ctrl.subjects)
      }
      ctrl.queryVersions(init)
    })
  }
  ctrl.onSbjClicked = (sbj) => {
    ctrl.currSbj = sbj
    ctrl.versions = sbj.childs
    ctrl.currVersion = _.head(ctrl.versions)
    ctrl.onVersionClicked(ctrl.currVersion)
  }
  ctrl.onVersionClicked = (v) => {
    ctrl.currVersion = v
    ctrl.books = ctrl.currVersion.childs
    ctrl.currBook = _.head(ctrl.books)
  }
  ctrl.queryVersions = (init = false) => {
    talcloudResource.queryVersionsByStageAndSubject({
      stage: ctrl.currSbj.stage,
      subjectId: ctrl.currSbj.subjectId,
    }).$promise.then((versions: TalVersionDTO[]) => {
      ctrl.versions = versions
      if (!init) {
        ctrl.currVersion = _.head(versions)
      }
      ctrl.queryBooks(init)
    })
  }
  ctrl.queryBooks = (init = false) => {
    talcloudResource.getBooksByVersionId({
      versionId: ctrl.currVersion.versionId,
    }).$promise.then((books: TalBookDTO[]) => {
      ctrl.books = books
      if (!init) {
        ctrl.currBook = _.head(books)
      }
    })
  }
  ctrl.onBookClicked = (b) => {
    ctrl.currBook = b
  }
  ctrl.close = () => {
    ctrl.isOpen = false
  }
  ctrl.tryToOpen = () => {
    if (!ctrl.isOpen)
      ctrl.isOpen = true
    else
      ctrl.isOpen = false
  }
}
