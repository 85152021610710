import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {LessonDTO} from 'app/lesson/models/lesson';
import {NotificationService} from 'app/shared/notification/notification.service';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-train-dir-editor',
  template: require('./train-dir-editor.component.html'),
  styles: [require('./train-dir-editor.component.scss')]
})
export class TrainDirEditorComponent implements OnInit {

  @Input() public itemData
  @Input() public itemType
  @Input() public editType
  @Output() public action = new EventEmitter<any>()

  private title
  private dataClone
  constructor(public bsModalRef: BsModalRef,
              private notifyService: NotificationService,
              private onlineTrainService: OnlineTrainService) { }

  ngOnInit() {
    this.setTitle()
    this.setDataClone()
  }

  public close() {
    this.bsModalRef.hide()
  }

  public ok() {
    if (_.isEmpty(this.dataClone.name)) {
      this.notifyService.notify('info', '名字不能为空')
      return
    }

    this.action.emit(this.dataClone)
    this.bsModalRef.hide()
  }

  private setTitle() {
    let t = ''
    if (this.editType === this.onlineTrainService.ADD) {
      t += '新增'
    } else if (this.editType === this.onlineTrainService.UPDATE) {
      t += '更新'
    }

    if (this.itemType === this.onlineTrainService.DIR) {
      t += '目录'
    }
    this.title = t
  }

  private setDataClone() {
    this.dataClone = _.clone(this.itemData)
  }

}
