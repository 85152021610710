angular.module('app.examination.editObjectTestPaper.deletePart', [])
  .controller('deletePartCtrl', deletePartCtrl);

deletePartCtrl.$inject = ['$scope', '$uibModalInstance', 'data']
function deletePartCtrl($scope, $uibModalInstance, data) {
  $scope.type = data.question.name;
  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };
  $scope.delete = () => {
    $uibModalInstance.close({
      part: data,
    });
  }
}
