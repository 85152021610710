import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LegacyAppService } from './legacy-app.service'

@Injectable({
  providedIn: 'root'
})
export class OedNotifyService {

  private notifies: any
  constructor(public legacyAppService: LegacyAppService) {
    this.notifies = legacyAppService.getNotify()
  }

  public notify(message: string, classes: string) {
    this.notifies({
      message,
      classes,
    });
  }

}
