import * as testIconUtils from 'app2/utils/testIconUtils'
import * as _ from 'lodash'
const dateUtils: any = require('app2/utils/dateUtils')

angular.module('app.directives.review.objective.votingCompletionPreviewer', [])
  .component('votingCompletionPreviewer', {
    bindings: {
      endTime: '<',
      fromClassSession: '<',
      showOnlyTestName: '<',
      startTime: '<',
      testSessionId: '<',
      objTest: '<'
    },
    controller: votingCompletionPreviewerCtrl,
    template: <string> require('./votingCompletionPreviewer.html'),
  });

votingCompletionPreviewerCtrl.$inject = ['oedTestSession']
function votingCompletionPreviewerCtrl(oedTestSession: any) {
  const ctrl = this;

  this.$onInit = () => {
    ctrl.format = ctrl.fromClassSession ? 'HH:mm' : 'MM月dd号';
  }

  const loadData = () => {

    oedTestSession.getRemoteVotingStat({testSessionId: ctrl.testSessionId}).$promise
    .then((detail: any) => {
      if (ctrl.objTest) {
        ctrl.testName = ctrl.objTest.name
      }
      ctrl.totalStudentCount = _.size(detail.finishUsers) + _.size(detail.unFinishUsers) + _.size(detail.unLoginUsers)
      calcOnlineStdsCount(detail)
      calcSubmitRate(detail)
    }).catch((msg: any) => {
      console.log(msg);
    });
  }

  const calcOnlineStdsCount = (detail: any) => {
    ctrl.onlineStudentCount = _.size(detail.finishUsers) + _.size(detail.unFinishUsers)
  }

  const calcSubmitRate = (detail: any) => {
    ctrl.totalSubmit = _.size(detail.finishUsers)
    ctrl.submitRate = ctrl.totalSubmit * 1.0 / ctrl.totalStudentCount;
    ctrl.submitRateInPercentage = (ctrl.submitRate * 100).toFixed(0);
  }

  ctrl.isGoodTest = () => {
    return testIconUtils.isGoodTest(ctrl.submitRate);
  };

  ctrl.isBadTest = () => {
    return testIconUtils.isBadTest(ctrl.submitRate);
  };

  ctrl.getSummaryImage = (prefix: any) => {
    return testIconUtils.getSummaryImage(prefix, ctrl.submitRate);
  }

  ctrl.getSessionDuration = () => {
    return dateUtils.sessionDuration(ctrl.startTime, ctrl.endTime, ctrl.fromClassSession);
  };

  ctrl.$onChanges = (changeobj: any) => {
    if (_.has(changeobj, 'testSessionId')) {
      ctrl.testSessionId = changeobj.testSessionId.currentValue;
      if (!_.isUndefined(ctrl.testSessionId)) {
        loadData();
      }
    }
  }
}
