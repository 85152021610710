(function() {
  angular.module('app.prepareCourse')
    .controller('addSubjectiveTestDialogCtrl', addSubjectiveTestDialogCtrl);

  addSubjectiveTestDialogCtrl.$inject = ['$scope', '$q', '$uibModalInstance',
    'oedSubjectiveTest', 'courseId', 'existingTests']
  function addSubjectiveTestDialogCtrl($scope, $q, $uibModalInstance,
    oedSubjectiveTest, courseId, existingTests) {
    $scope.exam = {};
    $scope.existingNames = _.map(existingTests, 'name');
    $scope.maxSort = _.max(_.map(existingTests, 'sort'));
    if (isNaN($scope.maxSort)) {
      $scope.maxSort = 1;
    } else {
      $scope.maxSort = $scope.maxSort + 1;
    }
    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.addTestForm.$invalid) {
        return;
      }

      var test = new oedSubjectiveTest({
        name: $scope.exam.name,
        courseId: courseId,
        sort: $scope.maxSort
      });
      var p = test.$save();
      $uibModalInstance.close({
        test: test,
        promise: p
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
