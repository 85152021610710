require('assets/styles/review.scss');
import * as _ from 'lodash';
import { isQuickWayToCreatePrepareCourse } from 'app2/prepareCourse/utils/prepareCourseUtils'

angular.module('app.review.qObjectTestPreview', [
  'app.review.qObjectTestPreview'
])
.config(['$stateProvider', ($stateProvider: any) => {
  $stateProvider.state('objectiveTestSessions.session', {
    bodyStyle: 'bg_img',
    controller: 'objectiveTestSessionDetailsCtrl',
    template: <string> require('assets/templates/review/objectiveTestSessionDetails.html'),
    url: '/session/:sessionId',
  });
  $stateProvider.state('tmSessions.objSession', {
    bodyStyle: 'bged2',
    controller: 'objectiveTestSessionDetailsCtrl',
    template: require('assets/templates/review/objectiveTestSessionDetails.html'),
    url: '/session/:sessionId',
  });
}])
.controller('objectiveTestSessionDetailsCtrl', objectiveTestSessionDetailsCtrl);

objectiveTestSessionDetailsCtrl.$inject = ['$scope', 'oedTestSession', '$stateParams', '$q',
  'oedObjectiveTest', 'oedClassSession', '$uibModal', '$state']
function objectiveTestSessionDetailsCtrl(
  $scope: any, oedTestSession: any, $stateParams: any, $q: any,
  oedObjectiveTest: any, oedClassSession: any, $uibModal: any, $state: any) {
  const ctrl = this;

  $scope.extraInfo = ''
  $scope.currentState.title = '客观试卷'
  $scope.activeTabIndex = 2
  $scope.sessionId = $stateParams.sessionId;
  $scope.tabs = [{index: 0, text: '题目和解析'},
    {index: 1, text: '正确率'},
    {index: 2, text: '完成情况'},
    {index: 3, text: '统计表格'},
  ]

  $scope.testSession = oedTestSession.get({id: $stateParams.sessionId})
  $scope.classSession = oedClassSession.get({id: $stateParams.classSessionId})

  $scope.loading = $q.all([$scope.testSession.$promise, $scope.classSession.$promise])
    .then(() => {
      $scope.objTest = oedObjectiveTest.get({
        id: $scope.testSession.testId,
      });
      return $scope.objTest.$promise.then(() => {
        $scope.loaded = true;
        if ($scope.getTheWayWithCreateTest()) {
          $scope.childQuestionList = $scope.objTest.questions[0].childQuestions
          $scope.imageRes = $scope.objTest.questions[0]['resourceUUIDs']
        }

        // 是否为答题器答题 0否 1是
        if (!_.isElement($scope.testSession.sessionType)) {
          $scope.sessionType = $scope.testSession.sessionType
        }
        if ($scope.objTest.isTemp) {
          return oedObjectiveTest.getObjTestQuestionType({id: $scope.objTest.id}).$promise.then((r) => {
            if (r && r.testId && r.extraInfo === 'yesorno') {
              $scope.extraInfo = 'yesorno'
            } else {
              $scope.extraInfo = 'singlechoice'
            }
          });
        }
      })
    })

    $scope.getTheWayWithCreateTest = () => {
      return isQuickWayToCreatePrepareCourse($scope.objTest)
    }

  $scope.showDetailsForStudent = (std) => {
    std = std.isView ? std.std : std
    const modalInstance = $uibModal.open({
      controller: 'testSessionReviewForStudentDialogCtrl',
      resolve: {
        data: () => {
          return {
            sessionId: $scope.testSession.id,
            student: std,
            objTest: $scope.objTest,
          };
        },
      },
      size: 'lg',
      template: require('assets/templates/review/testSessionReviewForStudentDialog.html'),
      windowClass: 'ques-detail-model modalCenter disable-overflow',
    });

    modalInstance.result.then((dirty: any) => {
      if (dirty) {
        $state.reload();
      }
    });
  };
}
