import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RayApiService } from 'app/core/ray-api.service'
import { ArrayType } from '@angular/compiler';
import { LevelsAllDTO, SubjectsAllDTO, VersionsAllDTO, LgvsAllDTO, BookAllDTO } from '../manage/manage';

@Injectable({
  providedIn: 'root'
})
export class CoursePathService {

  constructor( private httpClient: HttpClient,
               private rayApi: RayApiService) { }

  public getAllLevels(): Observable<LevelsAllDTO[]> {
    return this.httpClient.get<any>(this.rayApi.apiB('coursepath/levels/all'));
  }
  public getAllSubjects(): Observable<SubjectsAllDTO[]> {
    return this.httpClient.get<any>(this.rayApi.apiB('coursepath/subjects/all'));
  }
  public getAllVersions(): Observable<VersionsAllDTO[]> {
    return this.httpClient.get<any>(this.rayApi.apiB('coursepath/versions/all'));
  }
  public getAllBooks(): Observable<BookAllDTO[]> {
    return this.httpClient.get<any>(this.rayApi.apiB('coursepath/books/all'));
  }

  public getAllLgvs(): Observable<LgvsAllDTO[]> {
    return this.httpClient.get<any>(this.rayApi.apiB('coursepath/lgvs/all'));
  }
}
