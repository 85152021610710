/**
 * Created by ryoushikaoru on 2019/3/4.
 */
import * as _ from 'lodash';

import Promise = require('bluebird');
const boardUtils: any = require('app2/utils/boardUtils');
const resourceUtils: any = require('app2/utils/resourceUtils');
require('app2/prepareCourse/cloud/subjectiveTest/subjectiveTestLibraryDialog.ctrl')
const {confirmAndDownloadSubjectiveTest, importSubjectTestModel} = require('app2/utils/testUtils2')
require('./subjectiveTestDetail.scss')

export const subjectiveTestDetail = {
    bindings: {
        courseId: '<',
        currentTest: '<',
        tests: '<',
        maxSort: '=',
        existingNames: '=',
        close: '&',
        setCurrentTest: '&',
        status: '<'
    },
    controller: subjectiveTestDetailCtrl,
    template: <string>require('./subjectiveTestDetail.html'),
}

angular.module('app.prepareCourse2.subjectiveTestDetail', [])
    .component('subjectiveTestDetail', subjectiveTestDetail);

subjectiveTestDetailCtrl.$inject = [
  '$scope',
  '$q',
  '$log',
  '$uibModal',
  '$timeout',
  'notify',
  'dialogs',
  'Upload',
  'Lightbox',
  'oedSubjectiveTest',
  'userInfo',
  'oedCourse',
  'oedCoursePath',
  'oedCloudQuestion',
  'oedFileUtils',
  'oedConfig',
  'oedMisc',
  'oedUnitItem',
  'localStorageService',
  'notificationService'
];
function subjectiveTestDetailCtrl(
  $scope,
  $q,
  $log,
  $uibModal,
  $timeout,
  notify,
  dialogs,
  Upload,
  Lightbox,
  oedSubjectiveTest,
  userInfo,
  oedCourse,
  oedCoursePath,
  oedCloudQuestion,
  oedFileUtils,
  oedConfig,
  oedMisc,
  oedUnitItem,
  localStorageService,
  notificationService
) {
    const ctrl = this;
    ctrl.showInput = false;
    ctrl.isExitName = false;
    ctrl.versionStamp = 0;
    ctrl.cancel = () =>  {
        ctrl.close();
    };
    ctrl.$onChanges = (changesObj: any) => {
        if (_.has(changesObj, 'courseId')) {
            if (ctrl.courseId) {
                ctrl.course = oedCourse.get({
                    id: ctrl.courseId
                });
                ctrl.tests = oedSubjectiveTest.queryByCourseIncEmpty({
                    course_id: ctrl.courseId
                });
                ctrl.courseSections = oedUnitItem.queryAvailableBySchoolAndCourse({
                    schoolId: 0,
                    courseId: ctrl.courseId
                });
            }
        }
        if (_.has(changesObj, 'currentTest')) {
            ctrl.isExitName = false;
            ctrl.nullName = false;
            if (ctrl.currentTest) {
                ctrl.getInfo();
            }
        }
        if (_.has(changesObj, 'status')) {
            ctrl.status = ctrl.status || {}
        }
    };
    ctrl.activeName = (e) => {
        ctrl.isExitName = false;
        ctrl.nullName = false;
        if (e.target.value.length > 40) {
            e.target.value = e.target.value.substr(0, 40);
        }
    };
    ctrl.nullName = false;
    ctrl.blurName = () => {
        ctrl.showInput = false;
        if (!ctrl.currentTest.name) {
            ctrl.nullName = true;
            return;
        } else {
            ctrl.nullName = false;
        }
        // if (localStorageService.get('currentName') !== ctrl.currentTest.name) {
        ctrl.saveTest(true, true);
        // }
    }
    $scope.$watch('$ctrl.showInput', (newVal, oldVal) => {
        if (newVal) {
            setTimeout(() => {
                document.getElementById('currentName').focus();
            })
        }
    });
    ctrl.editorConfig = {
        serverUrl: oedConfig.url('ceditor/Test/') + 0
    };
    ctrl.getInfo = () => {
        ctrl.status.loading = ctrl.loading = $q.all([ctrl.currentTest.$promise, ctrl.courseSections.$promise]);
        ctrl.currentTest.$promise.then((test) => {
            ctrl.originalTest = test;
            ctrl.layoutType.name = 'auto';
            ctrl.deserializePosition();
            ctrl.currentZindex = _(ctrl.currentTest.question.$resourcePosition).map((p) => {
                return _.parseInt(p.zindex);
            }).max();
        });
        ctrl.course.$promise.then((res: any) => {
            oedCoursePath.get({
                id: res.coursepathId
            }).$promise.then((c: any) => {
                oedUnitItem.queryByVersionSubjectIdAndGrade({
                    versionId: c.versionId,
                    subjectId: c.subjectId,
                    gradeId: c.gradeId
                }).$promise.then((l: any) => {
                    ctrl.levelId = l[0].child[0].levelId;
                    oedSubjectiveTest.getQuestionType({
                        subjectId: c.subjectId,
                        levelId: ctrl.levelId
                    }).$promise.then((q: any) => {
                        ctrl.questionTypes = q;
                        if(_.includes(JSON.stringify(ctrl.currentTest.question), 'questionTypeId') && ctrl.currentTest.question.questionTypeId) {
                            const selectQuestionType = _.filter(q, (item: any) => {
                                return ctrl.currentTest.question.questionTypeId === item.id;
                            });
                            ctrl.selectQuestionType = {
                                selected: selectQuestionType[0]
                            };
                            localStorageService.set('currentSelectQuestionType', ctrl.selectQuestionType.selected)
                        } else {
                            ctrl.selectQuestionType = {
                                selected: {
                                    typeDefaultDesc: '',
                                    id: null
                                }
                            };
                            localStorageService.set('currentSelectQuestionType', null)
                        }
                        if (!ctrl.currentTest.question.question) {
                            ctrl.currentTest.question.question = '<p><p>任务要求</p>' +
                                '<p>1.做一做，按照要求完成题目并上传。</p>' +
                                '<p>2.评一评，进入互评，查看同学们的作品。</p>' +
                                '<p>3.改一改，修改自己的答案，再次提交。</p></p>';
                        }
                        // ctrl.lastSelected = ctrl.selectQuestionType.selected;
                        // if (!ctrl.currentTest.question.question && ctrl.selectQuestionType.typeDefaultDesc) {
                        //     ctrl.currentTest.question.question = ctrl.selectQuestionType.typeDefaultDesc;
                        // }
                    });
                });
            });
        });

        ctrl.loading.then(() => {
            ctrl.sectionIdMap = {};

            function visitSection(sec) {
                if (sec.id) {
                    ctrl.sectionIdMap[sec.id] = sec;
                }
                if (sec.child) {
                    sec.child = _.sortBy(sec.child, ['sort']);
                    _.each(sec.child, visitSection);
                }
            }
            _.each(ctrl.courseSections, visitSection);

            ctrl.sectionTree.treeData = oedMisc.utils.convertSectionsToJsTreeModel(ctrl.sectionIdMap,
                ctrl.courseSections);
            ctrl.treeConfig.version++;
            _.each(ctrl.sectionTree.treeData, (data) => {
                data.state.opened = true;
            });
        });
        ctrl.onTreeReady = () => {
            if (!ctrl.currentTest.question) {
                return;
            }

            const sections = _.map(ctrl.currentTest.question.relatedSections,  (relSec: any) => {
                return relSec.sectionId;
            });
            ctrl.treeInstance.jstree(true).select_node(sections, true, true);
        };
        ctrl.selectQuestion = (item) => {
            // let dlg = dialogs.confirm('确定切换题型', '切换题型会导致当前编辑的任务卡内容丢失, 确定要切换吗?');
            // dlg.result.then((res: any) => {
            //     ctrl.selectQuestionType.selected = item;
            //     ctrl.currentTest.question.question = item.typeDefaultDesc;
            //     ctrl.lastSelected = ctrl.selectQuestionType.selected;
            // }, (error: any) => {
            //     ctrl.selectQuestionType.selected = ctrl.lastSelected;
            // });
            ctrl.selectQuestionType.selected = item;
        };

        ctrl.tooltipImg = '<p><span></span>如果只需要学生将答题步骤写在纸上拍照上传，白板底图可以为空；</p>' +
            '<p><span></span>如果需要学生给一段内容进行批注修改，可以直接把内容作为图片上传；</p>' +
            '<p><span></span>如果需要学生分类拼摆，可以上传多张图片</p>';

        const updateResourceIds = () => {
            const question = ctrl.currentTest.question;
            question.resourceIds = _.map(question.resources,  (res: any) => {
                return res.id;
            });

            //
            // 不再为问题保存resourceId
            question.resourceId = null;
            question.resource = null;
        };

        ctrl.sortableOptions = {
            start: (event) => {
                //
                // 开始拖动前记住每个res对应的positionDetails
                _.forEach(ctrl.currentTest.question.resources, (res, idx) => {
                    res.$posDetails = _.get(ctrl.currentTest.question, `$resourcePosition[${idx}]`);
                });
            },
            stop: (event) => {
                updateResourceIds();
                //
                // 结束后恢复positionDetails
                ctrl.currentTest.question.$resourcePosition = _.map(
                    ctrl.currentTest.question.resources,
                    (res) => res.$posDetails);
                //
                // 去掉临时数据
                ctrl.currentTest.question.resources = _.map(
                    ctrl.currentTest.question.resources,
                    (res) => _.omit(res, '$posDetails'));
                ctrl.currentTest.isSameAsOrig = false;
            }
        };

        ctrl.deserializePosition = () => {
            const tmpPosition = ctrl.currentTest.question.resourcePosition;
            if (!_.isEmpty(tmpPosition) && !_.isArray(tmpPosition)) {
                try {
                    ctrl.currentTest.question.$resourcePosition = JSON.parse(tmpPosition);
                    ctrl.layoutType.name = 'manual';
                } catch (e) {
                  notificationService.notify('error', '加载位置信息失败, 转换为自动布局!')
                  ctrl.currentTest.question.$resourcePosition = null;
                  ctrl.layoutType.name = 'auto';
                }
            }
        };
        ctrl.viewMode = 'file';
        //白板布局类型
        ctrl.layoutType = {};
        //图片图层的前缀，用来在directive中操作不同图层
        ctrl.boardImagePrefix = 'bmg';
        //图片的前缀
        ctrl.imagePrefix = 'zmg';
        //当前的图层zindex
        ctrl.currentZindex = 0;
        //用来跟踪图片加载完成数目
        ctrl.loadedCount = 0;
        //用来跟踪图片缩略图加载完成数目
        ctrl.thumbLoadedCount = 0;
        //操作后的图片，成为目前的焦点
        ctrl.lastActiveId = null;

        //第一次加载图片时，需要等所有图片加载完成后才能显示
        //用来绑定哪个tab是active的，白板图片清空时，应该默认选中附件模式
        ctrl.activeTabIndex = 0;

        //图片默认占画布的最大比例
        ctrl.maxScaleToBoard = 0.8;

        ctrl.canShowPreview = false;

        ctrl.setCanPreview = (status) => {
            ctrl.canShowPreview = status;
        };

        ctrl.boardClicked = (event) => {
            const isBase = angular.element(event.target).hasClass('white-board-bg');
            if (isBase) {
                ctrl.lastActiveId = null;
            }
        };
        ctrl.switchMode = (index) => {
            if (ctrl.activeTabIndex === index) {
                return;
            }
            ctrl.activeTabIndex = index;
            if (typeof ctrl.loadImagePosition === 'function' && !ctrl.isAutoLayout() && index === 0) {
                ctrl.loadImagePosition(true);
            }
            if (index === 0) {
                ctrl.viewMode = 'file';
            } else {
                ctrl.viewMode = 'edit';
            }
        };
        ctrl.isEditMode = function() {
            return ctrl.viewMode === 'edit';
        };
        ctrl.imageThumbLoaded = function(index) {
            ctrl.thumbLoadedCount = ctrl.thumbLoadedCount + 1;
        };

        //是否组自动布局
        ctrl.isAutoLayout = function() {
            return ctrl.layoutType.name === 'auto';
        };
        // TODO: convert types to an array to keep it's order
        ctrl.types = [
            {
                value: 'subjective_blankboard',
                displayName: '白板'
            }
        ];

        ctrl.treeConfig = {
            core: {
                multiple: true,
                animation: true,
                error: (error) => {
                    $log.error('treeCtrl: error from js tree - ' + angular.toJson(error));
                },
                check_callback: true,
                worker: true
            },
            types: {
                default: {
                    icon: 'fa fa-book'
                },
                root: {
                    icon: 'glyphicon glyphicon-cloud'
                }
            },
            version: 1,
            plugins: ['types', 'checkbox']
        };
        ctrl.sectionTree = {
            treeData: []
        };

        ctrl.uploading = 0;

        const uploadUrl = oedConfig.url('ceditor/uploadresource');

        ctrl.uploadFile = (files) => {
            if (_.isEmpty(files)) {
                return null;
            }

            const filesToUpload = _(files).each(function(f) {
                const validateResults = oedFileUtils.validateFileUpload(f, {
                    fileType: 'image'
                });
                f.validateResults = validateResults;
            }).filter((f) => {
                const info = _.keyBy(f.validateResults, 'level');
                return !_.has(info, 'error');
            });

            const results = _(files).map('validateResults').flatten().value();
            oedFileUtils.notifyValidationReporter(results);
            if (_.isEmpty(filesToUpload)) {
                return null;
            }

            ctrl.uploading += _.size(files);
            ctrl.uploadprogress = 0;
            const total = _.size(files);
            const progresses = _.fill([], 0, 0, _.size(files));
            const uploadFrom = ctrl.viewMode;

            ctrl.loading = $q.all(_.map(filesToUpload, (file, idx) => {
                return $q((resolve, reject) => {
                    Upload.upload({
                        url: uploadUrl,
                        file: file
                    }).progress((evt) => {
                        const progressPercentage = String(100.0 * evt.loaded / evt.total);
                        progresses[idx] = progressPercentage;

                        ctrl.uploadprogress = String(_.sum(progresses) / total);
                    }).success((data, status, headers, config) => {
                        resolve(data);
                    }).error(() => {
                      notificationService.notify('error', '文件上传失败!')
                      resolve(null);
                    }).finally(() => {
                        ctrl.uploading--;
                    });
                });
            })).then((datas: any) => _.filter(datas, (d: any) => !!d))
                .then(computeFilesLayout).then((fileAndPoses: any) => {
                    const question = ctrl.currentTest.question;
                    question.resources = question.resources || [];

                    question.resources = question.resources.concat(_.map(fileAndPoses, 'file'));
                    const maxZIndex = _(question.$resourcePosition).map('zindex').max() || 0;

                    const defaultPos = {
                        isFixedPosition: 0
                    };

                    const newPositions = _.map(fileAndPoses, (v, idx) => _.assign({}, defaultPos, {
                        zindex: maxZIndex + idx
                    }, v.pos));
                    question.$resourcePosition = _.concat(question.$resourcePosition || [], newPositions);

                    updateResourceIds();
                    ctrl.currentTest.isSameAsOrig = false;
                });

            return ctrl.loading;
        };

        function computeFilesLayout(files) {
            if (ctrl.isAutoLayout()) {
                return Promise.resolve(_.map(files, (f: any) => ({
                    file: f
                })));
            }

            return loadImageAndCenter(files);
        }

        function loadImageAndCenter(files) {
            const imgUrls = _.map(files, resourceUtils['getResourceSrc']);

            return resourceUtils.loadImagesByUrls(imgUrls)
                .then((imgs: any) => _.map(imgs, (img: any) => computeCenterPosInfo(img.width, img.height)))
                .then((allPoses: any) => _.zipWith(files, allPoses, (file, pos) => ({
                    file, pos
                })));
        }

        function computeCenterPosInfo(width, height) {
            return boardUtils.scaleAndCenter(width, height);
        }

        ctrl.removeResource = (idx) => {
            const dlg = dialogs.confirm('确定删除?', '确定要删除当前图片吗');
            dlg.result.then((btn) => {
                ctrl.loadedCount = ctrl.loadedCount - 1;
                ctrl.currentTest.question.resources.splice(idx, 1);
                if (ctrl.currentTest.question.resources.length === 0) {
                    ctrl.viewMode = 'file';
                    ctrl.activeTabIndex = 0;
                    ctrl.currentTest.question.$resourcePosition = null;
                }
                if (!_.isEmpty(ctrl.currentTest.question.$resourcePosition)) {
                    ctrl.currentTest.question.$resourcePosition.splice(idx, 1);
                }
                updateResourceIds();
            }, (btn) => {});
        };

        ctrl.toggleShowAnswerDetails = function() {
            ctrl.showAnswerDetails = !ctrl.shouldShowAnswerDetails();
            if (!ctrl.showAnswerDetails) {
                ctrl.currentTest.question.answerDetails = '';
            }
        };

        ctrl.onAnswerTypeChanged = function() {
            if (ctrl.answerType !== 'subjective_blankboard') {
                ctrl.removeRes();
            }
        };

        ctrl.shouldShowAnswerDetails = function() {
            if (!ctrl.currentTest || !ctrl.currentTest.question) {
                return false;
            }

            return ctrl.showAnswerDetails || ctrl.currentTest.question.answerDetails;
        };

        ctrl.clearQuestion = ($event) => {
            $event.stopPropagation();
            $event.preventDefault();
            const dlg = dialogs.confirm('确定清除?', '清除后不可撤销，确定要清除吗？');
            dlg.result.then((btn) => {
                ctrl.showAnswerDetails = false;
                _.assign(ctrl.currentTest, {
                    question: {
                        question: '',
                        evaluateType: 'point',
                        relatedSections: ctrl.course.relatedSections,
                        point2: 10,
                        type: 'subjective_blankboard',
                        answerDetails: '',
                        resource: null,
                        resourceId: null
                    }
                });
                ctrl.loadedCount = 0;
                ctrl.viewMode = 'file';
                ctrl.currentTest.question.$resourcePosition = null;
                ctrl.currentTest.question.resourcePosition = null;
                ctrl.currentTest.question.questionTypeId = null;
                // ctrl.currentTest.question.question = '';
                ctrl.currentTest.question.question = '<p><p>任务要求</p>' +
                    '<p>1.做一做，按照要求完成题目并上传。</p>' +
                    '<p>2.评一评，进入互评，查看同学们的作品。</p>' +
                    '<p>3.改一改，修改自己的答案，再次提交。</p></p>';
                ctrl.selectQuestionType.selected = {
                    typeDefaultDesc: '',
                    id: null
                };
                const currentName = localStorageService.get('currentName');
                if (!ctrl.currentTest.name) {
                    ctrl.currentTest.name = currentName;
                }
                localStorageService.set('currentSelectQuestionType', null);

                ctrl.activeTabIndex = 0;
                resetSection();
                ctrl.saveTest(true, true);
            }, (btn) => {});
        };

        function resetSection() {
            const sections = _.map(ctrl.course.relatedSections, (relSec: any) => {
                return relSec.sectionId;
            });
            ctrl.treeInstance.jstree(true).deselect_all(true);
            ctrl.treeInstance.jstree(true).select_node(sections, true, true);
        }

        ctrl.saveTest = (shouldNotify, updateSameAsOrigin, useDefaultSections) => {
            ctrl.versionStamp++;
            ctrl.loading = $timeout(() => {
                return ctrl.saveTestWithContent(shouldNotify, updateSameAsOrigin, useDefaultSections);
            });

            return ctrl.loading;
        };

        ctrl.saveTestWithContent = (shouldNotify, updateSameAsOrigin, useDefaultSections) => {
            const currentName = localStorageService.get('currentName');
            if (!ctrl.currentTest.name) {
                return;
            }
            if (ctrl.existingNames.indexOf(ctrl.currentTest.name) > -1 && ctrl.currentTest.name !== currentName) {
                ctrl.isExitName = true;
                ctrl.currentTest.name = currentName;
                return;
            } else {
                ctrl.isExitName = false;
            }
            const curQuestionIndex = _.indexOf(ctrl.currentTest.questions, ctrl.currentQuestion);
            ctrl.currentTest.question.questionTypeId = ctrl.selectQuestionType.selected ? ctrl.selectQuestionType.selected.id : null;
            if (_.isEmpty(ctrl.currentTest.question.relatedSections)) {
                if (useDefaultSections) {
                    ctrl.currentTest.question.relatedSections = ctrl.course.relatedSections;
                    resetSection();
                } else {
                    dialogs.error('无法保存', '当前题目没有关联章节, 请关联章节后再保存');
                    return;
                }
            }
            if (ctrl.isAutoLayout()){
                ctrl.currentTest.question.resourcePosition = null;
            } else {
                const pos = _.filter(ctrl.currentTest.question.$resourcePosition);
                if (_.size(pos) !== _.size(ctrl.currentTest.question.$resourcePosition)) {
                    ctrl.currentTest.question.$resourcePosition = pos
                }
                if (!_.isEmpty(pos)) {
                    const resSize = _.size(ctrl.currentTest.question.resources);
                    if (resSize < _.size(pos)) {
                        ctrl.currentTest.question.$resourcePosition = _.slice(pos, 0, resSize);
                    }
                }
                ctrl.currentTest.question.resourcePosition = JSON.stringify(ctrl.currentTest.question.$resourcePosition);
            }
            const newTest = angular.copy(ctrl.currentTest);
            const currentSelectQuestionType = localStorageService.get('currentSelectQuestionType');

            newTest.question = _.omit(newTest.question, ['resource', 'resources']);

            if (updateSameAsOrigin && newTest.isSameAsOrig) {
                newTest.isSameAsOrig = angular.equals(ctrl.currentTest, ctrl.originalTest);
            }
            if (currentSelectQuestionType) {
                newTest.isSameAsOrig = newTest.isSameAsOrig && (ctrl.currentTest.question.questionTypeId === currentSelectQuestionType.id) && (ctrl.selectQuestionType.selected.typeDefaultDesc === currentSelectQuestionType.typeDefaultDesc);
            }
            // if (newTest.isSameAsOrig) {
            //     return;
            // }
            const notifySec = shouldNotify ? notificationService : () => {};

            ctrl.status.loading = ctrl.loading = newTest.$save()
                .then((test) => {
                    ctrl.dirty = false;
                    notificationService.notify('info', '活动保存成功')
                    ctrl.tests = oedSubjectiveTest.queryByCourseIncEmpty({
                        course_id: ctrl.courseId
                    });
                    ctrl.tests.$promise.then((res: any) => {
                        ctrl.existingNames = _.map(res, 'name');
                        ctrl.maxSort = _.max(_.map(res, 'sort'));
                    });
                    ctrl.setCurrentTest({t: ctrl.currentTest,  test: ctrl.tests});
                    if (!_.isEmpty(ctrl.currentTest.question.resourcePosition)) {
                        test.question.$resourcePosition = JSON.parse(test.question.resourcePosition);
                        ctrl.currentZindex = _(test.question.$resourcePosition).map((p) => {
                            return p.zindex;
                        }).max();
                        ctrl.layoutType.name = 'manual';
                    } else {
                        ctrl.layoutType.name = 'auto';
                    }
                    ctrl.loadedCount = 0;
                    ctrl.currentTest = test;
                    ctrl.originalTest = angular.copy(test);
                }).catch((error) => {
                    const errCode = _.get(error, 'status', 500);
                    if (errCode === 401 || errCode === 403) {
                      return notifySec.notify('error', '您的登录状态已改变，请重新登录!')
                    }
                    notifySec.notify('error', '活动保存失败，请联系锐学堂技术支持!')
                });

            return ctrl.loading;
        };

        ctrl.onSelChanged = () => {
            const selectedNodes = ctrl.treeInstance.jstree(true).get_selected();
            const curIds = _.map(ctrl.currentTest.question.relatedSections, function(sec) {
                return sec.sectionId;
            });

            if (_(curIds).xor(selectedNodes).isEmpty()) {
                return;
            }

            ctrl.currentTest.question.relatedSections = _.map(selectedNodes, function(node) {
                const sec = ctrl.sectionIdMap[node];

                return {
                    sectionId: sec.id,
                    sectionName: sec.name
                };
            });
        };

        ctrl.preventDropdownDefault = (event) => {
            event.preventDefault();
            event.stopPropagation();
        };

        ctrl.doImportTest = (res, deferred) => {
            confirmAndDownloadSubjectiveTest(res, deferred, {
                dialogs, oedCloudQuestion, oedSubjectiveTest,
            }).then((subTestAndCq) => {
                const {cloudQuestion, subTest} = subTestAndCq
                const newTest = importSubjectTestModel(ctrl.currentTest, subTest, cloudQuestion)
                if (_.isEmpty(subTest.question.positionDetails)) {
                    ctrl.layoutType.name = 'auto';
                } else {
                    ctrl.layoutType.name = 'manual';
                }

                if (_.isEmpty(ctrl.currentTest.question.resources)) {
                    ctrl.switchMode(0);
                }

                ctrl.saveTest(false, false, true).then(() => {
                    ctrl.dirty = false;
                    deferred.resolve(1);
                });
            }).catch((m) => {
                deferred.reject(m);
            });

            return deferred.promise;
        }
        ctrl.importFromLibrary = () => {
            const modalInstance = $uibModal.open({
                template: require('app2/prepareCourse/cloud/subjectiveTest/subjectiveTestLibraryDialog.html'),
                controller: 'subjectiveTestLibraryDialogCtrl',
                controllerAs: '$ctrl',
                size: 'full-screen',
                windowClass: 'shouldHideOnPreview cl-library-dialog2',
                resolve: {
                    data: () => ({
                        course: ctrl.course,
                        doImport: (res, deferred) => {
                            return ctrl.doImportTest([res], deferred)
                        },
                    })
                },
            });
        };
        ctrl.previewResource = (reses, idx) => {
            const resWithName = _.map(reses, (res, i) => {
                return _.defaults({}, res, {
                    resourceName: '第' + (i + 1) + '张'
                });
            });
            Lightbox.openModal(resWithName, idx);
        };

        ctrl.showShowCloudTestNo = function() {
            return !ctrl.isEmptyResource(ctrl.currentTest) && _.isNumber(ctrl.currentCloudTestId);
        };

        ctrl.isEmptyResource = (res) => {
            if (!res) {
                return true;
            }

            return _.get(res, 'isEmpty', false) ||
                (_.isEmpty(_.get(res, 'question.question')) &&
                    _.isEmpty(_.get(res, 'question.resources')));
        };

        ctrl.dirty = false;
        ctrl.layoutChange = () => ctrl.dirty = true;

        ctrl.onDisableAutoLayout = (positions) => {
            ctrl.layoutType.name = 'manual';
            ctrl.currentTest.question.$resourcePosition = positions;
            ctrl.dirty = true;
        };

        ctrl.onPositionsChange = (positions) => {
            ctrl.currentTest.question.$resourcePosition = positions;
            ctrl.dirty = true;
        };

        ctrl.onResourcesChange = (resources, positions) => {
            ctrl.dirty = true;
            ctrl.currentTest.question.resources = resources;
            ctrl.currentTest.question.$resourcePosition = positions;
            updateResourceIds();
            if (_.isEmpty(resources)) {
                ctrl.switchMode(0);
                ctrl.switchMode(0);
            }
        };

        ctrl.onConfirmRemoveRes =  (idx)  => dialogs.confirm('确定删除?', '确定要删除当前图片吗').result;

        ctrl.handlePaste = (e) => {
            const images = []
            for (let i = 0 ; i < e.originalEvent.clipboardData.items.length ; i++) {
                const item = e.originalEvent.clipboardData.items[i];
                if (item.type.indexOf('image') !== -1) {
                    images.push(item.getAsFile())
                }
            }
            if (!_.isEmpty(images)) {
                ctrl.uploadFile(images)
            }
        }

        ctrl.sortableOptions = {
            axis: 'y',
            stop: (event) => {
                const newOrders = _.map(ctrl.tests, (m, index) => {
                    return {
                        id: m.id,
                        sort: index
                    };
                });
                ctrl.loading = oedCourse.updateTestOrder(newOrders).$promise.then().catch(() => {
                  notificationService.notify('error', '保存失败')
                });
            }
        };
    };
    ctrl.deleteTest = ($event) => {
        $event.stopPropagation();
        $event.preventDefault();
        const dlg = dialogs.confirm('确定删除?', '确定要删除主观活动: "' + ctrl.currentTest.name + '"吗');
        dlg.result.then((btn) => {
            ctrl.loading = $q((resolve, reject) => {
                ctrl.currentTest.$delete(() => {
                    if (ctrl.isDone) {return}
                    ctrl.tests = oedSubjectiveTest.queryByCourseIncEmpty({
                        course_id: ctrl.courseId
                    });
                    notificationService.notify('info', '删除活动成功')
                    ctrl.tests.$promise.then((res: any) => {
                        if (res.length) {
                            ctrl.setCurrentTest({t: ctrl.tests[0],  test: ctrl.tests});
                        } else {
                            const test = new oedSubjectiveTest({
                                name: '主观活动1',
                                courseId: ctrl.courseId,
                                sort: ctrl.maxSort ? ctrl.maxSort : 1
                            });
                            test.$save().then((r: any) => {
                              notificationService.notify('info', '新建活动成功')
                                const newTest = r;
                                ctrl.tests.push(newTest);
                                ctrl.setCurrentTest({t: ctrl.tests[0],  test: ctrl.tests});
                            });
                        }
                    });
                    resolve();
                }, () => {
                    reject();
                });
            });
        }, (btn) => {});
    };
}
