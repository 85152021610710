
import _ = require('lodash');

export const standardBoardWidth = 1280; // 标准白板宽度
export const standardBoardHeight = 752; // 标准白板的高度
export const maxScaleToBoard = 0.8; // 一张图片自动布局时此图片相对标准白板的最大比例

export function scaleAndCenter(originWidth: number, originHeight: number) {
  const geometry = calcImageGeometry(maxScaleToBoard, {
    originHeight, originWidth,
  });

  return calcCenterPosInfo(geometry);
}

//
// 注意Geometry的计算都基于标准尺寸
export function calcImageGeometry(scale: number, opt: any) {
  const {originHeight, originWidth} = opt;
  const {width, height} = limitMaxBoardSize({height: originHeight, width: originWidth});
  const rateX = width / (standardBoardWidth * scale);
  const rateY = height / (standardBoardHeight * scale);
  const rate = rateX > rateY ? rateX : rateY;

  if (rate > 1) {
    return {
      height: height / rate,
      width: width / rate,
    };
  }

  return {height, width};
}

export function calcCenterPosInfo(opt: any) {
  const {height, width} = opt;
  const x = (standardBoardWidth - width) / 2;
  const y = (standardBoardHeight - height) / 2;
  return getPosInfoByGeometry(opt, {x, y});
}

export function limitMaxBoardSize(geom: any) {
  return limitMaxSize(geom, {
    height: standardBoardHeight,
    width: standardBoardWidth,
  });
}

export function limitMaxSize(curSize: any, maxSize: any) {
  const {width, height} = curSize;
  let targetWidth = _.min([width, maxSize.width]);
  let targetHeight = height * (targetWidth / width);
  if (targetHeight > maxSize.height) {
    targetHeight = maxSize.height;
    targetWidth = width * (targetHeight / height);
  }
  return {
    height: targetHeight,
    width: targetWidth,
  };
}

export function getPosInfoByGeometry(geom: any, leftTop: any) {
  const {width, height} = geom;
  const {x, y} = leftTop;
  return {
    ax: x, ay: y,
    bx: x + width, by: y,
    cx: x, cy: y + height,
    zx: x + width, zy: y + height,
  };
}
