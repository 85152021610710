import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LegacyAppService } from 'app/core/legacy-app.service';
import { Subject } from 'rxjs';

import * as _ from 'lodash'
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-section-names',
  template: require('./section-names.component.html'),
  styles: [require('./section-names.component.scss')]
})
export class SectionNamesComponent implements OnInit, OnChanges {
  @Input() enableTooltip: boolean
  @Input() emptyText: string
  @Input() sections

  sectionsSubject = new Subject<any>()
  sectionName$ = null

  courseUtils = null

  name = ''

  constructor(legacyApp: LegacyAppService) {
    this.courseUtils = legacyApp.getCourseUtils()

    this.sectionName$ = this.sectionsSubject.asObservable().pipe(switchMap((sections) => {
      const secIds = _.map(sections, 'sectionId');
      return this.courseUtils.loadSectionNamesById(secIds).catch((e) => {
        console.log('failed load sections', e)
        return '加载失败'
      }) as Promise<string>
    })).subscribe(name => {
      this.name = name
    })
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'sections')) {
      this.sectionsSubject.next(changes.sections.currentValue)
    }
  }

  ngOnDestroy() {
    this.sectionName$.unsubscribe()
  }

  getText() {
    return this.name || this.emptyText || ''
  }

  hasTooltip() {
    return _.isUndefined(this.enableTooltip) ? true : this.enableTooltip;
  }
}
