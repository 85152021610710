import * as _ from 'lodash'
import Promise = require('bluebird');
require('assets/app/controllers/examination/examEdit/paper/examEditPaper.scss')
require('assets/app/controllers/examination/examSummary/examSummaryCtrl');
require('assets/app/controllers/examination/examEdit/paper/examQuesPreviewer/examQuesPreviewerCtrl');
require('assets/app2/prepareCourse/cloud/objectiveTestLibrary.ctrl');
require('assets/app/controllers/examination/examEdit/paper/examSyncQuesPreviewer/examSyncQuesPreviewerCtrl');
import { openExamConfirmDialog, getExamQuesTotal, checkExamIsSaved, sortExamPartDetails, getQuesNum, geSyncChildPointArray } from './paperUtils'
import { getQuesKpByPartAndQuesIdx } from "app2/integration/ruanyun/ruanyunUtils";
import { createDefaultQuestion, checkNewQuesIsValid, checkExamInEditting, generateLocalQuestion, geneQuesUuid } from '../../examUtils';
import { v4 as uuidv4 } from 'uuid'

angular.module('app.examination.examEdit.paper', [
  'app.examination.examEdit.paper.quesPreviewer',
  'app.prepareCourse2.cloud.objectiveTestLibrary',
  'app.examination.examEdit.syncQuesPreviewer',
])
  .component('examEditPaper', {
    bindings: {
      examData: '<',
      updateExamData: '&',
      saveExamTest: '&',
      updateVisibleLetters: '&',
      allKnowledgePoints: '<',
      updateCurExam: '&',
      ruanYunCourseMappingId: '<',
      ruanYunCourseId: '<',
      onExamIsUpdate: '&',
      isExamUpdate: '<',
      sectionParentName: '<',
      coursepathId: '<',
      sectionId: '<',
      sectionParentId: '<',
      sectionRootId: '<',
      isCanEdit: '<',
      hideAnalyze: '<',
    },
    controller: examEditPaperCtrl,
    template: <string>require('./examEditPaper.html'),
  })

enum QuestionMove { Up, Down }

examEditPaperCtrl.$inject = ['$scope', '$uibModal', 'notificationService', 'oedExamination',
'ruanyunResource', '$location', '$anchorScroll', 'examApi', 'localStorageService']
function examEditPaperCtrl($scope, $uibModal, notificationService, oedExamination,
  ruanyunResource, $location, $anchorScroll, examApi, localStorageService) {

  const ctrl = this
  let listView;

  ctrl.partIndex = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十二', '十三', '十四', '十五']
  ctrl.usedQIds = []
  ctrl.qIdsInExam = []

  ctrl.$onChanges = (changeObj: any) => {
    if (_.has(changeObj, 'examData')) {
        ctrl.reloadExamData()
    }
  }

  ctrl.reloadExamData = () => {
    ctrl.showPaper = !_.isEmpty(ctrl.examData)
    ctrl.examWithTags = _.get(ctrl.examData, 'examWithTags', {})
    ctrl.examPartDetails = _.get(ctrl.examData, 'examPartDetails', {})
    if (!_.isEmpty(ctrl.examData)) {
      sortExamPartDetails(ctrl.examPartDetails)
    }
    ctrl.isSaved = checkExamIsSaved(ctrl.examData)
    const qDetails = _.map(ctrl.examPartDetails, `questionDetails`)
    const qList = _.map(_.flatten(qDetails), 'question')
    // usedQIds, 超过50个时，每次从队列最前面移除一个id
    if (_.size(ctrl.usedQIds) > 50) {
      ctrl.usedQIds.shift()
    }
    ctrl.qIdsInExam = _.map(qList, 'id')
    if (ctrl.showPaper) {
      setTimeout(() => {
        listView = document.getElementById('paper_content');
        ctrl.initScroll()
      }, 500)
    }
  }

  ctrl.addQuestion = (part, index) => {
    const size = _.size(part.questionDetails)

    const lq = _.last(part.questionDetails)

    if (size != 0 && !checkNewQuesIsValid(_.get(lq, 'question', {}), notificationService)) {
      setTimeout(() => {
        ctrl.scrollTo(index, size - 1)
      })
      return
    }

    const q = createDefaultQuestion(localStorageService)
    
    const maxSortQues = _.maxBy(part.questionDetails, 'sort')

    const partQues = {
      question: q,
      questionTags: [],
      relatedOutlines: [],
      sort: _.get(maxSortQues, 'sort', 0) + 1,
    }
    part = {
      ...part,
      questionDetails: [
        ..._.get(part, 'questionDetails', []),
        partQues,
      ]
    }
    const partDetails = ctrl.examData.examPartDetails
    partDetails[index] = part
    ctrl.examData = {
      ...ctrl.examData,
      examPartDetails: [
        ...partDetails,
      ]
    }
    ctrl.edittingUUID = _.get(q, 'uuid', uuidv4())
    ctrl.forceUpdateExamData()
    setTimeout(() => {
      ctrl.scrollTo(index, size)
    })
  }

  ctrl.changeEditingUuid = (uuid) => {
    ctrl.edittingUUID = uuid
  }

  ctrl.forceUpdateExamData = () => {
    ctrl.updateExamData({ examData: {
      ...ctrl.examData,
    } })
    ctrl.onExamIsUpdate({ update: true })
  }

  ctrl.refreshExamFromServer = () => {
    ctrl.updateCurExam({ id: _.get(ctrl.examWithTags, 'exam.id', '')})
  }

  ctrl.scrollTo = (partIndex, qIndex) => {
    const doc = document.getElementById(`anchor_${partIndex}_${qIndex}`)
    if (doc) {
      setTimeout(() => {
        doc.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start'
        })
      }, 15)
    }
  } 

  ctrl.updateExamWithTags = (name: string, duration: number, deferred: any) => {
    const newExam = {
      ..._.get(ctrl.examWithTags, 'exam', {}),
      name: name,
      duration: duration
    }
    if (!ctrl.isSaved) {
      updateExamWithExam(newExam)
      deferred.resolve()
      return
    }
    const id = _.get(newExam, 'id', '')
    $scope.loading = oedExamination.updateExamInfo({ id }, newExam).$promise
      .then(() => {
        updateExamWithExam(newExam)
        deferred.resolve()
        notificationService.notify('info', '修改成功')
      }).catch((e) => {
        const message = _.get(e, 'data.message', '')
        if (message === 'exam/nameExists') {
          notificationService.notify('error', '已经创建的试卷里有同名试卷，请修改。')
          return
        }
        deferred.reject()
        notificationService.notify('error', '更改试卷信息出错，请稍后再试')
      })
  }

  ctrl.handleDelete = (partId) => {
    if (partId) {
      return examApi.deleteExamPart({
        id: partId
      }).$promise
    } else {
      return Promise.resolve()
    }
  }

  ctrl.onDelPart = (part, index) => {
    openExamConfirmDialog($uibModal, '删除该大题后不可恢复，是否确定删除？', '删除', '确定', '取消', () => {
      ctrl.handleDelete(_.get(part, 'part.id', null)).then(() => {
        ctrl.examData.examPartDetails.splice(index, 1)
        ctrl.examData = {
          ...ctrl.examData,
        }
        ctrl.updateExamData({ examData: ctrl.examData })
        ctrl.onExamIsUpdate({ update: true })
      })
    })

  }

  function updateExamWithExam(newExam: any) {
    const newExamWithTags = {
      ...ctrl.examWithTags,
      exam: newExam
    }
    const newExamData = {
      ...ctrl.examData,
      examWithTags: newExamWithTags
    }
    ctrl.updateExamData({ examData: newExamData })
    ctrl.onExamIsUpdate({ update: true })
  }

  function updateQuesDetails(questionDetails: any[], partIdx: number, quesIdx: number, move: number) {
    const curQuesSort = _.get(questionDetails, `${quesIdx}.sort`, -1)
    if (move == QuestionMove.Up) {
      _.set(questionDetails, `${quesIdx}.sort`, curQuesSort - 1)
      _.set(questionDetails, `${quesIdx - 1}.sort`, curQuesSort)
    }
    if (move == QuestionMove.Down) {
      _.set(questionDetails, `${quesIdx}.sort`, curQuesSort + 1)
      _.set(questionDetails, `${quesIdx + 1}.sort`, curQuesSort)
    }
    const orderQuestionDetails = _.sortBy(questionDetails, qd => _.get(qd, 'sort', ''))
    const newPartDetails = _.set(ctrl.examPartDetails, `${partIdx}.questionDetails`, orderQuestionDetails)
    updateExamDataByNewPartDetails(newPartDetails)
  }

  function updatePartDetails(partIdx: number, move: number) {
    const curPartSort = _.get(ctrl.examPartDetails, `${partIdx}.part.sort`)
    if (move == QuestionMove.Up) {
      _.set(ctrl.examPartDetails, `${partIdx}.part.sort`, curPartSort - 1)
      _.set(ctrl.examPartDetails, `${partIdx - 1}.part.sort`, curPartSort)
    }
    if (move == QuestionMove.Down) {
      _.set(ctrl.examPartDetails, `${partIdx}.part.sort`, curPartSort + 1)
      _.set(ctrl.examPartDetails, `${partIdx + 1}.part.sort`, curPartSort)
    }
    const orderPartDetails = _.sortBy(ctrl.examPartDetails, pd => _.get(pd, 'part.sort', ''))
    updateExamDataByNewPartDetails(orderPartDetails)
  }

  ctrl.childQuesMove = (partIdx: number, quesIdx: number, move: number) => {
    event.stopPropagation();
    const partDetail = _.get(ctrl.examPartDetails, `${partIdx}`, {})
    const questionDetails = _.get(partDetail, 'questionDetails', [])
    if (_.size(questionDetails) <= 0) {
      notificationService.notify('error', '移动题目出错，请稍后再试')
      return
    }
    if (!ctrl.isSaved) {
      updateQuesDetails(questionDetails, partIdx, quesIdx, move)
      return
    }
    const requestData = getCurrentRequestData(questionDetails, quesIdx, move, 'question')
    const nextRequestData = getNextRequestData(questionDetails, quesIdx, move, 'question')
    const data = _.filter([
      ...requestData,
      ...nextRequestData,
    ], (d) => !_.isEmpty(_.toString(d.id)))
    const partId = _.get(partDetail, 'part.id', '')
    $scope.loading = oedExamination.updateQuestionOrders({ partId: partId }, data).$promise.then(() => {
      updateQuesDetails(questionDetails, partIdx, quesIdx, move)
      notificationService.notify('info', '移动题目成功')
    }).catch(() => {
      notificationService.notify('error', '移动题目出错，请稍后再试')
    });
  }

  function getCurrentRequestData(data: any[], idx: number, move: number, tag: string) {
    const curId = _.get(data, `${idx}.${tag}.id`, '')
    let sort = -1
    if (move == QuestionMove.Up) {
      sort = idx - 1
    } else if (move == QuestionMove.Down) {
      sort = idx + 1
    }
    return [{
      "id": curId,
      "sort": sort,
    }]
  }

  function getNextRequestData(data: any[], idx: number, move: number, tag: string) {
    let curId = -1
    const sort = idx
    if (move == QuestionMove.Up) {
      curId = _.get(data, `${idx - 1}.${tag}.id`, 0)
    } else if (move == QuestionMove.Down) {
      curId = _.get(data, `${idx + 1}.${tag}.id`, 0)
    }
    return [{
      "id": curId,
      "sort": sort,
    }]
  }

  ctrl.partMove = (partIdx: number, move: number) => {
    event.stopPropagation();
    if (_.size(ctrl.examPartDetails) <= 0) {
      notificationService.notify('error', '移动大题出错，请稍后再试')
      return
    }
    if (!ctrl.isSaved) {
      updatePartDetails(partIdx, move)
      return
    }
    const requestData = getCurrentRequestData(ctrl.examPartDetails, partIdx, move, 'part')
    const nextRequestData = getNextRequestData(ctrl.examPartDetails, partIdx, move, 'part')
    $scope.loading = oedExamination.updatePartOrders([
      ...requestData,
      ...nextRequestData,
    ]).$promise.then(() => {
      updatePartDetails(partIdx, move)
      notificationService.notify('info', '移动大题成功')
    }).catch(() => {
      notificationService.notify('error', '移动大题出错，请稍后再试')
    });
  }

  ctrl.checkEdittingQuesValid = () => {
    return checkExamInEditting(ctrl.edittingUUID, ctrl.examData, notificationService)
  }

  ctrl.importFromLibrary = (partIdx: number, quesIdx: number) => {
    event.stopPropagation();
    $scope.$suspend()
    const ruanyunQuesType = _.get(ctrl.examPartDetails, `${partIdx}.part.name`, '')
    const modalInstance = $uibModal.open({
      template: require('assets/app2/prepareCourse/cloud/objectiveTestLibrary.html'),
      controller: 'objectiveTestLibraryCtrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          sectionId: ctrl.sectionId,
          sectionParentId: ctrl.sectionParentId,
          coursepathId: ctrl.coursepathId,
          isExam: true,
          ruanyunQuesType,
          doImport: (questions, deferred) => {
            const nq = _.map(questions, (q) => generateLocalQuestion(q))
            return importQuestions(nq, deferred, partIdx, quesIdx, [])
          },
        })
      },
    })

    modalInstance.result.finally(() => {
      $scope.$resume()
    })
  }

  function updateimportQuestion(question: any, partIdx: number, quesIdx: number, replacePointIds: any[], replacePoint: number) {
    const curQuesDetails = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}`, {})
    const replaceTag = getReplaceQuesTag(curQuesDetails, question, replacePointIds)
    const newPartDetails = _.set(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question`, question)
    _.set(newPartDetails, `${partIdx}.questionDetails.${quesIdx}.questionTags`, replaceTag)
    _.set(newPartDetails, `${partIdx}.questionDetails.${quesIdx}.question.point2`, replacePoint)
    if (_.get(question, 'type', '') == 'synthetic') {
      question = recalculateSyncQuesScore(question)
    }
    updateExamDataByNewPartDetails(newPartDetails)
  }

  function getReplaceQuesTag(curQues: any, replaceQues: any, replacePointIds: any[]) {
    const curQuesTags = _.get(curQues, 'questionTags', [])
    const isIntelligentReplace = _.size(replacePointIds) > 0
    if (isIntelligentReplace) {
      // 智能替换
      if (replacePointIds[0] == -1) {
        return curQuesTags
      }
      _.remove(curQuesTags, tag => _.get(tag, 'tagName', '') == 'ruanyun.KnowledgePointId')
      _.forEach(replacePointIds, pId => {
        const kpIdTagDemo = { tagName: "ruanyun.KnowledgePointId", tagType: "number", numVal: pId}
        curQuesTags.push(kpIdTagDemo)
      })
    } else {
      // 手动替换
      _.remove(curQuesTags, tag => _.get(tag, 'tagName', '') == 'ruanyun.KnowledgePointId' || _.get(tag, 'tagName', '') == 'ruanyun.KnowledgePointName')
      const kpIdList = _.get(replaceQues, 'kpId', '').split(',')
      const kpNameList = _.get(replaceQues, 'kpName', '').split(',')
      _.forEach(kpIdList, id => {
        const kpIdTagDemo = { tagName: "ruanyun.KnowledgePointId", tagType: "number", numVal: parseInt(id)}
        curQuesTags.push(kpIdTagDemo)
      })
      _.forEach(kpNameList, name => {
        const kpNameTagDemo = { tagName: "ruanyun.KnowledgePointName", tagType: "string", strVal: name }
        curQuesTags.push(kpNameTagDemo)
      })
    }
    return curQuesTags
  }

  function importQuestions(questions: any[], deferred: any, partIdx: number, quesIdx: number, replaceKnowledgePointIds: any[]) {
    if (_.size(questions) > 1) {
      notificationService.notify('error', '当前仅能替换一道题目')
      if (!_.isEmpty(deferred)) {
        deferred.reject()
      }
      return
    }
    const curQuesDetail = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}`, {})
    const replacePoint = _.get(curQuesDetail, 'question.point2', '')
    if (!ctrl.isSaved || !_.get(curQuesDetail, 'question.id', null)) {
      ctrl.edittingUUID = _.get(questions, '0.uuid', uuidv4())
      updateimportQuestion(questions[0], partIdx, quesIdx, replaceKnowledgePointIds, replacePoint)
      if (!_.isEmpty(deferred)) {
        deferred.resolve()
      }
      return
    }
    const replaceQuestionTags = getReplaceQuesTag(curQuesDetail, questions[0], replaceKnowledgePointIds)
    let replaceQuestion = _.set(questions[0], 'point2', _.get(curQuesDetail, 'question.point2', ''))
    if (_.get(replaceQuestion, 'type', '') == 'synthetic') {
      replaceQuestion = recalculateSyncQuesScore(replaceQuestion)
    }
    const requestData = {
      "question": replaceQuestion,
      "sort": _.get(curQuesDetail, 'sort', ''),
      "questionTags": replaceQuestionTags,
      "relatedOutlines": _.get(curQuesDetail, 'relatedOutlines', []),
    }
    $scope.loading = oedExamination.replacePartQuestion({
      examId: _.get(ctrl.examWithTags, 'exam.id', ''),
      partId: _.get(ctrl.examPartDetails, `${partIdx}.part.id`, ''),
      questionId: _.get(curQuesDetail, 'question.id', ''),
    }, requestData).$promise
      .then(() => {
        ctrl.updateCurExam({ id: _.get(ctrl.examWithTags, 'exam.id', '')})
        if (!_.isEmpty(deferred)) {
          deferred.resolve()
        }
        ctrl.onExamIsUpdate({ update: true })
        notificationService.notify('info', '替换成功')
      }).catch(() => {
        notificationService.notify('error', '替换出错，请稍后再试')
      });
  }

  ctrl.getPartTitle = (index: number) => {
    const partIndex = ctrl.partIndex[index]
    const partTypeText = _.get(ctrl.examPartDetails, `${index}.part.name`, '')
    const quesDetails = _.get(ctrl.examPartDetails, `${index}.questionDetails`, [])
    return `${partIndex}、${partTypeText}\xa0（共${_.size(quesDetails)}小题，总分${getExamQuesTotal(ctrl.examPartDetails, index)}分）`
  }

  function recalculateSyncQuesScore(ques: any) {
    const parentPoint = _.get(ques, 'point2', -1)
    const childs = _.get(ques, 'childQuestions', [])
    const childPoints = geSyncChildPointArray(parentPoint, _.size(childs))
    _.forEach(childs, (c, i) => {
      _.set(c, 'point2', childPoints[i])
    })
    return ques
  }

  ctrl.intelligentReplace = (partIdx: number, quesIdx: number) => {
    event.stopPropagation();
    const curQuesDetail = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}`, {})
    ctrl.usedQIds.push(_.get(curQuesDetail, 'question.id'))

    function findTagByName(name) {
      return _.find(_.get(curQuesDetail, 'questionTags', []), (t) => t.tagName == name)
    }

    function getLevelId() {
      const tag = findTagByName('ruanyun.QuestionLevel')
      return _.get(tag, 'numVal', 3)
    }

    function getCategoryId() {
      const tag = findTagByName('ruanyun.QuestionCategory')
      return _.get(tag, 'numVal', 3)
    }

    function getChapterIds() {
      const baseChapterIds = [
        {
          CourseMappingId: 0,
          ChapterId: parseInt(ctrl.sectionId),
        }
      ]
      if (ctrl.sectionRootId) {
        return [
          ...baseChapterIds,
          {
            CourseMappingId: 0,
            ChapterId: parseInt(ctrl.sectionRootId),
          }
        ]
      } else {
        return baseChapterIds
      }
    }

    $scope.loading = examApi.getQuestionByIntelligence({
      ByCourseMapping: 1,
      ChapterIds: getChapterIds(),
      QuestionCounts: [{
        QuestionCategoryId: getCategoryId(),
        QuestionCount: 1,
      }],
      QuestionLevelId: getLevelId(),
      filterQIds: _.filter(
        [
          ...ctrl.qIdsInExam,
          ...ctrl.usedQIds,
        ]
      ),
    }).$promise.then((result) => {
      const ruanyunQuesList = _.map(result, (r) => {
        return {
          ...r,
          question: generateLocalQuestion(r.question),
          uuid: uuidv4(),
        }
      })
      if (_.isEmpty(ruanyunQuesList)) {
        ctrl.usedQIds = []
        notificationService.notify('waring', '本次替换未匹配到相应题目，请再试一次');
        return
      }
      const replaceQues = ruanyunQuesList[0]
      importQuestions([replaceQues.question], {}, partIdx, quesIdx, _.map(replaceQues.knowledgePoints, 'id'))
    }).catch(() => {
      notificationService.notify('error', '智能替换出错，请稍后再试')
    });
  }

  function updateQuesScore(partIdx: number, quesIdx: number, score: number) {
    const newPartDetails = _.set(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question.point2`, score)
    updateExamDataByNewPartDetails(newPartDetails)
  }

  function updateChildQuesScore(partIdx: number, quesIdx: number, childQuesIdx: number, score: number) {
    const curParentQues = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question`, {})
    const newParentScore = getParentQuesScore(curParentQues, childQuesIdx, score)
    _.set(curParentQues, `childQuestions.${childQuesIdx}.point2`, score)
    _.set(curParentQues, 'point2', newParentScore)
    const newPartDetails = _.set(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question`, curParentQues)
    updateExamDataByNewPartDetails(newPartDetails)
  }

  function getParentQuesScore(curParentQues: any, childQuesIdx: number, score: number) {
    const childs = _.get(curParentQues, 'childQuestions', [])
    let parentScore = 0
    _.forEach(childs, (c, i) => {
      if (_.parseInt(i) != childQuesIdx) {
        const score = _.get(c, 'point2', 0)
        parentScore += _.parseInt(score)
      }
    })
    parentScore += score
    return parentScore
  }

  function recalculateParentQuesScore(question: any) {
    const childs = _.get(question, 'childQuestions', [])
    let parentScore = 0
    _.forEach(childs, (c, i) => {
      const score = _.get(c, 'point2', 0)
      parentScore += _.parseInt(score)
    })
    return parentScore
  }

  ctrl.onScoreChange = (partIdx: number, quesIdx: number, score: number, parentPartIdx: number) => {
    let questionId = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question.id`, null)
    const isSyncChildQues = !_.isNil(parentPartIdx)
    if (!ctrl.isSaved || !questionId) {
      if (isSyncChildQues) {
        updateChildQuesScore(parentPartIdx, partIdx, quesIdx, score)
      } else {
        updateQuesScore(partIdx, quesIdx, score)
      }
      return
    }
    const examId = _.get(ctrl.examWithTags, 'exam.id', '')
    let partId = _.get(ctrl.examPartDetails, `${partIdx}.part.id`, '')
    
    const requestData = { "point": score }
    if (isSyncChildQues) {
      partId = _.get(ctrl.examPartDetails, `${parentPartIdx}.part.id`, '')
      questionId = _.get(ctrl.examPartDetails, `${parentPartIdx}.questionDetails.${partIdx}.question.id`, '')
      const childQuestionId = _.get(ctrl.examPartDetails, `${parentPartIdx}.questionDetails.${partIdx}.question.childQuestions.${quesIdx}.id`, '')
      _.set(requestData, 'childQuestionId', childQuestionId)
    }
    $scope.loading = oedExamination.updateExamQuesScore({ examId, partId, questionId }, requestData).$promise
      .then(() => {
        if (isSyncChildQues) {
          updateChildQuesScore(parentPartIdx, partIdx, quesIdx, score)
        } else {
          updateQuesScore(partIdx, quesIdx, score)
        }
        notificationService.notify('info', '分数修改成功')
      }).catch(() => {
        notificationService.notify('error', '修改分数出错，请稍后再试')
      });
  }

  ctrl.getQuestionNumber = (partIdx: number, quesIdx: number) => {
    return getQuesNum(ctrl.examPartDetails, partIdx, quesIdx)
  }

  ctrl.getVisibleEle = () => {
    if (_.isEmpty(listView)) {
      return
    }
    const temp = []
    const { top, bottom } = listView.getBoundingClientRect()
    _.forEach(ctrl.examPartDetails, (el1, idx1) => {
      _.forEach(el1.questionDetails, (e2, idx2) => {
        const doc = document.getElementById(`anchor_${idx1}_${idx2}`)
        const mTop = doc.getBoundingClientRect().top
        const mBottom = doc.getBoundingClientRect().bottom

        if (mTop >= top && mTop <= bottom) {
          temp.push(`anchor_${idx1}_${idx2}`)
        }
      })
    })
    ctrl.letters = temp
    setTimeout(() => {
      if (typeof ctrl.updateVisibleLetters == 'function') {
        ctrl.updateVisibleLetters({ letters: ctrl.letters })
      }
    }, 500)
  }

  ctrl.initScroll = () => {
    if (listView) {
      listView.onscroll = () => {
        ctrl.getVisibleEle()
      }
      // ctrl.getVisibleEle()
      setTimeout(() => {
        if (typeof ctrl.updateVisibleLetters == 'function') {
          ctrl.updateVisibleLetters({ letters: [`anchor_${0}_${0}`] })
        }
      }, 500)
    }
  }

  ctrl.getQuesKnowledgePoints = (partIdx: number, quesIdx: number) => {
    const curQuesTags = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.questionTags`, [])
    return getQuesKpByPartAndQuesIdx(curQuesTags, ctrl.allKnowledgePoints)
  }

  function updateExamDataByNewPartDetails(newPartDetails: any[]) {
    const newExamData = {
      ...ctrl.examData,
      examPartDetails: newPartDetails
    }
    ctrl.updateExamData({ examData: newExamData })
    ctrl.onExamIsUpdate({ update: true })
  }

  function moveQuesToSpecifiedLocation(questionDetails: any[], partIdx: number, quesIdx: number, targetIdx: number) {
    // 向当前题目的上方移动
    if (quesIdx > targetIdx) {
      for (let i = targetIdx; i <= quesIdx; i++) {
        const curQuesSort = _.get(questionDetails, `${i}.sort`, -1)
        if (curQuesSort == -1) {
          notificationService.notify('error', '获取当前题目位置出错，请稍后再试')
          break
        }
        if (i == quesIdx) {
          _.set(questionDetails, `${i}.sort`, targetIdx)
          continue
        }
        _.set(questionDetails, `${i}.sort`, curQuesSort + 1)
      }
    }
    // 向当前题目的下方移动
    if (quesIdx < targetIdx) {
      for (let i = quesIdx; i <= targetIdx; i++) {
        const curQuesSort = _.get(questionDetails, `${i}.sort`, -1)
        if (curQuesSort == -1) {
          notificationService.notify('error', '获取当前题目位置出错，请稍后再试')
          break
        }
        if (i == quesIdx) {
          _.set(questionDetails, `${i}.sort`, targetIdx)
          continue
        }
        _.set(questionDetails, `${i}.sort`, curQuesSort - 1)
      }
    }
    const orderQuesDetails = _.sortBy(questionDetails, pd => _.get(pd, 'sort', ''))
    const newPartDetails = _.set(ctrl.examPartDetails, `${partIdx}.questionDetails`, orderQuesDetails)
    updateExamDataByNewPartDetails(newPartDetails)
  }

  function getCustomizeMoveRequestData(questionDetails: any[], quesIdx: number, targetIdx: number) {
    const requestData = []
    // 向当前题目的上方移动
    if (quesIdx > targetIdx) {
      for (let i = targetIdx; i <= quesIdx; i++) {
        const curQuesSort = _.get(questionDetails, `${i}.sort`, -1)
        const curQuesId = _.get(questionDetails, `${i}.question.id`, -1)
        if (curQuesSort == -1 || curQuesId == -1) {
          notificationService.notify('error', '获取当前题目位置出错，请稍后再试')
          break
        }
        if (i == quesIdx) {
          const requestItem = { "id": curQuesId, "sort": targetIdx }
          requestData.push(requestItem)
          continue
        }
        const requestItem = { "id": curQuesId, "sort": curQuesSort + 1 }
        requestData.push(requestItem)
      }
    }
    // 向当前题目的下方移动
    if (quesIdx < targetIdx) {
      for (let i = quesIdx; i <= targetIdx; i++) {
        const curQuesSort = _.get(questionDetails, `${i}.sort`, -1)
        const curQuesId = _.get(questionDetails, `${i}.question.id`, -1)
        if (curQuesSort == -1 || curQuesId == -1) {
          notificationService.notify('error', '获取当前题目位置出错，请稍后再试')
          break
        }
        if (i == quesIdx) {
          const requestItem = { "id": curQuesId, "sort": targetIdx }
          requestData.push(requestItem)
          continue
        }
        const requestItem = { "id": curQuesId, "sort": curQuesSort - 1 }
        requestData.push(requestItem)
      }
    }
    return requestData
  }

  function scrollItemQues(partIdx, quesIdx) {
    ctrl.outerIdx = partIdx;
    ctrl.idx = quesIdx;
    $location.hash(`anchor_${partIdx}_${quesIdx}`);
    $anchorScroll();
  }

  ctrl.childQuesCustomizeMove = (partIdx: number, quesIdx: number, targetIdx: number) => {
    if (quesIdx == targetIdx) {
      notificationService.notify('info', '题目已在当前位置')
      return
    }
    const curQuesDetails = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails`, {})
    if (_.size(curQuesDetails) <= 0) {
      notificationService.notify('error', '获取当前大题信息出错，请稍后再试')
      return
    }
    if (!ctrl.isSaved) {
      moveQuesToSpecifiedLocation(curQuesDetails, partIdx, quesIdx, targetIdx)
      scrollItemQues(partIdx, targetIdx)
      notificationService.notify('info', '移动题目成功')
      return
    }

    const partId = _.get(ctrl.examPartDetails, `${partIdx}.part.id`, -1)
    const requestData = getCustomizeMoveRequestData(curQuesDetails, quesIdx, targetIdx)
    if (_.size(requestData) < 1) {
      notificationService.notify('error', '移动题目组织数据出错，请稍后再试')
      return
    }
    $scope.loading = oedExamination.updateQuestionOrders({ partId: partId }, requestData).$promise
      .then(() => {
        moveQuesToSpecifiedLocation(curQuesDetails, partIdx, quesIdx, targetIdx)
        scrollItemQues(partIdx, targetIdx)
        notificationService.notify('info', '移动题目成功')
      }).catch(() => {
        notificationService.notify('error', '移动题目出错，请稍后再试')
      });
  }

  function doDelQues(partIdx: number, quesIdx: number) {
    const questionDetails = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails`, [])
    _.remove(questionDetails, (qd, i) => i == quesIdx)
    updateExamDataByNewPartDetails(ctrl.examPartDetails)
  }

  function doDelChildQues(partIdx: number, quesIdx: number, childIdx: number) {
    const parentQues = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question`, [])
    const chids = _.get(parentQues, 'childQuestions', [])
    _.remove(chids, (c, i) => i == childIdx)
    const newParentScore = recalculateParentQuesScore(parentQues)
    _.set(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question.point2`, newParentScore)
    updateExamDataByNewPartDetails(ctrl.examPartDetails)
  }

  function doDel(partIdx: number, quesIdx: number, childIdx: number) {
    const qId = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question.id`, '')
    const cqId = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question.childQuestions.${childIdx}.id`, '')
    const isDelChid = childIdx >= 0
    // 未保存试卷
    // 综合题或者单题还未保存
    // 综合题下子题还未保存
    if (!ctrl.isSaved || !qId || (!cqId && isDelChid)) {
      if (isDelChid) {
        doDelChildQues(partIdx, quesIdx, childIdx)
      } else {
        doDelQues(partIdx, quesIdx)
      }
      return
    }
    const examId = _.get(ctrl.examWithTags, 'exam.id', '')
    const partId = _.get(ctrl.examPartDetails, `${partIdx}.part.id`, '')
    
    if (isDelChid) {
      $scope.loading = oedExamination.deleteChildQuestion({ examId, partId, qId, cqId }).$promise
        .then(() => {
          doDelChildQues(partIdx, quesIdx, childIdx)
          const parentQues = _.get(ctrl.examPartDetails, `${partIdx}.questionDetails.${quesIdx}.question`, [])
          const requestData = { point: recalculateParentQuesScore(parentQues) }
          // 删除综合题子题时，需要重新修改综合题的分数
          $scope.loading = oedExamination.updateExamQuesScore({ examId, partId, questionId: qId }, requestData).$promise
            .then(() => {
              notificationService.notify('info', '删除题目成功')
            })
        }).catch(() => {
          notificationService.notify('error', '删除题目出错，请稍后再试')
        })
    } else {
      $scope.loading = oedExamination.deleteQuestion({ examId, partId, qId }).$promise
        .then(() => {
          doDelQues(partIdx, quesIdx)
          notificationService.notify('info', '删除题目成功')
        }).catch(() => {
          notificationService.notify('error', '删除题目出错，请稍后再试')
        })
    }
  }

  ctrl.onDelQues = (partIdx: number, quesIdx: number, childIdx: number) => {
    const onYes = () => doDel(partIdx, quesIdx, childIdx)
    openExamConfirmDialog($uibModal, '删除该题后不可恢复，是否确定删除？', '删除', '确定', '取消', onYes)
  }
}
