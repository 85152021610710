/**
 * @fileOverview
 * @name studentSubjectiveTestPeerReviewCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentSubjectiveTestPeerReview', {
        url: '/studentSubjectiveTestPeerReview/:boardSessionId',
        template: require('assets/templates/student/studentSubjectiveTestPeerReview.html'),
        controller: 'studentSubjectiveTestPeerReviewCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('studentSubjectiveTestPeerReviewCtrl', studentSubjectiveTestPeerReviewCtrl);

  studentSubjectiveTestPeerReviewCtrl.$inject = ['$scope', '$state', '$stateParams', '$q',
    'dialogs', 'notify', 'Lightbox', 'userInfo', 'oedConfig', 'oedCoursePath', 'oedTaskList', 'oedTask',
    'oedTaskListPublishRecord', 'oedTaskTest', 'oedSubjectiveTest', 'oedTestSession', 'oedTestSessionStudent',
    'oedBoardSession', 'oedBoardContent', 'oedBoardContentComment', 'oedSubjectiveTestSessionStats', 'oedUserInfo',
    'oedBoardSessionStudent', 'oedUserInfoUtils']
  function studentSubjectiveTestPeerReviewCtrl($scope, $state, $stateParams, $q,
    dialogs, notify, Lightbox, userInfo, oedConfig, oedCoursePath, oedTaskList, oedTask,
    oedTaskListPublishRecord, oedTaskTest, oedSubjectiveTest, oedTestSession, oedTestSessionStudent,
    oedBoardSession, oedBoardContent, oedBoardContentComment, oedSubjectiveTestSessionStats, oedUserInfo,
    oedBoardSessionStudent, oedUserInfoUtils) {
    //
    // 注意这个controller也被教师端的view使用

    $scope.taskListPublishRecordId = _.parseInt($stateParams.recordId);
    $scope.userInfo = userInfo;
    $scope.userInfoService = userInfo;

    $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl;

    $scope.fromStudentId = _.parseInt($stateParams.fromStudentId);

    $scope.basePath = oedConfig.base();

    $scope.boardSessionId = _.parseInt($stateParams.boardSessionId);

    $scope.userInfoMap = {};

    $scope.stats = oedSubjectiveTestSessionStats.queryBySessoinId({
      boardSessionId: $scope.boardSessionId,
      includeBoardThumbnail: false,
      includeBoardContent: false,
      includeFlags: true,
      includeViews: true,
      includeComments: true,
      includeBravos: true,
      includeRatings: true,
      sortBy: 'bravosCount'
    });

    $scope.publishRecordInfo = oedTaskListPublishRecord.queryPublishInfo({
      id: $scope.taskListPublishRecordId
    });

    userInfo.then(function(uInfo) {
      $scope.userInfo = uInfo;

      $scope.userInfoMap[uInfo.uid] = uInfo;

      $scope.isTeacher = function() {
        return _.includes(uInfo.roles, 'teacher');
      };
    });

    var loadingDetails = $scope.stats.$promise.then(function(stats) {
      $scope.testSession = oedTestSession.get({
        id: stats.boardSession.testSessionId
      });

      var students = _.map(stats.interacts, function(ia) {
        $scope.userInfoMap[ia.owner.uid] = ia.owner;
        return ia.owner;
      });

      $scope.students = _.sortBy(students, 'pinyin');

      return $scope.testSession.$promise;
    }).then(function(ts) {
      $scope.subjTest = oedSubjectiveTest.get({
        id: ts.testId
      });

      return $scope.subjTest.$promise;
    });

    $scope.loading = $q.all([loadingDetails, $scope.publishRecordInfo.$promise]);

    $scope.getContentItemClass = function(item) {
      var width = _.size(item.resItems) * 166;

      return {
        width: width + 'px'
      };
    };

    if ($state.is('studentSubjectiveTestPeerReview')) {
      $state.go('studentSubjectiveTestPeerReview.allStudentDetails', {}, {
        location: 'replace'
      });
    } else if ($state.is('homework.reviewSubjectiveTestPeerReview')) {
      $state.go('homework.reviewSubjectiveTestPeerReview.allStudentDetails', {}, {
        location: 'replace'
      });
    }

    $scope.getViewedNames = function(viewList) {
      if (!viewList) {
        return null;
      }

      return _.map(viewList, function(r) {
        return r.left.name;
      }).join(',');
    };

    $scope.previewResource = function(reses, idx) {
      Lightbox.openModal(reses, idx);
    };
  }
})();
