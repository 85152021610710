angular.module('app.manage')
  .controller('assignActivateCodeCtrl', assignActivateCodeCtrl);

assignActivateCodeCtrl.$inject = ['$scope', '$uibModalInstance', 'notify',
  'oedActivateCode', 'oedSchool', 'school', 'existingCodes']
function assignActivateCodeCtrl($scope, $uibModalInstance, notify,
  oedActivateCode, oedSchool, school, existingCodes) {
  $scope.minActivateCount = 1;
  $scope.maxActivateCount = 5000;
  $scope.activateCodeCount = 100;  // 默认 100
  $scope.existingCodesActivated = _.filter(existingCodes, code => _.get(code, 'activated', false) === true);

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.assignActivateCode = function() {
    const existingCodesActivatedCount = _.size($scope.existingCodesActivated);
    if ($scope.assignActivateCodeForm.$invalid) {
      $scope.message = `请填写激活码数量 (${$scope.minActivateCount} ~ ${$scope.maxActivateCount})`;
      return;
    } else if (existingCodesActivatedCount > 0 && $scope.activateCodeCount <= existingCodesActivatedCount) {
      return;
    }
    $scope.message = '';
    $scope.loading = oedActivateCode.generate({
      schoolId: school.id,
      count: $scope.activateCodeCount
    }, {
      schoolId: school.id,
      count: $scope.activateCodeCount
    }).$promise.then(function(resp) {
      school.activateCodeAutoAssign = false;
      school.activateEnabled = true;
      school.activateCodeCount = $scope.activateCodeCount;
      $uibModalInstance.close({success: true});
    }).catch(function(e) {
      $scope.message = '分配激活码失败，请稍候重试';
    });
  }
}
