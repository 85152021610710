import { ExerciseBookComponent } from './exercise-book/exercise-book.component';
import { ImportJsonComponent } from './exercise-book/import-json/import-json.component'


function exerciseBookId($transition$: any) {
  return $transition$.params().exerciseBookId;
}

function exerciseBookName($transition$: any) {
  return $transition$.params().exerciseBookName;
}

function subjectId($transition$: any) {
  return $transition$.params().subjectId;
}

const levelId = ($transition$: any) => $transition$.params().levelId;

exerciseBookId.$inject = ['$transition$'];
exerciseBookName.$inject = ['$transition$'];
subjectId.$inject = ['$transition$'];
levelId.$inject = ['$transition$'];

export const manageStates = [
  {
    name: 'exerciseBook',
    url: '/exerciseBook',
    component: ExerciseBookComponent,
  },
  {
    name: 'importJson',
    url: '/exerciseBook/:exerciseBookId/importJson/subject/:subjectId/level/:levelId/:exerciseBookName',
    component: ImportJsonComponent,
    resolve: {
      exerciseBookId: exerciseBookId,
      exerciseBookName: exerciseBookName,
      subjectId: subjectId,
      levelId: levelId,
    }
  },
]
