/**
 * @fileOverview
 * @name urlBuilder.js
 * @author pangwa
 * @license
 */
(function() {
  angular.module('app.utils.oedZhongZhiSchool', [])
    .factory('oedZhongZhiSchool', oedZhongZhiSchool);

oedZhongZhiSchool.$inject = ['userInfo', 'oedSchool','schoolFeatureService']
  function oedZhongZhiSchool(userInfo, oedSchool, schoolFeatureService) {
    return {
      isZhongZhiSchool: isZhongZhiSchool,
      levelName: levelName,
      className: className,
      levelId: levelId,
    };
    function isZhongZhiSchool(){
      let isZhongZhiSchool;
      return userInfo.then(function(info) {
        return schoolFeatureService.getSchool(info.schoolId).then((res) => {
          isZhongZhiSchool = _.get(res, 'schoolType') === '中职';
          return isZhongZhiSchool;
        })
      })
    }
    function levelName () {
        return '中职';
    }

    function levelId () {
      return 4028599;
    }

    function className (result) {
      return isZhongZhiSchool().then((isZhongZhiSchool) => {
        let fullName = '';
        if(isZhongZhiSchool) {
          fullName = result.rxnd + '级' + result.className;
        } else {
          fullName = result.levelName + result.gradeName + result.className;
        }
        return fullName;
      })
    }
  }
})();
