require('assets/app/controllers/examination/examEdit/generating/questionType/questionType.scss');

angular.module('app.examination.examEdit.generating.questionType', [])
  .component('questionType', {
    bindings: {
      quesType: '<',
      onUpdateGeneratingSetting: '&'
    },
    controller: questionTypeCtrl,
    template: <string>require('./questionType.html'),
  });

questionTypeCtrl.$inject = ['localStorageService'];

function questionTypeCtrl(localStorageService) {

  const ctrl = this;

  ctrl.quesNum = 0;
  ctrl.totalScore = 0;

  ctrl.$onInit = () => {
    const num = localStorageService.get(`categoryId_${this.quesType.categoryId}_num`)
    const score = localStorageService.get(`categoryId_${this.quesType.categoryId}_score`)
    if (num && parseInt(num) > 0) {
      ctrl.quesNum = num
      ctrl.totalScore = score
      ctrl.isCheck = true
      const result = {
        ...this.quesType,
        num: num,
        totalScore: ctrl.totalScore,
        isCheck: ctrl.isCheck,
      };
      this.onUpdateGeneratingSetting({setting: result});
    }
  }

  function changeSetting() {
    const num = ctrl.quesNum;

    const result = {
      ...this.quesType,
      num: num,
      totalScore: ctrl.totalScore,
      isCheck: ctrl.isCheck,
    };
    localStorageService.set(`categoryId_${this.quesType.categoryId}_num`, num)
    localStorageService.set(`categoryId_${this.quesType.categoryId}_score`, ctrl.totalScore)
    this.onUpdateGeneratingSetting({setting: result});
  }

  ctrl.isCheck = false;
  ctrl.onCheck = () => {
    ctrl.isCheck = !ctrl.isCheck;
    changeSetting.call(this);
  };

  ctrl.onMinus = () => {
    let num = ctrl.quesNum;
    if (num > 0) {
      num--;
    } else {
      num = 0;
    }
    ctrl.quesNum = num;

    changeSetting.call(this);
  };

  ctrl.onPlus = () => {
    let num = ctrl.quesNum;
    if (num < 105) {
      num++;
    } else {
      num = 105;
    }

    ctrl.quesNum = num;

    changeSetting.call(this);
  };

  ctrl.onSettingChange = () => {
    changeSetting.call(this);
  };
}
