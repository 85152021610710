import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SbjCompletionDetailsComponent } from './sbj-completion-details/sbj-completion-details.component';
import { SbjStdContentComponent } from './sbj-std-content/sbj-std-content.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from 'app/shared/shared.module';
import { SbjStdContentToolComponent } from './sbj-std-content-tool/sbj-std-content-tool.component';
import { FormsModule } from '@angular/forms';
import { StdInfoComponent } from './std-info/std-info.component';
import { SbjTestStatTableComponent } from './sbj-test-stat-table/sbj-test-stat-table.component';
import { SbjCompletionPreviewerComponent } from './sbj-completion-previewer/sbj-completion-previewer.component';
import { ObjChartSummaryComponent } from './obj-chart-summary/obj-chart-summary.component';
import { ChartsModule } from 'app/charts/charts.module';
import { ObjTestStatTableComponent } from './obj-test-stat-table/obj-test-stat-table.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ObjCompletionDetailsComponent } from './obj-completion-details/obj-completion-details.component';
import { ObjCompletionPreviewerComponent } from './obj-completion-previewer/obj-completion-previewer.component';

@NgModule({
  declarations: [SbjCompletionDetailsComponent, SbjStdContentComponent, SbjStdContentToolComponent, StdInfoComponent, SbjTestStatTableComponent, SbjCompletionPreviewerComponent, ObjChartSummaryComponent, ObjTestStatTableComponent, ObjCompletionDetailsComponent, ObjCompletionPreviewerComponent],
  entryComponents: [ObjChartSummaryComponent, ObjCompletionDetailsComponent, ObjCompletionPreviewerComponent, ObjTestStatTableComponent, SbjCompletionDetailsComponent, SbjCompletionPreviewerComponent, SbjTestStatTableComponent, StdInfoComponent],
  imports: [
    CommonModule,
    ChartsModule,
    BsDropdownModule,
    FormsModule,
    TooltipModule,
    SharedModule,
  ]
})
export class ReviewModule { }
