import * as angular from 'angular';
import Promise = require('bluebird')
import * as _ from 'lodash';

angular.module('app.prepareCourse2.promptDialog',
[]).controller('promptDialogCtrl', promptDialogCtrl)

promptDialogCtrl.$inject = [
  '$scope',
  'data',
  '$uibModalInstance',
  '$q',
  '$uibModal',
  'notificationService'
]
function promptDialogCtrl(
  $scope,
  data: any,
  $uibModalInstance,
  $q,
  $uibModal,
  notificationService
) {

  $scope.cancelClick = () => {
    $uibModalInstance.close('cancel');
  }
}
