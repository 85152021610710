import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash'

import { RayApiService } from 'app/core/ray-api.service';
import { BatchApi } from 'app/utils/batch-api';
import { CloudQuestionStarDTO, CloudQuestionStatDTO, CloudTestDTO, CloudResourceDTO, UsedResourceDTO, CloudTestStatDTO, CloudTestStarInfoDTO } from './cloud-question.model';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CloudQuestionService {
  private questionStarApi: BatchApi<number, CloudQuestionStarDTO>
  private questionStatApi: BatchApi<number, CloudQuestionStatDTO>
  private questionTestApi: BatchApi<number, CloudTestDTO>
  private questionResApi: BatchApi<number, CloudResourceDTO>
  private ruanYunUsedResourceApi: BatchApi<string, UsedResourceDTO>
  private testStatApi: BatchApi<number, CloudTestStatDTO>
  private testStarInfoApi: BatchApi<number, CloudTestStarInfoDTO>


  constructor(private httpClient: HttpClient, private rayApi: RayApiService) {
    this.questionStarApi = new BatchApi<number, CloudQuestionStarDTO>({
      wait: 50,
      getAll: (ids: number[]) => {
        return httpClient.get<{[key: string]: CloudQuestionStarDTO}>(rayApi.apiB('cloudquestion/stars'), {
          params: {
            ids: _.map(ids, id => `${id}`),
          }
        }).toPromise()
      }
    })

    this.questionStatApi = new BatchApi<number, CloudQuestionStatDTO>({
      wait: 50,
      getAll: (ids: number[]) => {
        return httpClient.get<{[key: string]: CloudQuestionStatDTO}>(rayApi.apiB('cloudquestion/stats'), {
          params: {
            ids: _.map(ids, id=> `${id}`)
          }
        }).toPromise()
      }
    })

    this.questionTestApi = new BatchApi({
      wait: 50,
      getAll: (ids: number[]) => {
        return httpClient.get<{[key: string]: CloudTestDTO}>(rayApi.apiB('objectivequestion/cloudTests'), {
          params: {
            ids: _.map(ids, id=> `${id}`)
          }
        }).toPromise()
      }
    })

    this.questionResApi = new BatchApi({
      wait: 50,
      getAll: (ids: number[]) => {
        return httpClient.get<{[key: string]: CloudResourceDTO}>(rayApi.apiB('objectivequestion/cloudReses'), {
          params: {
            ids: _.map(ids, id=> `${id}`)
          }
        }).toPromise()
      }
    })

    this.ruanYunUsedResourceApi = new BatchApi({
      wait: 50,
      getAll: (ids: string[]) => {
        return httpClient.get<UsedResourceDTO[]>(rayApi.apiB(`usedResource/ruanyun`), {
          params: {
            resIds: _.map(ids, id=> `${id}`)
          }
        }).pipe(map((reses: UsedResourceDTO[]) => {
          return _.keyBy(reses, 'resId')
        })).toPromise()
      }
    })

    this.testStatApi = new BatchApi({
      wait: 50,
      getAll: (ids: number[]) => {
        console.warn('do test stat api request')
        return httpClient.get<{[key: string]: CloudTestStatDTO}>(rayApi.apiB('cloudtest/statses'), {
          params: {
            ids: _.map(ids, id=> `${id}`)
          }
        }).toPromise()
      }
    })

    this.testStarInfoApi = new BatchApi({
      wait: 50,
      getAll: (ids: number[]) => {
        return httpClient.get<{[key: string]: CloudTestStarInfoDTO}>(rayApi.apiB('cloudtest/starinfos'), {
          params: {
            ids: _.map(ids, id=> `${id}`)
          }
        }).toPromise()
      }
    })

  }

  public getStarByCloudId(id: number): Promise<CloudQuestionStarDTO> {
    return this.questionStarApi.get(id)
  }

  public getStatByCloudId(id: number): Promise<CloudQuestionStatDTO> {
    return this.questionStatApi.get(id)
  }

  public getCloudTestByQuestionId(id: number): Promise<CloudTestDTO> {
    return this.questionTestApi.get(id)
  }

  public getCloudResByQuestionId(id: number): Promise<CloudResourceDTO> {
    return this.questionResApi.get(id)
  }

  public getUsedRuanyunResourceById(id: string): Promise<UsedResourceDTO> {
    return this.ruanYunUsedResourceApi.get(id)
  }

  public markRuanyunResAsUsed(ids: string[]): Observable<UsedResourceDTO[]> {
    if (_.isEmpty(ids)) {
      return of([])
    }
    return this.httpClient.post<UsedResourceDTO[]>(this.rayApi.apiB('usedResource/use'), {
      resType: 'ruanyun',
      resIds: ids,
    })
  }

  public getCloudTestStatByCloudId(id: number): Promise<CloudTestStatDTO> {
    return this.testStatApi.get(id)
  }

  public getCloudTestStarInfoByCloudId(id: number): Promise<CloudTestStarInfoDTO> {
    return this.testStarInfoApi.get(id)
  }
  
  public getSubjects(schoolId: number) {
    return this.httpClient.get<any>(this.rayApi.apiA(`school/${schoolId}/subjects`))
  }
}
