import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from '@angular/core';
import * as _ from 'lodash'
import { LessonService } from '../../../services/lesson.service'

@Component({
  selector: 'app-section-exams-paper',
  template: require('./section-exams-paper.component.html'),
  styles: [require('./section-exams-paper.component.scss')]
})
export class SectionExamsPaperComponent implements OnInit, OnChanges {

  @ViewChild('scroller') scroller: ElementRef
  @Input() public examId: number
  private examDetail: any
  private examSummary: any
  private parts: any[]
  private examRelated: any
  private totalScore: any
  private examParts: any[]

  constructor(
    private lessonService: LessonService,
  ) { }

  ngOnChanges(changes): void {
    if (_.get(changes, 'examId.currentValue') != _.get(changes, 'examId.previousValue')) {
      if(!_.get(changes, 'examId.firstChange')) {
        this.loadExamDetail()
      }
    }
    setTimeout(() => {
      this.scroller.nativeElement.scrollTop = 0
    }, 0)
  }

  ngOnInit() {
    this.loadExamDetail()
  }

  loadExamDetail() {
    this.lessonService.getExamById(this.examId).toPromise()
    .then(res => {
      this.examDetail = res
      this.examSummary = res.exam;
      this.parts = res.parts;
      this.examRelated = res.relatedSections;
      this.examParts = []
      this.totalScore = 0
      if (this.parts && this.parts.length !== 0) {
        _.forEach(this.parts, (item) => {
          const name = item.part.name;
          const examId = item.part.examId;
          let score = 0;
          let questionNum = 0;
          const sort = item.part.sort;
          const questionDetails = item.questionDetails;
          const id = item.part.id;
          _.forEach(item.questionDetails, (value) => {
            score += value.question.point2;
            this.totalScore += value.question.point2;
            questionNum += 1;
          });
          this.examParts.push({
            score,
            questionNum,
            sort,
            examId,
            name,
            questionDetails,
            id
          });
        });
      }
    })
  }
  examPartsUpdate() {

  }
}
