var messages = require('app2/utils/messages');

(function() {
  'use strict';

  angular.module('app.manage.integralManagement', [])
    .controller('integralManagementCtrl', integralManagementCtrl);

  integralManagementCtrl.$inject = ['$scope', '$uibModalInstance', 'notificationService', 'oedAccountUtils',
    'oedUserInfo', 'data', '$uibModal', 'oedStudentGroupScore']
  function integralManagementCtrl(
    $scope,
    $uibModalInstance,
    notificationService,
    oedAccountUtils,
    oedUserInfo,
    data,
    $uibModal,
    oedStudentGroupScore
  ) {
    $scope.students = data.students
    $scope.groups = data.groups
    $scope.curGroupIdx = data.curGroupIdx
    $scope.stdMap = _.keyBy($scope.students, 'uid')
    $scope.group = $scope.groups[$scope.curGroupIdx]
    $scope.sg = $scope.group.subGroups[0]

    $scope.getSubGroupName = (index) => {
      const subNames = _.get($scope.group, 'subGroupInfo', []);
      let curSub;
      _.forEach(subNames, (o) => {
        if (o.subGroupIdx === index) {
          curSub = o;
        }
      });
      const curName = _.get(curSub, 'subName');
      if (curName) {
        return `${curName}`;
      }
      return `第${(index + 1)}组`
    }

    $scope.integral = 0
    $scope.groupScore = 0
    $scope.subGroupName = $scope.getSubGroupName(0)
    $scope.closeDialog = () => {
      $uibModalInstance.dismiss()
      data.doRefresh()
    }

    function computedGroupScore(index) {
      $scope.groupScore = 0
      if ($scope.scoreList) {
        _.forEach($scope.scoreList[index], (item) => {
          $scope.groupScore += item.score
        })
      }
    }

    function getCurrentMonthsScore() {
      const start = moment().startOf('months').format('x')
      const end = moment().endOf('months').format('x')
      if ($scope.sg && $scope.sg[0]) {
        const data = {
          id: $scope.sg[0].groupId,
          start: start,
          end: end
        }
        return oedStudentGroupScore.getScoreBySometime(data).$promise.then((res) => {
          $scope.scoreList = _.groupBy(res, 'subGroupIdx');
          if ($scope.group.subGroups && $scope.group.subGroups[0]) {
            computedGroupScore($scope.sg[0].subGroupIdx)
          }
        })
      }
    }

    $scope.switchGroup = (sg, index) => {
      $scope.integral = 0
      _.forEach($scope.group.subGroups, (item) => {
        item.$$isSelect = false
      })
      $scope.sg = sg
      $scope.sg.$$isSelect = true
      $scope.subGroupName = $scope.getSubGroupName(index)
      getCurrentMonthsScore()
      computedGroupScore($scope.group.subGroups[index][0].subGroupIdx)
    }

    getCurrentMonthsScore()
    $scope.searchIntegral = () => {
      const modalInstance = $uibModal.open({
        template: require('assets/app2/manage/searchIntegral.html'),
        controller: 'searchIntegralCtrl',
        size: 'lg',
        windowClass: 'shouldHideOnPreview cl-library-dialog2 modalCenter',
        resolve: {
          data: () => ({
            students: $scope.students,
            groups: $scope.groups,
            curGroupIdx: $scope.curGroupIdx
          })
        }
      })
    }
    $scope.changeIntegral = () => {
      if (!$scope.integral) {
        return notificationService.notify('info', '请正确填写分数')
      }
      if ($scope.sg && $scope.sg[0]) {
        const data = {
          groupId: $scope.sg[0].groupId,
          subGroupIdx: $scope.sg[0].subGroupIdx,
          score: $scope.integral
        }
        return oedStudentGroupScore.setScoreWithGroup(data).$promise.then((res) => {
          $scope.integral = 0
          getCurrentMonthsScore($scope.sg[0].subGroupIdx)
        })
      }
    }

    $scope.getStdAvatarImgSrc = function(std) {
      if ('female' == std.gender) {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_f1.png';
        } else {
          return 'Assets/images2/stu_avatar_f2.png';
        }
      } else {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_m1.png';
        } else {
          return 'Assets/images2/stu_avatar_m2.png';
        }
      }
    }

    $scope.validStd = (uid) => {
      return _.hasIn($scope.stdMap, uid);
    }

    _.forEach($scope.group.subGroups, (item, index) => {
      if (index === 0) {
        item.$$isSelect = true
        item.$$borderNone = true
      } else {
        item.$$isSelect = false
        item.$$borderNone = false
      }
    })

    $scope.validScore = () => {
      if (!$scope.integral) {
        return notificationService.notify('info', '请正确填写分数')
      }
    }
  }
})();

