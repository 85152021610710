import angular = require('angular');
import * as _ from 'lodash';

require('assets/styles/course.css');
const {weikyueyiValidVersionNames} = require('app2/prepareCourse/cloud/utils/libraryUtils')
import { ruanyunCourses, ruanyunGrades } from 'app2/integration/ruanyun/ruanyunDic'

angular.module('app.directives.course.ryMaterialSelectorTest', [])
  .component('ryMaterialSelectorTest', {
    bindings: {
      currentMaterial: '<',
      subjectId: '<',
      selectedCourse: '<',
      selectedBookVersion: '<',
      bookVersions: '<',
      onSelect: '&',
      reloadMaterial: '&',
      onBookVersionClicked: '&',
      courseMappings: '<',
      selectedCourseMapping: '<',
    },
    controller: ryMaterialSelectorTest,
    template: <any> require('./ryMaterialSelectorTest.html'),
  })

ryMaterialSelectorTest.$inject = ['$timeout', 'oedTeachingMaterialVersions', 'oedSubjects', '$q', 'notificationService']
function ryMaterialSelectorTest($timeout: any, oedTeachingMaterialVersions: any, oedSubjects: any, $q: any, notificationService: any) {
  const ctrl = this;
  ctrl.isOpen = false
  
  ctrl.ruanyunGrades = ruanyunGrades
  ctrl.selectGrade = ruanyunGrades[0];
  ctrl.ruanyunCourses = _.groupBy(ruanyunCourses, 'gradeId')
  ctrl.ruanyunCourseCurrent = _.get(ctrl.ruanyunCourses, `${_.get(ctrl.selectGrade, 'id', 1)}`)
  ctrl.onRuanyunGradeClicked = (grade) => {
    ctrl.selectGrade = grade
    ctrl.ruanyunCourseCurrent = _.get(ctrl.ruanyunCourses, `${_.get(ctrl.selectGrade, 'id', 1)}`)
  }
  ctrl.onCourseClicked = (course) => {
    ctrl.selectedCourse = course
    ctrl.reloadMaterial({course: ctrl.selectedCourse})
  }
  ctrl.onCourseMappingClicked = (cm) => {
    ctrl.selectedCourseMapping = cm
  }
  this.$onChanges = (changeObj: any) => {
    if (_.get(changeObj, 'selectedCourse.currentValue')) {
      if (this.selectedCourse) {
        ctrl.ruanyunCourseCurrent = _.get(ctrl.ruanyunCourses, `${_.get(ctrl.selectGrade, 'id', 1)}`)
        ctrl.reloadMaterial(ctrl.selectedCourse)
        ctrl.selectGrade = _.find(ruanyunGrades, g => g.id === _.get(this.selectedCourse, 'gradeId', 1))
      }
    }
  }
  ctrl.confirm = () => {
    ctrl.isOpen = false
    ctrl.onSelect({
      material: {selectedBookVersion:ctrl.selectedBookVersion, selectedCourseMapping: ctrl.selectedCourseMapping}
    })
  }
  ctrl.tryToOpen = () => {
    if (!ctrl.isOpen) 
      ctrl.isOpen = true
    else 
      ctrl.isOpen = false
  }
  ctrl.close = () => {
    ctrl.isOpen = false
  }
}
