import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { RayApiService } from 'app/core/ray-api.service'
import { SchoolDTO, JoinSchoolResultDTO } from 'app/models/school.d'
import { BatchApi } from 'app/utils/batch-api';
import * as _ from 'lodash'

@Injectable({
  providedIn: 'root'
})
export class SchoolService {

  private schoolBatchApi: BatchApi<number, SchoolDTO>

  constructor(private httpClient: HttpClient,
    private rayApi: RayApiService) {
    this.schoolBatchApi = new BatchApi({
      wait: 100,
      getAll: (ids: number[]) => {
        return httpClient.get<SchoolDTO[]>(rayApi.apiB('school/byIds'), {
          params: { ids: ids.map(_.toString) }
        }).toPromise().then(items => _.keyBy(items, 'id'))
      }
    })
  }

  public getSchool(id: number) {
    return this.schoolBatchApi.get(id)
  }

  public querySchool(q: string) {
    return this.httpClient.post<SchoolDTO[]>(this.rayApi.apiA(`school/search`), {
      name: q,
    })
  }

  public joinSchool(schoolId: number, idNo: string) {
    return this.httpClient.post<JoinSchoolResultDTO>(this.rayApi.apiA('school/join'), {
      schoolId,
      idNo,
    })
  }
}
