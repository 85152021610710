UE.registerUI('insertRecord', function(editor, uiname){

        /**
     * 创建插入视频字符窜
     * @param url 视频地址
     * @param width 视频宽度
     * @param height 视频高度
     * @param align 视频对齐
     * @param addParagraph  是否需要添加P 标签
     */
    function creatInsertStr(url,width,height,id,align,classname,type){
        var str;
        switch (type){
            case 'image':
                str = '<img ' + (id ? 'id="' + id+'"' : '') + ' width="'+ width +'" height="' + height + '" _url="'+url+'" class="' + classname.replace(/\bvideo-js\b/, '') + '"'  +
                    ' src="' + editor.options.UEDITOR_HOME_URL+'themes/default/images/spacer.gif" style="background:url('+editor.options.UEDITOR_HOME_URL+'themes/default/images/videologo.gif) no-repeat center center; border:1px solid gray;'+(align ? 'float:' + align + ';': '')+'" />'
                break;
            case 'video':
                str = '<video' + (id ? ' id="' + id + '"' : '') + ' class="video-js" ' + (align ? ' style="float:' + align + '"': '') +
                    ' controls preload="none" width="' + width + '" height="' + height + '" src="' + url + '" data-setup="{}">' +
                    '<source src="' + url + '" type="video/mp4" /></video>';
                break;
        }
        return str;
    }

        /**
     * 插入视频
     * @command insertvideo
     * @method execCommand
     * @param { String } cmd 命令字符串
     * @param { Array } videoArr 需要插入的视频的数组， 其中的每一个元素都是一个键值对对象， 描述了一个视频的所有属性
     * @example
     * ```javascript
     *
     * var videoAttr1 = {
     *      //视频地址
     *      url: 'http://www.youku.com/xxx',
     *      //视频宽高值， 单位px
     *      width: 200,
     *      height: 100
     * },
     * videoAttr2 = {
     *      //视频地址
     *      url: 'http://www.youku.com/xxx',
     *      //视频宽高值， 单位px
     *      width: 200,
     *      height: 100
     * }
     *
     * //editor 是编辑器实例
     * //该方法将会向编辑器内插入两个视频
     * editor.execCommand( 'insertvideo', [ videoAttr1, videoAttr2 ] );
     * ```
     */

  editor.registerCommand('insertmicrolessonrecord', {
        execCommand: function(cmd, videoObjs, type) {
            videoObjs = Array.isArray(videoObjs)?videoObjs:[videoObjs];
            var html = [],id = 'tmpVedio', cl;
            for(var i=0,vi,len = videoObjs.length;i<len;i++){
                vi = videoObjs[i];
                cl = (type == 'upload' ? 'edui-upload-video video-js vjs-default-skin':'edui-faked-video');
                html.push('<br/>' + creatInsertStr( vi.url, vi.width || 420,  vi.height || 280, id + i, null, cl, 'video'));
            }
            editor.execCommand("inserthtml",html.join(""),true);
            var rng = this.selection.getRange();
            for(var i= 0,len=videoObjs.length;i<len;i++){
                var img = this.document.getElementById('tmpVedio'+i);
                $(img).removeAttr('id');
                rng.selectNode(img).select();
                editor.execCommand('imagefloat',videoObjs[i].align)
            }
        },
		queryCommandState : function(){
            var img = editor.selection.getRange().getClosedNode(),
                flag = img && (img.className == "edui-faked-audio" || img.className.indexOf("edui-upload-audio")!=-1);
            return flag ? 1 : 0;
        }
    });

    // 创建dialog
    var kfDialog = new UE.ui.Dialog({

        // 指定弹出层路径
        iframeUrl: editor.options.UEDITOR_HOME_URL + 'microLessonRecord/record.html',
        // 编辑器实例
        editor: editor,
        // dialog 名称
        name: uiname,
        // dialog 标题
        title: '插入微课',

        // dialog 外围 css
        cssRules: 'width:830px; height: 496px;'
    });

    var url = editor.options.UEDITOR_HOME_URL + 'microLessonRecord/record.html';
    var iconUrl = editor.options.UEDITOR_HOME_URL + 'microLessonRecord/play-circle-o.png';
    var tmpLink = document.createElement('a');
    tmpLink.href = iconUrl;
    tmpLink.href = tmpLink.href;
    iconUrl = tmpLink.href;

    var kfBtn = new UE.ui.Button({
        name:'插入' + uiname,
        title:'插入微课-' + uiname,
        //需要添加的额外样式，指定icon图标
        cssRules :'background: url("' + iconUrl + '") no-repeat !important',
        onclick:function () {
            //渲染dialog
            kfDialog.render();
            kfDialog.open();
        }
    }, editor);

    return kfBtn;


}, 10);

