(function() {
  'use strict';
  angular.module('app.homework')
    .controller('selectTaskCtrl', selectTaskCtrl);

  selectTaskCtrl.$inject = ['$scope', '$uibModalInstance']
  function selectTaskCtrl($scope, $uibModalInstance) {
    $scope.addManual = function() {
      $uibModalInstance.close('manual');
    };

    $scope.addLibrary = function() {
      $uibModalInstance.close('library');
    };
  }
})();
