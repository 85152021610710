import angular = require('angular');
import * as _ from 'lodash';
import {choiceCategory, yesornoCategory, isQuickWayToCreatePrepareCourse, defaultChildQuestionList}
  from "app2/prepareCourse/utils/prepareCourseUtils";

angular.module('app.homework.homeworkUpdatePreviewerCtrl', [])
  .controller('homeworkUpdatePreviewerCtrl', homeworkUpdatePreviewerCtrl);

homeworkUpdatePreviewerCtrl.$inject = ['$scope', '$uibModalInstance', 'data', '$state', '$stateParams', '$q', '$timeout',
  '$log', '$uibModal', '$http', 'notify', 'Upload',
  'oedObjectiveTest', 'oedObjectiveQuestion', 'oedUnitItem', 'oedTestUtils', 'oedCourseUtils', 'oedCloudTest',
  'oedConfig', 'oedCloudQuestion', 'talcloudResource', '$window', 'userInfo',
  'oedCourse', 'oedObjectiveTestStats', 'oedUserInfo', 'oedSubjects', 'oedMaterialVersion',
  'oedGrade', 'oedLevel', 'oedLevelGradeVersionSubjects', 'oedMisc', 'dialogs', 'localStorageService',
  'cloudQuestionService', 'notificationService'];

function homeworkUpdatePreviewerCtrl($scope: any, $uibModalInstance: any, data: any, $state: any, $stateParams: any,
  $q: any, $timeout: any, $log: any, $uibModal: any, $http: any, notify: any, Upload: any, oedObjectiveTest: any,
  oedObjectiveQuestion: any, oedUnitItem: any, oedTestUtils: any, oedCourseUtils: any, oedCloudTest: any,
  oedConfig: any, oedCloudQuestion: any, talcloudResource: any, $window: any, userInfo: any,
  oedCourse: any, oedObjectiveTestStats: any, oedUserInfo: any, oedSubjects: any, oedMaterialVersion: any,
  oedGrade: any, oedLevel: any, oedLevelGradeVersionSubjects: any, oedMisc: any, dialogs: any, localStorageService: any,
  cloudQuestionService: any, notificationService: any) {

  $scope.close = () => {
    $uibModalInstance.close('close');
  };
  $scope.choiceCategory = choiceCategory;
  $scope.yesornoCategory = yesornoCategory;
  const tmpList = [];

  $scope.currentTest = oedObjectiveTest.get({
    id: data.test.id
  });
  $scope.currentTest.$promise.then((res: any) => {
    loadTestCloudQuestions(res.questions);
  });
  $scope.pageSize = 30;
  $scope.questionGroup = [];
  $scope.cloudQuestions = {};
  $scope.courseSections = oedUnitItem.queryAvailableBySchoolAndCourse({
    schoolId: 0,
    courseId: data.courseId
  });
  $scope.editorConfig = {
    serverUrl: oedConfig.url('ceditor/Test/') + data.courseId
  };
  $scope.stringToNumber = (str) => {
    return _.toNumber(str)
  }
  $scope.setAnswer = (outerIndex, index) => {
    $scope.childQuestionList[outerIndex].answer = _.toString(index)
    $scope.currentTest.questions[0].childQuestions = $scope.childQuestionList
    // $scope.saveTest();
  }
  $scope.isNewQuestion = function(question) {
    return !_.get(question, 'id');
  };
  const expTable = new RegExp('<table>');
  const regExpBr = /^(<p><br\/><\/p>)+|(<p><br\/><\/p>)+$/g;
  $scope.eachTrimEnter = function(x) {
    if (regExpBr.test(x) && x != '<p><br/></p>') {
      return x.replace(regExpBr, '');
    } else {
      return x;
    }
  };
  $scope.isQuickCreate = () => {
    return isQuickWayToCreatePrepareCourse($scope.currentTest)
  }
  $scope.getCurPageFirstQues = function() {
    return $scope.questionGroup[$scope.currentPage][0];
  }
  $scope.getCurPageFirstQuesIdx = () => {
    return _.findIndex($scope.currentTest.questions, (q: any) => {
      return q.id === $scope.getCurPageFirstQues().id;
    });
  };
  $scope.onEnterEditQuestion = function(question, event) {
    $scope.currentQuestion = angular.copy(question);
    $scope.editingQuestion = question;
  };
  $scope.onLeaveEditQuestion = function(question, event) {
    const idx = _.findIndex($scope.currentTest.questions, question);
    $scope.errorAnchor = function(idx) {
      $scope.scrollAnchor = {
        relativeTo: '.ot-question-list',
        to: idx - $scope.getCurPageFirstQuesIdx(),
        stamp: _.uniqueId('qscroll_')
      };
    };
    let ret = oedTestUtils.validateQuestion(question, false);
    if (question.type === 'synthetic') {
      const syntheticTable = [];
      const rets = _.map(question.childQuestions, function(q) {
        const tmpQ = angular.copy(q);
        syntheticTable.push(tmpQ);
        tmpQ.isSub = true;
        const tmp = oedTestUtils.validateQuestion(tmpQ, false);
        return tmp.valid ? null : tmp;
      });
      const result = _.find(rets, function(r) {
        return r !== null && !r.valid;
      });
      if (!_.isUndefined(result))
        ret = result;
      if (_.size(question.childQuestions) === 0) {
        ret = {
          valid: false,
          message: '当前综合题没有子题， 请设置子题后再保存'
        };
      }
      //综合题子题题干不能插入表格
      const synTableQues = _.find(syntheticTable, (c) => expTable.test(c.question));
      if (synTableQues !== undefined) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '综合题子题题干暂不支持插入表格')
        $scope.errorAnchor(idx);
        return false;
      }
      const synAnswerDetails = _.find(syntheticTable, (c) => expTable.test(c.answerDetails));
      if (synAnswerDetails !== undefined) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '综合题子题解析暂不支持插入表格')
        $scope.errorAnchor(idx);
        return false;
      }
      //综合题子题选项
      const syntheticChoices = [];
      const synChoicesContent = _.find(syntheticTable, function(x) {
        if (x.type === 'singlechoice' || x.type === 'multichoice' ||
          x.type === 'singlevote' || x.type === 'multivote') {
          _.each(x.choices, function(c) {
            syntheticChoices.push(c.content);
          });
        }
        return expTable.test(_.join(syntheticChoices, ''));
      });
      if (synChoicesContent !== undefined) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '综合题子题选项中暂不支持插入表格')
        $scope.errorAnchor(idx);
        return false;
      }
      //综合题leftOpts、rightOpts
      const syntheticLeftOpts = [];
      const syntheticRightOpts = [];
      const synLeftOpts = _.find(syntheticTable, function(x) {
        if (x.type === 'connect') {
          _.each(x.leftOpts, function(c) {
            syntheticLeftOpts.push(c.content);
          });
        }
        return expTable.test(_.join(syntheticLeftOpts, ''));
      });
      const synRightOpts = _.find(syntheticTable, function(x) {
        if (x.type === 'connect') {
          _.each(x.rightOpts, function(c) {
            syntheticRightOpts.push(c.content);
          });
        }
        return expTable.test(_.join(syntheticRightOpts, ''));
      });
      if (synLeftOpts !== undefined || synRightOpts !== undefined) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '综合题匹配题选项中暂不支持插入表格')
        $scope.errorAnchor(idx);
        return false;
      }
      //综合题子题题干、解析、选项去除多余回车
      _.forEach(question.childQuestions, function(c) {
        c.question = $scope.eachTrimEnter(c.question);
        c.answerDetails = $scope.eachTrimEnter(c.answerDetails);
        if (c.type === 'singlechoice' || c.type === 'multichoice' ||
          c.type === 'singlevote' || c.type === 'multivote') {
          _.forEach(c.choices, function(opt) {
            opt.content = $scope.eachTrimEnter(opt.content);
          });
        }
        if (c.type === 'connect') {
          _.forEach(c.leftOpts, function(opt) {
            opt.content = $scope.eachTrimEnter(opt.content);
          });
          _.forEach(c.rightOpts, function(opt) {
            opt.content = $scope.eachTrimEnter(opt.content);
          });
        }
      });
    }
    if (ret && !ret.valid) {
      $scope.errorForEmpty = true;
      notificationService.notify('error', ret.message)
      $scope.errorAnchor(idx);
      // $scope.$apply();
      return false;
    } else {
      if (expTable.test(question.question)) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '题干中暂不支持插入表格')
        $scope.errorAnchor(idx);
        return false;
      }
      if (expTable.test(question.answerDetails)) {
        $scope.errorForEmpty = true;
        notificationService.notify('error', '解析中暂不支持插入表格')
        $scope.errorAnchor(idx);
        return false;
      }
      //去除题干和解析中多余的回车
      question.question = $scope.eachTrimEnter(question.question);
      question.answerDetails = $scope.eachTrimEnter(question.answerDetails);
      //单选、多选、投票中的choices出入表格的处理
      if (question.type === 'singlechoice' || question.type === 'multichoice' ||
        question.type === 'singlevote' || question.type === 'multivote') {
        const tableQues = _.find(question.choices, (c) => expTable.test(c.content));
        if (tableQues !== undefined) {
          $scope.errorForEmpty = true;
          notificationService.notify('error', '选项中暂不支持插入表格')
          $scope.errorAnchor(idx);
          return false;
        }
        //去除选择题选项中多余的回车
        _.forEach(question.choices, function(c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
      }
      //当表格的leftOpts或rightOpts中粘贴了表格时候的处理
      if (question.type === 'connect') {
        const connectLeftOpts = _.find(question.leftOpts, (c) => expTable.test(c.content));
        const connectRightOpts = _.find(question.rightOpts, (c) => expTable.test(c.content));
        if (connectLeftOpts !== undefined || connectRightOpts !== undefined) {
          $scope.errorForEmpty = true;
          notificationService.notify('error', '匹配题中暂不支持插入表格')
          $scope.errorAnchor(idx);
          return false;
        }
        //去除leftOpt和rightOpt多余的回车
        _.forEach(question.leftOpts, function(c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
        _.forEach(question.rightOpts, function(c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
      }
    }
    //
    // 当前问题已经修改时要保存测试
    if (!angular.equals($scope.currentQuestion, question)) {
      $scope.saveQuestion(question);
    }

    $scope.currentQuestion = null;
    $scope.editingQuestion = null;

    return true;
  };
  $scope.saveQuestion = (question) => {
    if (question.type !== 'synthetic' && question.type !== 'fill') {
      localStorageService.set('lastQuestionPoint', question.point2);
    } else {
      localStorageService.set('lastQuestionPoint', 1);
    }
    let p;
    if (_.has(question, 'id')) {
      p = oedObjectiveQuestion.updateAnswer(question).$promise;
    } else {
      p = oedObjectiveQuestion.createForTest({
        question: question,
        testId: $scope.currentTest.id
      }).$promise.then((q) => {
        $scope.childQuestionList = q.childQuestions
        return loadCloudQuestionByQId(q.id).then(() => q);
      });
    }

    $scope.loading = p.then(function(q) {
      let original = question;
      if (_.has(question, 'id')) {
        original = _.find($scope.currentTest.questions, (q: any) => {
          return q.id === question.id;
        });
      }
      q.label = question.label;
      if (_.has(question, 'manualSort')) {
        q.manualSort = question.manualSort;
      }
      const idx = _.findIndex($scope.currentTest.questions, original);
      $scope.currentTest.questions[idx] = q;
      notificationService.notify('info', '保存成功')
    }).catch(function() {
      notificationService.notify('error', '保存失败')
    });
    return p;
  };

  function loadCloudQuestionByQId(qId) {
    return oedCloudQuestion.getByQuestionId({
      id: qId
    }).$promise.then((cq) => {
      $scope.cloudQuestions[cq.resourceId] = cq;
    });
  }

  function loadTestCloudQuestions(questions) {
    const ids = _(questions).map('id').filter().value();
    $scope.cloudQuestions = {};

    if (_.isEmpty(ids)) {
      return $q.resolve({});
    }

    if (questions &&
      questions.length === 1 &&
      questions[0].type === 'synthetic' &&
      _.isEmpty(questions[0]['rawBody']['resourceUUIDs']) &&
      questions[0]['resourceUUIDs']
    ) {
      $scope.imageRes = questions[0]['resourceUUIDs']
      $scope.childQuestionList = questions[0].childQuestions
    }

    return oedCloudQuestion.queryByQuestionIds({
      ids: ids
    }).$promise.then(function(cqs) {
      $scope.cloudQuestions = _.keyBy(cqs, 'resourceId');
      return $scope.cloudQuestions;
    });
  }
  $scope.isQuickCreate = () => {
    return isQuickWayToCreatePrepareCourse($scope.currentTest)
  }
  $scope.saveQuickPrepareCourseQuestion = (t, replace, needNotify) => {
    if (_.has($scope.currentTest.questions[0], 'id')) {
      const arr = _.map($scope.currentTest.questions[0].childQuestions, 'answer')
      if (_.indexOf(arr, '-1') > -1 || _.indexOf(arr, '') > -1) {
        return notificationService.notify('info', '请设置答案')
      } else {
        return oedObjectiveQuestion.get({id: $scope.currentTest.questions[0].id}).$promise.then((oldQuestion) => {
          const oldChildQuestions = oldQuestion.childQuestions
          const newChildQuestions = $scope.currentTest.questions[0].childQuestions
          const needDeleted = _.filter(oldChildQuestions, q =>
            _.isEmpty(_.filter(newChildQuestions, nq => nq.id && nq.id === q.id))
          )

          const childs = _.map(needDeleted, (r) => {
            if (r.id) {
              return oedObjectiveQuestion.removeFromTest({
                testId: -1,
                questionId: r.id
              }).$promise
            }
          })

          if (!_.isEmpty(childs)) {
            return $q.all(childs)
          }
        }).then(() => oedObjectiveQuestion.save($scope.currentTest.questions[0]).$promise.then((newQuestion) => {
          $scope.currentTest.questions[0] = newQuestion
          return oedObjectiveTest.save(angular.copy($scope.currentTest)).$promise.then((res) => {
            if (res.questions && res.questions.length === 1) {
              $scope.childQuestionList = res.questions[0].childQuestions
            }
            if (!_.isEqual(tmpList, res.questions[0].childQuestions) || needNotify) {
              notificationService.notify('info', '试卷保存成功')
            }
            if (t) {
              $scope.stateGoPath(t, replace)
            }
          })
        }))
      }
    }
  }
  $scope.saveTest = function(noAlert) {
    if ($scope.isQuickCreate()) {
      if ($scope.childQuestionList.length > 0) {
        $scope.saveQuickPrepareCourseQuestion('', '', true)
      } else {
        return notificationService.notify('info', '请设置答案后再保存')
      }
    } else {
      //
      // 点保存按钮时有可能要保存正在编辑的问题
      // 这种情况下应当等问题保存完毕后才保存整个试卷
      $scope.loading = $q.when($scope.loading).then(function() {
        const hasNewQuestion = _.findIndex($scope.currentTest.questions, function(q) {
          return !_.get(q, 'id');
        }) >= 0;
        let save = null;
        //
        // 当有新问题时保存需要更新测试的Model(因为会同时创建问题)
        if (hasNewQuestion) {
          save = $scope.currentTest.$save().then((t) => {
            return loadTestCloudQuestions(t.questions).then(() => t);
          });
        }else {
          save = oedObjectiveTest.save(angular.copy($scope.currentTest)).$promise;
        }
        return save.then(function(test) {
          $scope.currentTest = test;
          if (!noAlert) {
            notificationService.notify('info', '试卷保存成功')
          }
        }).catch(function(error) {
          const errCode = _.get(error, 'status', 500);
          if (errCode == 401 || errCode == 403) {
            return notificationService.notify('info', '您的登录状态已改变，请重新登录!')
          }
          notificationService.notify('error', '试卷保存失败，请联系锐学堂技术支持!')
        });
      })
    }
  };
  $scope.backCurrentQuestion = function() {
    if ($scope.errorForEmpty) {
      $scope.errorForEmpty = false;
    }
  };
  $scope.isAllQuestionValid = function(questions) {
    const inValid = _.findIndex(questions, function(ques: any) {
      if (ques.type === 'synthetic')
        return $scope.isAllQuestionValid(ques.childQuestions);
      return !oedTestUtils.validateQuestion(ques, false).valid;
    });
    return inValid < 0;
  };
  $scope.showQuesitonError = function(q) {
    return $scope.errorForEmpty && $scope.editingQuestion === q;
  };
  $scope.sectionIdMap = {};
  $scope.courseSections.$promise.then((secs) => {
    const sectionIdMap = {};

    function visitSection(sec) {
      if (sec.id) {
        sectionIdMap[sec.id] = sec;
      }
      if (sec.child) {
        sec.child = _.sortBy(sec.child, ['sort']);
        _.each(sec.child, visitSection);
      }
    }
    _.each(secs, visitSection);

    $scope.sectionIdMap = sectionIdMap;
    $scope.treeData = oedMisc.utils.convertSectionsToJsTreeModel(sectionIdMap, secs);
    _.each($scope.treeData, (data) => {
      data.state.opened = true;
    });
  });
  $scope.getQuesIndex = (question) => {
    return question.label;
  };
  $scope.getAllQuestionSize = () => {
    let questionSize = 0;
    _.forEach($scope.currentTest.questions, (question) => {
      if (_.size(question.childQuestions) > 0) {
        questionSize += question.childQuestions.length;
      } else {
        questionSize ++;
      }
    })
    return questionSize;
  }
  $scope.getQuestionGroup = () => {
    let index = 0;
    let length = 0;
    const groupList = [];
    let itemList = [];
    const allQuestionLength = $scope.getAllQuestionSize();

    _.forEach($scope.currentTest.questions, (question) => {
      if (_.size(question.childQuestions) > 0) {
        if (_.size(question.childQuestions) >= $scope.pageSize) {
          if (itemList.length > 0) {
            groupList.push(itemList);
            itemList = [];
            itemList.push(question);
            groupList.push(itemList);
            itemList = [];
            index = 0;
            length += question.childQuestions.length;
          } else {
            itemList.push(question);
            groupList.push(itemList);
            itemList = [];
            index = 0;
            length += question.childQuestions.length;
          }
        } else {
          index += question.childQuestions.length;
          length += question.childQuestions.length;
          if (index > $scope.pageSize) {
            groupList.push(itemList);
            itemList = [];
            index = 0;
            itemList.push(question);
            index += question.childQuestions.length;
          } else {
            itemList.push(question);
          }
        }
      } else {
        itemList.push(question);
        index ++;
        length ++;
      }

      if (index === $scope.pageSize) {
        groupList.push(itemList);
        itemList = [];
        index = 0;
      } else if (length === allQuestionLength) {
        if (itemList.length > 0) {
          groupList.push(itemList);
        }
      }
    })
    $scope.questionGroup = groupList;
    return groupList;
  }

  $scope.currentPage = 0;

  $scope.showPaging = () => {
    return _.size($scope.questionGroup) > 1;
  };

  $scope.isPrevPageBtnEnabled = () => {
    return $scope.currentPage > 0;
  };

  $scope.isNextPageBtnEnabled = () => {
    return $scope.currentPage < _.size($scope.questionGroup) - 1;
  };

  $scope.getPrevPageBtnClass = () => {
    return $scope.isPrevPageBtnEnabled() ?
      'cursor-pointer ot-page-navigator-btn-enabled pre-hover' :
      'ot-page-navigator-btn-disabled';
  };

  $scope.getNextPageBtnClass = () => {
    return $scope.isNextPageBtnEnabled() ?
      'cursor-pointer ot-page-navigator-btn-enabled next-hover' :
      'ot-page-navigator-btn-disabled';
  };

  $scope.switchToPrevPage = () => {
    if (!$scope.isPrevPageBtnEnabled()) {
      return;
    }
    $scope.currentPage --;
  };

  $scope.switchToNextPage = () => {
    if (!$scope.isNextPageBtnEnabled()) {
      return;
    }
    $scope.currentPage ++;
  };

  $scope.switchToLastPage = () => {
    $scope.currentPage = (_.size($scope.questionGroup) - 1)
  };
}
