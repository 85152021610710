import * as _ from 'lodash'
require('assets/app/controllers/examination/examCardList/examCardList.scss');
require('assets/app/controllers/examination/examCardList/examCard/examCardCtrl');
import { openExamConfirmDialog } from 'assets/app/controllers/examination/examEdit/paper/paperUtils'

angular.module('app.examination.examCardList', [
    'app.examination.examCardList.examCard',
  ])
  .component('examCardList', {
    bindings: {
      coursepathId: '<',
      examCardDatas: '<',
      selectedSection: '<',
      selectedSectionRpid: '<',
    },
    controller: examCardListCtrl,
    template: < string > require('./examCardList.html'),
  })

examCardListCtrl.$inject = ['$state', 'oedExamination', 'notificationService', '$uibModal']
function examCardListCtrl($state, oedExamination, notificationService, $uibModal) {
  const ctrl = this
  ctrl.isShowAll = false
  ctrl.showText = '显示全部'
  ctrl.$onChanges = (changeObj: any) => {
    if (_.has(changeObj, 'selectedSection') && !_.isEmpty(ctrl.selectedSection)) {
      ctrl.isShowExamCardList = _.get(ctrl.selectedSection, 'itemType', 0) == 1
      // ctrl.isShowExamCardList = true
      ctrl.isShowAll = false
      showOnlyLineCard()
    }
  }

  ctrl.reloadCardList = (cardDatas) => {
    ctrl.examCardDatas = cardDatas
  }

  ctrl.onCreateExam = () => {
    $state.go('createExamPaper', {
      coursepathId: ctrl.coursepathId,
      sectionId: _.get(ctrl.selectedSection, 'id', ''),
      sectionParentId: _.get(ctrl.selectedSection, 'parentId', ''),
      sectionRootId: ctrl.selectedSectionRpid,
      levelId: _.get(ctrl.selectedSection, 'levelId', ''),
      relId: _.get(ctrl.selectedSection, 'relId', '')
    })
  }

  function showOnlyLineCard() {
    if (document.getElementsByClassName('box-card-homework')[0]) {
      const boxWidth = document.getElementsByClassName('box-card-homework')[0].clientWidth
      ctrl.showCardNum = Math.floor(boxWidth / 222) - 1
    }
  }

  ctrl.onShowAll = () => {
    ctrl.isShowAll = !ctrl.isShowAll
    if (ctrl.isShowAll) {
      ctrl.showText = '收起全部'
      ctrl.showCardNum = ctrl.examCardDatas.length
    } else {
      ctrl.showText = '显示全部'
      showOnlyLineCard()
    }
  }

  ctrl.onDeleteExamItem = (cardData: any) => {
    event.stopPropagation();
    const onYes = () => {
      const examId = _.get(cardData, 'exam.id', -1)
      if (examId === -1) {
        notificationService.notify('error', '删除试卷出错请稍后再试')
        return
      }
      ctrl.loading = oedExamination.deleteExamTest({ examId }).$promise
        .then(() => {
          const sectionId = _.get(ctrl.selectedSection, 'id', '')
          return oedExamination.getExamSections({ sectionId }).$promise
            .then((res) => {
              ctrl.reloadCardList(res)
              notificationService.notify('info', '已将试卷移入回收站')
            })
        })
    }
    openExamConfirmDialog($uibModal, '是否将该试卷移入回收站？', '删除试卷', '是', '否', onYes)
  }
}
