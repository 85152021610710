(function() {
  require('assets/styles/courseList.scss');
  angular.module('app.prepareLesson').config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('prepareLesson.courseList', {
      url: '/:cpId',
      template: require('assets/templates/courseList.html'),
      controller: 'courseListCtrl',
      bodyStyle: 'bged2'
    });
  }]).controller('courseListCtrl', courseListCtrl)
    .controller('collectionDepotDialogCtrl', collectionDepotDialogCtrl);

  courseListCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal',
    '$animate', '$q', 'dialogs', 'localStorageService', 'notificationService',
    'resize', 'userInfo', 'oedCoursePath', 'oedRecommendCourse',
    'oedCourse', 'oedSchoolTerm','$window', 'oedUserInfo']
  function courseListCtrl($scope, $state, $stateParams, $uibModal,
    $animate, $q, dialogs, localStorageService, notificationService,
    resize, userInfo, oedCoursePath, oedRecommendCourse,
    oedCourse, oedSchoolTerm, $window, oedUserInfo) {
    // 判断顶层窗口是否为iframe(配合桌面端使用)
    $scope.topWindowIsIframe = $window.top === $window.self;
    $scope.courses = [];
    var cpId = $stateParams.cpId;
    $scope.cpId = cpId;
    $scope.loadComplete = false;
    $scope.showRc = localStorageService.get('showRc');
    userInfo.then(function(info) {
      $scope.uid = _.parseInt(info.uid);
      $scope.canStar = info.canStar;
    });
    function loadData() {
      $scope.courses = oedCourse.newQueryCourseListByPath({
        pathId: cpId
      });

      $scope.isList = localStorageService.get('isList');
      $scope.rcps = [];//找到的recommend coursepath对应的courses
      $scope.rcs = [];//选择的某个rcps的courses，当前是找到的第一个courses.length > 0的
      loadRecommendCourses();

      oedCoursePath.get({id: cpId}).$promise.then(function(c) {
        $scope.coursePath = c;
      });
      $scope.courses.$promise.then(function(res) {
        $scope.ensureLastItemVisible();
        focusLastSelectedCourse(true);
        $scope.loadComplete = true;
      });

      $scope.loading = $scope.courses.$promise;

      localStorageService.set('lastCoursePathId', cpId);
    }
    if (cpId) {
      loadData();
    }

    function loadRecommendCourses() {
      return oedRecommendCourse.queryRecommendCourses({id: cpId}).$promise.then(function(result) {
        $scope.rcps = _.map(result, function(rcp) {
          return oedCourse.newQueryCourseListByPath({
            pathId: rcp.coursepathId
          });
        });

        var allPromise = _.map($scope.rcps, function(rcp) {
          return rcp.$promise;
        });

        return $q.all(allPromise).then(function() {
          var rcIdx = _.findIndex($scope.rcps, function(rcp) {
            return rcp.length > 0;
          });

          $scope.rcs = $scope.rcps[rcIdx];
        });
      });
    }

    $scope.viewIndex = 0;

    var boxWidth = 271; //hard code here

    $scope.getCourseListStyle = function() {
      var width = _.isEmpty($scope.courses) ? '1200' : (_.size($scope.courses) * boxWidth);
      if (width < 1200)
        width = 1200;

      return {
        width: '' + width + 'px'
      };
    };

    $scope.getCourseStyle = function() {
      var offset = '-' + ($scope.viewIndex * boxWidth) + 'px';
      return {
        left: offset
      };
    };

    $scope.computeMaxCourseDisplayCount = function() {
      var width = $('#gundong').width();
      return Math.floor(width / boxWidth);
    };

    $scope.$on('resize', function() {
      $scope.computeMaxCourseDisplayCount();
    });

    $scope.next = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount();
      if ($scope.viewIndex + maxCount >= _.size($scope.courses))
        return;
      $scope.viewIndex++;
    };

    $scope.ensureLastItemVisible = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount();
      var totalCount = _.size($scope.courses);
      $scope.viewIndex = totalCount - maxCount;
      if ($scope.viewIndex < 0)
        $scope.viewIndex = 0;
    };
    $scope.togglePrepareCouseStatus = function () {
      oedUserInfo.updateNewPrepareLesson({uid:$scope.uid,value:"1"}).$promise.then(res=>{
        localStorageService.set('isPreLesson',true);
        $state.go('prepareLesson.newCourseList', {
          cpId: $scope.cpId
        });
      })
    }
    $scope.prev = function() {
      if ($scope.viewIndex <= 0)
        return;

      $scope.viewIndex--;
    };

    $scope.deleteCourse = function(cs) {
      var dlg = dialogs.confirm('确定删除?', '确定要删除课程: "' + cs.courseName + '"吗');
      dlg.result.then(function(btn) {
        cs.$delete(function(cs) {
          if ($scope.coursePath.templateType && $scope.canStar) {
            var removecourse = oedCoursePath.removeCourseFromTemplate({id: cs.id}).$promise;
          }
          _.remove($scope.courses, cs);

          if ($scope.viewIndex + 4 >= _.size($scope.courses))
            $scope.viewIndex = _.max([0, _.size($scope.course) - 4]);
        });
      });
    };
    $scope.labelMaxCount = function() {
      var width2 = $('#gundong').width();
      return Math.floor(width2 / 271);
    };
    $scope.editCourse = function(cs) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/editcourse.html'),
        controller: 'editCourseCtrl',
        size: 'sm',
        resolve: {
          course: function() {
            return cs;
          }
        }
      });

      modalInstance.result.then(function(r) {});
    };
    $scope.currentPagerIndex = 0;
    $scope.swiper2 = {swiper: {}};
    $scope.showLeftScrollArrow = true;
    $scope.showRightScrollArrow = true;
    // $scope.noNeedDigest = false;
    $scope.swiperParams2 = {
      keyboardControl: true,
      mousewheelControl: true,
      mousewheelForceToAxis: true,
      slidesPerView: 'auto',
      scrollbar: '.course-scroll',
      scrollbarHide: false,
      scrollbarDraggable: true,
      onTransitionStart: function() {
        $scope.popup = 0;
        $scope.currentPagerIndex = $scope.swiper2.swiper.activeIndex;
        setSwiperArrowVisible($scope.currentPagerIndex);
        // if (!$scope.noNeedDigest) $scope.$digest();
        // $scope.noNeedDigest = false;
      }
    }
    function setSwiperArrowVisible(pageIndex) {
      if (isNaN(pageIndex)) return;
      if (isNaN($scope.indexMax)) {
        $scope.indexMax = _.size($scope.courses) - $scope.labelMaxCount();
      }
      if (pageIndex >= $scope.indexMax) {
        $scope.showRightScrollArrow = false;
      } else {
        $scope.showRightScrollArrow = true;
      }
      if (pageIndex <= 0) {
        $scope.showLeftScrollArrow = false;
      } else {
        $scope.showLeftScrollArrow = true;
      }
    }
    $scope.courseOrderUpperOnce = function(course, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if ($index <= 0) return;
      var newOrders = _.map($scope.courses, function(c, index) {
        if (c.id == course.id) {
          return _.assign(c, {sort: index * 2});
        }
        return _.assign(c, {sort: index * 2 + 3});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCourse.updateCourseOrder(items).$promise.then(() => {
        $scope.courses = _.sortBy(newOrders, 'sort');
        // $scope.noNeedDigest = true;
        $scope.swiper2.swiper.slidePrev();
        $scope.changingCourseOrder --;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    }
    $scope.onChangeOrderToggle = function(open) {
      if (!open) {
        $scope.changingCourseOrder = -1;
      }
    }
    $scope.courseOrderDownOnce = function(course, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if (($index + 1) >= _.size($scope.courses)) return;
      var newOrders = _.map($scope.courses, function(c, index) {
        if (c.id == course.id) {
          return _.assign(c, {sort: index * 2 + 3});
        }
        return _.assign(c, {sort: index * 2});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCourse.updateCourseOrder(items).$promise.then(() => {
        $scope.courses = _.sortBy(newOrders, 'sort');
        // $scope.noNeedDigest = true;
        $scope.swiper2.swiper.slideNext();
        $scope.changingCourseOrder ++;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    }
    $scope.cantUpper = false;
    $scope.cantDowner = false;
    $scope.changingCourseOrder = -1;
    $scope.onDisplayOrderChange = function(index) {
      $scope.changingCourseOrder = index;
      if (index <= 0) {
        $scope.cantUpper = true;
      } else {
        $scope.cantUpper = false;
      }
      if ((index + 1) >= _.size($scope.courses)) {
        $scope.cantDowner = true;
      } else {
        $scope.cantDowner = false;
      }
    };

    function focusLastSelectedCourse(replace) {
      var curCpMap = localStorageService.get('lastCoursePathCourseMap');
      var focusCourseId;
      if (curCpMap) {
        var curCpId = localStorageService.get('lastCoursePathId');
        focusCourseId = curCpMap[curCpId];
      }
      var focusCourse = null;
      if (!_.isEmpty($scope.courses)) {
        focusCourse = focusCourseId ? _.find($scope.courses, function(course) {
          return course.id == focusCourseId;
        }) : $scope.courses[(_.size($scope.courses) - 1)];
        focusCourse = focusCourse || $scope.courses[(_.size($scope.courses) - 1)];
      }
      $scope.setFocusCourse(focusCourse, replace);
    }

    $scope.setFocusCourse = function(course, replace) {
      if ($scope.isDone) {
        return;
      }

      var courseId = course ? course.id : null;

      $scope.viewIndex = _.findIndex($scope.courses, function(course) {
        return course.id == courseId;
      });

      ensureLabelVisibiltiy();
      setSwiperArrowVisible($scope.viewIndex);
    };

    $scope.$on('$destroy', function() {
      $scope.isDone = true;
    });
    function ensureLabelVisibiltiy() {
      if ($scope.viewIndex < 0) {
        $scope.viewIndex = (_.size($scope.courses) - 1);
      }

      var labelCount = $scope.computeMaxCourseDisplayCount();
      if ($scope.viewIndex + labelCount >= _.size($scope.courses)) {
        $scope.viewIndex = _.size($scope.courses) - labelCount;
      }
    }
    $scope.slideToLast = function() {
      // $scope.noNeedDigest = true;
      $scope.swiper2.swiper.slidePrev();
    }
    $scope.slideToNext = function() {
      // $scope.noNeedDigest = true;
      $scope.swiper2.swiper.slideNext();
    }

    $scope.toggleRecommendCourse = function() {
      if ($scope.showRc) {
        $scope.showRc = false;
      } else {
        $scope.showRc = true;
        $scope.loading = loadRecommendCourses();
      }

      localStorageService.set('showRc', $scope.showRc);
    };

    $scope.onDropCourse = function() {
      var imported = _.filter($scope.courses, function(course) {
        return course.coursepathId !== _.parseInt(cpId);
      });

      if (imported.length <= 0) {
        return;
      }

      var allPromise = _.map(imported, function(course) {
        return oedRecommendCourse.addRecommendCourseToCp({id: cpId, courseId: course.id}).$promise;
      });

      function reloadCourse() {
        return oedCourse.queryByPath({pathId: cpId}).$promise.then(function(result) {
          $scope.courses = result;
        });
      }

      $scope.loading = $q.all(allPromise).then(function() {
          return reloadCourse();
        },
        function() {
          notificationService.notify('info', '导入课程失败')
          return reloadCourse();
        });
    };

    $scope.collectionDepot = () => {
      var modalInstance = $uibModal.open({
        template: require('assets/app2/prepareCourse/collectionDepotDialog/collectionDepotDialog.html'),
        controller: 'collectionDepotDialogCtrl',
        size: 'lg',
        windowClass: 'collectionModal',
        resolve: {
          cpId: function() {
            return $scope.cpId;
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r) {
          loadData();
        }
      });
    };

    $scope.switchClick = () => {
      $scope.isList = !$scope.isList
      localStorageService.set('isList', $scope.isList)
    }
  }
  collectionDepotDialogCtrl.$inject = ['$scope', '$state', '$stateParams', 'cpId', '$uibModal', '$uibModalInstance',
    '$animate', '$q', 'dialogs', 'localStorageService', 'notificationService',
    'resize', 'userInfo', 'oedCoursePath', 'oedRecommendCourse',
    'oedCourse', 'oedSchoolTerm']
  function collectionDepotDialogCtrl($scope, $state, $stateParams, cpId, $uibModal, $uibModalInstance,
                          $animate, $q, dialogs, localStorageService, notificationService,
                          resize, userInfo, oedCoursePath, oedRecommendCourse,
                          oedCourse, oedSchoolTerm) {
    $scope.swiper = {};
    $scope.currentPagerIndex = 0;
    $scope.displaySwiperSideLeft = false;
    $scope.displaySwiperSideRight = true;
    $scope.currentCpId = cpId;
    $scope.swiperParams = {
      slidesPerView: 'auto',
      mousewheelControl: true,
      onTransitionStart: function() {
        $scope.popup = 0;
        $scope.currentPagerIndex = $scope.swiper.activeIndex;
        setSwiperSideBtnVisible($scope.currentPagerIndex);
      }
    };
    $scope.courses = [];
    $scope.loadComplete = false;
    $scope.info = {};
    userInfo.then(function(info) {
      $scope.canStar = info.canStar;
      _.assign($scope.info, _.pick(info, ['name', 'uid', 'isSchoolAdmin']));
      function updateCoursePath() {
        $scope.loadingCp = true;
        $scope.coursePaths = oedCoursePath.queryDeleteFilter();

        updateCurrentCoursePath(true);

        return $scope.coursePaths.$promise;
      }

      $scope.loading = $q(function(resolve, reject) {
        userInfo.then(function(info) {
          $scope.terms = oedSchoolTerm.queryBySchool({
            schoolId: info.schoolId
          });
          $scope.showTemplate = info.canStar;
          $scope.terms.$promise.then(function(terms) {
            //
            // find current term
            var curTime = new Date().getTime();
            $scope.currentTerm = _.find(terms, function(t) {
              return t.startTime <= curTime && curTime <= t.endTime;
            });

            return updateCoursePath().then(function() {
              if (!$scope.showTemplate) {
                return;
              }
              var isRecommendCoursePaths = _.map($scope.coursePaths, function(cp) {
                return oedRecommendCourse.queryIsRecommendCourse({id: cp.id}).$promise;
              });

              return $q.all(isRecommendCoursePaths).then(function(results) {
                for (let i = 0; i < results.length; i++) {
                  $scope.coursePaths[i].isRc = results[i].isRc;
                }
              });
            });

          }).finally(resolve);
        });
      });

      $scope.toggleShowComplete = function(show) {
        if ($scope.showCompleted == show) return;

        $scope.showCompleted = show;
        updateCoursePath();
      };
    });

    $scope.getCourses = function() {
      $scope.courses = oedCourse.queryDeleteCourseByPath({
        id: $scope.currentCpId
      });

      $scope.isList = localStorageService.get('isList');
      $scope.rcps = [];//找到的recommend coursepath对应的courses
      $scope.rcs = [];//选择的某个rcps的courses，当前是找到的第一个courses.length > 0的
      loadRecommendCourses();
      oedCoursePath.get({id: $scope.currentCpId}).$promise.then(function(c) {
        $scope.coursePath = c;
      });

      $scope.courses.$promise.then(function(res) {
        $scope.currentPagerIndex3 = 0;

        $scope.ensureLastItemVisible();
        // $scope.setFocusCourse($scope.courses[_.size($scope.courses) - 1], true);
        $scope.setFocusCourse($scope.courses[0], true);
        $scope.loadComplete = true;
      });

      $scope.loading = $scope.courses.$promise;
    };

    function loadRecommendCourses() {
      return oedRecommendCourse.queryRecommendCourses({id: $scope.currentCpId}).$promise.then(function(result) {
        $scope.rcps = _.map(result, function(rcp) {
          return oedCourse.queryByPath({
            pathId: rcp.coursepathId
          });
        });

        var allPromise = _.map($scope.rcps, function(rcp) {
          return rcp.$promise;
        });

        return $q.all(allPromise).then(function() {
          var rcIdx = _.findIndex($scope.rcps, function(rcp) {
            return rcp.length > 0;
          });

          $scope.rcs = $scope.rcps[rcIdx];
        });
      });
    }

    $scope.viewIndex = 0;

    var boxWidth = 271; //hard code here

    $scope.getCourseListStyle = function() {
      var width = _.isEmpty($scope.courses) ? '1200' : (_.size($scope.courses) * boxWidth);
      if (width < 1200)
        width = 1200;

      return {
        width: '' + width + 'px'
      };
    };

    $scope.getCourseStyle = function() {
      var offset = '-' + ($scope.viewIndex * boxWidth) + 'px';
      return {
        left: offset
      };
    };

    $scope.computeMaxCourseDisplayCount3 = function() {
      var width = $('#gundong3').width();
      return Math.floor(width / boxWidth);
    };

    $scope.$on('resize', function() {
      $scope.computeMaxCourseDisplayCount3();
    });

    $scope.next = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount3();
      if ($scope.viewIndex + maxCount >= _.size($scope.courses))
        return;
      $scope.viewIndex++;
    };

    $scope.ensureLastItemVisible = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount3();
      var totalCount = _.size($scope.courses);
      $scope.viewIndex = totalCount - maxCount;
      if ($scope.viewIndex < 0)
        $scope.viewIndex = 0;
    };

    $scope.prev = function() {
      if ($scope.viewIndex <= 0)
        return;

      $scope.viewIndex--;
    };

    $scope.recoveryCourse = function(cs) {
      var dlg = dialogs.confirm('确定恢复?', '确定要恢复课程: "' + cs.courseName + '"吗?');
      dlg.result.then(function(btn) {
        cs.$delete(function(cs) {
          oedCourse.recoveryCourse({id: cs.id}).$promise.then(res => {
            _.remove($scope.courses, cs);
            if (!_.size($scope.courses)) {
              _.remove($scope.coursePaths, (cp) => {
                return cp.id === $scope.currentCpId;
              });
              $scope.currentCpId = $scope.coursePaths[0].id;
            }
            updateCurrentCoursePath(true);
          });
          notificationService.notify('info', '恢复成功');
          if ($scope.viewIndex + 3 >= _.size($scope.courses))
            $scope.viewIndex = _.max([0, _.size($scope.course) - 3]);
        });
      });
    };
    $scope.deleteCourse = function(cs) {
      var dlg = dialogs.confirm('确定彻底删除?', '确定要彻底删除课程: "' + cs.courseName + '"吗?');
      dlg.result.then(function(btn) {
        cs.$delete(function(cs) {
          oedCourse.finalDeleteCourse({id: cs.id}).$promise.then(res => {
            _.remove($scope.courses, cs);
            if (!_.size($scope.courses)) {
              _.remove($scope.coursePaths, (cp) => {
                return cp.id === $scope.currentCpId;
              });
              $scope.currentCpId = $scope.coursePaths[0].id;
            }
            updateCurrentCoursePath(true);
          });
          notificationService.notify('info', '删除成功');
          if ($scope.viewIndex + 3 >= _.size($scope.courses))
            $scope.viewIndex = _.max([0, _.size($scope.course) - 3]);
        });
      });
    };
    $scope.labelMaxCount3 = function() {
      var width2 = $('#gundong3').width();
      return Math.floor(width2 / 271);
    };

    function setSwiperArrowVisible3(pageIndex) {
      if (isNaN(pageIndex)) return;
      $scope.indexMax3 = _.size($scope.courses) - $scope.labelMaxCount3();
      if (pageIndex >= $scope.indexMax3) {
        $scope.showRightScrollArrow3 = false;
      } else {
        $scope.showRightScrollArrow3 = true;
      }
      if (pageIndex <= 0) {
        $scope.showLeftScrollArrow3 = false;
      } else {
        $scope.showLeftScrollArrow3 = true;
      }
    }
    $scope.courseOrderUpperOnce = function(course, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if ($index <= 0) return;
      var newOrders = _.map($scope.courses, function(c, index) {
        if (c.id == course.id) {
          return _.assign(c, {sort: index * 2});
        }
        return _.assign(c, {sort: index * 2 + 3});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCourse.updateCourseOrder(items).$promise.then(() => {
        $scope.courses = _.sortBy(newOrders, 'sort');
        $scope.swiper3.swiper.slidePrev();
        $scope.changingCourseOrder --;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    }
    $scope.onChangeOrderToggle = function(open) {
      if (!open) {
        $scope.changingCourseOrder = -1;
      }
    }
    $scope.courseOrderDownOnce = function(course, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if (($index + 1) >= _.size($scope.courses)) return;
      var newOrders = _.map($scope.courses, function(c, index) {
        if (c.id == course.id) {
          return _.assign(c, {sort: index * 2 + 3});
        }
        return _.assign(c, {sort: index * 2});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCourse.updateCourseOrder(items).$promise.then(() => {
        $scope.courses = _.sortBy(newOrders, 'sort');
        $scope.swiper3.swiper.slideNext();
        $scope.changingCourseOrder ++;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    }
    $scope.cantUpper = false;
    $scope.cantDowner = false;
    $scope.changingCourseOrder = -1;
    $scope.viewIndex = 0;
    $scope.onDisplayOrderChange = function(index) {
      $scope.changingCourseOrder = index;
      if (index <= 0) {
        $scope.cantUpper = true;
      } else {
        $scope.cantUpper = false;
      }
      if ((index + 1) >= _.size($scope.courses)) {
        $scope.cantDowner = true;
      } else {
        $scope.cantDowner = false;
      }
    };

    $scope.setFocusCourse = function(course, replace) {
      if ($scope.isDone) {
        return;
      }

      var courseId = course ? course.id : null;

      $scope.viewIndex = _.findIndex($scope.courses, function(course) {
        return course.id == courseId;
      });

      ensureLabelVisibiltiy3();
      setSwiperArrowVisible3($scope.viewIndex);
    };

    $scope.$on('$destroy', function() {
      $scope.isDone = true;
    });
    function ensureLabelVisibiltiy3() {
      if ($scope.viewIndex < 0) {
        $scope.viewIndex = (_.size($scope.courses) - 1);
      }

      var labelCount = $scope.computeMaxCourseDisplayCount3();
      if ($scope.viewIndex + labelCount >= _.size($scope.courses)) {
        $scope.viewIndex = _.size($scope.courses) - labelCount;
      }
    }
    $scope.slideToLast3 = function() {
      $scope.swiper3.swiper.slidePrev();
    }
    $scope.slideToNext3 = function() {
      $scope.swiper3.swiper.slideNext();
    }

    $scope.onDropCourse = function() {
      var imported = _.filter($scope.courses, function(course) {
        return course.coursepathId !== _.parseInt($scope.currentCpId);
      });

      if (imported.length <= 0) {
        return;
      }

      var allPromise = _.map(imported, function(course) {
        return oedRecommendCourse.addRecommendCourseToCp({id: $scope.currentCpId, courseId: course.id}).$promise;
      });

      function reloadCourse() {
        return oedCourse.queryByPath({pathId: $scope.currentCpId}).$promise.then(function(result) {
          $scope.courses = result;
        });
      }

      $scope.loading = $q.all(allPromise).then(function() {
          return reloadCourse();
        },
        function() {
          notificationService.notify('info', '导入课程失败')
          return reloadCourse();
        });
    }

    $scope.onSlideHover = function($event, cp) {
      $scope.hoverCp = cp;
      if (!$scope.showTemplate) {
        return;
      }

      if ($scope.hoverCp.isRc != undefined) {
        $scope.pageX = $event.pageX;
        $scope.pageY = $event.pageY;
        $scope.popup = 1;
      } else {
        $scope.loading = oedRecommendCourse.queryIsRecommendCourse({id: cp.id}).$promise.then(function(ret) {
          $scope.hoverCp.isRc = ret.isRc;
          $scope.pageX = $event.pageX;
          $scope.pageY = $event.pageY;
          $scope.popup = 1;
        });
      }
    };

    $scope.onSlideLeave = function($event) {
      if (!$scope.showTemplate) {
        return;
      }

      var nameFieldWidth = 185;
      var linkFieldWidth = 40;
      if ($scope.lastLeft >= 10000 || $event.pageX >= $scope.lastLeft + nameFieldWidth ||
        $event.pageX < $scope.lastLeft + linkFieldWidth || $scope.pageY > $event.pageY) {
        $scope.popup = 0;
        $scope.lastLeft = 10000;
      }
    };

    $scope.strLength = function(str) {
      return _.size(str);
    };

    $scope.gotoList = function(cp, opt) {
      $scope.currentCpId = cp.id;
      updateCurrentCoursePath(true);
    }

    //增加coursePaths的滚动
    var labelWidth = 197; // 每个标签的宽度 每次要移动的宽度为197px

    $scope.labelMaxCount = function() {
      var width2 = $('#gundong2').width();
      return Math.round(width2 / labelWidth);
    };

    function updateCurrentCoursePath(replace) {
      $scope.coursePaths.$promise.then(function(cp) {
        $scope.loadingCp = false;
        var curCp = null;
        if (!_.isEmpty($scope.coursePaths)) {
          curCp = $scope.currentCpId ? _.find($scope.coursePaths, function(cp) {
            return cp.id == $scope.currentCpId;
          }) : $scope.coursePaths[0];
          curCp = curCp || $scope.coursePaths[0];
          $scope.currentCpId = $scope.cpId = curCp.id;
          $scope.getCourses();
        }
        $scope.setCurrentCP(curCp, replace);
      });
    }
    function setSwiperSideBtnVisible(pageIndex) {
      if (isNaN(pageIndex)) return;
      $scope.maxIndex = _.size($scope.coursePaths) - $scope.labelMaxCount();
      if (pageIndex >= $scope.maxIndex) {
        $scope.displaySwiperSideRight = false;
      } else {
        $scope.displaySwiperSideRight = true;
      }
      if (pageIndex <= 0) {
        $scope.displaySwiperSideLeft = false;
      } else {
        $scope.displaySwiperSideLeft = true;
      }
    }
    $scope.scrollToTop = function() {
      // $scope.noNeedDigest = true;
      $scope.swiper.slideTo(0);
    };
    $scope.scrollToBottom = function() {
      $scope.maxIndex = _.size($scope.coursePaths) - $scope.labelMaxCount();
      $scope.swiper.slideTo($scope.maxIndex);
    };
    function ensureLabelVisibiltiy() {
      if ($scope.labelIndex < 0) {
        $scope.labelIndex = 0;
      }

      var labelCount = $scope.labelMaxCount();
      if ($scope.labelIndex + labelCount >= _.size($scope.coursePaths)) {
        $scope.labelIndex = _.size($scope.coursePaths) - labelCount;
      }
    }

    $scope.labelPrev = function() {
      if ($scope.labelIndex <= 0) {
        return;
      } else {
        $scope.labelIndex--;
      }
    };
    $scope.setCurrentCP = function(cp, replace) {
      if ($scope.isDone) {
        return;
      }

      var cpIds = cp ? cp.id : null;

      var opt = replace ? {
        location: 'replace'
      } : null;

      $scope.labelIndex = _.findIndex($scope.coursePaths, function(cp) {
        return cp.id == cpIds;
      });
      $scope.loadComplete = false;
      $scope.swiper3 = {swiper: {}};
      $scope.showLeftScrollArrow3 = true;
      $scope.showRightScrollArrow3 = true;
      $scope.swiperParams3 = {
        keyboardControl: true,
        mousewheelControl: true,
        mousewheelForceToAxis: true,
        slidesPerView: 'auto',
        scrollbar: '.course-scroll2',
        scrollbarHide: false,
        scrollbarDraggable: true,
        onTransitionStart: function() {
          $scope.popup = 0;
          $scope.currentPagerIndex3 = $scope.swiper3.swiper.activeIndex;
          setSwiperArrowVisible3($scope.currentPagerIndex3);
        }
      };
      setSwiperSideBtnVisible($scope.labelIndex);
      ensureLabelVisibiltiy3();
    };

    $scope.cantUpper = false;
    $scope.cantDowner = false;
    $scope.changingCourseOrder = 0;
    $scope.onDisplayOrderChange = function(index) {
      $scope.changingCourseOrder = index;
      if (index <= 0) {
        $scope.cantUpper = true;
      } else {
        $scope.cantUpper = false;
      }
      if ((index + 1) >= _.size($scope.coursePaths)) {
        $scope.cantDowner = true;
      } else {
        $scope.cantDowner = false;
      }
    };
    $scope.orderUpperOnce = function(cp, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if ($index <= 0) return;
      $scope.changingCourseOrder = $index;
      var newOrders = _.map($scope.coursePaths, function(c, index) {
        if (c.id == cp.id) {
          return _.assign(c, {sort: index * 2});
        }
        return _.assign(c, {sort: index * 2 + 3});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCoursePath.updateCoursePathOrder(items).$promise.then(() => {
        $scope.coursePaths = _.sortBy(newOrders, 'sort');
        // $scope.noNeedDigest = true;
        $scope.swiper.slidePrev();
        $scope.changingCourseOrder --;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    };
    $scope.orderDownOnce = function(cp, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if (($index + 1) >= _.size($scope.coursePaths)) return;
      $scope.changingCourseOrder = $index;
      var newOrders = _.map($scope.coursePaths, function(c, index) {
        if (c.id == cp.id) {
          return _.assign(c, {sort: index * 2 + 3});
        }
        return _.assign(c, {sort: index * 2});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCoursePath.updateCoursePathOrder(items).$promise.then(() => {
        $scope.coursePaths = _.sortBy(newOrders, 'sort');
        // $scope.noNeedDigest = true;
        $scope.swiper.slideNext();
        $scope.changingCourseOrder ++;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    };
    $scope.onChangeOrderToggle = function(open) {
      if (!open) {
        $scope.changingCourseOrder = -1;
      }
    };
    $scope.cancel = function() {
      $uibModalInstance.close(true);
    };
  }
})();
