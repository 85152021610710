/**
 * @fileOverview
 * @name objectiveTestSessionsCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('tmSessions', {
        url: '/review/:classSessionId/course/:courseId/tm/:tmId',
        template: require('assets/templates/review/tmSessions.html'),
        controller: 'tmSessionsCtrl',
        bodyStyle: 'bged2'
      });
    }])
    .controller('tmSessionsCtrl', tmSessionsCtrl);

  tmSessionsCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', '$animate', '$q', 'oedTeachingModule',
    'dialogs', 'resize', 'userInfo', 'oedCoursePath', 'oedClassSession', 'oedCourse', 'oedTestSession', 'oedTmSession']
  function tmSessionsCtrl($scope, $state, $stateParams, $uibModal, $animate, $q, oedTeachingModule,
    dialogs, resize, userInfo, oedCoursePath, oedClassSession, oedCourse, oedTestSession, oedTmSession) {
    $scope.currentState = {
      title: '环节推送'
    };
    $scope.courseId = _.parseInt($stateParams.courseId);
    $scope.tmId = _.parseInt($stateParams.tmId);
    $scope.classSessionId = _.parseInt($stateParams.classSessionId);

    $scope.shouldBeActive = function(obj, params) {
      return $state.includes(obj, params);
    };

    $scope.classSession = oedClassSession.get({
      id: $scope.classSessionId
    });

    const mapSession2Test = (tmSession, listSbjTestSession) => {
      const boardSessionMap = _.keyBy(listSbjTestSession, 'id')
      _.each(tmSession.objSessions, (objSession) => {
        objSession.name = $scope.objMap[objSession.testId].name
        objSession.sort = $scope.objMap[objSession.testId].sort
      })
      _.each(tmSession.boardSessions, (boardSession) => {
        boardSession.testId = boardSessionMap[boardSession.testSessionId].testId
        boardSession.name = $scope.sbjMap[boardSession.testId].name
        boardSession.sort = $scope.sbjMap[boardSession.testId].sort
      })
      _.each(tmSession.preresSessions, (preresSession) => {
        preresSession.name = $scope.preresMap[preresSession.preResId].name
        preresSession.sort = $scope.preresMap[preresSession.preResId].sort
      })
    }

    const loadTmSessionsDetail = () => {
      return oedTmSession.queryByTmId({
        classSessionId: $scope.classSessionId,
        tmId: $scope.tmId
      }).$promise.then((tmSessions) => {
        $scope.tmSessions = tmSessions
        return Promise.map(tmSessions, (session) => {
          return oedTmSession.getDetails({
            tmSessionId: session.id
          }).$promise.then((details) => {
            session.objSessions = details.listObjTestSession
            session.boardSessions = details.listBoardSession
            session.preresSessions = details.listPreResSession
            mapSession2Test(session, details.listSbjTestSession)
          })
        })
      })
    }

    $scope.gotoTmSession = function(session) {
      $scope.currentSelectSessionId = session.id;
      $state.go('tmSessions.summary', {
        tmSessionId: session.id
      }, {
        location: 'replace'
      });
    };
    $scope.selectSessionId = function(sessionId) {
      $scope.currentSelectSessionId = sessionId;
    };
    const loadTmTest = () => {
      return oedTeachingModule.get({
        id: $scope.tmId
      }).$promise.then((teachingModule) => {
        $scope.objMap = _.keyBy(teachingModule.objectiveTests, 'id')
        $scope.sbjMap = _.keyBy(teachingModule.subjectiveTests, 'id')
        $scope.preresMap = _.keyBy(teachingModule.presentResources, 'id')
      })
    }
    $scope.loading = $q.all([
      $scope.classSession.$promise,
      loadTmTest()
    ]).then(() => {
      return loadTmSessionsDetail()
    }).then(() => {
      if (!_.isEmpty($scope.tmSessions) && !$state.params.sessionId) {
        $scope.gotoTmSession($scope.tmSessions[0]);
      }
    })
  }
})();
