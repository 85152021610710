export const maxFillAnswerLength = 20
export const answerTypeNames = {
  'exact-match': '精确匹配',
  'keyword-match': '单一关键字匹配',
  'keyword-match-all': '全部关键字匹配',
  'manual-score': '老师评判'
}

export const answerTypeTips = {
  'exact-match': '精确匹配：学生答案需跟所给答案完全一致才正确。\n' +
    '\n'+
    '例: ' +
    '我的学校是：____（精确匹配）\n' +
    '设置答案：昌平二中\n' +
    '学生答案：昌平二中',
  'keyword-match': '单一关键字：学生答案只要包含所给的关键字就算正确。\n' +
    '\n'+
    '例： ' +
    '我的学校是：____（单一关键词）\n' +
    '设置答案：昌平二中；学校；（学生答案只需要包含其中一个关键字就正确）\n' +
    '学生答案：北京昌平二中；昌平二中；',
  'keyword-match-all': '全部关键字：学生答案必须同时包含这几个关键字才正确。\n' +
    '\n'+
    '例： ' +
    '我的学校是：____（全部关键字匹配）。\n' +
    '设置答案：北京；昌平二中；学校；（学生答案必须同时包含这几个关键字才正确）\n' +
    '学生答案：北京昌平二中学校',
  'manual-score': '老师评判：老师手动批改对错。\n' +
    '\n'+
    '例： ' +
    '我的学校是：____（老师评判）\n' +
    '不用设置答案',
}

