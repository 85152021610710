import * as _ from 'lodash'
require('assets/app/controllers/examination/examReport/examReportChart/qualityChart/qualityChart.scss')

angular.module('app.examination.examReport.chart.quality', [])
  .component('qualityChart', {
    bindings: {
      wholeQuesMap: '<',
      examSearch: '<',
    },
    controller: qualityChartCtrl,
    template: < string > require('./qualityChart.html'),
  })


qualityChartCtrl.$inject = []
function qualityChartCtrl() {
  const ctrl = this

  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'examSearch')) {
      buildQualityPaperInfo()
      buildQualityChartData()
    }
  }
  function buildQualityPaperInfo() {
    const overallInfo = _.get(ctrl.examSearch, 'studentPartData.0.data', {})
    ctrl.difficulty = _.round(_.get(overallInfo, 'difficulty', 0), 2)
    ctrl.discrimination = _.round(_.get(overallInfo, 'discrimination', 0), 2)
    ctrl.reliability = _.round(_.get(overallInfo, 'reliability', 0), 2)
    ctrl.validity = _.round(_.get(overallInfo, 'validity', 0), 2)
  }

  function buildQualityChartData() {
    const questionPartData = _.get(ctrl.examSearch, 'questionPartData', [])
    const notSortChartData = _.map(questionPartData, d => {
      return _.map(_.get(d, 'data.questionsData', []), qd => {
        const quesId = _.get(qd, 'questionId', {})
        const quesByWhole = _.get(ctrl.wholeQuesMap, quesId, {})
        const quesNum = _.get(quesByWhole, 'quesNum', '')
        const quesTypeByruanyu = _.get(quesByWhole, 'quesTypeByruanyu', '')
        const quesScore = _.get(quesByWhole, 'score', '')
        const sort = _.get(quesByWhole, 'quesIdx', '')
        const maxCount = getScoreCount(quesScore, quesByWhole, qd)
        const zeroCount = getScoreCount('0', quesByWhole, qd)
        const difficulty = _.round(_.get(qd, 'difficulty', 0), 2)
        const distinction = _.round(_.get(qd, 'discrimination', 0), 2)
        const variance = _.round(_.get(qd, 'variance', 0), 2)
        const deviation = _.round(_.get(qd, 'deviation', 0), 2)
        return { quesNum, quesTypeByruanyu, maxCount, zeroCount, difficulty, distinction, variance, deviation, sort,  quesScore, scoreDeatal:_.get(qd, 'scoreDetails', '')  }
      })
    })
    ctrl.chartData = _.sortBy(_.flatten(notSortChartData), data => _.get(data, 'sort', ''))
  }

  const sbjtypes = { fill: true, qa: true }
  function getScoreCount(quesScore: string, ques: any, qd: any[]) {
    const type = _.get(ques, 'type', '')
    const scoreDetails = _.get(qd, 'scoreDetails', '')
    const isSbj = _.get(sbjtypes, type, false)
    const objScore = _.get(ques, 'answer', '')
    let studentCount = 0
    for (let i = 0; i < scoreDetails.length; i++) {
      const detailScore = _.get(scoreDetails, `${i}.score`, '')
      if (isSbj && quesScore == detailScore) {
        studentCount += _.get(scoreDetails, `${i}.count`, '')
      }
      if (!isSbj && objScore == detailScore && quesScore != '0') {
        //  客观题满分人数
        studentCount += _.get(scoreDetails, `${i}.count`, '')    
      }
      if (!isSbj && objScore != detailScore && quesScore == '0') {
        //  客观题零分人数
        if (type === 'multichoice') {
          const detailScoreList = detailScore.split(',')
          const correctList = objScore.split(',')
          const hasWrongChoice = _.find(detailScoreList, c => correctList.indexOf(c) < 0);
          if (hasWrongChoice) {
            studentCount += _.get(scoreDetails, `${i}.count`, '')            
          }
        } else {
          studentCount += _.get(scoreDetails, `${i}.count`, '')
        }
      }
    }
    return studentCount
  }
}
