import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {flatMap} from 'rxjs/operators';
import {OnlineTrainingDirectoryDTO} from 'app/online-train/models/online-train';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import * as _ from 'lodash';
import { StateService } from '@uirouter/core'
import {forkJoin, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {TrainMassageService} from 'app/online-train/train-massage.service';
import {LegacyAppService} from 'app/core/legacy-app.service';

@Component({
  selector: 'app-train-dir-style4',
  template: require('./train-dir-style4.component.html'),
  styles: [require('./train-dir-style4.component.scss')]
})
export class TrainDirStyle4Component implements OnInit {
  @Input() public parentId
  @Input() public editable
  @Input() public prefix

  private dirs: OnlineTrainingDirectoryDTO[]
  private allDirs: OnlineTrainingDirectoryDTO[]
  private dirTree
  private dataToAdd: OnlineTrainingDirectoryDTO
  private itemType
  constructor(private onlineTrainService: OnlineTrainService,
              private state: StateService,
              private lagecyApp: LegacyAppService,
              private trainMessage: TrainMassageService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'parentId')) {
      this.parentId = changes.parentId.currentValue
    }
    if (_.has(changes, 'prefix')) {
      this.prefix = changes.prefix.currentValue
    }

    if (this.parentId > 0) {
      this.loadData()
    }
  }

  public onEdit(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      //
      this.onlineTrainService.addDir(params.item).pipe(
        (flatMap(() => this.onlineTrainService.getAllDirs(this.lagecyApp.getOem().curOem())))
      ).subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
        this.initData(dirs)
      }, (err) => {console.log(err)})
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      //
      this.onlineTrainService.updateDir(params.item).pipe(
        (flatMap(() => this.onlineTrainService.getAllDirs(this.lagecyApp.getOem().curOem())))
      ).subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
        this.initData(dirs)
      }, (err) => {console.log(err)})
    } else if (params.editType === this.onlineTrainService.DELETE) {
      //
      this.onlineTrainService.deleteDir(params.item.id).pipe(
        (flatMap(() => this.onlineTrainService.getAllDirs(this.lagecyApp.getOem().curOem())))
      ).subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
        this.initData(dirs)
      }, (err) => {console.log(err)})
    }
  }

  public showSubDirs(dir) {
    return (dir.$$fold === false || this.editable === true) && !_.isEmpty(this.dirTree)
  }

  public goto(dir) {
    if (!_.isEmpty(dir.$$resource)) {
      this.gotoContent(dir)
    } else {
      this.toggleSubDirs(dir)
    }
  }

  public gotoContent(dir) {
    this.state.go('onlineTrain.content', {
      parentId: dir.id,
      editable: this.editable,
      dirs: this.allDirs,
      prefix: this.prefix});
  }

  public toggleSubDirs(dir) {
    if (dir.$$fold === undefined) {
      dir.$$fold = false
    } else {
      dir.$$fold = !dir.$$fold
    }
  }

  public contentEdit(dir) {
    this.gotoContent(dir)
  }

  private filterByOem() {
    return _.filter(this.dirTree[this.parentId], (d: OnlineTrainingDirectoryDTO) => {
      if (d.oem === '') {
        return true
      }

      const oems = _.split(d.oem, ',')
      return _.includes(oems, this.lagecyApp.getOem().curOem())
    })
  }

  private initData(dirs: OnlineTrainingDirectoryDTO[]) {
    this.allDirs = _.filter(dirs, (d: OnlineTrainingDirectoryDTO) => d.id > 1)
    this.dirTree = this.onlineTrainService.buildDirTree(this.allDirs)
    this.dirs = this.filterByOem()
    this.dataToAdd = {
      dirId: this.parentId,
      name: '',
      sort: 0,
      deleted: false
    }

    this.itemType = this.onlineTrainService.DIR
  }

  private loadData() {
    this.trainMessage.sendLoadingAction(true)
    this.onlineTrainService.getAllDirs(this.lagecyApp.getOem().curOem()).pipe(
      flatMap((dirs: OnlineTrainingDirectoryDTO[]) => {
        this.initData(dirs)
        if (!_.isEmpty(this.dirs)) {
          const req = _.map(this.dirs, (dir) => {
            return forkJoin(this.onlineTrainService.getContentsByDirId(dir.id),
              this.onlineTrainService.getContentLinksByDirId(dir.id))
          })
          return forkJoin(...req)
        } else {
          return of([])
        }
      }),
      map((reses) => {
        if (!_.isEmpty(reses)) {
          _.zipWith(this.dirs, reses, (d, r: any) => {
            d.$$resource = _.flatten(r)
          })
        }

        return 0
      })).subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
  }
}
