(function() {
  'use strict';

  angular.module('app.core.newVersionCtrl', [])
    .controller('newVersionCtrl', newVersionCtrl);

  newVersionCtrl.$inject = ['$scope']
  function newVersionCtrl($scope) {
    $scope.doReload = function() {
      location.reload(true);
    };
  }
})();
