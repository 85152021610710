import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {CloudDriveService} from 'app/cloud-drive/cloud-drive.service';
import {finalize, flatMap} from 'rxjs/operators';
import {forkJoin, of} from 'rxjs';
import * as _ from 'lodash'
import {ResourceService} from 'app/core/resource.service';
import {CloudDriveItem} from 'app/models/cloudDrive';

@Component({
  selector: 'app-cloud-drive-dialog',
  template: require('./cloud-drive-dialog.component.html'),
  styles: [require('./cloud-drive-dialog.component.scss')]
})
export class CloudDriveDialogComponent implements OnInit {
  @Output() public action = new EventEmitter<any>();
  private defaultSeleted = '1'
  private rootId: string
  page = 0
  loading = true
  fullPath
  fileCount = 0
  fileArr
  tolSize = '0'
  tolSizeWidth
  fileSize
  constructor(public bsModalRef: BsModalRef,
              public cloudDriveService: CloudDriveService,
              public cdr: ChangeDetectorRef,
              public resourceService: ResourceService,) { }

  ngOnInit() {
    this.cloudDriveService.queryAllDirs()
      .pipe(
        flatMap((res) => {
          if(res.length == 0) {
            //创建根目录
            const request = {
              "name": "我的文件",
              "parentDirId": 1,
              "sort": 0
            }
            return this.cloudDriveService.createRootDir(request)
          } else {
            this.rootId= _.filter(res,(item: any) => {
              return item.parentDirId == '1';
            })[0]['id'];
            return of(_.find(res, (item: any) => item.parentDirId == '1'))
          }
        }),
        flatMap((rootItem) => {
          return this.handData(rootItem.id);
        }),
        flatMap(() => {
          return this.refreshSize()
        }),
        finalize(() => {
          this.loading =false
      }))
      .subscribe()
  }
  
  public handData = (id)=>{
    return forkJoin(
      this.cloudDriveService.getFullPath(id),
      this.cloudDriveService.getFileByPath(id, this.page, this.cloudDriveService.pageSize),
      this.cloudDriveService.getFileTotalCount(id)
    ).pipe(
      flatMap((value) => {
        this.fullPath = value[0]
        this.fileCount = value[2].count;
        this.fileArr = value[1]
        
        _.forEach( this.fileArr,(item: any)=>{
          item.$$checked = false;
          item.$$canEditName = false;
          if(item.itemType == 'dir'){
            item.originSize = 0;
          }
        });
        
        const needHandleArr = _.filter(this.fileArr,(item: any) => {
          return item.itemType === 'file';
        });
  
        if (needHandleArr.length > 0) {
          return forkJoin(_.map(needHandleArr, (r: any) => {return this.resourceService.getResourceInfoByUuid(r.resourceUuid)}))
        } else {
          return of([]);
        }
      }),
      flatMap((reses: any) => {
        if (!_.isEmpty(reses)) {
          let needHandleArr: any = _.remove(this.fileArr,(item: any) => {return item.itemType === 'file'})
          needHandleArr = _.zipWith(needHandleArr, reses, (a: CloudDriveItem, b: any) => {
            return {...a, suffix: b.suffix, size: this.cloudDriveService.bytesToSize(b.size), originSize: b.size}
          })
          
          this.fileArr = _.chain(this.fileArr).concat(needHandleArr).sortBy('createdTime', 'id').reverse().value()
        }
        return of([])
      })
    )
  }
  
  public changeSelected = (type)=>{
    if(type == '1'){
      this.loading = true
      this.handData(this.rootId).pipe(
        flatMap(() => this.refreshSize()),
        finalize(() => this.loading = false)).subscribe();
    }
    this.defaultSeleted = type;
  }
  
  public cancelClick = () => {
    this.bsModalRef.hide()
  }
  
  public refreshSize() {
    return this.cloudDriveService.totalSize().pipe(
      flatMap((r) => {
        if (r.tolSize > this.cloudDriveService.capacityLimit) {
          this.tolSize = '1.0G'
        } else {
          this.tolSize = this.cloudDriveService.bytesToSize(r.totalSize)
        }
        
        this.fileSize = r.totalSize
        const width = ((r.totalSize) / this.cloudDriveService.capacityLimit).toFixed(2)
        this.tolSizeWidth = {
          'width': (Number(width) * 100 > 100) ? (100 + '%') : (Number(width) * 100  + '%')
        }
  
        return of([])
      })
    )
  }
  
  public onLoading(loading) {
    if (this.loading === loading) {
      return
    }
    
    this.loading = loading
    this.cdr.detectChanges()
  }
  
  public onRefreshDriveSize(r) {
    if (r.tolSize > this.cloudDriveService.capacityLimit) {
      this.tolSize = '1.0G'
    } else {
      this.tolSize = this.cloudDriveService.bytesToSize(r.totalSize)
    }
  
    this.fileSize = r.totalSize
    const width = ((r.totalSize) / this.cloudDriveService.capacityLimit).toFixed(2)
    this.tolSizeWidth = {
      'width': (Number(width) * 100 > 100) ? (100 + '%') : (Number(width) * 100  + '%')
    }
  
    return of([])
  }
  
  public onImportRes(params) {
    this.action.emit(params)
    this.bsModalRef.hide()
  }
  
  public onDriveSizeChange(params) {
    this.refreshSize().subscribe()
  }
}
