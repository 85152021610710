(function() {
  require('qrcode-generator');
  require('angular-qrcode');
  require('app2/manage/createGroup.ctrl')
  require('app2/manage/emptyCoursePathGuide.ctrl')
  require('app2/manage/prepareLessonGuide.ctrl')
  require('app2/manage/updateGroupName.ctrl')
  require('app2/manage/studentPersonalSettings.ctrl')
  require('assets/app/controllers/manage/courseManagementCtrl.js');
  require('assets/app/controllers/manage/changeStateCtrl.js');
  require('assets/app/controllers/manage/changeSubjectCtrl.js');
  require('assets/app/controllers/manage/changeTeacherCtrl.js');

  angular.module('app.manage', [
    'ui.router', 'ngCookies', 'monospaced.qrcode',
    'app.manage.createGroupCtrl',
    'app.manage.emptyCoursePathGuideCtrl',
    'app.manage.prepareLessonGuideCtrl',
    'app.manage.updateGroupNameCtrl',
    'app.manage.studentPersonalSettings',
    'app.manage.courseManagement',
    'app.manage.changeState',
    'app.manage.changeSubject',
    'app.manage.changeTeacher'
  ])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage', {
        url: '/manage',
        template: require('assets/templates/manage.html'),
        controller: 'manageCtrl',
        loadingCls: 'pinkloading'
      });
    }])
    .controller('manageCtrl', manageCtrl)

  manageCtrl.$inject = ['$scope', '$state', '$window', 'userInfo', 'oedSchool', 'oedRayAuth', 'localStorageService']
  function manageCtrl($scope, $state, $window, userInfo, oedSchool, oedRayAuth, localStorageService) {
    $scope.$state = $state;
    // 判断顶层窗口是否为iframe(配合桌面端使用)
    $scope.topWindowIsIframe = $window.top === $window.self;
    $scope.linkOpenType = $scope.topWindowIsIframe ? '_blank' : '_self';
    if (_.includes($scope.$state.router.urlRouter.location, '/teachWeekDetail/') || _.includes($scope.$state.router.urlRouter.location, '/class/')) {
      $scope.showTitleTwo = true;
    } else {
      $scope.showTitleTwo = false;
    }
    $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){
      if (_.includes(toState.url, '/teachWeekDetail/') || _.includes(toState.url, '/class/')) {
        $scope.showTitleTwo = true;
      } else {
        $scope.showTitleTwo = false;
      }
    });
    $scope.currentState = {};
    $scope.showPlanManage = false;
    $scope.clearTitle = function(){
      $scope.currentState.titleTwo = '';
    };
    $scope.goClassHour = function() {
      localStorageService.set('teachWeekDetail', null)
    };
    $scope.isSystem = function() {
      if (!$scope.userInfo || !$scope.userInfo.roles)
        return false;

      return $scope.userInfo.roles.indexOf('system') >= 0;
    };

    $scope.isSchoolAdmin = function() {
      if (!$scope.userInfo || !$scope.userInfo.roles)
        return false;
      return _.includes($scope.uids, _.parseInt($scope.userInfo.uid));
    };
    $scope.isAdmin = function() {
      if (!$scope.userInfo || !$scope.userInfo.roles)
        return false;

      return $scope.userInfo.roles.indexOf('admin') >= 0;
    };
    userInfo.then(function(info) {
      if (process.env.NAME === 'beta' || info.uid === '1635' || info.uid === '1797') {
        $scope.showPlanManage = true
      }
      $scope.userInfo = info;
      $scope.$on('oedSchoolInfoChanged', function() {
        $scope.schoolInfo = oedSchool.get({
          id: info.schoolId
        });
      });
      oedSchool.getSchoolAdminsBySchoolIds([_.parseInt(info.schoolId)]).$promise.then(res => {
        $scope.uids = _.map(res[_.parseInt(info.schoolId)], 'uid').join(',');
      });

      $scope.schoolInfo = oedSchool.get({
        id: info.schoolId
      })
    });

    $scope.prepareLessonDiog = () => {
      let baseUrl = 'http://devschool.talbrain.com/api/ray/login'

      if (process.env.NAME === 'beta') {
        baseUrl = 'http://devschool.talbrain.com/api/ray/login'
      } else {
        baseUrl = 'http://wisebi.talcloud.com/api/ray/login'
      }

      const params = '?teacherId=' + $scope.classDetailParams.teacherId
        + '&timestamp=' + $scope.classDetailParams.timestamp
        + '&signature=' + $scope.classDetailParams.signature
      + '&type=beike'

      const requestUrl = baseUrl + params
      $window.open(requestUrl, $scope.linkOpenType);
    }
  }
})();
