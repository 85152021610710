(function() {
  angular.module('app.prepareCourse')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('editCourseInfo', {
        url: '/editCourseInfo/:courseId',
        template: require('assets/templates/prepareCourse/editInfo.html'),
        controller: 'editCourseInfoCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'bged'
      });
    }])
    .controller('editCourseInfoCtrl', editCourseInfoCtrl);

  editCourseInfoCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', '$q', '$log', '$timeout', 'dialogs',
    'Upload', 'userInfo', 'oedConfig', 'oedMisc', 'oedCourse', 'oedLoading', 'oedSection', 'oedUnitItem', 'oedCourseUtils',
    'localStorageService', 'oedCoursePath', 'oedOem', 'oedLog', '$window']
  function editCourseInfoCtrl($scope, $state, $stateParams, $uibModal, $q, $log, $timeout, dialogs,
    Upload, userInfo, oedConfig, oedMisc, oedCourse, oedLoading, oedSection, oedUnitItem, oedCourseUtils,
    localStorageService, oedCoursePath, oedOem, oedLog, $window) {
    var courseId = $stateParams.courseId;

    if (courseId) {
      var curCpId = localStorageService.get('lastCoursePathId');
      var curCpMap = localStorageService.get('lastCoursePathCourseMap', curCpId);
      if (!curCpMap) {
        curCpMap = {};
      }
      curCpMap[curCpId] = courseId;
      localStorageService.set('lastCoursePathCourseMap', curCpMap);
    }

    $scope.treeConfig = {
      core: {
        multiple: true,
        animation: true,
        error: function(error) {
          $log.error('treeCtrl: error from js tree - ' + angular.toJson(error));
        },
        check_callback: true,
        worker: true
      },
      types: {
        default: {
          icon: 'fa fa-book'
        },
        root: {
          icon: 'glyphicon glyphicon-cloud'
        }
      },
      version: 1,
      plugins: ['types', 'checkbox']
    };

    $scope.course = oedCourse.get({
      id: courseId
    }, function() {
      if ($scope.course.coursepathId) {
        oedCoursePath.get({id: $scope.course.coursepathId}).$promise.then(function(c) {
          $scope.coursePath = c;
        });
      }
    });

    $scope.treeLoading = $q.defer();

    $scope.sectionTree = {
      treeData: []
    };

    $scope.onSelChanged = function() {
      $scope.$apply(function() {
        var selectedNodes = $scope.treeInstance.jstree(true).get_selected();
        var ids = _(selectedNodes).map(function(node) {
          return _.parseInt(node, 10);
        }).filter().value();
        $scope.loading = oedCourseUtils.loadSectionNamesById(ids).then(function(name) {
          $scope.selectedSectionNames = name || '';
          if (_.isEmpty($scope.course.courseName)) {
            $scope.course.courseName = name;
          }
        });
      });
    };

    $scope.loading = userInfo.then(function(info) {
      $scope.availableSections = oedUnitItem.queryAvailableBySchoolAndCourse({
        schoolId: info.schoolId,
        courseId: courseId
      });

      $scope.canStar = info.canStar;
      $scope.uid = info.uid;

      return $q.all([$scope.availableSections.$promise,
        $scope.course.$promise,
        $scope.treeLoading.$promise
      ]).then(function(dataList) {
        $scope.sectionIdMap = {};

        function visitSection(sec) {
          if (sec.id) $scope.sectionIdMap[sec.id] = sec;
          if (sec.child) {
            sec.child = _.sortBy(sec.child, ['sort']);
            _.each(sec.child, visitSection);
          }
        }
        _.each($scope.availableSections, visitSection);

        $scope.sectionTree.treeData = oedMisc.utils.convertSectionsToJsTreeModel($scope.sectionIdMap,
          $scope.availableSections);
        _.each($scope.sectionTree.treeData, function(data) {
          data.state.opened = true;
        });
        $scope.treeConfig.version++;

      });
    });

    $scope.onTreeReady = function() {
      if ($scope.course.relatedSections) {
        var sections = _.map($scope.course.relatedSections, function(relSec) {
          return relSec.sectionId;
        });
        $scope.treeInstance.jstree(true).select_node(sections);
      }
      $scope.treeLoading.resolve();
    };

    $scope.shouldApplyModelChanges = function() {
      return true;
    };

    $scope.preventDropdownDefault = function(event) {
      event.preventDefault();
      event.stopPropagation();
    };

    function showCoursePlan() {
      return $scope.inEduGroup && oedOem.curOem() === 'futureSchool'
    }

    $scope.showSecNo = function() {
      return showCoursePlan()
    }

    $scope.gotoNext = function() {
      $scope.showValidationError = true;
      if ($scope.courseInfoForm.$invalid) {
        return;
      }
      var selectedNode = $scope.treeInstance.jstree(true).get_selected();
      $scope.course.relatedSections = _.map(selectedNode, function(secId) {
        return {
          sectionId: _.parseInt(secId)
        };
      });

      if (_.isEmpty($scope.course.relatedSections)) {
        dialogs.error('无法保存', '当前课程没有关联章节, 请关联章节后再继续');
        return;
      }

      if (showCoursePlan() && $scope.course.relatedSections.length > 1) {
        dialogs.error('无法保存', '课程只能关联一个章节');
        return;
      }

      if (showCoursePlan() &&
        ($scope.course.sectionIndex < 1 || $scope.course.sectionIndex > 5)) {
        dialogs.error('无法保存', '课时只能设置1-5');
        return;
      }
      oedLog.addPageView({
        courseId: $scope.course.id,
        type: 'editCourseInfo',
        userAgent: $window.navigator.userAgent,
        viewDetails: JSON.stringify({
          sectionIndex: $scope.course.sectionIndex
        })
      });
      $scope.loading = $q(function(resolve, reject) {
        $scope.course.$save(function() {
          //todo 可能会导致数据不一致
          if ($scope.coursePath.templateType && $scope.canStar) {
            var update = oedCoursePath.updateCourseOfTemplate({id: $scope.course.id}).$promise;
          }

          if (showCoursePlan()) {
            $state.go('prepareCoursePlan.autonomousLearning', {
              courseId: courseId
            });
          } else {
            $state.go('prepareCourse.objectiveTest', {
              courseId: courseId
            });
          }

          resolve();
        });
      });
    };

    $scope.open = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/open.html'),
        controller: 'openCtrl',
        size: 'edit-test',
        windowClass: '',
        resolve: {
          data: function() {
            return {
              courseId: 1841710
            };
          }
        }
      });
    };
  }
})();
