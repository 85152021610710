import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { QuestionDTO } from '../models/exam';
import _ = require('lodash');
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-edit-ques-score',
  template: require('./edit-ques-score.component.html'),
  styles: [require('./edit-ques-score.component.scss')]
})
export class EditQuesScoreComponent implements OnInit {

  @Input() question: QuestionDTO
  @Input() sort: string
  @Input() sortMap: object
  @Output() quesModelChanged = new EventEmitter<any>()

  quesStem: string
  qSort: string
  point: number
  constructor(public notifyService: NotificationService,) { }

  ngOnInit() {
    this.quesStem = _.get(this.question, 'question', '')
    const quuid = _.get(this.question, 'uuid', '')
    const key = _.isEmpty(quuid) ? _.get(this.question, 'id', 0) : quuid
    this.qSort = _.get(this.sortMap, key, '0')
    this.point = this.question.point2
  }

  handleModelChange(value) {
    const np = Math.floor(value)
    if (np != value) {
      this.notifyService.notify('error', '分数仅支持为1的倍数');
      this.point = np
    }
    if (np <= 0) {
      this.notifyService.notify('error', '分数必须大于0');
      this.point = this.question.point2
      return
    }
    this.question.point2 = np
    this.quesModelChanged.emit()
  }

}
