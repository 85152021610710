import { Component, OnInit } from '@angular/core';
import { CoursePathService } from '../../core/course-path.service';
import * as _ from 'lodash';
import { AppStateService } from 'app/core/app-state.service';
import { forkJoin, of } from 'rxjs';
import { flatMap, finalize } from 'rxjs/operators';
import { LevelsAllDTO, SubjectsAllDTO, VersionsAllDTO, LgvsAllDTO } from '../manage';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { LegacyAppService } from 'app/core/legacy-app.service';

@Component({
  selector: 'app-exercise-book',
  template: require('./exercise-book.component.html'),
  styles: [require('./exercise-book.component.scss')]
})
export class ExerciseBookComponent implements OnInit {
  globalLevels: LevelsAllDTO[];
  globalSubjects: SubjectsAllDTO[];
  globalVersions: VersionsAllDTO[];
  globalLgvs: LgvsAllDTO[];
  loading = true;
  user: any;
  currentLevel: LevelsAllDTO;
  currentSubject: SubjectsAllDTO;
  currentVersion: VersionsAllDTO;
  currentLevelId: number;
  currentSubjectId: number;
  currentVersionId: number;
  subjects: SubjectsAllDTO[];
  versions: VersionsAllDTO[];
  uibModal: any;
  localStorage: any;
  initData = true;

  constructor( private userInfo: AppStateService,
               private coursePath: CoursePathService,
               private oedDialogService: OedDialogService,
               private legacyApp: LegacyAppService,) {       
  }

  ngOnInit() {
    this.getData();
  }

  public getData() {
    this.localStorage = this.legacyApp.getLocalStorage();
    const lastLevelData = this.localStorage.get('ex_current_level');
    this.userInfo.then((info) => {
      // this.schoolId = info.schoolId;
      this.user = info;
      return forkJoin(this.coursePath.getAllLevels(),this.coursePath.getAllSubjects(),this.coursePath.getAllVersions(),
        this.coursePath.getAllLgvs(),)
        .pipe(
          flatMap((values) => {
            this.globalLevels = values[0];
            this.globalSubjects = values[1];
            this.globalVersions = values[2];
            this.globalLgvs = values[3];
            this.setCurrentLevel(lastLevelData || this.globalLevels[0]);
            this.initData = false;
            return of([])
          }),
          finalize(() => {
            this.loading = false
          })
        ).subscribe();
    });
  }

  public setCurrentLevel (t) {
    this.localStorage.set('ex_current_level', t);
    this.currentLevel = t;
    this.currentLevelId = _.get(this.currentLevel, 'levelId');
    this.reloadSubject();
    if (!_.isEmpty(this.subjects) && this.initData) {
      this.setCurrentSubject(this.localStorage.get('ex_current_subject') || this.subjects[0]);
    } else {
      this.setCurrentSubject(this.subjects[0]);
    }
  }

  public setCurrentSubject(t) {
    this.localStorage.set('ex_current_subject', t);
    this.currentSubject = t;
    this.currentSubjectId = _.get(this.currentSubject, 'id');
    this.reloadVersion();
    if (!_.isEmpty(this.versions) && this.initData) {
      this.setCurrentVersion(this.localStorage.get('ex_current_version') || this.versions[0]);
    } else {
      this.setCurrentVersion(this.versions[0]);
    }
  }

  public setCurrentVersion (t) {
    this.localStorage.set('ex_current_version', t);
    this.currentVersion = t;
    this.currentVersionId = _.get(this.currentVersion, 'versionId');
  }

  public reloadSubject () {
    const t = this.currentLevel;
    const glvs = _.uniq(_.map(_.filter(this.globalLgvs, s => s.levelId === t.levelId), t => t.subjectId));
    this.subjects = _.filter(this.globalSubjects, s => _.includes(glvs, s.id));
  }

  public reloadVersion () {
    const t = this.currentSubject;
    const glvs = _.uniq(_.map(_.filter(this.globalLgvs, s => s.levelId === this.currentLevel.levelId &&
      s.subjectId === t.id), t => t.versionId));
    this.versions = _.filter(this.globalVersions, s => _.includes(glvs, s.versionId));
  }
}
