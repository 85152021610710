angular.module('app.manage.changeTeacher', [])
  .controller('changeTeacherCtrl', changeTeacherCtrl);

changeTeacherCtrl.$inject = ['$scope', '$uibModalInstance', 'id', 'oedCourseManage']
function changeTeacherCtrl($scope, $uibModalInstance, id, oedCourseManage) {
  $scope.selectTea = '0';
  $scope.selectId = id;
  $scope.teacherList = [
    {
      id: -1,
      name: '全部老师'
    }
  ];
  $scope.query = {
    search: null
  };
  oedCourseManage.getTeacherList({teacherId: -1}).$promise.then((data)=> {
    _.forEach(data, (item, index) => {
      $scope.teacherList[index + 1] = item;
      if(item.id === $scope.selectId){
        $scope.selectTea = '' + (index + 1);
      }
    })
    $scope.teachers = $scope.teacherList;
  });
  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
  $scope.setTeacher = function (sel, id) {
    $scope.selectTea = '' + sel;
    $scope.selectId = id;
    $scope.changeTeacher();
  }
  $scope.isTeacher = function (sel) {
    return $scope.selectTea === '' + sel;
  }

  var matchProps = function(obj, props, val) {
    return _.findIndex(props, function(prop) {
      return _.includes(obj[prop], val);
    }) >= 0;
  };
  $scope.doSearch = function() {
    var search;
    $scope.teachers = _.filter($scope.teacherList, function(tea) {
      search = _.trim($scope.query.search);
      if (search) {
        return matchProps(tea, ['name'], search);
      }
      return true;
    });
    const ind = _.findIndex($scope.teachers, item => {
      return item.id === $scope.selectId;
    });
    $scope.selectTea = (ind || ind === 0) ? '' + ind : '';
  };

  $scope.changeTeacher = function () {
    $uibModalInstance.close({success: true, id: $scope.selectId});
  }
}
