
var questionDesigner = require('./questionDesigner.directive');
var questionPreviewer = require('./questionPreviewer.directive');
var syntheticPreviewer = require('./syntheticPreviewer.directive');
var questionAnswer = require('./questionAnswer.directive');
var restrictToMaxTags = require('./restrictToMaxTags.directive');
var syntheticDesigner = require('./design/syntheticDesigner.directive');
var exerciseQuestionPreviewer = require('./exerciseQuestionPreviewer');
var exerciseSyntheticPreviewer = require('./exerciseSyntheticPreviewer');

(function() {
  angular.module('app.directives.question', [
    'app.directives.question.questionDesigner',
    'app.directives.question.questionPreviewer',
    'app.directives.question.syntheticPreviewer',
    'app.directives.question.questionAnswer',
    'app.directives.question.restrictToMaxTags',
    'app.directives.question.design.syntheticDesigner',
    'app.directives.question.exerciseQuestionPreviewer',
    'app.directives.question.exerciseSyntheticPreviewer',
  ]);
})();
