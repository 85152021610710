import angular = require('angular');
import Promise = require('bluebird');
import _ = require('lodash');

import preResViewLogUtils = require('app2/utils/preResViewLogUtils');

angular.module('app.resource.preResReviewStudentDetails', [])
  .controller('preResReviewStudentDetailsCtrl', preResReviewStudentDetailsCtrl);

preResReviewStudentDetailsCtrl.$inject = ['$scope', 'oedUserInfoUtils', 'data',
  'oedPresentResource', '$uibModalInstance', 'Lightbox', 'oedCloudPresentResItem',
  '$q', 'oedViewLog', 'oedPreResSession']
function preResReviewStudentDetailsCtrl($scope: any, oedUserInfoUtils: any, data: any,
                                        oedPresentResource: any, $uibModalInstance: any,
                                        Lightbox: any, oedCloudPresentResItem: any,
                                        $q: any, oedViewLog: any, oedPreResSession: any) {

  const ctrl = this;

  $scope.data = data

  $scope.resource = oedPresentResource.get({
    id: data.preResId,
  });

  $scope.loading = $scope.resource.$promise.then((resource: any) => {
    if ($scope.data.isPreresItemSession) {
      resource.items = _.filter(resource.items, (item: any) => {
        return item.id === $scope.data.preResSession.preResItemId;
      })
    }
    return $q.all([ctrl.loadResouceItems(resource.items), ctrl.loadStdDetails()]);
  });

  ctrl.loadStdDetails = () => {
      $scope.unViewedItems = [];
      if (data.isPreresItemSession) {
      $scope.viewedItems = $scope.resource.items;
      _.each($scope.viewedItems, (item: any) => {
        item.duration = preResViewLogUtils.timeDiff(data.preResSession);
      })
    } else {
      return oedViewLog.queryPreResViewLogByUid({
        preResSessionId: data.preResSessionId,
        uid: data.student.uid,
      }).$promise.then((logList: any) => {
        $scope.viewedItemLogs = _.groupBy(logList, 'preResItemId');
        const part = _.partition($scope.resource.items, (item: any) => {
          const has = _.has($scope.viewedItemLogs, item.id);
          if (has) {
            item.duration = preResViewLogUtils.durationSpentOnItem($scope.viewedItemLogs[item.id]);
          }
          return has;
        });
        $scope.viewedItems = part[0];
        oedPreResSession.get({
            id: data.preResSessionId
        }).$promise.then((res: any) => {
          if (!res.excludePreResItemIds) {
            $scope.unViewedItems = part[1];
          } else {
            _.forEach(part[1], (item: any) => {
              const has = _.indexOf(res.excludePreResItemIds.split(','), ('' + item.id));
              if (has === -1) {
                  $scope.unViewedItems.push(item);
              }
            });
          }
        });
      });
    }
  }

  ctrl.loadResouceItems = (items: any) => {
    const resourceItems = $q.all(_.map(items, (item: any) => {
      return oedCloudPresentResItem.getByResItemId({
        id: item.id,
      }).$promise.then((ci: any) => {
        item.cloudRes = ci;
        return item;
      });
    }));
    return resourceItems;
  }

  $scope.close = () => {
    $uibModalInstance.close();
  };

  $scope.onPreviewItem = (items: any, index: any, $event: any) => {
    Lightbox.openModal(items, index);
  }

  $scope.getStdAvatarImgSrc = oedUserInfoUtils.getStdAvatarImgSrc;
}
