import _ = require('lodash')

export const getOrigSection = (origSections: any, sectionId: any) => {
  const flatChapters = _.cloneDeep(origSections[0].child)
  // 和ray保持一致，章节树最多处理10层
  let idx = 0
  while (idx < 10) {
    const hasChilds = _.filter(flatChapters, (c: any) => _.size(c.child) > 0)
    _.forEach(hasChilds, (hc: any) => {
      const childs = _.cloneDeep(hc.child)
      _.forEach(childs, (cp: any) => {
        cp.name = hc.name + cp.name
        return cp
      })
      flatChapters.push(...childs)
      hc.child = []
    })
    idx++
  }
  return _.find(flatChapters, (c: any) => c.id === sectionId)
}

export const commonReplace = (name: string) => {
  const from = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十']
  const from1 = ['ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE', 'TEN']
  const to = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
  let result = name.replace(/\s/g, '')
  _.forEach(from, (f, idx) => {
    result = _.replace(result, new RegExp(f, 'g'), to[idx])
  })
  _.forEach(from1, (f, idx) => {
    result = _.replace(result.toUpperCase(), new RegExp(f, 'g'), to[idx])
  })
  return result
}

export const matchSection = (chapters: any, secName: any = null, prop: any) => {
  const flatChapters = getFlatCps(chapters, secName, prop)
  return _.omit(_.maxBy(flatChapters, 'count'), 'count')
}

export const matchSectionByRate = (chapters: any, secName: any = null, prop: any, matchRate: number) => {
  const flatChapters = getFlatCps(chapters, secName, prop)
  const matchCp = _.maxBy(flatChapters, 'count')
  const count = _.get(matchCp, 'count', 0)
  if (count >= matchRate) {
    return _.omit(matchCp, 'count')
  }
  return null
}

const getFlatCps = (chapters: any, secName: any = null, prop: any) => {
  if (_.isEmpty(secName)) {
    return chapters[0]
  }
  const flatChapters: any = _.cloneDeep(chapters)
  // 和ray保持一致，章节树最多处理10层
  let idx = 0
  while (idx < 10) {
    const hasChilds: any = _.filter(flatChapters, (c: any) => _.chain(c).get(prop).size().value() > 0)
    _.forEach(hasChilds, (hc: any) => {
      const childs: any = _.cloneDeep(_.get(hc, prop))
      _.forEach(childs, (cp: any) => {
        cp.name = hc.name + cp.name
        return cp
      })
      flatChapters.push(...childs)
      hc.childs = []
    })
    idx++
  }
  const existChar = _.split(commonReplace(secName), '')
  _.forEach(flatChapters, (c: any) => {
    const name = commonReplace(c.name)
    const lsChar = _.split(name, '')
    c.count = _.size(_.intersection(existChar, lsChar)) / _.size(_.union(existChar, lsChar))
  });
  return flatChapters
}
