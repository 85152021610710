import * as angular from 'angular';

angular.module('app.common.dialogOne',
[]).component('dialogOne', {
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: dialogOneCtrl,
  template: <string> require('./dialogOne.html'),
})

dialogOneCtrl.$inject = []
function dialogOneCtrl() {
  this.ok = () => {
    this.modalInstance.close(true);
  }

  this.close = () => {
    this.modalInstance.close();
  }
}
