(function() {
  angular.module('app.signUp') //声明angular模块
    .controller('getVerifyCodeCtrl', getVerifyCodeCtrl);

  getVerifyCodeCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'data', 'oedUserInfo', 'notificationService']
  function getVerifyCodeCtrl($scope, $q, $uibModalInstance, data, oedUserInfo, notificationService) {

    var mobile = data.mobile;

    $scope.codeVer = _.random(0, 9999);
    $scope.disableSend = false;

    $scope.refreshCaptcha = function() {
      $scope.codeVer++;
    };

    $scope.cancel = function(result) {
      $uibModalInstance.close(result);
    };

    $scope.getVerifyCode = function() {
      $scope.disableSend = true;
      var signup = oedUserInfo.signupVerifyCode({
        mobile: mobile,
        type: data.type,
        captcha: $scope.captcha
      });

      $scope.loading = signup.$promise.then(function(resp) {
        if (resp.smsErrCode == -2) {
          notificationService.notify('error', '右图文字输入错误，请重新输入')
          $scope.disableSend = false;
        } else if (resp.smsErrCode == -1) {
          notificationService.notify('error', '短信发送失败，请稍后重试')
          $scope.cancel(false);
        } else {
          notificationService.notify('info', '短信发送成功，请继续')
          $scope.cancel(true);
        }
      });
    };
  }
})();

