import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { LessonDTO, SectionDTO } from '../../models/lesson'
import { LessonService } from '../../services/lesson.service'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IntelligentCreatePaperComponent } from '../../lesson-design/intelligent-create-paper/intelligent-create-paper.component'
import { from } from 'rxjs';
import { OedUibmodalService } from 'app/core/oed-uibmodal.service';

@Component({
  selector: 'app-section-exams',
  template: require('./section-exams.component.html'),
  styles: [require('./section-exams.component.scss')]
})
export class SectionExamsComponent implements OnInit {

  public bsModalRef: BsModalRef;
  @Input() public sectionId: number
  @Input() public sectionName: string
  @Input() public coursepathId: number
  private loading = false;

  private currentExam: any = {}
  private course: LessonDTO
  private exams: any[]
  private selectedCourse: LessonDTO
  private config = {
    animated: true,
    keyboard: true,
    class: 'modal-full-screen2 modal-lg'
  };
  constructor(
    private lessonService: LessonService,
    private oedUibModalService: OedUibmodalService,
    private modalService: BsModalService
  ) { 

  }

  public ngOnInit() {
    this.loadCurrentCoursePath()
  }

  private loadCurrentCoursePath() {
    this.loading = true
    this.exams = []
    this.lessonService.getCoursePath(this.coursepathId).toPromise()
    .then((data) => {
      return this.lessonService.getExamByCoursePath(this.coursepathId).toPromise()
    })
    .then(allExams => {
      this.exams = _.filter(allExams, exam => _.indexOf(_.map(_.get(exam, 'relatedSections', []), 'id'), Number(this.sectionId)) >= 0)
      if (!_.isEmpty(this.exams)) {
        this.currentExam = _.head(this.exams);
      } else {
        this.currentExam = {}
      }
      this.loading = false
    })
    .catch((error) => {
      this.loading = false
    })
  }

  public ngOnChanges(changes: SimpleChanges) {
  }

  private createNewRecommendPaper() {
    const initialState = {
      sectionId: this.sectionId,
      coursepathId: this.coursepathId
    }
    this.bsModalRef = this.modalService.show(IntelligentCreatePaperComponent, { // TODO !!
      ...this.config,
      initialState
    });
    return this.bsModalRef.content.done.subscribe(data => {
      if (_.get(data, 'done', false)) {
        console.log('refresh exam list')
        this.loadCurrentCoursePath()
      }
    });
  }

  public onCourseSelected(data: LessonDTO) {
    if (this.selectedCourse && this.selectedCourse.id === data.id) {
      return
    }
    this.selectedCourse = {
      ...data,
    }
  }

  private openPaper(exam) {
    this.currentExam = exam
  }

  private isExamChoiced(exam) {
    return _.get(exam, 'exam.id') === _.get(this.currentExam, 'exam.id')
  }

}
