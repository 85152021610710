(function() {
  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('student.exportPdf', {
        url: '/exportPdf',
        template: require('assets/templates/student/studentExportPdf.html'),
        controller: 'studentExportPdfCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('studentExportPdfCtrl', studentExportPdfCtrl);

  studentExportPdfCtrl.$inject = ['$scope', '$timeout', 'oedTestHistory', 'userInfo']
  function studentExportPdfCtrl($scope, $timeout, oedTestHistory, userInfo) {
    $scope.courseInfo = {};
    var init = function(info) {
      if (!info || !info.uid) {
        $timeout(function() {
          userInfo.update();
          userInfo.then(init);
        }, 1000);
        return;
      }
      $scope.courses = oedTestHistory.historyCoursePaths({
        userId: info.uid
      });
      $scope.loading = $scope.courses.$promise.then(function(resp) {
        $scope.loadingErrorMessage = '';
        $scope.coursePathList = resp;
      }).catch(function() {
        $scope.loadingErrorMessage = '加载数据错误';
      });

      var urlTmpl = _.template(
        '/ray/api/a/testsession/history/exportpdf/student' +
        '/<%=studentId%>/course/<%=coursePathId%>/<%=start%>/to/<%=end%>'
      );

      $scope.doExport = function() {
        $scope.showValidationError = true;
        if ($scope.exportHistoryForm.$invalid) {
          return;
        }
        var formatDate = function(d) {
          return moment(d).format('YYYYMMDD');
        };

        var url = urlTmpl({
          studentId: info.uid,
          coursePathId: $scope.courseInfo.course.id,
          start: formatDate($scope.courseInfo.startDate),
          end: formatDate($scope.courseInfo.endDate)
        });
        window.open(url);
      };
    };

    userInfo.then(init);

    $scope.openStart = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startOpened = true;
      $scope.endOpened = false;
    };

    $scope.openEnd = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startOpened = false;
      $scope.endOpened = true;
    };

    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };

    $scope.courseInfo.startDate = moment().subtract(2, 'days').toDate();
    $scope.courseInfo.endDate = moment().toDate();

    $scope.predefinedDates = [{
      title: '最近两天',
      applyDates: function() {
        $scope.courseInfo.startDate = moment().subtract(2, 'days').toDate();
        $scope.courseInfo.endDate = moment().toDate();
      }
    }, {
      title: '最近一周',
      applyDates: function() {
        $scope.courseInfo.startDate = moment().subtract(7, 'days').toDate();
        $scope.courseInfo.endDate = moment().toDate();
      }
    }, {
      title: '最近一月',
      applyDates: function() {
        $scope.courseInfo.startDate = moment().subtract(1, 'months').toDate();
        $scope.courseInfo.endDate = moment().toDate();
      }
    }];

    $scope.usePredefined = function(d) {
      d.applyDates();
    };

    $scope.$watch('courseInfo.endDate', function(newVal) {
      $scope.startDateOpt = {
        maxDate: newVal
      };
    });

    $scope.$watch('courseInfo.startDate', function(newVal) {
      $scope.endDateOpt = {
        minDate: newVal
      };
    });
  }
})();
