import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { LegacyAppService } from 'app/core/legacy-app.service';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { LessonDTO, PublishRecordDTO, PublishInfoDTO, TeachingModuleDTO } from 'app/lesson/models/lesson';
import { LessonService } from 'app/lesson/services/lesson.service';
import * as _ from 'lodash'
import { mergeMap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-module-tohw',
  template: require('./module-tohw.component.html'),
  styles: [require('./module-tohw.component.scss')]
})
export class ModuleTohwComponent implements OnInit {

  public isPublished = false
  @Input() public tModule: TeachingModuleDTO
  @Input() public isRecommend = false
  @Input() public course: LessonDTO
  private uibModal: any
  private records: PublishRecordDTO[]
  private publishedClasses: number[]
  private selectStudent: {[key: number]: number[]} = {}
  constructor(
    private oedDialogService: OedDialogService,
    private lessonService: LessonService,
    private legacyAppService: LegacyAppService,
  ) {
    this.uibModal = legacyAppService.getUibModal()
  }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.tModule && this.tModule.id) {
      this.isModulePublished()
    }
  }

  public tohw() {
    this.openPublishModal()
  }

  public isModulePublished() {
    this.lessonService.getPubRecordsOfModule(this.tModule.id).pipe(
      mergeMap((records: PublishRecordDTO[], index: number) => {
      this.isPublished = !_.isEmpty(records)
      this.records = records
      const pubInfo =  _.map(records, (r) => {
        return this.lessonService.getPublishRecordInfo(r.id)
      })
      return forkJoin(pubInfo)
    })).subscribe((pubInfos: PublishInfoDTO[]) => {
      this.publishedClasses = _.map(pubInfos, 'cls.id')
      _.each(pubInfos, (p: PublishInfoDTO) => this.selectStudent[p.cls.id] = p.publishedStudents)
    }, (error) => console.error(error))
  }

  private openPublishModal() {
    const modalInstance = this.uibModal.open({
      template: require('assets/templates/prepareCourse/publishTm.html'),
      controller: 'publishTmCtrl',
      size: 'md',
      windowClass: 'oedmodal modalCenter',
      resolve: {
        data: () => {
          return {
            course: this.course,
            tmId: this.tModule.id,
            publishedClasses: _.uniq(this.publishedClasses),
            selectStudent: this.selectStudent
          };
        }
      }
    })

    modalInstance.result.then((data) => {
      const records = _.get(data, 'records');
      if (records && !_.isEmpty(records)) {
        this.isModulePublished()
      }
    })
  }

}
