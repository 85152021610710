(function() {
  angular.module('app.teach', ['ui.router', 'ngCookies'])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('teach', {
        url: '/teach',
        template: require('assets/templates/teach.html'),
        controller: 'teachCtrl',
        loadingCls: 'greenloading'
      });
    }])
    .controller('teachCtrl', teachCtrl);

  teachCtrl.$inject = ['$scope']
  function teachCtrl($scope) {}
})();
