import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {OnlineTrainingDirectoryDTO} from 'app/online-train/models/online-train';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import { StateService } from '@uirouter/core'
import {TrainMassageService} from 'app/online-train/train-massage.service';

@Component({
  selector: 'app-train-paradigms',
  template: require('./train-paradigms.component.html'),
  styles: [require('./train-paradigms.component.scss')]
})
export class TrainParadigmsComponent implements OnInit {
  @Input() public params: any

  private parentId
  private editable
  private prefix
  private allDirs: OnlineTrainingDirectoryDTO[]
  private dirTree
  private dirs: OnlineTrainingDirectoryDTO[]
  private selectedDir: OnlineTrainingDirectoryDTO

  private dirsLvl4: OnlineTrainingDirectoryDTO[]
  private lvl4ParentId
  constructor(private state: StateService,
              private onlineTrainService: OnlineTrainService,
              private trainMessage: TrainMassageService) { }

  ngOnInit() {
    this.parentId = _.parseInt(this.params.parentId)
    this.editable = this.params.editable
    this.prefix = this.params.prefix
    this.loadData()
  }

  public selectDir(dir) {
    this.selectedDir = dir
    this.dirsLvl4 = this.dirTree[this.selectedDir.id]
    this.lvl4ParentId = this.selectedDir.id
  }

  private loadData() {
    this.trainMessage.sendLoadingAction(true)
    this.onlineTrainService.getAllDirs().subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
      this.initData(dirs)
    }, (err) => {console.log(err)}, () => {this.trainMessage.sendLoadingAction(false)})
  }

  private initData(dirs: OnlineTrainingDirectoryDTO[]) {
    this.allDirs = _.filter(dirs, (d: OnlineTrainingDirectoryDTO) => d.id > 1)
    this.dirTree = this.onlineTrainService.buildDirTree(this.allDirs)
    this.dirs = this.dirTree[this.parentId]

    if (!_.isEmpty(this.dirs)) {
      this.selectedDir = this.selectedDir ? this.selectedDir : this.dirs[0]
      this.selectDir(this.selectedDir)
    }
  }
}
