import * as _ from 'lodash';
import { UserDTO } from "app/models/user";

export function getUserAvatarUrl(u: UserDTO, uid?: number) {
  if (!_.has(u, 'uid')) {
    if (!uid) {
      return null;
    }
    return '/ray/account/' + uid + '/avatar';
  }

  if (_.includes(u.roles, 'student')) {
    if ('female' == u.gender) {
      if (u.uid % 2 == 1) {
        return 'Assets/images2/stu_avatar_f1.png';
      } else {
        return 'Assets/images2/stu_avatar_f2.png';
      }
    } else {
      if (u.uid % 2 == 1) {
        return 'Assets/images2/stu_avatar_m1.png';
      } else {
        return 'Assets/images2/stu_avatar_m2.png';
      }
    }
  } else { //老师
    if ('female' == u.gender) {
      return 'Assets/images2/avatar_teacher_f.png';
    } else {
      return 'Assets/images2/avatar_teacher_m.png';
    }
  }
}

export function getStdAvatarImgSrc(std: UserDTO) {
  if ('female' === std.gender) {
    if (std.uid % 2 == 1) {
      return 'Assets/images2/stu_avatar_f1.png';
    } else {
      return 'Assets/images2/stu_avatar_f2.png';
    }
  } else {
    if (std.uid % 2 == 1) {
      return 'Assets/images2/stu_avatar_m1.png';
    } else {
      return 'Assets/images2/stu_avatar_m2.png';
    }
  }
}

