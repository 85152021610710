(function() {
  'use strict';

  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentTaskDetails.res.presentResource', {
        url: '/presentResource/:resId',
        template: require('assets/templates/student/studentTaskDetailsPresentResource.html'),
        controller: 'studentTaskDetailsPresentResourceCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('studentTaskDetailsPresentResourceCtrl', studentTaskDetailsPresentResourceCtrl);

  studentTaskDetailsPresentResourceCtrl.$inject = ['$rootScope', '$scope', '$state',
    '$stateParams', '$q', 'dialogs', 'Lightbox', 'userInfo', 'oedConfig', 'oedCoursePath',
    'oedPreResSession', 'oedPreResSessionStudent',
    'oedTaskList', 'oedTask', 'oedTaskListPublishRecord', 'oedPresentResource', 'oedTaskPresentResource', 'oedOem']
  function studentTaskDetailsPresentResourceCtrl($rootScope, $scope, $state,
    $stateParams, $q, dialogs, Lightbox, userInfo, oedConfig, oedCoursePath,
    oedPreResSession, oedPreResSessionStudent,
    oedTaskList, oedTask, oedTaskListPublishRecord, oedPresentResource, oedTaskPresentResource, oedOem) {
    var recordId = _.parseInt($stateParams.recordId);
    var taskId = _.parseInt($stateParams.taskId);
    var resId = _.parseInt($stateParams.resId);

    $scope.enableManage = oedOem.curOem() != 'futureSchool'
    $scope.taskId = taskId;

    $scope.resource = oedPresentResource.get({
      id: resId
    });

    $scope.preResSession = oedPreResSession.getPreResSessionByTaskListRecordAndPreResId({
      recordId: recordId,
      taskId: taskId,
      preResId: resId
    });

    $scope.taskPreReses = oedTaskPresentResource.queryByTaskId({
      id: taskId
    });

    userInfo.then(function(info) {
      var uid = _.parseInt(info.uid);

      $scope.loading = $q.all([$scope.taskPreReses, $scope.resource.$promise,
                               $scope.preResSession.$promise, $scope.record.$promise]).then(function(data) {
        var taskPreRes = data[0];
        var preRes = data[1];
        var preResSession = data[2];
        var record = data[3];

        $scope.loaded = true;
        $scope.currentState.subTitle = preRes.name + '(' + preRes.items.length + ')';

        $scope.isTaskListCompleted = false;
        var dueDate = record.dueDate;
        if (dueDate && dueDate - Date.now() <= 0) {
          $scope.isTaskListCompleted = true;
        }

        var tpr = _.find(taskPreRes, function(r) {
          return r.resId == resId;
        });

        oedPreResSessionStudent.queryByPreResSessionAndStudentId({
          sessionId: preResSession.id,
          studentId: uid
        }).$promise.then(function(preResSessionStudent) {

        }).catch(function(err) {
          oedPreResSession.submitSession({
            sessionId: preResSession.id,
            studentId: uid
          }, {
            sessionId: preResSession.id,
            studentId: uid
          }).$promise.then(function(r) {
            $rootScope.$broadcast('readPresentResource', {
              taskId: taskId,
              resId: tpr.id
            });
          });
        });
      });
    });

    $scope.basePath = oedConfig.base();

    $scope.showPreview = function(reses, idx) {
      Lightbox.openModal(reses, idx);
    };

    $scope.getItemName = function(item) {
      if (_.get(item, 'resourceName'))
        return _.get(item, 'resourceName');

      if (_.get(item, 'flResource.name'))
        return _.get(item, 'flResource.name');
      return '';
    };
  }
})();
