const uiRouter = require('@uirouter/angularjs');
const upgradeModule = require('@uirouter/angular-hybrid');

require('app2/core/configure.module')
require('app2/core/index');
require('app2/directives/index');
require('app2/directives/question/index');
require('app2/directives/schoolFeature/oedIfSupport.directive');
require('app2/prepareCourse/index');
require('app2/newPrepareCourse/index');
require('app2/manage/index');
require('app2/filters/index');
require('app2/lang/zh_CN');
require('app2/utils/index');
require('app2/preview/index')
require('app2/directives/course/prepareLesson.directive');
require('app2/prepareCourse/quickCreate/objectTestPreview/qObjectTestPreview.directive');
require('app2/services/communicate.service')

const moment = require('moment')
const _ = require('lodash')

/* App Module */
var app = angular.module('oedApp', [
  'angular-loading-bar',
  'angular-cache',
  'ngFileUpload',
  'cgBusy',
  'cgNotify',
  'dialogs.main',
  'htmldiff',
  'dialogs.default-translations',
  'frapontillo.bootstrap-switch',
  'app.lang.zh-CN',
  'duScroll',
  'timer',
  'ui.router',
  'ui.router.upgrade',
  'ui.bootstrap',
  'ui.indeterminate',
  'ui.select',
  'ui.sortable',
  'ui.utils',
  'LocalStorageModule',
  'ngAnimate',
  'ngCookies',
  'ngDragDrop',
  'ngImgCrop',
  'ngJsTree',
  'ngMessages',
  'ngResize',
  'ngResource',
  'ngSanitize',
  'ngTagsInput',
  'ng.ueditor',
  'ui.tree',
  'ksSwiper',
  'xeditable',
  'ng-echarts',
  'angularMoment',
  'monospaced.qrcode',
  'oed.configure',
  'app.core.newVersionCtrl',
  'app.home',
  'app.signIn',
  'app.signUp',
  'app.signOut',
  'app.signin.findPassword',
  'app.postLogin.createSchool',
  'app.prepareLesson',
  'app.examination',
  'app.resCenter',
  'app.prepareCourse',
  'app.prepareCourse2',
  'app.newPrepareCourse',
  'app.newPrepareCourse2',
  'app.preview',
  'app.prepareCourse.resourcePreview',
  'app.prepareCourse.qObjectTestPreview',
  'app.manage',
  'app.manage2',
  'app.review',
  'app.review.qObjectTestPreview',
  'app.student',
  'app.teach',
  'app.query',
  'app.resources',
  'app.directives',
  'app.directives.question',
  'app.directives.cloud',
  'app.directives.commentComposer',
  'app.directives.userInfo',
  'app.directives.menuUserInfo',
  'app.directives.studentInfo',
  'app.directives.domLoadedRender',
  'app.directives.imageOnLoad',
  'app.directives.divKeepRatio',
  'app.directives.tag',
  'app.directives.smallData',
  'app.directives.smallData.objData',
  'app.directives.smallData.sbjData',
  'app.directives.smallData.selfData',
  'app.services.communicate',
  'app.filters',
  'app.homework',
  'app.utils',
  'app.activate',
  'oedweb.next',
  'app.directives.course.prepareLesson',
  'app.directives.checkStudentAnswer',
]);

app.value('cgBusyDefaults', {
  message: '',
  backdrop: false,
  template: require('assets/vendor/angular-busy/angular-busy.html'),
  delay: 0,
  minDuration: 0
});

function runApp($q, $http, $rootScope, $templateCache, amMoment,
          notify, oedConfig, oedMisc, oedLoading, userInfo, $state,
          oedVersionChecker, editableOptions, $uibModalStack, localStorageService, oedLog, $window) {
  window.digestTest = () => $rootScope.$digest()
  $rootScope.$digest2 = _.debounce($rootScope.$digest, 0)
  $http.defaults.headers.common.oedRequestFrom = 'Web';
  amMoment.changeLocale('zh-cn');
  editableOptions.theme = 'bs3';
  window.addEventListener('click',(e)=>{
     const now = _.now();
     const lastUpdate = localStorageService.get('bigdata_last_report_timestamp');
     if(lastUpdate && now - lastUpdate > 60 * 1000){
      const uid = localStorageService.get('bigdata_uid');
      const type = $state.current.name;
      const pre_type = localStorageService.get('pre_type');
      const sessionId = localStorageService.get('newParpreCourse_session_id');
      if (!_.isEmpty(type) && _.isNumber(uid)) {
       var courseId = _.isNil($state.params.courseId) ? $state.params.courseId : _.parseInt($state.params.courseId);
       var usage = {
         uid: uid,
         type: type,
         preType:pre_type,
         sessionId: sessionId,
         userAgent: $window.navigator.userAgent
       };
       if (courseId >= 0) {
         _.set(usage, 'courseId', courseId);
       }
       oedLog.addWebUsage(usage).$promise.then(r => {
         if(r){
           localStorageService.set('newParpreCourse_session_id',r.sessionId);
           localStorageService.set('pre_type',r.type);
         }
       });
      localStorageService.set('bigdata_last_report_timestamp', now);
      }
     }
  });

  var defer = null;
  $rootScope.showHoverIcon = function() {
    var isWebview = /pad_teacher_webview/.test(navigator.userAgent);
    //var version = navigator.userAgent.match(/Android\s+([\d\.]+)/)[1];
    //return isWebview && (parseFloat(version) < 5.0);
    return isWebview;
  };
  $rootScope.$on('$stateChangeStart',
    function(event, toState, toParams, fromState, fromParams) {
      if (toState.loadingCls) {
        defer = $q.defer();
        oedLoading.wait(defer.promise, toState.loadingCls);
      }
      //
      // 对于state改变或者参数变了的情况, 关掉所有modal
      // 如果只是hash变了, 忽略
      if (_.get(toState, 'name') != _.get(fromState, 'name') ||
          !_.isEqual(_.omit(fromParams, '#'), _.omit(toParams, '#'))) {
        $uibModalStack.dismissAll();
      }
  });
  $rootScope.$on('bigdata_report', function(event, data) {
    var uid = localStorageService.get('bigdata_uid');
    const type = $state.current.name;
    const pre_type = localStorageService.get('pre_type');
    const sessionId = localStorageService.get('newParpreCourse_session_id');
    if (!_.isEmpty($state.current.name) && _.isNumber(uid)) {
      var courseId = _.isNil($state.params.courseId) ? $state.params.courseId : _.parseInt($state.params.courseId);
      var usage = {
        uid: uid,
        type: type,
        preType: pre_type,
        sessionId: sessionId,
        userAgent: $window.navigator.userAgent
      };
      if (courseId >= 0) {
        _.set(usage, 'courseId', courseId)
      }
      oedLog.addWebUsage(usage).$promise.then(r => {
        if(r){
          localStorageService.set('newParpreCourse_session_id',r.sessionId);
          localStorageService.set('pre_type',r.type);
        }
      });
    }
  });
  $rootScope.$on('$stateChangeSuccess',
    function(event, toState, toParams, fromState, fromParams) {
      if (defer) {
        defer.resolve();
        defer = null;
      }

      var uid = localStorageService.get('bigdata_uid');
      // var sessionId = localStorageService.get('bigdata_session_id');
      var sessionId = localStorageService.get('newParpreCourse_session_id');
      var currentStateStart = localStorageService.get('bigdata_current_state_start');
      const preType = localStorageService.get('pre_type');
      var now = _.now();
      if (!_.isNumber(currentStateStart)) {
        localStorageService.set('bigdata_current_state_start', now);
      } else if (now - currentStateStart > 10 * 1000) {
        if (!_.isEmpty(fromState.name) && _.isNumber(uid)) {
          var courseId = _.isNil(fromParams.courseId) ? fromParams.courseId : _.parseInt(fromParams.courseId);
          var usage = {
            uid: uid,
            type: toState.name ? toState.name : '',
            preType: preType,
            sessionId: sessionId,
            userAgent: $window.navigator.userAgent
          };
          if (courseId >= 0) {
            _.set(usage, 'courseId', courseId)
          }
          oedLog.addWebUsage(usage).$promise.then(r => {
            if(r){
              localStorageService.set('newParpreCourse_session_id',r.sessionId);
              localStorageService.set('pre_type',r.type);
            }
          });
        }
        localStorageService.set('bigdata_current_state_start', now);
      }

      $rootScope.isMobile = toState.isMobile;

      oedMisc.hasPrevState = !!fromState.name;

      if (toState.name == 'signout' || toState.name == 'studentSignout') {
        //
        // 在signout时会访问 sigout 这个时候会删除session
        // 如果同时更新用户信息, 会因为session不存在而抛出异常
      } else {
        //
        // temporary solution to make sure user is logged in
        userInfo.update();
      }
      var body = $('body');
      var newStyle = toState.bodyStyle || '';
      body.attr('class', newStyle);
    });

  $rootScope.$on('$stateChangeError',
    function(event, toState, toParams, fromState, fromParams, error) {});

  notify.config({
    duration: 3000,
    maximumOpen: 1
  });

  oedVersionChecker.init({
    updateInterval: 5 * 60 * 1000 //每5分钟检查一次更新
  });

//  //
//  // 集成bluebird resovle的时候触发rootScope的digest cycle
//  Promise.setScheduler(function(cb) {
//    $rootScope.$evalAsync(cb);
//  });

  $templateCache.put('Assets/templates/utils/imagePreviewDialog.html',
    require('assets/templates/utils/imagePreviewDialog.html'));

  $templateCache.put('Assets/templates/utils/newversion.html',
    require('assets/templates/utils/newversion.html'));
  //
  //
  // TODO: Refactor 资源库界面, 使它不依赖于ng-include
  $templateCache.put('Assets/templates/prepareCourse/cloudSubjectiveTestItem.html',
     require('assets/templates/prepareCourse/cloudSubjectiveTestItem.html'));

  $templateCache.put('Assets/app2/directives/cloud/starInfoTooltip.html',
     require('assets/app2/directives/cloud/starInfoTooltip.html'));

  $templateCache.put('Assets/templates/prepareCourse/defaultCloudResourceItem.html',
     require('assets/templates/prepareCourse/defaultCloudResourceItem.html'));

  $templateCache.put('Assets/templates/prepareCourse/cloudObjectiveTestHeaderAddon.html',
     require('assets/templates/prepareCourse/cloudObjectiveTestHeaderAddon.html'));
  $templateCache.put('Assets/templates/prepareCourse/cloudObjectiveTestItem.html',
      require('assets/templates/prepareCourse/cloudObjectiveTestItem.html'));

  $templateCache.put('Assets/templates/prepareCourse/cloudPresentResourceHeaderAddon.html',
      require('assets/templates/prepareCourse/cloudPresentResourceHeaderAddon.html'));
  $templateCache.put('Assets/templates/prepareCourse/cloudPresentResourceItem.html',
     require('assets/templates/prepareCourse/cloudPresentResourceItem.html'));

  $templateCache.put(('Assets/templates/prepareCourse/cloudTeachingPatternItem.html'),
      require('assets/templates/prepareCourse/cloudTeachingPatternItem.html'));
}

runApp.$inject = [
  '$q', '$http', '$rootScope', '$templateCache', 'amMoment',
  'notify', 'oedConfig', 'oedMisc', 'oedLoading', 'userInfo', '$state',
  'oedVersionChecker', 'editableOptions', '$uibModalStack', 'localStorageService', 'oedLog', '$window'];
app.run(runApp);

angular.module('app.home', ['ui.router', 'app.directives.oedIfSupport'])
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('home', {
      url: '/?openCloud',
      template: require('assets/templates/home.html'),
      controller: 'homeCtrl',
      loadingCls: 'homeloading',
      bodyStyle: 'newstyle'
    });

    $stateProvider.state('classes', {
      url: '/classess',
      //TODO: fix it
      template: '', //require('assets/templates/classes.html'),
      loadingCls: 'classesloading'
    });

    $stateProvider.state('news', {
      url: '/news',
      //TODO: fix it
      template: '', //require('assets/templates/news.html'),
      loadingCls: 'newsloading'
    });
  }])
  .controller('homeCtrl', homeCtrl);

  homeCtrl.$inject = ['$scope', '$http', '$state', '$uibModal', '$stateParams', 'userInfo', 'preferenceService']
  function homeCtrl($scope, $http, $state, $uibModal, $stateParams, userInfo, preferenceService) {
    $('.newcontent').animate({
      'opacity': 1,
      'left': '0px'
    }, 400);

    $scope.openCloudDialog = _.get($stateParams, 'openCloud', false)

    // 获取新版状态
    userInfo.then((info) => {
      $scope.userInfoDto = info;
      $scope.isResearcher = _.findIndex(_.get(info, 'roles', []), (r) => {return r === 'researcher'}) !== -1;
      return preferenceService
        .getSchoolNewPrepareLessonState(info.schoolId)
        .toPromise()
        .then((state) => {
          $scope.schoolNewPrepareLessonState = state;
          return preferenceService.getUserNewPrepareLessonState(info.uid).toPromise();
        })
    })
      .then((state) => {
        $scope.userNewPrepareLessonState = state;
      })
      .catch((error) => {
      });

    $scope.clickPreCourse = function () {
      // 学校开启了新版备课
      // if ( $scope.schoolNewPrepareLessonState != null && $scope.schoolNewPrepareLessonState.value === '1'){
      //   if ($scope.userNewPrepareLessonState != null &&
      //     ($scope.userNewPrepareLessonState.value === '1'|| $scope.userNewPrepareLessonState.value === '0')) {
      //     $state.go($scope.userNewPrepareLessonState.value === '1' ? 'prepareLesson2': 'prepareLesson');
      //   }else {
      //     // 提醒用户 选择新版 旧版
      //     const uId = Number($scope.userInfoDto.uid);
      //     $uibModal.open({
      //       size: 'upgrade-pre',
      //       openedClass: 'is-choose-new',
      //       template: require('assets/templates/upgradePreLession.html'),
      //       controller: ($scope) => {
      //         $scope.ok = function() {
      //           $scope.$dismiss();
      //           preferenceService.updateUserNewPrepareLessonState({
      //             uid: uId,
      //             value: '1',
      //           }).toPromise()
      //             .catch((error) => {
      //               console.error(error)
      //             });
      //           $state.go('prepareLesson2');
      //         };

      //         $scope.cancel = function () {
      //           $scope.$dismiss();
      //           preferenceService.updateUserNewPrepareLessonState({
      //             uid: uId,
      //             value: '0',
      //           }).toPromise().catch((error) => {});
      //           $state.go('prepareLesson');
      //         }
      //       }
      //     });
      //   }
      // }else {
      //   // 跳旧版
      //   $state.go('prepareLesson');
      // }
      if ($scope.isResearcher) {
        $state.go('resCenter');
      } else {
        $state.go('prepareLesson');
      }
    }
  }

app.filter('propsFilter', function() {
  return function(items, props) {
    var out = [];

    if (angular.isArray(items)) {
      items.forEach(function(item) {
        var itemMatches = false;

        var keys = Object.keys(props);
        for (var i = 0; i < keys.length; i++) {
          var prop = keys[i];
          var text = props[prop].toLowerCase();
          if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
            itemMatches = true;
            break;
          }
        }

        if (itemMatches) {
          out.push(item);
        }
      });
    } else {
      // Let the output be the input untouched
      out = items;
    }

    return out;
  };
})
  .provider('oedLoading', function() {
    var loader = $('#loading');
    doGet.$inject = ['$q']
    function doGet($q) {
      return {
        wait: function(d, cls) {
          loader.attr('class', cls)
            .addClass('show');
          $q.when(d).finally(function() {
            loader.removeClass('show');
          });
        }
      };
    }
    this.$get = doGet;
  })
  .filter('SafeHtml', ['$sce', function($sce) {
    return function(text, target, otherProp) {
      return $sce.trustAsHtml(text);
    };
  }])
  .filter('truncate', function() {
    //
    // from: https://github.com/igreulich/angular-truncate/blob/master/src/igTruncate.js
    return function(text, length, end) {
      if (!_.isEmpty(text)) {
        if (isNaN(length)) {
          length = 10;
        }
        if (end === undefined) {
          end = '...';
        }
        if (end.length >= text.length) {
          return text;
        }

        if (text.length <= length) {
          return text;
        } else {
          return String(text).substring(0, length - end.length) + end;
        }
      }
      return undefined;
    };
  })
  .directive('uiSelectRequired', function() {
    //
    // TODO: workaround, 在集成ui-select最新版后应当移除
    return {
      require: 'ngModel',
      link: function(scope, elm, attrs, ctrl) {
        ctrl.$validators.uiSelectRequired = function(modelValue, viewValue) {

          var determineVal;
          if (angular.isArray(modelValue)) {
            determineVal = modelValue;
          } else if (angular.isArray(viewValue)) {
            determineVal = viewValue;
          } else {
            return false;
          }

          return determineVal.length > 0;
        };
      },
    };
  });

