/**
 * @fileOverview
 * @name addWebUrlItemCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  angular.module('app.prepareCourse')
    .controller('addWebUrlItemCtrl', addWebUrlItemCtrl);

  addWebUrlItemCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'Upload',
    'oedConfig', 'oedPresentResourceItem', 'notificationService', 'data','oedPresentResource']
  function addWebUrlItemCtrl($scope, $q, $uibModalInstance, Upload,
    oedConfig, oedPresentResourceItem, notificationService, data,oedPresentResource) {
    $scope.res = {
      resourceId: data.resourceId
    };

    $scope.ok = function() {
      if (data.moduleId) {
        if (data.packageId) {
          $scope.addItemToPackage(data.packageId);
        } else {
          oedPresentResource.createResourcePackge({ moduleId: data.moduleId }, {}).$promise.then(r => {
            return r;
          }).then(data => {
            $scope.addItemToPackage(data.id);
          });
        }
      } else {
        $scope.addUrl();
      }
    };
    $scope.addItemToPackage = (id) => {
      $scope.packageId = id;
      oedPresentResource.get({ id: id }).$promise.then(x => {
        $scope.res.resourceId = x.id
        $scope.addUrl();
      })
    }
    $scope.addUrl = function() {
      $scope.showValidationError = true;
      if (!$scope.addResItemForm.$valid) {
        return;
      }

      if (!_.startsWith($scope.res.webUrl, 'http://') && !_.startsWith($scope.res.webUrl, 'https://')) {
        $scope.res.webUrl = 'http://' + $scope.res.webUrl;
      }

      $scope.loading = oedPresentResourceItem.save($scope.res).$promise.then(function() {
        if(data.moduleId){
          $uibModalInstance.close($scope.packageId);
        }else{
          $uibModalInstance.close($scope.res);
        }
      }).catch(function() {
        notificationService.notify('error', '保存链接失败')
      });
    }
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
