import angular = require('angular');
import _ = require('lodash');
import { isQuickWayToCreatePrepareCourse } from 'app2/prepareCourse/utils/prepareCourseUtils'

require('assets/styles/oedTable.scss');

angular.module('app.review.objTestPreviewer', [])
  .controller('objTestPreviewerCtrl', objTestPreviewerCtrl);

objTestPreviewerCtrl.$inject = ['$scope', 'data', '$uibModalInstance',
  'oedObjectiveTest', '$q']
function objTestPreviewerCtrl(
  $scope: any, data: any, $uibModalInstance: any,
  oedObjectiveTest: any, $q: any) {

  $scope.extraInfo = ''

  $scope.objTest = oedObjectiveTest.get({
    id: data.testId,
  });
  $scope.loading = $scope.objTest.$promise.then(() => {
    if ($scope.getTheWayWithCreateTest()) {
      $scope.childQuestionList = $scope.objTest.questions[0].childQuestions
      $scope.imageRes = $scope.objTest.questions[0]['resourceUUIDs']
    }
    if ($scope.objTest.isTemp) {
      return oedObjectiveTest.getObjTestQuestionType({id: $scope.objTest.id}).$promise.then((r) => {
        if (r && r.testId && r.extraInfo === 'yesorno') {
          $scope.extraInfo = 'yesorno'
        } else {
          $scope.extraInfo = 'singlechoice'
        }
      })
    }
  })
  $scope.close = () => {
    $uibModalInstance.close();
  };
  $scope.getTheWayWithCreateTest = () => {
    return isQuickWayToCreatePrepareCourse($scope.objTest)
  }
}
