import { OedDialogService } from 'app/core/oed-dialog.service';
import { OedExerciseService } from './../../oed-exercise.service';
import { Component, OnInit, Input, Inject, forwardRef, Output, EventEmitter } from '@angular/core';
import { NotificationService } from 'app/shared/notification/notification.service';
import { LoadingService } from 'app/shared/loading/loading.service';
import _ = require('lodash');


@Component({
  selector: 'app-ex-paper-list-dialog',
  template: require('./ex-paper-list-dialog.component.html'),
  styles: [require('./ex-paper-list-dialog.component.scss')]
})
export class ExPaperListDialogComponent implements OnInit {
  @Input() private exerciseBookId: number;
  @Output() private action = new EventEmitter<any>()

  private paperList: any[]

  constructor(
    private notifyService: NotificationService,
    private oedExerciseService: OedExerciseService,
    private loadingService: LoadingService,
    @Inject(forwardRef(() => OedDialogService)) private oedDialogService: OedDialogService,
  ) { }

  ngOnInit() {
    this.loadTests()
  }

  private loadTests = () => {
    this.oedExerciseService.getTestsByExBookId(this.exerciseBookId)
      .pipe(this.loadingService.withLoading())
      .subscribe(
        (res: any[]) => this.paperList = res,
        e => this.notifyService.notify('error', '获取试卷信息失败，请稍后再试')
      )
  }

  private doDel = (paper: any) => {
    const nodeId = _.get(paper, 'nodeId', 0)
    const testId = _.get(paper, 'testId', 0)
    this.oedExerciseService.deleteTest(nodeId, testId)
      .pipe(this.loadingService.withLoading())
      .subscribe(
        () => {
          this.notifyService.notify('info', '试卷删除成功')
          _.remove(this.paperList, i => _.get(i, 'testId', 0) == _.get(paper, 'testId', 0))
          this.action.emit(!_.isEmpty(this.paperList));
        },
        e => this.notifyService.notify('error', '创建删除失败，请稍后再试')
      )
  }

  private onDelete = (paper: any) => {
    this.oedDialogService.openModalWithComponent2(
      'ExInfoDialogComponent',
      { title: '确认', info: '是否删除试卷?', isConfirm: true, btnText: '确认' },
      { class: 'ex-dialog-common' }
    ).subscribe(res => {
      if (res != 1) return
      this.doDel(paper);
    })
  }
}
