import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TrainMassageService {
  public loadingActionSource = new Subject<boolean>();
  public loadingAction$ = this.loadingActionSource.asObservable();
  constructor() { }

  public sendLoadingAction(loading: boolean) {
    this.loadingActionSource.next(loading)
  }
}
