import * as angular from 'angular';
import Promise = require('bluebird');
import * as _ from 'lodash';

angular.module('app.service.obSessionService', [])
.factory('objSessionService', ['oedTestSessionStat', '$q', 'oedTaskListPublishRecord',
  'oedClassUser', 'oedTestSession', 'oedUserInfo', (
  oedTestSessionStat: any, $q: any, oedTaskListPublishRecord: any,
  oedClassUser: any, oedTestSession: any, oedUserInfo: any) => {
  return {
    getStdListFromClassSession: queryStdListFromClassSession,
    getStdListFromClassSessionWithRemoteVoting: queryStdListWithRemoteVoting,
  };

  /**
   * 提交的学生的submitTime不为null
   * @param students 全部学生
   * @param map 参与答题的学生
   */
  function partitionSubmitStudents(students: any, map: any) {
    _.forEach(students, (s: any) => {
      if (s.uid in map && !_.isNil(s.submitTime)) {
        s.submitProgress = 100;
      }
    });
    const part = _.partition(students, (s: any) => {
      return s.submitProgress === 100;
    });
    return $q.resolve(part);
  }

  /**
   * 获取上课后的查询下学生完成与未完成列表
   * @param id classId
   * @param testSessionId testSessionId
   * @return [] => 0: finishedStds, 1: unFinishedStds, 2: unJoinStds
   */
  function queryStdListFromClassSession(id: any, testSessionId: any) {
    const testSessionDetails = oedTestSession.getDetails({
      sessionId: testSessionId,
    })
    const classStds = oedClassUser.queryClassStudents({
      classId: id,
    });
    const summary = oedTestSessionStat.queryByTestSessionId({
      sessionId: testSessionId,
    });
    return $q.all([testSessionDetails.$promise, classStds.$promise, summary.$promise]).then(() => {
      const mapClassStdsByUid: any = _.keyBy(classStds, 'uid');
      return $q.all(_.each(testSessionDetails.students, (std: any) => {
        std.uid = std.studentId
        if (_.has(mapClassStdsByUid, std.studentId)) {
          std.name = mapClassStdsByUid[std.studentId].name;
          std.gender = mapClassStdsByUid[std.studentId].gender;
        } else { // 该学生可能已经被移出班级
          return oedUserInfo.getByUid({
            id: std.uid,
          }).$promise.then((info: any) => {
            std.name = info.name
            std.gender = info.gender
          })
        }
      })).then(() => {
        const map = _.keyBy(summary.statOfRank.rows, 'stdId');
        return partitionSubmitStudents(testSessionDetails.students, map).then((stds: any) => {
          return partitionUnJoinStds(stds);
        });
      })
    });
  }

  function queryStdListWithRemoteVoting(testSessionId: number) {
    const testSessionDetails = oedTestSession.getRemoteVotingStat({
      testSessionId: testSessionId,
    })
    return testSessionDetails.$promise
  }

  /**
   * @param stds 全部学生
   * @return 提交的学生-未完成的学生-未参与的学生
   */
  function partitionUnJoinStds(stds: any) {
    const finishedStds = stds[0]
    const unsubmitStds = stds[1]
    const part = _.partition(unsubmitStds, (s: any) => {
      return s.wasOnline;
    })
    const unFinishedStds = part[0]
    const unJoinStds = part[1]
    return [finishedStds, unFinishedStds, unJoinStds]
  }

}]);
