import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-permission',
  template: require('./no-permission.component.html') as string,
  styles: [require('./no-permission.component.scss')]
})
export class NoPermissionComponent implements OnInit {
  constructor() { }
  ngOnInit() { }
}
