import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import * as _ from 'lodash'
import * as Promise from 'bluebird'
import { LegacyAppService } from 'app/core/legacy-app.service';

const questionTypes = {
  'yesorno': '判断',
  'singlechoice': '单选',
  'multichoice': '多选',
  'connect': '匹配',
  'fill': '填空',
  'vote': '投票',
  'synthetic': '综合',
  'qa': '问答'
}

const subQuestionTypes = _.omit(questionTypes, 'synthetic')

const questionTypeList = Object.keys(questionTypes)
const subQuestionTypeList = _.filter(questionTypeList, (v) => v !== 'synthetic')

@Component({
  selector: 'app-synthetic-designer',
  template: require('./synthetic-designer.component.html'),
  styles: [require('./synthetic-designer.component.scss')]
})
export class SyntheticDesignerComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() questionModel
  @Input() testId
  @Input() qIndex
  @Input() inEditMode
  @Input() enterEditing
  @Input() leaveEditing
  @Output() deleteClicked = new EventEmitter<any>()
  @Input() canMoveUp
  @Input() canMoveDown
  @Output() moveUpClicked = new EventEmitter<any>()
  @Output() moveDownClicked = new EventEmitter<any>()

  @Input() onSave
  @Output() scoreChanged = new EventEmitter<number>()
  @Input() qShowAnswer
  @Input() parentIndex: number
  @Input() sectionIdMap
  @Input() treeData
  @Input() showSections
  @Input() isThreeDegrees
  @Input() editorConfig

  @ViewChild('container') container: ElementRef

  choices = []
  errorForEmpty = false;
  currentChildQuestion = null
  editingChildQuestion = null
  inEditing
  saving: any
  loading: any

  constructor(private legacyApp: LegacyAppService) { }

  ngOnInit() {
    this.inEditing = this.inEditMode
  }

  ngAfterViewInit() {
    this.hookClickEvents()
  }

  ngOnDestroy() {
    this.container.nativeElement.removeEventListener('click', this.captureClicked, true);
    document.removeEventListener('click', this.captureDocumentClick, true);
  }

  hookClickEvents() {
    this.container.nativeElement.addEventListener('click', this.captureClicked, true);
    document.addEventListener('click', this.captureDocumentClick, true);
  }

  captureClicked = (evt) => {
    if (!this.inEditing) {
      this.inEditing = true;
      console.log('entering edit mode');
      document.removeEventListener('click', this.captureDocumentClick, true);
      document.addEventListener('click', this.captureDocumentClick, true);
      this.enterEditing(this.questionModel, evt)
    }
  }

  //
  // 检查是否离开问题编辑区域
  // 离开编辑区域的意思是:
  // 点击问题区外的任意位置
  captureDocumentClick = (event) => {
    if (!event.target || !this.inEditing) {
      return;
    }

    //
    // 当ueditor对话框打开时会有一个edui-mask的层
    // 当点击这个层的时候应当忽略
    const el = $(event.target);
    if (el.hasClass('edui-mask')) {
      return;
    }
    //
    // 如果点击的是ueditor弹出的对话框中的按钮或提示信息按钮的话
    // 也应当忽略掉点击事件
    const cls = '.ueditor, .edui-dialog, .edui-shadow, .edui-default, .cg-notify-message, .modal-dialog,' +
      '.select-label2, .batch-delete';
    const edDialog = el.closest(cls);
    if (edDialog.length > 0) {
      return;
    }

    const isChild = $(this.container.nativeElement).find(event.target).length > 0;
    if (isChild) {
      return;
    }

    console.log('leaving edit mode');
    const ret = this.leaveEditing(this.questionModel,event)

    if (ret === false) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      this.inEditing = false;
      document.removeEventListener('click', this.captureDocumentClick, true);
    }
  }

  getTypes() {
    return questionTypes
  }

  getCurrentQuesType() {
    if (this.questionModel.type == 'singlevote' || this.questionModel.type == 'multivote') {
      return 'vote';
    } else {
      return this.questionModel.type;
    }
  }

  isSubQuestion() {
    return false
  }

  getQuestionTypeList() {
    return questionTypeList
  }

  showRelatedSections() {
    return _.isUndefined(this.showSections)
  }

  setType (type: string) {
    if (type == this.getCurrentQuesType())
      return;

    const applyType = (t) => {
      const localStorageService = this.legacyApp.getLocalStorage()
      localStorageService.set('lastQuestionType', type);

      const prevType = this.questionModel.type;
      if (type == 'vote') {
        this.questionModel.type = this.isMultiVote ? 'multivote' : 'singlevote';
      } else {
        this.questionModel.type = type;
      }

      this.questionModel.answer = null;
      this.questionModel.childQuestions = [];

      this.choices = [];
      //
      // don't remove the options if switch between singlechoice/multichoice
      if (_.size(_.without(['singlechoice', 'multichoice'], prevType, type)) !== 0) {
        this.questionModel.choices = [];
        if (type == 'singlechoice' || type == 'multichoice' || type == 'vote') {
          _.times(4, () => {
            this.questionModel.choices.push({
              content: ''
            });
          });
        }
      }
    }

    if (!_.isEmpty(this.questionModel.childQuestions)) {
      this.legacyApp.getDialogs().confirm('确认修改问题类型',
                      '修改题型将导致当前添加的子题丢失，是否确认题型修改？')
        .result.then(() => {
          applyType(type);
        });
    } else {
      applyType(type);
    }
  }

  get isMultiVote() {
    if (this.questionModel.type === 'singlevote' || this.questionModel.type === 'multivote') {
      return this.questionModel.type === 'multivote';
    }

    return false
  }

  get questionPoint() {
    return this.questionModel.point2 || 1
  }

  set questionPoint(val: number) {
    this.questionModel.point2 = val
  }

  onMoveUpClicked(question: any) {
    this.moveUpClicked.emit(this.questionModel)
  }

  onMoveDownClicked(question: any) {
    this.moveDownClicked.emit(this.questionModel)
  }

  onDeleteClicked(question: any) {
    this.deleteClicked.emit(this.questionModel)
  }

  addSubQuestion() {
    if (this.saving) {
      this.saving.then((result) => {
        this.doAddSubQuestion();
        this.saving = null;
      });
    } else
      this.doAddSubQuestion();
  }

  doAddSubQuestion () {
    const newQ = this.defaultChildQuestion();
    if (_.isEmpty(this.questionModel.childQuestions))
      this.questionModel.childQuestions = [];
    this.questionModel.childQuestions.push(newQ);
    this.editingChildQuestion = newQ;
    this.onSubScoreChanged();
    /**this.childScrollAnchor = {
       relativeTo: '.ot-child-question-list',
       to: _.size(this.questionModel.childQuestions) - 1,
       stamp: _.uniqueId('qscroll_')
       };**/
  }

  defaultChildQuestion () {
    const localStorageService = this.legacyApp.getLocalStorage()
    let qType = localStorageService.get('lastQuestionType') || 'singlechoice';
    if (qType === 'synthetic')
      qType = 'singlechoice';
    if (qType == 'vote') {
      qType = 'singlevote';
    }
    let choices = [{}, {}, {}, {}];
    if (qType !== 'singlechoice' && qType != 'multichoice' && qType != 'singlevote' && qType != 'multivote') {
      choices = [];
    }

    const qPoint = localStorageService.get('lastQuestionPoint') || 1;

    return {
      answer: '',
      question: '',
      type: qType,
      point2: qType == 'synthetic' ? 0 : qPoint,
      choices: choices,
      leftOpts: [],
      rightOpts: [],
      needPic: false,
      answerDetails: null
    };
  }

  onSubScoreChanged() {
    this.questionModel.point2 = _.reduce(this.questionModel.childQuestions, (total, q) => {
      return total + q.point2;
    }, 0)
  }

  isAllQuestionValid() {
    const inValid = _.findIndex(this.questionModel.childQuestions, (ques: any) => {
      const tmpQ = {
        ...ques,
        isSub: true,
      }
      return !this.legacyApp.getTestUtils().validateQuestion(tmpQ).valid;
    });
    return inValid < 0
  }

  showQuesitonError(q) {
    return this.errorForEmpty && this.editingChildQuestion === q;
  }

  isNewQuestion(question) {
    return !_.get(question, 'id')
  }

  canMoveChildUp (question) {
    // 刚刚添加，尚未保存的子题，禁止上移
    if (!_.has(question, 'id')) {
      return false;
    }
    const idx = _.findIndex(this.questionModel.childQuestions, (q: any) => q.id === question.id);
    return idx > 0;
  }

  canMoveChildDown(question) {
    const idx = _.findIndex(this.questionModel.childQuestions, (q: any) => q.id === question.id);
    // 下面的一题不能是刚刚添加且尚未保存的子题
    return idx >= 0 && idx < (_.size(this.questionModel.childQuestions) - 1) &&
      _.has(_.get(this.questionModel.childQuestions, idx + 1, {}), 'id');
  }

  onMoveUpChildClicked (question) {
    const curIdx = _.findIndex(this.questionModel.childQuestions, (q: any) => q.id === question.id);
    if (curIdx <= 0) {
      return;
    }

    const temp = this.questionModel.childQuestions[curIdx - 1];
    this.questionModel.childQuestions[curIdx - 1] = question;
    this.questionModel.childQuestions[curIdx] = temp;

    this.saveQuestion(this.questionModel);
  }

  onMoveDownChildClicked (question) {
    const curIdx = _.findIndex(this.questionModel.childQuestions, (q: any) => q.id === question.id)
    if (curIdx < 0 || curIdx >= (_.size(this.questionModel.childQuestions) - 1)) {
      return;
    }

    const temp = this.questionModel.childQuestions[curIdx + 1];
    this.questionModel.childQuestions[curIdx + 1] = question;
    this.questionModel.childQuestions[curIdx] = temp;

    this.saveQuestion(this.questionModel)
  }

  isEditing () {
    return this.inEditing
  }

  saveQuestion(question) {
    if (!this.isEditing()) {
      return Promise.resolve()
    }
    this.saving = this.onSave(question)
    return this.saving;
  }

  backCurrentQuestion() {
    if (this.errorForEmpty) {
      this.errorForEmpty = false;
    }
  }

  onLeaveEditChildQuestion = (question, event) => {
    let ret: any = {
      valid: true
    };

    if (question.type === 'fill' && !this.isAllBlankHasAnswerSet(question)) {
      ret = {
        valid: false,
        message: '填空题需要设置所有非老师评判空的答案， 请设置答案后再加入试卷！'
      };
    } else if (_.isEmpty(question.answer) && !_.includes(['singlevote', 'multivote', 'qa'], question.type)) {
      ret = {
        valid: false,
        message: '请为当前题目设置答案！'
      };
    }
    if (!ret.valid) {
      this.errorForEmpty = true;
      this.legacyApp.getNotify()({
        message: ret.message,
        classes: 'alert-danger'
      });
      return false;
    }

    if (question.type !== 'synthetic' && question.type !== 'fill') {
      this.legacyApp.getLocalStorage().set('lastQuestionPoint', question.point2);
    }

    //子题应该忽略的点击事件列表
    const el = $(event.target);
    const cls = '.save-full-test';
    const edDialog = el.closest(cls);
    // 当前问题已经修改时要保存测试
    if (edDialog.length > 0 && !angular.equals(this.currentChildQuestion, question)) {
      this.onSubScoreChanged();
      this.loading = this.saveQuestion(this.questionModel);
    }

    this.currentChildQuestion = null
    this.editingChildQuestion = null

    return true;
  }

  isAllBlankHasAnswerSet(ques) {
    const content = _.get(ques, 'question', '');
    const count = (content.match(/__+/g) || []).length;
    //
    // 对于没有设置空的填空题也认为没有答案
    if (count === 0) {
      return false;
    }

    const answerArray = this.legacyApp.getTestUtils().convertFillAnswerStringToArray(_.get(ques, 'answer'));

    if (_.size(answerArray) != count) {
      return false;
    }

    const answerTypes = this.legacyApp.getTestUtils().convertFillAnswerTypesToArray(_.get(ques, 'answer'), _.size(answerArray));
    return _.findIndex(_.zip(answerTypes, answerArray), function(p) {
      return p[0] != 'manual-score' && _.isEmpty(p[1]);
    }) < 0;
  }

  isEditingQuestion(q) {
    return this.editingChildQuestion === q
  }

  onEnterEditChildQuestion = (question, event) => {
    this.currentChildQuestion = angular.copy(question)
    this.editingChildQuestion = question;
  }
}
