import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import $ = require('jquery');

@Component({
  selector: 'app-image-previewer',
  template: require('./image-previewer.component.html'),
  styles: [require('./image-previewer.component.scss')]
})
export class ImagePreviewerComponent implements OnInit {
  @Input() src: string;
  @ViewChild('preImg') public preImg: ElementRef
  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.initImagePreviewer();
  }
  public initImagePreviewer() {

    let ratio = 1.1
    const img: any = this.preImg.nativeElement
    console.log('loglog', img);
    img.addEventListener('mousewheel', MouseWheelHandler, false)
    img.addEventListener('DOMMouseScroll', MouseWheelHandler, false)

    $(this.preImg.nativeElement).draggable()

    function MouseWheelHandler(e: any) {
      e = window.event || e
      const delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)))
      if (delta === -1) {
        ratio = 0.95
      } else {
        ratio = 1.05
      }
      zoom()
    }

    function zoom() {
      const w = parseInt(img.width, 10) * ratio
      const h = parseInt(img.height, 10) * ratio

      this.preImg.nativeElement.width(w)
      this.preImg.nativeElement.height(h)
    }
  }
}
