(function() {

  var messages = require('app2/utils/messages');

  angular.module('app.prepareCourse')
    .config(['$stateProvider', function($stateProvider) {

    }])
    .controller('importFromLibraryByResIdCtrl', importFromLibraryByResIdCtrl);

  importFromLibraryByResIdCtrl.$inject = ['$scope', '$q', '$state', '$http', '$uibModal', '$uibModalInstance',
    '$log', '$timeout', 'notificationService', 'userInfo', 'oedMisc', 'resourceDef', 'queryData',
    'oedTestUtils']
  function importFromLibraryByResIdCtrl($scope, $q, $state, $http, $uibModal, $uibModalInstance,
    $log, $timeout, notificationService, userInfo, oedMisc, resourceDef, queryData,
    oedTestUtils) {

    $scope.resourceDef = _.defaults(resourceDef, {
      itemTemplateUrl: 'Assets/templates/prepareCourse/defaultCloudResourceItem.html'
    });

    var resType = resourceDef.viewType === 'question' ? 'cloudQuestion' : 'cloudTest';

    $scope.viewType = $scope.resourceDef.viewType;

    $scope.selected = {};
    $scope.hideAddBtn = true;

    var curCloudRes;
    var curCloudResPromise;
    if (resourceDef.excludeCurrent) {
      curCloudRes = resourceDef.res.getByResId({
        id: resourceDef.currentRes.id
      });
      curCloudResPromise = curCloudRes.$promise;
    }

    $scope.backToLibrary = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.courseInfo = {};
    $scope.queryOpt = {};
    $scope.queryString = null;

    var userInfoPromise = $q(function(resolve, reject) {
      userInfo.then(function(info) {
        resolve(info);
      });
    });

    $scope.queryItemsByResId = function() {
      return $q.when(curCloudResPromise).then(function() {
        // 更新列表
        $scope.queryOpt = {
          queryId: $scope.queryText
        };

        var items = null;
        if (_.isFunction(resourceDef.queryResById)) {
          items = resourceDef.queryResById($scope.queryOpt, $scope.viewType);
        } else {
          items = resourceDef.res.queryByCloudTestOrPreRedId($scope.queryOpt);
        }

        var promise = _.get(items, '$promise', items);
        promise.then(function(item) {
          if (item && item.id) {
            $scope.items = [item];
          }
        });

        $scope.loading = items.$promise;
        return $scope.loading;
      });
    };

    $scope.groupByParentName = function(node) {
      return node.parent ? node.parent.name : '';
    };

    $scope.doSearch = function() {
      $scope.selected.res = null;
      $scope.queryString = $scope.queryText;
      $scope.queryItemsByResId();
    };

    $scope.onAllToggled = function() {
      $scope.allSelected = !$scope.allSelected;
      _.each($scope.items, function(item) {
        item.selected = $scope.allSelected;
      });
    };

    $scope.onItemToggled = function() {
      $scope.allSelected = false;
    };

    $scope.previewRes = function(res) {
      var dlgs = $('.shouldHideOnPreview2');
      dlgs.css('display', 'none');
      resourceDef.doPreview(res).then((ret) => {
        if (_.get(ret, 'action') === 'showResBox') {
          $timeout(() => $scope.previewResBox($scope.getResBoxItems()));
        }
      }).finally(function() {
        dlgs.css('display', 'block');
      });
    };

    function importResources(res, keepOpen) {
      if (_.isEmpty(res)) {
        //
        // 没有选择资源
      } else {
        var deferred = $q.defer();
        $scope.loading = deferred.promise;
        deferred.promise.then(function() {
          if (!keepOpen) {
            $uibModalInstance.close('close');
          }
        }, null, function(update) {
          $scope.progressMsg = update;
        }).finally(function() {
          $scope.progressMsg = '';
        });

        resourceDef.doImport(res, deferred, $scope.viewType);
      }
    }

    $scope.importSelected = function() {
      var res = null;
      if (resourceDef.canImportMultiple) {
        res = _.filter($scope.items, function(item) {
          return item.selected;
        });
      } else {
        if ($scope.selected.res) {
          res = [$scope.selected.res];
        }
      }

      if (_.isEmpty(res) && !_.isEmpty($scope.items)) {
        res = [$scope.items[0]];
      }

      importResources(res);
    };

    $scope.downloadSelected = function() {
      console.log('here..');
      var res = null;
      if (resourceDef.canImportMultiple) {
        res = _.filter($scope.items, function(item) {
          return item.selected;
        });
      } else {
        if ($scope.selected.res) {
          res = [$scope.selected.res];
        }
      }

      if (_.isEmpty(res)) {
        //
        // 没有选择资源
      } else {
        var deferred = $q.defer();
        $scope.loading = deferred.promise;
        deferred.promise.then(function() {}, null, function(update) {
          $scope.progressMsg = update;
        }).finally(function() {
          $scope.progressMsg = '';
        });

        resourceDef.doDownload(res, deferred);
      }
    };

    var fileTypes = {
      audio: {
        iconClass: 'oed-file-type-new fa fa-file-audio-o',
        tooltip: '音频'
      },
      file: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '文件'
      },
      image: {
        iconClass: 'oed-file-type-new fa fa-file-photo-o',
        tooltip: '图片'
      },
      link: {
        iconClass: 'oed-file-type-new fa fa-link',
        tooltip: '链接'
      },
      office: {
        iconClass: 'oed-file-type-new fa fa-file-word-o',
        tooltip: '文档'
      },
      video: {
        iconClass: 'oed-file-type-new fa fa-file-video-o',
        tooltip: '视频'
      },
      unknown: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '其他文档'
      }
    };

    $scope.getFileTypeIconClass = function(type) {
      if (!fileTypes[type]) return null;
      return fileTypes[type].iconClass;
    };

    $scope.getFileTypeTooltip = function(type) {
      if (!fileTypes[type]) return '';
      return fileTypes[type].tooltip;
    };

    $scope.questionTypes = {
      'yesorno': '判断',
      'singlechoice': '单选',
      'multichoice': '多选',
      'connect': '匹配'
    };

    $scope.getRelatedMaterialVersion = function(res) {
      return res.materialVersion.versionName + ' - ' + res.materialVersion.name;
    };

    $scope.getRelatedSections = function(res) {
      if (!res.sections || _.isEmpty(res.sections)) {
        return '无';
      }
      return _.map(res.sections, function(section) {
        return section.name;
      }).join(',');
    };

    $scope.close = function(shouldDismissParent) {
      resourceDef.shouldDismissParent = shouldDismissParent;
      $uibModalInstance.close({});
    };

    $scope.toggleSelectQuestion = function(res) {
      res.selected = !res.selected;
    };

    $scope.onImportQuestionClicked = function(res, evt) {
      evt.stopPropagation();
      evt.preventDefault();

      importResources([res], true);
    };

    $scope.queryText = _.get(queryData, 'query', '');
    var item = _.get(queryData, 'res', null);
    if (item) {
      $scope.items = [item];
    }

    $scope.onGoToTest = function(ct) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/cloudTestPreviewer.html'),
        controller: 'cloudTestPreviewerCtrl',
        size: 'lg',
        resolve: {
          cloudTest: function() {
            return ct;
          },
          resourceDef: function() {
            return $scope.resourceDef;
          }
        }
      });

      return modalInstance.result;
    };

    $scope.toggleSelect = function(item, event) {
      event.preventDefault();
      event.stopPropagation();
      item.selected = !item.selected;
    };

    $scope.canImportItem = function(item) {
      return true;
    };

    $scope.importSubjectTest = function(test) {
      return importResources([test], false);
    };

    $scope.addCloudTest = function(test, event) {
      if ($scope.isAllAdded()) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: test,
        resType: 'cloudTest',
        event: event
      };
    };

    $scope.getTitle = function() {
      return '导入' + _.get(resourceDef, 'name');
    };

    $scope.getResBoxItems = function() {
      if (_.isFunction(resourceDef.getResItems)) {
        return resourceDef.getResItems();
      }
      return null;
    };

    $scope.addAllToResBox = function(event) {
      if ($scope.isAllAdded()) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: queryData.res,
        resType: resType,
        event: event
      };
    };

    $scope.addCloudQuestion = function(q, event) {
      if ($scope.isQuestionAddedToResBox(q)) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: q,
        resType: 'cloudQuestion',
        event: event
      };
    };

    $scope.onAddResBoxItem = function(res, resType) {
      resourceDef.addItemToResBox(res, resType);
      updateCached();
    };

    $scope.$watch('getResBoxItems()', function() {
      updateCached();
    });

    var hashesInResBox = {};
    var questionHashes = {};
    //
    // 缓存一些中间结果, 计算hash相对耗费时间
    function updateCached() {
      var existingHashes = _.map($scope.getResBoxItems(), oedTestUtils.computeQuestionHash);
      hashesInResBox = _.keyBy(existingHashes);
      var hashes = resType === 'cloudTest' ? _(queryData.res).get('test.questions', []).map(function(q) {
        return {
          id: q.id,
          hash: oedTestUtils.computeQuestionHash(q)
        };
      }) : [{
        id: _.get(queryData, 'res.question.id', 0),
        hash: oedTestUtils.computeQuestionHash(_.get(queryData, 'res.question'))
      }];
      questionHashes = _.keyBy(hashes, 'id');
    }

    $scope.isAllAdded = function() {
      if (!_.isFunction(resourceDef.getResItems)) {
        return false;
      }

      var hash = _.find(questionHashes, function(h) {
        return !_.has(hashesInResBox, h.hash);
      });
      return !hash;
    };

    $scope.isTestAddedToResBox = $scope.isAllAdded;

    $scope.isQuestionAddedToResBox = function(cq) {
      var q = cq.question;
      var hash = _.get(questionHashes, q.id, '');
      return _.has(hashesInResBox, hash.hash);
    };

    $scope.previewResBox = function(items) {
      if (_.isEmpty(items)) {
        notificationService.notify('info', messages['importResource/noResourceAdded'])
        return;
      }

      $uibModalInstance.close({
        action: 'showResBox'
      });
    };

    $scope.canAddToResBox = (res, resType) => {
      if (!_.isFunction(_.get(resourceDef, 'canAddToResBox'))) {
        return false;
      }

      return resourceDef.canAddToResBox(res, resType);
    };

    $scope.addCloudPresentRes = (res, event) => {
      if (!$scope.canAddToResBox(res, 'cloudPresentRes')) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: res,
        resType: 'cloudPresentRes',
        event: event
      };
    };

    $scope.addCloudPresentResItem = (res, event) => {
      if (!$scope.canAddToResBox(res, 'cloudPresentResItem')) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: res,
        resType: 'cloudPresentResItem',
        event: event
      };
    };
  }
})();
