(function() {
  'use strict';

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.reviewStudentTaskDetails.res.presentResource', {
        url: '/presentResource/:resId',
        template: require('assets/templates/homework/reviewStudentTaskDetailsPresentResource.html'),
        controller: 'reviewStudentTaskDetailsPresentResourceCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('reviewStudentTaskDetailsPresentResourceCtrl', reviewStudentTaskDetailsPresentResourceCtrl);

  reviewStudentTaskDetailsPresentResourceCtrl.$inject = ['$scope', '$state', '$stateParams',
    '$q', 'dialogs', 'Lightbox', 'userInfo', 'oedConfig', 'oedCoursePath', 'oedTaskList',
    'oedTask', 'oedTaskListPublishRecord', 'oedPresentResource', 'oedOem']
  function reviewStudentTaskDetailsPresentResourceCtrl($scope, $state, $stateParams,
    $q, dialogs, Lightbox, userInfo, oedConfig, oedCoursePath, oedTaskList,
    oedTask, oedTaskListPublishRecord, oedPresentResource, oedOem) {

    var recordId = _.parseInt($stateParams.recordId);
    var taskId = _.parseInt($stateParams.taskId);
    var resId = _.parseInt($stateParams.resId);

    $scope.enableManage = oedOem.curOem() != 'futureSchool';

    $scope.studentId = _.parseInt($stateParams.studentId);
    $scope.resId = resId;
    $scope.taskId = taskId;

    $scope.resource = oedPresentResource.get({
      id: resId
    });

    $scope.resource.$promise.then(function(res) {
      $scope.currentState.subTitle = res.name + '(' + res.items.length + ')';
    });

    $scope.basePath = oedConfig.base();

    $scope.loading = $scope.resource.$promise.then(function() {
      $scope.loaded = true;
    });

    $scope.showPreview = function(reses, idx) {
      Lightbox.openModal(reses, idx);
    };
    $scope.getItemName = function(item) {
      if (_.get(item, 'resourceName'))
        return _.get(item, 'resourceName');

      if (_.get(item, 'flResource.name'))
        return _.get(item, 'flResource.name');
      return '';
    };

  }
})();
