require('app2/homework/homeworkLibraryPreviewer.ctrl');
require('app2/homework/homeworkUpdatePreviewer.ctrl');
require('app2/directives/review/remoteVoting/votingCompletionPreviewer.directive');
require('app2/directives/review/remoteVoting/votingTestStatTable.directive');
require('app2/directives/review/remoteVoting/votingChartSummary.directive');
require('app2/directives/review/remoteVoting/votingCompletionDetails.directive');
require('app2/directives/utils/reviewTab.directive');
require('app2/directives/board/boardQuestionPreviewer.directive');
require('app2/directives/review/preres/preresCompletionPreviewer.directive');
require('app2/directives/review/preres/preresCompletionDetails.directive');
require('app2/directives/review/preres/preResItemsPreviewer.directive');
require('app2/directives/review/preres/preResItemPreviewer.directive');
require('app2/resource/preResReviewStudentDetails.ctrl');
require('assets/styles/oedTable.scss');
require('app2/resource/preResItemSummary.ctrl');
require('app2/directives/resource/preResItemPlayer.directive');
require('app2/directives/resource/downResource.directive');
require('app2/directives/review/preres/preResItemDetails.directive');
require('app2/directives/question/stdAnswerResult.directive');
require('app2/review/objOverAllStatTable.ctrl');
require('app2/review/qaAnswerPoint.ctrl');
require('app2/directives/review/sbjService/sbjSessionService.ts');
require('app2/directives/review/objService/objSessionService.ts');

(function() {
  angular.module('app.homework', [
    'ui.router', 'ngCookies', 'app.resources',
    'app.homework.designTask',
    'app.service.obSessionService',
    'app.service.sbjSessionService',
    'app.homework.homeworkLibraryPreviewerCtrl',
    'app.homework.homeworkUpdatePreviewerCtrl',
    'app.directives.review.objective.votingCompletionPreviewer',
    'app.directives.review.objective.votingCompletionDetails',
    'app.directives.review.objective.votingTestStatTable',
    'app.directives.review.objective.votingChartSummary',
    'app.directives.utils.reviewTab',
    'app.directives.board.boardQuestionPreviewer',
    'app.directives.review.preres.preresCompletionPreviewer',
    'app.directives.review.preres.preresCompletionDetails',
    'app.directives.review.preres.preResItemsPreviewer',
    'app.directives.review.preres.preResItemPreviewer',
    'app.resource.preResReviewStudentDetails',
    'app.resource.preResItemSummary',
    'app.directives.resource.preResItemPlayer',
    'app.directives.resource.downResource',
    'app.directives.question.stdAnswerResult',
    'app.review.objOverAllStatTable',
    'app.review.qaAnswerPoint',
    'app.directives.review.preres.preResItemDetails'])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework', {
        abstract: true,
        url: '/homework',
        template: '<ui-view/>',
        loadingCls: 'blueloading',
        bodyStyle: 'bged2'
      });
    }]);
})();
