import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-ex-choice-item-dialog',
  template: require('./ex-choice-item-dialog.component.html'),
  styles: [require('./ex-choice-item-dialog.component.scss')]
})
export class ExChoiceItemDialogComponent implements OnInit {
  @Input() private title: string
  @Input() private treeData: any[]
  @Input() private maxLevel: number
  @Input() private selectItems: any
  @Input() private defOpenMap: any
  @Input() private selectChild: any
  @Input() private selectOne: boolean
  @Input() private maxSelectedCount: number

  @Output() private action = new EventEmitter<any[]>()

  private selectItemMap: any

  constructor(
    private bsModalRef: BsModalRef,
    private notifyService: NotificationService,
  ) { }

  ngOnInit() {
    this.selectItemMap = _.keyBy(this.selectItems, i => _.get(i, 'id', 0))
  }

  private onClose = () => {
    const selectList = this.buildSelectList()
    this.action.emit(selectList)
    this.bsModalRef.hide()
  }

  private buildSelectList = () => {
    const selects = []
    for (const key in this.selectItemMap) {
      const values = this.selectItemMap[key]
      selects.push(values)
    }
    return selects
  }

  private onSelect = (item: any) => {
    if (this.selectOne) {
      const itemId = _.get(item, 'id', 0)
      if (itemId === _.get(this.selectItemMap, `${itemId}.id`)) return
      this.selectItemMap = {}
      this.selectItemMap[_.get(item, 'id', 0)] = item
    } else {
      const alreadySelect = this.selectItemMap
      this.selectItemMap = { ...alreadySelect }
      const selected = !_.isEmpty(this.selectItemMap[_.get(item, 'id', 0)])
      if (selected) {
        delete this.selectItemMap[_.get(item, 'id', 0)]
        this.eachChilds(_.get(item, 'children', []), true)
        return
      }
      this.selectItemMap[_.get(item, 'id', 0)] = item
      if (this.selectChild) {
        this.eachChilds(_.get(item, 'children', []), false)
      }
    }
  }

  private eachChilds = (children: any[], isdelete: boolean) => {
    if (_.size(children) <= 0) return
    _.forEach(children, child => {
      if (isdelete) {
        delete this.selectItemMap[_.get(child, 'id', 0)]
      } else {
        this.selectItemMap[_.get(child, 'id', 0)] = child
      }
      const children = _.get(child, 'children', [])
      this.eachChilds(children, isdelete)
    })
  }
}
