import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Input, Output,EventEmitter,} from '@angular/core';

@Component({
  selector: 'app-ex-info-dialog',
  template: require('./ex-info-dialog.component.html'),
  styles: [require('./ex-info-dialog.component.scss')]
})
export class ExInfoDialogComponent implements OnInit {
  @Input() private title: string;
  @Input() private info: string;
  @Input() private btnText: string;
  @Input() private isConfirm: boolean;
  @Output() private action = new EventEmitter<number>()

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit() { 
    if (!this.title) this.title = '标题'
    if (!this.btnText) this.btnText = '确认'
  }

  private onBtn = () => this.bsModalRef.hide()

  private onIgnore = () => {
    this.bsModalRef.hide()
    this.action.emit(0)
  }

  private onSave = () => {
    this.bsModalRef.hide()
    this.action.emit(1)
  }
}
