import { Component, OnInit } from '@angular/core';
import { LegacyAppService } from 'app/core/legacy-app.service';

@Component({
  selector: 'app-tutorial',
  template: require('./tutorial.component.html') as string,
  styles: [require('./tutorial.component.scss')]
})
export class TutorialComponent implements OnInit {
  videos: Array<any>
  curCate: string
  category = [{name: '所有教程', webUrl: '/#/anno/tutorial', class: 'cur'},
    //{name: '客观试卷', webUrl: '/xxx/1', class:''},
    //{name: '主观试卷', webUrl: '/xxx/2', class:''},
    //{name: '课堂小功能', webUrl: '/xxx/4', class:''},
    //{name: '作业发布和批阅', webUrl: '/xxx/5', class:''},
    //{name: '查询和管理功能', webUrl: '/xxx/6', class:''},
    //{name: '设备和其他功能', webUrl: '/xxx/7', class:''},
    //{name: '辅助软件', webUrl: '/xxx/8', class:''},
  ];
  lightBox: any = null
  constructor(
    legacyApp: LegacyAppService
  ) {
    this.lightBox = legacyApp.getLightBox();
  }

  ngOnInit() {
    const curCate = this.category[0].name;
    for (let i = 0; i < this.category.length; i++) {
      if (this.category[i].class == 'cur') {
        this.curCate = this.category[i].name;
      }
    }
    this.videos = [{
      fileType: 'video',
      name: '【微教程】教师注册与管理',
      suffix: 'mp4',
      imageSrcHttpPath: '/ray/resource/view/img_src/1891598',
      user: '锐学堂',
      date: '20170117'
    },
    {
        fileType: 'video',
        name: '【微教程】如何备课？',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1847300',
        user: '锐学堂',
        date: '20161106'
      },
    {
        fileType: 'video',
        name: '【微教程】如何进行主观活动？',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1873001',
        user: '锐学堂',
        date: '20161107'
      },
    {
        fileType: 'video',
        name: '【微视频】主观活动插入白板',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/2092444',
        user: '锐学堂',
        date: '20170929'
      },
    {
        fileType: 'video',
        name: '【微视频】主观活动音视频、附件答题',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/2092442',
        user: '锐学堂',
        date: '20170929'
      },
    {
        fileType: 'video',
        name: '【微视频】主观活动分组',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/2092443',
        user: '锐学堂',
        date: '20170929'
      },
    {
        fileType: 'video',
        name: '【微教程】如何使用填空题？',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1825718',
        user: '锐学堂',
        date: '20161107'
      },
    {
        fileType: 'video',
        name: '【微教程】填空题三种评判方式',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1890295',
        user: '锐学堂',
        date: '20170111'
      },
    {
        fileType: 'video',
        name: '【微教程】如何进行环节推送？',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1890319',
        user: '锐学堂',
        date: '20170111'
      },
    {
        fileType: 'video',
        name: '【微教程】如何进行投屏控制？',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1827588',
        user: '锐学堂',
        date: '20161107'
      },
    {
        fileType: 'video',
        name: '【微教程】作业编辑与发布',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1891599',
        user: '锐学堂',
        date: '20170117'
      },
    {
        fileType: 'video',
        name: '【微教程】批阅作业（PAD端）',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1891602',
        user: '锐学堂',
        date: '20170117'
      },
    {
        fileType: 'video',
        name: '【微教程】小锐作业安装与答题',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1891600',
        user: '锐学堂',
        date: '20170117'
      },
    {
        fileType: 'video',
        name: '【微教程】如何使用提问区？',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1828816',
        user: '锐学堂',
        date: '20161107'
      },
    {
        fileType: 'video',
        name: '【微教程】如何PPT批量转成图片？',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1884712',
        user: '锐学堂',
        date: '20161221'
      },
    {
        fileType: 'video',
        name: '【微教程】怎样将FLASH及PPT转为视频？',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1873005',
        user: '锐学堂',
        date: '20161107'
      },
    {
        fileType: 'video',
        name: '【微视频】投票题的使用',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1940711',
        user: '锐学堂',
        date: '20170414'
      },
    {
        fileType: 'video',
        name: '【微视频】综合题的使用',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1940712',
        user: '锐学堂',
        date: '20170414'
      },
    {
        fileType: 'video',
        name: '【微教程】问答题的使用',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1940713',
        user: '锐学堂',
        date: '20170414'
      },
    {
        fileType: 'video',
        name: '【微视频】客观试卷',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1964658',
        user: '锐学堂',
        date: '20170613'
      },
    {
        fileType: 'video',
        name: '【微视频】客观试卷-分层推送',
        suffix: 'mp4',
        imageSrcHttpPath: '/ray/resource/view/img_src/1964659',
        user: '锐学堂',
        date: '20170613'
      }
    ];
  }
  showPreview = function(reses, idx, event) {
    event.preventDefault();
    event.stopPropagation();
    this.lightBox.openModal(reses, idx, {
      windowClass: 'lightbox-modal oed-toturial-video'
    });
  }
}
