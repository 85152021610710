import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';
import {TrainMassageService} from 'app/online-train/train-massage.service';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import { StateService } from '@uirouter/core'
import { LegacyAppService } from 'app/core/legacy-app.service'

@Component({
  selector: 'app-train-resource',
  template: require('./train-resource.component.html'),
  styles: [require('./train-resource.component.scss')]
})
export class TrainResourceComponent implements OnInit {

  @Input() public parentId
  @Input() public contents
  @Input() public itemType
  @Input() public editable
  @Output() public onEdit = new EventEmitter<any>()

  constructor(private onlineTrainService: OnlineTrainService,
              private state: StateService,
              private trainMessage: TrainMassageService,
              private legacyApp: LegacyAppService) { }
  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'contents')) {
      this.contents = changes.contents.currentValue
    }

    if (_.has(changes, 'itemType')) {
      this.itemType = changes.itemType.currentValue
    }

    if (_.has(changes, 'parentId')) {
      this.parentId = changes.parentId.currentValue
    }

    if (_.has(changes, 'editable')) {
      this.editable = changes.editable.currentValue
    }

    if (!_.isEmpty(this.contents)) {
      if (this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO) {
        this.contents = _.slice(this.contents, 0, 1)
      } else if (this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_OTHER) {
        _.forEach(this.contents, (c: any) => c.name = this.contents[0].name)
      }
    }
  }

  public getName() {
    let name = ''
    if (!_.isEmpty(this.contents)) {
      name = this.contents[0].name
    }
    return name
  }
  public videoType(content) {
    return content && this.onlineTrainService.videoType(content.$$resource)
  }

  public textType(content) {
    return content && this.onlineTrainService.textType(content.$$resource)
  }

  public imgType(content) {
    return content && this.onlineTrainService.imgType(content.$$resource)
  }

  public getDataToAdd() {
    if (this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO) {
      return {
        name: '',
        info: '',
        resourceId: -1,
        goodCourseId: this.parentId,
        sort: 1,
        deleted: false,
      }
    } else if (this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_OTHER) {
      let name = ''
      if (!_.isEmpty(this.contents)) {
        name = this.contents[0].name
      }
      return {
        name,
        info: '',
        resourceId: -1,
        goodCourseId: this.parentId,
        sort: this.nextSort(),
        deleted: false,
      }
    } else if (this.itemType === this.onlineTrainService.CONTENT2) {
        const data = {
          dirId: this.parentId,
          resourceId: -1,
          name: '',
          sort: this.nextSort(),
          deleted: false
        }

        if (!_.isEmpty(this.contents)) {
          data.name = this.contents[0].name
        }
        return data
    } else if (this.itemType === this.onlineTrainService.CONTENT1) {
      return {
        dirId: this.parentId,
        resourceId: -1,
        name: '',
        sort: 1,
        deleted: false
      }
    }
  }

  public onEditResource(params) {
    this.onEdit.emit(params)
  }

  public getContentsVideo() {
    return _.filter(this.contents, (content) => content['sort'] === 1)
  }

  public getContentsOther() {
    return _.filter(this.contents, (content) => content['sort'] > 1)
  }

  public showInfo() {
    return this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO && this.contents[0].info
  }

  public showAddEdit() {
    if (this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO
        || this.itemType === this.onlineTrainService.CONTENT1) {
      return this.editable && _.isEmpty(this.contents)
    } else {
      return this.editable
    }
  }

  public showUp(idx) {
    return idx > 0
  }

  public showDown(idx) {
    return idx < _.size(this.contents) - 1
  }

  private nextSort() {
    const contentsOther = this.getContentsOther()
    if (_.isEmpty(contentsOther)) {
      return 2
    } else {
      return _.maxBy(contentsOther, (c) => c['sort'])['sort'] + 1
    }
  }

  private getUrl(baseUrl: string, type: string) {
    if (_.startsWith(baseUrl, 'http://') || _.startsWith(baseUrl, 'https://') || _.startsWith(baseUrl, 'data:image')) {
      return baseUrl
    }
    const oem = this.legacyApp.getOem().curOem()
    if (oem === 'futureSchool') {
      return baseUrl
    }

    if (process.env.NAME === 'beta') {
      return baseUrl
    }

    if (type === 'video') {
      return `http://s-v.rayclass.com/${baseUrl}`
    } else if (type === 'img') {
      return `http://s-pic.rayclass.com/${baseUrl}`
    }
  }
}
