import { ElementRef, Injectable } from '@angular/core';
import { Array, Map } from 'core-js'

import * as dragula from 'dragula'
import * as _ from 'lodash'

export interface ModuleDropEventHandler {
  container(): any;
  accepts(el, source, handle, sibling): boolean;
  onDrop(el, target, source, sibling): boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ModuleItemDragService {

  public registeredContainers = new Map<ModuleDropEventHandler, any>()
  private drake

  constructor() {
  }

  public ensureDrag() {
    if (this.drake) {
      return
    }
    this.drake = dragula({
      isContainer(el) {
        return false
      },
      moves(el, source, handle, sibling) {
        return true
      },
      accepts: (el, target, source, sibling) => {
        const handlers = Array.from(this.registeredContainers.keys())
        for (const h of handlers) {
          if (target === h.container() || ModuleItemDragService.isDescendant(h.container(), target)) {
            return h.accepts(el, target, source, sibling)
          }
        }
        return false
      },
      invalid(el, handle) {
        return false
      },
      direction: 'vertical',             // Y axis is considered when determining where an element would be dropped
      copy: (el, source) => {
        return _.chain(source.className).split(' ').includes('copy_drag').value()
      },                       // elements are moved by default, not copied
      // tslint:disable-next-line:max-line-length
      revertOnSpill: false,              // spilling will put the element back where it was dragged from, if this is true
      removeOnSpill: false,              // spilling will `.remove` the element, if this is true
      mirrorContainer: document.body,    // set the element that gets mirror elements appended
      ignoreInputTextSelection: true     // allows users to select input text, see details below
    })

    this.drake.on('drop', (...args) => {
      const handlers = Array.from(this.registeredContainers.keys())
      for (const h of handlers) {
        if (h.onDrop(...args)) {
          return
        }
      }
    })
  }

  public addContainer(container: ElementRef) {
    this.ensureDrag()
    this.drake.containers.push(container.nativeElement)
  }

  public updateContainers() {
    this.drake.containers = Array.from(this.registeredContainers.values())
  }

  public register(handler: ModuleDropEventHandler) {
    this.ensureDrag()
    const el = handler.container()
    this.registeredContainers.set(handler, el)
    this.updateContainers()
  }

  public unregister(handler: ModuleDropEventHandler) {
    this.ensureDrag()
    this.registeredContainers.delete(handler)
    this.updateContainers()
  }

  static isDescendant(parent, child) {
    let node = child.parentNode;
    while (node != null) {
      if (node == parent) {
        return true;
      }
      node = node.parentNode
    }
    return false;
  }
}
