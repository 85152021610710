(function() {
  'use strict';

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.taskListPubDetails', {
        url: '/origTaskList/:origTaskListId/taskList/:taskListId/progress/:publishRecordId',
        template: require('assets/templates/homework/taskListPubDetails.html'),
        controller: 'taskListPubDetailsCtrl',
        bodyStyle: 'bged'
      });
    }])
    .controller('taskListPubDetailsCtrl', taskListPubDetailsCtrl);

  taskListPubDetailsCtrl.$inject = ['$scope', '$state', '$stateParams', '$q',
    '$uibModal', 'userInfo', 'oedTaskList', 'oedCourse', 'dialogs', 'oedTaskListPublishRecord']
  function taskListPubDetailsCtrl($scope, $state, $stateParams, $q,
    $uibModal, userInfo, oedTaskList,
    oedCourse, dialogs, oedTaskListPublishRecord) {

    var origTaskListId = _.parseInt($stateParams.origTaskListId);
    var publishRecordId = _.parseInt($stateParams.publishRecordId);

    $scope.origTaskListId = origTaskListId;

    $scope.loadTaskListPublishDetails = function() {
      var getOrigTaskListPublishInfoQuery = oedTaskListPublishRecord.queryPublishInfo({
        id: publishRecordId
      });

      return getOrigTaskListPublishInfoQuery.$promise.then(function(publishInfo) {
        $scope.publishInfo = publishInfo;
        $scope.publishRecord = {
          id: publishRecordId
        };

        // for every publish record, query detailed publish info of every students
        var queryStudentsPubInfoOfAllPublishRecords = loadStudentsPubInfoOfPublishRecord($scope.publishRecord);

        return $q.all(queryStudentsPubInfoOfAllPublishRecords);
      });
    };

    function loadStudentsPubInfoOfPublishRecord(publishRecord) {
      var queryStudentsInfo = oedTaskListPublishRecord.queryStudentsInfo({
        id: publishRecord.id
      });

      return queryStudentsInfo.$promise.then(function(students) {
        publishRecord.students = students;

        _.forEach(students, function(s) {
          s.publishRecord = publishRecord;
        });

        var queryStudentsReviewed = oedTaskListPublishRecord.queryStudentsIsReviewed({
          id: publishRecord.id
        }).$promise.then(function(reviewInfo) {
          var map = _.keyBy(reviewInfo, 'uid');

          _.forEach(students, function(s) {
            if (s.uid in map) {
              s.isReviewed = map[s.uid].isReviewed;
            }
          });
        });

        var queryStudentsSubmitTaskCount = oedTaskListPublishRecord.queryStudentsSubmitTaskCount({
          id: publishRecord.id
        }).$promise.then(function(submitTaskInfo) {
          var map = _.keyBy(submitTaskInfo, 'uid');

          _.forEach(students, function(s) {
            if (s.uid in map) {
              var matched = map[s.uid];
              s.totalTaskCount = matched.totalTaskCount;
              s.submitTaskCount = matched.submitTaskCount;
              s.submitProgress = 0;
              if (s.totalTaskCount > 0) {
                s.submitProgress = (s.submitTaskCount * 100 / s.totalTaskCount).toFixed(0);
              }
            }
          });
        });
        return $q.all([queryStudentsReviewed, queryStudentsSubmitTaskCount]).then(function() {
          var part = _.partition(students, function(s) {
            return s.submitProgress && s.submitProgress == '100';
          });
          $scope.studentsOfFinish = part[0];
          $scope.studentsOfUnfinish = part[1];
        });
      });
    }

    $scope.getStdAvatarImgSrc = function(std) {
      if ('female' == std.gender) {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_f1.png';
        } else {
          return 'Assets/images2/stu_avatar_f2.png';
        }
      } else {
        if (std.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_m1.png';
        } else {
          return 'Assets/images2/stu_avatar_m2.png';
        }
      }
    };

    $scope.loading = $scope.loadTaskListPublishDetails();
  }
})();
