export const ruanyunLevels = [
    {
        id: 1,
        name: '七年级',
        gradeId: 1,
    },
    {
        id: 2,
        name: '八年级',
        gradeId: 1,
    },
    {
        id: 3,
        name: '九年级',
        gradeId: 1,
    },
    {
        id: 4,
        name: '高一',
        gradeId: 2,
    },
    {
        id: 5,
        name: '高二',
        gradeId: 2,
    },
    {
        id: 6,
        name: '高三',
        gradeId: 2,
    },
    {
        id: 7,
        name: '一年级',
        gradeId: 3,
    },
    {
        id: 8,
        name: '二年级',
        gradeId: 3,
    },
    {
        id: 9,
        name: '三年级',
        gradeId: 3,
    },
    {
        id: 10,
        name: '四年级',
        gradeId: 3,
    },
    {
        id: 11,
        name: '五年级',
        gradeId: 3,
    },
    {
        id: 12,
        name: '六年级',
        gradeId: 3,
    }
]

export const ruanyunGrades = [
    {
        id: 1,
        name: '初中'
    },
    {
        id: 2,
        name: '高中'
    },
    {
        id: 3,
        name: '小学'
    }
]

export const ruanyunCourses = [
    {
        id: 1,
        gradeName: '初中',
        subjectName: '语文',
        gradeId: 1,
    },
    {
        id: 2,
        gradeName: '初中',
        subjectName: '数学',
        gradeId: 1,
    },
    {
        id: 3,
        gradeName: '初中',
        subjectName: '英语',
        gradeId: 1,
    },
    {
        id: 4,
        gradeName: '初中',
        subjectName: '物理',
        gradeId: 1,
    },
    {
        id: 5,
        gradeName: '初中',
        subjectName: '化学',
        gradeId: 1,
    },
    {
        id: 6,
        gradeName: '初中',
        subjectName: '生物',
        gradeId: 1,
    },
    {
        id: 7,
        gradeName: '初中',
        subjectName: '历史',
        gradeId: 1,
    },
    {
        id: 8,
        gradeName: '初中',
        subjectName: '地理',
        gradeId: 1,
    },
    {
        id: 9,
        gradeName: '高中',
        subjectName: '语文',
        gradeId: 2,
    },
    {
        id: 10,
        gradeName: '高中',
        subjectName: '数学',
        gradeId: 2,
    },
    {
        id: 11,
        gradeName: '高中',
        subjectName: '英语',
        gradeId: 2,
    },
    {
        id: 12,
        gradeName: '高中',
        subjectName: '物理',
        gradeId: 2,
    },
    {
        id: 13,
        gradeName: '高中',
        subjectName: '化学',
        gradeId: 2,
    },
    {
        id: 14,
        gradeName: '高中',
        subjectName: '生物',
        gradeId: 2,
    },
    {
        id: 15,
        gradeName: '高中',
        subjectName: '历史',
        gradeId: 2,
    },
    {
        id: 16,
        gradeName: '高中',
        subjectName: '地理',
        gradeId: 2,
    },
    {
        id: 17,
        gradeName: '高中',
        subjectName: '政治',
        gradeId: 2,
    },
    {
        id: 19,
        gradeName: '初中',
        subjectName: '政治',
        gradeId: 1,
    },
    {
        id: 20,
        gradeName: '小学',
        subjectName: '奥数',
        gradeId: 3,
    },
    {
        id: 21,
        gradeName: '小学',
        subjectName: '语文',
        gradeId: 3,
    },
    {
        id: 22,
        gradeName: '小学',
        subjectName: '数学',
        gradeId: 3,
    },
    {
        id: 23,
        gradeName: '小学',
        subjectName: '英语',
        gradeId: 3,
    }
]

export const ruanyunDifficult = [
    {
        type: 0,
        typeName: '全部',
        sort: 0,
    },
    {
        type: 1,
        typeName: '容易',
        sort: 1,
    },
    {
        type: 2,
        typeName: '较易',
        sort: 2,
    },
    {
        type: 3,
        typeName: '一般',
        sort: 3,
    },
    {
        type: 4,
        typeName: '较难',
        sort: 4,
    },
    {
        type: 5,
        typeName: '困难',
        sort: 5,
    },
]

export const ruanyunAbilityStructures = [
  {
    courseId: 1,
    courseName: '初中语文',
    labelId: 5269,
    ability: '识记',
    commnet: '识别和记忆',
  },
  {
    courseId: 1,
    courseName: '初中语文',
    labelId: 5270,
    ability: '理解',
    commnet: '领会并能作简单的解释',
  },
  {
    courseId: 1,
    courseName: '初中语文',
    labelId: 5271,
    ability: '分析综合',
    commnet: '分析解剖和归纳整理',
  },
  {
    courseId: 1,
    courseName: '初中语文',
    labelId: 5272,
    ability: '表达应用',
    commnet: '指对语文知识和能力的运用',
  },
  {
    courseId: 1,
    courseName: '初中语文',
    labelId: 5273,
    ability: '鉴赏评价',
    commnet: '指对阅读材料初步的鉴别、赏析和评说',
  },
  {
    courseId: 1,
    courseName: '初中语文',
    labelId: 5274,
    ability: '探究',
    commnet: '指对某些问题进行探讨，有见解、有发现、有创新',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5084,
    ability: '运算能力',
    commnet: '运算能力主要是指能够根据法则和运算律正确地进行运算的能力。培养运算能力有助于学生理解运算的算理，寻求合理简洁的运算途径解决问题。',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5085,
    ability: '推理能力',
    commnet: '推理能力的发展应贯穿在整个数学学习过程中。推理是数学的基本思维方式，也是人们学习和生活中经常使用的思维方式。推理一般包括合情推理和演绎推理,合情推理是从已有的事实出发，凭借经验和直觉，通过归纳和类比等推断某些结果；演绎推理是从已有的事实（包括定义、公理、定理等）和确定的规则（包括运算的定义、法则、顺序等）出发，按照逻辑推理的法则证明和计算。在解决问题的过程中，合情推理用于探索思路，发现结论；演绎推理用于证明结论。',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5086,
    ability: '数据分析观念',
    commnet: '数据分析观念包括：了解在现实生活中有许多问题应当先做调查研究，收集数据，通过分析做出判断，体会数据中蕴涵着信息；了解对于同样的数据可以有多种分析的方法，需要根据问题的背景选择合适的方法；通过数据分析体验随机性，一方面对于同样的事情每次收集到的数据可能不同，另一方面只要有足够的数据就可能从中发现规律。',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5087,
    ability: '空间观念',
    commnet: '空间观念主要是指根据物体特征抽象出几何图形，根据几何图形想象出所描述的实际物体；想象出物体的方位和相互之间的位置关系；描述图形的运动和变化；依据语言的描述画出图形等。',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5088,
    ability: '模型思想',
    commnet: '模型思想的建立是学生体会和理解数学与外部世界联系的基本途径。建立和求解模型的过程包括：从现实生活或具体情境中抽象出数学问题，用数学符号建立方程、不等式、函数等表示数学问题中的数量关系和变化规律，求出结果、并讨论结果的意义。这些内容的学习有助于学生初步形成模型思想，提高学习数学的兴趣和应用意识。',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5089,
    ability: '归纳总结',
    commnet: '能从所学知识中找出重难点，能在大量习题中提炼出解题的基本思想方法，从而举一反三。能透过现象看本质。',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5090,
    ability: '应用意识',
    commnet: '应用意识有两个方面的含义，一方面有意识利用数学的概念、原理和方法解释现实世界中的现象，解决现实世界中问题；另一方面，认识到现实生活中蕴含着大量与数量和图形有关的问题，这些问题可以抽象成数学问题，用数学的方法予以解决。在整个数学教育的过程中都应该培养学生的应用意识，综合实践活动是培养应用意识很好的载体。 ',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5091,
    ability: '数感',
    commnet: '数感主要是指关于数与数量、数量关系、运算结果估计等方面的感悟。建立数感有助于学生理解现实生活中数的意义，理解或表述具体情境中的数量关系。',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5092,
    ability: '符号意识',
    commnet: '符号意识主要是指能够理解并且运用符号表示数、数量关系和变化规律；知道使用符号可以进行运算和推理，得到的结论具有一般性。建立符号意识有助于学生理解符号的使用是数学表达和进行数学思考的重要形式。',
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5093,
    ability: '几何直观',
    commnet: '几何直观主要是指利用图形描述和分析问题。借助几何直观可以把复杂的数学问题变得简明、形象，有助于探索解决问题的思路，预测结果。几何直观可以帮助学生直观地理解数学，在整个数学学习过程中都发挥着重要作用。',
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5229,
    ability: '语言识记能力',
    commnet: '识记常用单词或短语的含义的能力。',
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5230,
    ability: '细节辨析能力',
    commnet: '根据句子或文章中的细节之处来推测答案。',
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5231,
    ability: '信息提取能力',
    commnet: '抓住连接词及起关键作用的副词、代词、介词、插入语等。因为这些词具有因果，让步，递进，转折，指代，列举及承上启下等各种连接上下文的特殊功能。',
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5232,
    ability: '推理分析能力',
    commnet: '读者需统观全文，认真分析，综合推理及至计算，最后归纳出正确答案。',
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5233,
    ability: '情景交际能力',
    commnet: '语境提供交际信息，帮助交际者正确理解交际内容，从而运用语法组词造句并做出恰当的反应，以达到交际的目的。联系上下文情景，甚至是结合一定的文化背景，仔细品味，把握说话者的内在意义。',
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5109,
    ability: '识记能力',
    commnet: '根据对事物的记忆进行判断的能力',
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5111,
    ability: '应用物理知识处理实际问题的能力',
    commnet: '根据所学的物理知识，把生活中的实际问题转成熟悉的物理问题进行判断求解的能力。',
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5112,
    ability: '推理能力',
    commnet: '能够根据已知的知识和物理事实、条件，对物理问题进行逻辑推理和论证，得出正确的结论或做出正确的判断，并能把推理过程正确地表达出来。',
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5113,
    ability: '空间想象能力',
    commnet: '对于部分难以建构物理的模型的问题，需要通过空间形象能力进行理解判断。',
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5114,
    ability: '理解能力',
    commnet: '理解物理概念、物理规律的确切含义，理解物理规律的适用条件以及它们在简单情况下的应用；能够清楚地认识概念和规律的表达形式(包括文字表述和数学表达）；能够鉴别关于概念和规律的似是而非的说法；理解相关知识的区别和联系',
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5115,
    ability: '设计实验方案的能力',
    commnet: '为了验证某个物理结论或规律，通过设计实验过程、采集实验数据并总结归纳得出结论。',
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5116,
    ability: '分析综合能力',
    commnet: '能够独立地对所遇到的问题进行具体分析、研究，弄清其中的物理状态、物理过程和物理情境，找出起重要作用的因素及有关条件；能够把一个复杂问题分解为若干较简单的问题，找出它们之间的联系；能够提出解决问题的方法，运用物理知识综合解决所遇到的问题。',
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5156,
    ability: '对实验进行评估的能力',
    commnet: '能够比较客观地对某个物理实验的合理性，数据的收集以及实验结果的准确性进行评价总结的能力。',
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5157,
    ability: '分析数据，总结规律的能力',
    commnet: '对实验所得的数据进行分析，并能够归纳总结出某种特定的物理规律的能力。',
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5178,
    ability: '接受、吸收、整合化学信息能力',
    commnet: '1、对中学化学基础知识能融会贯通，有正确复述、再现、辨认的能力；2、能够通过对实际事物、实验现象、实物、模型、图像、图表的观察，以及对自然界、社会、生产、生活中的化学现象的观察，获取有关的感性知识和印象，并进行初步加工、吸收、有序储存的能力；3、从试题提供的信息中，准确的提取实质性内容，并与已有知识板块整合，重新组合为新知识板块的能力。',
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5179,
    ability: '解决实际问题能力',
    commnet: '1、以自然和生活中的物理为背景的问题情景，培养学生解释自然和生活现象的能力；2、以技术应用为背景的问题情景，培养学生进行技术设计和应用的能力；3、以科研为背景的问题情景，培养学生进行类科学研究的能力；4、以综合性为背景的问题情景，培养学生进行综合创新的能力。',
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5180,
    ability: '分析问题和解决化学问题能力',
    commnet: '1、将实际问题分解，通过运用相关知识，采用分析、综合的方法，解决简单化学问题的能力；2、将分析解决问题的过程和成果，用正确的化学术语及文字、图表、模型、图形等表达，并作出解释的能力。',
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5181,
    ability: '化学实验与探究能力',
    commnet: '1、了解并初步实践化学实验研究的一般过程，掌握化学实验基本方法和技能；2、在解决简单化学问题的过程中，运用科学简单的方法，初步了解化学变化规律，并对化学现象做出科学合理的解释。',
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5182,
    ability: '逻辑推理能力',
    commnet: '通过所学知识内容，对题干内容进行逻辑推理、分析，从而培养出能够快速得出答案的能力',
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5183,
    ability: '记忆能力',
    commnet: '对教材内容理解深刻，从而达到能迅速记忆起知识的能力',
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5184,
    ability: '计算能力',
    commnet: '通过大量数字计算，并能准确得出答案的能力',
  },
  {
    courseId: 6,
    courseName: '初中生物',
    labelId: 5212,
    ability: '记忆能力',
    commnet: '通过对所学知识进行记忆、快速得出正确答案',
  },
  {
    courseId: 6,
    courseName: '初中生物',
    labelId: 5213,
    ability: '理解能力',
    commnet: '能理解所学知识的要点，把握知识间的内在联系，形成知识的网络结构',
  },
  {
    courseId: 6,
    courseName: '初中生物',
    labelId: 5214,
    ability: '实验与探究能力',
    commnet: '具备验证简单生物学事实的能力，并能对实验现象和结果进行解释、分析和处理',
  },
  {
    courseId: 6,
    courseName: '初中生物',
    labelId: 5215,
    ability: '综合运用能力',
    commnet: '理论联系实际，综合运用所学知识解决自然界和社会生活中的一些生物学问题',
  },
  {
    courseId: 7,
    courseName: '初中历史',
    labelId: 5191,
    ability: '获取与解读',
    commnet: '此类题目主要考查学生阅读题干获取信息，对题干所给信息进行理解和消化，通过题干给出信息进行作答。此类题目难度相对较低，一般都是考查学生对历史基础知识的识记，或是对材料的简单理解',
  },
  {
    courseId: 7,
    courseName: '初中历史',
    labelId: 5192,
    ability: '调动与运用',
    commnet: '调动与运用强调的是学生在答题过程中对课本中所学知识和方法的运用。此类题目难度一般，要求学生在读取题干信息后，能够开展迁移与联想，从而调动课本中的知识，运用课本中的方法进行作答',
  },
  {
    courseId: 7,
    courseName: '初中历史',
    labelId: 5193,
    ability: '再认与再现',
    commnet: '再认强调的是在前期对历史知识的识记，在答题时遇到相关知识点，能够感到熟悉，并运用这些知识进行作答；再现则强调某一事物或某一概念并未出现，但一看到这个事物或概念的名称，能够通过运用自身储备的知识对该事物或概念进行分析和理解。此类试题难度较大，对学生的能力要求较高，要求学生有牢固的历史知识基础，在此基础上能够对题干中的关键信息进行充分的分析和理解',
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5194,
    ability: '获取和解读地理信息',
    commnet: '1、能够从题目的文字表述中获取地理信息，包括读取题目的要求和各种有关地理事物定性、定量的信息。2、能够快速、全面、准确地获取图形语言形式的地理信息，包括判读和分析各种地理图表所承载的信息。3、能够准确和完整地理解所获取的地理信息。',
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5195,
    ability: '调动和运用地理知识',
    commnet: '1、能够调动和运用基本的地理数据、地理概念、地理事物的主要特征及分布、地理原理与规律等知识，对题目要求作答。2、能够调动和运用自主学习过程中获得的相关地理信息。3、能够选择和运用中学其他相关学科的基本技能解决地理问题。4、能够运用地理基本技能。如地理坐标的判断和识别，不同类型地理数据之间的转换，不同类型地理图表的填绘，地理数据和地理图表之间的转换，基本的地理观测、地理实验等。',
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5196,
    ability: '描述与阐述地理事物',
    commnet: '1、能够用简洁的文字语言、图形语言或其他表达方式描述地理概念，地理事物的特征，地理事物的分布和发展变化，地理基本原理与规律的要点。2、能够运用所学的地理知识和相关学科的知识，通过比较、判断、分析，阐释地理基本原理与规律。',
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5197,
    ability: '论证与探讨地理问题',
    commnet: '1、能够发现或提出科学的、具有创新意识的地理问题。2、能够提出必要的论据，论证和解决地理问题。3、能够用科学的语言、正确的逻辑关系，表达出论证和解决地理问题的过程与结果。4、能够运用正确的地理观念，探讨、评价现实中的地理问题。',
  },
  {
    courseId: 9,
    courseName: '高中语文',
    labelId: 5264,
    ability: '识记',
    commnet: '指识别和记忆，是最基本的能力层级。要求能识别和记忆语文基础知识、文化常识和名句名篇等。',
  },
  {
    courseId: 9,
    courseName: '高中语文',
    labelId: 5265,
    ability: '理解',
    commnet: '指领会并能作简单的解释，是在识记基础上高一级的能力层级。要求能够领会并解释词语、句子、段落等的意思。',
  },
  {
    courseId: 9,
    courseName: '高中语文',
    labelId: 5266,
    ability: '分析综合',
    commnet: '指分解剖析和归纳整合，是在识记和理解的基础上进一步提高了的能力层级。要求能够筛选材料中的信息，分解剖析相关现象和问题，并予以归纳整合。',
  },
  {
    courseId: 9,
    courseName: '高中语文',
    labelId: 5267,
    ability: '表达应用',
    commnet: '指对语文知识和能力的运用，是以识记、理解和分析综合为基础，在表达方面发展了的能力层级。',
  },
  {
    courseId: 9,
    courseName: '高中语文',
    labelId: 5268,
    ability: '鉴赏评价',
    commnet: '指对阅读材料初步的鉴别、赏析和评说，是以识记、理解和分析综合为基础，在阅读方面发展了的能力层级。',
  },
  {
    courseId: 9,
    courseName: '高中语文',
    labelId: 5275,
    ability: '探究',
    commnet: '指对某些问题进行探讨，有发现、有创见，是以识记、理解和分析综合为基础，在创新性思维方面发展了的能力层级。',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5145,
    ability: '运算求解',
    commnet: '运算求解能力是思维能力和运算技能的结合.运算包括对数字的计算、估值和近似计算，对式子的组合变形与分解变形，对几何图形各几何量的计算求解等.运算能力包括分析运算条件、探究运算方向、选择运算公式、确定运算程序等一系列过程中的思维能力，也包括在实施运算过程中遇到障碍而调整运算的能力',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5146,
    ability: '推理论证',
    commnet: '推理是思维的基本形式之一，它由前提和结论两部分组成;论证是由已有的正确的前提到被论证的结论的一连串的推理过程.推理既包括演绎推理，也包括合情推理；论证方法既包括按形式划分的演绎法和归纳法，也包括按思考方法划分的直接证法和间接证法.一般运用合情推理进行猜想，再运用演绎推理进行证明',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5147,
    ability: '应用意识',
    commnet: '能综合应用所学数学知识、思想和方法解决问题， 包括解决相关学科、生产、生活中简单的数学问题；能理解对问题陈述 的材料，并对所提供的信息资料进行归纳、整理和分类，将实际问题抽象为数学问题；能应用相关的数学方法解决问题进而加以验证，并能用数学语言正确地表达和说明.应用的主要过程是依据现实的生活背景，提炼相关的数量关系，将现实问题转化为数学问题，构造数学模型，并加以解决',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5148,
    ability: '数据处理',
    commnet: '会收集、整理、分析数据，能从大量数据中抽取对研究问题有用的信息，并做出判断',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5149,
    ability: '空间想象',
    commnet: '能根据条件作出正确的图形，根据图形想象出 直观形象;能正确地分析出图形中的基本元素及其相互关系；能对图形 进行分解、组合;会运用图形与图表等手段形象地揭示问题的本质',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5150,
    ability: '创新意识',
    commnet: '创新意识是理性思维的高层次表现.对数学问题的“观察、猜测、 抽象、概括、证明”，是发现问题和解决问题的重要途径，对数学知识的迁移、组合、融会的程度越高，显示出的创新意识也就越强',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5151,
    ability: '阅读理解',
    commnet: '是指在阅读实践中和阅读后的理解、分析、概括、联想、鉴赏和评判能力',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5152,
    ability: '抽象概括',
    commnet: '抽象是指舍弃事物非本质的属性，揭示其本质的属性;概括是指把仅仅属于某一类对象的共同属性区分出来的思维过程.抽象和概括是相互联系的，没有抽象就不可能有概括，而概括必须在抽象的基础上得出某种观点或某个结论',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5153,
    ability: '逻辑思维',
    commnet: '是指正确、合理思考的能力。即对事物进行观察、比较、分析、综合、抽象、概括、判断、推理能力，采用科学的逻辑方法，准确而有条理地表达自己思维过程的能力',
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5154,
    ability: '归纳总结',
    commnet: '能从所学新知识中找出重难点，能在大量习题中提炼出解题的基本思想方法，从而举一反三。能透过现象看本质',
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5242,
    ability: '识记能力',
    commnet: '识记常用单词或短语的含义的能力。',
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5243,
    ability: '语言逻辑能力',
    commnet: '能对题干句子或文章的结构有所了解，把握前后逻辑关系，即句与句、段与段之间的逻辑关系。',
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5244,
    ability: '听取能力',
    commnet: '获取事实性的具体信息，对所听内容做简单推断，理解说话者的意图、观点和态度的能力。',
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5245,
    ability: '推理分析能力',
    commnet: '根据阅读材料及对上下文的理解，能够推测出作者暗含的观点和态度。在理解文章总体内容的基础上，去领会作者的言外之意。',
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5246,
    ability: '综合应用能力',
    commnet: '英语听、说、读、写的能力。',
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5247,
    ability: '综合语言运用能力',
    commnet: '根据语言使用的需要，综合运用语言知识和语言技能来进行真实交际的能力，也就是使用语言完成交际任务。英语写作是综合运用能力的具体体现，写作要涉及语法、词汇、文体等多方面的知识。',
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5248,
    ability: '获取信息能力',
    commnet: '阅读题干或文章以获得某些特定的信息或准确地寻求所需的细节。有时比较直接，理解字面意思即可答题；有时则较为间接，需要归纳、概括和推理才能答题。',
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5249,
    ability: '理解主旨要义',
    commnet: '能够浏览文章获得上下文提供的信息，并根据文章的内在逻辑意义、贯穿文章始终的主线以及作者行文的走向，把握文脉，对归纳、概括能力有一定的要求。',
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5001,
    ability: '理解能力',
    commnet: '理解物理概念、物理规律的确切含义，理解物理规律的适用条件以及它们在简单情况下的应用；能够清楚地认识概念和规律的表达形式(包括文字表述和数学表达）；能够鉴别关于概念和规律的似是而非的说法；理解相关知识的区别和联系。',
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5002,
    ability: '推理能力',
    commnet: '能够根据已知的知识和物理事实、条件，对物理问题进行逻辑推理和论证，得出正确的结论或做出正确的判断，并能把推理过程正确地表达出来。',
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5003,
    ability: '分析综合能力',
    commnet: '能够独立地对所遇到的问题进行具体分析、研究，弄清其中的物理状态、物理过程和物理情境，找出起重要作用的因素及有关条件；能够把一个复杂问题分解为若干较简单的问题，找出它们之间的联系；能够提出解决问题的方法，运用物理知识综合解决所遇到的问题。',
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5004,
    ability: '应用数学处理物理问题的能力',
    commnet: '能够根据具体问题列出物理量之间的关系式，进行推导和求解，并根据结果得出物理结论；能运用几何图形、函数图像进行表达、分析。',
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5005,
    ability: '实验能力',
    commnet: '能明确实验目的，能理解实验原理和方法，能控制实验条件，会使用仪器，会观察、分析实验现象，会记录、处理实验数据，并得出结论，对结论进行分析和评价；能发现问题、提出问题，并制订解决方案；能运用已学过的物理理论、实验方法和实验仪器去处理问题，包括简单的设计性实验',
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5055,
    ability: '化学实验与探究',
    commnet: '1、了解并初步实践化学实验研究的一般过程，掌握化学实验基本方法和技能；2、在解决简单化学问题的过程中，运用科学简单的方法，初步了解化学变化规律，并对化学现象做出科学合理的解释。',
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5056,
    ability: '接受、吸收、整合化学信息',
    commnet: '1、对中学化学基础知识能融会贯通，有正确复述、再现、辨认的能力；2、能够通过对实际事物、实验现象、实物、模型、图像、图表的观察，以及对自然界、社会、生产、生活中的化学现象的观察，获取有关的感性知识和印象，并进行初步加工、吸收、有序储存的能力；3、从试题提供的信息中，准确的提取实质性内容，并与已有知识板块整合，重新组合为新知识板块的能力。',
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5057,
    ability: '分析问题和解决化学问题',
    commnet: '1、将实际问题分解，通过运用相关知识，采用分析、综合的方法，解决简单化学问题的能力；2、将分析解决问题的过程和成果，用正确的化学术语及文字、图表、模型、图形等表达，并作出解释的能力。',
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5058,
    ability: '实践与创新能力',
    commnet: '通过对教材实验的掌握，运用新方法改进过程，开辟出一条更好的实验方案的能力',
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5059,
    ability: '运用数学知识解决化学问题',
    commnet: '通过数学的方法解决化学题目的一种解题方法，用于评估学生对此类题型方法的题目掌握程度',
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5023,
    ability: '理解能力',
    commnet: '能理解所学知识的要点，把握知识间的内在联系，形成知识的网络结构',
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5024,
    ability: '实验与探究能力',
    commnet: '具备验证简单生物学事实的能力，并能对实验现象和结果进行解释、分析和处理',
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5025,
    ability: '获取信息的能力',
    commnet: '能从课外材料中获取相关的生物学信息，并能运用这些信息，结合所学知识解决相关的生物学问题',
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5026,
    ability: '综合运用能力',
    commnet: '理论联系实际，综合运用所学知识解决自然界和社会生活中的一些生物学问题',
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5130,
    ability: '获取和解读信息',
    commnet: '理解试题提供的图文材料和考试要求；理解材料，最大限度地获取有效信息；对有效信息进行完整、准确、合理的解读',
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5131,
    ability: '调动和运用知识',
    commnet: '辨别历史事物和历史解释；理解历史事实，分析历史结论；说明和证明历史现象和历史观点',
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5132,
    ability: '描述和阐释事物',
    commnet: '客观叙述历史事物；准确描述和解释历史事物的特征；认识历史事物的本质和规律，并做出正确的阐释',
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5133,
    ability: '论证和探讨问题',
    commnet: '运用判断、比较、归纳的方法论证历史问题；运用批判、借鉴、引用的方式评论历史观点；独立地对历史问题和历史观点提出不同看法',
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5167,
    ability: '获取和解读地理信息',
    commnet: '1、能够从题目的文字表述中获取地理信息，包括读取题目的要求和各种有关地理事物定性、定量的信息。2、能够快速、全面、准确地获取图形语言形式的地理信息，包括判读和分析各种地理图表所承载的信息。3、能够准确和完整地理解所获取的地理信息。',
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5168,
    ability: '调动和运用地理知识',
    commnet: '1、能够调动和运用基本的地理数据、地理概念、地理事物的主要特征及分布、地理原理与规律等知识，对题目要求作答。2、能够调动和运用自主学习过程中获得的相关地理信息。3、能够选择和运用中学其他相关学科的基本技能解决地理问题。4、能够运用地理基本技能。如地理坐标的判断和识别，不同类型地理数据之间的转换，不同类型地理图表的填绘，地理数据和地理图表之间的转换，基本的地理观测、地理实验等。',
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5169,
    ability: '描述与阐述地理事物',
    commnet: '1、能够用简洁的文字语言、图形语言或其他表达方式描述地理概念，地理事物的特征，地理事物的分布和发展变化，地理基本原理与规律的要点。2、能够运用所学的地理知识和相关学科的知识，通过比较、判断、分析，阐释地理基本原理与规律。',
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5170,
    ability: '论证与探讨地理问题',
    commnet: '1、能够发现或提出科学的、具有创新意识的地理问题。2、能够提出必要的论据，论证和解决地理问题。3、能够用科学的语言、正确的逻辑关系，表达出论证和解决地理问题的过程与结果。4、能够运用正确的地理观念，探讨、评价现实中的地理问题。',
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5069,
    ability: '获取和解读信息',
    commnet: '·能够从题目的文字表述中获取回答问题的有关信息。·能够快速、全面、准确地从图、表等形式中获取回答问题的有关信息。·能够准确和完整地理解并整合所获取的有关信息。',
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5070,
    ability: '调动和运用知识',
    commnet: '·能够根据从题目中获取和解读的试题信息，有针对性地调动有关的经济、政治、文化、哲学等方面的知识，并运用这些知识做出必要的判断。·能够调动和运用自主学习过程中获得的重大时事和相关信息。·能够展现出检索和选用自己“知识库”中有关知识、基本技能的能力。',
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5071,
    ability: '描述和阐释事物',
    commnet: '·能够用简洁的语言描述经济、政治、文化、哲学等学科所涉及的基本概念和基本观点。·能够运用历史的、辩证的观点和方法，分析、比较和解释有关政治、经济、文化等现象，认识事物的本质。·综合阐释或评价有关理论问题和现实问题。',
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5072,
    ability: '论证和探究问题',
    commnet: '·根据有关信息，调动和运用相关知识和技能，发现或者提出体现科学精神和创新意识的问题。·综合使用题目提供的信息、课堂学习或自主学习获得的知识、方法，提出比较必要的论据，论证和探究问题，得出合理的结论。·能用顺畅的语言、清晰的层次、正确的逻辑关系，表达出论证、探究的过程和结果。',
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5287,
    ability: '获取和解读信息',
    commnet: '从事实和材料中提取有效信息；对相关表述或事实进行归纳和概括；用不同的词句或形式转换表述概念、观点或信息',
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5288,
    ability: '调动和运用知识',
    commnet: '将具体事例与相关知识建立联系；正确调用某个概念、原理或观点，描述和阐释情境中的现象和事实，说明事物存在的依据和问题解决的办法',
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5289,
    ability: '描述和阐释事物',
    commnet: '解释概念、原理、观点和事实的内涵与外延；辨别不同概念、原理、观点和事实，比较发现其异同',
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5290,
    ability: '论证和探究问题',
    commnet: '运用多个知识或从多个层面，通过分析与综合，有条理地论述、证明事物存在的依据、价值或发展的条件、过程和结果；根据已知的条件推断结果、根据结果推断需要的相关条件、根据已有事实推断事物的发展趋势和未来状态',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5320,
    ability: '数感',
    commnet: '数感主要是指关于数与数量、数量关系、运算结果估计等方面的感悟。建立数感有助于学生理解现实生活中数的意义，理解或表述具体情境中的数量关系。',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5321,
    ability: '符号意识',
    commnet: '符号意识主要是指能够理解并且运用符号表示数、数量关系和变化规律；知道使用符号可以进行运算和推理，得到的结论具有一般性。建立符号意识有助于学生理解符号的使用是数学表达和进行数学思考的重要形式。',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5322,
    ability: '空间观念',
    commnet: '空间观念主要是指根据物体特征抽象出几何图形，根据几何图形想象出所描述的实际物体；想象出物体的方位和相互之间的位置关系；描述图形的运动和变化；依据语言的描述画出图形等。',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5323,
    ability: '几何直观',
    commnet: '几何直观主要是指利用图形描述和分析问题。借助几何直观可以把复杂的数学问题变得简明、形象，有助于探索解决问题的思路，预测结果。几何直观可以帮助学生直观地理解数学，在整个数学学习过程中都发挥着重要作用。',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5324,
    ability: '数据分析观念',
    commnet: '数据分析观念包括：了解在现实生活中有许多问题应当先做调查研究，收集数据，通过分析做出判断，体会数据中蕴涵着信息；了解对于同样的数据可以有多种分析的方法，需要根据问题的背景选择合适的方法；通过数据分析体验随机性，一方面对于同样的事情每次收集到的数据可能不同，另一方面只要有足够的数据就可能从中发现规律。',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5325,
    ability: '运算能力',
    commnet: '运算能力主要是指能够根据法则和运算律正确地进行运算的能力。培养运算能力有助于学生理解运算的算理，寻求合理简洁的运算途径解决问题。',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5326,
    ability: '推理能力',
    commnet: '推理能力的发展应贯穿在整个数学学习过程中。推理是数学的基本思维方式，也是人们学习和生活中经常使用的思维方式。推理一般包括合情推理和演绎推理,合情推理是从已有的事实出发，凭借经验和直觉，通过归纳和类比等推断某些结果；演绎推理是从已有的事实（包括定义、公理、定理等）和确定的规则（包括运算的定义、法则、顺序等）出发，按照逻辑推理的法则证明和计算。在解决问题的过程中，合情推理用于探索思路，发现结论；演绎推理用于证明结论。',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5327,
    ability: '模型思想',
    commnet: '模型思想的建立是学生体会和理解数学与外部世界联系的基本途径。建立和求解模型的过程包括：从现实生活或具体情境中抽象出数学问题，用数学符号建立方程、不等式、函数等表示数学问题中的数量关系和变化规律，求出结果、并讨论结果的意义。这些内容的学习有助于学生初步形成模型思想，提高学习数学的兴趣和应用意识。',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5328,
    ability: '应用意识',
    commnet: '应用意识有两个方面的含义，一方面有意识利用数学的概念、原理和方法解释现实世界中的现象，解决现实世界中问题；另一方面，认识到现实生活中蕴含着大量与数量和图形有关的问题，这些问题可以抽象成数学问题，用数学的方法予以解决。在整个数学教育的过程中都应该培养学生的应用意识，综合实践活动是培养应用意识很好的载体。',
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5329,
    ability: '归纳总结',
    commnet: '能从所学知识中找出重难点，能在大量习题中提炼出解题的基本思想方法，从而举一反三。能透过现象看本质。',
  }]

export const ruanyunMethod = [
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5074,
    labelName: '配方法',
    comment: '所谓配方，就是把一个解析式利用恒等变形的方法，把其中的某些项配成一个或几个多项式正整数次幂的和形式。通过配方解决数学问题的方法叫配方法。其中，用的最多的是配成完全平方式。配方法是数学中一种重要的恒等变形的方法，它的应用十分非常广泛，在因式分解、化简根式、解方程、证明等式和不等式、求函数的极值和解析式等方面都经常用到它。'
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5075,
    labelName: '换元法',
    comment: '换元法是数学中一个非常重要而且应用十分广泛的解题方法。我们通常把未知数或变数称为元，所谓换元法，就是在一个比较复杂的数学式子中，用新的变元去代替原式的一个部分或改造原来的式子，使它简化，使问题易于解决。'
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5076,
    labelName: '待定系数法',
    comment: '在解数学问题时，若先判断所求的结果具有某种确定的形式，其中含有某些待定的系数，而后根据题设条件列出关于待定系数的等式，最后解出这些待定系数的值或找到这些待定系数间的某种关系，从而解答数学问题，这种解题方法称为待定系数法。它是中学数学中常用的方法之一。'
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5077,
    labelName: '反证法',
    comment: '反证法是一种间接证法，它是先提出一个与命题的结论相反的假设，然后，从这个假设出发，经过正确的推理，导致矛盾，从而否定相反的假设，达到肯定原命题正确的一种方法。反证法可以分为归谬反证法(结论的反面只有一种)与穷举反证法(结论的反面不只一种)。用反证法证明一个命题的步骤，大体上分为：(1)反设；(2)归谬；(3)结论。反设是反证法的基础，为了正确地作出反设，掌握一些常用的互为否定的表述形式是有必要的，例如：是/不是；存在/不存在；平行于/不平行于；垂直于/不垂直于；等于/不等于；大(小)于/不大(小)于；都是/不都是；至少有一个/一个也没有；至少有n个/至多有(n一1)个；至多有一个/至少有两个；唯一/至少有两个。归谬是反证法的关键，导出矛盾的过程没有固定的模式，但必须从反设出发，否则推导将成为无源之水，无本之木。推理必须严谨。导出的矛盾有如下几种类型：与已知条件矛盾；与已知的公理、定义、定理、公式矛盾；与反设矛盾；自相矛盾。'
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5078,
    labelName: '因式分解法',
    comment: '因式分解，就是把一个多项式化成几个整式乘积的形式。因式分解是恒等变形的基础，它作为数学的一个有力工具、一种数学方法在代数、几何、三角等的解题中起着重要的作用。因式分解的方法有许多，除中学课本上介绍的提取公因式法、公式法、分组分解法、十字相乘法等外，还有如利用拆项添项、求根分解、换元、待定系数等等。'
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5079,
    labelName: '构造法',
    comment: '在解题时，我们常常会采用这样的方法，通过对条件和结论的分析，构造辅助元素，它可以是一个图形、一个方程(组)、一个等式、一个函数、一个等价命题等，架起一座连接条件和结论的桥梁，从而使问题得以解决，这种解题的数学方法，我们称为构造法。运用构造法解题，可以使代数、三角、几何等各种数学知识互相渗透，有利于问题的解决。'
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5080,
    labelName: '变换法',
    comment: '在数学问题的研究中，，常常运用变换法，把复杂性问题转化为简单性的问题而得到解决。所谓变换是一个集合的任一元素到同一集合的元素的一个一一映射。中学数学中所涉及的变换主要是初等变换。有一些看来很难甚至于无法下手的习题，可以借助几何变换法，化繁为简，化难为易。另一方面，也可将变换的观点渗透到中学数学教学中。将图形从相等静止条件下的研究和运动中的研究结合起来，有利于对图形本质的认识。几何变换包括：（1）平移；（2）旋转；（3）对称。'
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5081,
    labelName: '面积法',
    comment: '平面几何中讲的面积公式以及由面积公式推出的与面积计算有关的性质定理，不仅可用于计算面积，而且用它来证明平面几何题有时会收到事半功倍的效果。运用面积关系来证明或计算平面几何题的方法，称为面积方法，它是几何中的一种常用方法。用归纳法或分析法证明平面几何题，其困难在添置辅助线。面积法的特点是把已知和未知各量用面积公式联系起来，通过运算达到求证的结果。所以用面积法来解几何题，几何元素之间关系变成数量之间的关系，只需要计算，有时可以不添置补助线，即使需要添置辅助线，也很容易考虑到。'
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5082,
    labelName: '判别式法与韦达定理',
    comment: '一元二次方程ax2+bx+c=0（a、b、c属于R，a≠0）根的判别，△=b2-4ac，不仅用来判定根的性质，而且作为一种解题方法，在代数式变形，解方程(组)，解不等式，研究函数乃至几何、三角运算中都有非常广泛的应用。韦达定理除了已知一元二次方程的一个根，求另一根；已知两个数的和与积，求这两个数等简单应用外，还可以求根的对称函数，计论二次方程根的符号，解对称方程组，以及解一些有关二次曲线的问题等，都有非常广泛的应用。'
  },
  {
    courseId: 2,
    courseName: '初中数学',
    labelId: 5083,
    labelName: '客观题解题方法',
    comment: '选择题是给出条件和结论，要求根据一定的关系找出正确答案的一类题型。选择题的题型构思精巧，形式灵活，可以比较全面地考察学生的基础知识和基本技能，从而增大了试卷的容量和知识覆盖面。填空题是标准化考试的重要题型之一，它同选择题一样具有考查目标明确，知识复盖面广，评卷准确迅速，有利于考查学生的分析判断能力和计算能力等优点，不同的是填空题未给出答案，可以防止学生猜估答案的情况。要想迅速、正确地解选择题、填空题，除了具有准确的计算、严密的推理外，还要有解选择题、填空题的方法与技巧。下面通过实例介绍常用方法。（1）直接推演法：直接从命题给出的条件出发，运用概念、公式、定理等进行推理或运算，得出结论，选择正确答案，这就是传统的解题方法，这种解法叫直接推演法。（2）验证法：由题设找出合适的验证条件，再通过验证，找出正确答案，亦可将供选择的答案代入条件中去验证，找出正确答案，此法称为验证法（也称代入法）。当遇到定量命题时，常用此法。（3）特殊元素法：用合适的特殊元素（如数或图形）代入题设条件或结论中去，从而获得解答。这种方法叫特殊元素法。（4）排除、筛选法：对于正确答案有且只有一个的选择题，根据数学知识或推理、演算，把不正确的结论排除，余下的结论再经筛选，从而作出正确的结论的解法叫排除、筛选法。（5）图解法：借助于符合题设条件的图形或图像的性质、特点来判断，作出正确的选择称为图解法。图解法是解选择题常用方法之一。（6）分析法：直接通过对选择题的条件和结论，作详尽的分析、归纳和判断，从而选出正确的结果，称为分析法。'
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5234,
    labelName: '直选法',
    comment: '直接利用相关语法，结合题干给出的信息捕捉到解题信息，从而直接选出真确答案的方法。'
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5235,
    labelName: '排除法',
    comment: '根据题干的结构、意义及语境，排除不符合的选项。'
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5236,
    labelName: '固定搭配法',
    comment: '①从语法角度来说，句子不是词的序列，而是词组的序列。②因搭配关系而产生的一般性词汇。如：see a film。③词序和意义皆以固定的复合词和动词短语。④因词组而构成的常见的句式，如It feels+形容词+不定式。'
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5237,
    labelName: '信息定位法',
    comment: '根据题干或选项中的关键线索词从阅读材料中找到相关的句子，与选项进行比较从而确定答案。'
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5238,
    labelName: '总结归纳法',
    comment: '对于不能从原文中直接找出答案的可在把握全文中心和线索的前题下进行概括、归纳得出正确答案。'
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5239,
    labelName: '时态判定法',
    comment: '根据题干给出的时间状语或暗含的动作先后来判定谓语动词的时态。'
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5240,
    labelName: '语态判定法',
    comment: '找出题干中的谓语动词，判断谓语与主语之间是主动还是被动关系。'
  },
  {
    courseId: 3,
    courseName: '初中英语',
    labelId: 5241,
    labelName: '构词法',
    comment: '在阅读文章时，总会遇上一些新词汇，有时很难根据上下文来推断其词意，而它们对文章的理解又有着举足轻重的作用，此时，如掌握了一些常用的词根、前缀、后缀等语法知识，如前缀un表反义词，就能很好地推测词意了。'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5097,
    labelName: '直接判断',
    comment: '通过阅读和观察,利用题中所给的条件,根据所学的知识和规律直接判断,得出正确的答案'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5098,
    labelName: '排除',
    comment: '通过排除(否定)错误选项的一种解题方法，适用于解选择题或选择性填空题'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5099,
    labelName: '综合分析',
    comment: '能够独立地对所遇到的问题进行具体分析、研究，弄清其中的物理状态、物理过程和物理情境，找出起重要作用的因素及有关条件；能够把一个复杂问题分解为若干较简单的问题，找出它们之间的联系；能够提出解决问题的方法，运用物理知识综合解决所遇到的问题。'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5100,
    labelName: '特殊值代入',
    comment: '在解题过程中代入特殊固定值进行求解'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5101,
    labelName: '等效',
    comment: '等效法，就是在保证效果相同的前提下，将一个复杂的物理问题转换成较简单问题的思维方法。其基本特征为等效替代。物理学中等效法的应用较多。合力与分力；合运动与分运动；总电阻与分电阻；交流电的有效值等。除这些等效等效概念之外，还有等效电路、等效电源、等效模型、等效过程等。'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5103,
    labelName: '作图分析',
    comment: '将物理现象或过程用图形/图象表征出后，再据图形表征的特点或图象斜率、截距、面积所表述的物理意义来求解的方法。尤其是图象法对于一些定性问题的求解独到好处。'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5104,
    labelName: '估算',
    comment: '物理估算，一般是指依据一定的物理概念和规律，运用物理方法和近似计算方法，对物理量的数量级或物理量的取值范围，进行大致的推算．物理估算是一种重要的方法．有的物理问题，在符合精确度的前提下可以用近似的方法简捷处理'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5106,
    labelName: '转换思维',
    comment: '有些物理问题，由于运动过程复杂或难以进行受力分析，造成解答困难．此种情况应根据运动的相对性或牛顿第三定律转换参考系或研究对象，即所谓的转换法．应用此法，可使问题化难为易、化繁为简，使解答过程一目了然．'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5107,
    labelName: '模型思维',
    comment: '物理模型就是在研究物理问题的本质时采用的一种简化描述或模拟的方法。'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5108,
    labelName: '单位判断',
    comment: '根据物理名称特定的单位来判断'
  },
  {
    courseId: 4,
    courseName: '初中物理',
    labelId: 5155,
    labelName: '控制变量',
    comment: '理学研究中常用的一种研究方法——控制变量法。所谓控制变量法，就是在研究和解决问题的过程中，对影响事物变化规律的因素或条件加以人为控制，使其中的一些条件按照特定的要求发生变化或不发生变化，最终解决所研究的问题。'
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5171,
    labelName: '守恒法',
    comment: '所谓“守恒”就是以化学反应过程中存在的某些守恒关系如质量守恒、元素守恒、原子个数守恒等。运用守恒法解题可避免在纷纭复杂得解题背景中寻找关系，提高解题的准确度。'
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5172,
    labelName: '关系式法',
    comment: '关系式法是根据化学方程式计算的诸法中较主要的一种方法，它可以通过方程式中物质的关系，列出比例式，使多步计算化为一步而完成。'
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5173,
    labelName: '估算法',
    comment: '有些选择题涉及计算、像这类计算题大多不用计算初确切的数值，只要大约估，再根据题目中的条件，就可以得出答案，这样不但不会计算失误，也为解决后面问题争取了大量时间'
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5174,
    labelName: '淘汰法',
    comment: '先根据一定条件和标准，对全部备选方法筛选一遍，把达不到要求的选项淘汰掉，以达到缩小选择范围的目的，一般客观题出现。'
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5175,
    labelName: '平均值法',
    comment: '平均值法是巧解混合问题的一种常见的有效方法。此方法不用考虑各组分的含量，通过求出混合物某个物理量的平均值，混合物的两个成分中的这个物理量肯定一个比平均值大，一个比平均值小，就符合要求，这样可以避免过多计算，准确而快捷的得出正确答案'
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5176,
    labelName: '差量法',
    comment: '差量法是依据化学反应前后的某些变化找出所谓的理论差量（固体质量差、溶液质量差、气体体积差、气体物质的量之差等），与反应或生成物的变化量成正比而建立的一种解题方法。此法将“差量”看作化学方程式右端的一项，将已知差量（实际差量）与化学方程式中对应差量（理论差量）列成比例，其他解题步骤与根据化学方程式列比例式解题完全一样。此方法的关键是正确找出理论差量。'
  },
  {
    courseId: 5,
    courseName: '初中化学',
    labelId: 5177,
    labelName: '识记法',
    comment: '考查概念等基础知识，不需要通过分析、推断、计算就可以得出答案的方法'
  },
  {
    courseId: 6,
    courseName: '初中生物',
    labelId: 5207,
    labelName: '正向选择',
    comment: '通过对所学知识分析理解、对比判断得出正确答案的方法'
  },
  {
    courseId: 6,
    courseName: '初中生物',
    labelId: 5208,
    labelName: '反推法',
    comment: '在计算性的试题中，根据给出的选项来反推出正确答案'
  },
  {
    courseId: 6,
    courseName: '初中生物',
    labelId: 5209,
    labelName: '排除法',
    comment: '从排除谬误入手，逐步缩小范围，去伪存真，最后确定正确答案'
  },
  {
    courseId: 6,
    courseName: '初中生物',
    labelId: 5210,
    labelName: '综合分析法',
    comment: '就是根据题目的主要条件，并按照知识体系分类整理，再进一步通过分析得到综合客观的答案'
  },
  {
    courseId: 6,
    courseName: '初中生物',
    labelId: 5211,
    labelName: '信息转换法',
    comment: '将不可见、不易见的现象或条件转换成可见、易见的；将陌生、复杂的问题转换成熟悉、简单的问题，并加以分析解答'
  },
  {
    courseId: 7,
    courseName: '初中历史',
    labelId: 5185,
    labelName: '排除法',
    comment: '是指在答题过程中将表述错误的选项排除在外，从而得到正确的选项；或者是按照可能性高低来归纳答案，从而提高正确答案命中概率'
  },
  {
    courseId: 7,
    courseName: '初中历史',
    labelId: 5186,
    labelName: '简化法',
    comment: '是指对繁琐的题干中的内容进行简化，对题干中材料所给出的大量信息进行提取，进行分析归类，选择重点并以最科学的方法加以处理，从而提高解题效率的方法'
  },
  {
    courseId: 7,
    courseName: '初中历史',
    labelId: 5187,
    labelName: '替换法',
    comment: '是指用一种事物或概念去替换另一种事物或概念，化抽象为具体，将不熟悉的概念转换为熟悉概念，从而使思路清晰更易解决问题'
  },
  {
    courseId: 7,
    courseName: '初中历史',
    labelId: 5188,
    labelName: '直接联想法',
    comment: '是指在读取某一信息后，直接联想到另一事物或另一信息的方法。学生在答题时，可通过题干信息，直接联想到课本知识从而作答；或是通过对题干的阅读，直接联想到正确的答案'
  },
  {
    courseId: 7,
    courseName: '初中历史',
    labelId: 5189,
    labelName: '题干还原法',
    comment: '把每个选项当做正确选项，按照题干表明的关系进行推理，找到正确答案的方法'
  },
  {
    courseId: 7,
    courseName: '初中历史',
    labelId: 5228,
    labelName: '图表法',
    comment: '是指通过读取图片或表格中的有效信息，以获取答题所需的信息，进而解决问题的方法。图表法强调的是学生在作答时能够通过对图标的读取，获取足够的信息，进行答题'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5198,
    labelName: '直选',
    comment: '根据题干和选项，直接把答案准确地选择出来'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5199,
    labelName: '精确计算',
    comment: '根据题干所给变量数据，按照一定的算法算出精确的答案'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5200,
    labelName: '因果联系',
    comment: '题干、选项或答案之间有因果关系。根据它们之间的因果关系得出答案'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5201,
    labelName: '图文转换',
    comment: '将图表中的信息转换成语言文字信息，并根据所得信息然后做出答案'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5202,
    labelName: '优选',
    comment: '从符合题意的选项中选取最优答案'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5203,
    labelName: '排除',
    comment: '根据所学知识以及题干所给信息排除干扰项，最终得出答案'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5204,
    labelName: '特例反证',
    comment: '根据符合条件的特定实例来从方面验证，得出答案'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5205,
    labelName: '排序',
    comment: '根据题干要求做相应排序，得出正确答案'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5206,
    labelName: '同意相溶',
    comment: '将题干、选项之间相同意思的内容进行综合分析，得出答案'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5291,
    labelName: '原因分析',
    comment: '根据问题与答案之间的逻辑关系或因果关系，得出答案'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5292,
    labelName: '特征描述',
    comment: '根据题干要求对一定的地理现象或事物进行特征描述'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5293,
    labelName: '区域比较',
    comment: '根据不同区域的地理要素进行相应比较'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5294,
    labelName: '综合评价',
    comment: '对地理现象或事物进行综合分析、评价'
  },
  {
    courseId: 8,
    courseName: '初中地理',
    labelId: 5295,
    labelName: '措施对策',
    comment: '对地理问题提出相关措施及对策'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5134,
    labelName: '消元法',
    comment: '对于含有多个变数的问题，有时可以利用题设条件和某些已知恒等式（代数恒等式或三角恒等式），通过适当变形，消去一部分变数，使问题得以解决，这种解题方法，通常称为消元法，又称消去法'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5135,
    labelName: '配方法',
    comment: '配方法是对数学式子进行一种定向变形（配成完全平方）的技巧，通过配方找到已知和未知的联系，从而化繁为简。何时配方，需要我们适当预测，并且合理运用“裂项”与“添项”、“配”与“凑”的技巧，从而完成配方。有时也将其称为“凑配法”'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5136,
    labelName: '换元法',
    comment: '解数学题时，把某个式子看成一个整体，用一个变量去代替它，从而使问题得到简化，这叫换元法'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5137,
    labelName: '反证法',
    comment: '反证法就是从否定命题的结论入手，并把对命题结论的否定作为推理的已知条件，进行正确的逻辑推理，使之得到与已知条件、已知公理、定理、法则或者已经证明为正确的命题等相矛盾，矛盾的原因使假设不成立，所以肯定了命题的结论，从而使命题获得证明'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5138,
    labelName: '数学归纳法',
    comment: '数学归纳法是用来证明某些与自然数有关的数学命题的一种推理方法，在解数学题中有着广泛的应用.它是一个递推的数学论证方法，论证的第一步是证明命题在n=1(或n0)时成立；这是递推的基础；第二步是假设在n=k时命题成立，再证明n=k+1时命题也成立，这是无限递推下去的理论依据，它判断命题的正确性能否由特殊推广到一般，实际上它使命题的正确性突破了有限，达到无限'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5139,
    labelName: '待定系数',
    comment: '要确定变量间的函数关系，设出某些未知系数，然后根据所给条件来确定这些未知系数的方法叫待定系数法'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5140,
    labelName: '分类法',
    comment: '不少数学问题，在解题过程中二常常需要借助逻辑中的分类规则，把题设条件所确定的集合，分成若干个便于讨论的非空真子集，然后在各个非空真子集内进行求解, 直到获得完满的结果。这种把逻辑分类思想移植到数学中来，用以指导解题的方法，通常称为分类或分域法'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5141,
    labelName: '数学模型法',
    comment: '数学模型法，是指把所考察的实际问题，进行数学抽象,构造相应的数学模型, 通过对数学模型的研究，使实际问题得以解决的一种数学方法'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5142,
    labelName: '定义法',
    comment: '所谓定义法，就是直接用数学定义解题。数学中的定理、公式、性质和法则等，都是由定义和公理推演出来。定义是揭示概念内涵的逻辑方法，它通过指出概念所反映的事物的本质属性来明确概念'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5143,
    labelName: '参数法',
    comment: '参数法是指在解题过程中，通过适当引入一些与题目研究的数学对象发生联系的新变量（参数），以此作为媒介，再进行分析和综合，从而解决问题'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5144,
    labelName: '数形结合法',
    comment: '数形结合法，其实也是一种数学解题方法.其实质是将抽象的数学语言与直观的图像结合起来，关键是代数问题与图形之间的相互转化，它可以使代数问题几何化，几何问题代数化。'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5217,
    labelName: '赋值法',
    comment: '对于有些问题，若能根据其具体情况，合理地、巧妙地对某些元素赋值，特别是赋予确定的特殊值（如0，1，－1等），往往能使问题获得简捷有效的解决。但是这仅仅只能得到该赋予的值的情况，所以做题时可以继续根据已得到的情况推断并证明。这就是赋值法'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5218,
    labelName: '分析综合法',
    comment: '在数学解题中，分析法是从数学题的待证结论或需求问题出发，一步一步地探索下去，最后达到题设的已知条件。综合法则是从数学题的已知条件出发，经过逐步的逻辑推理，最后达到待证结论或需求问题。对于解答证明来说，分析法表现为执果索因，综合法表现为由果导因，它们是寻求解题思路的两种基本思考方法，应用十分广泛'
  },
  {
    courseId: 10,
    courseName: '高中数学',
    labelId: 5219,
    labelName: '类比与归纳法',
    comment: '由一类事物中个别（或部分）事物具有某种属性，推断该类事物中每一个对象都具有该属性的推理，称为归纳推理；由于两类不同事物在某些属性上相同或相似，在此基础上，根据一类事物的其他特征，推断另一类事物也具有类似的其他特征，我们把这种推理过程称为类比推理'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5250,
    labelName: '直选法',
    comment: '直接利用相关语法，结合题干给出的信息捕捉到解题信息，从而直接选出真确答案的方法。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5251,
    labelName: '排除法',
    comment: '根据题干的结构，选项意义及语境，排除不符合的选项。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5252,
    labelName: '固定搭配法',
    comment: '对词组、短语有整体记忆，掌握它们的搭配规律。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5253,
    labelName: '信息定位法',
    comment: '根据题干或选项中的关键线索词从阅读材料中找到相关的句子，与选项进行比较从而确定答案。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5254,
    labelName: '时态判定法',
    comment: '根据题干给出的时间状语或暗含的动作先后来判定谓语动词的时态。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5255,
    labelName: '语态判定法',
    comment: '找出题干中的谓语动词，判断谓语与主语之间是主动还是被动关系。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5256,
    labelName: '构词法',
    comment: '在阅读文章时，总会遇上一些新词汇，有时很难根据上下文来推断其词意，而它们对文章的理解又有着举足轻重的作用，此时，如掌握了一些常用的词根、前缀、后缀等语法知识，如前缀un表反义词，就能很好地推测词意了。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5257,
    labelName: '题眼法',
    comment: '标志词就是“题眼”。抓住了题眼,就能准确判断命题者的意图。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5258,
    labelName: '还原法',
    comment: '在解题过程中将一个结构不明显或者复杂的句子，通过还原，使它变成另一个结构简单明了且意思相同的句子的一种解题方法。这种方法多用于疑问句，有被动语态的句子，倒装句，省略句和感叹句中。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5259,
    labelName: '推理法',
    comment: '对题干涉及的一些重点的语法如时态、语态、非谓语动词、情态动词、倒装句、复合句等的运用规律和使用规则熟悉，能够分析句子结构，对所缺成分做出判定。或根据阅读材料及对上下文的理解，能够推测出作者暗含的观点和态度。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5260,
    labelName: '略读法',
    comment: '快速阅读获得材料想要表达的主要观念，为了找到针对某个问题的答案，可以在脑子中带着这个问题通过略读有关材料找出答案。'
  },
  {
    courseId: 11,
    courseName: '高中英语',
    labelId: 5261,
    labelName: '查阅法',
    comment: '根据问题以及选择项，有目的地判断与猜测要查找的信息如时间、地点、人物等信息。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5006,
    labelName: '直接判断法',
    comment: '通过阅读和观察,利用题中所给的条件,根据所学的知识和规律直接判断,得出正确的答案'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5007,
    labelName: '计算分析求解法',
    comment: '通过分析问题类型，然后运用特定的公式进行计算分析求解。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5008,
    labelName: '极限思维方法',
    comment: '极限思维方法是将问题推向极端状态的过程中,着眼一些物理量在连续变化过程中的变化趋势及一般规律在极限值下的表现或者说极限值下一般规律的表现,从而对问题进行分析和推理的一种思维办法。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5009,
    labelName: '等效法',
    comment: '等效法，就是在保证效果相同的前提下，将一个复杂的物理问题转换成较简单问题的思维方法。其基本特征为等效替代。物理学中等效法的应用较多。合力与分力；合运动与分运动；总电阻与分电阻；交流电的有效值等。除这些等效等效概念之外，还有等效电路、等效电源、等效模型、等效过程等。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5010,
    labelName: '猜想与假设法',
    comment: '猜想与假设法，是在研究对象的物理过程不明了或物理状态不清楚的情况下，根据猜想，假设出一种过程或一种状态，再据题设所给条件通过分析计算结果与实际情况比较作出判断的一种方法,或是人为地改变原题所给条件，产生出与原题相悖的结论，从而使原题得以更清晰方便地求解的一种方法。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5011,
    labelName: '构建物理模型法',
    comment: ' 物理学很大程度上,可以说是一门模型课.无论是所研究的实际物体,还是物理过程或是物理情境,大都是理想化模型.如 实体模型有:质点、点电荷、点光源、轻绳轻杆、弹簧振子、平行玻璃砖、……物理过程有：匀速运动、匀变速、简谐运动、共振、弹性碰撞、圆周运动……物理情境有：人船模型、子弹打木块、平抛、临界问题……求解物理问题，很重要的一点就是迅速把所研究的问题归宿到学过的物理模型上来，即所谓的建模。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5012,
    labelName: '估算法',
    comment: '估算是根据具体条件及有关知识对事物的数量或算式的结果作出的大概推断或估计'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5013,
    labelName: '单位判断法',
    comment: '根据物理名称特定的单位来判断'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5014,
    labelName: '图形/图象图解法',
    comment: '图形/图象图解法就是将物理现象或过程用图形/图象表征出后，再据图形表征的特点或图象斜率、截距、面积所表述的物理意义来求解的方法。尤其是图象法对于一些定性问题的求解独到好处。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5015,
    labelName: '微元法',
    comment: '在处理问题时，从对事物的极小部分(微元)分析入手，达到解决事物整体目的的方法。它在解决物理学问题时很常用，思想就是“化整为零”，先分析“微元”，再通过“微元”分析整体。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5016,
    labelName: '几何法',
    comment: '运用数学中的几何方法进行分析求解'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5017,
    labelName: '递推归纳法',
    comment: '归纳法或归纳推理，有时叫做归纳逻辑，是从个别性知识，引出一般性知识的推理，是由已知真的前提，引出可能真的结论。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5018,
    labelName: '平均思想方法',
    comment: '物理学中，有些物理量是某个物理量对另一物理量的积累，若某个物理量是变化的，则在求解积累量时，可把变化的这个物理量在整个积累过程看作是恒定的一个值---------平均值，从而通过求积的方法来求积累量。这种方法叫平均思想方法。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5019,
    labelName: '整体法和隔离法',
    comment: '整体法是在确定研究对象或研究过程时,把多个物体看作为一个整体或多个过程看作整个过程的方法;隔离法是把单个物体作为研究对象或只研究一个孤立过程的方法.  整体法与隔离法，二者认识问题的触角截然不同.整体法，是大的方面或者是从整的方面来认识问题，宏观上来揭示事物的本质和规律.而隔离法则是从小的方面来认识问题，然后再通过各个问题的关系来联系，从而揭示出事物的本质和规律。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5020,
    labelName: '临界问题分析法',
    comment: '临界问题，是指一种物理过程转变为另一种物理过程，或一种物理状态转变为另一种物理状态时，处于两种过程或两种状态的分界处的问题，叫临界问题。处于临界状的物理量的值叫临界值。物理量处于临界值时：①物理现象的变化面临突变性。②对于连续变化问题，物理量的变化出现拐点，呈现出两性，即能同时反映出两种过程和两种现象的特点。解决临界问题，关键是找出临界条件。一般有两种基本方法：①以定理、定律为依据，首先求出所研究问题的一般规律和一般解，然后分析、讨论其特殊规律和特殊解②直接分析、讨论临界状态和相应的临界值，求解出研究问题的规律和解。'
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5021,
    labelName: '对称法',
    comment: '物理问题中有一些物理过程或是物理图形是具有对称性的。利用物理问题的这一特点求解，可使问题简单化。要认识到一个物理过程，一旦对称,则相当一部分物理量（如时间、速度、位移、加速度等）是对称的。  '
  },
  {
    courseId: 12,
    courseName: '高中物理',
    labelId: 5022,
    labelName: '寻找守恒量法',
    comment: '是研究数量时总量不变的一种现象。物理学中的守恒，是指在物理变化过程或物质的转化迁移过程中一些物理量的总量不变的现象或事实。守恒，已是物理学中最基本的规律（有动量守恒、能量守恒、电荷守恒、质量守恒），也是一种解决物理问题的基本思想方法。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5039,
    labelName: '直接判断法',
    comment: '通过读题干，能直接判断出答案的方法。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5040,
    labelName: '守恒法',
    comment: '所谓“守恒”就是以化学反应过程中存在的某些守恒关系如质量守恒、元素守恒、得失电子守恒，电荷守恒等。运用守恒法解题可避免复杂的过程，根据始态和终态直接寻找关系式，提高解题的准确度。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5041,
    labelName: '估算法',
    comment: '有些选择题涉及计算、像这类计算题大多不用计算初确切的数值，只要大约估算，再根据题目中的条件，就可以得出答案，这样不但不会计算失误，也为解决后面问题争取了大量时间'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5042,
    labelName: '差量法',
    comment: '差量法是依据化学反应前后的某些变化找出所谓的理论差量（固体质量差、溶液质量差、气体体积差、气体物质的量之差等），与反应或生成物的变化量成正比而建立的一种解题方法。此法将“差量”看作化学方程式右端的一项，将已知差量（实际差量）与化学方程式中对应差量（理论差量）列成比例，其他解题步骤与根据化学方程式列比例式解题完全一样。此方法的关键是正确找出理论差量。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5043,
    labelName: '关系式法',
    comment: '关系式法是根据化学方程式计算的诸法中较主要的一种方法，它可以通过方程式中物质的关系，列出比例式，使多步计算化为一步而完成。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5044,
    labelName: '平均值法',
    comment: '平均值法是巧解混合问题的一种常见的有效方法。此方法不用考虑各组分的含量，通过求出混合物某个物理量的平均值，混合物的两个成分中的这个物理量肯定一个比平均值大，一个比平均值小，就符合要求，这样可以避免过多计算，准确而快捷的得出正确答案'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5045,
    labelName: '淘汰法',
    comment: '根据试卷答案的特点，将试题的各选项跟题干进行比较，去伪存真，淘汰不合题意的选项，从而获得合乎题意的答案的解题方法'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5046,
    labelName: '极值法',
    comment: '所谓“极值法”就是对数据不足无从下手的求算或判断混合物组成的题，极端假设恰好为某一成分或恰好完全反应物质的量比（或体积比）的解题方法，以确定混合体系各成分的名称、质量分数、体积分数，这样使一些抽象的复杂的问题具体化、简单化，以达到解题快、效率高的目的。计算时常把混合物假设成纯净物。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5047,
    labelName: '假设法',
    comment: '当某一变因素的存在形式限定在有限种可能时，假设该因素处于某种情况，并以此为条件进行推理，谓之假设法。它是科学探究中的重要思想方法，以及部分计算题中大量。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5048,
    labelName: '十字相乘法',
    comment: '十字交叉法是进行二组分混和物平均量与组分量计算的一种简便方法。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5049,
    labelName: '等效代换法',
    comment: '等效替代法是在保证某种效果（特性和关系）相同的前提下，将实际的、复杂的转化为等效的、简单的、易于研究的研究和处理的方法，例如等效平衡'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5050,
    labelName: '摩尔电子质量法',
    comment: '1．摩尔电子质量（Me）：某物质在反应中转移1摩尔电子所引起的质量改变。通常指在反应中提 供单位物质的量的电子所需要的物质的质量。单位：克/摩尔电子（g/mol·e）；2．平均摩尔电子质量：(Me)：某混合物在反应中共转移1摩尔电子所引起的质量改变。单位：克/摩尔电子（g/mol·e）。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5051,
    labelName: '验证法',
    comment: '实验验证法是在人们对事物的认识已有了假设的前提下,采用再次实验,加以验证,以证明原有假设是否正确的—种检验方法，在实验、推断题中常见。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5052,
    labelName: '数轴法',
    comment: '主要指运用数轴方法解决题目的过程。'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5053,
    labelName: '逆推法',
    comment: '用还原思想解题的方法。就是从题目的问题或结果出发，根据已知条件一步一步进行逆向推理，逐步靠拢原始的条件，推断题中常见'
  },
  {
    courseId: 13,
    courseName: '高中化学',
    labelId: 5054,
    labelName: '图表法',
    comment: '已知条件或所求内容是以图像的形式表述的，解这类题的方法统称图解法。图解法既可用于解决定性判断方面的问题，也可以用于解决定量计算中的问题。运用图解法的核心问题是识图。'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5027,
    labelName: '直接法',
    comment: '在准确掌握生物知识的前提下，通过观察思考，直接从题目所给出的条件中作出判断，确定符合题干的选项'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5028,
    labelName: '排除法',
    comment: '从排除谬误入手，逐步缩小范围，去伪存真，最后确定正确答案'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5029,
    labelName: '推理法',
    comment: '通过比较选项所涉及的生物学的相似、相近、易混的概念、原理、方法、结构、功能等，以达到摒弃似是而非的选项，从而作出正确评判的方法'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5030,
    labelName: '比较判断',
    comment: '生物学的很多概念、生理过程相近但并不相同，通过比较判断法即可判断出其中的细微差别，解决问题时便能明辨对错，得出正确答案'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5031,
    labelName: '实验分析',
    comment: '通过对实验内容的分析理解，获取答案的方法'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5032,
    labelName: '个个击破法',
    comment: '即找出差异，分而解之，突破难点'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5033,
    labelName: '假设法',
    comment: '即先假设某个选项正确，再结合题干中的信息，就会有所突破'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5034,
    labelName: '估算法',
    comment: '根据题意分析挖掘题目的隐含条件。寻找估算的依据将之进行科学巧妙的转化,简化求解过程和难度对数据进行科学的近似处理得到结论'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5035,
    labelName: '逆向思维法',
    comment: '跳出常规思维模式，以另类角度从不同侧面思考问题'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5036,
    labelName: '分解法',
    comment: '是指把一个复杂的研究对象分解成规律简单、明显的几个研究对象的解题方法'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5037,
    labelName: '末位数计算法',
    comment: '即根据题干所给出的信息，列出正确的式子后，在观察到四个选项的数值的最末位的数都不同，可以用口算只求出末位的数值即可得到答案'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5038,
    labelName: '代入法',
    comment: '即根据题干所给出的信息，将所求的问题设成未知数，在列出正确的式子后，并不求出答案，而是将待选答案直接代入式子中'
  },
  {
    courseId: 14,
    courseName: '高中生物',
    labelId: 5060,
    labelName: '运算求解法',
    comment: '通过对题干条件数据分析，并加以计算得到正确答案的方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5117,
    labelName: '信息推断',
    comment: '挖掘题干和选项隐藏的信息，综合判断得出答案的方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5118,
    labelName: '首尾结合',
    comment: '确定前面和后面的选项就可以得出答案的解题方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5119,
    labelName: '史实支撑',
    comment: '联系所学知识，判断得出答案的解题方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5120,
    labelName: '史论结合',
    comment: '运用辩证唯物主义和历史唯物主义的理论高度分析具体的历史现象，揭示其内在的本质和规律的过程就是“史论结合”的过程。“史”是指史料、史实，即研究历史的资料和客观存在的历史事实；“论”就是用马克思主义的立场和观点分析史料得出的结论。此方法需要结合历史学的基本原理和所学基本史实，做到史实、材料与观点、结论的统一'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5121,
    labelName: '逻辑推理',
    comment: '通过题目提供的材料信息和所学知识，推导出逻辑上不一致的地方，从而找出正确选项的解题方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5122,
    labelName: '特征分析',
    comment: '即从题干的典型信息和典型选项入手，由典型特征确定答案的解题方法。典型选项可以是几个选项都包含的，也可以是其他选项都没有提到的'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5123,
    labelName: '排除法',
    comment: '找出一个或者几个较容易确定的明显错误，通过排除错误选项，找到正确选项的方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5124,
    labelName: '优选法',
    comment: '从几个表面看来都比较合理，一时无法找出应选项的选项中，找到一个概括最全面、最贴切的选项的解题方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5125,
    labelName: '逆推法',
    comment: '又叫分析法，是从分析每一个结论的必要条件开始，步步倒推，直至说明题目给出的条件恰好符合要求为止'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5126,
    labelName: '排序法',
    comment: '排序法就是对问题中的某些元素按照一定的顺序进行排列,通过对这种顺序关系的研究,而使问题获得解决的一种思考方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5127,
    labelName: '直选法',
    comment: '依据题目所给信息，借助于已学知识进行分析和判断，直接从备选项中选择正确答案的方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5128,
    labelName: '图表分析法',
    comment: '通过分析题目提供的图、表的图像或文字信息，找出关键信息，得出正确答案的解题方法'
  },
  {
    courseId: 15,
    courseName: '高中历史',
    labelId: 5129,
    labelName: '对比法',
    comment: '所谓对比,就是在各种对象间进行观察、比较和分析,并确定它们之间异同点的过程'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5158,
    labelName: '直选',
    comment: '根据题干和选项，直接把答案准确地选择出来'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5159,
    labelName: '精确计算',
    comment: '根据题干所给变量数据，按照一定的算法算出精确的答案'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5160,
    labelName: '因果联系',
    comment: '题干、选项或答案之间有因果关系。根据它们之间的因果关系得出答案'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5161,
    labelName: '图文转换',
    comment: '将图表中的信息转换成语言文字信息，并根据所得信息然后做出答案'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5162,
    labelName: '优选',
    comment: '从符合题意的选项中选取最优答案'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5163,
    labelName: '排除',
    comment: '根据所学知识以及题干所给信息排除干扰项，最终得出答案'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5164,
    labelName: '特例反证',
    comment: '根据符合条件的特定实例来从方面验证，得出答案'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5165,
    labelName: '排序',
    comment: '根据题干要求做相应排序，得出正确答案'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5166,
    labelName: '同意相溶',
    comment: '将题干、选项之间相同意思的内容进行综合分析，得出答案'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5296,
    labelName: '原因分析',
    comment: '根据问题与答案之间的逻辑关系或因果关系，得出答案'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5297,
    labelName: '特征描述',
    comment: '根据题干要求对一定的地理现象或事物进行特征描述'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5298,
    labelName: '区域比较',
    comment: '根据不同区域的地理要素进行相应比较'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5299,
    labelName: '综合评价',
    comment: '对地理现象或事物进行综合分析、评价'
  },
  {
    courseId: 16,
    courseName: '高中地理',
    labelId: 5300,
    labelName: '措施对策',
    comment: '对地理问题提出相关措施及对策'
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5061,
    labelName: '判断比较法',
    comment: '判断观点正误，通过比较分析题肢差异解题'
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5062,
    labelName: '直接选取法',
    comment: '能够从题干和选择项中直接把答案准确地选择出来'
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5063,
    labelName: '排除法',
    comment: '要排斥与题干无关的题肢；排斥与题干意思相同，重复的题肢；排斥知识内容大于或小于题干规定性要求的题肢；排斥错误的题肢'
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5064,
    labelName: '演绎法',
    comment: '从一般性的前提出发，通过推导即“演绎”，得出具体陈述或个别结论'
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5065,
    labelName: '归纳法',
    comment: '通过对具体的、个别的或特殊的情境材料进行分析，归纳出材料所包含的基本政治、经济、文化、哲学道理'
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5066,
    labelName: '主体发散法',
    comment: '通过设问主体的指向，依据主体发散、整合教材知识，组织答案'
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5067,
    labelName: '分析法',
    comment: '把整体分解为部分,把复杂的事物分解为简单的要素分别加以研究的思维方法'
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5068,
    labelName: '重点突破法',
    comment: '根据材料中的关键词语、重点句子，调动相应的知识点解题'
  },
  {
    courseId: 17,
    courseName: '高中政治',
    labelId: 5073,
    labelName: '运用公式计算法',
    comment: '运用相关公式，计算解答'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5276,
    labelName: '判断比较法',
    comment: '判断观点正误，通过比较分析题肢差异解题'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5277,
    labelName: '直接选取法',
    comment: '能够从题干和选择项中直接把答案准确地选择出来'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5278,
    labelName: '排除法',
    comment: '要排斥与题干无关的题肢；排斥与题干意思相同，重复的题肢；排斥知识内容大于或小于题干规定性要求的题肢；排斥错误的题肢'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5279,
    labelName: '择优选择法',
    comment: '在题肢都正确的前提下，选取更符合题意的选项'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5280,
    labelName: '演绎法',
    comment: '从一般性的前提出发，通过推导即“演绎”，得出具体陈述或个别结论'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5281,
    labelName: '归纳法',
    comment: '通过对具体的、个别的或特殊的情境材料进行分析，归纳出材料所包含的基本政治、经济、文化、哲学道理'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5282,
    labelName: '代入法',
    comment: '把选项代入题干相应的地方，进行分析比较，从而得到最佳答案'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5283,
    labelName: '对比法',
    comment: '通过两个相对或相关的概念或问题的比较,找出它们的相同点或不同点,达到从本质上弄清概念或问题的认识方法'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5284,
    labelName: '主体发散法',
    comment: '通过设问主体的指向，依据主体发散、整合教材知识，组织答案'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5285,
    labelName: '分析法',
    comment: '把整体分解为部分,把复杂的事物分解为简单的要素分别加以研究的思维方法。'
  },
  {
    courseId: 19,
    courseName: '道德与法治',
    labelId: 5286,
    labelName: '情境分析法',
    comment: '在一定情境中发现和提出新问题，探索解决问题的新方法'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5301,
    labelName: '假设法',
    comment: '先把题目中的未知量假设为某已知数，然后根据题目中的已知条件推算出结果，找出结果与题中其他已知数的差距，再对假设情况做适当分析和调整，得出最终结果。如鸡兔同笼假设。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5302,
    labelName: '归纳法',
    comment: '将题目简化（如减少量的个数），在简化的基础上解题，再逐步增加难度（如增加量的个数），找出其中变化的规律进行归纳，得出最终结果。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5303,
    labelName: '构造法',
    comment: '通过构造中间桥梁（容易理解、方便观察的对象或量），来简化或具体题意，从而得出结果。如抽屉原理构造抽屉和苹果，几何中构造辅助线。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5304,
    labelName: '配对法',
    comment: '打乱题目中原有的计算或求解顺序，对容易求解的两个或多个量进行配对，先行进行计算，从而达到简化计算的目的。如计算中的交换律、分配率。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5305,
    labelName: '对应法',
    comment: '解题时找到题目中的对应关系，理清整体与局部，通过对应关系来解题。如份数（倍数、分率）与量的对应。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5306,
    labelName: '反证法',
    comment: '反证法是间接证明的一种基本方法，当我们需要证明一个判断为真时，先假设这个判断为假，经过正确的推理，最后得出矛盾，因此说明假设错误，从而证明了原判断应为真。多用于逻辑推理中。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5307,
    labelName: '逆推还原法',
    comment: '一般用于最终结果已知的情形，从结果开始逆推，一步一步得出中间结果，最后得出最开始的情形。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5308,
    labelName: '公式法',
    comment: '利用已知公式或结论直接求解。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5309,
    labelName: '代数法',
    comment: '解题时，用字母代表题中的未知数，计算过程为数和字母的混合运算，一般最终能通过各种方法消去期中的字母，得到所需要的结果。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5310,
    labelName: '分组法',
    comment: '在日常生活和生产中，有些事物的数量是按照一定的规律，一组一组有秩序地出现的。只要能看出哪些数量是同一组的，并计算出总数量中包含有多少个这样的同一组的数量，就便于计算出这一组数量中的每一种物品各是多少个。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5311,
    labelName: '扩缩法',
    comment: '一般用于计算中的估算，当无法或难以给出计算结果时，可以先将算式的一部分进行放大或缩小，变成容易计算的形式，从而得出估算结果。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5312,
    labelName: '份数法',
    comment: '多见于含有分数或比例的相关题型中，一般设最小的未知量为一份，根据分数或比例的关系依次标出其余量，最终通过对应的方法求解。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5313,
    labelName: '消去法',
    comment: '利用等式的基本性质，通过观察把列出的等式中的某个未知量用相加、相减的方式消去，从而先求出另一未知量的解题方法。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5314,
    labelName: '排除法',
    comment: '排除法的逻辑原理是：任何事物都有其对立面，在有正确与错误的多种结果中，一切错误的结果都排除了，剩余的只能是正确的结果。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5315,
    labelName: '染色赋值法',
    comment: '对研究对象进行染色或者赋予简单数值，使某些条件能够直观、形象地显露出来，使变化过程更加容易观察，从而明晰解题方向和解题思路。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5316,
    labelName: '方程法',
    comment: '能够找到等量关系的题目，通过设未知量为未知数的方式，利用等量关系列出方程，再解方程得出结果。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5317,
    labelName: '列表法',
    comment: '对有多个对象、多种数据的题目，采用列表的形式理清各对象、各数据之间的联系，从而理顺解题思路的方法。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5318,
    labelName: '图解法',
    comment: '将题意转化成图形，形象标示出题目的过程和数据，并在此基础上进行分析，从而得出解题思路和解题结果。多用线段图，如行程问题和分数应用题。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5319,
    labelName: '最值法',
    comment: '根据题意，从最大或最小的值等极端情况出发进行分析的方法。'
  },
  {
    courseId: 20,
    courseName: '小学奥数',
    labelId: 5330,
    labelName: '归一归总法',
    comment: '归一法指先求出单位数量，再以单位数量为标准，计算出所求数量的解题方法。归总法指先求出总数量，在按另一单位数量或单位数量的个数求位置数量的解题方法。'
  }]
