import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { UserDTO } from 'app/models/user';
import { getStdAvatarImgSrc } from 'app/utils/userinfo-utils';
import { TestSessionStdDTO } from 'app/models/obj-test-session';
import { TestSessionService } from '../test-session.service';

@Component({
  selector: 'app-std-info',
  template: require('./std-info.component.html'),
  styles: [require('./std-info.component.scss')]
})
export class StdInfoComponent implements OnInit, OnChanges {
  @Input() testSessionId: number;
  @Input() student: TestSessionStdDTO | UserDTO;
  @Input() hideProgress: boolean;
  @Input() duration: number;
  @Input() showSubmitStatus: boolean;
  @Input() objTest: any; // todo: 增加客观试卷的类型定义
  quesCountTotal: number;
  quesCountAnswered: number;
  completionPercent: number;


  constructor(private testSessionService: TestSessionService) { }

  ngOnInit() {
    if (this.student.uid) {
      this.loadQuesAnswerStatIfNeed()
    } else if ('deviceUUID' in this.student) {
      this.loadQuesAnswerStatIfNeedWithRemoteVoting()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  public getStdAvatarImgSrc(u: UserDTO) {
    return getStdAvatarImgSrc(u)
  }

/**
   * 正常答题未完成状态 完成进度条计算
   */
  private loadQuesAnswerStatIfNeed() {
    if (!_.isUndefined(this.hideProgress)) {
      return;
    }

    this.testSessionService.getQuesAnswerStatByUser({
      sessionId: this.testSessionId,
      userId: this.student.uid
    }).subscribe((stat) => {
      this.quesCountTotal = stat.quesCountTotal;
      this.quesCountAnswered = stat.quesCountAnswered;
      this.completionPercent = this.quesCountAnswered * 100 / this.quesCountTotal;
    }, (err) => {
      console.log('error', err)
    })
  }

  /**
   * 答题器答题时未完成状态 完成进度条计算
   */
  public loadQuesAnswerStatIfNeedWithRemoteVoting() {
    if (!_.isUndefined(this.hideProgress)) {
      return
    }
    if (!('deviceUUID' in this.student)) {
      return
    }

    const deviceId = this.student.deviceUUID
    this.testSessionService.getStudentAnsersByDevice(this.testSessionId, deviceId)
      .subscribe((result) => {
        this.quesCountTotal = _.size(this.objTest.questions[0].childQuestions)
        this.quesCountAnswered = _.size(result)
        this.completionPercent = this.quesCountAnswered * 100 / this.quesCountTotal;
      }, (err) => {
        console.log('error', err)
      })
  }

  public getDisplayName() {
    if (this.student.name) {
      return this.student.name
    }

    return _.get(this.student, 'deviceUUID', '')
  }
}
