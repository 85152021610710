import { Component, EventEmitter, Input, OnDestroy,
  OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { LessonDTO } from 'app/lesson/models/lesson';
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { RecomendCourseService } from 'app/lesson/services/recomend-course.service';
import * as _ from 'lodash';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subscription } from 'rxjs';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'app-nav-name',
  template: require('./nav-name.component.html'),
  styles: [require('./nav-name.component.scss')]
})
export class NavNameComponent implements OnInit {

  @Input() public course: LessonDTO
  @Input() public selectedCourse: LessonDTO
  @Input() public coursepathId: number
  @Input() public isNewCourse: boolean
  @Input() public isCreateCourse: boolean
  @Input() public sectionId: number
  @Output() public editRecommendCourse = new EventEmitter<LessonDTO>()
  @Output() public selectCourse = new EventEmitter<LessonDTO>()
  @Output() public deleteCourseContent = new EventEmitter<LessonDTO>()

  @ViewChild('pop') public popover: PopoverDirective

  private popoverContent: string
  private showPopover = false

  constructor(
    private lessonMessageService: LessonMessageService,
    private oedDialogService: OedDialogService,
    private stateService: StateService,
  ) {}

  public ngOnInit() {}
  public ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'isNewCourse')) {
      this.getToolTip(this.isNewCourse)
    }
    if (_.has(changes, 'isCreateCourse')) {
      this.getToolTip(this.isCreateCourse)
    }
  }

  public ngAfterViewInit() {
    this.getToolTip(this.isNewCourse)
    this.getToolTip(this.isCreateCourse)
  }

  public deleteCourse(course: LessonDTO, $event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    this.deleteCourseContent.emit({
      ...course,
    })
  }

  public editCourse(course: LessonDTO, $event: any) {
    $event.stopPropagation();
    $event.preventDefault();
    if (course.isRecommend) {
      this.editRecommendCourse.emit({
        ...course,
      })
    } else {
      this.editMineCourse()
    }
  }

  public editMineCourse() {
    this.oedDialogService.openModalWithComponent2('EditCourseComponent', {
      course: this.selectedCourse,
    }, {
        class: 'modal-sm',
      }).subscribe((newCourse) => {
        this.course = newCourse
        this.selectedCourse = newCourse
      })
  }

  private getToolTip(show: boolean) {
    if (show && (this.selectedCourse.id === this.course.id)) {
      this.showPopover = true
      // tslint:disable-next-line:max-line-length
      this.popoverContent = this.showPopover ? (this.isCreateCourse ? '已为您新建此课程' : '已为您保存为新的课程') : this.course.courseName
      this.popover.show()
      setTimeout(() => {
        this.showPopover = false
        this.popover.hide()
      }, 3000);
    }
  }
}
