import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-right-arrow-input',
  template: require('./common-right-arrow-input.component.html'),
  styles: [require('./common-right-arrow-input.component.scss')]
})
export class CommonRightArrowInputComponent implements OnInit {
  @Input() private text: string;
  @Input() private disable: boolean;
  @Output() private action = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  private onConClick = () => this.action.emit()
}
