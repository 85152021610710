import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import _ = require('lodash');

@Component({
  selector: 'app-common-title',
  template: require('./common-title.component.html'),
  styles: [require('./common-title.component.scss')]
})
export class CommonTitleComponent implements OnInit {
  @Input() private title: string;
  @Input() private showEditBtn: boolean;
  @Input() private isEditDisable: boolean;
  @Output() private doClose = new EventEmitter();
  @Output() private doEdit = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {}

  private onClose = () => {
    if (_.size(this.doClose.observers) > 0) {
      this.doClose.emit()
      return
    }
    this.bsModalRef.hide()
  }

  private onEdit = () => {
    if (_.size(this.doEdit.observers) > 0) {
      this.doEdit.emit()
    }
  }
}
