import {  animate, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: '[version-notify-component]',
  styles: [`
    :host {
      color: #31708f;
      background-color: #d9edf7;
      position: relative;
      overflow: hidden;
      margin: 0 0 6px;
      padding: 10px 10px 10px 10px;
      border-radius: 3px 3px 3px 3px;
      pointer-events: all;
      cursor: pointer;
    }
    .btn-pink {
      -webkit-backface-visibility: hidden;
      -webkit-transform: translateZ(0);
    }
  `],
  template: `
  <div class="row" style="padding: 0 20px;">
    <div class="col-9">
      <div *ngIf="message" role="alert" aria-live="polite"
        [class]="options.messageClass" [attr.aria-label]="message" style="width: 270px;">
        {{ message }}
        <button type="button" class="pull-right btn btn-xs btn-info" (click)="action($event)">
          <i class="fa fa-refresh"></i>更新
        </button>
      </div>
    </div>
  </div>
  `,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({ opacity: 0 })),
      state('active', style({ opacity: 1 })),
      state('removed', style({ opacity: 0, display: 'none' })),
      transition(
        'inactive => active',
        animate('{{ easeTime }}ms {{ easing }}')
      ),
      transition(
        'active => removed',
        animate('{{ easeTime }}ms {{ easing }}')
      )
    ])
  ],
  preserveWhitespaces: false
})
export class VersionNotify extends Toast {
  constructor(
    protected toastrService: ToastrService,
    public toastr: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    location.reload(true);
  }
}
