import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserInfoComponent } from './user-info/user-info.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { EditingModule } from 'app/editing/editing.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FullLoadingComponent } from './loading/full-loading/full-loading.component';
import { OedConfirmComponent } from './oed-confirm/oed-confirm.component'
import { OedUserInfoDirective } from './oed-user-info.component';
import { sharedState } from './shared.state';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule, ToastNoAnimationModule } from 'ngx-toastr';
import { Notify } from './notification/notify.component';
import { VersionNotify } from './notification/version-notify.component';
import { NewPrepareNotify } from './notification/new-prepare-notify.component';
import { NgNextHolderComponent } from './ng-next-holder/ng-next-holder.component';
import { TextTrimPipe } from './text-trim.pipe';
import { InplaceEditorComponent } from './inplace-editor/inplace-editor.component';
import { NewPaginationComponent } from './new-pagination/new-pagination.component';
import { FormsModule } from '@angular/forms';


const SHARED_STATES = [ sharedState ];

@NgModule({
  declarations: [
    UserInfoComponent,
    OedUserInfoDirective,
    OedConfirmComponent,
    FullLoadingComponent,
    Notify,
    VersionNotify,
    NewPrepareNotify,
    NgNextHolderComponent,
    TextTrimPipe,
    InplaceEditorComponent,
    NewPaginationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    UIRouterUpgradeModule.forChild({ states: SHARED_STATES }),
    ModalModule.forRoot(),
    ToastrModule.forRoot({
      maxOpened: 1
    }),
    EditingModule,
    ToastNoAnimationModule
  ],
  exports: [OedUserInfoDirective, OedConfirmComponent, FullLoadingComponent, InplaceEditorComponent, TextTrimPipe],
  entryComponents: [OedConfirmComponent, FullLoadingComponent, VersionNotify, Notify, NewPrepareNotify, NgNextHolderComponent, NewPaginationComponent],
})
export class SharedModule { }
