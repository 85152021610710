(function() {
  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentTaskDetails', {
        url: '/student/tasklistrecord/:recordId',
        template: require('assets/templates/student/studentTaskDetails.html'),
        controller: 'studentTaskDetailsCtrl',
        bodyStyle: 'bg_img'
      });

    }]).controller('studentTaskDetailsCtrl', studentTaskDetailsCtrl);

  studentTaskDetailsCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', 'userInfo',
    'oedCoursePath', 'oedTaskList', 'oedTask', 'oedTaskListPublishRecord', 'oedTaskListPublishRecordComment',
    'oedTaskTest', 'oedTaskPresentResource', 'oedPresentResource', 'oedObjectiveTest', 'oedSubjectiveTest',
    'oedTestSession', 'oedTestSessionStudent', 'oedUserInfoUtils', 'oedPreResSession', 'oedPreResSessionStudent']
  function studentTaskDetailsCtrl($scope, $state, $stateParams, $q, userInfo,
    oedCoursePath, oedTaskList, oedTask, oedTaskListPublishRecord, oedTaskListPublishRecordComment,
    oedTaskTest, oedTaskPresentResource, oedPresentResource, oedObjectiveTest, oedSubjectiveTest,
    oedTestSession, oedTestSessionStudent, oedUserInfoUtils, oedPreResSession, oedPreResSessionStudent) {
    $scope.currentState = {};
    $scope.userInfo = userInfo;
    $scope.userInfoService = userInfo;
    var recordId = _.parseInt($stateParams.recordId);
    $scope.presentResIdMap = {};
    $scope.testIdMap = {};
    $scope.taskIdMap = {};

    $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl;

    $scope.record = oedTaskListPublishRecord.get({
      id: recordId
    });

    /*
    保持学生任务展示资源通讯
    */
    $scope.$on('readPresentResource', function(event, args) {
      var reses = $scope.taskResources[args.taskId].allReses;
      var obj = _.find(reses, function(r) {
        return r.id == args.resId;
      });

      obj.submitted = true;
    });

    /*
     保持学生客观题状态通讯
     */
    $scope.$on('readObjectTest', function(event, args) {
      var objs = $scope.taskResources[args.taskId].allTests;

      var obj = _.find(objs, function(r) {
        return r.testId == args.objId;
      });

      obj.submitted = true;
    });

    function getTaskFirstResource(task) {
      var taskRes = $scope.taskResources[task.id];
      if (!_.isEmpty(taskRes.presentResources)) {
        return taskRes.presentResources[0];
      }

      if (!_.isEmpty(taskRes.allTests)) {
        return taskRes.allTests[0];
      }

      return null;
    }

    function gotoResourceDetails(res, task) {
      if (res.constructor == oedTaskPresentResource) {
        $state.go('.res.presentResource', {
          taskId: task.id,
          resId: res.resId
        }, {
          location: 'replace'
        });
        return;
      }

      var taskRes = $scope.taskResources[task.id];
      if (res.constructor == oedTaskTest) {
        if (_.findIndex(taskRes.objectiveTests, function(ot) {
            return ot.id === res.id;
          }) >= 0) {
          $state.go('.res.objectiveTest', {
            taskId: task.id,
            origTestId: res.testId
          }, {
            location: 'replace'
          });
          return;
        }

        $state.go('.res.subjectiveTest', {
          taskId: task.id,
          origTestId: res.testId
        }, {
          location: 'replace'
        });
      }
    }

    userInfo.then(function(uInfo) {
      $scope.userInfo = uInfo;
      $scope.record.$promise.then(function() {
        var record = $scope.record;
        var taskListId = record.taskListId;
        $scope.taskList = oedTaskList.get({
          id: taskListId
        });

        var loadingComments = oedTaskListPublishRecordComment.queryByRecordAndStudentId({
          recordId: record.id,
          studentId: uInfo.uid
        }).$promise.then(function(cmts) {
          if (_.size(cmts) > 0) {
            $scope.isReviewed = true;
          }
        });

        $scope.tasks = oedTask.queryByTaskListId({
          id: taskListId
        });

        var loadingTasks = $scope.tasks.$promise.then(loadTaskResources);

        var courseResPromise = $scope.taskList.$promise.then(function(tl) {
          var courseId = tl.courseId;
          $scope.presentResources = oedPresentResource.queryByTaskListId({
            id: taskListId
          });

          $scope.objAndSbjTests = oedObjectiveTest.queryByTaskListId({
            id: taskListId
          });

          return $q.all([$scope.presentResources.$promise,
            $scope.objAndSbjTests.$promise
          ]).then(function() {
            $scope.presentResIdMap = _.reduce($scope.presentResources, function(t, p) {
              t[p.id] = p;
              return t;
            }, {});

            function convertTestToIdMap(tests, v) {
              var testIdMap = _.reduce(tests, function(total, t) {
                total[t.id] = t;
                return total;
              }, v);
              return testIdMap;
            }
            var testIdMap = {};

            testIdMap = convertTestToIdMap($scope.objAndSbjTests, testIdMap);
            $scope.testIdMap = testIdMap;
          });
        });

        return $q.all([loadingComments, loadingTasks, courseResPromise]);
      });
    });

    $scope.getPresentResById = function(id) {
      return $scope.presentResIdMap[id];
    };

    $scope.getTestById = function(id) {
      return $scope.testIdMap[id];
    };

    function loadTaskResources(tasks) {
      if (_.isEmpty(tasks)) {
        $scope.taskIdMap = {};
        $scope.taskResources = {};
        return 0;
      }

      $scope.taskIdMap = _.reduce(tasks, function(total, t) {
        total[t.id] = t;
        return total;
      }, {});

      $scope.taskResources = {};
      var loadingTasks = _.map(tasks, function(t) {
        var objTests = oedTaskTest.queryTaskObjectiveTestByTaskId({
          id: t.id
        });

        var subjTests = oedTaskTest.queryTaskSubjectiveByTaskId({
          id: t.id
        });

        var preReses = oedTaskPresentResource.queryByTaskId({
          id: t.id
        });

        $scope.taskResources[t.id] = {
          objectiveTests: objTests,
          subjectiveTests: subjTests,
          presentResources: preReses,
          allTests: [],
          allReses: []
        };

        preReses.$promise.then(function(reses) {
          $scope.taskResources[t.id].allReses = reses;

          //查看资源是否已经阅读过
          var loadPreResStudents = _.map(reses, function(res) {
            var preResSession = oedPreResSession.getPreResSessionByTaskListRecordAndPreResId({
              recordId: recordId,
              taskId: res.taskId,
              preResId: res.resId
            });

            return preResSession.$promise.then(function(session) {
              oedPreResSessionStudent.queryByPreResSessionAndStudentId({
                sessionId: session.id,
                studentId: $scope.userInfo.uid
              }).$promise.then(function(session) {
                res.submitted = true;
              }).catch(function(err) {
                res.submitted = false;
              });
            });
          });

          return $q.all(loadPreResStudents);
        });

        var p = $q.all([objTests.$promise, subjTests.$promise]).then(function(ot, st) {
          var allTests = objTests.concat(subjTests);
          allTests.sort(function(a, b) {
            return a.sort - b.sort;
          });

          $scope.taskResources[t.id].allTests = allTests;
          return allTests;
        }).then(function(tests) {
          //查看客观题是否已经提交
          var loadTestStudents = _.map(tests, function(test) {
            var testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
              recordId: recordId,
              taskId: test.taskId,
              origTestId: test.testId
            });

            return testSession.$promise.then(function(session) {
              oedTestSessionStudent.queryByTestSessionAndStudentId({
                sessionId: session.id,
                studentId: $scope.userInfo.uid
              }).$promise.then(function(session) {
                test.submitted = true;
              }).catch(function(err) {
                if (err == 404) {
                  test.submitted = false;
                }
              });
            });
          });

          return $q.all(loadTestStudents);
        });

        return $q.all([p, preReses.$promise]);
      });

      return $q.all(loadingTasks).then(function() {
        //定义getName方法在这,防止API没返回以前调用报错
        $scope.getName = function(id) {
          var name = $scope.getTaskById(id).name;
          return name.length >= 6 ? name.substring(6) : name;
        };

        var taskId = _.parseInt($state.params.taskId);
        if (!taskId && $state.current.name != 'studentTaskDetails.comments') {
          var task = _.find(tasks, function(t) {
            return !!getTaskFirstResource(t);
          });
          if (task) {
            var res = getTaskFirstResource(task);
            gotoResourceDetails(res, task);
          }
        }
      });
    }

    $scope.getPresentResById = function(id) {
      return $scope.presentResIdMap[id];
    };

    $scope.getTestById = function(id) {
      return $scope.testIdMap[id];
    };

    $scope.getTaskById = function(id) {
      return $scope.taskIdMap[id];
    };
  }
})();
