(function() {
  'use strict';
  angular.module('app.homework')
    .controller('notifyUnpublishedTaskCtrl', notifyUnpublishedTaskCtrl);

  notifyUnpublishedTaskCtrl.$inject = ['$scope', '$uibModalInstance']
  function notifyUnpublishedTaskCtrl($scope, $uibModalInstance) {

    $scope.continue = function() {
      $uibModalInstance.close('continue');
    };

    $scope.delete = function() {
      $uibModalInstance.close('delete');
    };

    $scope.cancel = function(result) {
      $uibModalInstance.close(result);
    };
  }
})();
