var messages = require('app2/utils/messages');
(function() {
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.manageArea', {
        url: '/manageArea',
        template: require('assets/templates/manage/manageArea.html'),
        controller: 'manageAreaCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('manageAreaCtrl', manageAreaCtrl)
    .controller('createAreaCtrl', createAreaCtrl)
    .controller('includeSchoolCtrl', includeSchoolCtrl);


  manageAreaCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$http', '$uibModal',
    'dialogs', 'notificationService', 'oedConfig', 'oedAreaManagement', 'localStorageService']
  function manageAreaCtrl($scope, $state, $stateParams, $q, $http, $uibModal,
                            dialogs, notificationService, oedConfig, oedAreaManagement, localStorageService) {
    $scope.currentState.title = '区域列表';

    $scope.pageSize = 15;
    $scope.curPage = 1;
    $scope.areas = [];
    $scope.query = {name: ''};
    $scope.showAreaMsg = false;
    function loadAreas() {
      oedAreaManagement.get({keyWords: $scope.query.name ? $scope.query.name : -1}).$promise.then(cou => {
        $scope.totalNums = cou.count;
        oedAreaManagement.getAreaList({
          keyWords: $scope.query.name ? $scope.query.name : -1,
          start: ($scope.curPage - 1) * $scope.pageSize,
          count: $scope.pageSize
        }).$promise.then(res => {
          $scope.areas = [];
          var arr = _.orderBy(res, ['id'], ['asc']);
          _.forEach(arr, item => {
            oedAreaManagement.getAreaAdminByAreaId({id: item.id}).$promise.then(ids => {
              let idStr = '';
              _.forEach(ids, i => {
                idStr += i.uid + ',';
              });
              idStr = idStr.substr(0, idStr.length - 1);
              item.areaIds = idStr;
              $scope.areas.push(item);
              $scope.areas = _.orderBy($scope.areas, ['id'], ['asc'])
            });
          })
        });
      });
    }
    loadAreas();

    $scope.createNewArea = function() {
      const cad = $uibModal.open({
        template: require('assets/templates/manage/createAreaDialog.html'),
        controller: 'createAreaCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          area: function () {
            return null;
          }
        }
      });
      cad.result.then(function(res) {
        if (res.message === 'create') {
          notificationService.notify('info', '创建区域权限成功')
          localStorageService.set('selectSchool', null);
          loadAreas();
        }
      });
    };

    $scope.doSearch = function(arg) {
      $scope.curPage = 1;
      $scope.showAreaMsg = true;
      loadAreas();
    };

    $scope.onPageChange = function(query, page) {
      $scope.curPage = page;
      loadAreas();
    };

    $scope.editAreaInfo = function(area) {
      const eai = $uibModal.open({
        template: require('assets/templates/manage/createAreaDialog.html'),
        controller: 'createAreaCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          area: function() {
            return area;
          }
        }
      });
      eai.result.then(function(res) {
        if (res.message === 'edit') {
          notificationService.notify('info', '修改区域信息成功')
          localStorageService.set('selectSchool', null);
          loadAreas();
        }
      });
    };

    $scope.remove = function(area) {
      var dlg = dialogs.confirm('删除区域', '你确定要删除此区域吗？');
      dlg.result.then(function() {
        oedAreaManagement.deleteArea({id: area.id}).$promise.then(res => {
          notificationService.notify('info', '删除区域成功')
          loadAreas();
        })
      });
    };
  }

  createAreaCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$http', '$uibModal',
    'dialogs', 'notificationService', 'oedConfig', 'oedSchool', 'oedCity', '$uibModalInstance',
    'oedAreaManagement', 'localStorageService', 'userInfo', 'area']
  function createAreaCtrl($scope, $state, $stateParams, $q, $http, $uibModal,
                          dialogs, notificationService, oedConfig, oedSchool, oedCity, $uibModalInstance, oedAreaManagement, localStorageService, userInfo, area) {
    $scope.area = area;
    $scope.areaName = area? area.name : '';
    $scope.user = {
      userIds: []
    };
    if (!area) {
      $scope.modalTitle = '创建区域权限';
      $scope.btnText = '创建权限';
      // 创建区域时默认不打开资源展示
      $scope.areaStatus = 0;
    } else {
      $scope.modalTitle = '更新区域信息';
      $scope.btnText = '更新';
      $scope.areaStatus = _.get($scope.area, 'status', 0);
      var idArrs = area.areaIds.split(',');
      _.forEach(idArrs, item => {
        $scope.user.userIds.push({uid: item});
      });
      oedAreaManagement.getAreaSchoolByAreaId({id: area.id}).$promise.then(sel => {
        $scope.selectedSchools = new Set();
        _.forEach(sel, item => {
          $scope.selectedSchools.add(item.schoolId);
        });
      });
    }
    $scope.region = {};
    $scope.provinces = oedCity.queryAll();

    var t;
    $scope.searchUsers = function(e) {
      clearTimeout(t);
      t = setTimeout(function () {
        $scope.userList = [];
        oedAreaManagement.getUserList({
          name: e.target.value,
          start: 0,
          count: 100
        }).$promise.then(res => {
          _.forEach(res, item => {
            if (!_.includes(_.map($scope.user.userIds, 'uid').join(','), item.uid)) {
              $scope.userList.push(item);
            }
          });
        })
      }, 1000)
    };
    $scope.resetAreaName = function(e){
      $scope.showValidationError = false;
      if (e.target.value.length > 30) {
        e.target.value = e.target.value.substr(0,30);
      }
      if(e.target.value.length === 0) {
        $scope.isUserIdNull=false;
      }
    };

    $scope.resetAreaIds = function (selectItem) {
      $scope.user.userIds.push(selectItem);
      document.getElementById('selectVal').childNodes[0].lastChild.value = '';
      $scope.userList = [];
      if ($scope.user.userIds.length) {
        $scope.isUserIdNull = false;
      }
      if ($scope.user.userIds.length && ($scope.areaName !== '')) {
        $scope.showValidationError = false;
      } else {
        $scope.showValidationError = true;
      }
    };
    $scope.provinces.$promise.then(function(provinces) {
      if (area) {
        _.forEach(provinces, (item, index) => {
          if (item.id === area.province) {
            $scope.region.province = provinces[index];
            $scope.provinceSelected(provinces[index]);
          }
        });
      } else {
        $scope.region.province = provinces[0];
        $scope.provinceSelected(provinces[0]);
      }
    });

    $scope.provinceSelected = function(item, model) {
      $scope.region.city = null;
      $scope.cities = oedCity.querySubCities({
        id: item.id
      });
      if (model) {
        $scope.areaChange = true;
        $scope.selectedSchools = new Set();
      }
      $scope.cities.$promise.then(function(cities) {
        if (cities.length > 0) {
          if (area && (area.province === item.id)) {
            oedCity.queryCityByDistrict({
              id: area.zone
            }).$promise.then(res => {
              _.forEach(cities, (item, index) => {
                if (res.id === item.id) {
                  $scope.region.city = cities[index];
                  $scope.citySelected(cities[index]);
                }
              });
            });
          } else {
            $scope.region.city = cities[0];
            $scope.citySelected(cities[0]);
          }
        } else {
          $scope.cities = [];
          $scope.districts = [];
          $scope.region.city = {id: null, name: '全部'};
          $scope.region.district = {id: null, name: '全部'};
        }
      });
    };

    $scope.citySelected = function(item, model) {
      $scope.region.district = null;
      $scope.districts = oedCity.querySubCities({
        id: item.id
      });
      if (model) {
        $scope.selectedSchools = new Set();
      }
      $scope.districts.$promise.then(function(districts) {
        if (districts.length > 0) {
          if (area && !$scope.areaChange && !model) {
            _.forEach(districts, (item, index) => {
              if (area.zone === item.id) {
                $scope.region.district = districts[index];
              }
            });
          } else {
            $scope.region.district = districts[0];
          }
        }
      });
    };

    $scope.districtSelected = function(item, model) {
      if (model) {
        $scope.selectedSchools = new Set();
      }
    };

    $scope.setIncludeSchool = function(){
      var data;
      if (area) {
        area.region = $scope.region;
        data = area;
      } else {
        var obj = {region: $scope.region};
        data = obj;
      }
      localStorageService.set('selectRegion', $scope.region);
      var sis = $uibModal.open({
        template: require('assets/templates/manage/includeSchool.html'),
        controller: 'includeSchoolCtrl',
        size: 'lg',
        windowClass: 'signupModal signupModal2',
        resolve: {
          data: function () {
            return data;
          },
          selectedSchools: function () {
            return null;
          }
        }
      });
      sis.result.then(function(res) {
        $scope.selectedSchools = res.ss;
        $scope.region = localStorageService.get('selectRegion');
      });
    };

    $scope.cancel = function(result) {
      $uibModalInstance.close({message: 'close'});
      localStorageService.set('selectSchool', null);
    };
    $scope.ok = function() {
      if ($scope.editInfoForm.$invalid) {
        $scope.showValidationError = true;
        return;
      } else {
        $scope.showValidationError = false;
      }
      if (!_.size($scope.user.userIds)) {
        $scope.showValidationError = true;
        $scope.isUserIdNull = true;
        return;
      } else {
        $scope.isUserIdNull = false;
      }
      var s = JSON.stringify($scope.selectedSchools);
      var selectStr = _.size($scope.selectedSchools) > 0 ? s.substr(1, s.length - 2) : null;
      var userIds = '';
      _.forEach($scope.user.userIds, item => {
        userIds += item.uid + ',';
      });
      if (area) {
        oedAreaManagement.updateArea({
          id: area.id,
          name: $scope.areaName,
          province: $scope.region.province.id,
          zone: $scope.region.district.id,
          userId: userIds.substring(0, userIds.length - 1),
          schoolId: selectStr,
          status: _.parseInt($scope.areaStatus)
        }).$promise.then(res => {
          $uibModalInstance.close({message: 'edit'});
        }, error => {
          if (_.includes(error.data.message, 'area/areaNameExist')) {
            $scope.existArea = true;
          } else {
            $scope.existArea = false;
          }
        });
      } else {
        oedAreaManagement.createArea({
          name: $scope.areaName,
          province: $scope.region.province.id,
          zone: $scope.region.district.id,
          userId: userIds.substring(0, userIds.length - 1),
          schoolId: selectStr,
          status: _.parseInt($scope.areaStatus)
        }).$promise.then(res => {
          $uibModalInstance.close({message: 'create', resArea: res});
        }, error => {
          if (_.includes(error.data.message, 'area/areaNameExist')) {
            $scope.existArea = true;
          } else {
            $scope.existArea = false;
          }
        });
      }
    };
  }

  includeSchoolCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$http', '$uibModal',
    'dialogs', 'notificationService', 'oedConfig', 'oedSchool', 'oedCity', '$uibModalInstance',
    'data', 'selectedSchools', 'oedAreaManagement', 'localStorageService']
  function includeSchoolCtrl($scope, $state, $stateParams, $q, $http, $uibModal,
                          dialogs, notificationService, oedConfig, oedSchool, oedCity, $uibModalInstance, data, selectedSchools, oedAreaManagement, localStorageService) {
    $scope.curPage = 1;
    $scope.pageSize = 10;
    $scope.schoolTypes = ['小学', '中学', '初中', '高中', '中小学', '中职', '其他'];
    $scope.schoolInfo = {schoolType: $scope.schoolTypes[0]};
    $scope.selectedSchools = new Set();
    $scope.selectedSchoolInfo = [];
    var selectSchool = localStorageService.get('selectSchool');
    $scope.getCurPageSelect = function(){
      $scope.selectNum = 0;
      _.forEach($scope.schools, item => {
        _.forEach(Array.from($scope.selectedSchools), i => {
          if (item.id === i) {
            $scope.selectNum++;
          }
        })
      });
    };
    if (selectSchool) {
      $scope.selectedSchools = new Set(selectSchool.selectedSchools);
      $scope.selectedSchoolInfo = selectSchool.selectedSchoolInfo;
      $scope.getCurPageSelect();
    }
    if (data !== null) {
      $scope.modalTitle = '配置包含学校';
      $scope.region = data.region;
      if (data.id) {
        if (!selectSchool) {
          oedAreaManagement.getAreaSchoolByAreaId({id: data.id}).$promise.then(sel => {
            _.forEach(sel, s => {
              if (s.schoolId !== null) {
                s.id = s.schoolId;
                $scope.selectedSchools.add(s.schoolId);
                $scope.selectedSchoolInfo.push(s);
              }
            });
            $scope.getCurPageSelect();
          });
        }
      }

      oedCity.queryAll().$promise.then(res => {
        $scope.provinces = res;
        $scope.provinces.unshift({id: null, name: '全部'});
      });
      oedCity.querySubCities({
        id: $scope.region.province.id
      }).$promise.then(res => {
        $scope.cities = res;
        $scope.cities.unshift({id: null, name: '全部'});
      });
      oedCity.querySubCities({
        id: $scope.region.city.id
      }).$promise.then(res => {
        $scope.districts = res;
        $scope.districts.unshift({id: null, name: '全部'});
      });

      $scope.provinceSelected = function(item, model) {
        if (!$scope.region.province.id) {
          $scope.cities = [];
          $scope.districts = [];
          $scope.region.province = {id: null, name: '全部'};
          $scope.region.city = {id: null, name: '全部'};
          $scope.region.district = {id: null, name: '全部'};
          $scope.getSchoolList();
          return;
        }
        $scope.region.city = null;
        oedCity.querySubCities({
          id: $scope.region.province.id
        }).$promise.then(res => {
          $scope.cities = res;
          $scope.cities.unshift({id: null, name: '全部'});
          $scope.region.city = $scope.cities[0];
          $scope.citySelected($scope.cities[0]);
        });
      };

      $scope.citySelected = function(item, model) {
        if (!$scope.region.city.id) {
          $scope.districts = [];
          $scope.region.city = {id: null, name: '全部'};
          $scope.region.district = {id: null, name: '全部'};
          $scope.getSchoolList();
          return;
        }
        $scope.region.district = null;
        oedCity.querySubCities({
          id: $scope.region.city.id
        }).$promise.then(res => {
          $scope.districts = res;
          $scope.districts.unshift({id: null, name: '全部'});
          $scope.region.district = $scope.districts[0];
          $scope.getSchoolList();
        });
      };

      $scope.districtSelected = function(item, model) {
        if (!$scope.region.district.id) {
          $scope.region.district = {id: null, name: '全部'};
          $scope.getSchoolList();
          $scope.getSchoolList();
          return;
        }
        $scope.getSchoolList();
      };

      $scope.selectSchoolType = function () {
        $scope.getSchoolList();
      };

      $scope.allTypes = function() {
        $scope.schoolInfo.schoolType = null;
        $scope.getSchoolList();
      };

      $scope.doSearch = function () {
        $scope.getSchoolList();
      };
    } else {
      $scope.modalTitle = '查看已选学校';
    }

    $scope.query = {name: ''};

    $scope.getSchoolList = function() {
      $scope.schools = [];
      var obj = {
        name: $scope.query.name,
        province: $scope.region.province.id,
        zone: $scope.region.district.id,
        schoolType: $scope.schoolInfo.schoolType,
        curPage: $scope.curPage,
        countPerPage: $scope.pageSize,
        allSchool: true,
      };
      oedAreaManagement.getSchoolCount(obj).$promise.then(cou => {
        $scope.totalNums = cou.count;
        oedAreaManagement.getSchoolList(obj).$promise.then(res => {
          $scope.schools = res;
          $scope.getCurPageSelect();
        });
      });
    };

    $scope.onPageChange = function(page) {
      $scope.curPage = page;
      if(data !== null){
        $scope.getSchoolList();
      }else {
        $scope.schools = angular.copy(selectedSchools).slice(($scope.curPage - 1) * $scope.pageSize, $scope.curPage * $scope.pageSize);
        $scope.getCurPageSelect();
      }
    };
    if (selectedSchools !== null) {
      $scope.selectedSchoolInfo = [];
      _.each(selectedSchools, s => s.id = s.id ? s.id : (s.schoolId ? s.schoolId : 0));
      $scope.schools = angular.copy(selectedSchools).slice(0, 10);
      $scope.totalNums = _.size(selectedSchools);
      _.each(selectedSchools, school => {$scope.selectedSchools.add(school.id);$scope.selectedSchoolInfo.push(school);});
      $scope.getCurPageSelect();
    } else {
      $scope.getSchoolList();
    }

    $scope.toggleSelectSchool = function(school) {
      if ($scope.selectedSchools.has(school.id)) {
        $scope.selectedSchools.delete(school.id);
        _.forEach($scope.selectedSchoolInfo, (item, index) => {
          if (item && (item.id === school.id)) {
            $scope.selectedSchoolInfo.splice(index, 1);
          }
        });
      } else {
        $scope.selectedSchools.add(school.id);
        $scope.selectedSchoolInfo.push(school);
      }
      $scope.getCurPageSelect();
    };
    $scope.toggleSelectAllSchools = function() {
      const allSchoolCount = _.size($scope.schools);
      if ($scope.selectNum === allSchoolCount) {
        // 已经选择全部，则全不选
        _.forEach($scope.selectedSchoolInfo, (item, index) => {
          _.forEach($scope.schools, s => {
            if (item.id === s.id) {
              $scope.selectedSchools.delete(item.id);
            }
          });
        });
      } else {
        // 部分选择或全不选，则全选
        _.each($scope.schools, school => {
          if (!$scope.selectedSchools.has(school.id)) {
            if (!$scope.selectedSchoolInfo) {
              $scope.selectedSchoolInfo = [];
            }
            $scope.selectedSchoolInfo.push(school);
            $scope.selectedSchools.add(school.id);
          }
        });
      }
      $scope.getCurPageSelect();
    };

    $scope.lookIncludeSchool = function(){
      var selects = [];
      if ($scope.selectedSchools.size) {
        _.flatMap(Array.from($scope.selectedSchools), item => {
          var obj = _.filter($scope.selectedSchoolInfo, function(i) {
            return i.id === item;
          });
          selects.push(obj[0]);
        });
      }
      var lookSchool = $uibModal.open({
        template: require('assets/templates/manage/includeSchool.html'),
        controller: 'includeSchoolCtrl',
        size: 'lg',
        windowClass: 'signupModal',
        resolve: {
          data: function () {
            return null;
          },
          selectedSchools: function () {
            return _.uniq(selects);
          }
        }
      });
      lookSchool.result.then(function (data) {
        if (data.ss !== null) {
          $scope.selectedSchools = data.ss;
          $scope.getCurPageSelect();
        }
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.close({ss: null});
      localStorageService.set('selectSchool', null);
    };
    $scope.ok = function() {
      if(data === null){
        $uibModalInstance.close({ss: $scope.selectedSchools});
        // localStorageService.set('selectSchool', null);
        localStorageService.set('selectSchool', {selectedSchools: $scope.selectedSchools});
      } else {
        localStorageService.set('selectSchool', {selectedSchools: $scope.selectedSchools, selectedSchoolInfo: $scope.selectedSchoolInfo});
        // $uibModalInstance.close({ss: $scope.selectedSchools, schoolType: {schoolType: $scope.schoolInfo.schoolType, areaId: data.id}});
        $uibModalInstance.close({ss: $scope.selectedSchools});
      }
    };
  }
})();
