import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash'
import { UiTreeComponent } from 'app/editing/ui-tree/ui-tree.component';

const cleanUpThreshold = 5000; // 下拉菜单关闭5秒后清理jsTree 的dom

@Component({
  selector: 'app-section-select-dropdown',
  template: require('./section-select-dropdown.component.html'),
  styles: [require('./section-select-dropdown.component.scss')]
})
export class SectionSelectDropdownComponent implements OnInit, OnChanges {
  @Input() treeData
  @Input() selectedSections

  @Output() selectionChanged = new EventEmitter<number[]>()

  @ViewChild('treeInstance') tree: UiTreeComponent

  showTree = false
  cleanupTimer

  treeConfig = {
    core: {
      animation: true,
      check_callback: true,
      error: (error: any) => {
        console.error('treeCtrl: error from js tree - ',  error)
      },
      multiple: true,
      worker: true,
    },
    plugins: ['types', 'checkbox'],
    types: {
      default: {
        icon: 'fa fa-book',
      },
      root: {
        icon: 'glyphicon glyphicon-cloud',
      },
    },
    version: 1,
  }

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'selectedSections')) {
      //
      // TODO: update
    }
  }

  onTreeChange() {
    const selectedNodes = _.map(this.tree.getSelected(), _.parseInt)
    const curIds = _.map(this.selectedSections, (sec: any) => {
      return _.parseInt(sec.sectionId);
    });

    if (_(curIds).xor(selectedNodes).isEmpty()) {
      return;
    }

    this.selectionChanged.emit(selectedNodes)
  }

  onTreeReady() {
    if (this.selectedSections) {
      this.updateSelection()
    }
  }

  updateSelection() {
    const selectedNodes = _.map(this.tree.getSelected(), _.parseInt);
    const curIds = _.map(this.selectedSections, (sec: any) => {
      return _.parseInt(sec.sectionId);
    });

    if (_(curIds).xor(selectedNodes).isEmpty()) {
      return;
    }
    this.tree.selectNodes(curIds)
  }

  onShowSections = (open: boolean) => {
    clearTimeout(this.cleanupTimer)
    this.showTree = true
    if (!open) {
      this.cleanupTimer = setTimeout(() => {
        this.showTree = false
        this.cleanupTimer = null
      }, cleanUpThreshold);
    }
  }
}
