import * as angular from 'angular';
import * as _ from 'lodash'

angular.module('app.directives.board.boardQuestionPreviewer', [])
.component('boardQuestionPreviewer', {
  bindings: {
    testId: '=',
    boardSession: '<',
  },
  controller: boardQuestionPreviewerCtrl,
  template: <string> require('./boardQuestionPreviewer.html'),
});

boardQuestionPreviewerCtrl.$inject = ['oedSubjectiveTest', '$sce', 'oedCloudSubjectiveTest']
function boardQuestionPreviewerCtrl(oedSubjectiveTest: any, $sce: any, oedCloudSubjectiveTest: any) {
  const ctrl = this;

  ctrl.parsingNoorOff = false;
  this.$onInit = () => {
    ctrl.sbjTest = oedSubjectiveTest.getSubjTestWithPositionDetails({
      id: ctrl.testId,
    });
    ctrl.loading = ctrl.sbjTest.$promise
    ctrl.parsingSwitch = () => {
      ctrl.parsingNoorOff = !ctrl.parsingNoorOff;
    }
    ctrl.loading.then((res: any) => {
      res.question.question = $sce.trustAsHtml(res.question.question);
      res.question.answerDetails = $sce.trustAsHtml(res.question.answerDetails);
    }).then(() => {
      return oedCloudSubjectiveTest.queryBySubjectiveTestId({
        id: ctrl.sbjTest.origId ? ctrl.sbjTest.origId : ctrl.sbjTest.id
      }).$promise.then((cloudTest) => {
        if (_.get(cloudTest, 'id', 0) > 0 && !ctrl.sbjTest.isTemp) {
          ctrl.sbjTest.$cloudTest = cloudTest
        }
        return ctrl.sbjTest
      })
    });
  }

  this.isTempSession = () => {
    return _.get(this.sbjTest, 'isTemp', false)
  }
}
