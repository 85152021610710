import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {OedDialogService} from 'app/core/oed-dialog.service';
import {TrainDirEditorComponent} from 'app/online-train/train-design/train-dir-editor/train-dir-editor.component';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import {TrainContentEditorComponentComponent} from 'app/online-train/train-design/train-content-editor-component/train-content-editor-component.component';
import {TrainContentLinkEditorComponent} from 'app/online-train/train-design/train-content-link-editor/train-content-link-editor.component';
import {TrainQAEditorComponent} from 'app/online-train/train-design/train-qaeditor/train-qaeditor.component';

@Component({
  selector: 'app-train-editor',
  template: require('./train-editor.component.html'),
  styles: [require('./train-editor.component.scss')]
})
export class TrainEditorComponent implements OnInit {

  @Input() public itemData
  @Input() public itemType
  @Input() public allDirs
  @Input() public showUp
  @Input() public showDown
  @Output() public onEdit = new EventEmitter<any>()
  private showAdd
  private showUpdate
  private showDelete

  private addText = '新增'

  constructor(private oedDialogService: OedDialogService,
              private onlineTrainService: OnlineTrainService) { }

  ngOnInit() {
    if (!_.has(this.itemData, 'id')) {
      this.showAdd = true
      this.showUpdate = false
      this.showDelete = false
    } else {
      this.showAdd = false
      this.showUpdate = true
      this.showDelete = true

      if (this.itemType === this.onlineTrainService.CONTENTLINK) {
        this.showUpdate = false
      }
    }

    if (this.itemType === this.onlineTrainService.CONTENT1
        || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO) {
      this.addText = '新增视频'
    } else if (this.itemType === this.onlineTrainService.CONTENT2
                || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_OTHER) {
      this.addText = '新增图文'
    } else if (this.itemType === this.onlineTrainService.CONTENTLINK) {
      this.addText = '新增相关链接'
    } else if (this.itemType === this.onlineTrainService.QA) {
      this.addText = '新增问答'
    }
  }

  public addItemData() {
    //
    if (this.itemType === this.onlineTrainService.DIR) {
      this.oedDialogService.openModalWithComponent2('TrainDirEditorComponent', {
        itemData: this.itemData,
        itemType: this.onlineTrainService.DIR,
        editType: this.onlineTrainService.ADD
      }, {
        class: 'modal-sm',
      }).subscribe((data) => {
        this.onEdit.emit({editType: this.onlineTrainService.ADD, item: data})
      })
    } else if (this.itemType === this.onlineTrainService.CONTENT1
                || this.itemType === this.onlineTrainService.CONTENT2
                || this.itemType === this.onlineTrainService.CONTENTQA
                || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO
                || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_OTHER) {
      this.oedDialogService.openModalWithComponent2('TrainContentEditorComponentComponent', {
        itemData: this.itemData,
        itemType: this.itemType,
        editType: this.onlineTrainService.ADD
      }, {
        class: 'modal-sm',
      }).subscribe((data) => {
        this.onEdit.emit({editType: this.onlineTrainService.ADD, item: data})
      })
    } else if (this.itemType === this.onlineTrainService.CONTENTLINK) {
      this.oedDialogService.openModalWithComponent2('TrainContentLinkEditorComponent', {
        itemData: this.itemData,
        itemType: this.itemType,
        editType: this.onlineTrainService.ADD,
        allDirs: this.allDirs
      }, {
        class: 'modal-sm',
      }).subscribe((data) => {
        this.onEdit.emit({editType: this.onlineTrainService.ADD, linkDirIds: data})
      })
    } else if (this.itemType === this.onlineTrainService.QA) {
      this.oedDialogService.openModalWithComponent2('TrainQAEditorComponent', {
        itemData: this.itemData,
        itemType: this.itemType,
        editType: this.onlineTrainService.ADD,
        allDirs: this.allDirs
      }, {
        class: 'modal-sm',
      }).subscribe((data) => {
        this.onEdit.emit({editType: this.onlineTrainService.ADD, item: data})
      })
    } else if (this.itemType === this.onlineTrainService.GOOD_COURSE) {
      this.onEdit.emit({editType: this.onlineTrainService.ADD, item: this.itemData})
    }
  }

  public updateItemData() {
    //
    if (this.itemType === this.onlineTrainService.DIR) {
      this.oedDialogService.openModalWithComponent2('TrainDirEditorComponent', {
        itemData: this.itemData,
        itemType: this.onlineTrainService.DIR,
        editType: this.onlineTrainService.ADD,
      }, {
        class: 'modal-sm',
      }).subscribe((data) => {
        this.onEdit.emit({editType: this.onlineTrainService.UPDATE, item: data})
      })
    } else if (this.itemType === this.onlineTrainService.CONTENT1
              || this.itemType === this.onlineTrainService.CONTENT2
              || this.itemType === this.onlineTrainService.CONTENTQA
              || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO
              || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_OTHER) {
      this.oedDialogService.openModalWithComponent2('TrainContentEditorComponentComponent', {
        itemData: this.itemData,
        itemType: this.itemType,
        editType: this.onlineTrainService.UPDATE
      }, {
        class: 'modal-sm',
      }).subscribe((data) => {
        this.onEdit.emit({editType: this.onlineTrainService.UPDATE, item: data})
      })
    } else if (this.itemType === this.onlineTrainService.QA) {
      this.oedDialogService.openModalWithComponent2('TrainQAEditorComponent', {
        itemData: this.itemData,
        itemType: this.itemType,
        editType: this.onlineTrainService.ADD,
        allDirs: this.allDirs
      }, {
        class: 'modal-sm',
      }).subscribe((data) => {
        this.onEdit.emit({editType: this.onlineTrainService.UPDATE, item: data})
      })
    } else if (this.itemType === this.onlineTrainService.GOOD_COURSE) {
      this.onEdit.emit({editType: this.onlineTrainService.UPDATE, item: this.itemData})
    }
  }

  public deleteItemData() {
    //
    if (this.itemType === this.onlineTrainService.DIR
        || this.itemType === this.onlineTrainService.CONTENT1
        || this.itemType === this.onlineTrainService.CONTENT2
        || this.itemType === this.onlineTrainService.CONTENTLINK
        || this.itemType === this.onlineTrainService.QA
        || this.itemType === this.onlineTrainService.CONTENTQA
        || this.itemType === this.onlineTrainService.GOOD_COURSE
        || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO
        || this.itemType === this.onlineTrainService.CONTENT_GOOD_COURSE_OTHER
    ) {
      this.onEdit.emit({editType: this.onlineTrainService.DELETE, item: this.itemData})
    }
  }

  public upItem() {
    this.onEdit.emit({editType: this.onlineTrainService.UP, item: this.itemData})
  }

  public downItem() {
    this.onEdit.emit({editType: this.onlineTrainService.DOWN, item: this.itemData})
  }
}
