
import * as _ from 'lodash'

angular.module('app.prepareCourse2.cloud.presentResource.talArea.talAreaMaterialDialog', [
])
.controller('oedTalAreaMaterialDialogCtrl', oedTalAreaMaterialDialogCtrl)

oedTalAreaMaterialDialogCtrl.$inject = ['$uibModalInstance', 'data', '$scope']
function oedTalAreaMaterialDialogCtrl($uibModalInstance: any, data: any, $scope: any) {

  $scope.versions = data.versions
  $scope.selectedVersion = data.selectedVersion
  $scope.selectedBook = data.selectedBook
  $scope.getBookFuc = data.getBookFuc

  $scope.onSelectVersion = (l) => {
    $scope.tempSelectedVersion = l
    $scope.curTabIdx = _.findIndex($scope.versions, l);
    $scope.getBookFuc(l.id).then((s) => {
      $scope.books = s
    })
  }

  const init = () => {
    $scope.onSelectVersion($scope.selectedVersion)
  }

  $scope.isBookSelected = (s) => {
    return _.get($scope.selectedBook, 'id') === _.get(s, 'id')
    && _.get($scope.tempSelectedVersion, 'id') === _.get($scope.selectedVersion, 'id')
  }

  init();

  $scope.onMaterialSelected = (l, s) => {
    $uibModalInstance.close({
      selectedVersion: l,
      selectedBook: s,
    })
  }
  $scope.onClose = () => $uibModalInstance.dismiss('cancel')
}
