import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'app-exam-summary'
})
export class OEdexamSummary extends UpgradeComponent {
  @Input() public summary: any;
  @Input() public related: any;
  @Input() public totalScore: any;
  @Input() public parts: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('examSummary', elementRef, injector);
  }
}
