import * as angular from 'angular';
import bluebird = require('bluebird')
import _ = require('lodash');

export const choiceCategory = {0: 'A', 1: 'B', 2: 'C', 3: 'D', 4: 'E', 5: 'F', 6: 'G', 7: 'H', 8: 'I', 9: 'J'}

export const yesornoCategory = {0: '是', 1: '否'}

export const defaultOption = {
  singlechoice: {
    questionNm: 10,
    optionNm: 4,
    scoreNm: 1,
    type: 'singlechoice'
  },
  yesorno: {
    questionNm: 10,
    optionNm: 2,
    scoreNm: 1,
    type: 'yesorno'
  }
}

export const defaultChildQuestionList = [{
  answer: '0',
  answerDetails: null,
  choices: [{}, {}, {}, {}],
  leftOpts: [],
  needPic: false,
  point: 1,
  point2: 1,
  question: '问题:1',
  rightOpts: [],
  type: 'singlechoice'
}]

/**
 * 快速备课 Test 条件
 * @param objectiveTest
 */
export function isQuickWayToCreatePrepareCourse(objectiveTest) {
  return objectiveTest && objectiveTest.questions &&
         objectiveTest.questions.length === 1 &&
         objectiveTest.questions[0]['type'] === 'synthetic' &&
         objectiveTest.questions[0]['resourceUUIDs'] &&
         objectiveTest.questions[0]['resourceUUIDs'].length > 0
}
