import * as _ from 'lodash'
require('assets/app/controllers/examination/examCardList/examCard/examCard.scss');
require('assets/app/controllers/examination/examReview/examReviewCtrl');
require('assets/app/controllers/examination/examReport/examReportCtrl');

angular.module('app.examination.examCardList.examCard', [
  'app.examination.examReview',
  'app.examination.examReport',
])
  .component('examCard', {
    bindings: {
      cardData: '<',
      selectedSection: '<',
      sectionRootId: '<',
      onDeleteExam: '&',
    },
    controller: examCardCtrl,
    template: <string>require('./examCard.html'),
  })

examCardCtrl.$inject = ['$state']
function examCardCtrl($state) {
  const ctrl = this

  ctrl.$onChanges = (changeObj: any) => {
    if (_.has(changeObj, 'cardData') && !_.isEmpty(ctrl.cardData)) {
      ctrl.isCanReview = _.get(ctrl.cardData, 'reviewEnabled', false)
      ctrl.isCanReport = _.get(ctrl.cardData, 'reportEnabled', false)
      ctrl.isShowRedPoint = _.get(ctrl.cardData, 'reviewPending', false)
    }
  }

  ctrl.onPaperBtn = () => {
    $state.go('examPaper', {
      coursepathId: _.get(ctrl.cardData, 'exam.coursepathId', ''),
      sectionId: _.get(ctrl.selectedSection, 'id', ''),
      sectionParentId: _.get(ctrl.selectedSection, 'parentId', ''),
      sectionRootId: ctrl.sectionRootId,
      examId: _.get(ctrl.cardData, 'exam.id', ''),
      levelId: _.get(ctrl.selectedSection, 'levelId', ''),
      relId: _.get(ctrl.selectedSection, 'relId', '')
    })
  }

  ctrl.onReviewBtn = () => {
    if (!ctrl.isCanReview) {
      return
    }
    $state.go('examReview', {
      examId: _.get(ctrl.cardData, 'exam.id', ''),
      levelId: _.get(ctrl.selectedSection, 'levelId', ''),
      relId: _.get(ctrl.selectedSection, 'relId', ''),
      coursepathId: _.get(ctrl.cardData, 'exam.coursepathId', ''),
    })
  }

  ctrl.onReportBtn = () => {
    if (!ctrl.isCanReport) {
      return
    }
    $state.go('examReport', {
      examId: _.get(ctrl.cardData, 'exam.id', ''),
      sectionId: _.get(ctrl.selectedSection, 'id', ''),
      sectionParentId: _.get(ctrl.selectedSection, 'parentId', ''),
      sectionRootId: ctrl.sectionRootId,
      levelId: _.get(ctrl.selectedSection, 'levelId', ''),
      relId: _.get(ctrl.selectedSection, 'relId', '')
    })
  }
}
