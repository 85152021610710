import * as _ from 'lodash'

oedPrepareLessonController.$inject = ['$scope',  '$uibModal']
function oedPrepareLessonController($scope,  $uibModal) {
  const initState = {
    objective: {
      text: '导入/新建客观练习',
      import: () => {
        return this.importObjRes()
      }
    },
    subjective: {
      text: '导入/新建主观活动',
      import: () => {
        return this.importSbjRes()
      }
    },
    presence: {
      text: '导入/新建展示资源  ',
      import: () => {
        return this.importPresentRes()
      }
    }
  }

  this.importObjRes = () => {
    $scope.$suspend()
    const modalInstance = $uibModal.open({
      template: require('../../prepareCourse/cloud/objectiveTestLibrary.html'),
      controller: 'objectiveTestLibraryCtrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          course: $scope.course,
          doImport: importQuestions,
        })
      },
    })

    modalInstance.result.finally(() => {
      $scope.$resume()
    })
  }

  this.importSbjRes = () => {
    $scope.$suspend()
    const modalInstance = $uibModal.open({
      template: require('app2/prepareCourse/cloud/subjectiveTest/subjectiveTestLibraryDialog.html'),
      controller: 'subjectiveTestLibraryDialogCtrl',
      controllerAs: '$ctrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          course: $scope.course,
          doImport: (res, deferred) => {
          },
        })
      },
    })

    modalInstance.result.finally(() => {
      $scope.$resume()
    })
  }

  this.importPresentRes = () => {
    $uibModal.open({
      template: require('app2/prepareCourse/cloud/presentResource/presentResourceLibraryDialog.html'),
      controller: 'presentResourceLibraryDialogCtrl',
      controllerAs: '$ctrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          doImport: (res, deferred) => this.doImport(),
        })
      },
    })
  }

  const importQuestions = () => {
    this.onImport({
      data: 'test',
    })
  }

  this.getText = () => {
    return _.get(initState, `${this.resType}.text`, '')
  }

  this.import = () => initState[this.resType].import()
}
export const oedPrepareLesson = {
  bindings: {
    resType: '<',
    onImport: '&',
  },
  template: <string>require('assets/app2/directives/course/prepareLesson.html'),
  controller: oedPrepareLessonController,
}
angular.module('app.directives.course.prepareLesson', [])
.component('oedPrepareLesson', oedPrepareLesson);
