import angular = require('angular');

angular.module('app.resource.preResItemSummary', [])
  .controller('preResItemSummaryCtrl', preResItemSummaryCtrl);

require('assets/styles/itemSummary.scss');

preResItemSummaryCtrl.$inject = ['$scope', 'data', '$uibModalInstance']
function preResItemSummaryCtrl($scope: any, data: any, $uibModalInstance: any) {
  $scope.data = data;
  $scope.activeTabIndex = 0;
  $scope.tabs = [{index: 0, text: '预览'},
    {index: 1, text: '统计'}];

  $scope.close = () => {
    $uibModalInstance.close();
  };

}
