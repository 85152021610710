import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { JoinSchoolComponent } from './join-school/join-school.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

export const postLoginStates = [{
  name: 'joinSchool',
  url: '/joinSchool',
  bodyStyle: 'newstyle',
  component: JoinSchoolComponent,
}]
