/**
 * @fileOverview
 * @name subjectiveTestSessionsCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('subjectiveTestSessions', {
        url: '/review/:classSessionId/course/:courseId/tm/:tmId/subTest/:testId',
        template: require('assets/templates/review/subjectiveTestSessions.html'),
        controller: 'subjectiveTestSessionsCtrl',
        bodyStyle: 'bged2'
      });
    }])
    .controller('subjectiveTestSessionsCtrl', subjectiveTestSessionsCtrl);

  subjectiveTestSessionsCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal',
    '$animate', '$q', 'dialogs', 'resize', 'userInfo', 'oedCoursePath', 'oedClassSession',
    'oedCourse', 'oedBoardSession', 'oedTestSession', 'oedStudentGroup', 'oedTeachingPattern']
  function subjectiveTestSessionsCtrl($scope, $state, $stateParams, $uibModal,
    $animate, $q, dialogs, resize, userInfo, oedCoursePath, oedClassSession,
    oedCourse, oedBoardSession, oedTestSession, oedStudentGroup, oedTeachingPattern) {

    $scope.currentState = {};

    $scope.courseId = _.parseInt($stateParams.courseId);

    $scope.classSession = oedClassSession.get({
      id: $stateParams.classSessionId
    });

    $scope.course = oedCourse.get({
      id: $stateParams.courseId
    })
    const isTempSession = $stateParams.testId === 'tempSessions'
    const filterNormalSessions = () => {
      const testSessionIdMap = _.uniq(_.map($scope.testSessions, 'id'))
      $scope.topLevelSessions = _.filter($scope.boardSessions, (s) => {
        return _.isNil(s.parentId) && _.includes(testSessionIdMap, s.testSessionId);
      });
    }
    const filterTempSessions = () => {
      $scope.topLevelSessions = _.filter($scope.boardSessions, (s) => {
        return _.isNil(s.parentId)
      })
    }
    const loadTempBoardSession = () => {
      $scope.boardSessions = oedBoardSession.queryTempSessionsByClassSessionId({
        classSessionId: $stateParams.classSessionId
      });
      return $scope.boardSessions.$promise.then(() => {
        return oedTeachingPattern.queryByClassSessionAndCourseId({
          classSessionId: $stateParams.classSessionId,
          courseId: $stateParams.courseId
        }).$promise
      }).then(patterns => {
        $scope.boardSessions = _.filter($scope.boardSessions, (r) => {
          const inCourse = _.filter(_.get($scope.classSession, 'testSessions', []), (s) => s.id === r.testSessionId
            && _.includes(_.map(patterns, 'id'), s.teachingPatternId))
          return !_.isEmpty(inCourse)
        })
        filterTempSessions()
      })
    }
    const loadNormalBoardSession = () => {
      $scope.boardSessions = oedBoardSession.queryByTestId({
        classSessionId: $stateParams.classSessionId,
        origTestId: $stateParams.testId
      });
      $scope.testSessions = oedTestSession.queryByTestIdExcludeTmSession({
        classSessionId: $stateParams.classSessionId,
        origTestId: $stateParams.testId
      });
      return $q.all([$scope.boardSessions.$promise, $scope.testSessions.$promise]).then(() => {
        filterNormalSessions()
      })
    }
    const loadBoardSessions = () => {
      if (isTempSession) {
        return loadTempBoardSession()
      } else {
        return loadNormalBoardSession()
      }
    }

    var sessionId = $state.params.sessionId;

    const groupsInfo = {

    }
    const getGroupInfo = (groupId) => {
      return oedStudentGroup.get({id: groupId})
      .$promise.then((group) => {
        if (!_.isEmpty(group.subGroupInfo)) {
          groupsInfo[group.id] = _.keyBy(group.subGroupInfo, 'subGroupIdx')
        }
      })
    }

    const getGroupsInfo = (groupsIds) => {
      if (_.isEmpty(groupsIds)) {
        return Promise.resolve()
      }
      return Promise.map(groupsIds, getGroupInfo)
    }

    const filterGroupSessions = () => {
      _.each($scope.topLevelSessions, (top) => {
        top.sndLevelSessions = _.filter($scope.boardSessions, (s) => {
          return s.parentId === top.id;
        })
        if (!_.isEmpty(top.sndLevelSessions)) {
          _.each(top.sndLevelSessions, (snd) => {
            if (snd.subGroupIdx === -1) {
              snd.name = '未分组';
            } else {
              if (_.has(groupsInfo, snd.groupId)) {
                const subInfo = groupsInfo[snd.groupId][snd.subGroupIdx]
                snd.name = subInfo ? subInfo.subName : '第' + (snd.subGroupIdx + 1) + '组';
              } else {
                snd.name = '第' + (snd.subGroupIdx + 1) + '组';
              }
            }
          });
          _.sortBy(top.sndLevelSessions, ['subGroupIdx']);
        }
      });
    }

    const toSessionDetails = (session) => {
      const boardSession = _.isNil(session) ?  _.last($scope.topLevelSessions) : session
      const hasSndLevelSession = boardSession.subGroupIdx && _.isNil(boardSession.parentId)
      if (hasSndLevelSession) {
        $scope.currentParentId = boardSession.sndLevelSessions[0].parentId
        $scope.currentBoardSessionId = boardSession.sndLevelSessions[0].id
      } else {
        $scope.currentParentId = boardSession.parentId
        $scope.currentBoardSessionId = boardSession.id
      }
      $state.go('subjectiveTestSessions.session', {
        sessionId: $scope.currentBoardSessionId
      }, {
        location: 'replace'
      });
    }

    $scope.toSessionDetails = toSessionDetails

    $scope.loading = $q.all([
      $scope.classSession.$promise,
      $scope.course.$promise,
      loadBoardSessions()
    ]).then(() => {
      return getGroupsInfo(_.map(_.filter($scope.topLevelSessions, 'groupId'), 'groupId'))
    }).then(() => {
      filterGroupSessions()
      toSessionDetails()
    });
  }

})();
