(function() {
  var messages = require('app2/utils/messages');
  var moment = require('moment');
  var _ = require('lodash');
  var quickPrepare = require('assets/app2/prepareCourse/utils/prepareCourseUtils')

  angular.module('app.prepareCourse.qObjectTestPreview', [
    'app.prepareCourse.qObjectTestPreview'
  ])
    .controller('objectiveTestPreviewerCtrl', objectiveTestPreviewerCtrl);

  objectiveTestPreviewerCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'notify', 'oedObjectiveTest',
    'oedCloudTest', 'oedUserInfo', 'oedTestUtils', 'cloudTest', 'resourceDef', 'templateRes', 'gotoRes', 'oedResource']
  function objectiveTestPreviewerCtrl($scope, $q, $uibModalInstance, notify, oedObjectiveTest,
  oedCloudTest, oedUserInfo, oedTestUtils, cloudTest, resourceDef, templateRes, gotoRes, oedResource) {
    resourceDef = resourceDef || {};

    $scope.extraInfo = ''

    $scope.resourceDef = resourceDef;

    $scope.templateRes = templateRes;

    $scope.canGoto = !!gotoRes

    $scope.gotoRes = function() {
      gotoRes(cloudTest);
    };

    $scope.importBtnText = _.get(resourceDef, 'importBtnText', '导入');

    $scope.titleName = cloudTest.name + ' - 预览';

    $scope.loading = oedObjectiveTest.get({id: cloudTest.id}).$promise.then((res) => {
      $scope.objectiveTest = res
      if ($scope.getTheWayWithCreateTest()) {
          $scope.childQuestionList = $scope.objectiveTest.questions[0].childQuestions
          $scope.imageRes = $scope.objectiveTest.questions[0]['resourceUUIDs']
      }

      if ($scope.objectiveTest.isTemp) {
        return oedObjectiveTest.getObjTestQuestionType({id: $scope.objectiveTest.id}).$promise.then((r) => {
          if (r && r.testId && r.extraInfo === 'yesorno') {
            $scope.extraInfo = 'yesorno'
          } else {
            $scope.extraInfo = 'singlechoice'
          }
        })
      }
    });
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    }

    $scope.getTheWayWithCreateTest = () => {
      return quickPrepare.isQuickWayToCreatePrepareCourse($scope.objectiveTest)
    }

    $scope.stringToNumber = (str) => {
      return _.toNumber(str)
    }

  }
})();
