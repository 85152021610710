import {isQuickWayToCreatePrepareCourse} from "../../../app2/prepareCourse/utils/prepareCourseUtils";
import { listeningHasntAudioUrl, convertRuanyunQuestionToRayQ } from 'app2/integration/ruanyun/ruanyunQuesConvertUtils';
import { computeQuestionHash } from 'app2/utils/testUtils2'
(function() {
  var messages = require('app2/utils/messages');
  var moment = require('moment');
  const Promise = require('bluebird')
  var _ = require('lodash');
  var talcloudUtils =  require('app2/integration/talcloud/talcloudUtils')

  angular.module('app.prepareCourse')
    .controller('cloudTestPreviewerCtrl', cloudTestPreviewerCtrl);

  cloudTestPreviewerCtrl.$inject  = ['$scope', '$q', '$uibModalInstance', '$uibModal', 'notificationService', 'oedObjectiveTest',
    'oedCloudTest', 'oedUserInfo', 'oedTestUtils', 'cloudTest', 'resBox', 'resourceDef', 'talcloudResource', 'userInfo','localStorageService','ruanyunResource']
  function cloudTestPreviewerCtrl($scope, $q, $uibModalInstance, $uibModal, notificationService, oedObjectiveTest,
  oedCloudTest, oedUserInfo, oedTestUtils, cloudTest, resBox, resourceDef, talcloudResource, userInfo,localStorageService,ruanyunResource) {
    resourceDef = resourceDef || {};
    $scope.resourceDef = resourceDef;
    $scope.isTalcloudTest = cloudTest.$sourceType === 'talcloud'

    $scope.importBtnText = _.get(resourceDef, 'importBtnText', '导入');

    $scope.previewTest = cloudTest
    function getCloudTest() {
      //
      // 如果有__type 这个属性表示是一个普通资源
      if (_.has(cloudTest, '__type')) {
        return oedCloudTest.queryByObjectiveTestId({
          id: cloudTest.id
        }).$promise;
      }

      if ($scope.isTalcloudTest) {
        return Promise.resolve(cloudTest);
      }

      return $q.when(cloudTest.cloudResource || cloudTest);
    }

    $scope.isTalcloudSyntheticQues = (q) => {
      return !_.isEmpty(q.subsets)
    }

    $scope.reload = () => {
      return getCloudTest().then(cloudRes => {
        if(cloudTest.sourceType == 'ruanyunTopic'){
          const sourceName = cloudRes.name.trim();
          const userCode = 'teacher' + localStorageService.get('ruanyunCourseId');
          let pageIndex = 1;
          const request = {
            "SourceName":sourceName,
            "PageIndex":pageIndex,
            "PageSize":20,
            "UserCode":userCode
          };
          let total = 0;
          return ruanyunResource.getTestPaperDestailQes(request).$promise.then(r => {
            if(r.ResultMessage == "暂无数据"){
              return notificationService.notify('info', '试卷暂无数据');
            }
            total = r.Value.QuestionTotal;
            const needRequestNum = Math.ceil(total/20);
            const arr = [];
            while (needRequestNum >= pageIndex + 1) {
              pageIndex++;
              const tempObj = {
                "SourceName":sourceName,
                "PageIndex":pageIndex,
                "PageSize":20,
                "UserCode":userCode
              };
              arr.push(ruanyunResource.getTestPaperDestailQes(tempObj).$promise);
            }
            return $q.all(arr).then(res => {
              let questionArr = r.Value.Questions;
              _.forEach(res, data => {
                questionArr = questionArr.concat(data.Value.Questions);
              });
              const questions = _.chain(questionArr).filter(q => !listeningHasntAudioUrl(q)).map(q => {
                return { question: convertRuanyunQuestionToRayQ(q) }
              }).filter('question.$valid').value();
              $scope.objectiveTest = {questions:[]};
              _.forEach(questions, item => {
                $scope.objectiveTest.questions.push(item.question);
              })
            })
          })
        }else{
          if (cloudRes.id && !$scope.isTalcloudTest) {
            $scope.objectiveTest = oedObjectiveTest.getPreviewByCloudRes({
              res_id: cloudRes.id
            });
            $scope.user = oedUserInfo.getByCloudTestId({
              id: cloudRes.id
            });
            return $q.all([$scope.objectiveTest.$promise, $scope.user.$promise]).then(data=> {
              const test = data[0]
              test.cloudRes = cloudRes
              if ($scope.isQuickTest()) {
                $scope.imageRes = $scope.objectiveTest.questions[0].resourceUUIDs
                $scope.childQuestionList = $scope.objectiveTest.questions[0].childQuestions
              }
            })
          } else if ($scope.isTalcloudTest) {
            $scope.objectiveTest = _.omit(cloudTest, 'questions')
            return getTalcloudTestQuestionDetails()
          } else {
            $scope.objectiveTest = oedObjectiveTest.get({id: cloudTest.id,})
            return $scope.objectiveTest.$promise
          }

        }
      })
    };

  function getTalcloudTestQuestionDetails() {
    return Promise.mapSeries(cloudTest.questions, (q) => {
      return talcloudUtils.getQuestionDetails(q, talcloudResource, userInfo).then(() => {
        return talcloudUtils.convertTalcloudQuestionToRayQ(q)
      })
    }).then((queses) => {
      _.chain($scope.objectiveTest).set('questions', _.filter(queses, '$valid')).filter().value()
      _.set(cloudTest, 'questions', _.filter(queses, '$valid'))
    }).catch((err) => {
      console.log(err)
    })
  }

    $scope.loading = $scope.reload();
    $scope.close = function() {
      $uibModalInstance.close({});
    };
    $scope.mouseOver = false;
    $scope.onMouseOver = function() {
      $scope.mouseOver = true;
    };

    $scope.onMouseLeave = function() {
      $scope.mouseOver = false;
    };

    $scope.getLabel = function() {
      let label = $scope.isAllAdded() ? '全部已加出题筐' : '全部加入出题筐';
      if ($scope.mouseOver && label === '全部已加出题筐') {
        label = '全部移出出题筐';
      }
      return label;
    };

    $scope.importRes = function() {
      var deferred = $q.defer();
      $scope.loading = deferred.promise;
      deferred.promise.then(function() {
        notificationService.notify('info', '资源导入成功')

        $uibModalInstance.close('close');
      }, null, function(update) {
        $scope.progressMsg = update;
      }).finally(function() {
        $scope.progressMsg = '';
      });

      const viewType = $scope.isTalcloudTest === true ? 'resBox' : 'objectiveTest';
      resourceDef.doImport([cloudTest], deferred, viewType);
    };

    var titleTpl = _.template('<%= testname %>-预览（<%=username%>）');
    var ruanyunTitleTpl =_.template('<%= testname %>')
    $scope.getTitle = function() {
      if(cloudTest.sourceType == "ruanyunTopic"){
        return  ruanyunTitleTpl({
          testname: cloudTest.name,
        })
      }else if($scope.isTalcloudTest){
        return _.get(cloudTest, 'title', '试卷预览')
      }else{
        return titleTpl({
          testname: _.get($scope.objectiveTest, 'name', ''),
          username: _.get($scope.user, 'name', '')
        });
      }
    };

    var subTitleTpl = _.template('<%=lastUpdateDate%> 引用: <%=downloads%> 浏览: <%=views%>');
    $scope.getSubTitleText = function() {
      if (!$scope.objectiveTest || !$scope.objectiveTest.cloudRes) {
        return ''
      }
      const res = $scope.objectiveTest.cloudRes
      return subTitleTpl({
        lastUpdateDate: moment(_.get(res, 'lastUpdatedDate')).format('YYYY-MM-DD'),
        downloads: _.get(res, 'downloads', 0),
        views: _.get(res, 'views', 0)
      });
    };

    $scope.getResBoxItems = function() {
      if (_.isFunction(resourceDef.getResItems)) {
        return resourceDef.getResItems();
      }
      return null;
    };

    $scope.addAllToResBox = function(event) {
      if(cloudTest.sourceType == "ruanyunTopic"){
        _.forEach( $scope.objectiveTest.questions,item=>{
          resourceDef.addItemToResBox({question:item}, 'cloudQuestion','ruanyunTopic',cloudTest.name);
        })
      }else{
        if ($scope.isAllAdded()) {
          return;
        }
        $scope.onAddResBoxItem(cloudTest, $scope.isTalcloudTest === true ? 'talcloudTest' : 'cloudTest');
      }
    };
    $scope.rmAllToResBox = function(event) {
      resourceDef.removeItemToResBox($scope.isTalcloudTest === true?cloudTest:_.get(cloudTest, 'test'), $scope.isTalcloudTest === true ? 'talcloudTest' : 'cloudTest');
    };

    $scope.addCloudQuestion = function(q, event) {
      if ($scope.isTalcloudTest === true) {
        if (!resBox.isQuestionAdded(q)) {
          resBox.addQuestion(q)
        } else {
          resBox.rmQuestion(q)
        }
        return
      }
      if ($scope.isQuestionAdded(q)) {
        resourceDef.removeItemToResBox({question: q}, 'cloudQuestion');
        return;
      }
      $scope.onAddResBoxItem({question: q}, 'cloudQuestion');
      // $scope.cloudResToAdd = {
      //   cloudRes: {
      //     question: q
      //   },
      //   resType: 'cloudQuestion',
      //   event: event
      // };
    };

    $scope.addOrRmAllToResBox = function() {
      if($scope.isAllAdded()) {
        $scope.rmAllToResBox()
      } else {
        $scope.addAllToResBox()
      }
    }
    $scope.onAddResBoxItem = function(res, resType) {
      if(cloudTest.sourceType == 'ruanyunTopic'){
        resourceDef.addItemToResBox(res, resType,'ruanyunTopic',cloudTest.name);
      }else{
        resourceDef.addItemToResBox(res, resType);
      }
      updateCached();
    };

    $scope.$watch('getResBoxItems()', function() {
      updateCached();
    });

    var hashesInResBox = {};
    var questionHashes = {};
    //
    // 缓存一些中间结果, 计算hash相对耗费时间
    function updateCached() {
      var existingHashes = _.map($scope.getResBoxItems(), oedTestUtils.computeQuestionHash);
      hashesInResBox = _.keyBy(existingHashes);
      const prop = $scope.isTalcloudTest === true ? 'questions' : 'test.questions'
      if (_.isEmpty(_.get(cloudTest, prop, []))) {
        return
      }
      var hashes = _(cloudTest).get(prop, []).map(function(q) {
        return {
          id: $scope.isTalcloudTest === true ? q.questionId : q.id,
          hash: oedTestUtils.computeQuestionHash(q)
        };
      });
      questionHashes = _.keyBy(hashes, 'id');
    }

    $scope.getCount = function() {
      return $scope.getResBoxItems() ? $scope.getResBoxItems().length : 0
    }

    $scope.isAllAdded = function() {
      if (!_.isFunction(resourceDef.getResItems)) {
        return false;
      }

      var hash = _.find(questionHashes, function(h) {
        return !_.has(hashesInResBox, h.hash);
      });
      return !hash;
    };

    $scope.isQuestionAdded = function(q) {
      if(cloudTest.sourceType == "ruanyunTopic"){
        const ruanyunNametoHash = $scope.$resolve.resBox.ruanyunNametoHash;
        if(ruanyunNametoHash){
          for (var item in ruanyunNametoHash) {
            if(item == cloudTest.name.trim()){
              if(!q.$$hashCached){
                q.$$hashCached = computeQuestionHash(q, true);
              }
              var index = ruanyunNametoHash[item].indexOf(q.$$hashCached);
              if(index >= 0){
               return true;
              }else{
                return false;
              }
            }
          }
        }
      }else{
        if (!resBox) {
          return false
        }
        return resBox.isQuestionAdded(q)
        // const quesId = $scope.isTalcloudTest === true ? q.questionId : q.id
        // var hash = _.get(questionHashes, quesId, '');
        // return _.has(hashesInResBox, hash.hash);
      }
    };

    $scope.previewResBox = function(items) {
      if (_.isEmpty(items)) {
        return notificationService.notify('info', messages['importResource/noResourceAdded'])
      }

      $uibModalInstance.close({
        action: 'showResBox'
      });
    };

    $scope.isQuickTest = () => {
      return isQuickWayToCreatePrepareCourse($scope.objectiveTest)
    }

    $scope.importQuickTest = function() {
      if ($scope.isQuickTest()) {
        if (!_.isEmpty($scope.getResBoxItems())) {
          const modalInstance = $uibModal.open({
            component: 'warningDialog',
            size: 'qp-edit',
            windowClass: 'cl-library-dialog2',
          })
          modalInstance.result.then((q) => {
            if (q === 'save') {
              const defer = $q.defer();
              $scope.loading = defer.promise;
              defer.promise.then(() => {
                notificationService.notify('info', '答题卡导入成功')

                $uibModalInstance.close({action: 'quickClose'});
              })

              resourceDef.importQuickTest(cloudTest, defer)
            }
          })
        } else {
          const defer = $q.defer();
          $scope.loading = defer.promise;
          defer.promise.then(() => {
            notificationService.notify('info', '答题卡导入成功')

            $uibModalInstance.close({action: 'quickClose'});
          })

          resourceDef.importQuickTest(cloudTest, defer)
        }
      }
    };
  }
})();
