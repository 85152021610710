import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RayApiService } from 'app/core/ray-api.service';
import { ClassroomService } from 'app/shared/classroom.service';

@Injectable({
  providedIn: 'root'
})
export class StudentGroupService {

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService,
              private classroomService: ClassroomService) {}

  public setSubGroupName(groupId: number, idx: number, name: string) {
    const body = {
      groupId,
      subGroupIdx: idx,
      subName: name,
    }

    return this.httpClient.post<void>(this.rayApi.apiB(`studentGroup/${groupId}/setName`), body)
  }
}
