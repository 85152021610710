import { BIG_FILE_SIZE } from "./constants";

/**
 * @fileOverview
 * @name testUtils.js
 * @author pangwa
 * @license
 */
(function() {
  angular.module('app.utils.fileUtils', [])
    .factory('oedFileUtils', oedFileUtils);

  oedFileUtils.$inject = ['notificationService'];
  function oedFileUtils(notificationService) {
    var imageSuffix = ['png', 'jpg', 'jpeg', 'gif', 'bmp'];
    var audioSuffix = ['mp3', 'wma', 'wav', 'amr', 'm4a'];
    var videoSuffix = ['mp4', 'avi', 'rmvb', 'mov', 'wmv', '3gp', 'flv', 'mkv', 'mpg'];
    var officeSuffix = ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'pdf'];
    var excelSuffix = ['xlsx'];
    var wordSuffix = ['doc', 'docx']
    var excelSuffix2 = ['xls', 'xlsx']
    var pptSuffix = ['ppt', 'pptx']
    var pdfSuffix = ['pdf']
    var txtSuffix = ['txt']
    var zipSuffix = ['zip', 'rar']
    var supportedSuffix = imageSuffix.concat(audioSuffix).concat(videoSuffix).concat(officeSuffix);

    var config = {
      maxUploadSize: 2048 * 1024 * 1024, //200M
      //
      // 支持的文件扩展名列表
      imageSuffix: imageSuffix,
      audioSuffix: audioSuffix,
      videoSuffix: videoSuffix,
      officeSuffix: officeSuffix,
      excelSuffix: excelSuffix,
      supportedSuffix: supportedSuffix
    };

    var beautifySize = function(bytes) {
      var thresh = 1024;
      if (bytes < thresh) return bytes + ' B';
      var units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while (bytes >= thresh);
      return bytes.toFixed(1) + units[u];
    };

    var uploadRules = [{
      name: 'size-check',
      level: 'error',
      check: function(file, opt) {
        if (file.size > opt.maxUploadSize) {
          var maxSize = beautifySize(opt.maxUploadSize);
          var msg = _.template('文件过大, 系统仅支持不超过<%= maxSize %>的文件');
          return {
            name: this.name,
            level: this.level,
            classes: 'fileupload-' + this.level,
            message: msg({
              maxSize: maxSize
            })
          };
        }
        return null;
      }
    }, {
      name: 'allowedSuffixes-check',
      level: 'error',
      check: function(file, opt) {
        var allowedSuffixes = opt.allowedSuffixes;
        if (_.isEmpty(allowedSuffixes)) {
          //
          // 允许任意类型的文件
          if (opt.fileType === 'all') {
            return null;
          }

          allowedSuffixes = opt[opt.fileType + 'Suffix'] || [];
        }

        var suffixIdx = _.findIndex(allowedSuffixes, function(sfx) {
          return _.endsWith(file.name.toLowerCase(), sfx);
        });

        if (suffixIdx >= 0) {
          return null;
        }

        var suffixString = allowedSuffixes.join(', ');
        var msg = _.template('不支持的导入格式(非<%= allowedSuffixes %>,文件为空)');
        return {
          name: this.name,
          level: this.level,
          classes: 'fileupload-' + this.level,
          message: msg({
            allowedSuffixes: suffixString
          })
        };
      }
    }, {
      name: 'filetype-support-check',
      level: 'warning',
      check: function(file, opt) {
        var supportIdx = _.findIndex(opt.supportedSuffix, function(sfx) {
          return _.endsWith(file.name.toLowerCase(), sfx);
        });
        if (supportIdx >= 0) {
          return null;
        }
        var parts = file.name.split('.');
        var suffix = _.size(parts) > 1 ? parts.pop().toLowerCase() : '';

        //
        // 不支持的文件类型
        return {
          name: this.name,
          level: this.level,
          classes: ['fileupload-' + this.level, 'fileupload-not-support-' + suffix],
          message: '系统暂不支持此类文件, 在PAD端将不能查看此类型的文档'
        };
      }
    }, {
      name: 'filetype-swf-check',
      level: 'info',
      check: function(file, opt) {
        var swfSuffixes = ['swf'];
        var isSwf = _.findIndex(swfSuffixes, function(sfx) {
          return _.endsWith(file.name.toLowerCase(), sfx);
        }) >= 0;
        if (isSwf) {
          var message = '由于Android系统限制原因，系统暂不支持SWF格式，建议转换成视频格式后再上传。<br/>' +
            '您可以下载flash文件转换工具进行转换，如超级录屏。';
          return {
            name: this.name,
            level: this.level,
            classes: ['file-upload-' + this.level],
            message: message
          };
        }
        return null;
      }
    }];

    var validateFileUpload = function(file, opt) {
      var cfg = _.defaults({}, opt, config, {
        fileType: 'supported' //默认使用已知支持的文件类型
      });

      var errors = _(uploadRules).map(function(rule) {
        var msg = rule.check(file, cfg);
        if (!_.isEmpty(msg)) {
          return msg;
        }
        return null;
      }).filter().value();

      return errors;
    };

    var notifyValidationReporter = function(results) {
      _(results).filter(function(r) {
        //
        // 仅对错误和swf类型的消息显示通知
        return r.level === 'error' || r.name === 'filetype-swf-check';
      }).uniq(false, 'name').each(function(rule) {
        // var messages = '<span>' + rule.message + '</span>';
        var messages = rule.message;
        notificationService.notify('info', messages, '', 10 * 1000)
      });
    };

    var fileTypes = {
      audio: {
        iconClass: 'oed-file-type-new fa fa-file-audio-o',
        tooltip: '音频'
      },
      file: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '文件'
      },
      image: {
        iconClass: 'oed-file-type-new fa fa-file-photo-o',
        tooltip: '图片'
      },
      link: {
        iconClass: 'oed-file-type-new fa fa-link',
        tooltip: '链接'
      },
      office: {
        iconClass: 'oed-file-type-new fa fa-file-word-o',
        tooltip: '文档'
      },
      excel: {
        iconClass: 'oed-file-type-new fa fa-file-excel-o',
        tooltip: '表格'
      },
      video: {
        iconClass: 'oed-file-type-new fa fa-file-video-o',
        tooltip: '视频'
      },
      unknown: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '其他文档'
      }
    };

    function getFileTypeIconClass(type) {
      if (!fileTypes[type]) return null;
      return fileTypes[type].iconClass;
    }

    function getFileTypeTooltip(type) {
      if (!fileTypes[type]) return '';
      return fileTypes[type].tooltip;
    }

    function getTalAreaFileTypeIconClass(fileTypeUp) {
      const fileType = _.toLower(fileTypeUp)
      let iconCls = 'fa fa-file-o file'
      if (_.indexOf(imageSuffix, fileType) >= 0) {
        iconCls = 'fa fa-file-image-o image'
      } else if (_.indexOf(audioSuffix, fileType) >= 0) {
        iconCls = 'fa fa-file-sound-o audio'
      } else if (_.indexOf(videoSuffix, fileType) >= 0 ) {
        iconCls = 'fa fa-file-movie-o movie'
      } else if (_.indexOf(wordSuffix, fileType) >= 0) {
        iconCls = 'fa fa-file-word-o word'
      } else if (_.indexOf(excelSuffix2, fileType) >= 0) {
        iconCls = 'fa fa-file-excel-o excel'
      } else if (_.indexOf(pptSuffix, fileType) >= 0) {
        iconCls = 'fa fa-file-powerpoint-o ppt'
      } else if (_.indexOf(pdfSuffix, fileType) >= 0) {
        iconCls = 'fa fa-file-pdf-o pdf'
      } else if (_.indexOf(zipSuffix, fileType) >= 0) {
        iconCls = 'fa fa-file-archive-o zip'
      } else if (_.indexOf(txtSuffix, fileType) >= 0) {
        iconCls = 'fa fa-file-text-o text'
      }
      return {
        iconCls,
      }
    }

    function hasBigFile(files){
      for(const file of files){
        if(file.size >= BIG_FILE_SIZE){
          return true;
        }
      }
      return false;
    }
    return {
      config: config,
      beautifySize: beautifySize,
      validateFileUpload: validateFileUpload,
      notifyValidationReporter: notifyValidationReporter,
      getFileTypeIconClass: getFileTypeIconClass,
      getFileTypeTooltip: getFileTypeTooltip,
      getTalAreaFileTypeIconClass: getTalAreaFileTypeIconClass,
      hasBigFile: hasBigFile,
    };
  }
})();
