angular.module('app.manage')
  .controller('viewSchoolActivateCodesCtrl', viewSchoolActivateCodesCtrl);

viewSchoolActivateCodesCtrl.$inject = [
  '$scope', '$uibModal', '$uibModalInstance',
  'notificationService', 'moment', 'oedActivateCode', 'school'
];

function viewSchoolActivateCodesCtrl($scope, $uibModal, $uibModalInstance, notificationService, moment, oedActivateCode, school) {
  $scope.school = school;
  $scope.codeDisplayCategory = 'all';
  $scope.pageSize = 10;
  $scope.curPage = 1;

  $scope.onCodeDisplayCategoryChange = function() {
    if ($scope.codeDisplayCategory === 'activated') {
      $scope.codesDisplay = $scope.codesActivated;
    } else if ($scope.codeDisplayCategory === 'unactivated') {
      $scope.codesDisplay = $scope.codesUnactivated;
    } else {
      $scope.codesDisplay = $scope.codes;
    }

    $scope.totalNums = _.size($scope.codesDisplay);
    $scope.curPage = 1;
    $scope.codesDisplayOfCurPage = _.slice($scope.codesDisplay,
      ($scope.curPage - 1) * $scope.pageSize,
      $scope.curPage * $scope.pageSize
    );
  }

  $scope.onPageChange = function(page) {
    $scope.curPage = page;
    $scope.codesDisplayOfCurPage = _.slice(
      $scope.codesDisplay,
      ($scope.curPage - 1) * $scope.pageSize,
      $scope.curPage * $scope.pageSize
    );
  };

  $scope.reassignActivateCode = function() {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/manage/assignActivateCode.html'),
      controller: 'assignActivateCodeCtrl',
      size: 'sm',
      windowClass: 'signupModal',
      resolve: {
        school: function() {
          return school;
        },
        existingCodes: function() {
          return $scope.codes
        }
      }
    });

    modalInstance.result.then(function(r) {
      if (r.success) {
        notificationService.notify('info', '分配成功')
        $scope.loading = loadActivateCodes();
      }
    });
  }

  function loadActivateCodes() {
    oedActivateCode.list({
      schoolId: school.id
    }, {
      schoolId: school.id
    }).$promise.then(function(codes) {
      $scope.codes = _.map(codes, code => Object.assign({
        activatedDateString: code.activatedDate ? moment(code.activatedDate).format('YYYY/MM/DD HH:mm') : ''
      }, code));
      $scope.codesActivated = _.filter($scope.codes, code => _.get(code, 'activated', false) === true);
      $scope.codesUnactivated = _.filter($scope.codes, code => _.get(code, 'activated', false) === false);
      $scope.onCodeDisplayCategoryChange();
    }).catch(function(e) {
      notificationService.notify('error', '获取激活码失败')
    });
  }
  $scope.loading = loadActivateCodes();

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
