/**
 * @fileOverview
 * @name urlBuilder.js
 * @author pangwa
 * @license
 */
(function() {
  angular.module('app.utils.urlBuilder', [])
    .factory('oedUrlBuilder', oedUrlBuilder);

oedUrlBuilder.$inject = ['$httpParamSerializer']
  function oedUrlBuilder($httpParamSerializer) {
    function buildUrl(url, params) {
      var serializedParams = $httpParamSerializer(params);

      if (serializedParams.length > 0) {
        url += ((url.indexOf('?') === -1) ? '?' : '&') + serializedParams;
      }

      return url;
    }
    return buildUrl;
  }
})();
