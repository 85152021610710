
var moment = require('moment');
const timeSpans = require('app2/utils/dateUtils').timeSpans;
const hourSpans = require('app2/utils/dateUtils').hourSpans;
const minuteSpans = require('app2/utils/dateUtils').minuteSpans;

(function() {
  angular.module('app.homework')
    .controller('publishTaskListCtrlV2', publishTaskListCtrl);

  publishTaskListCtrl.$inject = ['$scope', '$q', '$uibModal', '$uibModalInstance', 'userInfo',
    'oedUserClass', 'oedTaskList', 'oedTaskListPublishRecord', 'data', 'notificationService',
    '$state', 'oedClassroom', 'oedClassUser', 'anHuiResource', 'oedUserInfo', 'oedPresentResource', 'oedZhongZhiSchool']
  function publishTaskListCtrl($scope, $q, $uibModal, $uibModalInstance, userInfo,
    oedUserClass, oedTaskList, oedTaskListPublishRecord, data, notificationService,
    $state, oedClassroom, oedClassUser, anHuiResource, oedUserInfo, oedPresentResource, oedZhongZhiSchool) {
    var now = moment();
    var res_key = 'ykgGIYdX';
    var res_secret = 'c300be09275f43d2b22789ceda8dd762';

    $scope.record = {
      taskListId: data.taskList.id,
      classes: [],
      selectedClassStudents: {},
      course: null,
      startDate: now.toDate(),
      dueDate: now.add(1, 'days').toDate()
    };
    $scope.status = false;

    $scope.userThirdParty = ''
    $scope.userThirdPartyUid = ''

    $scope.publishedClassess = _.reduce(data.existingClassIds, function(total, clsId) {
      total[clsId] = true;
      return total;
    }, {});

    $scope.classes = [];
    $scope.time = timeSpans;
    $scope.hour = hourSpans;
    $scope.minute = minuteSpans;
    $scope.answerDetailsType = '0';

    userInfo.then(function(info) {
      var classes = oedUserClass.queryByUser({
        userId: info.uid
      });

      var coursePathList = oedTaskList.queryCoursePathsByTeacherIdV2({
        teacherId: info.uid
      });

      $scope.oedZhongZhi = oedZhongZhiSchool.isZhongZhiSchool();
      $scope.loading = $q.all([classes.$promise, coursePathList.$promise, $scope.oedZhongZhi]).then(function(resData) {
        $scope.classes = _.filter(classes, function(cls) {
          return !_.has($scope.publishedClassess, cls.id) && cls.studentCount !== 0;
        });
        $scope.classes.unshift({
          id: '-1',
          levelName: '',
          gradeName: '',
          rxnd: '',
          className: '全部班级',
        });
        $scope.coursePathList = _.orderBy(_.uniqBy(coursePathList, 'id'), 'createDate', 'asc');
        $scope.isZhongZhiSchool = resData[2];
      });

      $scope.userThirdParty = _.get(info, 'profile.thirdParty')
      $scope.userThirdPartyUid = _.get(info, 'profile.thirdPartyUid')
    });

    $scope.openDueDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.dueDateOpened = true;
    };

    $scope.openStartDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startDateOpened = true;
    };

    $scope.selectClassStudents = function(cls) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/selectStudents.html'),
        controller: 'selectStudentsCtrl',
        size: 'lg',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          data: function() {
            if($scope.status){
              $scope.selectedClassStudents = [];
              $scope.record.selectedClassStudents[cls.id] = [];
            }else{
              $scope.selectedClassStudents = _.get($scope.record.selectedClassStudents, cls.id, []);
            }
            return {
              schoolClass: cls,
              selectedClassStudents: $scope.selectedClassStudents
            };
          }
        }
      });

      modalInstance.result.then(function(data) {
        $scope.record.selectedClassStudents[data.schoolClass.id] = [...data.selectedClassStudents];
      });
      $scope.status = false;
    };

    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.publishForm.$invalid) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '选择的日期不正确，请检查。';
        return;
      }

      var clsIds = _.map($scope.record.classes, function(cls) {
        return cls.id;
      });
      if (_.isEmpty($scope.record.course)) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '请选择课程';
        return;
      }
      if (!$scope.record.startDate) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '请选择开始时间';
        return;
      }
      if (!$scope.record.dueDate) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '请选择结束时间';
        return;
      }
      var classStudentIds = $scope.record.selectedClassStudents;
      var startHourtime = _.isEmpty($scope.record.startHourtime) ? 0 : $scope.record.startHourtime.value;
      var startMinutetime = _.isEmpty($scope.record.startMinutetime) ? 0 : $scope.record.startMinutetime.value;
      var dueHourtime =  _.isEmpty($scope.record.dueHourtime) ? 0 : $scope.record.dueHourtime.value;
      var dueMinutetime = _.isEmpty($scope.record.dueMinutetime) ? 0 : $scope.record.dueMinutetime.value;
      var starMillion = startHourtime + startMinutetime;
      var endMillion = dueHourtime + dueMinutetime;
      var startDate = moment($scope.record.startDate).startOf('day').valueOf() + starMillion;
      var dueDate = moment($scope.record.dueDate).startOf('day').valueOf() + endMillion;
      var answerDetailsType = _.toInteger($scope.answerDetailsType)
      if (clsIds.length === 0) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '请选择班级';
        return;
      } else if (startDate >= dueDate) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '结束时间不能早于开始时间';
        return;
      }

      var updateTaskList = new oedTaskList(_.assign({}, data.taskList, {
        coursepathId: $scope.record.course.id
      }));
      var publishClasses = _.map(clsIds, clsId => ({
        classId: clsId,
        studentIds: classStudentIds[clsId]
      }))
      var records = oedTaskListPublishRecord.createRecordsV2({
        taskListId: data.taskList.id,
        publishClasses,
        dueDate: dueDate,
        startDate: startDate,
        answerDetailsType: answerDetailsType
      });
      $scope.loading = $q.all([records.$promise, updateTaskList.$save()])
      .then(function(records) {
        if ($scope.userThirdParty === 'anhui' && $scope.userThirdPartyUid) { // TODO:!!
          // 如果是安徽平台的账号，将安徽的作业资源同步到学生的平台上
          return syncHomeworkToAnhuiPlatform(publishClasses, data.taskList.id)
            .finally(() => afterPublishTasklist(records));
        }
        afterPublishTasklist(records);
      });
    };



    function syncHomeworkToAnhuiPlatform(publishClasses, taskListId) {
      const flResList = [];
      return oedPresentResource.queryByTaskListId({id: taskListId}).$promise
      .then(reses => {
        return $q.all(
          _.map(reses, r =>
            oedPresentResource.get( {id: _.get(r, 'id')} ).$promise
          )
        );
      })
      .then(resList => {
        const flResources = _.flatten(_.map(resList, 'items', []));
        const resQueue = _.map(flResources, resource => {
          const params = {
            res_key,
            res_secret,
            userId: $scope.userThirdPartyUid,
            filename: _.trim(_.get(resource, 'resourceName', '')),
            url: _.trim(`http://www.rayclass.com/ray/resource/uuid/${_.get(resource, 'resourceUuid', '')}/src?file=${_.get(resource, 'resourceName', '')}`),
            md5: _.get(resource, 'flResource.md5', ''),
            length: _.get(resource, 'flResource.size', 0)
          };
          return anHuiResource.addResToPan({}, params).$promise; // TODO
        })
        return Promise.all(resQueue);
      })
      .then(ahRes => {
        _.forEach(ahRes, r => {
          if (r.status === '200' || r.rid) {
            flResList.push(r)
          }
        })
        const allTask = _.map(publishClasses, classData => {
          return oedClassroom.get({id: classData.classId }).$promise
          .then(cls => {
            if (_.get(cls, 'thirdParty') != 'anhui' || !_.get(cls, 'thirdPartyId')) {
              return Promise.resolve()
            }
            return oedClassUser.queryClassStudents({ classId: classData.classId }).$promise
            .then(stus => {
              if (!classData.studentIds || _.isEmpty(classData.studentIds) || _.size(stus) <= _.size(classData.studentIds)) {
                // 班级同步
                const allPub = _.map(flResList, key => {
                  return anHuiResource.receiveResourceFeed({}, {
                    res_key,
                    res_secret,
                    classId: _.get(cls, 'thirdPartyId'),
                    teacherId: $scope.userThirdPartyUid,
                    title: _.get(key, 'fileName'),
                    resourceId: _.get(key, 'rid'),
                    type: 'class'
                  }).$promise
                })
                return Promise.all(allPub);
              } else {
                // 学生同步
                const chosenStus = _.filter(stus, st => _.indexOf(classData.studentIds, st.uid) >= 0)
                const stuProfiles = _.map(chosenStus, stu => {
                  return oedUserInfo.getProfileByUid({id: _.get(stu, 'uid')}).$promise
                  .then(pfl => {
                    const allPub = _.map(flResList, key => {
                      return anHuiResource.receiveResourceFeed({}, {
                        res_key,
                        res_secret,
                        stuId: _.get(pfl, 'profile.thirdPartyUid'),
                        teacherId: $scope.userThirdPartyUid,
                        title: _.get(key, 'fileName'),
                        resourceId: _.get(key, 'rid'),
                        type: 'student'
                      }).$promise
                    })
                    return Promise.resolve(allPub);
                  })
                });
                return Promise.all(stuProfiles);
              }
            });
          });
        });
        return Promise.resolve(allTask);
      })
      .then(res => {
        return Promise.resolve({});
      })
    }

    function afterPublishTasklist(records) {
      $uibModalInstance.close({
        records: records
      });
      notificationService.notify('info', '发布成功')

      $state.go('homework.taskListsV2.forStatus', {
        status: 'all'
      }, {
        location: true
      });

    }

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.resetStatus = function () {
      $scope.status = true;
    }
    $scope.onFilterSelected = (newVal)=> {
        const isChoiceAll = newVal.id === '-1';
        const isHasAll = _.some($scope.classes, item => item.id === '-1');
        // 选中"全部班级"
        if(isChoiceAll) {
          $scope.classes = _.filter($scope.classes, item => item.id !== '-1');
          $scope.record.classes = $scope.classes;
          return;
        }
        // 没选中"全部班级"，但人数累加到全部班级
        if(!isChoiceAll && isHasAll && (_.size($scope.record.classes) + 1 === _.size($scope.classes))){
          $scope.classes = _.filter($scope.classes, item => item.id !== '-1');
          return;
        }
        // 没选中"全部班级"，人数没有累加到全部班级
        if(!isChoiceAll && !isHasAll && (_.size($scope.record.classes) !== _.size($scope.classes))){
          $scope.classes.unshift({
            id: '-1',
            levelName: '',
            gradeName: '',
            rxnd: '',
            className: '全部班级',
          });
          return;
        }
    }
  }
})();
