(function() {
  'use strict';
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.microLessonRecord', {
        url: '/microLessonRecord',
        template: require('assets/templates/manage/microLessonRecord.html'),
        controller: 'microLessonRecordCtrl',
        bodyStyle: 'bg_img'
      });
    }])
  .controller('microLessonRecordCtrl', microLessonRecordCtrl);

  microLessonRecordCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal',
    'notify', 'userInfo', 'oedUserInfo', 'oedMicroLessonRecord', 'oedResource',
    'Lightbox', 'dialogs']
  function microLessonRecordCtrl($scope, $state, $stateParams, $q, $uibModal,
    notify, userInfo, oedUserInfo, oedMicroLessonRecord, oedResource,
    Lightbox, dialogs) {

    $scope.currentState.title = '我的微课';
    loadResources();
    $scope.selectedReses = [];

    function loadResources() {
      $scope.loading = userInfo.then(function(info) {
        return oedMicroLessonRecord.queryAllRecordByTeacher({uid: info.uid}).$promise.then(function(records) {
          var recordDetails = _.map(records, loadRecordItemDetails);
          return $q.all(recordDetails).then(function(data) {
            $scope.records = data;
          });
        });
      });
      return $scope.loading;
    }

    function loadRecordItemDetails(item) {
      item.res = oedResource.get({
        resourceId: item.resourceId
      });
      return item.res.$promise.then(function() {
        item.res.resourceName = item.resourceName;
        return item;
      });
    }

    $scope.showPreview = function(res, idx, event) {
      event.stopPropagation();
      event.preventDefault();

      var allReses = _.map($scope.records, 'res');

      Lightbox.openModal(allReses, idx);
    };

    $scope.getResourceName = function(item) {
      return item.resourceName + '.' + item.res.suffix;
    };

    $scope.isSelected = function(item) {
      return _.indexOf($scope.selectedReses, item) >= 0;
    };

    $scope.toggleSelect = function(item) {
      var idx = _.indexOf($scope.selectedReses, item);
      if (idx >= 0) {
        $scope.selectedReses.splice(idx, 1);
      } else {
        $scope.selectedReses.push(item);
      }
    };

    $scope.deleteSelected = function() {
      var message = '确定要删除选择的微课吗（共' + _.size($scope.selectedReses) + '项）？：';
      _.each($scope.selectedReses, function(res) {
        message = message + '<br>' + res.resourceName;
      });
      var dlg = dialogs.confirm('确定删除?', message);

      dlg.result.then(function() {
        $scope.loading = $q(function(resolve, reject) {
          var items = $scope.selectedReses;
          var promises = _.map(items, function(item) {
            return oedMicroLessonRecord.delete({
              id: item.id
            }).$promise;
          });

          $q.all(promises).finally(function() {
            $scope.selectedReses = [];
            loadResources();
          });
        });
      });
    };

  }
})();
