(function() {
  'use strict';

  var _ = require('lodash');
  var confirmDialog = require('app2/common/confirmDialog.ctrl');
  var cloudCss = require('assets/styles/cloud.css');
  var cloudResBox = require('app2/directives/cloud/cloudResBox.directive');
  var expandableList = require('app2/directives/utils/expandableList.directive');
  var expandableGroupList = require('app2/directives/utils/expandableGroupList.directive');
  var sectionSelector = require('app2/directives/course/sectionSelector.directive');
  var questionTypeSelector = require('app2/directives/course/questionTypeSelector.directive');
  var talcloudQuestionTypeSelector = require('app2/directives/course/talcloudQuestionTypeSelector.directive');

  var cloudResListItemObjTest = require('app2/directives/cloud/cloudResListItemObjTest.directive');
  var cloudResListItemSubjTest = require('app2/directives/cloud/cloudResListItemSubjTest.directive');
  var cloudResListItemTeachingPattern = require('app2/directives/cloud/cloudResListItemTeachingPattern.directive');
  var courseSelector = require('app2/directives/cloud/courseSelector.directive');
  var resourceTypeFilter = require('app2/directives/cloud/resourceTypeFilter.directive');

  var resourceUtils = require('app2/utils/resourceUtils');
  var materialSelector = require('app2/directives/course/materialSelector.directive');
  var knolwedgePointSelector = require('app2/directives/course/knowledgePointSelector.directive');
  var subjectSelector = require('app2/directives/course/subjectSelector.directive');
  var messages = require('app2/utils/messages');
  const convertSectionTreeToIdMap = require('app2/utils/materialUtils').convertSectionTreeToIdMap;

  require('app2/directives/cloud/thirdPartySelector.directive')
  require('app2/integration/hls/hlsQuestionBrowser.directive')
  require('app2/integration/hls/hlsResourceBrowser.directive')
  require('app2/directives/drawPlay/drawPlaySelector.directive')

  const thirdParties = [{
    cls: 'vendor-haoweilai',
    displayName: '好未来',
    name: 'haoweilai',

  }, {
    cls: 'vendor-hls',
    displayName: '华乐思',
    name: 'hualesi',

  }, {
    cls: 'vendor-oed',
    displayName: '锐学堂',
    name: 'oed',
  }]

  angular.module('app.prepareCourse.importFromLibraryCtrl', [
    'app.common.confirmDialog',
    'app.directives.cloud.cloudResBox',
    'app.directives.cloud.cloudResListItemObjTest',
    'app.directives.cloud.cloudResListItemSubjTest',
    'app.directives.cloud.cloudResListItemTeachingPattern',
    'app.directives.cloud.courseSelector',
    'app.directives.course.materialSelector',
    'app.directives.course.knowledgePointSelector',
    'app.directives.cloud.resourceTypeFilter',
    'app.directives.utils.expandableList',
    'app.directives.utils.expandableGroupList',
    'app.directives.course.sectionSelector',
    'app.directives.course.questionTypeSelector',
    'app.directives.course.talcloudQuestionTypeSelector',
    'app.directives.course.subjectSelector',
    'app.directives.cloud.thirdPartySelector',
    'app.integration.hls.hlsQuestionBrowser',
    'app.integration.hls.hlsResourceBrowser',
    'app.directives.drawPlay.drawPlaySelector',
  ])
    .controller('importFromLibraryCtrl', importFromLibraryCtrl);

  const maxPages = 800

  importFromLibraryCtrl.$inject = [
    '$scope', '$rootScope', '$q', '$state', '$http', '$uibModal', '$uibModalInstance',
    '$log', 'Lightbox', 'notificationService', 'localStorageService',
    'userInfo', 'oedMisc', 'oedSubjects', 'oedUnitItem',
    'oedCloudPresentResItem', 'oedTestUtils', '$timeout',
    'oedCoursePath', 'oedTeachingMaterialVersions', 'oedCourseUtils',
    'oedCloudTest', 'oedCloudSubjectiveTest', 'oedCloudPresentRes',
    'resourceDef', 'oedCloudTag', 'oedSchool', 'hlsResource']
  function importFromLibraryCtrl(
    $scope, $rootScope, $q, $state, $http, $uibModal, $uibModalInstance,
    $log, Lightbox, notificationService, localStorageService,
    userInfo, oedMisc, oedSubjects, oedUnitItem,
    oedCloudPresentResItem, oedTestUtils, $timeout,
    oedCoursePath, oedTeachingMaterialVersions, oedCourseUtils,
    oedCloudTest, oedCloudSubjectiveTest, oedCloudPresentRes,
    resourceDef, oedCloudTag, oedSchool, hlsResource) {
    var allSubject = {
      $isAll: true,
      id: -1,
      name: '全部教材'
    };
    $scope.originalType = [
      'yesorno',
      'singlechoice',
      'multichoice',
      'connect',
      'fill',
      'vote',
      'synthetic',
      'qa'
    ];
    $scope.homeworkMode = resourceDef.mode === 'homework';
    $scope.showQuesitonTypeChoose = resourceDef.name === '客观试卷';
    $scope.sectionNameDisplay = '';
    $scope.queryTextDisplay = '';
    $scope.choosedQuestionType = [];

    $scope.selectedVendor = 'oed'

    $scope.resourceDef = _.defaults(resourceDef, {
      itemTemplateUrl: 'Assets/templates/prepareCourse/defaultCloudResourceItem.html',
      viewType: 'question'
    });

    if ($scope.homeworkMode) {
      $scope.viewType = 'test';
      $scope.resourceDef.viewType = 'test';
    }

    $scope.showFilterTypeSelector = !!resourceDef.showFilterTypeSelector;

    $scope.filterTypes = [{
      name: '我的',
      value: 'mine'
    },{
      name: '同步教材',
      value: 'section'
    }, {
      name: '专题',
      value: 'topic',
      disabledVendor: ['haoweilai'],
    }, {
      name: '知识点',
      value: 'knowledge',
      viewType: 'question',
      vendor: ['haoweilai'],
    }];

    $scope.isVendorSupported = (filterType) => {
      const vendors = _.get(filterType, 'vendor', [])
      const thirdParty = _.get($scope.userInfo, 'profile.thirdParty', '')

      const checkBlackList = () => {
        const vendor = $scope.selectedVendor
        /**
         * 检查是否此厂商不支持
         **/
        const blacklist = _.get(filterType, 'disabledVendor', [])
        if (_.isEmpty(blacklist)) {
          return true
        }

        return _.indexOf(blacklist, vendor) < 0
      }

      if (_.isEmpty(vendors)) {
        return checkBlackList()
      }

      if (filterType.viewType && filterType.viewType != $scope.viewType) {
        return false
      }

      return _.indexOf(vendors, thirdParty) >= 0 && checkBlackList() && _.indexOf(vendors, $scope.selectedVendor) >= 0
    }

    if (!$scope.homeworkMode) {
      $scope.filterTypes.splice(0, 1);
      if (resourceDef.type === 'teachingDesign') {
        $scope.filterTypes.splice(1, 1);
      }
    } else {
      $scope.filterTypes.splice(0, 1);
      $scope.filterTypes.splice(1, 1);
    }

    /**用于标记是否为用户操作关闭此对话框*/
    var isCodeClose = false;

    //
    // 默认按章节
    $scope.curFilterType = resourceDef.curFilterType || 'section';

    $scope.sortType = resourceDef.defaultSortType || 'downloads';
    $scope.desc = true;
    $scope.countPerPage = 10;

    $scope.selected = {};
    $scope.viewType = $scope.resourceDef.viewType;
    $scope.curPage = 1;

    $scope.localState = {};

    $scope.extraSortTypes = resourceDef.extraSortTypes || [];

    $scope.sectionIdNameMap = {};

    var curCloudRes;
    var curCloudResPromise;
    if (resourceDef.excludeCurrent) {
      curCloudRes = resourceDef.res.getByResId({
        id: resourceDef.currentRes.id
      });
      curCloudResPromise = curCloudRes.$promise;
    }

    $scope.close = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.courseInfo = {};
    $scope.queryOpt = {};
    $scope.queryString = null;
    $scope.tags = [];

    $scope.courseFiler = {};

    var resourceConf = {
      test: {
        query: function() {
          $scope.totalNums = 0;
          return oedTestUtils.loadCloudTestsByCourseId($scope.courseFilter.courseId);
        },
        queryBySections: function(queryOpt) {
          return oedTestUtils.queryCloudTests(queryOpt);
        },
        preview: function(res) {
          var modalInstance = $uibModal.open({
            template: require('assets/templates/prepareCourse/cloudTestPreviewer.html'),
            controller: 'cloudTestPreviewerCtrl',
            size: 'lg',
            resolve: {
              cloudTest: function() {
                return res;
              },
              resourceDef: function() {
                return getResourceDefForHomework('cloudTest');
              }
            },
            windowClass: 'cl-preview-readonly'
          });

          return modalInstance.result;
        }
      },
      subjectiveTest: {
        query: function() {
          return oedTestUtils.loadCloudSubjTestByCourseId($scope.courseFilter.courseId);
        },
        queryBySections: function(queryOpt) {
          return oedTestUtils.queryCloudSubjTest(queryOpt);
        },
        preview: function(res) {
          var modalInstance = $uibModal.open({
            template: require('assets/templates/prepareCourse/cloudSubjectiveTestPreviewer.html'),
            controller: 'cloudSubjectiveTestPreviewerCtrl',
            size: 'lg',
            resolve: {
              cloudTest: function() {
                return res;
              },
              resourceDef: function() {
                return getResourceDefForHomework('cloudSubjTest');
              }
            },
            windowClass: 'cl-preview-readonly'
          });

          return modalInstance.result;
        }
      },
      resource: {
        query: function() {
          return oedCourseUtils.loadCloudPresentResourceByCourseId($scope.courseFilter.courseId);
        },
        queryBySections: function(queryOpt) {
          return oedCourseUtils.queryCloudPresentRes(queryOpt);
        },
        preview: function(res) {
          var modalInstance = $uibModal.open({
            template: require('assets/templates/prepareCourse/cloudPresentResPreviewer.html'),
            controller: 'cloudPresentResPreviewerCtrl',
            size: 'lg',
            resolve: {
              cloudRes: function() {
                return res;
              },
              currentRes: function() {
                return {
                  id: 0
                };
              },
              resourceDef: function() {
                return getResourceDefForHomework('cloudPresentRes');
              }
            },
            windowClass: 'cl-preview-readonly'
          });

          return modalInstance.result;
        }
      }
    };

    var userInfoPromise = userInfo.then(function(info) {
      if (oedCloudPresentRes === resourceDef.res || oedCloudTest === resourceDef.res) {
        $scope.selectedVendor = _.get(info, 'profile.thirdParty', 'oed') === 'haoweilai' ? 'haoweilai' : 'oed'
      }
      $scope.parties = []
      const schoolId = _.parseInt(_.get(info, 'schoolId', -1))
      oedSchool.get({
        id: schoolId
      }).$promise.then(s => {
        $scope.school = s;
        const features = _(s.enabledFeatures).split(',').map(_.trim).keyBy().value()
        $scope.parties.push(thirdParties[2]);
        if (_.has(features, 'resource_haoweilai')) {
          $scope.parties.push(thirdParties[0]);
        }
        if (_.has(features, 'resource_hls')) {
          $scope.parties.push(thirdParties[1]);
        }
      });
      $scope.allCoursePaths = oedCoursePath.queryFilter({
        userId: _.parseInt(info.uid),
        onGoing: true,
        unStarted: true,
        ended: true
      });

      $scope.allCoursePaths.$promise.then(function(cps) {
        var lastCpId = localStorageService.get('library.mine.lastCoursePathId');
        var lastCsId = localStorageService.get('library.mine.lastCourseId');

        if (_.isEmpty(cps)) {
          $scope.courseFilter = {};
        } else {
          $scope.courseFilter = {
            coursePathId: lastCpId || cps[0].id,
            courseId: lastCsId || 0
          };
        }
      });
      return info;
    });

    var coursePathPromsie = null;
    if (_.has(resourceDef, 'course.coursepathId')) {
      $scope.coursePath = oedCoursePath.get({
        id: resourceDef.course.coursepathId
      });

      coursePathPromsie = $scope.coursePath.$promise;
    } else {
      //
      // 没有coursePath, 则自动选中上一次选择的coursePath或当前用户的coursePath中的的
      coursePathPromsie = userInfoPromise.then(function() {
        var lastSubjectId = localStorageService.get('library.section.lastSubjectId');
        var lastVersionId = localStorageService.get('library.section.lastVersionId');
        var lastGradeId = localStorageService.get('library.section.lastGradeId');
        if (!lastSubjectId || !lastVersionId || !lastGradeId) {
          return $scope.allCoursePaths.$promise.then(function(cps) {
            var lastCpId = localStorageService.get('library.section.lastCoursePathId');
            return _.find(cps, function(c) {
              return c.id === lastCpId;
            }) || _.get(cps, '0', null);
          });
        } else {
          //
          // 返回一个mock的coursePath对象
          return {
            subjectId: lastSubjectId,
            versionId: lastVersionId,
            gradeId: lastGradeId
          };
        }
      });
    }

    $scope.sectionTree = {};

    //
    // 初始化对话框
    $scope.loading = $q.all([userInfoPromise, coursePathPromsie]).then(function(results) {
      var info = results[0];
      var coursePath = results[1];
      //保存当前操作的科目版本ID
      $scope.userInfo = info
      $scope.coursePath = coursePath

      $scope.schoolId = info.schoolId;
      return reloadVersions(info, coursePath)
    });

    function reloadVersions(info, coursePath) {
      //
      // 当前科目的gradeId
      var gradeId;
      $scope.subjects = oedSubjects.queryBySchoolId({
        schoolId: info.schoolId,
        vendor: $scope.selectedVendor,
      });

      $scope.selectSubject = function(sub) {
        $scope.subjectId = sub.id;
      };

      $scope.onSubjectSelected = function(item, model, updateSections, query, search) {
        $scope.selectedSubject = item;
        $scope.courseInfo.teachingMaterial = null;
        gradeId = null;
        $scope.subjectId = item.id;

        if (search) {
          return $scope.doSearch(true);
        }

        $scope.versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
          schoolId: info.schoolId,
          subjectId: item.id,
          vendor: $scope.selectedVendor,
        });

        if (updateSections) {
          $scope.selectedVersion = {
            $isAll: true
          };
        }

        $scope.loading = $scope.versions.$promise.then(function(versions) {
          return versions;
        });

        if (updateSections) {
          $scope.queryOpt.sectionIds = [];
          $scope.selectedNodes = [];

          $scope.sections = [];
          $scope.sectionIdNameMap = {};
        }

        return $scope.versions.$promise.then(function(items) {
          if (query) {
            return $scope.queryItems(true);
          }

          return items;
        });
      };

      $scope.onQuestionTypeSelected = function(list) {
        $scope.choosedQuestionType = list;
        $scope.curPage = 1;
        $scope.queryItems(true);
      };

      $scope.onVersionSelected = function(item, model, updateSections, query) {
        $scope.courseInfo.section = null;
        $scope.curPage = 1;
        $scope.selectedVersion = item;
        if (item.$isAll && query) {
          gradeId = -1;
          $scope.sections = [];
          $scope.sectionIdNameMap = {};
          $scope.sectionData = [];
          $scope.sectionIds = [];

          return $scope.queryItems(true);
        }
        $scope.sectionNameDisplay = '全部章节';
        //保存当前的versiond
        gradeId = item.gradeId;
        //保存当前的subjectId
        $scope.subjectId = item.subjectId;
        localStorageService.set('library.section.lastSubjectId', item.subjectId);
        localStorageService.set('library.section.lastVersionId', item.versionId);
        localStorageService.set('library.section.lastGradeId', item.gradeId);

        $scope.sections = oedUnitItem.queryByVersionSubjectIdAndGradeLeaf(
          _.pick(item, ['versionId', 'subjectId', 'gradeId'])
        );
        $scope.loading = $scope.sections.$promise;

        $scope.sectionIdMap = {};

        if (updateSections) {
          $scope.queryOpt.sectionIds = [];
          $scope.selectedNodes = [];

        }

        $scope.sections.$promise.then(function(sections) {
          $scope.sectionData = sections;
          $scope.sectionIdNameMap = convertSectionTreeToIdMap(sections);

          function visitSection(sec) {
            if (sec.id) $scope.sectionIdMap[sec.id] = sec;
            if (sec.child) {
              sec.child = _.sortBy(sec.child, ['sort']);
              _.each(sec.child, visitSection);
            }
          }
          _.each(sections, visitSection);

          return sections;
        });

        return $scope.sections.$promise.then(function(items) {
          if (query) {
            return $scope.queryItems(true);
          }

          return items;
        });
      };

      $scope.queryItems = function(queryCount) {
        $scope.queryString = _.toUpper($scope.queryText);

        $scope.localState.allSelected = false;

        if ($scope.curFilterType === 'mine') {
          return $scope.queryCourseItems();
        }
        var tagStr = null;
        var subjectId = $scope.subjectId;
        var secIds = _.map($scope.queryOpt.sectionIds, _.parseInt)
        var filteredGradeId = gradeId;
        var versionId = _.get($scope.selectedVersion, 'versionId');
        if ($scope.curFilterType === 'topic') {
          $scope.refreshSubjectFilter();
          if (!resourceDef.isObjectiveResource) {
            $scope.items = [];
            return;
          }
          tagStr = JSON.stringify($scope.tags);
          secIds = [];
          subjectId = null;
          filteredGradeId = null;
          versionId = null;
        }
        if (_.isEmpty($scope.choosedQuestionType)) {
          $scope.choosedQuestionType = $scope.originalType;
        }

        return $q.when(curCloudResPromise).then(function() {
          var excludeIds = [];
          // if (resourceDef.excludeCurrent) {
          //   excludeIds = [curCloudRes.id];
          // }
          // 更新列表
          // 通过章节id获得客观试卷列表
          $scope.queryOpt = {
            vendor: $scope.selectedVendor,
            sectionIds: secIds,
            curPage: $scope.curPage - 1,
            countPerPage: $scope.countPerPage,
            sortType: $scope.sortType,
            desc: $scope.desc,
            excludeIds: excludeIds,
            query: $scope.queryString,
            subjectId: subjectId,
            versionId: versionId,
            gradeId: filteredGradeId,
            minScore: suggestMinScore($scope.queryString),
            tags: tagStr,
            questionTypes: $scope.choosedQuestionType
          };

          if ($scope.curFilterType === 'knowledge') {
            $scope.queryOpt.kpIds = _.map($scope.knowledgePoints, 'id')
          }

          if (!$scope.subjectId || $scope.subjectId === -1) {
            $scope.queryOpt = _.omit($scope.queryOpt, ['subjectId']);
          }

          if (!$scope.queryOpt.versionId || gradeId === -1) {
            $scope.queryOpt = _.omit($scope.queryOpt, ['versionId', 'gradeId']);
          }

          //
          // TODO: refactor 资源库设计
          // 现在各种资源的view相对独立, 需要拆分成不同的controller
          var queries = null;
          if (!$scope.homeworkMode && _.isFunction(resourceDef.queryRes)) {
            //
            //  返回两个promise, 第一个是资源列表, 第二个是资源数
            //resourceDef.queryRes($scope.queryOpt, $scope.viewType);
            $scope.queryRetryType = null;
            queries = doQueryWithFailback($scope.queryOpt);
          } else if ($scope.homeworkMode) {
            if (!_.has(resourceConf, $scope.selectedType)) {
              $log.error('query by sections not supported for ' + $scope.selectedType);
            }

            queries = resourceConf[$scope.selectedType].queryBySections($scope.queryOpt);
          }
          if (queries) {
            if (_.isArray(queries)) {
              $scope.loading = $q.all(queries).then(function(data) {
                $scope.items = _.get(data, '0', [])
                $scope.totalNums = _.get(data, '1.data', 0)
              });
            } else {
              $scope.loading = queries.then(function(ret) {
                $scope.items = _.get(ret, 0, [])
                $scope.totalNums = _.get(ret, '1.data', 0)
              });
            }

            return $scope.loading;
          }

          var items = resourceDef.res.queryBySection($scope.queryOpt);
          items.$promise.then(function(items) {
            $scope.items = items;
          });

          var countReq;
          if (queryCount) {
            countReq = $http.post(resourceDef.queryCountURL, $scope.queryOpt)
              .then(function(resp) {
                $scope.totalNums = resp.data;
              });
          }

          $scope.loading = $q.all([items.$promise, countReq]);
          return $scope.loading;
        });
      };

      $scope.onPageChange = function(name, curPage) {
        var el = $('.cl-content-wrapper');
        el.scrollToElementAnimated($('.scrollTopLabel'));
        return $scope.queryItems(false);
      };

      $scope.subjects.$promise.then(function(subjects) {
        var subjectItems = _.map(subjects, function(s) {
          return {
            id: s.id,
            name: s.subjectName
          };
        });

        $scope.subjectItems = subjectItems;

        if (coursePath) {
          $scope.courseInfo.subject = _.find(subjectItems, function(sub) {
            return sub.id == coursePath.subjectId;
          });
        } else {
          $scope.courseInfo.subject = subjectItems[0];
        }

        $scope.selectedSubject = getCurrentSubject($scope.selectedSubject, $scope.courseInfo.subject, subjectItems);
        if ($scope.selectedSubject) {
          return $scope.onSubjectSelected($scope.selectedSubject, null, null, false);
        }
        return null;
      }).then(function(versions) {
        if (coursePath && coursePath.versionId) {
          $scope.courseInfo.teachingMaterial = _.find(versions, function(ver) {
            return ver.gradeId == coursePath.gradeId &&
              ver.versionId == coursePath.versionId &&
              ver.subjectId == coursePath.subjectId;
          });
        } else {
          $scope.courseInfo.teachingMaterial = $scope.versions[0];
        }

        $scope.selectedVersion = getCurrentVersion($scope.selectedVersion, coursePath, versions);

        if ($scope.selectedVersion) {
          return $scope.onVersionSelected($scope.selectedVersion, null, false, false);
        }
        return null;
      }).then(function(unitItems) {
        var relSections = _.get(resourceDef, 'course.relatedSections', []);
        if (!_.isEmpty(relSections)) {
          $scope.queryOpt.sectionIds = _.map(resourceDef.course.relatedSections, function(relSec) {
            return relSec.sectionId;
          });
          if (_.isEmpty($scope.queryOpt.sectionIds)) {
            $scope.sectionNameDisplay = '选择章节';
          } else {
            oedCourseUtils.loadSectionNameArrayById($scope.queryOpt.sectionIds).then(function(names) {
              $scope.sectionNameDisplay = names.join(', ');
            });
          }
        }
      }).finally(function() {
        $scope.queryItems(true);
      });
    }

    $scope.groupByParentName = function(node) {
      return node.parent ? node.parent.name : '';
    };

    var viewTypeKinds = {
      objectiveTest: 'test',
      question: 'question',
      presentResource: 'resource',
      presentResourceItem: 'resitem'
    };
    //
    // @returns Promise, 它的值为搜索的结果
    var tryDoSearchById = function(text) {
      if (!_.has($scope.resourceDef, 'queryResById')) {
        return $q.resolve({});
      }

      return $scope.resourceDef.queryResById({
        queryId: text
      }).then(function(item) {
        var resId = oedCourseUtils.getCloudResTypeAndId(text);
        var viewType = viewTypeKinds[_.get(resId, 'kind')];
        return {
          viewType: viewType,
          item: item
        };
      });
    };

    function doPreviewSingleResItem(viewType, data) {
      //
      // 按id搜索找到了资源, 打开资源
      if (!$scope.homeworkMode) {
        return getResourceDefWithResBoxSupport().importFromLibraryByResId(viewType, data);
      } else {
        return previewResourceItem(data);
      }
    }

    $scope.doSearch = function(keepSortType) {
      $scope.choosedQuestionType = [];
      $scope.selectedVersion = {
        $isAll: true
      };
      $scope.queryOpt.sectionIds = [];
      $scope.selectedNodes = [];
      $scope.sections = [];
      $scope.sectionNameDisplay = '全部章节';
      $scope.queryString = _.toUpper($scope.queryText);
      if (!keepSortType) {
        if (_.isEmpty($scope.queryString))
          $scope.sortType = resourceDef.defaultSortType || 'downloads';
        else
          $scope.sortType = 'score';
      }
      $scope.desc = true;
      $scope.queryTextDisplay = $scope.queryText;

      $scope.loading = tryDoSearchById($scope.queryString).then(function(item) {
        if (_.get(item.item, 'id')) {
          var dlgs = $('.shouldHideOnPreview');
          dlgs.css('display', 'none');
          //
          // 按id搜索找到了资源, 打开资源
          return doPreviewSingleResItem(item.viewType || $scope.viewType, {
            query: $scope.queryString,
            res: item.item
          }).then(function(ret) {
            if (_.get(ret, 'action') === 'showResBox') {
              dlgs.css('display', 'block');
              $scope.doPreviewResBox($scope.questionsToImport);
              return;
            }

            if (!$scope.homeworkMode) {
              $uibModalInstance.close('imported');
            } else {
              dlgs.css('display', 'block');
            }
          }, function() {
            dlgs.css('display', 'block');
          });
        } else {
          $scope.selected.res = null;
          $scope.curPage = 1;
          return $scope.queryItems(true);
        }
      });
    };

    $scope.onAllToggled = function() {
      _.each($scope.items, function(item) {
        if (!$scope.canImportItem(item)) {
          item.selected = false;
          return;
        }
        item.selected = $scope.localState.allSelected;
      });
    };

    $scope.onItemToggled = function() {
      $scope.localState.allSelected = false;
    };

    $scope.previewRes = function(res, event) {
      event.preventDefault();
      event.stopPropagation();

      var dlgs = $('.shouldHideOnPreview');
      dlgs.css('display', 'none');
      var preview = null;
      if (!$scope.homeworkMode) {
        if (!resourceDef.canImportResBox) {
          preview = resourceDef.doPreview(res);
        } else {
          //
          // 对支持resbox 的资源使用加入出题筐的导入行为
          preview = resourceDef.doPreview(res, getResourceDefWithResBoxSupport());
        }
      } else {
        if (!_.has(resourceConf, $scope.selectedType)) {
          $log.error('preview not supported for ' + $scope.selectedType);
        }
        preview = resourceConf[$scope.selectedType].preview(res);
      }

      preview.then(function(ret) {
        if (_.get(ret, 'action') === 'showResBox') {
          $scope.doPreviewResBox($scope.questionsToImport);
        }
      });

      preview.finally(function() {
        dlgs.css('display', 'block');
      });
    };

    function importResources(res, keepOpen) {
      if (_.isEmpty(res)) {
        //
        // 没有选择资源
      } else {
        var deferred = $q.defer();
        $scope.loading = deferred.promise;
        deferred.promise.then(function() {
          notificationService.notify('info', '资源导入成功')

          if (!keepOpen) {
            $uibModalInstance.close('close');
          }
        }, null, function(update) {
          $scope.progressMsg = update;
        }).finally(function() {
          $scope.progressMsg = '';
        });

        resourceDef.doImport(res, deferred, $scope.viewType);
      }
    }

    $scope.importSelected = function() {
      if ($scope.disableImport()) {
        return $q.resolve(null);
      }

      var res = null;
      var deferred = null;
      if (resourceDef.canImportResBox) {
        deferred = $q.defer();
        $scope.loading = deferred.promise;
        deferred.promise.then(function() {
          $uibModalInstance.close('close');
        }, null, function(update) {
          $scope.progressMsg = update;
        }).finally(function() {});

        return resourceDef.doImport($scope.questionsToImport, deferred, 'resBox');
      }

      if (resourceDef.canImportMultiple) {
        res = _.filter($scope.items, function(item) {
          return item.selected && $scope.canImportItem(item);
        });
      } else {
        if ($scope.selected.res && $scope.canImportItem($scope.selected.res)) {
          res = [$scope.selected.res];
        }
      }

      if (!res) {
        notificationService.notify('info', '没有选中资源, 请选中资源后再点击导入')

        return null;
      }

      return importResources(res);
    };

    $scope.downloadSelected = function() {
      var res = null;
      if (resourceDef.canImportMultiple) {
        res = _.filter($scope.items, function(item) {
          return item.selected;
        });
      } else {
        if ($scope.selected.res) {
          res = [$scope.selected.res];
        }
      }

      if (_.isEmpty(res)) {
        //
        // 没有选择资源
      } else {
        var deferred = $q.defer();
        $scope.loading = deferred.promise;
        deferred.promise.then(function() {}, null, function(update) {
          $scope.progressMsg = update;
        }).finally(function() {
          $scope.progressMsg = '';
        });

        resourceDef.doDownload(res, deferred, $scope.viewType);
      }
    };

    $scope.toggleSortType = function(type) {
      if ($scope.sortType != type) {
        $scope.sortType = type;
        $scope.desc = true;
      } else {
        if (type !== 'score') {
          $scope.desc = !$scope.desc;
        }
      }

      //更新列表
      $scope.queryItems();
    };

    $scope.preventDropdownDefault = function(event) {
      event.preventDefault();
      event.stopPropagation();
    };

    var fileTypes = {
      audio: {
        iconClass: 'oed-file-type-new fa fa-file-audio-o',
        tooltip: '音频'
      },
      file: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '文件'
      },
      image: {
        iconClass: 'oed-file-type-new fa fa-file-photo-o',
        tooltip: '图片'
      },
      link: {
        iconClass: 'oed-file-type-new fa fa-link',
        tooltip: '链接'
      },
      office: {
        iconClass: 'oed-file-type-new fa fa-file-word-o',
        tooltip: '文档'
      },
      video: {
        iconClass: 'oed-file-type-new fa fa-file-video-o',
        tooltip: '视频'
      },
      unknown: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '其他文档'
      }
    };

    $scope.getFileTypeIconClass = function(type) {
      if (!fileTypes[type]) return null;
      return fileTypes[type].iconClass;
    };

    $scope.getFileTypeTooltip = function(type) {
      if (!fileTypes[type]) return '';
      return fileTypes[type].tooltip;
    };

    $scope.getRelatedMaterialVersion = function(res) {
      return _.get(res, 'materialVersion.versionName', '') + ' - ' + _.get(res, 'materialVersion.name', '');
    };

    var getRelatedSectionsNew = function(res) {
      if (_.isEmpty(_.get(res, 'sectionsNew[0].child'))) {
        return '无';
      }

      var childs = _.get(res, 'sectionsNew[0].child');
      return _.map(childs, function(c) {
        return c.name;
      }).join(',');
    };

    $scope.getRelatedSections = function(res) {
      if (_.isEmpty(res.sections)) {
        return getRelatedSectionsNew(res);
      }

      return _.map(res.sections, function(section) {
        return section.name;
      }).join(',');
    };

    $scope.searchByResId = function() {
      var dlgs = $('.shouldHideOnPreview');
      dlgs.css('display', 'none');
      getResourceDefWithResBoxSupport().importFromLibraryByResId($scope.viewType).finally(function() {
        if (resourceDef.shouldDismissParent) {
          $uibModalInstance.close();
        } else {
          dlgs.css('display', 'block');
        }
      });
    };

    $scope.toggleViewType = function(t) {
      if ($scope.viewType === t) {
        return;
      }
      if (t === 'question' && resourceDef.name === '客观试卷') {
        $scope.showQuesitonTypeChoose = true;
      } else {
        $scope.showQuesitonTypeChoose = false;
      }
      $scope.viewType = t;
      $scope.items = []; //viewType改变时应先清空内容
      if (_.get($scope, 'curFilterType', '') === 'knowledge' && $scope.viewType != 'question') {
        $scope.curFilterType = 'section'
      }

      $scope.curPage = 1;
      $scope.queryItems(true);
    };

    $scope.toggleSelectQuestion = function(res) {
      res.selected = !res.selected;
    };

    $scope.onImportQuestionClicked = function(res, evt) {
      evt.stopPropagation();
      evt.preventDefault();

      importResources([res], true);
    };

    $scope.canImportItem = function(item) {
      if (!resourceDef.excludeCurrent) {
        return true;
      }

      return curCloudRes.id != item.id;
    };

    $scope.toggleSelect = function(item, event) {
      event.preventDefault();
      event.stopPropagation();
      item.selected = !item.selected;
    };

    $scope.onGoToTest = function(ct) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/cloudTestPreviewer.html'),
        controller: 'cloudTestPreviewerCtrl',
        size: 'lg',
        resolve: {
          cloudTest: function() {
            return ct;
          },
          resourceDef: function() {
            return $scope.resourceDef;
          }
        }
      });

      return modalInstance.result;
    };

    //
    // 预览单个资源项
    $scope.onCloudResItemClicked = function(res, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      var allReses = _.map($scope.items, 'res');

      var idx = _.findIndex($scope.items, res);
      if (idx < 0) {
        idx = 0;
      }
      oedCloudPresentResItem.getView({
        id: $scope.items[idx].id
      });

      Lightbox.openModal(allReses, idx, {
        size: 'new-lib-light-box'
      });
    };

    $scope.onImportResItemClicked = function(res, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      var curRes = resourceDef.currentRes;
      $scope.loading = curRes.$importResourceItem({
        item_id: res.res.id
      }).then(function(resp) {
        $rootScope.$broadcast('presentResource.changed', {
          resId: curRes.id
        });

        return oedCloudPresentResItem.getDownload({
          id: res.id
        }).$promise;
      }).catch(function(err) {
        if (err.status == 409) {
          notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
        }
      });
    };

    $scope.onSelectSections = function(items) {
      $scope.queryOpt.sectionIds = items;

      oedCourseUtils.loadSectionNameArrayById($scope.queryOpt.sectionIds).then(function(names) {
        $scope.sectionNameDisplay = names.join(', ');
      });

      $scope.totalNums = 0;
      $scope.curPage = 1;
      $scope.queryOpt.sectionIds = items;
      //清理选中的Res列表
      $scope.selected.res = null;
      $scope.queryItems(true);
    };

    $scope.onSelectKnowledgePoints = (items) => {
      $scope.knowledgePoints = items
      $scope.curPage = 1
      $scope.queryItems(true)
    }

    $scope.addCloudTest = function(res, event) {
      if (!checkAddToResBox(res, 'cloudTest')) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: res,
        resType: 'cloudTest',
        event: event
      };
    };

    $scope.addCloudQuestion = function(res, event) {
      if (!checkAddToResBox(res, 'cloudQuestion')) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: res,
        resType: 'cloudQuestion',
        event: event
      };
    };

    $scope.addCloudSubTest = function(res, event) {
      if (!checkAddToResBox(res, 'cloudSubjTest')) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: res,
        resType: 'cloudSubjTest',
        event: event
      };
    };

    $scope.addCloudPresentRes = function(res, event) {
      event.stopPropagation();
      event.preventDefault();

      if (!checkAddToResBox(res, 'cloudPresentRes')) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: res,
        resType: 'cloudPresentRes',
        event: event
      };
    };

    $scope.addCloudPresentResItem = function(res, event) {
      event.stopPropagation();
      event.preventDefault();

      if (!checkAddToResBox(res, 'cloudPresentResItem')) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: res,
        resType: 'cloudPresentResItem',
        event: event
      };
    };

    $scope.questionsToImport = [];
    $scope.onAddCloudRes = function(res, resType) {
      if ($scope.homeworkMode) {
        addCloudResToResBox(res, resType);
        return null;
      }

      switch (resType) {
        case 'cloudTest':
          return $scope.onAddCloudTest(res);
        case 'cloudQuestion':
          return $scope.onAddCloudQuestion(res);
        case 'cloudPresentRes':
          return $scope.onAddCloudPresentRes(res);
        case 'cloudPresentResItem':
          return $scope.onAddCloudPresentResItem(res);
        default:
          console.log('unsupported type: ' + resType);
      }
      return null;
    };

    $scope.onAddCloudTest = function(res) {
      var existingHashes = _.map($scope.questionsToImport, oedTestUtils.computeQuestionHash);
      var hashes = _.keyBy(existingHashes);
      var questionsToAdd = _.filter(res.test.questions, function(q) {
        return !_.has(hashes, oedTestUtils.computeQuestionHash(q));
      }).map(function(q) {
        return _.assign({}, q, {
          $cloudTestId: res.id
        });
      });

      $scope.questionsToImport = _.concat($scope.questionsToImport, questionsToAdd);
    };

    $scope.onAddCloudQuestion = function(res) {
      var existingHashes = _.map($scope.questionsToImport, oedTestUtils.computeQuestionHash);
      var hashes = _.keyBy(existingHashes);
      if (_.has(hashes, oedTestUtils.computeQuestionHash(res.question))) {
        return;
      }

      $scope.questionsToImport = _.concat($scope.questionsToImport, res.question);
    };

    $scope.onAddCloudPresentRes = function(res) {
      var existingHashes = _.map($scope.questionsToImport, resourceUtils.computeHashForResourceItem);
      var hashes = _.keyBy(existingHashes);
      var items = _.get(res, 'res.items', []);
      var itemsToAdd = _.filter(items, function(it) {
        return !_.has(hashes, resourceUtils.computeHashForResourceItem(it));
      }).map(function(item) {
        return _.assign({}, item, {
          $cloudResId: res.id
        });
      });

      $scope.questionsToImport = _.concat($scope.questionsToImport, itemsToAdd);
    };

    $scope.onAddCloudPresentResItem = function(res) {
      var existingHashes = _.map($scope.questionsToImport, resourceUtils.computeHashForResourceItem);
      var item = res.res;
      var hashes = _.keyBy(existingHashes);
      if (_.has(hashes, resourceUtils.computeHashForResourceItem(item))) {
        return;
      }

      $scope.questionsToImport = _.concat($scope.questionsToImport, item);
    };

    $scope.doPreviewResBox = function(items) {
      if (_.isEmpty(items)) {
        notificationService.notify('info', messages['importResource/noResourceAdded'])
        return;
      }
      $scope.resourceDef.doPreviewResBox(items).then(function(ret) {
        if (_.get(ret, 'imported', false)) {
          isCodeClose = true;
          $uibModalInstance.close('close');
        }
      });
    };

    $scope.importSubjectTest = function(test, event) {
      if ($scope.homeworkMode) {
        return $scope.addCloudSubTest(test, event);
      }
      return importResources([test.cloudResource], false);
    };

    function checkAddToResBox(res, resType) {
      if (!canAddToResBox(res, resType)) {
        notificationService.notify('info', '该资源已加入到出题筐')
        return false;
      }
      return true;
    }

    $scope.canAddToResBox = canAddToResBox;
    function canAddToResBox(res, resType) {
      if ($scope.homeworkMode) {
        const getResId = (r) => _.get(r, 'id', _.get(r, 'cloudResource.id', null))
        const resId = getResId(res)
        var existingRes = _.find($scope.questionsToImport, function(r) {
          return r.$type === resType && getResId(r) === resId;
        });

        return !existingRes;
      }

      switch (resType) {
        case 'cloudTest':
          return canAddTestToResBox(res);
        case 'cloudQuestion':
          return canAddQuestionToResBox(res);
        case 'cloudPresentRes':
          return canAddPresentResource(res);
        case 'cloudPresentResItem':
          return canAddResItem(res);
        case 'hlsResItem':
          return canAddHlsResItem(res);
        default:
          return false;
      }
    }

    function canAddTestToResBox(res) {
      var existingHashes = _.map($scope.questionsToImport, oedTestUtils.computeQuestionHash);
      var hashes = _.keyBy(existingHashes);
      var numQuestionsToAdd = _.filter(res.test.questions, function(q) {
        return !_.has(hashes, oedTestUtils.computeQuestionHash(q));
      }).length;

      return numQuestionsToAdd > 0;
    }

    function canAddQuestionToResBox(res) {
      var existingHashes = _.map($scope.questionsToImport, oedTestUtils.computeQuestionHash);
      var hashes = _.keyBy(existingHashes);

      return !_.has(hashes, oedTestUtils.computeQuestionHash(res.question));
    }

    function canAddPresentResource(res) {
      var existingHashes = _.map($scope.questionsToImport, resourceUtils.computeHashForResourceItem);
      var hashes = _.keyBy(existingHashes);
      var items = _.get(res, 'res.items', []);
      var numItemsToAdd = _.filter(items, function(it) {
        return !_.has(hashes, resourceUtils.computeHashForResourceItem(it));
      }).length;
      return numItemsToAdd > 0;
    }

    function canAddResItem(res) {
      var existingHashes = _.map($scope.questionsToImport, resourceUtils.computeHashForResourceItem);
      var item = res.res;
      var hashes = _.keyBy(existingHashes);
      return !_.has(hashes, resourceUtils.computeHashForResourceItem(item));
    }
    function canAddHlsResItem(res) {
      if (_.isNil(res) || !res.resourceID)
        return false;
      return _.findIndex($scope.questionsToImport, q => _.has(q, 'resourceID') &&
        q.resourceID === res.resourceID) === -1;
    }
    $scope.selectedTopic = {};
    $scope.setFilterType = function(ft) {
      $scope.choosedQuestionType = [];
      if (ft.disabled) {
        return;
      }
      $scope.curFilterType = ft.value;

      if ($scope.curFilterType === 'topic') {
        if (!$scope.topics)
          oedCloudTag.getTagsByCategory({
            name: 'topic'
          }).$promise.then(result => {
            var topics  = _.filter(result, r => !_.isEmpty(r.name));
            $scope.topics = topics;
            if (_.size(topics) > 0) {
              $scope.selectedTopic.topic = result[0];
              $scope.tags = [angular.copy($scope.selectedTopic.topic)];
              $scope.queryItems(true);
            }
          });
        else
          $scope.queryItems(true);
      } else
        $scope.queryItems(true);
    };

    $scope.getResultNumberInnerString = function() {
      var innerString = '';
      const verName = _.get($scope.selectedVersion, 'versionName', '')
      if (verName && $scope.queryRetryType != 'allVersions') {
        innerString = $scope.selectedVersion.versionName + $scope.selectedVersion.name;
      } else {
        innerString = '全部教材';
      }

      innerString += '-';
      if (!_.isEmpty($scope.sectionNameDisplay) && _.isEmpty($scope.queryRetryType)) {
        innerString += $scope.sectionNameDisplay;
      } else {
        innerString += '全部章节';
      }
      return innerString;
    };

    $scope.onCourseFilterChanged = function(f) {
      $scope.courseFilter = f;
      localStorageService.set('library.mine.lastCoursePathId', _.get(f, 'coursePathId', 0));
      localStorageService.set('library.mine.lastCourseId', _.get(f, 'courseId', 0));
      $scope.queryItems(true);
    };

    $scope.selectedType = 'test';

    var resourceTemplates = {
      test: 'Assets/templates/prepareCourse/cloudObjectiveTestItem.html',
      subjectiveTest: 'Assets/templates/prepareCourse/cloudSubjectiveTestItem.html',
      resource: 'Assets/templates/prepareCourse/cloudPresentResourceItem.html'
    };

    $scope.onTypeChanged = function(t) {
      $scope.selectedType = t;
      $scope.viewType = $scope.selectedType;
      $scope.resourceDef.itemTemplateUrl = resourceTemplates[t];
      $scope.items = [];
      $scope.totalNums = 0;
      $scope.curPage = 1;
      $scope.queryItems(true);
    };

    $scope.refreshSubjectFilter = function() {
      if (!_.isEmpty($scope.selectedSubject)) {
        var subject = _.find($scope.tags, tag => tag.category === 'subject');
        if (_.isEmpty(subject))
          $scope.tags.push({
            name: $scope.selectedSubject.name,
            category: 'subject',
            storedType: 'string'
          });
        else
          subject.name = $scope.selectedSubject.name;
      }
    };

    $scope.onTagChanged = function(selectedTag) {
      var tag = angular.copy(selectedTag);
      if (tag.id === -1) {
        $scope.tags = _.filter($scope.tags, function(t) {
          return t.category != tag.category;
        });
      } else {
        var oldItem = _.find($scope.tags, t => t.category === tag.category);
        if (_.isEmpty(oldItem))
          $scope.tags.push(tag);
        else
          oldItem.name = tag.name;
      }

      $scope.tags = _.compact(_.map($scope.tags, (v) => {
        return _.pick(v, ['name', 'category', 'storedType']);
      }));

      $scope.refreshSubjectFilter();
      $scope.curPage = 1;
      $scope.queryItems(true);
    };

    $scope.queryCourseItems = function() {
      //
      // 没有课程
      if (_.get($scope.courseFilter, 'courseId', -1) <= 0) {
        $scope.items = [];
        return $q.resolve([]);
      }
      $scope.items = [];

      if (!_.has(resourceConf, $scope.selectedType)) {
        $log.error('not supported type');
      }

      $scope.loading = resourceConf[$scope.selectedType].query().then(function(items) {
        $scope.items = items;
      });

      return $scope.loading;
    };

    function getResourceDefForHomework(resType) {
      return {
        importBtnText: '加入出题筐',
        doImport: function(reses, deferred) {
          _.forEach(reses, function(res) {
            addCloudResToResBox(res, resType);
          });

          deferred.resolve();
        },
        homeworkMode: true,
        disableImportItem: true
      };
    }

    //
    // 为homeworkMode使用
    // 添加客观试卷/主观活动/展示资源至出题筐中
    function addCloudResToResBox(res, type) {
      const getResId = (r) => _.get(r, 'id', _.get(r, 'cloudResource.id', null))
      const resId = getResId(res)
      var existingRes = _.find($scope.questionsToImport, function(r) {
        return r.$type === type && getResId(r) === resId
      });

      //
      // 当前试卷已经在出题筐中
      if (existingRes) {
        return;
      }

      $scope.questionsToImport = _.concat($scope.questionsToImport, _.assign(
        {}, res, {
          $type: type
        }));
    }

    var previewConf = {
      oedCloudTest: function(data) {
        var modalInstance = $uibModal.open({
          template: require('assets/templates/prepareCourse/cloudTestPreviewer.html'),
          controller: 'cloudTestPreviewerCtrl',
          size: 'lg',
          resolve: {
            cloudTest: function() {
              return data.res;
            },
            resourceDef: getResourceDefForHomework('cloudTest')
          }
        });

        return modalInstance.result;
      },
      oedSubjectiveTest: function(data) {
        var modalInstance = $uibModal.open({
          template: require('assets/templates/prepareCourse/cloudSubjectiveTestPreviewer.html'),
          controller: 'cloudSubjectiveTestPreviewerCtrl',
          size: 'lg',
          resolve: {
            cloudTest: function() {
              return data.res;
            },
            resourceDef: getResourceDefForHomework('cloudSubjTest')
          }
        });

        return modalInstance.result;
      },
      oedCloudPresentRes: function(data) {
        var modalInstance = $uibModal.open({
          template: require('assets/templates/prepareCourse/cloudPresentResPreviewer.html'),
          controller: 'cloudPresentResPreviewerCtrl',
          size: 'lg',
          resolve: {
            cloudRes: function() {
              return data.res;
            },
            currentRes: function() {
              return {};
            },
            resourceDef: getResourceDefForHomework('cloudPresentRes')
          }
        });

        return modalInstance.result;
      }
    };

    function previewResourceItem(data) {
      var resType = 'undefined';
      if (data.res instanceof oedCloudTest) {
        resType = 'oedCloudTest';
      } else if (data.res instanceof oedCloudSubjectiveTest) {
        resType = 'oedSubjectiveTest';
      } else if (data.res instanceof oedCloudPresentRes) {
        resType = 'oedCloudPresentRes';
      }

      if (!_.has(previewConf, resType)) {
        console.log('not supported preview type for: ' + resType);
        return $q.reject('unsupported');
      }

      return previewConf[resType](data);
    }

    function addResourceToResBox(res, deferred, viewType) {
      if (viewType === 'objectiveTest') {
        _.forEach(res, function(r) {
          $scope.onAddCloudTest(r);
        });
      } else if (viewType === 'resource') {
        _.forEach(res, function(r) {
          $scope.onAddCloudPresentRes(r);
        });
      } else {
        console.log('not supported viewType', viewType);
      }

      deferred.resolve();
    }

    $scope.disableImport = function() {
      if (resourceDef.canImportResBox || $scope.homeworkMode) {
        return _.isEmpty($scope.questionsToImport);
      }

      return false;
    };

    $scope.$on('modal.closing', function($event) {
      //
      // 如果是代码触发的关闭, 则不做进一步检查
      if (isCodeClose) {
        isCodeClose = false;
        return;
      }

      $event.preventDefault();
      checkResBoxIsEmpty().then(function() {
        //
        // 自动关闭
        isCodeClose = true;
        $uibModalInstance.dismiss();
      }).finally(function() {
        isCodeClose = false;
      });
    });

    function checkResBoxIsEmpty() {
      if (!_.get($scope.resourceDef, 'canImportResBox', false)) {
        return $q.resolve(true);
      }

      if (_.isEmpty($scope.questionsToImport)) {
        return $q.resolve(true);
      }
      // 您已经向出题筐中加入了<%= count %>项内容，确认关闭？
      var msg = _.template('出题筐中的内容尚未确认导入，关闭会自动清空出题筐，确认关闭么？')({
        count: _.size($scope.questionsToImport)
      });

      var modalInstance = $uibModal.open({
        template: require('app2/common/confirmDialog.html'),
        controllerAs: '$ctrl',
        controller: 'oedConfirmDialogCtrl',
        resolve: {
          data: function() {
            return {
              message: msg,
              title: '确认关闭',
              yesText: '关闭',
              noText: '取消'
            };
          }
        },
        size: 'sm',
        windowClass: 'oedmodal modalCenter'
      });

      return modalInstance.result;
    }

    $scope.importTeachingPattern = function(tp, event) {
      return importResources([tp], false);
    };

    var testsInResBox = {};
    var questionsInResBox = {};

    if (!$scope.homeworkMode) {
      $scope.$watch('questionsToImport', function() {
        updateInResBoxStatus();
      }, true);

      $scope.$watch('items', function() {
        updateInResBoxStatus();
      }, true);
    }

    function updateInResBoxStatus() {
      testsInResBox = {};
      questionsInResBox = {};
      if ($scope.viewType != 'test' && $scope.viewType != 'question') {
        return;
      }
      var existingHashes = _.map($scope.questionsToImport, oedTestUtils.computeQuestionHash);
      var hashesInResBox = _.keyBy(existingHashes);

      if ($scope.viewType === 'test') {
        testsInResBox = _($scope.items).map(function(ct) {
          return {
            id: ct.id,
            inResBox: checkCloudTestInResBox(ct, hashesInResBox)
          };
        }).keyBy('id').value();
      } else {
        questionsInResBox = _($scope.items).map(function(cq) {
          var hash = oedTestUtils.computeQuestionHash(cq.question);
          return {
            id: cq.id,
            inResBox: _.has(hashesInResBox, hash)
          };
        }).keyBy('id').value();
      }
    }

    function checkCloudTestInResBox(ct, existingHashes) {
      var hashes = _(ct).get('test.questions', []).map(function(q) {
        return {
          id: q.id,
          hash: oedTestUtils.computeQuestionHash(q)
        };
      });

      var questionHashes = _.keyBy(hashes, 'id');
      var hash = _.find(questionHashes, function(h) {
        return !_.has(existingHashes, h.hash);
      });
      return !hash;
    }

    //
    // 判断是否测试中的所有问题已经加入出题筐
    $scope.isTestAddedToResBox = function(cloudTest) {
      if ($scope.homeworkMode) {
        return !canAddToResBox(cloudTest, 'cloudTest');
      }

      return _.get(testsInResBox[cloudTest.id], 'inResBox', false);
    };

    $scope.isQuestionAddedToResBox = function(cq) {
      if ($scope.homeworkMode) {
        return !canAddToResBox(cq, 'cloudQuestion');
      }

      return _.get(questionsInResBox[cq.id], 'inResBox', false);
    };

    $scope.isSubjectiveTestInResBox = function(ct) {
      if ($scope.homeworkMode) {
        return !canAddToResBox(ct, 'cloudSubjTest');
      }
      return false;
    };

    function doQueryWithFailback(queryOpt) {
      return doQuery(queryOpt).then(function(ret) {
        var items = ret[0];
        if (_.isEmpty(items)) {
          const opt = queryOpt;
          return failbackQuery(opt);
        }
        return ret;
      });
    }

    function failbackQuery(queryOpt) {
      //
      // 如果有章节, 则尝试搜索全部章节
      if (!_.isEmpty(queryOpt.sectionIds)) {
        $scope.queryRetryType = 'allSections';
        return doQueryWithFailback(_.omit(queryOpt, 'sectionIds'));
      }

      //
      // 如果选择教材版本, 则尝试搜索全部教材
      if (_.has(queryOpt, 'versionId') && queryOpt.versionId !== -1) {
        $scope.queryRetryType = 'allVersions';
        return doQueryWithFailback(_.omit(queryOpt, ['versionId', 'gradeId']));
      }

      /**
       * 这里直接返回空, 不使用全学科搜索
       **/
      return Promise.resolve([]);
    }

    function doQuery(queryOpt) {
      var queries = resourceDef.queryRes(queryOpt, $scope.viewType);
      if (_.isArray(queries)) {
        return $q.all(queries);
      }

      return queries;
    }

    $scope.getCountSuffix = function() {
      if ($scope.homeworkMode || $scope.viewType === 'resource' || $scope.viewType === 'resitem') {
        return '项';
      }
      return '题';
    };

    function getResourceDefWithResBoxSupport() {
      const t = _.get(resourceDef, 'viewType');
      if (_.indexOf(['subjectiveTest'], t) >= 0) {
        return resourceDef;
      }

      return _.assign({}, resourceDef, {
        canAddToResBox: canAddToResBox,
        addItemToResBox: $scope.onAddCloudRes,
        importBtnText: '加入出题筐',
        doImport: addResourceToResBox,
        getResItems: function() {
          return $scope.questionsToImport;
        }
      });
    }

    function getSectionsNamesForSearch(ids) {
      const names = _.map(ids, (id) => _.get($scope.sectionIdMap, id + '.name'));
      const trimSectionName = (name) => {
        const trimmed = _.trim(name);
        const idx = _.indexOf(trimmed, ' ');
        if (idx >= 0) {
          return trimmed.substr(idx);
        }
        return trimmed;
      };
      return _(names).map(trimSectionName).filter().value();
    }

    $scope.onSelectVendor = (name) => {
      $scope.selectedVendor = name
      if (name === 'hualesi') {
        return
      }

      if (name === 'haoweilai') {
        if ($scope.viewType === 'test') {
          $scope.viewType = 'question'
        }
        if ($scope.curFilterType === 'topic') {
          $scope.curFilterType = 'section'
        }
      } else if (name === 'oed') {
        if ($scope.curFilterType === 'knowledge') {
          $scope.curFilterType = 'section'
        }
      }

      $scope.loading = reloadVersions($scope.userInfo, $scope.coursePath)
    }

    $scope.supportVendor = () => {
      return resourceDef.res === oedCloudTest || resourceDef.res === oedCloudPresentRes
    }

    $scope.getPagedTotalNumber = () => {
      const total = _.isNumber($scope.totalNums) ? $scope.totalNums : 0
      const maxPagedCount = $scope.countPerPage * maxPages
      return total > maxPagedCount ? maxPagedCount : total
    }

    $scope.showTooManyResultsHint = () => {
      return $scope.getPagedTotalNumber() < $scope.totalNums && $scope.curPage >= maxPages
    }

    $scope.getPageMaxSize = () => {
      return $scope.showTooManyResultsHint() ? 6 : 10
    }

    $scope.showContent = () => {
      return $scope.selectedVendor !== 'hualesi'
    }

    $scope.onAddHlsQuestion = (q, event) => {
      $scope.addCloudQuestion({
        $sourceType: 'hls',
        question: q,
      }, event)
      hlsResource.viewResource({
        vendor: 'hls',
        uid: $scope.userInfo.uid,
        sid: q.id
      }, {})
    }

    $scope.onAddHlsResource = (r, event) => {
      $scope.addCloudPresentResItem({
        $sourceType: 'hls',
        res: r,
      }, event)
      hlsResource.viewResource({
        vendor: 'hls',
        uid: $scope.userInfo.uid,
        sid: r.resourceID
      }, {})
    }
  }

  //
  // 粗略估算一个minScore
  // 方法:
  // 如果搜索字符串中少于或有一个单词则 为0.1
  // 否则为0.25
  // 单词的计算方法为
  // 1. 按空格拆分字符串
  // 2. 每个词再看
  //     对于英文单词 计为1
  //     非英文单词则判断长度是否大于3, 大于3的计2, 小于等于3的计1
  function suggestMinScore(q) {
    const words = _.words(q);
    const keyWordCount = _.reduce(words, (acc, w) => {
      //
      // 对纯英文单词, 认为是一个单词
      if (/^[a-zA-Z]+$/.test(w)) {
        return acc + 1;
      }

      //
      // 对于包含其他字符的(汉字)
      // 如果字符数超过3个字符则认为是多个单词
      const keywords = _.size(w) > 3 ? 2 : 1;
      return acc + keywords;
    }, 0);

    if (keyWordCount >= 2) {
      return 0.25;
    }
    return 0.10;
  }

  function getCurrentSubject(cur, courseSubject, allSubjects) {
    if (cur) {
      const s = _.find(allSubjects, sb => sb.id === cur.id)
      if (s) {
        return s
      }
    }

    if (courseSubject) {
      const s = _.find(allSubjects, sb => sb.id === courseSubject.id)
      if (s) {
        return s
      }
    }
    return allSubjects[0]
  }

  function getCurrentVersion(curVer, coursePath, allVersions) {
    if (curVer) {
      const v = _.find(allVersions, av => av.versionId === curVer.versionId && av.gradeId === curVer.gradeId)
      if (v) {
        return v
      }
    }

    if (coursePath) {
      const v = _.find(allVersions, ver => {
        return ver.gradeId == coursePath.gradeId &&
          ver.versionId == coursePath.versionId &&
          ver.subjectId == coursePath.subjectId;
      })
      if (v) {
        return v
      }
    }

    return allVersions[0]
  }
})();
