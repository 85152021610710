(function() {
  require('assets/styles/courseList.scss');
  require('assets/styles/resCenter.scss');
  angular.module('app.resCenter')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('resCenter.rcCourseList', {
        url: '/:cpId',
        template: require('assets/templates/rcCourseList.html'),
        controller: 'rcCourseListCtrl',
        bodyStyle: 'bged2'
      });
    }]).controller('rcCourseListCtrl', rcCourseListCtrl)
    .controller('createCourseCpCtrl', createCourseCpCtrl);
  rcCourseListCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal',
    '$animate', '$q', 'dialogs', 'localStorageService', 'notificationService',
    'resize', 'userInfo', 'oedCoursePath', 'oedRecommendCourse',
    'oedCourse', 'oedSchoolTerm','oedUnitItem','oedUserInfo']
  function rcCourseListCtrl($scope, $state, $stateParams, $uibModal,
                            $animate, $q, dialogs, localStorageService, notificationService,
                            resize, userInfo, oedCoursePath, oedRecommendCourse,
                            oedCourse, oedSchoolTerm, oedUnitItem,oedUserInfo) {
    $scope.courses = [];
    $scope.cloneData = [];
    $scope.zlCardData = [];
    $scope.recommendCardData = [];
    $scope.selectedSection = '';
    $scope.needLeftNum = 0;//右侧偏移量
    $scope.defaultSelect = true;
    $scope.sections = [];//左侧树列表
    $scope.name = '';//新建课程名字
    var cpId = $stateParams.cpId;
    $scope.cpId = cpId;
    $scope.loadComplete = false;
    $scope.tempData = [];
    $scope.showRc = localStorageService.get('showRc');
    $scope.noCp = true;
    $scope.isBuyZlCourse = localStorageService.get('isBuyZlCourse');
    $scope.sectionMap = {}

    function buildSectionMap(child) {
      for (let c of child) {
        if (c.child) {
          buildSectionMap(c.child)
        }
        if ($scope.sectionMap[c.id]) {
          continue
        }
        // 这里假设parentId 也是非法的
        if (!c.parentId) {
          continue
        }
        $scope.sectionMap[c.id] = c.parentId
      }
    }

    function findRootPid(id) {
      if ($scope.sectionMap[id]) {
        return findRootPid($scope.sectionMap[id])
      } else {
        return id
      }
    }

    function loadData(cpId) {
      $scope.cpId = cpId;
      $scope.courses = oedCourse.queryByPath({
        pathId: cpId
      });
      oedCoursePath.get({id: cpId}).$promise.then(function(c) {
        $scope.coursePath = c;
      });
      userInfo.then(function(info) {
        console.log('info===', info)
        $scope.uid = _.parseInt(info.uid);
        $scope.coursePathList = oedCoursePath.queryFilter({
          userId: _.parseInt(info.uid),
          onGoing: true,
          unStarted: true, //false, // TODO: 处理归档
          ended: true
        });
        $scope.canStar = info.canStar;
        const promise  =  oedUnitItem.queryAvailableBySchoolAndCoursePath({
          schoolId: info.schoolId,
          coursePathId: cpId
        }).$promise;
        $q.all([promise,$scope.courses.$promise]).then(data=>{
          buildSectionMap(data[0][0].child)
        $scope.tempData = data[0][0].child;
        $scope.sections = handleData(data[0]);
        return $scope.sections;
      });
      });
      localStorageService.set('lastCoursePathId', cpId);
    }
    if (cpId) {
      $scope.noCp = false;
      loadData(cpId);
    }else{
      var cp = {};
      var dlg = $uibModal.open({
        template: require('assets/templates/manage/createCourseDialog.html'),
        controller: 'createCourseCpCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          coursePath: function() {
            return cp;
          },
          existing: function() {
            return null;
          },
          copy: null,
          createCp: true,
          newPreCp: null
        }
      });
      dlg.result.then(function(data) {
        $scope.noCp = false;
        loadData(data.cp.id);
      });
    }
    //get点击的sectionId
    function handleData(res){
      var chapterId = '';
      const cpId = localStorageService.get('lastCoursePathId');
      const  lastSelectContentId = localStorageService.get('lastSelectContentId');
      expandAllLeaf(res[0].child);
      if(cpId){
        chapterId = localStorageService.get('lastCoursePathId_' + cpId);
        if(chapterId && chapterId!='-1'){
          $scope.defaultSelect = false;
          findTreeLastSelectedLeaf(res[0].child,chapterId);
          if($scope.selectedSection && $scope.selectedSection.parentId){
            openParentById($scope.selectedSection.parentId,res[0].child)
          }
          oedCourse.querySectionDetailById({
            sectionId: chapterId,
            coursePathId: cpId
          }).$promise.then(item=>{
            const idMap = [];
          _.forEach(item,data=>{
            idMap.push(data.id);
        })
          $scope.cloneData = _.filter($scope.courses,function(data){
            return idMap.indexOf(data.id) >= 0;
          });
        });
        } else {
          //给默认选中
          $scope.defaultSelect = false;
          findLastLeafId(res[0].child[0]);
        }
      }
      setTimeout(() => {
        if($('.selected') && $('.selected')[0]){
        $('.selected')[0].scrollIntoView({behavior:'smooth'});
      }
    }, 0);
      if(lastSelectContentId){
        setTimeout(() => {
          var dom = document.getElementById(lastSelectContentId);
        if(dom){
          dom.scrollIntoView({behavior:'smooth'});
        }
      }, 1000);
      }
      return res;
    }
    function findLastLeafId(data){
      if(data && data.child && data.child.length > 0){
        findLastLeafId(data.child[0]);
      }else{
        const cpid = localStorageService.get('lastCoursePathId');
        localStorageService.set('lastCoursePathId_' + cpid, data.id);
        findTreeLastSelectedLeaf($scope.tempData,data.id);
        if($scope.selectedSection && $scope.selectedSection.parentId){
          openParentById($scope.selectedSection.parentId,$scope.tempData)
        }
        oedCourse.querySectionDetailById({
          sectionId: data.id,
          coursePathId: cpId
        }).$promise.then(item=>{
          const idMap = [];
        _.forEach(item,data=>{
          idMap.push(data.id);
      })
        $scope.cloneData = _.filter($scope.courses,function(data){
          return idMap.indexOf(data.id) >= 0;
        });
      });
      }
    }
    //默认展开所有树节点
    function expandAllLeaf(data){
      _.forEach(data,function(c){
        c.$opened = true;
        c.noClick = true;
      });
    }
    //当前选中的章节
    function findTreeLastSelectedLeaf(data,chapterId){
      _.forEach(data,item=>{
        if(item.id == chapterId){
        $scope.selectedSection = item;
        $scope.selectedSecRootPid = findRootPid(item.id)
        if($scope.isBuyZlCourse) {  //这个参数代表有没有买中联资源
          $scope.getZlData(true);
        } else{
          $scope.getZlData(false);
        }
      }else{
        if(item.child){
          findTreeLastSelectedLeaf(item.child,chapterId);
        }
      }
    });
    }
    function openParentById(id,res){
      _.forEach(res,r=>{
        if(r.id === id){
        r.$opened = true;
        if(r.parentId){
          openParentById(r.parentId, $scope.tempData)
        }
      }else{
        if(r.child && r.child.length > 0){
          openParentById(id,r.child);
        }
      }
    })
    }
    $scope.getZlData = function (isShowZl){
      if($scope.defaultSelect){
        $scope.zlCardData = [];
        $scope.recommendCardData = [];
      } else {
        if(isShowZl) {
          const zlPromise =  oedCourse.getZlClass({sectionId:$scope.selectedSection.id}).$promise;
          const recommendPromise =  oedCourse.getRecommendClass({sectionId:$scope.selectedSection.id}).$promise;
          $scope.loading = $q.all([$scope.courses.$promise,zlPromise,recommendPromise]).then(res=>{
            $scope.zlCardData = res[1];
          $scope.zlCardData.sort((a,b)=>{
            return a.classTime - b.classTime;
        })
          $scope.recommendCardData = res[2];
        })
        } else{
          $scope.loading = $q.all([$scope.courses.$promise,oedCourse.getRecommendClass({sectionId:$scope.selectedSection.id}).$promise]).then(res=>{
            $scope.zlCardData = [];
          $scope.recommendCardData = res[1];
        })
        }
      }
    }
    //左侧树选中并且filter右侧数据
    $scope.onSelectSection = sec => {
      $scope.defaultSelect = false;
      $scope.selectedSection = sec;
      $scope.selectedSecRootPid = findRootPid(sec.id)
      const cpId = localStorageService.get('lastCoursePathId');
      if(cpId){
        localStorageService.set('lastCoursePathId_' + cpId, sec.id);
      }
      $scope.loading = oedCourse.querySectionDetailById({
        sectionId: sec.id,
        coursePathId: cpId
      }).$promise.then(item=>{
        const idMap = [];
      _.forEach(item,data=>{
        idMap.push(data.id);
    })
      $scope.cloneData = _.filter($scope.courses,function(data){
        return idMap.indexOf(data.id) >= 0;
      });
      if($scope.isBuyZlCourse){
        return $scope.getZlData(true);
      }else{
        return $scope.getZlData(false);
      }
    });
    }
    $scope.classTimeMap = ['','第一课时','第二课时','第三课时','第四课时','第五课时','第六课时','第七课时','第八课时','第九课时'];
    $scope.classTimeNumMap = ['','一','二','三','四','五','六','七','八','九'];
    $scope.getClassTimeName = function (classTime) {
      if(classTime == 0) {
        return '';
      }
      return `（${$scope.classTimeMap[classTime]}）`;
    }
    $scope.getClassTime = function (classTime) {
      return $scope.classTimeNumMap[classTime];
    }
    //新建课程相关
    $scope.close = function() {
      $scope.editCourseModalInstance.dismiss('cancel');
    };
    $scope.add = function(){
      const questObj = {
        coursePathId: Number($scope.cpId),
        sectionId: $scope.selectedSection.id,
        time: -1,
        courseName: `${$scope.selectedSection.name}`
      };
      oedCourse.createQualityCourse(questObj).$promise.then(r => {
        oedCourse.get({id: r.courseId}).$promise.then(data=>{
        localStorageService.set('lastSelectContentId', data.id);
      localStorageService.set('lastCurentCourse',data);
      localStorageService.set('newPrepareNeedPointOut', false);
      $state.go('newPrepareCourse.ownerPrepare', {
        courseId: data.id,
      });
    })
    })
    }
    $scope.deleteCourseItem = function(cs) {
      var dlg = dialogs.confirm('确定删除?', '确定要删除课程: "' + cs.courseName + '"吗');
      dlg.result.then(function(btn) {
        cs.$delete(function(cs) {
          if ($scope.coursePath.templateType && $scope.canStar) {
            var removecourse = oedCoursePath.removeCourseFromTemplate({id: cs.id}).$promise;
          }
          _.remove($scope.courses, cs);
          _.remove($scope.cloneData, cs);
        });
      });
    };
    $scope.examCollectionDepot = () => {
      var modalInstance = $uibModal.open({
        template: require('assets/app/controllers/recycleBinDialog/recycleBinDialog.html'),
        controller: 'recycleBinDialogCtrl',
        size: 'lg',
        windowClass: 'ex-collectionModal',
        resolve: {
          cpId: function() {
            return $scope.cpId;
          }
        }
      });
      modalInstance.result.then(function(r) {
        if (r) {
          loadData($scope.cpId);
        }
      });
    };
    $scope.switchClick = () => {
      $scope.isList = !$scope.isList
      localStorageService.set('isList', $scope.isList)
      return;
    }
    $scope.addEditCourse = (type,item) => {
      $scope.type = type;
      if(type == 'edit') {
        localStorageService.set('lastSelectContentId', item.id);
        localStorageService.set('lastCurentCourse',item);
        localStorageService.set('newPrepareNeedPointOut', false);
        $state.go('newPrepareCourse.ownerPrepare', {
          courseId: item.id
        });
      }else{
        $scope.add();
      }
    }

    $scope.createExam = () => {
      $state.go('editObjectTestPaper', {
        coursepathId: $scope.cpId,
        sectionId: $scope.selectedSection.id,
        sectionParentId: $scope.selectedSection.parentId,
        sectionRootId: $scope.selectedSecRootPid,
        levelId: _.get($scope.selectedSection, 'levelId', ''),
        relId: _.get($scope.selectedSection, 'relId', '')
      });
    }

    $scope.editZlCourse = (data) => {
      const questObj = {
        coursePathId: Number($scope.cpId),
        sectionId: $scope.selectedSection.id,
        time: data.classTime,
        courseName: `${$scope.selectedSection.name}${$scope.getClassTimeName(data.classTime)}`
      };
      oedCourse.createQualityCourse(questObj).$promise.then(r => {
        oedCourse.get({id: r.courseId}).$promise.then(x=>{
        localStorageService.set('lastSelectContentId', x.id);
      localStorageService.set('lastCurentCourse',x);
      localStorageService.set('newPrepareNeedPointOut', true);
      $state.go('newPrepareCourse.ownerPrepare', {
        courseId: x.id,
      });
    })
    })
    }
    $scope.editRecommendCourse = (data) => {
      const questObj = {
        coursePathId: Number($scope.cpId),
        courseId: data.id,
        sectionId: $scope.selectedSection.id,
      };
      oedCourse.createRecommendCourse(questObj).$promise.then(r => {
        localStorageService.set('lastSelectContentId', r.id);
      localStorageService.set('lastCurentCourse',r);
      localStorageService.set('newPrepareNeedPointOut', true);
      $state.go('newPrepareCourse.ownerPrepare', {
        courseId: r.id,
      });
    })
    }
    $scope.changeSelectChapter = function(){
      $scope.defaultSelect = true;
      $scope.selectedSection = '';
      $scope.selectedSecRootPid = 0
      const cpId = localStorageService.get('lastCoursePathId');
      if(cpId){
        localStorageService.set('lastCoursePathId_' + cpId, '-1');
      }
      $scope.zlCardData = [];
      $scope.recommendCardData = [];
      $scope.cloneData = $scope.courses;
    }
    $scope.togglePrepareCouseStatus = function () {
      oedUserInfo.updateNewPrepareLesson({uid:$scope.uid,value:'0'}).$promise.then(res=>{
        localStorageService.set('isPreLesson',false);
      $state.go('prepareLesson.courseList', {
        cpId: $scope.cpId
      });

    })
    }

    $scope.changeCp = function (id) {
      $state.go('resCenter.rcCourseList', {
        cpId: id
      });
    }
  }

  createCourseCpCtrl.$inject = ['$scope', 'userInfo', '$uibModalInstance', '$q', 'dialogs', 'oedUserClass',
    'oedSubjects', 'oedTeachingMaterialVersions', 'oedCoursePath', 'coursePath', 'existing', 'copy','newPreCp','createCp']
  function createCourseCpCtrl($scope, userInfo, $uibModalInstance, $q, dialogs, oedUserClass,
                              oedSubjects, oedTeachingMaterialVersions, oedCoursePath, coursePath, existing, copy,newPreCp,createCp) {
    $scope.subjects = [];
    $scope.classes = [];
    $scope.edition = [];
    $scope.newPreCp = newPreCp;
    $scope.createCp = createCp;
    $scope.globalVersions = oedCoursePath.getAllVersions();
    $scope.globalLgvs = oedCoursePath.getAllLgvs();
    $scope.globalBooks = oedCoursePath.getAllBooks();
    $scope.editionList = []; //版本list
    if (existing !== null) {
      $scope.allCoursePaths = existing;
      $scope.fromExisting = true;
    } else {
      $scope.fromExisting = false;
    }
    // make a copy
    $scope.courseInfo = _.assign({}, coursePath);

    if (copy !== null) {
      $scope.copyMode = true;
      $scope.courseInfo.oldname = $scope.courseInfo.name;
      $scope.courseInfo.name = $scope.courseInfo.name + '2';
    } else {
      $scope.copyMode = false;
    }

    $scope.courseInfo.startDate = moment($scope.courseInfo.startDate).toDate();
    var endDate = $scope.courseInfo.endDate ?
      moment($scope.courseInfo.endDate).toDate() :
      moment($scope.courseInfo.startDate).add(5, 'months').toDate();
    $scope.courseInfo.endDate = endDate;

    $scope.$watch('courseInfo.endDate', function(newVal) {
      $scope.startDateOpt = {
        maxDate: newVal
      };
    });

    $scope.$watch('courseInfo.startDate', function(newVal) {
      $scope.endDateOpt = {
        minDate: newVal
      };
    });

    userInfo.then(function(info) {
      $scope.subjects = oedSubjects.queryBySchoolId({
        schoolId: info.schoolId
      });

      if ($scope.courseInfo.subjectId) {
        $scope.subjects.$promise.then(function(subjects) {
          $scope.courseInfo.subject = _.find(subjects, function(sub) {
            return sub.id == $scope.courseInfo.subjectId;
          });

          $scope.versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
            schoolId: info.schoolId,
            subjectId: $scope.courseInfo.subject.id
          });

          $scope.versions.$promise.then(function(versions) {
            $scope.courseInfo.teachingMaterial = _.find(versions, function(ver) {
              return ver.gradeId == $scope.courseInfo.gradeId &&
                ver.versionId == $scope.courseInfo.versionId &&
                ver.subjectId == $scope.courseInfo.subjectId;
            });
          });

          return $scope.versions.$promise;
        });
      }

      $scope.classes = oedUserClass.queryByUser({
        userId: info.uid
      });

      $scope.isEdit = function() {
        return !!$scope.courseInfo.id;
      };

      $scope.isCopyMode = function() {
        return !!$scope.copyMode;
      };

      $scope.isArchive = function() {
        return !!$scope.courseInfo.isArchive;
      };

      $scope.isCopied = function() {
        return $scope.courseInfo.copyFromCp && $scope.courseInfo.copyFromCp.id;
      };

      $scope.isSelected = function() {
        return $scope.courseInfo.copyFromCp !== null && $scope.courseInfo.copyFromCp !== undefined;
      };

      $scope.classes.$promise.then(function(classes) {
        $scope.courseInfo.classess = _($scope.courseInfo.classIds).map(function(clsId) {
          return _.find(classes, function(cls) {
            return cls.id == clsId;
          });
        }).reject(function(v) {
          return !v;
        }).value();
      });

      $scope.loading = $q.all([$scope.subjects.$promise, $scope.classes.$promise]);
      // 选择-学科onchange方法
      $scope.onSubjectSelected = function(item, model) {
        $scope.courseInfo.teachingMaterial = null;
        $scope.courseInfo.edition = null;
        var versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
          schoolId: info.schoolId,
          subjectId: item.id
        });
        $scope.loading = versions.$promise;
        $scope.reloadVersion(item)
        $scope.courseInfo.name = ''
      };
      // 加载对应学科版本方法
      $scope.reloadVersion = function(subJect) {
        var t = subJect;
        const glvs = _.uniq(_.map(_.filter($scope.globalLgvs, s => s.subjectId === t.id), t => t.versionId));
        $scope.editionList = _.filter($scope.globalVersions, s => _.includes(glvs, s.versionId));
      };
      // 选择-版本onchange方法
      $scope.onEditionSelected = function(item, model){
        $scope.courseInfo.teachingMaterial = null;
        var t = $scope.courseInfo.edition;
        const glvs = _.uniq(_.map(_.filter($scope.globalLgvs, s => s.subjectId === $scope.courseInfo.subject.id && s.versionId === t.versionId), t => t.gradeId));
        let versions = _.filter($scope.globalBooks, s => _.includes(glvs, s.gradeId));
        groupByOrd(versions)
        $scope.courseInfo.name = ''
      }
      // 分组排序
      var groupByOrd = function(versions) {
        // 正常
        var normalList = []
        // 选修
        var electiveList = []
        // 必修
        var compulsoryList = []
        // 选择性必修
        var optionalCompulsoryList = []
        // 其他
        var oList = []
        var patten = /[一｜二｜三｜四｜五｜六｜七｜八｜九]/g
        // 正常 patten
        var normaPatten = /^[一｜二｜三｜四｜五｜六｜七｜八｜九]/
        // 选修 patten
        var electivePatten = /^选修/
        // 必修 patten
        var compulsoryPatten = /^必修/
        // 必修 patten
        var optionalCompulsoryPatten = /^选择性必修/
        var myMap = new Map();
        myMap.set('一', 1);
        myMap.set('二', 2);
        myMap.set('三', 3);
        myMap.set('四', 4);
        myMap.set('五', 5);
        myMap.set('六', 6);
        myMap.set('七', 7);
        myMap.set('八', 8);
        myMap.set('九', 9);
        // 进行分组
        versions.forEach(item => {
          if (normaPatten.test(item.gradeName)) {
            normalList.push(item)
          } else if (optionalCompulsoryPatten.test(item.gradeName)){
          optionalCompulsoryList.push(item)
          } else if (electivePatten.test(item.gradeName)){
            electiveList.push(item)
          } else if (compulsoryPatten.test(item.gradeName)){
            compulsoryList.push(item)
          } else {
            oList.push(item)
          }
        });
        // 分别排序
        normalList.sort((n1,n2)=>{
          // n2 是后面的值 n1 是前面的值
          let head1 = myMap.get(n1.gradeName.match(patten)? n1.gradeName.match(patten)[0]:0);
          let head2 = myMap.get(n2.gradeName.match(patten)? n2.gradeName.match(patten)[0]:0);
          if(head1 !== head2){
            return head1 - head2
          } else {
            let sw = n2.gradeName.search('上') !== -1
            if(sw){
              return 0
            }
            return -1
          }
        })
        electiveList.sort((n1,n2)=>{
          let head1 = myMap.get(n1.gradeName.match(patten)? n1.gradeName.match(patten)[0]:0);
          let head2 = myMap.get(n2.gradeName.match(patten)? n2.gradeName.match(patten)[0]:0);
          return head1 - head2
        })
        compulsoryList.sort((n1,n2)=>{
          let head1 = myMap.get(n1.gradeName.match(patten)? n1.gradeName.match(patten)[0]:0);
          let head2 = myMap.get(n2.gradeName.match(patten)? n2.gradeName.match(patten)[0]:0);
          return head1 - head2
        })
        oList.sort((n1,n2)=>{
          let head1 = myMap.get(n1.gradeName.match(patten)? n1.gradeName.match(patten)[0]:0);
          let head2 = myMap.get(n2.gradeName.match(patten)? n2.gradeName.match(patten)[0]:0);
          return head1 - head2
        })
        $scope.versions = [...normalList,...oList, ...compulsoryList, ...electiveList,...optionalCompulsoryList];
      }

      $scope.onCoursePathSelected = function(cp, model) {
        if (_.isEmpty($scope.courseInfo.name)) {
          $scope.courseInfo.name = cp.name;
        }

        $scope.courseInfo.subject = _.find($scope.subjects, function(sub) {
          return sub.id == cp.subjectId;
        });

        $scope.versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
          schoolId: info.schoolId,
          subjectId: $scope.courseInfo.subject.id
        });

        $scope.versions.$promise.then(function(versions) {
          $scope.courseInfo.teachingMaterial = _.find(versions, function(ver) {
            return ver.gradeId == cp.gradeId && ver.versionId == cp.versionId && ver.subjectId == cp.subjectId;
          });
        });

        $scope.loading = $scope.versions.$promise;
      };

    }); //userInfo.then

    var courseNameTmpl = _.template('<%=version.versionName%> <%= version.name%>');
    // 选择-教材onchange方法
    $scope.onVersionSelected = function(item, model) {
      $scope.courseInfo.name = $scope.courseInfo.edition.versionName+' '+$scope.courseInfo.subject.subjectName+$scope.courseInfo.teachingMaterial.gradeName
    };

    $scope.getTeachingMaterialList = function(val) {
      return _.filter($scope.versions, function(v) {});
    };

    $scope.clearCoursePath = function(event) {
      event.stopPropagation();
      $scope.courseInfo.copyFromCp = null;
    };

    var checkTeachingMaterialChanged = function() {
      if ($scope.isEdit()) {
        var oldCp = _(coursePath);
        var tmProps = ['gradeId', 'subjectId', 'versionId'];
        var newInfo = {};
        if ($scope.courseInfo.subject) {
          newInfo.subjectId = $scope.courseInfo.subject.id;
        }

        if ($scope.courseInfo.teachingMaterial) {
          var tm = $scope.courseInfo.teachingMaterial;
          newInfo.gradeId = tm.gradeId;
          newInfo.versionId = tm.versionId;
        }

        if (!oldCp.pick(tmProps).isEqual(newInfo)) {
          return dialogs.confirm('修改教材', '改教材后备课的章节关联信息将被重置，是否确定修改?').result;
        }
      }

      return true;
    };
    $scope.deleteCourse = function() {
      $uibModalInstance.close({cp: $scope.courseInfo, type: 'delete'});
    };
    $scope.archiveCourse = function() {
      $uibModalInstance.close({cp: $scope.courseInfo, type: 'archive'});
    };
    $scope.unArchiveCourse = function() {
      $uibModalInstance.close({cp: $scope.courseInfo, type: 'unarchive'});
    };
    $scope.copyCourse = function() {
      $uibModalInstance.close({cp: $scope.courseInfo, type: 'copy'});
    };
    $scope.preCourse = function() {
      $uibModalInstance.close({cp: $scope.courseInfo, type: 'preCourse'});
    };
    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.createCourseForm.$invalid) {
        return;
      }
      $q.when(checkTeachingMaterialChanged()).then(function() {
        var courseInfo;
        if ($scope.copyMode) {
          courseInfo = _.pick($scope.courseInfo, ['name']);
        } else {
          courseInfo = _.pick($scope.courseInfo, ['id', 'name']);
        }
        if ($scope.courseInfo.subject) {
          courseInfo.subjectId = $scope.courseInfo.subject.id;
        }

        if ($scope.courseInfo.copyFromCp) {
          courseInfo.copyFromCpId = $scope.courseInfo.copyFromCp.id;
        }

        //
        // 转换成开始日期的0点
        courseInfo.startDate = moment(moment($scope.courseInfo.startDate).format('YYYYMMDD'), 'YYYYMMDD').toDate();
        //
        // 转换成结束日期的最后一秒
        // 这里需要服务器时区和浏览器时区一致
        courseInfo.endDate = moment(moment($scope.courseInfo.endDate)
            .format('YYYYMMDD 23:59:59'),
          'YYYYMMDD hh:mm:ss').toDate();
        if ($scope.courseInfo.teachingMaterial) {
          var tm = $scope.courseInfo.teachingMaterial;
          courseInfo.gradeId = tm.gradeId;
          courseInfo.versionId = $scope.courseInfo.edition.versionId;
        }

        courseInfo.classIds = _.map($scope.courseInfo.classess, function(cls) {
          return cls.id;
        });

        var cp = new oedCoursePath(courseInfo);
        $scope.loading = cp.$save();
        $scope.loading.then(function(cp) {
          $uibModalInstance.close({cp, type: 'ok'});
        });
      });
    };
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.now = moment();
    $scope.groupByStatus = function(item) {
      if (item.isUnStarted($scope.now)) {
        return '未开始';
      }
      if (item.isOnGoing($scope.now)) {
        return '进行中';
      }

      return '已完成';
    };
    $scope.groupByArchive = function(item) {
      if (item.isArchive == 1) {
        return '已归档';
      } else {
        return '进行中';
      }
    };

    $scope.openStart = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startOpened = true;
      $scope.endOpened = false;
    };

    $scope.openEnd = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startOpened = false;
      $scope.endOpened = true;
    };

    $scope.dateOptions = {
      formatYear: 'yy',
      startingDay: 0
    };
  }
})();
