angular.module('app.examImageView', [])
  .component('examImageView', {
  bindings: {
    inRatio: '<',
    src: '@',
    class: '@',
  },
  controller: examImageView,
  template: `<img ng-mousedown="$ctrl.imageMouseDown($event)" class="{{$ctrl.class}} exam-img-view" src="{{$ctrl.src}}"></img>`,
});

examImageView.$inject = ['$document','$element']
function examImageView($document, $element) {
  console.warn('examImageView--------',this)
  // 图片缩小放大
  $('.exam-img-view', $element).css({ transition: 'all .2s' });
  this.getInitImageData = () => {
    const dom = $('.exam-img-view', $element)[0];
    if(dom) {
      this.imageWidth = dom.offsetWidth;
      this.imageHeight = dom.offsetHeight;
      this.naturalWidth  = _.get(dom, 'naturalWidth');
      this.naturalHeight = _.get(dom, 'naturalHeight');
    } else {
      this.imageWidth = 0;
      this.imageHeight = 0;
    }
  };
  this.zoomTo = (newRetio) => {
    $('.exam-img-view', $element).css({
      transform: `scale(${newRetio})`
    });
  };
  // 图片拖拽
  this.x = 0;
  this.y = 0;
  const that = this;
  this.firstMove = true;
  this.imageStartLeft = 0;
  let drag;

  // 鼠标按下事件
  this.imageMouseDown= ($event) => {
    $event.preventDefault();
    if(this.firstMove) {
      drag = $('.exam-img-view', $element)[0]
    }
    this.startX = $event.clientX ;
    this.diffY = $event.clientY - drag.offsetTop;
    $document.on('mousemove', mousemove);
    $document.on('mouseup', mouseup);
  };

  // 拖拽事件
  function mousemove($event) {
    $event.preventDefault();
    that.x = $event.clientX - that.startX + that.imageStartLeft;
    that.y = $event.clientY - that.diffY;
    $('.exam-img-view', $element)
      .css({
      "margin-top": that.y + 'px',
      "margin-left": that.x + 'px',
      "margin-right": 'auto',
      "margin-bottom": 'auto',
      transition: 'margin 0s'
    });
  }

  // 鼠标放开事件
  function mouseup() {
    that.imageStartLeft = that.x;
    that.firstMove = false;
    $document.off('mousemove', mousemove);
    $document.off('mouseup', mouseup);
    $('.exam-img-view', $element)
      .css({ transition: 'all .2s' });
  }
  

  this.$onChanges = () => {
    if (this.inRatio) {
      this.zoomTo(this.inRatio)
    }
  }

  this.$onDestroy = () => {
    mouseup()
  }
}
