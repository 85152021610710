import angular = require('angular');
import * as bluebird from 'bluebird'
import _ = require('lodash');
import moment = require('moment');

// require('assets/styles/oedTable.scss');

angular.module('app.review.filterClassSessions', [])
  .controller('filterClassSessionsCtrl', filterClassSessionsCtrl);

filterClassSessionsCtrl.$inject = ['$scope', 'data', '$uibModalInstance',
  'oedTmSession', 'oedClassUser', 'userInfo', '$q', '$uibModal',
  'oedUserClass', 'oedZhongZhiSchool']
function filterClassSessionsCtrl(
  $scope: any, data: any, $uibModalInstance: any,
  oedTmSession: any, oedClassUser: any, userInfo: any, $q: any, $uibModal: any,
  oedUserClass: any, oedZhongZhiSchool: any
) {
  $scope.data = data;
  $scope.close = () => {
    $uibModalInstance.close();
  };

  $scope.record = {
    cpId: data.cpId,
    classes: data.selectedClasses,
    startDate: data.startDate,
    dueDate: data.dueDate,
  };
  $scope.classes = [];  // 尚未发布过的班级列表
  $scope.loading = oedUserClass.queryClassByCpId({
    cpId: $scope.record.cpId
  }).$promise.then((allClasses: any) => {
    $scope.classes = allClasses;
  });

  $scope.loading = oedZhongZhiSchool.isZhongZhiSchool().then(res => {
    $scope.isZhongZhiSchool = res;
  })

  $scope.openDueDate = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.dueDateOpened = true;
  };

  $scope.openStartDate = ($event) => {
    $event.preventDefault();
    $event.stopPropagation();
    $scope.startDateOpened = true;
  };

  $scope.status = false;
  $scope.ok = () => {
    $scope.showValidationError = true;
    if ($scope.publishForm.$invalid) {
      $scope.publishForm.$valid = false;
      $scope.errorMsg = '选择的日期不正确，请检查。';
      return;
    }
    if (!$scope.record.startDate) {
      $scope.publishForm.$valid = false;
      $scope.errorMsg = '请选择开始时间';
      return;
    }
    if (!$scope.record.dueDate) {
      $scope.publishForm.$valid = false;
      $scope.errorMsg = '请选择结束时间';
      return;
    }
    const clsIds = _.map($scope.record.classes, 'id');
    const startDate = moment($scope.record.startDate).startOf('day').valueOf();
    const dueDate = moment($scope.record.dueDate).startOf('day').valueOf();
    if (clsIds.length === 0) {
      $scope.publishForm.$valid = false;
      $scope.errorMsg = '请选择班级';
      return;
    } else if (startDate >= dueDate) {
      $scope.publishForm.$valid = false;
      $scope.errorMsg = '结束时间不能早于开始时间';
      return;
    }
    $uibModalInstance.close({
      startDate: $scope.record.startDate,
      dueDate: $scope.record.dueDate,
      selectedClasses: $scope.record.classes
    });
  };

  $scope.reset = () => {
    $scope.record.classes = $scope.classes;
    $scope.record.startDate = new Date('2015-09-01');
    $scope.record.dueDate = new Date();
  }

}
