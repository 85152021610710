(function() {
  angular.module('app.resources', [])
  .service('oedHttpCache', ['CacheFactory', function(CacheFactory) {
      var defaultCache = CacheFactory('defaultCache', {
        maxAge: 60 * 1000, //默认缓存一分钟
        deleteOnExpire: 'aggressive'
      });
      var longCache = CacheFactory('longCache', {
        maxAge: 60 * 60 * 1000, //较长缓存为一小时
        deleteOnExpire: 'aggressive'
      });
      return {
        defaultCache: defaultCache,
        longCache: longCache
      };
    }]).factory('oedUserInfo', ['$resource', 'oedHttpCache', 'oedConfig', function($resource, oedHttpCache, oedConfig) {
      return $resource(oedConfig.url('userinfo/:id'), {
        id: '@id'
      }, {
        getByUid: {
          url: oedConfig.url_b('userinfo/:id'),
          method: 'GET',
          cache: oedHttpCache.defaultCache
        },
        getProfileByUid: {
          url: oedConfig.url_b('userinfo/:id/whole'),
          method: 'GET',
          cache: oedHttpCache.defaultCache
        },
        getDefaultPasswordByUid: {
          url: oedConfig.url('userinfo/defaultPassword/:uid'),
          method: 'GET'
        },
        getByCloudTestId: {
          url: oedConfig.url_b('cloudtest/:id/owner'),
          method: 'GET',
          cache: oedHttpCache.longCache
        },
        get: {
          url: oedConfig.url('login/userinfo'),
          method: 'GET'
        },
        getByCloudPresentResourceId: {
          url: oedConfig.url_b('cloudpresentresource/:id/owner'),
          cache: oedHttpCache.longCache
        },
        getByCloudPresentResourceItemId: {
          url: oedConfig.url_b('cloudpresentresourceitem/:id/owner'),
          cache: oedHttpCache.longCache
        },
        signup: {
          url: oedConfig.url('login/signup/v2'),
          method: 'POST'
        },
        signupVerifyCode: {
          url: oedConfig.url('login/signup/getVerifyCode'),
          method: 'POST'
        },
        sendSignupEmail: {
          url: oedConfig.url('login/signup/sendSignupEmail/:id'),
          method: 'POST'
        },
        findPassword: {
          url: oedConfig.url('login/findpassword'),
          method: 'POST'
        },
        updatePassowrd: {
          url: oedConfig.url('userinfo/:id/password'),
          method: 'POST'
        },
        updatePassowrdForce: {
          url: oedConfig.url('login/updatePasswordForForce'),
          method: 'POST'
        },
        updateAvatar: {
          url: oedConfig.url('userinfo/:id/avatar'),
          method: 'POST'
        },
        resetPassword: {
          url: oedConfig.url('userinfo/:id/password/reset'),
          method: 'GET',
          params: {
            resetThirdParty: '@resetThirdParty',
          },
        },
        queryTeachersBySchool: {
          url: oedConfig.url('school/:schoolId/teachers'),
          method: 'GET',
          isArray: true
        },
        activateAccount: {
          url: oedConfig.url('login/activate/:code'),
          method: 'GET'
        },
        updateStudentInfo: {
          url: oedConfig.url('userinfo/:id/updateStudentInfo'),
          method: 'POST'
        },
        createAndJoinClass: {
          url: oedConfig.url('classroom/:classId/createStudent'),
          method: 'POST'
        },
        queryStdsByBoardSessionId: {
          url: oedConfig.url_b('boardsession/:id/students'),
          isArray: true
        },
        changeVaildSession: {
          url: oedConfig.url('userinfo/updateValidClassSession/:validClassSession'),
          method: 'GET'
        },
        batchQueryByUid: {
          url: oedConfig.url_b('resourceAuth/batchQueryByUid'),
          params: {
            testIds: '@uid'
          },
          method: 'GET',
          isArray: true
        },
        batchCreateOrUpdate: {
          url: oedConfig.url_b('resourceAuth/batchCreateOrUpdate'),
          method: 'POST',
        },
        deleteResourceAuth: {
          url: oedConfig.url_b('resourceAuth/uid/:uid'),
          method: 'DELETE'
        },
        getUserPreference: {
          url: oedConfig.url_b('userPreference/uid/:uid/newPrepareLessonState/v2'),
          method: 'GET'
        },
        getZlwhResourcePreference:{
          url: oedConfig.url_b('schoolPreference/schoolId/:schoolId/newPrepareLessonResourceState'),
          method: 'GET'
        },
        updateNewPrepareLesson:{
          url: oedConfig.url_b('userPreference/updateNewPrepareLessonState/v2'),
          method: 'POST'
        }
      });
    }])
    .factory('oedCoursePath', ['$resource', 'oedConfig', function($resource, oedConfig) {
      var res = $resource(oedConfig.url('coursepath/:id'), {
        id: '@id'
      }, {
        queryAll: {
          url: oedConfig.url_b('coursepath/mylist'),
          method: 'GET',
          cache: false,
          isArray: true
        },
        queryByTerm: {
          url: oedConfig.url('coursepath/byterm/:termId'),
          method: 'GET',
          cache: false,
          isArray: true
        },
        queryFilter: {
          url: oedConfig.url_b('coursepath/query'),
          method: 'POST',
          isArray: true
        },
        queryFilterInSchool: {
          url: oedConfig.url_b('coursepath/query/wholeSchool'),
          method: 'GET',
          isArray: true
        },
        finalDeleteOrRecoveryCoursePath: {
          url: oedConfig.url_b('coursepath/:id/completeDelete/:flag'),
          method: 'get',
        },
        queryDeleteFilter: {
          url: oedConfig.url_b('coursepath/withDeletedCourses'),
          method: 'get',
          isArray: true
        },
        startCourse: {
          url: oedConfig.url('coursepath/:id/start'),
          method: 'GET'
        },
        endCourse: {
          url: oedConfig.url('coursepath/:id/end'),
          method: 'GET'
        },
        queryCoursePathsByUserAndSubject: {
          url: oedConfig.url('coursepath/allofuser/:userId/subject/:subjectId'),
          method: 'GET',
          isArray: true
        },
        importContentFromExisting: {
          url: oedConfig.url('coursepath/:id/importfromexisting/:cpIds'),
          method: 'GET'
        },
        queryCoursePathWithTaskByStudent: {
          url: oedConfig.url_b('coursepath/withtasks/forstudent/:uid'),
          method: 'GET',
          isArray: true
        },
        manageCoursePathArchive: {
          url: oedConfig.url_b('coursepath/:id/archive'),
          method: 'POST'
        },
        markCouresAsTemplate: {
          url: oedConfig.url('coursepath/:id/marktemplate'),
          method: 'POST'
        },
        unMarkCouresAsTemplate: {
          url: oedConfig.url('coursepath/:id/unmarktemplate'),
          method: 'POST'
        },
        removeCourseFromTemplate: {
          url: oedConfig.url('coursepath/course/:id/removetemplate'),
          method: 'POST'
        },
        updateCourseOfTemplate: {
          url: oedConfig.url('coursepath/course/:id/updatetemplate'),
          method: 'POST'
        },
        updateUseTempate: {
          url: oedConfig.url('userinfo/:id/usetemplate'),
          method: 'POST'
        },
        updateImportedTemplate: {
          url: oedConfig.url_b('course/update/importedtemplate'),
          method: 'POST'
        },
        updateCoursePathOrder: {
          url: oedConfig.url_b('coursepath/updateorders'),
          method: 'POST'
        },
        getAllLevels: {
          url: oedConfig.url_b('coursepath/levels/all'),
          method: 'GET',
          isArray: true
        },
        getAllSubjects: {
          url: oedConfig.url_b('coursepath/subjects/all'),
          method: 'GET',
          isArray: true
        },
        getAllVersions: {
          url: oedConfig.url_b('coursepath/versions/all'),
          method: 'GET',
          isArray: true
        },
        getAllLgvs: {
          url: oedConfig.url_b('coursepath/lgvs/all'),
          method: 'GET',
          isArray: true
        },
        getAllBooks: {
          url: oedConfig.url_b('coursepath/books/all'),
          method: 'GET',
          isArray: true
        },
        getByRelId: {
          url: oedConfig.url_b('coursepath/relId/:id'),
          method: 'GET',
          isArray: true
        },
        getBySectionId: {
          url: oedConfig.url_b('coursepath/sectionId/:id'),
          method: 'GET',
          isArray: true
        }
      });

      res.prototype.isOnGoing = function(now) {
        var dtStart = moment(this.startDate);
        var dtEnd = moment(this.endDate);

        return dtStart.isBefore(now) && now.isBefore(dtEnd);
      };

      res.prototype.isUnStarted = function(now) {
        var dtStart = moment(this.startDate);
        return now.isBefore(dtStart);
      };

      return res;
    }])
    .factory('oedSection', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('sections'), {}, {
        queryAll: {
          url: oedConfig.url('sections/list'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('cloundApi', ['$resource', 'oedConfig',function($resource,oedConfig){
      return $resource(oedConfig.url_b('cloudDrive/:id'), {
        id: '@id'
      }, {
        queryAllDirs: {
          url: oedConfig.url_b('cloudDrive/allDirs'),
          method: 'GET',
          isArray: true
        },
        getFullPath:{
          url: oedConfig.url_b('cloudDrive/:id/fullPath'),
          method: 'GET',
          isArray: true
        },
        getFileByPath:{
          url: oedConfig.url_b('cloudDrive/:id/items?page=:page&pageSize=:pageSize'),
          method: 'GET',
          isArray: true
        },
        deleteFile:{
          url: oedConfig.url_b('cloudDrive/delete'),
          method: 'POST'
        },
        createDir:{
          url: oedConfig.url_b('cloudDrive/createDir'),
          method: 'POST'
        },
        updateFile:{
          url: oedConfig.url_b('cloudDrive/:id/update'),
          method: 'POST'
        },
        createRootDir:{
          url: oedConfig.url_b('cloudDrive/createRootDir'),
          method: 'POST'
        },
        moveFile:{
          url: oedConfig.url_b('cloudDrive/moveTo/:newParentId'),
          method: 'POST'
        },
        copyFile:{
          url: oedConfig.url_b('cloudDrive/copyTo/:newParentId'),
          method: 'POST'
        },
        createContent:{
          url: oedConfig.url_b('cloudDrive/createContent'),
          method: 'POST'
        },
        recycle:{
          url: oedConfig.url_b('cloudDrive/recycle'),
          method: 'POST'
        },
        microLesson:{
          url: oedConfig.url_b('cloudDrive/microLesson/query?page=:page&pageSize=:pageSize'),
          method: 'GET',
          isArray: true
        },
        microLessonRecycle:{
          url: oedConfig.url_b('cloudDrive/microLesson/recycle'),
          method: 'POST',
        },
        recycleBin:{
          url: oedConfig.url_b('cloudDrive/recycleBin?page=:page&pageSize=:pageSize'),
          method: 'GET',
          isArray: true
        },
        recycleBin_microLesson:{
          url: oedConfig.url_b('cloudDrive/microLesson/recycleBin?page=:page&pageSize=:pageSize'),
          method: 'GET',
          isArray: true
        },
        restoreFile:{
          url: oedConfig.url_b('cloudDrive/:id/restore'),
          method: 'POST',
        },
        restoreCourse:{
          url: oedConfig.url_b('cloudDrive/microLesson/restore'),
          method: 'POST',
        },
        recycleBin_DeleteMicroLesson:{
          url: oedConfig.url_b('cloudDrive/microLesson/delete'),
          method: 'POST',
        },
        recycleBin_DeleteFile:{
          url: oedConfig.url_b('cloudDrive/delete'),
          method: 'POST',
        },
        clearRecycleBin:{
          url: oedConfig.url_b('cloudDrive/clearRecycleBin'),
          method: 'POST',
        },
        clearRecycleBin_microLesson:{
          url: oedConfig.url_b('cloudDrive/microLesson/clearRecycleBin'),
          method: 'POST',
        },
        totalSize:{
          url: oedConfig.url_b('cloudDrive/totalSize'),
          method: 'GET',
        },
        totalSize_microLesson:{
          url: oedConfig.url_b('cloudDrive/microLesson/totalSize'),
          method: 'GET',
        },
        getFileTotalCount:{
          url: oedConfig.url_b('cloudDrive/:id/count'),
          method: 'GET',
        },
        getMicroLessonTotalCount:{
          url: oedConfig.url_b('cloudDrive/microLesson/count'),
          method: 'GET',
        },
        getFileCountByRecycBin:{
          url: oedConfig.url_b('cloudDrive/recycleBin/count'),
          method: 'GET',
        },
        getMicroLessonCountByRecycBin:{
          url: oedConfig.url_b('cloudDrive/microLesson/recycleBin/count'),
          method: 'GET',
        },
        restoreAgain:{
          url: oedConfig.url_b('cloudDrive/:id/restoreAgain'),
          method: 'POST',
        },
        importCloudDriveItem:{
          url: oedConfig.url_b('prepare/presentResource/cloudDriveItem/course/:courseId/teachingModule/:moduleId/presentResource/:presentResourceId/batchImport'),
          method: 'POST',
        },
        importMicroLessonItem:{
          url: oedConfig.url_b('prepare/presentResource/microLessonRecord/course/:courseId/teachingModule/:moduleId/presentResource/:presentResourceId/batchImport'),
          method: 'POST',
        },
      })
    }])
    .factory('oedCourse', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('course/:id'), {
        id: '@id'
      }, {
        queryByPath: {
          url: oedConfig.url('coursepath/:pathId/courses'),
          method: 'GET',
          isArray: true
        },
        newQueryCourseListByPath: {
          url: oedConfig.url_b('coursepath/:pathId/courses'),
          method: 'GET',
          isArray: true
        },
        queryDeleteCourseByPath: {
          url: oedConfig.url_b('course/:id/deletedCourses'),
          method: 'GET',
          isArray: true
        },
        finalDeleteCourse: {
          url: oedConfig.url_b('course/:id/finalDelete'),
          method: 'POST',
          isArray: true
        },
        deleteCourse: {
          url: oedConfig.url_b('course/:id'),
          method: 'DELETE',
          isArray: true
        },
        recoveryCourse: {
          url: oedConfig.url_b('course/:id/restore'),
          method: 'POST',
          isArray: true
        },
        querySectionDetailById: {
          url: oedConfig.url_b('course/section/:sectionId/coursepath/:coursePathId/deep'),
          method: 'GET',
          isArray: true
        },
        addCourseByPathIdandSectionIds:{
          url: oedConfig.url_b('course'),
          method: 'POST',
        },
        getByTeachingPatternId: {
          url: oedConfig.url_b('teachingpattern/:id/course')
        },
        updateCourseOrder: {
          url: oedConfig.url_b('course/update/courseorders'),
          method: 'POST'
        },
        updateTestOrder: {
          url: oedConfig.url_b('course/update/testorders'),
          method: 'POST'
        },
        updatePresentResourceOrder: {
          url: oedConfig.url_b('course/update/presentresourceorders'),
          method: 'POST'
        },
        getZlClass: {
          url: oedConfig.url_b('prepare/qualityClass/:sectionId'),
          method: 'GET',
          isArray: true
        },
        getRecommendClass: {
          url: oedConfig.url_b('prepare/recommendClass/:sectionId'),
          method: 'GET',
          isArray: true
        },
        createQualityCourse: {
          url: oedConfig.url_b('prepare/qualityCourse/create'),
          method: 'POST'
        },
        createRecommendCourse: {
          url: oedConfig.url('course/courseFromTemplate'),
          method: 'POST'
        },
        editCourseName: {
          url: oedConfig.url_b('course/update/rename'),
          method: 'POST'
        }
      });
    }])
    // 考试模块
    .factory('oedExamination', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('exam/:id'), {
        id: '@id'
      }, {
        queryByPath: {
          url: oedConfig.url_b('exam/coursepath/:id/all '),
          method: 'GET',
          isArray: true
        },
        addTestPaper: {
          url: oedConfig.url_b('exam'),
          method: 'POST',
        },
        updateTestPaper: {
          url: oedConfig.url_b('exam/:id/updateWithSections'),
          method: 'POST',
        },
        updateCourseOrder: {
          url: oedConfig.url_b('exam/updateorders'),
          method: 'POST'
        },
        getTestPaperDetail: {
          url: oedConfig.url_b('exam/:id/details'),
          method: 'GET',
        },
        updateExamInfoAndSection: {
          url: oedConfig.url_b('exam/:id/updateWithSections'),
          method: 'POST'
        },
        updateParts: {
          url: oedConfig.url_b('exam/:id/updateDetails'),
          method: 'POST'
        },
        createExamQuestion: {
          url: oedConfig.url_b('question/part/:id'),
          method: 'POST',
          isArray: true
        },
        deleteExamQuestion: {
          url: oedConfig.url_b('examPart/:id/question/:questionId'),
          method: 'DELETE',
        },
        updateQuestionOrders: {
          url: oedConfig.url_b('examPart/:partId/question/updateorders'),
          method: 'POST',
        },
        updatePartOrders: {
          url: oedConfig.url_b('examPart/updateorders'),
          method: 'POST',
        },
        getCoursePath: {
          url: oedConfig.url('coursepath/:id'),
          method: 'GET'
        },
        getMapping: {
          url: oedConfig.url_b('mapping/:oem/subject/:subjectId/version/:versionId/grade/:gradeId'),
          method: 'GET'
        },
        getRecommendList: {
          url: oedConfig.url_b('exam/section/:sectionId/recommend'),
          method: 'GET',
          isArray: true
        },
        replacePartQuestion: {
          url: oedConfig.url_b('exam/:examId/part/:partId/question/:questionId/replace'),
          method: 'POST',
        },
        saveExamTest: {
          url: oedConfig.url_b('exam/createWhole'),
          method: 'POST',
        },
        deleteExamTest: {
          url: oedConfig.url_b('exam/:examId'),
          method: 'DELETE',
        },
        getExamTestWhole: {
          url: oedConfig.url_b('exam/:examId/whole'),
          method: 'GET',
        },
        getExamSearch: {
          url: oedConfig.url_b('examSession/:sessionId/search'),
          method: 'GET',
        },
        updateExamInfo: {
          url: oedConfig.url_b('exam/:id'),
          method: 'POST',
        },
        deleteQuestion: {
          url: oedConfig.url_b('exam/:examId/part/:partId/question/:qId'),
          method: 'DELETE',
        },
        deleteChildQuestion: {
          url: oedConfig.url_b('exam/:examId/part/:partId/question/:qId/child/:cqId'),
          method: 'DELETE',
        },
        updateExamQuesScore: {
          url: oedConfig.url_b('exam/:examId/part/:partId/question/:questionId/point'),
          method: 'POST',
        },
        getExamSections: {
          url: oedConfig.url_b('exam/section/:sectionId/forTeacher'),
          method: 'GET',
          isArray: true
        },
        getCoursePathForRecycled: {
          url: oedConfig.url_b('exam/coursepath/forRecycled'),
          method: 'GET',
          isArray: true
        },
        getRecycledList: {
          url: oedConfig.url_b('exam/coursepath/:coursepathId/recycled'),
          method: 'GET',
          isArray: true
        },
        restoreExamTest: {
          url: oedConfig.url_b('exam/:examId/restore'),
          method: 'POST',
        },
        deleteExamTestComplete: {
          url: oedConfig.url_b('exam/:examId/complete'),
          method: 'DELETE',
        },
        getExamSessions: {
          url: oedConfig.url_b('exam/:examId/sessions'),
          method: 'GET',
          isArray: true,
        },
        reviewStdExamQuestion: {
          url: oedConfig.url_b('examAnswer/review/:examSessionId/:studentId/:questionId/point/:point'),
          method: 'POST',
        },
        getExamSessionSbjAnswers: {
          url: oedConfig.url_b('examSession/:id/subjAnswers'),
          method: 'GET',
          isArray: true,
        },
        getExamSessionStdStat: {
          url: oedConfig.url_b('examSession/:id/students'),
          method: 'GET',
          isArray: true,
        },
        endReview: {
          url: oedConfig.url_b('examSession/:id/endReview'),
          method: 'POST',
        }
      });
    }])
    // end
    .factory('oedTest', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('test/:id'), {
        id: '@id'
      }, {
        getByIds: {
          url: oedConfig.url_b('test/byIds'),
          method: 'GET',
          isArray: true
        },
        getOralCalc: {
          url: oedConfig.url_b('test/:id/oralCalc'),
          method: 'GET',
        }
      })
    }])
    .factory('oedObjectiveTest', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('objectivetest/:id'), {
        id: '@id'
      }, {
        queryByCourse: {
          url: oedConfig.url_b('course/:course_id/objectivetests'),
          method: 'GET',
          isArray: true
        },
        queryByTaskListId: {
          url: oedConfig.url_b('tasklist/:id/tests'),
          isArray: true
        },
        getTestWithoutAnswer: {
          url: oedConfig.url_b('objectivetest/:id/withoutanswer')
        },
        queryByCourseIncEmpty: {
          url: oedConfig.url_b('course/:course_id/objectivetests_inc_empty'),
          method: 'GET',
          isArray: true
        },
        getPreviewByCloudRes: {
          url: oedConfig.url('cloudtest/:res_id/getpreview'),
          method: 'GET'
        },
        getDownloadByCloudRes: {
          url: oedConfig.url('cloudtest/:res_id/getdownload'),
          method: 'GET'
        },
        getObjTestQuestionType: {
          url: oedConfig.url_b('test/:id/questionType'),
          method: 'GET'
        },
        createObjPackge: {
          url: oedConfig.url_b('prepare/objectivetest/teachingModule/:moduleId/create?teachingPatternId=:tpId'),
          method: 'POST'
        },
        getGuidanceCasePdf:{
          url: oedConfig.url('pdfParse/resource/:resourceId'),
          method: 'GET'
        },
        createGuidanceCaseTest:{
          url: oedConfig.url_b('prepare/objectivetest/teachingModule/:moduleId/resource/:resourceId/pdf/create?teachingPatternId=:tpId'),
          method: 'POST'
        },
        restartTestSessionByTestSessionIdAndStudentId: {
          url: oedConfig.url_b('testsession/:testSessionId/student/:studentId/restart'),
          method: 'POST'
        }
      });
    }]).factory('oedObjectiveTestStats', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('objectivetest/:id/stats'), {
        id: '@id'
      }, {
        queryByTestId: {
          url: oedConfig.url_b('objectivetest/:id/stats'),
          isArray: true
        }
      });
    }]).factory('oedSubjectiveTest', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('subjectivetest/:id'), {
        id: '@id'
      }, {
        queryByCourse: {
          url: oedConfig.url_b('course/:course_id/subjectivetests'),
          method: 'GET',
          isArray: true
        },
        queryByCourseIncEmpty: {
          url: oedConfig.url_b('course/:course_id/subjectivetests_inc_empty'),
          method: 'GET',
          isArray: true
        },
        getPreviewByCloudRes: {
          url: oedConfig.url('cloudsubjectivetest/:res_id/getpreview'),
          method: 'GET'
        },
        getDownloadByCloudRes: {
          url: oedConfig.url('cloudsubjectivetest/:res_id/getdownload'),
          method: 'GET'
        },
        getSubjTestWithPositionDetails: {
          url: oedConfig.url('subjectivetest/:id'),
          method: 'GET'
        },
        getQuestionType: {
          url: oedConfig.url_b('question/simpleType/query/subject/:subjectId/level/:levelId'),
          method: 'GET',
          isArray: true
        },
        updateQuestionTypeInfo: {
          url: oedConfig.url_b('question/simpleType/update/:questionTypeId'),
          method: 'POST'
        },
        createSubjPackge: {
          url: oedConfig.url_b('prepare/subjectivetest/teachingModule/:moduleId/create?teachingPatternId=:tpId'),
          method: 'POST'
        }
      });
    }])
    .factory('oedPresentResource', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('presentresource/:id'), {
        id: '@id'
      }, {
        getByIds: {
          url: oedConfig.url_b('presentresource/byIds'),
          method: 'GET',
          isArray: true
        },
        queryByCourse: {
          url: oedConfig.url_b('course/:course_id/presentresource'),
          method: 'GET',
          isArray: true
        },
        queryByCourseIncEmpty: {
          url: oedConfig.url_b('course/:course_id/presentresource_inc_empty'),
          method: 'GET',
          isArray: true
        },
        queryByTaskListId: {
          url: oedConfig.url_b('tasklist/:id/presentresources'),
          isArray: true
        },
        getPreviewByCloudRes: {
          url: oedConfig.url('cloudpresentresource/:res_id/getpreview'),
          method: 'GET'
        },
        getDownloadByCloudRes: {
          url: oedConfig.url('cloudpresentresource/:res_id/getdownload'),
          method: 'GET'
        },
        importItemsFromCloudRes: {
          url: oedConfig.url('presentresource/:id/importcloud/:res_id'),
          method: 'GET'
        },
        importResourceItem: {
          url: oedConfig.url('presentresource/:id/importitem/:item_id'),
          method: 'GET'
        },
        importItemFromMicroLessonRecord: {
          url: oedConfig.url('presentresource/:id/importmicrorecord/:record_id'),
          method: 'GET'
        },
        updateItemOrder: {
          url: oedConfig.url_b('presentresource/:id/updateitemorder'),
          method: 'POST'
        },
        downloadrecord: {
          url: oedConfig.url_b('presentresource/:preResId/downloadrecord/:preResItemId'),
          method: 'POST'
        },
        createResourcePackge: {
          url: oedConfig.url_b('prepare/presentResource/teachingModule/:moduleId/create?teachingPatternId=:tpId'),
          method: 'POST'
        },
        updatePackageItem:{
          url: oedConfig.url_b('prepare/presentResource/item/order/update'),
          method: 'POST'
        },
        moveItemToNewPreRes:{
          url: oedConfig.url_b('prepare/presentResourceItem/:itemId/moveItemToNewPreRes/teachingModule/:moduleId'),
          method: 'POST'
        },
        moveItemToNewPreResByIndex:{
          url: oedConfig.url_b('prepare/presentResourceItem/:itemId/moveItemToNewPreRes/teachingModule/:moduleId/index/:index'),
          method: 'POST'
        },
        dragLeftPackageToModule:{
          url: oedConfig.url_b('prepare/presentResource/course/:courseId/from/:fromTmId/to/:toTmId/presentResource/:presentResourceId/replace'),
          method: 'POST'
        },
        dragLeftPackageToModuleByIndex:{
          url: oedConfig.url_b('prepare/presentResource/course/:courseId/from/:fromTmId/to/:toTmId/presentResource/:presentResourceId/index/:index/replace'),
          method: 'POST'
        },
        dragLeftSubjectiveToModule:{
          url: oedConfig.url_b('prepare/subjectivetest/course/:courseId/from/:fromTmId/to/:toTmId/test/:testId/replace'),
          method: 'POST'
        },
        dragLeftSubjectiveToModuleByIndex:{
          url: oedConfig.url_b('prepare/subjectivetest/course/:courseId/from/:fromTmId/to/:toTmId/test/:testId/index/:index/replace'),
          method: 'POST'
        },
        dragLeftObjectiveToModule:{
          url: oedConfig.url_b('prepare/objectivetest/course/:courseId/from/:fromTmId/to/:toTmId/test/:testId/replace'),
          method: 'POST'
        },
        dragLeftObjectiveToModuleByIndex:{
          url: oedConfig.url_b('prepare/objectivetest/course/:courseId/from/:fromTmId/to/:toTmId/test/:testId/index/:index/replace'),
          method: 'POST'
        }
      });
    }])
    .factory('oedNewPrepareCourse', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('prepare/'), {}, {
        copyObjectiveTest: {
          url: oedConfig.url_b('prepare/objectivetest/course/:courseId/teachingModule/:teachingModuleId/test/:testId/copy'),
          method: 'POST',
          isArray: false
        },
        copyObjectiveTestByIndex: {
          url: oedConfig.url_b('prepare/objectivetest/course/:courseId/teachingModule/:teachingModuleId/test/:testId/index/:index/copy'),
          method: 'POST',
          isArray: false
        },
        copySubjective: {
          url: oedConfig.url_b('prepare/subjectivetest/course/:courseId/teachingModule/:teachingModuleId/test/:testId/copy'),
          method: 'POST',
          isArray: false
        },
        copySubjectiveByIndex: {
          url: oedConfig.url_b('prepare/subjectivetest/course/:courseId/teachingModule/:teachingModuleId/test/:testId/index/:index/copy'),
          method: 'POST',
          isArray: false
        },
        copySingleResource: {
          url: oedConfig.url_b('prepare/presentResourceItem/import'),
          method: 'POST',
          isArray: false
        },
        copySingleResourceByIndex: {
          url: oedConfig.url_b('prepare/presentResourceItem/importWithIndex'),
          method: 'POST',
          isArray: false
        },
        copyPackage:{
          url: oedConfig.url_b('prepare/presentResource/course/:courseId/teachingModule/:moduleId/presentResource/:packageId/copy'),
          method: 'POST',
          isArray: false
        },
        copyPackageByIndex: {
          url: oedConfig.url_b('prepare/presentResource/course/:courseId/teachingModule/:moduleId/presentResource/:packageId/index/:index/copy'),
          method: 'POST',
          isArray: false
        },
        getUuid:{
          url: oedConfig.newBase('resource/uuid/:uuid/pdf'),
          method: 'HEAD',
          isArray: false
        },
        dragAllModule: {
          url: oedConfig.url('teachingpattern/:tpId/importmodules/:moduleId/index/:index'),
          method: 'GET',
          params: {
            moduleId: '@moduleId',
          }
        }
      });
    }])
    .factory('oedPresentResourceSection', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('nil/:id'), {
        id: '@id'
      }, {
        queryByPresentResId: {
          url: oedConfig.url_b('presentresource/:resId/sections'),
          isArray: true
        },
        updatePresentResourceSections: {
          url: oedConfig.url_b('presentresource/:resId/sections'),
          method: 'POST',
          isArray: true
        }
      });
    }])
    .factory('oedMicroLessonRecord', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('microlessonrecord/:id'), {
        id: '@id'
      }, {
        queryAllRecordByTeacher: {
          url: oedConfig.url('microlessonrecord/teacher/:uid/list'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedResource', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('resource/:resourceId'), {
        resourceId: '@resourceId'
      }, {
        getResourceInfoByUuid: {
          url: oedConfig.url_b('resource/uuid/:uuid'),
          method: 'GET'
        },
        createResource: {
          url: oedConfig.url_b('resource/create'),
          method: 'POST',
          headers: { 'Content-Type': 'multipart/form-data' }
        },
        pdf2jpg: {
          url: oedConfig.url_b('resource/:id/pdf2jpg'),
          method: 'POST',
          isArray: true
        },
        getResourceInfoById: {
          url: oedConfig.url_b('resource/:id'),
          method: 'GET'
        }
      });
    }])
    .factory('oedPresentResourceItem', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('presentresourceitem/:id'), {
        id: '@id'
      }, {
        importItemsByDownloadTask: {
          url: oedConfig.url_b('presentresource/:resId/importByDownloadTask/:taskId'),
          method: 'POST',
          isArray: true,
        },
        editPresentResourceItemName: {
          url: oedConfig.url_b('presentresourceitem/rename'),
          method: 'POST',
        }
      });
    }])
    .factory('oedOfflineResource', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('offlineresource/:id'), {
        id: '@id'
      }, {
        queryByCourse: {
          url: oedConfig.url('course/:course_id/offlineresource'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedOfflineResourceItem', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('offlineresourceitem/:id'), {
        id: '@id'
      });
    }])
    .factory('oedPickedCourse', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('prepare/qualityResource'), {}, {
        getPickedCourse: {
          url: oedConfig.url_b('prepare/qualityResource/:sectionId/:classTime'),
          method: 'GET',
          isArray: false
        },
        getZhonglianPickedCourse: {
          url: oedConfig.url_b('prepare/qualityResource2/:sectionId/:classTime'),
          method: 'GET',
          isArray: false
        },
      });
    }])
    .factory('oedBusinessCourse', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('prepare/qualityResourceByBussiness'), {}, {
        getBusinessCourse: {
          url: oedConfig.url_b('prepare/qualityResourceByBussiness/:sectionId'),
          method: 'GET',
          isArray: true
        },
      });
    }])
    .factory('oedTeachingPattern', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('teachingpattern/:id'), {
        id: '@id'
      }, {
        get: {
          url: oedConfig.url_b('teachingpattern/:id'),
          method: 'GET'
        },
        delete: {
          url: oedConfig.url_b('teachingpattern/:id'),
          method: 'DELETE'
        },
        getByCourse: {
          url: oedConfig.url_b('course/:courseId/teachingpattern'),
          method: 'GET',
          isArray: false
        },
        getByCourse2: {
          url: oedConfig.url_b('course/:courseId/teachingPattern'),
          method: 'GET',
          isArray: false
        },
        teachingPatternOfHomework: {
          url: oedConfig.url_b('course/:courseId/teachingPatternOfHomework'),
          method: 'GET',
        },
        updateMoudleOrder: {
          url: oedConfig.url('teachingpattern/:id/updatemoduleorder'),
          method: 'POST'
        },
        getPreviewByCloudRes: {
          url: oedConfig.url('cloudteachingpattern/:res_id/getpreview'),
          method: 'GET'
        },
        getDownloadByCloudRes: {
          url: oedConfig.url('cloudteachingpattern/:res_id/getdownload'),
          method: 'GET'
        },
        importByCourse: {
          url: oedConfig.url('course/:courseId/importcloudteachingpattern/:cloudPatternId'),
          method: 'GET'
        },
        queryCandindateByCourse: {
          url: oedConfig.url('course/:courseId/importedteachingpatterns'),
          method: 'GET',
          isArray: true
        },
        importTeachingModule: {
          url: oedConfig.url('teachingpattern/:id/importmodule/:moduleId'),
          method: 'GET'
        },
        importTeachingModulesFromResource: {
          url: oedConfig.url('teachingpattern/:teachingpatternId/importmodules/:moduleIds/index/:index'),
          method: 'GET'
        },
        importTemplate: {
          url: oedConfig.url('teachingpattern/course/:id/importtemplate'),
          method: 'POST'
        },
        importTemplateById: {
          url: oedConfig.url('teachingpattern/importtemplate'),
          method: 'POST'
        },
        findAllTemplate: {
          url: oedConfig.url('teachingpattern/course/:id/findAllTemplate'),
          method: 'GET'
        },
        clearTeachingPattern: {
          url: oedConfig.url('teachingpattern/:id/clear'),
          method: 'POST'
        },
        queryLatestByClassSessionAndCourseId: {
          url: oedConfig.url('classsession/:classSessionId/lastteachingpattern/ofcourse/:courseId'),
          method: 'GET'
        },
        queryByClassSessionAndCourseId: {
          url: oedConfig.url('classsession/:classSessionId/teachingpatterns/ofcourse/:courseId'),
          method: 'GET',
          isArray: true
        },
        fromTeachingPlan: {
          url: oedConfig.url_b('teachingPlan/:id/toPattern'),
          method: 'POST',
        }
      });
    }])
    .factory('oedTeachingModule', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('teachingmodule/:id'), {
        id: '@id'
      }, {
        updateItemOrder: {
          url: oedConfig.url('teachingmodule/:id/updateitemorder'),
          method: 'POST'
        },
        newUpdateItemOrder: {
          url: oedConfig.url('teachingmodule/:id/updateitemorder?containsEmptyTest=true'),
          method: 'POST'
        },
        getPublishedRecords: {
          url: oedConfig.url('teachingmodule/:id/publishRecords'),
          method: 'GET',
          isArray: true
        },
        getModuleDetails: {
          url: oedConfig.url_b('teachingmodule/:id'),
          method: 'GET',
        },
        updateTmtest:{
          url: oedConfig.url_b('prepare/tmtest/:id/shown/:isShown'),
          method: 'POST',
        },
        updateTmPresentRes:{
          url: oedConfig.url_b('prepare/tmPresentRes/:id/shown/:isShown'),
          method: 'POST',
        }
      });
    }])
    .factory('oedTeachingMaterial', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('teachingmaterial/:id'), {
        id: '@id'
      }, {
        queryAll: {
          url: oedConfig.url('teachingmaterial/all'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedSubjects', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      //
      // 学科
      return $resource(oedConfig.url('subject/:id'), {
        id: '@id'
      }, {
        get: {
          cache: oedHttpCache.longCache
        },
        queryBySchoolId: {
          url: oedConfig.url('school/:schoolId/subjects'),
          method: 'GET',
          isArray: true
        },
        queryByTeacherId: {
          url: oedConfig.url('userinfo/:id/subjects'),
          method: 'GET',
          isArray: true
        },
      });
    }])
    .factory('oedTeachingMaterialVersions', ['$resource', 'oedConfig', function($resource, oedConfig) {
      //
      // 学科
      return $resource(oedConfig.url('teachingmaterialversion/:id'), {
        id: '@id'
      }, {
        queryBySchoolAndSubjectId: {
          url: oedConfig.url('materialversion/school/:schoolId/subject/:subjectId'),
          method: 'GET',
          isArray: true
        },
        queryRootVersionsBySchoolAndSubjectId: {
          url: oedConfig.url('materialversion/school/:schoolId/subject/:subjectId/rootversion'),
          method: 'GET',
          isArray: true
        },
        queryBookListByVersionAndSubject: {
          url: oedConfig.url('materialversion/subject/:subjectId/version/:versionId/books'),
          method: 'GET',
          isArray: true
        },
        queryBySchoolId: {
          url: oedConfig.url('materialversion/school/:schoolId'),
          method: 'GET',
          isArray: true,
          cache: true
        },
        updateVersions: {
          url: oedConfig.url_b('coursepath/versions'),
          method: 'POST',
          isArray: true
        },
        addVersion: {
          url: oedConfig.url_b('coursepath/version'),
          method: 'POST'
        },
        updateBooks: {
          url: oedConfig.url_b('coursepath/books'),
          method: 'POST',
          isArray: true
        },
        addBook: {
          url: oedConfig.url_b('coursepath/book'),
          method: 'POST'
        },
        addBookNew: {
          url: oedConfig.url_b('coursepath/book/new'),
          method: 'POST'
        },
        syncBook: {
          url: oedConfig.url_b('coursepath/sync/relId/:id'),
          method: 'POST'
        },
        getBookSync: {
          url: oedConfig.url_b('coursepath/sync/relId/:id'),
          method: 'GET'
        },
        updateSubjects: {
          url: oedConfig.url_b('coursepath/subjects'),
          method: 'POST',
          isArray: true
        },
        addSubject: {
          url: oedConfig.url_b('coursepath/subject'),
          method: 'POST'
        },
        saveSection: {
          url: oedConfig.url_b('coursepath/sections'),
          method: 'POST',
          isArray: true
        },
        deleteSection: {
          url: oedConfig.url_b('coursepath/section/:id/withId/:withId'),
          method: 'DELETE',
          isArray: true
        },
        setExamSection: {
          url: oedConfig.url_b('coursepath/sections'),
          method: 'POST',
          isArray: true
        },
        saveLgvs: {
          url: oedConfig.url_b('coursepath/lgvs'),
          method: 'POST'
        },
        deleteSubject: {
          url: oedConfig.url_b('coursepath/subject/:id'),
          method: 'DELETE'
        },
        deleteVersion: {
          url: oedConfig.url_b('coursepath/version/:id'),
          method: 'DELETE'
        },
        deleteBook: {
          url: oedConfig.url_b('coursepath/book/:id'),
          method: 'DELETE'
        },
      });
    }])
    .factory('oedUnitItem', ['$resource', 'oedConfig', function($resource, oedConfig) {
      function transformUnitResponse(data, header, status) {
        if (status > 400)
          return null;

        var units = angular.fromJson(data);
        if (_.isEmpty(units))
          return units;
        //
        // form the tree structure of the data
        var allDepths = _.keys(_.reduce(units, function(r, u) {
          r[u.depth] = true;
          return r;
        }, {})).map(function(k) {
          return parseInt(k, 10);
        }).sort();

        function handleDepths(parents, nodes) {}

        var treeNodes = _.filter(units, function(u) {
          return u.depth == allDepths[0];
        });

        //
        // handle root elements
        var handled = _.reduce(treeNodes, function(r, n) {
          r[n.id] = n;
          return r;
        }, {});

        allDepths.splice(0, 1);
        _.each(allDepths, function(depth) {
          var nodes = _.filter(units, function(u) {
            return u.depth == depth;
          });

          _.each(nodes, function(node) {
            if (!node.parentId || !handled[node.parentId]) {
              return; //skip wrong nodes
            }

            var p = handled[node.parentId];
            p.child = p.child || [];
            p.child.push(node);
            handled[node.id] = node;
          });
        });

        var gradeNodes = [];
        var gradeIdNodes = {};
        _.each(treeNodes, function(node) {
          if (_.has(gradeIdNodes, node.gradeId)) {
            gradeIdNodes[node.gradeId].child.push(node);
            return;
          }
          var gradeNode = {
            gradeName: node.gradeName,
            gradeId: node.gradeId,
            child: [node]
          };
          gradeNodes.push(gradeNode);
          gradeIdNodes[node.gradeId] = gradeNode;
        });
        //
        // organize nodes using grade
        return gradeNodes;
      }

      //
      // only return leaf nodes
      function transformUnitResponseLeaf(data, header, status) {
        var nodes = transformUnitResponse(data, header, status);
        if (!nodes) return nodes;

        function traverse(node, fn) {
          fn(node);
          if (!_.isEmpty(node.child)) {
            _.each(node.child, function(child) {
              traverse(child, fn);
            });
          }
        }

        var resultNodes = [];

        var parentNode = {}; //id - > node mapping

        _.each(nodes, function(n) {
          traverse(n, function(node) {
            //
            // leaf node
            if (_.isEmpty(node.child)) {
              if (_.has(parentNode, node.parentId)) {
                node.parent = parentNode[node.parentId];
              }
              resultNodes.push(node);
            } else {
              parentNode[node.id] = node;
            }
          });
        });

        return resultNodes;
      }

      //
      // 学科
      return $resource(oedConfig.url_b('section/:id'), {
        id: '@id'
      }, {
        get: {
          cache: true
        },
        queryByVersionAndSubjectId: {
          url: oedConfig.url('materialversion/:versionId/subject/:subjectId/units'),
          method: 'GET',
          isArray: true,
          transformResponse: transformUnitResponse
        },
        queryByVersionSubjectIdAndGrade: {
          url: oedConfig.url('materialversion/:versionId/subject/:subjectId/grade/:gradeId/units'),
          method: 'GET',
          isArray: true,
          transformResponse: transformUnitResponse
        },
        queryByVersionSubjectIdAndGradeLeaf: {
          url: oedConfig.url('materialversion/:versionId/subject/:subjectId/grade/:gradeId/units'),
          method: 'GET',
          isArray: true,
          transformResponse: transformUnitResponse
        },
        queryAvailableBySchoolAndCourse: {
          url: oedConfig.url_b('sections/school/:schoolId/course/:courseId'),
          method: 'GET',
          isArray: true,
          transformResponse: transformUnitResponse
        },
        queryAvailableBySchoolAndCoursePath: {
          url: oedConfig.url('sections/school/:schoolId/coursepath/:coursePathId'),
          method: 'GET',
          isArray: true,
          transformResponse: transformUnitResponse
        },
        queryItemsInCourse: {
          url: oedConfig.url_b('sections/incourse/:courseId'),
          method: 'GET',
          isArray: true,
          transformResponse: transformUnitResponse
        },
        queryRelatedSectionsInTest: {
          url: oedConfig.url_b('subjectivetest/:id/sections'),
          method: 'GET',
          isArray: true
        },
        queryKnowledgePointSections: {
          url: oedConfig.url_b('knowledgePoint/byLevel/:levelId/subject/:subjectId'),
          isArray: true,
        },
        queryByPresentResId: {
          url: oedConfig.url_b('presentresource/:id/sections2'),
          isArray: true,
        }
      });
    }])
    .factory('oedPreResItem', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('presentresourceitem'), {}, {
        uploadExternalItem: {
          url: oedConfig.url('presentresourceitem/external/upload'),
          method: 'POST',
        }
      })
    }])
    .factory('talOpenApi', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('openapi'), {}, {
        getUserAppRight: {
          url: oedConfig.url_b('thirdParty/tal/openapi/api/app/getUserAppRight'),
          method: 'GET',
          timeout: 5000,
        }
      })
    }])
    .factory('talAreaResource', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return  $resource(oedConfig.url('common'), {}, {
        getPhaseSubjectVersionBooks: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/book/getPhaseSubjectVersionBooks'),
          method: 'GET',
        },
        getChapterByBookId: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/book/getChapters'),
          method: 'GET',
        },
        getDocumentList: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/document/list'),
          method: 'GET',
        },
        getResourceDetail: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/resource/detail'),
          method: 'GET',
        },
        getDownloadInfo: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/download'),
          method: 'GET',
        },
        getPaperList: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/paper/list'),
          method: 'GET',
        },
        getMetasByCodes: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/meta/getMetasByCodes'),
          method: 'GET',
        },
        getShengxueList: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/shengxue/list'),
          method: 'GET',
        },
        getWeikeList: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/weike/list'),
          method: 'GET',
        },
        getPreviewData: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/preview'),
          method: 'GET',
        },
        getObjectiveList: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/question/list'),
          method: 'GET',
        },
        getQuestionDetails: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/question/detail'),
          method: 'GET',
        },
        getKnows: {
          url: oedConfig.url_b('thirdParty/talAreaRes/areares/api/know/getKnows'),
          method: 'GET',
        }
      })
    }])
    .factory('examApi', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('common'), {}, {
        getKnowledgePoint: {
          url: oedConfig.url_b('knowledgePoint/ry/subject/:subjectId/level/:levelId'),
          method: 'GET',
          isArray: true,
        },
        getChapters: {
          url: oedConfig.url_b('outline/ruanyun/chapters/lgvs/:id'),
          method: 'GET',
          isArray: true,
        },
        getCategorys: {
          url: oedConfig.url_b('ry/question/category/level/:levelId/subject/:subjectId'),
          method: 'GET',
          isArray: true,
        },
        getQuestionByIntelligence: {
          url: oedConfig.url_b('question/search/GetQuestionsByIntelligentQuestion'),
          method: 'POST',
          isArray: true,
        },
        deleteExamPart: {
          url: oedConfig.url_b('examPart/:id'),
          method: 'DELETE',
        }
      })
    }])
    .factory('ruanyunResource', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('common'), {},
        {
          getUserSession: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/UserBind/GetUserSession'),
            method: 'POST',
          },
          getQuestionTypes: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Common/GetQuestionCategory'),
            method: 'POST',
          },
          getBookVersions: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Common/GetBookVersion'),
            method: 'POST',
          },
          getCourseMappings: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Common/GetCourseMapping'),
            method: 'POST',
          },
          getProvinces: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Common/GetLocationInfo'),
            method: 'POST',
          },
          getChapterSections: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Common/GetChapterSection'),
            method: 'POST',
          },
          getKnowledgePoint: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Common/GetKnowledgePoint'),
            method: 'POST',
          },
          getQuestionsByChapterSection: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Search/GetQuestionsByChapterSection'),
            method: 'POST',
          },
          getQuestionsByKnowledgePoint: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Search/GetQuestionsByKnowledgePoint'),
            method: 'POST',
          },
          getQuestionsBykeyWord: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Search/GetQuestionsByKeyWord'),
            method: 'POST',
          },
          reportError: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Question/CorrectionQuestion'),
            method: 'POST',
          },
          getRuanyunTopic:{
            url: oedConfig.url_b('ryDocument/baseInfo/:baseType'),
            method: 'GET',
            isArray: true
          },
          queryTopicPaperData:{
            url: oedConfig.url_b('ryDocument/byArgs'),
            method: 'POST',
            isArray: true
          },
          queryTopicPaperDataCount:{
            url: oedConfig.url_b('ryDocument/byArgs/count'),
            method: 'POST',
          },
          getTestPaperDestailQes:{
            url: oedConfig.url_b('thirdParty/ruanyunRes/Search/GetQuestionsBySourceName'),
            method: 'POST',
          },
          getQuestionByIntelligence: {
            url: oedConfig.url_b('thirdParty/ruanyunRes/Search/GetQuestionsByIntelligentQuestion'),
            method: 'POST',
          },
        }
        , {
          stripTrailingSlashes: false
        })
    }])
    .factory('xuersiResource', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('common'), {},
        {
          getBooks: {
            url: oedConfig.url_b('thirdParty/talRes/common/book/non'),
            method: 'GET',
          },
          getChapters: {
            url: oedConfig.url_b('thirdParty/talRes/common/chapters/non'),
            method: 'GET',
          },
          getQuestions: {
            url: oedConfig.url_b('thirdParty/talRes/question/question_search/non'),
            method: 'GET',
          },
          getLevels: {
            url: oedConfig.url_b('thirdParty/talRes/common/level/non'),
            method: 'GET',
          },
          getSubjects: {
            url: oedConfig.url_b('thirdParty/talRes/common/subject/non'),
            method: 'GET',
          },
          getOralQuestion: {
            url: oedConfig.url_b('thirdParty/talResGetDefault/oralCalc1/question/q_info/'),
            method: 'GET',
          },
          generalOralQuestions: {
            url: oedConfig.url_b('thirdParty/talResPostWithAgent/userAgent/RayClassPreparationSystem/thirdParty/rus/rus/'),
            method: 'POST',
          },
        }, {
          stripTrailingSlashes: false
        })
    }])
    .factory('anHuiResource', ['$resource', 'oedConfig', function($resource, oedConfig) {
      // 安徽平台资源
      const currentTP = new Date().getTime()
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        'S-Auth-AppId': '2c994ba6a320426e91a6faa283a68b5d',
        'Accept': 'application/json',
        'S-Auth-Timestamp': `${currentTP}`
      }
      const transformRequest = function(obj) {
        var str = [];
        for(var p in obj)
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        return str.join("&");
      }
      return $resource(oedConfig.url_b('integration'), {},
      {
        listAllPhase: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/listAllPhase'),
          method: 'POST',
          headers,
          transformRequest
        },
        listSubject: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/listSubject'),
          method: 'POST',
          headers,
          transformRequest
        },
        listGrade: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/listGrade'),
          method: 'POST',
          headers,
          transformRequest
        },
        pageBook: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/pageBook'),
          method: 'POST',
          headers,
          transformRequest
        },
        queryResource: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/queryResource'),
          method: 'POST',
          headers,
          transformRequest
        },
        getResourceUrl: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/getResourceUrl'),
          method: 'POST',
          headers,
          transformRequest
        },
        getPreviewUrl: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/getPreviewUrl'),
          method: 'POST',
          headers,
          transformRequest
        },
        listEdition: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/listEdition'),
          method: 'POST',
          headers,
          transformRequest
        },
        listUnit: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/listUnit'),
          method: 'POST',
          headers,
          transformRequest
        },
        addResource: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/addResource'),
          method: 'POST',
          headers,
          transformRequest
        },
        addResToPan: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/addResToPan'),
          method: 'POST',
          headers,
          transformRequest
        },
        receiveResourceFeed: {
          url: oedConfig.url_b('integration/anHuiPlatformProxy/receiveResourceFeed'),
          method: 'POST',
          headers,
          transformRequest
        },
      });
    }])
    .factory('talcloudResource', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('talresource'), {},
        {
          querySubjectByStage: {
            url: oedConfig.url('talresource/common/:stage/subjects'),
            method: 'GET',
            isArray: true,
          },
          queryAllSubjects: {
            url: oedConfig.url('talresource/common/allsubjects'),
            method: 'GET',
            isArray: true,
          },
          queryVersionsByStageAndSubject: {
            url: oedConfig.url('talresource/common/:stage/:subjectId/versions'),
            method: 'GET',
            isArray: true,
          },
          getBooksByVersionId: {
            url: oedConfig.url('talresource/common/:versionId/books'),
            method: 'GET',
            isArray: true,
          },
          getChaptersByBookId: {
            url: oedConfig.url('talresource/common/:bookId/chapters'),
            method: 'GET',
            isArray: true,
          },
          getDocumentsByStageAndSubjectId: { // formParam: chapterId, page, perpage
            url: oedConfig.url('talresource/document/:stage/:subjectId/documents'),
            method: 'GET',
          },
          getDocumentsDetail: {
            url: oedConfig.url('talresource/document/:itemIds/documentsUrl'),
            method: 'GET',
            isArray: true,
          },
          getDocumentPreview: {
            url: oedConfig.url('talresource/document/:itemId/preview'),
            method: 'GET',
            isArray: true,
          },
          getQuestionList: { // formParams: stage, subjectId, chapterId
            url: oedConfig.url('talresource/questions/list'),
            method: 'GET',
          },
          getQuestionDetailsByIds: {
            url: oedConfig.url('talresource/questions/details/:questionIds'),
            method: 'GET',
          },
          getQuestionTypes: {
            url: oedConfig.url('talresource/questions/:stage/:subjectId/types'),
            method: 'GET',
            isArray: true,
          },
          getPaperList: {
            url: oedConfig.url('talresource/papers/list'),
            method: 'GET',
          },
          getPaperDetails: {
            url: oedConfig.url('talresource/papers/details/:id'),
            method: 'GET',
          },
          getTopicList: {
            url: oedConfig.url('talresource/topic/:stage/:subjectId/topics'),
            method: 'GET'
          },
          getTopicTypes: {
            url: oedConfig.url('talresource/topic/:stage/topicTypes'),
            method: 'GET',
            isArray: true
          },
          getKnowledges: {
            url: oedConfig.url('talresource/common/:stage/:subjectId/knowledges'),
            method: 'GET',
            isArray: true
          },
          getTopicDetails: {
            url: oedConfig.url('talresource/topic/:id/detail'),
            method: 'GET',
            isArray: true
          }
        });
    }])
    .factory('oedSchoolSubjectVersion', ['$resource', 'oedConfig', function($resource, oedConfig) {
      //
      // 学科
      return $resource(oedConfig.url('schoolsubjectversion/:id'), {
        id: '@id'
      }, {
        queryBySchoolAndSubjectId: {
          url: oedConfig.url('schoolsubjectversion/school/:schoolId/subject/:subjectId'),
          method: 'GET'
        }
      });
    }])
    .factory('oedUserClass', ['$resource', 'oedConfig', function($resource, oedConfig) {
      //
      // 学科
      return $resource(oedConfig.url('userclass/:id'), {
        id: '@id'
      }, {
        queryByUser: {
          url: oedConfig.url('userinfo/:userId/classes'),
          method: 'GET',
          isArray: true
        },
        queryClassByCpId: {
          url: oedConfig.url('userinfo/:cpId/classes/byCpId'),
          method: 'GET',
          isArray: true
        },
        queryAllBySchool: {
          url: oedConfig.url('school/:schoolId/classes'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedClassUser', ['$resource', 'oedConfig', function($resource, oedConfig) {
      //
      // 学科
      return $resource(oedConfig.url('classroom/:classId/user/:uid'), {
        classId: '@classId',
        uid: '@uid'
      }, {
        queryClassStudents: {
          url: oedConfig.url('classroom/:classId/students'),
          method: 'GET',
          isArray: true
        },
        queryClassTeachers: {
          url: oedConfig.url('classroom/:classId/teachers'),
          method: 'GET',
          isArray: true
        },
        updateItemOrder: {
          url: oedConfig.url_b('classroom/:classId/updateitemorder'),
          method: 'POST',
          isArray: true
        },
        querySchoolStudents: {
          url: oedConfig.url_b('school/:schoolId/students/query?q=:q'),
          method: 'GET',
          isArray: true
        },
        querySchoolUsers: {
          url: oedConfig.url_b('school/:schoolId/user/query/:role?q=:q'),
          method: 'GET',
          isArray: true
        },
        addExistingStudent: {
          url: oedConfig.url_b('classroom/:classId/student/:uid'),
          method: 'POST',
          isArray: false
        },
        addExistingTeacher: {
          url: oedConfig.url_b('classroom/:classId/teacher/:uid'),
          method: 'POST',
          isArray: false
        }
      });
    }])
    .factory('oedSchoolLevel', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('schoollevel/:id'), {
        id: '@id'
      }, {
        querySchoolLevels: {
          url: oedConfig.url('school/:schoolId/levelList'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedSchoolLevelGrade', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('schoollevelgrade/:id'), {
        id: '@id'
      }, {
        queryByLevelId: {
          url: oedConfig.url('school/:schoolId/level/:levelId/grades'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedClassroom', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('classroom/:id'), {
        id: '@id'
      }, {
        queryByLevelAndGradeId: {
          url: oedConfig.url('school/:schoolId/level/:levelId/grade/:gradeId/classes'),
          method: 'GET',
          isArray: true
        },
        deleteOrRecoveryClass: {
          url: oedConfig.url_b('classroom/:classId/delete/:deleteType'),
          method: 'DELETE'
        },
        recoveryClassFromArchive: {
          url: oedConfig.url_b('classroom/:classId/archive/:archive'),
          method: 'POST'
        },
        setClassAdmin: {
          url: oedConfig.url('classroom/:classId/setadmin/:teacherId'),
          method: 'GET'
        },
        removeUserFromClass: {
          url: oedConfig.url('classroom/:classId/user/:uid'),
          method: 'DELETE'
        },
        queryClassByDetails: {
          url: oedConfig.url_b('school/:schoolId/queryClass'),
          method: 'GET',
          params: {
            rxnd: '@rxnd',
            levelId: '@levelId',
            name: '@name'
          },
        }
      });
    }])
    .factory('oedClassTeacherSubjects', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('classroom/:classId/teacher/:teacherId/subjects'), {
        classId: '@classId',
        teacherId: '@teacherId'
      }, {
        queryByClassTeacherId: {
          url: oedConfig.url('classroom/:classId/teacher/:teacherId/subjects'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedActivateCode', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('activatecode/:id'), {
        id: '@id'
      }, {
        enableSchoolActivation: {
          url: oedConfig.url_b('activateCode/enable/school/:schoolId'),
          method: 'POST'
        },
        disableSchoolActivation: {
          url: oedConfig.url_b('activateCode/disable/school/:schoolId'),
          method: 'POST'
        },
        enableSchoolActivateCodeAutoAssign: {
          url: oedConfig.url_b('activateCode/autoAssign/enable/school/:schoolId'),
          method: 'POST'
        },
        disableSchoolActivateCodeAutoAssign: {
          url: oedConfig.url_b('activateCode/autoAssign/disable/school/:schoolId'),
          method: 'POST'
        },
        list: {
          url: oedConfig.url_b('activateCode/list/school/:schoolId'),
          method: 'GET',
          isArray: true
        },
        generate: {
          url: oedConfig.url_b('activateCode/generate/school/:schoolId/count/:count'),
          method: 'POST',
          isArray: true
        }
      });
    }])
    .factory('oedSchool', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('school/:id'), {
        id: '@id'
      }, {
        save: {
          url: oedConfig.url('school'),
          method: 'POST'
        },
        update: {
          url: oedConfig.url('school/:id'),
          method: 'POST'
        },
        updateShare: {
          url: oedConfig.url_b('school/:id/share/:share'),
          method: 'POST'
        },
        queryByName: {
          url: oedConfig.url('school/search'),
          method: 'POST',
          isArray: true
        },
        getSchoolAdminsBySchoolIds: {
          url: oedConfig.url_b('school/admins'),
          method: 'POST'
        },
        getSchoolPreference: {
          url: oedConfig.url_b('schoolPreference/schoolId/:schoolId/newPrepareLessonResourceState'),
          method: 'GET'
        },
        getSchoolScreenBroadCastPreference: {
          url: oedConfig.url_b('schoolPreference/schoolId/:schoolId/screenBroadcast'),
          method: 'GET'
        },
        join: {
          url: oedConfig.url('school/join'),
          method: 'POST'
        },
        joinPublicSchool: {
          url: oedConfig.url('school/joinpublicschool'),
          method: 'GET'
        },
        removeTeacher: {
          url: oedConfig.url('school/removeteacher/:uid'),
          method: 'GET'
        },
        setMaster: {
          url: oedConfig.url('school/setmaster/:uid'),
          method: 'GET'
        },
        setSchoolAdmin: {
          url: oedConfig.url('school/:schoolId/setadmin/:adminName'),
          method: 'GET'
        },
        setSchoolNewPre: {
          url: oedConfig.url_b('schoolPreference/prepareLessonState'),
          method: 'POST'
        },
        searchAdminList: {
          url: oedConfig.url_b('userinfo/list/school/:schoolId/name/:name/start/:start/count/:count'),
          method: 'GET',
          isArray: true
        },
        setSchoolAdmins: {
          url: oedConfig.url_b('school/:schoolId/admin'),
          method: 'POST'
        },
        setSchoolPreference: {
          url: oedConfig.url_b('schoolPreference/schoolId/:schoolId/newPrepareLessonResourceState/:authority'),
          method: 'POST'
        },
        setSchoolScreenBroadCastPreference: {
          url: oedConfig.url_b('schoolPreference/schoolId/:schoolId/screenBroadcast/:value'),
          method: 'POST'
        },
      });
    }])
    .factory('oedSchoolJoinRequest', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('schooljoinrequest/:id'), {
        id: '@id'
      }, {
        queryBySchoolId: {
          url: oedConfig.url('school/:id/joinrequests'),
          method: 'GET',
          isArray: true
        },
        approve: {
          url: oedConfig.url('schooljoinrequest/:id/approve'),
          method: 'GET'
        },
        reject: {
          url: oedConfig.url('schooljoinrequest/:id/reject'),
          method: 'GET'
        }
      });
    }])
    .factory('oedSchoolRequest', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('school/:school_id'), {
        school_id: '@school_id'
      }, {
        queryPendingApprovals: {
          url: oedConfig.url('school/pendingApproval'),
          method: 'GET',
          isArray: true
        },
        approve: {
          url: oedConfig.url('school/:school_id/approve'),
          method: 'GET'
        },
        reject: {
          url: oedConfig.url('school/:school_id/reject'),
          method: 'GET'
        }
      });
    }])
    .factory('oedSchoolTerm', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('schoolterm/:id'), {
        id: '@id'
      }, {
        queryBySchool: {
          url: oedConfig.url('school/:schoolId/terms'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedCity', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('mapinfo/province/:id'), {
        id: '@id'
      }, {
        queryAll: {
          url: oedConfig.url('mapinfo/provinces/'),
          method: 'GET',
          isArray: true
        },
        querySubCities: {
          url: oedConfig.url('mapinfo/city/:id/subcities'),
          method: 'GET',
          isArray: true
        },
        queryCityByDistrict: {
          url: oedConfig.url('mapinfo/child/:id'),
          method: 'GET',
          isArray: false
        }
      });
    }])
    .factory('oedWechat', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('wechat/follow'), {
        userId: '@userId'
      }, {
        follow: {
          method: 'GET',
          isArray: true
        },
        addFollow: {
          method: 'POST'
        }
      });
    }])
    .factory('oedTestHistory', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('testsession'), {
        userId: '@userId',
        sessionId: '@sessionId',
        history: '@history'
      }, {
        history: {
          url: oedConfig.url('testsession/history'),
          method: 'GET',
          cache: true
        },
        historyCoursePaths: {
          url: oedConfig.url('testsession/history/coursepaths'),
          method: 'GET',
          cache: true,
          isArray: true
        },
        historySessions: {
          url: oedConfig.url('testsession/history/sessions'),
          method: 'GET',
          params: {
            userId: '@userId',
            coursepathId: '@coursepathId'
          },
          cache: true,
          isArray: true
        },
        statforreview: {
          url: oedConfig.url('testsession/statforreview'),
          method: 'GET',
          cache: true
        },
        historyOfStudent: {
          url: oedConfig.url('testsession/history/student/:studentId/course/:courseId/:start/to/:end'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedWrongBook', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('wrongbook'), {
        id: '@id'
      }, {
        getWrongBook: {
          url: oedConfig.url_b('wrongbook/sessionsByTime/export'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedCloudTest', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url('cloudtest/:id'), {
        id: '@id'
      }, {
        queryByTestIds: {
          url: oedConfig.url('cloudtest'),
          params: {
            testIds: '@testIds'
          },
          method: 'GET',
          isArray: true
        },
        queryByCloudTestId: {
          url: oedConfig.url_b('cloudtest/:id'),
          method: 'GET'
        },
        queryOnlyById: {
          url: oedConfig.url_b('cloudtest/:id/only'),
          method: 'GET'
        },
        queryByCloudTestOrPreRedId: {
          url: oedConfig.url('cloudtest/querybycloudtestid'),
          method: 'POST'
        },
        queryBySection: {
          url: oedConfig.url_b('cloudtest/query2'),
          isArray: true
        },
        queryExerciseTest: {
          url: oedConfig.url_b('exbk/cloudtest/query'),
          method: 'POST',
          isArray: true
        },
        queryByObjectiveTestId: {
          url: oedConfig.url('objectivetest/:id/cloudres'),
          method: 'GET'
        },
        queryByObjectiveQuestionId: {
          url: oedConfig.url_b('objectivequestion/:id/cloudtest'),
          cache: oedHttpCache.defaultCache,
          method: 'GET'
        },
        querySuggestions: {
          url: oedConfig.url_b('cloudtest/suggestions'),
          method: 'POST',
        },
      });
    }])
    .factory('oedCloudTestStats', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('cloudtest/:id/stats'), {
        id: '@id'
      }, {
      });
    }])
    .factory('oedCloudSubjectiveTest', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('cloudsubjectivetest/:id'), {
        id: '@id'
      }, {
        queryByTestIds: {
          url: oedConfig.url('cloudsubjectivetest'),
          params: {
            testIds: '@testIds'
          },
          method: 'GET',
          isArray: true
        },
        queryOnlyById: {
          url: oedConfig.url_b('cloudsubjectivetest/:id/only'),
          method: 'GET'
        },
        queryByCloudTestId: {
          url: oedConfig.url_b('cloudsubjectivetest/:id'),
          method: 'GET'
        },
        queryByCloudTestOrPreRedId: {
          url: oedConfig.url('cloudsubjectivetest/querybycloudtestid'),
          method: 'POST'
        },
        queryBySection: {
          url: oedConfig.url_b('cloudsubjectivetest/query2'),
          isArray: true
        },
        queryBySubjectiveTestId: {
          url: oedConfig.url('subjectivetest/:id/cloudres'),
          method: 'GET'
        },
        querySuggestions: {
          url: oedConfig.url_b('cloudsubjectivetest/suggestions'),
          method: 'POST',
        },
      });
    }])
    .factory('oedCloudPresentResItem', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('cloudpresentresourceitem/:id'), {
        id: '@id'
      }, {
        queryBySection: {
          url: oedConfig.url_b('cloudpresentresourceitem/query2'),
          isArray: true
        },
        queryBySectionPost: {
          url: oedConfig.url_b('cloudpresentresourceitem/query2'),
          method: 'POST',
          isArray: true
        },
        getView: {
          url: oedConfig.url_b('cloudpresentresourceitem/:id/read')
        },
        getDownload: {
          url: oedConfig.url_b('cloudpresentresourceitem/:id/download')
        },
        getByResItemId: {
          url: oedConfig.url_b('presentresourceitem/:id/cloudres')
        },
        querySuggestions: {
          url: oedConfig.url_b('cloudpresentresourceitem/suggestions'),
          method: 'POST',
        },
        queryByCloudResItemId: {
          url: oedConfig.url_b('cloudpresentresourceitem/:id/query')
        },
        getMicroLessonByCourseId: {
          url: oedConfig.url_b('presentresourceitem/course/:courseId'),
          isArray: true
        },
      });
    }])
    .factory('oedCloudPresentRes', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('cloudpresentresource/:id'), {
        id: '@id'
      }, {
        queryByResIds: {
          url: oedConfig.url('cloudpresentresource'),
          params: {
            resIds: '@resIds'
          },
          method: 'GET',
          isArray: true
        },
        queryByCloudResId: {
          url: oedConfig.url_b('cloudpresentresource/:id'),
          method: 'GET'
        },
        queryOnlyByCloudResId: {
          url: oedConfig.url_b('cloudpresentresource/:id/only'),
          method: 'GET'
        },
        queryByCloudTestOrPreRedId: {
          url: oedConfig.url('cloudpresentresource/querybypreresid'),
          method: 'POST'
        },
        queryBySection: {
          url: oedConfig.url_b('cloudpresentresource/query2'),
          isArray: true
        },
        validateDownload: {
          url: oedConfig.url('cloudpresentresource/validatedownloadresource/:idList'),
          method: 'GET'
        },
        queryByPresentResId: {
          url: oedConfig.url('presentresource/:id/cloudres'),
          method: 'GET'
        },
        querySuggestions: {
          url: oedConfig.url_b('cloudpresentresource/suggestions'),
          method: 'POST',
        },
      });
    }])
    .factory('oedCloudOfflineRes', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('cloudofflineresource/:id'), {
        id: '@id'
      }, {
        queryByResIds: {
          url: oedConfig.url('cloudofflineresource'),
          params: {
            resIds: '@resIds'
          },
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedCloudTeachingPattern', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('cloudteachingpattern/:id'), {
        id: '@id'
      }, {
        queryByResIds: {
          url: oedConfig.url('cloudteachingpattern'),
          params: {
            resIds: '@resIds'
          },
          method: 'GET',
          isArray: true
        },
        queryBySection: {
          url: oedConfig.url_b('cloudteachingpattern/query3'),
          isArray: true
        },
        getByResId: {
          url: oedConfig.url('teachingpattern/:id/cloudres'),
          method: 'GET'
        },
        querySuggestions: {
          url: oedConfig.url_b('cloudteachingpattern/suggestions'),
          method: 'POST',
        },
        queryById: {
          url: oedConfig.url_b('cloudteachingpattern/:id'),
          method: 'GET',
        },
      });
    }])
    .factory('oedClassSession', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('classsession/:id/details'), {
        id: '@id'
      }, {
        // queryByCoursePathId: {
        //   url: oedConfig.url('coursepath/:pathId/classsessions'),
        //   method: 'GET',
        //   isArray: true
        // },
        queryByCoursePathId: {
          url: oedConfig.url('coursepath/:pathId/classsessions/class/:classIds/start/:start/end/:end'),
          method: 'GET',
          isArray: true
        },
        queryStats: {
          url: oedConfig.url('classsession/:classSessionId/stats')
        },
        queryPreResItemSessions: {
          url: oedConfig.url('classsession/:id/preresitemsessionof/:preResId'),
          method: 'GET',
          isArray: true
        },
        queryPreResSessions: {
          url: oedConfig.url('classsession/:id/preressessionof/:preResId'),
          method: 'GET',
          isArray: true
        },
        queryPreResSessionsExcludeTmSession: {
          url: oedConfig.url('classsession/:id/preressessionof/:preResId/excludetmsession'),
          method: 'GET',
          isArray: true
        },
        queryCourseDetail:{
          url: oedConfig.url('course/:id'),
          method: 'GET'
        }
      });
    }])
    .factory('oedTmSession', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('tmsession/:id'), {
        id: '@id'
      }, {
        queryByTmId: {
          url: oedConfig.url('classsession/:classSessionId/teachingmodule/:tmId'),
          method: 'GET',
          isArray: true
        },
        publishTm: {
          url: oedConfig.url('tmsession/publishTm'),
          method: 'POST',
          isArray: true
        },
        getDetails: {
          url: oedConfig.url('tmsession/:tmSessionId/details'),
          method: 'GET'
        },
        getPubDetails: {
          url: oedConfig.url('tmsession/pubDetails/:pubRecordId'),
          method: 'GET'
        }
      });
    }]).factory('oedTestSession', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('testsession/:id'), {
        id: '@id'
      }, {
        queryByTestId: {
          url: oedConfig.url('classsession/:classSessionId/testsessionsbyorigtestid/:origTestId'),
          method: 'GET',
          isArray: true
        },
        queryByTestIdExcludeTmSession: {
          url: oedConfig.url('classsession/:classSessionId/testsessionsbyorigtestid/:origTestId/excludetmsession'),
          method: 'GET',
          isArray: true
        },
        getDetails: {
          url: oedConfig.url('testsession/details'),
          method: 'GET'
        },
        getTestSessionByTaskListRecordAndOrigTestId: {
          url: oedConfig.url_b('tasklistpublishrecord/:recordId/task/:taskId/testsessionof/:origTestId'),
          method: 'GET'
        },
        submitTest: {
          url: oedConfig.url('testsession/:testSessionId/submit/:studentId'),
          method: 'POST'
        },
        getFillQuesStatus: {
          url: oedConfig.url('testsession/stdFillQuesStates'),
          method: 'POST',
          isArray: true
        },
        getStudentsDetails: {
          url: oedConfig.url_b('testsession/:id/students'),
          method: 'GET',
          isArray: true
        },
        getQuesAnswerStatByUser: {
          url: oedConfig.url('testsession/statbyuser2'),
          method: 'GET'
        },
        getRemoteVotingStat: {
          url: oedConfig.url_b('testsession/:testSessionId/device/stat'),
          method: 'GET'
        },
        getRemoteVotingAnswerStat: {
          url: oedConfig.url_b('testsession/:testSessionId/question/:questionId/device/stat'),
          method: 'GET'
        },
        getRemoteVotingAnswerStatByOption: {
          url: oedConfig.url_b('testsession/:testSessionId/question/:questionId/device/answer/stat'),
          method: 'GET',
          isArray: true
        },
        getRemoteVotingAnswerRank: {
          url: oedConfig.url_b('testsession/:testSessionId/device/rank'),
          method: 'GET',
          isArray: true
        }
      });
    }]).factory('oedObjectiveTestSessionStats', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource('nil/', {}, {
        // queryBySessoinId: {
        //   url: oedConfig.url('testsession/statbyques'),
        //   isArray: true
        // },
        queryBySessoinId: {
          url: oedConfig.url('testsession/statByQuesWithRevise'),
          isArray: true
        },
        queryCorrectRateBySessoinId: {
          url: oedConfig.url('testsession/:sessionId/correctrate')
        },
        getHotRateOfSubjectiveTest: {
          url: oedConfig.url('testsession/:sessionId/subhotrate')
        },
        getHotCountOfPresentResource: {
          url: oedConfig.url('testsession/:sessionId/presenthotcount')
        }
      });
    }]).factory('oedTestQuestionStats', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource('nil/', {}, {
        queryBySessoinAndQuestionId: {
          url: oedConfig.url('testsession/quesdetails')
        }
      });
    }]).factory('oedTestSessionStat', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource('nil/', {}, {
        queryByTestSessionId: {
          url: oedConfig.url('testsession/statoftimeandrank')
        }
      });
    }]).factory('oedTestSessionStatForReview', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource('nil/', {}, {
        queryByTestSessionAndStudentId: {
          url: oedConfig.url('testsession/statforreview')
        },
        getStudentAnsersByDevice: {
          url: oedConfig.url_b('testsession/:testSessionId/device/answers'),
          method: 'POST',
          isArray: true
        },
        batchTest:{
           url: oedConfig.url('testanswer/review/batch?testSessionId=:testSessionId&studentId=:studentId&correctDetails=:type'),
           method: 'POST',
           isArray: true
        },
        batchTestforAll: {
          url: oedConfig.url('testanswer/review/batch?testSessionId=:testSessionId&questionId=:questionId&correctDetails=:type'),
          method: 'POST',
          isArray: true
        },
        batchUnReviewQuestin:{
          url: oedConfig.url('testanswer/review/unreviewed?testSessionId=:testSessionId&studentId=:studentId&correctDetails=:type'),
          method: 'POST',
          isArray: true
        },
        batchUnReviewQuestinForAll:{
          url: oedConfig.url('testanswer/review/unreviewed?testSessionId=:testSessionId&questionId=:questionId&correctDetails=:type'),
          method: 'POST',
          isArray: true
        },
        getInteractByQuestionId:{
          url: oedConfig.url('testanswer/interactByStudentId?testSessionId=:testSessionId&questionId=:questionId&studentId=:studentId'),
          method: 'GET',
        },
        bravo:{
          url: oedConfig.url('testanswer/bravo?testSessionId=:testSessionId&studentId=:studentId&questionId=:questionId'),
          method: 'GET',
          isArray: true
        },
        comment:{
          url: oedConfig.url('testanswer/comment'),
          method: 'POST',
        },
        deleteComment:{
          url: oedConfig.url('testanswer/delcomment?id=:id'),
          method: 'POST',
        }
      });
    }]).factory('oedPreResSession', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('preressession/:id'), {
        id: '@id'
      }, {
        queryByClassSessionAndPreResId: {
          url: oedConfig.url('classsession/:classSessionId/preressessionof/:preResId'),
          method: 'GET',
          isArray: true
        },
        queryItemByClassSessionAndPreResId: {
          url: oedConfig.url('classsession/:classSessionId/preresitemsessionof/:preResId'),
          method: 'GET',
          isArray: true
        },
        getPreResSessionByTaskListRecordAndPreResId: {
          url: oedConfig.url_b('tasklistpublishrecord/:recordId/task/:taskId/preressessionof/:preResId'),
          method: 'GET'
        },
        submitSession: {
          url: oedConfig.url('preressession/:sessionId/submit/:studentId'),
          method: 'POST'
        }
      });
    }]).factory('oedPreResItemSession', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('preresitemsession/:id'), {
        id: '@id'
      }, {
        queryByClassSessionId: {
          url: oedConfig.url('classsession/:classSessionId/preresitemsessionof/:preResItemSessionId/students'),
          isArray: true
        }
      });
    }]).factory('oedPreResSessionStudent', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('preressessionstudent/:id'), {
        id: '@id'
      }, {
        queryByPreResSessionAndStudentId: {
          url: oedConfig.url_b('preressessionstudent/:sessionId/student/:studentId')
        },
        queryByPreResSessionId: {
          url: oedConfig.url_b('preressessionstudent/:sessionId/students'),
          isArray: true
        }
      });
    }]).factory('oedBoardSession', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('boardsession/:id'), {
        id: '@id'
      }, {
        queryByTestId: {
          url: oedConfig.url('classsession/:classSessionId/boardsessionsbyorigtestid/:origTestId'),
          isArray: true
        },
        queryTempSessionsByClassSessionId: {
          url: oedConfig.url('classsession/:classSessionId/tempboardsessions'),
          isArray: true
        },
        queryByTestSessionId: {
          url: oedConfig.url_b('testsession/:sessionId/boardsessions'),
          isArray: true
        },
        getHotRateOfSubjectiveTest: {
          url: oedConfig.url('boardsession/:sessionId/subhotrate')
        }
      });
    }]).factory('oedSubjectiveTestSessionStats', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource('nil/', {}, {
        queryBySessoinId: {
          url: oedConfig.url('boardsession/interactsm')
        },
        queryBySessionAndUserId: {
          url: oedConfig.url('boardsession/interactofuserm')
        }
      });
    }]).factory('oedTaskList', ['$resource', 'oedConfig',function($resource, oedConfig) {
      return $resource(oedConfig.url_b('tasklist/:id'), {
        id: '@id'
      }, {
        queryByCourseId: {
          url: oedConfig.url_b('course/:courseId/tasklists'),
          isArray: true
        },
        queryByTeacherId: {
          url: oedConfig.url_b('tasklist/ofteacher/:teacherId'),
          isArray: true
        },
        queryByTeacherIdV2: {
          url: oedConfig.url_b('tasklistv2/ofteacher/:teacherId'),
          isArray: true
        },
        queryDeleteByTeacherIdV2: {
          url: oedConfig.url_b('tasklist/deleted'),
          isArray: true
        },
        queryCoursePathsByTeacherId: {
          url: oedConfig.url_b('tasklist/coursepathofteacher/:teacherId'),
          isArray: true
        },
        queryCoursePathsByTeacherIdV2: {
          url: oedConfig.url_b('tasklistv2/coursepathofteacher/:teacherId'),
          isArray: true
        },
        queryCoursePathsJoinTaskListByTeacherId: {
          url: oedConfig.url_b('tasklist/coursepathOfTeacherTaskList/:teacherId'),
          isArray: true
        },
        queryCoursePathsByCourseId: {
          url: oedConfig.url_b('course/:courseId/coursepath'),
          isArray: true
        },
        queryCountByTeacherId: {
          url: oedConfig.url_b('tasklist/countofteacher/:teacherId'),
          isArray: false
        },
        queryDeleteCountByTeacherIdV2: {
          url: oedConfig.url_b('tasklist/countOfDeleted'),
          isArray: false
        },
        finalDeletehomeWork: {
          url: oedConfig.url_b('tasklist/:id/finalDelete'),
          method: 'POST',
          isArray: false
        },
        recoveryDeletehomeWork: {
          url: oedConfig.url_b('tasklist/:id/restore'),
          method: 'POST',
          isArray: false
        },
        queryCountByTeacherIdV2: {
          url: oedConfig.url_b('tasklistv2/countofteacher/:teacherId'),
          isArray: false
        },
        queryPublishInfo: {
          url: oedConfig.url_b('tasklist/:id/publishinfo'),
          isArray: false
        },
        queryPublishDeleteInfo: {
          url: oedConfig.url_b('tasklist/:id/deletedPublishinfo'),
          isArray: false
        },
        queryPublishInfoForClass: {
          url: oedConfig.url_b('tasklist/:id/publishinfo/class/:clsId'),
          isArray: false
        },
        queyPublishInfoForStudent: {
          url: oedConfig.url_b('tasklist/:id/publishinfo/student/:stdId'),
          isArray: false
        },
        getClassInfoById: {
          url: oedConfig.url('classroom/:classId'),
          isArray: false
        },
        deleteTasklistAndRelatedRecords: {
          url: oedConfig.url_b('tasklist/:id/tasklistAndRelatedRecords'),
          method: 'DELETE'
        }
      });
    }]).factory('oedTask', ['$resource', 'oedConfig',function($resource, oedConfig) {
      return $resource(oedConfig.url_b('task/:id'), {
        id: '@id'
      }, {
        queryByTaskListId: {
          url: oedConfig.url_b('tasklist/:id/tasks'),
          isArray: true
        }
      });
    }]).factory('oedTaskTest', ['$resource', 'oedConfig',function($resource, oedConfig) {
      return $resource(oedConfig.url_b('task/test/:id'), {
        id: '@id'
      }, {
        queryTaskObjectiveTestByTaskId: {
          url: oedConfig.url_b('task/:id/objectivetests'),
          isArray: true
        },
        queryTaskSubjectiveByTaskId: {
          url: oedConfig.url_b('task/:id/subjectivetests'),
          isArray: true
        }
      });
    }]).factory('oedTaskPresentResource', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('task/presentresoruce/:id'), {
        id: '@id'
      }, {
        queryByTaskId: {
          url: oedConfig.url_b('task/:id/presentresources'),
          isArray: true
        }
      });
    }]).factory('oedReportTaskListPublish', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('tasklist/course'), {}, {
        queryByTaskId: {
          url: oedConfig.url_b('tasklist/course/:courseId/publishinfo'),
          isArray: true
        }
      });
    }]).factory('oedTaskListPublishRecord', ["$resource", "oedConfig", function($resource, oedConfig) {
      return $resource(oedConfig.url_b('tasklistpublishrecord/:id'), {
        id: '@id'
      }, {
        queryByTaskListId: {
          url: oedConfig.url_b('tasklist/:id/publishrecords'),
          isArray: true
        },
        createRecords: {
          url: oedConfig.url_b('tasklistpublishrecord'),
          method: 'POST',
          isArray: true
        },
        createRecordsV2: {
          url: oedConfig.url_b('tasklistpublishrecordV2'),
          method: 'POST',
          isArray: true
        },
        queryCountByCoursePathAndUid: {
          url: oedConfig.url_b('tasklistpublishrecord/coursepath/:coursePathId/user/:uid/count'),
          isArray: false
        },
        queryByCoursePathAndUid: {
          url: oedConfig.url_b('tasklistpublishrecord/coursepath/:coursePathId/user/:uid'),
          isArray: true
        },
        queryPublishInfo: {
          url: oedConfig.url_b('tasklistpublishrecord/:id/publishinfo')
        },
        queryStudentsInfo: {
          url: oedConfig.url_b('tasklistpublishrecord/:id/publishinfo/students/myinfo'),
          isArray: true
        },
        queryStudentsIsReviewed: {
          url: oedConfig.url_b('tasklistpublishrecord/:id/publishinfo/students/isreviewed'),
          isArray: true
        },
        queryStudentsSubmitTaskCount: {
          url: oedConfig.url_b('tasklistpublishrecord/:id/publishinfo/students/submittaskcount'),
          isArray: true
        },
        queryStudentPublishInfo: {
          url: oedConfig.url_b('tasklistpublishrecord/:id/publishinfo/student/:stdId')
        },
        queryStudentTaskListPublishRecordByStuIdAndRecordId: {
          url: oedConfig.url_b('tasklistpublishrecord/:recordId/stat/student/:studentId')
        },
        queryReadPresentResourceRecord: {
          url: oedConfig.url_b('tasklistpublishrecord/:recordId/student/:studentId/presentresource/:resId/readrecord')
        },
        getTaskPresentResourceReadSummary: {
          url: oedConfig.url_b('tasklistpublishrecord/:publishRecordId/presentresource/:taskResourceId/readsummary'),
          isArray: true
        },
        createTaskPresentResourceReadRecord: {
          url: oedConfig.url_b('taskpresentresourcereadrecord'),
          method: 'POST'
        },
        queryTestSessionSubmitCount: {
          url: oedConfig.url_b('tasklistpublishrecord/testsession/:id/submittaskcount'),
          isArray: true
        },
      });
    }]).factory('oedTestAnswer', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('testanswer/:id'), {
        id: '@id'
      }, {
        submitAnswer: {
          url: oedConfig.url('testanswer/submit'),
          method: 'POST'
        },
        fillBlankRight: {
          url: oedConfig.url('testanswer/review/:testSessionId/:studentId/:questionId/:blankId/right'),
          method: 'POST'
        },
        fillBlankWrong: {
          url: oedConfig.url('testanswer/review/:testSessionId/:studentId/:questionId/:blankId/wrong'),
          method: 'POST'
        },
        fillBlankClear: {
          url: oedConfig.url('testanswer/review/:testSessionId/:studentId/:questionId/:blankId/clear'),
          method: 'POST'
        },
        reviewQaAnswer: {
          url: oedConfig.url('testanswer/review/:testSessionId/:studentId/:questionId/correct/:correctDetails'),
          method: 'POST'
        },
        clearQaReview: {
          url: oedConfig.url('testanswer/review/:testSessionId/:studentId/:questionId/correct/clear'),
          method: 'POST'
        },
        scoreQaAnswer: {
          url: oedConfig.url('testanswer/review/:testSessionId/:studentId/:questionId/point'),
          method: 'POST'
        }
      });
    }]).factory('oedTestSessionStudent', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('testsessionstudent/:id'), {
        id: '@id'
      }, {
        queryByTestSessionAndStudentId: {
          url: oedConfig.url_b('testsessionstudent/:sessionId/student/:studentId')
        }
      });
    }).factory('oedTaskListPublishRecordComment', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('/tasklistpublishrecordcomment/:id'), {
        id: '@id'
      }, {
        queryByRecordAndStudentId: {
          url: oedConfig.url_b('tasklistpublishrecord/:recordId/comments/student/:studentId'),
          isArray: true
        }
      });
    }).factory('oedTaskPresentResourceReadRecord', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('taskpresentresourcereadrecord/:id'), {
        id: '@id'
      }, {});
    }).factory('oedBoardContent', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('boardcontent/:id'), {
        id: '@id'
      }, {
        submitBoardContent: {
          url: oedConfig.url('boardcontent/submitm'),
          method: 'POST'
        },
        queryByBoardSessionAndStudentId: {
          url: oedConfig.url_b('boardsession/:sessionId/student/:studentId/contents'),
          isArray: true
        }
      });
    }).factory('oedBoardContentComment', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('boardcontentcomment/:id'), {
        id: '@id'
      }, {
        queryByBoardSessionAndStudentId: {
          url: oedConfig.url_b('boardsession/:sessionId/student/:studentId/comments'),
          isArray: true
        },
        createCommentWithRank: {
          url: oedConfig.url_b('boardcontentcomment/withrank'),
          method: 'POST'
        },
        toggleBravo: {
          url: oedConfig.url('boardcontent/bravom'),
          method: 'POST'
        },
        markReadBoardContent: {
          url: oedConfig.url('boardcontent/viewm'),
          method: 'POST'
        },
        submitComment: {
          url: oedConfig.url('boardcontent/comment'),
          method: 'POST'
        },
        deleteComment: {
          url: oedConfig.url('boardcontent/delcomment'),
          method: 'POST'
        }
      });
    }]).factory('oedBoardSessionStudent', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('boardsessionstudent/:id'), {
        id: '@id'
      }, {
        queryByBoardSessionAndStudentId: {
          url: oedConfig.url_b('boardsession/:sessionId/student/:studentId')
        }
      });
    }]).factory('oedCloudQuestion', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('cloudquestion/:id'), {
        id: '@id'
      }, {
        queryBySection: {
          url: oedConfig.url_b('cloudquestion/query2'),
          method: 'POST',
          isArray: true
        },
        queryExerciseQuestions: {
          url: oedConfig.url_b('exbk/cloudquestion/query'),
          method: 'POST',
          isArray: true
        },
        queryByCloudQuestionId: {
          url: oedConfig.url_b('cloudquestion/:id/query'),
          method: 'GET'
        },
        getByQuestionId: {
          url: oedConfig.url_b('objectivequestion/:id/cloudres'),
          method: 'GET'
        },
        queryByQuestionIds: {
          url: oedConfig.url_b('cloudquestion/byQuestionIds'),
          method: 'GET',
          isArray: true
        },
        querySuggestions: {
          url: oedConfig.url_b('cloudquestion/suggestions'),
          method: 'POST',
        },
      });
    }]).factory('oedCloudQuestionStats', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('cloudquestion/:id/stats'), {
        id: '@id'
      }, {
      });
    }]).factory('oedObjectiveQuestion', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('objectivequestion/:id'), {
        id: '@id'
      }, {
        createForTest: {
          url: oedConfig.url_b('objectivequestion'),
          method: 'POST'
        },
        changeQuestionIndex: {
          url: oedConfig.url_b('objectivequestion/:questionId/test/:testId/manualSort/:manualSort'),
          method: 'GET'
        },
        save: {
          url: oedConfig.url_b('objectivequestion/:id'),
          method: 'POST'
        },
        updateAnswer: {
          url: oedConfig.url_b('objectivequestion/:id/testAnswer'),
          method: 'POST'
        },
        download: {
          url: oedConfig.url_b('objectivequestion/download/:cloudResId')
        },
        view: {
          url: oedConfig.url_b('objectivequestion/view/:cloudResId')
        },
        removeFromTest: {
          url: oedConfig.url_b('objectivetest/:testId/question/:questionId'),
          method: 'DELETE'
        }
      });
    }]).factory('oedMaterialVersion', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      //
      // 学科
      return $resource(oedConfig.url_b('materialversion/:id'), {
        id: '@id'
      }, {
        get: {
          cache: oedHttpCache.longCache
        }
      });
    }]).factory('oedLevelGradeVersionSubjects', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url('nil/:id'), {
        id: '@id'
      }, {
        queryByPresentResId: {
          url: oedConfig.url_b('presentresource/:resId/material'),
          method: 'GET'
        },
        queryBySubjectiveTestId: {
          url: oedConfig.url_b('subjectivetest/:id/material'),
          method: 'GET'
        },
        queryByCourseId: {
          url: oedConfig.url_b('course/:id/material'),
          method: 'GET'
        },
        queryMappingKnowledgePoint: {
          url: oedConfig.url_b('mapping/ryKnowledgePoint/levelId/:levelId/subjectId/:subjectId'),
          method: 'GET',
          isArray: true,
        },
        getMapping: {
          url: oedConfig.url_b('mapping/:oem/subject/:subjectId/version/:versionId/grade/:gradeId'),
          method: 'GET'
        }
      });
    }]).factory('oedGrade', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('grade/:id'), {
        id: '@id'
      }, {
        get: {
          cache: oedHttpCache.longCache
        }
      });
    }]).factory('oedLevel', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('schoollevel/:id'), {
        id: '@id'
      }, {
        get: {
          cache: oedHttpCache.longCache
        },
        queryByKnowledgeSubject: {
          url: oedConfig.url_b('knowledgePoint/subject/:subjectId/levels'),
          method: 'GET',
          isArray: true,
        }
      });
    }]).factory('oedPresentResourceStat', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('presentresource/:id/stat'), {
        id: '@id'
      }, {
        get: {
          cache: oedHttpCache.defaultCache
        }
      });
    }]).factory('oedCloudObjectiveQuestion', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudquestion/:id'), {
        id: '@id'
      }, {
        getByQuestionId: {
          url: oedConfig.url_b('objectivequestion/:id/cloudres'),
          cache: oedHttpCache.defaultCache
        }
      });
      }]).factory('oedServerInfo', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/serverinfo/:id'), {
        id: '@id'
      }, {
        getBySchoolId: {
          url: oedConfig.url_b('school/:id/serverinfo')
        },
        current: {
          url: oedConfig.url_b('serverinfo/current')
        }
      });
      }]).factory('oedLoginToken', [ '$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/logintoken/:id'), {
        id: '@id'
      }, {
        newToken: {
          url: oedConfig.url('/logintoken/newtoken'),
          method: 'POST'
        }
      });
      }]).factory('oedTestStarInfo', [ '$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudteststarinfo/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudtest/:id/starinfo'),
          method: 'GET'
        },
        updateForCloudRes: {
          url: oedConfig.url_b('cloudtest/:id/starinfo'),
          method: 'POST'
        },
        removeForCloudRes: {
          url: oedConfig.url_b('cloudtest/:id/starinfo'),
          method: 'DELETE'
        }
      });
      }]).factory('oedTestTag', [ '$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudtesttag/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudtest/:id/tags'),
          method: 'GET',
          isArray: true
        }
      });
      }]).factory('oedReportResource', [ '$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/prepare/report'), {}, {
        reportResource: {
          url: oedConfig.url_b('prepare/report'),
          method: 'POST',
          isArray: false
        }
      });
      }]).factory('oedQuestionStarInfo', [ '$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudteststarinfo/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudquestion/:id/starinfo'),
          method: 'GET'
        },
        updateForCloudRes: {
          url: oedConfig.url_b('cloudquestion/:id/starinfo'),
          method: 'POST'
        },
        removeForCloudRes: {
          url: oedConfig.url_b('cloudquestion/:id/starinfo'),
          method: 'DELETE'
        }
      });
      }]).factory('oedQuestionTag', [ '$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudtesttag/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudquestion/:id/tags'),
          method: 'GET',
          isArray: true
        }
      });
      }]).factory('oedPreResStarInfo', [ '$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudteststarinfo/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudpresentresource/:id/starinfo'),
          method: 'GET'
        },
        updateForCloudRes: {
          url: oedConfig.url_b('cloudpresentresource/:id/starinfo'),
          method: 'POST'
        },
        removeForCloudRes: {
          url: oedConfig.url_b('cloudpresentresource/:id/starinfo'),
          method: 'DELETE'
        }
      });
      }]).factory('oedPreResTag', [ '$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudtesttag/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudpresentresource/:id/tags'),
          method: 'GET',
          isArray: true
        }
      });
      }]).factory('oedPreResItemStarInfo', [ '$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudteststarinfo/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudpresentresourceitem/:id/starinfo'),
          method: 'GET'
        },
        updateForCloudRes: {
          url: oedConfig.url_b('cloudpresentresourceitem/:id/starinfo'),
          method: 'POST'
        },
        removeForCloudRes: {
          url: oedConfig.url_b('cloudpresentresourceitem/:id/starinfo'),
          method: 'DELETE'
        }
      });
      }]).factory('oedPreResItemTag', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudtesttag/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudpresentresourceitem/:id/tags'),
          method: 'GET',
          isArray: true
        }
      });
    }])
    .factory('oedTeachingPatternStarInfo', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudteststarinfo/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudteachingpattern/:id/starinfo'),
          method: 'GET'
        },
        updateForCloudRes: {
          url: oedConfig.url_b('cloudteachingpattern/:id/starinfo'),
          method: 'POST'
        },
        removeForCloudRes: {
          url: oedConfig.url_b('cloudteachingpattern/:id/starinfo'),
          method: 'DELETE'
        }
      });
    }]).factory('oedTeachingPatternTag', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/cloudtesttag/:id'), {
        id: '@id'
      }, {
        getByCloudResId: {
          url: oedConfig.url_b('cloudteachingpattern/:id/tags'),
          method: 'GET',
          isArray: true
        }
      });
    }]).factory('oedClassSessionQuestions', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url('/classsessionquestion/:id'), {
        id: '@id'
      }, {
        queryByClassSession: {
          url: oedConfig.url('classsession/:id/studentquestions'),
          method: 'GET',
          isArray: true
        }
      });
    }]).factory('oedCloudTag', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/tag/:id'), {
        id: '@id'
      }, {
        getTagsByCategory: {
          url: oedConfig.url_b('tag/:name'),
          method: 'GET',
          isArray: true
        }
      });
    }]).factory('oedStudentGroup', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('studentGroup/:id'), {
        id: '@id'
      }, {
        queryByClass: {
          url: oedConfig.url_b('classroom/:classId/groups'),
          method: 'GET',
          isArray: true
        },
        setGroupLeader: {
          url: oedConfig.url_b('studentGroup/:groupId/student/:studentId/idx/:subGroupIdx/role/:role'),
          method: 'GET',
          isArray: true
        },
        setSubgroupName: {
          url: oedConfig.url_b('studentGroup/:id/setName'),
          method: 'POST'
        },
        deleteChosenGroup: {
          url: oedConfig.url_b('studentGroup/:id/deleteGroup'),
          method: 'POST'
        }
      });
    }]).factory('oedStudentGroupScore', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('classSession/:id'), {
        id: '@id'
      }, {
        getScoreBySometime: {
          url: oedConfig.url_b('studentGroup/:id/scoreQuery?start=:start&end=:end'),
          method: 'GET',
          isArray: true
        },
        setScoreWithGroup: {
          url: oedConfig.url_b('studentGroup/score'),
          method: 'POST'
        }
      });
    }]).factory('oedWritingPad', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('writingPad/paperImage/:id'), {
      }, {
        get: {
          url: oedConfig.url_b('writingPad/paperImage/:id'),
          method: 'GET',
          isArray: true
        },
        delete: {
          url: oedConfig.url_b('writingPad/paperImage/:id'),
          method: 'DELETE'
        },
        setDatas: {
          url: oedConfig.url_b('writingPad/paperImage/set'),
          method: 'POST',
          isArray: true
        },
        getWritingPadSummary: {
          url: oedConfig.hal_writing('api/open/ray/studyprocess/class_student_write'),
          method: 'GET'
        },
        getStudentWritingPad: {
          url: oedConfig.hal_writing('api/open/ray/studyprocess/student_write_info'),
          method: 'POST'
        },
        setGames: {
          url: oedConfig.url_b('writingPad/paperGame'),
          method: 'POST',
          isArray: true
        },
        getGames: {
          url: oedConfig.url_b('writingPad/paperGame/:testId'),
          method: 'GET',
          isArray: true
        },
        deleteGame: {
          url: oedConfig.url_b('writingPad/paperGame/:testId/:questionId'),
          method: 'DELETE'
        },
      });
    }]).factory('oedAnalytic', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_c('/analytics/:id'), ['$resource', 'oedConfig', {
        id: '@id'
      }], {
        getScoreData: {
          url: oedConfig.url_c('analytics/score'),
          method: 'POST',
          isArray: true
        },
        getLearningData: {
          url: oedConfig.url_c('analytics/learning'),
          method: 'POST',
          isArray: true
        },
        getTestAnswer: {
          url: oedConfig.url_c('analytics/getTestAnswer'),
          method: 'POST',
          isArray: true
        },
        getSocialData: {
          url: oedConfig.url_c('analytics/social'),
          method: 'POST',
          isArray: true
        },
        getSocialAction: {
          url: oedConfig.url_c('analytics/getSocialAction'),
          method: 'POST',
          isArray: true
        },
        getTeachingData: {
          url: oedConfig.url_c('analytics/teaching'),
          method: 'POST',
          isArray: true
        },
        getClassTeachingData: {
          url: oedConfig.url_c('analytics/classTeaching'),
          method: 'POST',
          isArray: true
        }
      });
    }])
    .factory('oedKnowledgePoint', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/knowledgePoint/:id'), {
        id: '@id',
      }, {
        get: {
          url: oedConfig.url_b('/knowledgePoint/:id'),
          cache: oedHttpCache.longCache
        }
      })
    }])
    .factory('hlsSubjectVersion', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/thirdParty/hls/subjectAndVersions'), {
      }, {
        get: {
          url: oedConfig.url_b('thirdParty/hls/subjectAndVersions'),
          isArray: true,
          cache: oedHttpCache.longCache,
        }
      })
    }])
    .factory('hlsCatalog', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/thirdParty/hls/catalog'), {
      }, {
        get: {
          url: oedConfig.url_b('thirdParty/hls/catalog/:sid/:vid'),
          cache: oedHttpCache.longCache,
        }
      })
    }])
    .factory('hlsQuestion', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/thirdParty/hls/catalog'), {
      }, {
        query: {
          url: oedConfig.url_b('thirdParty/hls/queryQuestions'),
          method: 'POST',
        }
      })
    }])
    .factory('hlsResource', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('/thirdParty/hls/catalog'), {
      }, {
        query: {
          url: oedConfig.url_b('thirdParty/hls/queryResources'),
          method: 'POST',
        },
        queryViews: {
          url: oedConfig.url_b('thirdParty/hls/queryViews/:vendor/:sid'),
          method: 'GET',
        },
        queryDownloads: {
          url: oedConfig.url_b('thirdParty/hls/queryDownloads/:vendor/:sid'),
          method: 'GET',
        },
        viewResource: {
          url: oedConfig.url_b('thirdParty/hls/view/:vendor/uid/:uid/res/:sid'),
          method: 'POST',
        },
        downloadResource: {
          url: oedConfig.url_b('thirdParty/hls/download/:vendor/uid/:uid/res/:sid'),
          method: 'POST',
        }
      })
    }])
    .factory('oedViewLog', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url('nil/:id'), {
        id: '@id'
      }, {
        queryPreResViewLogByUid: {
          url: oedConfig.url_b('log/preResViews/:uid/ofSession/:preResSessionId'),
          method: 'GET',
          isArray: 'true'
        },
        queryViewLogByPreResItemId: {
          url: oedConfig.url('preressessionview/preressession/:sessionId/preresitem/:itemId/student'),
          method: 'GET',
          isArray: 'true'
        },
        queryViewLogBySessionId: {
          url: oedConfig.url('preressessionview/preressession/:sessionId/student'),
          method: 'GET',
          isArray: 'true'
        }
      })
    }]).factory('oedRecommendCourse', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url('coursepath/:id/recommend'), {
        id: '@id',
      }, {
        addRecommendCourse: {
          url: oedConfig.url('coursepath/:id/recommend'),
          method: 'POST'
        },
        queryIsRecommendCourse: {
          url: oedConfig.url('coursepath/:id/isrecommend'),
          method: 'GET'
        },
        queryRecommendCourses: {
          url: oedConfig.url('coursepath/:id/recommend'),
          method: 'GET',
          isArray: 'true'
        },
        addRecommendCourseToCp: {
          url: oedConfig.url('coursepath/:id/importcourse'),
          method: 'POST'
        }
      })
    }]).factory('oedSmallData', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url('classsession/smalldata/:id'), {
        id: '@id',
      }, {
        get: {
          url: oedConfig.url('classsession/smalldata//:id'),
          method: 'GET'
        }
      })
    }]).factory('oedLog', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url('log/web/:id'), {
        id: '@id',
      }, {
        addWebUsage: {
          url: oedConfig.url_b('log/webUsage'),
          method: 'POST'
        },
        addPageView: {
          url: oedConfig.url_b('log/pv'),
          method: 'POST'
        }
      })
    }]).factory('oedResDownloadTask', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('resDownloadTask/:id'), {
        id: '@id',
      })
    }]).factory('oedTeachingPlan', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('teachingPlan/:id'), {
        id: '@id',
      }, {
        getPlanContents: {
          url: oedConfig.url_b('teachingPlan/:id/contents'),
          method: 'GET',
          isArray: true
        },
        getSharePlan: {
          url: oedConfig.url_b('course/:id/sharedPlan'),
          method: 'GET'
        },
        getBaseSharePlan: {
          url: oedConfig.url_b('course/:id/baseSharedPlan'),
          method: 'GET'
        },
        createBasePlan: {
          url: oedConfig.url_b('course/:id/createBasePlan'),
          method: 'POST'
        },
        getPersonalPlan: {
          url: oedConfig.url_b('course/:id/personalPlan'),
          method: 'GET'
        },
        releasePublicPlan: {
          url: oedConfig.url_b('teachingPlan/:id/publish'),
          method: 'POST'
        },
        getNewestPlan: {
          url: oedConfig.url_b('course/:id/newestSharedPlan'),
          method: 'GET'
        },
        getPublishedSharedPlan: {
          url: oedConfig.url_b('course/:id/publishedSharedPlan'),
          method: 'GET'
        },
        getPersonalPlanPublished: {
          url: oedConfig.url_b('course/:id/personalPlanPublished'),
          method: 'GET'
        },
        getPublicTeachingPlanList: {
          url: oedConfig.url_b('teachingPlan/query/publicPlans'),
          method: 'POST',
          isArray: true
        },
        getPublicTeachingPlanYears: {
          url: oedConfig.url_b('teachingPlan/query/publicPlan/years'),
          method: 'POST',
          isArray: true
        },
        getTeachingPlanSubjects: {
          url: oedConfig.url_b('teachingPlan/query/subjects'),
          method: 'GET',
          isArray: true
        },
        getTeachingPlanVersion: {
          url: oedConfig.url_b('teachingPlan/query/subject/:subjectId/versions'),
          method: 'GET',
          isArray: true
        },
        getTeachingPlanGrades: {
          url: oedConfig.url_b('teachingPlan/query/subject/:subjectId/version/:versionId/grades'),
          method: 'GET',
          isArray: true
        },
        getTeachingPlanSections: {
          url: oedConfig.url_b('teachingPlan/query/subject/:subjectId/version/:versionId/grade/:gradeId/sections'),
          method: 'GET',
          isArray: true
        },
        getBasePlanId: {
          url: oedConfig.url_b('teachingPlan/:planId/base'),
          method: 'GET'
        },
        getPersonalTeachingPlanList: {
          url: oedConfig.url_b('teachingPlan/query/personalPlans'),
          method: 'POST',
          isArray: true
        },
        getPersonalTeachingPlanYears: {
          url: oedConfig.url_b('teachingPlan/query/personalPlan/years'),
          method: 'POST',
          isArray: true
        },
        getPersonalTeachingPlanTeacher: {
          url: oedConfig.url_b('teachingPlan/query/personalPlan/teachers'),
          method: 'POST',
          isArray: true
        },
        shareResource: {
          url: oedConfig.url_b('course/:courseId/cloudRes'),
          method: 'POST',
        },
        cancelShareResource: {
          url: oedConfig.url_b('course/:courseId/cloudRes'),
          method: 'DELETE',
        }
      })
    }]).factory('oedTeachingPlanContent', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('teachingPlanContent/:id'), {
        id: '@id',
      }, {
        getPlanContent: {
          url: oedConfig.url_b('teachingPlanContent/:id'),
          method: 'GET'
        },
        getTeachingPlanIntents: {
          url: oedConfig.url_b('teachingPlanContent/:id/intents'),
          method: 'GET',
          isArray: true
        },
        deleteTeachingPlanIntent: {
          url: oedConfig.url_b('teachingContentIntent/content/:contentId/intentContent/:intentId'),
          method: 'DELETE'
        },
        addTeachingPlanIntent: {
          url: oedConfig.url_b('teachingContentIntent'),
          method: 'POST'
        }
      })
    }]).factory('oedSharePlanComment', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('teachingPlanComment/:id'), {
        id: '@id',
      }, {
        createPlanComments: {
          url: oedConfig.url_b('teachingPlanComment'),
          method: 'POST',
          isArray: true
        },
        getPlanComments: {
          url: oedConfig.url_b('teachingPlan/:id/comments'),
          method: 'GET',
          isArray: true
        },
        getPlanCommentsHotByCategory: {
          url: oedConfig.url_b('teachingPlan/:id/comments/hot/:cat'),
          method: 'GET',
          isArray: true
        },
        getPlanCommentsStarByCategory: {
          url: oedConfig.url_b('teachingPlan/:id/comments/star/:cat'),
          method: 'GET',
          isArray: true
        },
        getPlanCommentsMyBravo: {
          url: oedConfig.url_b('teachingPlanComment/:id/mybravo'),
          method: 'GET',
          isArray: true
        },
        getPlanCommentsBravo: {
          url: oedConfig.url_b('teachingPlanComment/:id/bravos'),
          method: 'GET',
          isArray: true
        },
        doPlanCommentsBravo: {
          url: oedConfig.url_b('teachingPlanComment/:id/bravo'),
          method: 'POST',
        },
        undoPlanCommentsBravo: {
          url: oedConfig.url_b('teachingPlanComment/:id/bravo'),
          method: 'DELETE',
        },
        doPlanCommentsStar: {
          url: oedConfig.url_b('teachingPlanComment/:id/star'),
          method: 'POST',
        },
        undoPlanCommentsStar: {
          url: oedConfig.url_b('teachingPlanComment/:id/unstar'),
          method: 'POST',
        }
      })
    }]).factory('oedCourseManage', ['$resource', 'oedConfig', function($resource, oedConfig) {
    return $resource(oedConfig.url('classsession/:id'), {id: '@id'}, {
      queryByClassId: {
        url: oedConfig.url('classsession/listOfUser'),
        isArray: true
      },
      getCourseDetails: {
        url: oedConfig.url('classsession/:id/info'),
      },
      changeCourseState: {
        url: oedConfig.url('classsession/updateManual/:id/:state'),
      },
      getSubjectList: {
        url: oedConfig.url('classsession/subjectList'),
        method: 'GET',
        isArray: true
      },
      getTeacherList: {
        url: oedConfig.url('classsession/teacherList/:teacherId'),
        method: 'GET',
        isArray: true
      },
      getCourseListById: {
        url: oedConfig.url('classsession/listOfUser'),
        isArray:true
      }
    });
    }]).factory('oedTeachingWeek', ['$resource', 'oedConfig', function($resource, oedConfig) {
    return $resource('', {}, {
      createTeachingWeek: {
        url: oedConfig.url_b('teachingWeek'),
        method: 'POST'
      },
      getTeachingWeekCount: {
        url: oedConfig.url_b('teachingWeek/count/school/:schoolId/semester/:semester/year/:year'),
        method: 'GET',
        isArray: false
      },
      getTeachingWeekList: {
        url: oedConfig.url_b('teachingWeek/query/school/:schoolId/semester/:semester/year/:year/start/:start/count/:count'),
        method: 'GET',
        isArray: true
      },
      getTermDate: {
        url: oedConfig.url_b('school/:schoolId/termDate'),
        method: 'GET',
        isArray: true
      },
      deleteTeachingWeek: {
        url: oedConfig.url_b('teachingWeek/:id'),
        method: 'DELETE'
      },
      updateTeachingWeek: {
        url: oedConfig.url_b('teachingWeek/update/:id'),
        method: 'POST',
        params: {id: '@id'}
      },
      getClassHourList: {
        url: oedConfig.url_b('teachingWeek/statistics/list/teachingWeek/:teachWeekId/start/:start/count/:count'),
        method: 'GET',
        isArray: true
      },
      getClassHourCount: {
        url: oedConfig.url_b('teachingWeek/statistics/count/:teachWeekId'),
        method: 'GET',
        isArray: false
      },
      getPadClassHourCount: {
        url: oedConfig.url_b('classsession/grade/:grade/teacher/:teacherId/school/:schoolId/level/:levelId/subject/:subjectId/start/:startDate/end/:endDate '),
        method: 'GET',
        isArray: false
      },
      deleteClassHour: {
        url: oedConfig.url_b('teachingWeek/statistics/:classHourId'),
        method: 'DELETE'
      },
      updateClassHour: {
        url: oedConfig.url_b('teachingWeek/statistics/update/:classHourId'),
        method: 'POST',
        params: {classHourId: '@classHourId'}
      },
      importClassHour: {
        url: oedConfig.url('teachingWeek/importTeachingData'),
        method: 'POST'
      }
    });
    }]).factory('oedAreaManagement', ['$resource', 'oedConfig', function($resource, oedConfig) {
    return $resource(oedConfig.url_b('area/count/:keyWords'), {
      keyWords: '@keyWords'
    }, {
      getAreaList: {
        url: oedConfig.url_b('area/list/:keyWords/start/:start/count/:count'),
        method: 'GET',
        isArray: true
      },
      getAreaAdminByAreaId: {
        url: oedConfig.url_b('area/user/:id'),
        method: 'GET',
        isArray: true
      },
      createArea: {
        url: oedConfig.url_b('area/create'),
        method: 'POST'
      },
      deleteArea: {
        url: oedConfig.url_b('area/:id/delete'),
        method: 'DELETE'
      },
      updateArea: {
        url: oedConfig.url_b('area/:id/update'),
        method: 'POST',
        params: {id: '@id'}
      },
      getAreaSchoolByAreaId: {
        url: oedConfig.url_b('area/school/:id'),
        method: 'GET',
        isArray: true
      },
      getSchoolList: {
        url: oedConfig.url('school/search'),
        method: 'POST',
        isArray: true
      },
      getSchoolCount: {
        url: oedConfig.url('school/count'),
        method: 'POST',
        isArray: false
      },
      getUserList: {
        url: oedConfig.url_b('userinfo/list/name/:name/start/:start/count/:count'),
        method: 'GET',
        isArray: true
      },
      getSchoolAreaList: {
        url: oedConfig.url_b('area/list/school/:schoolId?status=1'),
        method: 'GET',
        isArray: true
      }
    });
    }]).factory('oedTeachingPlanStats', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
    return $resource(oedConfig.url_b('teachingPlanStats/personalChange/start'), {
    }, {
      personalChangeStart: {
        url: oedConfig.url_b('teachingPlanStats/personalChange/start'),
        method: 'POST'
      },
      personalChangeEnd: {
        url: oedConfig.url_b('teachingPlanStats/personalChange/end'),
        method: 'POST'
      }
    })
    }]).factory('oedTeachingPlanImportedRes', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
    return $resource(oedConfig.url_b('teachingPlanImportedRes/:id'), {
      id: '@id',
    }, {
      getImportedRes: {
        url: oedConfig.url_b('teachingPlanImportedRes/query'),
        method: 'POST',
        isArray:true
      },
      getImportedMaterialsByResId: {
        url: oedConfig.url_b('teachingPlanImportedRes/:id/materials'),
        method: 'GET',
        isArray:true
      },
      getImagesByMaterialId: {
        url: oedConfig.url_b('teachingPlanImportedRes/:id/material/:mId/images'),
        method: 'GET',
        isArray:true
      },
      getTeacherTalkComments: {
        url: oedConfig.url_b('teacherTalkComment/query?sectionId=:sectionId&sectionIndex=:sectionIndex'),
        method: 'GET',
        isArray:true
      },
      createTeacherTalkComment: {
        url: oedConfig.url_b('teacherTalkComment'),
        method: 'POST'
      },
      doTeacherTalkCommentStar: {
        url: oedConfig.url_b('teacherTalkComment/:id/star'),
        method: 'POST',
      },
      undoTeacherTalkCommentStar: {
        url: oedConfig.url_b('teacherTalkComment/:id/unstar'),
        method: 'POST',
      }
    })
    }]).factory('oedTestExtraInfo', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
    return $resource(oedConfig.url_b('testExtraInfo'), {
    }, {})
    }]).factory('oedRayAuth', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
    return $resource(oedConfig.url_b('rayAuth/getTalSign'), {
    }, {
      getTalSign: {
        url: oedConfig.url_b('rayAuth/getTalSign'),
        method: 'POST'
      },
    })
    }]).factory('oedTaskListStudent', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
    return $resource(oedConfig.url_b('taskliststudent'), {
    }, {
      getStudentsByTaskList: {
        url: oedConfig.url_b('tasklist/:id/students'),
        isArray: true,
      }
    })
    }]).factory('oedGlobalKnowledgePoint', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
    return $resource(oedConfig.url_b('globalKnowledgePoint'), {
    }, {
      getKpsBySubjectAndLevel: {
        url: oedConfig.url_b('globalKnowledgePoint/subject/:subjectId/level/:levelId'),
        isArray: true,
      }
    })
    }]).factory('oedHwlTegong', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
    return $resource(oedConfig.url('cloudtest/:id'), {
      id: '@id'
    }, {
      queryQuestionBySection: {
        url: oedConfig.url_b('hwl/cloudquestion/query2'),
        method: 'POST',
        isArray: true
      },
      queryQuestionSuggestions: {
        url: oedConfig.url_b('hwl/cloudquestion/suggestions'),
        method: 'POST',
      },
      queryTestBySection: {
        url: oedConfig.url_b('hwl/cloudtest/query2'),
        isArray: true
      },
      queryTestSuggestions: {
        url: oedConfig.url_b('hwl/cloudtest/suggestions'),
        method: 'POST',
      },
      querySubjTestBySection: {
        url: oedConfig.url_b('hwl/cloudsubjectivetest/query2'),
        isArray: true
      },
      querySubjTestSuggestions: {
        url: oedConfig.url_b('hwl/cloudsubjectivetest/suggestions'),
        method: 'POST',
      },
      queryPreResItemBySectionPost: {
        url: oedConfig.url_b('hwl/cloudpresentresourceitem/query2'),
        method: 'POST',
        isArray: true
      },
      queryPreResItemSuggestions: {
        url: oedConfig.url_b('hwl/cloudpresentresourceitem/suggestions'),
        method: 'POST',
      },
      queryPreResBySection: {
        url: oedConfig.url_b('hwl/cloudpresentresource/query2'),
        isArray: true
      },
      queryPreResSuggestions: {
        url: oedConfig.url_b('hwl/cloudpresentresource/suggestions'),
        method: 'POST',
      },
    })
    }]).factory('wirtingPad', ['$resource', 'oedConfig', 'oedHttpCache', function($resource, oedConfig, oedHttpCache) {
      return $resource(oedConfig.url_b('writingPad/resource/uuid/:uuid/src?inline=true'), {
        uuid: '@uuid'
      }, {
        getWirtingPadInfo: {
          url: oedConfig.url_b('writingPad/:sessionId/:stuId'),
          method: 'GET',
        }
      })
    }]).factory('exerciseBook', ['$resource', 'oedConfig', function($resource, oedConfig) {
      return $resource(oedConfig.url_b('exerciseBook/exerciseBooks?subjectId=:sbjId&versionId=:verId&levelId=:levelId'), {}, {
        getExerciseBook: {
          url: oedConfig.url_b('exerciseBook/exerciseBooks?subjectId=:sbjId&versionId=:verId&levelId=:levelId'),
          method: 'GET',
          isArray: true
        },
        getSchoolExerciseBook: {
          url: oedConfig.url_b('exerciseBook/school/:schoolId/exerciseBooks'),
          method: 'GET',
          isArray: true
        }
      })
    }])
})();
