import * as _ from 'lodash'
import Promise = require('bluebird')
require('assets/app/controllers/examination/examReport/examReport.scss');
require('assets/app/controllers/examination/examReport/examReportHeader/examReportHeaderCtrl');
require('assets/app/controllers/examination/examReport/examReportTab/examReportTabCtrl');
require('assets/app/controllers/examination/examReport/examReportChart/examReportChart');
import { sortExamWhole } from 'assets/app/controllers/examination/examEdit/paper/paperUtils'

angular.module('app.examination.examReport', [
  'app.examination.examReport.header',
  'app.examination.examReport.tab',
  'app.examination.examReport.chart',
]).config(['$stateProvider', ($stateProvider) => {
  $stateProvider.state('examReport', {
    url: '/examReport?&examId&sectionId',
    template: require('assets/app/controllers/examination/examReport/examReport.html'),
    controller: 'examReportCtrl',
    loadingCls: 'blueloading',
    bodyStyle: 'bged text-hidden'
  });
}]).controller('examReportCtrl', examReportCtrl);

export enum ExamReportTab {
  Overall,
  Student,
  Question,
  KnowledgePoint,
  Quality
}

examReportCtrl.$inject = ['$stateParams', '$scope', 'oedExamination', '$state', 'notificationService', '$interval', 'oedUnitItem']
function examReportCtrl($stateParams, $scope, oedExamination, $state, notificationService, $interval, oedUnitItem) {
  $scope.examId = $stateParams.examId
  $scope.sectionId = $stateParams.sectionId
  $scope.selectedTab = ExamReportTab.Overall
  let retry = null

  $scope.loading = oedUnitItem.get({ id: $scope.sectionId }).$promise
    .then(seciton => $scope.sectionName = _.get(seciton, 'name', ''))
    .then(() => oedExamination.getExamSessions({ examId: $scope.examId }).$promise)
    .then(sessions => {
      const reportSessions = _.filter(sessions, s => _.get(s, 'examSession.reviewEndTime', 0) > 946656000000)
      $scope.sessions = _.sortBy(reportSessions, s => -_.get(s, 'examSession.startTime', 0))
      if (_.size($scope.sessions)) {
        $scope.onToggleSession($scope.sessions[0])
      }
    })

  $scope.onToggleSession = (session: any) => {
    if (_.isEmpty(session)) {
      return
    }
    const sessionId = _.get(session, 'examSession.id', 0)
    const examId = _.get(session, 'examSession.examId', 0)
    $scope.loading = oedExamination.getExamTestWhole({ examId }).$promise
      .then((whole) => $scope.examWhole = buildExamWholeData(whole))
      .then(() => oedExamination.getExamSearch({ sessionId }).$promise)
      .then((search) => {
        $scope.selectedSessionId = sessionId
        $scope.isCalculating = !_.get(search, 'complete', false)
        if ($scope.isCalculating) {
          return doRetry(sessionId).then(res => $scope.examSearch = parseSearchData(res))
        } else {
          $scope.examSearch = parseSearchData(search)
        }
      })
      .catch(() => notificationService.notify('error', '获取统计信息出错，请稍后再试'))
  }

  function doRetry(sessionId: number) {
    return new Promise((resolve, reject) => {
      if (retry) {
        $interval.cancel(retry)
        retry = null
      }
      retry = $interval(() => {
        return oedExamination.getExamSearch({ sessionId }).$promise
        .then(search => {
          $scope.isCalculating = !_.get(search, 'complete', false)
          if (!$scope.isCalculating) {
            $interval.cancel(retry)
            return resolve(search)
          }
        })
        .catch(() => $interval.cancel(retry))
      }, 5000, 5)
      retry.then(() => {
        $interval.cancel(retry)
        return reject('retry fail')
      })
    })
  }

  $scope.$on('$destroy',() => {
    if (retry) {
      $interval.cancel(retry)
      retry = null
    }
  })

  function parseSearchData(search: any) {
    search.knowledgeData = _.map(search.knowledgeData, d => {
      d.keyword = JSON.parse(d.keyword)
      d.data = JSON.parse(d.data)
      return d
    })
    search.studentPartData = _.map(search.studentPartData, d => {
      d.keyword = JSON.parse(d.keyword)
      d.data = JSON.parse(d.data)
      return d
    })
    search.questionPartData = _.map(search.questionPartData, d => {
      d.keyword = JSON.parse(d.keyword)
      d.data = JSON.parse(d.data)
      return d
    })
    return search
  }

  function buildExamWholeData(whole: any) {
    sortExamWhole(whole)
    let quesNum = 0
    let quesIdx = 0
    const examPartDetails =  _.map(_.get(whole, 'examPartDetails', []), (pd, pdIdx) => {
      const quesTypeByruanyu = _.get(pd, 'part.name', '')
      _.map(_.get(pd, 'questionDetails', []), (qd, qdIdx) => {
        quesNum ++
        const isSync = _.get(qd, 'question.type', '') === 'synthetic'
        if (isSync) {
          _.map(_.get(qd, 'question.childQuestions', []), (child, childIdx) => {
            quesIdx ++
            const num = `${quesNum}.${childIdx + 1}`
            _.set(child, 'quesNum', num)
            _.set(child, 'quesIdx', quesIdx)
            _.set(child, 'quesTypeByruanyu', quesTypeByruanyu)
            _.set(child, 'score', _.get(child, 'point2', ''))
            return child
          })
        }
        quesIdx ++
        _.set(qd, 'question.quesNum', quesNum)
        _.set(qd, 'question.quesIdx', quesIdx)
        _.set(qd, 'question.quesTypeByruanyu', quesTypeByruanyu)
        _.set(qd, 'question.score', _.get(qd, 'question.point2', ''))
        return qd
      })
      return pd
    })
    return { ...whole, examPartDetails }
  }

  $scope.onBack = () => {
    $state.go('prepareLesson')
  }

  $scope.onToggleTab = (tab: number) => {
    $scope.selectedTab = tab
  }
}
