import * as _ from 'lodash'
import { ClassSessionSmallData } from './smallData';

const transparent = '#00000000';
const selectBorder: any = {
  obj: '#0084b0',
  sbj: '#de8d47',
  self: '#d43d22',
  teacher: '#ff6666',
};
const echarts: any = require('echarts');
const ecStat = require('echarts-stat/dist/ecStat');

angular.module('app.directives.smallData', [])
  .component('smallData', {
    bindings: {
      classSessionId: '<',
    },
    controller: smallDataCtrl,
    template: <string> require('./smallData.html'),
  });

smallDataCtrl.$inject = ['oedSmallData', '$window', '$scope', '$uibModal', '$location']
function smallDataCtrl(oedSmallData: any, $window: any, $scope: any, $uibModal: any, $location: any) {
  const ctrl = this;
  this.$onChanges = function() {
    this.chartData = [];
    this.chartDataSummary = [];
    $scope.url = $location.protocol() + '://' + $location.host() + '/rayweb/smalldata/' + this.classSessionId;
    oedSmallData.get({
      id: this.classSessionId,
    }).$promise.then((res: any) => {
      res.objSessions = _.filter(res.objSessions, (s: any) => {
        if (_.isEmpty(s.answers)) {
          return false;
        }
        const ques = this.getTestQuestions(s.test);
        const point = _.sumBy(ques, (q: any) => q.point2);
        return point !== 0;
      });
      this.smallData = res;
      this.classMin = this.getMin(this.smallData.classDuration);
      this.classSec = this.getSec(this.smallData.classDuration);
      this.buildChart();
    });
  }

  this.getTestQuestions = function(test: any) {
    return _.compact(_.flatten(_.map(test.questions, (q: any) => {
      if (!_.isEmpty(q.childQuestions)) {
        return this.getQuestion(q.childQuestions);
      }
      return this.getQuestion([q]);
    })));
  }

  this.getQuestion = (questions: Array<any>) => {
    return _.filter(questions, (q: any) => q.type !== 'singlevote' && q.type !== 'multivote');
  }

  this.getSec = (time: number) => {
    return _.parseInt(((time % 60000) / 1000).toFixed(0));
  }

  this.getMin = (time: number) => {
    return _.parseInt(String(time / 60000));
  }

  this.getPercent = (time: number, total: number) => {
    return _.parseInt((time * 100 / total).toFixed(0));
  }

  this.getTeacherItemStyle = (color: any, isOuter = false) => {
    const style: any = {
      normal: {
        borderColor: '#ff6666',
        borderWidth: isOuter ? 0 : 5,
      },
    };
    if (!_.isEmpty(color)) {
      style.normal.color = color;
    }
    return style;
  }

  this.getStuItemStyle = (color: any, isOuter = false) => {
    const style: any = {
      normal: {
        borderColor: isOuter ? '#0084b0' : '#e1f4fa',
        borderWidth: isOuter ? 5 : 2,
        color: isOuter ? transparent : color,
      },
    };
    if (!_.isEmpty(color)) {
      style.normal.color = color;
    }
    return style;
  }

  this.getAnalysisDuration = (session: any) => {
    if (session.onlineStu > 0) {
      return session.analysisDuration / session.onlineStu;
    }
    return 0;
  }

  this.buildData = function() {
    const data: ClassSessionSmallData = this.smallData;
    // 练习的时间
    let obj = 0;
    let analysis = 0;
    if (_.size(data.objSessions) > 0) {
      obj = _.sumBy(data.objSessions, (s: any) => {
        if (s.onlineStu > 0) {
          return s.submitDuration / s.onlineStu;
        }
        return 0;
      });
      analysis = _.sumBy(data.objSessions, (s: any) => this.getAnalysisDuration(s));
    }
    // 活动的时间
    let sbj = 0;
    const nonGrpSbj = _.filter(data.sbjSessions, (s: any) => !s.isGroup);
    if (_.size(nonGrpSbj) > 0) {
      sbj = _.sumBy(nonGrpSbj, (s: any) => {
        if (s.onlineStu > 0) {
          return (s.submitDuration + s.socialDuration) / s.onlineStu;
        }
        return 0;
      });
      analysis = analysis + _.sumBy(nonGrpSbj, (s: any) => this.getAnalysisDuration(s));
    }
    // 小组讨论
    let grp = 0;
    const grpSbj = _.filter(data.sbjSessions, (s: any) => s.isGroup);
    if (_.size(grpSbj) > 0) {
      const grpOfGrp = _.groupBy(grpSbj, (g: any) => g.parentId);
      _.forEach(grpOfGrp, (value, key) => {
        const totalTime = _.sumBy(value, (s: any) => s.submitDuration + s.socialDuration);
        const totalAnalysis = _.sumBy(value, (s: any) => s.analysisDuration);
        const totalStu = _.sumBy(value, (s: any) => s.onlineStu);
        if (totalStu > 0) {
          grp = grp + totalTime / totalStu;
          analysis = analysis + totalAnalysis / totalStu;
        }
      });
    }
    // 展示资源
    let pre = 0;
    if (_.size(data.preSessions) > 0) {
      pre = _.sumBy(data.preSessions, (s: any) => {
        if (s.onlineStu > 0) {
          return s.totalDuration / s.onlineStu;
        }
        return 0;
      });
    }

    const self = pre + analysis;
    const stu = obj + sbj + grp + self;
    const total = data.classDuration;
    const teacher = total - stu;
    this.stuTime = stu;
    this.teacherTime = teacher;
    if (teacher > 0) {
      this.chartData.push({
        alias: 'teacher',
        itemStyle: this.getTeacherItemStyle('#d5deeb'),
        labelLine: {
          emphasis: {
            show: false,
          },
          show: false,
        },
        name: 'teacher',
        selected: true,
        value: teacher,
      });
      this.chartDataSummary.push({
        itemStyle: this.getTeacherItemStyle(transparent, true),
        name: 'teacher',
        selected: true,
        value: teacher,
      });
    }
    if (obj > 0) {
      this.chartData.push({
        alias: 'obj',
        itemStyle: this.getStuItemStyle('#5cc7ed'),
        name: '练习',
        percent: this.getPercent(obj, total),
        value: obj,
      });
    }
    if (sbj > 0) {
      this.chartData.push({
        alias: 'sbj',
        itemStyle: this.getStuItemStyle('#fcbe21'),
        name: '活动',
        percent: this.getPercent(sbj, total),
        value: sbj,
      });
    }
    if (grp > 0) {
      this.chartData.push({
        alias: 'grp',
        itemStyle: this.getStuItemStyle('#b3cc41'),
        name: '小组讨论',
        percent: this.getPercent(grp, total),
        value: grp,
      });
    }
    if (self > 0) {
      this.chartData.push({
        alias: 'self',
        itemStyle: this.getStuItemStyle('#fc8a88'),
        name: '自主学习',
        percent: this.getPercent(self, total),
        value: self,
      });
    }
    if (stu > 0) {
      this.chartDataSummary.push({
        emphasis: {
          itemStyle: {
            borderWidth: 5,
          },
        },
        itemStyle: this.getStuItemStyle(null, true),
        name: 'student',
        value: stu,
      });
    }
  }

  this.onClickDetail = (params: any, noApply: boolean) => {
    ctrl.curPart = params.data.alias;
    _.forEach(ctrl.chartData, (s: any) => {
      if (s.alias === ctrl.curPart) {
        s.itemStyle.normal.borderColor = selectBorder[ctrl.curPart];
        s.itemStyle.normal.borderWidth = 4;
        s.itemStyle.normal.opacity = 1;
      } else {
        s.itemStyle.normal.borderWidth = 2;
        s.itemStyle.normal.opacity = 0.3;
      }
    });
    _.forEach(ctrl.chartDataSummary, (s: any) => {
      s.itemStyle.normal.opacity = 0.3;
    });
    ctrl.option.series[0].data = ctrl.chartDataSummary;
    ctrl.option.series[1].data = ctrl.chartData;
    const dom = angular.element('#echarts')[0];
    const chart = echarts.getInstanceByDom(dom);
    if (!_.isUndefined(chart)) {
      chart.setOption(ctrl.option);
    }
  }

  this.showQr = () => {
    this.model = $uibModal.open({
      backdrop: true,
      resolve: {},
      scope: $scope,
      size: 'sm',
      template: require('./qrcode.html'),
      windowClass: 'small-data-modal',
    })
  }

  this.close = () => {
    this.model.close();
  }

  this.config = {
    dataLoaded: true,
    event: [{
      click: this.onClickDetail,
    }],
    height: 300,
    theme: 'light',
    width: $window.innerWidth * 0.5,
  };

  this.selectTeacher = () => {
    this.onClickDetail({
      data: {
        alias: 'teacher',
      },
    }, true);
  }

  this.selectStudent = function() {
    const tea = _.find(this.chartData, (s: any) => s.name === 'teacher');
    const stu = _.find(this.chartDataSummary, (s: any) => s.name === 'student');
    if (stu) {
      _.forEach(this.chartData, (s: any) => {
        s.itemStyle.normal.opacity = 1;
        s.itemStyle.normal.borderWidth = 2;
        s.itemStyle.normal.borderColor = '#e1f4fa';
      });
      if (!_.isEmpty(tea)) {
        tea.itemStyle.normal.opacity = 0.3;
      }
      stu.itemStyle.normal.opacity = 1;
      this.option.series[0].data = this.chartDataSummary;
      this.option.series[1].data = this.chartData;
    }
  }

  this.buildChart = function() {
    this.buildData();
    this.option = {
      series: [{
        avoidLabelOverlap: true,
        data: this.chartDataSummary,
        hoverAnimation: false,
        label: {
          emphasis: {
            show: true,
            textStyle: {
              fontSize: '12',
              fontWeight: 'bold',
            },
          },
          normal: {
            position: 'center',
            show: false,
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        name: '',
        radius: [0, 103],
        selectedOffset: 30,
        silent: true,
        startAngle: 225,
        type: 'pie',
      }, {
        avoidLabelOverlap: true,
        data: this.chartData,
        grid: {
          top: 0,
        },
        hoverAnimation: false,
        label: {
          normal: {
            formatter: (params: any) => {
              if (params.name === 'teacher') {
                return '';
              }
              const percent = params.data.percent;
              const time = params.data.value;
              let label = '';
              const min = _.parseInt(String(time / 60000));
              if (min > 0) {
                label = min + '分';
              }
              const sec = _.parseInt(((time % 60000) / 1000).toFixed(0));
              if (sec > 0 || min === 0) {
                label = label + sec + '秒';
              }
              return '{black|' + params.name + ' ' + percent + '%}\n{hr|}\n{black_lg|' + label + '}';
            },
            rich: {
              black: {
                align: 'center',
                color: '#000000',
                fontSize: 14,
                padding: 3,
              },
              black_lg: {
                align: 'right',
                color: '#000000',
                fontSize: 18,
                padding: 3,
              },
              hr: {
                borderColor: '#999999',
                borderWidth: 2,
                height: 0,
                width: '100%',
              },
            },
          },
        },
        labelLine: {
          normal: {
            length: 20,
            length2: 0,
            lineStyle: {
              color: '#999999',
              width: 2,
            },
          },
        },
        name: '',
        radius: [0, 100],
        selectedOffset: 30,
        startAngle: 225,
        type: 'pie',
      }],
      title: {
        bottom: 0,
        left: 'center',
        text: '',
        textStyle: {
          color: '#999999',
          fontSize: 14,
          fontWeight: 'normal',
        },
      },
    };
  }
}
