import { Directive, ElementRef, SimpleChange, Input, Output, EventEmitter, HostListener } from '@angular/core';
import * as _ from 'lodash'
import { LessonDTO } from '../models/lesson';

@Directive({
  selector: '[appElemWidth]'
})
export class ElemWidthDirective {

  @Input() public itemCount: number
  @Output() public itemCountToFix = new EventEmitter<number>()
  @Input() public itemWidth: number
  @Input() public ratio: number
  constructor(
    private el: ElementRef,
  ) { }

  public ngOnChanges(changes: SimpleChange) {
    if (_.has(changes, 'itemCount')) {
      this.calcWith()
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    setTimeout(() => {
      this.calcWith(event.target.innerWidth * this.ratio)
    }, 0)
  }

  public calcWith(width: number = null) {
    setTimeout(() => {
      const elem = this.el.nativeElement
      if (!width) {
        width = elem.clientWidth
      }
      const itemToFix = Math.floor(width / this.itemWidth)
      this.itemCountToFix.emit(itemToFix)
      elem.style.width = `${itemToFix * this.itemWidth}px`
    }, 0)
  }

}
