import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CloudQuestionService} from 'app/cloud/cloud-question.service';

@Component({
  selector: 'app-subject-selector',
  template: require('./subject-selector.component.html'),
  styles: [require('./subject-selector.component.scss')]
})
export class SubjectSelectorComponent implements OnInit {
  @Input() subjectId: number;
  @Input() subjectName: string;
  @Input() schoolId: number;
  @Input() vendor: string;
  @Input() levelName: string;
  @Output() selectedSubject = new EventEmitter<any>()
  
  levelSubj: string
  subjects: any
  selectedSubj: any

  constructor(private cqService: CloudQuestionService) { }

  ngOnInit() {
    this.levelSubj = `${this.levelName} - ${this.subjectName}`
    this.cqService.getSubjects(this.schoolId).subscribe((s) => {
      this.subjects = s
    })
  }
  
  private onItem(subj) {
    this.selectedSubj = subj
    this.subjectName = subj.subjectName
  }
  
  private confirm() {
    this.levelSubj = `${this.levelName} - ${this.subjectName}`
    this.selectedSubject.emit(this.selectedSubj)
  }
}
