import * as angular from 'angular';
import Promise = require('bluebird')
import * as _ from 'lodash';

const {importPresentResourceItems, uploadAnhuiResToAnhuiSpace} = require('app2/utils/resourceUtils')
require('app2/prepareCourse/cloud/presentResource/presentResourceLibraryDialog.ctrl');
require('app2/prepareCourse/editPresentRes/editPresentRes.ctrl')
require('./presentResource.scss');
require('assets/styles/testimport.scss');

angular.module('app.prepareCourse2.presentResource', [
  'app.prepareCourse2.cloud.presentResource.presentResourceLibraryDialog',
  'app.prepareCourse2.editPresentRes'
])
.component('presentResource', {
  bindings: {
    resId: '<',
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
  controller: presentResourceCtrl,
  template: <string>require('./presentResource.html'),
});

presentResourceCtrl.$inject = [
  '$rootScope',
  '$q',
  '$http',
  '$state',
  '$uibModal',
  '$httpParamSerializer',
  'dialogs',
  'userInfo',
  'hlsResource',
  'oedPreResItem',
  'notify',
  'oedConfig',
  'oedCourse',
  'oedPresentResource',
  'oedCloudPresentRes',
  'oedCloudPresentResItem',
  'oedPresentResourceItem',
  'oedUserInfo',
  'oedCourseUtils',
  'oedResDownloadTask',
  'teachingModuleService',
  'notificationService',
  'anHuiResource',
  'talAreaResource',
]

function presentResourceCtrl(
  $rootScope,
  $q,
  $http,
  $state,
  $uibModal,
  $httpParamSerializer,
  dialogs,
  userInfo,
  hlsResource,
  oedPreResItem,
  notify,
  oedConfig,
  oedCourse,
  oedPresentResource,
  oedCloudPresentRes,
  oedCloudPresentResItem,
  oedPresentResourceItem,
  oedUserInfo,
  oedCourseUtils,
  oedResDownloadTask,
  teachingModuleService,
  notificationService,
  anHuiResource,
  talAreaResource,
) {
  const ctrl = this

  ctrl.selectedReses = [];
  userInfo.then((user) => {
    ctrl.userInfo = user;
  });

  this.importedTests = []

  ctrl.closeClicked = () => {
    this.doClose()
  };

  this.doClose = () => {
    this.close({
      $value: {
        importedTests: this.importedTests,
      }
    })
  }

  ctrl.onImportTestChange = (tests: number[]) => {
    this.importedTests = tests
  }

  ctrl.setTemplateHeight = () => {
    const bodyHeight = document.documentElement.clientHeight - 55;
    return {
      'min-height': bodyHeight + 'px'
    };
  };

  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'resolve')) {
      this.courseId = this.resolve.data.courseId
      this.moduleId = this.resolve.data.moduleId
      this.moduleName = this.resolve.data.moduleName
      ctrl.getResources()
      if (this.moduleId) {
        this.loadModuleRes()
      }
    }

    if (_.has(changesObj, 'resId')) {
      ctrl.curResId = ctrl.resId
    }
  }

  this.loadModuleRes = () => {
    this.loading = teachingModuleService.loadModuleDetails(this.moduleId).then((updateModuleDTO) => {
        const allModuleTestIds = _.map(updateModuleDTO.presentResources, 'id')
        this.moduleDetails = {
            ...this.moduleDetails,
            updateModuleDTO,
            allModuleTestIds,
            moduleName: this.moduleName,
        }
    })
  }

  this.reload = (updateModuleDTO, test) => {
    let newTestIds = this.importedTests
    if (updateModuleDTO.$$action === 'add') {
      newTestIds = [...newTestIds, test.id]
    } else {
      newTestIds = _.remove(newTestIds, test.id)
    }

    this.onImportTestChange(newTestIds)

    this.loading = teachingModuleService.updateTeachingModule(updateModuleDTO).then(() => {
        return this.loadModuleRes()
    })
  }

  ctrl.getResources = () => {
    return this.loading = oedPresentResource.queryByCourseIncEmpty(
      {
        course_id: ctrl.courseId
      }
      ).$promise.then((res) => {
      ctrl.resources = res
      let selected = null;
      if (_.size(ctrl.resources) > 0) {
        if (ctrl.resId) {
          selected = _.find(ctrl.resources, (r: any) => {
            return r.id === ctrl.resId
          })
        }
        if (!selected) {
          selected = ctrl.resources[0]
        }
        ctrl.setCurrentRes(selected, ctrl.resources)
      } else {
        const r = new oedPresentResource({
          name: '展示资源1',
          courseId: ctrl.courseId,
          isEmpty: false,
        });
        r.$save().then((t) => {
          ctrl.resources.push(t)
          ctrl.setCurrentRes(ctrl.resources[0])
        })
      }
    }).then((res) => {
      if (res) {
        return oedCloudPresentRes.queryByResIds(
          {resIds: _.map(ctrl.resources, 'id'),
          }).$promise.then((allSharedReses) => {
          ctrl.sharedResources = _.keyBy(allSharedReses, 'resourceId');
          if (!ctrl.sharedResources) {return}
          ctrl.refreshCurrentCloudResId()
        })
      }
    }).then(() => {
      return oedCourse.get({id: ctrl.courseId}).$promise.then((res) => {
        if (res) {
          ctrl.course = res
        }
      })
    })
  }

  ctrl.refreshCurrentCloudResId = () => {
    if (ctrl.sharedResources[ctrl.currentRes.id]) {
      ctrl.sharedResourceId = ctrl.sharedResources[ctrl.currentRes.id].id;
    } else {
      ctrl.sharedResourceId = null;
    }
  };
  ctrl.shouldShowShare = (res) => {
    return res && ctrl.sharedResources && ctrl.sharedResources[res.id];
  };
  ctrl.setCurrentRes = (t, res) => {

    if (ctrl.currentRes && t && ctrl.currentRes.id === t.id) {
      return
    }

    ctrl.currentRes = t;
    ctrl.curResId = t.id
    ctrl.busy = false
  };

  ctrl.updateRes = (t, res) => {
    if (t) {
      ctrl.getResources()
    }
    ctrl.resources = res
  }

  ctrl.basePath = oedConfig.base();

  ctrl.setName = (sort) => {
    const str = '展示资源' + (sort);
    if (ctrl.existingNames.indexOf(str) > -1) {
      sort = sort + 1;
      ctrl.setName(sort);
    } else {
      ctrl.newName = str;
    }
  };

  ctrl.addResource = () => {

    ctrl.existingNames = _.map(ctrl.resources, 'name');
    ctrl.maxSort = _.max(_.map(ctrl.resources, 'sort'));

    if (isNaN(ctrl.maxSort)) {
      ctrl.maxSort = 1;
    } else {
      ctrl.maxSort = ctrl.maxSort + 1;
    }

    ctrl.setName(ctrl.maxSort)

    if (!ctrl.busy) {
      ctrl.busy = true
      const res = new oedPresentResource({
        name: ctrl.newName,
        courseId: ctrl.courseId,
        sort: ctrl.maxSort
      })
      ctrl.loading = res.$save().then((r) => {
        ctrl.resources.push(r);
        ctrl.setCurrentRes(r, ctrl.resources);
      })
    }
  };

  ctrl.editRes = ($event, res) => {
    $event.stopPropagation();
    $event.preventDefault();
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/editPresentResourceDialog.html'),
      controller: 'editPresentResourceCtrl',
      size: 'sm',
      windowClass: 'oedmodal modalCenter',
      resolve: {
        resource: () => {
          return res;
        },
        existingRes: () => {
          return ctrl.resources;
        }
      }
    });
  };

  ctrl.toggleShareRes = ($event, res) => {
    $event.stopPropagation();
    $event.preventDefault();
    let dlg = null;
    if (res.id in ctrl.sharedResources) {
      dlg = dialogs.confirm('确定取消共享?', '确定要取消共享该展示类资源吗？');
      dlg.result.then((btn) => {
        ctrl.sharedResources[res.id].$delete().then((result) => {
          delete ctrl.sharedResources[res.id];
          ctrl.refreshCurrentCloudResId();
        });
      }, (btn) => {});
    } else {
      dlg = dialogs.confirm('确定共享?', '确定要共享该展示类资源吗？');
      dlg.result.then((btn) => {
        const sharedRes = new oedCloudPresentRes({
          resourceId: res.id
        });
        sharedRes.$save().then((r) => {
          ctrl.sharedResources[res.id] = r;
          ctrl.refreshCurrentCloudResId();
        });
      }, (btn) => {});
    }
  };
  function queryCloudPresentResItem(queryOpt) {
    const queryCountURL = oedConfig.url_b('cloudpresentresourceitem/count2');
    const countReq = $http({
      url: queryCountURL,
      method: 'GET',
      params: queryOpt
    });
    const loadItems = oedCloudPresentResItem.queryBySection(queryOpt).$promise.then((cloudItems) => {
      //
      // 加载详细信息
      const loadDetails = _.map(cloudItems, loadCloudResItemDetails);
      return $q.all(loadDetails);
    });
    return [loadItems, countReq];
  }

  function loadCloudResItemDetails(item) {
    item.res = oedPresentResourceItem.get({
      id: item.resourceId
    });

    item.owner = oedUserInfo.getByCloudPresentResourceItemId({
      id: item.id
    });

    return $q.all([item.res.$promise, item.owner.$promise]).then(() => {
      return item;
    });
  }

  function importResources(deferred, res) {
    let curIdx = 0;
    let hasDuplicated = false;

    const doDownload = () => {
      if (curIdx >= _.size(res)) {
        return $q.when('done');
      }

      const curSize = _.size(ctrl.currentRes.items);

      const cloudResItemCount = res[curIdx].stats.itemCount;
      const idx = curIdx;
      deferred.notify('正在导入第' + (idx + 1) + '个资源');
      const promise = ctrl.currentRes.$importItemsFromCloudRes({
        res_id: res[curIdx].id
      }).then((resp) => {
        const targetSize = curSize + cloudResItemCount;
        if (!hasDuplicated && targetSize > _.size(resp.items)) {
          hasDuplicated = true;
        }

        return doDownload();
      });

      curIdx++;
      return promise;
    };

    $q.when(doDownload())
      .then((r) => {
        $rootScope.$broadcast('presentResource.changed', {
          resId: ctrl.currentRes.id
        });
        return ctrl.currentRes.$get();
      }).then(() => {
        deferred.resolve(curIdx);
      }).catch((m) => {
        deferred.reject(m);
      })
      .finally((r) => {
        if (hasDuplicated) {
          notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
        }
      });
  }

  let downloadOpts = null
  //
  // TODO: refactor
  function importResourceItems(deferred, res) {
    const opts = {
      canceled: false,
      hlsResource,
      hasDuplicated: false,
      oedCloudPresentResItem,
      oedPreResItem, oedPresentResourceItem,
      oedPresentResource,
      oedResDownloadTask,
      uid: ctrl.userInfo.uid,
      notify,
      talAreaResource,
    }

    downloadOpts = opts

    importPresentResourceItems(ctrl.currentRes, res, deferred, opts).then((r) => {
        $rootScope.$broadcast('presentResource.changed', {
          resId: ctrl.currentRes.id
        });
        return ctrl.currentRes.$get();
      }).then(() => {
        deferred.resolve();
      }).catch((m) => {
        deferred.reject(m);
      }).finally((r) => {
        downloadOpts = null
        if (opts.hasDuplicated) {
          notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
        }
        doUploadAnhuiResToAnhuiSpace(res);
      });
    }

    function doUploadAnhuiResToAnhuiSpace(res) {
      console.log('doUploadAnhuiResToAnhuiSpace C')
      const opt = {
        anHuiResource
      }
      userInfo.then(function(user) {
        if (_.get(user, 'profile.thirdParty', '') != 'anhui' || !_.get(user, 'profile.thirdPartyUid', '')) {
          return Promise.reject({msg: 'current user isnot belong to anhui platform'})
        }
        _.set(opt, 'user', user)
        return uploadAnhuiResToAnhuiSpace(res, opt);
      })
      .then(res => {
        console.log('uploadAnhuiResToAnhuiSpace res:', res)
      })
      .catch(error => console.error)
    }

  function downloadResourceAsFile(deferred, reses) {
    const idList = _.map(reses, (res) => {
      return res.id;
    }).join(',');
    deferred.notify('正在准备下载...');
    oedCloudPresentRes.validateDownload({
      idList,
    }).$promise.then((resp) => {
      const downloadUrl = oedConfig.url('cloudpresentresource/downloadresources/' + idList);
      if (resp.success) {
        deferred.resolve();
        window.open(downloadUrl, _.get(window, '_link_open_type'));
      } else {
        notificationService.notify('error', resp.message)
        deferred.reject(resp);
      }
    }).catch(deferred.reject);
  }

  function downloadResourceItemsAsFile(deferred, reses) {
    const idList = _.map(reses, 'id');
    const urlPart = $httpParamSerializer({
      resIds: idList
    });

    console.log(urlPart);

    deferred.notify('正在准备下载...');
    const downloadUrl = oedConfig.url_b('cloudpresentresourceitem/download?' + urlPart);
    window.open(downloadUrl, _.get(window, '_link_open_type'));
    deferred.resolve();
  }

  ctrl.importFromLibrary = () => {
    $uibModal.open({
      template: require('assets/templates/prepareCourse/importFromLibraryDialog.html'),
      controller: 'importFromLibraryCtrl',
      size: 'lg',
      windowClass: 'shouldHideOnPreview cl-library-dialog',
      resolve: {
        resourceDef: () => {
          return {
            name: '展示资源',
            course: ctrl.course,
            res: oedCloudPresentRes,
            canImportMultiple: true,
            canImportResBox: true,
            isPreRes: true,
            headerAddonUrl: 'Assets/templates/prepareCourse/cloudPresentResourceHeaderAddon.html',
            itemTemplateUrl: 'Assets/templates/prepareCourse/cloudPresentResourceItem.html',
            queryCountURL: oedConfig.url('cloudpresentresource/querybysection/count'),
            shouldDismissParent: false,
            viewType: 'resitem',
            currentRes: ctrl.currentRes,
            doPreview: (res, resDef) => {
              const def = this;
              const modalInstance = $uibModal.open({
                template: require('assets/templates/prepareCourse/cloudPresentResPreviewer.html'),
                controller: 'cloudPresentResPreviewerCtrl',
                size: 'lg',
                resolve: {
                  cloudRes: () => {
                    return res;
                  },
                  currentRes: () => {
                    return ctrl.currentRes;
                  },
                  resourceDef: () => {
                    return resDef || def;
                  }
                },
                windowClass: 'cl-library-preview'
              });

              return modalInstance.result;
            },
            doPreviewResBox: (items) => {
              const def = this;
              const modalInstance = $uibModal.open({
                template: require('app2/prepareCourse/resourceItemsPreviewer.html'),
                controller: 'resourceItemsPreviewerCtrl as ctrl',
                size: 'lg',
                resolve: {
                  data: () => {
                    return {
                      items,
                    };
                  },
                  resourceDef: () => {
                    return def;
                  }
                },
                windowClass: 'cl-library-preview'
              });

              return modalInstance.result;
            },
            importFromLibraryByResId: (vt, data) => {
              const def = _.assign(this, {
                viewType: vt
              });
              const modalInstance = $uibModal.open({
                template: require('assets/templates/prepareCourse/importFromLibraryByResIdDialog.html'),
                controller: 'importFromLibraryByResIdCtrl',
                windowClass: 'shouldHideOnPreview cl-library-dialog cl-import-by-res-dialog',
                size: 'lg',
                resolve: {
                  resourceDef: () => {
                    return def;
                  },
                  queryData: () => {
                    return data;
                  }
                }
              });
              return modalInstance.result;
            },
            queryRes: (queryOpt, viewType) => {
              if (viewType === 'resource') {
                return oedCourseUtils.queryCloudPresentRes(queryOpt);
              }

              return queryCloudPresentResItem(queryOpt);
            },
            queryResById: (queryOpt, viewType) => {
              const resId = oedCourseUtils.getCloudResTypeAndId(_.trim(queryOpt.queryId));
              if (!resId) {
                return $q.resolve(null);
              }

              const kind = _.get(resId, 'kind', '');
              if (kind !== 'presentResource') {
                const res = oedCloudPresentResItem.get({
                  id: resId.id
                });
                return res.$promise.then((item) => {
                  return loadCloudResItemDetails(item);
                }).catch(() => {
                  return null;
                });
              }

              queryOpt = _.assign({}, queryOpt, {
                queryId: resId.id
              });

              const item = oedCloudPresentRes.queryByCloudTestOrPreRedId(queryOpt);
              return item.$promise.then(oedCourseUtils.loadCloudPresentResDetails);
            },
            doImport: doImportResources,
            doDownload: (reses, deferred, viewType) => {
              if (viewType === 'resource') {
                downloadResourceAsFile(deferred, reses);
              } else {
                downloadResourceItemsAsFile(deferred, reses);
              }
            }
          };
        }
      }
    });
  };
  ctrl.sortableOptions = {
    axis: 'y',
    stop: (event) => {
      const newOrders = _.map(ctrl.resources, (m, index) => {
        return {
          id: m.id,
          sort: index
        };
      });
      ctrl.loading = oedCourse.updatePresentResourceOrder(newOrders).$promise.then().catch(() => {
        notificationService.notify('error', '保存失败')
      });
    }
  };

  function doImportResources(res, deferred, viewType) {
    if (_.isEmpty(res)) {
      deferred.resolve(0);
    } else {
      if (viewType === 'resource') {
        importResources(deferred, res);
      } else {
        importResourceItems(deferred, res);
      }
    }

    return deferred.promise;
  }

  ctrl.importFromLibrary2 = () => {
    const modalInstance = $uibModal.open({
      template: require('app2/prepareCourse/cloud/presentResource/presentResourceLibraryDialog.html'),
      controller: 'presentResourceLibraryDialogCtrl',
      controllerAs: '$ctrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          cancelDownload,
          course: ctrl.course,
          doImport: (res, deferred) => doImportResources(res, deferred, null),
        })
      },
    })
    modalInstance.result.then(() => {
      return oedPresentResource.get({id: ctrl.currentRes.id}).$promise.then((res) => {
        const loadCloudItems = _.map(res.items, (item) => {
          return oedCloudPresentResItem.getByResItemId({
            id: item.id
          }).$promise.then((ci) => {
            item.cloudRes = ci;
            return item;
          })
        })
        ctrl.resource = res
        return $q.all(loadCloudItems)
      })
    })
  }

  function cancelDownload() {
    if (downloadOpts) {
      downloadOpts.canceled = true
    }
  }
}
