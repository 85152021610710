/**
 * @fileOverview
 * @name versionChecker.js
 * @author
 * @license
 */
(function() {
  'use strict';
  const _ = require('lodash');
  angular.module('app.utils.versionChecker', [])
    .factory('oedVersionChecker', oedVersionChecker);

  oedVersionChecker.$inject = ['$interval', '$http', '$uibModal', 'notificationService']
  function oedVersionChecker($interval, $http, $uibModal, notificationService) {
    var updateVersion = function(opt) {
      var now = moment().valueOf();
      return $http.get(opt.versionUrl + '?' + now, {
        headers: {
          'Cache-Control': 'no-cache'
        }
      });
    };

    var svc = {
      notifiedVerions: {},
      init: function(opt) {
        opt = opt || {};
        this.config = _.defaults(opt, {
          updateInterval: 30 * 1000, //ms
          versionUrl: 'Assets/version_oedweb.txt'
        });
        var self = this;
        self.curVersion  = _.trim(COMMITHASH);
        self.sceduleUpdate();
      },
      sceduleUpdate: function() {
        var self = this;
        function doCheckUpdate() {
          return updateVersion(self.config).then(function(ret) {
            var newVer = _.trim(ret.data);
            if (!newVer) return;
            //
            // 发现新版本

            if (_.has(self, 'curVersion') && newVer != self.curVersion) {
              if (_.has(self.notifiedVerions, newVer)) {
                return;
              }
              notificationService.versionNotify()
              // let modalInstance = $uibModal.open({
              //   template: require('assets/templates/utils/newversion.html'),
              //   controller: 'newVersionCtrl',
              //   size: 'sm',
              //   windowClass: 'oed-common-modal'
              // })
              self.notifiedVerions[newVer] = true;
            } else {
              self.curVersion = newVer;
            }
          }).catch(e => {
            console.warn('verion check failed', e)
          })
        }

        doCheckUpdate().finally(() => {
          $interval(function() {
            doCheckUpdate();
          }, this.config.updateInterval);
        });
      }
    };

    return svc;
  }

})();
