import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CloudDriveDialogComponent} from 'app/cloud-drive/cloud-drive-dialog/cloud-drive-dialog.component';
import { MyFileComponent } from './my-file/my-file.component';
import {SharedModule} from 'app/shared/shared.module';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { FileUploadModule } from 'ng2-file-upload';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { DirDialogComponent } from './dir-dialog/dir-dialog.component';
import {TreeModule} from 'angular-tree-component';
import { MyCourseComponent } from './my-course/my-course.component';
import { MyRecycleBinComponent } from './my-recycle-bin/my-recycle-bin.component';
import { RestoreDialogComponent } from './restore-dialog/restore-dialog.component';

@NgModule({
  declarations: [CloudDriveDialogComponent, MyFileComponent, ErrorDialogComponent, DirDialogComponent, MyCourseComponent, MyRecycleBinComponent, RestoreDialogComponent],
  imports: [
    CommonModule,
    SharedModule,
    TooltipModule,
    InfiniteScrollModule,
    FileUploadModule,
    TreeModule.forRoot()
  ],
  entryComponents: [CloudDriveDialogComponent, ErrorDialogComponent, DirDialogComponent, RestoreDialogComponent]
})
export class CloudDriveModule { }
