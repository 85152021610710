import { Injectable } from '@angular/core'
import { TestOrPresentDTO, LessonDTO} from 'app/lesson/models/lesson.d'
import { LegacyAppService } from './legacy-app.service'
import { NotificationService } from 'app/shared/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class OedUibmodalService {

  private uibModal: any
  private resPreviewHandler = {
    oedObjectiveTest: {
      preview: (res, isTemplate) => {
        return this.previewObjectiveTest(res, isTemplate)
      },
      edit: (courseId, testId, moduleId, moduleName) => {
        return this.openObjEditModal(courseId, testId, moduleId, moduleName)
      }
    },
    objective: {
      preview: (res, isTemplate) => {
        return this.previewObjectiveTest(res, isTemplate)
      },
      edit: (courseId, testId, moduleId, moduleName) => {
        return this.openObjEditModal(courseId, testId, moduleId, moduleName)
      }
    },
    oedSubjectiveTest: {
      preview: (res, isTemplate) => {
        return this.previewSubjectiveTest(res, isTemplate)
      },
      edit: (courseId, testId, moduleId, moduleName) => {
        return this.openSubResModal(courseId, testId, moduleId, moduleName)
      }
    },
    subjective: {
      preview: (res, isTemplate) => {
        return this.previewSubjectiveTest(res, isTemplate)
      },
      edit: (courseId, testId, moduleId, moduleName) => {
        return this.openSubResModal(courseId, testId, moduleId, moduleName)
      }
    },
    oedPresentResource: {
      preview: (res, isTemplate) => {
        return this.previewPresentRes(res, isTemplate)
      },
      edit: (courseId, testId, moduleId, moduleName) => {
        return this.openPreResModal(courseId, testId, moduleId, moduleName)
      }
    },
    presentresource: {
      preview: (res, isTemplate) => {
        return this.previewPresentRes(res, isTemplate)
      },
      edit: (courseId, testId, moduleId, moduleName) => {
        return this.openPreResModal(courseId, testId, moduleId, moduleName)
      }
    },
  };
  constructor(
    private legacyAppService: LegacyAppService,
    private notifyService: NotificationService
    ) {
    this.uibModal = legacyAppService.getUibModal()
  }

  // tslint:disable-next-line:max-line-length
  public openObjEditModal = (courseId: number, testId: number = null, moduleId: number = null, moduleName = '') => {
    const modalInstance = this.uibModal.open({
      component: 'objectiveTestDesign',
      size: 'edit-test',
      windowClass: 'shouldHideOnPreview cl-library-dialog',
      resolve: {
        data: () => {
          return {
            moduleId,
            moduleName,
            courseId,
            testId,
          };
        }
      }
    })

    return modalInstance.result
  }

  // tslint:disable-next-line:max-line-length
  public openPreResModal = (courseId: number, resId: number = null, moduleId: number = null, moduleName = '') => {
    const modalInstance = this.uibModal.open({
      component: 'presentResource',
      size: 'edit-test',
      windowClass: 'shouldHideOnPreview cl-library-dialog',
      resolve: {
        data: () => {
          return {
            courseId,
            resId,
            moduleId,
            moduleName,
          };
        }
      }
    })

    return modalInstance.result
  }

  // tslint:disable-next-line:max-line-length
  public openSubResModal = (courseId: number, testId: number = null, moduleId: number = null, moduleName = '') => {
      const modalInstance = this.uibModal.open({
          component: 'subjectiveTestDialog',
          size: 'edit-test',
          windowClass: 'oed-dialog-modal',
          resolve: {
            data: () => ({
                courseId,
                testId,
                moduleId,
                moduleName,
            })
          }
      });
      return modalInstance.result
  }

  public openCourseImportModal = (course: LessonDTO, doImport: any) => {
    const modalInstance = this.uibModal.open({
      component: 'teachingDesignLibrary',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          course,
          doImport,
        })
      },
    })

    return modalInstance.result
  }

  // pattern => 是否为推荐资源
  // tslint:disable-next-line:max-line-length
  public gotoPreviewOrEdit = (res: TestOrPresentDTO, isRecommend: boolean, courseId: number, moduleId: number = null, moduleName = '') => {
    if (this.resPreviewHandler[res.type] && isRecommend) {
      return this.resPreviewHandler[res.type].preview(res, isRecommend)
    }
    if (this.resPreviewHandler[res.type] && !isRecommend) {
      return this.resPreviewHandler[res.type].edit(courseId, res.id, moduleId, moduleName)
    }
    this.notifyService.notify('info', '预览出错')
  };

  public previewObjectiveTest = (res: any, isRecommend: any) => {
    const modalInstance = this.uibModal.open({
      template: require('assets/templates/prepareCourse/objectiveTestPreviewer.html'),
      controller: 'objectiveTestPreviewerCtrl',
      size: 'lg',
      resolve: {
        cloudTest: () => {
          return res
        },
        templateRes: () => {
          return isRecommend
        },
        gotoRes: () => {
          return '$scope.gotoResource'
        },
        resourceDef: null
      },
      windowClass: 'oed-dialog-modal cl-preview-readonly modalCenter'
    });

    return modalInstance.result;
  }

  public previewSubjectiveTest = (res: any, isRecommend: any) => {
    const modalInstance = this.uibModal.open({
      template: require('assets/templates/prepareCourse/subjectiveTestPreviewer.html'),
      controller: 'subjectiveTestPreviewerCtrl',
      size: 'lg',
      resolve: {
        cloudTest: () => {
          return res;
        },
        templateRes: () => {
          return isRecommend;
        },
        gotoRes: () => {
          return '$scope.gotoResource';
        },
        resourceDef: null
      },
      windowClass: 'oed-dialog-modal cl-preview-readonly modalCenter'
    });

    return modalInstance.result;
  }

  public previewPresentRes = (res: any, isRecommend: any) => {
    const modalInstance = this.uibModal.open({
      template: require('assets/templates/prepareCourse/presentResPreviewer.html'),
      controller: 'presentResPreviewerCtrl',
      size: 'lg',
      resolve: {
        cloudRes: () => {
          return res;
        },
        templateRes: () => {
          return isRecommend;
        },
        gotoRes: () => {
          return '$scope.gotoResource';
        },
        currentRes: () => {
          return res;
        },
        resourceDef: null
      },
      windowClass: 'oed-dialog-modal cl-preview-readonly modalCenter'
    });

    return modalInstance.result;
  }

  public previewIntelligentPaper = (data: any, ids: any) => {
    const modalInstance = this.uibModal.open({
      template: require('assets/templates/prepareCourse/previewIntelligentPaper.html'),
      controller: 'previewIntelligentPaperCtrl',
      size: 'lg',
      resolve: {
        data: () => {
          return data;
        },
        ids: () => {
          return ids;
        },
      },
      windowClass: 'modal-full-screen2 modal-lg',
      windowTopClass: 'modal-full-screen2-top'
    });

    return modalInstance.result;
  }

}
