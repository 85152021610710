import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RayApiService } from 'app/core/ray-api.service';
import * as _ from 'lodash';
import { map, flatMap, onErrorResumeNext, toArray } from 'rxjs/operators';
import { BoardSessionInteractsMDTO, BoardContentCommentBodyDTO, BoardSessionStudentDTO, BoardSessionStudentDTOView, TestSessionSummaryDTO } from 'app/models/sbj-test-session';
import { ClassroomService } from 'app/shared/classroom.service';
import { zip, of, from, Observable } from 'rxjs';
import { UserInfoService } from 'app/core/user-info.service';
import { MyInfoDTO } from 'app/models/user';

@Injectable({
  providedIn: 'root'
})
export class SbjSessionService {

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService,
              private classroomService: ClassroomService,
              private userInfoService: UserInfoService) { }

  public getSessionStat(boardSessionId: number): Observable<BoardSessionInteractsMDTO<BoardContentCommentBodyDTO>> {
    const rawParams = {
      boardSessionId ,
      includeBoardContent: false,
      includeBoardThumbnail: true, /** 拿thumbnail 用于区分是否真的有content, 没有的话就用contentBody 中的内容 */
      includeBravos: true,
      includeComments: true,
      includeFlags: true,
      includeRatings: true,
      includeViews: true,
      sortBy: 'bravosCount',
    }
    const params = _.mapValues(rawParams, (v) => '' + v)
    function parseComment(commentBody: string): BoardContentCommentBodyDTO {
      try {
        return JSON.parse(commentBody);
      } catch (ex) {
        return {}
      }
    }

    return this.httpClient.get<BoardSessionInteractsMDTO>(this.rayApi.apiA('boardsession/interactsm'), {
      params,
    }).pipe(map((stats) => {
       _.forEach(stats.interacts, (interact) => {
         //
         // todo: 使用更好的类型标注
        interact.commentsList = _.map(interact.commentsList, (cmt) => {
          const cmtContent = parseComment(cmt.value.commentBody)
          cmt.value.commentBody = cmtContent as any;
          cmt.right.commentBody = cmtContent as any;
          return cmt;
        })
      })

      return stats as BoardSessionInteractsMDTO<BoardContentCommentBodyDTO>
    }))
  }

  public queryStdsByBoardSessionId(id: number) {
    return this.httpClient.get<BoardSessionStudentDTO[]>(this.rayApi.apiB(`boardsession/${id}/students`))
  }

  public queryBoardSessionStdList(boardSessionId: number, id: number, interacts: any) {
    const boardSessionStds$ = this.queryStdsByBoardSessionId(boardSessionId)
    const classStds$ = this.classroomService.queryClassStudents(id)

    function partitionStudents(boardSessionStds: BoardSessionStudentDTOView[], interacts: any) {
      const map = _.groupBy(interacts, 'owner.uid')
      const partFinish = _.partition(boardSessionStds, (s) => {
        return _.has(map, s.uid)
      });
      const stdsOfFinish = partFinish[0]
      const stdsOfUnFinish = partFinish[1]
      const partJoin = _.partition(stdsOfUnFinish, 'wasOnline')
      return [stdsOfFinish, partJoin[0], partJoin[1]]
    }

    return zip(boardSessionStds$, classStds$)
      .pipe(flatMap(([bsStds, clsStds]) => {
        const mapClassStdsByUid = _.keyBy(clsStds, 'uid')
        const allStdIds: number[] = _.map(bsStds, 'studentId')
        const [, noExistStdIds] = _.partition(allStdIds, (uid) => _.has(mapClassStdsByUid, uid))
        //
        // 加载不存在的学生信息(学生被移出了班级)
        return from(noExistStdIds)
          .pipe(flatMap((stdId) => onErrorResumeNext(this.userInfoService.getByUid(stdId))),
                toArray(),
                map((nonExistInfos: MyInfoDTO[]) => {
                  const nonExistInfoMap = _.keyBy(nonExistInfos, 'uid')
                  const newStds = _.chain(bsStds).map((std) => {
                    if (_.has(mapClassStdsByUid, std.studentId)) {
                      const info = mapClassStdsByUid[std.studentId]
                      return {
                        ...std,
                        uid: std.studentId,
                        name: info.name,
                        gender: info.gender,
                      }
                    }
                    if (_.has(nonExistInfoMap, std.studentId)) {
                      const info = nonExistInfoMap[std.studentId]
                      return {
                        ...std,
                        uid: std.studentId,
                        name: info.name,
                        gender: info.gender,
                      }
                    }
                    return null // 学生信息不存在，忽略此类学生
                  }).filter().value() as BoardSessionStudentDTOView[]
                  return newStds
                }),
                map((bsStdView) => partitionStudents(bsStdView, interacts)))
      }))
  }

  public getHotRateBySessionId(boardSessionId: number) {
    return this.httpClient.get<TestSessionSummaryDTO>(this.rayApi.apiA(`boardsession/${boardSessionId}/subhotrate`))
  }
}
