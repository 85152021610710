import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { LessonService } from '../../services/lesson.service'
import { CoursePathDTO, LessonDTO } from 'app/lesson/models/lesson';
import { forkJoin } from 'rxjs';
import * as _ from 'lodash'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OedUibmodalService } from 'app/core/oed-uibmodal.service';

@Component({
  selector: 'app-intelligent-create-paper',
  template: require('./intelligent-create-paper.component.html'),
  styles: [require('./intelligent-create-paper.component.scss')]
})
export class IntelligentCreatePaperComponent implements OnInit {
  @Input() public sectionId: number
  @Input() public coursepathId: number
  @Output() public done = new EventEmitter<any>()
  private cp: CoursePathDTO
  private ruanyunMapping: any
  private sections: any
  private itemToAdd: any
  private items = [];

  constructor(
    private lessonService: LessonService,
    private bsModalRef: BsModalRef,
    private bsModalService: BsModalService,
    private oedUibModalService: OedUibmodalService
  ) { }

  ngOnInit() {
    this.loadData();
  }

  private loadData() {
    this.lessonService.getCoursePath(this.coursepathId).toPromise()
    .then(coursepath => {
      this.cp = coursepath
      return this.lessonService.getMapping('ruanyun', coursepath.subjectId, coursepath.versionId, coursepath.gradeId).toPromise()
    })
    .then(mapping => {
      this.ruanyunMapping = mapping
    })
  }

  private onItemsGenerate(items: [any]) {
    this.items = items
    this.itemToAdd = { x: 500, y: 400}
  }

  private onQuesPreview(data: any) {
    if (!this.items || _.isEmpty(this.items)) {

      return;
    }
    this.oedUibModalService.previewIntelligentPaper(this.items, {coursepathId: this.coursepathId, sectionId: this.sectionId})
    .then(res => {
      if (_.get(res, 'import', false)) {
        this.done.emit({done: true})
        this.bsModalRef.hide()
      }
    })
  }


  private closeModal() {
    this.bsModalRef.hide()
  }
}
