import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core'
import * as _ from 'lodash'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { OedNotifyService } from '../../core/oed-notify.service'
import { ErrorContent } from '../../models/ruanyun'
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-report-error',
  template: require('./report-error.component.html'),
  styles: [require('./report-error.component.scss')]
})
export class ReportErrorComponent implements OnInit, OnDestroy {
  @Output() public action = new EventEmitter<ErrorContent>();
  public ruanyunQuestionId: string;

  private errors = [
    { id: 1, text: '题目内容错误', checked: false },
    { id: 2, text: '选项错误', checked: false},
    { id: 3, text: '答案错误', checked: false},
    { id: 4, text: '解析错误', checked: false},
    { id: 5, text: '公式错误', checked: false},
    { id: 6, text: '图片错误', checked: false},
    { id: 7, text: '题型错误', checked: false},
    { id: 8, text: '其他', checked: false}
  ]
  private descripe = ''
  constructor(
    public bsModalRef: BsModalRef,
    public oedNotifyService: OedNotifyService,
    private notifyService: NotificationService
  ) {}

  public ngOnInit() {
    window.addEventListener('keydown', this.consumeKeydown, true);
  }

  public ngOnDestroy() {
    window.removeEventListener('keydown', this.consumeKeydown, true)
  }

  public onSelect(e) {
    e.checked = !e.checked
  }

  public ok() {
    const FaultTypes = _.chain(this.errors).filter('checked').map('id').join(',').value()
    if (_.isEmpty(FaultTypes)) {
      this.notifyService.notify('info', '请勾选错误类型')
      return
    }
    const errorContent: ErrorContent = {
      FaultTypes,
      CommentContent: this.descripe,
      QuestionId: _.toNumber(this.ruanyunQuestionId),
    }
    this.action.emit(errorContent)
    this.bsModalRef.hide()
  }

  public close() {
    this.bsModalRef.hide()
  }
  private consumeKeydown =  (event) => {
    if (event.keyCode === 27) {
      event.stopPropagation()
      event.preventDefault()
      this.bsModalRef.hide()
      return true;
    }
  }

}
