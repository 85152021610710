import * as angular from 'angular';
import 'app2/directives/utils/expandableList.directive';

angular.module('app.directives.cloud.resourceTypeFilter', [
])
  .component('resourceTypeFilter', {
    bindings: {
      onTypeChanged: '&',
      selectedType: '<',
    },
    controller: resourceTypeFilterCtrl,
    template: <string> require('./resourceTypeFilter.html'),
  });

interface ResourceType {
  name: string;
  value: string;
}

function resourceTypeFilterCtrl() {
  this.types = [{
    name: '客观试卷',
    value: 'test',
  }, {
    name: '主观活动',
    value: 'subjectiveTest',
  }, {
    name: '展示资源',
    value: 'resource',
  }];

  this.isSelected = (v: ResourceType) => v.value === this.selectedType;

  this.selectItem = (it: ResourceType, event: any) => {
    event.preventDefault();
    event.stopPropagation();

    if (this.isSelected(it)) {
      return;
    }

    this.onTypeChanged({
      type: it.value,
    });
  };
}
