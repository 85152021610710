import { ruanyunCourses, ruanyunGrades } from 'app2/integration/ruanyun/ruanyunDic'
import { RuanyunCourse, RuanyunGrade } from 'app2/integration/ruanyun/model'
import * as _ from 'lodash'

angular.module('app.prepareCourse2.cloud.core.ruanyunCourseDialog', [
])
.controller('ruanyunCourseDialogCtrl', ruanyunCourseDialogCtrl)

ruanyunCourseDialogCtrl.$inject = ['$uibModalInstance', 'data', '$scope']
function ruanyunCourseDialogCtrl($uibModalInstance: any, data: any, $scope: any) {

  const filterCourses = (gradeId: number) => {
    return _.filter(ruanyunCourses, (c: RuanyunCourse) => c.gradeId === gradeId)
  }

  $scope.onCourseSelected = (course: RuanyunCourse) => {
    $uibModalInstance.close(course)
  }

  $scope.onGradeSelected = (grade: RuanyunGrade) => {
    $scope.courses = filterCourses(grade.id)
  }

  $scope.isSubjectSelected = (course: RuanyunCourse) => {
    return $scope.selectedCourse === course
  }

  $scope.selectedCourse = data.selectedCourse
  $scope.courses = filterCourses(data.selectedCourse.gradeId)
  $scope.grades = ruanyunGrades
  $scope.curTabIdx = _.findIndex(ruanyunGrades, (d) => d.id === data.selectedCourse.gradeId) + 1
  $scope.onClose = () => $uibModalInstance.dismiss('cancel')
}
