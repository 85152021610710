import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReportErrorComponent } from './report-error/report-error.component';

@NgModule({
  declarations: [ReportErrorComponent],
  imports: [
    CommonModule,
    FormsModule,
  ],
  entryComponents: [ReportErrorComponent],
})
export class RuanyunModule { }
