import * as _ from 'lodash'
require('app2/prepareCourse/designTest2.ctrl');
const {Promise} = require('bluebird');
const questionDesigner2 = require('app2/directives/question/questionDesigner2.directive');
const autoScrollTo = require('app2/directives/dom/autoScrollTo.directive');
const testEditMode = require('app2/directives/test/testEditMode.directive');
const talcloudUtils = require('app2/integration/talcloud/talcloudUtils');
const {convertQuesToQaType} = require('app2/integration/questionUtils');
import moment = require('moment');

angular.module('app.prepareCourse2.objTestDesign',[
  'app.directives.question.questionDesigner2',
  'app.directives.dom.autoScrollTo',
  'app.directives.test.testEditMode',
  'app.prepareCourse',
  'app.prepareCourse2.designTest2',
  'app.prepareCourse2.cloud.objectiveTestLibrary'
])
.component('objTestDesign', {
  bindings: {
    currentTest: '<',
    courseId: '<',
    course: '<',
    toggleShareTest: '&',
    cloudTests: '<',
    importFromLibrary2:'&',
    tests: '<',
    updateTest: '&',
    setCurrentTest: '&',
    courseSections: '<',
    treeData: '<',
    sectionIdMap: '<',
    status: '<',
    saveTest: '<'
  },
  controller: objTestDesignCtrl,
  template: <string>require('./objTestDesign.html'),
});

objTestDesignCtrl.$inject = [
  '$scope',
  '$q',
  '$uibModal',
  'notificationService',
  'oedObjectiveTest',
  'oedObjectiveQuestion',
  'oedTestUtils',
  'oedCourseUtils',
  'oedCloudTest',
  'oedConfig',
  'oedCloudQuestion',
  '$window',
  'oedCourse',
  'dialogs',
  'localStorageService'
]

function objTestDesignCtrl(
  $scope,
  $q,
  $uibModal,
  notificationService,
  oedObjectiveTest,
  oedObjectiveQuestion,
  oedTestUtils,
  oedCourseUtils,
  oedCloudTest,
  oedConfig,
  oedCloudQuestion,
  $window,
  oedCourse,
  dialogs,
  localStorageService
) {
  this.firstVisibleQuesIndex = 0;
  this.pageSize = 50;
  this.currentState = {
    loading: null
  };
  this.cloudQuestions = {};
  // 判断顶层窗口是否为iframe(配合桌面端使用)
  this.topWindowIsIframe = $window.top === $window.self;
  this.linkOpenType = this.topWindowIsIframe ? '_blank' : '_self';

  this.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'status')) {
      this.status = this.status || {}
    }
    if (_.has(changesObj, 'currentTest')) {
      this.nullName = false;
      this.isExitName = false;
    }
  };
  const isEmptyResource = (test) => {
    if (!test) {
      return true;
    }

    return _.get(test, 'isEmpty', false) || _.isEmpty(_.get(test, 'questions'));
  };
  this.showShowCloudTestNo = () => {
    if(this.cloudTests) {
      this.currentCloudTestId =  _.get(this.cloudTests[0], 'id');
    }
    return !isEmptyResource(this.currentTest) && _.isNumber(this.currentCloudTestId);
  };

  this.togglePrint = () => {
    if (!this.currentTest) {
      return;
    }
    $window.open('/ray/api/a/course/export/' + this.courseId + '/test/' + this.currentTest.id, _.get($window, '_link_open_type'));
  };

  this.toggleUpload = () => {
    if (!this.currentTest) {
      return;
    }

    let modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/wordUploader.html'),
      controller: 'uploadResCtrl',
      size: 'md',
      windowClass: 'modalCenter',
      resolve: {
        resourceNeeded: () => {
          return {
            url: oedConfig.url('objectivetest/' + this.currentTest.id + '/uploadresource'),
            showRules: () => {
              modalInstance = $uibModal.open({
                template: require('assets/templates/prepareCourse/wordUploaderRulesDialog.html'),
                controller: 'wordUploaderRulesCtrl',
                size: 'md',
                resolve: {
                  test: () => {
                    return 'ab';
                  },
                  data: () => {
                    return {
                      isDaoxuean: false
                    }
                  }
                },
              });

              return modalInstance.result;
            }
          };
        }
      }
    });
    modalInstance.result.then((r) => {
      const relSections = _.map(this.course.relatedSections, (sec) => {
        return _.pick(sec, ['sectionId', 'sectionName']);
      });
      const initIdx = this.currentTest.questions.length;
      if (r.respondData) {
        _.each(r.respondData, (docData) => {
          if (docData.questions && docData.questions.length > 0) {
            const startIdx = this.currentTest.questions.length;

            _.each(docData.questions, (question) => {
              question.relatedSections = relSections;
            });
            this.currentTest.questions = _.concat(this.currentTest.questions, docData.questions);
          }
        });

        if (this.currentTest.questions.length > initIdx) {
          // 有新的题目加入，则保存试卷
          this.saveTest();
        }
      }
    });
  };
  // 保存题目
  this.saveQuestion = (question) => {
    if (question.type !== 'synthetic' && question.type !== 'fill') {
      localStorageService.set('lastQuestionPoint', question.point2);
    } else {
      localStorageService.set('lastQuestionPoint', 1);
    }
    let p;
    if (_.has(question, 'id')) {
      this.status.loading = p = oedObjectiveQuestion.save(question).$promise;
    } else {
      this.status.loading = p = oedObjectiveQuestion.createForTest({
        question,
        testId: this.currentTest.id
      }).$promise.then((q) => {
        return this.loadCloudQuestionByQId(q.id).then(() => q);
      });
    }
    this.currentState.loading = p.then((q) => {
      let original = question;
      if (_.has(question, 'id')) {
        original = _.find(this.currentTest.questions, (q) => {
          return q.id === question.id;
        });
      }
      q.label = question.label;
      if (question.manualSort) {
        q.manualSort = question.manualSort;
      }
      const idx = _.findIndex(this.currentTest.questions, original);
      this.currentTest.questions[idx] = q;
      notificationService.notify('info', '保存成功')
    }).catch((err) => {
      notificationService.notify('error', '保存失败')
    });
    return p;
  };
  this.deleteQuestion = (question) => {
    // 删除新问题
    if (!_.has(question, 'id')) {
      return;
    }

    this.currentState.loading = oedObjectiveQuestion.removeFromTest({
      testId: this.currentTest.id,
      questionId: question.id
    }).$promise.then((res: any) => {
      const idx = _.findIndex(this.currentTest.questions, (q: any) => {
        return q.manualSort
      });
      if (idx !== -1) {
        _.forEach(this.currentTest.questions, (item, index) => {
          if (item.manualSort) {
            this.reSort(index + 1, item.manualSort);
          }
        });
      } else {
        _.forEach(this.currentTest.questions, (item, index) => {
          item.label = index + 1;
        });
      }
    });
  };
  this.loadTestCloudQuestions = (questions) => {
    const ids = _(questions).map('id').filter().value();
    $scope.cloudQuestions = {};

    if (_.isEmpty(ids)) {
      return $q.resolve({});
    }

    return oedCloudQuestion.queryByQuestionIds({ids})
      .$promise.then((cqs) => {
      $scope.cloudQuestions = _.keyBy(cqs, 'resourceId');
      return $scope.cloudQuestions;
    });
  }
  // 批量删除
  this.selectedQuestions = {};
  this.toggleSelect = (item) => {
    if (_.has(this.selectedQuestions, item.id)) {
      this.selectedQuestions = _.omit(this.selectedQuestions, item.id);
    } else {
      this.selectedQuestions[item.id] = true;
    }
  };
  this.isSelected = (item) => {
    return _.has(this.selectedQuestions, item.id);
  };
  this.hasSelected = () => {
    return !_.isEmpty(this.selectedQuestions);
  };
  this.deleteSelected = () => {
    const message = '确定要删除客观题吗（共' + _.size(this.selectedQuestions) + '题）？';
    const dlg = dialogs.confirm('确定删除?', message);
    dlg.result.then(() => {
      this.currentTest.questions = _.filter(this.currentTest.questions, (q) => {
        return !this.isSelected(q);
      });
      this.saveTest();
      this.selectedQuestions = {};
    });
  };
  this.errorForEmpty = false;
  this.onEnterEditQuestion = (question, event) => {
    this.currentQuestion = angular.copy(question);
    this.editingQuestion = question;
  };
  const expTable = new RegExp('<table>');
  const regExpBr = /^(<p><br\/><\/p>)+|(<p><br\/><\/p>)+$/g;
  this.eachTrimEnter = (x) => {
    if (regExpBr.test(x) && x !== '<p><br/></p>') {
      return x.replace(regExpBr, '');
    } else {
      return x;
    }
  };
  this.onLeaveEditQuestion = (question, event) => {
    const idx = _.findIndex(this.currentTest.questions, question);
    this.errorAnchor = (idx: any) => {
      this.scrollAnchor = {
        relativeTo: '.ot-question-list',
        to: idx - this.firstVisibleQuesIndex,
        stamp: _.uniqueId('qscroll_')
      };
    };
    let ret = oedTestUtils.validateQuestion(question);
    if (question.type === 'synthetic') {
      const syntheticTable = [];
      const rets = _.map(question.childQuestions, (q) => {
        const tmpQ = angular.copy(q);
        syntheticTable.push(tmpQ);
        tmpQ.isSub = true;
        const tmp = oedTestUtils.validateQuestion(tmpQ);
        return tmp.valid ? null : tmp;
      });
      const result = _.find(rets, (r) => {
        return r !== null && !r.valid;
      });
      if (!_.isUndefined(result)) {
        ret = result;
      }
      if (_.size(question.childQuestions) === 0) {
        ret = {
          valid: false,
          message: '当前综合题没有子题， 请设置子题后再保存'
        };
      }
      // 综合题子题题干不能插入表格
      const synTableQues = _.find(syntheticTable, (c) => expTable.test(c.question));
      if (synTableQues !== undefined) {
        this.errorForEmpty = true;
        notificationService.notify('error', '综合题子题题干暂不支持插入表格')
        this.errorAnchor(idx);
        return false;
      }
      const synAnswerDetails = _.find(syntheticTable, (c) => expTable.test(c.answerDetails));
      if (synAnswerDetails !== undefined) {
        this.errorForEmpty = true;
        notificationService.notify('error', '综合题子题解析暂不支持插入表格')
        this.errorAnchor(idx);
        return false;
      }
      // 综合题子题选项
      const syntheticChoices = [];
      const synChoicesContent = _.find(syntheticTable, (x) => {
        if (x.type === 'singlechoice' || x.type === 'multichoice' ||
          x.type === 'singlevote' || x.type === 'multivote') {
          _.each(x.choices, (c: any) => {
            syntheticChoices.push(c.content);
          });
        }
        return expTable.test(syntheticChoices.join());
      });
      if (synChoicesContent !== undefined) {
        this.errorForEmpty = true;
        notificationService.notify('error', '综合题子题选项中暂不支持插入表格')
        this.errorAnchor(idx);
        return false;
      }
      // 综合题leftOpts、rightOpts
      const syntheticLeftOpts = [];
      const syntheticRightOpts = [];
      const synLeftOpts = _.find(syntheticTable, (x) => {
        if (x.type === 'connect') {
          _.each(x.leftOpts, (c) => {
            syntheticLeftOpts.push(c.content);
          });
        }
        return expTable.test(syntheticLeftOpts.join());
      });
      const synRightOpts = _.find(syntheticTable, (x) => {
        if (x.type === 'connect') {
          _.each(x.rightOpts, (c) => {
            syntheticRightOpts.push(c.content);
          });
        }
        return expTable.test(syntheticRightOpts.join());
      });
      if (synLeftOpts !== undefined || synRightOpts !== undefined) {
        this.errorForEmpty = true;
        notificationService.notify('error', '综合题匹配题选项中暂不支持插入表格')
        this.errorAnchor(idx);
        return false;
      }
      // 综合题子题题干、解析、选项去除多余回车
      _.forEach(question.childQuestions, (c) => {
        c.question = this.eachTrimEnter(c.question);
        c.answerDetails = this.eachTrimEnter(c.answerDetails);
        if (c.type === 'singlechoice' || c.type === 'multichoice' ||
          c.type === 'singlevote' || c.type === 'multivote') {
          _.forEach(c.choices, (opt) => {
            opt.content = this.eachTrimEnter(opt.content);
          });
        }
        if (c.type === 'connect') {
          _.forEach(c.leftOpts, (opt) => {
            opt.content = this.eachTrimEnter(opt.content);
          });
          _.forEach(c.rightOpts, (opt) => {
            opt.content = this.eachTrimEnter(opt.content);
          });
        }
      });
    }
    if (ret && !ret.valid) {
      this.errorForEmpty = true;
      notificationService.notify('error', ret.message)
      this.errorAnchor(idx);
      // $scope.$apply();
      return false;
    } else {
      if (expTable.test(question.question)) {
        this.errorForEmpty = true;
        notificationService.notify('error', '题干中暂不支持插入表格')
        this.errorAnchor(idx);
        return false;
      }
      if (expTable.test(question.answerDetails)) {
        this.errorForEmpty = true;
        notificationService.notify('error', '解析中暂不支持插入表格')
        this.errorAnchor(idx);
        return false;
      }
      // 去除题干和解析中多余的回车
      question.question = this.eachTrimEnter(question.question);
      question.answerDetails = this.eachTrimEnter(question.answerDetails);
      // 单选、多选、投票中的choices出入表格的处理
      if (question.type === 'singlechoice' || question.type === 'multichoice' ||
        question.type === 'singlevote' || question.type === 'multivote') {
        const tableQues = _.find(question.choices, (c) => expTable.test(c.content));
        if (tableQues !== undefined) {
          this.errorForEmpty = true;
          notificationService.notify('error', '选项中暂不支持插入表格')
          this.errorAnchor(idx);
          return false;
        }
        // 去除选择题选项中多余的回车
        _.forEach(question.choices, (c) => {
          c.content = this.eachTrimEnter(c.content);
        });
      }
      // 当表格的leftOpts或rightOpts中粘贴了表格时候的处理
      if (question.type === 'connect') {
        const connectLeftOpts = _.find(question.leftOpts, (c) => expTable.test(c.content));
        const connectRightOpts = _.find(question.rightOpts, (c) => expTable.test(c.content));
        if (connectLeftOpts !== undefined || connectRightOpts !== undefined) {
          this.errorForEmpty = true;
          notificationService.notify('error', '匹配题中暂不支持插入表格')
          this.errorAnchor(idx);
          return false;
        }
        // 去除leftOpt和rightOpt多余的回车
        _.forEach(question.leftOpts, (c) => {
          c.content = this.eachTrimEnter(c.content);
        });
        _.forEach(question.rightOpts, (c) => {
          c.content = this.eachTrimEnter(c.content);
        });
      }
    }
    //
    // 当前问题已经修改时要保存测试
    if (!angular.equals(this.currentQuestion, question)) {
      this.saveQuestion(question);
    }

    this.currentQuestion = null;
    this.editingQuestion = null;

    return true;
  };

  this.backCurrentQuestion = () => {
    if (this.errorForEmpty) {
      this.errorForEmpty = false;
    }
  };

  this.onDeleteQuestionClicked = (question) => {
    const dlg = dialogs.confirm('确定删除？', '确定删除此题吗？');
    dlg.result.then((btn) => {
      const idx = _.findIndex(this.currentTest.questions, question);
      this.currentTest.questions.splice(idx, 1);
      // question.manualSort = 0;
      this.deleteQuestion(question);
      if (question === this.currentQuestion) {
        this.currentQuestion = null;
      }
      if (this.isSelected(question)) {
        this.selectedQuestions = _.omit(this.selectedQuestions, question.id);
      }

      // 如果删除的题目是最后一题，且该题为最后一页的唯一一道题，则切到前一页
      if (idx === _.size(this.currentTest.questions)) {
        this.switchToPrevPage();
      }
    }, (btn) => {});
  };

  this.canMoveUp = (question) => {
    // 刚刚添加，尚未保存的题目，禁止上移
    if (!_.has(question, 'id')) {
      return false;
    }
    const idx = _.findIndex(this.currentTest.questions, (q: any) => {
      return q.id === question.id;
    });
    return idx > 0;
  };

  this.canMoveDown = (question) => {
    const idx = _.findIndex(this.currentTest.questions, (q: any) => {
      return q.id === question.id;
    });
    // 下面的一题不能是刚刚添加且尚未保存的题目
    return idx >= 0 && idx < (_.size(this.currentTest.questions) - 1) &&
      _.has(_.get(this.currentTest.questions, idx + 1, {}), 'id');
  };

  this.onMoveUpClicked = (question) => {
    const curIdx = _.findIndex(this.currentTest.questions, (q: any) => {
      return q.id === question.id;
    });
    if (curIdx <= 0) {
      return;
    }

    const temp = this.currentTest.questions[curIdx - 1];
    this.currentTest.questions[curIdx - 1] = question;
    this.currentTest.questions[curIdx] = temp;

    if (curIdx === this.firstVisibleQuesIndex) {
      this.switchToPrevPage();
    }

    this.scrollAnchor = {
      relativeTo: '.ot-question-list',
      to: curIdx - 1 - this.firstVisibleQuesIndex,
      stamp: _.uniqueId('qscroll_')
    };

    this.saveTest();
  };

  this.onMoveDownClicked = (question) => {
    const curIdx = _.findIndex(this.currentTest.questions, (q: any) => { return q.id === question.id;});
    if (curIdx < 0 || curIdx >= (_.size(this.currentTest.questions) - 1)) {
      return;
    }

    const temp = this.currentTest.questions[curIdx + 1];
    this.currentTest.questions[curIdx + 1] = question;
    this.currentTest.questions[curIdx] = temp;

    if (curIdx === (this.firstVisibleQuesIndex + this.pageSize - 1)) {
      this.switchToNextPage();
    }

    this.scrollAnchor = {
      relativeTo: '.ot-question-list',
      to: curIdx + 1 - this.firstVisibleQuesIndex,
      stamp: _.uniqueId('qscroll_')
    };

    this.saveTest();
  };

  // this.firstVisibleQuesIndex = 0;
  // this.pageSize = 50;
  // this.showPaging = () => {
  //   return _.size(this.currentTest.questions) > this.pageSize;
  // };

  this.showAddQues = () => {
    return (this.firstVisibleQuesIndex + this.pageSize) >= _.size(this.currentTest.questions);
  };

  this.isPrevPageBtnEnabled = () => {
    return this.firstVisibleQuesIndex > 0 && this.firstVisibleQuesIndex >= this.pageSize;
  };

  this.isNextPageBtnEnabled = () => {
    return (this.firstVisibleQuesIndex + this.pageSize) < _.size(this.currentTest.questions);
  };

  this.getPrevPageBtnClass = () => {
    return this.isPrevPageBtnEnabled() ?
      'cursor-pointer ot-page-navigator-btn-enabled' :
      'ot-page-navigator-btn-disabled';
  };

  this.getNextPageBtnClass = () => {
    return this.isNextPageBtnEnabled() ?
      'cursor-pointer ot-page-navigator-btn-enabled' :
      'ot-page-navigator-btn-disabled';
  };

  this.switchToPrevPage = () => {
    if (!this.isPrevPageBtnEnabled()) {
      return;
    }
    this.firstVisibleQuesIndex -= this.pageSize;
  };

  this.switchToNextPage = () => {
    if (!this.isNextPageBtnEnabled()) {
      return;
    }
    this.firstVisibleQuesIndex += this.pageSize;
  };

  this.switchToLastPage = () => {
    const quesCount = _.size(this.currentTest.questions);
    const prevPageCount = _.floor((quesCount - 1) / this.pageSize);
    console.log('this.switchToLastPage111', this.firstVisibleQuesIndex)
    this.firstVisibleQuesIndex = prevPageCount * this.pageSize;
    console.log('this.switchToLastPage111', this.firstVisibleQuesIndex)
  };

  this.getQuesIndex = (question) => {
    // return _.findIndex(this.currentTest.questions, (q: any) => {
    //   return q.id === question.id;
    // });
    return question.label;
  };

  this.isNewQuestion = (question) => {
    return !_.get(question, 'id');
  };

  this.addQuestion = () => {
    const newQ = oedCourseUtils.defaultQuestion(this.course.relatedSections);
    let maxSort = _.get(_.maxBy(this.currentTest.questions, 'sort'), 'sort');
    if (_.isUndefined(maxSort) || _.isNaN(maxSort) || !_.isNumber(maxSort)) {
      maxSort = 0;
    }

    maxSort++;
    if (!_.size(this.currentTest.questions)) {
      newQ.label = 1;
    } else {
      newQ.label = this.currentTest.questions[_.size(this.currentTest.questions)-1].label + 1;
    }
    newQ.sort = _.max([maxSort, _.size(this.currentTest.questions)]);
    this.editingQuestion = newQ;
    this.currentTest.questions.push(newQ);

    this.switchToLastPage();
    this.scrollAnchor = {
      relativeTo: '.ot-question-list',
      to: _.size(this.currentTest.questions) - 1 - this.firstVisibleQuesIndex,
      stamp: _.uniqueId('qscroll_')
    };
  };

  const showCannotSortMessage = _.debounce(() => {
    notificationService.notify('info', '当前编辑的问题无效, 请编辑后再排序')
  }, 500);

  function getQuestionIds(questions) {
    return _.map(questions, (q) => {
      return _.get(q, 'id', -1);
    });
  }

  this.onEditQuestionNumber = (questio) => {
    // 客观试卷题号
    const that = this;
    const dlg = $uibModal.open({
      template: require('app2/directives/question/editQuestionNumber.html'),
      controller: 'editQuestionNumberCtrl',
      size: 'sm',
      resolve: {
        data: () => {
          return {
            question: questio,
            testId: that.currentTest.id,
          };
        }
      }
    });

    dlg.result.then((res) => {
      if (res.manualSort) {
        questio.manualSort = questio.label = _.parseInt(res.manualSort);
      } else {
        const currentIndex = _.findIndex(that.currentTest.questions, (q: any) => {
          return q.id === questio.id;
        });
        questio.manualSort = 0;
        questio.label = currentIndex > 0 ? that.currentTest.questions[currentIndex - 1].label + 1 : 1;
        this.reSort(currentIndex + 1, questio.label);
      }

      if (res.type === 'new') {
        return;
      }
      _.forEach(that.currentTest.questions, (item, index) => {
        if (item.manualSort) {
          that.reSort(index + 1, item.manualSort);
        }
      });
    });
  };
  this.reSort = function(index, sort) {
    const arr = this.currentTest.questions.slice(index);
    if (_.size(arr)) {
      _.forEach(arr, (item, i) => {
        if (!item.manualSort) {
          item.label = sort + i + 1;
        }
      });
    }
  }

  this.isAllQuestionValid = (questions) => {
    const inValid = _.findIndex(questions, (ques: any) => {
      if (ques.type === 'synthetic') {
        return this.isAllQuestionValid(ques.childQuestions);
      }
      return !oedTestUtils.validateQuestion(ques).valid;
    });
    return inValid < 0;
  };

  function downloadTestsById(cloudTestIds) {
    if (_.isEmpty(cloudTestIds)) {
      return Promise.resolve();
    }
    return $q.all(_.map(cloudTestIds, (tId) => {
      return oedObjectiveTest.getDownloadByCloudRes({
        res_id: tId
      }).$promise;
    }));
  }
  this.switchMode = (m) => {
    if (m === 'question') {
      return $q.when(this.currentState.loading).then(() => {
        oedTestUtils.switchEditMode(this.testId, m);
      });
    }

    return true;
  };

  this.showQuesitonError = (q) => {
    return this.errorForEmpty && this.editingQuestion === q;
  };

  this.loadCloudQuestionByQId = (qId) => {
    return oedCloudQuestion.getByQuestionId({
      id: qId
    }).$promise.then((cq) => {
      this.cloudQuestions[cq.resourceId] = cq;
    });
  };

  // TODO: convert types to an array to keep it's order
  this.types = {
    'singlechoice': '单选',
    'multichoice': '多选',
    'yesorno': '判断',
    'fill': '填空',
    'connect': '匹配',
    'vote': '投票',
    'synthetic': '综合',
    'qa': '问答'
  };

  this.getCountOfType = (type) => {
    if (!this.currentTest) {
      return 0;
    }

    if (type === 'vote') {
      const voteSize = _.sumBy(this.currentTest.questions, (q: any) => {
        return (q.type === 'singlevote' || q.type === 'multivote') ? 1 : 0;
      });

      return voteSize;
    } else {
      return _.size(_.filter(this.currentTest.questions, (q: any) => {
        return q.type === type;
      }));
    }
  };

  this.getScoreOfType = (type) => {
    if (!this.currentTest) {
      return 0;
    }

    if (type === 'vote') {
      const singlevote_score =  _.reduce(_.filter(this.currentTest.questions, (q) => {
        return q.type === 'singlevote';
      }), (acc, q) => {
        return acc + (q.point2 || 0);
      }, 0);

      const multivote_score =  _.reduce(_.filter(this.currentTest.questions, (q)=> {
        return q.type === 'multivote';
      }), (acc, q) => {
        return acc + (q.point2 || 0);
      }, 0);

      return singlevote_score + multivote_score;
    } else {
      return _.reduce(_.filter(this.currentTest.questions, (q) => {
        return q.type === type;
      }), (acc, q) => {
        return acc + (q.point2 || 0);
      }, 0);
    }
  };

  this.getTotalScore = () => {
    if (!this.currentTest) {
      return 0;
    }
    return _.reduce(this.currentTest.questions, (acc, q) => {
      return acc + (q.point2 || 0);
    }, 0);
  };

  this.getTotalCount = () => {
    if (!this.currentTest) {
      return 0;
    }
    return _.size(this.currentTest.questions);
  };

  this.toggleSt = () => {
    if (this.stShow) {
      this.stShow = false;
    } else {
      this.stShow = true;
    }
  };
  this.getStWidth = () => {
    return {
      'width': this.stShow
    };
  };
  this.addGame = () => {
    if (_.isEmpty(this.currentTest.questions)) {
      notificationService.notify('info', '请先添加题目')
      return;
    }
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addTestPaperGame.html'),
      controller: 'addTestPaperGameCtrl',
      size: 'md',
      windowClass: 'oedmodal',
      resolve: {
        curTest: () => {
          return this.currentTest;
        }
      }
    });

    return modalInstance.result.then((r) => {
      if (!_.isEmpty(r)) {
        _.set(this.currentTest, 'writingPadBody', _.get(r, 'writingPadBody'));
        this.saveTest();
      }
    });
  };
  this.uploadFileTestPaper = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    // var isCurrent = test.id == this.currentTest.id;
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addTestPaperUnderPic.html'),
      controller: 'addTestPaperUnderPicCtrl',
      size: 'md',
      windowClass: 'oedmodal',
      resolve: {
        curTest: () => {
          return this.currentTest;
        }
      }
    });

    return modalInstance.result.then((r) => {
      if (!_.isEmpty(r)) {
        _.set(this.currentTest, 'writingPadBody', _.get(r, 'writingPadBody'));
        this.saveTest();
      }
    });
  };

  this.convertAllQuesToQaType = ($event) => {
    $event.stopPropagation();
    $event.preventDefault();

    this.status.loading = Promise.resolve(doConvert())
      .then(this.saveTest())
  };

  this.editorConfig = {
    serverUrl: oedConfig.url('ceditor/Test/') + 0
  };

  $scope.$watch('$ctrl.showInput',function(newVal, oldVal){
    if (newVal) {
      setTimeout(function () {
        document.getElementById('currentName').focus();
      })
    }
  });

 // 编辑试卷名称时限定长度
  this.activeName = function (e) {
    this.nullName = false;
    this.isExitName = false;
    if (e.target.value.length > 40) {
      e.target.value = e.target.value.substr(0, 30);
    }
  };

  this.saveTitle = (test) => {
    this.showInput = false;
    if (!this.currentTest.name) {
      this.nullName = true
      return;
    } else {
      this.nullName = false
    }
    test.name = test.name.substr(0, 30);
    this.exsitingNames = _(this.tests).filter((t) => {
      return t.id != test.id;
    }).map('name').value();
    this.isExitName = _.some(this.exsitingNames, (item) => {
      return item === test.name
    });
    // 如果不存在相同的试卷名称则保存试卷，否则恢复原来的试卷名称
    if(!this.isExitName) {
      _.forEach(this.tests, (item: any) => {
        if(item.id === test.id) {
          item.name = test.name;
        }
      });
      this.saveTest();
      this.updateTest({t:false, test: this.tests});
    } else {
      _.forEach(this.tests, (item: any) => {
        if(item.id === test.id) {
          test.name = item.name;
        }
      });
    }
  };

  this.showEditInput = () => {
    this.showInput = true;
    this.isExitName = false;
    this.nullName = false;
  }

  // 删除试卷
  this.deleteTest = () => {
    const dlg = dialogs.confirm('确定删除?', '确定要删除客观试卷: "' + this.currentTest.name + '"吗');
    dlg.result.then((btn) => {
      this.currentState.loading = $q((resolve, reject) =>{
        this.currentTest.$delete(() => {
          if (_.size(this.tests) > 1) {
            this.tests = _.filter(this.tests, (item) => {
              return item.id !== this.currentTest.id;
            });
            this.setCurrentTest({t: this.tests[0], test: this.tests});
          } else {
            this.tests = _.filter(this.tests, (item) => {
              return item.id !== this.currentTest.id;
            });
            new oedObjectiveTest({
              name: '客观试卷1',
              courseId: this.courseId,
              sort: 1
            }).$save().then((t) => {
              const newTest = t;
              this.tests.push(newTest);
              this.setCurrentTest({t: this.tests[0], test: this.tests});
            });
          }
          resolve();
        }, function() {
          reject();
        });
      });
    }, function(btn) {});
  };
  function doConvert() {
    this.currentTest.questions = _.map(this.currentTest.questions, convertQuesToQaType)
  }
}
