import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NotificationService} from 'app/shared/notification/notification.service';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-train-qaeditor',
  template: require('./train-qaeditor.component.html'),
  styles: [require('./train-qaeditor.component.scss')]
})
export class TrainQAEditorComponent implements OnInit {
  @Input() public itemData
  @Input() public itemType
  @Input() public editType
  @Output() public action = new EventEmitter<any>()

  private title
  private dataClone
  constructor(public bsModalRef: BsModalRef,
              private notifyService: NotificationService,
              private onlineTrainService: OnlineTrainService) { }

  ngOnInit() {
    this.setTitle()
    this.setDataClone()
  }

  public close() {
    this.bsModalRef.hide()
  }

  public ok() {
    if (_.isEmpty(this.dataClone.question)) {
      this.notifyService.notify('info', '问题不能为空')
      return
    }

    if (_.isEmpty(this.dataClone.answer)) {
      this.notifyService.notify('info', '回答不能为空')
      return
    }

    this.action.emit(this.dataClone)
    this.bsModalRef.hide()
  }

  private setTitle() {
    this.title = '新增Q&A'
  }

  private setDataClone() {
    this.dataClone = _.clone(this.itemData)
  }
}
