import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { AppStateService } from 'app/core/app-state.service';
import { StateService } from '@uirouter/core';
import * as _ from 'lodash';
import { ExerciseDTO } from '../../manage';
import { OedExerciseService } from '../oed-exercise.service'
import { LegacyAppService } from 'app/core/legacy-app.service';

@Component({
  selector: 'app-exercise-book-item',
  template: require('./exercise-book-item.component.html'),
  styles: [require('./exercise-book-item.component.scss')]
})
export class ExerciseBookItemComponent implements OnInit {
  user: any;
  exerciseBookAll: ExerciseDTO[];
  time: number;
  selectExerciseBook: any = {};
  localStorage: any ;
  initData = true;
  @Input() selectLevelId: any;
  @Input() selectSubjectId: any;
  @Input() selectVersionId: any;
  
  constructor(private oedDialogService: OedDialogService,
    private userInfo: AppStateService,
    private oedExerciseService: OedExerciseService,
    private stateService: StateService,
    private legacyApp: LegacyAppService,) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['selectLevelId'] !== undefined) {
        this.selectLevelId = changes['selectLevelId'].currentValue;
    } else if (changes['selectSubjectId'] !== undefined) {
        this.selectSubjectId = changes['selectSubjectId'].currentValue;
    } else if (changes['selectVersionId'] !== undefined) {
        this.selectVersionId = changes['selectVersionId'].currentValue;
    }
    if(this.selectLevelId && this.selectSubjectId && this.selectVersionId) {
      this.getCurrentExerciseBook();
      this.selectExerciseBook = {};
    }
    if(this.initData) {
      this.localStorage = this.legacyApp.getLocalStorage();
      this.selectExerciseBook = this.localStorage.get('ex_current_exercise') || {};
      this.initData = false;
    }
  }
  
  ngOnInit() {
    this.time =  new Date('2020-01-1').getTime();
    this.userInfo.then((info) => {
      this.user = info;
    });
  }

  public getCurrentExerciseBook() {
    this.oedExerciseService.getExerciseBook(
      this.selectSubjectId, this.selectVersionId, this.selectLevelId
    ).subscribe((data) => {
      this.exerciseBookAll = _.orderBy(data, 'createTime', 'desc');
    })
  }

  public deleteExerciseBook(item) {
    this.oedDialogService.openModalWithComponent2('ExInfoDialogComponent', {
      title: '确认',
      info: '确认是否删除当前习题册?',
      isConfirm: true,
      btnText: '确认',
    }, {
       class: 'ex-dialog-common' 
    }).subscribe(data => {
      if(data) {
        this.oedExerciseService.deleteExeriseBook(item.id)
        .subscribe(() => {
          const resultIndex = _.findIndex(this.exerciseBookAll, ex => ex.id === item.id);
          if(resultIndex !== -1) {
            this.exerciseBookAll.splice(resultIndex, 1);
            this.selectExerciseBook = {};
          }
        })
      }
    })
  }

  public importJson(item) {
    const href = this.stateService.href('importJson', {
      exerciseBookId: item.id,
      exerciseBookName: item.name,
      subjectId: this.selectSubjectId,
      levelId: this.selectLevelId,
    })
    window.open(href, '_blank');
  }

  public isSystemAdmin() {
    return this.user && _.includes(this.user.roles, 'system');
  }

  public openCreatBookModal() {
    this.oedDialogService.openModalWithComponent2('CreatExerciseBookDialogComponent', {
      subjectId: this.selectSubjectId, versionId: this.selectVersionId, levelId: this.selectLevelId,
    }, {
      class: 'modal-sm creatExeciseBook',
    }).subscribe((exerciseBook) => {
      this.exerciseBookAll.unshift(exerciseBook);
    })
  }

  public onSelectBook(item) {
    this.selectExerciseBook = item;
    this.localStorage.set('ex_current_exercise', item);
  }

}
