import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { RayApiService } from 'app/core/ray-api.service'
import { LoginDTO, LoginResultDTO, UserData, UserDTO, MyInfoDTO } from 'app/models/user.d.ts'

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService) {
  }

  public currentUserInfo(): Observable<UserDTO> {
    return this.httpClient.get<UserDTO>(this.rayApi.apiA('login/userinfo'))
  }

  public login(data: LoginDTO): Observable<LoginResultDTO> {
    return this.httpClient.post<LoginResultDTO>(this.rayApi.apiA('login'), data)
  }

  public logout(): Observable<any> {
    return this.httpClient.get<any>(this.rayApi.apiA('login/logout'));
  }

  public getByUid(uid: number) {
    return this.httpClient.get<MyInfoDTO>(this.rayApi.apiB(`userinfo/${uid}`))
  }
}
