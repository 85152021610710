import { Injectable } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import * as _ from 'lodash';
import { CreateCourseFromModuleDTO, ImportedTempCourseDTO, LessonDTO } from '../models/lesson';
import { LessonMessageService } from './lesson-message.service';
import { LessonService } from './lesson.service';
import { NotificationService } from 'app/shared/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class RecomendCourseService {

  constructor(
    private lessonService: LessonService,
    private lessonMessageService: LessonMessageService,
    private oedDialogService: OedDialogService,
    private notifyService: NotificationService
  ) { }

  public openConfirmModal(
    selectedCourse: LessonDTO, coursepathId: number,
    sectionId: number, mineCourses: LessonDTO[],
    title = '确定保存?',
    message = '确定要把此推荐课程保存为我的课程?'
    ) {
    this.oedDialogService.openModalWithComponent('OedConfirmComponent', {
      title,
      message,
    }, () => {
      this.doSave(selectedCourse, coursepathId, sectionId, mineCourses)
    }, {
      class: 'modal-sm',
    })
  }

  public doSave(
    selectedCourse: LessonDTO, coursepathId: number,
    sectionId: number, mineCourses: LessonDTO[]) {
    if (_.toNumber(selectedCourse.id) !== -1) {
      this.doSaveRealTmpCourse(selectedCourse.id, coursepathId, sectionId)
    } else {
      this.createCourseWithModule(sectionId, coursepathId, mineCourses, true)
    }
  }

  public guessCourseName(mineCourses: LessonDTO[], isCanStar?: boolean) {
    const prefix = isCanStar ? '推荐课程' : '我的课程'
    let maxSort = _.chain(mineCourses)
    .map('courseName')
    .filter((n: string) => _.includes(n, prefix))
    .map((n) => _.chain(n).replace(prefix, '').toNumber().value())
    .filter().max()
    .value()
    if (!maxSort) {
      maxSort = 0
    }
    maxSort ++
    return prefix + maxSort
  }

  public createCourseWithModule(sectionId: number, coursepathId: number, mineCourses: LessonDTO[], isSave?: boolean, isCanStar?: boolean) {
    this.lessonMessageService.sendLoadingAction(true)
    const dummyCourse = this.lessonService.createDummyTmpCourse(sectionId, this.guessCourseName(mineCourses, isCanStar))
    const dummyModules = this.lessonService.createDummyModules()
    const createDTO: CreateCourseFromModuleDTO = {
      coursePathId: coursepathId,
      courseName: dummyCourse.courseName,
      relatedSections: dummyCourse.relatedSections,
      modules: dummyModules,
    }
    this.lessonService.createCourseWithModule(createDTO).subscribe((data) => {
      if (isSave) {
        const createData = {
          ...data,
        }
        this.lessonMessageService.sendAddCourseAction(createData)
      } else {
        const createData = {
          ...data,
          $$isCreateCourse: true,
        }
        this.lessonMessageService.sendAddCourseAction(createData)
      }
    }, (error) => {
      console.log(error)
      this.notifyService.notify('info', '新建课程失败')
    },
    () => this.lessonMessageService.sendLoadingAction(false))
  }

  public doSaveRealTmpCourse(existingCourseId: number, coursepathId: number, sectionId: number) {
    this.lessonMessageService.sendLoadingAction(true)
    const imprtTempCourseDTO: ImportedTempCourseDTO = {
      existingCourseId,
      coursepathId,
      relatedSections: [{
        sectionId,
      }],
    }
    return this.lessonService.saveTmpCourse(imprtTempCourseDTO).subscribe((data: LessonDTO) => {
      this.lessonMessageService.sendAddCourseAction(data)
    }, (error) => {
      console.log(error)
      this.notifyService.notify('info', '保存推荐课程失败')
    },
    () => this.lessonMessageService.sendLoadingAction(false))
  }
}
