require('app2/directives/question/questionDesigner2.directive');
require('assets/app/controllers/examination/examPartsEditor/addQuestion.scss');

angular.module('app.examination.editObjectTestPaper.examPartsEditor.addQuestion', [])
.component('addQuestion', {
  bindings: {
    question: '<',
    editingQuestion: '=',
    onQuestionUpdate: '&',
    part: '<',
    index: '<',
    // onSaveGroup: '&',
    // createTasksFromLibrary: '&'
  },
  controller: addQuestionCtrl,
  template: <string>require('./addQuestion.html'),
});

addQuestionCtrl.$inject = ['dialogs', 'oedObjectiveTest', '$q', 'oedCourseUtils',
'notificationService', 'oedTestUtils', '$location', '$anchorScroll', '$scope',
'oedExamination', 'localStorageService', 'oedObjectiveQuestion', 'oedCloudQuestion']
function addQuestionCtrl(dialogs, oedObjectiveTest, $q, oedCourseUtils,
                         notificationService, oedTestUtils, $location, $anchorScroll, $scope,
                         oedExamination, localStorageService, oedObjectiveQuestion, oedCloudQuestion) {
  this.$onInit = () => {
  };
  const expTable = new RegExp('<table>');
  const regExpBr = /^(<p><br\/><\/p>)+|(<p><br\/><\/p>)+$/g;
  $scope.eachTrimEnter = (x) => {
    if (regExpBr.test(x) && x !== '<p><br/></p>') {
      return x.replace(regExpBr, '');
    } else {
      return x;
    }
  };
  this.cloudQuestions = {};

  // 滚动到答题区域
  function scrollToQuestion(groupId, quesSort) {
    $location.hash(`question_${groupId}_${quesSort}`);
    $anchorScroll();
  }
  // 进入答题区域
  this.onEnterEditQuestion = (question) => {
    this.currentQuestion = angular.copy(question);
    this.editingQuestion = question;
  };
  // 判断是否新增一题，由此判断是否离开答题区域
  this.isNewQuestion = (question) => {
    return !_.get(question, 'id');
  };
  // 判断是否出错显示， 如果出错则显示红色边框
  this.showQuesitonError = (q) => {
    return this.errorForEmpty && this.editingQuestion === q;
  };
  // 点击编辑区域
  this.backCurrentQuestion = () => {
    if (this.errorForEmpty) {
      this.errorForEmpty = false;
    }
  };
  // 离开答题区域
  this.onLeaveEditQuestion = (group, question) => {
    const idx = _.findIndex(group.questionDetails, (item: any) => {
      return item.id === question.id;
    });
    const ret = oedTestUtils.validateQuestion(question, false);
    if (ret && !ret.valid) {
      this.errorForEmpty = true;
      notificationService.notify('error', ret.message)
      scrollToQuestion(group.sort, question.sort);
      $scope.$apply();
      return false;
    } else {
      if (expTable.test(question.question)) {
        this.errorForEmpty = true;
        notificationService.notify('error', '题干中暂不支持插入表格')
        scrollToQuestion(group.sort, question.sort);
        return false;
      }
      if (expTable.test(question.answerDetails)) {
        this.errorForEmpty = true;
        notificationService.notify('error', '解析中暂不支持插入表格')
        scrollToQuestion(group.sort, question.sort);
        return false;
      }
      // 去除题干和解析中多余的回车
      question.question = $scope.eachTrimEnter(question.question);
      question.answerDetails = $scope.eachTrimEnter(question.answerDetails);
      // 单选、多选、投票中的choices出入表格的处理
      if (question.type === 'singlechoice' || question.type === 'multichoice' ||
        question.type === 'singlevote' || question.type === 'multivote') {
        const tableQues = _.find(question.choices, (c) => expTable.test(c.content));
        if (tableQues !== undefined) {
          this.errorForEmpty = true;
          notificationService.notify('error', '选项中暂不支持插入表格')
          scrollToQuestion(group.sort, question.sort);
          return false;
        }
        // 去除选择题选项中多余的回车
        _.forEach(question.choices, function (c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
      }
      // 当表格的leftOpts或rightOpts中粘贴了表格时候的处理
      if (question.type === 'connect') {
        const connectLeftOpts = _.find(question.leftOpts, (c) => expTable.test(c.content));
        const connectRightOpts = _.find(question.rightOpts, (c) => expTable.test(c.content));
        if (connectLeftOpts !== undefined || connectRightOpts !== undefined) {
          this.errorForEmpty = true;
          notificationService.notify('error', '匹配题中暂不支持插入表格')
          scrollToQuestion(group.sort, question.sort);
          return false;
        }
        // 去除leftOpt和rightOpt多余的回车
        _.forEach(question.leftOpts, function (c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
        _.forEach(question.rightOpts, function (c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
      }
    }
    //
    // 当前问题已经修改时要保存测试
    if (!angular.equals($scope.currentQuestion, question)) {
      this.saveQuestion(group, question);
    }

    $scope.currentQuestion = null;
    $scope.editingQuestion = null;

    return true;
  };
  this.saveQuestion = (part, question) => {
    $scope.loading = oedExamination.createExamQuestion(
      {id: part.id},
      [{
        question,
        sort: question.sort,
        relatedOutlines: []
      }]
    ).$promise.then((res) => {
      this.onQuestionUpdate({ques: null, part, isDelete: false});
      _.map(this.part.questionDetails, (item: any) => {
        if (!item.question.id) {
          item.question.id = res[_.size(res) - 1].questionId;
          return item;
        }
        return item;
      });
      notificationService.notify('info', '保存成功')
    }).catch((e) => {
      console.log('saveErroe', e);
      notificationService.notify('error', '保存失败')
    });
    return $scope.loading;
  };
  // 删除一道题
  this.onDeleteQuestionClicked = (test, question) => {
    const dlg = dialogs.confirm('确定删除？', '确定删除此题吗？');
    dlg.result.then((btn) => {
      if (!question.id) {
        test.questionDetails.splice(_.size(test.questionDetails) - 1, 1);
      } else {
        const idx = _.findIndex(test.questionDetails, (item: any) => {
          return item.question.id === question.id;
        });
        if (idx < 0) {
          notificationService.notify('error', '删除失败')
        } else {
          oedExamination.deleteExamQuestion({
            id: test.id,
            questionId: question.id
          }).$promise.then(() => {
            test.questionDetails.splice(idx, 1);
            this.onQuestionUpdate({ques: null, isDelete: true, part: test});
            notificationService.notify('info', '删除成功')
          });
        }
      }
      if (question === this.currentQuestion) {
        this.currentQuestion = null;
      }
    });
  };
  this.canMoveDown = (group, question) => {
    if (!_.has(question, 'id')) {
      return false;
    }
    const idx = _.findIndex(group.questionDetails, (q: any) => {
      return q.question.id === question.id;
    });
    // 下面的一题不能是刚刚添加且尚未保存的题目
    return idx >= 0 && idx < (_.size(group.questionDetails) - 1) &&
    _.has(_.get(group.questionDetails, idx + 1, {}), 'question.id');
  };
  this.canMoveUp = (group, question) =>{
    // 刚刚添加，尚未保存的题目，禁止上移
    if (!_.has(question, 'id')) {
      return false;
    }
    const idx = _.findIndex(group.questionDetails, (q: any) => {
      return q.question.id === question.id;
    });
    return idx > 0;
  };
  this.onMoveUpClicked = (group, question) => {
    const curIdx = _.findIndex(group.questionDetails, (q: any) => {
      return q.question.id  === question.id;
    });
    if (curIdx <= 0) {
      return;
    }
    const preId = group.questionDetails[curIdx - 1].question.id;
    const preSort = group.questionDetails[curIdx - 1].question.sort;
    const tempSort = preSort;
    group.questionDetails[curIdx - 1].question.sort = question.sort;
    group.questionDetails[curIdx].question.sort = tempSort;
    group.questionDetails = _.orderBy(group.questionDetails, 'question.sort', 'asc');
    oedExamination.updateQuestionOrders({id: group.id},
      [{id: question.id, sort: preSort}, {id: preId, sort: question.sort}]);
    // scrollToQuestion(group.id, preSort);
  };

  this.onMoveDownClicked = (group, question) =>{
    const curIdx = _.findIndex(group.questionDetails, (q: any) => {
      return q.question.id === question.id;
    });
    if (curIdx < 0 || curIdx >= (_.size(group.questionDetails) - 1)) {
      return;
    }

    const nextId = group.questionDetails[curIdx + 1].question.id;
    const nextSort = group.questionDetails[curIdx + 1].question.sort;
    const tempSort = nextSort;
    group.questionDetails[curIdx + 1].question.sort = question.sort;
    group.questionDetails[curIdx].question.sort = tempSort;
    group.questionDetails = _.orderBy(group.questionDetails, 'question.sort', 'asc');
    oedExamination.updateQuestionOrders({id: group.id},
      [{id: question.id, sort: nextSort}, {id: nextId, sort: question.sort}]);
    // scrollToQuestion(group.sort, tempSort);
  };
}
