import { Component, EventEmitter,
         OnInit,
  Input, Output, ElementRef, ViewChild, OnChanges, SimpleChanges, AfterViewInit, OnDestroy } from '@angular/core';
import * as _ from 'lodash'

import { InlineEditorConfig } from './inline-editor';

import { isEmptyContent } from 'app2/utils/testUtils2'

@Component({
  selector: 'app-inline-ue-editor',
  template: require('./inline-ue-editor.component.html'),
  styles: [require('./inline-ue-editor.component.scss')]
})
export class InlineUeEditorComponent implements AfterViewInit, OnDestroy, OnInit, OnChanges {
  @Input() content: string
  @Input() config: InlineEditorConfig
  @Input() emptyText?: string
  @Input() emptyActionText?: string

  @Output()
  public contentChange = new EventEmitter<string>();
  @Output() onEnterEditor = new EventEmitter<number>()
  @Output() onLeaveEditor = new EventEmitter<number>()

  localConfig: InlineEditorConfig

  inEditMode = false
  versionStamp = 0
  lastCheckEmpty = null
  lastEmptyValue = false

  @ViewChild('editorEl') public editorEl: ElementRef

  constructor() { }

  ngOnInit() {
    this.localConfig = _.defaults({
      focus: true,
      serverUrl: '/ray/api/a/ceditor/Test/123',
    }, this.config)
  }

  ngOnChanges(changesObj: SimpleChanges) {
    if (_.has(changesObj, 'config')) {
      this.localConfig = _.defaults({
        focus: true,
      }, this.config)
    }
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.onDocumentClick)
    this.editorEl.nativeElement.removeEventListener('click', this.processLinkInEditor, true)
  }

  public ngAfterViewInit() {
    this.bindEvents()
  }

  isEmpty() {
    if (this.lastCheckEmpty && this.content === this.lastCheckEmpty) {
      return this.lastEmptyValue
    }
    this.lastEmptyValue = isEmptyContent(this.content)
    this.lastCheckEmpty = this.content
    return this.lastEmptyValue
  }

  public toggleEditMode(event: any) {
    // 处理之前需要由link中的方法先check是不是click inside
    // 所以要延迟处理此事件
    setTimeout(() => {
      this.inEditMode = !this.inEditMode;
      if (this.inEditMode) {
        this.onEnterEditor.emit(1);
      } else {
        this.onLeaveEditor.emit(1);
      }
    })
    //
    // 如果从编辑模式切换到查看模式则增加version戳
    // 强制保存问题内容
    if (this.inEditMode) {
      this.versionStamp++;
    }
  }

  public onClickOutside () {
    this.inEditMode = false;
    this.onLeaveEditor.emit(1)
  }

  public handleUpdate (content: string) {
    this.contentChange.emit(content)
  }

  private bindEvents() {
    document.addEventListener('click', this.onDocumentClick)
    this.editorEl.nativeElement.addEventListener('click', this.processLinkInEditor, true)
  }

  public onDocumentClick = (event: MouseEvent) => {
    //
    // 仅当有event.target和在编辑模式时才需要检查是否点击在了外面
    if (!event.target || !this.inEditMode) {
      return;
    }
    //
    // 当ueditor对话框打开时会有一个edui-mask的层
    // 当点击这个层的时候应当忽略
    const el = $(event.target);
    if (el.hasClass('edui-mask')) {
      return;
    }
    //
    // 如果点击的是ueditor弹出的对话框中的按钮的话也应当忽略掉点击事件
    const cls = '.ueditor, .edui-dialog, .edui-shadow, .edui-default, .cg-notify-message, .modal-dialog';
    const edDialog = el.closest(cls);
    if (edDialog.length > 0) {
      return;
    }

    const isChild = $(this.editorEl.nativeElement).find(event.target).length > 0;

    if (!isChild) {
        this.onClickOutside();
    }
  }

  public processLinkInEditor = (event: MouseEvent) => {
    if (!event.target) {
      return
    }

    const isChild = $(this.editorEl.nativeElement).find(event.target).length > 0;

    if (!isChild) {
      return
    }

    if ($(event.target).attr('target') === '_self') {
      $(event.target).attr('target', '_blank')
    }
  }

  public dummyClick() {
    return false
  }
}
