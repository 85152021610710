angular.module('app.manage.changeState', [])
  .controller('changeStateCtrl', changeStateCtrl);

changeStateCtrl.$inject = ['$scope', '$uibModalInstance', 'id', 'state', 'course', 'oedCourseManage']
function changeStateCtrl($scope, $uibModalInstance, id, state, course, oedCourseManage) {
  $scope.state = course.manualInvalid;
  if(state === 0){
    state = 1;
  }else{
    state = 0;
  }

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.changeState = function () {
    course.manualInvalid = state;
    oedCourseManage.changeCourseState({id ,state}).$promise.then(function(arr) {
      $uibModalInstance.close({success: true});
      course.manualInvalid = state;
    }).catch(function(e) {
      $scope.message = '修改状态失败';
    });
  }
}
