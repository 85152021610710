import * as _ from 'lodash'
require('assets/app/controllers/examination/examEdit/paper/examSyncQuesPreviewer/examSyncQuesPreviewer.scss')
require('assets/app/controllers/examination/examEdit/paper/examQuesPreviewer/examQuesPreviewerCtrl');
import { getQuestionTypeText, geSyncChildPointArray } from '../paperUtils'
import { v4 as uuidv4 } from 'uuid'
import { createDefaultQuestion, checkNewQuesIsValid } from 'assets/app/controllers/examination/examUtils';
import * as BlueBird from 'bluebird'

angular.module('app.examination.examEdit.syncQuesPreviewer', [
  'app.examination.examEdit.paper.quesPreviewer',
])
  .component('examSyncQuesPreviewer', {
    bindings: {
      question: '=ngModel',
      qIndex: '<',
      partIndex: '<',
      partSize: '<',
      childQuesMove: '&',
      outerIndex: '<',
      importFromLibrary: '&',
      intelligentReplace: '&',
      onScoreChange: '&',
      getQuestionNumber: '&',
      getQuesKnowledgePoints: '&',
      childQuesCustomizeMove: '&',
      onDelQues: '&',
      isCanEdit: '<',
      updateExamData: '&',
      refreshExamDataFromServer: '&',
      editingUuid: '<',
      partId: '<',
      quesSort: '<',
      changeEditingUuid: '&',
      checkEdittingQuesValid: '&',
      hideScoreInput: '<',
      forReview: '<',
      getQIndex: '&',
      hideAnalyze: '<',
    },
    controller: examSyncQuesPreviewerCtrl,
    template: <string>require('./examSyncQuesPreviewer.html'),
  })


examSyncQuesPreviewerCtrl.$inject = ['notificationService', 'oedConfig', 'localStorageService', 'oedObjectiveQuestion', 'oedExamination']
function examSyncQuesPreviewerCtrl(notificationService, oedConfig, localStorageService,
  oedObjectiveQuestion, oedExamination) {
  const ctrl = this

  ctrl.examValid = true

  ctrl.getSubQIndex = (q) => {
    return ctrl.getQIndex({q}) 
  }

  ctrl.willIgnoreClickEvent = () => {
    return !ctrl.checkEdittingQuesValid()
  }

  ctrl.editorConfig = {
    serverUrl: oedConfig.url('ceditor/Test/') + 0
  };

  ctrl.$onChanges = () => {
    const isSave = _.get(ctrl.question, 'id', 0) > 0
    if (!_.isEmpty(ctrl.question) && !isSave) {
      recalculateChildQuesScore()
    }
    if (!_.isNil(ctrl.partSize)) {
      buildQuesIndexList()
    }
  }

  ctrl.getCanEditQType = () => {
    return ctrl.isCanEdit && _.get(ctrl.question, 'uuid', '') === ctrl.editingUuid
  }

  ctrl.showKp = () => {
    return ctrl.getQuesKnowledgePoints({partIdx: ctrl.partIndex, quesIdx: ctrl.qIndex}) != '无'
  }

  ctrl.addSubQuestion = () => {
    if (!checkNewQuesIsValid(ctrl.question, notificationService, true)) {
      return
    }
    const q = createDefaultQuestion(localStorageService, true)
    ctrl.question = {
      ...ctrl.question,
      childQuestions: [
        ..._.get(ctrl.question, 'childQuestions', []),
        q,
      ],
      point2: _.get(ctrl.question, 'point2', 0) + _.get(q, 'point2', 0),
      point: Math.floor(ctrl.question.point2),
    }
    ctrl.changeEditingUuid({
      uuid: _.get(q, 'uuid', uuidv4())
    })
    setTimeout(() => {
      ctrl.updateExamData()
    })
  }

  ctrl.updateParentQuestion = () => {
    // 综合题不存在需要创建
    if (!ctrl.partId) {
      ctrl.changeEditingUuid({
        uuid: ''
      })
      return
    }
    if (!ctrl.question.id) {
      return ctrl.createQuestion();
    }
    if (!ctrl.checkEdittingQuesValid()) {
      return BlueBird.reject('not save');
    }
    // 综合题存在需要更新
    return oedObjectiveQuestion.save(ctrl.question)
    .$promise.then((data) => {
      ctrl.question = data
      ctrl.changeEditingUuid({
        uuid: ''
      })
    }).catch((e) => {
      console.log('save question error', e)
      return BlueBird.reject(e);
    })
  }

  ctrl.createQuestion = () => {
    // todo: 这里的api，应该返回新建的试卷
    return oedExamination.createExamQuestion(
      {id: ctrl.partId},
      [{
        question: ctrl.question,
        sort: ctrl.quesSort,
        relatedOutlines: []
      }]
    ).$promise.then((data) => {
      ctrl.question.id = data[_.size(data) - 1].questionId
      ctrl.changeEditingUuid({
        uuid: ''
      })
      notificationService.notify('info', '保存成功')
    }).catch((e) => {
      console.error('create part question error', e)
      notificationService.notify('info', '保存失败，请刷新后重试')
    })
  }

  function recalculateChildQuesScore() {
    const childs = _.get(ctrl.question, 'childQuestions', [])
    if (_.size(childs) <= 1) {
      return
    }
    const parentScore = _.get(ctrl.question, 'point2', 0)
    const points = geSyncChildPointArray(parentScore, _.size(childs))
    _.forEach(childs, (c, i) => {
      _.set(c, 'point2', points[i])
    })
  }

  function buildQuesIndexList() {
    ctrl.quesIdxList = []
    for (let i = 0; i < ctrl.partSize; i++) {
      const quesItemNum = ctrl.getQuestionNumber({ partIdx: ctrl.partIndex, quesIdx: i })
      ctrl.quesIdxList.push(quesItemNum)
    }
  }

  ctrl.onCheckAvailable = () => {
    if (ctrl.curScore <= 0) {
      notificationService.notify('error', '分数必须大于0')
    }
  }

  ctrl.onScoreBlur = (partIdx, quesIdx) => {
    ctrl.onScoreChange({ partIdx: partIdx, quesIdx: quesIdx, score: ctrl.curScore })
  }

  ctrl.getTypeLabel = (t: string) => getQuestionTypeText(t)

  ctrl.onChildQuesScoreChange = (partIdx: number, quesIdx: number, score: number, parentParIdx: number) => {
    ctrl.onScoreChange({ partIdx, quesIdx, score, parentParIdx })
  }

  ctrl.onDelChildQues = (partIdx: number, quesIdx: number, childIdx: number) => {
    ctrl.onDelQues({partIdx : ctrl.partIndex, quesIdx : partIdx, childIdx : quesIdx})
  }
}


