/**
 * @fileOverview
 * @name subjectiveTestSessionsCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('preResSessions', {
        url: '/review/:classSessionId/course/:courseId/preRes/:preResId',
        template: require('assets/templates/review/preResSessions.html'),
        controller: 'preResTestSessionsCtrl',
        bodyStyle: 'bged2'
      });
      $stateProvider.state('tempItemSessions', {
        url: '/review/:classSessionId/course/:courseId/tempItemSessions',
        template: require('assets/templates/review/preResSessions.html'),
        controller: 'preResTestSessionsCtrl',
        bodyStyle: 'bged2'
      });
    }])
    .controller('preResTestSessionsCtrl', preResTestSessionsCtrl);

  preResTestSessionsCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal',
    '$animate', '$q', 'dialogs', 'resize', 'userInfo', 'oedCoursePath', 'oedClassSession', 'oedTeachingPattern', 'oedCourse']
  function preResTestSessionsCtrl($scope, $state, $stateParams, $uibModal,
    $animate, $q, dialogs, resize, userInfo, oedCoursePath, oedClassSession, oedTeachingPattern, oedCourse) {

    $scope.currentState = {};

    $scope.courseId = _.parseInt($stateParams.courseId);
    $scope.classSessionId = _.parseInt($stateParams.classSessionId);
    $scope.preResId = _.parseInt($stateParams.preResId);

    $scope.classSession = oedClassSession.get({
      id: $stateParams.classSessionId
    });

    $scope.course = oedCourse.get({
      id: $stateParams.courseId
    })

    const getPreResItemSessions = () => {
      if (_.isNaN($scope.preResId)) {
        return oedTeachingPattern.queryByClassSessionAndCourseId({
          classSessionId: $stateParams.classSessionId,
          courseId: $stateParams.courseId
        }).$promise.then(patterns => {
          $scope.preResItemSessions = _.chain($scope.classSession).get('preResItemSessions', [])
            .filter('isTemp').filter((r) => {
              return _.includes(_.map(patterns, 'id'), r.teachingPatternId)
            }).value();
        });
      } else {
        return oedClassSession.queryPreResItemSessions({
          id: $stateParams.classSessionId,
          preResId: $scope.preResId
        }).$promise.then(items => {
          $scope.preResItemSessions = items
        })
      }
    }

    const getPreResSessions = () => {
      if (_.isNaN($scope.preResId)) {
        return Promise.resolve()
      } else {
        return oedClassSession.queryPreResSessionsExcludeTmSession({
          id: $stateParams.classSessionId,
          preResId: $scope.preResId
        }).$promise.then(preRes => {
          $scope.preResSessions = preRes
        })
      }
    }
    $scope.course.$promise.then(() => {
      return $scope.classSession.$promise
    }).then(r => {
      return $q.all([getPreResItemSessions(),
        getPreResSessions()]).then(r => {
        if (!_.isEmpty($scope.preResItemSessions)) {
          $scope.currSession = _.last($scope.preResItemSessions);
          const state = _.isNaN($scope.preResId) ? 'tempItemSessions.session' : 'preResSessions.session'
          $state.go(state, {
            sessionId: $scope.currSession.id,
            isItem: true
          }, {
            location: 'replace'
          });
        } else {
          $scope.currSession = _.last($scope.preResSessions);
          $state.go('preResSessions.session', {
            sessionId: $scope.currSession.id,
            isItem: false
          }, {
            location: 'replace'
          });
        }
      })
    })

    $scope.selectCurrSession = function(session) {
      $scope.currSession = session;
    }
  }

})();
