(function() {
  angular.module('app.directives.commentComposer', [])
    .directive('commentComposer', commentComposer)
    .directive('commentComposerCompact', commentComposerCompact);

  function commentComposer() {
    return {
      restrict: 'EA',
      require: '^ngModel',
      scope: {
        content: '=ngModel',
        user: '=',
        buttonText: '@',
        showRanking: '=',
        submit: '&onSubmit',
        cancel: '&onCancel'
      },
      template: require('assets/templates/directives/commentComposer.html'),
      controller: commnetComposerController
    };
  }

  function commentComposerCompact() {
    return {
      restrict: 'E',
      require: '^ngModel',
      scope: {
        content: '=ngModel',
        user: '=',
        buttonText: '@',
        showRanking: '=',
        submit: '&onSubmit',
        cancel: '&onCancel'
      },
      template: require('assets/templates/directives/commentComposerCompact.html'),
      controller: commnetComposerController
    };
  }
  commnetComposerController.$inject = ['$scope', '$q', 'Lightbox', 'Upload', 'notificationService', 'oedConfig', 'oedUserInfoUtils']
  function commnetComposerController($scope, $q, Lightbox, Upload, notificationService, oedConfig, oedUserInfoUtils) {
    $scope.basePath = oedConfig.base();

    $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl;

    $scope.previewResource = function(reses, idx) {
      Lightbox.openModal(reses, idx);
    };

    var uploadUrl = oedConfig.url('ceditor/uploadresource');

    $scope.uploading = 0;

    $scope.uploadFile = function(files) {
      if (_.isEmpty(files)) {
        return null;
      }

      $scope.uploading += _.size(files);
      $scope.uploadprogress = 0;
      var total = _.size(files);
      var progresses = _.fill([], 0, 0, _.size(files));

      return $q.all(_.map(files, function(file, idx) {
        return $q(function(resolve, reject) {
          Upload.upload({
            url: uploadUrl,
            file: file
          }).progress(function(evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            progresses[idx] = progressPercentage;

            $scope.uploadprogress = parseInt(_.sum(progresses) / total);
          }).success(function(data, status, headers, config) {
            resolve(data);
          }).error(function() {
            notificationService.notify('error', '文件上传失败!')
            resolve(null);
          }).finally(function() {
            $scope.uploading--;
          });
        });
      })).then(function(datas) {
        var comment = $scope.content;
        comment.resources = comment.resources || [];

        var successFiles = _.filter(datas, function(data) {
          return !!data;
        }).map(function(r) {
          return {
            id: r.id,
            resourceName: r.resourceName,
            fileType: r.fileType,
            suffix: r.suffix
          };
        });

        comment.resources = comment.resources.concat(successFiles);
      });
    };

    $scope.setRank = function(rank) {
      $scope.content.rank = rank;
    };

    $scope.removeResource = function($index) {
      var comment = $scope.content;
      comment.resources.splice($index, 1);
    };
  }
})();
