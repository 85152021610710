import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
const dateUtils: any = require('app2/utils/dateUtils');
import { of, zip, Observable, EMPTY, Subscription } from 'rxjs';
import { LoadingService } from 'app/shared/loading/loading.service';
import { SbjSessionService } from '../sbj-session.service';
import { BoardSessionStudentDTO, TestSessionSummaryDTO } from 'app/models/sbj-test-session';
import * as testIconUtils from 'app2/utils/testIconUtils';

@Component({
  selector: 'app-sbj-completion-previewer',
  template: require('./sbj-completion-previewer.component.html'),
  styles: [require('./sbj-completion-previewer.component.scss')]
})
export class SbjCompletionPreviewerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() boardSessionId: number;
  @Input() endTime: number;
  @Input() fromClassSession: boolean;
  @Input() showOnlyTestName: boolean;
  @Input() startTime: number;
  @Input() testName: string;
  @Input() pubStudentCount: number;
  format: string;
  totalStudentCount: number;
  onlineStdsCount: number;
  totalComment: number;
  totalSubmit: number;
  commentRate: number;
  submitRate: number;
  commentRateInPercentage: string;
  submitRateInPercentage: string;
  subs$: Subscription;

  constructor(private loadingService: LoadingService,
              private sbjSessionService: SbjSessionService) { }

  ngOnInit() {
    this.format = this.fromClassSession ? 'HH:mm' : 'MM月dd号'
  }

  ngOnDestroy(): void {
    if (this.subs$) {
      this.subs$.unsubscribe()
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.has(changes, 'boardSessionId')) {
      if (!_.isUndefined(this.boardSessionId)) {
        this.loadData()
      }
    }
  }

  queryStds () {
    if (!this.boardSessionId) {
      return of([])
    }
    return this.sbjSessionService.queryStdsByBoardSessionId(this.boardSessionId)
  }

  queryHotrate (): Observable<TestSessionSummaryDTO> {
    if (!this.boardSessionId) {
      return EMPTY
    }
    return this.sbjSessionService.getHotRateBySessionId(this.boardSessionId)
  }

  stdsCount (boardSessionStds: BoardSessionStudentDTO[]) {
    this.totalStudentCount = this.fromClassSession ? _.size(boardSessionStds) : this.pubStudentCount
    this.onlineStdsCount = _.reduce(boardSessionStds, (sum, std) => {
      if (std.wasOnline) {
        sum ++
      }
      return sum
    }, 0)
  }

  loadData () {
    if (this.subs$) {
      this.subs$.unsubscribe()
    }
    this.subs$ = zip(this.queryStds(), this.queryHotrate())
      .pipe(map(([boardSessionStds, summary]) => {
        this.stdsCount(boardSessionStds)
        this.calculateSubmitRate(summary)
      })).subscribe(() => {}, (e) => {
        console.log('error', e)
      })
  }

  calculateSubmitRate(summary: TestSessionSummaryDTO) {
    this.totalComment = summary.totalComment
    this.totalSubmit = summary.totalSubmit
    const totalStudent = this.fromClassSession ? this.onlineStdsCount : this.totalStudentCount;
    if (totalStudent === 0) {
      this.commentRate = 0;
      this.submitRate = 0;
    } else {
      this.commentRate = this.totalComment * 1.0 / totalStudent;
      this.submitRate = this.totalSubmit * 1.0 / totalStudent;
    }
    this.commentRateInPercentage = (this.commentRate * 100).toFixed(0);
    this.submitRateInPercentage = (this.submitRate * 100).toFixed(0);
  }

  getSummaryImage = (prefix: string) => {
    return testIconUtils.getSummaryImage(prefix, this.submitRate);
  }

  isGoodTest () {
    return testIconUtils.isGoodTest(this.submitRate);
  }

  isBadTest () {
    return testIconUtils.isBadTest(this.submitRate);
  }

  getBoardSessionDuration () {
    return dateUtils.sessionDuration(this.startTime, this.endTime, this.fromClassSession);
  }
}
