import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreModule } from 'app/core/core.module';
import { SharedModule } from 'app/shared/shared.module';
import { FilterContainerComponent } from './filter-container/filter-container.component';
import { TalresPaperComponent } from './talres-paper/talres-paper.component';
import { FilterSelectorComponent } from './filter-selector/filter-selector.component';
import { FilterQuestionTypeComponent } from './filter-question-type/filter-question-type.component';

@NgModule({
  declarations: [TalresPaperComponent, FilterContainerComponent, FilterSelectorComponent, FilterQuestionTypeComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    CoreModule,
  ],
  entryComponents: [TalresPaperComponent, FilterContainerComponent],
  exports: [ FilterContainerComponent ],
})
export class TalcloudModule { }
