(function() {
  angular.module('app.manage')
    .controller('editSchoolInfoDialogCtrl', editSchoolInfoDialogCtrl);

  editSchoolInfoDialogCtrl.$inject = ['$uibModalInstance', '$scope', '$http', 'notificationService', 'school']
  function editSchoolInfoDialogCtrl($uibModalInstance, $scope, $http, notificationService, school) {
    $scope.schoolTypes = ['小学', '中学', '初中', '高中', '中小学', '中职', '其他'];

    $scope.schoolInfo = angular.copy(school);

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.ok = function(info) {
      $scope.showFormError = true;
      if ($scope.updateForm.$invalid) {
        return;
      }

      $scope.loading = $scope.schoolInfo.$update().then(function() {
        notificationService.notify('info', '学校信息更新成功')

        _.assign(school, _.pick($scope.schoolInfo, ['schoolName', 'schoolType', 'approve']));

        $uibModalInstance.close($scope.schoolInfo);
      });
    };
  }
})();
