(function() {
  var resStarInfo = require('app2/directives/cloud/resStarInfo.directive');
  var questionsPreviewer = require('app2/prepareCourse/questionsPreviewer.ctrl');

  angular.module('app.prepareCourse')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareCourse.objectiveTest', {
        url: '/objectiveTest/:testId',
        params: {
          testId: '',
        },
        template: require('assets/templates/prepareCourse/objectiveTest.html'),
        controller: 'objectiveTestCtrl',
        loadingCls: 'blueloading'
      });
      $stateProvider.state('prepareCourse.objectiveTest.designTest', {
        url: '/designTest',
        template: require('assets/templates/prepareCourse/designTest.html'),
        controller: 'designTestCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'bg_img'
      });
      //测试新版备课route
      $stateProvider.state('prepareCourse.objectiveTest.betaDesignTest', {
        url: '/betaDesignTest',
        template: require('assets/templates/prepareCourse/betaDesignTest.html'),
        controller: 'designTestCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('objectiveTestCtrl', objectiveTestCtrl)
    .controller('designTestCtrl', designTestCtrl)
    .controller('addTestCtrl', addTestCtrl)
    .controller('editTestCtrl', editTestCtrl);

  objectiveTestCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal', 'dialogs', 'notificationService', 'localStorageService',
    'oedObjectiveTest', 'oedCloudTest']
  function objectiveTestCtrl($scope, $state, $stateParams, $q, $uibModal, dialogs, notificationService, localStorageService,
    oedObjectiveTest, oedCloudTest) {
    /*控制器通信*/
    $scope.mySaveTest = function() {
      if (!$scope.iAmDeleted) {
        $scope.$broadcast('saveObjectiveTest');
      }
    };

    $scope.$on('$destroy', function() {
      $scope.isDone = true;
    });

    $scope.currentState.title = '客观试卷';
    $scope.currentState.loading = null;

    var courseId = _.parseInt($stateParams.courseId);
    $scope.tests = oedObjectiveTest.queryByCourseIncEmpty({
      course_id: courseId
    });

    var curTestId = $stateParams.testId;

    $scope.currentState.loading = $q(function(resolve, reject) {
      $scope.tests.$promise.then(function(tests) {
        if (!_.isEmpty(tests)) {
          var curTest = curTestId ? _.find(tests, function(t) {
            return t.id == curTestId;
          }) : tests[0];
          curTest = curTest || tests[0];
          $scope.setCurrentTest(curTest, true);
          return null;
        } else { // no tests, create one
          var test = new oedObjectiveTest({
            name: '习题检测1',
            courseId: courseId,
            isEmpty: true
          });
          return test.$save();
        }
      }).then(function(newtest) {
        if ($scope.isDone)
          return;

        if (newtest) {
          $state.go('prepareCourse.objectiveTest', {}, {
            reload: true,
            location: 'replace'
          });
        } else {
          // populate shared test map
          var sharedTests = oedCloudTest.queryByTestIds({
            testIds: _.map($scope.tests, 'id')
          });
          sharedTests.$promise.then(function(allSharedTests) {
            $scope.sharedTests = _.keyBy(allSharedTests, 'testId');
            $scope.refreshCloudTestId();
          });
        }
        resolve();
      }).catch(reject);
    });
    $scope.refreshCloudTestId = function() {
      if ($scope.sharedTests[$scope.currentTest.id])
        $scope.currentCloudTestId = $scope.sharedTests[$scope.currentTest.id].id;
      else
        $scope.currentCloudTestId = null;
    };
    $scope.shouldShowShare = function(test) {
      return test && $scope.sharedTests && $scope.sharedTests[test.id];
    };
    $scope.setCurrentTest = function(t, replace) {
      if ($scope.isDone)
        return;

      if ($scope.currentTest == t) {
        return;
      }

      $scope.currentTest = t;

      var opt = replace ? {
        location: 'replace'
      } : null;

      var state = 'prepareCourse.objectiveTest.designTest2';
      if (localStorageService.get('lastTestEditMode') === 'question') {
        state = 'prepareCourse.objectiveTest.designTest';
      }
      $state.go(state, {
        testId: t.id
      }, opt);
    };

    /*自动保存*/
    $scope.$on('$destroy', function() {
      $scope.mySaveTest();
    });

    //$scope.isBlcok=

    $scope.addTest = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/addExamDialog.html'),
        controller: 'addTestCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          courseId: function() {
            return courseId;
          },
          existingTests: function() {
            return $scope.tests;
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r.promise) {
          r.promise.then(function() {
            $scope.tests.push(r.test);
            $scope.setCurrentTest(r.test);
          });
        }
      });

    };
    $scope.deleteTest = function($event, test) {
      $event.stopPropagation();
      $event.preventDefault();
      var dlg = dialogs.confirm('确定删除?', '确定要删除客观试卷: "' + test.name + '"吗');
      dlg.result.then(function(btn) {
        $scope.currentState.loading = $q(function(resolve, reject) {
          test.$delete(function() {
            if ($scope.isDone)
              return;

            if (test == $scope.currentTest) {
              $scope.iAmDeleted = true;
              $state.go('prepareCourse.objectiveTest', {}, {
                reload: true
              });
            } else {
              _.remove($scope.tests, test);
              if (_.isEmpty($scope.tests)) {
                $state.go('prepareCourse.objectiveTest', {}, {
                  reload: true
                });
              }
            }
            resolve();
          }, function() {
            reject();
          });
        });
      }, function(btn) {});
    };

    $scope.toggleShareTest = function($event, test) {
      $event.stopPropagation();
      $event.preventDefault();
      var dlg = null;
      if (test.id in $scope.sharedTests) {
        dlg = dialogs.confirm('确定取消共享?', '确定要取消共享该客观试卷吗？');
        dlg.result.then(function(btn) {
          $scope.sharedTests[test.id].$delete().then(result => {
            delete $scope.sharedTests[test.id];
            $scope.refreshCloudTestId();
          });
        }, function(btn) {});
      } else {
        dlg = dialogs.confirm('确定共享?', '确定要共享该客观试卷吗？');
        dlg.result.then(function(btn) {
          var sharedTest = new oedCloudTest({
            testId: test.id
          });
          sharedTest.$save().then(t => {
            $scope.sharedTests[test.id] = t;
            $scope.refreshCloudTestId();
          });
        }, function(btn) {});
      }
    };
  }

  designTestCtrl.$inject = ['$scope', '$state', '$stateParams', '$http', '$uibModal', '$q',
    '$timeout', 'dialogs', 'resize', 'notificationService', 'localStorageService', 'oedConfig',
    'oedObjectiveTest', 'oedCloudTest', 'oedUnitItem', 'oedCloudQuestion', 'oedObjectiveQuestion',
    'oedCourse', 'oedUserInfo', 'oedLevelGradeVersionSubjects', 'oedMaterialVersion',
    'oedGrade', 'oedSubjects', 'oedLevel', 'oedObjectiveTestStats', 'oedTestUtils', 'oedCourseUtils']
  function designTestCtrl($scope, $state, $stateParams, $http, $uibModal, $q, $timeout,
    dialogs, resize, notificationService, localStorageService,
    oedConfig, oedObjectiveTest, oedCloudTest, oedUnitItem,
    oedCloudQuestion, oedObjectiveQuestion, oedCourse, oedUserInfo,
    oedLevelGradeVersionSubjects, oedMaterialVersion, oedGrade, oedSubjects, oedLevel, oedObjectiveTestStats,
    oedTestUtils, oedCourseUtils) {
    var flag = false; /*判断是否保存标记*/
    /*控制器通信*/
    $scope.lastChange = moment();
    $scope.lastSave = $scope.lastChange;

    $scope.$on('saveObjectiveTest', function(e) {
      if ($scope.lastSave.isBefore($scope.lastChange)) {
        $scope.saveTest(true);
      }
    });

    $scope.versionStamp = 0;

    $scope.$on('$destroy', function() {
      $scope.isDone = true;
    });

    $scope.testId = $stateParams.testId;
    $scope.currentTest = oedObjectiveTest.get({
      id: $scope.testId
    });

    $scope.courseSections = oedUnitItem.queryAvailableBySchoolAndCourse({
      schoolId: 0, //schoolId 无用, 传一个dummy的值
      courseId: $stateParams.courseId
    });

    $scope.currentState.loading = $q.all([$scope.currentTest.$promise,
      $scope.course.$promise,
      $scope.courseSections.$promise
    ]);

    $scope.currentState.loading.then(function(results) {
      $timeout(function() {
        if ($scope.isDone) {
          return;
        }
        //
        // scroll to the new question element after the dom is updated
        var el = $('.ques-view-list');
        el.scrollToElementAnimated($('#new_question'));
      }, 0);

      $scope.newquestion = defaultQuestion();
      $scope.allReady = true;
    });
    $scope.onDeleteClicked = function(question) {
      var curIndex = _.indexOf($scope.newquestion.childQuestions, question);
      $scope.newquestion.childQuestions.splice(curIndex, 1);
    };
    $scope.addSubQuestion = function() {
      var relSections = _.map($scope.course.relatedSections, function(sec) {
        return _.pick(sec, ['sectionId', 'sectionName']);
      });

      var qType = localStorageService.get('lastQuestionType') || 'singlechoice';
      if (qType == 'synthetic')
        qType = 'singlechoice';
      var choices = [{}, {}, {}, {}];
      var answer = '';
      if (qType === 'singlevote' || qType === 'multivote' || qType === 'vote') {
        answer = '-1';
      }
      if (qType !== 'singlechoice' && qType !== 'multichoice' &&
          qType !== 'singlevote' && qType !== 'multivote' && qType !== 'vote') {
        choices = [];
      }

      var question =  {
        answer: answer,
        question: '',
        type: qType,
        point2: qType == 'synthetic' ? 0 : 1,
        choices: choices,
        leftOpts: [],
        rightOpts: [],
        needPic: false,
        answerDetails: null,
        relatedSections: relSections
      };

      if (_.isEmpty($scope.newquestion.childQuestions))
        $scope.newquestion.childQuestions = [];
      $scope.newquestion.childQuestions.push(question);
      $scope.onDesignSubScoreChanged();
      /**
      $scope.$watch(function() {
        return $scope.newquestion.childQuestions[_.size($scope.newquestion.childQuestions) - 1];
      }, function() {
        $scope.newquestion.point = _.reduce($scope.newquestion.childQuestions, function(total, q) {
          return total + q.point;
        }, 0);
      }, true);**/
    };
    $scope.onDesignSubScoreChanged = function() {
      $scope.newquestion.point2 = _.reduce($scope.newquestion.childQuestions, function(total, q) {
        return total + q.point2;
      }, 0);
    };
    //
    // TODO: convert types to an array to keep it's order
    $scope.types = {
      'singlechoice': '单选',
      'multichoice': '多选',
      'yesorno': '判断',
      'fill': '填空',
      'connect': '匹配',
      'vote': '投票',
      'synthetic': '综合',
      'qa': '问答'
    };

    function defaultQuestion() {
      return oedCourseUtils.defaultQuestion($scope.course.relatedSections);
    }

    $scope.newquestion = defaultQuestion();

    var optLabels = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
    var getRightOptName = function(idx) {
      return idx + 1;
    };

    var getRightOptNameByObject = function(opts, opt) {
      return getRightOptName(_.indexOf(opts, opt));
    };

    function editToViewModel(ques) {
      var newModel = angular.copy(ques);
      //
      // remove
      if (newModel.type == 'connect') {
        var answer = _.reduce(newModel.leftOpts, function(ret, opt, idxLeft) {
          var choices = [];
          _.each(opt.connectsTo, function(conn) {
            var choiceIdx = _.indexOf(newModel.rightOpts, conn);
            choices.push(choiceIdx);
          });

          choices.sort(function(a, b) {
            return a - b;
          });

          if (_.isEmpty(choices)) {
            return ret;
          }
          var choicesStr = _.reduce(choices, function(str, idxRight) {
            var choice = '' + idxLeft + ':' + idxRight;
            if (_.isEmpty(str)) {
              return choice;
            }
            return str + ',' + choice;
          }, '');

          if (_.isEmpty(ret)) {
            return choicesStr;
          }
          return ret + ',' + choicesStr;
        }, '');
        if (_.isEmpty(answer) && !_.isEmpty(newModel.answer)) {
          newModel.answer = answer;
        }
        if (!_.isEmpty(answer)) {
          newModel.answer = answer;
        }

        newModel.leftOpts = _.map(newModel.leftOpts, function(opt) {
          return _.omit(opt, 'connectsTo');
        });
      }
      return newModel;
    }

    function toEditModel(ques) {
      var newModel = angular.copy(ques);
      if (newModel.type == 'connect') {
        if (_.isEmpty(newModel.answer) || _.isEmpty(newModel.leftOpts) || _.isEmpty(newModel.rightOpts)) {
          return newModel;
        }

        var answers = newModel.answer.split(',');
        _.each(answers, function(aws) {
          aws = _.trim(aws);
          if (_.isEmpty(aws)) {
            return;
          }
          var parts = aws.split(':');
          if (_.size(parts) != 2) {
            console.log('error parsing answer: ' + aws);
            return;
          }
          var idx = _.parseInt(parts[0]);
          var choiceIdx = _.parseInt(parts[1]);
          //
          // in case the format is the old format like: 0 A, 1 B
          var optLbIdx = _.indexOf(optLabels, parts[1]);
          if (optLbIdx >= 0)
            choiceIdx = optLbIdx;

          newModel.leftOpts = newModel.leftOpts || [];
          newModel.leftOpts[idx].connectsTo = newModel.leftOpts[idx].connectsTo || [];
          newModel.leftOpts[idx].connectsTo.push(newModel.rightOpts[choiceIdx]);
        });
      }
      return newModel;
    }
    $scope.isBlock = false;
    $scope.isBlock2 = false;
    var isAllBlankHasAnswerSet = function(ques) {
      var content = _.get(ques, 'question', '');
      var count = (content.match(/__+/g) || []).length;
      //
      // 对于没有设置空的填空题也认为没有答案
      if (count === 0) {
        return false;
      }

      var answerArray = oedTestUtils.convertFillAnswerStringToArray(_.get(ques, 'answer'));

      if (_.size(answerArray) != count) {
        return false;
      }

      var answerTypes = oedTestUtils.convertFillAnswerTypesToArray(_.get(ques, 'answer'), _.size(answerArray));
      return _.findIndex(_.zip(answerTypes, answerArray), function(p) {
        return p[0] != 'manual-score' && _.isEmpty(p[1]);
      }) < 0;
    };

    $scope.addToTest = function() {
      //
      // 更新问题版本
      // 强制view => model状态更新
      $scope.versionStamp ++;
      $scope.loading = $timeout($scope.addToTestWithQuestion);
    };
    $scope.$watch('newquestion.childQuestions', function(newVal, oldVal) {
      if (_.get($scope, 'newquestion.type', '') === 'synthetic') {
        $scope.onDesignSubScoreChanged();
      }
    }, true);
    $scope.addToTestWithQuestion = function() {
      if (_.get($scope, 'newquestion.type', '') === 'synthetic') {
        $scope.onDesignSubScoreChanged();
      }

      var idx = $scope.currentQuestion ? _.findIndex($scope.currentTest.questions, $scope.currentQuestion) :
          _.size($scope.currentTest.questions) - 1;
      $scope.scrollAnchor = {
        relativeTo: '.scroll-to',
        to: idx,
        stamp: _.uniqueId('qscroll_')
      };
      if ($scope.isBlock) $scope.isBlock = false;
      if (_.isEmpty($scope.newquestion.relatedSections)) {
        dialogs.error('无法加入', '当前题目没有关联章节, 请关联章节后再加入试卷');
        return;
      }
      //
      // filter values

      var newQ = editToViewModel($scope.newquestion);
      const regExpBr = /^(<p><br\/><\/p>)+|(<p><br\/><\/p>)+$/g;
      $scope.eachTrimEnter = function(x) {
        if (regExpBr.test(x) && x != '<p><br/></p>') {
          return x.replace(regExpBr, '');
        } else {
          return x;
        }
      };
      //题干去除多余的回车
      newQ.question = $scope.eachTrimEnter(newQ.question);
      //解析去除多余的回车
      newQ.answerDetails = $scope.eachTrimEnter(newQ.answerDetails);

      if (_.includes(['singlevote', 'multivote', 'qa'], newQ.type)) {
        // set fake answer for vote type, as most of the login in fleaf will ignore test without answer
        // It's easier to add this fake answer instead of changing fleaf logic.
        newQ.answer = '-1';
      }

      if (_.isEmpty(newQ.answer) && newQ.type != 'synthetic') {
        dialogs.error('无法加入', '当前题目没有设置答案， 请设置答案后再加入试卷');
        return;
      }
      var expTable = new RegExp('<table>');
      if (newQ.type === 'synthetic') {
        const syntheticTable = [];
        if (_.size($scope.newquestion.childQuestions) === 0) {
          dialogs.error('无法加入', '当前综合题没有子题， 请设置子题后再加入试卷');
          return;
        }
        var hasAnswer = _.isEmpty(_.find($scope.newquestion.childQuestions, function(q) {
          var tmp = editToViewModel(q);
          syntheticTable.push(tmp);
          return _.isEmpty(tmp.answer) && tmp.type != 'singlevote' &&
                    tmp.type != 'multivote' && tmp.type != 'vote' && tmp.type != 'qa';
        }));
        if (!hasAnswer) {
          dialogs.error('无法加入', '当前题目有子题没有设置答案， 请设置答案后再加入试卷');
          return;
        }
        newQ.childQuestions = _.map($scope.newquestion.childQuestions, function(q) {
          return editToViewModel(q);
        });
        //综合题子题标题不能粘贴表格
        const synTableQues = _.find(syntheticTable, (c) => expTable.test(c.question));
        if (synTableQues !== undefined) {
          dialogs.error('无法加入', '题干中暂不支持插入表格');
          return;
        }
        const synAnswerDetails = _.find(syntheticTable, (c) => expTable.test(c.answerDetails));
        if (synAnswerDetails !== undefined) {
          dialogs.error('无法加入', '解析中暂不支持插入表格');
          return;
        }
        //综合题子题题干、解析、选项、去除多余回车
        _.forEach(newQ.childQuestions, function(c) {
          c.question = $scope.eachTrimEnter(c.question);
          c.answerDetails = $scope.eachTrimEnter(c.answerDetails);
          if (c.type === 'singlechoice' || c.type === 'multichoice' ||
            c.type === 'singlevote' || c.type === 'multivote') {
            _.forEach(c.choices, function(opt) {
              opt.content = $scope.eachTrimEnter(opt.content);
            });
          }
          if (c.type === 'connect') {
            _.forEach(c.leftOpts, function(opt) {
              opt.content = $scope.eachTrimEnter(opt.content);
            });
            _.forEach(c.rightOpts, function(opt) {
              opt.content = $scope.eachTrimEnter(opt.content);
            });
          }
        });
        //综合题选项
        const syntheticChoices = [];
        const synChoicesContent = _.find(syntheticTable, function(x) {
          if (x.type === 'singlechoice' || x.type === 'multichoice' ||
            x.type === 'singlevote' || x.type === 'multivote') {
            _.each(x.choices, function(c) {
              syntheticChoices.push(c.content);
            });
          }
          return expTable.test(syntheticChoices);
        });
        if (synChoicesContent !== undefined) {
          dialogs.error('无法加入', '选项中暂不支持插入表格');
          return;
        }
        //综合题leftOpts、rightOpts
        const syntheticLeftOpts = [];
        const syntheticRightOpts = [];
        const synLeftOpts = _.find(syntheticTable, function(x) {
          if (x.type === 'connect') {
            _.each(x.leftOpts, function(c) {
              syntheticLeftOpts.push(c.content);
            });
          }
          return expTable.test(syntheticLeftOpts);
        });
        const synRightOpts = _.find(syntheticTable, function(x) {
          if (x.type === 'connect') {
            _.each(x.rightOpts, function(c) {
              syntheticRightOpts.push(c.content);
            });
          }
          return expTable.test(syntheticRightOpts);
        });
        if (synLeftOpts !== undefined || synRightOpts !== undefined) {
          dialogs.error('无法加入', '匹配题选项中暂不支持插入表格');
          return;
        }
      }
      //
      // 对于填空题应当检查是否所有的空都设置了答案
      if (newQ.type === 'fill' && !isAllBlankHasAnswerSet(newQ)) {
        dialogs.error('无法加入', '填空题需要设置所有非老师评判空的答案， 请设置答案后再加入试卷');
        return;
      }
      if (newQ.type === 'singlevote' || newQ.type === 'multivote') {
        localStorageService.set('lastQuestionType', 'vote');
      } else {
        localStorageService.set('lastQuestionType', newQ.type);
      }
      //存储分数,当题型是综合题或者填空题的时候，不存
      if (newQ.type !== 'synthetic' && newQ.type !== 'fill') {
        localStorageService.set('lastQuestionPoint', newQ.point2);
      } else {
        localStorageService.set('lastQuestionPoint', 1);
      }

      //题干中插入了表格的处理
      if (expTable.test(newQ.question)) {
        dialogs.error('无法加入', '题干中暂不支持插入表格');
        return;
      }
      if (expTable.test(newQ.answerDetails)) {
        dialogs.error('无法加入', '解析中暂不支持插入表格');
        return;
      }
      //单选、多选、投票中的choices插入了表格的处理
      if (newQ.type === 'singlechoice' || newQ.type == 'multichoice' ||
        newQ.type === 'singlevote' || newQ.type === 'multivote') {
        const tableQues = _.find(newQ.choices, (c) => expTable.test(c.content));
        if (tableQues !== undefined) {
          dialogs.error('无法加入', '选项中暂不支持插入表格');
          return;
        }
        _.forEach(newQ.choices, function(c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
      }
      //当表格的leftOpts或rightOpts中粘贴了表格时候的处理
      if (newQ.type === 'connect') {
        const connectLeftOpts = _.find(newQ.leftOpts, (c) => expTable.test(c.content));
        const connectRightOpts = _.find(newQ.rightOpts, (c) => expTable.test(c.content));
        if (connectLeftOpts !== undefined || connectRightOpts !== undefined) {
          dialogs.error('无法加入', '匹配题选项中暂不支持插入表格');
          return;
        }
        _.forEach(newQ.leftOpts, function(c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
        _.forEach(newQ.rightOpts, function(c) {
          c.content = $scope.eachTrimEnter(c.content);
        });
      }
      var message = null;
      if (!$scope.currentQuestion) {
        $scope.currentTest.questions.push(newQ);
        //$scope.createNewQuestion();
        $scope.currentQuestion = null;
        $scope.showAnswerDetails = false;
        message = '试卷加入成功';
      } else {
        angular.copy(newQ, $scope.currentQuestion);
        message = '试卷更新成功';
        //var idx = _.indexOf($scope.currentTest.questions, $scope.currentQuestion);
        //if (idx >= $scope.currentTest.questions.length - 1){
        //    $scope.createNewQuestion();
        //}else{
        //    $scope.goToQuestionByIndex(idx + 1);
        //}
      }

      $scope.currentTest.isSameAsOrig = false;

      $scope.createNewQuestion();
      $scope.lastChange = moment();

      $scope.saveTest(false).then(function() {
        notificationService.notify('info', message)
      });
    };

    $scope.clearQuestion = function() {
      $scope.newquestion = defaultQuestion();
    };

    var checkEditChanged = function() {
      var isChanged = function() {
        if (!$scope.currentQuestion) {
          return false;
        }

        var curQues = $scope.currentQuestion || defaultQuestion();
        var newQ = editToViewModel($scope.newquestion);
        if (newQ.question == '<p><br/></p>') {
          newQ.question = '';
        }
        if (!angular.equals(_.omit(curQues, 'relatedSections'), _.omit(newQ, 'relatedSections'))) {
          return true;
        }

        // check whether the related sections changed...
        var curRelSecs = _.map(curQues.relatedSections, function(sec) {
          return sec.sectionId;
        });

        var newRelSecs = _.map(newQ.relatedSections, function(sec) {
          return sec.sectionId;
        });

        return _(curRelSecs).xor(newRelSecs).size() !== 0;
      };
      if (!isChanged()) {
        return true;
      } else { // question modified, show a confirm dialog
        var dlg = dialogs.confirm('确定取消修改', '切换问题会导致当前的修改丢失, 确定要放弃修改后的内容吗?');
        return dlg.result;
      }
    };

    $scope.createNewQuestion = function() {
      $scope.newquestion = defaultQuestion();
      $scope.currentQuestion = null;
      setTimeout(function() {
        if ($scope.isDone) {
          return;
        }
        //
        // scroll to the new question element after the dom is updated
        var el = $('.ques-view-list');
        el.scrollToElementAnimated($('#new_question'));
      }, 0);
    };

    $scope.newQuestion = function() {
      $q.when(checkEditChanged()).then($scope.createNewQuestion);
      $scope.scrollAnchor = {
        relativeTo: '.scroll-to',
        to: _.size($scope.currentTest.questions) - 1,
        stamp: _.uniqueId('qscroll_')
      };
    };

    $scope.isNewQuestion = function() {
      return !$scope.currentQuestion;
    };

    $scope.shouldShowEditHints = function() {
      return _.isEmpty($scope.currentTest.questions) || $scope.isNewQuestion();
    };

    $scope.onScoreChanged = function(model) {
      if ($scope.currentQuestion == model) {
        $scope.newquestion.point2 = model.point2;
      }
    };

    $scope.onSubScoreChanged = function(model) {
      var parent = _.find($scope.currentTest.questions, function(q) {
        return q.id == model.parentQuestionId;
      });
      var total = _.reduce(parent.childQuestions, function(total, q) {
        return total + q.point2;
      }, 0);
      parent.point2 = total;
      if ($scope.currentQuestion == parent) {
        var newSelf = _.find($scope.newquestion.childQuestions, function(q) {
          return q.id == model.id;
        });
        newSelf.point2 = model.point2;
        $scope.onDesignSubScoreChanged();
      }
    };

    $scope.setCurrentQuestion = function(q, $event) {
      // don't change question on clicking the score box
      if ($event && $event.target) {
        if ($($event.target).hasClass('score-input') || $($event.target).hasClass('select-label2'))
          return;
      }

      $scope.versionStamp++;
      $scope.loading = $timeout(function() {
        $scope.setCurrentQuestionWithContent(q, $event);
      });
    };

    $scope.setCurrentQuestionWithContent = function(q, $event) {
      if ($scope.currentQuestion == q) {
        return;
      }

      $q.when(checkEditChanged()).then(function() {
        $scope.currentQuestion = q;
        $scope.newquestion = toEditModel(q);
        //
        // tricky here
        // newquestion might be updated by the editor
        // because editor will filter the content firstly with setContent
        // need to copy the content back to the original question:w
        $timeout(function() {
          angular.copy(editToViewModel($scope.newquestion), $scope.currentQuestion);
        }, 0);
      });
    };

    /*        $scope.getCurQ =function(q){
                  $scope.readyDel=q;
              }

              $scope.removeCurQ=function(){
                  $scope.readyDel=null;
              }
*/

    $scope.saveTest = function(showNotify) {
      var curQuestionIndex = _.indexOf($scope.currentTest.questions, $scope.currentQuestion);
      $scope.lastSave = moment();
      $scope.currentState.loading = $scope.currentTest.$save()
        .then(function(test) {
          if (showNotify) {
            notificationService.notify('info', '试卷保存成功')
          }
          if (test.questions[curQuestionIndex]) {
            $scope.currentQuestion = test.questions[curQuestionIndex];
            //如果是删除子题后的保存,需要同时删除design区域的子题
            if ($scope.currentQuestion.id == $scope.newquestion.id) {
              $scope.newquestion = toEditModel($scope.currentQuestion);
            }
          }
          flag = true;

        }).catch(function(error) {
          var errCode = _.get(error, 'status', 500);
          if (errCode == 401 || errCode == 403) {
            notificationService.notify('error', '您的登录状态已改变，请重新登录!')
            return;
          }
          notificationService.notify('error', '试卷保存失败，请联系锐学堂技术支持!')
        });

      return $scope.currentState.loading;
    };

    //批量删除
    $scope.selectedQuestions = {};
    $scope.toggleSelect = function(item) {
      if (_.has($scope.selectedQuestions, item.id)) {
        $scope.selectedQuestions = _.omit($scope.selectedQuestions, item.id);
      } else {
        $scope.selectedQuestions[item.id] = true;
      }
    };
    $scope.isSelected = function(item) {
      return _.has($scope.selectedQuestions, item.id);
    };
    $scope.hasSelected = function() {
      return !_.isEmpty($scope.selectedQuestions);
    };
    $scope.deleteSelected = function() {
      var message = '确定要删除客观题吗（共' + _.size($scope.selectedQuestions) + '题）？';
      var dlg = dialogs.confirm('确定删除?', message);
      dlg.result.then(function() {
        $scope.currentTest.questions = _.filter($scope.currentTest.questions, function(q) {
          return !$scope.isSelected(q);
        });
        $scope.saveTest();
        $scope.selectedQuestions = {};
      });
    };
    $scope.deleteQuestionByIndex = function($event, idx, item) {
      $event.stopPropagation();
      $event.preventDefault();
      var dlg = dialogs.confirm('确定删除？', '确定删除此题吗？');
      dlg.result.then(function(btn) {
        var curIndex = _.indexOf($scope.currentTest.questions, $scope.currentQuestion);
        if (idx >= 0) {
          $scope.currentTest.questions.splice(idx, 1);

          if (idx == curIndex) {
            $scope.newquestion = defaultQuestion();
            $scope.currentQuestion = null;
          }
          $scope.currentTest.isSameAsOrig = false;
          $scope.lastChange = moment();
          if ($scope.isSelected(item)) {
            $scope.selectedQuestions = _.omit($scope.selectedQuestions, item.id);
          }
          $scope.saveTest(false);
        }
      }, function(btn) {});
    };

    $scope.deleteSubQuestionByIndex = function($event, idx, item) {
      $event.stopPropagation();
      $event.preventDefault();
      var dlg = dialogs.confirm('确定删除？', '确定删除此子题吗？');
      dlg.result.then(function() {
        if (idx >= 0) {
          var parent = _.find($scope.currentTest.questions, function(q) {
            return q.id == item.parentQuestionId;
          });
          parent.childQuestions.splice(idx, 1);
          $scope.currentTest.isSameAsOrig = false;
          $scope.lastChange = moment();
          $scope.saveTest(false);
          $scope.onSubScoreChanged(item);
        }
      }, function(btn) {});
    };
    /*$scope.deleteCurrentQuestion = function(){
        var idx = _.indexOf($scope.currentTest.questions, $scope.readyDel);
        if(idx >= 0){
            $scope.currentTest.questions.splice(idx, 1);
            if($scope.readyDel==$scope.currentQuestion) {
               $scope.newquestion = defaultQuestion();
               $scope.currentQuestion = null;
            }
            //$scope.newquestion = defaultQuestion();
            //$scope.currentQuestion = null;
            $scope.readyDel = null;
            $scope.iAmChanged = true;
        }
    };*/

    $scope.goToQuestionByIndex = function(idx) {
      if (idx >= $scope.currentTest.questions.length) {
        console.log('Error: question out of range');
        return;
      }

      var q = $scope.currentTest.questions[idx];
      $scope.currentQuestion = q;
      $scope.newquestion = toEditModel(q);
      //
      // tricky here
      // newquestion might be updated by the editor
      // because editor will filter the content firstly with setContent
      // need to copy the content back to the original question:w
      $timeout(function() {
        angular.copy(editToViewModel($scope.newquestion), $scope.currentQuestion);
      }, 0);

      setTimeout(function() {
        if ($scope.isDone) {
          return;
        }
        //
        // scroll to the new question element after the dom is updated
        var el = $('.ques-view-list');
        el.scrollToElementAnimated($('#question_' + idx));
      }, 0);

    };

    $scope.getCountOfType = function(type) {
      if (!$scope.currentTest) return 0;

      if (type == 'vote') {
        var voteSize = _.sumBy($scope.currentTest.questions, function(q) {
          return (q.type == 'singlevote' || q.type == 'multivote') ? 1 : 0;
        });

        return voteSize;
      } else {
        return _.size(_.filter($scope.currentTest.questions, function(q) {
          return q.type == type;
        }));
      }
    };

    $scope.getScoreOfType = function(type) {
      if (!$scope.currentTest) return 0;

      if (type == 'vote') {
        var singlevote_score =  _.reduce(_.filter($scope.currentTest.questions, function(q) {
          return q.type == 'singlevote';
        }), function(acc, q) {
          return acc + (q.point2 || 0);
        }, 0);

        var multivote_score =  _.reduce(_.filter($scope.currentTest.questions, function(q) {
          return q.type == 'multivote';
        }), function(acc, q) {
          return acc + (q.point2 || 0);
        }, 0);

        return singlevote_score + multivote_score;
      } else {
        return _.reduce(_.filter($scope.currentTest.questions, function(q) {
          return q.type == type;
        }), function(acc, q) {
          return acc + (q.point2 || 0);
        }, 0);
      }
    };

    $scope.getTotalScore = function() {
      if (!$scope.currentTest) return 0;
      return _.reduce($scope.currentTest.questions, function(acc, q) {
        return acc + (q.point2 || 0);
      }, 0);
    };

    $scope.toggleFold = function() {
      $scope.isBlock = !$scope.isBlock;
    };

    //$scope.isBlock2=false;
    $scope.toggleFold2 = function() {
      $scope.isBlock2 = !$scope.isBlock2;
    };

    $scope.editTest = function($event, test) {
      $event.stopPropagation();
      $event.preventDefault();
      var isCurrent = test.id == $scope.currentTest.id;
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/editTestDialog.html'),
        controller: 'editTestCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          test: function() {
            return test;
          },
          existingTests: function() {
            return $scope.tests;
          },
          isPrepareLesson:false
        }
      });

      if (isCurrent) {
        modalInstance.result.then(function() {
          $scope.currentTest.name = test.name;
        });
      }
    };

    var computeQuestionHash = oedTestUtils.computeQuestionHash;

    function downloadTestsById(cloudTestIds) {
      return $q.all(_.map(cloudTestIds, function(tId) {
        return oedObjectiveTest.getDownloadByCloudRes({
          res_id: tId
        }).$promise;
      }));
    }

    function importQuestions(questions, deferred) {
      var testIds = _(questions).filter('$cloudTestId').map('$cloudTestId').keyBy().value();

      return downloadTestsById(testIds).then(function() {
        $q.all(_.map(questions, function(q) {
          if (!_.isEmpty(q.$sourceType)) {
            return {
              $sourceType: q.$sourceType,
              question: q,
            }
          }

          return oedCloudQuestion.getByQuestionId({
            id: q.id
          }).$promise;
        })).then(function(cqs) {
          return importCloudQuestions(cqs, deferred);
        });
      });
    }

    function importCloudQuestions(reses, deferred) {
      if (_.isEmpty(reses)) {
        deferred.resolve(0);
        return deferred.promise;
      }

      $scope.currentState.loading = deferred.promise;

      var allQuestions = _.reduce($scope.currentTest.questions, function(total, ques) {
        total[computeQuestionHash(ques)] = true;
        return total;
      }, {});

      var questionsToImport = _.map(reses, function(res) {
        if (!_.isEmpty(res.$sourceType)) {
          return res.question;
        }
        return oedObjectiveQuestion.download({
          cloudResId: res.id
        });
      });

      var loadingQuestions = _.map(questionsToImport, '$promise');

      var hasDuplicated = false;
      $q.all(loadingQuestions).then(function() {
        var quesToAdd = _.filter(questionsToImport, function(q) {
          if (!_.get(q, 'id') && _.isEmpty(q.$sourceType)) {
            return false;
          }

          var hash = computeQuestionHash(q);
          if (_.has(allQuestions, hash)) {
            return false;
          }

          return true;
        });

        $scope.currentTest.questions = _.reduce(quesToAdd, function(total, ques) {
          var hash = computeQuestionHash(ques);
          allQuestions[hash] = true;
          //
          // 如果导入的问题有源id, 则使用它的源id, 否则用此问题的id作为源id
          if (!ques.sourceId) {
            ques.sourceId = ques.id;
          }
          var newQ = _.omit(ques, ['id', 'recordFlag', 'uid', 'thirdParty', 'thirdPartyId']);

          return total.concat([newQ]);
        }, $scope.currentTest.questions);

        if (!_.isEmpty(quesToAdd)) {
          $scope.currentTest.isSameAsOrig = false;
          $scope.lastChange = moment();
        }
        if (_.size(quesToAdd) != _.size(questionsToImport)) {
          notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
        }

        return $scope.saveTest(false);
      }).then(function(resp) {
        deferred.resolve(resp);
      }).catch(function(m) {
        deferred.reject(m);
      });

      return deferred.promise;
    }

    $scope.importFromLibrary = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/importFromLibraryDialog.html'),
        controller: 'importFromLibraryCtrl',
        size: 'lg',
        windowClass: 'shouldHideOnPreview cl-library-dialog',
        resolve: {
          resourceDef: function() {
            return {
              name: '客观试卷',
              course: $scope.course,
              res: oedCloudTest,
              canImportMultiple: true,
              canImportResBox: true,
              isObjectiveResource: true,
              headerAddonUrl: 'Assets/templates/prepareCourse/cloudObjectiveTestHeaderAddon.html',
              itemTemplateUrl: 'Assets/templates/prepareCourse/cloudObjectiveTestItem.html',
              queryCountURL: oedConfig.url('cloudtest/count2'),
              shouldDismissParent: false,
              queryRes: function(queryOpt, viewType) {
                var items = null;
                var countReq = null;
                var itemsWithQuestion = null;
                var queryCountURL = null;
                if (viewType === 'question') {
                  items = oedCloudQuestion.queryBySection(queryOpt);
                  queryCountURL = oedConfig.url_b('cloudquestion/count2');
                  countReq = $http({
                    url: queryCountURL,
                    method: 'POST',
                    data: queryOpt
                  });

                  itemsWithQuestion = items.$promise.then(function(cloudQuestions) {
                    var loadQuestions = _.map(cloudQuestions, function(cq) {
                      cq.question = oedObjectiveQuestion.view({
                        cloudResId: cq.id
                      });

                      return cq.question.$promise;
                    });

                    return $q.all(loadQuestions);
                  });
                } else {
                  return oedTestUtils.queryCloudTests(queryOpt);
                }

                return [items.$promise, countReq, itemsWithQuestion];
              },
              queryResById: function(query, viewType) {
                var supportedSearchKinds = {
                  objectiveTest: 'test',
                  question: 'question'
                };

                var resId = oedCourseUtils.getCloudResTypeAndId(_.trim(query.queryId));

                if (!resId) {
                  //
                  // resId为null, 判断query是不是一个数字
                  var id = _.parseInt(query.queryId);
                  if (_.isNaN(id)) {
                    return $q.resolve(null);
                  }
                  //
                  // 如果输入是一个数字, 则按客观试卷来处理
                  resId = {
                    kind: 'objectiveTest',
                    id: id
                  };
                }

                var kind = _.get(resId, 'kind');
                //
                // 只支持客观试卷和问题的查询
                if (!_.has(supportedSearchKinds, kind)) {
                  return $q.resolve(null);
                }

                //
                // 如果传入了viewType, 那么如果要查询的id类型不对也返回null
                if (!!viewType && viewType !== supportedSearchKinds[kind]) {
                  return $q.resolve(null);
                } else {
                  viewType = supportedSearchKinds[kind];
                }

                query = _.assign({}, query, {
                  queryId: resId.id
                });

                if (viewType === 'question') {
                  return oedCloudQuestion.get({
                    id: query.queryId
                  }).$promise.then(function(cq) {
                    cq.question = oedObjectiveQuestion.view({
                      cloudResId: cq.id
                    });

                    return cq.question.$promise.then(function() {
                      return cq;
                    });
                  });
                } else {
                  return oedCloudTest.queryByCloudTestOrPreRedId(query)
                    .$promise.then(function(item) {
                      item.cloudResource = _.cloneDeep(item);
                      return oedTestUtils.loadCloudTestDetails(item);
                    });
                }
              },
              doPreview: function(res, resDef) {
                var def = this;
                var modalInstance = $uibModal.open({
                  template: require('assets/templates/prepareCourse/cloudTestPreviewer.html'),
                  controller: 'cloudTestPreviewerCtrl',
                  size: 'lg',
                  resolve: {
                    cloudTest: function() {
                      return res;
                    },
                    resourceDef: function() {
                      return resDef || def;
                    }
                  },
                  windowClass: 'cl-library-preview'
                });

                return modalInstance.result;
              },
              doPreviewResBox: function(items) {
                var def = this;
                var modalInstance = $uibModal.open({
                  template: require('app2/prepareCourse/questionsPreviewer.html'),
                  controller: 'questionsPreviewerCtrl as ctrl',
                  size: 'lg',
                  resolve: {
                    data: function() {
                      return {
                        questions: items
                      };
                    },
                    resourceDef: function() {
                      return def;
                    }
                  },
                  windowClass: 'cl-library-preview'
                });

                return modalInstance.result;
              },
              importFromLibraryByResId: function(viewType, queryData) {
                var def = this;
                var resName = viewType === 'question' ? '试题' : def.name;
                var newResDef = _.assign(def, {
                  viewType: viewType,
                  name: resName
                });

                var modalInstance = $uibModal.open({
                  template: require('assets/templates/prepareCourse/importFromLibraryByResIdDialog.html'),
                  controller: 'importFromLibraryByResIdCtrl',
                  windowClass: 'shouldHideOnPreview cl-library-dialog cl-import-by-res-dialog',
                  size: 'lg',
                  resolve: {
                    resourceDef: function() {
                      return newResDef;
                    },
                    queryData: function() {
                      return queryData;
                    }
                  }
                });
                return modalInstance.result;
              },
              doImport: function(res, deferred, viewType) {
                if (viewType === 'resBox') {
                  return importQuestions(res, deferred);
                } else if (viewType === 'question') {
                  var questions = _.map(res, function(r) {
                    return {
                      id: r.resourceId
                    };
                  });

                  return importQuestions(questions, deferred);
                }

                var tests = _.map(res, function(r) {
                  return oedObjectiveTest.getDownloadByCloudRes({
                    res_id: r.id
                  }).$promise;
                });

                $scope.currentState.loading = deferred.promise;

                var allQuestions = _.reduce($scope.currentTest.questions, function(total, ques) {
                  total[computeQuestionHash(ques)] = true;
                  return total;
                }, {});

                var hasDuplicated = false;
                $q.all(tests).then(function(tests) {
                  //
                  // 只有当前test中没有问题且导入一个试卷时认为和原始试卷相同
                  var sameAsOrigin = _.isEmpty($scope.currentTest.questions) && _.size(tests) == 1;
                  $scope.currentTest.questions = _.reduce(tests, function(total, test) {
                    var quesToAdd = _.filter(test.questions, function(ques) {
                      var hash = computeQuestionHash(ques);
                      if (_.has(allQuestions, hash)) {
                        return false;
                      }

                      allQuestions[hash] = true;
                      return true;
                    });
                    if (hasDuplicated === false && _.size(quesToAdd) != _.size(test.questions)) {
                      hasDuplicated = true;
                    }

                    return total.concat(_.map(quesToAdd, function(q) {
                      //
                      // 如果导入的问题有源id, 则使用它的源id, 否则用此问题的id作为源id
                      if (!q.sourceId) {
                        q.sourceId = q.id;
                      }
                      return _.omit(q, ['id', 'recordFlag', 'uid']);
                    }));
                  }, $scope.currentTest.questions);

                  $scope.currentTest.isSameAsOrig = sameAsOrigin;
                  $scope.lastChange = moment();
                  if (hasDuplicated) {
                    notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
                  }
                  return $scope.saveTest(false);
                }).then(function(resp) {
                  deferred.resolve(resp);
                }).catch(function(m) {
                  deferred.reject(m);
                });

                return deferred.promise;
              },
              defaultSortType: 'use_count',
              extraSortTypes: [{
                name: '正确率',
                value: 'correct_ratio'
              }, {
                name: '使用人数',
                value: 'use_count'
              }]
            };
          }
        }
      });
    };

    $scope.showShowCloudTestNo = function() {
      return !$scope.isEmptyResource($scope.currentTest) && _.isNumber($scope.currentCloudTestId);
    };

    $scope.isEmptyResource = function(test) {
      if (!test) {
        return true;
      }

      return _.get(test, 'isEmpty', false) || _.isEmpty(_.get(test, 'questions'));
    };

    $scope.switchMode = function(m) {
      if (m === 'full') {
        return oedTestUtils.switchEditMode($scope.testId, m);
      }

      return true;
    };

    var questionsOrderBeforeSort = [];

    $scope.sortableOptions = {
      axis: 'y',
      // handle: '.ot-question-header',
      // cancel: '.ot-invalid,input',
      tolerance: 'pointer',
      scrollSpeed: 40,
      scrollSensitivity: 80,
      start: function(e, ui) {
        questionsOrderBeforeSort  = _.map($scope.currentTest.questions, 'id');
        //
        // 当位置变化时应当refresh 位置, 不然有些情况下会有问题
        // 比如当第一个元素高过整个list高时, 如果不refreshPosition会导致无法拖动第一个元素
        ui.placeholder.height(60);
        $(this).sortable('refreshPositions');
      },
      stop: function() {
        var newOrder = _.map($scope.currentTest.questions, 'id');
        if (_.isEqual(questionsOrderBeforeSort, newOrder)) {
          return;
        }

        $scope.$apply(function() {
          $scope.saveTest();
        });
      },
      helper: function(event, el) {
        var header = $('.source-title', el).clone();
        el.css('overflow', 'hidden');
        header.height(60);
        return header;
      }
    };
  }

  addTestCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedObjectiveTest', 'courseId', 'existingTests']
  function addTestCtrl($scope, $q, $uibModalInstance, oedObjectiveTest, courseId, existingTests) {
    $scope.exam = {};
    $scope.exsitingNames = _.map(existingTests, 'name');
    $scope.maxSort = _.max(_.map(existingTests, 'sort'));
    if (isNaN($scope.maxSort)) {
      $scope.maxSort = 1;
    } else {
      $scope.maxSort = $scope.maxSort + 1;
    }
    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.addTestForm.$invalid) {
        return;
      }

      var test = new oedObjectiveTest({
        name: $scope.exam.name,
        courseId: courseId,
        sort: $scope.maxSort
      });
      var p = test.$save();
      $uibModalInstance.close({
        test: test,
        promise: p
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }

  editTestCtrl.$inject = ['$scope', '$q', '$state', '$uibModalInstance', 'oedObjectiveTest', 'test', 'existingTests','isPrepareLesson']
  function editTestCtrl($scope, $q, $state, $uibModalInstance, oedObjectiveTest, test, existingTests, isPrepareLesson) {
    $scope.exam = {
      name: test.name
    };

    $scope.exsitingNames = _(existingTests).filter(function(t) {
      return t.id != test.id;
    }).map('name').value();

    $scope.ok = () => {
      if(isPrepareLesson){
        test.name =  $scope.exam.name;
        $scope.loading = oedObjectiveTest.save(test).$promise;
        $scope.loading.finally(function() {
          $uibModalInstance.close({});
        });
      }else{
        $scope.showValidationError = true;
        if ($scope.editTestForm.$invalid) {
          return;
        }
        _.assign(test, $scope.exam);
        $scope.loading = $q(function(resolve, reject) {
          test = _.omit(test, 'questions')
          test.$save().finally(function() {
            resolve();
            $uibModalInstance.close({});
          });
        });
      }
    };

    $scope.cancel = () => {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
