(function() {
  'use strict';

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.taskListPubDetailsSummary.forClass', {
        url: '/class/:classId',
        template: require('assets/templates/homework/taskListPubDetailsSummaryForClass.html'),
        controller: 'taskListPubDetailsSummaryForClassCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('taskListPubDetailsSummaryForClassCtrl', taskListPubDetailsSummaryForClassCtrl);

  taskListPubDetailsSummaryForClassCtrl.$inject = ['$scope', '$state', '$stateParams', '$q',
    '$uibModal', 'userInfo', 'oedTaskList', 'oedCourse', 'oedCoursePath',
    'dialogs', 'oedTaskListPublishRecord','oedPresentResource', 'oedObjectiveTest', 'oedTestSession',
    'oedTask', 'oedBoardSession', 'oedPreResSession', 'oedTaskTest', 'oedObjectiveTestSessionStats',
    'oedTaskPresentResource', 'oedPreResSessionStudent', 'oedZhongZhiSchool']
  function taskListPubDetailsSummaryForClassCtrl($scope, $state, $stateParams, $q,
    $uibModal, userInfo, oedTaskList, oedCourse, oedCoursePath,
    dialogs, oedTaskListPublishRecord,
    oedPresentResource, oedObjectiveTest, oedTestSession,
    oedTask, oedBoardSession, oedPreResSession,
    oedTaskTest, oedObjectiveTestSessionStats,
    oedTaskPresentResource, oedPreResSessionStudent, oedZhongZhiSchool) {

    var origTaskListId = _.parseInt($stateParams.origTaskListId);
    var classId = _.parseInt($stateParams.classId);
    $scope.origTaskListId = origTaskListId;

    $scope.loadTaskListPublishDetailsForClass = $scope.loadingReadyForChild.then(function() {
      if (!_.isEmpty($scope.classes)) {
        var matchedRecord = _.find($scope.publishRecords, function(r) {
          return r.classId == classId;
        });
        var matchedClass = _.find($scope.classes, function(r) {
          return r.id == classId;
        });
        $scope.$parent.selectedClass = matchedClass;
        var queryPubInfoOfSelectedPublishRecords = loadPubInfoOfPublishRecord(matchedRecord);

        return queryPubInfoOfSelectedPublishRecords.then(function() {
          $scope.publishRecordOfSelectedClass = matchedRecord;
          $scope.publishInfoOfSelectedClass = matchedRecord.publishInfo;
          $scope.taskListOfSelectedClass = matchedRecord.publishInfo.taskList;
          return oedZhongZhiSchool.isZhongZhiSchool().then(isZhongZhiSchool => {
            $scope.isZhongZhiSchool = isZhongZhiSchool;
            return $q.all([getCoursePathName(matchedRecord.publishInfo), loadTestsOfPublishRecord(matchedRecord)]);
          })
        });
      }
    });

    $scope.getRemainingHours = function(record) {
      if (!record || !record.dueDate) {
        return 0;
      }
      return _.ceil((record.dueDate - Date.now()) / (60 * 60 * 1000));
    };

    function getCoursePathName(publishInfo) {
      if (!publishInfo)
        return;
      userInfo.then(function(user) {
        $scope.teacherId = user.uid; 
        return oedTaskList.queryCoursePathsJoinTaskListByTeacherId({
          teacherId: $scope.teacherId
        }).$promise.then(function(res) {
          var courseId = publishInfo.taskList.coursepathId;
          var cp = _.find(res, function(cp) {
            return cp.id === courseId;
          });
          $scope.coursePathName = _.get(cp, 'name') || '已删除课程';
        });
      });
    }

    $scope.getRemaining = function(record) {
      if (!record || !record.dueDate) {
        return 0;
      }
      var hour = _.ceil((record.dueDate - Date.now()) / (60 * 60 * 1000));
      if (hour <= 24)
        return hour + ' 小时';
      return (parseInt(hour / 24) + 1) + ' 天';
    };

    function loadTestsOfPublishRecord(publishRecord) {
      $scope.tasks = oedTask.queryByTaskListId({
        id: publishRecord.taskListId
      });

      $scope.taskResources = {};

      $scope.taskResources = {
        objectiveTests: [],
        subjectiveTests: [],
        presentResources: []
      };

      $scope.submitedStudents = [];

      $scope.tasks.$promise.then(function(tasks) {
        $scope.presentResources = oedPresentResource.queryByTaskListId({
          id: publishRecord.taskListId
        });

        $scope.objAndSbjTests = oedObjectiveTest.queryByTaskListId({
          id: publishRecord.taskListId
        });
        var loadingTasks = _.map(tasks, function(t) {
          var objTests = oedTaskTest.queryTaskObjectiveTestByTaskId({
            id: t.id
          });

          var subjTests = oedTaskTest.queryTaskSubjectiveByTaskId({
            id: t.id
          });

          var preReses = oedTaskPresentResource.queryByTaskId({
            id: t.id
          });

          return $q.all([objTests.$promise, subjTests.$promise, preReses.$promise]).then(function() {
            var objSession = $q.all(_.map(objTests, function(test) {
              var testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
                recordId: publishRecord.id,
                taskId: t.id,
                origTestId: test.testId
              });
              return testSession.$promise.then(function(ts) {
                test.testSession = ts;
                return oedTaskListPublishRecord.queryTestSessionSubmitCount({
                  id: ts.id
                }).$promise.then(function(submitCount) {
                  $scope.submitedStudents = $scope.submitedStudents.concat(_.map(submitCount, 'uid'));
                });
              });
            }));

            var subSession = $q.all(_.map(subjTests, function(test) {
              var testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
                recordId: publishRecord.id,
                taskId: t.id,
                origTestId: test.testId
              });

              return testSession.$promise.then(function(ts) {
                var boardSession = oedBoardSession.queryByTestSessionId({
                  sessionId: ts.id
                });
                var submitCount = oedTaskListPublishRecord.queryTestSessionSubmitCount({
                  id: ts.id
                });
                return $q.all([boardSession.$promise, submitCount.$promise]).then(function() {
                  test.boardSession = boardSession[0];
                  test.sessionId = ts.id;
                  test.boardSessionId = boardSession[0].id;
                  $scope.submitedStudents = $scope.submitedStudents.concat(_.map(submitCount, 'uid'));
                });
              });
            }));

            var showSession = $q.all(_.map(preReses, function(preRes) {
              return oedPreResSession.getPreResSessionByTaskListRecordAndPreResId({
                recordId: publishRecord.id,
                taskId: t.id,
                preResId: preRes.resId
              }).$promise.then(function(preResSession) {
                preRes.sessionId = preResSession.id;
                var preresStudent = oedPreResSessionStudent.queryByPreResSessionId({
                  sessionId: preResSession.id
                });
                return $q.all([preresStudent.$promise]).then(function() {
                  $scope.submitedStudents = $scope.submitedStudents.concat(
                    _.map(_.filter(preresStudent, 'submitTime'), 'studentId'));
                });
              });
            }));

            return $q.all([objSession, subSession, showSession]).then(function() {
              $scope.taskResources.objectiveTests = $scope.taskResources.objectiveTests.concat(objTests);

              $scope.taskResources.subjectiveTests = $scope.taskResources.subjectiveTests.concat(subjTests);

              $scope.taskResources.presentResources = $scope.taskResources.presentResources.concat(preReses);
            });
          });
        });
        return $q.all(loadingTasks);
      });
    }

    $scope.getSubTaskName = function(test) {

      var name = _.result(_.find($scope.objAndSbjTests, function(tmp) {
        return tmp.id == test.testId;
      }), 'name');
      if (!_.isEmpty(name))
        return name;
      else
        return _.result(_.find($scope.presentResources, function(tmp) {
          return tmp.id == test.resId;
        }), 'name');
    };

    function loadPubInfoOfPublishRecord(publishRecord) {
      var query = oedTaskListPublishRecord.queryPublishInfo({
        id: publishRecord.id
      });

      return query.$promise.then(function(pubInfo) {
        publishRecord.publishInfo = pubInfo;
        pubInfo.submitProgress = 0;
        pubInfo.reviewProgress = 0;
        if (pubInfo.totalStudentCount > 0) {
          pubInfo.submitProgress = (pubInfo.totalSubmittedStdCount * 100 / pubInfo.totalStudentCount).toFixed(0);
          pubInfo.reviewProgress = (pubInfo.totalReviewedStdCount * 100 / pubInfo.totalStudentCount).toFixed(0);
        }
      });
    }

    $scope.publishOrigTaskList = function() {
      var existingClsIds = _.map($scope.publishRecords, function(pr) {
        return pr.classId;
      });
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/publishTaskList.html'),
        controller: 'publishTaskListCtrl',
        size: 'md',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          data: function() {
            return {
              taskListId: $scope.originalTaskList.id,
              existingClassIds: existingClsIds
            };
          }
        }
      });
      modalInstance.result.then(function(r) {
        $scope.loading = $scope.loadTaskListPublishDetails();
      });
    };

    function cancelCurrentPublishRecord() {
      if ($scope.selectedClass.id === 0) {
        return $q.all(_.map($scope.publishRecords, function(pr) {
          return oedTaskListPublishRecord.delete({
            id: pr.id
          }).$promise;
        }));
      } else {
        if(_.size($scope.classes) < 2) {
          return $q.all([oedTaskListPublishRecord.delete({
            id: $scope.publishRecordOfSelectedClass.id
          }), oedTaskList.deleteTasklistAndRelatedRecords({id: origTaskListId})]).$promise;
        } else {
          return oedTaskListPublishRecord.delete({
            id: $scope.publishRecordOfSelectedClass.id
          }).$promise;
        }
      }
    }

    function ensureAllRecordPubInfos(records) {
      return $q.all(_.map(records, record => {
        if (!_.isEmpty(record.publishInfo)) {
          return $q.resolve(record.publishInfo);
        }
        return oedTaskListPublishRecord.queryPublishInfo({id: record.id}).$promise.then(pubInfo => {
          record.publishInfo = pubInfo;
          return pubInfo;
        });
      }));
    }

    $scope.showTable = function() {
      $uibModal.open({
        template: require('assets/app2/review/objOverAllStatTable.html'),
        controller: 'objOverAllStatTableCtrl',
        windowClass: 'oedmodal modalCenter',
        size: 'lg',
        resolve: {
          data: function() {
            return {
              classId: $scope.publishRecordOfSelectedClass.classId,
              publishRecordId: $scope.publishRecordOfSelectedClass.id,
              totalResourceCount: _.size($scope.taskResources.objectiveTests) +
              _.size($scope.taskResources.subjectiveTests) + _.size($scope.taskResources.presentResources)
            };
          }
        }
      });
    };

    $scope.updateCurrentPublishRecord = function() {
      var existingClsIds = _.map($scope.classes, function(cls) {
        return cls.id;
      });

      ensureAllRecordPubInfos($scope.publishRecords)
        .then(whatever => {
          var selectedClassStudents = {};
          _.each($scope.publishRecords, record => {
            selectedClassStudents[record.classId] = _.get(record, 'publishInfo.publishedStudents', []);
          });

          $uibModal.open({
            template: require('assets/templates/homework/updatePublishTaskList.html'),
            controller: 'updatePublishTaskListCtrl',
            size: 'md',
            windowClass: 'oedmodal modalCenter',
            resolve: {
              data: function() {
                return {
                  taskList: $scope.taskListOfSelectedClass,
                  publishRecord: $scope.publishRecordOfSelectedClass,
                  publishRecords: $scope.publishRecords,
                  selectedClassStudents,
                  existingClassIds: existingClsIds,
                  objAndSbjTests: $scope.objAndSbjTests,
                  presentResources: $scope.presentResources,
                  submitedStudents: _.uniq($scope.submitedStudents),
                  courseId: $scope.taskListOfSelectedClass.courseId ? $scope.taskListOfSelectedClass.courseId : null
                };
              }
            }
          });
        });
    };

    $scope.refreshCurrentPublishRecord = function() {
      var existingClsIds = _.map($scope.classes, function(cls) {
        return cls.id;
      });
      ensureAllRecordPubInfos($scope.publishRecords)
        .then(whatever => {
          var selectedClassStudents = {};
          _.each($scope.publishRecords, record => {
            selectedClassStudents[record.classId] = _.get(record, 'publishInfo.publishedStudents', []);
          });

          $uibModal.open({
            template: require('assets/templates/homework/refreshPublishTaskList.html'),
            controller: 'refreshPublishTaskListCtrl',
            size: 'md',
            windowClass: 'oedmodal modalCenter',
            resolve: {
              data: function() {
                return {
                  taskList: $scope.taskListOfSelectedClass,
                  publishRecord: $scope.publishRecordOfSelectedClass,
                  publishRecords: $scope.publishRecords,
                  selectedClassStudents,
                  taskResources: $scope.taskResources,
                  existingClassIds: existingClsIds,
                  courseId: $scope.taskListOfSelectedClass.courseId ? $scope.taskListOfSelectedClass.courseId : null
                };
              }
            }
          });
        });
    }

    $scope.cancelCurrentPublishRecord = function() {
      var dlg = dialogs.confirm('确定取消作业', '确定取消' + $scope.selectedClass.className + '的作业?');
      dlg.result.then(function(btn) {
        var promises = cancelCurrentPublishRecord();
        $q.all(promises).then(function() {
          if (!$scope.classes && _.size($scope.classes) > 1)
            $scope.loading = $scope.loadTaskListPublishDetails();
          else
            $state.go('homework.taskListsV2.forStatus', {
              status: 'all'
            }, {
              location: 'replace'
            });
        });
      });
    };

    $scope.deleteOrigTaskList = function() {
      var dlg = dialogs.confirm('确定删除作业', '确定取消所有已发布班级的作业, 并且从作业列表中移除该作业?');
      dlg.result.then(function(btn) {
        var promises = $q.all(_.map($scope.publishRecords, function(pr) {
          return oedTaskListPublishRecord.delete({
            id: pr.id
          }).$promise;
        }));

        $q.all(promises).then(function() {
          oedTaskList.delete({
            id: $scope.originalTaskList.id
          }).$promise.then(function() {
            $state.go('homework.taskLists', {}, {
              location: 'replace'
            });
          });
        });
      });
    };

    $scope.loading = $scope.loadTaskListPublishDetailsForClass;
  }
})();
