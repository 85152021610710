import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OedUibmodalService } from 'app/core/oed-uibmodal.service';
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { TeachingPatternDataService } from 'app/lesson/teaching-pattern-data.service';
import * as _ from 'lodash'

@Component({
  selector: 'app-module-drop-down',
  template: require('./module-drop-down.component.html'),
  styles: [require('./module-drop-down.component.scss')]
})
export class ModuleDropDownComponent implements OnInit {

  @Input() public courseId: number
  @Input() public moduleId: number
  @Input() public moduleName: string
  @Input() public disable: boolean
  constructor(
    private oedUibmodalService: OedUibmodalService,
    private lessonMessageService: LessonMessageService,
    private patternDataService: TeachingPatternDataService,
  ) { }

  public ngOnInit() {
  }

  public importObjTest() {
    if (this.disable) {
      this.lessonMessageService.sendSaveRecommendCourseAction()
      return
    }
    this.oedUibmodalService.openObjEditModal(this.courseId, null, this.moduleId, this.moduleName).then((ret) => {
      if (this.moduleId) {
        this.patternDataService.setLastImportObjectiveTests(this.moduleId, _.get(ret, 'importedTests', []), [], [])
      }
      this.reloadCourseResource()
    }, () => {
      this.reloadCourseResource()
    })
  }

  public reloadCourseResource() {
    this.lessonMessageService.sendReloadPatternAction()
  }

  public importPreRes() {
    if (this.disable) {
      this.lessonMessageService.sendSaveRecommendCourseAction()
      return
    }
    this.oedUibmodalService.openPreResModal(this.courseId, null, this.moduleId, this.moduleName).then((ret) => {
      if (this.moduleId) {
        this.patternDataService.setLastImportObjectiveTests(this.moduleId, [], [], _.get(ret, 'importedTests', []))
      }
      this.reloadCourseResource()
    }, () => this.reloadCourseResource())
  }

  public importSbjTest() {
    if (this.disable) {
      this.lessonMessageService.sendSaveRecommendCourseAction()
      return
    }
    this.oedUibmodalService.openSubResModal(this.courseId, null, this.moduleId, this.moduleName).then((ret) => {
      if (this.moduleId) {
        this.patternDataService.setLastImportObjectiveTests(this.moduleId, [], _.get(ret, 'importedTests', []), [])
      }
      this.reloadCourseResource()
    }, () => this.reloadCourseResource())
  }

}
