import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-oed-counter',
  template: require('./oed-counter.component.html'),
  styles: [require('./oed-counter.component.scss')]
})
export class OedCounterComponent implements OnInit {

  @Input() theNumber: number
  @ViewChild('input1') theInput: ElementRef
  @Output() numberChange = new EventEmitter<any>()
  constructor(
    private render: Renderer2
  ) { }

  ngOnInit() {
  }

  private minusOneQues($event) {
    if (this.theNumber > 0) {
      this.theNumber = Number(this.theNumber) - 1;
    }
    this.numberChange.emit({count: this.theNumber})
    $event.stopPropagation()
    $event.preventDefault()
  }
  private plusOneQues($event) {
    if(this.theNumber < 50) {
      this.theNumber = Number(this.theNumber) + 1
    }
    this.numberChange.emit({count: this.theNumber})
    $event.stopPropagation()
    $event.preventDefault()
  }

  private inputDone() {
    if (isNaN(parseInt(String(this.theNumber)))) {
      this.theNumber = 5
      this.numberChange.emit({count: 5})
    } else if (Number(this.theNumber) > 50) {
      this.numberChange.emit({count: 50})
    } else if (Number(this.theNumber) < 0) {
      this.numberChange.emit({count: 0})
    } else {
      this.numberChange.emit({count: this.theNumber})
    }
  }
  private nullClick($event) {
    $event.stopPropagation()
    $event.preventDefault()
  }
}
