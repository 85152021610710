(function() {
  angular.module('app.activate', ['ui.router'])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('activate', {
        url: '/activate/:code',
        template: require('assets/templates/signin/activate.html'),
        controller: 'activateCtrl',
        bodyStyle: 'newstyle'
      });
    }])
    .controller('activateCtrl', activateCtrl);

  activateCtrl.$inject = ['$scope', '$http', '$state', 'oedUserInfo', '$stateParams']
  function activateCtrl($scope, $http, $state, oedUserInfo, $stateParams) {
    $scope.code = $stateParams.code;
    $scope.message = '激活中，请稍后...';
    var activateAccount = oedUserInfo.activateAccount({
      code: $scope.code
    });
    $scope.loading = activateAccount.$promise.then(function(resp) {
      if (!resp.success) {
        $scope.message = resp.message;
        return resp;
      }
      $scope.message = '已激活，跳转中...';
      var msg = $(resp.message);

      if (!_.isEmpty(msg.attr('src'))) {
        $http.get(msg.attr('src'));
      }

      if (resp.user.schoolId < 0) {
        $state.go('joinSchool');
      } else {
        $state.go('home');
      }

      return resp;
    }).catch(function(reason) {
      $scope.message = '激活失败, 发生未知错误';
    });
  }
})();
