import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast } from 'ngx-toastr';
import { NotifyLevel } from '../../models/notify'
import { VersionNotify } from './version-notify.component';
import { NewPrepareNotify } from './new-prepare-notify.component';
import { Notify } from './notify.component';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }

  /**
   * messageClass value : oed-message-info-text || oed-message-error-text
   * toastClass value : oed-message-info-bg || oed-message-error-bg
   * @private defaultConf
   * @memberof NotificationService
   */
  private defaultConf = {
    enableHtml: false,
    easeTime: 300,
    timeOut: 1000,
    closeButton: false,
    maxOpened: 1,
    easing: 'ease-in',
    positionClass: 'toast-top-center',
    messageClass: 'oed-message-info-text',
    toastClass: 'ngx-toastr oed-message-info-bg',
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut',
    toastComponent: Notify
  }

  private versionModalConf = {
    easeTime: 300,
    disableTimeOut: true,
    easing: 'ease-in',
    positionClass: 'toast-top-center',
    toastComponent: VersionNotify
  }

  private newPrepareModalConf = {
    easeTime: 300,
    timeOut: 1000,
    easing: 'ease-in',
    positionClass: 'toast-top-center',
    pointPosition: '',
    toastComponent: NewPrepareNotify
  }

  /**
   * @param {NotifyLevel} type
   * @param {string} messge
   * @param {string} [title]
   * @param {number} [duration]
   * @memberof NotificationService
   */
  public notify(type: NotifyLevel, messge: string, title?: string, duration?: number) {
    if (duration) {
      this.defaultConf.timeOut = duration
    }
    if (type === 'error') {
      this.defaultConf.messageClass = 'oed-message-error-text'
      this.defaultConf.toastClass = 'ngx-toastr oed-message-error-bg'
    } else if (type === 'success') {
      //
    } else if (type === 'waring') {
      //
    } else {
      this.defaultConf.messageClass = 'oed-message-info-text'
      this.defaultConf.toastClass = 'ngx-toastr oed-message-info-bg'
    }
    this.toastr.show(messge, title, this.defaultConf)
  }

  public error(message: string, title?: string, duration?: number) {
    this.notify('error', message, title, duration)
  }

  public versionNotify() {
    this.toastr.show('发现新版本, 请保存您的工作后点击更新！', '', this.versionModalConf)
  }

  public newPrepareNotify(messge: string, pointPosition: string, duration?: number) {
    if (duration) {
      this.newPrepareModalConf.timeOut = duration
    }
    if (pointPosition) {
      this.newPrepareModalConf.pointPosition = pointPosition
    }
    this.toastr.show(messge, '', this.newPrepareModalConf)
  }


  /**
 * notifyAlways
 */
  public notifyAlways(type: NotifyLevel, messge: string, title?: string) {
    if (type === 'error') {
      this.defaultConf.messageClass = 'oed-message-error-text'
      this.defaultConf.toastClass = 'ngx-toastr oed-message-error-bg'
    } else if (type === 'info'){
      this.defaultConf.messageClass = 'oed-message-info-text'
      this.defaultConf.toastClass = 'ngx-toastr oed-message-info-bg'
    }
    return this.toastr.show(messge, title, {
      ...this.defaultConf,
      disableTimeOut: true,
      onActivateTick: true,
      enableHtml: true,
      tapToDismiss: false,
      closeButton: true,
    })
  }

  remove(toast: ActiveToast<any>) {
    this.toastr.remove(toast.toastId)
  }
}
