(function() {
  angular.module('app.prepareCourse') //声明angular模块
    .controller('addTaskListCtrl', addTaskListCtrl);

  addTaskListCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedTaskList', 'data']
  function addTaskListCtrl($scope, $q, $uibModalInstance, oedTaskList, data) {
    //如果传入了 taskList,是编辑操作,否则是添加操作
    if (data.taskList) {
      $scope.taskList = data.taskList;
      $scope.isNewRecord = false;
    } else {
      $scope.taskList = new oedTaskList({
        courseId: data.courseId
      });
      $scope.isNewRecord = true;
    }

    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.addTaskListForm.$invalid) {
        return;
      }

      var taskList = $scope.taskList;
      $scope.loading = taskList.$save().then(function() {
        $uibModalInstance.close({
          taskList: taskList
        });
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
