import { OedDialogService } from 'app/core/oed-dialog.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import * as _ from 'lodash'
import { NotificationService } from 'app/shared/notification/notification.service';
import { OedExerciseService } from '../oed-exercise.service'
import { LoadingService } from 'app/shared/loading/loading.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-import-json',
  template: require('./import-json.component.html'),
  styles: [require('./import-json.component.scss')]
})
export class ImportJsonComponent implements OnInit {
  @Input() exerciseBookId: number;
  @Input() exerciseBookName: string;
  @Input() subjectId: number;
  @Input() levelId: number;
  @ViewChild('fileInput') fileInput;
  hasJson: any = false;
  hasTestPaper: any = false;
  isEdit: any = true;
  jsonHasEdit: any = false;
  uploader: FileUploader;
  uploadUrl = '/ray/api/a/ceditor/uploadresource';
  uploadFileName = '';
  jsonObject: any;
  canUpload = true;
  errorPosition
  // 每页节点数
  countPerPage = 50;
  // 非法节点 
  illegalNodeIdList = [];
  loading = false;
  responseId: any;

  constructor(private loadingService: LoadingService,
    private oedDialogService: OedDialogService,
    public notifyService: NotificationService,
    public oedExerciseService: OedExerciseService
    ) {
    this.uploader = new FileUploader({
      url: this.uploadUrl,
    });

    this.uploader.onAfterAddingFile = (items: any) => {
      // 开始上传
      this.uploader.queue[0].upload();
      this.uploader.queue[0].onSuccess = (response, status) => {
        // 上传文件成功
        if (status == 200) {
            if(_.get(JSON.parse(response), 'id') === this.responseId) {
              this.notifyService.notify('error', '请勿重复上传');
              return;
            }
            // 上传文件后获取服务器返回的数据
            this.responseId = _.get(JSON.parse(response), 'id');
            this.oedExerciseService.importJson(this.exerciseBookId, this.responseId)
            .pipe(this.loadingService.withLoading())
            .subscribe((data)=> {
              if(data) {
                this.hasJson = true;
                this.canUpload = !this.hasJson;
                this.jsonObject = data;
                // this.uploadFileName = _.get(items, 'some.name');
              }
              this.uploader.clearQueue();
            },  (error) => {
              this.notifyService.notify('info', '上传文件为非法文件，请检查后再上传');
              this.uploader.clearQueue();
            });
        } else {
            // 上传文件后获取服务器返回的数据错误
            this.notifyService.notify('info', '上传失败，请稍后再试')
        }
      };
    }

    this.uploader.onProgressItem = (item, progress) => {
      if (progress < 100) {
        console.log(progress);
      }
      item.onComplete = (res) => {
        item.progress = 110
      }
    };

  }

  ngOnInit() {
    this.getInitData();
  }

  public getInitData() {
    return forkJoin(this.oedExerciseService.getJsonInExeriseBook(this.exerciseBookId),
    this.oedExerciseService.getTestsByExBookId(this.exerciseBookId))
    .pipe(this.loadingService.withLoading())
    .subscribe((data)=> {
      if(data[0] && !_.isEmpty(data[0])) {
        this.hasJson = true;
        this.canUpload = !this.hasJson;
        this.jsonObject = data[0];
      }
      this.hasTestPaper = _.size(data[1])
    });
  }

  public selectEdit() {
    this.isEdit = true;
  }

  public selecPreview() {
    this.isEdit = false;
  }

  private onPaperList = () => {
    this.oedDialogService.openModalWithComponent2(
      'ExPaperListDialogComponent',
      { exerciseBookId: this.exerciseBookId },
      { class: 'ex-dialog-common' }
    ).subscribe(data => {
      this.hasTestPaper = data;
    })
  }
  public sureCanUpload() {
      this.oedDialogService.openModalWithComponent2('ExInfoDialogComponent', {
        title: '警告',
        info: '导入新的Json文件会使当前内容全部丢失。是否继续?',
        isConfirm: true,
        btnText: '继续',
      }, {
         class: 'ex-dialog-common'
      }).subscribe(data => {
        if(data) {
          this.fileInput.nativeElement.click();
        }
      })
  }

  release() {
    this.loading = true;
    this.oedExerciseService.releaseExercise(this.exerciseBookId)
    .subscribe((data)=> {
      this.loading = false;
      if(_.get(data, 'status') === 'success') {
        this.notifyService.notify('info', '习题册发布成功');
      } else {
        const errorMsg = _.get(data, 'errorMsg');
        this.illegalNodeIdList = _.get(data, 'invalidSynQuestionNodeIds');
        if(_.includes(errorMsg,'invalidSynQuestion')) { 
          const page = this.getErrorPosition(this.illegalNodeIdList);
          this.oedDialogService.openModalWithComponent2('ExInfoDialogComponent', {
            title: '发布失败',
            info: `您有未关联子题的综合题，请返回修改后再发布。（位置：第${page}页）`,
            isConfirm: true,
            btnText: '返回修改',
          }, {
             class: 'ex-dialog-common'
          }).subscribe(data => {
            if(data) {
              this.selectEdit();
            }
          })
        }
      }
    })
  }

  getErrorPosition(dataIdList) {
    const errorPageList = _.uniq(_.map(dataIdList, id => {
      const jsonIndex = _.findIndex(this.jsonObject, data => _.get(data,'node.id') === Number(id));
      if(jsonIndex !== -1) {
        const errorPage = Math.floor((jsonIndex + 1) / this.countPerPage) + 1;
        return errorPage;
      }
    }))
    return errorPageList.sort().join('、')
  }

  changeEditStatus(status) {
    this.jsonHasEdit = status;
  }

  changeTestPaper($event) {
    this.hasTestPaper = $event;
  }
}
