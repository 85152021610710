(function() {
  'use strict';

  angular.module('app.prepareCourse') //声明angular模块
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareCourse.taskList', {
        url: '/task/list',
        template: require('assets/templates/prepareCourse/taskList.html'),
        controller: 'taskListCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'bg_img'
      });
    }]).controller('taskListCtrl', taskListCtrl);

  taskListCtrl.$inject = ['$scope', '$q', '$state', '$stateParams', '$uibModal',
    'dialogs', 'oedTaskList', 'oedTaskListPublishRecord']
  function taskListCtrl($scope, $q, $state, $stateParams, $uibModal,
    dialogs, oedTaskList, oedTaskListPublishRecord) {

    $scope.currentState.title = '课后作业设计';

    function loadTaskListItems() {
      $scope.taskListItems = oedTaskList.queryByCourseId({
        courseId: $stateParams.courseId
      });

      $scope.loading = $scope.taskListItems.$promise.then(function() {
        $scope.taskListItems = _.reverse($scope.taskListItems);
        _.map($scope.taskListItems, function(item) {
          item.visibility = 'hidden';
          oedTaskListPublishRecord.queryByTaskListId({
            id: item.id
          }).$promise.then(function(records) {
            item.published = records.length === 0;
          });
        });
      });
    }

    $scope.showTaskOption = false;
    $scope.toggleSel = function() {
      $scope.showTaskOption = !$scope.showTaskOption;
    };

    $scope.course.$promise.then(function() {
      $scope.addTaskList = function() {
        $scope.taskList = new oedTaskList({
          courseId: _.parseInt($stateParams.courseId),
          name: $scope.course.courseName + moment().format('YYMMDD') + '作业',
          description: ''
        });

        $scope.taskList.$save().then(function(data) {
          //$scope.taskList = data;
          loadTaskListItems();
          $state.go('prepareCourse.taskDesign', {
            taskListId: $scope.taskList.id
          }, {});
        });
      };
    });

    loadTaskListItems();

    $scope.hoverTaskList = function(item, obj) {
      item.visibility = 'visible';
    };

    $scope.hoverOutOfTaskList = function(item, obj) {
      item.visibility = 'hidden';
    };

    $scope.deleteTaskList = function(item, $event) {
      $event.stopPropagation();
      var dlg = dialogs.confirm('确定删除作业', '确定取消所有已发布班级的作业,并且从作业列表中移除该作业?');
      dlg.result.then(function(btn) {
        $scope.publishRecords = oedTaskListPublishRecord.queryByTaskListId({
          id: item.id
        });

        var promises = $scope.publishRecords.$promise.then(_.map($scope.publishRecords, function(record) {
          return record.$delete();
        }));
        $q.all(promises).then(function() {
          oedTaskList.delete({
            id: item.id
          });
        }).finally(function() {
          loadTaskListItems();
        });
      });
    };
  }
})();
