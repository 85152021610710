import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import _ = require('lodash');
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TeachingModuleDTO } from '../../models/lesson';
import { LessonService } from '../../services/lesson.service'
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-edit-teaching-module',
  template: require('./edit-teaching-module.component.html'),
  styles: [require('./edit-teaching-module.component.scss')]
})
export class EditTeachingModuleComponent implements OnInit {
  @Input() public module: TeachingModuleDTO
  @Output() public onSave = new EventEmitter<TeachingModuleDTO>();
  private moduleCloned: TeachingModuleDTO

  constructor(
    private bsModalRef: BsModalRef,
    private lessonService: LessonService,
    private notifyService: NotificationService,
    private lessonMessageService: LessonMessageService,
    ) { }

  public ngOnInit() {
    this.moduleCloned = _.clone(this.module)
  }

  public ok() {
    if (_.isEmpty(this.moduleCloned.name)) {
      this.notifyService.notify('info', '教学环节名称不能为空')
    } else {
      this.lessonService.saveTeachingModule(this.moduleCloned).subscribe((m: TeachingModuleDTO) => {
        this.close()
        this.onSave.emit(m)
      }, (err: any) => {
        console.log(err)
        this.notifyService.notify('info', '保存失败')
      })
    }

  }

  public close() {
    this.bsModalRef.hide()
  }

}
