import { Component, OnInit, Input, SimpleChanges, OnChanges, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash'

import { answerTypeNames, answerTypeTips, maxFillAnswerLength } from '../fill-answer-designer/fill-designer.config'
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-fill-answer-item',
  template: require('./fill-answer-item.component.html'),
  styles: [require('./fill-answer-item.component.scss')],
})
export class FillAnswerItemComponent implements OnChanges, OnInit {
  @Input() answerItems: string[]
  @Input() answerType: string
  @Input() answerPoint: number
  @Input() disableChangeAnswerType: boolean
  @Input() disableChangePoint: boolean

  answerTypes = answerTypeNames

  @Output() answerItemsChange = new EventEmitter<string[]>()
  @Output() answerPointChange = new EventEmitter<number>()
  @Output() answerTypeChange = new EventEmitter<string>()
  @Output() onValidationError = new EventEmitter<any>()
  @Output() onInit = new EventEmitter<any>()

  public validators = [this.validateLength]

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.onInit.emit()
    })
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  ngOnDestroy() {
  }

  onPointChange(v: number) {
    this.answerPointChange.emit(v)
  }

  selectedAnswerTypeName(): string {
    return answerTypeNames[this.answerType]
  }

  onSelectAnswerType(answerType: string) {
    this.answerType = answerType
    this.answerTypeChange.emit(answerType)
  }

  handleItemChange(items: string[]) {
    this.answerItems = items
    this.answerItemsChange.emit(items)
  }

  handleValidationError(error: any) {
    this.onValidationError.emit(error)
  }

  isManualScore() {
    return this.answerType === 'manual-score'
  }

  isValid() {
    return this.isManualScore() || !_.isEmpty(this.answerItems)
  }

  answerTip() {
    return answerTypeTips[this.answerType]
  }

  validateLength(control: FormControl) {
    if (control.value.length > maxFillAnswerLength) {
      return {
        'maxTextLength': true
      };
    }
  }
}
