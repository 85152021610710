import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-train-levels',
  template: require('./train-levels.component.html'),
  styles: [require('./train-levels.component.scss')]
})
export class TrainLevelsComponent implements OnInit {
  @Input() public dirs
  @Input() public prefixText
  @Input() public selectedDir
  @Output() public onSelect = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'dirs')) {
      this.dirs = changes.dirs.currentValue
    }

    if (!_.isEmpty(this.dirs)) {
      this.selectedDir = this.selectedDir ? this.selectedDir : this.dirs[0]
    }
  }

  public select(dir) {
    this.selectedDir = dir
    this.onSelect.emit(this.selectedDir)
  }

}
