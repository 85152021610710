import * as angular from 'angular';
import _ = require('lodash');

import { QuestionAnswerStatus } from 'app2/types/answerResult';

require('app2/utils/testUtils');
require('assets/styles/answer.scss');

angular.module('app.directives.question.stdAnswerResult', [])
.component('stdAnswerResult', {
  bindings: {
    isDrawPlay: '<',
    getAnswerText: '&',
    isSyntheticChildQues: '=',
    ques: '=ngModel',
    reviewQa: '&',
    reviewQaClear: '&',
    submitQaPoint: '&',
  },
  controller: stdAnswerResultCtrl,
  template: <string> require('./stdAnswerResult.html'),
});

stdAnswerResultCtrl.$inject = ['oedTestUtils', '$uibModal']
function stdAnswerResultCtrl(oedTestUtils: any, $uibModal: any) {
  const ctrl = this;

  ctrl.getCorrectRate = (question: any) => {
    const correct = question.quesStat.countAnsweredCorrectAndHesitate;
    const hesitate = question.quesStat.countAnsweredCorrectNotHesitate;
    const total = question.quesStat.countAnsweredTotal;
    if (total !== 0) {
      return (correct + hesitate) * 100 / total;
    }
    return 0;
  };

  function getAnswerStatus(q: any): QuestionAnswerStatus {
    if (q.question.type === 'fill' && q.answer) {
      const correctDetails = oedTestUtils.parseFillAnswerCorrectDetails(
        _.get(q.answer, 'correctDetails', ''),
        q.question,
      );
      if (correctDetails.blankPendingReviewCount > 0) {
        return 'pendingReview'
      } else if (correctDetails.blankRightCount > 0 && correctDetails.blankWrongCount > 0) {
        return 'rightAndWrong'
      } else if (correctDetails.blankRightCount > 0) {
        return 'right'
      }
    }

    if (q.question.type !== 'qa' && q.answer) {
      const correct = (q.question.answer === q.answer.answer) || q.answer.correct;
      return correct ? 'right' : 'wrong'
    }

    const cd = _.get(q.answer, 'correctDetails', '');
    if (cd === '-1') {
      return 'pendingReview'
    } else if (cd === '0') {
      return 'wrong'
    } else if (cd === '1') {
      return 'right'
    } else if (cd === '2') {
      return 'pendingReview'
    }

    return 'wrong'
  }

  ctrl.getAnswerBoxBgClass = (q: any) => {
    switch (getAnswerStatus(q)) {
      case 'right':
        return 'answer-right-box'
      case 'rightAndWrong':
      case 'wrong':
        return 'answer-error-box'
      case 'pendingReview':
        return 'answer-pending-review-box'
    }
  }

  ctrl.getAnswerStatus = (q: any) => {
    switch (getAnswerStatus(q)) {
      case 'right':
        return '回答正确'
      case 'rightAndWrong':
        return '半对'
      case 'wrong':
        return '回答错误'
      case 'pendingReview':
        return '待批'
    }
  }

  ctrl.getQaAnswerIsCorrect = (q: any) => {
    const cd = _.get(q.answer, 'correctDetails', '');
    if (cd === '-1') {
      return '待批';
    } else if (cd === '0') {
      return '回答错误';
    } else if (cd === '1') {
      return '回答正确';
    } else if (cd === '2') {
      return '半对';
    }
    return '回答正确';
  }

  ctrl.getAnswerTextColorClass = (q: any) => {
    if (q.question.type === 'fill' && q.answer) {
      const correctDetails = oedTestUtils.parseFillAnswerCorrectDetails(
        _.get(q.answer, 'correctDetails', ''),
        q.question,
      );
      if (correctDetails.blankPendingReviewCount > 0) {
        return 'answer-correct-hesitate-text-color';
      } else if (correctDetails.blankRightCount > 0 && correctDetails.blankWrongCount > 0) {
        return 'answer-error-text-color';
      } else if (correctDetails.blankRightCount > 0) {
        return 'answer-correct-text-color';
      }
    }

    if (q.question.type !== 'qa' && q.answer) {
      const correct = (q.question.answer === q.answer.answer) || q.answer.correct;
      return correct ? 'answer-correct-text-color' : 'answer-error-text-color';
    }
    const cd = _.get(q.answer, 'correctDetails', '');
    if (cd === '-1') {
      return 'answer-pending-text-color';
    } else if (cd === '0') {
      return 'answer-error-text-color';
    } else if (cd === '1') {
      return 'answer-correct-text-color';
    } else if (cd === '2') {
      return 'answer-correct-hesitate-text-color';
    }
    return 'answer-error-text-color';
  }

  ctrl.showQaAnswerCorrectResult = (q: any) => {
    if (!q.answer) {
      return false;
    }
    const cd = _.get(q.answer, 'correctDetails', '');
    return q.question.type === 'qa' && q.answer.answer && cd !==  String(-1);
  }

  ctrl.getQuestionClasses = (q: any) => {
    if (!q) {
      return '';
    }

    // 未作答
    if (!q.answer) {
      return 'ques-review-unanswered';
    }

    if (q.question.type === 'fill') {
      // 填空题
      const correctDetails = oedTestUtils.parseFillAnswerCorrectDetails(
        _.get(q.answer, 'correctDetails', ''),
        q.question,
      );
      if (correctDetails.blankPendingReviewCount > 0) {
        return 'qa-ques-pending-review';
      } else if (correctDetails.blankRightCount > 0 && correctDetails.blankWrongCount > 0) {
        return 'fill-ques-right-wrong';
      } else if (correctDetails.blankRightCount > 0) {
        return 'ques-review-right';
      }
      return 'ques-review-wrong';
    } else if (q.question.type === 'qa') {
      const cd = _.get(q.answer, 'correctDetails', '');
      if (cd === '-1') {
        return 'qa-ques-pending-review';
      } else if (cd === '0') {
        return 'ques-review-wrong';
      } else if (cd === '1') {
        return 'ques-review-right';
      } else if (cd === '2') {
        return 'fill-ques-right-wrong';
      }
      return 'ques-review-wrong';
    } else {
      // 非填空题
      if ((q.question.answer === q.answer.answer) || q.answer.correct) {
        return 'ques-review-right';
      }
      return 'ques-review-wrong';
    }
  };

  ctrl.reviewQaAndPoint = () => {
    return ctrl.reviewQa({ques: ctrl.ques, point: 2}).then(() => {
      const modalInstance = $uibModal.open({
        controller: 'qaAnswerPointCtrl',
        resolve: {
          data: () => {
            return {
              ques: ctrl.ques,
              submit: (q: any) => {
                return ctrl.submitQaPoint({ques: q}).then(() => {
                  modalInstance.close();
                }, (error: any) => {
                  console.log(error);
                });
              },
            };
          },
        },
        size: 'qa-point-box-size',
        template: require('assets/app2/review/qaAnswerPoint.html'),
        windowClass: 'oedmodal qa-center-modal',
      });
    }, (error: any) => {
      console.log(error);
    })
  }
}
