interface DialogOpts {
  component: string;
  size: string;
  title: string;
  message: string;
  uibModal: any;
  callback: any;
  callbackParams: any;
  yesText: string;
}
export function openDialog(params: DialogOpts) {
  const modalInstance = params.uibModal.open({
    component: params.component,
    size: params.size,
    resolve: {
      data: () => {
        return {
          title: params.title,
          msg: params.message,
          yesText: params.yesText
        }
      }
    }
  })

  return modalInstance.result.then((r) => {
    if (r) {
      return params.callback(params.callbackParams)
    }
  })
}
