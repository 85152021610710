
require('core-js')
/* vendor js */
require('angular');
// require('angular-ui-router');
require('angular-resource');
require('angular-cookies');
require('vendor/html5shiv/dist/html5shiv.js');
// require('vendor/respond/dest/respond.src.js');

require('angular-sanitize');
require('moment');
require('moment/locale/zh-cn');
require('angular-ui-bootstrap');
require('lodash');
require('angular-moment');

require('vendor/bootstrap/dist/js/bootstrap.js');
require('ui-select');
require('vendor/angular-ueditor/dist/angular-ueditor.js');
require('vendor/ng-file-upload/ng-file-upload.js');
require('vendor/jquery-ui/jquery-ui.js');
require('vendor/angular-dragdrop/src/angular-dragdrop.js');
require('angular-animate');
require('angular-busy');
require('vendor/angular-bootstrap-lightbox/dist/angular-bootstrap-lightbox.js');
require('../../node_modules/angular-ui-tree/dist/angular-ui-tree.min.js')
require('angularjs-slider');
require('vendor/angular-ui-utils/ui-utils.js');
require('angular-translate');
require('vendor/angular-dialog-service/dist/dialogs.min.js');
require('vendor/angular-dialog-service/dist/dialogs-default-translations.min.js');
require('vendor/angular-scroll/angular-scroll.js');
require('vendor/ng-img-crop/compile/minified/ng-img-crop.js');
require('vendor/angular-ui-sortable/sortable.js');
require('@cgross/angular-notify');
require('vendor/ng-resize/ngresize.js');
require('vendor/bootstrap-switch/dist/js/bootstrap-switch.js');
require('vendor/angular-bootstrap-switch/dist/angular-bootstrap-switch.js');
require('vendor/ng-tags-input/ng-tags-input.js');
require('vendor/jstree/dist/jstree.js');
require('vendor/ng-js-tree/dist/ngJsTree.js');
require('angular-messages');
require('d3');
require('vendor/nvd3/build/nv.d3.js');
require('vendor/angular-local-storage/dist/angular-local-storage.js');
require('vendor/angular-loading-bar/build/loading-bar.js');
require('angular-cache');
require('vendor/angular-xeditable/dist/js/xeditable.js');
require('bowser');
require('vendor/angular-timer/dist/angular-timer.min.js');
require('vendor/humanize-duration/humanize-duration.js');
require('vendor/angular-ui-indeterminate/dist/indeterminate.js');
require('bluebird');
require('interact.js');
require('angular-i18n/angular-locale_zh-cn.js');
require('angular-htmldiff');

require('vendor/swiper/dist/js/swiper.js');
require('vendor/angular-swiper/src/angular-swiper.js');

require('vendor/jquery-ui-touch-punch/jquery.ui.touch-punch.js');

require('assets/3rdParty/ueditor/ueditor.all.js');
require('assets/3rdParty/ueditor/lang/zh-cn/zh-cn.js');
require('assets/3rdParty/ueditor/oAudio/addoAudioDialog.js');
require('assets/3rdParty/ueditor/microLessonRecord/addRecordDialog.js');
require('assets/3rdParty/ueditor/kityformula-plugin/addKityFormulaDialog.js');
require('assets/3rdParty/ueditor/kityformula-plugin/getKfContent.js');
require('assets/3rdParty/ueditor/kityformula-plugin/defaultFilterFix.js');
require('assets/3rdParty/ueditor/fillButton/fillButton.js');

