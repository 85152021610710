(function() {
  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentSignout', {
        url: '/anno/studentSignout',
        template: require('assets/templates/signout.html'),
        controller: 'studentSignOutCtrl',
        bodyStyle: 'signout'
      });
    }])
    .controller('studentSignOutCtrl', studentSignOutCtrl);

  studentSignOutCtrl.$inject = ['$http', '$state', '$q', '$timeout', 'oedConfig']
  function studentSignOutCtrl($http, $state, $q, $timeout, oedConfig) {
    var signOutUrl = oedConfig.url('login/logout');
    $http.get(signOutUrl).finally(function() {
      $state.go('signinStudent');
    });
  }
})();
