// import Promise = require('bluebird')
import $ = require('jquery')
import _ = require('lodash')
import { convertChoiceToQa, convertFillToQa, convertSyntheticToQa } from './rayQaUtils'

const blank = '_____'
const circleNumMap = {
  '⓪': 0,
  '①': 1,
  '②': 2,
  '③': 3,
  '④': 4,
  '⑤': 5,
  '⑥': 6,
  '⑦': 7,
  '⑧': 8,
  '⑨': 9,
  '⑩': 10,
}

function parseChoiceAnswer(data): number[] {
  const answers = $(`<div>${data}</div>`).text()
  return _.chain(answers).trim().split(',').map((answer: string) => {
    const aCode = 'a'.charCodeAt(0);
    const answerCode = answer.toLowerCase().charCodeAt(0);
    return answerCode - aCode;
  }).value()
}

function parseChoiceOption(option) {
    return {
      content: `<p>${_.get(option, 'content', '')}</p>`,
    }
}

function choiceDataExtractor(type: string, q) {
  // 选项数只能从答案中反推...
  const answer = (_.chain(q).get('answer') as any).map(parseChoiceAnswer).flatten().join(',').value() // tslint:disable-line
  const choices = (_.chain(q).get('options') as any)
    .sortBy((o) => _.toUpper(_.get(o, 'label'))).map(parseChoiceOption).value()  // tslint:disable-line
  const question = _.get(q, 'stem', '')

  if (_.isEmpty(answer) || _.includes(answer, '-1')) {
    return convertChoiceToQa(question, choices)
  }

  return {
    question,
    answer,
    choices,
    type,
  }
}

function fillQuestionExtractor(answerType: string, q) {
  const stem = _.get(q, 'stem', '') || ''
  const pattern1 = /<u>(\s)*(&nbsp;)*(\s)*<\/u>/g
  const pattern2 = /__+/g
  const pattern3 = /_____+/g
  // const pattern4 = /<span( )*(data-ph( )*=.*)>(___*)<\/span>/g

  const content = _.chain(stem).replace(pattern1, blank).replace(pattern2, blank).value()

  const blankCount = content.match(pattern3)

  if (_.isEmpty(blankCount)) {
    return convertFillToQa(content)
  }

  // 如果答案为空，则手动批改
  const answer = (_.chain(q).get('answer', []) as any).map(a => _.split(a, '<i>;&nbsp;</i>')).flatten().value(); // tslint:disable-line
  if (_.isEmpty(answer)) {
    answerType = 'manual-score'
  }

  const answerStr = JSON.stringify({
    // tslint:disable-next-line:no-shadowed-variable
    answer: _.map(blankCount, (blank: any, index: number) => {
      const text = $(`<div>${_.get(answer, index, '')}</div>`).text()
      return [_.chain(text).split('').map(a => _.get(circleNumMap, a, a)).join('').value()]
    }),
    answerPoints: _.map(blankCount, () => 1),
    // tslint:disable-next-line:no-shadowed-variable
    answerTypes: _.map(blankCount, (blank: any, index: number) => {
      const text = _.get(answer, index, '')
      if ($(`<div>${text}</div>`).text() === '') {
        return 'manual-score'
      }
      return guessAnswerType(text)
    }),
  })
  return {
    answer: answerStr,
    point2: _.size(answer),
    question: `<p>${content}</p>`,
    type: 'fill',
  }
}

function guessAnswerType(answer: string) {
  if (answer.indexOf('&plusmn;') >= 0 || answer.indexOf('&times;') >= 0 || answer.indexOf('答案不唯一') >= 0
  || answer.indexOf('&#39;') || answer.indexOf('&shy;')) {
    return 'manual-score'
  }
  if ($('<div></div>').html(answer).find('img').length > 0 || $('<div></div>').html(answer).find('sup').length > 0) {
    return 'manual-score'
  }
  return 'exact-match'
}

function qaDataExtractor(q) {
  const question = _.get(q, 'stem', '') || ''
  return {
    question: _.isEmpty(question) ? q.Stem : question,
    answerDetails: (_.chain(q).get('analysis', []) as any).join('\n').value(), // tslint:disable-line
    answer: '-1',
    type: 'qa',
  }
}

function answerDetailsExtractor(q) {
  const answerDetails = (_.chain(q).get('analysis', []) as any).join('\n').value()
  return {
    answerDetails,
  }
}

const rightAnswerFlag = 'TW对√A'
function parseYesornoAnswer(q: any) {
  const answer = _.get(q, 'answer', '')
  return _.includes(rightAnswerFlag, answer) ? '1' : '0'
}

function yesOrNoQuestionExtractor(q: any) {
  const answer = parseYesornoAnswer(q)
  const question = _.get(q, 'stem', '') || ''
  return {
    question,
    answer,
    type: 'yesorno',
  }
}

function connectQuesExtrator(q) {

}

function syntheticQuestionExtractor(q) {
  const childQuestions = (_.chain(q).get('subsets', []) as any)
  .map(convertTalAreaQuestionToRayQ).value()
  const $valid = _.size(childQuestions) > 0
  if (!$valid) {
    return convertSyntheticToQa(_.get(q, 'stem', ''))
  }
  return {
    childQuestions,
    type: 'synthetic',
  }
}

const simpleQaExtractor = [qaDataExtractor]
/* tslint:disable: object-literal-key-quotes */
const convertConfigs = {
  '单选题': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'singlechoice')],
  '选择题': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'singlechoice')],
  '多选题': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'multichoice')],
  '双选题': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'multichoice')],
  '判断题': [answerDetailsExtractor, yesOrNoQuestionExtractor],
  //'连线题': [answerDetailsExtractor, connectQuesExtrator],
  //'匹配题': [answerDetailsExtractor, connectQuesExtrator],
  '填空题': [answerDetailsExtractor, _.partial(fillQuestionExtractor, 'exact-match')],
  '语法填空': [answerDetailsExtractor, _.partial(fillQuestionExtractor, 'exact-match')],
  '语言表达': [answerDetailsExtractor, syntheticQuestionExtractor],
  '翻译': [answerDetailsExtractor, qaDataExtractor],
  // '文言文阅读': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '现代文阅读': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '连词成句': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '默写': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '诗歌鉴赏': [answerDetailsExtractor, syntheticQuestionExtractor],
  '综合题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '书写': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '写作题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '语段阅读': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '计算题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '作图题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '应用题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '完形填空': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '阅读理解': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '句型转换': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '选词填空（词汇运用）': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '补全对话': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '单词拼写（词汇运用）': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '书面表达': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '听力题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '排序题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '情景交际': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '音标题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '实验探究题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '辨析题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '名著导读': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '改错题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '任务型阅读': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '材料分析题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '推断题': [answerDetailsExtractor, syntheticQuestionExtractor],
  // '列举题': [answerDetailsExtractor, syntheticQuestionExtractor],
}

function commonDataExtractor(q: any) {
  const fromThirdParty = 'talAreaRes'
  const fromThirdPartyId = `${_.get(q, 'id')}`
  const questionBody = _.get(q, 'stem', '')
  const sort = 0
  return {
    $sourceType: 'talAreaRes',
    $examTypeName: _.get(q, 'examTypeName', ''),
    $zujuanTimes: _.get(q, 'zujuanTimes', 0),
    fromThirdParty,
    fromThirdPartyId,
    point2: 1,
    question: questionBody,
    $valid: true,
    sort,
  }
}

export const convertTalAreaQuestionToRayQ = (q) => {

  q.$valid = true
  const commQ = commonDataExtractor(q)
  let conveters: any
  const typeName = q.typeName

  if (_.has(convertConfigs, typeName)) {
    conveters = _.flatten([_.get(convertConfigs, typeName)])
  } else {
    conveters = simpleQaExtractor
    console.warn(`question transformation for ${typeName} not implemented`)
  }

  const qData: any = _.map(conveters, (c: any) => c(q))
  const allData = (<any> _.spread)(_.defaults, 1)({}, qData)

  const rayQ = {
    ...commQ,
    ...allData,
    $valid: q.$valid,
    $isTalAreaRes: true,
  }
  return rayQ
}
