import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { BoardContentInteractsMDTO, BoardContentFlagDTO, BoardContentDTO, BoardContentInteractsMDTOView, BoardContentCommentDTO, LegacyPairDTO, BoardContentCommentBodyDTO } from 'app/models/sbj-test-session';
import { UserDTO } from 'app/models/user';

type CountFunc = (interact: BoardContentInteractsMDTOView) => number;
type CountType = 'bravosCount' | 'commentsCount' | 'flagsCount' | 'time' | 'viewsCount';

@Component({
  selector: 'app-sbj-std-content',
  template: require('./sbj-std-content.component.html'),
  styles: [require('./sbj-std-content.component.scss')]
})
export class SbjStdContentComponent implements OnInit {
  @Input() boardSessionId: number;
  @Input() interactsSorted: BoardContentInteractsMDTOView[];
  @Output() showDetailsForStudent = new EventEmitter<number>();
  @Output() toggleSelect = new EventEmitter<BoardContentInteractsMDTOView>();

  countInteract = {
    bravosCount: (interact: BoardContentInteractsMDTOView) => {
      return _.size(interact.bravosList);
    },
    commentsCount: (interact: BoardContentInteractsMDTOView) => {
      const rankComments: any = _.filter(_.map(interact.commentsList, 'right.commentBody.rank'), (rank: any) => {
        return !_.isUndefined(rank)
      })

      return _(interact.commentsList).filter(this.isVisibleComment as any).size()
    },
    flagsCount: (interact: BoardContentInteractsMDTOView) => _.size(interact.flagsList),
    time: (interact: BoardContentInteractsMDTOView) => interact.contentList[0].submitTime,
    viewsCount: (interact: BoardContentInteractsMDTOView) => {
      return _.size(interact.viewsList);
    },
  }

  constructor() { }

  ngOnInit() {
  }

  public flagsExist (flags: [UserDTO, BoardContentFlagDTO][]) {
    return !_.isEmpty(flags)
  }

  public contentOnlyText(content: BoardContentDTO) {
    if (_.isEmpty(content.boardContentThumbnail) && !_.isEmpty(content.contentBody)) {
      try {
        const body = JSON.parse(content.contentBody)
        if (!_.isEmpty(body.resources)) {
          return false;
        } else {
          return true;
        }
      } catch (ex) {
        return false;
      }
    }

    return false;
  }

  public getContentText(content: BoardContentDTO) {
    if (_.isEmpty(content.boardContentThumbnail) && !_.isEmpty(content.contentBody)) {
      try {
        const body = JSON.parse(content.contentBody)
        if (!_.isEmpty(body.content)) {
          return _.truncate(body.content, {
            length: 30,
          });
        } else {
          return '';
        }
      } catch (ex) {
        return '';
      }
    }

    return '';
  }

  public getContentThumbnail (content: BoardContentDTO) {
    if (_.isEmpty(content.boardContentThumbnail) && !_.isEmpty(content.contentBody)) {
      try {
        const body = JSON.parse(content.contentBody)
        if (!_.isEmpty(body.resources)) {
          const res = body.resources[0]
          if (!_.isEmpty(res.resourceUUID)) {
            return `/ray/resource/uuid/${res.resourceUUID}/img_thumb`
          }
          return `/ray/resource/view/img_thumb/${res.id}/?size=big`
        }
      } catch (ex) {
        console.log(ex);
      }
    }

    return `/ray/resource/board/${content.id}/thumbnail`
  }

  public isSelected (interact: BoardContentInteractsMDTOView) {
    return !!interact.selected;
  }

  public getInteractCorrectClass(interact: BoardContentInteractsMDTOView) {
    const rank = _.get(interact, 'student.rank', 0);
    if (rank === 2) {
      return 'correct';
    }
    if (rank === 1) {
      return 'half-correct';
    }

    if (rank === 0) {
      return 'wrong';
    }

    return '';
  }

  public isVisibleComment(c: LegacyPairDTO<UserDTO, BoardContentCommentDTO<string | BoardContentCommentBodyDTO>>) {
    const cmt = c.right;
    return !_.isEmpty(cmt.comment) ||  !_.isEmpty(_.get(cmt.commentBody, 'content', null)) ||
      !_.isEmpty(_.get(cmt.commentBody, 'resources', null)) || cmt.imgResId || cmt.imgResUuid;
  }


  public getCountByType(interact: BoardContentInteractsMDTOView, t: CountType) {
    return this.countInteract[t](interact)
  }

  public onShowDetailsForStudent(uid: number) {
    this.showDetailsForStudent.emit(uid)
  }

  public onToggleSelect(interact: BoardContentInteractsMDTOView) {
    this.toggleSelect.emit(interact)
  }
}
