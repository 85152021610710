(function() {
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.personalSettings', {
        url: '/personalSettings',
        template: require('assets/templates/manage/personalSettings.html'),
        controller: 'personalSettingsCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('personalSettingsCtrl', personalSettingsCtrl)
    .controller('changePasswordCtrl', changePasswordCtrl)
    .controller('changeAvatarCtrl', changeAvatarCtrl)
    .controller('imageCropCtrl', imageCropCtrl);

  personalSettingsCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal',
    'notificationService', 'userInfo', 'oedUserInfo', 'oedSubjects', 'oedOem']
  function personalSettingsCtrl($scope, $state, $stateParams, $q, $uibModal,
    notificationService, userInfo, oedUserInfo, oedSubjects, oedOem) {
    $scope.currentState.title = '个人设置';

    $scope.subjects = [];
    $scope.info = {};
    $scope.userInfoService = userInfo;
    $scope.enableManage = oedOem.curOem() != 'hal2'

    $scope.isTeacher = true;
    $scope.isStudent = false;

    $scope.getUserGender = function(gender) {
      return gender === 'female' ? '女' : '男'
    }

    userInfo.then(function(info) {
      $scope.userInfo = info;
      $scope.info = {
        id: info.uid,
        name: info.name,
        gender: info.gender,
        email: info.email,
        mobile: info.mobile,
        shareResource: info.shareResource,
        validClassSession: info.validClassSession
      };
      $scope.showEffective = !!$scope.info.validClassSession;

      $scope.isTeacher = _.indexOf(info.roles, 'teacher') >= 0
      $scope.isStudent = _.indexOf(info.roles, 'student') >= 0
      $scope.isSchoolAdmin = !!info.isSchoolAdmin;

      // $scope.subjects = oedSubjects.queryBySchoolId({
      //     schoolId: info.schoolId
      // });

      // $scope.info.subjects = oedSubjects.queryByTeacherId({
      //     id: info.uid
      // });

      // $scope.loading = $q.all([$scope.subjects.$promise, $scope.info.subjects.$promise]).then(function(){
      //     var newSubjects = [];
      //     _.each($scope.info.subjects, function(sub){
      //         var idx = _.findIndex($scope.subjects, function(s){
      //             return s.id == sub.subjectId;
      //         });
      //         if(idx >= 0){
      //             newSubjects.push($scope.subjects[idx]);
      //         }
      //     });
      //     $scope.info.subjects = newSubjects;
      // });

      $scope.validateMobile = function(mobile) {
        var reMobile = /^\d{11}$/;
        return mobile && mobile.match(reMobile);
      };
      // $scope.changeEffective = function(){
      //   $scope.showEffective= !$scope.showEffective;
      //   $scope.info.validClassSession = $scope.showEffective? 1 : 0;
      //   oedUserInfo.changeVaildSession({validClassSession: $scope.info.validClassSession}).$promise.then((data) => {
      //     if(data.success){
      //       userInfo.update();
      //     }
      //   })
      // };
      $scope.updateSettings = function(settings) {
        $scope.showFormError = true;
        if ($scope.updateForm.$invalid) {
          return;
        }

        var res = oedUserInfo.save(settings);

        $scope.loading = res.$promise.then(function(resp) {
          if (resp.sucess) {
            $scope.message = null;
            userInfo.update();
          } else {
            $scope.message = resp.message;
          }
          notificationService.notify('info', '个人信息修改成功')
          userInfo.update();
        });
      };

    });
  }

  changePasswordCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal',
    'notificationService', 'userInfo', 'oedUserInfo', 'oedSubjects']
  function changePasswordCtrl($scope, $state, $stateParams, $q, $uibModal,
    notificationService, userInfo, oedUserInfo, oedSubjects) {
    $scope.message = null;
    $scope.loading = null;
    $scope.pwd = {};
    userInfo.then(function(info) {
      $scope.pwd.id = info.uid;
      $scope.updatePassowrd = function(pwd) {
        $scope.showUpdatePasswordError = true;
        if ($scope.updatePasswordForm.$invalid) {
          return;
        }

        if ($scope.updatePasswordForm.$invalid) {
          return;
        }
        $scope.loading = oedUserInfo.updatePassowrd(pwd, function(resp) {
          if (resp.success) {
            $scope.updatePasswordMessage = null;
            userInfo.update();
            notificationService.notify('info', '密码修改成功')
          } else {
            $scope.updatePasswordMessage = resp.message;
          }

          userInfo.update();
        });
      };
    });
  }

  changeAvatarCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal', 'userInfo', 'oedUserInfo']
  function changeAvatarCtrl($scope, $state, $stateParams, $q, $uibModal, userInfo, oedUserInfo) {
    $scope.message = null;
    $scope.loading = null;
    $scope.pwd = {};

    userInfo.then(function(info) {
      $scope.info = info;

      $scope.$watch('files', function(newVal) {
        if (!newVal) return;

        //
        // show the image crop dialog
        var modalInstance = $uibModal.open({
          template: require('assets/templates/utils/cropImageDialog.html'),
          controller: 'imageCropCtrl',
          size: 'md',
          resolve: {
            file: function() {
              return newVal[0];
            }
          }
        });

        modalInstance.result.then(function(ret) {
          if (!ret || !ret.image) return;
          var req = oedUserInfo.updateAvatar({
            id: info.uid,
            image: ret.image
          });

          $scope.loading = req.$promise.then(function() {
            userInfo.updateCounter();
          });
        });
      });
    });
  }

  imageCropCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal', '$uibModalInstance', 'file']
  function imageCropCtrl($scope, $state, $stateParams, $q, $uibModal, $uibModalInstance, file) {
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    var reader = new FileReader();
    reader.onload = function(evt) {
      $scope.$apply(function($scope) {
        $scope.srcImg = evt.target.result;
      });
    };
    reader.readAsDataURL(file);

    $scope.resultImg = null;

    $scope.ok = function() {
      $uibModalInstance.close({
        image: $scope.resultImg
      });
    };
  }
})();
