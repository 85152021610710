import { errorPageStates } from './error-page-routin.state';
import { NoPermissionComponent } from './no-permission/no-permission.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { SharedModule } from 'app/shared/shared.module';
import { MenuModule } from '../menu/menu.module'

@NgModule({
  declarations: [
    NoPermissionComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    TypeaheadModule.forRoot(),
    SharedModule,
    UIRouterUpgradeModule.forChild({ states: errorPageStates, }),
    MenuModule,
  ]
})
export class ErrorPageModule { }
