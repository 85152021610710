(function() {
  angular.module('app.directives.domLoadedRender', [])
    .directive('domLoadedRender', domLoadedRender);

  domLoadedRender.$inject = ['$timeout']
  function domLoadedRender($timeout) {
    return {
      restrict: 'A',
      link: function(scope, element, attr) {
        if (scope.$last === true) {
          $timeout(function() {
            scope.$emit('domLoaded');
          });
        }
      },
    };
  }
})();
