(function() {
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.manageSchool', {
        url: '/manageSchool',
        template: require('assets/templates/manage/manageSchool.html'),
        controller: 'manageSchoolCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('manageSchoolCtrl', manageSchoolCtrl);

  manageSchoolCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$http', '$uibModal',
    'dialogs', 'notificationService', 'oedConfig', 'oedSchool', 'oedActivateCode']
  function manageSchoolCtrl($scope, $state, $stateParams, $q, $http, $uibModal,
    dialogs, notificationService, oedConfig, oedSchool, oedActivateCode) {
    $scope.currentState.title = '学校列表';

    $scope.pageSize = 20;
    $scope.curPage = $stateParams.page || 1;
    $scope.name = $stateParams.query;

    $scope.query = {};

    function loadSchools() {
      $scope.showSchoolMsg = false;
      $scope.schools = oedSchool.queryByName({
        name: $scope.name,
        curPage: $scope.curPage,
        countPerPage: $scope.pageSize
      });

      $scope.schools.$promise.then(function(res) {
        $scope.showSchoolMsg = true;
        var schoolIds = [];
        _.forEach(res, item => {
          schoolIds.push(item.id);
        });
        oedSchool.getSchoolAdminsBySchoolIds(schoolIds).$promise.then(function (adm) {
          _.forEach(res, item => {
            _.each(adm, (val, key)=>{
              if (item.id == key) {
                item.uids = _.map(val, 'uid').join(',');
                item.admins = val;
              }
            })
          });
        })
      });

      var countPromise = $http.post(oedConfig.url('school/count'), {
        name: $scope.name
      }).then(function(resp) {
        $scope.totalNums = resp.data.count;
      });

      $scope.loading = $q.all([$scope.schools.$promise, countPromise]);
    }

    loadSchools();

    $scope.doSearch = function(arg) {
      $scope.name = $scope.query.name;
      $scope.curPage = 1;
      loadSchools();
    };

    /**
     * 首为学校打开激活，则弹出对话框输入激活码的数目，并生成指定数量的激活码；
     * 之后这些激活码可能会被使用；
     * 之后再关闭激活，则已经生成或分配的激活码在数据库中保留；
     * 再次打开激活时，只需要更新flag，不需要再去重新生成激活码
     */
    function enableActivate(school) {
      if (_.get(school, 'activateCodeCount', 0) > 0) {
        enableActivationWithExistingCodes(school);
      } else {
        enableActivationWithSpecifiedCount(school);
      }
    }

    function enableActivationWithExistingCodes(school) {
      $scope.loading = oedActivateCode.enableSchoolActivation(
        {schoolId: school.id},
        {schoolId: school.id}
      ).$promise.then(function(s) {
        school.activateCodeAutoAssign = false;
        school.activateEnabled = true;
        school.activateCodeCount = s.activateCodeCount;
      }).catch(function(e) {
        notificationService.notify('error', '打开激活失败')
      });
    }

    function enableActivationWithSpecifiedCount(school) {
      const modalInstance = $uibModal.open({
        template: require('assets/templates/manage/assignActivateCode.html'),
        controller: 'assignActivateCodeCtrl',
        size: 'sm',
        windowClass: 'signupModal',
        resolve: {
          school: function() {
            return school;
          },
          existingCodes: function() {
            return [];
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r.success) {
          notificationService.notify('info', '分配成功')
        }
      });
    }

    function disableActivate(school) {
      $scope.loading = oedActivateCode.disableSchoolActivation(
        {schoolId: school.id},
        {schoolId: school.id}
      ).$promise.then(function(s) {
        school.activateCodeAutoAssign = s.activateCodeAutoAssign;
        school.activateEnabled = false;
      }).catch(function(e) {
        notificationService.notify('error', '关闭激活失败')
      });
    }

    $scope.toggleActivateEnabled = function(school) {
      const enabled = _.get(school, 'activateEnabled', false);
      if (!enabled) {
        enableActivate(school);
      } else {
        disableActivate(school);
      }
    }

    $scope.toggleActivateCodeAutoAssign = function(school) {
      const enabled = _.get(school, 'activateCodeAutoAssign', false);
      if (!enabled) {
        const activateEnabled = _.get(school, 'activateEnabled', false);
        if (!activateEnabled) {
          return notificationService.notify('error', '请先打开激活')
        }

        $scope.loading = oedActivateCode.enableSchoolActivateCodeAutoAssign(
          {schoolId: school.id},
          {schoolId: school.id}
        ).$promise.then(function(s) {
          school.activateCodeAutoAssign = true;
        }).catch(function(e) {
          notificationService.notify('error', '打开激活码自动分配失败')
        });
      } else {
        $scope.loading = oedActivateCode.disableSchoolActivateCodeAutoAssign(
          {schoolId: school.id},
          {schoolId: school.id}
        ).$promise.then(function(s) {
          school.activateCodeAutoAssign = false;
        }).catch(function(e) {
          notificationService.notify('error', '关闭激活码自动分配失败')
        });
      }
    };

    $scope.toggleValue = function(school) {
      const enabled = _.get(school, 'value', false);
      var val = enabled === '1' ? '0' : '1';
      var str = val === '1' ? '启用' : '关闭';
      oedSchool.setSchoolNewPre({
        schoolId: school.id,
        value: val
      }).$promise.then(function(s) {
        school.value = s.value;
        notificationService.notify('info', str + '新版备课成功')
      }).catch(function(e) {
        notificationService.notify('info', str + '新版备课失败')
      });
    };

    $scope.schoolPreferenceAuthorityMap = {}

    $scope.screenBroadCastMap = {}

    loadschoolPreference();

    loadSchoolScreenBroadCastConfig();

    function loadSchoolScreenBroadCastConfig() {
      $scope.schools.$promise.then(function(res) {
        _.forEach(res, school => {
          const schoolId = _.get(school, 'id', '')
          oedSchool.getSchoolScreenBroadCastPreference({
            schoolId: _.get(school, 'id', ''),
          }).$promise.then(function(r) {
            const showZL = _.get(r, 'value', '') ===  '1'
            $scope.screenBroadCastMap[schoolId] = showZL;
          }).catch(function(e) {
            $scope.screenBroadCastMap[schoolId] = false
            notificationService.notify('error', `获取${_.get(school, 'schoolName', '')}屏幕广播权限失败`)
          });
        });
      })
    }

    function loadschoolPreference() {
      $scope.schools.$promise.then(function(res) {
        _.forEach(res, school => {
          const schoolId = _.get(school, 'id', '')
          oedSchool.getSchoolPreference({
            schoolId: _.get(school, 'id', ''),
          }).$promise.then(function(r) {
            const showZL = _.get(r, 'value', '') ===  '1'
            $scope.schoolPreferenceAuthorityMap[schoolId] = showZL;
          }).catch(function(e) {
            $scope.schoolPreferenceAuthorityMap[schoolId] = false
            notificationService.notify('error', `获取${_.get(school, 'schoolName', '')}中联资源权限失败`)
          });
        });
      })
    }

    $scope.isShowZL = function(school) {
      const schoolId = _.get(school, 'id', '')
      const enabled = $scope.schoolPreferenceAuthorityMap[schoolId]
      return _.isNil(enabled) ? false : enabled
    }

    $scope.screenBroadCastEnabled = function(school) {
      const schoolId = _.get(school, 'id', '')
      const enabled = $scope.screenBroadCastMap[schoolId]
      return _.isNil(enabled) ? false : enabled
    }

    $scope.toggleShowZL = function(school) {
      const schoolId = _.get(school, 'id', '')
      // 0: 无权限  1:有权限
      const enabled = $scope.schoolPreferenceAuthorityMap[schoolId]
      var val = enabled ? '0' : '1'
      var str = enabled ? '关闭' : '启用'
      oedSchool.setSchoolPreference({
        schoolId: schoolId,
        authority: val,
      }, {}).$promise.then(function(s) {
        $scope.schoolPreferenceAuthorityMap[schoolId] = !enabled
        notificationService.notify('info', str +'中联资源成功')
      }).catch(function(e) {
        notificationService.notify('error', str +'中联资源失败')
      });
    }

    $scope.toggleEnableScreenBroadCast = function(school) {
      const schoolId = _.get(school, 'id', '')
      // 0: 无权限  1:有权限
      const enabled = $scope.screenBroadCastMap[schoolId]
      var val = enabled ? '0' : '1'
      var str = enabled ? '关闭' : '启用'
      oedSchool.setSchoolScreenBroadCastPreference({
        schoolId: schoolId,
        value: val,
      }, {}).$promise.then(function(s) {
        $scope.screenBroadCastMap[schoolId] = !enabled
        notificationService.notify('info', str +'屏幕广播成功')
      }).catch(function(e) {
        notificationService.notify('error', str +'屏幕广播失败')
      });
    }

    $scope.viewSchoolActivateCodes = function(school) {
      $uibModal.open({
        template: require('assets/templates/manage/viewSchoolActivateCodes.html'),
        controller: 'viewSchoolActivateCodesCtrl',
        size: 'lg',
        windowClass: 'signupModal',
        resolve: {
          school: function() {
            return school;
          }
        }
      });
    }

    $scope.onPageChange = function(query, page) {
      $scope.showSchoolMsg = false;
      $scope.curPage = page;
      loadSchools();
    };

    $scope.createSchool = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/postLogin/createSchool.html'),
        controller: 'createSchoolCtrl',
        size: 'md',
        resolve: {
          idNo: function() {
            return $scope.idNo;
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r.success) {
          notificationService.notify('info', '学校创建成功')
          loadSchools();
        }
      });
    };

    $scope.editSchool = function(school) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/manage/editSchoolInfoDialog.html'),
        controller: 'editSchoolInfoDialogCtrl',
        size: 'md',
        resolve: {
          school: function() {
            return school;
          }
        }
      });
    };

    $scope.updateAdmin = function(school) {
      // var modalInstance = $uibModal.open({
      //   template: require('assets/templates/manage/setSchoolAdminDialog.html'),
      //   controller: 'setSchoolAdminDialogCtrl',
      //   size: 'sm',
      //   resolve: {
      //     school: function() {
      //       return school;
      //     }
      //   }
      // });
      var modalInstance = $uibModal.open({
        template: require('assets/templates/manage/setSchoolAdminsDialog.html'),
        controller: 'setSchoolAdminsDialogCtrl',
        size: 'md',
        resolve: {
          school: function() {
            return school;
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r === 'success') {
          notificationService.notify('info', '信息更新成功')
        }
      });
    };

    $scope.remove = function(school) {
      var dlg = dialogs.confirm('删除学校', '你确定要删除此学校吗？');
      dlg.result.then(function() {
        $scope.loading = oedSchool.delete({
          id: school.id
        }).$promise.then(function() {
          notificationService.notify('info', '信息更新成功')
          loadSchools();
        });
      });
    };
  }
})();
