/**
 * Created by ryoushikaoru on 2019/3/5.
 */
(function() {
  angular.module('app.prepareCourse')
    .controller('openCtrl', openCtrl);

  openCtrl.$inject = ['$scope', 'data']
  function openCtrl($scope, data) {
    $scope.testId = data.testId;
    $scope.courseId = data.courseId;
  }
})();
