import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoadingService } from 'app/shared/loading/loading.service';
import { OedExerciseService } from './../../oed-exercise.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-ex-create-paper-dialog',
  template: require('./ex-create-paper-dialog.component.html'),
  styles: [require('./ex-create-paper-dialog.component.scss')]
})
export class ExCreatePaperDialogComponent implements OnInit {
  @Input() private nodeId: number
  @Input() private testName: string
  @Input() private nodeName: string
  @Output() private action = new EventEmitter<boolean>()

  private duration = 45

  constructor(
    private bsModalRef: BsModalRef,
    private notifyService: NotificationService,
    private oedExerciseService: OedExerciseService,
    private loadingService: LoadingService,
  ) { }

  ngOnInit() { }

  private onCheckTime = () => {
    if (this.duration <= 0 || this.duration%1 != 0) {
      this.notifyService.notify('error', '答题时长必须大于0分钟，且不能为小数')
      this.duration = 45
    }
  }

  private checkCreateDisable = () => this.testName === ''

  private onCreateTest = () => {
    if (this.testName === '') {
      this.notifyService.notify('error', '试卷名称不能为空')
      return
    }
    if (this.duration <= 0) {
      this.notifyService.notify('error', '答题时长必须大于0分钟')
      return
    }
    const body = {
      nodeId: this.nodeId,
      testName: this.testName,
      duration: this.duration
    }
    this.oedExerciseService.createTest(body)
      .pipe(this.loadingService.withLoading())
      .subscribe(
        () => {
          this.notifyService.notify('info', '试卷创建成功')
          this.action.emit(true);
          this.bsModalRef.hide()
        }, e => {
          this.notifyService.notify('error', '创建试卷失败，请稍后再试')
          console.error('create test request error: ', e)
        }
      )
  }
}
