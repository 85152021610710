import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NotificationService} from 'app/shared/notification/notification.service';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import * as _ from 'lodash';
import {UiTreeComponent} from 'app/editing/ui-tree/ui-tree.component';

const cleanUpThreshold = 5000;

@Component({
  selector: 'app-train-content-link-editor',
  template: require('./train-content-link-editor.component.html'),
  styles: [require('./train-content-link-editor.component.scss')]
})
export class TrainContentLinkEditorComponent implements OnInit {
  @Input() public itemData
  @Input() public itemType
  @Input() public editType
  @Input() public allDirs
  @Output() public action = new EventEmitter<any>()
  @ViewChild('treeInstance') private tree: UiTreeComponent

  private title
  private dataClone
  private dirTree
  private treeData
  private dirIdMap

  private selectedDirIds

  private showTree = false
  private cleanupTimer
  private treeConfig = {
    core: {
      animation: true,
      check_callback: true,
      error: (error: any) => {
        console.error('treeCtrl: error from js tree - ',  error)
      },
      multiple: true,
      worker: true,
    },
    plugins: ['types', 'checkbox'],
    types: {
      default: {
        icon: 'fa fa-book',
      },
      root: {
        icon: 'glyphicon glyphicon-cloud',
      },
    },
    version: 1,
  }
  constructor(public bsModalRef: BsModalRef,
              private notifyService: NotificationService,
              private onlineTrainService: OnlineTrainService) { }

  ngOnInit() {
    this.setTitle()
    this.setDataClone()
    this.dirTree = this.onlineTrainService.buildDirTree(this.allDirs)
    _.each(this.allDirs, (dir) => dir.child = this.dirTree[dir.id])
    this.dirIdMap = _.reduce(this.allDirs, (r, dir) => {
      r[dir.id] = dir
      return r
    }, {})
    this.treeData = this.convertDirsToJsTreeModel(this.dirIdMap, _.filter(this.allDirs, (dir) => dir.dirId === 1))
  }

  public onTreeChange() {
    const selectedNodes = _.map(this.tree.getSelected(), _.parseInt)
    const curIds = _.map(this.allDirs, (sec: any) => {
      return _.parseInt(sec.sectionId);
    });

    if (_(curIds).xor(selectedNodes).isEmpty()) {
      return;
    }

    // this.action.emit(selectedNodes)
    this.selectedDirIds = selectedNodes
  }

  public onTreeReady() {
    if (this.allDirs) {
      this.updateSelection()
    }
  }

  public updateSelection() {
    const selectedNodes = _.map(this.tree.getSelected(), _.parseInt);
    const curIds = _.map(this.allDirs, (sec: any) => {
      return _.parseInt(sec.sectionId);
    });

    if (_(curIds).xor(selectedNodes).isEmpty()) {
      return;
    }
    this.tree.selectNodes(curIds)
  }

  public onShowSections = (open: boolean) => {
    clearTimeout(this.cleanupTimer)
    this.showTree = true
    if (!open) {
      this.cleanupTimer = setTimeout(() => {
        this.showTree = false
        this.cleanupTimer = null
      }, cleanUpThreshold);
    }
  }

  public close() {
    this.bsModalRef.hide()
  }

  public ok() {
    this.action.emit(this.selectedDirIds)
    this.bsModalRef.hide()
  }

  private setTitle() {
    this.title = '新增相关链接'
  }

  private setDataClone() {
    this.dataClone = _.clone(this.itemData)
  }

  private convertDirsToJsTreeModel(dirIdMap, dirs) {
    const allNodes = [];

    function addAllNodes(sec) {
      const id = sec.id || 0;
      let parentId = (_.has(sec, 'dirId') && sec.dirId !== null) ? sec.dirId : '0';
      if (!_.has(dirIdMap, parentId)) {
        parentId = '#';
      }

      const name = sec.name;

      allNodes.push({
        id: id,
        parent: parentId,
        text: name,
        state: {
          opened: false
        }
      });
      _.each(sec.child, addAllNodes);
    }

    _.each(dirs, addAllNodes);

    return allNodes;
  }
}
