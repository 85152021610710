import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-qa-designer',
  template: require('./qa-designer.component.html'),
  styles: [require('./qa-designer.component.scss')]
})
export class QaDesignerComponent implements OnInit {

  @Input() content
  @Input() resDelShowType
  @Input() isThreeDegrees
  @Input() editorConfig

  @Output() contentChange = new EventEmitter<any>()
  @Output() showResAndDelClick = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {
  }

  public handleShowResAndDelClick() {
    this.showResAndDelClick.emit(1)
  }

}
