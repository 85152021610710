(function() {
  angular.module('app.manage')
    .controller('setSchoolAdminDialogCtrl', setSchoolAdminDialogCtrl);

  setSchoolAdminDialogCtrl.$inject = ['$scope', '$uibModalInstance', 'notify', 'oedSchool', 'school']
  function setSchoolAdminDialogCtrl($scope, $uibModalInstance, notify, oedSchool, school) {

    $scope.schoolInfo = {
      schoolId: school.id
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.setAdmin = function() {
      $scope.showValidationError = true;
      if ($scope.setSchoolAdminForm.$invalid)
        return;

      $scope.loading = oedSchool.setSchoolAdmin($scope.schoolInfo).$promise.then(function(resp) {
        if (!resp.success) {
          //
          // 提示原因
          $scope.message = resp.message;
        } else {
          //
          $scope.message = '';
          school.uid = resp.message;
          $uibModalInstance.close(resp);
        }
      });
    };
  }
})();
