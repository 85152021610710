/**
 * @fileOverview 提供一些bind-html并且编译html的directive
 * 从 https://github.com/incuna/angular-bind-html-compile 拷过来
 * 这个directive是为了支持客观题中的视频播放的连接泄漏修复
 * @name bindHtmlCompile.js
 */
(function() {
  angular.module('app.directives')
    .directive('bindHtmlCompile', bindHtmlCompile);

  bindHtmlCompile.$inject = ['$compile']
  function bindHtmlCompile($compile) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        scope.$watch(function() {
          return scope.$eval(attrs.bindHtmlCompile);
        }, function(value) {
          // In case value is a TrustedValueHolderType, sometimes it
          // needs to be explicitly called into a string in order to
          // get the HTML string.
          element.html(value && value.toString());
          // If scope is provided use it, otherwise use parent scope
          var compileScope = scope;
          if (attrs.bindHtmlScope) {
            compileScope = scope.$eval(attrs.bindHtmlScope);
          }
          $compile(element.contents())(compileScope);
        });
      },
    };
  }
})();