import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import * as _ from 'lodash'

@Component({
  selector: 'app-fill-designer',
  template: require('./fill-designer.component.html'),
  styles: [require('./fill-designer.component.scss')]
})
export class FillDesignerComponent implements OnChanges, OnInit {
  @Input() content
  @Input() editorConfig
  @Input() resDelShowType: boolean
  @Input() isThreeDegrees: boolean
  @Input() disableChangeAnswerType: boolean
  @Input() disableChangePoint: boolean

  @Output() contentChange = new EventEmitter<any>()
  @Output() showResAndDelClick = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'content')) {
      this.content = _.defaults(this.content, {
        type: 'fill',
        point: 1
      })
    }
    if (_.has(changes, 'editorConfig')) {
      this.editorConfig = _.defaults({}, this.editorConfig, {
        showAddFill: true
      })
    }
  }

  handleShowResAndDelClick() {
    this.showResAndDelClick.emit(0)
    return false
  }
}
