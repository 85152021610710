import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-empty-content',
  template: require('./empty-content.component.html'),
  styles: [require('./empty-content.component.scss')]
})
export class EmptyContentComponent implements OnInit {
  @Input() private emptyInfo = '暂时没有符合条件的资源……'

  constructor() { }

  ngOnInit() {
  }

}
