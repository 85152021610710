import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {TrainMassageService} from 'app/online-train/train-massage.service';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import { StateService } from '@uirouter/core'
import {forkJoin, of} from 'rxjs';
import {flatMap} from 'rxjs/operators';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-train-excellent',
  template: require('./train-excellent.component.html'),
  styles: [require('./train-excellent.component.scss')]
})
export class TrainExcellentComponent implements OnInit {
  @Input() public params: any

  private parentId
  private editable
  private prefix

  private levels
  private subjects
  private grades
  private smallGrades
  private middleGrades
  private highGrades
  private allGrade
  private allSubjects

  private selectedLvl
  private selectedSubj
  private selectedGrade

  private goodCourses
  private itemType
  private lvlToSubjMap
  constructor(private state: StateService,
              private onlineTrainService: OnlineTrainService,
              private trainMessage: TrainMassageService) { }

  ngOnInit() {
    this.parentId = _.parseInt(this.params.parentId)
    this.editable = this.params.editable
    this.prefix = this.params.prefix

    this.allGrade = {
      id: -1,
      name: '全部',
      deleted: false
    }
    this.middleGrades = [
      {id: -1, name: '全部', deleted: false},
      {id: 1, name: '七年级', deleted: false},
      {id: 2, name: '八年级', deleted: false},
      {id: 3, name: '九年级', deleted: false}
    ]

    this.highGrades = [
      {id: -1, name: '全部', deleted: false},
    ]
    this.itemType = this.onlineTrainService.GOOD_COURSE

    this.lvlToSubjMap = {
      1 : this.onlineTrainService.subjectsOfPrimary,
      2 : this.onlineTrainService.subjectsOfJunior,
      3 : this.onlineTrainService.subjectsOfSenior
    }
    this.loadData()
  }

  public onSelectLevel(params) {
    // 学段选择小学时
    if (params.id === 1) {
      this.grades = this.smallGrades
      // 学段选择初中时
    } else if (params.id === 2) {
      this.grades = this.middleGrades
    } else if (params.id === 3) {
      this.grades = this.highGrades

    }
    this.selectedLvl = params
    this.subjects = _.filter(this.allSubjects, (s: any) => _.includes(this.lvlToSubjMap[this.selectedLvl.id], s.name))
    this.selectedSubj = this.subjects[0]
    this.selectedGrade = this.grades[0]
    this.queryGoodCourseWrapper()
  }

  public onSelectSubject(params) {
    this.selectedSubj = params
    this.queryGoodCourseWrapper()
  }

  public onSelectGrade(params) {
    this.selectedGrade = params
    this.queryGoodCourseWrapper()
  }

  public contentEdit(gc) {
    let pId = -1
    if (!_.isEmpty(gc)) {
      pId = gc.id
    }
    this.state.go('onlineTrain.goodCourseContent', {
      parentId: pId,
      editable: true,
      levels: this.levels,
      subjects: this.subjects,
      grades: this.grades,
      prefix: this.prefix});
  }

  public gotoContent(gc) {
    this.state.go('onlineTrain.goodCourseContent', {
      parentId: gc.id,
      editable: this.editable,
      levels: this.levels,
      subjects: this.subjects,
      grades: this.grades,
      prefix: this.prefix});
  }

  public onEdit(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      this.contentEdit(params.item)
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      this.contentEdit(params.item)
    } else if (params.editType === this.onlineTrainService.DELETE) {
      this.trainMessage.sendLoadingAction(true)
      this.onlineTrainService.deleteGoodCourse(params.item.id)
        .pipe(flatMap(() => this.queryGoodCourse())).subscribe(() => {
      }, (e) => {console.log(e)}, () => this.trainMessage.sendLoadingAction(false))
    }
  }

  private queryGoodCourseWrapper() {
    this.trainMessage.sendLoadingAction(true)
    return this.queryGoodCourse().subscribe(() => {}, (e) => console.log(e),
      () => this.trainMessage.sendLoadingAction(false))
  }

  private queryGoodCourse() {
    return this.onlineTrainService.queryGoodCourse(this.selectedLvl.id,
      this.selectedSubj.id,
      this.selectedGrade.id).pipe(
        flatMap((goodCourses) => {
          if (_.isEmpty(goodCourses)) {
            return of([])
          } else {
            const req = _.map(goodCourses, (gc) => this.onlineTrainService.getContentsByGoodCourseId(gc.id).pipe(
              flatMap((contents) => {
                if (_.isEmpty(contents)) {
                  return of(gc)
                } else {
                  const content = _.find(contents, (c) => c['sort'] === 1)
                  if (_.isEmpty(content)) {
                    return of(gc)
                  } else {
                    return this.onlineTrainService.getResource(content.resourceId).pipe(
                      flatMap((res) => {
                        content.$$resource = res
                        gc.$$content = content
                        return of(gc)
                      })
                    )
                  }
                }
              })
            ))
            return forkJoin(...req)
          }
        }),
        map((goodCourses) => {
          this.goodCourses = goodCourses
          return 0
        })
      )
  }
  private loadData() {
    this.trainMessage.sendLoadingAction(true)
    return forkJoin(
      this.onlineTrainService.getLevels(),
      this.onlineTrainService.getSubjects(),
      this.onlineTrainService.getGrades())
      .pipe(
        flatMap((v) => {
          this.levels = v[0]
          this.allSubjects = v[1]
          this.grades = _([]).concat(this.allGrade).concat(v[2]).value()
          this.smallGrades = this.grades
          this.selectedLvl = this.levels[0]
          this.subjects = _.filter(this.allSubjects,
            (s: any) => _.includes(this.lvlToSubjMap[this.selectedLvl.id], s.name))
          this.selectedSubj = this.subjects[0]
          this.selectedGrade = this.allGrade
          return this.queryGoodCourse()
        })
      ).subscribe(() => {
      }, (err) => {
      }, () => {
        this.trainMessage.sendLoadingAction(false)
      })
  }

}
