import * as _ from 'lodash';

import Promise = require('bluebird');
import {isEmpty} from 'rxjs/operators';
const boardUtils: any = require('app2/utils/boardUtils');
const resourceUtils: any = require('app2/utils/resourceUtils');
const {importPresentResourceItems} = require('app2/utils/resourceUtils')
require('app2/prepareCourse/cloud/subjectiveTest/subjectiveTestLibraryDialog.ctrl')
const {confirmAndDownloadSubjectiveTest, importSubjectTestModel} = require('app2/utils/testUtils2')
const {doImportQuestions} = require('app2/utils/testUtils2')
require('./newPrepareLeft.scss')
require('../importIngDialog/importIngDialog')
require('../uploadFailedDialog/uploadFailedDialog')
import { defaultChildQuestionList, isQuickWayToCreatePrepareCourse} from '../../prepareCourse/utils/prepareCourseUtils'
export const newPrepareLeft = {
  bindings: {
    courseId: '<',
    setLoading: '&',
  },
  controller: newPrepareLeftCtrl,
  template: <string>require('./newPrepareLeft.html'),
}

angular.module('app.newPrepareCourse2.newPrepareLeft', ['app.newPrepareCourse2.importIngDialog',
'app.newPrepareCourse2.uploadFailedDialog'])
  .component('newPrepareLeft', newPrepareLeft)
  .controller('editCourseNameCtrl', editCourseNameCtrl)
  .controller('editPresentResourceItemNameCtrl', editPresentResourceItemNameCtrl)
  .controller('newUploadResCtrl', newUploadResCtrl)
  .controller('uploadFailedDialogCtrl', uploadFailedDialogCtrl);
newPrepareLeftCtrl.$inject = [
  '$scope',
  '$state',
  '$q',
  '$log',
  '$uibModal',
  '$timeout',
  'notify',
  'dialogs',
  'Upload',
  'Lightbox',
  'oedSubjectiveTest',
  'userInfo',
  'oedCourse',
  'oedCoursePath',
  'oedCloudQuestion',
  'oedFileUtils',
  'oedConfig',
  'oedMisc',
  'oedUnitItem',
  'localStorageService',
  'notificationService',
  'oedTeachingPattern',
  'oedTest',
  'oedPresentResource',
  'oedTeachingModule',
  'oedPresentResourceItem',
  'hlsResource',
  'oedCloudPresentResItem',
  'oedPreResItem',
  'oedResDownloadTask',
  '$rootScope',
  'talcloudResource',
  'oedObjectiveQuestion',
  'oedObjectiveTest',
  'cloudQuestionService',
  'communicateService',
  'oedTaskListPublishRecord',
  'cloundApi',
  'oedNewPrepareCourse',
  'oedResource',
  '$interval',
  '$http',
  'oedTaskList',
  'oedTask',
  'oedTaskTest',
  'oedTaskPresentResource',
  'oedTestSession',
  'oedBoardSession',
  'oedPreResSession',
  'oedPreResSessionStudent',
  'oedReportTaskListPublish',
  '$location',
  'oedCloudTeachingPattern',
  'oedDialogService'
];
function newPrepareLeftCtrl(
  $scope,
  $state,
  $q,
  $log,
  $uibModal,
  $timeout,
  notify,
  dialogs,
  Upload,
  Lightbox,
  oedSubjectiveTest,
  userInfo,
  oedCourse,
  oedCoursePath,
  oedCloudQuestion,
  oedFileUtils,
  oedConfig,
  oedMisc,
  oedUnitItem,
  localStorageService,
  notificationService,
  oedTeachingPattern,
  oedTest,
  oedPresentResource,
  oedTeachingModule,
  oedPresentResourceItem,
  hlsResource,
  oedCloudPresentResItem,
  oedPreResItem,
  oedResDownloadTask,
  $rootScope,
  talcloudResource,
  oedObjectiveQuestion,
  oedObjectiveTest,
  cloudQuestionService,
  communicateService,
  oedTaskListPublishRecord,
  cloundApi,
  oedNewPrepareCourse,
  oedResource,
  $interval,
  $http,
  oedTaskList,
  oedTask,
  oedTaskTest,
  oedTaskPresentResource,
  oedTestSession,
  oedBoardSession,
  oedPreResSession,
  oedPreResSessionStudent,
  oedReportTaskListPublish,
  $location,
  oedCloudTeachingPattern,
  oedDialogService
) {

  const $ctrl = this;
  $ctrl.modules = [];
  $ctrl.currentModelId = null;
  $ctrl.memoryHandleId = null;
  $ctrl.subscription = null;
  $ctrl.getMessageScription = null;
  $ctrl.expandScription = null;
  $ctrl.hideDom = false;
  $ctrl.course = null;
  userInfo.then((user) => {
    $ctrl.userInfo = user;
  });
  $ctrl.tmpList = [];
  $ctrl.cloudQuestions = {};
  $ctrl.tpcloundId = '';
  $ctrl.$onInit = () => {
    $ctrl.collapseIdsArr = [];
    $ctrl.firstInit = true;
    const urlRes = $location.search();
    if (!jQuery.isEmptyObject(urlRes)) {
      $ctrl.memoryHandleId = Number(urlRes.resId);
    }
    $ctrl.collapseIdsArr.push($ctrl.memoryHandleId);
    oedCourse.get({
      id: $ctrl.courseId
    }).$promise.then((r: any) => {
      $ctrl.course = r;
      // communicateService.sendCourseMessage({data: $ctrl.course});
    });
    const loading = $ctrl.getOedCourse();
    $ctrl.setLoading({loading});
    // if ($ctrl.activeTabIndex === 1) {
    //   $ctrl.loadHomeworkReport();
    // }
    $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
  }
  $ctrl.$onDestroy = () => {
    $ctrl.subscription.unsubscribe();
    $ctrl.getMessageScription.unsubscribe();
    $ctrl.expandScription.unsubscribe();
  }
  $ctrl.getOedCourse = () => {
    $ctrl.teachingPattern = oedTeachingPattern.getByCourse2({
        courseId: $ctrl.courseId
    });
    return $ctrl.teachingPattern.$promise.then((tp) => {
      oedCloudTeachingPattern.getByResId({
        id: tp.id
      }).$promise.then((r) => {
        if (r) {
          $ctrl.tpcloundId = 'D' + r.id;
        } else {
          $ctrl.tpcloundId = '尚未上传到资源库';
        }
      })
      $ctrl.teachingPattern.id = tp.id;
      $ctrl.modules = tp.modules;
      if ($ctrl.modules.length === 0) {
        communicateService.sendSelectModuleMessage({module: null});
      }
      if ($ctrl.firstInit) {
        $ctrl.selectModuleId = _.size($ctrl.modules) ? $ctrl.modules[0].id : null;
        communicateService.sendSelectModuleMessage({module: $ctrl.modules[0]});
        communicateService.sendMessage({sourceFrom: 'left', data: null});
        $ctrl.firstInit = false;
      }
      communicateService.sendModulesNum({num: _.size($ctrl.modules)});
      communicateService.sendTeachingPatternId({tpId: tp.id});
      const testIds = _(tp.modules).map('tests').flatten().map('objectiveTestId').uniq().value()
      const resIds = _(tp.modules).map('presentResources').flatten().map('presentResourceId').uniq().value()
      const tests = _.isEmpty(testIds) ? [] : oedTest.getByIds({
        ids: testIds
      }).$promise
      const reses = _.isEmpty(resIds) ? [] : oedPresentResource.getByIds({
        ids: resIds
      }).$promise.then((resItem: any) => {
        const arr = [];
        _.each(resItem, (m: any) => {
          arr.push(oedPresentResource.get({ id: m.id }).$promise);
        });
        return $q.all(arr).then((n: any) => {
          return n;
        })
      });

      return $q.all([tests, reses]).then((arr) => {
        const testById = _.keyBy(arr[0], 'id')
        const resById = _.keyBy(arr[1], 'id')
        _.forEach($ctrl.modules, (m: any) => {
          const moduleTests = _(m.tests).map((t: any) => _.assign(
            {}, testById[t.objectiveTestId], {
              sort: t.sort,
            })).partition((t: any) => t.type === 'objective').value()
          const moduleReses = _(m.presentResources).map((r: any) => jQuery.extend(true, {},
             resById[r.presentResourceId],
             {sort: r.sort, type: 'presentresource'})).value();
          m.treeData = [];
          // 给树的第一层挂选中状态
          _.each(moduleTests[0], (x: any) => {
            x.isShown = _.filter(m.tests,
            (item: any) => Number(item.objectiveTestId) === Number(x.id))[0].isShown ? true : false;
            x.resDetail = _.cloneDeep(x);
            x.resoureType = 'objective';
            x.moduleId = m.id;
            if ($ctrl.memoryHandleId && Number($ctrl.memoryHandleId) ===  Number(x.id)) {
              if ($ctrl.needClick) {
                communicateService.sendMessage({sourceFrom: 'left', data: x, needClick: true});
                $ctrl.needClick = false;
              } else {
                communicateService.sendMessage({sourceFrom: 'left', data: x});
              }
              $ctrl.goToResItem();
            }
            m.treeData.push(x);
          })
          _.each(moduleTests[1], (y: any) => {
            y.isShown = _.filter(m.tests,
            (item: any) => Number(item.objectiveTestId) ===  Number(y.id))[0].isShown ? true : false;
            y.resDetail = _.cloneDeep(y);
            y.resoureType = 'subjective';
            y.moduleId = m.id;
            if ($ctrl.memoryHandleId && Number($ctrl.memoryHandleId) === Number(y.id)) {
              if ($ctrl.needClick) {
                communicateService.sendMessage({sourceFrom: 'left', data: y, needClick: true});
                $ctrl.needClick = false;
              } else {
                communicateService.sendMessage({sourceFrom: 'left', data: y});
              }
              $ctrl.goToResItem();
            }
            m.treeData.push(y);
          })
          _.each(moduleReses, (z: any) => {
            z.isShown = _.filter(m.presentResources,
            (item: any) => Number(item.presentResourceId) ===  Number(z.id))[0].isShown ? true : false;
            z.resDetail = _.cloneDeep(z);
            z.resoureType = 'presentPackage';
            z.moduleId = m.id;
            if ($ctrl.collapseIdsArr.indexOf(z.id) >= 0) {
              z.collapsed = false;
            } else {
              z.collapsed = true;
            }
            if ($ctrl.memoryHandleId && Number($ctrl.memoryHandleId) ===  Number(z.id)) {
              communicateService.sendMessage({sourceFrom: 'left', data: z});
              $ctrl.goToResItem();
            }
            _.each(z.items, (child: any, index) => {
              child.resDetail = z.resDetail;
              child.resoureType = 'singleResource';
              child.resIndex = index;
              if ($ctrl.memoryHandleId && Number($ctrl.memoryHandleId) === Number(child.id)) {
                if ($ctrl.needClick) {
                  communicateService.sendMessage({sourceFrom: 'left', data: child, needClick: true});
                  $ctrl.needClick = false;
                } else {
                  communicateService.sendMessage({sourceFrom: 'left', data: child});
                }
                // communicateService.sendMessage({sourceFrom: 'left', data: child});
                $ctrl.goToResItem();
                z.collapsed = false;
              }
            })
            m.treeData.push(z);
          })
          m.objectiveTests = moduleTests[0];
          m.subjectiveTests = moduleTests[1];
          m.treeData = _.sortBy(m.treeData, 'sort');
          const obj = { // 解决bug 当资源包位于左侧最下面一个，且展开的时候，右侧想拖动一个资源放在资源包并行的位置下面（并非包内）
            resoureType: 'tempLi'
          }
          m.treeData.push(obj);
        });
        if ($ctrl.modules.length !== 0) {
          const moduleObj = {
            id: ''
          }
          $ctrl.modules.push(moduleObj);
        }
        return $ctrl.modules;
      })
    })
  }
  $ctrl.subscription = communicateService.getDragMessage().subscribe((res: any) => {
    if (res.needfresh) {
      if (res.memoryHandleId) {
        $ctrl.memoryHandleId = res.memoryHandleId;
      }
      if (res.selectModuleId) {
        $ctrl.selectModuleId = res.selectModuleId;
        $ctrl.memoryHandleId = null;
      }
      if (res.isFromUseResource && res.presentPackageId) {
        $ctrl.collapseIdsArr.push(res.presentPackageId);
      }
      if (res.needWait) {
        setTimeout(() => {
          $ctrl.loading = $ctrl.getOedCourse();
          $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
        }, 3000);
      } else {
        $ctrl.getOedCourse();
        $ctrl.autoScrollIntoView($ctrl.memoryHandleId ? $ctrl.memoryHandleId :  $ctrl.selectModuleId);
      }
    } else {
      if (res.memoryHandleId) {
        $ctrl.memoryHandleId = res.memoryHandleId;
      }
      if (jQuery('.selected') && jQuery('.selected')[0]) {
        jQuery('.selected').removeClass('selected');
      }
      jQuery('#' + $ctrl.memoryHandleId).addClass('selected');
      if (res.isChangeResource) {
        const curModule = _.find($ctrl.modules, (m: any) => m.id === res.moduleId);
        const curSelPackage = _.find(curModule.treeData, (t: any) => t.id === res.packageId);
        const curSelRes = _.find(curSelPackage.items, (r: any) => r.id === res.memoryHandleId);
        $ctrl.selectNode(curSelRes, curModule)
      } else if (res.isReturnPresentPackage) {
        const curModule = _.find($ctrl.modules, (m: any) => m.id === res.moduleId);
        const curSelPackage = _.find(curModule.treeData, (t: any) => t.id === res.memoryHandleId);
        $ctrl.selectNode(curSelPackage, curModule)
      } else {
        communicateService.sendMessage({sourceFrom: 'left', data: res.data});
      }
    }
  })
  $ctrl.getMessageScription = communicateService.getMessage().subscribe((res: any) => {
    if (res.sourceFrom === 'right') {
      if (jQuery('.selected') && jQuery('.selected')[0]) {
        jQuery('.selected').removeClass('selected');
      }
    }
  });
  $ctrl.expandScription = communicateService.getExpandMessage().subscribe((res: any) => {
    $ctrl.hideDom = res.isFullScreen;
  });
  $ctrl.checkId = (module) => {
    if ($ctrl.memoryHandleId) {
      let curSelectedModule = false;
      if (module.treeData && module.treeData.length > 0) {
        _.each(module.treeData, (item: any) => {
          if (item.id && item.id === $ctrl.memoryHandleId) {
            curSelectedModule = true;
            return false;
          } else {
            if (item.items && item.items.length > 0) {
              _.each(item.items, (item1: any) => {
                if (item1.id === $ctrl.memoryHandleId) {
                  curSelectedModule = true;
                  return false;
                }
              })
            }
          }
        })
        if (curSelectedModule) {
          $ctrl.selectModuleId = module.id;
          communicateService.sendSelectModuleMessage({module: module});
        }
        return curSelectedModule;
      }
    } else {
      return false;
    }
  }
  $ctrl.selectModule = (item) => {
    $ctrl.selectModuleId = item.id;
    $ctrl.memoryHandleId = null;
    $state.go('newPrepareCourse.ownerPrepare.resItem', {
      resId:  null
    });
    // communicateService.sendMessage({sourceFrom: 'left', data: null});
    communicateService.sendSelectModuleMessage({module: item});
  }
  $ctrl.goToResItem = () => {
    $state.go('newPrepareCourse.ownerPrepare.resItem', {
      resId: $ctrl.memoryHandleId
    });
  }
  // 返回上个页面
  $ctrl.back = () => {
    const id = localStorageService.get('lastCoursePathId');
    if (localStorageService.get('newCreateCourseId') == $ctrl.courseId && _.size($ctrl.modules) == 0){
      $scope.loading = oedCourse.deleteCourse({ id : $ctrl.courseId }).$promise
        .then(() => {
          oedCourse.finalDeleteCourse({ id: $ctrl.courseId }).$promise.then(() => {
            $state.go('prepareLesson.newCourseList', {
              cpId: id,
            });
          })
        }
       )
    } else {
      $state.go('prepareLesson.newCourseList', {
        cpId: id,
      });
    }
  }
  $ctrl.toggle = (node) => {
    event.stopPropagation();
    event.preventDefault();
    node.collapsed = !node.collapsed;
    if (node.collapsed) {
      const index  = $ctrl.collapseIdsArr.indexOf(node.id);
      if (index >= 0) {
        $ctrl.collapseIdsArr.splice(index, 1);
      }
    } else {
      $ctrl.collapseIdsArr.push(node.id);
    }
  }
  // 获取父节点icon
  $ctrl.getIcon = (type) => {
    switch (type) {
      case 'subjective':
        return 'fo fo-subj';
        break;
      case 'presentresource':
        return 'fo fo-present-package';
        break;
      case 'objective':
        return 'fo fo-obj';
        break;
      default:
        break;
    }
  }
  // 获取叶子节点icon
  $ctrl.getLeafIcon = (type) => {
    switch (type ? type.toLowerCase() : type) {
      case 'doc':
      case 'docx':
        return 'fa-file-word-o';
        break;
      case 'pdf':
        return 'fa-file-pdf-o';
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        return 'fa-file-photo-o';
        break;
      case 'mp4':
      case 'flv':
      case 'rmvb':
      case 'wmv':
      case 'rm':
      case 'avi':
      case 'mpg':
        return 'fa-file-video-o';
        break;
      case 'mp3':
      case 'wma':
      case 'wav':
      case 'amr':
      case 'm4a':
        return 'fa-file-audio-o';
        break;
      case 'txt':
        return  'fa-file-text-o';
        break;
      case 'ppt':
      case 'pptx':
        return  'fa-file-powerpoint-o';
        break;
      case 'xls':
      case 'xlsx':
        return  'fa-file-excel-o';
        break;
      case 'zip':
        return 'fa-file-archive-o'
        break;
      default:
        return 'fa-file-o';
        break;
    }
  }
  // 教学环节改名
  $ctrl.editModule = (module) => {
    event.stopPropagation();
    event.preventDefault();
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/editTeachingModule.html'),
      controller: 'editTeachingModuleCtrl',
      size: 'md',
      windowClass: 'oedmodal modalCenter',
      resolve: {
        teachingModule: () => {
          return module;
        }
      }
    });

    modalInstance.result.then(() => {
      $ctrl.getOedCourse();
      $ctrl.autoScrollIntoView(module.id);
    });

  }
  // 教学环节删除
  $ctrl.deleteModule = (module) => {
    event.stopPropagation();
    event.preventDefault();
    const dlg = dialogs.confirm('确定删除?', '确定要删除教学环节: "' + module.name + '"吗');
    const index = _.findIndex($ctrl.modules, (m: any) => {
      return m.id === module.id
    });
    const reSelect = ($ctrl.selectModuleId === module.id) && (_.size($ctrl.modules) > 2)
    const currentIndex = index === (_.size($ctrl.modules) - 2) ? (index - 1) : (index + 1);
    dlg.result.then((btn) => {
      $ctrl.loading = oedTeachingModule.delete({
        id: module.id
      }, () => {

        $ctrl.getOedCourse();
        if (reSelect) {
          $ctrl.selectModule($ctrl.modules[currentIndex]);
        } else {
          communicateService.sendMessage({sourceFrom: 'left', data: null});
        }
      }).$promise;
    });
  };
  // 树第一层的数据删除
  $ctrl.deleteDepthByOne = (node, type) => {
    event.stopPropagation();
    event.preventDefault();
    const dlg = dialogs.confirm('确定删除?', '确定要删除"' + node.name + '"吗');
    dlg.result.then((r: any) => {
     if (type === 'presentresource') {
      oedPresentResource.delete({id: node.id}).$promise.then((data: any) => {
        $ctrl.getOedCourse();
        if (Number($ctrl.memoryHandleId) === Number(node.id)) {
          communicateService.sendMessage({sourceFrom: 'left', data: null});
          $ctrl.memoryHandleId = '';
          $ctrl.goToResItem();
        } else {
          const arr = _.filter(node.items, (item: any) => Number(item.id) ===  Number($ctrl.memoryHandleId));
          if (arr.length > 0) {
            $ctrl.memoryHandleId = '';
            communicateService.sendMessage({sourceFrom: 'left', data: null});
            $ctrl.goToResItem();
          }
        }
      })
     } else {
      oedObjectiveTest.delete({id: node.id}).$promise.then((data: any)  => {
        $ctrl.getOedCourse();
        if (Number($ctrl.memoryHandleId) === Number(node.id) || Number($ctrl.memoryHandleId) === Number(node.id))  {
          communicateService.sendMessage({sourceFrom: 'left', data: null});
          $ctrl.memoryHandleId = '';
          $ctrl.goToResItem();
        }
      })
     }
    })

  }
  // 新建教学环节
  $ctrl.createTeachingModule = () => {
    // let modalInstance = $uibModal.open({
    //   template: require('assets/templates/prepareCourse/addTeachingModule.html'),
    //   controller: 'addTeachingModuleCtrl',
    //   size: 'sm',
    //   windowClass: 'oedmodal modalCenter',
    //   resolve: {
    //     patternId: () => {
    //       return $ctrl.teachingPattern.id;
    //     },
    //     isHomework: false
    //   }
    // });

    // modalInstance.result.then((tm) => {
    //   $ctrl.getOedCourse();
    //   $ctrl.autoScrollIntoView(tm.id)
    //   $ctrl.selectModule(tm);
    // });
    const arr = _.filter($ctrl.modules, (item: any) => item.id);
    const module = new oedTeachingModule({
      name: `教学环节${arr.length > 0 ? arr.length + 1 : 1}`,
      note: '',
      teachingPatternId: $ctrl.teachingPattern.id,
      isHomework: false
    });
    module.$save().then((tm) => {
      $ctrl.getOedCourse();
      $ctrl.autoScrollIntoView(tm.id)
      $ctrl.selectModule(tm);
    })
  };
  $ctrl.autoScrollIntoView =  (id) => {
    setTimeout(() => {
      if (document.getElementById(id)) {
        document.getElementById(id).scrollIntoView({behavior: 'smooth', block: 'center'});
      }
    }, 1500);
  }
  // 修改展示资源包,客观试卷,主观活动名称
  $ctrl.editRes = (res, type) => {
    event.stopPropagation();
    event.preventDefault();
    if (type === 'presentresource') {
      const modal = $uibModal.open({
        template: require('assets/templates/prepareCourse/editPresentResourceDialog.html'),
        controller: 'editPresentResourceCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          resource: () => {
            return res;
          },
          existingRes: () => {
            return null;
          },
        }
      });
      modal.result.then((r: any) => {
        $ctrl.getOedCourse();
      })
    } else if (type === 'objective') {
      const modal = $uibModal.open({
        template: require('assets/templates/prepareCourse/editTestDialog.html'),
        controller: 'editTestCtrl',
        size: 'sm',
        windowClass: 'modalCenter',
        resolve: {
          test: () => {
            return res;
          },
          existingTests: () => {
            return null;
          },
          isPrepareLesson: true
        }
      });
      modal.result.then((r: any) => {
        $ctrl.getOedCourse();
      })
    } else if (type === 'subjective') {
      const modal = $uibModal.open({
        template: require('assets/templates/prepareCourse/editSubjectiveTest.html'),
        controller: 'editSubjectiveTestCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          test: () => {
            return res;
          },
          existingTests: () => {
            return null;
          },
          isPrepareLesson: true
        }
      });
      modal.result.then((r: any) => {
        $ctrl.getOedCourse();
      })
    }

  };
  $ctrl.tooltip = `<i class="fa fa-check-circle vertical-middle
  margin-right-md" style="color: #869919;font-size: 24px;"></i>' +
    '<div role="alert" aria-live="polite" class="vertical-middle inline-block"
    style="min-width: 270px; display: inline-block; margin-top: 2px;">修改成功，已为您保存在此处</div>' +
    '<i class="fo-x pull-right margin-left-md vertical-middle" style="font-size: 24px;color: #cccccc;"></i>`;
  // 选中资源是否在上课显示
  // $ctrl.changeSelectStatus = (moduleId, node, isShown) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   node.isShown = !isShown;
  //   let currentHandleModel = _.filter($ctrl.modules, (item: any) => Number(item.id) === Number(moduleId));
  //   if (node.type === 'presentresource') {
  //     let selId = _.filter(currentHandleModel[0].presentResources,
  //     (item: any) => Number(item.presentResourceId) === Number(node.id))[0].id;
  //     oedTeachingModule.updateTmPresentRes({id: selId, isShown: node.isShown}, {}).$promise.then((r: any) => {
  //     })
  //   } else {
  //     let selId = _.filter(currentHandleModel[0].tests,
  //     (item: any) => Number(item.objectiveTestId) === Number(node.id))[0].id;
  //     oedTeachingModule.updateTmtest({id: selId, isShown: node.isShown}, {}).$promise.then((r: any) => {
  //     })
  //   }
  // }
  // 展示资源包里资源单项删除
  $ctrl.deleteSelected = (selData, node, module) => {
    event.preventDefault();
    event.stopPropagation();
    let message = '确定要删除当前选中展示资源吗：';
    message = message + '<br>' + selData.resourceName;
    const dlg = dialogs.confirm('确定删除?', message);
    dlg.result.then(() => {
      oedPresentResourceItem.delete({
        id: selData.id
      }).$promise.then((r: any) => {
        const idIndex = _.findIndex(node.items, (x: any) => {
          return x.id === selData.id;
        });
        node.items.splice(idIndex, 1);
        const cloneNode = _.cloneDeep(node);
        _.each(node.items, (n: any, index) => {
          n.resDetail = cloneNode;
          n.resIndex = index;
        });
        node.resDetail = cloneNode;
        const curNode = _.filter(node.items,(r)=> r.id === Number($ctrl.memoryHandleId));
        if (Number($ctrl.memoryHandleId) ===  Number(selData.id)) {
          if (jQuery('.selected') && jQuery('.selected')[0]) {
            jQuery('.selected').removeClass('selected');
          }
          communicateService.sendMessage({sourceFrom: 'left', data: null});
        } else if (Number($ctrl.memoryHandleId) === Number(node.id)) {
          communicateService.sendMessage({sourceFrom: 'left', data: node});
        } else {
            communicateService.sendMessage({sourceFrom: 'left', data: curNode.length != 0?curNode[0]:null});
        }
      })
    });
  };
  // 树选中节点
  $ctrl.selectNode = (res, item) => {
    event.preventDefault();
    event.stopPropagation();
    $ctrl.selectModuleId = item.id;
    if (res.resoureType === 'presentPackage') {
      if (!res.collapsed) {
        res.collapsed = true;
        const index  = $ctrl.collapseIdsArr.indexOf(res.id);
        if (index >= 0) {
          $ctrl.collapseIdsArr.splice(index, 1);
        }
      } else {
        res.collapsed = false;
        const index  = $ctrl.collapseIdsArr.indexOf(res.id);
        if (index === - 1) {
          $ctrl.collapseIdsArr.push(res.id);
        }
      }
    }
    communicateService.sendSelectModuleMessage({module: item});
    if (jQuery('.selected') && jQuery('.selected')[0]) {
      jQuery('.selected').removeClass('selected');
    }
    $ctrl.memoryHandleId = res.id;
    jQuery('#' + $ctrl.memoryHandleId).addClass('selected');
    $state.go('newPrepareCourse.ownerPrepare.resItem', {
      resId:  res.id
    });
    communicateService.sendMessage({sourceFrom: 'left', data: res});
  }
  // 导入word试卷
  $ctrl.uploadCreateTest = (files, id) => {

    $ctrl.testFile = null;
    validateWordFileType(files);
    if (!_.isEmpty(files.validateResults)) {
      notificationService.notify(files.validateResults[0].level, files.validateResults[0].message, '', 5 * 1000)
      return
    }
    oedObjectiveTest.createObjPackge({moduleId: id}, {}).$promise.then((r: any) => {
      $ctrl.memoryHandleId = r.id;
      $ctrl.testId = r.id;
      $ctrl.test = r;
      let nextStep = true;
      let cancelInstance = null;
      return $q((resolve, reject) => {
        cancelInstance = $uibModal.open({
          template: require('app2/prepareCourse/promptDialog/promptDialog.html'),
          controller: 'promptDialogCtrl',
          size: 'qp-edit',
          windowClass: 'shouldHideOnPreview cl-library-dialog2',
          scope: $scope,
          resolve: {
            data: (() => {})
          }
        })
        cancelInstance.result.then(() => {
          nextStep = false
        })

        $scope.progressMsg = '正在上传文件... (1/4)'
        Upload.upload({
          url: oedConfig.url_b('resource/create'),
          file: files
        }).progress((evt) => {
        }).success((data, status, headers, config) => {
          resolve(data);
        }).error(() => {
          resolve(null);
        })
      }).then((res) => {
        if (!nextStep) {
          return
        }

        $scope.progressMsg = '正在转码... (2/4)'
        if (res.suffix === 'pdf') {
          return convertAndCreatTest(res.id, nextStep, cancelInstance)
        } else {
          let cancel = false
          const ret = $interval(() => {
            const url = '/ray/api/b/resource/' + res.id
            if (!nextStep) {
              return
            }
            return $http.get(url).then((res1) => {
              if (res1.data.transFlag !== 1) {
                return
              }
              if (!cancel) {
                $interval.cancel(ret)
                cancel = true
                if (!nextStep) {
                  return
                }

                let pdf2jpgSuccess = false;
                let retry = 3;
                $scope.progressMsg = '正在生成图片…（3/4）'
                const successCall = (imgReses) => {
                  pdf2jpgSuccess = true
                  if (!nextStep) {
                    return
                  }
                  if (imgReses) {
                    $scope.progressMsg = '正在生成试卷…（4/4）'
                    return quickCreateTest(imgReses).then(() => {
                      $ctrl.needClick = true;
                      $ctrl.getOedCourse();
                      cancelInstance.close();
                      $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
                    })
                  }
                }
                const failCall = () => {
                  retry--
                  if (retry > 0 && !pdf2jpgSuccess) {
                    $scope.loading = oedResource.pdf2jpg({id: res.id}, {}).$promise.then(successCall, failCall)
                  }

                  if (retry <= 0 && !pdf2jpgSuccess) {
                    notificationService.notify('error', '试卷转码失败，请稍后重试', '', 5 * 1000)
                  }
                }

                $scope.loading = oedResource.pdf2jpg({id: res.id}, {}).$promise.then(successCall, failCall)
              }

            }).catch(() => {
              return
            })
          }, 1500, 100)
          ret.then(() => {
            notificationService.notify('error', '生成试卷失败，请稍后重试', '', 5 * 1000)
            cancelInstance.close()
          })
        }
      })
    })

  }
  $rootScope.$on('uploadTestCard', (event, data) => {
    $ctrl.uploadCreateTest(data.file, data.moduleId);
  });
  function convertAndCreatTest(converId, nextStep, cancelInstance) {
    $scope.loading = oedResource.pdf2jpg({id: converId}, {}).$promise.then((imageReses) => {
      if (!nextStep) {
        return
      }

      if (imageReses && imageReses.length > 0) {
        $scope.progressMsg = '正在生成试卷…（4/4）'
        return quickCreateTest(imageReses).then(() => {
          $ctrl.needClick = true;
          $ctrl.getOedCourse();
          cancelInstance.close()
          $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
        })
      } else {
        cancelInstance.close();
        $ctrl.getOedCourse();
        notificationService.notify('error', '文件转码失败，请检查文件格式')
      }
    })
  }
  function validateWordFileType(f) {
    const validateResults = oedFileUtils.validateFileUpload(f, {
      allowedSuffixes: ['doc', 'docx', 'pdf']
    });
    f.validateResults = validateResults;
    f.progressOpacity = 0;
  }
  function quickCreateTest(imageReses) {
    function createSyntheticQuestion(test) {
      const relSections = _.map($ctrl.course.relatedSections, (sec) => {
        return _.pick(sec, ['sectionId', 'sectionName']);
      });

      const obj = {
        answer: '',
        question: '',
        type: 'synthetic',
        point2: 0,
        choices: [],
        leftOpts: [],
        rightOpts: [],
        resourceUUIDs: _.map(imageReses, (image: any) => {
          return _.get(image, 'md5')
        }),
        relatedSections: relSections,
        needPic: false,
        answerDetails: null
      }

      return oedObjectiveQuestion.createForTest({
        question: obj,
        testId: test.id
      }).$promise.then((q: any) => {
        q.childQuestions = defaultChildQuestionList
        return oedObjectiveQuestion.save(q).$promise.then((r) => {
          return loadCloudQuestionByQId(q.id).then(() => q);
        })
      })
    }

    return createSyntheticQuestion($ctrl.test).then(() => {
      $scope.currentTest = oedObjectiveTest.get({
        id: $ctrl.testId
      });

      return loadTestDetail()
    })
  }
  function loadCloudQuestionByQId(qId) {
    return oedCloudQuestion.getByQuestionId({
      id: qId
    }).$promise.then((cq) => {
      $ctrl.cloudQuestions[cq.resourceId] = cq;
    });
  }
  function loadTestDetail() {
    return $scope.currentTest.$promise.then((test) => {
      if (isQuickWayToCreatePrepareCourse(test)) {
        $scope.childQuestionList = test.questions[0].childQuestions
        $ctrl.tmpList = _.cloneDeep(test.questions[0].childQuestions)
      }
      return loadTestCloudQuestions(test.questions)
    })
  }
  function loadTestCloudQuestions(questions) {
    const idArr = _(questions).map('id').filter().value();
    $ctrl.cloudQuestions = {};

    if (_.isEmpty(idArr)) {
      return $q.resolve({});
    }

    if (questions &&
        questions.length === 1 &&
        questions[0].type === 'synthetic' &&
        _.isEmpty(questions[0]['rawBody']['resourceUUIDs']) &&
        questions[0]['resourceUUIDs']
      ) {
        $scope.imageRes = questions[0]['resourceUUIDs']
    }

    return oedCloudQuestion.queryByQuestionIds({
      ids: idArr
    }).$promise.then((cqs) => {
      $scope.cloudQuestions = _.keyBy(cqs, 'resourceId');
      return $scope.cloudQuestions;
    });
  }
  $ctrl.validateWordFileType = (f) => {
    const validateResults = oedFileUtils.validateFileUpload(f, {
      allowedSuffixes: ['doc', 'docx', 'pdf']
    });
    f.validateResults = validateResults;
    f.progressOpacity = 0;
  }
  $ctrl.importTest = (module) => {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/newPreWordUploader.html'),
      controller: 'newUploadResCtrl',
      size: 'sm',
      windowClass: 'modalCenter newPreWordUploader',
      resolve: {
        resourceNeeded: () => {
          return {
            // url: oedConfig.url('objectivetest/' + testId + '/uploadresource'),
            moduleId: module.id,
            // isClassing: Number($ctrl.activeTabIndex) === 0 ? true : false,
            showRules: () => {
              const modalInstance1 = $uibModal.open({
                template: require('assets/templates/prepareCourse/wordUploaderRulesDialog.html'),
                controller: 'wordUploaderRulesCtrl',
                size: 'md',
                resolve: {
                  test: () => {
                    return 'ab';
                  },
                  data: () => {
                    return {
                      isDaoxuean: false
                    }
                  }
                },
              });
              return modalInstance1.result;
            }
          };
        }
      }
    });
    modalInstance.result.then((r) => {
      const relSections = _.map($ctrl.course.relatedSections, (sec) =>  {
        return _.pick(sec, ['sectionId', 'sectionName']);
      });
      if (r.respondData) {
        _.each(r.respondData, (docData) => {
          if (docData.questions && docData.questions.length > 0) {
            _.each(docData.questions, (question) => {
              question.relatedSections = relSections;
            });
            r.test.questions = _.concat(r.test.questions, docData.questions);
          }
        });
        return oedObjectiveTest.save(angular.copy(r.test)).$promise.then(() => {
          notificationService.notify('info', '试卷保存成功');
          $ctrl.getOedCourse();
        });
      }
    });
  };
  // 添加链接
  $ctrl.addUrlItem = (module) => {
    const packageArr = _.filter(module.treeData, (r: any) => {
      return r.resoureType === 'presentPackage';
    });
    let lastPackageId = null;
    if (packageArr.length > 0) {
      lastPackageId = packageArr[packageArr.length - 1].id;
    }
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addWebUrlItem.html'),
      controller: 'addWebUrlItemCtrl',
      size: 'md',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        data: () => {
          return {
             resourceId: '',
             moduleId: module.id,
             packageId: lastPackageId
          };
        }
      }
    });
    modalInstance.result.then((r) => {
      if (r) {
        $ctrl.memoryHandleId = r;
        $ctrl.getOedCourse();
        $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
      }
    });
  };
  // 展示资源资源库导入
  $ctrl.importFromLibrary2_pre = (moduleId) => {
    $ctrl.currentModelId = moduleId;
    const modalInstance = $uibModal.open({
      template: require('app2/prepareCourse/cloud/presentResource/presentResourceLibraryDialog.html'),
      controller: 'presentResourceLibraryDialogCtrl',
      controllerAs: '$ctrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          course: $ctrl.course,
          doImport: (res, deferred) => $ctrl.doImportResources(res, deferred),
        })
      },
    })
  }
  $ctrl.doImportResources = (res, deferred) => {
    $ctrl.importResourceItems(deferred, res);
    return deferred.promise;
  }
  $ctrl.importResourceItems = (deferred, res) => {
    if (res && res.length > 0) {
      const curModule = _.filter($ctrl.modules, (item: any) => {
        return item.id === $ctrl.currentModelId;
      });
      const packageArr = _.filter(curModule[0].treeData, (r: any) => {
        return r.resoureType === 'presentPackage';
      });
      if (packageArr.length > 0) {
        $ctrl.addItemForPackage(deferred, res, packageArr[packageArr.length - 1].id);
      } else {
        oedPresentResource.createResourcePackge({ moduleId: $ctrl.currentModelId }, {}).$promise.then((r: any) => {
          return r;
        }).then((data: any) => {
          $ctrl.addItemForPackage(deferred, res, data.id);
        })
      }
    }
  }
  $ctrl.addItemForPackage = (deferred, res, packgeId) => {
    oedPresentResource.get({ id: packgeId }).$promise.then((x: any) => {
      let downloadOpts = null;
      const opts = {
        canceled: false,
        hlsResource,
        hasDuplicated: false,
        oedCloudPresentResItem,
        oedPreResItem, oedPresentResourceItem,
        oedPresentResource,
        oedResDownloadTask,
        uid: $ctrl.userInfo.uid,
        notify,
        notificationService
      }
      downloadOpts = opts
      importPresentResourceItems(x, res, deferred, opts).then((r) =>  {
          $rootScope.$broadcast('presentResource.changed', {
            resId:  x.id
          });
          return  x.$get();
        }).then(() => {
          deferred.resolve();
        }).catch((m) =>  {
          deferred.reject(m);
        }).finally((r) => {
          downloadOpts = null
          if (opts.hasDuplicated) {
            notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
          }
          $ctrl.memoryHandleId = x.id;
          $ctrl.getOedCourse();
          $ctrl.currentModelId = null;
          $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
        });
    })
  }
  // 展示资源本地上传
  $ctrl.toggleUpload = (module) => {
    const packageArr = _.filter(module.treeData, (r: any) => {
      return r.resoureType === 'presentPackage';
    });
    let lastPackageId = null;
    if (packageArr.length > 0) {
      lastPackageId = packageArr[packageArr.length - 1].id;
    }
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/resourceUploader.html'),
      controller: 'uploadResourceCtrl',
      size: 'md',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        resourceId: () => {
          return '';
        },
        url: () => {
          return '';
        },
        isPrepareLesson: true,
        moduleId: module.id,
        packageId: lastPackageId
      }
    });

    modalInstance.result.then((r) => {
      if (r.uploaded && r.uploaded > 0) {
        $ctrl.memoryHandleId = r.packgeId;
        $ctrl.getOedCourse();
        $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
      }
    });
  };
  // 客观试卷资源库导入
  $ctrl.importFromLibrary2_obj = (id) => {
    function importQuestions(questions, deferred) {
      if (questions && questions.length > 0) {
        $ctrl.needClick = true;
        oedObjectiveTest.createObjPackge({moduleId: id}, {}).$promise.then((r: any) => {
          $ctrl.memoryHandleId = r.id;
          return doImportQuestions(questions, deferred, {
            notify,
            oedCloudQuestion,
            talcloudResource,
            oedObjectiveQuestion,
            oedObjectiveTest,
            userInfo,
            cloudQuestionService,
            questionOp: {
              get: () => r.questions,
              set: (qs) => r.questions = qs
            },
            relatedSections: $ctrl.course.relatedSections || [],
            state: {loading: Promise.resolve()},
            notificationService,
          }).then((quesToAdd: any) => {
            return oedObjectiveTest.save(angular.copy(r)).$promise.then(() => {
              notificationService.notify('info', '试卷保存成功')
            });
          }).then((res: any) => deferred.resolve(res))
            .catch((e: any) => deferred.reject(e))
        })
      }
    }
    const modalInstance = $uibModal.open({
      template: require('app2/prepareCourse/cloud/objectiveTestLibrary.html'),
      controller: 'objectiveTestLibraryCtrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          course: $ctrl.course,
          curIsBlankTest: true,
          doImport: importQuestions
        })
      },
    })

    modalInstance.result.finally((r) => {
      $ctrl.getOedCourse();
    })
  };
  // 添加客观试卷
  $ctrl.addObj = (id) => {
    oedObjectiveTest.createObjPackge({moduleId: id}, {}).$promise.then((r: any) => {
      $ctrl.needClick = true;
      $ctrl.memoryHandleId = r.id;
      $ctrl.getOedCourse();
      $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
    })
  }
  // 主观活动资源库导入
  $ctrl.importFromLibrary2_subj = (id) => {
    function doImportTest(res, deferred) {
      if (res && res.length > 0) {
        confirmAndDownloadSubjectiveTest(res, deferred, {
          dialogs, oedCloudQuestion, oedSubjectiveTest,
        }).then((subTestAndCq) => {
          oedSubjectiveTest.createSubjPackge({moduleId: id}, {}).$promise.then((r: any) => {
            const {cloudQuestion, subTest} = subTestAndCq
            const newTest = importSubjectTestModel(r, subTest, cloudQuestion)
            newTest.$save().then(() => {
              $ctrl.needClick = true;
              $ctrl.memoryHandleId = r.id;
              deferred.resolve();
              $ctrl.getOedCourse();
              $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
            })
          })
        }).catch((m) => {
          deferred.reject(m);
        });
      }
      return deferred.promise;
    }
    const modalInstance = $uibModal.open({
      template: require('app2/prepareCourse/cloud/subjectiveTest/subjectiveTestLibraryDialog.html'),
      controller: 'subjectiveTestLibraryDialogCtrl',
      controllerAs: '$ctrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          course: $ctrl.course,
          doImport: (res, deferred) => {
            return doImportTest([res], deferred)
          },
        })
      },
    })
  }
  // 添加主观活动
  $ctrl.addSubj = (id) => {
    oedSubjectiveTest.createSubjPackge({moduleId: id}, {}).$promise.then((r: any) => {
      $ctrl.needClick = true;
      $ctrl.memoryHandleId = r.id;
      $ctrl.getOedCourse();
      $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
    })
  }
  // 课程改名
  $ctrl.editCourseName = (id) => {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/editPreCourseNameDialog.html'),
      controller: 'editCourseNameCtrl',
      size: 'sm',
      windowClass: 'oedmodal modalCenter',
      resolve: {
        data: () => ({
          course: $ctrl.course,
        })
      }
    });
  }
  // 资源单项改名
  $ctrl.resItemName = (item) => {
    event.stopPropagation();
    event.preventDefault()
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/editPresentResourceItemNameDialog.html'),
      controller: 'editPresentResourceItemNameCtrl',
      size: 'sm',
      windowClass: 'oedmodal modalCenter',
      resolve: {
        data: () => ({
          node: item,
        })
      }
    });
    modalInstance.result.then((r: any) => {
      $ctrl.getOedCourse();
    })
  }
  // 云盘上传入口
  $ctrl.openCloudDrive = (module) => {
    const id = module.id;
    oedDialogService.openModalWithComponent('CloudDriveDialogComponent',
      {
        isPrepareLesson: true,
      }, (params)=> {
        const packageArr: any = _.filter(module.treeData, (r: any) => {
          return r.resoureType === 'presentPackage';
        });
        let packgeId = -1;
        if (packageArr.length > 0) {
          packgeId = packageArr[packageArr.length - 1].id;
        }
        if (params.type === 'myFile') {
          cloundApi.importCloudDriveItem({
            courseId: $ctrl.courseId,
            moduleId: id,
            presentResourceId: packgeId}, params.res).$promise.then((r: any) => {
            notificationService.newPrepareNotify('已成功导入1个资源到 ' + module.name, '', 5000);
            $ctrl.memoryHandleId = r.id;
            $ctrl.getOedCourse();
            $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
          })
        } else {
          cloundApi.importMicroLessonItem({
            courseId: $ctrl.courseId,
            moduleId: id,
            presentResourceId: packgeId}, params.res).$promise.then((r: any) => {
            notificationService.newPrepareNotify('已成功导入1个资源到 ' + module.name, '', 5000);
            $ctrl.memoryHandleId = r.id;
            $ctrl.getOedCourse();
            $ctrl.autoScrollIntoView($ctrl.memoryHandleId);
          })
        }
      },
      {class: 'cl-library-dialog2 modal-cloud-drive'})
  }
  // 环节发布为作业
  $ctrl.mapTmTaskList = {};
  $ctrl.publishModule = (module) => {
    event.stopPropagation();
    event.preventDefault()
    if (module.treeData.length === 1) {
      return notificationService.notify('info', '空环节不支持转作业');
    }
    $ctrl.selectStudent = {};
    const tmTaskListKeys = _.keys($ctrl.mapTmTaskList[module.id]);
    const allRecords = _.get($ctrl.mapTmTaskList[module.id], _.max(tmTaskListKeys), []);
    const publishedClasses = [];
    $ctrl.loading = oedTeachingModule.getPublishedRecords({
          id: module.id
        }).$promise.then((records: any) => {
          if (_.size(records)) {
            return Promise.map(records, (item: any) => {
              publishedClasses.push(item.classId);
              // return oedTaskListPublishRecord.queryPublishInfo({id: item.id}).$promise.then(
              //   (res: any) => {
              //     $ctrl.selectStudent[res.cls.id] = res.publishedStudents;
              //   })
              $ctrl.selectStudent = {};
              return Promise.resolve();
            })
          } else {
            $ctrl.selectStudent = {};
            return Promise.resolve();
          }
        }).then(() => {
          $ctrl.doPublishModule(module, publishedClasses, allRecords, $ctrl.selectStudent);
        });
  };
  $ctrl.doPublishModule = (module, publishedClasses, allRecords, selStudent) => {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/publishTm.html'),
      controller: 'publishTmCtrl',
      size: 'md',
      windowClass: 'oedmodal modalCenter',
      resolve: {
        data: () => {
          return {
            course: $ctrl.course,
            publishRecords: allRecords,
            tmId: module.id,
            publishedClasses: _.uniq(publishedClasses),
            selectStudent: selStudent
          };
        }
      }
    });
    modalInstance.result.then((data) => {
      // $ctrl.loadHomeworkReport();
      notificationService.notify('info', '发布成功')
    });
  }
  $ctrl.autoScroll = false;
  $ctrl.handleAutoScroll = (num) => {
    if ($ctrl.autoScroll) {
      const topNum =  jQuery('.new-prepare-left-data').scrollTop();
      jQuery('.new-prepare-left-data').scrollTop(topNum + num);
      setTimeout(() => {
        $ctrl.handleAutoScroll(num);
      }, 50);
    }
  }
  // 拖动逻辑
  $ctrl.treeOption = {
    accept: (sourceNodeScope, destNodesScope, destIndex) => {
      const sourceData = sourceNodeScope.$modelValue;
      if (sourceData && sourceData.form === 'right') { // 判断来源是从右边
        if (sourceData.resoureType === 'allmodule'
         || sourceData.resoureType === 'module') { // 此判断禁止右侧教学设计或者某个环节拖到左侧某个环节中
          sourceData.handleDrag = 'reject';
          $('.angular-ui-tree-placeholder').remove();
          return false;
        }
        const targetValue = destNodesScope.$nodeScope ? destNodesScope.$nodeScope.$modelValue : null;
        if (targetValue) {
          if (targetValue.resoureType === 'presentPackage') {// 此判断禁止右侧客观试卷，主观活动，资源包拖到左侧某个环节的资源包
            if (((sourceData.resoureType === 'objective') ||
              (sourceData.resoureType === 'subjective') || (sourceData.resoureType === 'presentPackage'))) {
              sourceData.handleDrag = 'reject';
              $('.angular-ui-tree-placeholder').remove();
              return false;
            }
            if (sourceData.resoureType === 'singleResource' ||
               sourceData.resoureType === 'zhonglian') {
               sourceData.handleDrag = 'resolve';
               sourceData.needCreatePackage = false;
               return true;
            }
          } else {
            if (sourceData.resoureType === 'singleResource' ||
            sourceData.resoureType === 'zhonglian') {
              sourceData.handleDrag = 'resolve';
              sourceData.needCreatePackage = true;
              sourceData.memoryModuleId = destNodesScope.item.id;
              return true;
           } else {
            sourceData.handleDrag = 'resolve';
            return true
           }
          }
        }
        // if (!targetValue) {
        //   sourceData.handleDrag = 'resolve';
        //   if (sourceData.resoureType === 'singleResource' ||
        //    sourceData.resoureType === 'zhonglian') {
        //     sourceData.needCreatePackage = true;
        //     sourceData.memoryModuleId = destNodesScope.item.id;
        //   }
        //   return true;
        // } else {
        //   if (((sourceData.resoureType === 'objective') ||
        //      (sourceData.resoureType === 'subjective')) &&
        //      targetValue.resoureType === 'presentPackage') {
        //     sourceData.handleDrag = 'reject';
        //     return false;
        //   } else {
        //     sourceData.handleDrag = 'resolve';
        //     sourceData.needCreatePackage = false;
        //     return true;
        //   }
        // }
      } else {
        // if (sourceData && !sourceData.resoureType) {
        //   $ctrl.ingoreDrag = true;
        //   return false;
        // }
        if (sourceNodeScope.item.id !== destNodesScope.item.id) {
          if (sourceData.resoureType) {
            const targetValue = destNodesScope.$nodeScope ? destNodesScope.$nodeScope.$modelValue : null;
            if (targetValue) {
              if (((sourceData.resoureType === 'objective') ||
              (sourceData.resoureType === 'subjective') || (sourceData.resoureType === 'presentPackage')) &&
                 targetValue.resoureType === 'presentPackage') {
                 $ctrl.ingoreDrag = true;
                 return false;
              } else {
                $ctrl.ingoreDrag = false;
                return true;
              }
            } else {
              $ctrl.ingoreDrag = false;
              return true;
            }
          } else {
            $ctrl.ingoreDrag = true;
            return false;
          }
        } else {
          const sourceValue = sourceData;
          const targetValue = destNodesScope.$nodeScope ? destNodesScope.$nodeScope.$modelValue : null;
          if (targetValue) {
            if (((sourceValue.resoureType === 'objective') ||
            (sourceValue.resoureType === 'subjective') || (sourceValue.resoureType === 'presentPackage')) &&
               targetValue.resoureType === 'presentPackage') {// 此判断是用来禁止客观试卷，主观活动，资源包拖入资源包
               $ctrl.ingoreDrag = true;
               return false;
            } else {
              $ctrl.ingoreDrag = false;
              return true;
            }
          }
        }
      }
    },
    dragMove: (e) => {
      if (e.pos.nowY  + 30 >= window.innerHeight) {
        $ctrl.autoScroll = true;
        $ctrl.handleAutoScroll(3);
      } else if (e.pos.nowY < 180) {
        $ctrl.autoScroll = true;
        $ctrl.handleAutoScroll(-3);
      } else {
        $ctrl.autoScroll = false;
      }
    },
    dragStop: (e) => {
      $ctrl.autoScroll = false;
    },
    dropped: (e) => {
      const dest = e.dest.nodesScope;
      const sourceData = e.source.nodeScope.$modelValue;
      if (!dest.$nodeScope) {// 说明进行的操作是环节的子项拖到了教学设计的环节平级
        if (sourceData && sourceData.resoureType === 'singleResource') {
            oedPresentResource.moveItemToNewPreResByIndex({
              itemId: sourceData.id,
              moduleId: -1,
              index: e.dest.index
            }, {}).$promise.then((res: any) => {
              $ctrl.memoryHandleId = res.presentResourceDTO.id;
              $ctrl.collapseIdsArr.push(res.presentResourceDTO.id);
              $ctrl.getOedCourse();
            })
        } else if (sourceData && sourceData.resoureType === 'presentPackage') {
          oedPresentResource.dragLeftPackageToModuleByIndex({
            courseId: parseFloat($ctrl.courseId),
            fromTmId: sourceData.moduleId,
            toTmId: -1,
            presentResourceId: sourceData.id,
            index: e.dest.index
          }, {}).$promise.then((r: any) => {
             $ctrl.memoryHandleId = r.id;
             $ctrl.collapseIdsArr.push(r.id);
             $ctrl.getOedCourse();
          })
        } else if (sourceData && sourceData.resoureType === 'subjective') {
          oedPresentResource.dragLeftSubjectiveToModuleByIndex({
            courseId: parseFloat($ctrl.courseId),
            fromTmId: sourceData.moduleId,
            toTmId: -1,
            testId: sourceData.id,
            index: e.dest.index
          }, {}).$promise.then((r: any) => {
             $ctrl.memoryHandleId = r.id;
             $ctrl.getOedCourse();
          })
        } else if (sourceData && sourceData.resoureType === 'objective') {
          oedPresentResource.dragLeftObjectiveToModuleByIndex({
            courseId: parseFloat($ctrl.courseId),
            fromTmId: sourceData.moduleId,
            toTmId: -1,
            testId: sourceData.id,
            index: e.dest.index
          }, {}).$promise.then((r: any) => {
             $ctrl.memoryHandleId = r.id;
             $ctrl.getOedCourse();
          })
        }
      } else {
        if (!$ctrl.ingoreDrag) {
          const needHandleData = e.source.nodeScope.item.treeData;
          const tempLiIndex = _.findIndex(needHandleData, (item: any) => {
            return item.resoureType === 'tempLi';
          });
          if (tempLiIndex >= 0) {
            needHandleData.splice(tempLiIndex, 1);
            needHandleData.push({ resoureType: 'tempLi'});
          }
          if (e.source.nodeScope.item.id !== dest.item.id) { // 跨环节拖动
            const targetModuleId = dest.item.id;
            if (sourceData && sourceData.resoureType === 'singleResource') {
              if (dest.$nodeScope.$modelValue && dest.$nodeScope.$modelValue.resoureType === 'presentPackage') {
                const sourceTreeData = e.source.nodeScope.item.treeData;
                const findSourcePackage = _.filter(sourceTreeData, (x: any) => {
                  return Number(x.id) === Number(sourceData.resDetail.id);
                });
                const fromPresentResourceIdArr = findSourcePackage[0]['items'].map((r: any) => {
                  return r.id;
                });
                const target = dest.$nodeScope.$modelValue;
                const toPresentResourceIdArr = target['items'].map((r: any) => {
                  return r.id;
                })
                const requestObj = {
                  from: findSourcePackage[0].id,
                  to: target.id,
                  fromPresentResourceId: fromPresentResourceIdArr,
                  toPresentResourceId: toPresentResourceIdArr
                }
                oedPresentResource.updatePackageItem(requestObj).$promise.then((r: any) => {
                  $ctrl.getOedCourse();
                }).catch(() => {
                  $ctrl.getOedCourse();
                })
              } else {
                oedPresentResource.moveItemToNewPreResByIndex({
                  itemId: sourceData.id,
                  moduleId: targetModuleId,
                  index: e.dest.index
                }, {}).$promise.then((res: any) => {
                  $ctrl.memoryHandleId = res.presentResourceDTO.id;
                  $ctrl.collapseIdsArr.push(res.presentResourceDTO.id);
                  $ctrl.getOedCourse();
                })
              }
            } else if (sourceData && sourceData.resoureType === 'presentPackage') {
              oedPresentResource.dragLeftPackageToModuleByIndex({
                courseId: parseFloat($ctrl.courseId),
                fromTmId: sourceData.moduleId,
                toTmId: targetModuleId,
                presentResourceId: sourceData.id,
                index: e.dest.index
              }, {}).$promise.then((r: any) => {
                $ctrl.collapseIdsArr.push(r.id);
                $ctrl.memoryHandleId = r.id;
                $ctrl.getOedCourse();
              })
            } else if (sourceData && sourceData.resoureType === 'subjective') {
              oedPresentResource.dragLeftSubjectiveToModuleByIndex({
                courseId: parseFloat($ctrl.courseId),
                fromTmId: sourceData.moduleId,
                toTmId: targetModuleId,
                testId: sourceData.id,
                index: e.dest.index
              }, {}).$promise.then((r: any) => {
                $ctrl.memoryHandleId = r.id;
                $ctrl.getOedCourse();
              })
            } else if (sourceData && sourceData.resoureType === 'objective') {
              oedPresentResource.dragLeftObjectiveToModuleByIndex({
                courseId: parseFloat($ctrl.courseId),
                fromTmId: sourceData.moduleId,
                toTmId: targetModuleId,
                testId: sourceData.id,
                index: e.dest.index
              }, {}).$promise.then((r: any) => {
                $ctrl.memoryHandleId = r.id;
                $ctrl.getOedCourse();
              })
            }
          } else {
            const isDragResouceItem = e.source.nodeScope.$modelValue.resoureType === 'singleResource';
            if (isDragResouceItem) {
              const target = e.dest.nodesScope.$nodeScope ? e.dest.nodesScope.$nodeScope.$modelValue : null;
              if (!target.resoureType) {
                const resItemId = e.source.nodeScope.$modelValue.id;
                const resItemPackageId = e.source.nodeScope.$modelValue.resDetail.id;
                const curModule = e.source.nodeScope.item;
                const resItemPackage = _.filter(curModule.treeData, (item: any) => {
                  return item.id === resItemPackageId;
                })[0];
                const treeData = e.source.nodeScope.item.treeData;
                oedPresentResource.moveItemToNewPreRes({
                  itemId: resItemId,
                  moduleId: curModule.id
                }, {}).$promise.then((r: any) => {
                  let indexNum = null;
                  _.remove(treeData, (n: any, index) => {
                    if (n.id === resItemId) {
                      indexNum = index;
                      return n.id === resItemId;
                    }
                  });
                  const packageDto = r.presentResourceDTO;
                  packageDto.resDetail = _.cloneDeep(packageDto);
                  packageDto.resoureType = 'presentPackage';
                  packageDto.type = 'presentresource';
                  packageDto.isShown = true;
                  packageDto.collapsed = false;
                  packageDto.items[0].resDetail = packageDto.resDetail;
                  packageDto.items[0].resoureType = 'singleResource';
                  packageDto.items[0].resIndex = 0;
                  packageDto.moduleId = curModule.id;
                  treeData.splice(indexNum, 0, packageDto);
                  curModule.presentResources.push(r.teachingModulePresentResourceDTO);
                  $ctrl.collapseIdsArr.push(packageDto.id);
                  if (resItemPackage && resItemPackage.items.length === 0) {
                    $ctrl.sortLeftTreeData(treeData, curModule);
                    // oedPresentResource.delete({id: resItemPackage.id}).$promise.then((data: any) => {
                    //   _.remove(treeData, (n: any, index) => {
                    //     if (n.id === resItemPackage.id) {
                    //       return n.id === resItemPackage.id;
                    //     }
                    //   });
                    //   $ctrl.sortLeftTreeData(treeData, curModule);
                    // }).catch(() => {
                    //   $ctrl.getOedCourse();
                    // })
                  } else {
                    // if (resItemPackage) {
                    //   resItemPackage.resDetail = _.cloneDeep(resItemPackage);
                    //   if (resItemPackage.id === $ctrl.memoryHandleId) {
                    //     communicateService.sendMessage({ sourceFrom: 'left', data: resItemPackage });
                    //   }
                    // }
                    $ctrl.sortLeftTreeData(treeData, curModule);
                  }
                }).catch(() => {
                  $ctrl.getOedCourse();
                })
              } else {
                if (Number(target.id) === Number(e.source.nodeScope.$modelValue.resourceId)) {
                  const arr = target.items;
                  const itemsIdArr = arr.map((r: any) => {
                    return { id: r.id }
                  });
                  const requestObj = {
                    id: e.source.nodeScope.$modelValue.resourceId,
                    items: itemsIdArr
                  };
                  oedPresentResource.updateItemOrder({ id: e.source.nodeScope.$modelValue.resourceId }, requestObj)
                    .$promise.then((res: any) => {
                      // target.resDetail = _.cloneDeep(target);
                      // communicateService.sendMessage({ sourceFrom: 'left', data: target });
                       $ctrl.getOedCourse();
                    })
                } else {
                  const sourcePackageId = e.source.nodeScope.$modelValue.resourceId;
                  const targetPackageId = target.id;
                  const handleTreeData = e.source.nodeScope.item.treeData;
                  const findSourcePackage = _.filter(handleTreeData, (x: any) => {
                    return Number(x.id) === Number(sourcePackageId);
                  });
                  const sourcePackageIndex = _.findIndex(handleTreeData, (x: any) => {
                    return Number(x.id) === Number(sourcePackageId);
                  });
                  const fromPresentResourceIdArr = findSourcePackage[0]['items'].map((r: any) => {
                    return r.id;
                  })
                  const toPresentResourceIdArr = target['items'].map((r: any) => {
                    return r.id;
                  })
                  const requestObj = {
                    from: sourcePackageId,
                    to: targetPackageId,
                    fromPresentResourceId: fromPresentResourceIdArr,
                    toPresentResourceId: toPresentResourceIdArr
                  }
                  oedPresentResource.updatePackageItem(requestObj).$promise.then((r: any) => {
                    $ctrl.getOedCourse();
                    // e.source.nodeScope.$modelValue.resourceId = targetPackageId;
                    // findSourcePackage[0].resDetail = _.cloneDeep(findSourcePackage[0]);
                    // target.resDetail = _.cloneDeep(target);
                    // if (fromPresentResourceIdArr.length === 0) {
                    //   // oedPresentResource.delete({id: findSourcePackage[0].id}).$promise.then((data: any) => {
                    //   //   handleTreeData.splice(sourcePackageIndex, 1);
                    //   // })
                    //   if (findSourcePackage[0].id === $ctrl.memoryHandleId) {
                    //     communicateService.sendMessage({ sourceFrom: 'left', data: null });
                    //   } else if (target.id === $ctrl.memoryHandleId) {
                    //     communicateService.sendMessage({ sourceFrom: 'left', data: target });
                    //   }
                    // } else {
                    //   if (findSourcePackage[0].id === $ctrl.memoryHandleId) {
                    //     communicateService.sendMessage({ sourceFrom: 'left', data: findSourcePackage[0] });
                    //   } else if (target.id === $ctrl.memoryHandleId) {
                    //     communicateService.sendMessage({ sourceFrom: 'left', data: target });
                    //   }
                    // };
                  })
                }
              }
            } else {
              const source = e.source.nodeScope.item;
              const handleTreeData = source.treeData;
              $ctrl.sortLeftTreeData(handleTreeData, source);
            }
          }
        }
      }
    }
  }
  $ctrl.treeOption1 = {
    accept: (sourceNodeScope, destNodesScope, destIndex) => {
      if (sourceNodeScope.$modelValue && sourceNodeScope.$modelValue.form === 'right') { // 判断来源是从右边
        sourceNodeScope.$modelValue.tpId = $ctrl.teachingPattern.id;
        return true
        // let targetValue = destNodesScope.$nodeScope ? destNodesScope.$nodeScope.$modelValue : null;
        // if (!targetValue) {
        //   sourceNodeScope.$modelValue.handleDrag = 'resolve';
        //   if (sourceNodeScope.$modelValue.resoureType === 'singleResource' ||
        //    sourceNodeScope.$modelValue.resoureType === 'zhonglian') {
        //     sourceNodeScope.$modelValue.needCreatePackage = true;
        //     sourceNodeScope.$modelValue.memoryModuleId = destNodesScope.item.id;
        //   }
        //   return true;
        // } else {
        //   if (((sourceNodeScope.$modelValue.resoureType === 'objective') ||
        //      (sourceNodeScope.$modelValue.resoureType === 'subjective')) &&
        //      targetValue.resoureType === 'presentPackage') {
        //     sourceNodeScope.$modelValue.handleDrag = 'reject';
        //     return false;
        //   } else {
        //     sourceNodeScope.$modelValue.handleDrag = 'resolve';
        //     sourceNodeScope.$modelValue.needCreatePackage = false;
        //     return true;
        //   }
        // }
      } else {
        // if (sourceNodeScope.$modelValue && sourceNodeScope.$modelValue.resoureType) {
        //   $ctrl.ingoreDrag = true;
        //   return false;
        // } else {
        //   $ctrl.ingoreDrag = false;
        //   return true;
        // }
        return true;
      }
    },
    dragMove: (e) => {
      if (e.pos.nowY  + 30 >= window.innerHeight) {
        $ctrl.autoScroll = true;
        $ctrl.handleAutoScroll(3);
      } else if (e.pos.nowY < 180) {
        $ctrl.autoScroll = true;
        $ctrl.handleAutoScroll(-3);
      } else {
        $ctrl.autoScroll = false;
      }
    },
    dragStop: (e) => {
      $ctrl.autoScroll = false;
    },
    dropped: (e) => {
      const tempLiIndex = _.findIndex($ctrl.modules, (item: any) => {
        return item.id === '';
      });
      if (tempLiIndex >= 0) {
        $ctrl.modules.splice(tempLiIndex, 1);
      }
      const idMap = _.map($ctrl.modules, (item: any) => {
        return { id: item.id }
      });

      oedTeachingPattern.updateMoudleOrder({ id: $ctrl.teachingPattern.id, modules: idMap }).$promise.then((r: any) => {
        $ctrl.modules.push({id: ''});
      }).catch(() => {
        $ctrl.modules.push({id: ''});
      })
    }
  }
  $ctrl.sortLeftTreeData = (handleTreeData, source) => {
    _.each(handleTreeData, (res: any, index) => {
      res.sort = index;
    });
    const objectiveTestsArr = _.filter(handleTreeData, (obj: any) => {
      return obj.resoureType === 'objective'
    }).map((r: any) => {
      return {id: r.id, sort: r.sort}
    });
    const presentResourcesArr = _.filter(handleTreeData, (obj: any) => {
      return obj.resoureType === 'presentPackage'
    }).map((r: any) => {
      return {id: r.id, sort: r.sort}
    });
    const subjectiveTestsArr = _.filter(handleTreeData, (obj: any) => {
      return obj.resoureType === 'subjective'
    }).map((r: any) => {
      return {id: r.id, sort: r.sort}
    });
    const moduleId = source.id;
    const requestObj = {
      id: moduleId,
      objectiveTests: objectiveTestsArr,
      presentResources: presentResourcesArr,
      subjectiveTests: subjectiveTestsArr
    }
    oedTeachingModule.newUpdateItemOrder({id: moduleId}, requestObj).$promise.then((x: any) => {
       $ctrl.getOedCourse();
    }).catch(() => {
      $ctrl.getOedCourse();
    })
  }
  $ctrl.showHomeworkReport = false;
  $ctrl.showHomeworkReportFun = () => {
    $ctrl.selectedClass = null;
    $ctrl.isDeleteHomework = false;
    $ctrl.showHomeworkReport = !$ctrl.showHomeworkReport;
  }

  $ctrl.goSubjectiveDetail = (obj: any) => {
    $ctrl.goToHomeworkDetail = true;
    $ctrl.homeworkDetailType = 'subjective';
    $ctrl.homeworkDetailSubjective = obj;
  }
  $ctrl.goObjectiveDetail = (obj: any) => {
    $ctrl.goToHomeworkDetail = true;
    $ctrl.homeworkDetailType = 'objective';
    $ctrl.homeworkDetailObjective = obj;
  }
  $ctrl.goResourceDetail = (obj: any) => {
    $ctrl.goToHomeworkDetail = true;
    $ctrl.homeworkDetailType = 'resource';
    $ctrl.homeworkDetailResource = obj;
  }
  $ctrl.returnReportList = () => {
    $ctrl.goToHomeworkDetail = false;
  }
  $ctrl.selectReport = (cls) => {
    $ctrl.goToHomeworkDetail = false;
    $ctrl.selectedClass = cls;
    $ctrl.origTaskListId = cls.taskListId;
    const matchedRecord = _.find($ctrl.publishRecords, (r: any) => {
      return (r.classId === $ctrl.selectedClass.id) && (r.taskListId === $ctrl.selectedClass.taskListId);
    });
    const queryPubInfoOfSelectedPublishRecords = loadPubInfoOfPublishRecord(matchedRecord);

    return queryPubInfoOfSelectedPublishRecords.then(() => {
      $ctrl.publishRecordOfSelectedClass = matchedRecord;
      $ctrl.publishInfoOfSelectedClass = matchedRecord.publishInfo;
      $ctrl.taskListOfSelectedClass = matchedRecord.publishInfo.taskList;
      return $q.all([getCoursePathName(matchedRecord.publishInfo), loadTestsOfPublishRecord(matchedRecord)]);
    });
  };
  $ctrl.loadTaskListPublishDetails = () => {
    const getOrigTaskLists = oedReportTaskListPublish.queryByTaskId({
      courseId: $ctrl.courseId
    });
    return getOrigTaskLists.$promise.then((taskLists: any) => {
      if (_.size(taskLists)) {
        taskLists = _.orderBy(taskLists, 'publishRecords[0].publishDate', 'desc');
        const currentTask: any = $ctrl.selectedClass ?
          _.find(taskLists, (tl: any) => {
            return tl.taskList.id === $ctrl.selectedClass.taskListId
          }) : taskLists[0];
        $ctrl.originalTaskList = currentTask.taskList;
        $ctrl.originalTaskList.publishInfo = currentTask;
        return _.map(taskLists, (originalTaskList: any, index) => {
          _.forEach(originalTaskList.publishedClasses, (cls: any) => {
            cls.taskListId = originalTaskList.taskList.id;
            cls.taskListName = originalTaskList.taskList.name;
            const publishRecord = _.find(originalTaskList.publishRecords, (rec: any) => {
              return rec.classId === cls.id
            });
            cls.createDate = publishRecord.publishDate;
          });
          $ctrl.classes = _.concat($ctrl.classes, originalTaskList.publishedClasses);
          $ctrl.publishRecords = _.concat($ctrl.publishRecords, originalTaskList.publishRecords);
          if (!_.isEmpty($ctrl.classes)) {
            _.forEach($ctrl.classes, (cls: any) => {
              oedTaskList.getClassInfoById({
                classId: cls.id
              }).$promise.then((result: any) => {
                cls.fullClassName = result.levelName + result.gradeName + cls.className;
              });
            });
            if (_.size($ctrl.classes) && $ctrl.isDeleteHomework) {
              $ctrl.selectedClass = $ctrl.classes[0];
              $ctrl.origTaskListId = $ctrl.selectedClass.taskListId;
            }
          }
        })
      }
    });
  };
  $ctrl.loadHomeworkReport = () => {
    $ctrl.classes = [];
    $ctrl.publishRecords = [];
    $ctrl.goToHomeworkDetail = false;
    $ctrl.loadingReadyForChild = $ctrl.loadTaskListPublishDetails();
    $ctrl.loading = $ctrl.loadingReadyForChild;
    $ctrl.loadTaskListPublishDetailsForClass = $ctrl.loadingReadyForChild.then(() => {
      if (!_.isEmpty($ctrl.classes)) {
        const matchedRecord = _.find($ctrl.publishRecords, (r: any) => {
          return (r.classId === $ctrl.selectedClass.id) && (r.taskListId === $ctrl.selectedClass.taskListId);
        });

        const matchedClass = _.find($ctrl.classes, (r: any) => {
          return r.id === $ctrl.selectedClass.id;
        });
        $ctrl.selectedClass = matchedClass;
        const queryPubInfoOfSelectedPublishRecords = loadPubInfoOfPublishRecord(matchedRecord);

        return queryPubInfoOfSelectedPublishRecords.then(() => {
          $ctrl.publishRecordOfSelectedClass = matchedRecord;
          $ctrl.publishInfoOfSelectedClass = matchedRecord.publishInfo;
          $ctrl.taskListOfSelectedClass = matchedRecord.publishInfo.taskList;
          return $q.all([getCoursePathName(matchedRecord.publishInfo), loadTestsOfPublishRecord(matchedRecord)]);
        });
      } else {
        $ctrl.showHomeworkReport = false;
      }
    });
    $ctrl.loading = $ctrl.loadTaskListPublishDetailsForClass;
  }

  $ctrl.$onChanges = (changesObj: any) => {
    // if (_.has(changesObj, 'courseId')) {
    //   $ctrl.loadHomeworkReport();
    // }
  };

  $ctrl.getRemainingHours = (record) => {
    if (!record || !record.dueDate) {
      return 0;
    }
    return _.ceil((record.dueDate - Date.now()) / (60 * 60 * 1000));
  };

  function getCoursePathName(publishInfo: any) {
    if (!publishInfo) {
      return;
    }
    return oedCoursePath.get({
      id: publishInfo.taskList.coursepathId
    }).$promise.then((c: any) => {
      $ctrl.coursePathName = c.name;
    });
  }

  $ctrl.getRemaining = (record: any) => {
    if (!record || !record.dueDate) {
      return 0;
    }
    const hour = _.ceil((record.dueDate - Date.now()) / (60 * 60 * 1000));
    if (hour <= 24) {
      return hour + ' 小时';
    }
    return (Math.floor(hour / 24) + 1) + ' 天';
  };

  function loadTestsOfPublishRecord(publishRecord) {
    $ctrl.tasks = oedTask.queryByTaskListId({
      id: publishRecord.taskListId
    });

    $ctrl.taskResources = {};

    $ctrl.taskResources = {
      objectiveTests: [],
      subjectiveTests: [],
      presentResources: []
    };

    $ctrl.submitedStudents = [];

    $ctrl.tasks.$promise.then((tasks: any) => {
      $ctrl.presentResources = oedPresentResource.queryByTaskListId({
        id: publishRecord.taskListId
      });

      $ctrl.objAndSbjTests = oedObjectiveTest.queryByTaskListId({
        id: publishRecord.taskListId
      });
      const loadingTasks = _.map(tasks, (t: any) => {
        const objTests = oedTaskTest.queryTaskObjectiveTestByTaskId({
          id: t.id
        });

        const subjTests = oedTaskTest.queryTaskSubjectiveByTaskId({
          id: t.id
        });

        const preReses = oedTaskPresentResource.queryByTaskId({
          id: t.id
        });

        return $q.all([objTests.$promise, subjTests.$promise, preReses.$promise]).then(() => {
          const objSession = $q.all(_.map(objTests, (test) => {
            const testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
              recordId: publishRecord.id,
              taskId: t.id,
              origTestId: test.testId
            });
            return testSession.$promise.then((ts) => {
              test.testSession = ts;
              return oedTaskListPublishRecord.queryTestSessionSubmitCount({
                id: ts.id
              }).$promise.then((submitCount) => {
                $ctrl.submitedStudents = $ctrl.submitedStudents.concat(_.map(submitCount, 'uid'));
              });
            });
          }));

          const subSession = $q.all(_.map(subjTests, (test) => {
            const testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
              recordId: publishRecord.id,
              taskId: t.id,
              origTestId: test.testId
            });

            return testSession.$promise.then((ts) => {
              const boardSession = oedBoardSession.queryByTestSessionId({
                sessionId: ts.id
              });
              const submitCount = oedTaskListPublishRecord.queryTestSessionSubmitCount({
                id: ts.id
              });
              return $q.all([boardSession.$promise, submitCount.$promise]).then(() => {
                test.boardSession = boardSession[0];
                test.sessionId = ts.id;
                test.boardSessionId = boardSession[0].id;
                $ctrl.submitedStudents = $ctrl.submitedStudents.concat(_.map(submitCount, 'uid'));
              });
            });
          }));

          const showSession = $q.all(_.map(preReses, (preRes) => {
            return oedPreResSession.getPreResSessionByTaskListRecordAndPreResId({
              recordId: publishRecord.id,
              taskId: t.id,
              preResId: preRes.resId
            }).$promise.then((preResSession) => {
              preRes.sessionId = preResSession.id;
              const preresStudent = oedPreResSessionStudent.queryByPreResSessionId({
                sessionId: preResSession.id
              });
              return $q.all([preresStudent.$promise]).then(() => {
                $ctrl.submitedStudents = $ctrl.submitedStudents.concat(
                  _.map(_.filter(preresStudent, 'submitTime'), 'studentId'));
              });
            });
          }));

          return $q.all([objSession, subSession, showSession]).then(() => {
            $ctrl.taskResources.objectiveTests = $ctrl.taskResources.objectiveTests.concat(objTests);

            $ctrl.taskResources.subjectiveTests = $ctrl.taskResources.subjectiveTests.concat(subjTests);

            $ctrl.taskResources.presentResources = $ctrl.taskResources.presentResources.concat(preReses);
          });
        });
      });
      return $q.all(loadingTasks);
    });
  }

  $ctrl.getSubTaskName = (test: any) => {

    const name = _.result(_.find($ctrl.objAndSbjTests, (tmp) => {
      return tmp.id === test.testId;
    }), 'name');
    if (!_.isEmpty(name)) {
      return name;
    } else {
      return _.result(_.find($ctrl.presentResources, (tmp) => {
        return Number(tmp.id) === Number(test.resId);
      }), 'name');
    }
  };

  function loadPubInfoOfPublishRecord(publishRecord) {
    const query = oedTaskListPublishRecord.queryPublishInfo({
      id: publishRecord.id
    });

    return query.$promise.then((pubInfo) => {
      publishRecord.publishInfo = pubInfo;
      pubInfo.submitProgress = 0;
      pubInfo.reviewProgress = 0;
      if (pubInfo.totalStudentCount > 0) {
        pubInfo.submitProgress = (pubInfo.totalSubmittedStdCount * 100 / pubInfo.totalStudentCount).toFixed(0);
        pubInfo.reviewProgress = (pubInfo.totalReviewedStdCount * 100 / pubInfo.totalStudentCount).toFixed(0);
      }
    });
  }

  $ctrl.publishOrigTaskList = () => {
    const existingClsIds = _.map($ctrl.publishRecords, (pr) => {
      return pr.classId;
    });
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/publishTaskList.html'),
      controller: 'publishTaskListCtrl',
      size: 'md',
      windowClass: 'oedmodal modalCenter',
      resolve: {
        data: () => {
          return {
            taskListId: $ctrl.originalTaskList.id,
            existingClassIds: existingClsIds
          };
        }
      }
    });
    modalInstance.result.then((r) => {
      $ctrl.loading = $ctrl.loadTaskListPublishDetails();
    });
  };

  function ensureAllRecordPubInfos(records) {
    return $q.all(_.map(records, (record: any) => {
      if (!_.isEmpty(record.publishInfo)) {
        return $q.resolve(record.publishInfo);
      }
      return oedTaskListPublishRecord.queryPublishInfo({id: record.id}).$promise.then((pubInfo: any) => {
        record.publishInfo = pubInfo;
        return pubInfo;
      });
    }));
  }

  $ctrl.showTable = () => {
    $uibModal.open({
      template: require('assets/app2/review/objOverAllStatTable.html'),
      controller: 'objOverAllStatTableCtrl',
      windowClass: 'oedmodal modalCenter',
      size: 'lg',
      resolve: {
        data: () => {
          return {
            classId: $ctrl.publishRecordOfSelectedClass.classId,
            publishRecordId: $ctrl.publishRecordOfSelectedClass.id,
            totalResourceCount: _.size($ctrl.taskResources.objectiveTests) +
              _.size($ctrl.taskResources.subjectiveTests) + _.size($ctrl.taskResources.presentResources)
          };
        }
      }
    });
  };

  $ctrl.updateCurrentPublishRecord = () => {
    const existingClsIds = _.map($ctrl.classes, (cls) => {
      if (cls.taskListId === $ctrl.selectedClass.taskListId) {
        return cls.id;
      }
    });

    ensureAllRecordPubInfos($ctrl.publishRecords)
      .then((whatever: any) => {
        const selectedClassStudents = {};
        _.each($ctrl.publishRecords, (record) => {
          selectedClassStudents[record.classId] = _.get(record, 'publishInfo.publishedStudents', []);
        });

        const modalInstance = $uibModal.open({
          template: require('assets/templates/homework/updatePublishTaskList.html'),
          controller: 'updatePublishTaskListCtrl',
          size: 'md',
          windowClass: 'oedmodal modalCenter',
          resolve: {
            data: () => {
              return {
                taskList: $ctrl.taskListOfSelectedClass,
                publishRecord: $ctrl.publishRecordOfSelectedClass,
                publishRecords: $ctrl.publishRecords,
                selectedClassStudents,
                existingClassIds: existingClsIds,
                objAndSbjTests: $ctrl.objAndSbjTests,
                presentResources: $ctrl.presentResources,
                submitedStudents: _.uniq($ctrl.submitedStudents),
                isFromNewPrepareReport: true,
                courseId: $ctrl.courseId
              };
            }
          }
        });
        modalInstance.result.then((dirty) => {
          if (dirty) {
            $ctrl.loading = $ctrl.loadHomeworkReport();
          }
        });
      });
  };

  $ctrl.refreshCurrentPublishRecord = () => {
    const existingClsIds = _.map($ctrl.classes, (cls) => {
      if (cls.taskListId === $ctrl.selectedClass.taskListId) {
        return cls.id;
      }
    });
    ensureAllRecordPubInfos($ctrl.publishRecords)
      .then((whatever) => {
        const selectedClassStudents = {};
        _.each($ctrl.publishRecords, (record) => {
          selectedClassStudents[record.classId] = _.get(record, 'publishInfo.publishedStudents', []);
        });

        const modalInstance = $uibModal.open({
          template: require('assets/templates/homework/refreshPublishTaskList.html'),
          controller: 'refreshPublishTaskListCtrl',
          size: 'md',
          windowClass: 'oedmodal modalCenter',
          resolve: {
            data: () => {
              return {
                taskList: $ctrl.taskListOfSelectedClass,
                publishRecord: $ctrl.publishRecordOfSelectedClass,
                publishRecords: $ctrl.publishRecords,
                selectedClassStudents,
                taskResources: $ctrl.taskResources,
                existingClassIds: existingClsIds,
                isFromNewPrepareReport: true,
                courseId: $ctrl.courseId
              };
            }
          }
        });
        modalInstance.result.then((dirty) => {
          if (dirty) {
            $ctrl.loading = $ctrl.loadHomeworkReport();
          }
        });
      });
  }

  $ctrl.cancelCurrentPublishRecord = () => {
    const dlg = dialogs.confirm('确定取消作业', '确定取消' + $ctrl.selectedClass.className + '的作业?');
    dlg.result.then((btn) => {
      if ($ctrl.selectedClass.id === 0) {
        return $q.all(_.map($ctrl.publishRecords, (pr: any) => {
          return oedTaskListPublishRecord.delete({
            id: pr.id
          }).$promise.then(() => {
            $ctrl.isDeleteHomework = true;
            $ctrl.loading = $ctrl.loadHomeworkReport();
          });
        }));
      } else {
        return oedTaskListPublishRecord.delete({
          id: $ctrl.publishRecordOfSelectedClass.id
        }).$promise.then(() => {
          $ctrl.isDeleteHomework = true;
          $ctrl.loading = $ctrl.loadHomeworkReport();
        });
      }
    });
  };
}
editCourseNameCtrl.$inject = ['$scope', '$q', '$uibModalInstance',
'oedCourse', 'data', 'notificationService']
function editCourseNameCtrl($scope, $q, $uibModalInstance, oedCourse, data,notificationService) {
  $scope.course = data.course;
  $scope.cloneName = _.cloneDeep($scope.course.courseName);
  $scope.ok = () => {
    $scope.showValidationError = true;
    if ($scope.course.courseName && $scope.course.courseName.length > 64) {
      return notificationService.notify('error', '教学题目不能超过64个字');
    }
    if ($scope.editResForm.$invalid) {
      return;
    }
    $scope.loading = oedCourse.editCourseName({
      id:  $scope.course.id,
      name:  $scope.course.courseName
    }).$promise;
    $scope.loading.finally(() => {
      $uibModalInstance.close({});
    });
  };

  $scope.cancel = () => {
    $scope.course.courseName =  $scope.cloneName;
    $uibModalInstance.dismiss('cancel');
  };

}
editPresentResourceItemNameCtrl.$inject = ['$scope', '$q', '$uibModalInstance',
'oedPresentResourceItem', 'data']
function editPresentResourceItemNameCtrl($scope, $q, $uibModalInstance, oedPresentResourceItem, data) {
  $scope.node = data.node;
  $scope.resourceName = $scope.node.resourceName.split('.')[0];
  $scope.suffix = $scope.node.resourceName.split('.')[1];
  $scope.cloneName = _.cloneDeep($scope.node.resourceName);
  $scope.ok = () => {
    $scope.showValidationError = true;
    if ($scope.editResForm.$invalid) {
      return;
    }
    $scope.loading = oedPresentResourceItem.editPresentResourceItemName({
      itemId:  $scope.node.id,
      itemName:  $scope.resourceName + '.' + $scope.suffix
    }).$promise;
    $scope.loading.finally(() => {
      $scope.node.resourceName = $scope.resourceName + '.' + $scope.suffix;
      $uibModalInstance.close({});
    });
  };

  $scope.cancel = () => {
    data.node.resourceName = $scope.cloneName;
    $uibModalInstance.dismiss('cancel');
  };

}
newUploadResCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'Upload', 'notify',
'oedConfig', 'oedPresentResource', 'oedFileUtils', 'resourceNeeded', 'communicateService',
'notificationService', 'oedObjectiveTest', '$rootScope', '$uibModal', '$interval', '$http']
function newUploadResCtrl($scope, $q, $uibModalInstance, Upload, notify,
  oedConfig, oedPresentResource, oedFileUtils, resourceNeeded, communicateService,
  notificationService, oedObjectiveTest, $rootScope, $uibModal, $interval, $http) {
  $scope.res = {};
  if (resourceNeeded.isClassing) {
    $scope.selectDataArr = [{ name: '答题卡试卷' }, { name: '普通模版试卷' }];
  } else {
    $scope.selectDataArr = [{ name: '导学案' }, { name: '答题卡试卷' }, { name: '普通模版试卷' }];
  }
  $scope.selectedName = $scope.selectDataArr[0].name;
  $scope.uploadCount = 0;
  $scope.uploading = 0;

  $scope.respondData = [];
  $scope.changeSelect = (data) => {
    $scope.selectedName = data.name;
  }
  $scope.startUpload = () => {
    oedObjectiveTest.createObjPackge({ moduleId: resourceNeeded.moduleId }, {}).$promise.then((res: any) => {
      $scope.test = res;
      // 正在上传
      if ($scope.uploading > 0) {
        return;
      }
      const allFiles = $scope.handledFiles;
      $scope.loadingMessage = '正在上传';

      const uploadFile = (upfile) => {
        $scope.uploading++;
        return $q((resolve, reject) => {
          Upload.upload({
            url: oedConfig.url('objectivetest/' + res.id + '/uploadresource'),
            file: upfile
          }).progress((evt: any) => {
            if (upfile.progress && upfile.progress === 120) {
              return;
            }
            const progressPercentage = _.parseInt((100.0 * evt.loaded / evt.total) as any);
            upfile.progress = progressPercentage;
          }).success((data, status, headers, config) => {
            $scope.uploading--;
            $scope.uploadCount++;
            $scope.respondData.push(data);
            if (data.error.length > 0) {
              return notificationService.notify('error', data.error[0], '', 2000);
            }
          }).error(() => {
            $scope.uploading--;
            upfile.progress = 120;
            $scope.respondData.push({ error: ['上传失败'] });
          }).finally(() => {
            resolve();
          });
        });
      };

      const filesToUpload = _(allFiles).filter((f: any) => {
        const info = _.keyBy(f.validateResults, 'level');
        return !_.has(info, 'error');
      });

      if (filesToUpload.isEmpty()) {
        return notificationService.notify('info', '没有要上传的文件, 请选择文件后再点击上传')
      }

      $scope.uploadOnce = true;

      $scope.loading = Promise.map(filesToUpload.value(), (f) => {
        if (f.progress) {
          return null;
        }
        return uploadFile(f);
      }).then(() => {
        let failedFiles = [];
        failedFiles = _.filter($scope.respondData, (data) => {
          return data.error.length > 0;
        });
        if (failedFiles.length === 0) {
          $uibModalInstance.close({
            respondData: $scope.respondData,
            test: $scope.test
          });
        }
      }).finally(() => { });
    })
  };

  $scope.close = () => {
    $uibModalInstance.close({
      respondData: $scope.respondData,
      test: $scope.test
    });
  };

  $scope.closeWithoutData = () => {
    $uibModalInstance.close({
    });
  };
  $scope.showRules = () => {
    if ($scope.selectedName === '导学案') {
      const modalInstance1 = $uibModal.open({
        template: require('assets/templates/prepareCourse/wordUploaderRulesDialog.html'),
        controller: 'wordUploaderRulesCtrl',
        size: 'md',
        resolve: {
          test: () => {
            return 'ab';
          },
          data: () => {
            return {
              isDaoxuean: true
            }
          }
        },
      });
    } else {
      const result = resourceNeeded.showRules();
    }
  };

  $scope.handledFiles = [];
  $scope.$watch('files', () => {
    if ($scope.files) {
      $scope.cancelUpload = false;
      if ($scope.selectedName === '答题卡试卷') {
        $rootScope.$broadcast('uploadTestCard', { file: $scope.files, moduleId: resourceNeeded.moduleId });
      } else if ($scope.selectedName === '普通模版试卷') {
        $scope.handledFiles = [];
        const validateResults = oedFileUtils.validateFileUpload($scope.files, {
          allowedSuffixes: ['doc', 'docx']
        });
        if (validateResults.length > 0) {
          oedFileUtils.notifyValidationReporter(validateResults);
        } else {
          $scope.handledFiles = [$scope.files];
          $scope.startUpload();
        }
      } else {
        const validateResults = oedFileUtils.validateFileUpload($scope.files, {
          allowedSuffixes: ['doc', 'docx', 'pdf']
        });
        if (validateResults.length > 0) {
          return oedFileUtils.notifyValidationReporter(validateResults);
        }
        $uibModalInstance.close({
        });
        return $q((resolve, reject) => {
          $scope.importInstance = $uibModal.open({
            component: 'importIngDialog',
            size: 'sm',
            windowClass: 'importIngDialog modalCenter',
            resolve: {
              data: () => {
                return {
                };
              }
            }
          });
          $scope.importInstance.result.then((r: any) => {
            if (r) {
              $scope.cancelUpload = true;
            }
          })
          Upload.upload({
            url: oedConfig.url_b('resource/create'),
            file: $scope.files
          }).progress((evt) => {
            if ($scope.cancelUpload) {
              resolve(null);
            }
          }).success((data, status, headers, config) => {
            resolve(data);
          }).error(() => {
            resolve(null);
          })
        }).then((res) => {
          if (!res) {
            notificationService.notify('error', '上传失败');
            $scope.importInstance.close();
          }
          let cancel = false;
          const ret = $interval(() => {
            const url = '/ray/api/b/resource/' + res.id;
            return $http.get(url).then((res1) => {
              if ($scope.cancelUpload) {
                return;
              }
              if (res1.data.transFlag !== 1) {
                return;
              }
              if (!cancel) {
                $interval.cancel(ret);
                cancel = true;
                return oedObjectiveTest.getGuidanceCasePdf({ resourceId: res.id }).$promise.then((r: any) => {
                  if (r && !r.success) {
                    $scope.importInstance.close();
                    return $scope.importFailedFiledDialog = $uibModal.open({
                      template: require('assets/app2/newPrepareCourse/uploadFailedDialog/uploadFailedDialog.html'),
                      controller: 'uploadFailedDialogCtrl',
                      size: 'sm',
                      windowClass: 'uploadFailedDialog modalCenter',
                      resolve: {
                        data1: () => {
                          return {
                            moduleId: resourceNeeded.moduleId
                          };
                        }
                      }
                    });
                  }
                  return oedObjectiveTest.createGuidanceCaseTest({
                    moduleId: resourceNeeded.moduleId,
                    resourceId: res.id
                  }, {}).$promise.then((r: any) => {
                    $scope.memoryTestId = r.id;
                    $scope.importInstance.close();
                    const modalInstance = $uibModal.open({
                      component: 'dialogOne',
                      resolve: {
                        data: () => {
                          return {
                            msg: '',
                            title: '已成功导入word试卷',
                            yesText: '下载打印版本并查看试卷',
                            isImportTest: true
                          }
                        },
                      },
                      size: 'sm',
                      windowClass: 'commonDialog importTest',
                    });
                    modalInstance.result.then(res => {
                      if (res) {
                        window.open(`/ray/resource/view/pageMarked/pdf/
                        test/${$scope.memoryTestId}/pdf/pageMarked`, '_blank');
                      }
                      communicateService.sendDragMessage({needfresh: true, memoryHandleId: $scope.memoryTestId});
                    })
                  });
                })
              }
            }).catch(() => {
              return
            })
          }, 1500, 100)
          ret.then(() => {
            $scope.importInstance.close();
            notificationService.notify('error', '生成试卷失败，请稍后重试', '', 5 * 1000)
          })
        })
      }
    }
  });
  $scope.$on('modal.closing', ($event) => {
    if ($scope.uploading > 0) {
      $event.preventDefault();
    }
  });
}
uploadFailedDialogCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'Upload', 'notify',
  'oedConfig', 'oedPresentResource', 'oedFileUtils', 'data1', 'communicateService',
  'notificationService', 'oedObjectiveTest', '$rootScope', '$uibModal', '$interval', '$http']
function uploadFailedDialogCtrl($scope, $q, $uibModalInstance, Upload, notify,
  oedConfig, oedPresentResource, oedFileUtils, data1, communicateService,
  notificationService, oedObjectiveTest, $rootScope, $uibModal, $interval, $http) {
  $scope.res = {};
  $scope.close = () => {
    $uibModalInstance.close({
    });
  };
  $scope.$watch('files', () => {
    if ($scope.files) {
        $uibModalInstance.close({
        });
        return $q((resolve, reject) => {
          $scope.importInstance = $uibModal.open({
            component: 'importIngDialog',
            size: 'sm',
            windowClass: 'importIngDialog modalCenter',
            resolve: {
              data: () => {
                return {
                };
              }
            }
          });
          Upload.upload({
            url: oedConfig.url_b('resource/create'),
            file: $scope.files
          }).progress((evt) => {
          }).success((data, status, headers, config) => {
            resolve(data);
          }).error(() => {
            resolve(null);
          })
        }).then((res) => {
          let cancel = false;
          const ret = $interval(() => {
            const url = '/ray/api/b/resource/' + res.id;
            return $http.get(url).then((res1) => {
              if (res1.data.transFlag !== 1) {
                return;
              }
              if (!cancel) {
                $interval.cancel(ret);
                cancel = true;
                return oedObjectiveTest.getGuidanceCasePdf({ resourceId: res.id }).$promise.then((r: any) => {
                  if (r && !r.success) {
                    $scope.importInstance.close();
                    return $scope.importFailedFiledDialog = $uibModal.open({
                      template: require('assets/app2/newPrepareCourse/uploadFailedDialog/uploadFailedDialog.html'),
                      controller: 'uploadFailedDialogCtrl',
                      size: 'sm',
                      windowClass: 'uploadFailedDialog modalCenter',
                      resolve: {
                        data1: () => {
                          return {
                            moduleId: data1.moduleId
                          };
                        }
                      }
                    });
                  }
                  return oedObjectiveTest.createGuidanceCaseTest({
                    moduleId: data1.moduleId,
                    resourceId: res.id
                  }, {}).$promise.then((r: any) => {
                    $scope.importInstance.close();
                    const modalInstance = $uibModal.open({
                      component: 'dialogOne',
                      resolve: {
                        data: () => {
                          return {
                            msg: '',
                            title: '已成功导入word试卷',
                            yesText: '下载打印版本并查看试卷',
                            isImportTest: true
                          }
                        },
                      },
                      size: 'sm',
                      windowClass: 'commonDialog importTest',
                    });
                    modalInstance.result.then(r => {
                      communicateService.sendDragMessage({needfresh: true});
                    })
                  });
                })
              }
            }).catch(() => {
              return
            })
          }, 1500, 100)
          ret.then(() => {
            notificationService.notify('error', '生成试卷失败，请稍后重试', '', 5 * 1000)
          })
        })
    }
  });
  $scope.showRules = () => {
    event.preventDefault();
    event.stopPropagation();
    const modalInstance1 = $uibModal.open({
      template: require('assets/templates/prepareCourse/wordUploaderRulesDialog.html'),
      controller: 'wordUploaderRulesCtrl',
      size: 'md',
      resolve: {
        test: () => {
          return 'ab';
        },
        data: () => {
          return {
            isDaoxuean: true
          }
        }
      },
    });
  }
  $scope.cancel = () => {
    $uibModalInstance.close({
    });
  };
}
