var bluebird = require('bluebird');
require('app2/utils/taskUtils');
require('app2/utils/homeworkGroupUtils');

angular.module('app.directives.homework.homeworkEditorGroupHead', [])
  .directive('homeworkEditorGroupHead', homeworkEditorGroupHead);

homeworkEditorGroupHead.$inject = ['$document', '$timeout']
function homeworkEditorGroupHead($document, $timeout) {
  return {
    restrict: 'E',
    scope: {
      groups: '=',
      group: '=',
      hasPreresesSelected: '&',
      doPreResEvent: '&',
      createTasksFromLibrary: '&',
      deletePreresesSelected: '&',
      hasObjQuesSelected: '&',
      deleteObjQuesSelected: '&',
      onSaveGroup: '&'
    },
    template: require('assets/app2/directives/homework/homeworkEditorGroupHead.html'),
    controller: homeworkEditorGroupHeadCtrl
  };
}

homeworkEditorGroupHeadCtrl.$inject = [
  '$scope', '$log', 'dialogs', 'localStorageService', 'notify', '$q',
  '$uibModal', 'oedTaskTest', 'oedTaskPresentResource', 'oedHomeworkGroupUtils']
function homeworkEditorGroupHeadCtrl($scope, $log, dialogs, localStorageService, notify, $q, $uibModal,
  oedTaskTest, oedTaskPresentResource, oedHomeworkGroupUtils) {
  $scope.types = {
    'objectiveTest': '习题检测',
    'subjectiveTest': '活动探究',
    'presentResource': '展示资源'
  };

  $scope.onLeaveEditText = function(group) {
    if (_.has(group, 'id')) {
      $scope.onSaveGroup(group);
    }
  };

  $scope.toggleDropDown = function(group) {
    group.$dropDown = !group.$dropDown;
  };

  $scope.toggleOpen = function(group) {
    group.$open = !group.$open;
  };

  $scope.removeGroup = function(group) {
    oedHomeworkGroupUtils.removeGroup($scope.groups, group);
  };

  $scope.getGroupName = function(group) {
    return oedHomeworkGroupUtils.getGroupName(group);
  }

  $scope.modifyGroupType = function(group, type) {
    oedHomeworkGroupUtils.modifyGroupType($scope.groups, group, type);
  };

}
