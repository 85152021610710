(function() {
  angular.module('app.lang.zh-CN', ['pascalprecht.translate'])
    .config(config);

  config.$inject = ['$translateProvider'];
  function config($translateProvider) {
    $translateProvider.translations('zh-CN', {
      DIALOGS_ERROR: '错误',
      DIALOGS_ERROR_MSG: '发生未知错误.',
      DIALOGS_CLOSE: '关闭',
      DIALOGS_PLEASE_WAIT: '请稍等',
      DIALOGS_PLEASE_WAIT_ELIPS: '请稍等...',
      DIALOGS_PLEASE_WAIT_MSG: '等待操作完成.',
      DIALOGS_PERCENT_COMPLETE: '% 已完成',
      DIALOGS_NOTIFICATION: '通知',
      DIALOGS_NOTIFICATION_MSG: '未知的应用程序通知.',
      DIALOGS_CONFIRMATION: '确认',
      DIALOGS_CONFIRMATION_MSG: '需要确认.',
      DIALOGS_OK: '好',
      DIALOGS_YES: '是',
      DIALOGS_NO: '否'
    });

    $translateProvider.preferredLanguage('zh-CN');
  }
})();
