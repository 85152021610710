(function() {
  'use strict';

  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentTasks', {
        url: '/student/courseTasks',
        template: require('assets/templates/student/studentTasks.html'),
        controller: 'studentTasksCtrl'
      });

    }]).controller('studentTasksCtrl', studentTasksCtrl);

  studentTasksCtrl.$inject = ['$scope', '$state', '$stateParams', 'userInfo', 'oedCoursePath']
  function studentTasksCtrl($scope, $state, $stateParams, userInfo, oedCoursePath) {
    $scope.userInfo = userInfo.get();
    $scope.userInfoService = userInfo;
    var coursePathId = _.parseInt($state.params.coursePathId);
    $scope.currentCoursePath = {};
    userInfo.then(function(info) {
      var query = oedCoursePath.queryCoursePathWithTaskByStudent({
        uid: info.uid
      });

      query.$promise.then(function(cps) {
        $scope.coursePaths = cps;
        // 添加个特殊的"全部任务"的course并选中
        // $scope.coursePaths.splice(0, 0, {id: 0, name: '全部任务'});

        // 默认选中"全部任务"
        $scope.setCurrentCoursePath(0, true);
      });
    });

    $scope.pageSize = 10;
    $scope.filters = [{
      name: '所有作业',
      value: 'all'
    }, {
      name: '进行中',
      value: 'inprogress'
    }, {
      name: '已完成',
      value: 'finished'
    }];
    $scope.curState = {
      selectedFilter: $scope.filters[0]
    };

    $scope.setCurrentCoursePath = function(cpId, replace) {
      $scope.currentCoursePath.id = cpId;
      var opt = replace ? {
        location: 'replace'
      } : null;

      $state.go('studentTasks.courseTasks', {
        coursePathId: cpId
      }, opt);
    };
  }
})();
