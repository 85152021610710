(function() {
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.reviewJoinSchoolRequest', {
        url: '/reviewJoinSchoolRequest',
        template: require('assets/templates/manage/reviewJoinSchoolRequest.html'),
        controller: 'reviewJoinSchoolRequestCtrl'
      });
    }])
    .controller('reviewJoinSchoolRequestCtrl', reviewJoinSchoolRequestCtrl);

  reviewJoinSchoolRequestCtrl.$inject = ['$scope', '$state', '$stateParams',
    'userInfo', 'dialogs', 'notificationService', 'oedSchoolJoinRequest']
  function reviewJoinSchoolRequestCtrl($scope, $state, $stateParams,
    userInfo, dialogs, notificationService, oedSchoolJoinRequest) {
    $scope.currentState.title = '审核教师';
    userInfo.then(function(info) {
      $scope.users = oedSchoolJoinRequest.queryBySchoolId({
        id: info.schoolId
      });
    });

    $scope.approve = function(user) {
      var dlg = dialogs.confirm('批准老师申请', '确定批准 "' + user.name + '" 加入学校吗？');
      dlg.result.then(function() {
        $scope.loading = user.$approve();
        $scope.loading.then(function() {
          notificationService.notify('info', '信息更新成功')
          _.remove($scope.users, user);
        });
      });
    };

    $scope.reject = function(user) {
      var dlg = dialogs.confirm('拒绝教师申请', '确定拒绝 "' + user.name + '" 加入学校申请吗？');
      dlg.result.then(function() {
        $scope.loading = user.$reject();
        $scope.loading.then(function() {
          notificationService.notify('info', '信息更新成功')
          _.remove($scope.users, user);
        });
      });
    };
  }
})();
