const timeSpans = require('app2/utils/dateUtils').timeSpans;
const hourSpans = require('app2/utils/dateUtils').hourSpans;
const minuteSpans = require('app2/utils/dateUtils').minuteSpans;
(function() {
  angular.module('app.prepareCourse')
    .controller('publishTmCtrl', publishTmCtrl);

  publishTmCtrl.$inject = ['$scope', '$q', '$uibModal', '$uibModalInstance', 'userInfo',
    'oedUserClass', 'oedTmSession', 'data', 'oedTaskList', 'oedTaskListPublishRecord', 'notify', 'oedZhongZhiSchool']
  function publishTmCtrl($scope, $q, $uibModal, $uibModalInstance, userInfo,
    oedUserClass, oedTmSession, data, oedTaskList, oedTaskListPublishRecord, notify, oedZhongZhiSchool) {

    $scope.record = {
      tmId: data.tmId,
      classes: [],
      selectedClassStudents: data.selectStudent,
      startDate: new Date(),
      dueDate: new Date(),
    };
    $scope.record.dueDate.setDate($scope.record.startDate.getDate() + 1);
    $scope.item = []; // 选中的班级

    $scope.classes = [];  // 尚未发布过的班级列表
    $scope.time = timeSpans;
    $scope.hour = hourSpans;
    $scope.minute = minuteSpans;
    $scope.answerDetailsType = '0';
    userInfo.then(function(info) {
      $scope.uid = info.uid;
      $scope.loading = oedUserClass.queryByUser({
        userId: info.uid
      }).$promise.then(function(allClasses) {
        calcPublishedClassNames(allClasses);
        $scope.classes = _.filter(allClasses, cl => cl.studentCount !== 0);
        $scope.classes.unshift({
          id: '-1',
          levelName: '',
          gradeName: '',
          rxnd: '',
          className: '全部班级',
        });
        // $scope.record.classes = _.filter(allClasses, function(cls) {
        //   return _.includes(data.publishedClasses, cls.id);
        // });
      });
      $scope.loading = oedZhongZhiSchool.isZhongZhiSchool().then(res => {
        $scope.isZhongZhiSchool = res;
      })
    });

    function calcPublishedClassNames(allClasses) {
      var mapClasses = _.keyBy(allClasses, 'id');
      var validPublishedClasses = _.filter(data.publishedClasses, function(clsId) {
        return clsId in mapClasses;
      });
      var clsNameList = _.map(validPublishedClasses, function(clsId) {
        var cls = mapClasses[clsId];
        return cls.levelName + cls.gradeName + cls.className;
      });
      var clsNames = _.join(clsNameList, ', ');
      $scope.publishedClassNames = _.isEmpty(clsNames) ? '' : '(' + clsNames + ')';
    }

    $scope.openDueDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.dueDateOpened = true;
    };

    $scope.openStartDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startDateOpened = true;
    };

    $scope.status = false;
    $scope.selectClassStudents = function(cls) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/selectStudents.html'),
        controller: 'selectStudentsCtrl',
        size: 'lg',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          data: function() {
            $scope.status = _.includes($scope.item, cls);
            if($scope.status){
              $scope.selected = [];
              $scope.record.selectedClassStudents[cls.id] = [];
            }else{
              $scope.selected = _.get($scope.record.selectedClassStudents, cls.id, []);
            }
            return {
              schoolClass: cls,
              selectedClassStudents: $scope.selected
            };
          }
        }
      });
      modalInstance.result.then(function(data) {
        $scope.record.selectedClassStudents[data.schoolClass.id] = [...data.selectedClassStudents];
      });
      $scope.item = _.filter($scope.item, item => item.id !== cls.id);
    };

    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.publishForm.$invalid) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '选择的日期不正确，请检查。';
        return;
      }
      if (!$scope.record.startDate) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '请选择开始时间';
        return;
      }
      if (!$scope.record.dueDate) {
        $scope.publishForm.$valid = false;
        $scope.errorMsg = '请选择结束时间';
        return;
      }
      var clsIds = _.map($scope.record.classes, 'id');
      var classStudentIds = $scope.record.selectedClassStudents;
      var startHourtime = _.isEmpty($scope.record.startHourtime) ? 0 : $scope.record.startHourtime.value;
      var startMinutetime = _.isEmpty($scope.record.startMinutetime) ? 0 : $scope.record.startMinutetime.value;
      var dueHourtime =  _.isEmpty($scope.record.dueHourtime) ? 0 : $scope.record.dueHourtime.value;
      var dueMinutetime = _.isEmpty($scope.record.dueMinutetime) ? 0 : $scope.record.dueMinutetime.value;
      var starMillion = startHourtime + startMinutetime;
      var endMillion = dueHourtime + dueMinutetime;
      var startDate = moment($scope.record.startDate).startOf('day').valueOf() + starMillion;
      var dueDate = moment($scope.record.dueDate).startOf('day').valueOf() + endMillion;
      var answerDetailsType = _.toInteger($scope.answerDetailsType);
      // if (!$scope.publishedClassNames) {
        if (clsIds.length === 0) {
          $scope.publishForm.$valid = false;
          $scope.errorMsg = '请选择班级';
          return;
        } else if (startDate >= dueDate) {
          $scope.publishForm.$valid = false;
          $scope.errorMsg = '结束时间不能早于开始时间';
          return;
        }

        $scope.loading = oedTmSession.publishTm({
          tmId: data.tmId,
          classIds: clsIds,
          classStudentIds,
          teacherId: $scope.uid,
          //默认为 xxxx年xx月xx日 23:59:59
          dueDate: dueDate,
          startDate: startDate,
          answerDetailsType: answerDetailsType
        }).$promise.then(function(records) {
          $uibModalInstance.close({
            records: records
          });
        });
        // return;
      // }
      // ============================================================ //
      // var publishRecordsToDetele = _.filter(data.publishRecords, (r) => {
      //   return !_.includes(clsIds, r.classId);
      // });
      // var taskListId = _.get(_.head(_.get(data, 'publishRecords')), 'taskListId', 0);
      //
      // $scope.loading = $q.all(_.map(publishRecordsToDetele, function(pr) {
      //   return oedTaskListPublishRecord.delete({
      //     id: pr.id
      //   }).$promise;
      // })).then(function() {
      //   var records = oedTaskListPublishRecord.createRecordsV2({
      //     taskListId,
      //     publishClasses: _.map(clsIds, clsId => ({
      //       classId: clsId,
      //       studentIds: classStudentIds[clsId]
      //     })),
      //     dueDate: dueDate,
      //     startDate: startDate,
      //   });
      //   return records.$promise.then(function(records) {
      //     $uibModalInstance.close({
      //       records: records
      //     });
      //     notify({
      //       message: '发布成功'
      //     });
      //   });
      // });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.resetStatus = function (selectItem) {
      $scope.item.push(selectItem);
    }
    $scope.onFilterSelected = (newVal)=> {
      const isChoiceAll = newVal.id === '-1';
      const isHasAll = _.some($scope.classes, item => item.id === '-1');
      // 选中"全部班级"
      if(isChoiceAll) {
        $scope.classes = _.filter($scope.classes, item => item.id !== '-1');
        $scope.record.classes = $scope.classes;
        return;
      }
      // 没选中"全部班级"，但人数累加到全部班级
      if(!isChoiceAll && isHasAll && (_.size($scope.record.classes) + 1 === _.size($scope.classes))){
        $scope.classes = _.filter($scope.classes, item => item.id !== '-1');
        return;
      }
      // 没选中"全部班级"，人数没有累加到全部班级
      if(!isChoiceAll && !isHasAll && (_.size($scope.record.classes) !== _.size($scope.classes))){
        $scope.classes.unshift({
          id: '-1',
          levelName: '',
          gradeName: '',
          rxnd: '',
          className: '全部班级',
        });
        return;
      }
    }
  }
})();
