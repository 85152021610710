import { TalSubjectDTO } from 'app2/integration/talcloud/models'
import * as _ from 'lodash'

angular.module('app.prepareCourse2.cloud.core.talcloudLevelSubjectSelector', [
])
  .component('oedTalcloudLevelSubjectSelector', {
    bindings: {
      onClose: '&',
      onSubjectSelected: '&',
      selectedStage: '<',
      selectedSubject: '<',
      stages: '<talStages',
      tal2Subjects: '<talSubjects',
      fromTalcloud2: '<',
    },
    controller: oedTalcloudLevelSubjectSelectorCtrl,
    template:  <string> require('./talcloudLevelSubjectSelector.html'),
  })

oedTalcloudLevelSubjectSelectorCtrl.$inject = ['talcloudResource']
function oedTalcloudLevelSubjectSelectorCtrl(talcloudResource: any) {
  this.$onInit = () => {
    this.origSelectedStage = this.selectedStage
    this.origSelectedSubject = this.selectedSubject
    this.onSelectStage(this.selectedStage)
  }

  this.onSelectStage = (stage: any) => {
    this.selectedStage = stage
    const index = _.findIndex(this.stages, (v: any) => {
      return v.stage === this.selectedStage.stage
    })
    this.curTabIdx = index + 1
    if (this.fromTalcloud2) {
      this.subjects = this.tal2Subjects
    } else {
      this.loading = talcloudResource.querySubjectByStage({
        stage: this.selectedStage.stage,
      }).$promise.then((subjects: TalSubjectDTO[]) => {
        this.subjects = subjects
      })
    }
  }

  this.isSubjectSelected = (s: TalSubjectDTO) => {
    if (!this.selectedSubject || !s) {
      return false
    }
    return this.origSelectedStage.stage === this.selectedStage.stage &&
    this.origSelectedSubject.subjectId === s.subjectId
  }
}
