require('assets/app/controllers/examination/testPaperListCtrl');
require('assets/app/controllers/examination/addTestPaperCtrl');
require('assets/app/controllers/examination/updateTestPaperCtrl');
require('assets/app/controllers/examination/examEdit/editObjectTestPaperCtrl');

angular.module('app.examination', [
  'ui.router', 'ngCookies',
  'app.examination.testPaperList',
  'app.examination.addTestPaper',
  'app.examination.updateTestPaper',
  'app.examination.editObjectTestPaper'])
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('examination', {
      url: '/examination',
      template: require('assets/templates/examination.html'),
      controller: 'examinationCtrl',
      loadingCls: 'blueloading',
      bodyStyle: 'bged2'
    });
  }])
  .controller('examinationCtrl', examinationCtrl)
  .controller('editCourseCtrl', editCourseCtrl);

examinationCtrl.$inject = ['$scope', '$state', '$uibModal', 'localStorageService', '$animate',
  '$q', 'dialogs', 'resize', 'userInfo', 'oedCoursePath', 'oedCourse', 'oedSchoolTerm']
function examinationCtrl(
  $scope, $state, $uibModal, localStorageService, $animate,
  $q, dialogs, resize, userInfo, oedCoursePath, oedCourse, oedSchoolTerm) {
  $scope.swiper = {};
  $scope.info = {}; // 用户信息
  const labelWidth = 197; // 每个标签的宽度 每次要移动的宽度为197px
  $scope.$on('$destroy', () => {
    $scope.isDone = true;
  });
  $scope.strLength = (str) => {
    return _.size(str);
  };
  $scope.getSectionNames = (secs) => {
    return _.map(secs, (sec) => {
      return sec.sectionName;
    }).join(',');
  };
  // 课程列表鼠标Hover事件
  $scope.onSlideHover = ($event, cp) => {
    $scope.hoverCp = cp;
    if (!$scope.showTemplate) {
      return;
    }

    if ($scope.hoverCp.isRc !== undefined) {
      $scope.pageX = $event.pageX;
      $scope.pageY = $event.pageY;
      $scope.popup = 1;
    }
  };
  // 获取上一次路径的记录
  function updateCurrentCoursePath(replace) {
    const curCpId = $state.params.cpId || Number(localStorageService.get('lastCoursePathId'));
    $scope.coursePaths.$promise.then((res) => {
      $scope.loadingCp = false;
      let curCp = null;
      if (!_.isEmpty($scope.coursePaths)) {
        curCp = curCpId ? _.find($scope.coursePaths, (cp) => {
          return cp.id === curCpId;
        }) : $scope.coursePaths[0];
        curCp = curCp || $scope.coursePaths[0];
      }
      $scope.setCurrentCP(curCp, replace);
    });
  }

  userInfo.then((info) => {
    _.assign($scope.info, _.pick(info, ['name', 'uid', 'isSchoolAdmin']));
    // 获取课程列表信息
    function updateCoursePath() {
      $scope.loadingCp = true;
      $scope.coursePaths = oedCoursePath.queryFilter({
        userId: _.parseInt(info.uid),
        onGoing: true,
        unStarted: true, // TODO: 处理归档
        ended: true // TODO: 处理归档
      });

      updateCurrentCoursePath(true);
      return $scope.coursePaths.$promise;
    }

    $scope.loading = $q((resolve, reject) => {
      userInfo.then((user) => {
        $scope.terms = oedSchoolTerm.queryBySchool({
          schoolId: user.schoolId
        });
        $scope.showTemplate = user.canStar;
        $scope.terms.$promise.then((terms) => {
          const curTime = new Date().getTime();
          $scope.currentTerm = _.find(terms, (t) => {
            return t.startTime <= curTime && curTime <= t.endTime;
          });

          return updateCoursePath().then(() => {
            if (!$scope.showTemplate) {
              return;
            }
          });

        }).finally(resolve);
      });
    });
  });
  $scope.setCurrentCP = (cp, replace) => {
    if ($scope.isDone) {
      return;
    }
    const cpId = cp ? cp.id : null;
    const opt = replace ? {
      location: 'replace'
    } : null;
    $scope.labelIndex = _.findIndex($scope.coursePaths, (res) => {
      return res['id'] === cpId;
    });
    setSwiperSideBtnVisible($scope.labelIndex);
    ensureLabelVisibiltiy();
    $state.go('examination.testPaperList', {
      cpId
    }, opt);
  };
  $scope.getLableListStyle = () => {
    let width2 = _.isEmpty($scope.coursePaths) ? '1200' : (_.size($scope.coursePaths) * labelWidth);
    if (width2 < 1200) {
      width2 = 1200;
    }
    return {
      width: '' + width2 + 'px'
    };
  };
  $scope.getLableStyle = () => {
    const labelOffset = '-' + ($scope.labelIndex * labelWidth) + 'px';
    return {
      left: labelOffset
    };
  };
  $scope.labelMaxCount = () => {
    const width2 = $('#gundong2').width();
    return Math.floor(width2 / labelWidth);
  };
  $scope.labelNext = () => {
    const labelCount = $scope.labelMaxCount();
    if ($scope.labelIndex + labelCount >= _.size($scope.coursePaths)) {
      return;
    }
    $scope.labelIndex++;
  };

  function ensureLabelVisibiltiy() {
    if ($scope.labelIndex < 0) {
      $scope.labelIndex = 0;
    }

    const labelCount = $scope.labelMaxCount();
    if ($scope.labelIndex + labelCount >= _.size($scope.coursePaths)) {
      $scope.labelIndex = _.size($scope.coursePaths) - labelCount;
    }
  }

  $scope.labelPrev = () => {
    if ($scope.labelIndex <= 0) {
      return;
    } else {
      $scope.labelIndex--;
    }
  };
  $scope.currentPagerIndex = 0;
  $scope.displaySwiperSideLeft = false;
  $scope.displaySwiperSideRight = true;
  // $scope.noNeedDigest = false;
  $scope.swiperParams = {
    slidesPerView: 'auto',
    mousewheelControl: true,
    onTransitionStart: () => {
      $scope.popup = 0;
      $scope.currentPagerIndex = $scope.swiper.activeIndex;
      setSwiperSideBtnVisible($scope.currentPagerIndex);
      // if (!$scope.noNeedDigest) {
        // $scope.$digest();
      // }
      // $scope.noNeedDigest = false;
    }
  };

  function setSwiperSideBtnVisible(pageIndex) {
    if (isNaN(pageIndex)) {
      return;
    }
    if (isNaN($scope.maxIndex)) {
      $scope.maxIndex = _.size($scope.coursePaths) - $scope.labelMaxCount();
    }
    if (pageIndex >= $scope.maxIndex) {
      $scope.displaySwiperSideRight = false;
    } else {
      $scope.displaySwiperSideRight = true;
    }
    if (pageIndex <= 0) {
      $scope.displaySwiperSideLeft = false;
    } else {
      $scope.displaySwiperSideLeft = true;
    }
  }

  $scope.scrollToTop = () => {
    // $scope.noNeedDigest = true;
    $scope.swiper.slideTo(0);
  };
  $scope.scrollToBottom = () => {
    // $scope.noNeedDigest = true;
    if (isNaN($scope.maxIndex)) {
      $scope.maxIndex = _.size($scope.coursePaths) - $scope.labelMaxCount();
    }
    $scope.swiper.slideTo($scope.maxIndex);
  };
  $scope.cantUpper = false;
  $scope.cantDowner = false;
  $scope.changingCourseOrder = 0;
  $scope.onDisplayOrderChange = (index) => {
    $scope.changingCourseOrder = index;
    if (index <= 0) {
      $scope.cantUpper = true;
    } else {
      $scope.cantUpper = false;
    }
    if ((index + 1) >= _.size($scope.coursePaths)) {
      $scope.cantDowner = true;
    } else {
      $scope.cantDowner = false;
    }
  };
  $scope.orderUpperOnce = (cp, $event, $index) => {
    $event.stopPropagation();
    $event.preventDefault();
    if ($index <= 0) {
      return
    }
    $scope.changingCourseOrder = $index;
    const newOrders = _.map($scope.coursePaths,  (c, index: number) => {
      if (c.id === cp.id) {
        return _.assign(c, {sort: index * 2});
      }
      return _.assign(c, {sort: index * 2 + 3});
    });
    const items = _.map(newOrders,  (c) => {
      return _.pick(c, ['id', 'sort']);
    });
    $scope.loading = oedCoursePath.updateCoursePathOrder(items).$promise.then(() => {
      $scope.coursePaths = _.sortBy(newOrders, 'sort');
      // $scope.noNeedDigest = true;
      $scope.swiper.slidePrev();
      $scope.changingCourseOrder--;
      $scope.onDisplayOrderChange($scope.changingCourseOrder);
    });
  };
  $scope.orderDownOnce = (cp, $event, $index) => {
    $event.stopPropagation();
    $event.preventDefault();
    if (($index + 1) >= _.size($scope.coursePaths)) {
      return
    }
    
    $scope.changingCourseOrder = $index;
    const newOrders = _.map($scope.coursePaths, (c, index: number) => {
      if (c.id === cp.id) {
        return _.assign(c, {sort: index * 2 + 3});
      }
      return _.assign(c, {sort: index * 2});
    });
    const items = _.map(newOrders, (c) => {
      return _.pick(c, ['id', 'sort']);
    });
    $scope.loading = oedCoursePath.updateCoursePathOrder(items).$promise.then(() => {
      $scope.coursePaths = _.sortBy(newOrders, 'sort');
      // $scope.noNeedDigest = true;
      $scope.swiper.slideNext();
      $scope.changingCourseOrder++;
      $scope.onDisplayOrderChange($scope.changingCourseOrder);
    });
  };
  $scope.onChangeOrderToggle = (open) => {
    if (!open) {
      $scope.changingCourseOrder = -1;
    }
  };
}

editCourseCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedSection', 'oedCourse', 'course']
function editCourseCtrl($scope, $q, $uibModalInstance, oedSection, oedCourse, course) {
  $scope.sections = oedSection.queryAll();
  $scope.course = {
    courseName: course.courseName
  };

  const secIds = _.reduce(course.relatedSections, (r, s) => {
    r[s.sectionId] = true;
    return r;
  }, {});

  $scope.sections.$promise.then((secs) => {
    $scope.course.sections = _.filter($scope.sections, (s) => {
      return secIds[s.id];
    });
  });

  $scope.ok = () => {
    const newCourse = $scope.course;
    course.courseName = newCourse.courseName;
    course.relatedSections = _.map($scope.course.sections, (s) => {
      return {
        sectionId: s.id,
      };
    });
    course.$save();
    $uibModalInstance.close({
      course,
    });
  };

  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };
}
