import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { LessonDTO, TeachingModuleDTO, TestOrPresentDTO, UpdateModuleItemDTO } from 'app/lesson/models/lesson.d'
import { ModuleDropEventHandler, ModuleItemDragService } from 'app/lesson/module-item-drag.service'
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { LessonService } from 'app/lesson/services/lesson.service'
import { TeachingPatternService } from 'app/lesson/services/teaching-pattern.service';
import * as _ from 'lodash'
import { TeachingPatternDataService } from 'app/lesson/teaching-pattern-data.service';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-teaching-module',
  template: require('./teaching-module.component.html'),
  styles: [require('./teaching-module.component.scss')]
})
export class TeachingModuleComponent implements OnInit, OnDestroy, ModuleDropEventHandler {
  @Input() public module: TeachingModuleDTO
  @Input() public reses: TestOrPresentDTO[]
  @Input() public isRecommend: boolean
  @Input() public course: LessonDTO
  @ViewChild('moduleContainer') public moduleContainer: ElementRef
  public hidden = false
  public tests: TestOrPresentDTO[]
  constructor(
    private lessonMessageService: LessonMessageService,
    private moduleItemDrag: ModuleItemDragService,
    private teachingPatternService: TeachingPatternService,
    private patternDataService: TeachingPatternDataService,
    private notifyService: NotificationService
    ) {
    }

  public ngOnInit() {
  }

  public ngAfterViewInit() {
    if (!this.isRecommend) {
      this.moduleItemDrag.register(this)
    }
    this.scrollToPosition()
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'module') && _.has(changes, 'reses')) {
        this.initState()
    }
  }

  public updateOrder(tests: TestOrPresentDTO[]) {
    this.lessonMessageService.sendLoadingAction(true)
    this.teachingPatternService.updateModuleItem(tests, this.module.id)
    .subscribe((m: UpdateModuleItemDTO) => {
      this.lessonMessageService.sendReloadPatternAction()
     }, (error: any) => {
      console.error(error)
      this.notifyService.notify('info', '排序失败')
    }, () => this.lessonMessageService.sendLoadingAction(false))
  }

  public ngOnDestroy() {
    this.moduleItemDrag.unregister(this)
  }

  public deleteItem(res: TestOrPresentDTO) {
    this.tests = _.filter(this.tests, (r) => r.id !== res.id)
    this.teachingPatternService.updateModuleItem(this.tests, this.module.id)
    .subscribe((m: UpdateModuleItemDTO) => {
      this.lessonMessageService.sendReloadPatternAction()
      this.module = {
        ...this.teachingPatternService.deleteModuleData(res, this.module)
      }
    }, (error: any) => {
      console.error(error)
      this.notifyService.notify('info', '删除失败')
    })
  }

  public container() {
    return this.moduleContainer.nativeElement
  }

  public accepts(el, target, source, sibling) {
    const ids = (_.chain(target).get('children') as any).map((c: Element) => {
      //
      // 忽略拖动时临时加入的节点
      if (c.classList.contains('gu-transit')) {
        return null
      }
      return c.getAttribute('data-id')
    }).filter().value()
    return !_.includes(ids, el.getAttribute('data-id')) || target.id === source.id || _.includes(ids, 'empty_holder')
  }

  public onDrop(el: any, target: any, source: any, sibling: any): boolean {
    let tests: TestOrPresentDTO[] = []
    if (target === this.container() || source === this.container()) {
      this.hidden = true
      tests = (_.chain(this.container()).get('children', []) as any)
      .map((c: Element, index: number) => {
        const id = _.toNumber(c.getAttribute('data-id'))
        const type = c.getAttribute('data-type')
        const sort = index
        return {
          id,
          type,
          sort
        }
      }).value()
      if (target === this.container()) {
        const elDataId = _.toNumber(el.getAttribute('data-id'))
        const elDataType = el.getAttribute('data-type')
        if (_.findIndex(tests, (t: TestOrPresentDTO) => t.id === elDataId) === -1) {
          tests = [
            ...tests,
            {
              id: elDataId,
              type: elDataType,
              sort: _.size(tests),
            }
          ]
        }
      }
      this.updateOrder(tests)
    }
    if (target !== source && source.getAttribute('data-id') !== 'bottom') {
      return false
    }
    if (target === source && target !== this.container()) {
      return false
    }
    return true
  }

  private initState() {
    const m = this.module
    const resKeyById = _.chain(m).get('presentResources').flatten().keyBy('presentResourceId').value()
    const testKeyById = _.chain(m).get('tests').flatten().keyBy('objectiveTestId').value()
    const allById = {
      ...resKeyById,
      ...testKeyById,
    }
    const courseRes =  [
      ...this.reses
    ]
    this.tests = [
      ..._.chain(courseRes)
    .filter((t) => _.includes([..._.keysIn(allById)], _.toString(t.id)))
    .map((t: TestOrPresentDTO) => {
      return {
        ...t,
        sort: allById[t.id].sort,
        moduleId: m.id,
      }
    }).sortBy('sort').value()]
  }

  private isEmptyTest() {
    return _.isEmpty(this.tests)
  }

  public isNewImportRes(res: TestOrPresentDTO) {
    const isNewImportRes = this.patternDataService.isTestNewImport(this.module.id, res.id)
    if (res.type === 'objective') {
      return {type:'客观练习', isNewRes: isNewImportRes}
    }
    if (res.type === 'subjective') {
      return {type:'主观活动', isNewRes: isNewImportRes}
    }
    if (res.type === 'presentresource') {
      return {type:'展示资源', isNewRes: isNewImportRes}
    }
    return {type:'', isNewRes: false, isNewContain: {}}
  }

  public getHighLight(res: TestOrPresentDTO) {
    return this.patternDataService.getNeedHighLight(this.module.id, res.id)
  }

  private scrollToPosition() {
    const scrollSectionId = "module_item_" + this.patternDataService.getScrollId()
    if (document.getElementById(scrollSectionId) != null) {
      document.getElementById(scrollSectionId).scrollIntoView();
    }
  }

}
