import { Component, OnInit, Input, EventEmitter, Output, ViewChild, OnChanges, SimpleChanges, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';

import * as _ from 'lodash'
import { UiTreeComponent } from 'app/editing/ui-tree/ui-tree.component';
import { SectionSelectDropdownComponent } from '../section-select-dropdown/section-select-dropdown.component';
import { LegacyAppService } from 'app/core/legacy-app.service';
import { RayApiService } from 'app/core/ray-api.service';

const questionTypes = {
  'yesorno': '判断',
  'singlechoice': '单选',
  'multichoice': '多选',
  'connect': '匹配',
  'fill': '填空',
  'vote': '投票',
  'synthetic': '综合',
  'qa': '问答'
}

const subQuestionTypes = _.omit(questionTypes, 'synthetic')

const questionTypeList = Object.keys(questionTypes)
const subQuestionTypeList = _.filter(questionTypeList, (v) => v !== 'synthetic')

@Component({
  selector: 'app-question-designer',
  template: require('./question-designer.component.html'),
  styles: [require('./question-designer.component.scss')]
})
export class QuestionDesignerComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  @Input() cloudRes
  @Input() questionModel
  @Input() testId: number
  @Input() qIndex: number
  @Input() inEditMode: boolean

  @Input() canMoveUp: boolean
  @Input() canMoveDown: boolean
  @Input() isSub: boolean
  @Input() parentIndex: number
  @Input() treeData
  @Input() sectionIdMap
  @Input() showSections: boolean
  @Input() rightWider: boolean
  @Input() isThreeDegrees: boolean
  @Input() editorConfig

  @Input() enterEditing: any
  @Input() leaveEditing: any
  @Output() deleteClicked = new EventEmitter<any>()
  @Output() moveUpClicked = new EventEmitter<any>()
  @Output() moveDownClicked = new EventEmitter<any>()
  @Output() scoreChanged = new EventEmitter<number>()

  @Output() questionChanged = new EventEmitter<any>()

  @ViewChild('containerEl') container: ElementRef

  choices = []
  resDelShowType = false
  inEditing = false

  constructor(private legacyApp: LegacyAppService,
              private rayApi: RayApiService) { }

  ngOnInit() {
    this.updateResDelShowType()
    this.inEditing = this.inEditMode
    if (!this.editorConfig) {
      this.editorConfig = {
        serverUrl: this.rayApi.apiA('ceditor/Test/') + this.testId
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'isThreeDegrees')) {
      this.updateResDelShowType()
    }
  }

  ngAfterViewInit() {
    this.hookClickEvents()
  }

  ngOnDestroy() {
    this.container.nativeElement.removeEventListener('click', this.captureClicked, true);
    document.removeEventListener('click', this.captureDocumentClick, true);
  }

  hookClickEvents() {
    this.container.nativeElement.addEventListener('click', this.captureClicked, true);
    document.addEventListener('click', this.captureDocumentClick, true);
  }

  captureClicked = (evt) => {
    if (!this.inEditing) {
      this.inEditing = true;
      console.info('entering edit mode');
      document.removeEventListener('click', this.captureDocumentClick, true);
      document.addEventListener('click', this.captureDocumentClick, true);
      this.enterEditing(this.questionModel, event)
    }
  }

  //
  // 检查是否离开问题编辑区域
  // 离开编辑区域的意思是:
  // 点击问题区外的任意位置
  captureDocumentClick = (event) => {
    if (!event.target || !this.inEditing) {
      return;
    }

    //
    // 当ueditor对话框打开时会有一个edui-mask的层
    // 当点击这个层的时候应当忽略
    const el = $(event.target);
    if (el.hasClass('edui-mask')) {
      return;
    }
    //
    // 如果点击的是ueditor弹出的对话框中的按钮或提示信息按钮的话
    // 也应当忽略掉点击事件
    const cls = '.ueditor, .edui-dialog, .edui-shadow, .edui-default, .cg-notify-message, .modal-dialog,' +
      '.select-label2, .batch-delete, .delete-parent-question';
    const edDialog = el.closest(cls);
    if (edDialog.length > 0) {
      if (!edDialog.hasClass('modal-edit-test')) {
        return;
      }
    }

    const isChild = $(this.container.nativeElement).find(event.target).length > 0;
    if (isChild) {
      return;
    }

    console.log('leaving edit mode');
    const ret = this.leaveEditing(this.questionModel, event)

    if (ret === false) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      this.inEditing = false;
      document.removeEventListener('click', this.captureDocumentClick, true);
    }
  }

  isSubQuestion() {
    return !_.isUndefined(this.isSub) && this.isSub
  }

  getQuestionNumber() {
    if (this.parentIndex >= 0)
      return (this.parentIndex + 1) + '.' + (this.qIndex + 1)
    else
      return this.qIndex + 1
  }


  getTypes() {
    return this.isSubQuestion() ? subQuestionTypes : questionTypes
  }

  getQuestionTypeList() {
    return this.isSubQuestion() ? subQuestionTypeList : questionTypeList
  }

  getCurrentQuesType() {
    if (this.questionModel.type == 'singlevote' || this.questionModel.type == 'multivote') {
      return 'vote';
    } else {
      return this.questionModel.type;
    }
  }

  setType (type: string) {
    if (type === this.getCurrentQuesType())
      return;

    const applyType = (t) => {
      const localStorageService = this.legacyApp.getLocalStorage()
      localStorageService.set('lastQuestionType', type);
      this.questionModel.answer = null;
      const prevType = this.questionModel.type;
      if (type == 'vote') {
        this.questionModel.type = this.isMultiVote ? 'multivote' : 'singlevote';
        this.questionModel.answer = '-1';
      } else {
        this.questionModel.type = type;
        if (type == 'qa') {
          this.questionModel.answer = '-1';
        }
      }

      this.choices = [];
      if (type != 'fill' && !this.questionModel.point2) {
        this.questionModel.point2 = localStorageService.get('lastQuestionPoint') || 1;
      }
      //
      // don't remove the options if switch between singlechoice/multichoice
      if (_.size(_.without(['singlechoice', 'multichoice'], prevType, type)) !== 0) {
        this.questionModel.choices = [];
        if (type == 'singlechoice' || type == 'multichoice' || type == 'vote') {
          _.times(4, () => {
            this.questionModel.choices.push({
              content: ''
            });
          });
        }
      }

      this.questionChanged.emit(this.questionModel)
    }

    if (!_.isEmpty(this.questionModel.answer)) {
      this.legacyApp.getDialogs().confirm('确认修改问题类型',
                      '修改题型将导致当前设置的答案丢失，是否确认题型修改？')
        .result.then(() => {
          applyType(type)
        })
    } else {
      applyType(type);
    }
  }

  get isMultiVote() {
    if (this.questionModel.type === 'singlevote' || this.questionModel.type === 'multivote') {
      return this.questionModel.type === 'multivote';
    }

    return false
  }

  set isMultiVote(val: boolean) {
    if (val) {
      this.questionModel.type = 'multivote'
    } else {
      this.questionModel.type = 'singlevote'
    }
    this.questionChanged.emit(this.questionModel)
  }

  get questionPoint() {
    return this.questionModel.point2 || 1
  }

  set questionPoint(val: number) {
    this.questionModel.point2 = val
  }

  onScoreChanged(score: number) {
    this.scoreChanged.emit(score)
  }

  onMoveUpClicked(question: any) {
    this.moveUpClicked.emit(this.questionModel)
  }

  onMoveDownClicked(question: any) {
    this.moveDownClicked.emit(this.questionModel)
  }

  onDeleteClicked(question: any) {
    this.deleteClicked.emit(this.questionModel)
  }

  updateResDelShowType() {
    if (this.isThreeDegrees) {
      this.resDelShowType = false
    } else {
      this.resDelShowType = true
    }
  }

  showResAndDelClick() {
    this.resDelShowType = !this.resDelShowType
  }

  addChoice() {
    if (_.size(this.questionModel.choices) >= 26) {
      this.legacyApp.getNotify()({
        message: '选项个数不能超过26个!'
      });
      return
    }
    this.questionModel.choices = [...this.questionModel.choices, {
      content: ''
    }]
  }

  onSelChanged (sections: number[]) {
    this.questionModel.relatedSections = _.map(sections, (node) => {
      const sec = this.sectionIdMap[node]

      return {
        sectionId: sec.id,
        sectionName: sec.name
      }
    })
  }

  showRelatedSections() {
    return _.isUndefined(this.showSections);
  }
}
