(function() {
  'use strict';
  require('assets/styles/preRes.scss');

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.taskListPresentResourceView', {
        url: '/origTaskList/:origTaskListId/publishRecord/:publishRecordId/preResSession/:preResSessionId/' +
        'presentresource/:preResId',
        template: require('assets/templates/homework/taskListPresentResourceView.html'),
        controller: 'taskListPresentResourceViewCtrl',
        bodyStyle: 'bged'
      });
    }])
    .controller('taskListPresentResourceViewCtrl', taskListPresentResourceViewCtrl)
    .directive('taskListPresentResourceView', taskListPresentResourceView);
    function taskListPresentResourceView($document, $timeout) {
      return {
        restrict: 'E',
        scope: {
          origTaskListId: '<',
          publishRecordId: '<',
          preResSessionId: '<',
          preResId: '<',
          isFromNewPrepareReport: '<',
          returnReportList: '&'
        },
        template: require('assets/templates/homework/taskListPresentResourceView.html'),
        controller: taskListPresentResourceViewCtrl
      };
    }

  taskListPresentResourceViewCtrl.$inject = ['$scope', '$state', '$stateParams', '$q',
    '$uibModal', 'userInfo', 'oedTaskList', 'oedPreResSessionStudent', 'oedPreResSession',
    'oedCourse', 'dialogs', 'oedTaskListPublishRecord', 'oedPresentResource']
  function taskListPresentResourceViewCtrl($scope, $state, $stateParams, $q,
    $uibModal, userInfo, oedTaskList, oedPreResSessionStudent, oedPreResSession,
    oedCourse, dialogs, oedTaskListPublishRecord, oedPresentResource) {

    var origTaskListId = $scope.isFromNewPrepareReport ? $scope.origTaskListId : _.parseInt($stateParams.origTaskListId);
    var publishRecordId = $scope.isFromNewPrepareReport ? $scope.publishRecordId : _.parseInt($stateParams.publishRecordId);
    var preResSessionId = $scope.isFromNewPrepareReport ? $scope.preResSessionId : _.parseInt($stateParams.preResSessionId);
    var preResId = $scope.isFromNewPrepareReport ? $scope.preResId : _.parseInt($stateParams.preResId);

    $scope.origTaskListId = origTaskListId;
    $scope.preResSessionId = preResSessionId;
    $scope.publishRecordId = publishRecordId;
    $scope.preResId = preResId;

    $scope.activeTabIndex = 1;
    $scope.tabs = [{index: 0, text: '资源内容'},
      {index: 1, text: '查看情况'}];

    $scope.publishInfo = oedTaskListPublishRecord.queryPublishInfo({
      id: publishRecordId
    });

    $scope.publishRecords = oedTaskList.queryPublishInfo({
      id: $scope.origTaskListId
    });

    $scope.preResource = oedPresentResource.get({
      id: preResId
    });

    $scope.loading = $q.all([$scope.preResource.$promise, $scope.publishInfo.$promise,
      $scope.publishRecords.$promise]).then(function() {
      $scope.publishRecordOfSelectedClass = _.find($scope.publishRecords.publishRecords, function(record) {
        return record.classId === $scope.publishInfo.cls.id;
      });
      $scope.loaded = true;
    });

    $scope.showItemSummary = function(itemIndex, items) {
      $uibModal.open({
        controller: 'preResItemSummaryCtrl',
        resolve: {
          data: () => {
            return {
              classId: $scope.publishInfo.cls.id,
              index: itemIndex,
              items: items,
              preResSessionId: $scope.preResSessionId,
              publishRecordId: $scope.publishRecordId
            };
          },
        },
        size: 'lg',
        template: require('assets/app2/resource/preResItemSummary.html'),
        windowClass: 'oedmodal modalCenter',
      });
    }
  }
})();
