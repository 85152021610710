var inlineUeEditor = require('app2/directives/editor/inlineUeEditor.directive');

(function() {
  'use strict';
  angular.module('app.directives.question.design.qaDesigner', [
    'app.directives.editor.inlineUeEditor'
  ])
    .component('qaDesigner', {
      bindings: {
        questionModel: '=ngModel',
        editorConfig: '<',
        showResAndDelClick: '&',
        resDelShowType: '=',
        isThreeDegrees: '=',
        forbidEdit: '=',
      },
      controller: qaDesigner,
      template: require('assets/app2/directives/question/design/qaDesigner.html')
    });

  qaDesigner.$inject = ['oedTestUtils', 'dialogs']
  function qaDesigner(oedTestUtils, dialogs) {

  }
})();
