import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import _ = require('lodash');

@Component({
  selector: 'app-common-drop-down',
  template: require('./common-drop-down.component.html'),
  styles: [require('./common-drop-down.component.scss')]
})
export class CommonDropDownComponent implements OnInit, OnChanges {
  @Input() private defText: string
  @Input() private itemList: any[]
  @Output() private onDropDownItem = new EventEmitter<any>()

  constructor() { }

  ngOnInit() {
    this.defText = this.replaceLabel(this.defText)
    this.itemList = _.map(this.itemList, item => ({ ...item, name: this.replaceLabel(item.name)}))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'defText')) {
      this.defText = this.replaceLabel(this.defText)
    }
  }

  private replaceLabel = (str: string) => {
    if (str.indexOf('<img') >= 0) {
      str = str.replace(/<img(?:.|\s)*?>/gi, '[图片]')
    }
    if (str.indexOf('<video') >= 0) {
      str = str.replace(/<video(?:.|\s)*?>/gi, '[视频]')
    }
    if (str.indexOf('<audio') >= 0) {
      str = str.replace(/<audio(?:.|\s)*?>/gi, '[音频]')
    }
    return str
  }

  private onItem = (item: any) => this.onDropDownItem.emit(item)
}
