/**
 * @fileOverview
 * @name boardContentViewerCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .directive('boardContentViewer', boardContentViewer);

  boardContentViewer.$inject = ['$q']
  function boardContentViewer($q) {
    return {
      restrict: 'A',
      require: '^ngModel',
      scope: {
        ngModel: '=',
        renderStatus: '='
      },
      template: require('assets/templates/review/boardContentViewer.html'),
      link: function postLink(scope, elm) {

        var heightRatio = 1280 / 800; //保持高度比

        var parentEl = $('.board-content-viewer', elm);
        var canvas = document.createElement('canvas');
        var elCanvas = $(canvas);
        elCanvas.attr('width', '1024');
        elCanvas.attr('height', '640');
        parentEl.append(canvas);
        var ctx = canvas.getContext('2d');

        var widgets = [];

        function calcUrl(url) {
          let idx
          if ((url.startsWith('http://') || url.startsWith('https://')) && (idx = url.indexOf('/ray')) >= 0) {
            return url.substring(idx)
          }

          return url
        }

        function realizeMatrix(matrix, width, height) {
          var ratio = 1280 / width;
          if (!matrix || matrix.length < 9) {
            return;
          }

          matrix[8] = matrix[8] * ratio;
        }

        function getImageScaleRatio(img) {
          var ratio = 1.0;
          if (img.width > 1280) {
            ratio = 1280 / img.width;
          }
          var height = img.height * ratio;
          if (height > 800) {
            ratio = ratio * 800 / height;
          }

          return ratio;
        }

        var images = [];

        function loadImages(board) {
          images = [];
          if (!board) {
            return images;
          }

          images = _.map(board.widgets, function(widget) {
            var img = new Image();
            var matrix = _.clone(widget.matrix);
            realizeMatrix(matrix, canvas.width, canvas.height);
            let imgUrl = widget.url
            if (widget.url) {
              imgUrl = calcUrl(widget.url)
            }
            var data = {
              widget: widget,
              image: img,
              matrix: matrix
            };

            img.onload = function() {
              data.loaded = true;
              renderBoard();
            };

            if (imgUrl) {
              img.src = imgUrl;
            } else if (widget.bitmap) {
              img.src = 'data:image/jpeg;base64,' + widget.bitmap;
            }

            return data;
          });

          if (board.strokeBitmap) {
            var stroke = new Image();
            var data = {
              image: stroke
            };

            stroke.onload = function() {
              data.loaded = true;
              renderBoard();
            };

            stroke.src = 'data:image/jpeg;base64,' + board.strokeBitmap;

            images.push(data);
          }
          return images;
        }

        function renderBoard() {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.fillStyle = '#666666';
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          if (_.isEmpty(images)) {
            return;
          }
          _(images).filter(function(imgData) {
            return imgData.loaded;
          }).each(function(data) {
            ctx.save();

            var matrix = data.matrix;
            var ratio = canvas.width / 1280;
            var imgRatio = getImageScaleRatio(data.image);
            if (matrix) {
              //
              // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/setTransform
              // ctx.setTransform(a, b, c, d, e, f);
              //
              // The transformation matrix is described by:
              //   a c e
              // [ b d f ]
              //   0 0 1
              //
              // 另外注意我们这里的ratio 在平移时需要额外计算进去
              ctx.transform(matrix[0], matrix[3], matrix[1], matrix[4], matrix[2] / matrix[8], matrix[5] / matrix[8]);
              ctx.scale(imgRatio / matrix[8], imgRatio / matrix[8]);
              ctx.drawImage(data.image, 0, 0, data.image.width, data.image.height, 0, 0,
                data.image.width, data.image.height);
            } else {
              //
              // 没有matrix, 一般是strokeBitmap
              // 需要把strokeBitmap缩放成canvas大小
              ratio = canvas.width / data.image.width;
              ctx.drawImage(data.image, 0, 0, data.image.width, data.image.height,
                0, 0, canvas.width, data.image.height * ratio);
            }

            ctx.restore();
          });
        }

        scope.$watch('ngModel', function(newVal, oldVal) {
          reload();
        });

        var recomputeGeometry = function() {
          var width = parentEl.innerWidth();
          var height = parentEl.innerWidth() / heightRatio;
          if (height > parentEl.innerHeight()) {
            height = parentEl.innerHeight();
            width = height * heightRatio;
          }
          //
          // 根据parent div计算宽高
          elCanvas.attr('width', width);
          elCanvas.attr('height', height);
        };

        function reload() {
          recomputeGeometry();
          var imgs = loadImages(scope.ngModel);

          if (scope.renderStatus) {
            scope.renderStatus.loading = $q.all(_.map(imgs, function(img) {
              return $q(function(resolve, reject) {
                $(img.image).on('load', function() {
                  resolve();
                }).on('error', function() {
                  resolve();
                });
              });
            }));
          }

          renderBoard();
        }

        scope.$on('resize', function() {
          recomputeGeometry();
          //
          // resize的时候重新计算图片的matrix
          _.each(images, function(img) {
            if (img.widget) {
              var matrix = _.clone(img.widget.matrix);
              realizeMatrix(matrix, canvas.width, canvas.height);
              img.matrix = matrix;
            }
          });
          renderBoard();
        });

        reload();
      },
    };
  }
})();
