(function() {
  var moment = require('moment');

  angular.module('app.prepareCourse')
    .controller('subjectiveTestPreviewerCtrl', subjectiveTestPreviewerCtrl);

  subjectiveTestPreviewerCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'dialogs', 'notificationService',
    'oedConfig', 'oedSubjectiveTest', 'oedCloudSubjectiveTest', 'cloudTest', 'oedUserInfo',
    'resourceDef', 'templateRes', 'gotoRes']
  function subjectiveTestPreviewerCtrl($scope, $q, $uibModalInstance, dialogs, notificationService,
  oedConfig, oedSubjectiveTest, oedCloudSubjectiveTest, cloudTest, oedUserInfo,
  resourceDef, templateRes, gotoRes) {
    $scope.cloudTest = cloudTest;
    $scope.templateRes = templateRes;

    $scope.canGoto = !!gotoRes

    $scope.gotoRes = function() {
      gotoRes(cloudTest);
    };

    $scope.basePath = oedConfig.base();

    $scope.titleName = cloudTest.name + ' - 预览';

    resourceDef = resourceDef || {};

    $scope.importBtnText = resourceDef.importBtnText || '导入';

    $scope.getTypeLabel = function(type) {
      if (type == 'subjective_blankboard')
        return '白板';
      return '!!!';
    };

    $scope.deserializePosition = function() {
      var tmpPosition = $scope.subjectiveTest.question.resourcePosition;
      if (!_.isEmpty(tmpPosition) && !_.isArray(tmpPosition)) {
        try {
          $scope.subjectiveTest.question.$resourcePosition = JSON.parse(tmpPosition);
          $scope.subjectiveTest.question.positionDetails = false;
        } catch (e) {
          notificationService.notify('error', '加载位置信息失败, 转换为自动布局!')
          $scope.subjectiveTest.question.$resourcePosition = null;
          $scope.subjectiveTest.question.positionDetails = true;
        }
      }
    };

    $scope.resourceDef = resourceDef;

    $scope.subjectiveTest = oedSubjectiveTest.get({
      id: cloudTest.id
    });
    $scope.loading = $scope.subjectiveTest.$promise.then(function() {
      $scope.subjectiveTest.question.positionDetails = true;
      $scope.deserializePosition();
    });

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
