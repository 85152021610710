import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PreLoginContactMenuComponent } from './pre-login-contact-menu/pre-login-contact-menu.component';
import { ProductionDownloadComponent } from './production-download/production-download.component';
import { RandomApkPipe } from './random-apk.pipe';
import { PostLoginContactMenuComponent } from './post-login-contact-menu/post-login-contact-menu.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
  declarations: [PreLoginContactMenuComponent, ProductionDownloadComponent, RandomApkPipe, PostLoginContactMenuComponent],
  entryComponents: [PreLoginContactMenuComponent, ProductionDownloadComponent, PostLoginContactMenuComponent],
  exports: [RandomApkPipe, PostLoginContactMenuComponent],
  imports: [
    CommonModule,
    UIRouterUpgradeModule,
    TooltipModule,
  ]
})
export class MenuModule { }
