/**
 * @fileOverview
 * @name classSessionOverviewCtrl.js
 * @author pangwa
 * @license
 */
const dateUtils = require('app2/utils/dateUtils');
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('classSessionOverview', {
        url: '/review/:classSessionId/course/:courseId/overview',
        template: require('assets/templates/review/classSessionOverview.html'),
        controller: 'classSessionOverviewCtrl',
        bodyStyle: 'bged2'
      });
    }])
    .controller('classSessionOverviewCtrl', classSessionOverviewCtrl);

  classSessionOverviewCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', '$animate', '$q', 'oedTmSession',
    'dialogs', 'resize', 'userInfo', 'oedCoursePath', 'oedClassSession', 'oedTestSession', 'oedBoardSession', 'oedPreResSession',
    'oedTeachingPattern', 'localStorageService', 'oedClassSessionQuestions', 'oedUserInfo', 'Lightbox', '$sce', 'oedObjectiveTest', '$window', 'oedZhongZhiSchool']

  function classSessionOverviewCtrl($scope, $state, $stateParams, $uibModal, $animate, $q, oedTmSession,
    dialogs, resize, userInfo, oedCoursePath, oedClassSession, oedTestSession, oedBoardSession, oedPreResSession,
    oedTeachingPattern, localStorageService, oedClassSessionQuestions, oedUserInfo, Lightbox, $sce, oedObjectiveTest, $window, oedZhongZhiSchool) {

    // 判断顶层窗口是否为iframe(配合桌面端使用)
    $scope.topWindowIsIframe = $window.top === $window.self;
    $scope.linkOpenType = $scope.topWindowIsIframe ? '_blank' : '_self';
    $scope.classSessionId = $stateParams.classSessionId;
    $scope.classSession = oedClassSession.get({
      id: $stateParams.classSessionId
    })

    $scope.stats = oedClassSession.queryStats({
      classSessionId: $stateParams.classSessionId
    });
    $scope.studentQues = oedClassSessionQuestions.queryByClassSession({
      id: $stateParams.classSessionId
    });
    $scope.hasStdAsk = function() {
      return !_.isEmpty($scope.studentQues);
    };
    $scope.currentMode = 'class';
    $scope.selectMode = function(mode) {
      $scope.currentMode = mode;
    };
    $scope.moduleReses = {};

    $scope.getResIcon = function(res) {
      if (res['__type'] == 'objectivetest')
        return 'keguan_icon';
      if (res['__type'] == 'subjectivetest')
        return 'zhuguan_icon';
      if (res['__type'] == 'presentresource')
        return 'zhanshi_icon';

      return '??';
    };

    $scope.sessionsByRes = {
      objectivetest: {},
      subjectivetest: {},
      presentresource: {}
    };
    $scope.excludeSessions = {
      objectivetest: [],
      subjectivetest: []
    }
    $scope.sessionsByTm = {};
    $scope.sessionsInSub = {};

    var boxWidth = 230; //hard code here

    $scope.listTmObjTestSession = [];
    $scope.listTmSbjTestSession = [];
    $scope.listTmPreResSession = [];
    $scope.tmSessionsCount = 0;
    const filterSessions = () => {
      _.each($scope.sessionsByRes.objectivetest, (sessions) => {
        _.remove(sessions, (session) => {
          return _.includes($scope.listTmObjTestSession, session.id);
        })
      })
      _.each($scope.sessionsByRes.subjectivetest, (sessions) => {
        _.remove(sessions, (session) => {
          return _.includes($scope.listTmSbjTestSession, session.testSessionId);
        })
      })
      _.each($scope.sessionsByRes.presentresource, (sessions) => {
        _.remove(sessions, (session) => {
          return _.includes($scope.listTmPreResSession, session.id);
        })
      })
    }

    const coursePromise = oedClassSession.queryCourseDetail({
      id: $stateParams.courseId
    }).$promise

    const teachingPatternPromise = oedTeachingPattern.queryByClassSessionAndCourseId({
      classSessionId: $stateParams.classSessionId,
      courseId: $stateParams.courseId
    }).$promise

    const testTypeMap = {
      'objective': 'objectivetest',
      'subjective': 'subjectivetest',
    }

    var loadAllStuff = $scope.classSession.$promise.then(function(clsSession) {
      return Promise.all([coursePromise, teachingPatternPromise, oedZhongZhiSchool.isZhongZhiSchool()]).then(([course, listTp, isZhongZhiSchool]) => {
        $scope.teachingPatterns = listTp
        $scope.teachingPatternId = _.get(listTp, '0.id')
        $scope.tmList = _.flatten(_.map(listTp, function(tp) { return _.get(tp, 'modules', []); }));

        $scope.classSession.course = course
        $scope.allTestSession = _.get(clsSession, 'testSessions', []);
        $scope.tempTestSessions = []
        const testSessions = _.map($scope.allTestSession, (t) => {
          return oedObjectiveTest.get({id: t.testId}).$promise.then((res) => {
            if (t.teachingPatternId != $scope.teachingPatternId) {
              const t = testTypeMap[_.get(res, 'type')]
              $scope.excludeSessions[t] = _.concat($scope.excludeSessions[t], t)
            }
            $scope.tempTestSessions.push(res)
          })
        })

        var curCpId = localStorageService.get('lastReviewCoursePathId');
        var curClassSessionMap = localStorageService.get('lastReviewClassSessionMap', curCpId);
        if (!curClassSessionMap) {
          curClassSessionMap = {};
        }
        curClassSessionMap[curCpId] = $stateParams.classSessionId;
        localStorageService.set('lastReviewClassSessionMap', curClassSessionMap);
        $scope.timeDiff = dateUtils.sessionDuration($scope.classSession.classSession.startTime,
          $scope.classSession.classSession.endTime, true);

        $scope.teachingPatterns = oedTeachingPattern.queryByClassSessionAndCourseId({
          classSessionId: $stateParams.classSessionId,
          courseId: $stateParams.courseId
        });

        $scope.isZhongZhiSchool = isZhongZhiSchool;
        return $q.all(testSessions)
      });
    }).then(function(pattern) {
      function loadSessions(tests, type, res) {
        return $q.all(_.map(tests, function(test) {
          //
          // 如果已有, 则不重新加载(一个资源可以出现在多个环节中)
          if (!_.has($scope.sessionsByRes[type], test.id)) {
            $scope.sessionsByRes[type][test.id] = res.queryByTestId({
              classSessionId: $stateParams.classSessionId,
              origTestId: test.id
            })
            return $scope.sessionsByRes[type][test.id].$promise.then(function(sessions) {
              const subSesions = _.remove(sessions, function(s) {
                return !_.isNil(s.parentId);
              })
              $scope.sessionsByRes[type][test.id] = _.flatten(sessions);
              _.each(subSesions, (s) => {
                s.testName = test.name;
                s.origTestId = test.id;
                $scope.sessionsInSub[s.parentId] = $scope.sessionsInSub[s.parentId] || [];
                $scope.sessionsInSub[s.parentId] = _.concat($scope.sessionsInSub[s.parentId], s);
              })
              $scope.excludeSessions[type] = _.concat($scope.excludeSessions[type], subSesions)
            });
          } else {
            return Promise.resolve('');
          }
        }));
      }

      const loadTmSessionDetails = (tmSessionId) => {
        return oedTmSession.getDetails({tmSessionId: tmSessionId}).$promise.then((details) => {
          $scope.listTmObjTestSession = _.concat($scope.listTmObjTestSession, _.map(details.listObjTestSession, 'id'));
          $scope.listTmSbjTestSession = _.concat($scope.listTmSbjTestSession, _.map(details.listSbjTestSession, 'id'));
          $scope.listTmPreResSession = _.concat($scope.listTmPreResSession, _.map(details.listPreResSession, 'id'));
          return;
        });
      }

      function loadTmSessions(tm) {
        $scope.sessionsByTm[tm.id] = oedTmSession.queryByTmId({
          classSessionId: $stateParams.classSessionId,
          tmId: tm.id
        })
        return $scope.sessionsByTm[tm.id].$promise.then((tmSessions) => {
          $scope.tmSessionsCount = $scope.tmSessionsCount + _.size(tmSessions);
          return Promise.map(tmSessions, (tmSession) => {
            return loadTmSessionDetails(tmSession.id);
          })
        });
      }

      function loadPreResSessions(pres) {
        return $q.all(_.map(pres, function(pre) {
          const preResSession = oedPreResSession.queryByClassSessionAndPreResId({
            classSessionId: $stateParams.classSessionId,
            preResId: pre.id
          });
          const preResItemSession = oedPreResSession.queryItemByClassSessionAndPreResId({
            classSessionId: $stateParams.classSessionId,
            preResId: pre.id
          });
          //load pre res item sessions
          return $q.all([preResSession.$promise, preResItemSession.$promise]).then(function(sessions) {
            $scope.sessionsByRes['presentresource'][pre.id] = _.flatten(sessions);
          })
        }));
      }

      $scope.getShaftDivStyle = function() {
        var width = _.isEmpty($scope.tmList) ?
          '1200' : (_.size($scope.tmList) * boxWidth);
        if (width < 1200)
          width = 1200;

        return {
          width: '' + width + 'px'
        };
      };

      return $q.all(_.map($scope.tmList, function(m) {
        _.each(m.objectiveTests, function(test) {
          test['__type'] = 'objectivetest';
        });

        _.each(m.subjectiveTests, function(test) {
          test['__type'] = 'subjectivetest';
        });

        _.each(m.presentResources, function(res) {
          res['__type'] = 'presentresource';
        });

        $scope.moduleReses[m.id] = _.sortBy([].concat(m.objectiveTests)
          .concat(m.subjectiveTests)
          .concat(m.presentResources), ['sort'], ['asc']);

        return $q.all([
          loadSessions(m.objectiveTests, 'objectivetest', oedTestSession),
          loadSessions(m.subjectiveTests, 'subjectivetest', oedBoardSession),
          loadPreResSessions(m.presentResources),
          loadTmSessions(m)
        ])
      })).then(() => {
        filterSessions();
        _.each($scope.tmList, (tm) => {
          _.each(tm.objectiveTests, (objTest) => {
            _.each($scope.sessionsByRes[objTest.__type][objTest.id], (session) => {
              const sessionId = session.id;
              if (_.hasIn($scope.sessionsInSub, sessionId)) {
                objTest.origSubSessions = objTest.origSubSessions || [];
                objTest.origSubSessions = _.concat(objTest.origSubSessions, $scope.sessionsInSub[sessionId]);
                objTest.subSessions = _.unionBy(objTest.origSubSessions, 'origTestId');
              }
            });
          });
        });
        $scope.loaded = true
      });
    });

    $scope.tempBoardSessions = oedBoardSession.queryTempSessionsByClassSessionId({
      classSessionId: $stateParams.classSessionId
    });


    loadAllStuff = $q.all([loadAllStuff, $scope.tempBoardSessions.$promise]).then(function() {
      $scope.tempItemSession = _.chain($scope.classSession).get('preResItemSessions', [])
        .filter('isTemp').filter((r) => {
          return _.includes(_.map($scope.teachingPatterns, 'id'), r.teachingPatternId)
        }).value();
      $scope.tempTestSessions = _.filter($scope.tempTestSessions, (r) => {
        const inCourse = _.filter($scope.allTestSession, (s) => s.testId === r.id
                                && _.includes(_.map($scope.teachingPatterns, 'id'), s.teachingPatternId))
        return !_.isEmpty(inCourse) && r.isTemp && r.type === 'objective'
      })

      $scope.tempBoardSessions = _.filter($scope.tempBoardSessions, (r) => {
        const inCourse = _.filter($scope.allTestSession, (s) => s.id === r.testSessionId
          && _.includes(_.map($scope.teachingPatterns, 'id'), s.teachingPatternId))
        return !_.isEmpty(inCourse)
      })

      if (_.isEmpty($scope.tempBoardSessions) && _.isEmpty($scope.tempItemSession) && _.isEmpty($scope.tempTestSessions)) {
        return;
      }
      //
      // 如果临时主观活动不为空, 则创建一个临时教学环节

      $scope.tmList = $scope.tmList || [];

      $scope.tmList.push({
        id: 'tempModule',
        name: '临时推送',
        __isTemp: true
      });

      $scope.moduleReses.tempModule = [{
        id: 'tempTestSessions',
        name: '临时客观推送',
        __isTemp: true,
        __type: 'objectivetest'
      },
      {
        id: 'tempSessions',
        name: '临时主观活动',
        __isTemp: true,
        __type: 'subjectivetest'
      },
      {
        id: 'tempItemSessions',
        name: '临时展示资源',
        __isTemp: true,
        __type: 'presentresource'
      }];
    });

    $scope.loading = loadAllStuff;
    $scope.getTmSessionCount = function(module) {
      return _.size($scope.sessionsByTm[module.id]);
    };
    $scope.getSessionCountBySubSessions = function(res, s) {
      const countBy = _.countBy(res.origSubSessions, 'origTestId');
      return countBy[s.origTestId];
    }
    $scope.getSessionCountByRes = function(res, tmId) {
      //
      // 临时主观活动

      // 筛选出分组临时主观活动的小组sessions
      if (res.__isTemp) {
        if (res.__type === 'subjectivetest') {
          var sessionChild = _.map(_.takeWhile($scope.tempBoardSessions, (s) => {
            return s.groupId && s.groupId > 0;
          }), (w) => w.groupId);
          var sessionChildUniq = _.uniq(sessionChild);
          return _.size($scope.tempBoardSessions) - _.size(sessionChild) + _.size(sessionChildUniq);
        } else if (res.__type === 'objectivetest') {
          return _.size($scope.tempTestSessions)
        } else {
          return _.size($scope.tempItemSession)
        }
      }

      if (!_.has($scope.sessionsByRes, res.__type)) {
        return 0;
      }
      var sessions = $scope.sessionsByRes[res.__type][res.id];
      if (_.size(sessions) <= 0) {
        return 0;
      }
      if (_.get(_.head(sessions), 'teachingModuleId', 0) > 0) {
        // 如果session中出现teachingModuleId，则按新的方式筛选然后计算。
        var arrSelected = _.takeWhile(sessions, sr => _.get(sr, 'teachingModuleId') === tmId);
        return _.size(arrSelected);
      } else if (res.__type === 'subjectivetest') {
        //主观活动存的是board session，没有tmId, 需要通过关联的test session找到对应的tmId
        return _.chain(sessions).filter(s => {
          return _.chain($scope.classSession.testSessions)
            .filter(ts => {
              return ts.id === s.testSessionId && (!ts.teachingModuleId || ts.teachingModuleId === tmId)
            }).size().value() > 0
        }).size().value()
      } else {
        return _.size(sessions);
      }
    };

    $scope.viewIndex = 0;

    $scope.computeMaxCourseDisplayCount = function() {
      var width = $('#shaftArea').outerWidth();
      return Math.floor(width / boxWidth);
    };

    $scope.getShaftStyle = function() {
      var offset = '-' + ($scope.viewIndex * boxWidth) + 'px';
      return {
        left: offset
      };
    };

    $scope.$on('resize', function() {
      $scope.computeMaxCourseDisplayCount();
    });

    $scope.next = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount();
      if ($scope.viewIndex + maxCount >= _.size($scope.tmList))
        return;
      $scope.viewIndex++;
    };

    $scope.prev = function() {
      if ($scope.viewIndex <= 0)
        return;

      $scope.viewIndex--;
    };
    $scope.toTmSessionDetailsOfRes = function(res) {
      $state.go('tmSessions', {
        classSessionId: $stateParams.classSessionId,
        courseId: $stateParams.courseId,
        tmId: res.id
      });
    };

    const openResModal = {
      'objectivetest': openObjPreviewerModal,
      'subjectivetest': openSbjPreviewerModal,
      'presentresource': openPreresPreviewerModal
    }

    function openPreresPreviewerModal(id, name) {
      if (id === 'tempItemSessions') {
        return
      }
      $uibModal.open({
        template: require('assets/app2/review/preresPreviewer.html'),
        controller: 'preresPreviewerCtrl',
        windowClass: 'oed-common-modal',
        size: 'lg',
        resolve: {
          data: function() {
            return {
              preResId: id,
              name: name
            };
          }
        }
      });
    }

    function openSbjPreviewerModal(id, name) {
      $uibModal.open({
        template: require('assets/app2/review/sbjTestPreviewer.html'),
        controller: 'sbjTestPreviewerCtrl',
        windowClass: 'oed-common-modal',
        size: 'lg',
        resolve: {
          data: function() {
            return {
              testId: id,
              name: name
            };
          }
        }
      });
    }

    function openObjPreviewerModal(id, name) {
      $uibModal.open({
        template: require('assets/app2/review/objTestPreviewer.html'),
        controller: 'objTestPreviewerCtrl',
        windowClass: 'oed-common-modal',
        size: 'lg',
        resolve: {
          data: function() {
            return {
              testId: id
            };
          }
        }
      });
    }

    $scope.toObjSessionDetailsByResSubSession = function(res, session, tmId) {
      $state.go('objectiveTestSessions.subSession', {
        classSessionId: $stateParams.classSessionId,
        courseId: $stateParams.courseId,
        testId: res.id,
        tmId: tmId,
        subSessionId: session.id
      });
    }

    $scope.toSessionDetailsOfRes = function(res, tmId) {
      if (!$scope.getSessionCountByRes(res, tmId)) {
        openResModal[res.__type](res.id, res.name);
        return;
      }

      if (res.__type == 'objectivetest') {
        $state.go('objectiveTestSessions', {
          classSessionId: $stateParams.classSessionId,
          courseId: $stateParams.courseId,
          tmId: tmId,
          testId: res.id
        });
      } else if (res.__type == 'subjectivetest') {
        $state.go('subjectiveTestSessions', {
          classSessionId: $stateParams.classSessionId,
          courseId: $stateParams.courseId,
          tmId: tmId,
          testId: res.id
        });
      } else if (res.__type == 'presentresource' && !res.__isTemp) {
        $state.go('preResSessions', {
          classSessionId: $stateParams.classSessionId,
          courseId: $stateParams.courseId,
          preResId: res.id
        });
      } else if (res.__type == 'presentresource' && res.__isTemp) {
        $state.go('tempItemSessions', {
          classSessionId: $stateParams.classSessionId,
          courseId: $stateParams.courseId,
        });
      }
    };
    $scope.stdAskFlag = false;
    $scope.switchFlag = function() {
      $scope.stdAskFlag = !$scope.stdAskFlag;
    };
    $scope.showPreview = function(reses, idx, event) {
      event.stopPropagation();
      event.preventDefault();
      const resList = _.map(reses, (q) => ({
        id: q.resourceId,
        resourceName: q.resourceId + '.jpg',
        fileType: 'image',
      }));
      Lightbox.openModal(resList, idx);
    };
  }
})();
