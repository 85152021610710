import _ = require('lodash')

const md5: any = require('md5')

const appid = '972493'
const appkey = '4d816fa9f0c6ddd6'
const regex = /wuli|huaxue/

const subjectMap: any = {
  wuli: 'phy',
}

export function buildRedirectUrl(webUrl: string, uid: any) {
  let subjectDisplayName = 'phy'
  const result = webUrl.match(regex)
  if (result) {
    subjectDisplayName = result[0]
  }
  const timestamp = Math.round(new Date().getTime() / 1000)
  const subject = subjectMap[subjectDisplayName]
  const url = 'https://res-api.nobook.com/api/login/autologin?'
  const signKey = `${appid}${appkey}${subject}${timestamp}${uid}`
  const sign = md5(signKey)
  return `${url}appid=${appid}&uid=${uid}` +
  `&subject=${subject}&timestamp=${timestamp}` +
  `&sign=${sign}&redirect=${webUrl}`
}
