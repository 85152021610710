import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash'
import {CoursePathDTO, NodeSectionDTO, SectionDTO, LessonDTO, IdAndName, TransformResult, MergedTransformResult} from '../models/lesson'
import {LessonService} from '../services/lesson.service'
import { LegacyAppService } from 'app/core/legacy-app.service';


/**
 * 这里是章节列表
 */
@Component({
  selector: 'app-section-list',
  template: require('./section-list.component.html'),
  styles: [require('./section-list.component.scss')]
})
export class SectionListComponent implements OnInit {
  @Input() public coursePathId: number;

  private sectionsData: MergedTransformResult[]
  private coursePath: CoursePathDTO
  private loading = false;
  private dateArr = [];

  constructor(
    private lessonService: LessonService,
    private legacyApp: LegacyAppService,
  ) { }

  public ngOnInit() {
    this.getAllCourseList()
  }

  private getDateListWithSectionId(res) {
    if (res) {
      _.forEach(res, (r1) => {
        _.forEach(r1.relatedSections, (r2) => {
          const obj = {
            sectionId: 0,
            createDate: 0
          };
          obj.sectionId = r2.sectionId;
          obj.createDate = r1.createDate;
          this.dateArr.push(obj)
        })
      })
    }
  }

  private getSectionDate(id) {
    const arr = _.sortBy(_.groupBy(this.dateArr, 'sectionId')[id], 'createDate')
    if (arr.length > 0) {
      return arr[arr.length - 1]['createDate']
    }
    return null
  }

  private toTree(list: SectionDTO[] , parentId = null, tree: NodeSectionDTO[] = []) {
    _.forEach(list, (s) => {
      if (s.parentId === parentId) {
        const treeTemp = this.toTree(list, s.id);
        tree.push({section: s, child: treeTemp});
      }
    });
    return tree;
  }

  private getAllCourseList() {
    this.loading = true;
    return this.lessonService
      .getCoursePath(this.coursePathId)
      .toPromise()
      .then((coursePath: CoursePathDTO) => {
        this.coursePath = coursePath;
        if (this.coursePath) {
          return this.lessonService.getQueryByVersionSubjectIdAndGrade(
              this.coursePath.versionId,
              this.coursePath.subjectId,
              this.coursePath.gradeId
            )
            .toPromise()
            .then((section: SectionDTO[]) => {
              return this.lessonService.getCourseByCoursePathId(
                this.coursePath.id
              )
              .toPromise()
              .then((res: LessonDTO[]) => {
                this.getDateListWithSectionId(res);
                _.map(section, (r) => {
                  r.createDate = this.getSectionDate(r.id)
                });
                this.displayData(section);
                this.loading = false;
                this.scrollToPosition()
              })
            })
        }
      })
      .catch(() => {
        this.loading = false;
      })
  }

  private transformSections(sections: NodeSectionDTO[]): TransformResult[]{
    const parents = [];
    let section = [];
    for (const c of sections) {
      section = this.transformSection(c, parents, section)
    }
    return section
  }

  private transformSection(section: NodeSectionDTO, parents: IdAndName[], results: TransformResult[]): TransformResult[] {
    if (_.isEmpty(section.child)) {
      return [
        ...results, {
          section: section.section,
          parents: parents,
        }
      ]
    }
    const curParents = [...parents, {
      id: section.section.id,
      depthNameAll: section.depthNameAll
    }];

    let newResult = results;
    for (const child of section.child) {
      newResult = this.transformSection (child, curParents, newResult)
    }
    return newResult
  }

  private mergeResult(results: TransformResult[]): MergedTransformResult[] {
    let merged = [];
    for (const r of results) {
      const last: MergedTransformResult = _.last(merged);
      if (!last) {
        merged = [{
          parents: r.parents,
          sections: [r.section],
        }]
      } else {
        const lastMergedParent = _.last(last.parents);
        const parent = _.last(r.parents);
        if (lastMergedParent != null && parent && parent.id === lastMergedParent.id) {
          last.sections = [...last.sections, r.section]
        } else {
          merged = [...merged, {
            parents: r.parents,
            sections: [r.section],
          }]
        }
      }
    }
    return merged
  }


  // 给树中每个节点编号
  private treeAddNumber(section: NodeSectionDTO[], pName = '', pNameAll = '') {
      for (let i = 0; i < section.length; i++) {
        const s = section[i];
        // s.depthName = _.isEmpty(pName) ? `${i + 1}` : `${pName}.${i + 1}`;
        // s.depthNameAll = _.isEmpty(pNameAll) ? `${s.section.name}` : `${pNameAll} / ${s.depthName}${s.section.name}`;
        s.depthName = _.isEmpty(pName) ? `` : `${pName}`;
        s.depthNameAll = _.isEmpty(pNameAll) ? `${s.section.name}` : `${pNameAll} / ${s.depthName}${s.section.name}`;
        if (!_.isEmpty(s.child)) {
          this.treeAddNumber(s.child, s.depthName, s.depthNameAll)
        }
      }
  }

  private displayData(sections: SectionDTO[]) {
    const section: NodeSectionDTO[] = this.toTree(sections);
    this.treeAddNumber(section);
    const transResult = this.transformSections(section);
    const mergedResult = this.mergeResult(transResult);
    this.sectionsData = mergedResult;
  }

  private scrollToPosition() {
    const scrollSectionId = this.legacyApp.getLocalStorage().get('scrollToSectionId')
    setTimeout(() => {
      if (document.getElementById(scrollSectionId) != null) {
        document.getElementById(scrollSectionId).scrollIntoView();
      }
    }, 5)
  }
}
