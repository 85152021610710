(function() {
  require('assets/styles/course.scss')
  const Promise = require('bluebird')
  const _ = require('lodash')

  require('app2/prepareCourse/cloud/teachingDesign/teachingDesignLibrary.directive')

  angular.module('app.prepareCourse.teachingDesign', [
    'app.prepareCourse2.cloud.teachingDesign.teachingDesignLibrary'
  ]).config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareCourse.teachingDesign', {
        url: '/teachingDesign',
        template: require('assets/templates/prepareCourse/teachingDesign.html'),
        controller: 'teachingDesignCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'body-teachingdesign'
      });
      $stateProvider.state('prepareCourse.teachingDesignTable', {
        url: '/teachingDesignTable',
        template: require('assets/templates/prepareCourse/teachingDesignTable.html'),
        controller: 'teachingDesignCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'body-teachingdesign'
      });
    }])
    .controller('teachingDesignCtrl', teachingDesignCtrl)
    .controller('addTeachingModuleCtrl', addTeachingModuleCtrl)
    .controller('editTeachingModuleCtrl', editTeachingModuleCtrl)
    .controller('editTeachingPatternCtrl', editTeachingPatternCtrl)
    .controller('templatePreviewerCtrl', templatePreviewerCtrl)
    .controller('confirmMsgDialogCtrl', confirmMsgDialogCtrl)
    .controller('clearTpDialogCtrl', clearTpDialogCtrl);

  teachingDesignCtrl.$inject = [
    '$rootScope', '$scope', '$q', '$state', '$stateParams',
    '$uibModal', 'Upload', '$http', 'dialogs', 'resize', 'notify', 'oedUserClass',
    'oedConfig', 'oedCourse', 'oedTeachingPattern', 'oedTeachingModule', 'oedCloudTest', 'oedCloudSubjectiveTest',
    'oedPresentResource', 'oedOfflineResource', 'oedObjectiveTest', 'userInfo', 'oedCloudPresentRes',
    'oedSubjectiveTest', 'oedCloudTeachingPattern', 'oedCoursePath', 'oedCourseUtils', 'oedCloudPresentResItem', 'oedCloudQuestion',
    'oedTest', 'oedTaskListPublishRecord', 'oedSchool', 'notificationService', 'oedTestUtils', 'oedTeachingPlan']
  function teachingDesignCtrl(
    $rootScope, $scope, $q, $state, $stateParams,
    $uibModal, Upload, $http, dialogs, resize, notify, oedUserClass,
    oedConfig, oedCourse, oedTeachingPattern, oedTeachingModule, oedCloudTest, oedCloudSubjectiveTest,
    oedPresentResource, oedOfflineResource, oedObjectiveTest, userInfo, oedCloudPresentRes,
    oedSubjectiveTest, oedCloudTeachingPattern, oedCoursePath, oedCourseUtils, oedCloudPresentResItem, oedCloudQuestion,
    oedTest, oedTaskListPublishRecord, oedSchool, notificationService, oedTestUtils, oedTeachingPlan) {
    $scope.currentState.title = '课堂教学设计';

    var courseId = $stateParams.courseId;
    $scope.shareResource = false;
    $scope.course = oedCourse.get({
      id: $stateParams.courseId
    });
    $scope.course.$promise.then(c => {
      $scope.shareResource = c.shareResource;
    });
    $scope.cloudTeachingPatterns = [];

    function loadData() {
      $scope.userInfo = userInfo;
      $scope.teachingPattern = oedTeachingPattern.getByCourse2({
        courseId: courseId
      });

      $scope.templates = oedTeachingPattern.findAllTemplate({id: courseId, type: 1});

      return $q.all([$scope.userInfo.$promise, $scope.teachingPattern.$promise,
        $scope.templates.$promise, $scope.course.$promise])
        .then(() => {
          oedSchool.get({id: _.parseInt(userInfo.userInfo.schoolId)}).$promise.then((data) => {
            $scope.schoolShare = data.shareResource
          })

          $scope.courseSectionName = $scope.course.relatedSections[0].sectionName

          const haveTemplate = $scope.templates.sectionTemplates.length > 0;
          const everUsedTemplate = $scope.course.importedTemplate;
          const haveTm = $scope.teachingPattern.modules.length > 0;
          const oldCourse = haveTm || everUsedTemplate;

          $scope.haveTemplate = haveTemplate;

          if (haveTemplate) {
            $scope.templateRes = $scope.templates.sectionTemplates[0];

            initTemplatesTps()
            const sectionNames = _.map($scope.course.relatedSections, (s) => s.sectionName)
            $scope.templateTabs = _.map($scope.templates.sectionTemplates, (template) => {
              return getTemplateTabName(template.name, sectionNames)
            })
            $scope.selectedTemplate = $scope.templates.sectionTemplates[0]
          } else {
            $scope.templateRes = null;
          }

          //无模板，则加载tm后返回
          if (!haveTemplate) {
            teachingPatternLoad($scope.teachingPattern);
            return;
          }

          if (!oldCourse) {
            //新课自动导入模板
            return oedTeachingPattern.importTemplateById({teachingPatternId: $scope.templates.sectionTemplates[0].id,
              templateCourseId: $scope.templates.sectionTemplates[0].courseId,
              curCourseId: courseId
            }).$promise.then(function() {
              reloadAll();

              //自动导入后，标记为导入过
              if (!everUsedTemplate) {
                oedCoursePath.updateImportedTemplate({id: $scope.course.id, importedTemplate: true}).$promise.then(
                  function() {
                    $scope.useTemplate = 1;
                  });
              }
            });
          } else {
            teachingPatternLoad($scope.teachingPattern);
          }
        });
    }

    $scope.loading = loadData();

    $scope.refreshCloudResourceShare = function() {
      var share = $scope.shareResource === 1;
      var objs = _.map($scope.objectiveTests, obj => {
        return oedObjectiveTest.get({id: obj.id}).$promise.then((data) => {
          return Promise.mapSeries(data.questions, (question) => {
            return oedCloudQuestion.getByQuestionId({id: question.id}).$promise.then((cloudQuestion) => {
              if (!share && _.has(cloudQuestion, 'id')) {
                return cloudQuestion.$delete();
              }
              if (share && !_.has(cloudQuestion, 'id')) {
                return new oedCloudQuestion({
                  resId: question.id
                }).$save();
              }
            })
          })
        }).then(() => {
          oedCloudTest.queryByObjectiveTestId({
            id: obj.id
          }).$promise.then(function(r) {
            if (!share && _.has(r, 'id'))
              return r.$delete();
            if (share && !_.has(r, 'id'))
              return new oedCloudTest({
                testId: obj.id
              }).$save();
          });
        })
      });

      var subjs = _.map($scope.subjectiveTests, sub => {
        return oedCloudSubjectiveTest.queryBySubjectiveTestId({
          id: sub.id
        }).$promise.then(r => {
            if (!share && _.has(r, 'id'))
              return r.$delete();
            if (share && !_.has(r, 'id'))
              return new oedCloudSubjectiveTest({
                testId: sub.id
              }).$save();
          });
      });
      var ress = _.map($scope.presentResources, res => {
        return oedPresentResource.get({
          id: res.id
        }).$promise.then((data) => {
          return Promise.mapSeries(data.items, (item) => {
            return oedCloudPresentResItem.getByResItemId({id: item.id}).$promise.then((cloudItem) => {
              if (!share && _.has(cloudItem, 'id')) {
                return cloudItem.$delete();
              }
              if (share && !_.has(cloudItem, 'id')) {
                return new oedCloudPresentResItem({
                  resId: item.id
                }).$save();
              }
            })
          })
        }).then(() => {
          return oedCloudPresentRes.queryByPresentResId({
            id: res.id
          }).$promise.then(r => {
            if (!share && _.has(r, 'id'))
              return r.$delete();
            if (share && !_.has(r, 'id'))
              return new oedCloudPresentRes({
                resourceId: res.id
              }).$save();
          });
        })
      });
      var tm = oedCloudTeachingPattern.getByResId({
        id: $scope.teachingPattern.id
      }).$promise.then(r => {
        if (!share && _.has(r, 'id'))
          return r.$delete();
        if (share && !_.has(r, 'id'))
          return new oedCloudTeachingPattern({
            teachingPatternId: $scope.teachingPattern.id
          }).$save();
      });
      const allJob = _.chain(objs).concat(subjs).concat(ress).concat([tm]).value();
      return $q.all(allJob).then(function() {
        $scope.reloadCloudRes();
      });
    };
    $scope.toggleShareResource = function() {
      $scope.course.shareResource = Math.abs($scope.course.shareResource - 1);
      if ($scope.shareResource) {
        $scope.loading = oedTeachingPlan.cancelShareResource({courseId: $scope.course.id}).$promise.then(c => {
          $scope.shareResource = 0;
        });
      } else {
        $scope.loading = oedTeachingPlan.shareResource({courseId: $scope.course.id}, {courseId: $scope.course.id}).$promise.then(c => {
          $scope.shareResource = 1;
        });
      }
      // $scope.loading = $scope.course.$save().then(c => {
      //   $scope.shareResource = c.shareResource;
      //   return $scope.refreshCloudResourceShare();
      // });
    };
    var beforeModules = [];
    $scope.sortableOptions = {
      start: function(event) {
        beforeModules = _.map($scope.teachingPattern.modules, 'id');
      },
      stop: function(event) {
        //event.preventDefault();
        //event.stopPropagation();
        var newModules = _.map($scope.teachingPattern.modules, 'id');
        if (_.isEqual(beforeModules, newModules)) {
          return;
        }
        var modules = _.map($scope.teachingPattern.modules, function(m) {
          return {
            id: m.id
          };
        });

        var res = new oedTeachingPattern({
          id: $scope.teachingPattern.id,
          modules: modules
        });

        $scope.loading = res.$updateMoudleOrder();
      }
    };

    $scope.tmDropableOptions = {
      accept: function(el) {
        if (!el.hasClass('res-item')) {
          return false;
        }
        var idx = el.attr('data-cur-index');
        var item = $scope.avaliableReses[idx];
        var moduleIdx = $(this).attr('data-module-index');
        var curModule = $scope.teachingPattern.modules[moduleIdx];

        var resources = ['objectiveTests', 'subjectiveTests', 'presentResources'];
        //
        // 现在所有的resource的id唯一, 所以简单的比较一下就可以了
        var resIdx = _.findIndex(resources, function(res) {
          return _.findIndex(curModule[res], function(resItem) {
            return resItem.id == item.id;
          }) >= 0;
        });

        return resIdx < 0;
      }
    };

    $scope.getResIcon = function(res) {
      var t = getType(res);
      switch (t) {
        case 'oedObjectiveTest':
          return 'keguan_icon';
        case 'oedSubjectiveTest':
          return 'zhuguan_icon';
        case 'oedPresentResource':
          return 'zhanshi_icon';
        default:
          return '??';
      }
    };

    $scope.getCloudResIcon = function(res) {
      if (res['__type'] == 'oedObjectiveTest')
        return 'keguan_icon';
      if (res['__type'] == 'oedSubjectiveTest')
        return 'zhuguan_icon';
      if (res['__type'] == 'oedPresentResource')
        return 'zhanshi_icon';

      return '??';
    };

    $scope.edit = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/editTeachingPattern.html'),
        controller: 'editTeachingPatternCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          pattern: function() {
            return $scope.teachingPattern;
          }
        }
      });

      modalInstance.result.then(function(r) {});
    };

    function getType(res) {
      if (_.has(res, '$type')) {
        return res.$type;
      }

      if (res.constructor === oedObjectiveTest) {
        return 'oedObjectiveTest';
      }

      if (res.constructor === oedSubjectiveTest) {
        return 'oedSubjectiveTest';
      }

      if (res.constructor === oedPresentResource) {
        return 'oedPresentResource';
      }

      return '??';
    }

    function updateModuleInternalState(m) {
      m.$allReses = _.map(_.filter(m.objectiveTests, test => {
        return !test.origId
      }), function(t) {
        return _.assign({}, t, {
          sort: t.sort,
          $type: 'oedObjectiveTest'
        });
      });
      m.$allReses = m.$allReses.concat(_.map(_.filter(m.subjectiveTests, test => {
        return !test.origId
      }), function(t) {
        return _.assign({}, t, {
          sort: t.sort,
          $type: 'oedSubjectiveTest'
        });
      }));
      m.$allReses = m.$allReses.concat(_.map(_.filter(m.presentResources, test => {
        return !test.origId
      }), function(p) {
        return _.assign({}, p, {
          sort: p.sort,
          $type: 'oedPresentResource'
        });
      }));

      m.$allReses.sort(function(a, b) {
        return a.sort - b.sort;
      });

      return m;
    }

    var updateScopeVariables = function() {
      _.each($scope.presentResources, pr => pr.type = 'presentresource');
      $scope.allResources = $scope.objectiveTests.concat($scope.subjectiveTests)
        .concat($scope.presentResources)

      _.each($scope.teachingPattern.modules, updateModuleInternalState);
      $scope.teachingPattern.modules = $scope.teachingPattern.modules || [];

      $scope.unusedReses = $scope.allResources;

      $scope.updateReses();
    };

    function reloadTeachingPattern() {
      return oedTeachingPattern.getByCourse2({
        courseId: courseId
      }).$promise.then(function(tp) {
        $scope.teachingPattern = tp;
        return teachingPatternLoad(tp)
      });
    }

    $scope.onDropModule = function() {
      //
      // 正常情况下最后一个 module就是要导入的模块
      // 不过为了安全起见, 找出所有patternId不是当前教学设计的模块
      var importedModules = _.filter($scope.teachingPattern.modules, function(m) {
        return m.teachingPatternId != $scope.teachingPattern.id;
      });
      if (_.isEmpty(importedModules)) {
        console.log('no imported module...');
        return;
      }

      var curImportIdx = 0;

      function doImportModule() {
        if (curImportIdx >= _.size(importedModules)) {
          return 0;
        }

        var m = importedModules[curImportIdx];
        return $scope.teachingPattern.$importTeachingModule({
          id: $scope.teachingPattern.id,
          moduleId: m.id
        }).then(function() {
          curImportIdx++;
          return doImportModule();
        });
      }

      $scope.loading = doImportModule().then(reloadTeachingPattern);
    };

    function updateTeachingModuleResources(moduleIdx) {
      var tm = $scope.teachingPattern.modules[moduleIdx];
      updateModuleItemsOrder(tm);
      $scope.teachingPattern.modules[moduleIdx] = oedTeachingModule.updateItemOrder(tm);
      $scope.loading = $scope.teachingPattern.modules[moduleIdx].$promise.then(m => {
        updateModuleInternalState(m);
      });
    }

    function updateModuleItemsOrder(tm) {
      //
      // update each modules' resource list
      var resByTypes = _.groupBy(tm.$allReses, getType);
      tm.objectiveTests = _.get(resByTypes, 'oedObjectiveTest', []);
      tm.subjectiveTests = _.get(resByTypes, 'oedSubjectiveTest', []);
      tm.presentResources = _.get(resByTypes, 'oedPresentResource', []);

      var itemWithoutOrder = _.findIndex(tm.$allReses, function(res) {
        return _.isNull(res.sort) || _.isUndefined(res.sort);
      });

      var maxSort = -1;
      if (itemWithoutOrder >= 0) {
        var maxItem = _.maxBy(tm.$allReses, function(res) {
          return res.sort || 0;
        });
        maxSort = _.get(maxItem, 'sort', 0) + 1;
        tm.$allReses[itemWithoutOrder].sort = maxSort;
      }
    }

    var updateTeachingPatternResources = function() {
      //
      // update teaching pattern model
      _.each($scope.teachingPattern.modules, updateModuleItemsOrder);

      $scope.loading = $scope.teachingPattern.$save(updateScopeVariables);
    };

    $scope.onDropItemToModule = function($event) {
      var el = $($event.target);
      var idx = _.parseInt(el.attr('data-module-index'));
      var module = $scope.teachingPattern.modules[idx];
      if (!module) {
        console.log('invalid module...');
        return;
      }
      updateTeachingModuleResources(idx);
    };

    function loadTeachingPattern() {
      $scope.teachingPattern = oedTeachingPattern.getByCourse2({
        courseId: courseId
      });

      return $scope.teachingPattern.$promise.then((tp) => {
        const testIds = _(tp.modules).map('tests').flatten().map('objectiveTestId').uniq().value()
        const resIds  = _(tp.modules).map('presentResources').flatten().map('presentResourceId').uniq().value()
        const tests = _.isEmpty(testIds) ? [] : oedTest.getByIds({
          ids: testIds
        }).$promise
        const reses = _.isEmpty(resIds) ? [] : oedPresentResource.getByIds({
          ids: resIds
        }).$promise

        return Promise.all([tests, reses]).spread((tests, reses) => {
          const testById = _.keyBy(tests, 'id')
          const resById = _.keyBy(reses, 'id')

          _.forEach(tp.modules, m => {
            const moduleTests = _(m.tests).map(t => _.assign(
              {}, testById[t.objectiveTestId], {
              sort: t.sort,
            })).partition(t => t.type === 'objective').value()

            const moduleReses = _(m.presentResources).map(r => _.assign(
              {}, resById[r.presentResourceId], {
                sort: r.sort,
              })).value()
            m.objectiveTests = moduleTests[0]
            m.subjectiveTests = moduleTests[1]
            m.presentResources = moduleReses
          })
        })
      })
    }

    var reload = function() {
      $scope.loading = loadTeachingPattern().then(updateScopeVariables)
    };

    var boxWidth = 230; //hard code here

    $scope.getShaftDivStyle = function() {
      var width = _.isEmpty($scope.teachingPattern.modules) ?
        '1200' : (_.size($scope.teachingPattern.modules) * boxWidth);
      if (width < 1200)
        width = 1200;
      var offset = '-' + ($scope.viewIndex * boxWidth) + 'px';
      return {
        width: '' + width + 'px',
        left: offset
      };
    };

    $scope.getCloudShaftDivStyle = function(tp, idx) {
      var width = _.isEmpty(tp.modules) ?
        '1200' : (_.size(tp.modules) * boxWidth);
      if (width < 1200)
        width = 1200;
      var offset = '-' + ($scope.cloudTpViewStatus[idx] * boxWidth) + 'px';
      return {
        width: '' + width + 'px',
        left: offset
      };
    };

    /*
    $scope.getShaftStyle = function() {
      var offset = '-' + ($scope.viewIndex * boxWidth) + 'px';
      return {
        left: offset
      };
    };
    */
    $scope.getCloudShaftStyle = function(patternIdx, idx) {
      var offset = '-' + ($scope.cloudTpViewStatus[patternIdx].viewIndex * boxWidth) + 'px';
      return {
        left: offset
      };
    };

    $scope.onDropDelete = function(evt, item) {
      var el = $(item.draggable[0]);
      var moduleId = _.parseInt(el.attr('data-module-id'));
      var resIdx = _.parseInt(el.attr('data-item-index'));
      var moduleIdx = _.findIndex($scope.teachingPattern.modules, function(m) {
        return m.id === moduleId;
      });
      if (moduleIdx >= 0) {
        var module = $scope.teachingPattern.modules[moduleIdx];
        $scope.removeResFromModule(module, resIdx);
      }
    };

    $scope.createTeachingModule = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/addTeachingModule.html'),
        controller: 'addTeachingModuleCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          patternId: function() {
            return $scope.teachingPattern.id;
          },
          isHomework:false
        }
      });

      modalInstance.result.then(function(tm) {
        updateModuleInternalState(tm);
        $scope.teachingPattern.modules = $scope.teachingPattern.modules || [];
        $scope.teachingPattern.modules.push(tm);
        if (_.size($scope.teachingPattern.modules) > $scope.computeMaxCourseDisplayCount()) {
          $scope.viewIndex = _.size($scope.teachingPattern.modules) - $scope.computeMaxCourseDisplayCount();
        }
      });
    };

    $scope.deleteModule = function(module) {
      var idx = _.findIndex($scope.teachingPattern.modules, module);
      if (idx < 0) {
        console.log('invalid module');
        return;
      }
      var dlg = dialogs.confirm('确定删除?', '确定要删除教学环节: "' + module.name + '"吗');
      dlg.result.then(function(btn) {
        $scope.loading = oedTeachingModule.delete({
          id: module.id
        }, function() {
          $scope.teachingPattern.modules.splice(idx, 1);
        }).$promise;
      });
    };

    $scope.publishModule = function(module) {
      if (module.$allReses.length == 0) {
        return notificationService.notify('info', '空环节不支持转作业');
      }
      // check if all classes are published
      $scope.selectStudent = {};
      var tmTaskListKeys = _.keys($scope.mapTmTaskList[module.id]);
      var allRecords = _.get($scope.mapTmTaskList[module.id], _.max(tmTaskListKeys), []);
      // var taskListId = _.get(_.head(allRecords), 'taskListId', 0);
      // var publishedClasses = _.map(allRecords, 'classId');
      var publishedClasses = [];
      $scope.loading = oedTeachingModule.getPublishedRecords({
            id: module.id
          }).$promise.then(records => {
            if (_.size(records)) {
              return Promise.map(records, item => {
                publishedClasses.push(item.classId);
                // return oedTaskListPublishRecord.queryPublishInfo({id: item.id}).$promise.then(
                //   res => {
                //     $scope.selectStudent[res.cls.id] = res.publishedStudents;
                //   })
                $scope.selectStudent = {};
                return Promise.resolve();
              })
            } else {
              $scope.selectStudent = {};
              return Promise.resolve();
            }
          }).then(() => {
            doPublishModule(module, publishedClasses, allRecords, $scope.selectStudent);
          });
    };

    function doPublishModule(module, publishedClasses, allRecords, selectStudent) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/publishTm.html'),
        controller: 'publishTmCtrl',
        size: 'md',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          data: function() {
            return {
              course: $scope.course,
              publishRecords: allRecords,
              tmId: module.id,
              publishedClasses: _.uniq(publishedClasses),
              selectStudent: selectStudent
            };
          }
        }
      });

      modalInstance.result.then(function(data) {
        var records = _.get(data, 'records');
        if (records && !_.isEmpty(records)) {
          $scope.loading = reloadTmPubRecords();
        }
      });
    }

    $scope.editModule = function(module) {
      var moduleIdx = _.findIndex($scope.teachingPattern.modules, module);
      if (moduleIdx < 0) {
        console.log('can not find module');
        return;
      }

      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/editTeachingModule.html'),
        controller: 'editTeachingModuleCtrl',
        size: 'md',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          teachingModule: function() {
            return module;
          }
        }
      });

      modalInstance.result.then(function(newTm) {
        updateModuleInternalState(newTm);
        $scope.teachingPattern.modules[moduleIdx] = newTm;
      });

    };

    $scope.typeFilter = function(res) {
      if (!$scope.filterType)
        return true;
      return getType(res) == $scope.filterType;
    };

    $scope.updateReses = function() {
      if (!$scope.filterType) {
        $scope.avaliableReses = $scope.unusedReses;
        return;
      }

      $scope.avaliableReses = _.filter($scope.unusedReses, function(res) {
        return getType(res) == $scope.filterType;
      });
    };

    $scope.setFilter = function(type) {
      $scope.filterType = type;
      $scope.updateReses();
    };

    $scope.viewIndex = 0;

    $scope.getCloudShaftStyle = function(patternIdx, idx) {
      var offset = '-' + ($scope.cloudTpViewStatus[patternIdx].viewIndex * boxWidth) + 'px';
      return {
        left: offset
      };
    };

    $scope.computeMaxCourseDisplayCount = function() {
      var width = $('#shaftArea').outerWidth();
      return Math.floor(width / boxWidth);
    };

    $scope.$on('resize', function() {
      $scope.computeMaxCourseDisplayCount();
    });

    $scope.cloudTpViewStatus = [];

    $scope.computeCloudTeachingPatternDisplayCount = function(idx) {
      var cls = '.cloud-tp-area-' + idx;
      var width = $(cls).outerWidth();
      return Math.floor(width / boxWidth);
    };

    $scope.nextForCloudTp = function(cloudTpIdx) {
      var maxCount = $scope.computeMaxCourseDisplayCount(cloudTpIdx);
      if ($scope.cloudTpViewStatus[cloudTpIdx].viewIndex + maxCount >
        _.size($scope.cloudTeachingPatterns[cloudTpIdx].modules))
        return;
      $scope.cloudTpViewStatus[cloudTpIdx].viewIndex++;
    };

    $scope.prevForCloudTp = function(cloudTpIdx) {
      if ($scope.cloudTpViewStatus[cloudTpIdx].viewIndex <= 0)
        return;

      $scope.cloudTpViewStatus[cloudTpIdx].viewIndex--;
    };

    $scope.next = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount();
      if ($scope.viewIndex + maxCount >= _.size($scope.teachingPattern.modules))
        return;
      $scope.viewIndex++;
    };

    $scope.prev = function() {
      if ($scope.viewIndex <= 0)
        return;

      $scope.viewIndex--;
    };

    /*        $scope.getListWidth=function(){
                var listWidth=(boxWidth * $scope.computeMaxCourseDisplayCount())+'px'
                return {
                    width:listWidth
                }
            };

            $scope.getBodyWidth=function(){
                var bodyWidth=(boxWidth * $scope.computeMaxCourseDisplayCount()+100)+'px'
                return {
                    width:bodyWidth
                }
            };*/

    $scope.hiddenOk = false;
    $scope.hidden = function() {
      $scope.hiddenOk = !$scope.hiddenOk;
    };

    $scope.isTemplateItem = function(pattern) {
      if (pattern && pattern == $scope.templateRes) {
        return true;
      }

      return false;
    };

    $scope.onNameHover = function(pattern) {
      if (!$scope.isTemplateItem(pattern)) {
        pattern.showClose = true;
      }
    };

    $scope.onNameLeave = function(pattern) {
      pattern.showClose = false;
    };

    $scope.getTmSourceHeight = function() {
      var height = 160;

      if ($scope.cloudTeachingPatterns.length > 0 || !_.isEmpty($scope.selectedTemplate)) {
        height = 260;
      }

      return {
        height: height + 'px'
      };
    };

    // template display compute functions start
    $scope.templatesTpViewStatus = 0

    $scope.selectTemplate = (template) => {
      $scope.selectedTemplate = template
      $scope.templatesTpViewStatus = 0
    }

    $scope.getTemplateShaftDivStyle = function(tp) {
      var width = (_.isEmpty(tp) || _.isEmpty(tp.modules)) ?
        '1200' : (_.size(tp.modules) * boxWidth);
      if (width < 1200)
        width = 1200;
      var offset = '-' + ($scope.templatesTpViewStatus * boxWidth) + 'px';
      return {
        width: '' + width + 'px',
        left: offset
      };
    };

    $scope.getTemplateShaftStyle = function() {
      var offset = '-' + ($scope.templatesTpViewStatus * boxWidth) + 'px';
      return {
        left: offset
      };
    };

    $scope.prevForTemplateTp = function() {
      if ($scope.templatesTpViewStatus <= 0)
        return;

      $scope.templatesTpViewStatus--;
    };

    $scope.nextForTemplateTp = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount();
      if ($scope.templatesTpViewStatus + maxCount >
        _.size($scope.selectedTemplate.modules))
        return;
      $scope.templatesTpViewStatus++;
    };

    $scope.gotoTemplatePreview = function(res, pattern) {
      if (resPreviewHandler[res.type]) {
        resPreviewHandler[res.type](res, true);
      } else if (resPreviewHandler[res['__type']]) {
        resPreviewHandler[res['__type']](res, true);
      } else if (resPreviewHandler[res.$type]) {
        resPreviewHandler[res.$type](res, true);
      }
    };

    const initTemplatesTps = () => {
      _.map($scope.templates.sectionTemplates, function(pat) {
        _.each(pat.modules, function(m) {
          _.each(m.objectiveTests, function(test) {
            test['__type'] = 'oedObjectiveTest';
          });

          _.each(m.subjectiveTests, function(test) {
            test['__type'] = 'oedSubjectiveTest';
          });

          _.each(m.presentResources, function(res) {
            res['__type'] = 'oedPresentResource';
          });

          var allReses = [].concat(m.objectiveTests)
            .concat(m.subjectiveTests)
            .concat(m.presentResources);
          m.allReses = _.orderBy(allReses, 'sort');
        })
      })
    }

    const getTemplateTabName = (name, sectionNames) => {
      const containsSectionName = _.find(sectionNames, (secName) => _.includes(name, secName))

      if (_.isEmpty(containsSectionName)) {
        return name
      } else {
        const trimName = _.trim(name.replace(containsSectionName, ''))
        return _.isEmpty(trimName) ? name : trimName
      }
    }
    // template display compute functions end

    function addCloudTeachingPatterns(patterns) {
      $scope.cloudTeachingPatterns = $scope.cloudTeachingPatterns.concat(patterns);
      $scope.cloudTpViewStatus = $scope.cloudTpViewStatus.concat(_.map(patterns, function(pat) {
        _.each(pat.modules, function(m) {
          _.each(m.objectiveTests, function(test) {
            test['__type'] = 'oedObjectiveTest';
          });

          _.each(m.subjectiveTests, function(test) {
            test['__type'] = 'oedSubjectiveTest';
          });

          _.each(m.presentResources, function(res) {
            res['__type'] = 'oedPresentResource';
          });

          var allReses = [].concat(m.objectiveTests)
            .concat(m.subjectiveTests)
            .concat(m.presentResources);
          m.allReses = _.orderBy(allReses, 'sort');
        });

        return {
          viewIndex: 0
        };
      }));
    }

    $scope.removeCloudTp = function(idx) {
      var dlg = dialogs.confirm('确定删除', '删除此教学设计会删除所有导入的素材, 确定要删除吗?');
      dlg.result.then(function() {
        var tp = $scope.cloudTeachingPatterns[idx];
        var length = $scope.cloudTeachingPatterns.length;
        $scope.loading = tp.$delete().then(function() {
          if (length === $scope.cloudTeachingPatterns.length) {
            $scope.cloudTeachingPatterns.splice(idx, 1);
          }
          return reloadData();
        });
      });
    };

    function doImportTp(res, deferred) {
      if (_.isEmpty(res)) {
        deferred.resolve(0);
      } else {
        var curImportIdx = 0;

        var doImportCloudTp = function() {
          if (curImportIdx >= _.size(res)) {
            return 0;
          }

          var r = res[curImportIdx];
          deferred.notify('正在导入第' + (curImportIdx + 1) + '个教学设计');

          return oedTeachingPattern.importByCourse({
            courseId: courseId,
            cloudPatternId: r.id
          }).$promise.then(function() {
            curImportIdx++;
            $scope.filterType = 'oedTeachingDesign';
            return doImportCloudTp();
          });
        };

        doImportCloudTp().then(function() {
          $scope.loading = reloadData();
          deferred.resolve(0);
        }).catch(deferred.reject);
      }

      return deferred.promise;
    }

    $scope.importFromLibrary = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/importFromLibraryDialog.html'),
        controller: 'importFromLibraryCtrl',
        size: 'lg',
        windowClass: 'shouldHideOnPreview cl-library-dialog',
        resolve: {
          resourceDef: function() {
            return {
              name: '教学设计',
              course: $scope.course,
              res: oedCloudTeachingPattern,
              type: 'teachingDesign',
              canImportMultiple: true,
              currentRes: $scope.teachingPattern,
              excludeCurrent: true,
              queryCountURL: oedConfig.url_b('cloudteachingpattern/count2'),
              itemTemplateUrl: 'Assets/templates/prepareCourse/cloudTeachingPatternItem.html',
              queryRes: function(queryOpt, viewType) {
                var me = this;
                return tryQueryByResId(queryOpt.query).then(function(item) {
                  if (_.has(item, 'id')) {
                    return loadCloudTpDetails(item).then(function(item) {
                      return [
                        [item], {
                          data: 1
                        }
                      ];
                    });
                  }
                  return _.bind(queryItems, me)(queryOpt);
                });
              },
              queryResById2: function(query, viewType) {
                var resId = oedCourseUtils.getCloudResTypeAndId(_.trim(query.queryId));
                if (!resId) {
                  //
                  // resId为null, 判断query是不是一个数字
                  var id = _.parseInt(query.queryId);
                  if (_.isNaN(id)) {
                    return $q.resolve(null);
                  }
                  //
                  // 如果输入是一个数字, 则按客观试卷来处理
                  resId = {
                    kind: 'teachingDesign',
                    id: id
                  };
                }

                var kind = _.get(resId, 'kind');
                if (kind !== 'teachingDesign') {
                  return $q.resolve(null);
                }

                return oedCloudTeachingPattern.get({
                  id: resId.id
                }).$promise.catch(function() {
                  return null;
                });
              },
              doPreview: function(res) {
                var def = this;
                var modalInstance = $uibModal.open({
                  template: require('assets/templates/prepareCourse/cloudTeachingPatternPreviewer.html'),
                  controller: 'cloudTeachingPatternPreviewerCtrl',
                  size: 'lg',
                  windowClass: 'shouldHideOnPreviewItem cl-library-preview',
                  resolve: {
                    cloudRes: function() {
                      return res;
                    },
                    currentRes: function() {
                      return $scope.currentRes;
                    },
                    resourceDef: function() {
                      return def;
                    }
                  }
                });

                return modalInstance.result;
              },
              doImport: doImportTp,
            };
          }
        }
      });
    };

    $scope.importFromLibrary2 = () => {
      var modalInstance = $uibModal.open({
        component: 'teachingDesignLibrary',
        size: 'full-screen',
        windowClass: 'shouldHideOnPreview cl-library-dialog2',
        resolve: {
          data: () => ({
            course: $scope.course,
            currentTeachingPattern: $scope.teachingPattern,
            doImport: doImportTp,
          })
        },
      })
    }

    function queryItems(queryOpt) {
      var items = oedCloudTeachingPattern.queryBySection(queryOpt);
      var loadItems = items.$promise.then(function(its) {
        var loadDetails = _.map(its, ctp => {
          ctp.cloudResource.teachingPatterns = ctp.teachingPatterns
          return loadCloudTpDetails(ctp.cloudResource)
        });
        return $q.all(loadDetails);
      });

      var countReq = $http({
        url: this.queryCountURL,
        method: 'GET',
        params: queryOpt
      });

      return $q.all([loadItems, countReq]);
    }

    function loadCloudTpDetails(tp) {
      var loadTp = oedTeachingPattern.get({
        id: tp.teachingPatternId
      }).$promise.then(function(tpDetails) {
        tp.name = tpDetails.name;
      });

      var loadDetails = oedCourse.getByTeachingPatternId({
        id: tp.teachingPatternId
      }).$promise.then(function(cs) {
        tp.courseName = cs.courseName;
        return oedCourse.get({
          id: cs.id
        }).$promise.then(function(csDetails) {
          tp.course = csDetails;
          tp.relatedSections = _.map(csDetails.relatedSections, _.partialRight(_.pick, 'sectionId'));
          return tp;
        });
      }).then(function(tp) {
        return oedCoursePath.get({
          id: tp.course.coursepathId
        }).$promise.then(function(cp) {
          tp.coursePath = cp;
          tp.userName = cp.teacherName;
          tp.materialVersion = {
            versionName: cp.versionName,
            name: cp.gradeName + cp.subjectName
          };
          return tp;
        });
      });

      return $q.all([loadTp, loadDetails]).then(function() {
        return tp;
      });
    }

    function tryQueryByResId(query) {
      if (!query) {
        return $q.resolve(null);
      }

      var resId = oedCourseUtils.getCloudResTypeAndId(_.trim(query));
      if (!resId) {
        //
        // resId为null, 判断query是不是一个数字
        var id = _.parseInt(query);
        if (_.isNaN(id)) {
          return $q.resolve(null);
        }
        //
        // 如果输入是一个数字, 则按客观试卷来处理
        resId = {
          kind: 'teachingDesign',
          id: id
        };
      }

      var kind = _.get(resId, 'kind');
      if (kind !== 'teachingDesign') {
        return $q.resolve(null);
      }

      let req
      if ($scope.status.filterBySchool) {
        req = oedCloudTeachingPattern.queryById({id: resId.id, schoolId: _.parseInt($scope.status.schoolId)})
      } else {
        req = oedCloudTeachingPattern.queryById({id: resId.id})
      }
      return req
        .$promise.catch(function() {
        return null;
      })
    }

    var goToResourceHandler = {
      oedObjectiveTest: function(res) {
        $state.go('prepareCourse.objectiveTest', {
          testId: res.id
        });
      },
      oedSubjectiveTest: function(res) {
        $state.go('prepareCourse.subjectiveTest', {
          testId: res.id
        });
      },
      oedPresentResource: function(res) {
        $state.go('prepareCourse.presentResource', {
          resId: res.id
        });
      }
    };

    $scope.gotoResource = function(res) {
      var type = getType(res);
      if (!_.has(goToResourceHandler, type)) {
        //
        // 只可能是导入教学设计的资源
        type = res['__type'];
      }

      return goToResourceHandler[type](res);
    };

    function previewObjectiveTest(res, templateRes) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/objectiveTestPreviewer.html'),
        controller: 'objectiveTestPreviewerCtrl',
        size: 'lg',
        resolve: {
          cloudTest: function() {
            return res;
          },
          templateRes: function() {
            return templateRes;
          },
          gotoRes: function() {
            return $scope.gotoResource;
          },
          resourceDef: null
        },
        windowClass: 'oed-dialog-modal cl-preview-readonly modalCenter'
      });

      return modalInstance.result;
    }

    function previewSubjectiveTest(res, templateRes) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/subjectiveTestPreviewer.html'),
        controller: 'subjectiveTestPreviewerCtrl',
        size: 'lg',
        resolve: {
          cloudTest: function() {
            return res;
          },
          templateRes: function() {
            return templateRes;
          },
          gotoRes: function() {
            return $scope.gotoResource;
          },
          resourceDef: null
        },
        windowClass: 'oed-dialog-modal cl-preview-readonly modalCenter'
      });

      return modalInstance.result;
    }

    function previewPresentRes(res, templateRes) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/presentResPreviewer.html'),
        controller: 'presentResPreviewerCtrl',
        size: 'lg',
        resolve: {
          cloudRes: function() {
            return res;
          },
          templateRes: function() {
            return templateRes;
          },
          gotoRes: function() {
            return $scope.gotoResource;
          },
          currentRes: function() {
            return res;
          },
          resourceDef: null
        },
        windowClass: 'oed-dialog-modal cl-preview-readonly modalCenter'
      });

      return modalInstance.result;
    }

    var resPreviewHandler = {
      oedObjectiveTest: previewObjectiveTest,
      objective: previewObjectiveTest,
      oedSubjectiveTest: previewSubjectiveTest,
      subjective: previewSubjectiveTest,
      oedPresentResource: previewPresentRes,
      presentresource: previewPresentRes,
    };

    $scope.gotoResOrPreview = function(res, pattern) {
      if (pattern && pattern == $scope.templateRes) {
        resPreviewHandler[res['__type']](res);
        return;
      }

      $scope.gotoResource(res);
    };

    $scope.gotoPreview = function(res, pattern) {
      if (resPreviewHandler[res.type]) {
        resPreviewHandler[res.type](res, pattern && pattern == $scope.templateRes);
      } else if (resPreviewHandler[res['__type']]) {
        resPreviewHandler[res['__type']](res, pattern && pattern == $scope.templateRes);
      } else if (resPreviewHandler[res.$type]) {
        resPreviewHandler[res.$type](res, pattern && pattern == $scope.templateRes);
      }
    };

    $scope.mapTmPubRecords = {};
    $scope.mapTmTaskList = {};

    $scope.isModulePublished = function(module) {
      var records = $scope.mapTmPubRecords[module.id];
      return records && !_.isEmpty(records);
    };
    $scope.isModulePublished2 = function(module) {
      var records = $scope.mapTmTaskList[module.id];
      return records && !_.isEmpty(records);
    };

    // loadTeachingPattern();

    function reloadTmPubRecords() {
      return $scope.teachingPattern.$promise.then(function(tp) {
        return $q.all(_.map(tp.modules, function(tm) {
          return oedTeachingModule.getPublishedRecords({
            id: tm.id
          }).$promise.then(function(records) {
            $scope.mapTmPubRecords[tm.id] = records;
            var tmTaskList = {};
            _.forEach(records, function(o) {
              if (tmTaskList[o.publishDate]) {
                tmTaskList[o.publishDate].push(o);
              } else {
                tmTaskList[o.publishDate] = [o];
              }
            });
            $scope.mapTmTaskList[tm.id] = tmTaskList;
          });
        }));
      });
    }
    $scope.reloadCloudRes = function() {
      return $scope.teachingPattern.$promise.then(function(tp) {
        return oedCloudTeachingPattern.queryByResIds({
          resIds: [tp.id]
        }).$promise.then(function(res) {
          $scope.cloudRes = res[0];
        });
      });
    };

    $scope.closeTemplateTip = function() {
      $scope.popupTip = 0;
    };

    $scope.useLoadedTemplate = function() {
      $scope.findTemplate();
      $scope.popupTip = 0;
    };

    $scope.findTemplate = function() {
      $scope.loading = oedTeachingPattern.findAllTemplate({id: courseId}).$promise.then(
        function(result) {
          var total = 0;

          var templateLists = [];
          if (result) {
            if (result.sectionTemplates) {
              templateLists = templateLists.concat(result.sectionTemplates);
            }

            if (result.versionTemplates) {
              templateLists = templateLists.concat(result.versionTemplates);
            }

            if (result.subjectTemplates) {
              templateLists = templateLists.concat(result.subjectTemplates);
            }

            total = templateLists.length;

            if (total <= 0) {
              notificationService.notify('info', '未找到匹配的课程模板')
            } else if (total === 1) {
              if ($scope.teachingPattern.modules.length > 0) {
                var confirmMessage = '将替换现有教学设计内容，是否继续？';
                var modalInstance = $uibModal.open({
                  template: require('assets/templates/prepareCourse/confirmMsgDialog.html'),
                  controller: 'confirmMsgDialogCtrl',
                  size: 'sm',
                  windowClass: 'oed-dialog-modal',
                  resolve: {
                    msgConfirm: function() {
                      return confirmMessage;
                    }
                  }
                });

                modalInstance.result.then(function() {
                    oedTeachingPattern.importTemplate({id: courseId}).$promise.then(function() {
                      reloadAll();
                    });
                  },
                  function(msg) {
                    if (msg && msg !== 'cancel') {
                      notificationService.notify('info', '课程模板导入失败，请重试')
                    }
                  });
              } else {
                oedTeachingPattern.importTemplate({id: courseId}).$promise.then(function() {
                  reloadAll();
                });
              }
            } else {
              showAllTemplate(templateLists);
            }
          }
        }
      );
    };

    function showAllTemplate(allTypeTemplates) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/templatePreviewer.html'),
        controller: 'templatePreviewerCtrl',
        size: 'lg',
        windowClass: 'shouldHideOnPreviewItem oed-dialog-modal',
        resolve: {
          allTemplatesData: function() {
            return allTypeTemplates;
          },
          courseId: function() {
            return courseId;
          },
          curTmLen: function() {
            return $scope.teachingPattern.modules.length;
          }
        }
      });

      modalInstance.result.then(function() {reloadAll()}, function(msg) {
        if (msg && msg !== 'cancel') {
          notificationService.notify('info', '课程模板导入失败，请重试')
        }
      });
    }

    $scope.importTemplate = function() {
      $scope.loading = oedTeachingPattern.importTemplate({id: courseId}).$promise.then(function() {
        reloadAll();
      });
    };

    function reloadAll() {
      $scope.teachingPattern = oedTeachingPattern.getByCourse2({
        courseId: courseId
      });

      $scope.teachingPattern.$promise.then((tp) => {
        return teachingPatternLoad(tp);
      });
    }

    function teachingPatternLoad(tp) {
      const testIds = _(tp.modules).map('tests').flatten().map('objectiveTestId').uniq().value()
      const resIds  = _(tp.modules).map('presentResources').flatten().map('presentResourceId').uniq().value()
      const tests = _.isEmpty(testIds) ? [] : oedTest.getByIds({
        ids: testIds
      }).$promise
      const reses = _.isEmpty(resIds) ? [] : oedPresentResource.getByIds({
        ids: resIds
      }).$promise

      return $q.all([tests, reses]).then(function(arr) {
        const testById = _.keyBy(arr[0], 'id')
        const resById = _.keyBy(arr[1], 'id')

        _.forEach($scope.teachingPattern.modules, m => {
          const moduleTests = _(m.tests).map(t => _.assign(
            {}, testById[t.objectiveTestId], {
              sort: t.sort,
            })).partition(t => t.type === 'objective').value()

          const moduleReses = _(m.presentResources).map(r => _.assign(
            {}, resById[r.presentResourceId], {
              sort: r.sort, type: 'presentresource',
            })).value()
          m.objectiveTests = moduleTests[0]
          m.subjectiveTests = moduleTests[1]
          m.presentResources = moduleReses
        })

        reloadData()
      })
    }

    $scope.clearTeachingPattern = function() {
      var confirmMessage = '将清空教学环节，是否清空素材';
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/clearTpDialog.html'),
        controller: 'clearTpDialogCtrl',
        size: 'sm',
        windowClass: 'oed-dialog-modal modalCenter',
        resolve: {
          msgConfirm: function() {
            return confirmMessage;
          }
        }
      });

      modalInstance.result.then(function(type) {
          oedTeachingPattern.clearTeachingPattern({id: $scope.teachingPattern.id,
            courseId: courseId, clearType: type}).$promise.then(function() {
            reloadAll();
          });
        });
    };

    function reloadData() {
      var loadTmPubRecords = reloadTmPubRecords();

      $scope.offlineResources = [];

      $scope.presentResources = oedPresentResource.queryByCourse({
        course_id: courseId // TODO: fix naming
      });

      $scope.objectiveTests = oedObjectiveTest.queryByCourse({
        course_id: courseId // TODO: fix naming
      });

      $scope.subjectiveTests = oedSubjectiveTest.queryByCourse({
        course_id: courseId // TODO: fix naming
      });

      var candindatesTp = oedTeachingPattern.queryCandindateByCourse({
        courseId: courseId
      }).$promise.then(function(tps) {
        $scope.cloudTeachingPatterns = [];
        $scope.cloudTpViewStatus = [];
        tps = _.filter(tps, (tp) => _.isEmpty(_.find($scope.templates.sectionTemplates, (template) => template.id === tp.id)))
        addCloudTeachingPatterns(tps);
      });

      //
      // post process
      return $q.all([$scope.teachingPattern.$promise,
          loadTmPubRecords.$promise,
          $scope.reloadCloudRes(),
          $scope.offlineResources.$promise,
          $scope.presentResources.$promise,
          $scope.objectiveTests.$promise,
          $scope.subjectiveTests.$promise,
          candindatesTp
        ])
        .then(() => {
          updateScopeVariables();
        });
    }

    // $scope.loading = reloadData();

    function filterResesByType(reses, t) {
      return _(reses).filter(function(res) {
        return getType(res) === t;
      });
    }

    $scope.isEmptyResource = function(tp) {
      return _.isEmpty(tp.modules);
    };

    function updateModuleItems(moduleId) {
      var moduleIdx = _.findIndex($scope.teachingPattern.modules, function(m) {
        return m.id == moduleId;
      });
      if (moduleIdx < 0) {
        console.log('invlaid module id');
        return;
      }
      var module = $scope.teachingPattern.modules[moduleIdx];
      var reses = module.$allReses;

      var m = new oedTeachingModule({
        id: moduleId
      });

      var sort = 0;
      _.each(reses, function(res) {
        res.sort = sort++;
      });

      function filterType(type) {
        return filterResesByType(reses, type).map(function(res) {
          return {
            id: res.id,
            sort: res.sort
          };
        }).value();
      }

      m.objectiveTests = filterType('oedObjectiveTest');
      m.subjectiveTests = filterType('oedSubjectiveTest');
      m.presentResources = filterType('oedPresentResource');

      $scope.loading = m.$updateItemOrder().then(function(newTm) {
        updateModuleInternalState(newTm);
        $scope.teachingPattern.modules[moduleIdx] = newTm;
      });
    }

    $scope.moduleResSortOptions = {
      stop: function(event) {
        //event.preventDefault();
        //event.stopPropagation();
        var moduleId = $(this).attr('module-id');

        updateModuleItems(moduleId);
      }
    };

    $scope.removeResFromModule = function(module, idx, $event) {
      $event.stopPropagation();
      $event.preventDefault();
      //
      // 这里需要先找到对应的索引, 因为module有可能会变化
      var moduleIdx = _.findIndex($scope.teachingPattern.modules, module);
      var dlg = dialogs.confirm('确定移除?', '确定要把此资源从教学环节中移除? ');
      dlg.result.then(function(btn) {
        var m = $scope.teachingPattern.modules[moduleIdx];
        m.$allReses.splice(idx, 1);
        if (moduleIdx < 0) {
          console.log('invalid module index');
          return;
        }
        updateTeachingModuleResources(moduleIdx);
      });
    };

    $scope.cloudTpDragOptions = {
      revert: 'invalid',
      appendTo: 'body',
      helper: function(event) {
        var el = $(this).clone();
        var list = $('.shaft-list', el);
        var resources = $('.shaft-res', list);
        if (resources.length > 3) {
          var newEls = resources.slice(0, 3);
          list.empty();
          list.append(newEls);
          list.append('<div class="col-md-12 shaft-res res-placeholder">' +
            '<i class="fa fa-2x fa-ellipsis-v"></i> <div>');
        }
        return el;
      }
    };

    $scope.convertToLayer = (m) => {
      console.log('convert to layer', m);
    }

    $scope.openShareInfo = () => {
      $scope.showShareInfo = true
    }

    $scope.closeShareInfo = () => {
      $scope.showShareInfo = false
    }
  }

  addTeachingModuleCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedTeachingModule', 'patternId','isHomework']
  function addTeachingModuleCtrl($scope, $q, $uibModalInstance, oedTeachingModule, patternId,isHomework) {
    $scope.module = {};
    $scope.isHomework = isHomework;
    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.addTmForm.$invalid) {
        return;
      }

      var module = new oedTeachingModule({
        name: $scope.module.name,
        note: $scope.module.note,
        teachingPatternId: patternId,
        isHomework: $scope.isHomework
      });
      var p = $q(function(resolve, reject) {
        module.$save().then(function(tm) {
          resolve();
          $uibModalInstance.close(tm);
        }).catch(function() {
          resolve();
          $uibModalInstance.cancel('error');
        });
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }

  editTeachingModuleCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedTeachingModule', 'teachingModule']
  function editTeachingModuleCtrl($scope, $q, $uibModalInstance, oedTeachingModule, teachingModule) {
    $scope.module = {
      name: teachingModule.name,
      note: teachingModule.note
    };

    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.editTmForm.$invalid) {
        return;
      }

      _.assign(teachingModule, $scope.module);
      var p = $q(function(resolve, reject) {
        oedTeachingModule.save(teachingModule).$promise.then(function(tm) {
          resolve();
          $uibModalInstance.close(tm);
        }).catch(function() {
          resolve();
          $uibModalInstance.dismiss('error');
        });
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }

  editTeachingPatternCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedTeachingModule', 'pattern']
  function editTeachingPatternCtrl($scope, $q, $uibModalInstance, oedTeachingModule, pattern) {
    $scope.pattern = {
      name: pattern.name
    };

    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.editTpForm.$invalid) {
        return;
      }

      pattern.name = $scope.pattern.name;
      var p = $q(function(resolve, reject) {
        pattern.$save().finally(function() {
          resolve();
          $uibModalInstance.close({});
        });
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }

  confirmMsgDialogCtrl.$inject = ['$scope', '$q', '$uibModal', '$uibModalInstance', 'msgConfirm']
  function confirmMsgDialogCtrl($scope, $q, $uibModal, $uibModalInstance, msgConfirm) {
    $scope.message = msgConfirm;
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.ok = function() {
      $uibModalInstance.close('ok');
    }
  }

  clearTpDialogCtrl.$inject = ['$scope', '$q', '$uibModal', '$uibModalInstance', 'msgConfirm']
  function clearTpDialogCtrl($scope, $q, $uibModal, $uibModalInstance, msgConfirm) {
    $scope.message = msgConfirm;
    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
    $scope.clearAll = function() {
      $uibModalInstance.close(1);
    };
    $scope.clearModules = function() {
      $uibModalInstance.close(2);
    }
  }

  templatePreviewerCtrl.$inject = ['$scope', '$q', '$uibModal', '$uibModalInstance', 'allTemplatesData', 'courseId',
    'oedTeachingPattern', 'curTmLen', 'dialogs']
  function templatePreviewerCtrl($scope, $q, $uibModal, $uibModalInstance, allTemplatesData, courseId,
                                 oedTeachingPattern, curTmLen, dialogs) {
    $scope.moduleReses = {};

    $scope.objectiveTests = {};
    $scope.subjectiveTests = {};
    $scope.presentResources = {};

    $scope.getResIcon = function(res) {
      if (res['__type'] == 'objectivetest')
        return 'keguan_icon';
      if (res['__type'] == 'subjectivetest')
        return 'zhuguan_icon';
      if (res['__type'] == 'presentresource')
        return 'zhanshi_icon';

      return '??';
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.ok = function() {
      if (curTmLen > 0) {
        var confirmMessage = '将替换现有教学设计内容，是否继续？';
        var modalInstance = $uibModal.open({
          template: require('assets/templates/prepareCourse/confirmMsgDialog.html'),
          controller: 'confirmMsgDialogCtrl',
          size: 'sm',
          windowClass: 'oed-dialog-modal',
          resolve: {
            msgConfirm: function() {
              return confirmMessage;
            }
          }
        });

        modalInstance.result.then(function() {
            importTemplate();
          },
          function(msg) {
            if (msg && msg !== 'cancel') {
              $uibModalInstance.dismiss('internalError');
            }
          });
      } else {
        importTemplate();
      }

      function importTemplate() {
        if ($scope.curTemplate) {
          $scope.loading = oedTeachingPattern.importTemplateById({teachingPatternId: $scope.curTemplate.id,
            templateCourseId: $scope.curTemplate.courseId,
            curCourseId: courseId
          }).$promise.then(function() {
            $uibModalInstance.close();
          }, function() {
            $uibModalInstance.dismiss('internalError');
          });
        } else {
          $uibModalInstance.dismiss('noSelectedTemplate');
        }
      }
    };

    $scope.getDate = function(timestamp) {
      var date = new Date(timestamp);
      return [date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-');
    };

    $scope.selectOne = function(selectedTemplate) {
      $scope.viewIndex = 0;
      $scope.lastTemplate = $scope.curTemplate;
      $scope.curTemplate = selectedTemplate;

      if ($scope.lastTemplate) {
        $scope.lastTemplate.selected = false;
      }

      $scope.curTemplate.selected = true;

      _.each($scope.curTemplate.modules, function(m) {
        _.each(m.objectiveTests, function(test) {
          test['__type'] = 'objectivetest';
        });

        _.each(m.subjectiveTests, function(test) {
          test['__type'] = 'subjectivetest';
        });

        _.each(m.presentResources, function(res) {
          res['__type'] = 'presentresource';
        });

        $scope.moduleReses[m.id] = [].concat(m.objectiveTests)
          .concat(m.subjectiveTests)
          .concat(m.presentResources);
      });
    };

    var boxWidth = 230;
    $scope.getShaftDivStyle = function() {
      var width = _.isEmpty($scope.curTemplate.modules) ?
        '1200' : (_.size($scope.curTemplate.modules) * boxWidth);
      if (width < 1200)
        width = 1200;
      var offset = '-' + ($scope.viewIndex * boxWidth) + 'px';
      return {
        width: '' + width + 'px',
        left: offset
      };
    };
    $scope.viewIndex = 0;
    $scope.computeMaxCourseDisplayCount = function() {
      var width = $('#shaftArea').outerWidth();
      return Math.floor(width / boxWidth);
    };
    $scope.$on('resize', function() {
      $scope.computeMaxCourseDisplayCount();
    });
    $scope.next = function() {
      var maxCount = $scope.computeMaxCourseDisplayCount();
      if ($scope.viewIndex + maxCount >= _.size($scope.curTemplate.modules))
        return;
      $scope.viewIndex++;
    };

    $scope.prev = function() {
      if ($scope.viewIndex <= 0)
        return;

      $scope.viewIndex--;
    };

    $scope.selectedStyle = function(template) {
      if (template.selected) {
        return {
          background: '#ddd'
        }
      }
    };

    $scope.templates = allTemplatesData;
    if ($scope.templates.length > 0) {
      $scope.selectOne($scope.templates[0]);
    }

    function previewObjectiveTest(res) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/objectiveTestPreviewer.html'),
        controller: 'objectiveTestPreviewerCtrl',
        size: 'lg',
        resolve: {
          cloudTest: function() {
            return res;
          },
          resourceDef: null
        },
        windowClass: 'oed-dialog-modal cl-preview-readonly modalCenter'
      });

      return modalInstance.result;
    }

    function previewSubjectiveTest(res) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/subjectiveTestPreviewer.html'),
        controller: 'subjectiveTestPreviewerCtrl',
        size: 'lg',
        resolve: {
          cloudTest: function() {
            return res;
          },
          resourceDef: null
        },
        windowClass: 'oed-dialog-modal cl-preview-readonly modalCenter'
      });

      return modalInstance.result;
    }

    function previewPresentRes(res) {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/presentResPreviewer.html'),
        controller: 'presentResPreviewerCtrl',
        size: 'lg',
        resolve: {
          cloudRes: function() {
            return res;
          },
          currentRes: function() {
            return res;
          },
          resourceDef: null
        },
        windowClass: 'oed-dialog-modal cl-preview-readonly modalCenter'
      });

      return modalInstance.result;
    }

    var resPreviewHandler = {
      objectivetest: previewObjectiveTest,
      subjectivetest: previewSubjectiveTest,
      presentresource: previewPresentRes
    };

    $scope.previewItem = function(res) {
      var dlgs = $('.shouldHideOnPreviewItem');
      dlgs.css('display', 'none');
      resPreviewHandler[res['__type']](res).finally(function() {
        dlgs.css('display', 'block');
      });
    };
  }
})();
