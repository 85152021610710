import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import _ = require('lodash');
import { v4 as uuidv4 } from 'uuid'

@Component({
    selector: 'app-yes-or-no-designer',
    template: require('./yes-or-no-designer.component.html'),
    styles: [require('./yes-or-no-designer.component.scss')]
})
export class YesOrNoDesignerComponent implements OnInit {

    @Input() content
    @Input() resDelShowType
    @Input() isThreeDegrees
    @Input() editorConfig

    @Output() contentChange = new EventEmitter<any>()
    @Output() showResAndDelClick = new EventEmitter<any>()

    uuid: string
    constructor() {
        this.uuid = uuidv4()
    }

    ngOnInit() {
    }

    public handleShowResAndDelClick() {
        this.showResAndDelClick.emit(1)
    }

    getAnswer() {
        return _.get(this.content, 'answer', 0)
    }

}
