import * as _ from 'lodash'
// tslint:disable-next-line:ordered-imports
import { EventEmitter, Component, Input, Output, OnInit, SimpleChanges, AfterViewInit, ViewChild } from '@angular/core'
import { OedDialogService } from 'app/core/oed-dialog.service'
import { OedUibmodalService } from 'app/core/oed-uibmodal.service'
import { TestOrPresentDTO } from 'app/lesson/models/lesson.d'
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { TeachingPatternDataService } from 'app/lesson/teaching-pattern-data.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
  selector: 'app-module-item',
  template: require('./module-item.component.html'),
  styles: [require('./module-item.component.scss')]
})
export class ModuleItemComponent implements OnInit, AfterViewInit {

  @Input() public res: TestOrPresentDTO
  @Input() public disable: boolean
  @Input() public courseId: number
  @Input() public moduleId: number
  @Input() public moduleName: string
  @Input() public highLight: boolean
  @Input() public newImport: {type: string; isNewRes: boolean}
  @Output() public delete = new EventEmitter<number>()
  @ViewChild('pop') popOver: PopoverDirective
  private viewReady: boolean
  private popoverContent: string
  constructor(
    private oedUibmodalService: OedUibmodalService,
    private oedDialogService: OedDialogService,
    private lessonMessageService: LessonMessageService,
    private teachingPatternDataService: TeachingPatternDataService
    ) { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'res')) {
        this.initState()
    }
    if (_.has(changes, 'newImport')) {
      this.clearHighLightData()
      if (this.newImport) {
        this.popoverContent = '已为您导入新的' + this.newImport.type
        this.checkImportedNotify()
      }
    }
  }

  public ngAfterViewInit() {
    this.viewReady = true
    this.clearHighLightData()
    if (this.newImport) {
      this.checkImportedNotify()
    }
  }

  public gotoPreview() {
    this.oedUibmodalService.gotoPreviewOrEdit(
      this.res, this.disable, this.courseId, this.moduleId, this.moduleName).then(() => {
      this.reloadCourseResource()
    }, () => {
      this.reloadCourseResource()
    })
  }

  public reloadCourseResource() {
    this.lessonMessageService.sendReloadPatternAction()
  }

  public deleteItem() {
    if (this.disable) {
      this.lessonMessageService.sendSaveRecommendCourseAction()
      return
    }
    this.oedDialogService.openModalWithComponent('OedConfirmComponent', {
      title: '确定移除?',
      message: '确定要把此资源从教学环节中移除?',
    }, () => {
      this.delete.emit(this.res.id)
    }, {
      class: 'modal-sm',
    })
  }

  private initState() {
    this.res = {
      ...this.res,
    }
  }

  public checkImportedNotify() {
    if(!this.viewReady || !this.newImport.isNewRes) {
      return
    }
    this.popOver.show()
    this.teachingPatternDataService.clearLastImportedObjectiveTests()
    setTimeout(() => {
      this.popOver.hide()
    }, 3000);
  }

  public clearHighLightData() {
    if(!this.viewReady || !this.highLight) {
      return
    }
    setTimeout(() => {
      this.teachingPatternDataService.clearHighLightImport()
    }, 3000);
  }
}
