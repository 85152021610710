(function() {
  require('assets/styles/course.scss')
  const Promise = require('bluebird')
  const _ = require('lodash')

  angular.module('app.newPrepareCourse.ownerPrepare', [])
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('newPrepareCourse.ownerPrepare', {
      url: '/ownerPrepare',
      template: require('assets/templates/newPrepareCourse/ownerPrepare.html'),
      controller: 'ownerPrepareCtrl',
      loadingCls: 'blueloading',
      bodyStyle: 'body-teachingdesign body-ownerPrepare'
    }).state('newPrepareCourse.ownerPrepare.resItem', {
      url: '/resItem?resId',
      template: require('assets/templates/newPrepareCourse/ownerPrepare.html'),
      controller: 'ownerPrepareCtrl',
      loadingCls: 'blueloading',
      bodyStyle: 'body-teachingdesign body-ownerPrepare'
    });
  }])
    .controller('ownerPrepareCtrl', ownerPrepareCtrl)

  ownerPrepareCtrl.$inject = [
    '$rootScope', '$scope', '$q', '$state', '$stateParams',
    '$uibModal', 'Upload', '$http', 'dialogs', 'resize', 'notify', 'oedUserClass',
    'oedConfig', 'oedCourse', 'oedTeachingPattern', 'oedTeachingModule', 'oedCloudTest', 'oedCloudSubjectiveTest',
    'oedPresentResource', 'oedOfflineResource', 'oedObjectiveTest', 'userInfo', 'oedCloudPresentRes',
    'oedSubjectiveTest', 'oedCloudTeachingPattern', 'oedCoursePath', 'oedCourseUtils', 'oedCloudPresentResItem', 'oedCloudQuestion',
    'oedTest', 'oedTaskListPublishRecord', 'oedSchool', 'notificationService', 'oedTestUtils', 'oedTeachingPlan']
  function ownerPrepareCtrl(
    $rootScope, $scope, $q, $state, $stateParams,
    $uibModal, Upload, $http, dialogs, resize, notify, oedUserClass,
    oedConfig, oedCourse, oedTeachingPattern, oedTeachingModule, oedCloudTest, oedCloudSubjectiveTest,
    oedPresentResource, oedOfflineResource, oedObjectiveTest, userInfo, oedCloudPresentRes,
    oedSubjectiveTest, oedCloudTeachingPattern, oedCoursePath, oedCourseUtils, oedCloudPresentResItem, oedCloudQuestion,
    oedTest, oedTaskListPublishRecord, oedSchool, notificationService, oedTestUtils, oedTeachingPlan) {
    $scope.currentState.title = '课堂教学设计';
    $scope.courseId = $stateParams.courseId;
    $scope.shareResource = false;
    $scope.loading = oedCourse.get({
      id: $stateParams.courseId
    }).$promise;
    $scope.loading.then(c => {
      $scope.shareResource = c.shareResource;
      $scope.sectionId = c.relatedSections[0].sectionId;
      $scope.classTime = c.classTime ? c.classTime : -1;
      $scope.load = true;
    });
    $scope.setLoading = (params) => {
      $scope.requestLoading = params.loading
    }

  }
})();
