import {Component, OnInit, Input, OnDestroy, ElementRef, HostListener} from '@angular/core';
import { map, finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { LoadingService } from 'app/shared/loading/loading.service';
import { SbjSessionService } from '../sbj-session.service';
import { LegacyAppService } from 'app/core/legacy-app.service';
import { BoardContentCommentBodyDTO, BoardContentInteractsMDTO, BoardContentCommentDTO, LegacyPairDTO, BoardSessionInteractsMDTO, BoardContentDTO } from 'app/models/sbj-test-session';
import { UserDTO } from 'app/models/user';
import { Subscription } from 'rxjs';

type SbjTestStatTableSortProperties = 'studentId' | 'studentName' |
  'contentLength' | 'startTime' | 'submitTime' | 'flagsList.length' |
  'viewsList.length' |
  'bravosList.length' |
  'validCommentsLength';

interface BoardContentInteractsMDTOView extends BoardContentInteractsMDTO<BoardContentCommentBodyDTO> {
  validCommentsLength: number;
  contentLength: number;
}

interface BoardSessionInteractsMDTOView extends BoardSessionInteractsMDTO<BoardContentCommentBodyDTO> { // eslint-disable-line
}

@Component({
  selector: 'app-sbj-test-stat-table',
  template: require('./sbj-test-stat-table.component.html'),
  styles: [require('./sbj-test-stat-table.component.scss')]
})
export class SbjTestStatTableComponent implements OnInit, OnDestroy {
  @Input() boardSessionId: number;
  @HostListener('scroll', ['$event']) public scrolled($event: Event) {
  }
  loadingName = 'sbj-test-stats-table'
  reverse = true
  propertyId: SbjTestStatTableSortProperties = 'contentLength'

  propertys = [
    {id: 'studentId', name : '学生ID'},
    {id: 'studentName', name : '姓名'},
    {id: 'contentLength', name : '作品数目'},
    {id: 'startTime', name : '开始答题时间'},
    {id: 'submitTime', name : '完成时间'},
    {id: 'flagsList.length', name : '被标记'},
    {id: 'viewsList.length', name : '被查看'},
    {id: 'bravosList.length', name : '被点赞'},
    {id: 'validCommentsLength', name : '被评论'},
  ];
  stats: BoardSessionInteractsMDTOView;
  index: number;
  subs$: Subscription;

  constructor(private loadingService: LoadingService,
              private sbjSessionService: SbjSessionService,
              private legacyAppService: LegacyAppService,
              private el: ElementRef) { }

  ngOnInit() {
    this.loadingService.show(false, this.loadingName)

    this.subs$ = this.sbjSessionService.getSessionStat(this.boardSessionId)
      .pipe(map(stats => {
        const interactsView: BoardContentInteractsMDTOView[] = _.map(stats.interacts, (it) => {
          return {
            ...it,
            validCommentsLength: this.getVisibleCommentsCount(it.commentsList),
            contentLength: this.calcBoardContentLength(it.contentList),
          } as BoardContentInteractsMDTOView
        })

        const statsView: BoardSessionInteractsMDTOView = {
          ...stats,
          interacts: interactsView,
        }
        this.stats = statsView
        this.doSort()
      }), finalize(() => {
        this.loadingService.hide(this.loadingName)
      }))
      .subscribe(() => {}, (e) => {
        console.log('error', e)
      })
  }

  ngOnDestroy(): void {
    if (this.subs$) {
      this.subs$.unsubscribe()
    }
  }

  getVisibleCommentsCount (commentList: LegacyPairDTO<UserDTO, BoardContentCommentDTO<string> >[]) {
    const rankComments = _.filter(_.map(commentList, 'value.commentBody.rank'), (rank: any) => {
      return !_.isUndefined(rank);
    })
    return _.size(commentList) - _.size(rankComments);
  }

  calcBoardContentLength (contentList: BoardContentDTO[]) {
    let count = 0;
    _.map(contentList, (content) => {
      count = count + this.realBoardContentLength(content.contentBody);
    })
    return count;
  }
  /**
   * 小锐作业上传的boardContent，存在文字,直接计算contentList.length不正确
   * @param contentBody
   */
  realBoardContentLength (contentBody: string) {
    const body: BoardContentCommentBodyDTO = JSON.parse(contentBody)
    let count = 0
    if (!_.isEmpty(body.content)) {
      count = count + 1;
    }
    count = count + _.size(body.resources);
    return count
  }

  sortBy (propertyId: SbjTestStatTableSortProperties) {
    this.reverse = (this.propertyId === propertyId) ? !this.reverse : true
    this.propertyId = propertyId
    this.doSort()
  }

  doSort() {
    const order = this.reverse ? 'desc' : 'asc'
    this.stats.interacts = _.orderBy(this.stats.interacts, this.propertyId, [order])
  }

  getSubmitTime (contentList: BoardContentDTO[]) {
    const max = _.maxBy(contentList, 'submitTime')
    return max.submitTime
  }

  findStdIndex (uid: string) {
    this.index = _.findIndex(this.stats.interacts, (interact: any) => {
      return _.isEqual('' + interact.owner.uid, '' + uid)
    })
  }

  doShowDetailsForStudent (uid: string) {
    const uibModal = this.legacyAppService.getUibModal()
    this.findStdIndex(uid)
    uibModal.open({
      controller: 'interactDetailsViewerCtrl',
      resolve: {
        data: () => {
          return {
            index: this.index,
            interacts: this.stats.interacts,
          }
        },
      },
      size: 'fullwidth without-bg',
      template: require('assets/templates/review/interactDetailsViewer.html'),
      windowClass: 'oedmodal modal-without-bg modalCenter',
    })
  }
  handleScroll(){
    const scrollTop = this.el.nativeElement.querySelector('#tbl').scrollTop
    this.el.nativeElement.querySelector('#thead').style.transform = 'translateY(' + scrollTop + 'px)';
  }
}
