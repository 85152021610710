(function() {
  angular.module('app.query', ['ui.router', 'ngCookies'])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('query', {
        url: '/query',
        template: require('assets/templates/query.html'),
        controller: 'queryCtrl',
        loadingCls: 'orangeloading'
      });
    }])
    .controller('queryCtrl', queryCtrl);

  queryCtrl.$inject = ['$scope']
  function queryCtrl($scope) {}
})();
