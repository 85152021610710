import * as bluebird from 'bluebird'
import { Array, Map } from 'core-js'
import * as _ from 'lodash'

import { BatchApiConfig, PendingRequest } from "./batch-model"

/**
 * 支持把多个请求批量化的API包装
 */
export class BatchApi<TKey extends string | number, TData> {
  private pendingRequests = new Map<TKey, PendingRequest<TData>>()
  private pendingPromises = new Map<TKey, Promise<TData>>()
  private timer

  constructor(private config: BatchApiConfig<TKey, TData>) {
    if (config.wait < 0) {
      throw 'invalid config, wait should be greater than 0'
    }
  }

  public get(k: TKey): Promise<TData> {
    if (this.pendingPromises.get(k)) {
      return this.pendingPromises.get(k)
    }

    const promise = new bluebird((resolve, reject) => {
      const req = {
        resolve,
        reject,
      }
      this.pendingRequests.set(k, req)
    }) as Promise<TData>

    this.pendingPromises.set(k, promise)

    this.checkSchedule()
    return promise
  }

  private checkSchedule() {
    if (this.timer) {
      return
    }

    this.timer = setTimeout(() => {
      this.timer = null
      this.sendBatchRequests()
    }, this.config.wait)

  }

  private sendBatchRequests() {
    if (this.pendingRequests.size >0 ) {
      const requests = this.pendingRequests
      const keys = Array.from(this.pendingRequests.keys())
      this.pendingRequests = new Map()
      this.pendingPromises = new Map()
      bluebird.resolve(this.config.getAll(keys)).then((result) => {
        _.forEach(keys, (k: TKey) => {
          requests.get(k).resolve(_.get(result, k, null))
        })
      }).catch((e) => {
        _.forEach(keys, (k: TKey) => {
          requests.get(k).reject(e)
        })
      })
    } else {
      console.warn('no pending requests!')
    }
  }
}
