import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';
import {OnlineTrainService} from 'app/online-train/online-train.service';

@Component({
  selector: 'app-train-good-course-item',
  template: require('./train-good-course-item.component.html'),
  styles: [require('./train-good-course-item.component.scss')]
})
export class TrainGoodCourseItemComponent implements OnInit {

  @Input() public goodCourse
  @Input() public editable
  @Output() public onEdit = new EventEmitter<any>()
  @Output() public gotoContent = new EventEmitter<any>()
  private imgUrl
  private name
  private itemType
  constructor(private onlineTrainService: OnlineTrainService) { }

  ngOnInit() {
    this.imgUrl = '/ray/resource/view/default_thumb/unknown'
    if (this.goodCourse && this.goodCourse.$$content && !_.isEmpty(this.goodCourse.$$content.$$resource)) {
      this.imgUrl = `/ray/resource/uuid/${this.goodCourse.$$content.$$resource.md5}/img_thumb_animation?size=middle`
    }

    if (this.goodCourse && this.goodCourse.$$content) {
      this.name = this.goodCourse.$$content.name
    }

    this.itemType = this.onlineTrainService.GOOD_COURSE
  }

  public onEditItem(params) {
    this.onEdit.emit(params)
  }

  public goto() {
    this.gotoContent.emit(this.goodCourse)
  }

}
