import { Component, Input, OnInit } from '@angular/core';
import { CreateTeachingModuleDTO, LessonDTO, TeachingModuleDTO, TeachingPatternDTO, TestOrPresentDTO } from 'app/lesson/models/lesson';
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { LessonService } from 'app/lesson/services/lesson.service';
import { TeachingModuleService } from 'app/lesson/services/teaching-module.service';
import { TeachingPatternService } from 'app/lesson/services/teaching-pattern.service';
import * as _ from 'lodash'
import { Subscription } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-pattern-table',
  template: require('./pattern-table.component.html'),
  styles: [require('./pattern-table.component.scss')]
})
export class PatternTableComponent implements OnInit {
  @Input() public courseId: number
  @Input() public sectionId: number
  @Input() public isRecommend: boolean

  private patternDTO: TeachingPatternDTO
  private testDTOs: TestOrPresentDTO[]
  private modules: TeachingModuleDTO[]
  private selectedCourse: LessonDTO

  private subs = new Subscription()
  private subscription: Subscription

  constructor(
    private lessonMessageService: LessonMessageService,
    private patternService: TeachingPatternService,
    private teachingModuleService: TeachingModuleService,
    private lessonService: LessonService,
  ) {
    const createModuleSub = this.lessonService.createModule$.pipe(
      flatMap((createDTO: CreateTeachingModuleDTO) => {
      const newCreateDTO: CreateTeachingModuleDTO = {
        ...createDTO,
        teachingPatternId: this.patternDTO.id,
      }
      return this.lessonService.createTeachingModule(newCreateDTO)
    })).subscribe((tmodule: TeachingModuleDTO) => {
      this.modules = [
        ...this.modules,
        tmodule,
      ]
      this.patternDTO.modules = {
        ...this.modules,
      }
    }, (error) => {
      console.log(error)
    })
    const reloadPatternSub = this.lessonMessageService.reloadPatternAction$.subscribe(() => {
      this.queryTeachingPatternByCourseId()
    })
    this.subs.add(createModuleSub)
    this.subs.add(reloadPatternSub)
   }

  public queryTeachingPatternByCourseId() {
  this.lessonMessageService.sendLoadingAction(true)
  this.subscription = this.patternService.loadPatternDetails(_.toNumber(this.courseId), this.sectionId)
  .subscribe((data: {course: LessonDTO; pattern: TeachingPatternDTO; tests: TestOrPresentDTO[]}) => {
    this.selectedCourse = {
      ...data.course,
    }
    this.patternDTO = {
      ...data.pattern
    }
    this.modules = [
      ...data.pattern.modules,
    ]
    this.testDTOs = [
      ...data.tests,
    ]
    this.lessonMessageService.sendReloadCourseResesAction(this.testDTOs)
    this.lessonMessageService.sendLoadingAction(false)
  },
  (error) => {
    console.error(error)
    this.doWithError()
  },
  () => this.doWithError())
  }

  public ngOnInit() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this.queryTeachingPatternByCourseId()
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  public doWithError() {
    this.lessonMessageService.sendLoadingAction(false)
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  private editModule(tmodule: TeachingModuleDTO) {
    this.teachingModuleService.editModuleInfo(this.isRecommend, tmodule)
    .subscribe((teachingModule: TeachingModuleDTO) => {
      _.remove(this.modules, tmodule)
      tmodule = {
        ...tmodule,
        name: teachingModule.name,
        note: teachingModule.note,
      }
      this.modules = [
        ...this.modules,
        tmodule,
      ]
      this.modules = _.orderBy(this.modules, ['sort'], ['asc'])
    });
  }

  private deleteModule(tmodule: TeachingModuleDTO) {
    this.teachingModuleService.deleteModule(this.isRecommend, tmodule)
  }

}
