require('assets/app/controllers/examination/examReport/examReportHeader/examReportHeader.scss')
require('./headerClassTab/headerClassTabCtrl')

angular.module('app.examination.examReport.header', [
  'app.examination.examReport.header.classTab',
])
  .component('examReportHeader', {
    bindings: {
      sessions: '<',
      selectedSessionId: '<',
      onToggleSession: '&',
      onBack: '&',
      name: '<',
    },
    controller: examReportHeaderCtrl,
    template: < string > require('./examReportHeader.html'),
  })

examReportHeaderCtrl.$inject = []
function examReportHeaderCtrl() {
  const ctrl = this

  ctrl.$onChanges = (changesObj: any) => {
  }
}
