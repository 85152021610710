require('assets/styles/testPaperList.scss');

angular.module('app.examination.testPaperList',[])
.config(['$stateProvider', ($stateProvider) => {
  $stateProvider.state('examination.testPaperList', {
    url: '/:cpId',
    template: require('assets/templates/examination/testPaperList.html'),
    controller: 'testPaperListCtrl',
    bodyStyle: 'bged2'
  });
}]).controller('testPaperListCtrl', testPaperListCtrl);

testPaperListCtrl.$inject = ['$scope', '$state', '$stateParams',
  '$uibModal', '$animate', '$q', 'dialogs', 'localStorageService', 'notify',
  'resize', 'userInfo', 'oedCoursePath', 'oedExamination']
function testPaperListCtrl($scope, $state, $stateParams, $uibModal,
                           $animate, $q, dialogs, localStorageService, notify,
                           resize, userInfo, oedCoursePath, oedExamination) {
  $scope.courses = [];
  $scope.sort = 1; // 最后一个试卷列表的sort
  const cpId = $stateParams.cpId;
  $scope.cpId = cpId;
  $scope.loadComplete = false;
  $scope.showRc = localStorageService.get('showRc');
  $scope.isGrid = true;
  if (cpId) {
    $scope.courses = oedExamination.queryByPath({
      id: cpId
    })
    $scope.isList = localStorageService.get('isList')
    $scope.rcps = []; // 找到的recommend coursepath对应的courses
    $scope.rcs = []; // 选择的某个rcps的courses，当前是找到的第一个courses.length > 0的

    oedCoursePath.get({id: cpId}).$promise.then((c) => {
      $scope.coursePath = c;
    });

    userInfo.then((info) => {
      $scope.canStar = info.canStar;
    });

    $scope.loading = oedExamination.queryByPath({
      id: cpId
    }).$promise.then((courses) => {
      $scope.ensureLastItemVisible();
      focusLastSelectedCourse(true);
      $scope.loadComplete = true;
      if (courses.length >= 1) {
        $scope.sort = courses[courses.length - 1].exam.sort + 1;
        // 将对象属性名称relatedSections替换成sectionName
        $scope.courses = _.forEach(courses, (item) => {
          item.relatedSections = JSON.parse(JSON.stringify(item.relatedSections).replace(/name/g,'sectionName'));
          _.forEach(item.relatedSections, (value) => {
            value.sectionId = value.id;
          });
        });
      }
    });
    localStorageService.set('lastCoursePathId', cpId);
  }
  $scope.viewIndex = 0;

  const boxWidth = 271; // hard code here

  $scope.gettestPaperListStyle = () => {
    let width = _.isEmpty($scope.courses) ? '1200' : (_.size($scope.courses) * boxWidth);
    if (width < 1200) {
      width = 1200;
    }
    return {
      width: '' + width + 'px'
    };
  };

  $scope.getCourseStyle =  () => {
    const offset = '-' + ($scope.viewIndex * boxWidth) + 'px';
    return {
      left: offset
    };
  };

  $scope.computeMaxCourseDisplayCount = () => {
    const width = $('#gundong').width();
    return Math.floor(width / boxWidth);
  };

  $scope.$on('resize', () => {
    $scope.computeMaxCourseDisplayCount();
  });

  $scope.next = () => {
    const maxCount = $scope.computeMaxCourseDisplayCount();
    if ($scope.viewIndex + maxCount >= _.size($scope.courses)){
      return;
    }
    $scope.viewIndex++;
  };

  $scope.ensureLastItemVisible = () => {
    const maxCount = $scope.computeMaxCourseDisplayCount();
    const totalCount = _.size($scope.courses);
    $scope.viewIndex = totalCount - maxCount;
    if ($scope.viewIndex < 0) {
      $scope.viewIndex = 0;
    }
  };

  $scope.prev = () => {
    if ($scope.viewIndex <= 0) {
      return;
    }
    $scope.viewIndex--;
  };
  // 删除试卷
  $scope.deleteTestPaper = (cs) => {
    const dlg = dialogs.confirm('确定删除?', '确定要删除试卷: "' + cs.exam.name + '"吗');
    dlg.result.then((btn) => {
      _.remove($scope.courses, cs);
      $scope.loading = oedExamination.delete({id: cs.exam.id});
    });
  };
  $scope.labelMaxCount = () => {
    const width2 = $('#gundong').width();
    return Math.floor(width2 / 271);
  };
  $scope.editCourse = (cs) => {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/editcourse.html'),
      controller: 'editCourseCtrl',
      size: 'sm',
      resolve: {
        course: () => {
          return cs;
        }
      }
    });

    modalInstance.result.then((r) => {
    });
  };
  $scope.currentPagerIndex = 0;
  $scope.swiper2 = {swiper: {}};
  $scope.showLeftScrollArrow = true;
  $scope.showRightScrollArrow = true;
  // $scope.noNeedDigest = false;
  $scope.swiperParams2 = {
    keyboardControl: true,
    mousewheelControl: true,
    mousewheelForceToAxis: true,
    slidesPerView: 'auto',
    scrollbar: '.course-scroll',
    scrollbarHide: false,
    scrollbarDraggable: true,
    onTransitionStart: () => {
      $scope.popup = 0;
      $scope.currentPagerIndex = $scope.swiper2.swiper.activeIndex;
      setSwiperArrowVisible($scope.currentPagerIndex);
      // if (!$scope.noNeedDigest) {
        // $scope.$digest()
      // };
      // $scope.noNeedDigest = false;
    }
  }

  function setSwiperArrowVisible(pageIndex) {
    if (isNaN(pageIndex)) {
      return
    }
    if (isNaN($scope.indexMax)) {
      $scope.indexMax = _.size($scope.courses) - $scope.labelMaxCount();
    }
    if (pageIndex >= $scope.indexMax) {
      $scope.showRightScrollArrow = false;
    } else {
      $scope.showRightScrollArrow = true;
    }
    if (pageIndex <= 0) {
      $scope.showLeftScrollArrow = false;
    } else {
      $scope.showLeftScrollArrow = true;
    }
  }
  // 向左移动考试列表
  $scope.courseOrderUpperOnce = (course, $index, $event) => {
    $event.stopPropagation();
    $event.preventDefault();
    if ($index <= 0) {
      return
    }
    const newOrders = _.map($scope.courses,  (c, index: number) => {
      if (c.id === course.id) {
        return _.assign(c, {sort: index * 2, id: c.exam.id});
      }
      return _.assign(c, {sort: index * 2 + 3, id: c.exam.id});
    });
    const items = _.map(newOrders, (c) => {
      return _.pick(c, ['id', 'sort']);
    });
    $scope.loading = oedExamination.updateCourseOrder(items).$promise.then(() => {
      $scope.courses = _.sortBy(newOrders, 'sort');
      // $scope.noNeedDigest = true;
      $scope.swiper2.swiper.slidePrev();
      $scope.changingCourseOrder--;
      $scope.onDisplayOrderChange($scope.changingCourseOrder);
    });
  }
  $scope.onChangeOrderToggle = (open) => {
    if (!open) {
      $scope.changingCourseOrder = -1;
    }
  }
  // 向右移动考试列表
  $scope.courseOrderDownOnce = (course, $index, $event) => {
    $event.stopPropagation();
    $event.preventDefault();
    if (($index + 1) >= _.size($scope.courses)) {
      return
    }
    const newOrders = _.map($scope.courses, (c, index: number) => {
      if (c.id === course.id) {
        return _.assign(c, {sort: index * 2 + 3, id: c.exam.id});
      }
      return _.assign(c, {sort: index * 2, id: c.exam.id});
    });
    const items = _.map(newOrders, (c) => {
      return _.pick(c, ['id', 'sort']);
    });
    $scope.loading = oedExamination.updateCourseOrder(items).$promise.then(() => {
      $scope.courses = _.sortBy(newOrders, 'sort');
      // $scope.noNeedDigest = true;
      $scope.swiper2.swiper.slideNext();
      $scope.changingCourseOrder ++;
      $scope.onDisplayOrderChange($scope.changingCourseOrder);
    });
  };
  $scope.cantUpper = false;
  $scope.cantDowner = false;
  $scope.changingCourseOrder = -1;
  $scope.onDisplayOrderChange =  (index) => {
    $scope.changingCourseOrder = index;
    if (index <= 0) {
      $scope.cantUpper = true;
    } else {
      $scope.cantUpper = false;
    }
    if ((index + 1) >= _.size($scope.courses)) {
      $scope.cantDowner = true;
    } else {
      $scope.cantDowner = false;
    }
  };

  function focusLastSelectedCourse(replace) {
    const curCpMap = localStorageService.get('lastCoursePathCourseMap');
    let focusCourseId;
    if (curCpMap) {
      const curCpId = localStorageService.get('lastCoursePathId');
      focusCourseId = curCpMap[curCpId];
    }
    let focusCourse = null;
    if (!_.isEmpty($scope.courses)) {
      focusCourse = focusCourseId ? _.find($scope.courses, (course) => {
        return course.id === focusCourseId;
      }) : $scope.courses[(_.size($scope.courses) - 1)];
      focusCourse = focusCourse || $scope.courses[(_.size($scope.courses) - 1)];
      $scope.setFocusCourse(focusCourse.exam, replace);
    } else {
      $scope.setFocusCourse(focusCourse, replace);
    }
  }

  $scope.setFocusCourse =  (course, replace) =>{
    if ($scope.isDone) {
      return;
    }

    const courseId = course ? course.id : null;

    $scope.viewIndex = _.findIndex($scope.courses, (c: any) => {
      return c.id === courseId;
    });

    ensureLabelVisibiltiy();
    setSwiperArrowVisible($scope.viewIndex);
  };

  $scope.$on('$destroy', () => {
    $scope.isDone = true;
  });

  function ensureLabelVisibiltiy() {
    if ($scope.viewIndex < 0) {
      $scope.viewIndex = (_.size($scope.courses) - 1);
    }

    const labelCount = $scope.computeMaxCourseDisplayCount();
    if ($scope.viewIndex + labelCount >= _.size($scope.courses)) {
      $scope.viewIndex = _.size($scope.courses) - labelCount;
    }
  }

  $scope.slideToLast = () => {
    // $scope.noNeedDigest = true;
    $scope.swiper2.swiper.slidePrev();
  }
  $scope.slideToNext = () => {
    // $scope.noNeedDigest = true;
    $scope.swiper2.swiper.slideNext();
  }

  $scope.toggleRecommendCourse = () => {
    if ($scope.showRc) {
      $scope.showRc = false;
    } else {
      $scope.showRc = true;
    }

    localStorageService.set('showRc', $scope.showRc);
  };
  $scope.inlineClick = () => {
    $scope.isGrid = false;
  }
  $scope.gridClick = () => {
    $scope.isGrid = true;
  }
}
