(function() {
  angular.module('app.directives.studentInfo', [])
    .directive('studentInfo', studentInfo);

  function studentInfo() {
    return {
      restrict: 'EA',
      require: '^studentId',
      scope: {
        studentId: '='
      },
      template: require('assets/templates/directives/studentInfo.html'),
      controller: studentInfoController,
    };
  }
})();

studentInfoController.$inject = ['$scope', 'oedUserInfo']
function studentInfoController($scope, oedUserInfo) {
  $scope.student = oedUserInfo.getByUid({
    id: $scope.studentId
  });

  $scope.getStdAvatarImgSrc = function(std) {
    if ('female' == std.gender) {
      if (std.uid % 2 == 1) {
        return 'Assets/images2/stu_avatar_f1.png';
      } else {
        return 'Assets/images2/stu_avatar_f2.png';
      }
    } else {
      if (std.uid % 2 == 1) {
        return 'Assets/images2/stu_avatar_m1.png';
      } else {
        return 'Assets/images2/stu_avatar_m2.png';
      }
    }
  };
}
