var interact = require('interact.js');
(function() {
  angular.module('app.directives.divKeepRatio', [])
    .directive('divKeepRatio', divKeepRatio);
})();

divKeepRatio.$inject = ['$window', '$timeout']
function divKeepRatio($window, $timeout) {
  function link(scope, element, attrs) {
    //画布为1280 * 752， 也就是16:9
    var perfectScale = 752 / 1280;
    //最左最右离边界20像素
    var marginLeftRight = 20;
    //最上最下离边界10像素
    var marginTopBottom = 10;
    var marginBetween = 5;

    //监听窗口大小，改变时，应该缩放其中所有的图片
    scope.getElementWidth = function() {
      return {'w': element.width()};
    };
    function delayedCenterImage(target) {
      $timeout(function() {
        centerImage(target);
      }, 100);
    }
    scope.$parent.centerImageById = function(imgId) {
      var imgObj = document.getElementById(imgId);
      centerImage(imgObj);
    };
    //将一幅图片div居中放置
    function centerImage(imgContainer) {
      var canvasHeight = scope.currentBoardHeight;
      var canvasWidth = scope.currentBoardWidth;
      var child = $(imgContainer).children('img');
      //如果发生某种情况，图片未能加载，那么返回
      if (child.width() === 0 || child.height() === 0) {
        delayedCenterImage(imgContainer);
        return;
      }
      var x = (canvasWidth - child.width()) / 2;
      var y = (canvasHeight - child.height()) / 2;
      imgContainer.style.webkitTransform = imgContainer.style.transform =
        'translate(' + x  + 'px,' + y + 'px)';
      var isFixedPosition = parseInt(imgContainer.getAttribute('data-is-fix') || 0);
      imgContainer.setAttribute('data-x', x);
      imgContainer.setAttribute('data-y', y);
      imgContainer.setAttribute('data-is-fix', isFixedPosition);
    }

    function logCurrentInfo() {
      _.forEach(scope.imgData, function(data) {
      });
    }

    scope.delayedAutoLayout = function(timeInMillion) {
      $timeout(function() {
        scope.autoLayout();
      }, timeInMillion);
    };
    //缩放图片，适配屏幕大小
    function initialScale(imgContainer, scale) {
      var child = $(imgContainer).children('img');
      var rateX = child.width() / (scope.currentBoardWidth * scale);
      var rateY = child.height() / (scope.currentBoardHeight * scale);
      var rate = rateX > rateY ? rateX : rateY;
      if (rate > 1) {
        child[0].style.width = child.width() / rate + 'px';
        child[0].style.height = child.height() / rate + 'px';
      } else {
        var width = child[0].naturalWidth > scope.currentBoardWidth * scale ? scope.currentBoardWidth * scale :
          child[0].naturalWidth;
        var height = width * child[0].naturalHeight / child[0].naturalWidth;
        if (height > scope.currentBoardHeight * scale) {
          height = scope.currentBoardHeight * scale;
          width = height * child[0].naturalWidth / child[0].naturalHeight;
        }
        child[0].style.width = width + 'px';
        child[0].style.height = height + 'px';
      }
    }
    function loadImageData() {
      //获取所有图片的大小信息
      scope.imgData = [];
      var completedLoad = true;
      $(element).find('img').each(function(index) {
        var thisWidth = $(this).width();
        var thisHeight = $(this).height();
        var originalWidth = $(this)[0].naturalWidth;
        var originalHeight = $(this)[0].naturalHeight;
        if (thisWidth === 0 || thisHeight === 0)
          completedLoad = false;

        //这里需要取得图片的fit size来自动布局
        var width = originalWidth;
        var height = originalHeight;
        if (width > scope.currentBoardWidth) {
          width = scope.currentBoardWidth;
          height = width * originalHeight / originalWidth;
        }
        if (height > scope.currentBoardHeight) {
          height = scope.currentBoardHeight;
          width = height * originalWidth / originalHeight;
        }

        scope.imgData.push({
          width: width,
          height: height,
          origWidth: originalWidth,
          origHeight: originalHeight,
          dom: $(this)[0],
          index: index
        });
      });
      return completedLoad;
    }
    //自动布局
    scope.$parent.autoLayout = function() {
      var divs = $(element).find('div');
      if (divs.length != scope.loadedCount) {
        //说明有图片删除，切ng-repeat尚未完成
        scope.delayedAutoLayout(100);
        return;
      }
      //如果只有一张，那么居中，缩放到屏幕的80%
      if (divs.length == 1) {
        var child = $(divs.get(0)).children('img');
        var width = child.width();
        var height = child.height();
        if (width === 0 || height === 0) {
          scope.delayedAutoLayout(100);
          return;
        }
        initialScale(divs.get(0), scope.maxScaleToBoard);
        centerImage(divs.get(0));
        return;
      }

      if (!loadImageData()) {
        scope.imgData = [];
        scope.delayedAutoLayout(100);
        return;
      }

      scope.totalX = 0;
      scope.totalY = 0;
      scope.maxX = 0;
      scope.maxY = 0;

      _.forEach(scope.imgData, function(data) {
        var currentX = data.width;
        var currentY = data.height;
        scope.totalX += currentX;
        scope.totalY += currentY;
        scope.maxX = (currentX > scope.maxX) ? currentX : scope.maxX;
        scope.maxY = (currentY > scope.maxY) ? currentY : scope.maxY;
      });

      //计算是否需要计算切格子
      var total = _.size(scope.imgData);
      scope.flatMode = null;

      //计算横向平铺能够放下
      if (scope.totalX + (total - 1) * marginBetween <= scope.currentBoardWidth - 2 * marginLeftRight &&
        scope.maxY <= scope.currentBoardHeight - 2 * marginTopBottom)
        scope.flatMode = 'horizontal';

      //计算纵向平铺能够放下
      if (scope.totalY + (total - 1) * marginBetween <= scope.currentBoardHeight - 2 * marginTopBottom &&
        scope.maxX <= scope.currentBoardWidth - 2 * marginLeftRight)
        scope.flatMode = 'vertical';

      scope.bestWidth = scope.currentBoardWidth;
      scope.bestHeight = scope.currentBoardHeight;
      scope.bestColumnNo = 1;
      scope.bestRowNo = 1;
      scope.bestScale = 99999;
      var maxEndX = scope.currentBoardWidth - marginLeftRight * 2;
      var maxHeight = scope.currentBoardHeight - marginTopBottom * 2;
      if (_.isEmpty(scope.flatMode) || scope.flatMode == 'vertical') {
        scope.expectedRate = scope.totalX  / scope.totalY;
        //计算最佳行数
        var smallestRateDifference = 99999;
        for (var i = 1; i <= total; i++) {
          var row = i;
          var column = _.parseInt(Math.ceil(total / row));
          var ceilWidth = (maxEndX - marginBetween * (column - 1)) / column;
          var ceilHeight = (maxHeight - marginBetween * (row - 1)) / row;
          var ceilRate = ceilWidth / ceilHeight;
          var rateDifference = Math.abs(ceilRate - scope.expectedRate);
          if (rateDifference < smallestRateDifference) {
            scope.bestWidth = ceilWidth;
            scope.bestHeight = ceilHeight;
            smallestRateDifference = rateDifference;
            scope.bestColumnNo = column;
            scope.bestRowNo = row;
          }
        }
      } else if (scope.flatMode == 'horizontal') {
        scope.bestRowNo = 1;
        scope.bestColumnNo = total;
        scope.bestWidth = (maxEndX - marginBetween * (scope.bestColumnNo - 1)) / scope.bestColumnNo;
        scope.bestHeight = maxHeight;
      }
      //计算所有图片中缩放比例最大的，按该比例依次缩放所有图片
      _.forEach(scope.imgData, function(data) {
        var ceilWidthScale = scope.bestWidth / data.width;
        var ceilHeightScale = scope.bestHeight / data.height;
        var smallScale = ceilWidthScale < ceilHeightScale ? ceilWidthScale : ceilHeightScale;
        if (smallScale < scope.bestScale) {
          scope.bestScale = smallScale;
        }
      });

      drawImage();
    };

    function drawImage() {
      _.forEach(scope.imgData, function(data) {
        //计算图片所在的slot, row和column从0开始
        var row = _.parseInt(data.index / scope.bestColumnNo);
        var column = data.index % scope.bestColumnNo;

        //计算左上角的坐标
        var x = marginLeftRight + column * (scope.bestWidth + marginBetween);
        var y = marginTopBottom + row * (scope.bestHeight + marginBetween);

        //格子内应该居中
        var fitWidth = data.width * scope.bestScale > data.origWidth ? data.origWidth :
          data.width * scope.bestScale;
        var fitHeight = data.height * scope.bestScale > data.origHeight ? data.origHeight :
          data.height * scope.bestScale;
        x = x + (scope.bestWidth - fitWidth) / 2;
        y = y + (scope.bestHeight - fitHeight) / 2;

        var parent = data.dom.parentNode;
        parent.style.webkitTransform = parent.style.transform =
          'translate(' + x + 'px,' + y + 'px)';
        data.ax = x;
        data.ay = y;
        data.bx = x + fitWidth;
        data.by = y;
        data.cx = x;
        data.cy = y + fitHeight;
        data.zx = x + fitWidth;
        data.zy = y + fitHeight;
        data.dom.style.width  = fitWidth + 'px';
        data.dom.style.height = fitHeight + 'px';
        data.dom.style.maxWidth = scope.currentBoardWidth + 'px';
        data.dom.style.maxHeight = scope.currentBoardHeight + 'px';

        parent.setAttribute('data-x', x);
        parent.setAttribute('data-y', y);
      });
    }
    scope.$parent.restoreLayout = function() {
      var rate = scope.currentBoardWidth / 1280;
      var data = scope.currentTest.question.resourcePosition;
      $(element).find('img').each(function(index) {
        var parent = $(this).parent();
        if (_.isUndefined(data[index].ax)) {
          $(this).on('load', function() {
            $(this)[0].style.width = $(this).width() + 'px';
            $(this)[0].style.height = $(this).height() + 'px';
          });
        } else {
          var x = data[index].ax * rate;
          var y = data[index].ay * rate;
          var width = (data[index].bx - data[index].ax) * rate;
          var height = (data[index].cy - data[index].ay) * rate;
          $(this)[0].style.width = width + 'px';
          $(this)[0].style.height = height + 'px';
          parent[0].style.webkitTransform = parent[0].style.transform =
            'translate(' + x  + 'px,' + y + 'px)';
          parent[0].style.zIndex = data[index].zindex;
          parent[0].setAttribute('data-x', x);
          parent[0].setAttribute('data-y', y);
          parent[0].setAttribute('data-is-fix', data[index].isFixedPosition || 0);
        }
      });
    };
    scope.$parent.setFixedPosition = function(imgId, isFixed) {
      var target = document.getElementById(imgId);
      if (target === null) {
        return;
      }
      target.setAttribute('data-is-fix', isFixed);
      if (isFixed) {
        var minZindex = _.minBy(scope.currentTest.question.resourcePosition, function(r) {
          if (!_.isNumber(r.zindex)) {
            r.zindex = 1;
          } else {
            r.zindex = r.zindex + 1;
          }
          return r.zindex;
        }).zindex;
        scope.$parent.lastActiveId = target.getAttribute('id');
        var index = _.parseInt(scope.$parent.lastActiveId.match(/[0-9]+/g)[0]);
        scope.currentTest.question.resourcePosition[index].zindex = minZindex - 1;
        target.style.zIndex = minZindex - 1;
        scope.updateZIndex();
      }
    };
    scope.$parent.loadImagePosition = function(noSerialize) {
      var rate =  1280 / scope.currentBoardWidth;
      var position = [];
      $(element).find('img').each(function(index) {
        var thisWidth = $(this).width();
        var thisHeight = $(this).height();
        var parent = $(this).parent();
        if (thisWidth === 0) {
          thisWidth = _.parseInt($(this)[0].style.width.match(/[0-9]+/g)[0]);
        }
        if (thisHeight === 0) {
          thisHeight = _.parseInt($(this)[0].style.height.match(/[0-9]+/g)[0]);
        }
        var tx =  parseFloat(parent.attr('data-x'));
        var ty = parseFloat(parent.attr('data-y'));
        var tfix = parseInt(parent.attr('data-is-fix') || 0);
        var precision = 2;
        position.push({
          ax: _.round(tx * rate, precision),
          ay: _.round(ty * rate, precision),
          bx: _.round((tx + thisWidth) * rate, precision),
          by: _.round(ty * rate, precision),
          cx: _.round(tx * rate, precision),
          cy: _.round((ty + thisHeight) * rate, precision),
          zx: _.round((tx + thisWidth) * rate, precision),
          zy: _.round((ty + thisHeight) * rate, precision),
          isFixedPosition: tfix,
          zindex: _.parseInt($(parent)[0].style.zIndex)
        });
      });
      if (noSerialize === true) {
        scope.currentTest.question.resourcePosition = position;
      } else {
        var stringPosition = JSON.stringify(position);
        scope.currentTest.question.resourcePosition = stringPosition;
      }
    };
    //每当有新的图片插入时候，需要缩放其比例到屏幕大小
    scope.$parent.initialScaleById = function(imgId, scale) {
      var target = document.getElementById(imgId).getElementsByTagName('img')[0];
      target.style.maxWidth = scope.currentBoardWidth * scale + 'px';
      target.style.maxHeight = scope.currentBoardHeight * scale + 'px';
    };

    //每当窗口大小变化时，需要重新缩放画布内所有图片
    scope.$watch(scope.getElementWidth, function(newValue, oldValue) {
      var canvasHeight = newValue.w * perfectScale + 'px';
      element.css('height', canvasHeight);
      scope.currentBoardWidth = newValue.w;
      scope.currentBoardHeight = newValue.w * perfectScale;
      //we need to re-scale all the img when window size change
      var imgs = angular.element(element).find('div');
      angular.forEach(imgs, function(img) {
        var imgId = angular.element(img).attr('id');
        var target = document.getElementById(imgId);
        if (target === null) {
          return;
        }
        var ratio = newValue.w / oldValue.w;
        if (_.isNumber(ratio) && ratio > 0) {
          var x = (parseFloat(target.getAttribute('data-x')) || 0) * ratio;
          var y = (parseFloat(target.getAttribute('data-y')) || 0) * ratio;
          var child = $(target).children('img');
          var width = child.width();
          var height = child.height();
          if (width > 0 && height > 0) {
            child[0].style.width  = width * ratio + 'px';
            child[0].style.height = height * ratio + 'px';
          }
          target.style.webkitTransform = target.style.transform =
            'translate(' + x  + 'px,' + y + 'px)';

          target.setAttribute('data-x', x);
          target.setAttribute('data-y', y);
        }
        var isFixed = parseInt(target.getAttribute('data-is-fix') || 0);
        target.setAttribute('data-is-fix', isFixed);
      });
    }, true);

    scope.$parent.enableDrag = function(id) {
      var targetId = scope.boardImagePrefix + id;
      var isFixedPosition = 0;
      if (!_.isEmpty(scope.currentTest.question.resourcePosition) &&
        !_.isEmpty(scope.currentTest.question.resourcePosition[id])) {
        isFixedPosition = scope.currentTest.question.resourcePosition[id].isFixedPosition || 0;
      }
      interact('#' + targetId).unset();
      interact('#' + targetId)
        .draggable({
          // enable inertial throwing
          inertia: false,
          // keep the element within the area of it's parent
          restrict: {
            restriction: 'parent',
            elementRect: {top: 0, left: 0, bottom: 1, right: 1}
          },
          // enable autoScroll
          autoScroll: true,

          // call this function on every dragmove event
          onmove: isFixedPosition === 1 ? null : dragMoveListener,
          // call this function on every dragend event
          onend: function(event) {
          }
        })
        .on('down', function(event) {
          scope.$parent.lastActiveId = event.currentTarget.getAttribute('id');
          if (isFixedPosition === 0) {
            //按下时候，需要将图片放置到最上面，也就是更新zindex
            scope.$parent.currentZindex = scope.$parent.currentZindex + 1;
            var index = _.parseInt(scope.$parent.lastActiveId.match(/[0-9]+/g)[0]);
            scope.currentTest.question.resourcePosition[index].zindex = scope.$parent.currentZindex;
            event.currentTarget.style.zIndex = scope.$parent.currentZindex;
            scope.updateZIndex();
          }
        })
        .on('dragstart', function(event) {
          //取消自动布局
          scope.layoutType.name = 'manual';
          scope.setCanPreview(false);
        })
        .on('dragend', function() {
          scope.enableDrag(id);
        });
    };
    scope.$parent.updateZIndex = function() {
      var copyOfPositions = angular.copy(scope.currentTest.question.resourcePosition);
      _.forIn(copyOfPositions, function(value, key) {
        value.idx = key;
      });
      copyOfPositions = _.orderBy(copyOfPositions, ['zindex'], ['asc']);
      _.forIn(copyOfPositions, function(value, key) {
        value.zindex = _.parseInt(key);
      });
      _.forIn(scope.currentTest.question.resourcePosition, function(value, key) {
        value.zindex = _.find(copyOfPositions, function(p) {
          return p.idx == key;
        }).zindex;
        document.getElementById(scope.boardImagePrefix + key).style.zIndex = value.zindex;
      });
    };
    scope.$parent.enableZoom = function(id) {
      var targetId = scope.imagePrefix + id;
      var isFixedPosition = 0;
      if (!_.isEmpty(scope.currentTest.question.resourcePosition) &&
        !_.isEmpty(scope.currentTest.question.resourcePosition[id])) {
        isFixedPosition = scope.currentTest.question.resourcePosition[id].isFixedPosition || 0;
      }
      var isCanZoom = isFixedPosition === 0;
      interact('#' + targetId).unset();
      interact('#' + targetId)
        .resizable({
          preserveAspectRatio: true,
          edges: {left: isCanZoom, right: isCanZoom, bottom: isCanZoom, top: isCanZoom},
          restrict: {
            endOnly: false
          }
        })
        .on('down', function(event) {
          scope.$parent.lastActiveId = event.target.parentNode.getAttribute('id');
          if (isFixedPosition === 0) {
            //按下时候，需要将图片放置到最上面，也就是更新zindex
            scope.$parent.currentZindex = scope.$parent.currentZindex + 1;
            var index = _.parseInt(scope.$parent.lastActiveId.match(/[0-9]+/g)[0]);
            scope.currentTest.question.resourcePosition[index].zindex = scope.$parent.currentZindex;
            event.target.style.zIndex = scope.$parent.currentZindex;
            scope.updateZIndex();
          }
        })
        .on('tap', function(event) {
          scope.setCanPreview(false);
          scope.$parent.lastActiveId = event.target.parentNode.getAttribute('id');
        })
        .on('doubletap', function(event) {
          scope.setCanPreview(true);
        })
        .on('resizestart', function(event) {
          //取消自动布局
          scope.layoutType.name = 'manual';
          scope.setCanPreview(false);
          var target = event.target;
          target.style.maxWidth = scope.currentBoardWidth + 'px';
          target.style.maxHeight = scope.currentBoardHeight + 'px';
        })
        .on('resizemove', function(event) {
          var target = event.target;
          var parent = target.parentNode;
          var x = (parseFloat(parent.getAttribute('data-x')) || 0);
          var y = (parseFloat(parent.getAttribute('data-y')) || 0);

          // translate when resizing from top or left edges
          x += event.deltaRect.left;
          y += event.deltaRect.top;

          if (x < 0) x = 0;
          if (y < 0) y = 0;
          // update the element's style
          target.style.width  = event.rect.width + 'px';
          target.style.height = event.rect.height + 'px';

          parent.style.webkitTransform = parent.style.transform =
            'translate(' + x + 'px,' + y + 'px)';
          parent.setAttribute('data-x', x);
          parent.setAttribute('data-y', y);
        })
        .on('resizeend', function(event) {
          //如果图片大小超出了画布，需要重新计算图片的大小
          var target = event.target;
          keepImageRatio(target);
          limitCanvas(target);
        });
    };

    //保持图片在画布中
    function limitCanvas(target) {
      var parent = target.parentNode;
      var x = (parseFloat(parent.getAttribute('data-x')) || 0);
      var y = (parseFloat(parent.getAttribute('data-y')) || 0);
      var width = target.clientWidth;
      var height = target.clientHeight;
      if (x + width > scope.currentBoardWidth) {
        x = scope.currentBoardWidth - width;
      }
      if (y + height > scope.currentBoardHeight) {
        y = scope.currentBoardHeight - height;
      }
      parent.style.webkitTransform =
        parent.style.transform =
          'translate(' + x + 'px, ' + y + 'px)';
      parent.setAttribute('data-x', x);
      parent.setAttribute('data-y', y);
    }
    //保持图片比例
    function keepImageRatio(target) {
      var naturalRatio = target.naturalWidth / target.naturalHeight;
      var width = target.clientWidth;
      var height = target.clientHeight;
      var newWidth = width;
      var newHeight = width / naturalRatio;
      if (newHeight > height) {
        newWidth = height * naturalRatio;
      }
      //最小50像素
      if (newWidth < 50)
        newWidth = 50;
      newHeight = newWidth / naturalRatio;
      target.style.width  = newWidth + 'px';
      target.style.height = newHeight + 'px';
    }
    //每当图层被拖动的时候，需要同时更新他的tansform
    function dragMoveListener(event) {
      var target = event.target;
      // keep the dragged position in the data-x/data-y attributes
      var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
      var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;
      // translate the element
      target.style.webkitTransform =
        target.style.transform =
          'translate(' + x + 'px, ' + y + 'px)';

      // update the posiion attributes
      target.setAttribute('data-x', x);
      target.setAttribute('data-y', y);
    }
  }

  return {
    restrict: 'AE',
    scope: false,
    link: link
  };
}

