import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import * as _ from 'lodash'
import { CoursePathDTO, SectionDTO, SelectedLessonDTO, LessonDTO } from '../models/lesson'
import { LegacyAppService } from 'app/core/legacy-app.service';
import { StateService } from '@uirouter/core'

/**
 * 这里是章节
 */
@Component({
  selector: 'oed-section-card',
  template: require('./section-card.component.html'),
  styles: [require('./section-card.component.scss')]
})
export class SectionCardComponent implements OnInit {

  @Output() public onCourseSelected = new EventEmitter<SelectedLessonDTO>();
  @Input() public section: SectionDTO;
  @Input() public coursepathId: number;
  @Input() public index: number;
  @Input() public courseList: LessonDTO[];
  private createDate: number;

  constructor(
    private legacyApp: LegacyAppService,
    private state: StateService,
  ) { }
  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {

  }

  public onSelect() {
    this.onCourseSelected.emit({ id: this.section.id })
    this.legacyApp.getLocalStorage().set('scrollToSectionId', this.section.id)
    if (_.get(this.section, 'itemType', 0) < 1) {
      this.state.go('lessonDesign', {sectionId: this.section.id, coursepathId:  this.coursepathId});
    } else { // 这是考试章节 要跳转到智能组卷
      this.state.go('sectionExams', {sectionId: this.section.id, coursepathId:  this.coursepathId, sectionName: this.section.name});
    }
  }
}
