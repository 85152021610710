import * as angular from 'angular';
require('./objectiveDialog.scss')

angular.module('app.newPrepareCourse2.objectiveDialog',
  []).controller('objectiveDialogCtrl', objectiveDialogCtrl)

objectiveDialogCtrl.$inject = [
  '$scope',
  '$q',
  '$uibModalInstance',
  'data',
  '$uibModal'
]
function objectiveDialogCtrl(
  $scope,
  $q,
  $uibModalInstance,
  data,
  $uibModal
) {
  $scope.close = () => {
    $uibModalInstance.close({
    });
  };
  $scope.showRules = (bool) => {
    event.preventDefault();
    event.stopPropagation();
    const modalInstance1 = $uibModal.open({
      template: require('assets/templates/prepareCourse/wordUploaderRulesDialog.html'),
      controller: 'wordUploaderRulesCtrl',
      size: 'md',
      resolve: {
        data: () => {
          return {
            isDaoxuean: bool,
            isFromNewPrepare: true
          }
        }
      },
    });
  }
}
