import * as _ from 'lodash'
require('assets/app/controllers/recycleBinDialog/reExamCard/reExamCard.scss');

angular.module('app.recycle.reExamCard', [])
  .component('reExamCard', {
    bindings: {
      data: '<',
      onRecoveryExam: '&',
      onDeleteExam: '&',
    },
    controller: reExamCardCtrl,
    template: < string > require('./reExamCard.html'),
  })

reExamCardCtrl.$inject = []
function reExamCardCtrl() {}
