import { Component, OnInit, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-preview-papers-list',
  template: require('./preview-papers-list.component.html'),
  styles: [require('./preview-papers-list.component.scss')]
})
export class PreviewPapersListComponent implements OnInit {
  @Input() exerciseBookId: any;
  @Input() paperList: any;
  @Input() inLibary: boolean;
  @Input() resboxHolder: any;
  @Input() testSizeInLibary: any;
  @Output() onDeleteQuestion = new EventEmitter();
  @Output() onAddToResBox = new EventEmitter();
  @Output() onDeleteTest = new EventEmitter();
  @Output() onAddTestToResBox = new EventEmitter();
  @Output() pageChangedInLibary = new EventEmitter();
  currentPaperList: any
  // 总数
  totalItems: number;
  // 每页节点数
  countPerPage: any = 50;
  // 最大页数
  maxSize: any = 10;
  // 当前页码
  currentPage: any = 1;

  constructor(
    private oedDialogService: OedDialogService,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'paperList')) {
      if (this.inLibary) {
        this.totalItems = this.testSizeInLibary
        this.currentPaperList = this.paperList
      } else {
        this.totalItems = _.size(this.paperList);
        this.getCurrentPaperList();
      }

    }
  }

  public showAllQuestions(item) {
    console.log('yaya')
    this.oedDialogService.openModalWithComponent2('TestPaperContentComponent', {
      testPaper: item,
      inLibary: this.inLibary,
      resboxHolder: this.resboxHolder
    }, {
      class: 'ex-dialog-common2 almost-full-screen',
    }).subscribe(res => {
      if (res.type === 'addQues') this.addCloudQuestion(res.params.q, res.params.$event)
      if (res.type === 'delQues') this.doDeleteQuestion(res.params)
    })
  }

  public pageChanged(event) {
    if (this.inLibary) {
      this.pageChangedInLibary.emit()
      return
    }
    this.currentPage = event.page;
    this.getCurrentPaperList();
  }

  public getCurrentPaperList() {
    this.currentPaperList = _.slice(this.paperList, (this.currentPage - 1) * this.countPerPage, this.countPerPage * this.currentPage);
  }

  private addCloudQuestion = (q: any, $event: any) => this.onAddToResBox.emit({ $event, q })
  private doDeleteQuestion = (q) => this.onDeleteQuestion.emit(q)
  private doAddTestToResBox = (params) => this.onAddTestToResBox.emit(params)
  private doDeleteTest = (t) => this.onDeleteTest.emit(t)
}
