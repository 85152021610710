/**
 * @fileOverview
 * @name studentSubjectiveTestPeerReviewAllStudentsCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentSubjectiveTestPeerReview.student', {
        url: '/student/:studentId',
        template: require('assets/templates/student/studentSubjectiveTestPeerReviewStudent.html'),
        controller: 'studentSubjectiveTestPeerReviewlStudentCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('studentSubjectiveTestPeerReviewlStudentCtrl', studentSubjectiveTestPeerReviewlStudentCtrl);

  studentSubjectiveTestPeerReviewlStudentCtrl.$inject = ['$scope', '$state', '$stateParams',
    '$q', 'dialogs', 'notify', 'Lightbox', 'userInfo', 'oedConfig', 'oedCoursePath', 'oedTaskList',
    'oedTask', 'oedTaskListPublishRecord', 'oedTaskTest', 'oedSubjectiveTest', 'oedTestSession',
    'oedTestSessionStudent', 'oedBoardSession', 'oedBoardContent', 'oedBoardContentComment',
    'oedSubjectiveTestSessionStats', 'oedUserInfo', 'oedBoardSessionStudent', 'oedUserInfoUtils', 'notificationService']
  function studentSubjectiveTestPeerReviewlStudentCtrl($scope, $state, $stateParams,
    $q, dialogs, notify, Lightbox, userInfo, oedConfig, oedCoursePath, oedTaskList,
    oedTask, oedTaskListPublishRecord, oedTaskTest, oedSubjectiveTest, oedTestSession,
    oedTestSessionStudent, oedBoardSession, oedBoardContent, oedBoardContentComment,
    oedSubjectiveTestSessionStats, oedUserInfo, oedBoardSessionStudent, oedUserInfoUtils, notificationService) {
    //
    // 注意这个controller也被教师端的view使用

    $scope.studentId = _.parseInt($stateParams.studentId);

    $scope.answer = {
      commentContent: {}
    };

    function readContent(content) {
      var c = _.omit(content, 'contentBody');
      var body = {};
      if (content.contentBody) {
        body = angular.fromJson(content.contentBody);
      }
      c.body = body;

      var resItems = [];

      if (c.body.content) {
        resItems = [{
          fileType: 'text',
          text: c.body.content,
          textTrimed: _.truncate(c.body.content, {
            length: 30
          })
        }];
      }

      if (c.boardContentThumbnail) {
        resItems = resItems.concat({
          fileType: 'boardContent',
          id: c.id
        });
      }

      if (_.size(body.resources) > 0) {
        resItems = resItems.concat(body.resources);
      }

      c.resItems = resItems;
      return c;
    }

    function readComment(cmt, user) {
      var c = _.omit(cmt, 'commentBody');

      c.left = user;
      var body = {};
      if (cmt.commentBody) {
        body = angular.fromJson(cmt.commentBody);
      }
      c.body = body;

      c.body.resources = c.body.resources || [];
      // pad端添加的文字评论
      if (cmt.comment) {
        c.body.content = cmt.comment;
      }

      // pad端添加的图片评论
      if (cmt.imgResId) {
        c.body.resources = c.body.resources.concat({
          fileType: 'boardComment',
          id: cmt.imgResId
        });
      }

      if (!_.has($scope.userInfoMap, cmt.userId)) {
        $scope.userInfoMap[cmt.userId] = oedUserInfo.getByUid({
          id: cmt.userId
        });
      }

      return c;
    }

    function updateStats() {
      $scope.stats = oedSubjectiveTestSessionStats.queryBySessionAndUserId({
        boardSessionId: $scope.boardSessionId,
        userId: $scope.studentId,
        includeBoardContent: false,
        includeBoardThumbnail: true,
        includeViewedBoards: false,
        includeBravoedBoards: false,
        includeCommentedBoards: false,
        includeBoardViews: true,
        includeBoardBravos: true,
        includeBoardComments: true,
        sortBy: 'bravosCount'
      });

      return $scope.stats.$promise.then(function(stats) {
        var contents = _.get(stats, 'board.contentList', []);
        if (!_.isEmpty(contents)) {
          $scope.isDetailsCollapsed = true;
        }
        $scope.boardContents = _.map(contents, readContent);
        $scope.boardComments = _.map(_.get(stats, 'board.commentsList', []),
        c => readComment(c.right, c.left)).reverse();
        $scope.sortCommentList();
      });
    }
    $scope.sortCommentList = function() {
      const commentsList = $scope.boardComments;
      const teacherComments = _.filter(commentsList, isTeacherComment);
      _.remove(commentsList, isTeacherComment);
      $scope.boardComments = _.concat(teacherComments, commentsList);
    };

    function isTeacherComment(comment) {
      return comment.left.roles === 'teacher';
    }
    $scope.isTeacherComment = function(comment) {
      return isTeacherComment(comment);
    };

    /**
    var boardContents = oedBoardContent.queryByBoardSessionAndStudentId({
      sessionId: $scope.boardSessionId,
      studentId: $scope.studentId
    }).$promise.then(function(contents) {
      if (!_.isEmpty(contents)) {
        $scope.isDetailsCollapsed = true;
      }
      $scope.boardContents = _.map(contents, readContent);
    });

    var boardComments = oedBoardContentComment.queryByBoardSessionAndStudentId({
      sessionId: $scope.boardSessionId,
      studentId: $scope.studentId
    }).$promise.then(function(comments) {
      $scope.boardComments = _.map(comments, readComment).reverse();
    });
     */

    $scope.boardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
      sessionId: $scope.boardSessionId,
      studentId: $scope.studentId
    });

    function getAllContentRes() {
      var allContents = _.reduce($scope.boardContents, function(total, c) {
        return total.concat(c.resItems);
      }, []);

      return allContents;
    }

    $scope.openAddCommentPanel = function() {
      $scope.showAddAnswerPane = false;
      $scope.showAddCommentPane = true;
    };

    $scope.cancelEditor = function() {
      $scope.showAddAnswerPane = false;
      $scope.showAddCommentPane = false;
    };

    $scope.submitComment = function(comment) {
      if (_.isEmpty($scope.boardContents)) {
        return notificationService.notify('error', '还没有提交作品, 不能添加评论')
      }

      var c = _.pick(comment, ['content', 'resources']);
      if (_.isEmpty(c.content) && _.isEmpty(c.resources)) {
        return notificationService.notify('error', '请填写评论内容')
      }

      var body = angular.toJson(c);

      var cmt = {
        boardContentId: $scope.boardContents[0].id,
        userId: $scope.uid,
        commentBody: body,
        createTime: moment().valueOf()
      };

      var cmtToAdd = oedBoardContentComment.save(cmt);
      $scope.loading = cmtToAdd.$promise.then(function(cmt) {
        $scope.answer.commentContent = {};
        $scope.showAddCommentPane = false;
        $scope.boardComments.unshift(readComment(cmt, userInfo));

        $scope.boardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
          sessionId: $scope.boardSessionId,
          studentId: $scope.studentId
        });

        return $scope.boardStudent.$promise.then(updateStats);
      });
    };

    userInfo.then(function(info) {
      $scope.uid = _.parseInt(info.uid);
      if (!_.has($scope.userInfoMap, $scope.uid)) {
        $scope.userInfoMap[$scope.uid] = info;
      }

      $scope.isTeacher = function() {
        return _.includes(info.roles, 'teacher');
      };

      $scope.toggleBravo = function() {
        $scope.loading = oedBoardContentComment.toggleBravo({
          boardSessionId: $scope.boardSessionId,
          ownerId: $scope.studentId,
          userId: $scope.uid
        }, {}).$promise.then(updateStats);
      };

      $scope.isSelfBravoed = function() {
        if (!$scope.stats.board) {
          return false;
        }

        return _.findIndex($scope.stats.board.bravosList, function(bravo) {
          return bravo.key.uid == $scope.uid;
        }) >= 0;
      };

      $scope.previewContentResource = function(curRes) {
        var allRes = getAllContentRes();
        var idx = _.findIndex(allRes, curRes);

        oedBoardContentComment.markReadBoardContent({
          boardContentId: $scope.boardContents[0].id,
          userId: $scope.uid
        }, {}).$promise.then(updateStats);

        Lightbox.openModal(allRes, idx);
      };
    });

    $scope.submitCommentTeacher = function(comment) {
      if (_.isEmpty($scope.boardContents)) {
        return notificationService.notify('error', '该学生还没有提交作品, 不能添加评论')
      }

      var c = _.pick(comment, ['content', 'resources']);
      if (_.isEmpty(c.content) && _.isEmpty(c.resources)) {
        c = undefined;
      }

      if (_.isUndefined(comment.rank) && _.isUndefined(c)) {
        return notificationService.notify('error', '请填写评论内容')
      }

      c = c || {};
      c.rank = comment.rank;

      var body = angular.toJson(c);

      var cmt = {
        rank: comment.rank,
        comment: {
          boardContentId: $scope.boardContents[0].id,
          userId: $scope.uid,
          commentBody: body,
          createTime: moment().valueOf()
        }
      };

      var cmtToAdd = oedBoardContentComment.createCommentWithRank(cmt);
      $scope.loading = cmtToAdd.$promise.then(function(cmt) {
        $scope.answer.commentContent = {};
        $scope.showAddCommentPane = false;
        $scope.boardComments.unshift(readComment(cmt.comment, userInfo));

        $scope.boardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
          sessionId: $scope.boardSessionId,
          studentId: $scope.studentId
        });

        return $scope.boardStudent.$promise.then(updateStats);
      });
    };

    var loadStats = updateStats();

    $scope.loading = $q.all([loadStats, $scope.boardStudent.$promise]);
  }
})();
