(function() {
  angular.module('app.signUp', ['ui.router', 'ngCookies', 'timer'])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('signup', {
        url: '/signup',
        template: require('assets/templates/signup.html'),
        controller: 'signupCtrl',
        bodyStyle: 'newstyle'
      });
    }])
    .controller('signupCtrl', signupCtrl);

  signupCtrl.$inject = ['$scope', '$http', '$cookies', '$location', '$state', 'dialogs',
    '$uibModal', 'oedUserInfo', 'notificationService', 'oedAccountUtils']
  function signupCtrl($scope, $http, $cookies, $location, $state, dialogs,
   $uibModal, oedUserInfo, notificationService, oedAccountUtils) {
    $scope.info = {
      gender: 'male'
    };
    $scope.type = {
      name: 'mobile'
    };
    $scope.setGender = function(val) {
      $scope.info.gender = val;
    };

    $scope.enableCountDown = false;

    $scope.$on('timer-stopped', function(event, data) {
      $scope.$apply(function() {
        $scope.enableCountDown = false;
        $scope.timerRunning = false;
      });
    });

    $scope.validateMobile = function(mobile) {
      var reMobile = /^\d{11}$/;
      return mobile && mobile.match(reMobile);
    };

    $scope.validateId = oedAccountUtils.validateIdNo;

    $scope.doSignup = function() {
      $scope.showValidationError = true;

      if ($scope.signupForm.$invalid) {
        return;
      }

      var info = _.assign({}, $scope.info);
      info.classCodes = _.map(info.classCodes, function(code) {
        return code.code;
      });
      info.type = $scope.type.name;

      var signup = oedUserInfo.signup(info);
      $scope.loading = signup.$promise.then(function(resp) {
        if (!resp.success) {
          $scope.errorMessage = resp.message;
          return resp;
        }
        $scope.signupResp = resp;
        var msg = $(resp.message);
        //
        // 检查有没有设置ucenter同步登录的html片段, 如果有的话访问一下此url
        // 注意: 此处假设ucenter和oedweb在同一domain下,
        // 如果不在同一domain,需要做跨域处理
        if (!_.isEmpty(msg.attr('src'))) {
          $http.get(msg.attr('src'));
        }

        //dialogs.notify('注册成功', '注册成功, 你的系统ID号是: ' + resp.user.uid + ', 你可以用此ID登录').result.finally(function() {
        //
        // no school
        if ($scope.type.name === 'mobile') {
          if (resp.user.schoolId < 0) {
            $state.go('joinSchool');
          } else {
            $state.go('home');
          }
        } else {
          $scope.mailSent = true;
        }

        //});
        return resp;
      }).catch(function() {
        $scope.errorMessage = '注册失败, 发生未知错误';
      });
    };

    $scope.getVerifyCode = function() {
      if (_.isEmpty($scope.info.mobile)) {
        return notificationService.notify('error', '请先填写表单中的手机号')
      }

      var signup = oedUserInfo.signupVerifyCode({
        mobile: $scope.info.mobile,
        type: 'register'
      });

      $scope.loading = signup.$promise.then(function(resp) {
        if (resp.smsErrCode == -2) {
          notificationService.notify('error', '请求过于频繁，请稍后再试')
        } else if (resp.smsErrCode == -1) {
          notificationService.notify('error', '短信发送失败，请稍后重试')
        } else if (resp.smsErrCode == -3) {
          notificationService.notify('error', '该手机号已经注册，请直接登录')
        } else {
          notificationService.notify('info', '短信发送成功，请继续')
          if ((!$scope.timerRunning)) {
            $scope.$broadcast('timer-reset');
          }

          $scope.$broadcast('timer-start');
          $scope.timerRunning = true;
          $scope.enableCountDown = true;
        }
      });
      /**
      var modalInstance = $uibModal.open({
        template: require('assets/templates/getVerifyCode.html'),
        controller: 'getVerifyCodeCtrl',
        size: 'sm',
        windowClass: 'signupModal',
        resolve: {
          data: function() {
            return {
              type: 'register',
              mobile: $scope.info.mobile
            };
          }
        }
      });

      modalInstance.result.then(function(result) {
        if (result) {
          if ((!$scope.timerRunning)) {
            $scope.$broadcast('timer-reset');
          }

          $scope.$broadcast('timer-start');
          $scope.timerRunning = true;
          $scope.enableCountDown = true;
        }
      });**/
    };

    $scope.reSendEmail = function() {
      var sendEmail = oedUserInfo.sendSignupEmail({
        id: $scope.signupResp.user.uid
      });

      $scope.loading = sendEmail.$promise.then(function(resp) {
        if (!resp.success) {
          notificationService.notify('error', '邮件发送失败')
        } else {
          notificationService.notify('info', '邮件发送成功')
        }
      });

    };
  }
})();
