/**
 * @fileOverview
 * @name reviewSubjectiveTestPeerReviewCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.reviewSubjectiveTestPeerReview', {
        url: '/reviewSubjectiveTestPeerReview/record/:recordId/boardSession/:boardSessionId/from/:fromStudentId',
        template: require('assets/templates/homework/reviewSubjectiveTestPeerReview.html'),
        controller: 'studentSubjectiveTestPeerReviewCtrl',
        bodyStyle: 'bg_img'
      });
    }]);
})();
