/**
 * Created by ryoushikaoru on 2019/3/5.
 */
import * as _ from 'lodash'
const {doImportQuestions} = require('app2/utils/testUtils2');
import moment = require('moment');
require('./objectiveTestDesign.scss')

objectiveTestDesignCtrl.$inject = [
    'notify',
    'oedCloudQuestion',
    'talcloudResource',
    'oedObjectiveQuestion',
    'oedObjectiveTest',
    'userInfo',
    '$uibModal',
    'oedCourse',
    'oedCloudTest',
    '$q',
    'oedUnitItem',
    'oedMisc',
    'cloudQuestionService',
    'notificationService'
]

function objectiveTestDesignCtrl(
    notify,
    oedCloudQuestion,
    talcloudResource,
    oedObjectiveQuestion,
    oedObjectiveTest,
    userInfo,
    $uibModal,
    oedCourse,
    oedCloudTest,
    $q,
    oedUnitItem,
    oedMisc,
    cloudQuestionService,
    notificationService
  ) {
    this.status = {}; // loading
    this.importedTests = []
    this.$onChanges = (changesObj: any) => {
        if (_.has(changesObj, 'resolve')) {
            this.testId = this.resolve.data.testId
            this.courseId = this.resolve.data.courseId
            this.moduleId = this.resolve.data.moduleId
            this.moduleName = this.resolve.data.moduleName
            if (Number(this.courseId)) {
                this.tests = oedObjectiveTest.queryByCourseIncEmpty({
                    course_id: this.courseId
                });
                this.courseSections = oedUnitItem.queryAvailableBySchoolAndCourse({
                    schoolId: 0,
                    courseId: this.courseId
                });
                this.sectionIdMap = {};
                this.currentState.loading = this.courseSections.$promise.then((secs) => {
                    const sectionIdMap = {};

                    function visitSection(sec) {
                        if (sec.id) {
                            sectionIdMap[sec.id] = sec
                        }
                        if (sec.child) {
                            sec.child = _.sortBy(sec.child, ['sort']);
                            _.each(sec.child, visitSection);
                        }
                    }
                    _.each(secs, visitSection);

                    this.sectionIdMap = sectionIdMap;
                    this.treeData = oedMisc.utils.convertSectionsToJsTreeModel(sectionIdMap, secs);
                    _.each(this.treeData, (data) => {
                        data.state.opened = true;
                    });
                });
            }
        }
    };
    this.currentState = {
        loading: null
    };
    this.reloadCurrentTest = (id) => {
        this.currentTest = oedObjectiveTest.get({id});
        this.currentTest.$promise.then((test) => {
            this.courseId = test.courseId;
            this.course = oedCourse.get({id: this.courseId});
        });
        const loadTesDetails = this.currentTest.$promise.then((test) => this.loadTestCloudQuestions(test.questions));
        this.cloudTests = oedCloudTest.queryByTestIds({
            testIds: [id]
        });
        this.firstVisibleQuesIndex = 0;
        this.pageSize = 50;
        this.showPaging = () => {
            return _.size(this.currentTest.questions) > this.pageSize;
        };
    };
    this.loadTestCloudQuestions = (questions) => {
        const ids = _(questions).map('id').filter().value();
        this.cloudQuestions = {};

        if (_.isEmpty(ids)) {
            return $q.resolve({});
        }

        return oedCloudQuestion.queryByQuestionIds({ids})
            .$promise.then((cqs) => {
                this.cloudQuestions = _.keyBy(cqs, 'resourceId');
                return this.cloudQuestions;
            });
    }
    // 资源库导入
    this.importFromLibrary2 = () => {
        const modalInstance = $uibModal.open({
            template: require('./../cloud/objectiveTestLibrary.html'),
            controller: 'objectiveTestLibraryCtrl',
            size: 'full-screen',
            windowClass: 'shouldHideOnPreview cl-library-dialog2',
            resolve: {
                data: () => ({
                    course: this.course,
                    doImport: this.importQuestions,
                })
            },
        });

        modalInstance.result.finally(() => {
            // this.resume()
        })
    };
    this.importQuestions = (questions, deferred) => {
        return doImportQuestions(questions, deferred, {
            notify,
            oedCloudQuestion,
            talcloudResource,
            oedObjectiveQuestion,
            oedObjectiveTest,
            userInfo,
            cloudQuestionService,
            questionOp: {
                get: () => this.currentTest.questions,
                set: (qs) => this.currentTest.questions = qs
            },
            relatedSections: this.course.relatedSections,
            state: this.currentState,
            notificationService,
        }).then((quesToAdd) => {
            if (!_.isEmpty(quesToAdd)) {
                this.currentTest.isSameAsOrig = false;
                this.lastChange = moment();
            }
            return this.saveTest(true);
        }).then((r) => deferred.resolve(r))
            .catch((e) => deferred.reject(e))
    };
    // 更新当前试卷
    this.updateTest = (t, test) => {
        if (t) {
            this.reloadCurrentTest(t.id);
        }
        this.tests = test;
    };
    // 保存当前试卷
    this.saveTest = (noAlert) => {
        // 点保存按钮时有可能要保存正在编辑的问题
        // 这种情况下应当等问题保存完毕后才保存整个试卷
        this.currentState.loading = $q.when(this.currentState.loading).then(() => {
            const hasNewQuestion = _.findIndex(this.currentTest.questions, (q) => {
                    return !_.get(q, 'id');
                }) >= 0;
            let save = null;
            //
            // 当有新问题时保存需要更新测试的Model(因为会同时创建问题)
            if (hasNewQuestion) {
                save = this.currentTest.$save().then((t) => {
                    return this.loadTestCloudQuestions(t.questions).then(() => t);
                });
            } else {
                save = oedObjectiveTest.save(angular.copy(this.currentTest)).$promise;
            }
            return save.then((test) => {
                if (!noAlert) {
                  notificationService.notify('info', '试卷保存成功')
                }
            }).catch((error) => {
                const errCode = _.get(error, 'status', 500);
                if (errCode === 401 || errCode === 403) {
                  return notificationService.notify('error', '您的登录状态已改变，请重新登录!')
                }
                notificationService.notify('error', '试卷保存失败，请联系锐学堂技术支持!')
            });
        });
    };
    // 设置当前试卷
    this.setCurrentTest = (t, test) => {
        if (this.currentTest && t && this.currentTest.id === t.id) {
            return;
        }

        this.currentTest = t;
        this.updateTest(t, test);
    };
    // 关闭弹框
    this.cancel = () => {
      this.doClose()
    };

  this.doClose = () => {
    this.close({
      $value: {
        importedTests: this.importedTests,
      }
    })
  }

  this.onImportTestChange = (tests: number[]) => {
    this.importedTests = tests
  }
}


export const objectiveTestDesign =  {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
    controller: objectiveTestDesignCtrl,
    template: <string>require('./objectiveTestDesign.html'),
}

angular.module('app.prepareCourse2.objectiveTestDesign', [])
    .component('objectiveTestDesign', objectiveTestDesign);
