(function() {
    require('assets/styles/courseList.scss');
    angular.module('app.prepareLesson').config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareLesson.newCourseList', {
        url: '/:cpId',
        template: require('assets/templates/newCourseList.html'),
        controller: 'newCourseListCtrl',
        bodyStyle: 'bged2'
      });
    }]).controller('newCourseListCtrl', newCourseListCtrl)
      .controller('createCourseCpCtrl', createCourseCpCtrl);
    newCourseListCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal',
      '$animate', '$q', 'dialogs', 'localStorageService', 'notificationService',
      'resize', 'userInfo', 'oedCoursePath', 'oedRecommendCourse',
      'oedCourse', 'oedSchoolTerm','oedUnitItem','oedUserInfo', 'oedExamination']
    function newCourseListCtrl($scope, $state, $stateParams, $uibModal,
      $animate, $q, dialogs, localStorageService, notificationService,
      resize, userInfo, oedCoursePath, oedRecommendCourse,
      oedCourse, oedSchoolTerm, oedUnitItem,oedUserInfo, oedExamination) {
      $scope.courses = [];
      $scope.cloneData = [];
      $scope.zlCardData = [];
      $scope.recommendCardData = [];
      $scope.selectedSection = '';
      $scope.selectedSecRootPid = 0;
      $scope.needLeftNum = 0;//右侧偏移量
      $scope.defaultSelect = true;
      $scope.sections = [];//左侧树列表
      $scope.name = '';//新建课程名字
      var cpId = $stateParams.cpId;
      $scope.cpId = cpId;
      $scope.loadComplete = false;
      $scope.tempData = [];
      $scope.showRc = localStorageService.get('showRc');
      $scope.noCp = true;
      $scope.defaultWatchStatus = true;
      $scope.defaultWatchText = '显示全部';
      $scope.isDispayText = false;
      $scope.sectionMap = {}

      function buildSectionMap(child) {
        for (let c of child) {
          if (c.child) {
            buildSectionMap(c.child)
          }
          if ($scope.sectionMap[c.id]) {
            continue
          }
          // 这里假设parentId 也是非法的
          if (!c.parentId) {
            continue
          }
          $scope.sectionMap[c.id] = c.parentId
        }
      }

      function findRootPid(id) {
        if ($scope.sectionMap[id]) {
          return findRootPid($scope.sectionMap[id])
        } else {
          return id
        }
      }

      function loadData(cpId) {
        $scope.cpId = cpId;
        $scope.courses = oedCourse.queryByPath({
          pathId: cpId
        });
        oedCoursePath.get({id: cpId}).$promise.then(function(c) {
          $scope.coursePath = c;
        });
        userInfo.then(function(info) {
          $scope.uid = _.parseInt(info.uid);
          $scope.coursePathList = oedCoursePath.queryFilter({
            userId: _.parseInt(info.uid),
            onGoing: true,
            unStarted: true, //false, // TODO: 处理归档
            ended: true
          });
          $scope.canStar = info.canStar;
            const promise  =  oedUnitItem.queryAvailableBySchoolAndCoursePath({
              schoolId: info.schoolId,
              coursePathId: cpId
            }).$promise;
            $q.all([promise,$scope.courses.$promise]).then(data=>{
              buildSectionMap(data[0][0].child)
              $scope.tempData = data[0][0].child;
              $scope.sections = handleData(data[0]);
              return $scope.sections;
            });
        });
        localStorageService.set('lastCoursePathId', cpId);
      }

      function loadExamCardList(cpId) {
        $scope.coursepathId = cpId
        const sectionId = _.get($scope.selectedSection, 'id', 0)
        $scope.isExamSection = _.get($scope.selectedSection, 'itemType', 0) == 1
        // $scope.isExamSection = true
        if (!$scope.isExamSection) {
          return
        }
        oedExamination.getExamSections({ sectionId }).$promise
          .then((res) => {
            $scope.examCardDatas = res
          })
      }

      if (cpId) {
        $scope.noCp = false;
        loadData(cpId);
      }else{
        var cp = {};
        var dlg = $uibModal.open({
          template: require('assets/templates/manage/createCourseDialog.html'),
          controller: 'createCourseCpCtrl',
          size: 'md',
          windowClass: 'signupModal',
          resolve: {
            coursePath: function() {
              return cp;
            },
            existing: function() {
              return null;
            },
            copy: null,
            createCp: true,
            newPreCp: null
          }
        });
        dlg.result.then(function(data) {
          $scope.noCp = false;
          loadData(data.cp.id);
        });
      }
      //get点击的sectionId
      function handleData(res){
        var chapterId = '';
        const cpId = localStorageService.get('lastCoursePathId');
        expandAllLeaf(res[0].child);
        if(cpId){
          chapterId = localStorageService.get('lastCoursePathId_' + cpId);
          if(chapterId && chapterId!='-1'){
            $scope.defaultSelect = false;
            findTreeLastSelectedLeaf(res[0].child,chapterId);
            if($scope.selectedSection && $scope.selectedSection.parentId){
                openParentById($scope.selectedSection.parentId,res[0].child)
            }
            oedCourse.querySectionDetailById({
              sectionId: chapterId,
              coursePathId: cpId
            }).$promise.then(item=>{
              const idMap = [];
              _.forEach(item,data=>{
                  idMap.push(data.id);
              })
              $scope.cloneData = _.filter($scope.courses,function(data){
                return idMap.indexOf(data.id) >= 0;
              });
              $scope.cloneData = _.sortBy($scope.cloneData, function(item) {
                return -item.createDate;
              });
              if ($scope.isExamSection) {
                return
              }
              const boxWidth = document.getElementsByClassName('card-content')[0].clientWidth;
              $scope.defaultCardNum = Math.floor(boxWidth/222) - 1;
              if($scope.cloneData.length <= $scope.defaultCardNum) {
                $scope.isDispayText = false;
              } else {
                $scope.isDispayText = true;
              }
            });
          } else {
            //给默认选中
            $scope.defaultSelect = false;
            findLastLeafId(res[0].child[0]);
          }
        }
        setTimeout(() => {
          if($('.selected') && $('.selected')[0]){
            $('.selected')[0].scrollIntoView({behavior:'smooth'});
          }
        }, 0);
        return res;
      }
      function findLastLeafId(data){
        if(data && data.child && data.child.length > 0){
          findLastLeafId(data.child[0]);
        }else{
          const cpid = localStorageService.get('lastCoursePathId');
          localStorageService.set('lastCoursePathId_' + cpid, data.id);
          findTreeLastSelectedLeaf($scope.tempData,data.id);
          if($scope.selectedSection && $scope.selectedSection.parentId){
              openParentById($scope.selectedSection.parentId,$scope.tempData)
          }
          oedCourse.querySectionDetailById({
            sectionId: data.id,
            coursePathId: cpId
          }).$promise.then(item=>{
            const idMap = [];
            _.forEach(item,data=>{
                idMap.push(data.id);
            })
            $scope.cloneData = _.filter($scope.courses,function(data){
              return idMap.indexOf(data.id) >= 0;
            });
            $scope.cloneData = _.sortBy($scope.cloneData, function(item) {
              return -item.createDate;
            });
            if ($scope.isExamSection) {
              return
            }
            const boxWidth = document.getElementsByClassName('card-content')[0].clientWidth;
            $scope.defaultCardNum = Math.floor(boxWidth/222) - 1;
            if($scope.cloneData.length <= $scope.defaultCardNum) {
              $scope.isDispayText = false;
            } else {
              $scope.isDispayText = true;
            }
          });
        }
      }
      //默认展开所有树节点
      function expandAllLeaf(data){
        _.forEach(data,function(c){
          c.$opened = true;
          c.noClick = true;
        });
      }
      //当前选中的章节
      function findTreeLastSelectedLeaf(data,chapterId){
        const cpId = localStorageService.get('lastCoursePathId');
        _.forEach(data,item=>{
          if(item.id == chapterId){
            $scope.selectedSection = item;
            $scope.selectedSecRootPid = findRootPid(item.id)
            loadExamCardList(cpId)
          }else{
            if(item.child){
              findTreeLastSelectedLeaf(item.child,chapterId);
            }
          }
        });
      }
      function openParentById(id,res){
        _.forEach(res,r=>{
          if(r.id === id){
            r.$opened = true;
            if(r.parentId){
              openParentById(r.parentId, $scope.tempData)
            }
          }else{
            if(r.child && r.child.length > 0){
              openParentById(id,r.child);
            }
          }
        })
      }
      $scope.changeSeeStatus = () => {
        $scope.defaultWatchStatus = !$scope.defaultWatchStatus;
        if ($scope.defaultWatchStatus) {
           $scope.defaultWatchText = '显示全部';
           if ($scope.isExamSection) {
            return
          }
           const boxWidth = document.getElementsByClassName('card-content')[0].clientWidth;
           $scope.defaultCardNum = Math.floor(boxWidth/222) - 1;
        } else {
          $scope.defaultWatchText = '收起全部';
          $scope.defaultCardNum = $scope.cloneData.length;
        }
      }
      //左侧树选中并且filter右侧数据
      $scope.onSelectSection = sec => {
        
        $scope.defaultSelect = false;
        $scope.selectedSection = sec;
        $scope.selectedSecRootPid = findRootPid(sec.id)
        const cpId = localStorageService.get('lastCoursePathId');
        loadExamCardList(cpId)
        if(cpId){
          localStorageService.set('lastCoursePathId_' + cpId, sec.id);
        }
        $scope.loading = oedCourse.querySectionDetailById({
          sectionId: sec.id,
          coursePathId: cpId
        }).$promise.then(item=>{
          const idMap = [];
          _.forEach(item,data=>{
              idMap.push(data.id);
          })
          $scope.cloneData = _.filter($scope.courses,function(data){
            return idMap.indexOf(data.id) >= 0;
          });
          $scope.cloneData =  _.sortBy($scope.cloneData, function(item) {
            return -item.createDate;
          });
          const boxWidth = document.getElementsByClassName('card-content')[0].clientWidth;
          $scope.defaultCardNum = Math.floor(boxWidth/222) - 1;
          if($scope.cloneData.length <= $scope.defaultCardNum) {
            $scope.isDispayText = false;
          } else {
            $scope.isDispayText = true;
            $scope.defaultWatchText = '显示全部';
          }
          $scope.defaultWatchStatus = true;
        });
      }
      //新建课程相关
      $scope.close = function() {
        $scope.editCourseModalInstance.dismiss('cancel');
      };
      $scope.add = function(){
        const questObj = {
          coursepathId: Number($scope.cpId),
          relatedSections:[{sectionId:$scope.selectedSection.id}],
          courseName: `${$scope.selectedSection.name}(${$scope.cloneData.length + 1})`
        };
        oedCourse.addCourseByPathIdandSectionIds(questObj).$promise.then(r => {
            localStorageService.set('newPrepareNeedPointOut', false);
            localStorageService.set('newCreateCourseId', r.id);
            $state.go('newPrepareCourse.ownerPrepare', {
              courseId: r.id,
            });
        })
      }
      $scope.deleteCourseItem = function(cs) {
        var dlg = dialogs.confirm('确定删除?', '确定要删除课程: "' + cs.courseName + '"吗');
        dlg.result.then(function(btn) {
          cs.$delete(function(cs) {
            if ($scope.coursePath.templateType && $scope.canStar) {
              var removecourse = oedCoursePath.removeCourseFromTemplate({id: cs.id}).$promise;
            }
            _.remove($scope.courses, cs);
            _.remove($scope.cloneData, cs);
            if ($scope.isExamSection) {
              return
            }
            const boxWidth = document.getElementsByClassName('card-content')[0].clientWidth;
            if($scope.cloneData.length <= Math.floor(boxWidth/222) - 1) {
              $scope.isDispayText = false;
              $scope.defaultWatchStatus = true;
              $scope.defaultWatchText = '显示全部';
              $scope.defaultCardNum = Math.floor(boxWidth/222) - 1;
            }
          });
        });
      };
      $scope.collectionDepot = () => {
        var modalInstance = $uibModal.open({
          template: require('assets/app/controllers/recycleBinDialog/recycleBinDialog.html'),
          controller: 'recycleBinDialogCtrl',
          size: 'lg',
          windowClass: 'ex-collectionModal',
          resolve: {
            cpId: function() {
              return $scope.cpId;
            }
          }
        });
        modalInstance.result.then(function(r) {
          if (r) {
            loadData($scope.cpId);
          }
        });
      };
      $scope.switchClick = () => {
        $scope.isList = !$scope.isList
        localStorageService.set('isList', $scope.isList)
          return;
      }
      $scope.addEditCourse = (type,item) => {
        $scope.type = type;
        if(type == 'edit') {
          localStorageService.set('lastCurentCourse',item);
          localStorageService.set('newPrepareNeedPointOut', false);
          localStorageService.set('newCreateCourseId', null);
          $state.go('newPrepareCourse.ownerPrepare', {
            courseId: item.id
          });
        }else{
          $scope.add();
        }
      }
      $scope.changeSelectChapter = function(){
        $scope.defaultSelect = true;
        $scope.selectedSection = '';
        $scope.selectedSecRootPid = 0
        const cpId = localStorageService.get('lastCoursePathId');
        if(cpId){
          localStorageService.set('lastCoursePathId_' + cpId, '-1');
        }
        $scope.zlCardData = [];
        $scope.recommendCardData = [];
        $scope.cloneData = $scope.courses;
      }
      $scope.togglePrepareCouseStatus = function () {
        oedUserInfo.updateNewPrepareLesson({uid:$scope.uid,value:'0'}).$promise.then(res=>{
          localStorageService.set('isPreLesson',false);
          $state.go('prepareLesson.courseList', {
            cpId: $scope.cpId
          });

        })
      }
      $scope.addCp = function(existingCourses) {
        var cp = {};
        var dlg = $uibModal.open({
          template: require('assets/templates/manage/createCourseDialog.html'),
          controller: 'createCourseCpCtrl',
          size: 'md',
          windowClass: 'signupModal',
          resolve: {
            coursePath: function() {
              return cp;
            },
            existing: function() {
              return existingCourses;
            },
            copy: null,
            createCp: true,
            newPreCp: null
          }
        });
        dlg.result.then(function(data) {
          $scope.noCp = false;
          // loadData(data.cp.id);
          $state.go('prepareLesson.newCourseList', {
            cpId: data.cp.id
          });
        });
      };
      $scope.editCp = function(cp) {
        event.stopPropagation();
        event.preventDefault();
        var dlg = $uibModal.open({
          template: require('assets/templates/manage/createCourseDialog.html'),
          controller: 'createCourseCpCtrl',
          size: 'md',
          windowClass: 'signupModal',
          resolve: {
            coursePath: function() {
              return cp;
            },
            existing: null,
            copy: null,
            newPreCp: true,
            createCp: null,
          }
        });
        dlg.result.then(function(newCp) {
          if (newCp.type) {
            if (newCp.type == 'ok') {
              loadData(newCp.cp.id);
            } else if (newCp.type == 'archive') {
              $scope.archiveCourse(newCp.cp);
            } else if (newCp.type == 'copy') {
              $scope.copyCourse(newCp.cp);
            } else if (newCp.type == 'delete') {
              $scope.deleteCourse(newCp.cp);
            } else if (newCp.type == 'preCourse'){
             loadData(newCp.cp.id);
            }
          }
        });
      };
      $scope.deleteCourse = function(cp) {
        var toDeleteObj = _.find($scope.coursePathList, function(o) {
          return o.id === cp.id;
        });
        var dlg = dialogs.confirm('删除课程', '你确定要删除此课程吗？');
        dlg.result.then(function() {
          toDeleteObj.$delete().then(function() {
            _.remove($scope.coursePathList, (coursePath) => {
              return coursePath.id == cp.id;
            });
            if ($scope.coursePathList.length > 0) {
              loadData($scope.coursePathList[0].id);
            }else{
              $scope.noCp = true;
            }
          });
        });
      };
      $scope.archiveCourse = function(cp) {
        var dlg = dialogs.confirm('归档课程', '你确定要归档此课程吗？');
        dlg.result.then(function() {
          oedCoursePath.manageCoursePathArchive({
            id: cp.id,
            flag: 1
          }).$promise.then(function() {
            notificationService.notify('info', '归档成功');
            _.remove($scope.coursePathList, (coursePath) => {
              return coursePath.id == cp.id;
            });
            if ($scope.coursePathList.length > 0) {
              loadData($scope.coursePathList[0].id);
            }else{
              $scope.noCp = true;
            }
            }).catch(function() {
              notificationService.notify('error', '发生未知错误')
            });
        });
      };
      $scope.copyCourse = function(existingCourses) {
        var cp = {};
        var dlg = $uibModal.open({
          template: require('assets/templates/manage/createCourseDialog.html'),
          controller: 'createCourseCpCtrl',
          size: 'md',
          windowClass: 'signupModal',
          resolve: {
            coursePath: function() {
              return existingCourses;
            },
            existing: function() {
              return null;
            },
            copy: function() {
              return true;
            },
            newPreCp: null,
            createCp: null,
          }
        });
        dlg.result.then(function(cp) {
          $scope.coursePathList.push(cp.cp);
        });
      };
      $scope.changeCp = function (id) {
        // loadData(id);
        $state.go('prepareLesson.newCourseList', {
          cpId: id
        });
      }
    }

    createCourseCpCtrl.$inject = ['$scope', 'userInfo', '$uibModalInstance', '$q', 'dialogs', 'oedUserClass',
    'oedSubjects', 'oedTeachingMaterialVersions', 'oedCoursePath', 'coursePath', 'existing', 'copy','newPreCp','createCp']
    function createCourseCpCtrl($scope, userInfo, $uibModalInstance, $q, dialogs, oedUserClass,
      oedSubjects, oedTeachingMaterialVersions, oedCoursePath, coursePath, existing, copy,newPreCp,createCp) {
      $scope.subjects = [];
      $scope.classes = [];
      $scope.newPreCp = newPreCp;
      $scope.createCp = createCp;
      if (existing !== null) {
        $scope.allCoursePaths = existing;
        $scope.fromExisting = true;
      } else {
        $scope.fromExisting = false;
      }
      // make a copy
      $scope.courseInfo = _.assign({}, coursePath);

      if (copy !== null) {
        $scope.copyMode = true;
        $scope.courseInfo.oldname = $scope.courseInfo.name;
        $scope.courseInfo.name = $scope.courseInfo.name + '2';
      } else {
        $scope.copyMode = false;
      }

      $scope.courseInfo.startDate = moment($scope.courseInfo.startDate).toDate();
      var endDate = $scope.courseInfo.endDate ?
        moment($scope.courseInfo.endDate).toDate() :
        moment($scope.courseInfo.startDate).add(5, 'months').toDate();
      $scope.courseInfo.endDate = endDate;

      $scope.$watch('courseInfo.endDate', function(newVal) {
        $scope.startDateOpt = {
          maxDate: newVal
        };
      });

      $scope.$watch('courseInfo.startDate', function(newVal) {
        $scope.endDateOpt = {
          minDate: newVal
        };
      });

      userInfo.then(function(info) {
        $scope.subjects = oedSubjects.queryBySchoolId({
          schoolId: info.schoolId
        });

        if ($scope.courseInfo.subjectId) {
          $scope.subjects.$promise.then(function(subjects) {
            $scope.courseInfo.subject = _.find(subjects, function(sub) {
              return sub.id == $scope.courseInfo.subjectId;
            });

            $scope.versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
              schoolId: info.schoolId,
              subjectId: $scope.courseInfo.subject.id
            });

            $scope.versions.$promise.then(function(versions) {
              $scope.courseInfo.teachingMaterial = _.find(versions, function(ver) {
                return ver.gradeId == $scope.courseInfo.gradeId &&
                  ver.versionId == $scope.courseInfo.versionId &&
                  ver.subjectId == $scope.courseInfo.subjectId;
              });
            });

            return $scope.versions.$promise;
          });
        }

        $scope.classes = oedUserClass.queryByUser({
          userId: info.uid
        });

        $scope.isEdit = function() {
          return !!$scope.courseInfo.id;
        };

        $scope.isCopyMode = function() {
          return !!$scope.copyMode;
        };

        $scope.isArchive = function() {
          return !!$scope.courseInfo.isArchive;
        };

        $scope.isCopied = function() {
          return $scope.courseInfo.copyFromCp && $scope.courseInfo.copyFromCp.id;
        };

        $scope.isSelected = function() {
          return $scope.courseInfo.copyFromCp !== null && $scope.courseInfo.copyFromCp !== undefined;
        };

        $scope.classes.$promise.then(function(classes) {
          $scope.courseInfo.classess = _($scope.courseInfo.classIds).map(function(clsId) {
            return _.find(classes, function(cls) {
              return cls.id == clsId;
            });
          }).reject(function(v) {
            return !v;
          }).value();
        });

        $scope.loading = $q.all([$scope.subjects.$promise, $scope.classes.$promise]);

        $scope.onSubjectSelected = function(item, model) {
          $scope.courseInfo.teachingMaterial = null;
          $scope.versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
            schoolId: info.schoolId,
            subjectId: item.id
          });
          $scope.loading = $scope.versions.$promise;
        };

        $scope.onCoursePathSelected = function(cp, model) {
          if (_.isEmpty($scope.courseInfo.name)) {
            $scope.courseInfo.name = cp.name;
          }

          $scope.courseInfo.subject = _.find($scope.subjects, function(sub) {
            return sub.id == cp.subjectId;
          });

          $scope.versions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
            schoolId: info.schoolId,
            subjectId: $scope.courseInfo.subject.id
          });

          $scope.versions.$promise.then(function(versions) {
            $scope.courseInfo.teachingMaterial = _.find(versions, function(ver) {
              return ver.gradeId == cp.gradeId && ver.versionId == cp.versionId && ver.subjectId == cp.subjectId;
            });
          });

          $scope.loading = $scope.versions.$promise;
        };

      }); //userInfo.then

      var courseNameTmpl = _.template('<%=version.versionName%> <%= version.name%>');

      $scope.onVersionSelected = function(item, model) {
        $scope.courseInfo.name = courseNameTmpl({
          version: model
        });
      };

      $scope.getTeachingMaterialList = function(val) {
        return _.filter($scope.versions, function(v) {});
      };

      $scope.clearCoursePath = function(event) {
        event.stopPropagation();
        $scope.courseInfo.copyFromCp = null;
      };

      var checkTeachingMaterialChanged = function() {
        if ($scope.isEdit()) {
          var oldCp = _(coursePath);
          var tmProps = ['gradeId', 'subjectId', 'versionId'];
          var newInfo = {};
          if ($scope.courseInfo.subject) {
            newInfo.subjectId = $scope.courseInfo.subject.id;
          }

          if ($scope.courseInfo.teachingMaterial) {
            var tm = $scope.courseInfo.teachingMaterial;
            newInfo.gradeId = tm.gradeId;
            newInfo.versionId = tm.versionId;
          }

          if (!oldCp.pick(tmProps).isEqual(newInfo)) {
            return dialogs.confirm('修改教材', '改教材后备课的章节关联信息将被重置，是否确定修改?').result;
          }
        }

        return true;
      };
      $scope.deleteCourse = function() {
        $uibModalInstance.close({cp: $scope.courseInfo, type: 'delete'});
      };
      $scope.archiveCourse = function() {
        $uibModalInstance.close({cp: $scope.courseInfo, type: 'archive'});
      };
      $scope.unArchiveCourse = function() {
        $uibModalInstance.close({cp: $scope.courseInfo, type: 'unarchive'});
      };
      $scope.copyCourse = function() {
        $uibModalInstance.close({cp: $scope.courseInfo, type: 'copy'});
      };
      $scope.preCourse = function() {
        $uibModalInstance.close({cp: $scope.courseInfo, type: 'preCourse'});
      };
      $scope.ok = function() {
        $scope.showValidationError = true;
        if ($scope.createCourseForm.$invalid) {
          return;
        }
        $q.when(checkTeachingMaterialChanged()).then(function() {
          var courseInfo;
          if ($scope.copyMode) {
            courseInfo = _.pick($scope.courseInfo, ['name']);
          } else {
            courseInfo = _.pick($scope.courseInfo, ['id', 'name']);
          }
          if ($scope.courseInfo.subject) {
            courseInfo.subjectId = $scope.courseInfo.subject.id;
          }

          if ($scope.courseInfo.copyFromCp) {
            courseInfo.copyFromCpId = $scope.courseInfo.copyFromCp.id;
          }

          //
          // 转换成开始日期的0点
          courseInfo.startDate = moment(moment($scope.courseInfo.startDate).format('YYYYMMDD'), 'YYYYMMDD').toDate();
          //
          // 转换成结束日期的最后一秒
          // 这里需要服务器时区和浏览器时区一致
          courseInfo.endDate = moment(moment($scope.courseInfo.endDate)
            .format('YYYYMMDD 23:59:59'),
            'YYYYMMDD hh:mm:ss').toDate();

          if ($scope.courseInfo.teachingMaterial) {
            var tm = $scope.courseInfo.teachingMaterial;
            courseInfo.gradeId = tm.gradeId;
            courseInfo.versionId = tm.versionId;
          }

          courseInfo.classIds = _.map($scope.courseInfo.classess, function(cls) {
            return cls.id;
          });

          var cp = new oedCoursePath(courseInfo);
          $scope.loading = cp.$save();
          $scope.loading.then(function(cp) {
            $uibModalInstance.close({cp, type: 'ok'});
          });
        });
      };
      $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
      };

      $scope.now = moment();
      $scope.groupByStatus = function(item) {
        if (item.isUnStarted($scope.now)) {
          return '未开始';
        }
        if (item.isOnGoing($scope.now)) {
          return '进行中';
        }

        return '已完成';
      };
      $scope.groupByArchive = function(item) {
        if (item.isArchive == 1) {
          return '已归档';
        } else {
          return '进行中';
        }
      };

      $scope.openStart = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.startOpened = true;
        $scope.endOpened = false;
      };

      $scope.openEnd = function($event) {
        $event.preventDefault();
        $event.stopPropagation();
        $scope.startOpened = false;
        $scope.endOpened = true;
      };

      $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 0
      };
    }
  })();
