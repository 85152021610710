import _ = require('lodash')
require('assets/styles/tab.scss');
angular.module('app.directives.utils.reviewTab', [
])
.component('reviewTab', {
  bindings: {
    activeTabIndex: '=',
    flexFlow: '@',
    justifyContent: '@',
    tabs: '<',
  },
  controller: reviewTabCtrl,
  template: <string> require('./reviewTab.html'),
  transclude: true,
})

function reviewTabCtrl() {
    this.onSeleceTab = (tab: any) => {
      this.activeTabIndex = tab.index;
    }
    this.getTabClass = () => {
      let flow = 'flex-flow-row'
      let justify = 'justify-content-center'
      if (this.flexFlow === 'column') {
        flow = 'flex-flow-column';
      }
      if (this.justifyContent === 'flex-start') {
        justify = 'justify-content-start';
      }
      return flow + ' ' + justify;
    }
}
