import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { NotificationService } from 'app/shared/notification/notification.service';
import { ExerciseDTO } from '../../../manage';
import { OedExerciseService } from '../../oed-exercise.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-import-school-dialog',
  template: require('./import-school-dialog.component.html'),
  styles: [require('./import-school-dialog.component.scss')]
})
export class ImportSchoolDialogComponent implements OnInit {
  provinceArray: any;
  cityArray: any;
  areaArray: any;
  schoolArray: any
  topicArray: any
  topicData: any;
  selectProvince: any = '';
  selectCity: any = '';
  selectArea: any = ''
  selectSchool: any = '';
  selectTopic: any = [];
  schoolData: any;
  isSelectXiaoBen: any = false;
  isSelectTopic: any = true;
  loading = true;
  schoolList: any;
  private selectExerciseBook: any;
  private selectSchoolId: number;

  @Output() public action = new EventEmitter<any>()

  constructor(public bsModalRef: BsModalRef,
    private notifyService: NotificationService,
    private oedExerciseService: OedExerciseService,) { }

  ngOnInit() {
    const exerciseId = _.get(this.selectExerciseBook, 'id');
    forkJoin( this.oedExerciseService.getExeriseBookPositionList(exerciseId),
    this.oedExerciseService.getSchoolList(),  
    this.oedExerciseService.getTopicList())
    .subscribe((data) => {
      this.schoolList = data[0];
      this.schoolData = data[1];
      this.provinceArray = _.get(this.schoolData, 'provinces');
      this.topicData = _.filter(data[2], item => !_.isEmpty(item.name));
      this.topicArray = [...this.topicData];
      if(this.selectSchoolId) {
        const item = _.find(this.schoolList, item => _.get(item, 'position.schoolId') === this.selectSchoolId);
        this.setLoadData(item, 'includeSchool');
      } else {
        this.onSelectProvince(_.get(this.provinceArray, '0'));
        if(_.size(this.schoolList)) {
          this.setLoadData(this.schoolList[this.schoolList.length - 1], '');
        }
      }
      this.canAdd();
      this.loading = false;
    })
  }
  // 选择省份
  onSelectProvince (item) {
    this.selectProvince = item;
    this.reloadCity(item);
  }

  reloadCity(item) {
    this.cityArray = _.filter(_.get(this.schoolData, 'citys'), res => res.parentId === item.id);
    this.onSelectCity(_.get(this.cityArray, '0'));
  }

  onSelectCity (item) {
    this.selectCity = item;
    this.reloadArea(item);   
  }

  reloadArea(item) {
    this.areaArray = _.filter(_.get(this.schoolData, 'zones'), res => res.parentId === item.id);
    this.onSelectArea(_.get(this.areaArray, '0'));
  }

  onSelectArea (item) {
    this.selectArea = item;
    this.reloadSchool(item);
  }

  reloadSchool(item) {
    this.schoolArray = _.filter(_.get(this.schoolData, 'schools'), res => res.zongId === item.id);
    this.onSelectSchool(_.get( this.schoolArray, '0'));
  }

  onSelectSchool(item) {
    this.selectSchool = item;
  }
  
  onSelectTopic (item) {
    this.selectTopic.push(item);
    this.topicArray.splice(_.findIndex(this.topicArray, item), 1);
    this.canAdd();
  }

  onDeleteSelectTopic(item) {
    this.selectTopic.splice( _.findIndex(this.selectTopic, item), 1);
    this.topicArray = _.filter(this.topicData, item => _.indexOf(this.selectTopic, item) === -1);
    this.canAdd();
  }

  chooseTopic() {
    this.isSelectTopic = !this.isSelectTopic ;
    this.canAdd();
  }

  chooseXiaoBen() {
    this.isSelectXiaoBen = !this.isSelectXiaoBen;
    this.canAdd();
  }

  close() {
    this.bsModalRef.hide()
  }

  canAdd() {
    if(this.isSelectTopic && !_.isEmpty(this.selectTopic)) {
      return true;
    } else if (this.isSelectXiaoBen && !this.isSelectTopic) {
      return true;
    } else {
      return false;
    }
  }

  // 设置默认选中值
  setLoadData(item, status) {
    if(status === 'includeSchool') {
      const schoolId = _.get(item, 'position.schoolId');
      // 加载已选择学校
      this.selectSchool = _.find(_.get(this.schoolData, 'schools'), res => res.schoolId === schoolId);
      // 加载对应的学校列表
      this.schoolArray = _.filter(_.get(this.schoolData, 'schools'), res => res.zongId === this.selectSchool.zongId);
      // 加载已选择的区域
      this.selectArea = _.find(_.get(this.schoolData, 'zones'), res => res.id === this.selectSchool.zongId);
      // 加载对应区域列表
      this.areaArray = _.filter(_.get(this.schoolData, 'zones'), res => res.parentId === this.selectArea.parentId);
      // 加载已选择市
      this.selectCity = _.find(_.get(this.schoolData, 'citys'), res => res.id === this.selectArea.parentId);
      // 加载市列表
      this.cityArray = _.filter(_.get(this.schoolData, 'citys'), res => res.parentId === this.selectCity.parentId);
      // 加载已选中的省份
      this.selectProvince =_.find(_.get(this.schoolData, 'provinces'), res => res.id === this.selectCity.parentId);
    }
    // 加载区域
    const topics = _.includes(_.get(item, 'positionTypes'), 'topic');
    const school = _.includes(_.get(item, 'positionTypes'), 'school');
    if(topics && school) {
      this.isSelectXiaoBen = true;
      this.isSelectTopic = true;
      this.setTopic(item);
    } else if(topics){
      this.isSelectTopic = true;
      this.isSelectXiaoBen = false;
      this.setTopic(item);
    } else {
      this.isSelectTopic = false;
      this.isSelectXiaoBen = true;
      this.isSelectXiaoBen = true;
    }
  }

  // 设置区域
  setTopic(item) {
    const nameList = _.map(item.topics, res => res.name);
    this.selectTopic = _.filter(this.topicData, data => _.includes(nameList, _.get(data, 'name')));
    this.topicArray =  _.filter(this.topicData, data => !_.includes(nameList, _.get(data, 'name')));
  }

  ok() {
    if(this.canAdd()) {
      const schoolId = _.get(this.selectSchool, 'schoolId');
      const hasSelected = _.some(this.schoolList, item => _.get(item, 'position.schoolId') === schoolId);
      if(hasSelected && !this.selectSchoolId) {
        this.notifyService.notify('error', '此习题册已经导入该校，请勿重复导入');
        return;
      }
      let topicIds = [];
      _.forEach(this.selectTopic, item => {
        topicIds.push(item.id)
      })
      let positionTypes = [];
      if(this.isSelectXiaoBen && this.isSelectTopic) {
        positionTypes = ["school", "topic"]
      } else if(this.isSelectTopic) {
        positionTypes = ["topic"]
      } else if(this.isSelectXiaoBen) {
        positionTypes = ["school"];
        topicIds = [];
      }
      const params = {
        schoolId,
        positionTypes,
        topicIds,
      }
      const id = _.get(this.selectExerciseBook, 'id');
      this.oedExerciseService.importSchool(id, params).subscribe(data => {
        console.log('this.bsModalRef', data);
        this.action.emit(data);
        this.bsModalRef.hide();  
      })
    }
  }

}
