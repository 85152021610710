import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {
  OnlineTrainingContentDTO, OnlineTrainingContentLinkDTO, OnlineTrainingContentQaDTO,
  OnlineTrainingDirectoryDTO
} from 'app/online-train/models/online-train';
import * as _ from 'lodash';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import {concat, forkJoin, Observable, of, zip} from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { StateService } from '@uirouter/core'

@Component({
  selector: 'app-train-content-qa-editor',
  template: require('./train-content-qa-editor.component.html'),
  styles: [require('./train-content-qa-editor.component.scss')]
})
export class TrainContentQaEditorComponent implements OnInit {
  @Input() public params: any
  private dirs: OnlineTrainingDirectoryDTO[]
  private parentId
  private editable
  private contentNav: OnlineTrainingDirectoryDTO[]

  private itemType
  private contents: OnlineTrainingContentQaDTO[]
  private qa
  constructor(private onlineTrainService: OnlineTrainService,
              private state: StateService) { }

  ngOnInit() {
    this.parentId = _.parseInt(this.params.parentId)
    this.editable = this.params.editable
    this.dirs = this.params.dirs
    this.qa = this.params.other
    this.contentNav = []
    if (!_.isEmpty(this.dirs)) {
      this.buildNav()
    } else {
      this.onlineTrainService.getAllDirs().subscribe((dirs) => {
        this.dirs = _.filter(dirs, (dir) => dir.id > 1)
        this.buildNav()
      }, (err) => {console.log(err)})
    }

    this.itemType = this.onlineTrainService.CONTENTQA

    this.loadData().subscribe()
  }

  public onEdit(params) {
    const item = params.item[0]
    if (params.editType === this.onlineTrainService.ADD) {
      this.onlineTrainService.addContentQa(item)
        .pipe(flatMap(() => this.loadData())).subscribe()
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      this.onlineTrainService.updateContentQa(item)
        .pipe(flatMap(() => this.loadData())).subscribe()
    } else if (params.editType === this.onlineTrainService.DELETE) {
      this.onlineTrainService.deleteContentQa(params.item.id)
        .pipe(flatMap(() => this.loadData())).subscribe()
    }
  }

  public getDataToAdd() {
    return {
      qaId: this.parentId,
      resourceId: -1,
      name: '',
      sort: 0,
      deleted: false
    }
  }

  public videoType(content) {
    return this.onlineTrainService.videoType(content.$$resource)
  }

  public textType(content) {
    return this.onlineTrainService.textType(content.$$resource)
  }

  public imgType(content) {
    return this.onlineTrainService.imgType(content.$$resource)
  }

  private buildNav() {
    let parentDir = _.find(this.dirs, (dir) => dir.id === this.qa.dirId)
    while (parentDir && parentDir.id && parentDir.id > 1) {
      this.contentNav.push(parentDir)
      parentDir = _.find(this.dirs, (dir) => dir.id === parentDir.dirId)
    }

    this.contentNav = _.reverse(this.contentNav)
  }

  private loadData() {
    return this.onlineTrainService.getContentsByQaId(this.parentId)
      .pipe(
        flatMap((v) => {
          this.contents = v
          if (_.isEmpty(this.contents)) {
            return of([])
          } else {
            const req = _.map(this.contents, (c) => this.onlineTrainService.getResource(c.resourceId))
            return forkJoin(...req)
          }
        }),
        map((reses) => {
          if (!_.isEmpty(reses)) {
            this.onlineTrainService.enhanceContent(this.contents, reses)
          }
          return of(0)
        })
      )
  }
}
