import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, SimpleChange } from '@angular/core';
import _ = require('lodash');

@Component({
  selector: 'app-click-hook-com',
  template: require('./click-hook-com.component.html'),
  styles: [require('./click-hook-com.component.scss')]
})
export class ClickHookComComponent implements OnInit {

  @Input() editingUuid: any
  @Input() uuid: any
  @Input() ignoreHook: any
  @Output() enterEditing = new EventEmitter<any>()
  @Output() leaveEditing = new EventEmitter<any>()
  @Input() ignoreClickEvent: any
  inEditing = false
  @ViewChild('containerEl') container: ElementRef

  constructor() { }

  ngOnInit() {
    this.inEditing = this.editingUuid && this.editingUuid == this.uuid
  }

  ngOnChanges(changes: SimpleChange) {
    if (_.has(changes, 'editingUuid')) {
      this.editingUuid = _.get(changes, 'editingUuid.currentValue', '')
    }
    this.inEditing = this.editingUuid && this.editingUuid == this.uuid
  }

  ngOnDestroy() {
    this.container.nativeElement.removeEventListener('click', this.captureClicked, true);
    document.removeEventListener('click', this.captureDocumentClick, true);
  }

  ngAfterViewInit() {
    this.hookClickEvents()
  }

  hookClickEvents() {
    this.container.nativeElement.addEventListener('click', this.captureClicked, true);
    document.addEventListener('click', this.captureDocumentClick, true);
  }

  captureClicked = (evt) => {
    if (!this.inEditing && !this.ignoreHook) {
      this.inEditing = true;
      console.info('entering edit mode');
      document.removeEventListener('click', this.captureDocumentClick, true);
      document.addEventListener('click', this.captureDocumentClick, true);
      // this.enterEditing(this.question, event)
      this.enterEditing.emit()
    }
  }

  //
  // 检查是否离开问题编辑区域
  // 离开编辑区域的意思是:
  // 点击问题区外的任意位置
  captureDocumentClick = (event) => {
    if (!event.target || !this.inEditing) {
      return;
    }

    //
    // 当ueditor对话框打开时会有一个edui-mask的层
    // 当点击这个层的时候应当忽略
    const el = $(event.target);
    if (el.hasClass('edui-mask')) {
      return;
    }
    //
    // 如果点击的是ueditor弹出的对话框中的按钮或提示信息按钮的话
    // 也应当忽略掉点击事件
    const cls = '.ueditor, .edui-dialog, .edui-shadow, .edui-default, .cg-notify-message, .modal-dialog,' +
      '.select-label2, .batch-delete, .delete-parent-question .ques-vote-type';
    const edDialog = el.closest(cls);
    if (edDialog.length > 0) {
      if (!edDialog.hasClass('modal-edit-test')) {
        return;
      }
    }

    const isChild = $(this.container.nativeElement).find(event.target).length > 0;
    if (isChild) {
      return;
    }

    console.log('leaving edit mode');

    const ret = this.ignoreClickEvent()
    console.log('will ignore event', ret)
    if (ret) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      this.inEditing = false;
      document.removeEventListener('click', this.captureDocumentClick, true);
      this.leaveEditing.emit()
    }
  }

}
