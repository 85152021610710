import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'app-prepare-lesson'
})
export class OedPrepareLessonDirective extends UpgradeComponent {
  @Input() public resType: string;
  @Output() public onImport: EventEmitter<any>;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('oedPrepareLesson', elementRef, injector);
  }
}
