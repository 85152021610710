import { ExInfoDialogComponent } from './../manage/exercise-book/dialog/ex-info-dialog/ex-info-dialog.component';
import { ExChoiceItemDialogComponent } from './../manage/exercise-book/dialog/ex-choice-item-dialog/ex-choice-item-dialog.component';
import { ExQuesEditDialogComponent } from '../manage/exercise-book/dialog/ex-ques-edit-dialog/ex-ques-edit-dialog.component';
import { ExMarkEditDialogComponent } from './../manage/exercise-book/dialog/ex-mark-edit-dialog/ex-mark-edit-dialog.component';
import { ExPaperListDialogComponent } from './../manage/exercise-book/dialog/ex-paper-list-dialog/ex-paper-list-dialog.component';
import { ExCreatePaperDialogComponent } from './../manage/exercise-book/dialog/ex-create-paper-dialog/ex-create-paper-dialog.component';
import { ExMarkDialogComponent } from './../manage/exercise-book/dialog/ex-mark-dialog/ex-mark-dialog.component';
import { Injectable } from '@angular/core';
import { CreateModuleComponent } from 'app/lesson/lesson-design/create-module/create-module.component'
import { EditCourseComponent } from 'app/lesson/lesson-design/edit-course/edit-course.component'
import { CreateTeachingModuleDTO } from 'app/lesson/models/lesson';
import { OedConfirmComponent } from 'app/shared/oed-confirm/oed-confirm.component'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { ReportErrorComponent } from '../ruanyun/report-error/report-error.component';
import { LittleTipComponent } from 'app/lesson/lesson-design/little-tip/little-tip.component'
import {TrainDirEditorComponent} from 'app/online-train/train-design/train-dir-editor/train-dir-editor.component';
import {TrainContentEditorComponentComponent} from 'app/online-train/train-design/train-content-editor-component/train-content-editor-component.component';
import {TrainContentLinkEditorComponent} from 'app/online-train/train-design/train-content-link-editor/train-content-link-editor.component';
import {TrainQAEditorComponent} from 'app/online-train/train-design/train-qaeditor/train-qaeditor.component';
import {TrainResourceViewerComponent} from 'app/online-train/train-design/train-resource-viewer/train-resource-viewer.component';
import {TrainGoodCourseEditorComponent} from 'app/online-train/train-design/train-good-course-editor/train-good-course-editor.component';
import {OfficeEditorTipComponent} from 'app/office-editor/office-editor-tip/office-editor-tip.component';
import {OfficeUploadTipComponent} from 'app/office-editor/office-upload-tip/office-upload-tip.component';
import {OfficeEditorDialogComponent} from 'app/office-editor/office-editor-dialog/office-editor-dialog.component';
import {CloudDriveDialogComponent} from 'app/cloud-drive/cloud-drive-dialog/cloud-drive-dialog.component';
import {ErrorDialogComponent} from 'app/cloud-drive/error-dialog/error-dialog.component';
import {DirDialogComponent} from 'app/cloud-drive/dir-dialog/dir-dialog.component';
import {RestoreDialogComponent} from 'app/cloud-drive/restore-dialog/restore-dialog.component';
import {AdjustScoreComponentComponent} from 'app/exam/adjust-score-component/adjust-score-component.component';
import {CreatExerciseBookDialogComponent} from 'app/manage/exercise-book/dialog/creat-exercise-book-dialog/creat-exercise-book-dialog.component'
import {ImportSchoolDialogComponent} from 'app/manage/exercise-book/dialog/import-school-dialog/import-school-dialog.component'
import {TestPaperContentComponent} from 'app/manage/exercise-book/dialog/test-paper-content/test-paper-content.component'
@Injectable({
  providedIn: 'root'
})
export class OedDialogService {
  public bsModalRef: BsModalRef;
  public componentMap = {
    ReportErrorComponent,
    OedConfirmComponent,
    CreateModuleComponent,
    EditCourseComponent,
    TrainDirEditorComponent,
    TrainContentEditorComponentComponent,
    TrainContentLinkEditorComponent,
    TrainQAEditorComponent,
    TrainResourceViewerComponent,
    TrainGoodCourseEditorComponent,
    LittleTipComponent,
    OfficeEditorTipComponent,
    OfficeUploadTipComponent,
    OfficeEditorDialogComponent,
    CloudDriveDialogComponent,
    ErrorDialogComponent,
    DirDialogComponent,
    RestoreDialogComponent,
    CreatExerciseBookDialogComponent,
    ImportSchoolDialogComponent,
    ExMarkDialogComponent,
    ExCreatePaperDialogComponent,
    ExPaperListDialogComponent,
    ExMarkEditDialogComponent,
    ExQuesEditDialogComponent,
    ExChoiceItemDialogComponent,
    ExInfoDialogComponent,
    TestPaperContentComponent,
    AdjustScoreComponentComponent,
  }
  public config = {
    animated: false,
    keyboard: true,
  };
  constructor(private modalService: BsModalService) { }

  public openModalWithComponent(name: string, state: any = {}, callback: any, customConfig: any = {}) {
    this.bsModalRef = this.modalService.show(this.componentMap[name], {
      ...this.config,
      ...customConfig,
      initialState: {
        ...state
      }
    });
    this.bsModalRef.content.action.subscribe((value) => {
      callback(value)
    });
  }

  public openModalWithComponent2(name: string, initialState: any = {}, customConfig: any = {}) {
    this.bsModalRef = this.modalService.show(this.componentMap[name], {
      ...this.config,
      ...customConfig,
      initialState,
    });
    return this.bsModalRef.content.action
  }

  public openModalWithComponent3(name: string, initialState: any = {}, customConfig: any = {}) {
    this.bsModalRef = this.modalService.show(this.componentMap[name], {
      ...this.config,
      ...customConfig,
      initialState,
    });
    return this.bsModalRef.content
  }

}
