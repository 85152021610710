import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TalPaperDTO, TalQuestionDetailsDTO, TalTopicDetailsDTO } from 'app2/integration/talcloud/models';
import * as _ from 'lodash';
import { TalApiResponse, TalPaperQueryDTO, TalQuestionQueryDTO, TalTopicQueryDTO } from '../models/talcloud';
import { TalApiService } from '../services/tal-api.service';

@Component({
  selector: 'app-talres-paper',
  template: require('./talres-paper.component.html'),
  styles: [require('./talres-paper.component.scss')],
  providers: [ TalApiService ]
})
export class TalresPaperComponent implements OnInit, OnChanges {

  @Input() public queryCond: TalPaperQueryDTO | TalQuestionQueryDTO | TalTopicQueryDTO
  @Output() public loadingChange = new EventEmitter()
  @Output() public paperLoaded = new EventEmitter<TalApiResponse<TalPaperDTO[]>>()
  @Output() public questionLoaded = new EventEmitter<TalApiResponse<TalQuestionDetailsDTO[]>>()
  @Output() public topicLoaded = new EventEmitter<TalApiResponse<TalTopicDetailsDTO[]>>()
  @Output() public onError = new EventEmitter<void>()
  private loading: any

  constructor(
    private talApiService: TalApiService
  ) { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'queryCond')) {
      return this.doQuery()
    }
  }

  private omitProps(props: string[]) {
    for (const p of props) {
      if (_.get(this.queryCond, p, '-1') === '-1') {
        this.queryCond = _.omit(this.queryCond, p)
      }
    }
  }

  private doQuery() {
    const type = _.get(this.queryCond, '$type', '');
    if (_.get(this.queryCond, '$type', '') === 'paper' || _.get(this.queryCond, '$type', '') === 'typeTest') {
      this.queryCond = _.pick(this.queryCond, ['stage', 'subjectId',
      'type', 'province', 'categoryId', 'year', 'title', 'page', 'perPage'])

      if (!this.isInValid(['stage', 'subjectId', 'categoryId', 'page', 'perPage'])) {
        this.omitProps(['type', 'province', 'year', 'title'])
        return this.doQueryPaper(type);
      }
    }


    if (_.get(this.queryCond, '$type', '') === 'question') {
      this.queryCond = _.pick(this.queryCond, ['stage', 'subjectId',
      'type', 'chapterId', 'difficult', 'examType', 'keyword', 'page', 'perPage'])
      if (!this.isInValid(['stage', 'subjectId', 'chapterId', 'type', 'page', 'perPage'])) {
        this.omitProps(['type', 'examType', 'difficult', 'keyword'])
        return this.doQueryQuestion()
      }
    }

    if (_.get(this.queryCond, '$type', '') === 'knowledge') {
      this.queryCond = _.pick(this.queryCond, ['stage', 'subjectId',
      'type', 'knowledgeId', 'difficult', 'examType', 'keyword', 'page', 'perPage'])
      if (!this.isInValid(['stage', 'subjectId', 'type', 'page', 'perPage', 'knowledgeId'])) {
        this.omitProps(['type', 'examType', 'difficult', 'keyword'])
        return this.doQueryQuestion()
      }
    }

    if (_.get(this.queryCond, '$type', '') === 'topic') {
      this.queryCond = _.pick(this.queryCond, ['stage', 'subjectId', 'type', 'page', 'perPage'])
      if (!this.isInValid(['stage', 'subjectId', 'type', 'page', 'perPage'])) {
        this.omitProps(['type'])
        return this.doQueryTopic()
      }
    }
  }
  // 同步教材只获取"单元测试"，"同步测试"。
  private doQueryPaper(type: string) {
    const body = _.omit(this.queryCond, ['$type', 'paperType'])
    this.loading = this.talApiService.getPaperList(body).toPromise()
    .then((result: TalApiResponse<TalPaperDTO[]>) => {
      if (result.code !== 'success' || _.isEmpty(result.data)) {
        console.error(result)
        this.onError.emit()
      } else {
        // 同步教材只获取"单元测试"，"同步测试"。
        if(type === 'paper') {
          result.data = _.filter(result.data, (item: TalPaperDTO)=> {
            return item.type === "1" || item.type === "14"
          })
        }
        this.paperLoaded.emit(result)
      }
    }).catch((e) => {
        console.error(e)
        this.onError.emit(e)
    })
    this.loadingChange.emit(this.loading)
  }

  private doQueryQuestion() {
    const body = _.omit(this.queryCond, ['$type', 'paperType']) as TalQuestionQueryDTO
    this.loading = this.talApiService.getQuestionList(body).toPromise()
    .then((result: TalApiResponse<TalQuestionDetailsDTO[]>) => {
      if (result.code !== 'success' || _.isEmpty(result.data)) {
        console.error(result)
        this.onError.emit()
      } else {
        this.questionLoaded.emit(result)
      }
    }).catch((e) => {
        console.error(e)
        this.onError.emit(e)
    })
    this.loadingChange.emit(this.loading)
  }

  private doQueryTopic() {
    const body = _.omit(this.queryCond, ['$type']) as TalTopicQueryDTO
    this.loading = this.talApiService.getTopicList(body).toPromise()
    .then((result: TalApiResponse<TalTopicDetailsDTO[]>) => {
      if (result.code !== 'success' || _.isEmpty(result.data)) {
        console.error(result)
        this.onError.emit()
      } else {
        this.topicLoaded.emit(result)
      }
    }).catch((e) => {
        console.error(e)
        this.onError.emit(e)
    })
    this.loadingChange.emit(this.loading)
  }

  private isInValid(args: string[]) {
    const valids = _.map(args, (arg) => !!this.queryCond[arg])
    return _.includes(valids, false)
  }

}
