(function() {
  var messages = require('app2/utils/messages');
  var moment = require('moment');
  var _ = require('lodash');
  const downloadUtils = require('app2/utils/downloadUtils');

  angular.module('app.prepareCourse')
    .controller('cloudPresentResPreviewerCtrl', cloudPresentResPreviewerCtrl);

  cloudPresentResPreviewerCtrl.$inject = ['$scope', '$q', '$rootScope', '$uibModalInstance', 'notificationService',
    'Lightbox', 'oedConfig', 'oedPresentResource', 'oedCloudPresentRes', 'cloudRes', 'resourceDef',
    'oedUserInfo', 'oedCloudPresentResItem']
  function cloudPresentResPreviewerCtrl($scope, $q, $rootScope, $uibModalInstance, notificationService,
   Lightbox, oedConfig, oedPresentResource, oedCloudPresentRes, cloudRes, resourceDef,
   oedUserInfo, oedCloudPresentResItem) {
    $scope.basePath = oedConfig.base();

    $scope.cloudRes = cloudRes;
    $scope.showImportCtrls = !_.has(cloudRes, '__type') && resourceDef;
    resourceDef = resourceDef || {};

    $scope.resourceDef = resourceDef;
    $scope.mouseOver = false;
    $scope.getLabel = function() {
      let label = $scope.isAllAdded() ? '全部已加出题筐' : '全部加入出题筐';
      if ($scope.mouseOver && label === '全部已加出题筐') {
        label = '全部移出出题筐';
      }
      return label;
    };

    $scope.onMouseOver = function() {
      $scope.mouseOver = true;
    };

    $scope.onMouseLeave = function() {
      $scope.mouseOver = false;
    };

    $scope.importBtnText = _.get(resourceDef, 'importBtnText',  '导入');

    function getCloudRes() {
      //
      // 如果有__type 这个属性表示是一个普通资源
      if (_.has(cloudRes, '__type')) {
        return oedCloudPresentRes.queryByPresentResId({
          id: cloudRes.id
        }).$promise;
      }

      return $q.when(cloudRes);
    }

    $scope.loading = getCloudRes().then(function(res) {
      $scope.res = oedPresentResource.getPreviewByCloudRes({
        res_id: res.id
      });
      $scope.owner = oedUserInfo.getByCloudPresentResourceId({
        id: res.id
      });

      return $q.all([$scope.res.$promise, $scope.owner.$promise]).then(function(data) {
        var res = data[0];
        var loadItems = _.map(res.items, function(item) {
          var cloudItem = oedCloudPresentResItem.getByResItemId({
            id: item.id
          });
          return cloudItem.$promise.then(function(ci) {
            ci.res = item;
            ci.owner = $scope.owner;
            return ci;
          });
        });

        return $q.all(loadItems).then(function(allItems) {
          $scope.items = allItems;
        });
      });
    });

    $scope.getItemName = function(item) {
      if (item.resourceName)
        return item.resourceName;

      if (item.flResource.name)
        return item.flResource.name;
      return '';
    };

    $scope.importItem = function(item, event) {
      if ($scope.isItemInResBox(item)) {
        return;
      }
      if ($scope.resourceDef.resBox) {
        $scope.resourceDef.resBox.addResItem(item.res)
      }

      // $scope.cloudResToAdd = {
      //   cloudRes:  item,
      //   resType: 'cloudPresentResItem',
      //   event: event
      // };
    };

    $scope.downloadItem = function(item, event) {
      event.preventDefault();
      event.stopPropagation();

      oedCloudPresentResItem.getDownload({
        id: item.id
      });

      window.open(downloadUtils.providerDownUrl(item.res), window._link_open_type);
    };

    $scope.showPreview = function(reses, idx) {
      Lightbox.openModal(reses, idx, {
        size: 'new-lib-light-box'
      });
    };

    $scope.close = function() {
      $uibModalInstance.close({});
    };

    $scope.importRes = function() {
      var deferred = $q.defer();
      $scope.loading = deferred.promise;
      deferred.promise.then(function() {
        notificationService.notify('info', '资源导入成功')

        $uibModalInstance.close('close');
      }, null, function(update) {
        $scope.progressMsg = update;
      }).finally(function() {
        $scope.progressMsg = '';
      });

      resourceDef.doImport([cloudRes], deferred, 'resource');
    };

    var fileTypes = {
      audio: {
        iconClass: 'oed-file-type-new fa fa-file-audio-o',
        tooltip: '音频'
      },
      file: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '文件'
      },
      image: {
        iconClass: 'oed-file-type-new fa fa-file-photo-o',
        tooltip: '图片'
      },
      link: {
        iconClass: 'oed-file-type-new fa fa-link',
        tooltip: '链接'
      },
      office: {
        iconClass: 'oed-file-type-new fa fa-file-word-o',
        tooltip: '文档'
      },
      video: {
        iconClass: 'oed-file-type-new fa fa-file-video-o',
        tooltip: '视频'
      },
      unknown: {
        iconClass: 'oed-file-type-new fa fa-file-o',
        tooltip: '其他文档'
      }
    };

    $scope.getItemFileTypeClass = function(item) {
      var url = _.get(item, 'webUrl');
      var fileType = _.isEmpty(url) ? _.get(item, 'flResource.fileType') : 'link';
      if (_.has(fileTypes, fileType)) {
        return fileTypes[fileType].iconClass;
      }

      return fileTypes.unknown.iconClass;
    };

    $scope.onCloudResItemClicked = function(res, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      var allReses = _.map($scope.items, 'res');

      var idx = _.findIndex($scope.items, res);
      if (idx < 0) {
        idx = 0;
      }
      oedCloudPresentResItem.getView({
        id: $scope.items[idx].id
      });

      Lightbox.openModal(allReses, idx, {
        size: 'new-lib-light-box'
      });
    };

    var titleTpl = _.template('<%=resName%> (<%=username%>)');
    $scope.getTitleText = function() {
      var resName = _.get($scope.cloudRes, 'res.name', '');
      resName = resName || _.get($scope, 'res.name');

      return titleTpl({
        resName: resName,
        username: _.get($scope, 'owner.name', '')
      });
    };
    var subTitleTpl = _.template('<%=lastUpdateDate%> 引用: <%=downloads%> 浏览: <%=views%>');
    $scope.getSubTitleText = function() {
      return subTitleTpl({
        lastUpdateDate: moment(_.get($scope.cloudTest, 'lastUpdatedDate')).format('YYYY-MM-DD'),
        downloads: _.get($scope.cloudRes, 'downloads', 0),
        views: _.get($scope.cloudRes, 'views', 0)
      });
    };

    $scope.getResBoxItems = function() {
      if (_.isFunction(resourceDef.getResItems)) {
        return resourceDef.getResItems();
      }

      return null;
    };

    $scope.getCount = function() {
      return $scope.getResBoxItems() ? $scope.getResBoxItems().length : 0
    }

    $scope.onDelete = function(res) {
      if (resourceDef && resourceDef.resBox) {
        return resourceDef.resBox.rmResItem(res.res)
      }
    }
    $scope.addOrRmAllToResBox = function() {
      if (resourceDef && resourceDef.resBox) {
        if (!resourceDef.resBox.isPresentResourceAdded($scope.cloudRes.res)) {
          resourceDef.resBox.addPresentResource($scope.cloudRes.res)
        } else {
          resourceDef.resBox.rmPresentResource($scope.cloudRes.res)
        }
      }
    }
    $scope.onAddResBoxItem = function(res, resType) {
      resourceDef.addItemToResBox(res, resType);
    };

    $scope.previewResBox = function(items) {
      if (_.isEmpty(items)) {
        return notificationService.notify('info', messages['importResource/noResourceAdded'])
      }

      $uibModalInstance.close({
        action: 'showResBox'
      });
    };

    function isItemInResBox(res, resType) {
      // if (!_.isFunction(_.get(resourceDef, 'canAddToResBox'))) {
      //   return false;
      // }

      // return !resourceDef.canAddToResBox(res, resType);
      if (resourceDef && resourceDef.resBox) {
        return resourceDef.resBox.isResItemAdded(res.res)
      }
      return false
    }

    $scope.isItemInResBox = function(res) {
      return isItemInResBox(res, 'cloudPresentResItem');
    };

    $scope.isAllAdded = function() {
      if (resourceDef && resourceDef.resBox) {
        return resourceDef.resBox.isPresentResourceAdded($scope.cloudRes.res)
      }
      return false
    };

    $scope.addAllToResBox = function(event) {
      if ($scope.isAllAdded()) {
        return;
      }

      $scope.cloudResToAdd = {
        cloudRes: cloudRes,
        resType: 'cloudPresentRes',
        event: event
      };
    };
  }
})();
