import { getRyKnowledget, getQuesKpByPartAndQuesIdx } from "app2/integration/ruanyun/ruanyunUtils"
import _ = require("lodash")

require('assets/app/controllers/examination/examReview/examReviewQues/examReviewQues.scss')
require('assets/app/controllers/examination/examEdit/paper/examEditPaperCtrl')

angular.module('app.examination.examReviewQuestion', [])
  .controller('examReviewQuesCtrl', examReviewQuesCtrl)

examReviewQuesCtrl.$inject = ['$scope', 'data', '$uibModalInstance', 'oedExamination', 'examApi']
function examReviewQuesCtrl($scope, data, $uibModalInstance, oedExamination, examApi) {

  function init() {
    $scope.examData = data.examData
    $scope.question = data.question
    $scope.questionTags = data.questionTags
    $scope.levelId = data.levelId
    $scope.relId = data.relId
    $scope.coursepathId = data.coursepathId
    $scope.indexLabel = data.indexLabel

    $scope.indexMap = data.indexMap

    oedExamination.getCoursePath({ id: $scope.coursepathId }).$promise
    .then((coursepath) => {
      getRyKnowledget(examApi, coursepath.subjectId, $scope.levelId)
      .then((kps) => {
        $scope.knowledgePoints = kps
      })
    })

  }

  $scope.close = () => $uibModalInstance.close(true)

  $scope.getQuesKnowledgePoints = () => {
    return getQuesKpByPartAndQuesIdx($scope.questionTags, $scope.knowledgePoints)
  }

  $scope.getQuestionNumber = (q) => {
    return _.get($scope.indexMap, _.get(q, 'id', _.get(q, 'uuid', '')))
  }

  init()
}
