import * as _ from 'lodash'
require('assets/app/controllers/examination/examReport/examReportHeader/headerClassTab/headerClassTab.scss')

angular.module('app.examination.examReport.header.classTab', [])
  .component('headerClassTab', {
    bindings: {
      'sessions': '<',
      'selectedSessionId': '<',
      'onToggleSession': '&'
    },
    controller: headerClassTabCtrl,
    template: < string > require('./headerClassTab.html'),
  })


headerClassTabCtrl.$inject = ['moment']
function headerClassTabCtrl(moment) {
  const ctrl = this
  ctrl.curPage = 0
  ctrl.swiper = { swiper: {} }
  ctrl.swiperParams = {
    slidesPerView: 'auto',
    mousewheelControl: true,
  }

  ctrl.getMaxCpsPage = () => {
    const resSlides = $('#ex-class-list-slides').width()
    // 120是列表左右的margin和，180为一个item的宽度
    return Math.ceil(((_.size(ctrl.sessions) * 180) - (resSlides - 90)) / 170)
  }

  ctrl.checkIsSelceted = (session: any) => {
    return ctrl.selectedSessionId == _.get(session, 'examSession.id', 0)
  }

  ctrl.onToggle = (session: any) => {
    const sessionId = _.get(session, 'examSession.id', 0)
    if (sessionId === ctrl.selectedSessionId) {
      return
    }
    ctrl.onToggleSession({ session })
  }

  ctrl.toTopPage = () => {
    ctrl.curPage = 0
    ctrl.swiper.swiper.slideTo(0)
  }

  ctrl.toPrePage = () => {
    if (ctrl.curPage <= 0) {
      return
    }
    ctrl.curPage -= 1
    ctrl.swiper.swiper.slideTo(ctrl.curPage)
  }

  ctrl.toNextPage = () => {
    if (ctrl.curPage >= ctrl.getMaxCpsPage()) {
      return
    }
    ctrl.curPage += 1
    ctrl.swiper.swiper.slideTo(ctrl.curPage)
  }

  ctrl.toBottomPage = () => {
    ctrl.curPage = ctrl.getMaxCpsPage()
    ctrl.swiper.swiper.slideTo(ctrl.getMaxCpsPage())
  }

  ctrl.getClassTime = (session: any) => {
    const startTime = _.get(session, 'examSession.startTime', 0)
    return moment(startTime).format('YYYY.MM.DD  HH:mm')
  }
}
