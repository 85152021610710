import { TalSubjectDTO } from 'app2/integration/talcloud/models'
import * as _ from 'lodash'

require('app2/prepareCourse/cloud/presentResource/anHuiPlatform/anHuiPlatformLevelSubjectSelector.directive')

angular.module('app.prepareCourse2.cloud.core.anHuiPlatformLevelSubjectDialog', [
  'app.prepareCourse2.cloud.core.anHuiPlatformLevelSubjectSelector',
])
.controller('anHuiPlatformLevelSubjectDialogCtrl', anHuiPlatformLevelSubjectDialogCtrl)

anHuiPlatformLevelSubjectDialogCtrl.$inject = ['$uibModalInstance', 'data']
function anHuiPlatformLevelSubjectDialogCtrl($uibModalInstance: any, data: any) {
  this.dataPackage = data
  this.onClose = () => $uibModalInstance.dismiss('cancel')
  this.ensure = (phase, grade, subject) => {
    $uibModalInstance.close({phase, grade, subject})
  }
}
