import {Injectable} from '@angular/core';
import {FullLoadingComponent} from 'app/shared/loading/full-loading/full-loading.component';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {isNullOrUndefined} from 'util';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadings: {[key: string]: BsModalRef} = {}
  constructor(
    private modalService: BsModalService
  ) {
  }

  public show(mBackdrop =  false, key?: string) {
    setTimeout(() => {
      const k = key ? key : '1';
      if (this.loadings[k]) {
        this.hideIf(k)
      }
      const options = new ModalOptions();
      options.backdrop = mBackdrop;
      options.animated = false;
      options.ignoreBackdropClick = true;
      options.class = 'modal-content-for-loading';
      const ref = this.modalService.show(FullLoadingComponent, options);
      this.loadings[k] = ref
    })
  }

  // @eslint-ignore
  public hide(key?: string) {
    const k = key ? key : 1;
    const modal = this.loadings[k];
    if (!isNullOrUndefined(modal)) {
      this.closeTime(modal);
      this.loadings[key] = null
    } else {
      setTimeout(() => {
        this.hideIf(key)
      })
    }
  }

  public closeTime(ref: BsModalRef) {
    setTimeout(() => {
      return ref.hide();
    }, 100);
  }

  private hideIf(key?: string) {
    const k = key ? key : '1';
    const modal = this.loadings[k];
    if (!isNullOrUndefined(modal)) {
      this.closeTime(modal);
      this.loadings[key] = null
    }
  }

  public withLoading = (backdrop = false, key?: string) => {
    this.show(backdrop, key)
    return finalize(() => this.hide(key))
  }
}
