import { RuanyunCourse, RuanyunAPiResult, RuanyunBookVersion, RuanyunCourseMapping, RuanyunQuestionType, RuanyunChapterSection, RuanyunSelectedMaterial} from 'app2/integration/ruanyun/model'
import * as _ from 'lodash'

export const convertChaptersToRaySections = (chpaters: RuanyunChapterSection[], depth: any) => {
  return _.map(chpaters, (c, idx) => {
   const child: any = convertChaptersToRaySections(c.Children, depth + 1)
   return {
     $opened: true,
     child,
     depth,
     id: c.NodeId,
     item_id: c.NodeId,
     name: c.NodeName,
     parent_id: c.ParentNodeId,
     sort: idx,
   }
 })
}

const convertRyKpsToRayKps = (chpaters: any[], depth: any) => {
  return _.map(chpaters, (c, idx) => {
   const child: any = convertRyKpsToRayKps(c.Children, depth + 1)
   return {
     $opened: true,
     child,
     depth,
     id: c.id,
     item_id: c.id,
     name: c.name,
     parent_id: c.pid,
     sort: idx,
   }
 })
}

export const getUserSession = (ruanyunResource: any, userCode: string, courseId: number) => {
    return ruanyunResource.getUserSession({
        UserCode: userCode,
        UserTrueName: 'teacher1',
        UserTypeId: 1,
        CourseId: courseId,
        gradeId: 0,
    }).$promise.then(r => {
    })
}

function treeToArray(childs: any[]) {
  return _.flatten(_.map(childs, (child: any) => _.concat(child, _.flatten(treeToArray(child.child)))))
}

export const getRyKnowledget = (examApi: any, subjectId: number, levelId: number) => {
  return examApi.getKnowledgePoint({
    subjectId,
    levelId,
  }).$promise.then((data) => {
     if (_.isEmpty(data)) {
       return []
     } else {
       const kps = [{child: convertRyKpsToRayKps(data, 0)}]
       return _.keyBy(treeToArray(_.get(kps, '0', {}).child), 'id')
     }
  })
}

export const getQuesKpByPartAndQuesIdx = (curQuesTags, allKnowledgePoints) => {
  let kpNameByAllKps = ''
  let kpNameByTags = ''
  let isUseKpNameByTags = false
  _.forEach(curQuesTags, tag => {
    const tagName = _.get(tag, 'tagName', '')
    if (tagName == 'ruanyun.KnowledgePointId') {
      const numVal = _.get(tag, 'numVal', '')
      kpNameByAllKps += `\xa0${_.get(allKnowledgePoints, `${numVal}.name`, '')}`
    }
    if (tagName == 'ruanyun.KnowledgePointName') {
      const strVal = _.get(tag, 'strVal', '')
      kpNameByTags += `\xa0${strVal}`
      if (!isUseKpNameByTags) {
        // 只要有一个ruanyun.KnowledgePointName有值就使用tags带的name
        isUseKpNameByTags = strVal != ''
      }
    }
  })
  if (kpNameByTags == '') {
    kpNameByTags = ''
  }
  if (isUseKpNameByTags) {
    return kpNameByTags
  }
  if (kpNameByAllKps == '') {
    kpNameByAllKps = ''
  }
  return kpNameByAllKps
}
