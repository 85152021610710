import * as _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { ConsoleService } from '@ng-select/ng-select/ng-select/console.service'

// 目录最外层的parent id为1
export const dirListToTree = (list: any[]) => listToTree(1, list)
// 知识点最外层的parent id为0
export const kpListToTree = (list: any[]) => listToTree(0, list)

const listToTree = (outerMostId: number, list: any[]) => {
  const map = {}
  let item, i
  list.push({ id: outerMostId, children: [] })
  for (i = 0; i < list.length; i++) {
    map[list[i].id] = i
    list[i].children = []
  }
  for (i = 0; i < list.length; i++) {
    item = list[i]
    if (!_.isNumber(item.parentId)) continue
    if (map[item.parentId] >= 0) {
      list[map[item.parentId]].children.push(item)
    }
  }
  return list[list.length - 1].children
}

export const buildDefOpenMap = (list: any[], openItems: any[]) => buildOpenListMap(list, openItems, false)

export const buildKpDefOpenMap = (list: any[], openItems: any[]) => buildOpenListMap(list, openItems, true)

const buildOpenListMap = (list: any[], openItems: any[], isKp: boolean) => {
  const map = {}
  let item, i
  _.forEach(list, (item, idx) => map[item.id] = idx)
  const defOpenMap = {}
  for (i = 0; i < openItems.length; i++) {
    item = openItems[i]
    if (isKp) {
      addKpOpenItem(item, list, map, defOpenMap)
    } else {
      addOpenItem(item, list, map, defOpenMap)
    }
  }
  return defOpenMap
}

const addOpenItem = (item: any, list: any[], idxMap: any, openMap: any) => {
  openMap[_.get(item, 'id', 0)] = item
  const parentId = _.get(item, 'parentId', 0)
  if (parentId <= 1) return
  const idx = idxMap[parentId]
  if (idx < 0) return
  const parentItem = list[idx]
  addOpenItem(parentItem, list, idxMap, openMap)
}

const addKpOpenItem = (item: any, list: any[], idxMap: any, openMap: any) => {
  const parentId = _.get(item, 'pid', 0)
  if (parentId <= 1) return
  const idx = idxMap[parentId]
  if (idx < 0) return
  const parentItem = list[idx]
  openMap[parentId] = parentItem
  addOpenItem(parentItem, list, idxMap, openMap)
}

export const buildTaskByQues = (body: any) => {
  // 题目中可能会出现图片资源的字段 question, answerDetails, choices, leftOpts, rightOpts
  const question = _.get(body, 'question.question', '')
  const answerDetails = _.get(body, 'question.answerDetails', '')
  const choices = _.map(_.get(body, 'question.choices', []), c => _.get(c, 'content', ''))
  const leftOpts = _.map(_.get(body, 'question.leftOpts', []), c => _.get(c, 'content', ''))
  const rightOpts = _.map(_.get(body, 'question.rightOpts', []), c => _.get(c, 'content', ''))

  const richTexts = _.concat([question, answerDetails], choices, leftOpts, rightOpts)
  const needDlTexts = _.filter(richTexts, t => _.includes(t, '<img'))
  if (_.size(needDlTexts) <= 0) {
    return []
  }
  return _.chain(needDlTexts)
    .map(t => buildLabelKey(t)).flatten().uniq()
    .filter(f => !_.isEmpty(buildTaskBody(f)))
    .map(k => ({ labelKey: k, taskBody: buildTaskBody(k) }))
    .value()
}

const imgReg: any = /<img.*?(?:>|\/>)/gi
const buildLabelKey = (str: any) => str.match(imgReg)

const srcReg: any = /src=['"]?([^'"]*)['"]?/i
const buildTaskBody = (str: any) => {
  const url = str.match(srcReg)[1]
  if (!_.startsWith(url, 'http://') && !_.startsWith(url, 'https://')) {
    return {}
  }
  return {
    url: str.match(srcReg)[1],
    filename: uuidv4(),
    fileType: url.substr(url.length - 3)
  }
}

const buildRayImgLabel = (uuid: string, name: string) => {
  if (uuid === '' || name === '') return ''
  const src = `/ray/resource/uuid/${uuid}/src`
  return `<img src="${src}" title="${name}" alt="${src}">`
}

export const getRayImgLabelByTask = (taskRes: any) => {
  const resourceUUID = _.get(taskRes, 'resourceUUID', '')
  const filename = _.get(taskRes, 'filename', '')
  return buildRayImgLabel(resourceUUID, filename)
}

export const buildRayImgLabelByFormula = (formula: any) => {
  const uuid = _.get(formula, 'response.resource.md5', '')
  const name = _.get(formula, 'response.resource.md5', '')
  return buildRayImgLabel(uuid, name)
}

const buildRayInfo = (str: string, taskInfoMap: any) => {
  if (!_.includes(str, '<img')) return str
  const imgKeys = buildLabelKey(str)
  for (const k of imgKeys) {
    const url = k.match(srcReg)[1]
    if (!_.startsWith(url, 'http')) continue
    const info = _.get(taskInfoMap, k, '')
    const rayImgLabel = _.get(info, 'rayImgLabel', '')
    str = _.replace(str, k, rayImgLabel)
  }
  return str
}

export const buildCreateQuesBodyByTaskInfo = (body: any, taskInfoList: any[]) => {
  const taskInfoMap = _.keyBy(taskInfoList, info => _.get(info, 'labelKey', ''))

  const question = _.get(body, 'question.question', '')
  const answerDetails = _.get(body, 'question.answerDetails', '')
  const choices = _.get(body, 'question.choices', [])
  const leftOpts = _.get(body, 'question.leftOpts', [])
  const rightOpts = _.get(body, 'question.rightOpts', [])

  _.set(body, 'question.question', buildRayInfo(question, taskInfoMap))
  _.set(body, 'question.answerDetails', buildRayInfo(answerDetails, taskInfoMap))
  _.set(body, 'question.choices', _.map(choices, c => ({ content: buildRayInfo(_.get(c, 'content', ''), taskInfoMap) })))
  _.set(body, 'question.leftOpts', _.map(leftOpts, c => ({ content: buildRayInfo(_.get(c, 'content', ''), taskInfoMap) })))
  _.set(body, 'question.rightOpts', _.map(rightOpts, c => ({ content: buildRayInfo(_.get(c, 'content', ''), taskInfoMap) })))
  return body
}

export const replaceFormulaRes = (str: string, map: any) => {
  // 将str中的公式根据转换好的结果替换为img标签
  const rpList = _.chain(str).split('$$').filter((r, i) => i % 2 != 0).value()
  _.forEach(rpList, k => {
    const rayImgLabel = buildRayImgLabelByFormula(_.get(map, k, {}))
    str = _.replace(str, `$$${k}$$`, rayImgLabel)
  })
  return str
}

export const validateQuestion = (question: any) => {

  if (_.includes(['singlevote', 'multivote', 'qa'], question.type)) {
    // set fake answer for vote type, as most of the login in fleaf will ignore test without answer
    // It's easier to add this fake answer instead of changing fleaf logic.
    question.answer = '-1';
  }

  if (_.isEmpty(question.answer) && question.type != 'synthetic') {
    return {
      valid: false,
      message: '请为当前题目设置答案！'
    };
  }

  if ((question.type == 'singlechoice' || question.type == 'multichoice') && _.isEmpty(question.choices)) {
    return {
      valid: false,
      message: '请为当前题目设置选项！'
    };
  }

  if (question.type == 'connect' && (_.isEmpty(question.leftOpts) || _.isEmpty(question.rightOpts))) {
    return {
      valid: false,
      message: '请为当前题目设置选项！'
    };
  }

  // 对于填空题应当检查是否所有的空都设置了答案
  if (question.type === 'fill' && !isAllBlankHasAnswerSet(question)) {
    return {
      valid: false,
      message: '填空题需要设置所有非老师评判空的答案'
    };
  }
  return {
    valid: true
  };
}

function isAllBlankHasAnswerSet(ques) {
  const content = _.get(ques, 'question', '');
  const count = (content.match(/__+/g) || []).length;
  //
  // 对于没有设置空的填空题也认为没有答案
  if (count === 0) {
    return false;
  }

  const answerArray = convertFillAnswerStringToArray(_.get(ques, 'answer'));
  if (_.size(answerArray) != count) {
    return false;
  }

  const answerTypes = convertFillAnswerTypesToArray(_.get(ques, 'answer'), _.size(answerArray));
  return _.findIndex(_.zip(answerTypes, answerArray), function (p) {
    return p[0] != 'manual-score' && _.isEmpty(p[1]);
  }) < 0;
}

function convertFillAnswerStringToArray(quesAnswer) {
  let ret = [];
  //
  // 把问题答案解析成数组
  if (!_.isEmpty(quesAnswer)) {
    const answers = parseJson(quesAnswer, {});
    ret = _.map(answers.answer, function (opts) {
      return _.map(opts, function (opt) {
        return {
          text: opt
        };
      });
    });
  }

  return ret || [];
}

function parseJson(str, defaultVal) {
  try {
    return angular.fromJson(str);
  } catch (ex) {
    console.error(str + ' is not a valid json string');
    return defaultVal;
  }
}

function convertFillAnswerTypesToArray(answerJson, count) {
  let ret = [];
  if (!_.isEmpty(answerJson)) {
    const answersMap = parseJson(answerJson, {});
    ret = _.get(answersMap, 'answerTypes', []);
  }

  // 数目不够，即填充默认值 exact-match
  if (_.size(ret) < count) {
    ret = _.concat(ret, _.fill(Array(count - _.size(ret)), 'exact-match'));
  }
  // 超出空的数目则截取
  else if (_.size(ret) > count) {
    ret = _.slice(ret, 0, count);
  }

  return ret;
}

