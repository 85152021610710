
require('assets/app/resources.js');
require('assets/app/controllers/signInCtrl.js');
require('assets/app/controllers/signupCtrl.js');
require('assets/app/controllers/getVerifyCodeCtrl.js');
require('assets/app/controllers/signOutCtrl.js');
require('assets/app/controllers/reviewCtrl.js');
require('assets/app/controllers/signin/findPassword.js');
require('assets/app/controllers/signin/activateCtrl.js');
require('assets/app/controllers/signin/changePasswordCtrl.js');
require('assets/app/controllers/prepareLessonCtrl.js');
require('assets/app/controllers/examinationCtrl.ts');
require('assets/app/controllers/resCenterCtrl.js');
require('assets/app/controllers/courseListCtrl.js');
require('assets/app/controllers/newCourseListCtrl.js');
require('assets/app/controllers/rcCourseListCtrl.js');
require('assets/app/controllers/prepareCourseCtrl.js');
require('assets/app/controllers/newPrepareCourseCtrl.js');
require('assets/app/controllers/postLogin.js');
require('assets/app/controllers/postLogin/createSchoolCtrl.js');
require('assets/app/controllers/prepareCourse/addCourseCtrl.js');
require('assets/app/controllers/prepareCourse/openCtrl.js');
require('assets/app/controllers/prepareCourse/editInfoCtrl.js');
require('assets/app/controllers/prepareCourse/objectiveTestCtrl.js');
require('assets/app/controllers/prepareCourse/subjectiveTestCtrl.js');
require('assets/app/controllers/prepareCourse/addSubjectiveTestDialogCtrl.js');
require('assets/app/controllers/prepareCourse/editSubjectiveTestCtrl.js');
require('assets/app/controllers/prepareCourse/presentResourceCtrl.js');
require('assets/app/controllers/prepareCourse/editPresentResCtrl.js');
require('assets/app/controllers/prepareCourse/addWebUrlItemCtrl.js');
require('assets/app/controllers/prepareCourse/addMicroLessonRecordCtrl.js');
require('assets/app/controllers/prepareCourse/offlineResourceCtrl.js');
require('assets/app/controllers/prepareCourse/teachingDesignCtrl.js');
require('assets/app/controllers/prepareCourse/resourcePreviewCtrl.js');
require('assets/app/controllers/prepareCourse/importFromLibraryCtrl.js');
require('assets/app/controllers/prepareCourse/importFromLibraryByResIdCtrl.js');
require('assets/app/controllers/prepareCourse/cloudTestPreviewer.js');
require('assets/app/controllers/prepareCourse/cloudSubjectiveTestPreviewer.js');
require('assets/app/controllers/prepareCourse/cloudPresentResPreviewer.js');
require('assets/app/controllers/prepareCourse/cloudTeachingPatternPreviewer.js');
require('assets/app/controllers/prepareCourse/objectiveTestPreviewerCtrl.js');
require('assets/app/controllers/prepareCourse/subjectiveTestPreviewerCtrl.js');
require('assets/app/controllers/prepareCourse/presentResPreviewerCtrl.js');
require('assets/app/controllers/prepareCourse/previewIntelligentPaperCtrl.js');

require('assets/app/controllers/teachCtrl.js');
require('assets/app/controllers/queryCtrl.js');
require('assets/app/controllers/manageCtrl.js');
require('assets/app/controllers/manage/personalSettingsCtrl.js');
require('assets/app/controllers/manage/microLessonRecordCtrl.js');
require('assets/app/controllers/manage/teachingMaterialCtrl.js');
require('assets/app/controllers/manage/myClassesCtrl.js');
require('assets/app/controllers/manage/classHourManagementCtrl.js');
require('assets/app/controllers/manage/editClassInfoDialogCtrl.js');
require('assets/app/controllers/manage/reviewSchoolCreationCtrl.js');

require('assets/app/controllers/manage/reviewJoinSchoolRequestCtrl.js');
require('assets/app/controllers/manage/manageCoursesCtrl.js');
require('assets/app/controllers/manage/importFromCoursePathsDialogCtrl.js');
require('assets/app/controllers/manage/manageSchoolCtrl.js');
require('assets/app/controllers/manage/manageAreaCtrl.js');
require('assets/app/controllers/manage/editSchoolInfoDialogCtrl.js');
require('assets/app/controllers/manage/setSchoolAdminDialogCtrl.js');
require('assets/app/controllers/manage/setSchoolAdminsDialogCtrl.js');
require('assets/app/controllers/manage/assignActivateCodeCtrl.js');
require('assets/app/controllers/manage/viewSchoolActivateCodesCtrl.js');
require('assets/app/controllers/manage/manageTeachersCtrl.js');
require('assets/app/controllers/manage/updateSchoolInfoCtrl.js');
require('assets/app/controllers/review/coursePathsCtrl.js');
require('assets/app/controllers/review/classSessionsCtrl.js');
require('assets/app/controllers/review/classSessionOverviewCtrl.js');
require('assets/app/controllers/review/objectiveTestSessionsCtrl.js');
require('assets/app/controllers/review/testSessionStatsCtrl.js');
require('assets/app/controllers/review/testSessionRankingCtrl.js');
require('assets/app/controllers/review/questionAnswerDetailsCtrl.js');
require('assets/app/controllers/review/testSessionReviewForStudentCtrl.js');
require('assets/app/controllers/review/testSessionReviewForStudentDialogCtrl.js');
require('assets/app/controllers/review/subjectiveTestSessionsCtrl.js');
require('assets/app/controllers/review/subjectiveTestSessionDetailsCtrl.js');
require('assets/app/controllers/review/objectiveTestSessionDetailsCtrl.ts');
require('assets/app/controllers/review/questionReviewDetailsCtrl.js');
require('assets/app/controllers/review/boardContentViewerCtrl.js');
require('assets/app/controllers/review/interactDetailsViewerCtrl.js');
require('assets/app/controllers/review/objStudentAnswerViewCtrl.js');
require('assets/app/controllers/review/testInteractDetailsViewerCtrl.js');
require('assets/app/controllers/review/tmSessionsCtrl.js');
require('assets/app/controllers/review/tmSessionSummary.js');
require('assets/app/controllers/review/preResSessionsCtrl.js');
require('assets/app/controllers/review/preResSessionDetailsCtrl.js');
require('assets/app/controllers/review/handWritingPreviewDioalog.js');
require('assets/app/controllers/student/reviewExportCtrl.js');
require('assets/app/controllers/signinStudentCtrl.js');
require('assets/app/controllers/student/studentCtrl.js');
require('assets/app/controllers/student/studentHomeCtrl.js');
require('assets/app/controllers/student/studentExportPdfCtrl.js');
require('assets/app/controllers/student/studentSignOutCtrl.js');
require('assets/app/directives/commentComposer.js');
require('assets/app/directives/menuUserInfo.js');
require('assets/app/directives/studentInfo.js');
require('assets/app/directives/onDomLoadedRender.js');
require('assets/app/directives/videoFix.js');
require('assets/app/directives/bindHtmlCompile.js');
require('assets/app/directives/sectionName.js');
require('assets/app/directives/hoverFix.js');
require('assets/app/directives/relativeHeightToDocument.js');
require('assets/app/directives/styleToClientHeight.js');
require('assets/app/directives/divKeepRatio.js');
require('assets/app/directives/imageOnLoad.js');
require('assets/app/controllers/prepareCourse/taskListCtrl.js');
require('assets/app/controllers/prepareCourse/taskDesignCtrl.js');
require('assets/app/controllers/prepareCourse/addTaskListCtrl.js');
require('assets/app/controllers/prepareCourse/addTaskCtrl.js');
require('assets/app/controllers/prepareCourse/publishTaskListCtrl.js');
require('assets/app/controllers/prepareCourse/publishTmCtrl.js');
require('assets/app/controllers/prepareCourse/selectStudentsCtrl.js');

require('assets/app/controllers/student/studentTasksCtrl.js');
require('assets/app/controllers/student/studentCourseTasksCtrl.js');
require('assets/app/controllers/student/studentTaskDetailsCtrl.js');
require('assets/app/controllers/student/studentTaskCommentsCtrl.js');
require('assets/app/controllers/student/studentTaskDetailsResCtrl.js');
require('assets/app/controllers/student/studentTaskDetailsObjectiveTestCtrl.js');
require('assets/app/controllers/student/studentTaskDetailsSubjectiveTestCtrl.js');
require('assets/app/controllers/student/studentTaskDetailsPresentResourceCtrl.js');
require('assets/app/controllers/student/studentSubjectiveTestPeerReviewCtrl.js');
require('assets/app/controllers/student/studentSubjectiveTestPeerReviewAllStudentsCtrl.js');
require('assets/app/controllers/student/studentSubjectiveTestPeerReviewStudentCtrl.js');
require('assets/app/controllers/homework/homeworkCtrl.js');
require('assets/app/controllers/homework/taskListsCtrl.js');
require('assets/app/controllers/homework/taskListsCtrlV2.js');
require('assets/app/controllers/homework/taskListsForStatusCtrl.js');
require('assets/app/controllers/homework/taskListsForStatusCtrlV2.js');
require('assets/app/controllers/homework/taskListPubDetailsCtrl.js');
require('assets/app/controllers/homework/taskListPubDetailsSummaryCtrl.js');
require('assets/app/controllers/homework/taskListPresentResourceViewCtrl.js');
require('assets/app/controllers/homework/taskListPubDetailsSummaryForClassCtrl.js');
require('assets/app/controllers/homework/reviewStudentTaskDetailsCtrl.js');
require('assets/app/controllers/homework/reviewStudentTaskDetailsResCtrl.js');
require('assets/app/controllers/homework/reviewStudentTaskDetailsPresentResourceCtrl.js');
require('assets/app/controllers/homework/reviewStudentTaskDetailsObjectiveTestCtrl.js');
require('assets/app/controllers/homework/reviewStudentTaskDetailsSubjectiveTestCtrl.js');
require('assets/app/controllers/homework/reviewStudentTaskCommentsCtrl.js');
require('assets/app/controllers/homework/reviewSubjectiveTestPeerReviewCtrl.js');
require('assets/app/controllers/homework/reviewSubjectiveTestPeerReviewAllStudentsCtrl.js');
require('assets/app/controllers/homework/reviewSubjectiveTestPeerReviewStudentCtrl.js');
require('assets/app/controllers/homework/taskListPubObjectiveTestSummaryCtrl.js');
require('assets/app/controllers/homework/testSessionSubmitDetailsCtrl.js');
require('assets/app/controllers/homework/designTaskCtrl.js');
require('assets/app/controllers/homework/notifyUnpublishedTaskCtrl.js');
require('assets/app/controllers/homework/publishTaskListCtrl.js');
require('assets/app/controllers/homework/refreshPublishTaskListCtrl.js');
require('assets/app/controllers/homework/updatePublishTaskListCtrl.js');

require('assets/app/utils/videoLeakFix.js');
