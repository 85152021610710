import { RuanyunAPiResult, RuanyunBookVersion, RuanyunCourseMapping } from 'app2/integration/ruanyun/model'
import * as _ from 'lodash'

angular.module('app.prepareCourse2.cloud.core.ruanyunMaterialDialog', [
])
.controller('ruanyunMaterialDialogCtrl', ruanyunMaterialDialogCtrl)

ruanyunMaterialDialogCtrl.$inject = ['$uibModalInstance', 'data', '$scope', 'ruanyunResource']
function ruanyunMaterialDialogCtrl($uibModalInstance: any, data: any, $scope: any, ruanyunResource: any) {

  $scope.onCourseSelected = (course: RuanyunCourseMapping) => {
    $uibModalInstance.close({
      selectedBookVersion: $scope.selectedBookVersion,
      selectedCourseMapping: course,
    })
  }

  $scope.onBookVersionSelected = (bookVersion: RuanyunBookVersion) => {
    $scope.selectedBookVersion = bookVersion
    return ruanyunResource.getCourseMappings({
      BookVersionId: bookVersion.BookVersionId
    }).$promise.then((result: RuanyunAPiResult<RuanyunCourseMapping[]>) => {
      $scope.courseMappings = result.Value
    })
  }

  $scope.isSubjectSelected = (course: RuanyunCourseMapping) => {
    return $scope.selectedCourseMapping.CourseMappingId === course.CourseMappingId
  }

  $scope.selectedBookVersion = data.selectedBookVersion
  $scope.selectedCourseMapping = data.selectedCourseMapping
  $scope.bookVersions = data.bookVersions
  $scope.curTabIdx = _.findIndex($scope.bookVersions, (b: RuanyunBookVersion) => b.BookVersionId === data.selectedBookVersion.BookVersionId) + 1
  $scope.onBookVersionSelected($scope.selectedBookVersion)
  $scope.onClose = () => $uibModalInstance.dismiss('cancel')
}
