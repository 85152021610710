(function() {
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.manageTeachers', {
        url: '/manageTeachers',
        template: require('assets/templates/manage/manageTeachers.html'),
        controller: 'manageTeachersCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('manageTeachersCtrl', manageTeachersCtrl);

  manageTeachersCtrl.$inject = ['$scope', '$state', '$stateParams', '$http', 'dialogs',
    'notificationService', 'userInfo', 'oedUserInfo', 'oedSchool', 'oedSchoolJoinRequest', 'oedOem']
  function manageTeachersCtrl($scope, $state, $stateParams, $http, dialogs,
    notificationService, userInfo, oedUserInfo, oedSchool, oedSchoolJoinRequest, oedOem) {
    $scope.currentState.title = '教师管理';
    $scope.enableManage = oedOem.curOem() != 'hal2'
    $scope.pageSize = 20;
    $scope.curPage = $stateParams.page || 1;
    $scope.name = $stateParams.query;
    $scope.schoolAdmin = false;
    $scope.query = {
      reviewed: true,
      unReviewed: true,
      search: null
    };

    $scope.applyFilter = function() {
      $scope.query.search = '';
      $scope.filterResult();
    };

    $scope.doSearch = function(arg) {
      _.assign($scope.query, {
        reviewed: true,
        unReviewed: true
      });

      $scope.filterResult();
    };

    var matchProps = function(obj, props, val) {
      return _.findIndex(props, function(prop) {
        return _.includes(obj[prop], val);
      }) >= 0;
    };

    $scope.filterResult = function() {
      $scope.teachers = _.filter($scope.allTeachers, function(tea) {
        if (tea.reviewedByAdmin && !$scope.query.reviewed) {
          return false;
        }

        if (!tea.reviewedByAdmin && !$scope.query.unReviewed) {
          return false;
        }

        var search = _.trim($scope.query.search);
        if (search) {
          return matchProps(tea, ['name', 'mobile', 'idNo'], search);
        }

        return true;
      });

      $scope.curPage = 1;
      $scope.totalNums = _.size($scope.teachers);

      $scope.onPageChange(null, 1);
    };

    $scope.onPageChange = function(name, curPage) {
      var start = (curPage - 1) * $scope.pageSize;
      var end = start + $scope.pageSize;
      const tempTeachers =  _.slice($scope.teachers, start, end);
      const uidMap = tempTeachers.map((item) => {
        return item.id;
      });
      oedUserInfo.batchQueryByUid({
        uid: _.map(tempTeachers, 'id')
      }).$promise.then(res => {
        const arr = _.map(res, item =>{
          return item.uid;
        })
        _.each(tempTeachers, t => {
          if(arr.indexOf(t.id) >= 0) {
            t.isOpenClound = true;
          } else {
            t.isOpenClound = false;
          }
        });
        $scope.curTeachers = tempTeachers;
      });
    };
    $scope.changeStatus = (tea) => {
      tea.isOpenClound = !tea.isOpenClound;
      if (tea.isOpenClound) {
        const arr = [{
          "uid": tea.id,
          "objectiveTest": true,
          "subjectiveTest": true,
          "question": true,
          "presentResource": true,
          "presentResourceItem": true,
          "teachingPattern":true
        }];
        oedUserInfo.batchCreateOrUpdate(arr).$promise.then(r => {
        })
      } else {
        oedUserInfo.deleteResourceAuth({uid: tea.id}).$promise.then(r => {
        })
      }
    }
    userInfo.then(function(info) {
      $scope.userInfo = info;
      $scope.allTeachers = oedUserInfo.queryTeachersBySchool({
        schoolId: info.schoolId
      });
      oedSchool.getSchoolAdminsBySchoolIds([_.parseInt(info.schoolId)]).$promise.then(res => {
        $scope.uids = _.map(res[_.parseInt(info.schoolId)], 'uid');
        $scope.schoolAdmin = _.includes($scope.uids, _.parseInt(info.uid));
      });
      $scope.loading = $scope.allTeachers.$promise;

      $scope.loading.then(function() {
        $scope.loaded = true;
        $scope.filterResult();
      });
    });

    $scope.approve = function(user) {
      var dlg = dialogs.confirm('确认审核教师', '确定审核通过 "' + user.name + '" 加入学校吗？');
      dlg.result.then(function() {
        $scope.loading = oedSchoolJoinRequest.approve({
          id: user.id
        }).$promise;

        $scope.loading.then(function() {
          notificationService.notify('info', '信息更新成功')
          user.reviewedByAdmin = true;
        });
      });
    };

    $scope.setMaster = function(user) {
      var dlg = dialogs.confirm('确认设置校长', '确定设置 "' + user.name + '" 为校长吗？');
      dlg.result.then(function() {
        $scope.loading = oedSchool.setMaster({
          uid: user.id
        }).$promise;

        $scope.loading.then(function() {
          notificationService.notify('info', '信息更新成功')
        });
      });
    };

    $scope.remove = function(user) {
      const dlg = dialogs.confirm('确认移除教师', '确定把 "' + user.name + '" 从学校中移除吗？移除教师同时会把此教师从所有已经加入的班级中移除.');
      $scope.loading = dlg.result.then(() =>  {
        return oedSchool.removeTeacher({
          uid: user.id
        }).$promise.then(() => {
          return oedUserInfo.queryTeachersBySchool({
            schoolId: $scope.userInfo.schoolId
          }).$promise.then((res) => {
            $scope.allTeachers = res
          }).then(() => {
            $scope.loaded = true;
            $scope.filterResult();
            notificationService.notify('info', '信息更新成功')
          });
        });
      });
    };

    $scope.resetPassword = function(user) {
      console.log(JSON.stringify(user))
      oedUserInfo.getDefaultPasswordByUid({uid: user.id}).$promise.then(function (pw) {
        const dlg = dialogs.confirm('确认重置密码', '确定重置密码为:' + pw.plainPassword );
        $scope.loading = dlg.result.then(() => {
          return oedUserInfo.resetPassword({
            id: user.id,
            resetThirdParty: oedOem.curOem() === 'hal2',
          }).$promise.then((resp) => {
            let message
            if (!resp.success) {
              message = `重置教师:${user.name}的密码失败,原因: ${resp.message}`
            } else {
              message = `已将"${user.name}"的密码重置为"${resp.data}"`
            }
            notificationService.notify('info', message)
          });
        });
      });
    };
  }
})();
