(function() {
  'use strict';

  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.reviewStudentTaskDetails.res.subjectiveTest', {
        url: '/subjectiveTest/:origTestId',
        template: require('assets/templates/homework/reviewStudentTaskDetailsSubjectiveTest.html'),
        controller: 'reviewStudentTaskDetailsSubjectiveTestCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('reviewStudentTaskDetailsSubjectiveTestCtrl', reviewStudentTaskDetailsSubjectiveTestCtrl);

  reviewStudentTaskDetailsSubjectiveTestCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal',
    'dialogs', 'Lightbox', 'notificationService', 'userInfo', 'oedConfig', 'oedCoursePath', 'oedTaskList', 'oedTask', 'oedTaskListPublishRecord',
    'oedTaskTest', 'oedObjectiveTest', 'oedSubjectiveTest', 'oedTestSession', 'oedTestSessionStudent', 'oedTestAnswer',
    'oedTestSessionStatForReview', 'oedObjectiveTestSessionStats', 'oedUserInfo', 'oedBoardSession', 'oedBoardContent',
    'oedSubjectiveTestSessionStats', 'oedBoardContentComment', 'oedBoardSessionStudent', 'oedUserInfoUtils']
  function reviewStudentTaskDetailsSubjectiveTestCtrl($scope, $state, $stateParams, $q, $uibModal,
    dialogs, Lightbox, notificationService, userInfo, oedConfig, oedCoursePath, oedTaskList, oedTask, oedTaskListPublishRecord,
    oedTaskTest, oedObjectiveTest, oedSubjectiveTest, oedTestSession, oedTestSessionStudent, oedTestAnswer,
    oedTestSessionStatForReview, oedObjectiveTestSessionStats, oedUserInfo, oedBoardSession, oedBoardContent,
    oedSubjectiveTestSessionStats, oedBoardContentComment, oedBoardSessionStudent, oedUserInfoUtils) {

    $scope.basePath = oedConfig.base();

    var recordId = _.parseInt($stateParams.recordId);
    var origTestId = _.parseInt($stateParams.origTestId);
    var studentId = _.parseInt($stateParams.studentId);

    $scope.studentId = studentId;
    $scope.origTestId = origTestId;
    $scope.recordId = recordId;

    $scope.isDetailsCollapsed = true;
    $scope.isAnswersCollapsed = false;

    $scope.answer = {
      commentContent: {}
    };

    $scope.userInfoMap = {};

    $scope.record = oedTaskListPublishRecord.get({
      id: recordId
    });

    $scope.loadTestSession = $scope.record.$promise.then(function(r) {
      var tasks = oedTask.queryByTaskListId({
        id: r.taskListId
      });
      return tasks.$promise.then(function(ts) {
        if (_.isEmpty(ts)) {
          console.log('error, no tasks in task list: ' + r.taskListId);
        }
        $scope.testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
          recordId: recordId,
          taskId: ts[0].id,
          origTestId: origTestId
        });
        return $scope.testSession.$promise;
      });
    });

    function readContent(content) {
      var c = _.omit(content, 'contentBody');
      var body = {};
      if (content.contentBody) {
        body = angular.fromJson(content.contentBody);
      }
      c.body = body;

      var resItems = [];

      if (c.body.content) {
        resItems = [{
          fileType: 'text',
          text: c.body.content,
          textTrimed: _.truncate(c.body.content, {
            length: 30
          })
        }];
      }

      if (c.boardContentThumbnail) {
        resItems = resItems.concat({
          fileType: 'boardContent',
          id: c.id
        });
      }

      if (_.size(body.resources) > 0) {
        resItems = resItems.concat(body.resources);
      }

      c.resItems = resItems;
      return c;
    }

    function readComment(cmt) {
      var c = _.omit(cmt, 'commentBody');

      var body = {};
      if (cmt.commentBody) {
        body = angular.fromJson(cmt.commentBody);
      }
      c.body = body;

      c.body.resources = c.body.resources || [];
      // pad端添加的文字评论
      if (cmt.comment) {
        c.body.content = cmt.comment;
      }

      // pad端添加的图片评论
      if (cmt.imgResId) {
        c.body.resources = c.body.resources.concat({
          fileType: 'boardComment',
          id: cmt.imgResId
        });
      }

      if (!_.has($scope.userInfoMap, cmt.userId)) {
        $scope.userInfoMap[cmt.userId] = oedUserInfo.getByUid({
          id: cmt.userId
        });
      }

      return c;
    }

    function updateStats() {
      $scope.stats = oedSubjectiveTestSessionStats.queryBySessionAndUserId({
        boardSessionId: $scope.boardSession.id,
        userId: studentId,
        includeBoardContent: false,
        includeBoardThumbnail: true,
        includeViewedBoards: false,
        includeBravoedBoards: false,
        includeCommentedBoards: false,
        includeBoardViews: true,
        includeBoardBravos: true,
        includeBoardComments: true,
        sortBy: 'bravosCount'
      });

      return $scope.stats.$promise.then(function(stats) {
        if (!stats.board) {
          return;
        }
        $scope.selfBravoed = _.findIndex(stats.board.bravosList, function(bravo) {
          return bravo.key.uid == $scope.uid;
        }) >= 0;

        var contents = _.get(stats, 'board.contentList', []);
        if (!_.isEmpty(contents)) {
          $scope.isDetailsCollapsed = true;
        }
        $scope.boardContents = _.map(contents, readContent);
        $scope.boardComments = _.map(_.get(stats, 'board.commentsList', []), c => readComment(c.right)).reverse();
      });
    }

    userInfo.then(function(info) {
      $scope.userInfoMap[info.uid] = info;
      $scope.userInfoMap[studentId] = oedUserInfo.getByUid({
        id: studentId
      });

      $scope.uid = _.parseInt(info.uid);

      $scope.loading = $scope.loadTestSession.then(function(ts) {
        return oedBoardSession.queryByTestSessionId({
          sessionId: ts.id
        }).$promise;

      }).then(function(boardSessions) {
        $scope.test = oedSubjectiveTest.get({
          id: $scope.testSession.testId
        });
        $scope.test.$promise.then(function(test) {
          $scope.currentState.subTitle = $scope.test.name;
        });

        if (_.isEmpty(boardSessions)) {
          console.error('no board sessions for board sessoin');
          return $scope.test.$promise;
        }

        $scope.boardSession = boardSessions[0];

        $scope.boardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
          sessionId: $scope.boardSession.id,
          studentId: studentId
        });

        var loadStats = updateStats();

        return $q.all([$scope.test.$promise, $scope.boardStudent.$promise, loadStats]);

      }).finally(function() {
        $scope.loaded = true;
      });
    });

    $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl;

    function getAllContentRes() {
      var allContents = _.reduce($scope.boardContents, function(total, c) {
        return total.concat(c.resItems);
      }, []);

      return allContents;
    }

    function hasViewed() {
      var vl = _.get($scope.stats, 'board.viewsList', []);
      return _.findIndex(vl, function(v) {
        return v.key.uid === $scope.uid;
      }) >= 0;
    }

    $scope.previewContentResource = function(curRes) {
      var allRes = getAllContentRes();
      var idx = _.findIndex(allRes, curRes);

      if (!hasViewed()) {
        oedBoardContentComment.markReadBoardContent({
          boardContentId: $scope.boardContents[0].id,
          userId: $scope.uid
        }, {}).$promise.then(updateStats);
      }

      Lightbox.openModal(allRes, idx);
    };

    $scope.previewResource = function(reses, idx) {
      Lightbox.openModal(reses, idx);
    };

    $scope.openAddCommentPanel = function() {
      $scope.showAddCommentPane = true;
    };

    $scope.cancelEditor = function() {
      $scope.showAddCommentPane = false;
    };

    $scope.loading = $scope.loadTestSession;

    $scope.submitComment = function(comment) {
      if (_.isEmpty($scope.boardContents)) {
        return notificationService.notify('error', '该学生还没有提交作品, 不能添加评论')
      }

      var c = _.pick(comment, ['content', 'resources']);
      if (_.isEmpty(c.content) && _.isEmpty(c.resources)) {
        c = undefined;
      }

      if (_.isUndefined(comment.rank) && _.isUndefined(c)) {
        return notificationService.notify('error', '请填写评论内容')
      }

      c = c || {};
      c.rank = comment.rank;

      var body = angular.toJson(c);

      var cmt = {
        rank: comment.rank,
        comment: {
          boardContentId: $scope.boardContents[0].id,
          userId: $scope.uid,
          commentBody: body,
          createTime: moment().valueOf()
        }
      };

      var cmtToAdd = oedBoardContentComment.createCommentWithRank(cmt);
      $scope.loading = cmtToAdd.$promise.then(function(cmt) {
        $scope.answer.commentContent = {};
        $scope.showAddCommentPane = false;
        $scope.boardComments.unshift(readComment(cmt.comment));

        $scope.boardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
          sessionId: $scope.boardSession.id,
          studentId: studentId
        });

        return $scope.boardStudent.$promise.then(updateStats);
      });
    };

    $scope.getContentItemClass = function(item) {
      var width = _.size(item.resItems) * 166;

      return {
        width: width + 'px'
      };
    };

    $scope.toggleBravo = function() {
      $scope.loading = oedBoardContentComment.toggleBravo({
        boardSessionId: $scope.boardSession.id,
        ownerId: studentId,
        userId: $scope.uid
      }, {}).$promise.then(updateStats);
    };
  }
})();
