require('assets/app/controllers/examination/dialog/editSummaryEdit.scss');

angular.module('app.examination.editObjectTestPaper.editSummaryEdit', [])
  .controller('editSummaryEditCtrl', editSummaryEditCtrl);

editSummaryEditCtrl.$inject = ['$scope', '$uibModalInstance', 'data', 'notificationService', '$q']
function editSummaryEditCtrl($scope, $uibModalInstance, data, notificationService, $q) {

  let beforeChangeName = data.name
  $scope.name = data.name
  $scope.duration = data.duration

  $scope.ok = () => {
    if ($scope.name == '') {
      notificationService.notify('error', '请填写试卷信息')
      return;
    }
    if ($scope.duration <= 0) {
      notificationService.notify('error', '大题时间必须大于0')
      return;
    }
    const deferred = $q.defer();
    $scope.loading = deferred.promise
    deferred.promise.then(() => {
      $uibModalInstance.close('close')
    })
    data.doChange($scope.name, $scope.duration, deferred)
  }
  
  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.onCheckNameAvailable = () => {
    if (_.size($scope.name) > 256) {
      notificationService.notify('error', '试卷名称不能超过256个字符')
      $scope.name = beforeChangeName
      return
    }
    beforeChangeName = $scope.name
  };
}
