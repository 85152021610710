import { TalSubjectDTO } from 'app2/integration/talcloud/models'

require('app2/prepareCourse/cloud/core/talcloudLevelSubjectSelector.directive')

angular.module('app.prepareCourse2.cloud.core.talcloudLevelSubjectDialog', [
  'app.prepareCourse2.cloud.core.talcloudLevelSubjectSelector',
])
.controller('oedTalcloudLevelSubjectDialogCtrl', oedTalcloudLevelSubjectDialogCtrl)

oedTalcloudLevelSubjectDialogCtrl.$inject = ['$uibModalInstance', 'data', '$scope']
function oedTalcloudLevelSubjectDialogCtrl($uibModalInstance: any, data: any, $scope: any) {

  $scope.stages = data.stages
  $scope.subjects = data.subjects
  $scope.fromTalcloud2 = data.fromTalcloud2

  $scope.onSubjectSelected = (stageSelected: TalSubjectDTO, subjectSelected: TalSubjectDTO) => {
    $uibModalInstance.close({
      stage: stageSelected.stage,
      stageName: stageSelected.stageName,
      subjectId: subjectSelected.subjectId,
      subjectName: subjectSelected.subjectName,
    })
  }

  $scope.selectedStage = data.selectedSubject
  $scope.selectedSubject = data.selectedSubject
  $scope.onClose = () => $uibModalInstance.dismiss('cancel')
}
