import * as _ from 'lodash'
require('assets/app/controllers/examination/examReport/examReportChart/overallChart/overallChart.scss')
import { getOverallRate } from 'assets/app/controllers/examination/examReport/examReportChart/examChartUtils';

angular.module('app.examination.examReport.chart.overall', [])
  .component('overallChart', {
    bindings: {
      examSearch: '<',
    },
    controller: overallChartCtrl,
    template: < string > require('./overallChart.html'),
  })


overallChartCtrl.$inject = []
function overallChartCtrl() {
  const ctrl = this

  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'examSearch')) {
      loadStudentDatas()
    }
  }

  function loadStudentDatas() {
    ctrl.studentData = _.get(ctrl.examSearch, 'studentPartData.0.data.studentsData', [])
    if (_.isEmpty(ctrl.studentData)) {
      return
    }
    initOverall()
    initBarChart()
  }

  function initOverall() {
    ctrl.totalScore = _.get(ctrl.studentData, '0.totalScore', 0)
    const scoreList = _.map(ctrl.studentData, d => _.get(d, 'gainedScore', 0))
    ctrl.minScore = _.round(_.min(scoreList), 1)
    ctrl.averageScore = _.round((scoreList.reduce((pre, cur) => cur += pre)) / _.size(scoreList), 1)
    ctrl.maxScore = _.round(_.max(scoreList), 1)

    const examTotalScore = _.get(ctrl.studentData, '0.totalScore', 0)
    ctrl.passRate = getOverallRate(0.6, scoreList, examTotalScore)
    ctrl.goodRate = getOverallRate(0.75, scoreList, examTotalScore)
    ctrl.bestRate = getOverallRate(0.85, scoreList, examTotalScore)
  }

  function initBarChart() {
    ctrl.chartData = getChartData()
    ctrl.xAxisData = getxAxisData()
    ctrl.barData = getBarData()
    ctrl.maxBarData = _.max(ctrl.barData)
    ctrl.maxBarCount = _.size(ctrl.barData)
    ctrl.maxChartWidth = document.getElementsByClassName('header-class-tab')[0].clientWidth - 240
  }

  function getxAxisData() {
    const xAxisData = []
    for (const key in ctrl.chartData) {
      xAxisData.push(key)
    }
    return xAxisData
  }

  function getBarData() {
    const xAxisData = getxAxisData()
    return _.map(xAxisData, data => _.get(ctrl.chartData, `${data}.count`, 0))
  }

  function getChartData() {
    const chartData = {}
    const sortedStdDatas = _.sortBy(ctrl.studentData, d => _.get(d, 'gainedScore', 0))
    _.forEach(sortedStdDatas, data => {
      const score = _.get(data, 'gainedScore', 0)
      const studentName = _.get(data, 'studentName', '')
      let tenNum = Math.floor(score / 10)
      if (tenNum === 10) {
        tenNum --
      }
      let level = ''
      if (tenNum * 10 === ctrl.totalScore) {
        level = `${tenNum * 10 - 10}-${tenNum * 10}分`
      } else {
        level = `${(tenNum * 10)}-${(tenNum + 1) * 10}分`
      }
      const leftScore = tenNum * 10
      const count = _.get(chartData, `${level}.count`, 0)
      const names = _.get(chartData, `${level}.names`, [])
      names.push(studentName)
      if (count === 0) {
        const levelData = { count: 1, names, leftScore }
        chartData[level] = levelData
      } else {
        const levelData = { count: count + 1, names, leftScore }
        chartData[level] = levelData
      }
    })
    resetLevelNames(chartData)
    return chartData
  }

  function resetLevelNames(chartData: any) {
    for (const key in chartData) {
      const names = _.get(chartData, `${key}.names`, [])
      _.map(names, (n: string, i: number) => {
        if (i > 0 && (i + 1) % 5 == 0) {
          return names[i] =`\xa0${n}\xa0`
        } else {
          return names[i] =`\xa0${n}\xa0`
        }
      })
      const namesText = _.join(names, '')
      _.set(chartData, `${key}.names`, namesText)
    }
  }

  ctrl.getTooltip = (index: number) => {
    const label = ctrl.xAxisData[index]
    return _.get(ctrl.chartData, `${label}.names`, '')
  }

  ctrl.getBarStyle = (count: number) => {
    return {'flex': `${count / ctrl.maxBarData}`}
  }

  ctrl.getChartMaxWidth = () => {
    const isMaxView = ((ctrl.maxBarCount * 171) - 71) > ctrl.maxChartWidth
    if (isMaxView) {
      return {'width': `${ctrl.maxChartWidth}`}
    }
    return {'width': `${(ctrl.maxBarCount * 171) - 51}`}
  }

  ctrl.getTextContainerWith = (index: number) => {
    if ((index + 1) === ctrl.maxBarCount) {
      return {'flex': '0 0 120px'}
    }
  }

  ctrl.getTextWith = (index: number) => {
    if ((index + 1) === ctrl.maxBarCount) {
      return {'width': '120px'}
    }
  }

  ctrl.getXaxisTooltip = (text: string) => {
    const leftScore = _.get(ctrl.chartData, `${text}.leftScore`, 0)
    const rightScore = leftScore + 10
    if (leftScore === ctrl.totalScore) {
      return `区间为：大于等于${leftScore - 10}，小于等于${leftScore}`
    }
    if (rightScore === ctrl.totalScore) {
      return `区间为：大于等于${leftScore}，小于等于${rightScore}`
    }
    return `区间为：大于等于${leftScore}，小于${rightScore}`
  }
}
