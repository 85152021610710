import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import * as _ from 'lodash';
import { LoadingService } from 'app/shared/loading/loading.service';
import { OedExerciseService } from '../../oed-exercise.service';

@Component({
  selector: 'app-preview-paper-item',
  template: require('./preview-paper-item.component.html'),
  styles: [require('./preview-paper-item.component.scss')]
})
export class PreviewPaperItemComponent implements OnInit {
  @Input() item: any
  @Input() inLibary: boolean
  @Input() resboxHolder: any
  @Output() onDeleteTest = new EventEmitter()
  @Output() onAddTestToResBox = new EventEmitter()

  private testDeatils

  constructor(
    private oedExerciseService: OedExerciseService,
  ) { }

  ngOnInit() {
    this.loadData()
  }

  private loadData = () => {
    if (!this.inLibary) return
    const id = _.get(this.item, 'id', 0);
    this.oedExerciseService.getTestPaperQuestions(id)
      .subscribe((data: any) => {
        const questions = _.map(_.get(data, 'questions', []), q => q.question)
        const details = _.set(data, 'questions', questions)
        this.testDeatils = details
      })
  }

  private doAddTestToResBox = ($event: any) => {
    this.onAddTestToResBox.emit({ $event, t: this.testDeatils })
  }

  private doDeleteTest = () => {
    this.onDeleteTest.emit(this.testDeatils)
  }

  private isTestAddedToResBox = () => {
    if (!this.resboxHolder || !this.testDeatils) {
      return false
    }
    return this.resboxHolder.isTestAdded(this.testDeatils)
  }
}
