(function() {
  var _ = require('lodash');
  var confirmDialog = require('app2/common/confirmDialog.ctrl');
  var cloudCss = require('assets/styles/cloud.css');
  var cloudResBox = require('app2/directives/cloud/cloudResBox.directive');
  var messages = require('app2/utils/messages');
  var resItemsPreviewerForMicroLesson = require('app2/prepareCourse/resItemsPreviewerForMicroLesson.ctrl');

  angular.module('app.prepareCourse.addMicroLessonRecordCtrl', [
    'app.common.confirmDialog',
    'app.directives.cloud.cloudResBox'
    ])
    .controller('addMicroLessonRecordCtrl', addMicroLessonRecordCtrl);

  addMicroLessonRecordCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'Upload',
    'oedConfig', 'oedPresentResourceItem', 'notificationService', 'data', 'userInfo', 'oedMicroLessonRecord',
    'oedResource', 'Lightbox', '$uibModal']
  function addMicroLessonRecordCtrl($scope, $q, $uibModalInstance, Upload,
    oedConfig, oedPresentResourceItem, notificationService, data, userInfo, oedMicroLessonRecord,
    oedResource, Lightbox, $uibModal) {

    $scope.recordsToImport = [];
    $scope.presentresourceId = data.presentresourceId;
    var isCodeClose = false;

    $scope.loading = userInfo.then(function(info) {
      return oedMicroLessonRecord.queryAllRecordByTeacher({uid: info.uid}).$promise.then(function(records) {
        var recordDetails = _.map(records, loadRecordItemDetails);
        return $q.all(recordDetails).then(function(data) {
          $scope.records = data;
        });
      });
    });

    function loadRecordItemDetails(item) {
      item.res = oedResource.get({
        resourceId: item.resourceId
      });
      return item.res.$promise.then(function() {
        item.res.resourceName = item.resourceName;
        return item;
      });
    }

    $scope.close = function() {
      $uibModalInstance.close('close');
    };

    function checkResBoxIsEmpty() {
      if (_.isEmpty($scope.recordsToImport)) {
        return $q.resolve(true);
      }
      // 您已经向出题筐中加入了<%= count %>项内容，确认关闭？
      var msg = _.template('出题筐中的内容尚未确认导入，关闭会自动清空出题筐，确认关闭么？')({
        count: _.size($scope.recordsToImport)
      });

      var modalInstance = $uibModal.open({
        template: require('app2/common/confirmDialog.html'),
        controllerAs: '$ctrl',
        controller: 'oedConfirmDialogCtrl',
        resolve: {
          data: function() {
            return {
              message: msg,
              title: '确认关闭',
              yesText: '关闭',
              noText: '取消'
            };
          }
        },
        size: 'sm',
        windowClass: 'oedmodal modalCenter'
      });

      return modalInstance.result;
    }

    $scope.addMicroLessonResItem = function(res, event) {
      event.stopPropagation();
      event.preventDefault();

      if (!checkAddToResBox(res)) {
        return;
      }

      $scope.microLessonResToAdd = {
        cloudRes: res,
        resType: 'microLessonRecord',
        event: event
      };
    };

    $scope.removeMicroLessonResItem = function(res, event) {
      event.stopPropagation();
      event.preventDefault();

      $scope.recordsToImport = _.filter($scope.recordsToImport, s => s.id !== res.id);
    };

    $scope.onAddMicroLessonRes = function(res, resType) {
      if (canAddToResBox(res)) {
        $scope.recordsToImport = _.concat($scope.recordsToImport, res);
      }
    };

    function checkAddToResBox(res) {
      if (!canAddToResBox(res)) {
        notificationService.notify('error', '该资源已加入到出题筐')
        return false;
      }
      return true;
    }

    $scope.canAddToResBox = canAddToResBox;
    function canAddToResBox(res) {
      var existingResourceIds = _.map($scope.recordsToImport, 'resourceId');
      return !_.includes(existingResourceIds, res.resourceId);
    }

    $scope.$on('modal.closing', function($event) {
      //
      // 如果是代码触发的关闭, 则不做进一步检查
      if (isCodeClose) {
        isCodeClose = false;
        return;
      }

      $event.preventDefault();
      checkResBoxIsEmpty().then(function() {
        //
        // 自动关闭
        isCodeClose = true;
        $uibModalInstance.dismiss();
      }).finally(function() {
        isCodeClose = false;
      });
    });

    $scope.doPreviewResBox = function(items) {
      if (_.isEmpty(items)) {
        return notificationService.notify('info', messages['importResource/noResourceAdded'])
      }

      $scope.doOpenPreviewResBox(items).then(function() {
        isCodeClose = true;
        $uibModalInstance.close('close');
      });
    }

    $scope.doOpenPreviewResBox = function(items) {
      var modalInstance = $uibModal.open({
        template: require('app2/prepareCourse/resItemsPreviewerForMicroLesson.html'),
        controller: 'resItemsPreviewerForMicroLessonCtrl as ctrl',
        size: 'lg',
        resolve: {
          data: function() {
            return {
              items: items,
              presentresourceId: $scope.presentresourceId
            };
          }
        },
        windowClass: 'cl-library-preview'
      });

      return modalInstance.result;
    };

    $scope.showPreview = function(res, idx, event) {
      event.stopPropagation();
      event.preventDefault();

      var allReses = _.map($scope.records, 'res');

      Lightbox.openModal(allReses, idx);
    };

    $scope.getCountSuffix = function() {
      return '项';
    };

    $scope.getResourceName = function(item) {
      return item.resourceName + '.' + item.res.suffix;
    };
  }
})();
