/**
 * @fileOverview
 * @name subjectiveTestSessionDetailsCtrl.js
 * @author pangwa
 * @license
 */
//
// var commentComposer = require('commentComposer');

(function() {
  'use strict';
  require('app2/directives/board/boardDesigner.directive');
  require('assets/styles/review.scss');

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('subjectiveTestSessions.session', {
        url: '/session/:sessionId',
        template: require('assets/templates/review/subjectiveTestSessionDetails.html'),
        controller: 'subjectiveTestSessionDetailsCtrl',
        bodyStyle: 'bg_img'
      });
      $stateProvider.state('tmSessions.sbjSession', {
        url: '/subTest/:testId/session/:sessionId/tmsession',
        template: require('assets/templates/review/subjectiveTestSessionDetails.html'),
        controller: 'subjectiveTestSessionDetailsCtrl',
        bodyStyle: 'bged2'
      });
    }])
    .controller('subjectiveTestSessionDetailsCtrl', subjectiveTestSessionDetailsCtrl);

  subjectiveTestSessionDetailsCtrl.$inject = ['$scope', '$state', '$stateParams', '$timeout', '$uibModal',
    '$animate', '$q', 'dialogs', 'Lightbox', 'notify', 'resize',
    'oedTestSession', 'oedBoardSession', 'oedObjectiveTestSessionStats', 'oedSubjectiveTestSessionStats',
    'oedConfig', 'oedUrlBuilder', 'oedSubjectiveTest', 'oedUserInfo', 'oedClassSession']
  function subjectiveTestSessionDetailsCtrl($scope, $state, $stateParams, $timeout, $uibModal,
    $animate, $q, dialogs, Lightbox, notify, resize,
    oedTestSession, oedBoardSession, oedObjectiveTestSessionStats, oedSubjectiveTestSessionStats,
    oedConfig, oedUrlBuilder, oedSubjectiveTest, oedUserInfo, oedClassSession) {

    $scope.currentState.title = '主观活动';

    $scope.activeTabIndex = 1;
    $scope.boardSessionId = $stateParams.sessionId;
    $scope.tabs = [{index: 0, text: '题目和解析'},
      {index: 1, text: '完成情况'},
      {index: 2, text: '统计表格'}];

    $scope.boardSession = oedBoardSession.get({
      id: $stateParams.sessionId
    });

    $scope.classSession = oedClassSession.get({
      id: $stateParams.classSessionId
    });

    $scope.sbjTest = {};

    $scope.boardSession.$promise.then(function(boardSession) {
      try {
        boardSession.baseBoardContent = JSON.parse(boardSession.baseBoardContent);
      } catch (ex) {} // eslint-disable-line

      if (!_.isNil(boardSession.testId)) {
        getSbjTestByTestId(boardSession);
      } else {
        getSbjTestByTestSessionId(boardSession.testSessionId);
      }
    });

    function getSbjTestByTestId(boardSession) {
      $scope.sbjTest = oedSubjectiveTest.get({
        id: boardSession.testId
      });
    }

    function getSbjTestByTestSessionId(testSessionId) {
      return oedTestSession.getDetails({
        sessionId: testSessionId
      }).$promise.then(function(detail) {
        $scope.sbjTest = detail.sbjTest;
      });
    }

    $scope.loading = $q.all([
      $scope.boardSession.$promise,
      $scope.classSession.$promise
    ]).then(function() {
        $scope.loaded = true;
      });
  }
})();
