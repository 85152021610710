import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { StateService } from '@uirouter/core';
import { LoadingService } from 'app/shared/loading/loading.service';
import { TestSessionService } from 'app/review/test-session.service';
import { of, zip, Observable, Subscription } from 'rxjs';
import { TestSessionDTO, TestSessionStudentDTOViewProgress, TestSessionStatByQuesNewDTO } from 'app/models/obj-test-session';
import { map, finalize } from 'rxjs/operators';
import { getTestStatLabel } from 'app2/utils/testUtils2';
import { isQuickWayToCreatePrepareCourse } from 'app2/prepareCourse/utils/prepareCourseUtils';
import { LegacyAppService } from 'app/core/legacy-app.service';
import { NotificationService } from 'app/shared/notification/notification.service';

declare var nv: any; // eslint-disable-line
declare var d3: any; // eslint-disable-line

@Component({
  selector: 'app-obj-chart-summary',
  template: require('./obj-chart-summary.component.html'),
  styles: [require('./obj-chart-summary.component.scss')]
})
export class ObjChartSummaryComponent implements OnInit, OnDestroy {
  @Input() testSession: any;
  @Input() taskId: number;
  @Input() recordId: number;
  @Input() origTestId: number;
  @Input() origTaskListId: number;
  @Input() isQuickCreateObjTest: boolean;
  @Input() objTest: any;
  @Input() extraInfo: any;
  @Input() pubStudents: number[];
  @Input() classId: number;

  loadingName = 'obj-chart-summary';
	studentsOfFinish: TestSessionStudentDTOViewProgress[];
	overall: { total: number; numStudents: number; }; // eslint-disable-line
	currentLabels: any[];
  data = [{
    'key': '正确',
    values: [],
    color: '#469e24' //color - optional: choose your own line color.
  }, {
    key: '犹豫/半对',
    color: '#f0c46e',
    values: []
  }, {
    key: '订正',
    color: '#d56215',
    values: []
  }, {
    key: '错误',
    color: '#a70d0d',
    values: []
  }] as any;

	barChartOptions: any;
	stats: TestSessionStatByQuesNewDTO[];
	subs$ = new Subscription();

  constructor(private loadingService: LoadingService,
              private testSessionService: TestSessionService,
              private notifyService: NotificationService,
              private legacyApp: LegacyAppService,
              private stateService: StateService) { }

  ngOnInit() {
    this.barChartOptions = {
      'chart': {
        type: 'multiBarHorizontalChart',
        height: 100,
        showControls: false,
        showValues: true,
        stacked: true,
        noData: '没有数据',
        groupSpacing: 0.4,
        margin: {
          'top': 0,
          'bottom': 0,
          left: 100
        },
        valueFormat: d3.format('d'),
        'transitionDuration': 500,
        'xAxis': {
          'showMaxMin': false
        },
        x: function(d) {
          return d.label;
        },
        y: function(d) {
          return d.value;
        },
        dispatch: {
          renderEnd: (e) => {
            d3.selectAll('.nv-x.nv-axis, .nv-x .nv-axis').style('pointer-events', 'all')
            const elems = d3.selectAll('.tick text')
            if (elems.length > 0) {
              _.map(elems[0], (elem, index: number) => {
                elem.onclick = (e) => {
                  this.handleElemClicked(index)
                }
              })
            }
          }
        },
        callback: function(chart) {
          // d3.selectAll('.nvd3 .nv-x.nv-axis g.tick:nth-child(1)').style('fill', "yellow");
          d3.selectAll('.obj-test-summary-rate svg').style('overflow', 'visible');
          d3.selectAll('.obj-test-summary-rate rect').style('cursor', 'pointer');
        },
        yAxis: {
          tickFormat: function(d) {
            return d < 1 ? 0 : d3.format('d')(d);
          }
        },
        multibar: {
          dispatch: {
            elementClick: (e) => {
              const idx = e.index; // 点击的题目的index
              this.handleElemClicked(idx)
            }
          }
        }
      }
    }

    this.loadTestSessionStats(this.testSession)
  }

	ngOnDestroy(): void {
    this.subs$.unsubscribe()
	}

  loadTestSessionStats(ts: TestSessionDTO) {
    this.loadingService.show(false, this.loadingName)

    const students$: Observable<TestSessionStudentDTOViewProgress[][]> = this.classId ? this.testSessionService.queryStdListFromClassSession(this.classId, ts.id) : of([[], [], []])
    const stats$ = this.testSessionService.getStatByQuestionsWithRevise(ts.id)

    this.subs$ = zip(students$, stats$)
      .pipe(map(([stdList, stats]) => {
        if (!_.isEmpty(this.pubStudents)) {
          const pubStdMap = _.keyBy(this.pubStudents)
          stdList[0] = _.filter(stdList[0], (s) => _.has(pubStdMap, s.studentId))
        }
        this.studentsOfFinish = stdList[0];
        this.updateStats(stats)
      }), finalize(() => this.loadingService.hide(this.loadingName)))
      .subscribe(() => {}, (e) => {
        console.log('error', e)
        this.notifyService.error('加载数据失败, 请刷新页面或者联系技术支持')
      })
  }

  updateStats(stats: TestSessionStatByQuesNewDTO[]) {
    this.stats = stats
    if (_.isEmpty(stats)) {
      return;
    }
    this.overall = {
      total: stats.length,
      numStudents: stats[0].countAnsweredTotal
    };

    this.barChartOptions.chart.height = 30 + _.size(stats) * 60;
    this.barChartOptions = _.clone(this.barChartOptions)

    let maxChoiceCountForVote = 0;
    const labels = getTestStatLabel(stats);
    this.currentLabels = labels;
    for (let k = 0; k < stats.length; k++) {
      const qType = stats[k].question.type;
      if (qType == 'singlevote' || qType == 'multivote' || qType === 'yesorno') {
        maxChoiceCountForVote = Math.max(maxChoiceCountForVote, stats[k].question.choices.length);
      }
    }

    if (this.extraInfo === 'yesorno') {
      const yesColor = isQuickWayToCreatePrepareCourse(this.objTest) ? '#469e24' : '#a70d0d'
      const noColor = isQuickWayToCreatePrepareCourse(this.objTest) ?  '#a70d0d' : '#469e24'
      this.data.push({
        key: '是',
        color: yesColor,
        values: []
      });
      this.data.push({
        key: '否',
        color: noColor,
        values: []
      });
    } else {
      for (let j = 0; j < maxChoiceCountForVote; j++) {
        const colorStr = (j % 2 == 1) ? '#469e24' : '#A5E14D';
        this.data.push({
          key: '选项' + String.fromCharCode(65 + j),
          color: colorStr,
          values: []
        });
      }
    }
    if (this.isQuickCreateObjTest) {
      for (let idx = 0; idx < stats.length; idx++) {
        const stat = stats[idx];
        const ques = stat.question;
        const quesType = ques.type;
        const isQa = quesType == 'qa'

        if (!isQa && _.parseInt(ques.answer) < 0) {
          // 设正确/犹豫/错误为0
          // 临时客观推送 选择题未设置答案且学生答题则判对 判断题未设置答案且学生答题则判对
          for (let i = 0; i < this.data.length; i++) {
            if (i - 4 < ques.choices.length) {
              let count = 0;
              // if (_.size(stat.choiceAnswers[(i - 4).toString()]) > 0) {
              // count = _.size(stat.choiceAnswers[(i - 4).toString()])
              if (quesType === 'singlevote' && i === 0) {
                count = stat.countAnsweredTotal
              } else if (quesType === 'yesorno' && (stat.errorProne === '0,1' || stat.errorProne === '0' || stat.errorProne === '1') && i === 3) {
                count = stat.countAnsweredTotal
              }
              this.data[i].values.push({
                label: _.parseInt(ques.answer) > 0 ? '第' + (idx + 1) + '题' : '第' + (idx + 1) + '题（未设置答案）',
                value: count
              });
            } else {
              this.data[i].values.push({
                label: '第' + (idx + 1) + '题',
                value: 0
              });
            }
          }
        } else {
          const getLabelWithCorrect = (stat, idx, value) => {
            return {
              label: this.getReviewStatus(stat) + '第' + (idx + 1) + '题（正确率' + this.getCorrectRate(stat) + '%）',
              value,
            }
          }

          this.data[0].values
            .push(getLabelWithCorrect(stat, idx, stat.countAnsweredCorrectNotHesitate))

          this.data[1].values
            .push(getLabelWithCorrect(stat, idx, stat.countAnsweredCorrectAndHesitate + stat.countAnsweredCorrectAndWrong))

          this.data[2].values
            .push(getLabelWithCorrect(stat, idx, stat.countAnsweredRevise))

          this.data[3].values
            .push(getLabelWithCorrect(stat, idx, stat.countAnsweredWrong))

          for (let ii = 4; ii < this.data.length; ii++) {
            this.data[ii].values.push(getLabelWithCorrect(stat, idx, 0))
          }
        }
      }
    } else {
      for (let idx = 0; idx < stats.length; idx++) {
        const stat = stats[idx];
        const ques = stat.question;
        const quesType = ques.type;

        if (quesType == 'singlevote' || quesType == 'multivote') {
          // 设正确/犹豫/错误为0
          for (let i = 0; i < this.data.length; i++) {
            if (i < 4) {
              this.data[i].values.push({
                label: '第' + labels[idx] + '题',
                value: 0
              });
            } else if (i - 4 < ques.choices.length) {
              let count = 0;
              if ((i - 4).toString() in stat.choiceAnswers) {
                count = stat.choiceAnswers[(i - 4).toString()].length;
              }
              this.data[i].values.push({
                label: '第' + labels[idx] + '题',
                value: count
              });
            } else {
              this.data[i].values.push({
                label: '第' + labels[idx] + '题',
                value: 0
              });
            }
          }
        } else {
          const getStatLabelData = (stat, idx, value) => {
            return {
              label: this.getReviewStatus(stat) + '第' + labels[idx] + '题（正确率' + this.getCorrectRate(stat) + '%）',
              value,
            }
          }
          this.data[0].values.push(getStatLabelData(stat, idx, stat.countAnsweredCorrectNotHesitate));

          this.data[1].values.push(getStatLabelData(stat, idx, stat.countAnsweredCorrectAndHesitate + stat.countAnsweredCorrectAndWrong))

          this.data[2].values.push(getStatLabelData(stat, idx, stat.countAnsweredRevise))

          this.data[3].values.push(getStatLabelData(stat, idx, stat.countAnsweredWrong))

          for (let ii = 4; ii < this.data.length; ii++) {
            this.data[ii].values.push(getStatLabelData(stat, idx, 0))
          }
        }
      }
    }
  }

  getCorrectRate(stat: any) {
    if (stat.countAnsweredTotal === 0) {
      return 0;
    }
    return ((stat.countAnsweredCorrectNotHesitate + stat.countAnsweredCorrectAndHesitate) * 100 / stat.countAnsweredTotal).toFixed(0);
  }

  getReviewStatus(stat: any) {
    const isQa = stat.question.type === 'qa';
    const needReview = (stat.needReview || isQa) && stat.countAnsweredTotal > 0;
    if (needReview) {
      if (stat.anyBlankPendingReview || stat.qaAnyAnswerPendingReview) {
        return '(待批) ';
      }
    }

    return '';
  }

  handleElemClicked (idx: number) {
    const uibModal = this.legacyApp.getUibModal()

    const modalInstance = uibModal.open({
      template: require('assets/templates/review/questionAnswerDetails.html'),
      controller: 'questionAnswerDetailsCtrl',
      size: 'lg',
      windowClass: 'question-details-modal modalCenter',
      resolve: {
        data: () => {
          return {
            sessionType: 0,
            sessionId: this.testSession.id,
            questionId: this.stats[idx].questionId,
            index: this.isQuickCreateObjTest ? (idx + 1) : this.currentLabels[idx],
            objTest: this.objTest,
            studentsOfFinish: this.studentsOfFinish,
            questionType: this.stats[idx].question.type,
          };
        }
      }
    });

    modalInstance.result.then(function(dirty) {
      if (dirty) {
        this.stateService.reload()
      }
    });
  }
}
