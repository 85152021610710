import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {
  OnlineTrainingContentDTO, OnlineTrainingContentGoodCourseDTO, OnlineTrainingContentLinkDTO,
  OnlineTrainingDirectoryDTO, OnlineTrainingGoodCourseDTO
} from 'app/online-train/models/online-train';
import * as _ from 'lodash';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import {concat, forkJoin, Observable, of, zip} from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { StateService } from '@uirouter/core'
import {TrainMassageService} from 'app/online-train/train-massage.service';
import {OedDialogService} from 'app/core/oed-dialog.service';
import {CreateTeachingModuleDTO} from 'app/lesson/models/lesson';
import {AppStateService} from 'app/core/app-state.service';

@Component({
  selector: 'app-train-good-course-content',
  template: require('./train-good-course-content.component.html'),
  styles: [require('./train-good-course-content.component.scss')]
})
export class TrainGoodCourseContentComponent implements OnInit {
  @Input() public params: any
  private parentId
  private goodCourse: OnlineTrainingGoodCourseDTO
  private levels
  private subjects
  private grades
  private editable
  private prefix

  private content1
  private content2
  private contents: OnlineTrainingContentGoodCourseDTO[]

  private itemType1
  private itemType2

  private contentNav
  private dirs

  private canStar
  constructor(private onlineTrainService: OnlineTrainService,
              private state: StateService,
              private userInfo: AppStateService,
              private oedDialogService: OedDialogService,
              private trainMessage: TrainMassageService) { }

  ngOnInit() {
    this.parentId = _.parseInt(this.params.parentId)
    this.levels = this.params.levels
    this.subjects = this.params.subjects
    this.grades = this.params.grades
    this.editable = this.params.editable
    this.prefix = this.params.prefix
    this.itemType1 = this.onlineTrainService.CONTENT_GOOD_COURSE_VIDEO
    this.itemType2 = this.onlineTrainService.CONTENT_GOOD_COURSE_OTHER

    this.userInfo.then((info) => {
      this.canStar = info.canStar
    })

    if (this.parentId >= 0) {
      this.loadData().subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
    }
  }

  public editLSG() {
    const dataToEdit = this.getGoodCourseToEdit()
    const editType = _.has(dataToEdit, 'id') ? this.onlineTrainService.UPDATE : this.onlineTrainService.ADD

    const action = this.oedDialogService.openModalWithComponent2('TrainGoodCourseEditorComponent', {
      itemData: dataToEdit,
      itemType: this.onlineTrainService.GOOD_COURSE,
      editType
    }, {
      class: 'modal-sm',
    }) as Observable<OnlineTrainingGoodCourseDTO>
    action.pipe(flatMap((data) => {
      return this.onEditGoodCourse(data, editType)
    })).subscribe((data) => {
      this.parentId = data.id
      this.goodCourse = data
    })
  }

  public onEditGoodCourse(data, editType) {
    if (editType === this.onlineTrainService.ADD) {
      return this.onlineTrainService.addGoodCourse(data)
    } else if (editType === this.onlineTrainService.UPDATE) {
      return this.onlineTrainService.updateGoodCourse(data)
        .pipe(flatMap(() => this.onlineTrainService.getGoodCourseById(this.parentId)))
    }
  }

  public onEdit1(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      if (_.isEmpty(params.item) || params.item[0]['sort'] !== 1) {
        return
      }
      this.onlineTrainService.addContentGoodCourse(params.item[0])
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      this.onlineTrainService.updateContentGoodCourse(params.item)
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
    } else if (params.editType === this.onlineTrainService.DELETE) {
      this.onlineTrainService.deleteContentGoodCourse(params.item.id)
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
        if (_.isEmpty(this.getContent1())) {
          this.content1 = null
        }
      }, (e) => {console.log(e)}, () => this.trainMessage.sendLoadingAction(false))
    }
  }

  public onEdit2(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      if (_.isEmpty(params.item) || params.item[0]['sort'] === 1) {
        return
      }

      const requestsOfAdd = _.map(params.item, (item) => this.onlineTrainService.addContentGoodCourse(item))
      forkJoin(...requestsOfAdd)
        .pipe(
          flatMap(() => {
            if (!_.isEmpty(this.content2) && params.item[0].name !== this.content2[0].name) {
              const contentsToUpdate = _(this.content2).filter((c: any) => c.name !== params.item[0].name)
                .map((c) => {
                  c.name = params.item[0].name
                  return this.onlineTrainService.updateContentGoodCourse(c)
                }).value()
              return forkJoin(...contentsToUpdate)
            } else {
              return of(0)
            }
          }),
          flatMap(() => {
            return this.onlineTrainService.getContentsByGoodCourseId(this.parentId)
          }),
          flatMap((contents) => {
            this.contents = contents
            if (_.isEmpty(this.contents)) {
              return of([])
            } else {
              const req = _.map(this.contents, (c) => this.onlineTrainService.getResource(c.resourceId))
              return forkJoin(...req)
            }
          }),
          map((reses) => {
            if (!_.isEmpty(reses)) {
              this.onlineTrainService.enhanceContent(this.contents, reses)
              this.content2 = this.getContent2()
            }
            return of(0)
          })
        ).subscribe()
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      this.onlineTrainService.updateContentGoodCourse(params.item)
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
      }, (err) => console.log(err), () => this.trainMessage.sendLoadingAction(false))
    } else if (params.editType === this.onlineTrainService.DELETE) {
      this.onlineTrainService.deleteContentGoodCourse(params.item.id)
        .pipe(flatMap(() => this.loadData())).subscribe(() => {
        if (_.isEmpty(this.getContent2())) {
          this.content2 = null
        }
      }, (e) => {console.log(e)}, () => this.trainMessage.sendLoadingAction(false))
    } else if (params.editType === this.onlineTrainService.UP) {
      this.up(params.item)
    } else if (params.editType === this.onlineTrainService.DOWN) {
      this.down(params.item)
    }
  }

  public showEditBtn() {
    return this.canStar
  }

  public toggleEdit() {
    this.editable = !this.editable
  }

  private getGoodCourseToEdit() {
    if (_.isEmpty(this.goodCourse)) {
      return {
        levelId: -1,
        subjectId: -1,
        gradeId: -1,
        name: '',
        deleted: false
      }
    } else {
      return this.goodCourse
    }
  }
  private getContent1() {
    return _.filter(this.contents, (c) => c['sort'] === 1)
  }

  private getContent2() {
    return _.filter(this.contents, (c) => c['sort'] > 1)
  }

  private loadData() {
    this.trainMessage.sendLoadingAction(true)
    return forkJoin(this.onlineTrainService.getGoodCourseById(this.parentId),
                    this.onlineTrainService.getContentsByGoodCourseId(this.parentId))
            .pipe(
              flatMap((values) => {
                this.goodCourse = values[0]
                this.contents = values[1]
                this.content1 = this.getContent1()
                this.content2 = this.getContent2()
                if (_.isEmpty(this.contents)) {
                  return of([])
                } else {
                  const req = _.map(this.contents, (c) => this.onlineTrainService.getResource(c.resourceId))
                  return forkJoin(...req)
                }
              }),
              map((reses) => {
                if (!_.isEmpty(reses)) {
                  this.onlineTrainService.enhanceContent(this.contents, reses)
                }
                return 0
              }),
              flatMap(() => {
                return this.onlineTrainService.getAllDirs()
              }),
              map((dirs) => {
                this.dirs = dirs
                this.contentNav = [_.find(this.dirs, (dir) => dir.id === 5), {name: '课例详情'}]
              })
              )
  }

  private up(item) {
    const idx = _.findIndex(this.content2, item)
    if (idx <= 0) {
      return
    }

    const item1 = this.content2[idx]
    const item2 = this.content2[idx - 1]
    this.onlineTrainService.swapSort(item1, item2)

    this.trainMessage.sendLoadingAction(true)
    forkJoin(this.onlineTrainService.updateContentGoodCourse(item1),
      this.onlineTrainService.updateContentGoodCourse(item2))
      .subscribe(() => this.onlineTrainService.swap(idx, idx - 1, this.content2),
        () => this.onlineTrainService.swapSort(item1, item2), () => this.trainMessage.sendLoadingAction(false))
  }

  private down(item) {
    const idx = _.findIndex(this.content2, item)
    if (idx >= _.size(this.content2) - 1) {
      return
    }

    const item1 = this.content2[idx]
    const item2 = this.content2[idx + 1]
    this.onlineTrainService.swapSort(item1, item2)
    this.trainMessage.sendLoadingAction(true)
    forkJoin(this.onlineTrainService.updateContentGoodCourse(item1),
      this.onlineTrainService.updateContentGoodCourse(item2))
      .subscribe(() => this.onlineTrainService.swap(idx, idx + 1, this.content2),
        () => this.onlineTrainService.swapSort(item1, item2), () => this.trainMessage.sendLoadingAction(false))
  }

}
