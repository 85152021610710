import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-login-contact-menu',
  template: require('./pre-login-contact-menu.component.html'),
  styles: [require('./pre-login-contact-menu.component.scss')]
})
export class PreLoginContactMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
