(function() {
  var messages = require('app2/utils/messages');
  var moment = require('moment');
  var _ = require('lodash');
  var resItemPreview = require('app2/directives/cloud/resItemPreview.directive');

  angular.module('app.prepareCourse')
    .controller('presentResPreviewerCtrl', presentResPreviewerCtrl);

  presentResPreviewerCtrl.$inject = ['$scope', '$q', '$rootScope', '$uibModalInstance', 'notify',
    'Lightbox', 'oedConfig', 'oedPresentResource', 'oedCloudPresentRes', 'cloudRes', 'currentRes', 'resourceDef',
    'oedUserInfo', 'oedCloudPresentResItem', 'oedPresentResourceItem', 'templateRes', 'gotoRes']
  function presentResPreviewerCtrl($scope, $q, $rootScope, $uibModalInstance, notify,
   Lightbox, oedConfig, oedPresentResource, oedCloudPresentRes, cloudRes, currentRes, resourceDef,
   oedUserInfo, oedCloudPresentResItem, oedPresentResourceItem, templateRes, gotoRes) {
    $scope.basePath = oedConfig.base();

    $scope.cloudRes = cloudRes;
    $scope.showImportCtrls = !_.has(cloudRes, '__type') && resourceDef;
    resourceDef = resourceDef || {};

    $scope.resourceDef = resourceDef;
    $scope.templateRes = templateRes;
    $scope.canGoto = !!gotoRes
    $scope.gotoRes = function() {
      gotoRes(cloudRes);
    };

    $scope.importBtnText = _.get(resourceDef, 'importBtnText',  '导入');

    $scope.titleName = cloudRes.name + ' - 预览';
    function getPresentRes() {
      //
      // 如果有__type 这个属性表示是一个普通资源
      if (_.has(cloudRes, '__type') || _.has(cloudRes, 'type') || _.has(cloudRes, '$type')) {
        return oedPresentResource.get({id: cloudRes.id}).$promise;
      }

      return $q.when(cloudRes);
    }

    $scope.loading = getPresentRes().then(function(res) {
      var loadItems = _.map(res.items, function(item) {
        var cloudItem = oedPresentResourceItem.get({
          id: item.id
        });
        return cloudItem.$promise.then(function(ci) {
          ci.res = item;
          return ci;
        });
      });

      return $q.all(loadItems).then(function(allItems) {
        $scope.items = allItems;
      });
    });

    $scope.onCloudResItemClicked = function(res, $event) {
      $event.preventDefault();
      $event.stopPropagation();

      var allReses = _.map($scope.items, 'res');

      var idx = _.findIndex($scope.items, res);
      if (idx < 0) {
        idx = 0;
      }
      // oedCloudPresentResItem.getView({
      //   id: $scope.items[idx].id
      // });

      Lightbox.openModal(allReses, idx);
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
