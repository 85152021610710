import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {OnlineTrainService} from 'app/online-train/online-train.service';

@Component({
  selector: 'app-train-resource-viewer',
  template: require('./train-resource-viewer.component.html'),
  styles: [require('./train-resource-viewer.component.scss')]
})
export class TrainResourceViewerComponent implements OnInit {

  @Input() public url
  @Input() public webUrl
  @Input() public resource
  constructor(public bsModalRef: BsModalRef, private onlineTrainService: OnlineTrainService) { }

  ngOnInit() {
  }

  public close() {
    this.bsModalRef.hide()
  }

  public videoType(resource) {
    return this.onlineTrainService.videoType(resource)
  }

  public textType(resource) {
    return this.onlineTrainService.textType(resource)
  }

  public imgType(resource) {
    return this.onlineTrainService.imgType(resource)
  }
}
