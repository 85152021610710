(function() {
  angular.module('app.directives.menuUserInfo', [])
    .directive('menuUserInfo', menuUserInfo);

  menuUserInfo.$inject = ['oedUserInfoUtils']
  function menuUserInfo(oedUserInfoUtils) {
    return {
      restrict: 'EA',
      scope: {
        openNow: '<'
      },
      template: require('assets/templates/directives/menuUserInfo.html'),
      controller: menuUserInfoController,
    };
  }
})();

menuUserInfoController.$inject = ['$scope', 'userInfo', 'oedUserInfoUtils','$uibModal', '$window', 'oedDialogService']
function menuUserInfoController($scope, userInfo, oedUserInfoUtils,$uibModal, $window, oedDialogService) {
  $scope.userInfo = userInfo.get();
  $scope.userInfoService = userInfo;

  // 判断顶层窗口是否为iframe(配合桌面端使用)
  $scope.topWindowIsIframe = $window.top === $window.self;
  $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl;
  $scope.openCloudDrive = () => {
    oedDialogService.openModalWithComponent('CloudDriveDialogComponent',
      {}, ()=> {},
      {class: 'cl-library-dialog2 modal-cloud-drive'})
  }
  if ($scope.openNow && $scope.openNow === 'true') {
    $scope.openCloudDrive();
  }
}
