import { Injectable } from '@angular/core';
import * as _ from 'lodash'

import { BatchApi } from 'app/utils/batch-api';
import { UnitItemDTO } from './models/material';
import { HttpClient } from '@angular/common/http';
import { RayApiService } from 'app/core/ray-api.service';

@Injectable({
  providedIn: 'root'
})
export class MaterialService {
  private unitItemService: BatchApi<number, UnitItemDTO>

  constructor(private httpClient: HttpClient, private rayApi: RayApiService) {
    this.unitItemService = new BatchApi<number, UnitItemDTO>({
      wait: 50,
      getAll: (ids: number[]) => {
        return httpClient.get<UnitItemDTO[]>(rayApi.apiB('sections/ids'), {
          params: {
            ids: _.map(ids, id => `${id}`),
          }
        }).toPromise().then(items => _.keyBy(items, 'id'))
      }
    })
  }

  public getUnitItemById(id: number) {
    return this.unitItemService.get(id)
  }
}
