import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash'
import { Observable, Subject } from 'rxjs';

import { RayApiService } from 'app/core/ray-api.service'
import {
  LessonDTO, PublishRecordDTO, TeachingModuleDTO, TeachingPatternDTO,
  TestOrPresentDTO, UpdateModuleItemDTO, UpdatePatternModuleDTO,
  RecommendCourseDTO, CoursePathDTO, SectionDTO, ImportedTempCourseDTO,
  CreateTeachingModuleDTO,
  PublishInfoDTO,
  RelatedSectionDTO,
  CreateCourseFromModuleDTO,
  UserInfoDTO,
} from 'app/lesson/models/lesson.d.ts'
import { LessonMessageService } from './lesson-message.service';

@Injectable({
  providedIn: 'root'
})
export class LessonService {

  public dummpyModuleName = ['导入', '新授', '练习', '提高']
  public createModuleSource = new Subject<CreateTeachingModuleDTO>();
  public createModule$ = this.createModuleSource.asObservable();

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService,
              private lessonMessageService: LessonMessageService) {
  }

  public createDummyModules(): TeachingModuleDTO[] {
    return _.map(this.dummpyModuleName, (name: string, index: number) => {
      return {
        name,
        sort: index,
      }
    })
  }

  public createDummyTmpCourse(sectionId: number, courseName = '我的课程1'): LessonDTO {
    const relatedSecs: RelatedSectionDTO = {
      sectionId: _.toNumber(sectionId),
    }
    const c: LessonDTO = {
      id: -1,
      courseName,
      relatedSections: [relatedSecs],
      isRecommend: true,
    }
    return c
  }

  public sendCreateTeachingModuleAction(createDTO: CreateTeachingModuleDTO) {
    this.createModuleSource.next(createDTO)
  }

  public getCourseInfo(id: number): Observable<LessonDTO> {
      return this.httpClient.get<LessonDTO>(this.rayApi.apiB(`course/${id}`))
    }

  public createOrUpdateCourseInfo(id: number, courseDTO: LessonDTO): Observable<LessonDTO> {
    return this.httpClient.post<LessonDTO>(this.rayApi.apiB(`course/${id}`), courseDTO)
  }

  public getCourseByCoursePathId(id: number): Observable<LessonDTO[]> {
      return this.httpClient.get<LessonDTO[]>(this.rayApi.apiA(`coursepath/${id}/courses`))
    }

  public getTeachingPatternByCourseId(id: number): Observable<TeachingPatternDTO> {
      return this.httpClient.get<TeachingPatternDTO>(this.rayApi.apiB(`course/${id}/teachingPattern`))
    }

  public getTestByIds(ids: number[]): Observable<TestOrPresentDTO[]> {
    return this.httpClient.get<TestOrPresentDTO[]>(this.rayApi.apiB(`test/byIds`),  {
      params: {
        ids: _.map(ids, _.toString)
      }
    })
  }

  public getPreResByIds(ids: number[]): Observable<TestOrPresentDTO[]> {
    return this.httpClient.get<TestOrPresentDTO[]>(this.rayApi.apiB(`presentresource/byIds`),  {
      params: {
        ids: _.map(ids, _.toString)
      }
    })
  }

  public updateModuleItemOrder(teachingModule: UpdateModuleItemDTO): Observable<UpdateModuleItemDTO> {
    return  this.httpClient.post<UpdateModuleItemDTO>(
      this.rayApi.apiA(`teachingmodule/${teachingModule.id}/updateitemorder`), teachingModule)
  }

  public saveTeachingModule(teachingModule: TeachingModuleDTO): Observable<TeachingModuleDTO> {
    return  this.httpClient.post<TeachingModuleDTO>(this.rayApi.apiA(`teachingmodule/${teachingModule.id}`),
    teachingModule)
  }

  public updatePatternModuleOrder(orders: UpdatePatternModuleDTO): Observable<TeachingPatternDTO> {
    return  this.httpClient.post<TeachingPatternDTO>(
      this.rayApi.apiA(`teachingpattern/${orders.id}/updatemoduleorder`), orders)
  }
  
  public getCoursePath(id: number): Observable<CoursePathDTO> {
    return this.httpClient.get<CoursePathDTO>(this.rayApi.apiA(`coursepath/${id}`))
  }
  public getExamByCoursePath(id: number): Observable<any[]> {
    return this.httpClient.get<any[]>(this.rayApi.apiB(`exam/coursepath/${id}/all`))
  }
  public getExamById(id: number): Observable<any> {
    return this.httpClient.get<any>(this.rayApi.apiB(`exam/${id}/details`))
  }

  public getRecommendCourseByCoursePathId(id: number): Observable<RecommendCourseDTO[]> {
      return this.httpClient.get<RecommendCourseDTO[]>(this.rayApi.apiA(`coursepath/${id}/recommend`))
  }
  
  public getSectionsByCoursePathIdAndSchoolId(coursepathId: number, schoolId: number): Observable<SectionDTO[]> {
      return this.httpClient.get<SectionDTO[]>(this.rayApi.apiA(`sections/school/${schoolId}/coursepath/${coursepathId}`))
  }

  public getQueryByVersionSubjectIdAndGrade(versionId: number, subjectId: number, gradeId: number): Observable<SectionDTO[]> {
    return this.httpClient.get<SectionDTO[]>(this.rayApi.apiA(`materialversion/${versionId}/subject/${subjectId}/grade/${gradeId}/units`))
  }

  public getPubRecordsOfModule(moduleId: number): Observable<PublishRecordDTO[]> {
    return  this.httpClient.get<PublishRecordDTO[]>(this.rayApi.apiA(`teachingmodule/${moduleId}/publishRecords`))
  }

  public getRecommendCoursesBySectionId(id: number) {
    return this.httpClient.get<LessonDTO[]>(this.rayApi.apiB(`coursepath/recommend/section/${id}`))
  }

  public getCoursesBySectionId(id: number, coursepathId: number) {
    return this.httpClient.get<LessonDTO[]>(this.rayApi.apiB(`course/section/${id}/coursepath/${coursepathId}`))
  }

  public saveTmpCourse(saveTmpCourseDTO: ImportedTempCourseDTO) {
    return this.httpClient.post<LessonDTO>(
      this.rayApi.apiA(`course/importFromExistingCourse`), saveTmpCourseDTO)
  }

  public loadPreResOfCourse(id: number) {
    return this.httpClient.get<TestOrPresentDTO[]>(this.rayApi.apiB(`course/${id}/presentresource`))
  }

  public loadObjOfCourse(id: number) {
    return this.httpClient.get<TestOrPresentDTO[]>(this.rayApi.apiB(`course/${id}/objectivetests`))
  }

  public loadSbjOfCourse(id: number) {
    return this.httpClient.get<TestOrPresentDTO[]>(this.rayApi.apiB(`course/${id}/subjectivetests`))
  }

  public createTeachingModule(createTeachingModuleDTO: CreateTeachingModuleDTO): Observable<TeachingModuleDTO> {
    return this.httpClient.post<TeachingModuleDTO>(this.rayApi.apiA(`teachingmodule`), createTeachingModuleDTO)
  }

  public getPublishRecordInfo(id: number): Observable<PublishInfoDTO> {
    return this.httpClient.get<PublishInfoDTO>(this.rayApi.apiB(`tasklistpublishrecord/${id}/publishinfo`))
  }

  public createCourseWithModule(createDTO: CreateCourseFromModuleDTO): Observable<LessonDTO> {
    return this.httpClient.post<LessonDTO>(this.rayApi.apiB(`course/create/withMoudle`), createDTO)
  }

  public deleteModule(id: number): Observable<void> {
    return this.httpClient.delete<void>(this.rayApi.apiA(`teachingmodule/${id}`))
  }

  public deleteCourse(id: number): Observable<void> {
    return this.httpClient.delete<void>(this.rayApi.apiB(`course/${id}`))
  }
  public getMapping(oem: string, subjectId: number, versionId: number, gradeId: number): Observable<any> {
    return this.httpClient.get<any>(this.rayApi.apiB(`mapping/${oem}/subject/${subjectId}/version/${versionId}/grade/${gradeId}`))
  }
  
  public getChapterSection(body: any): Observable<any> {
    return this.httpClient.post<any>(this.rayApi.apiB('thirdParty/ruanyunRes/Common/GetChapterSection'), body)
  }
  
  public getQuestionCategory(body: any): Observable<any> {
    return this.httpClient.post<any>(this.rayApi.apiB('thirdParty/ruanyunRes/Common/GetQuestionCategory'), body)
  }
  public getUserSession(body: any): Observable<any> {
    return this.httpClient.post<any>(this.rayApi.apiB('thirdParty/ruanyunRes/UserBind/GetUserSession'), body)
  }
  public getQuestionByIntelligence(body: any): Observable<any> {
    return this.httpClient.post<any>(this.rayApi.apiB('thirdParty/ruanyunRes/Search/GetQuestionsByIntelligentQuestion'), body)
  }

  public getModule(id: number): Observable<TeachingModuleDTO> {
    return this.httpClient.get<TeachingModuleDTO>(this.rayApi.apiB(`teachingmodule/${id}`))
  }

  public getUserInfo(): Observable<UserInfoDTO> {
    return this.httpClient.get<UserInfoDTO>(this.rayApi.apiA(`login/userinfo`))
  }

  public getTeachingModulesByPatternId(id: number): Observable<TeachingModuleDTO[]> {
    return this.httpClient.get<TeachingModuleDTO[]>(this.rayApi.apiA(`teachingpattern/${id}/modules`))
  }

}
