import { Injectable, OnDestroy } from '@angular/core';
import * as bluebird from 'bluebird'
import * as _ from 'lodash'
import { HttpClient } from '@angular/common/http';
import { MaintenanceInfoDTO } from 'app/models/system';

import { RayApiService } from 'app/core/ray-api.service';
import { NotificationService } from 'app/shared/notification/notification.service';
import { ActiveToast } from 'ngx-toastr';
import { interval, Subscription, onErrorResumeNext, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {
  private checkIntervel?: Subscription = null
  private lastNotify?: ActiveToast<any> = null
  private show = true; // 是否需要显示 当用户点击了关闭就不在弹出.直到有新的Info出现.
  private infoId = null;
  constructor(
    private rayApi: RayApiService,
    private http: HttpClient,
    private notificationService: NotificationService,
  ) {
  }


  private getMaintenanceInfo() {
    return this.http.get<MaintenanceInfoDTO>(this.rayApi.apiB('system/maintenanceinfo'))
  }


  private clearNotify() {
    if (this.lastNotify) {
      this.notificationService.remove(this.lastNotify)
      this.lastNotify = null
    }
  }


  private handleInfo(info: MaintenanceInfoDTO) {
    // show
    if (info && _.isNumber(info.id) && info.message) {
      const newInfo = this.infoId !== info.id // 是否有新的info出现.
      this.infoId = info.id
      if (newInfo){
        this.clearNotify()
        this.show = true;
      }
      if (!this.show) {
        this.clearNotify()
        return
      }
      if (this.lastNotify) {
        return
      }
      this.lastNotify = this.notificationService.notifyAlways('error', info.message)
      this.lastNotify.onHidden.subscribe(action => {
        this.show = false
      })
    } else {
      this.clearNotify()
    }
  }
  /**
   * start
   * 开始循环检查是否有服务器更新 {每20秒检查一次}
   * 并在有更新的时候提示用户
   */
  public start() {
    this.stop()
    this.checkIntervel = interval(20 * 1000)
    .pipe(switchMap(() => onErrorResumeNext(this.getMaintenanceInfo())))
    // .pipe(switchMap(() => of({id: 1, message: '测试数据'})))
    .subscribe(this.handleInfo.bind(this))
  }

  /**
   * stop
   * 停止检查
   */
  public stop() {
    if (this.checkIntervel && !this.checkIntervel.closed) {
      this.checkIntervel.unsubscribe()
      this.checkIntervel = null
    }
  }
}
