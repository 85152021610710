import * as _ from "lodash";
require("./examReviewNav.scss");
require("assets/app/controllers/examination/examQuesNav/examQuesNavCtrl");

angular
  .module("app.examination.examReview.examReviewNav", [
    "app.examination.examQuesNav",
  ])
  .component("examReviewNav", {
    bindings: {
      examWhole: "<",
      onClickQues: "&",
      currentTag: '<',
      currentNm: '<',
      onClickEnd: '&',
      endEnable: '<'
    },
    template: <string>require("./examReviewNav.html"),
    controller: examReviewNavCtrl,
  });

examReviewNavCtrl.$inject = ["$uibModal"];
function examReviewNavCtrl($uibModal) {
  const ctrl = this;
  ctrl.$onChanges = () => {
    if (ctrl.examWhole) {
      ctrl.examPartDetails = _.get(ctrl.examWhole, "examPartDetails", []);
    }
  };
}
