import {commonReplace} from 'app2/integration/sectionMatchUtils'
import { TalChapterDTO, TalQuestionDetailsDTO, TalKnowledgeDTO } from 'app2/integration/talcloud/models'
import Promise = require('bluebird')
import $ = require('jquery')
import _ = require('lodash')

const blank = '_____'

const talStages = [{
  stage: '1',
  stageName: '小学',
}, {
  stage: '2',
  stageName: '初中',
}, {
  stage: '3',
  stageName: '高中',
}]

const normalizeQues = (q: TalQuestionDetailsDTO, quesMap: any) => {
  if (q && q.subsets) {
   _.each(q.subsets, (s) => {
     const ques = quesMap[q.questionId]
      if (ques && ques.subsets) {
        const map = _.keyBy(ques.subsets, 'questionId')
        normalizeQues(s, map)
      }
   })
  }
  const qmap = quesMap[q.questionId]
  if (!qmap) {
    // 有些数据没有details: questions/details/1361873?
    console.log('q is not valid: ', q)
  } else {
    q.answer = qmap.answer
    q.answerJson = qmap.answerJson
    q.explanation = qmap.explanation
    q.$sourceType = 'talcloud'
    q.$detailLoaded = true
  }
}

export const convertTalChaptersToRaySections = (chpaters: TalChapterDTO[], depth: any) => {
  return _.map(chpaters, (c, idx) => {
   const child: any = convertTalChaptersToRaySections(c.childs, depth + 1)
   return {
     $opened: true,
     child,
     depth,
     id: c.id,
     item_id: c.id,
     name: c.name,
     parent_id: c.upid,
     sort: idx,
     stage: c.stage,
     subjectId: c.subjectId,
   }
 })
}

export const convertTalKnowledgesToRaySections = (knowledges: TalKnowledgeDTO[], depth: any) => {
  return _.map(knowledges, (c, idx) => {
   const child: any = convertTalKnowledgesToRaySections(c.childs, depth + 1)
   return {
     $opened: true,
     child,
     depth,
     id: c.id,
     item_id: c.id,
     name: c.name,
     parent_id: c.upid,
     sort: idx,
     stage: c.stage,
     subjectId: c.subjectId,
   }
 })
}

export const getQuestionDetails = (q: any, talcloudResource: any, userInfo: any, subject?: any, type?: any) => {
  if (q.$rayQuestion) {
    return Promise.resolve(q)
  }

  const qIds = q.questionId
  // if (q.subsets) {
  //   qIds = `${qIds},${_.chain(q.subsets).map('questionId').join(',').value()}`
  // }

  // 综合题
  let query: any = {
    questionIds: qIds
  }
  const thirdPartySchool = _.get(userInfo, 'userInfo.profile.thirdParty', '')
  const thirdPartyUid = _.get(userInfo, 'userInfo.profile.thirdPartyUid', '');
  if (thirdPartySchool && thirdPartySchool.startsWith('tal')) {
    query = {
      ...query,
      school: thirdPartySchool,
      uid: thirdPartyUid,
      subject,
      type
    }
  }
  return talcloudResource.getQuestionDetailsByIds(query)
  .$promise.then((questionsDetails: any) => {
    if (!questionsDetails) {
      q.$valid = false
      return Promise.resolve(q)
    }
    const quesMap = _.keyBy(questionsDetails.data, 'questionId')
    normalizeQues(q, quesMap)
    return Promise.resolve(q)
  })
}

export const getDocumentDetails = (ids: any, talcloudResource: any, userInfo: any, subject?: any, type?: any) => {
  let query: any = {
    itemIds: ids
  }

  const thirdPartySchool = _.get(userInfo, 'userInfo.profile.thirdParty', '');
  const thirdPartyUid = _.get(userInfo, 'userInfo.profile.thirdPartyUid', '');
  if (thirdPartySchool && thirdPartySchool.startsWith('tal')) {
    query = {
      ...query,
      school: thirdPartySchool,
      uid: thirdPartyUid,
      subject,
      type
    }
  }
  return talcloudResource.getDocumentsDetail(query).$promise
}

export const getTalStages = () => {
  return Promise.resolve(talStages)
}

export const fillQuesStemConvert = (ques: TalQuestionDetailsDTO) => {
  if (ques.typeName === '填空题' || ques.baseTypeName === '填空题') {
    ques.stem = _.replace(ques.stem, /({#blank#})/g, blank)
    ques.stem = _.replace(ques.stem, /({#\/blank#})/g, blank)
  }
}

export const isChoiceQuesType = (ques: TalQuestionDetailsDTO) => {
  return (ques.type === '1' && ques.typeName === '单选题') ||
   (ques.type === '2' && ques.typeName === '多选题') ||
   (ques.type === '39' && ques.typeName === '听力题') ||
   (ques.baseType === '1' && ques.baseTypeName === '单选题')
}

export const isYesOrNoQuesType = (ques: TalQuestionDetailsDTO) => {
  return (ques.type === '3' && ques.typeName === '判断题') ||
  (ques.baseType === '3' && ques.baseTypeName === '判断题')
}

export const getChoice = (ques: TalQuestionDetailsDTO): any[] => {
  if (_.isEmpty(ques.options)) {
    return []
  }
  return _.chain(ques.options).head().keys().map(toUpper).value()
}

const toUpper = (l: any) => {
  return _.toUpper(l)
}

export const getChoceValue = (ques: any, c: string) => {
  if (_.isEmpty(ques)) {
    return ''
  }
  const options: any = _.head(ques.options)
  return options[_.toUpper(c)]
}

function fillQuesStemConvertToRay(q: TalQuestionDetailsDTO) {
  q.stem = q.stem.replace(/{#blank#}[\s\S]*?{#\/blank#}/g, '_______')
}

const audioCss = 'edui-upload-audio  vjs-default-skin audio-js'
const audioDefault = `controls="" preload="none" width="auto" data-setup="{}"`

function commonDataExtractor(q: TalQuestionDetailsDTO) {
  const qId = q.questionId
  const content = q.stem
  const fromThirdParty = 'talcloud'
  const fromThirdPartyId = _.toString(q.questionId)
  const audioTag = `<audio class='${audioCss}' ${audioDefault} src='${q.audioFileUrl}'></audio>`
  const questionBody = _.isEmpty(q.audioFileUrl) ? `<p>${content}</p>` : `<p>${audioTag}${content}</p>`
  return {
    $sourceType: 'talcloud',
    fromThirdParty,
    fromThirdPartyId,
    point2: 1,
    question: questionBody,
  }
}

function fillQuestionExtractor(q: TalQuestionDetailsDTO) {
  let answerType = 'exact-match'
  fillQuesStemConvertToRay(q)
  const qId = q.questionId
  const content = q.stem
  const audioTag = `<audio class='${audioCss}' ${audioDefault} src='${q.audioFileUrl}'></audio>`

  // 如果答案为空，则手动批改
  if (_.isEmpty(q.answer)) {
    answerType = 'manual-score'
  }

  let answers: any = q.answerJson
  try {
    if (_.isNil(answers)) {
      answers = JSON.parse(q.answer)
    }
  } catch(e) {
    // 部分填空题格式不是json数据
    console.log('fill answer json parse failed: ', e, answers)
    q.$valid = false
    answers = []
  }
  const answerStr = JSON.stringify({
    answer: _.map(answers, (an: string) => {
      const nonHtmlAnswer = $(`<p>${an}</p>`)
      return [nonHtmlAnswer.text()]
    } ),
    answerPoints: _.map(answers, () => 1),
    answerTypes: _.map(answers, (an) => {
      const nonHtmlAnswer = $(`<p>${an}</p>`)
      const text = nonHtmlAnswer.text()
      if (!text) {
        answerType = 'manual-score'
      } else {
        answerType = 'exact-match'
      }
      return answerType
    }),
  })
  return {
    answer: answerStr,
    point2: _.size(answers),
    question: _.isEmpty(q.audioFileUrl) ? `<p>${content}</p>` : `<p>${audioTag}${content}</p>`,
    type: 'fill',
  }
}

function answerDetailsExtractor(q: TalQuestionDetailsDTO) {
  const answerDetails = q.explanation
  return {
    answerDetails,
  }
}

function qaDataExtractor(q: TalQuestionDetailsDTO) {
  return {
    answer: '-1',
    answerDetails: _.isEmpty(q.explanation) ? q.answer : q.explanation,
    type: 'qa',
  }
}

function parseChoiceAnswers(answers: string[]): string {
  const index = _.findIndex(answers, (s: string) => {
    return !_.isEmpty(s)
  })
  return index.toString()
}

function parseChoiceAnswerFromTalAnswer(answer: any) {
  return ((_.toUpper(answer)).charCodeAt(0) - 'A'.charCodeAt(0)).toString()
}

const aCode = 'a'.charCodeAt(0)
function buildDefaultChoices(options: any) {
  if (_.isEmpty(options)) {
    return
  }
  const sortKeys = _(options[0]).keys().sortBy().value()
  return _.map(sortKeys, (key) => {
    const value = _.get(options[0], key, '')
    return {
      content: `<p>${_.isEmpty(value) ? '' : value}</p>`,
    }
  })
}

function singleChoiceDataExtractor(q: TalQuestionDetailsDTO) {
  let valid = true
  if (_.isNil(q.answerJson) && _.isEmpty(q.answer)) {
    valid = false
  }

  //
  // 选项数只能从答案中反推...
  const answer = !_.isNil(q.answerJson) ? parseChoiceAnswers(q.answerJson) :
  parseChoiceAnswerFromTalAnswer(q.answer)

  // 0 保存时会报invalid entity, 这个时候保存不了，需要老师手动设置答案
  return {
    answer: _.chain(answer).toNumber().isNumber().value() ? answer : 0,
    answerDetails: q.explanation,
    choices: buildDefaultChoices(q.options),
    type: 'singlechoice',
    $valid: valid,
  }
}

function parseChoiceAnswer(answer: string) {
  if (_.isEmpty(answer)) {
    return null
  }
  return _.chain(answer).trim().replace(/,/g, '').split('').map((answer: string) => {
    const aCode = 'a'.charCodeAt(0);
    const answerCode = answer.toLowerCase().charCodeAt(0);
    return answerCode - aCode;
  }).join(',').value()
}

function parseChiceAnswerArray(answerArray) {
  return _.chain(answerArray).map((a) => {
    if (_.isEmpty(a)) {
      return null
    }
    const aCode = 'a'.charCodeAt(0);
    const answerCode = a.toLowerCase().charCodeAt(0);
    return answerCode - aCode;
  }).flatten().filter().join(',').value()
}

function parseMultiChoiceAnswers(answer: string, answerJson: string[]) {
 if (!_.isEmpty(answer)) {
   return parseChoiceAnswer(answer)
 } else if (!_.isEmpty(answerJson)) {
   return parseChiceAnswerArray(answerJson) 
 } else {
   return null
 }
}

function multiChoiceQuestionExtractor(q: TalQuestionDetailsDTO) {
//
  // 选项数只能从答案中反推...
  const answer = parseMultiChoiceAnswers(q.answer, q.answerJson)

  if (_.isEmpty(answer) || _.includes(answer, '-1')) {
    return {
      $valid: false,
    }
  }

  return {
    answer,
    answerDetails: q.explanation,
    choices: buildDefaultChoices(q.options),
    type: 'multichoice',
  }
}

function parseYesornoAnswer(q: TalQuestionDetailsDTO) {
  if (!_.isEmpty(q.answer)) {
    return _.get(q, 'answer', 0)
  } else {
    return _.get(q, 'answerJson.0', 0)
  }
}

function yesOrNoQuestionExtractor(q: TalQuestionDetailsDTO) {
  const answer = parseYesornoAnswer(q)
  if (answer !== "1" && answer !== "0") {
    q.$valid = false
    return
  }
  return {
    answer,
    answerDetails: q.explanation,
    type: 'yesorno',
  }
}
function syntheticQuestionExtractor(q: TalQuestionDetailsDTO) {
  const childQuestions = _.map(q.subsets, (s: any) => {
    const answerDetails = q.explanation
    return {
      answerDetails,
      ...convertTalcloudQuestionToRayQ(s),
    }
  });
  return {
    childQuestions,
    type: 'synthetic',
  }
}

function fillToSyntheticQuestionExtractor(q: TalQuestionDetailsDTO) {
  const childQuestions = _.map(q.options, (s, index) => {
    const answerDetails = q.explanation;
    const answers: any = q.answerJson[index];
    const obj: any = {
      $detailLoaded: true,
      $sourceType: 'talcloud',
      answer: answers.join(''),
      answerJson: answers,
      audioFileUrl: q.audioFileUrl,
      baseType: '1',
      baseTypeName: '单选题',
      create_at: q.created_at,
      difficult: q.difficult,
      difficultName: q.difficultName,
      examType: q.examType,
      examTypeName: q.examTypeName,
      explanation: q.explanation,
      grade: q.grade,
      knowledge: q.knowledge,
      options: [q.options[index]],
      paperId: q.paperId,
      parentId: q.questionId,
      questionId: q.questionId + '' + index,
      source: q.source,
      stage: q.stage,
      stem: '',
      subjectId: q.subjectId,
      subsets: null,
      type: '1',
      typeName: '单选题',
    };
    return {
      answerDetails,
      ...convertTalcloudQuestionToRayQ(obj),
    }
  });
  return {
    childQuestions,
    type: 'synthetic',
  }
}

const simpleQaExtractor = [qaDataExtractor]
/* tslint:disable: object-literal-sort-keys */
const convertConfigs = {
  '解答题': simpleQaExtractor,
  '解答题(主观题)': simpleQaExtractor,
  '单项选择题': singleChoiceDataExtractor,
  '单选题': singleChoiceDataExtractor,
  '选择题': singleChoiceDataExtractor,
  '综合题': [answerDetailsExtractor, fillQuestionExtractor],
  '填空题': [answerDetailsExtractor, fillQuestionExtractor],
  '完型填空题': [answerDetailsExtractor, fillToSyntheticQuestionExtractor],
  '判断题': [answerDetailsExtractor, yesOrNoQuestionExtractor],
  '多选题': [answerDetailsExtractor, multiChoiceQuestionExtractor],
  '复合题': [answerDetailsExtractor, syntheticQuestionExtractor],
}
// 已经解析的有: 填空题，单选题，多选题，判断题
export function convertTalcloudQuestionToRayQ(q: TalQuestionDetailsDTO) {
  let $valid = true
  if (!q || !q.answer && !q.answerJson && !q.subsets) {
    $valid = false
  }

  if (q.$rayQuestion) {
    return q
  }
  const commQ = commonDataExtractor(q)
  let conveters: any
  let typeName = q.baseTypeName
  if (!_.has(convertConfigs, typeName)) {
    typeName = q.baseTypeName
  }
  if (_.has(convertConfigs, typeName)) {
    conveters = _.flatten([_.get(convertConfigs, typeName)])
  } else {
    conveters = simpleQaExtractor
    console.warn(`question transformation for ${typeName} not implemented`)
  }
  const qData: any = _.map(conveters, (c: any) => c(q))
  const allData = (<any> _.spread)(_.defaults, 1)({}, qData)
  const ray = {
    $valid,
    ...commQ,
    ...allData,
    $rayQuestion: true,
  }
  return ray
}
