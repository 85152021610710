/**
 * @fileOverview
 * @name questionReviewDetailsCtrl.js
 * @author pangwa
 * @license
 */
(function() {

  angular.module('app.review')
    .directive('questionReviewDetailsCtrl', questionReviewDetailsCtrl);


  questionReviewDetailsCtrl.$inject = []
  function questionReviewDetailsCtrl() {
    return {
      restrict: 'EA',
      require: '^ngModel',
      scope: {
        ngModel: '=',
        qIndex: '=',
        qReadonly: '=',
        qSessionId: '=',
        qUserId: '=',
        scoreChanged: '&onScoreChanged',
        submitQaPoint: '&',
      },
      template: require('assets/templates/review/questionReviewDetails.html'),
      controller: questionReviewDetailsCtrlController,
    };
  }
})();

questionReviewDetailsCtrlController.$inject = ['$scope', 'oedTestUtils']
function questionReviewDetailsCtrlController($scope, oedTestUtils) {
  $scope.getChoiceName = oedTestUtils.getChoiceName;

  $scope.showChoices = function() {
    return $scope.ngModel.question.type == 'singlechoice' || $scope.ngModel.type == 'multichoice';
  };

  $scope.getQuestionNumber = function() {
    return $scope.qIndex;
  };

  $scope.getLeftOptName = oedTestUtils.getChoiceName;

  $scope.getRightOptName = function(idx) {
    return idx + 1;
  };

  $scope.getRelatedSectionNames = function() {
    if (_.isEmpty($scope.ngModel.question.relatedSections)) {
      return '无';
    }

    return _($scope.ngModel.question.relatedSections).map(function(sec) {
      return sec.sectionName;
    }).join(', ');
  };

  var loadAnswerFromModel = function(answer) {
    $scope.answers = {};
    answer = answer || '';
    var answers = answer.split(',');
    _.each(answers, function(aws) {
      var parts = aws.split(':');
      if (_.size(parts) != 2) {
        console.log('error parsing answer: ' + aws);
        return;
      }

      var idx = _.parseInt(parts[0]);
      var choiceIdx = _.parseInt(parts[1]);
      var existing = _.get($scope.answers, idx, []);
      existing.push(choiceIdx);
      _.set($scope.answers, idx, existing);
    });
  };

  if ($scope.ngModel.question.type == 'connect') {
    loadAnswerFromModel(_.get($scope.ngModel, 'answer.answer'));
  }
}
