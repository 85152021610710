(function() {
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.classHourManagement', {
        url: '/classHourManagement',
        template: require('assets/templates/manage/classHourManagement.html'),
        controller: 'classHourManagementCtrl',
        bodyStyle: 'bg_img'
      });
      $stateProvider.state('manage.teachWeekDetail', {
        url: '/teachWeekDetail',
        template: require('assets/templates/manage/teachWeekDetail.html'),
        controller: 'teachWeekDetailCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('classHourManagementCtrl', classHourManagementCtrl)
    .controller('teachWeekDetailCtrl', teachWeekDetailCtrl)
    .controller('createTeachWeekCtrl', createTeachWeekCtrl)
    .controller('editclassHourInfoCtrl', editclassHourInfoCtrl);

  classHourManagementCtrl.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$q', '$uibModal', 'localStorageService',
    'notificationService', 'userInfo', 'oedUserInfo', 'oedSubjects', 'oedTeachingWeek']
  function classHourManagementCtrl($rootScope, $scope, $state, $stateParams, $q, $uibModal, localStorageService,
    notificationService, userInfo, oedUserInfo, oedSubjects, oedTeachingWeek) {
    $scope.currentState.title = '课时管理';
    userInfo.then(function(info) {
      $scope.userInfo = info;
      $scope.load();
    });
    var thisYear = new Date().getFullYear();
    $scope.years = [(thisYear-2) + ' - ' + (thisYear-1), (thisYear-1) + ' - ' + thisYear, thisYear + ' - ' + (thisYear+1)];
    $scope.semesters = [
      {
        id: 1,
        name:'第一学期'
      },
      {
        id: 2,
        name:'第二学期'
      }
    ];
    $scope.curPage = 1;
    $scope.pageSize = 15;

    var teachWeekObj = localStorageService.get('teachWeekDetail');
    if (teachWeekObj) {
      // var obj = JSON.parse($stateParams.teachWeekObj);
      $scope.selectYear = (teachWeekObj.year-1) + ' - ' + teachWeekObj.year;
      $scope.selectSemester = $scope.semesters[teachWeekObj.semester -1];
    } else {
      $scope.selectYear = $scope.years[1];
      $scope.selectSemester = $scope.semesters[0];
    }

    $scope.getTeachWeekList = function (curPage, pageSize, schoolId, selectSemester, year) {
      var yea = year.substring(7);
      oedTeachingWeek.getTeachingWeekCount(
        {
          schoolId: schoolId,
          semester: selectSemester,
          year: yea
        }
      ).$promise.then(cou => {
        $scope.totalNums = cou.count;
      });
      oedTeachingWeek.getTeachingWeekList(
        {
          schoolId: schoolId,
          semester: selectSemester,
          year: yea,
          start: (curPage - 1) * pageSize,
          count: pageSize
        }
      ).$promise.then(res => {
        _.forEach(res, (item) => {
          var start = new Date(item.startDate);
          var end = new Date(item.endDate);
          item.startDate =  start.getFullYear() + '-' + ((start.getMonth() + 1) > 9 ? (start.getMonth() + 1) : ('0' + (start.getMonth() + 1))) + '-' + (start.getDate() > 9 ? start.getDate() : ('0' + start.getDate()));
          item.endDate = end.getFullYear() + '-' + ((end.getMonth() + 1) > 9 ? (end.getMonth() + 1) : ('0' + (end.getMonth() + 1))) + '-' + (end.getDate() > 9 ? end.getDate() : ('0' + end.getDate()));
        });
        $scope.teachWeeks = res;
      });
    };

    $scope.load = function () {
      $scope.loading = $scope.getTeachWeekList($scope.curPage, $scope.pageSize, $scope.userInfo.schoolId, $scope.selectSemester.id, $scope.selectYear);
    };

    $scope.onPageChange = function(curPage) {
      $scope.curPage = curPage;
      $scope.loading = $scope.getTeachWeekList(curPage, $scope.pageSize, $scope.userInfo.schoolId, $scope.selectSemester.id, $scope.selectYear);
    };

    $scope.yearSelected = function (item, model) {
      $scope.selectYear = item;
      $scope.load();
    };

    $scope.semesterSelected = function (item, model) {
      $scope.selectSemester = item;
      $scope.load();
    };

    $scope.createTeachWeek = function () {
      var obj = {
        selectYear: $scope.selectYear,
        selectSemester: $scope.selectSemester
      };
      var ctw = $uibModal.open({
        template: require('assets/templates/manage/createTeachWeek.html'),
        controller: 'createTeachWeekCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          teachWeek: function () {
            return null;
          },
          obj: function () {
            return obj;
          }
        }
      });
      ctw.result.then(function(res) {
        if (res) {
          $scope.goDetail(res);
        }
      });
    };

    $scope.goDetail = function (tws) {
      // var obj = JSON.stringify(tws);
      // $state.go('manage.teachWeekDetail', {teachWeek: obj});
      $state.go('manage.teachWeekDetail');
      localStorageService.set('teachWeekDetail', tws);
    }
  }

  createTeachWeekCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal', '$uibModalInstance',
    'notificationService', 'userInfo', 'oedUserInfo', 'oedSubjects', 'teachWeek', 'obj', 'oedTeachingWeek']
  function createTeachWeekCtrl($scope, $state, $stateParams, $q, $uibModal, $uibModalInstance,
    notificationService, userInfo, oedUserInfo, oedSubjects, teachWeek, obj, oedTeachingWeek) {
    var thisYear = new Date().getFullYear();
    $scope.years = [(thisYear-2) + ' - ' + (thisYear-1), (thisYear-1) + ' - ' + thisYear, thisYear + ' - ' + (thisYear+1)];
    userInfo.then(function(info) {
      $scope.userInfo = info;
    });
    $scope.timeFail = false;
    $scope.dueDateOpened = false;
    $scope.startDateOpened = false;
    $scope.semesters = [
      {
        id: 1,
        name:'第一学期'
      },
      {
        id: 2,
        name:'第二学期'
      }
    ];

    $scope.getDate = function() {
      userInfo.then(function(info) {
        oedTeachingWeek.getTermDate({schoolId: _.toNumber(info.schoolId)}).$promise.then(res => {
          var end = new Date($scope.selectYear.substring(7) + '-' + res[1].values).getTime() - 24*60*60*1000;
          $scope.startDate = new Date($scope.selectYear.substring(7)-1 + '-' + res[0].values);
          $scope.endDate = new Date($scope.selectYear.substring(7) + '-' + res[1].values);
          if ($scope.selectSemester.id === 1) {
            $scope.startDateSelect = $scope.startDate;
            $scope.endDateSelect = new Date(end);
          } else {
            $scope.startDateSelect = $scope.endDate;
            end = new Date($scope.selectYear.substring(7) + '-' + res[0].values).getTime() - 24*60*60*1000;
            $scope.endDateSelect = new Date(end);
          }
          if (teachWeek === null) {
            $scope.weekInfo = {
              weekName:'',
              startDate: $scope.selectSemester.id === 1? $scope.startDate : $scope.endDate,
              dueDate: $scope.selectSemester.id === 1? $scope.startDate : $scope.endDate
            };
          }
          $scope.dateOptions = {
            minDate: new Date($scope.startDateSelect),
            maxDate: new Date($scope.endDateSelect)
          };
          $scope.dateOptions1 = {
            minDate: new Date($scope.startDateSelect),
            maxDate: new Date($scope.endDateSelect)
          };
        });
      });
    };

    $scope.yearSelected = function (item, model) {
      $scope.selectYear = item;
      $scope.getDate();
    };

    $scope.semesterSelected = function (item, model) {
      $scope.selectSemester = item;
      $scope.getDate();
    };

    $scope.changeNum = function () {
      var val = document.getElementById('weekName').value;
      document.getElementById('weekName').value = (val > 0 || !val) ? val : 1;
    };

    if (teachWeek === null) {
      $scope.modalTitle = '新建教学周';
      $scope.btnText = '新建';
      $scope.selectYear = obj.selectYear;
      $scope.selectSemester = obj.selectSemester;
    } else if (teachWeek) {
      $scope.modalTitle = '教学周设置';
      $scope.btnText = '设置';
      $scope.selectYear = teachWeek.year;
      $scope.selectYear = (teachWeek.year - 1) + ' - ' + teachWeek.year;
      $scope.selectSemester = teachWeek.semester === 1 ? $scope.semesters[0] : $scope.semesters[1];
      $scope.weekInfo = {
        weekName: teachWeek.sort,
        startDate: new Date(teachWeek.startDate),
        dueDate: new Date(teachWeek.endDate)
      };
    }
    $scope.getDate();

    $scope.openDueDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.dueDateOpened = true;
    };

    $scope.openStartDate = function($event) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.startDateOpened = true;
    };

    $scope.ok = function () {
      if (!$scope.weekInfo.weekName) {
        $scope.showValidationError = true;
        return;
      } else {
        $scope.showValidationError = false;
      }
      if (!$scope.weekInfo.startDate || !$scope.weekInfo.dueDate) {
        $scope.timeFail = true;
        return;
      }
      if (new Date($scope.weekInfo.startDate) < new Date($scope.weekInfo.dueDate)) {
        $scope.timeFail = false;
      } else {
        $scope.timeFail = true;
        return;
      }
      var start = new Date(new Date($scope.weekInfo.startDate).toLocaleDateString()).getTime();
      var end = new Date(new Date($scope.weekInfo.dueDate).toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1000;
      var year = $scope.selectYear.substring(7);
      if (teachWeek === null) {
        var schoolId = _.toNumber($scope.userInfo.schoolId);
        oedTeachingWeek.createTeachingWeek({
          schoolId: schoolId,
          sort: $scope.weekInfo.weekName,
          semester: $scope.selectSemester.id,
          year: parseInt(year),
          startDate: start,
          endDate: end
        }).$promise.then( res => {
          $uibModalInstance.close(res);
        }, error => {
          if (_.includes(error.data.message, 'teachingWeek/existSort')) {
            $scope.existSort = true;
            $scope.invalidDate = false;
            $scope.existDateScope = false;
          } else if (_.includes(error.data.message, 'teachingWeek/existDateScope')) {
            $scope.existDateScope = true;
            $scope.existSort = false;
            $scope.invalidDate = false;
          } else if (_.includes(error.data.message, 'teachingWeek/invalidDate')) {
            $scope.invalidDate = true;
            $scope.existSort = false;
            $scope.existDateScope = false;
          }
        });
      } else if (teachWeek) {
        oedTeachingWeek.updateTeachingWeek(
          {
            id: teachWeek.id,
            schoolId: teachWeek.schoolId,
            sort: $scope.weekInfo.weekName,
            semester: $scope.selectSemester.id,
            year: parseInt(year),
            startDate: start,
            endDate: end
          }
        ).$promise.then(res => {
          $uibModalInstance.close(res);
          notificationService.notify('info', '教学周信息设置成功')
        }, error => {
          if (_.includes(error.data.message, 'teachingWeek/existSort')) {
            $scope.existSort = true;
            $scope.invalidDate = false;
            $scope.existDateScope = false;
          } else if (_.includes(error.data.message, 'teachingWeek/existDateScope')) {
            $scope.existDateScope = true;
            $scope.existSort = false;
            $scope.invalidDate = false;
          } else if (_.includes(error.data.message, 'teachingWeek/invalidDate')) {
            $scope.invalidDate = true;
            $scope.existSort = false;
            $scope.existDateScope = false;
          }
        })
      }
    };

    $scope.cancel = function(result) {
      $uibModalInstance.close(result);
    };
  }

  teachWeekDetailCtrl.$inject = ['$scope', '$state', '$stateParams', 'dialogs', '$q', 'Upload', '$uibModal', 'localStorageService',
    'notificationService', 'userInfo', 'oedTeachingWeek', 'oedFileUtils', 'oedConfig']
  function teachWeekDetailCtrl($scope, $state, $stateParams, dialogs, $q, Upload, $uibModal, localStorageService,
    notificationService, userInfo, oedTeachingWeek, oedFileUtils, oedConfig) {
    $scope.currentState.title = '课时管理';
    $scope.pageSize = 15;
    // $scope.teachWeek = JSON.parse($stateParams.teachWeek);
    $scope.teachWeek = localStorageService.get('teachWeekDetail');
    $scope.curPage = 1;
    $scope.currentState.titleTwo = '第' + $scope.teachWeek.sort + '教学周';

    $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){
      if (_.includes(fromState.url, 'teachWeekDetail') && _.includes(toState.url, 'classHourManagement')) {
        // $state.go('manage.classHourManagement', {teachWeekObj: fromParams.teachWeek})
        $state.go('manage.classHourManagement');
        localStorageService.set('teachWeekDetail', $scope.teachWeek);
      }
    });

    $scope.getClassHours = function (curPage, pageSize) {
      var start = new Date(new Date($scope.teachWeek.startDate).toLocaleDateString()).getTime();
      var end = new Date(new Date($scope.teachWeek.endDate).toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1000;
      oedTeachingWeek.getClassHourCount({
        teachWeekId: $scope.teachWeek.id
      }).$promise.then(res => {
        $scope.totalNums = res.count;
      });
      oedTeachingWeek.getClassHourList({
        teachWeekId: $scope.teachWeek.id,
        start: (curPage - 1) * pageSize,
        count: pageSize
      }).$promise.then(res => {
        _.forEach(res, item => {
          oedTeachingWeek.getPadClassHourCount({
            grade: item.grade,
            teacherId: item.teacherId,
            schoolId: item.schoolId,
            levelId: item.levelId,
            subjectId: item.subjectId,
            startDate: start,
            endDate: end
          }).$promise.then(cou => {
            var grade;
            switch (item.grade) {
              case 1:
                grade = '一年级';
                break;
              case 2:
                grade = '二年级';
                break;
              case 3:
                grade = '三年级';
                break;
              case 4:
                grade = '四年级';
                break;
              case 5:
                grade = '五年级';
                break;
              case 6:
                grade = '六年级';
                break;
              case 7:
                grade = '七年级';
                break;
              case 8:
                grade = '八年级';
                break;
              case 9:
                grade = '九年级';
                break;
              default:
                break;
            }
            item.levelGradeName = item.levelName + grade;
            item.padCount = cou.count;
          });
        });
        $scope.classHourList = res;
      });
    };
    $scope.getClassHours($scope.curPage, $scope.pageSize);

    $scope.tooltip1 = '<p>Pad课时数由系统自动计算，每天刷新一次，建议间隔一天进行查询。该数值不可自行修改，请核实有效课时计算条件，如有问题请联系平台技术支持。</p>' +
      '<p>有效课时满足以下五个条件之一：</p>' +
      '<p>1.推送了一套客观试卷，提交答案（非提交测试）的学生数大于5</p>' +
      '<p>2.推送了一个主观活动，提交白板的学生数大于5</p>' +
      '<p>3.推送了一个展示资源包，在线学生数大于5</p>' +
      '<p>4.推送了一个展示资源单项，在线学生数大于5</p>' +
      '<p>5.推送了一个环节，满足如下两个条件之一:</p>' +
      '<p>a.环节中的作答情况符合上述1, 2, 3中的条件</p>' +
      '<p>b.环节提交的学生数大于5，同时在线学生数也大于5</p>' +
      '<p>此外还有一个必须条件：上课持续时间大于5分钟</p>';

    $scope.setTeachWeek = function () {
      var ect = $uibModal.open({
        template: require('assets/templates/manage/createTeachWeek.html'),
        controller: 'createTeachWeekCtrl',
        size: 'md',
        windowClass: 'signupModal',
        resolve: {
          teachWeek: function () {
            return $scope.teachWeek;
          },
          obj: function () {
            return null;
          }
        }
      });
      ect.result.then(function(res) {
        if (res) {
          $scope.teachWeek = res;
          $scope.currentState.titleTwo = '第' + res.sort + '教学周';
        }
      });
    };

    $scope.uploadFile = function (files) {
      if (_.isEmpty(files)) return;
      var url = oedConfig.url('teachingWeek/importTeachingData');
      $scope.loading = Upload.upload({
        url: url,
        method: 'POST',
        data: {fileData: files[0], schoolId: $scope.teachWeek.schoolId, teachingWeekId: $scope.teachWeek.id}
      }).then(function(resp) {
        if (resp.data.success) {
          $scope.importError = false;
          notificationService.notify('info', '课时导入成功')
          $scope.getClassHours($scope.curPage, $scope.pageSize);
        } else {
          $scope.importError = true;
          $scope.errorMessage = resp.data.message;
        }
      }, function() {
        notificationService.notify('error', '文件上传失败!')
      });
    };

    $scope.deleteTeachWeek = function () {
      var dlg = dialogs.confirm('删除教学周', '你确定要删除此教学周吗？');
      dlg.result.then(function() {
        $scope.loading = oedTeachingWeek.deleteTeachingWeek({
          id: $scope.teachWeek.id
        }).$promise.then(res => {
          notificationService.notify('info', '删除成功')
          // $state.go('manage.classHourManagement', {teachWeekObj: $stateParams.teachWeek})
          $state.go('manage.classHourManagement')
        });
      });
    };

    $scope.onPageChange = function(curPage) {
      $scope.curPage = curPage;
      $scope.loading = $scope.getClassHours(curPage, $scope.pageSize);
    };


    $scope.editClassHour = function(cla) {
      var ech = $uibModal.open({
        template: require('assets/templates/manage/editclassHourInfo.html'),
        controller: 'editclassHourInfoCtrl',
        size: 'sm',
        windowClass: 'signupModal',
        resolve: {
          cla: function() {
            return cla;
          }
        }
      });
      ech.result.then(function(res) {
        if (res) {
          $scope.getClassHours($scope.curPage, $scope.pageSize);
        }
      });
    };

    $scope.removeClassHour = function(cla) {
      var dlg = dialogs.confirm('删除课时', '你确定要删除此课时吗？');
      dlg.result.then(function() {
        $scope.loading = oedTeachingWeek.deleteClassHour({
          classHourId: cla.id
        }).$promise.then(res => {
          notificationService.notify('info', '删除成功')
          $scope.getClassHours($scope.curPage, $scope.pageSize);
        })
      });
    };
  }

  editclassHourInfoCtrl.$inject = ['$scope', '$state', '$stateParams', 'dialogs', '$q', '$uibModal', '$uibModalInstance',
    'notificationService', 'userInfo', 'oedSchoolLevel', 'oedCoursePath', 'oedTeachingWeek', 'oedOem', 'cla']
  function editclassHourInfoCtrl($scope, $state, $stateParams, dialogs, $q, $uibModal, $uibModalInstance,
    notificationService, userInfo, oedSchoolLevel, oedCoursePath, oedTeachingWeek, oedOem, cla) {
    $scope.cla = cla;
    $scope.loading = oedCoursePath.getAllSubjects().$promise.then(res => {
      $scope.subjects = res;
      _.forEach($scope.subjects, item => {
        if (item.id === cla.subjectId) {
          $scope.selectSubject = item;
        }
      });
    });
    $scope.weekClassNum = cla.expectSessionNum;
    $scope.selectGrade = {levelId: cla.levelId, levelName: cla.levelGradeName, grade: cla.grade};
    $scope.juniorGrades = [
      '一年级',
      '二年级',
      '三年级',
      '四年级',
      '五年级',
      '六年级'
    ];
    $scope.juniorMiddleGrades = [
      '一年级',
      '二年级',
      '三年级',
      '四年级'
    ];
    $scope.highGrades = [
      '一年级',
      '二年级',
      '三年级'
    ];
    $scope.grades = [];
    oedSchoolLevel.querySchoolLevels({schoolId: cla.schoolId}).$promise.then(res => {
      _.forEach(res, item => {
        if (_.includes(item.levelName, '小学')) {
          _.forEach($scope.juniorGrades, (g, i) => {
            var obj = {};
            obj.levelId = item.id;
            obj.levelName = item.levelName + g;
            obj.grade = i + 1;
            $scope.grades.push(obj);
          });
        } else if (_.includes(item.levelName, '初中')) {
          _.forEach($scope.juniorMiddleGrades, (g, i) => {
            var obj = {};
            obj.levelId = item.id;
            obj.levelName = item.levelName + g;
            obj.grade = i + 1;
            $scope.grades.push(obj);
          });
        } else if (_.includes(item.levelName, '高中') || _.includes(item.levelName, '中职')) {
          _.forEach($scope.highGrades, (g, i) => {
            var obj = {};
            obj.levelId = item.id;
            obj.levelName = item.levelName + g;
            obj.grade = i + 1;
            $scope.grades.push(obj);
          });
        }
      });
    });

    $scope.changeNum = function () {
      var val = document.getElementById('sessionNum').value;
      document.getElementById('sessionNum').value = (val > 0 || !val) ? val : 1;
    };

    $scope.subjectSelected = function (sub, model) {
      $scope.selectSubject = sub;
    };
    $scope.gradeSelected = function (gra, model) {
      $scope.selectGrade = gra;
    };
    $scope.ok = function () {
      $scope.showValidationError = true;
      if (!$scope.editInfoForm.$invalid) {
        $scope.showValidationError = false;
      } else {
        return;
      }
      oedTeachingWeek.updateClassHour({
        classHourId: cla.id,
        teachingWeekId: cla.teachingWeekId,
        teacherId: cla.teacherId,
        subjectId: this.selectSubject.id,
        levelId: this.selectGrade.levelId,
        schoolId: cla.schoolId,
        grade: this.selectGrade.grade,
        expectSessionNum: $scope.weekClassNum
      }).$promise.then(res => {
        $uibModalInstance.close(res);
      });
    };

    $scope.cancel = function(result) {
      $uibModalInstance.close(result);
    };
  }

})();
