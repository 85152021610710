(function() {
  var templateMarkPopup = require('app2/directives/templateMarkPopup.directive');
  require('assets/app/controllers/examination/examCardList/examCardListCtrl');
  require('assets/app/controllers/examination/examPaper/examPaperCtrl');
  require('assets/app/controllers/examination/examCreate/createExamPaperCtrl');
  require('assets/app/controllers/recycleBinDialog/recycleBinDialogCtrl.ts');
  angular.module('app.prepareLesson', [
    'ui.router',
    'ngCookies',
    'app.directives.templateMarkPopup',
    'app.examination.examCardList',
    'app.examination.examPaper',
    'app.examination.createExamPaper',
    'app.prepareCourse.recycleBinDialog',
  ])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareLesson', {
        url: '/prepareLesson',
        template: require('assets/templates/preparelesson.html'),
        controller: 'prepareLessonCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'bged2'
      });

      $stateProvider.state('prepareLesson2',{
        url: '/prepareLesson2',
        template: require('assets/templates/preparelesson.html'),
        controller: 'prepareLessonCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'bged2'
      });

    }])
    .controller('prepareLessonCtrl', prepareLessonCtrl)
    .controller('addCoursePathCtrl', addCoursePathCtrl)
    .controller('addCourseCtrl', addCourseCtrl)
    .controller('editCourseCtrl', editCourseCtrl);

  prepareLessonCtrl.$inject = ['$scope', '$state', '$location', '$uibModal', 'localStorageService', '$animate',
    '$q', 'dialogs', 'resize', 'userInfo', 'oedCoursePath', 'oedCourse', 'oedSchoolTerm', 'notificationService', '$element', 'oedRecommendCourse',
    'preferenceService', 'oedDialogService']
  function prepareLessonCtrl($scope, $state, $location, $uibModal, localStorageService, $animate,
    $q, dialogs, resize, userInfo, oedCoursePath, oedCourse, oedSchoolTerm, notificationService, $element, oedRecommendCourse, preferenceService, oedDialogService) {
    $scope.showCompleted = false;
    $scope.swiper1 = {swiper: {}};
    $scope.newVersion = _.includes($location.path(),'/prepareLesson2');
    // 获取用户所在学习是否开启新版备课
    userInfo.then((info) => {
      $scope.userInfoDto = info;
      return preferenceService
        .getSchoolNewPrepareLessonState(info.schoolId)
        .toPromise()
        .then((state) => {
          $scope.schoolNewPrepareLessonState = state;
        })
    });
    $scope.isNewPrepareLesson = _.includes($state.current.name, 'prepareLesson2');
    $scope.isPreLesson = localStorageService.get('isPreLesson');
    $scope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams){
      if(toState.name == 'prepareLesson.newCourseList'){
        $scope.isPreLesson = true;
      }else{
        $scope.isPreLesson = false;
        if (fromState.name == 'prepareLesson.newCourseList') {
          $scope.coursePaths = oedCoursePath.queryFilter({
            userId: _.parseInt($scope.userInfoDto.uid),
            onGoing: true,
            unStarted: true, //false, // TODO: 处理归档
            ended: true //$scope.showCompleted // TODO: 处理归档
          });
          updateCurrentCoursePath(true);
        }
      }
    });
    $scope.$on('$destroy', function() {
      $scope.isDone = true;
    });
    $scope.strLength = function(str) {
      return _.size(str);
    };
    $scope.onUseOld = () => {
      localStorageService.set('prepareLessonUseNewVersion');
      userInfo.then((info) => {
        preferenceService
          .updateUserNewPrepareLessonState({uid: Number(info.uid), value: '0'})
          .subscribe((next) => {
          }, (error) => {
          })
      })
    };
    $scope.openCloudDrive = ()=>{
      oedDialogService.openModalWithComponent('CloudDriveDialogComponent',
        {}, ()=> {},
        {class: 'cl-library-dialog2 modal-cloud-drive'})
    }
    $scope.onUseNew = () => {
      localStorageService.set('prepareLessonUseNewVersion');
      userInfo.then((info) => {
        preferenceService
          .updateUserNewPrepareLessonState({uid: Number(info.uid), value: '1'})
          .subscribe((next) => {
          }, (error) => {
          })
      })
    };
    $scope.setRecommendCourse = function(val) {
      $scope.loading = oedRecommendCourse.addRecommendCourse({id: $scope.hoverCp.id, value: val})
        .$promise.then(function() {
          $scope.hoverCp.isRc = (val > 0);
        }, function() {
          notificationService.notify('error', '添加失败，请刷新页面后重试')
        });
    };

    var msgs = [
      '删除课程模板成功',
      '导入课程模板成功',
      '导入教材通用模板成功',
      '导入学科通用模板成功'
    ];

    $scope.onSlideHover = function($event, cp) {
      $scope.hoverCp = cp;
      if (!$scope.showTemplate) {
        return;
      }

      if ($scope.hoverCp.isRc != undefined) {
        $scope.pageX = $event.pageX;
        $scope.pageY = $event.pageY;
        $scope.popup = 1;
      } else {
        $scope.loading = oedRecommendCourse.queryIsRecommendCourse({id: cp.id}).$promise.then(function(ret) {
          $scope.hoverCp.isRc = ret.isRc;
          $scope.pageX = $event.pageX;
          $scope.pageY = $event.pageY;
          $scope.popup = 1;
        });
      }
    };

    $scope.setLastLeft = function(left) {
      $scope.lastLeft = left;
    };

    $scope.onSlideLeave = function($event) {
      if (!$scope.showTemplate) {
        return;
      }

      var nameFieldWidth = 185;
      var linkFieldWidth = 40;
      if ($scope.lastLeft >= 10000 || $event.pageX >= $scope.lastLeft + nameFieldWidth ||
        $event.pageX < $scope.lastLeft + linkFieldWidth || $scope.pageY > $event.pageY) {
        $scope.popup = 0;
        $scope.lastLeft = 10000;
      }
    };

    $scope.onRatiosLeave = function() {
      $scope.popup = 0;
    };

    $scope.onRadioClick = function(type) {
      if ($scope.hoverCp.templateType === type) {
        return;
      }

      var msg = msgs[type];

      if (!msg) {
        msg = '不支持的模板类型';
      }

      if (type === 0) {
        $scope.loading = oedCoursePath.unMarkCouresAsTemplate({id: $scope.hoverCp.id}).$promise
          .then(function() {
            $scope.coursePaths.filter(function(x) {
              return x === $scope.hoverCp;
            }).forEach(function(x) {
              x.templateType = 0;
            });
            notificationService.notify('info', msg)
          }).catch(function() {
            notificationService.notify('error', '发生未知错误')
          });
      } else {
        $scope.loading = oedCoursePath.markCouresAsTemplate({id: $scope.hoverCp.id, type: type}).$promise
          .then(function(cp) {
            $scope.coursePaths.filter(function(x) {
              return x === $scope.hoverCp && x.id === cp.id;
            }).forEach(function(x) {
              x.templateType = cp.templateType;
            });
            notificationService.notify('info', msg)
          }).catch(function() {
            notificationService.notify('error', '发生未知错误')
          });
      }
    };

    function updateCurrentCoursePath(replace) {
      var curCpId = $state.params.cpId || localStorageService.get('lastCoursePathId');
      $scope.coursePaths.$promise.then(function(cp) {
        $scope.loadingCp = false;
        var curCp = null;
        if (!_.isEmpty($scope.coursePaths)) {
          curCp = curCpId ? _.find($scope.coursePaths, function(cp) {
            return cp.id == curCpId;
          }) : $scope.coursePaths[0];
          curCp = curCp || $scope.coursePaths[0];
        }
        $scope.setCurrentCP(curCp, replace);
      });
    }
    $scope.info = {};
    userInfo.then(function(info) {
      _.assign($scope.info, _.pick(info, ['name', 'uid', 'isSchoolAdmin']));
      function updateCoursePath() {
        $scope.loadingCp = true;
        $scope.coursePaths = oedCoursePath.queryFilter({
          userId: _.parseInt(info.uid),
          onGoing: true,
          unStarted: true, //false, // TODO: 处理归档
          ended: true //$scope.showCompleted // TODO: 处理归档
        });

        updateCurrentCoursePath(true);

        return $scope.coursePaths.$promise;
      }

      $scope.loading = $q(function(resolve, reject) {
        userInfo.then(function(info) {
          $scope.terms = oedSchoolTerm.queryBySchool({
            schoolId: info.schoolId
          });
          $scope.showTemplate = info.canStar;
          $scope.terms.$promise.then(function(terms) {
            //
            // find current term
            var curTime = new Date().getTime();
            $scope.currentTerm = _.find(terms, function(t) {
              return t.startTime <= curTime && curTime <= t.endTime;
            });

            return updateCoursePath().then(function() {
              if (!$scope.showTemplate) {
                return;
              }
              var isRecommendCoursePaths = _.map($scope.coursePaths, function(cp) {
                return oedRecommendCourse.queryIsRecommendCourse({id: cp.id}).$promise;
              });

              return $q.all(isRecommendCoursePaths).then(function(results) {
                for (let i = 0; i < results.length; i++) {
                  $scope.coursePaths[i].isRc = results[i].isRc;
                }
              });
            });

          }).finally(resolve);
        });
      });

      $scope.toggleShowComplete = function(show) {
        if ($scope.showCompleted == show) return;

        $scope.showCompleted = show;
        updateCoursePath();
      };
    });

    $scope.gotoList = function(cp, opt) {
      $scope.currentCpId = cp && cp.id;
      // if ($scope.newVersion) {
      //   localStorageService.set('lastCoursePathId', cp.id);
      //   $state.go('prepareLesson2.lessonList', {
      //     coursepathId: cp.id
      //   }, {
      //     location: 'replace'
      //   });
      // } else {
      //   $state.go('prepareLesson.courseList', {
      //     cpId: cp.id
      //   }, opt);
      // }
      if ($scope.isPreLesson) {
        $state.go('prepareLesson.newCourseList', {
          cpId: cp && cp.id,
        }, opt);
      } else {
        $state.go('prepareLesson.courseList', {
          cpId: cp && cp.id,
        }, opt);
      }
    }

    $scope.setCurrentCP = function(cp, replace) {
      if ($scope.isDone) {
        return;
      }

      var cpId = cp ? cp.id : null;

      var opt = replace ? {
        location: 'replace'
      } : null;

      $scope.labelIndex = _.findIndex($scope.coursePaths, function(cp) {
        return cp.id == cpId;
      });
      setSwiperSideBtnVisible($scope.labelIndex);
      ensureLabelVisibiltiy();
      $scope.gotoList(cp, opt);
    };

    $scope.addCoursePath = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/addcoursepath.html'),
        controller: 'addCoursePathCtrl',
        size: 'sm',
        resolve: {
          coursePath: function() {
            return $scope.currentCP;
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r && r.coursePath) {
          $scope.coursePaths.push(r.coursePath);
        }
      });
    };

    $scope.addCourse = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/addcourse.html'),
        controller: 'addCourseCtrl',
        size: 'sm',
        resolve: {
          coursePath: function() {
            return $scope.currentCP;
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r && r.course) {
          $scope.courses.push(r.course);
        }
      });
    };

    $scope.getSectionNames = function(secs) {
      return _.map(secs, function(sec) {
        return sec.sectionName;
      }).join(',');
    };

    //增加coursePaths的滚动
    var labelWidth = 197; // 每个标签的宽度 每次要移动的宽度为197px

    $scope.getLableListStyle = function() {
      var width2 = _.isEmpty($scope.coursePaths) ? '1200' : (_.size($scope.coursePaths) * labelWidth);
      if (width2 < 1200)
        width2 = 1200;
      return {
        width: '' + width2 + 'px'
      };
    };

    $scope.getLableStyle = function() {
      var labelOffset = '-' + ($scope.labelIndex * labelWidth) + 'px';
      return {
        left: labelOffset
      };
    };
    $scope.labelMaxCount = function() {
      var width2 = $('#gundong2').width();
      return Math.floor(width2 / labelWidth);
    };

    $scope.labelNext = function() {
      var labelCount = $scope.labelMaxCount();
      if ($scope.labelIndex + labelCount >= _.size($scope.coursePaths))
        return;
      $scope.labelIndex++;
    };

    function ensureLabelVisibiltiy() {
      if ($scope.labelIndex < 0) {
        $scope.labelIndex = 0;
      }

      var labelCount = $scope.labelMaxCount();
      if ($scope.labelIndex + labelCount >= _.size($scope.coursePaths)) {
        $scope.labelIndex = _.size($scope.coursePaths) - labelCount;
      }
    }

    $scope.labelPrev = function() {
      if ($scope.labelIndex <= 0) {
        return;
      } else {
        $scope.labelIndex--;
      }
    };
    $scope.currentPagerIndex = 0;
    $scope.displaySwiperSideLeft = false;
    $scope.displaySwiperSideRight = true;
    // $scope.noNeedDigest = false;
    $scope.swiperParams = {
      slidesPerView: 'auto',
      mousewheelControl: true,
      onTransitionStart: function() {
        $scope.popup = 0;
        $scope.currentPagerIndex = $scope.swiper1.swiper.activeIndex;
        setSwiperSideBtnVisible($scope.currentPagerIndex);
        // if (!$scope.noNeedDigest) $scope.$digest();
        // $scope.noNeedDigest = false;
      }
    };

    function setSwiperSideBtnVisible(pageIndex) {
      if (isNaN(pageIndex)) return;
      if (isNaN($scope.maxIndex)) {
        $scope.maxIndex = _.size($scope.coursePaths) - $scope.labelMaxCount();
      }
      if (pageIndex >= $scope.maxIndex) {
        $scope.displaySwiperSideRight = false;
      } else {
        $scope.displaySwiperSideRight = true;
      }
      if (pageIndex <= 0) {
        $scope.displaySwiperSideLeft = false;
      } else {
        $scope.displaySwiperSideLeft = true;
      }
    }
    $scope.scrollToTop = function() {
      // $scope.noNeedDigest = true;
      $scope.swiper1.swiper.slideTo(0);
    };
    $scope.scrollToBottom = function() {
      // $scope.noNeedDigest = true;
      if (isNaN($scope.maxIndex)) {
        $scope.maxIndex = _.size($scope.coursePaths) - $scope.labelMaxCount();
      }
      $scope.swiper1.swiper.slideTo($scope.maxIndex);
    };
    $scope.cantUpper = false;
    $scope.cantDowner = false;
    $scope.changingCourseOrder = 0;
    $scope.onDisplayOrderChange = function(index) {
      $scope.changingCourseOrder = index;
      if (index <= 0) {
        $scope.cantUpper = true;
      } else {
        $scope.cantUpper = false;
      }
      if ((index + 1) >= _.size($scope.coursePaths)) {
        $scope.cantDowner = true;
      } else {
        $scope.cantDowner = false;
      }
    };
    $scope.orderUpperOnce = function(cp, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if ($index <= 0) return;
      $scope.changingCourseOrder = $index;
      var newOrders = _.map($scope.coursePaths, function(c, index) {
        if (c.id == cp.id) {
          return _.assign(c, {sort: index * 2});
        }
        return _.assign(c, {sort: index * 2 + 3});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCoursePath.updateCoursePathOrder(items).$promise.then(() => {
        $scope.coursePaths = _.sortBy(newOrders, 'sort');
        // $scope.noNeedDigest = true;
        $scope.swiper1.swiper.slidePrev();
        $scope.changingCourseOrder --;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    };
    $scope.orderDownOnce = function(cp, $event, $index) {
      $event.stopPropagation();
      $event.preventDefault();
      if (($index + 1) >= _.size($scope.coursePaths)) return;
      $scope.changingCourseOrder = $index;
      var newOrders = _.map($scope.coursePaths, function(c, index) {
        if (c.id == cp.id) {
          return _.assign(c, {sort: index * 2 + 3});
        }
        return _.assign(c, {sort: index * 2});
      });
      var items = _.map(newOrders, function(c) {
        return _.pick(c, ['id', 'sort']);
      });
      $scope.loading = oedCoursePath.updateCoursePathOrder(items).$promise.then(() => {
        $scope.coursePaths = _.sortBy(newOrders, 'sort');
        // $scope.noNeedDigest = true;
        $scope.swiper1.swiper.slideNext();
        $scope.changingCourseOrder ++;
        $scope.onDisplayOrderChange($scope.changingCourseOrder);
      });
    };
    $scope.onChangeOrderToggle = function(open) {
      if (!open) {
        $scope.changingCourseOrder = -1;
      }
    };
  }

  addCoursePathCtrl.$inject = ['$scope', '$uibModalInstance', 'oedCoursePath']
  function addCoursePathCtrl($scope, $uibModalInstance, oedCoursePath) {
    $scope.coursePath = {};

    $scope.ok = function() {
      //
      // todo: remove hard coded values
      var cp = _.assign({
        gradeId: 697522,
        subjectId: 963025
      }, $scope.coursePath);

      var coursePath = new oedCoursePath(cp);
      coursePath.$save();
      $uibModalInstance.close({
        coursePath: coursePath
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }

  addCourseCtrl.$inject = ['$scope', '$uibModalInstance', 'oedSection', 'oedCourse', 'coursePath']
  function addCourseCtrl($scope, $uibModalInstance, oedSection, oedCourse, coursePath) {
    $scope.sections = oedSection.queryAll();
    $scope.course = {};

    $scope.ok = function() {
      var course = new oedCourse({
        courseName: $scope.course.courseName,
        coursepathId: coursePath.id,
        relatedSections: _.map($scope.course.sections, function(s) {
          return {
            sectionId: s.id,
          };
        }),
      });
      course.$save();
      $uibModalInstance.close({
        course: course
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }

  editCourseCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedSection', 'oedCourse', 'course']
  function editCourseCtrl($scope, $q, $uibModalInstance, oedSection, oedCourse, course) {
    $scope.sections = oedSection.queryAll();
    $scope.course = {
      courseName: course.courseName
    };

    var secIds = _.reduce(course.relatedSections, function(r, s) {
      r[s.sectionId] = true;
      return r;
    }, {});

    $scope.sections.$promise.then(function(secs) {
      $scope.course.sections = _.filter($scope.sections, function(s) {
        return secIds[s.id];
      });
    });

    $scope.ok = function() {
      var newCourse = $scope.course;
      course.courseName = newCourse.courseName;
      course.relatedSections = _.map($scope.course.sections, function(s) {
        return {
          sectionId: s.id,
        };
      });
      course.$save();
      $uibModalInstance.close({
        course: course
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
