import { Component, OnInit, Input, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-preview-questions-list',
  template: require('./preview-questions-list.component.html'),
  styles: [require('./preview-questions-list.component.scss')]
})
export class PreviewQuestionsListComponent implements OnInit {
  @Input() inLibary: boolean;
  @Input() quesSizeInLibary: number;
  @Input() exerciseBookId: any;
  @Input() questionsList: any;
  @Input() resboxHolder: any;
  @Output() onDeleteQuestion = new EventEmitter();
  @Output() onAddToResBox = new EventEmitter();
  @Output() pageChangedInLibary = new EventEmitter();
  @ViewChild('top') top: ElementRef;
  currentQuestionsList: any
  // 总数
  totalItems: number;
  // 每页节点数
  countPerPage: any = 10;
  // 最大页数
  maxSize: any = 10;
  // 当前页码
  currentPage: any = 1;
  exDifficult: ['容易', '较易','中等', '较难', '困难'];

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'questionsList')) {
      if (this.inLibary) {
        this.totalItems = this.quesSizeInLibary
        this.getCurrentQuestionsListInLibary()
      } else {
        this.totalItems = _.size(this.questionsList);
        this.getCurrentQuestionsList();
      }
    }
  }

  public pageChanged(event){
    if (this.inLibary) {
      this.pageChangedInLibary.emit(event)
      return
    }
    this.currentPage = event.page;
    this.getCurrentQuestionsList();
    this.top.nativeElement.scrollIntoView({
      behavior: 'smooth', block: 'start', inline: 'start' 
    });
  }

  public getCurrentQuestionsList() {
    this.currentQuestionsList = _.slice(this.questionsList, (this.currentPage - 1)*this.countPerPage, this.countPerPage*this.currentPage);
  }

  private getCurrentQuestionsListInLibary = () => this.currentQuestionsList = this.questionsList

  getCurrentIndex(index) {
    return index + (this.currentPage - 1) * this.countPerPage;
  }

  getDifficult(item) {
    if(_.isEmpty(item.tags)) {
      return;
    }
    const index = _.findIndex(item.tags, data => _.get(data, 'tagName') === 'difficulty');
    const difficult = _.get(item.tags, `${index}.numVal`);
    if(difficult && difficult !== -1) {
      return this.exDifficult[difficult];
    }
  }
  getSnyPonit(item) {
    if(_.isEmpty(item.question.childQuestions)) {
      return
    }
    let point = 0;
    _.forEach(item.question.childQuestions, item => {
      point += item.point2
    })

    return point;
  }

  exKnowledgePoint(item) {
    const data = _.map(item, i => i.name);
    return data.join('，');
  }

  private addCloudQuestion = (q: any, $event: any) => this.onAddToResBox.emit({ $event, q })
  private doDeleteQuestion = (q) => this.onDeleteQuestion.emit(q)
  private isQuestionAddedToResBox = (q: any) => {
    if (!this.resboxHolder || !q) {
      return false
    }
    return this.resboxHolder.isQuestionAdded(q)
  }
}
