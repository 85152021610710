import * as _ from 'lodash'
require('assets/app/controllers/examination/examReport/examReportTab/examReportTab.scss')

angular.module('app.examination.examReport.tab', [])
  .component('examReportTab', {
    bindings: {
      selectedTab: '<',
      onToggleTab: '&',
    },
    controller: examReportTabCtrl,
    template: < string > require('./examReportTab.html'),
  })

examReportTabCtrl.$inject = []
function examReportTabCtrl() {
  const ctrl = this
}
