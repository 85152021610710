import * as _ from 'lodash'
require('assets/app/controllers/examination/examEdit/statistics/examEditStat.scss');
import { getExamQuesNm, getExamTestTotal, getExamQuesTotal, percent } from '../paper/paperUtils';
// 引入 echarts 主模块。
import * as echarts from 'echarts/lib/echarts';
// 引入折线图。
import 'echarts/lib/chart/line';
// 引入提示框组件、标题组件、工具箱组件。
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/toolbox';
import 'echarts/theme/macarons';

angular.module('app.examination.examEdit.statistics', [
])
  .component('examEditStat', {
    bindings: {
      examData: '<',
      saveExamTest: '&',
      cloneExamTest: '&',
      currentTag: '<',
      currentNm: '<',
      isCanEdit: '<',
      updateExamData: '&',
    },
    controller: examEditStatCtrl,
    template: <string>require('./examEditStat.html'),
  })

examEditStatCtrl.$inject = ['oedDialogService']
function examEditStatCtrl(oedDialogService: any) {
  const ctrl = this;
  const myChart = echarts.init(document.getElementById('main'));
  ctrl.colorList = [
    '#B0D89F', '#79C5EB', '#F6C14A', '#B7C958', '#EF908D',
    '#AA7A28', '#E476BC', '#E0BFEF', '#807CE3', '#9EBFFA'
  ]

  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'examData')) {
      ctrl.loadExamStat()
    }
  }

  ctrl.loadExamStat = () => {
    ctrl.showStat = !_.isEmpty(ctrl.examData)
    ctrl.examPartDetails = _.get(ctrl.examData, 'examPartDetails', {})
    ctrl.testName = _.get(ctrl.examData, 'examWithTags.exam.name', '')
    ctrl.data = []
    ctrl.quesNm = getExamQuesNm(ctrl.examPartDetails)
    ctrl.testTotal = getExamTestTotal(ctrl.examPartDetails)
    _.forEach(ctrl.examPartDetails, (e, i) => {
      const obj = {
        quesSize: _.size(_.get(e, 'questionDetails', [])),
        value: getExamQuesTotal(ctrl.examPartDetails, i),
        percent: percent(getExamQuesTotal(ctrl.examPartDetails, i), ctrl.testTotal, 1) + '%',
        text: _.get(e, 'part.name', ''),
        total: getExamQuesTotal(ctrl.examPartDetails, i),
        itemStyle: {
          normal: {
            color: ctrl.colorList[i]
          }
        }
      }
      ctrl.data.push(obj)
    });
    ctrl.loadPie()
  }

  ctrl.loadPie = () => {
    ctrl.option = {
      tooltip: {
        trigger: 'item',
        formatter: (params) => {
          const quesSize = _.get(params, 'data.quesSize', 0)
          const percent = _.get(params, 'data.percent', 0)
          return `题目数 : ${quesSize} (${percent})`
        },
        position: ['25%', '-10']
      },
      series: [
        {
          name: ctrl.testName,
          type: 'pie',
          radius: '60%',
          center: ['50%', '50%'],
          data: ctrl.data,
          animation: false,
          label: {
            position: 'outer',
            alignTo: 'none',
            bleedMargin: 5,
            normal: {
              color: '#000000',
            }
          },
          labelLine: {
            normal: {
              show: false,
              length: 3,
              length2: 8,
            },
          },
          left: 0,
          right: '66%',
          top: 0,
          bottom: 0
        }
      ]
    };
    myChart.setOption(ctrl.option);
  }

  ctrl.setExamScore = () => {
    const modal = oedDialogService.openModalWithComponent3('AdjustScoreComponentComponent', {
      examData: ctrl.examData
    },
    {class: 'cl-library-dialog2 modal-cloud-drive'})

    function update() {
      ctrl.examData = {
        ...ctrl.examData,
      }
      ctrl.updateExamData({ examData: ctrl.examData })
    }

    modal.action.subscribe(update)
    modal.modelChanged.subscribe(update)
  }

}
