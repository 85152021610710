import {Component, Input, OnInit} from '@angular/core';
import * as _ from 'lodash';
import {OnlineTrainingDirectoryDTO} from 'app/online-train/models/online-train';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import {flatMap} from 'rxjs/operators';
import { StateService } from '@uirouter/core'
import {TrainMassageService} from 'app/online-train/train-massage.service';

@Component({
  selector: 'app-train-dir-style5',
  template: require('./train-dir-style5.component.html'),
  styles: [require('./train-dir-style5.component.scss')]
})
export class TrainDirStyle5Component implements OnInit {

  @Input() public dirs: OnlineTrainingDirectoryDTO[]
  @Input() public parentId
  @Input() public editable
  @Input() public prefix

  private allDirs: OnlineTrainingDirectoryDTO[]
  private dirTree
  private dataToAdd: OnlineTrainingDirectoryDTO
  private itemType
  constructor(private onlineTrainService: OnlineTrainService,
              private state: StateService,
              private trainMessage: TrainMassageService) { }

  ngOnInit() {
    this.loadData()
  }

  public onEdit(params) {
    if (params.editType === this.onlineTrainService.ADD) {
      //
      this.onlineTrainService.addDir(params.item).pipe(
        (flatMap(() => this.onlineTrainService.getAllDirs()))
      ).subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
        this.initData(dirs)
      }, (err) => {console.log(err)})
    } else if (params.editType === this.onlineTrainService.UPDATE) {
      //
      this.onlineTrainService.updateDir(params.item).pipe(
        (flatMap(() => this.onlineTrainService.getAllDirs()))
      ).subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
        this.initData(dirs)
      }, (err) => {console.log(err)})
    } else if (params.editType === this.onlineTrainService.DELETE) {
      //
      this.onlineTrainService.deleteDir(params.item.id).pipe(
        (flatMap(() => this.onlineTrainService.getAllDirs()))
      ).subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
        this.initData(dirs)
      }, (err) => {console.log(err)})
    }
  }

  public gotoContent(dir) {
    this.state.go('onlineTrain.content', {
      parentId: dir.id,
      editable: this.editable,
      dirs: this.allDirs,
      prefix: this.prefix});
  }

  private initData(dirs: OnlineTrainingDirectoryDTO[]) {
    this.allDirs = _.filter(dirs, (d: OnlineTrainingDirectoryDTO) => d.id > 1)
    this.dirTree = this.onlineTrainService.buildDirTree(this.allDirs)
    this.dirs = this.dirTree[this.parentId]
    this.dataToAdd = {
      dirId: this.parentId,
      name: '',
      sort: 0,
      deleted: false
    }

    this.itemType = this.onlineTrainService.DIR
  }

  private loadData() {
    this.trainMessage.sendLoadingAction(true)
    this.onlineTrainService.getAllDirs().subscribe((dirs: OnlineTrainingDirectoryDTO[]) => {
      this.initData(dirs)
    }, (err) => {console.log(err)}, () => this.trainMessage.sendLoadingAction(false))
  }

}
