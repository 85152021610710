import * as _ from 'lodash'
require('assets/app/controllers/examination/examNm/examNm.scss')
import { getExamQuesTotal, convertQuesNumber } from '../examEdit/paper/paperUtils';

angular.module('app.examination.examEdit.examNm', [])
  .component('examNm', {
    bindings: {
      examPartDetails: '<',
      currentTag: '<',
      currentNm: '<'
    },
    controller: examNmCtrl,
    template: <string>require('./examNm.html'),
  })

examNmCtrl.$inject = ['$location', '$anchorScroll']
function examNmCtrl(
  $location,
  $anchorScroll,
) {
  const ctrl = this;
  ctrl.$onInit = () => {
  };

  ctrl.getQuesNumber = (outerIndex, index) => {
    return convertQuesNumber(ctrl.examPartDetails, outerIndex, index)
  }

  this.partIndex = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十']

  ctrl.getPartTitle = (index: number) => {
    const partIndex = this.partIndex[index]
    const partTypeText = _.get(ctrl.examPartDetails[index], 'part.name', '')
    return `${partIndex}、${partTypeText}\xa0（共${_.size(_.get(ctrl.examPartDetails[index], 'questionDetails', []))}小题，总分${getExamQuesTotal(ctrl.examPartDetails, index)}分）`
  }

  ctrl.scrollItemQues = (outerIndex, index) =>  {
    ctrl.outerIdx = outerIndex;
    ctrl.idx = index;
    // $location.hash(`anchor_${outerIndex}_${index}`);
    // $anchorScroll();

    const doc = document.getElementById(`anchor_${outerIndex}_${index}`)
    if (doc) {
      setTimeout(() => {
        doc.scrollIntoView({
          block: 'start',
          behavior: 'smooth',
          inline: 'start'
        })
      }, 15)
    }
  }

  ctrl.getTagHeightLight = (index) => {
    return _.includes(ctrl.currentTag, index)
  }

  ctrl.getNmHeightLight = (index) => {
    return _.includes(ctrl.currentNm, index)
  }
}