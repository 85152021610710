import * as _ from 'lodash'
require('assets/app/controllers/examination/examEdit/header/examEditHeader.scss');

angular.module('app.examination.examCreat.header', [])
  .component('examCreateHeader', {
    bindings: {
      page: '=ngModel',
      isShowTab: '<',
      examName: '<',
      onBackToPrePage: '&',
      toggleCurrentPage: '&',
    },
    controller: examCreateHeaderCtrl,
    template: < string > require('./examCreateHeader.html'),
  })


examCreateHeaderCtrl.$inject = []
function examCreateHeaderCtrl() {
  const ctrl = this
}
