import angular = require('angular');

angular.module('app.directives.course.simpleDropdown', [
])
  .component('oedSimpleDropdown', {
    bindings: {
      items: '<',
      selectedItem: '<',
      onItemSelected: '&'
    },
    controller: simpleDropdownCtrl,
    template: <any> require('./simpleDropdown.html'),
  })

simpleDropdownCtrl.$inject = ['$uibModal']
function simpleDropdownCtrl($uibModal: any) {
    this.selectItem = (item: any) => {
        if (this.selectedItem === item) {
            return
        }
        this.selectedItem = item
        this.onItemSelected({
            item,
        })
    }
}
