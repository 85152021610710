(function() {
  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('student', {
        url: '/student',
        template: require('assets/templates/student/student.html'),
        controller: 'studentCtrl'
      });

    }]).controller('studentCtrl', studentCtrl);

  studentCtrl.$inject = ['$scope', 'userInfo']
  function studentCtrl($scope, userInfo) {
    $scope.userInfo = userInfo.get();
    $scope.userInfoService = userInfo;
    $scope.currentState = {};
  }
})();
