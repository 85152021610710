import * as angular from 'angular';
require('./uploadFailedDialog.scss');
angular.module('app.newPrepareCourse2.uploadFailedDialog',
[]).component('uploadFailedDialog', {
  bindings: {
    modalInstance: '<',
    resolve: '<',
  },
  controller: uploadFailedDialogCtrl,
  template: <string> require('./uploadFailedDialog.html'),
})
function uploadFailedDialogCtrl() {
  this.cancel = () => {
    this.modalInstance.close('');
  }
}
