/**
 * @fileOverview
 * @name testUtils.js
 * @author pangwa
 * @license
 */
import { computeQuestionHash } from 'app2/utils/testUtils2'

(function() {
  var jsSHA = require('jssha');
  var _ = require('lodash');
  var $ = require('jquery')

  angular.module('app.utils.testUtils', [])
    .factory('oedTestUtils', oedTestUtils);

  let cachedResults = {
  }

  let cleanupTimer = null

oedTestUtils.$inject = ['$http', '$log', '$state', '$q', 'localStorageService',
    'oedConfig', 'oedHwlTegong',
    'oedCloudTest', 'oedCloudSubjectiveTest', 'oedObjectiveTest', 'oedSubjectiveTest', 'oedObjectiveTestStats',
    'oedLevelGradeVersionSubjects', 'oedUnitItem', 'oedCourse', 'oedUserInfo', 'oedCourseUtils',
    'oedMaterialVersion', 'oedSubjects', 'oedGrade', 'oedLevel']
  function oedTestUtils($http, $log, $state, $q, localStorageService,
    oedConfig, oedHwlTegong,
    oedCloudTest, oedCloudSubjectiveTest, oedObjectiveTest, oedSubjectiveTest, oedObjectiveTestStats,
    oedLevelGradeVersionSubjects, oedUnitItem, oedCourse, oedUserInfo, oedCourseUtils,
    oedMaterialVersion, oedSubjects, oedGrade, oedLevel) {
    var optLabels = 'abcdefghijklmnopqrstuvwxyz'.toUpperCase();
    var questionTypes = {
      'yesorno': '判断',
      'singlechoice': '单选',
      'multichoice': '多选',
      'connect': '匹配',
      'fill': '填空',
      'singlevote': '投票',
      'multivote': '投票',
      'vote': '投票',
      'synthetic': '综合',
      'qa': '问答'
    };

    return {
      getChoiceName: getChoiceName,
      getAnswerText: getAnswerText,
      convertFillAnswerStringToArray: convertFillAnswerStringToArray,
      convertFillAnswerStringToPlainArray: convertFillAnswerStringToPlainArray,
      convertFillAnswerTypesToArray: convertFillAnswerTypesToArray,
      convertFillAnswerPointsToArray: convertFillAnswerPointsToArray,
      parseFillAnswerCorrectDetails: parseFillAnswerCorrectDetails,
      questionTypes: questionTypes,
      isEmptyContent: isEmptyContent,
      optLabels: optLabels,
      validateQuestion: validateQuestion,
      computeQuestionHash: computeQuestionHash,
      switchEditMode: switchEditMode,
      loadCloudTestDetails: loadCloudTestDetails,
      loadCloudTestDetailsForSearch: loadCloudTestDetailsForSearch,
      loadCloudTestsByCourseId: loadCloudTestsByCourseId,
      loadCloudSubjTestByCourseId: loadCloudSubjTestByCourseId,
      queryCloudTests: queryCloudTests,
      queryCloudSubjTest: queryCloudSubjTest,
      loadCloudSubjTestDetails: loadCloudSubjTestDetails,
      loadCloudSubjTestDetailsForSearch: loadCloudSubjTestDetailsForSearch,
      getTestStatLabel: getTestStatLabel,
      parseJson: parseJson,
      quickTestQuestion: quickTestQuestion
    };

    function getChoiceName(idx) {
      return optLabels[idx];
    }

    function getAnswerText(question, answer) {
      var t = _.get(question, 'type');
      var answers;
      var parts;
      if (_.isNull(answer) || _.isUndefined(answer)) {
        return '空';
      }
      if (t == 'singlechoice' || t == 'singlevote') {
        if (!answer) return '';
        return getChoiceName(answer);
      } else if (t == 'multichoice' || t == 'multivote') {
        parts = answer.split(',');
        return _.map(parts, function(part) {
          return getChoiceName(part);
        }).join(',');
      } else if (t == 'yesorno') {
        const answerValue = _.parseInt(answer);
        return answerValue ? '正确' : '错误';
      } else if (t == 'connect') {
        parts = answer.split(',');
        return _.map(parts, function(part) {
          if (!part) return '';

          var connectPart = part.split(':');
          var left = getChoiceName(connectPart[0]);
          var right = _.parseInt(connectPart[1], 10) + 1;
          if (_.isNaN(right)) {
            return left;
          }
          return left + ':' + right;
        }).join(',');
      } else if (t === 'fill') {
        return _(answer.split(',')).filter().map(function(v) {
          var idx = _.parseInt(v) + 1;
          return '空' + idx;
        }).join(',');
      } else if (t === 'qa') {
        return '空'
      }

      return answer;
    }

    function parseJson(str, defaultVal) {
      try {
        return angular.fromJson(str);
      } catch (ex) {
        $log.error(str + ' is not a valid json string');
        return defaultVal;
      }
    }

    function convertFillAnswerTypesToArray(answerJson, count) {
      var ret = [];
      if (!_.isEmpty(answerJson)) {
        var answersMap = parseJson(answerJson, {});
        ret = _.get(answersMap, 'answerTypes', []);
      }

      // 数目不够，即填充默认值 exact-match
      if (_.size(ret) < count) {
        ret = _.concat(ret, _.fill(Array(count - _.size(ret)), 'exact-match'));
      }
      // 超出空的数目则截取
      else if (_.size(ret) > count) {
        ret = _.slice(ret, 0, count);
      }

      return ret;
    }

    function convertFillAnswerPointsToArray(answerJson, count) {
      var ret = [];
      if (!_.isEmpty(answerJson)) {
        var answersMap = parseJson(answerJson, {});
        ret = _.get(answersMap, 'answerPoints2', []);
      }

      // 数目不够，即填充默认值 1
      if (_.size(ret) < count) {
        ret = _.concat(ret, _.fill(Array(count - _.size(ret)), 1));
      }
      // 超出空的数目则截取
      else if (_.size(ret) > count) {
        ret = _.slice(ret, 0, count);
      }

      return ret;
    }

    function convertFillAnswerStringToArray(quesAnswer) {
      var ret = [];
      //
      // 把问题答案解析成数组
      if (!_.isEmpty(quesAnswer)) {
        var answers = parseJson(quesAnswer, {});
        ret = _.map(answers.answer, function(opts) {
          return _.map(opts, function(opt) {
            return {
              text: opt
            };
          });
        });
      }

      return ret || [];
    }

    function convertFillAnswerStringToPlainArray(quesAnswer) {
      var ret = [];
      //
      // 把问题答案解析成数组
      if (!_.isEmpty(quesAnswer)) {
        var answers = parseJson(quesAnswer, {});
        ret = _.map(answers.answer, function(opts) {
          return _.map(opts, function(opt) {
            return opt;
          });
        });
      }

      return ret || [];
    }

    function parseFillAnswerCorrectDetails(correctDetailsStr, ques) {
      var details = {
        blankTotalCount: 0,
        blankNeedReviewCount: 0,
        blankPendingReviewCount: 0,
        blankRightCount: 0,
        blankWrongCount: 0
      };

      var correctDetails = _.split(correctDetailsStr || '', ',');
      if (_.isEmpty(correctDetails)) {
        return details;
      }

      var content = _.get(ques, 'question', '');
      var count = (content.match(/__+/g) || []).length;
      var answerTypes = convertFillAnswerTypesToArray(_.get(ques, 'answer'), count);

      details.blankTotalCount = count;
      details.blankNeedReviewCount = _.size(_.filter(answerTypes, function(t) {
        return t == 'manual-score';
      }));
      _.each(correctDetails, function(c) {
        var i = _.parseInt(c);
        if (i < 0) {
          details.blankPendingReviewCount++;
        } else if (i === 0) {
          details.blankWrongCount++;
        } else {
          details.blankRightCount++;
        }
      });

      return details;
    }

    function isEmptyContent(str) {
      if (_.isEmpty(str)) {
        return true
      }
      if (_.has(cachedResults, str)) {
        return cachedResults[str]
      }
      const ret = isEmptyContentImpl(str)
      cachedResults[str] = ret
      if (cleanupTimer == null) {
        cleanupTimer = setInterval(() => {
          cachedResults = {}
        }, 10 * 1000)
      }
      return ret
    }

    function isEmptyContentImpl(str) {
      if (_.isEmpty(str)) {
        return true;
      }

      var e = $('<p>' + str + '</p>');
      //
      // 文字内容不为空
      if (!_.isEmpty(e.text())) {
        return false;
      }
      return $('img,video,embed,audio', e).length === 0;
    }

    function isAllBlankHasAnswerSet(ques) {
      var content = _.get(ques, 'question', '');
      var count = (content.match(/__+/g) || []).length;
      //
      // 对于没有设置空的填空题也认为没有答案
      if (count === 0) {
        return false;
      }

      var answerArray = convertFillAnswerStringToArray(_.get(ques, 'answer'));
      if (_.size(answerArray) != count) {
        return false;
      }

      var answerTypes = convertFillAnswerTypesToArray(_.get(ques, 'answer'), _.size(answerArray));
      return _.findIndex(_.zip(answerTypes, answerArray), function(p) {
        return p[0] != 'manual-score' && _.isEmpty(p[1]);
      }) < 0;
    }

    function validateQuestion(question, checkRelatedSections) {
      if (_.isUndefined(checkRelatedSections)) {
        if (_.isEmpty(question.relatedSections) && (_.isUndefined(question.isSub) || !question.isSub)) {
          return {
            valid: false,
            message: '请为当前问题关联章节'
          };
        }
      }

      if (_.includes(['singlevote', 'multivote', 'qa'], question.type)) {
        // set fake answer for vote type, as most of the login in fleaf will ignore test without answer
        // It's easier to add this fake answer instead of changing fleaf logic.
        question.answer = '-1';
      }

      if (_.isEmpty(question.answer) && question.type != 'synthetic') {
        return {
          valid: false,
          message: '请为当前题目设置答案！'
        };
      }

      //
      // 对于填空题应当检查是否所有的空都设置了答案
      if (question.type === 'fill' && !isAllBlankHasAnswerSet(question)) {
        return {
          valid: false,
          message: '填空题需要设置所有非老师评判空的答案'
        };
      }
      return {
        valid: true
      };
    }

    function switchEditMode(testId, mode) {
      if (mode === 'question') {
        $state.go('prepareCourse.objectiveTest.designTest', {
          testId: testId
        });
      } else {
        $state.go('prepareCourse.objectiveTest.designTest2', {
          testId: testId
        });
      }
      localStorageService.set('lastTestEditMode', mode);
      return true;
    }

    function loadCloudTestDetails(ct) {
      if (!_.get(ct, 'testId')) {
        return null;
      }

      var test = oedObjectiveTest.get({
        id: ct.testId
      });
      var loadStats = oedObjectiveTestStats.queryByTestId({
        id: ct.testId
      }).$promise.then(function(stats) {
        ct.questionStats = _.reduce(stats, function(total, s) {
          total[s.name] = s.count;
          return total;
        }, {});
      });
      ct.test = test;
      var loadTestDetails = test.$promise.then(function(t) {
        var loadMaterial = oedCourseUtils.loadCourseMaterialVersion(t.courseId).catch(function(err) {
          return {};
        });
        var items = oedUnitItem.queryRelatedSectionsInTest({
          id: t.id
        });

        var loadCS = oedCourse.get({
          id: t.courseId
        }).$promise.then(function(cs) {
          ct.courseName = cs.courseName;
          return oedUserInfo.getByUid({
            id: cs.teacherId
          }).$promise.then(function(u) {
            ct.userName = u.name;
            return ct;
          });
        }).catch(function(err) {
          return {};
        });

        return $q.all([loadMaterial, loadCS, items.$promise]).then(function(info) {
          var material = info[0];
          ct.materialVersion = material;
          ct.relatedSections = _.map(items, function(item) {
            return {
              sectionId: item.id,
              name: item.name,
            };
          });
          ct.name = test.name;
          return ct;
        });
      });

      return $q.all([loadTestDetails, loadStats]).then(function() {
        return ct;
      });
    }

    function loadCloudTestDetailsForSearch(ct) {
      if (!_.get(ct.test, 'id')) {
        return null;
      }
      const testId = ct.test.id;
      ct.test = oedObjectiveTest.get({
        id: testId
      });
      return oedObjectiveTestStats.queryByTestId({
        id: testId
      }).$promise.then(function(stats) {
        ct.questionStats = _.reduce(stats, function(total, s) {
          total[s.name] = s.count;
          return total;
        }, {});
        return ct;
      });
    }

    function loadCloudTestsByCourseId(csId) {
      var cs = oedCourse.get({
        id: csId
      });

      var loadUser = cs.$promise.then(function(cs) {
        return oedUserInfo.getByUid({
          id: cs.teacherId
        }).$promise;
      });

      var courseMaterials = oedCourseUtils.loadCourseMaterialVersion(csId);
      var loadTests = oedObjectiveTest.queryByCourse({
        course_id: csId
      }).$promise.then(function(ts) {
        var testsById = _.keyBy(ts, 'id');
        var testIds = _.keys(testsById);
        var testsWithDetails = _.mapValues(testsById, function(t) {
          return oedObjectiveTest.get({
            id: t.id
          });
        });

        var loadTests = $q.all(_.map(testsWithDetails, '$promise'));

        var testItems = {};
        var testStats = {};
        var loadItems = $q.all(_.map(ts, function(t) {
          var loadUi = oedUnitItem.queryRelatedSectionsInTest({
            id: t.id
          }).$promise.then(function(items) {
            testItems[t.id] = items;
            return items;
          });

          var loadStats = oedObjectiveTestStats.queryByTestId({
            id: t.id
          }).$promise.then(function(stats) {
            testStats[t.id] = _.reduce(stats, function(total, s) {
              total[s.name] = s.count;
              return total;
            }, {});
          });

          return $q.all([loadUi, loadStats]);
        }));

        var loadCts = oedCloudTest.queryByTestIds({
          testIds: testIds
        }).$promise;

        return $q.all([loadCts, loadItems, loadTests]).then(function(data) {
          var cts = data[0];
          cts = _.map(cts, function(ct) {
            ct.relatedSections = _.map(testItems[ct.testId], function(item) {
              return {
                sectionId: item.id
              };
            });
            ct.questionStats = testStats[ct.testId];
            ct.test = testsWithDetails[ct.testId];
            ct.name = _.get(ct, 'test.name', '');
            return ct;
          });

          return cts;
        });
      });

      return $q.all([loadTests, loadUser, courseMaterials]).then(function(data) {
        var cts = data[0];
        var u = data[1];
        var m = data[2];
        return _.map(cts, function(ct) {
          ct.userName = u.name;
          ct.materialVersion = m;
          ct.courseName = cs.courseName;
          return ct;
        });
      });
    }

    function loadCloudSubjTestByCourseId(csId) {
      var cs = oedCourse.get({
        id: csId
      });

      var loadUser = cs.$promise.then(function(cs) {
        return oedUserInfo.getByUid({
          id: cs.teacherId
        }).$promise;
      });

      var courseMaterials = oedCourseUtils.loadCourseMaterialVersion(csId);
      var loadTests = oedSubjectiveTest.queryByCourse({
        course_id: csId
      }).$promise.then(function(ts) {
        var testsById = _.keyBy(ts, 'id');
        var testIds = _.keys(testsById);

        var loadTests = $q.all(_.map(ts, function(t) {
          return oedSubjectiveTest.get({id: t.id}).$promise;
        }));

        var loadCts = oedCloudTest.queryByTestIds({
          testIds: testIds
        }).$promise;

        var loadDetails = $q.all([loadTests, loadCts]).then(function(data) {
          var tests = data[0];
          var cts = data[1];

          var testDetailsById = _.keyBy(tests, 'id');
          return _.map(cts, function(ct) {
            ct.test = testDetailsById[ct.testId];
            ct.relatedSections = _.get(ct.test, 'question.relatedSections');
            ct.name = _.get(ct, 'test.name', '');
            ct.sections = _.map(_.get(ct.test, 'question.relatedSections'), function(sec) {
              return {
                sectionId: sec.sectionId,
                name: sec.sectionName
              };
            });
            return ct;
          });
        });

        return loadDetails;
      });

      return $q.all([loadUser, loadTests, courseMaterials]).then(function(data) {
        var u = data[0];
        var cts = data[1];
        var m = data[2];
        return _.map(cts, function(ct) {
          ct.courseName = cs.courseName;
          ct.userName = u.name;
          ct.materialVersion = m;
          return ct;
        });
      });
    }

    function queryCloudTests(queryOpt, vendor={}, useExercise=false) {
      var queryCountURL = (!_.isEmpty(vendor) && vendor.name === 'tegong')
        ? oedConfig.url_b('hwl/cloudtest/count2') : oedConfig.url_b('cloudtest/count2');
      var countReq = $http({
        url: queryCountURL,
        method: 'GET',
        params: queryOpt
      });
      if (useExercise) {
        queryCountURL = oedConfig.url_b('exbk/cloudtest/count');
        countReq = $http({
          url: queryCountURL,
          method: 'POST',
          data: queryOpt
        });
      }
      var items = (!_.isEmpty(vendor) && vendor.name === 'tegong')
        ? oedHwlTegong.queryTestBySection(queryOpt) : useExercise ? oedCloudTest.queryExerciseTest(queryOpt) : 
        oedCloudTest.queryBySection(queryOpt);
      var itemsWithQuestion = items.$promise.then(function(its) {
        var loadDetails = _.map(its, loadCloudTestDetailsForSearch);
        return $q.all(loadDetails);
      });

      items.$promise.then((r) => console.warn(r))
      return [items.$promise, countReq, itemsWithQuestion];
    }

    function queryCloudSubjTest(queryOpt, vendor) {
      var queryCountURL = (!_.isEmpty(vendor) && vendor === 'tegong')
        ? oedConfig.url_b('hwl/cloudsubjectivetest/count2') : oedConfig.url_b('cloudsubjectivetest/count2');

      var countReq = $http({
        url: queryCountURL,
        method: 'GET',
        params: queryOpt
      });
      var loadItems = (!_.isEmpty(vendor) && vendor === 'tegong')
        ? oedHwlTegong.querySubjTestBySection(queryOpt) : oedCloudSubjectiveTest.queryBySection(queryOpt)
      const loadItemsWithDetail = loadItems.$promise.then(function(items) {
        var loadTests = _.map(items, loadCloudSubjTestDetailsForSearch);
        return $q.all(loadTests);
      });

      return [loadItemsWithDetail, countReq];
    }

    function loadCloudSubjTestDetails(ct) {
      if (!_.get(ct, 'testId')) {
        return null;
      }

      var loadTest = oedSubjectiveTest.get({
        id: ct.testId
      }).$promise.then(function(test) {
        ct.test = test;
        ct.relatedSections = _.get(test, 'question.relatedSections');
        ct.name = test.name;
        ct.sections = _.map(_.get(test, 'question.relatedSections'), function(sec) {
          return {
            sectionId: sec.sectionId,
            name: sec.sectionName
          };
        });

        //
        // 正常的题目会有course
        if (test.courseId) {
          return oedCourse.get({
            id: test.courseId
          }).$promise.then(function(cs) {
            ct.courseName = cs.courseName;
            return oedUserInfo.getByUid({
              id: cs.teacherId
            }).$promise.then(function(u) {
              ct.userName = u.name;
              return ct;
            });
          });
        } else {
          var uid = _.get(test, 'question.uid');
          if (uid) {
            return oedUserInfo.getByUid({
              id: uid
            }).$promise.then(function(u) {
              ct.userName = u.name;
              return ct;
            });
          } else {
            return ct;
          }
        }
      });

      var loadMaterial = loadSubjTestMaterialVersion(ct.testId).catch((err) => {
        return {}
      });

      return $q.all([loadTest, loadMaterial]).then(function(info) {
        var material = info[1];
        ct.materialVersion = material;
        return ct;
      });
    }

    function loadCloudSubjTestDetailsForSearch(ct) {
      if (!_.get(ct.test, 'id')) {
        return null;
      }
      const testId = ct.test.id;
      ct.test = oedSubjectiveTest.get({
        id: testId
      });
      return ct;
    }

    function loadSubjTestMaterialVersion(testId) {
      return oedLevelGradeVersionSubjects.queryBySubjectiveTestId({
        id: testId
      }).$promise.then(loadMaterialsInfo);
    }

    function loadMaterialsInfo(info) {
      var version = oedMaterialVersion.get({
        id: info.versionId
      });

      var subject = oedSubjects.get({
        id: info.subjectId
      });

      var grade = oedGrade.get({
        id: info.gradeId
      });

      var level = oedLevel.get({
        id: info.levelId
      });

      return $q.all([version.$promise, subject.$promise, grade.$promise, level.$promise]).then(function() {
        return {
          versionName: version.versionName,
          name: grade.gradeName + subject.subjectName
        };
      }).catch((err) => {
        return {
          versionName: "",
          name: ""
        }
      });
    }
  }

  function getTestStatLabel(stats) {
    var baseIndex = 0;
    var childIndex = 0;
    var previousGroupId = -1;
    var results = new Array(stats.length);
    for (var i = 0; i < stats.length; i++) {
      const currentGroupId = stats[i].question.parentQuestionId || 0;
      if (currentGroupId > 0) {
        if (currentGroupId != previousGroupId) {
          baseIndex++;
          childIndex = 1;
        } else {
          childIndex++;
        }
        previousGroupId = currentGroupId;
        results[i] = stats[i].question.label ? (stats[i].question.label + '.' + childIndex) : (baseIndex + '.' + childIndex);
      } else {
        baseIndex++;
        childIndex = -1;
        previousGroupId = -1;
        results[i] = stats[i].question.label ? stats[i].question.label : baseIndex;
      }
    }
    return results;
  }

  function quickTestQuestion(q) {
    return q && q.type === 'synthetic' && q.resourceUUIDs && q.resourceUUIDs.length > 0
  }
}());
