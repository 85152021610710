import { Injectable } from '@angular/core';
import * as queryString from 'query-string';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class UrlBuilderService {

  constructor() { }

  public buildUrl(url: string, params: any) {
    const serializedParams = queryString.stringify(params)

    if (serializedParams.length > 0) {
      url += ((url.indexOf('?') === -1) ? '?' : '&') + serializedParams;
    }

    return url;
  }
}
