import { QuestionType } from './../../../../talcloud/models/talcloud.d';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { Component, OnInit, Input, Inject, forwardRef, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';
import { OedExerciseService } from '../../oed-exercise.service';
import { LoadingService } from 'app/shared/loading/loading.service';
import { dirListToTree, kpListToTree, buildTaskByQues, getRayImgLabelByTask, buildCreateQuesBodyByTaskInfo, replaceFormulaRes, validateQuestion, buildKpDefOpenMap, buildDefOpenMap } from '../ex-dialog-utils';
import { NotificationService } from 'app/shared/notification/notification.service';
import { flatMap, take, repeatWhen, filter, map, timeout } from 'rxjs/operators';
import { interval, of, forkJoin, timer } from 'rxjs';

enum QuesType {
  singlechoice,
  multichoice,
  fill,
  yesorno,
  qa,
  synthetic,
  connect,
  singlevote
}
@Component({
  selector: 'app-ex-mark-edit-dialog',
  template: require('./ex-mark-edit-dialog.component.html'),
  styles: [require('./ex-mark-edit-dialog.component.scss')]
})
export class ExMarkEditDialogComponent implements OnInit {
  @Input() private subjectId: number
  @Input() private levelId: number
  @Input() private nodeData: any
  @Input() private preQuesOptions: any
  @Input() private preNodeId: number
  @Output() private action = new EventEmitter<any>()

  private quesTypeList: any[]
  private isChild: boolean
  private typeIdx: number
  private score: number
  private allKps: any[]
  private kpList: any[]
  private isSaved: boolean
  private exerciseBookId: number
  private formulaResMap: any

  private allDirsList: any[]
  private currentNode: any
  private parentNode: any
  private parentNodeName: string

  private currentTest: any
  private currentTestName: string
  private currentTestList: any[]
  private question: any
  private formulaResList: any[]
  private syncQuesList = []
  private curSyncQues: any
  private curSyncQuesName: any

  constructor(
    private loadingService: LoadingService,
    private notifyService: NotificationService,
    private bsModalRef: BsModalRef,
    @Inject(forwardRef(() => OedDialogService)) private oedDialogService: OedDialogService,
    private oedExerciseService: OedExerciseService,
  ) { }

  ngOnInit() {
    this.buildQuesTypeList()
    this.buildInitData()
    this.loadData()
  }

  private buildInitData = () => {
    this.currentNode = _.get(this.nodeData, 'node', {})
    this.isSaved = _.get(this.nodeData, 'origNode.status', '') === 'saved'
    this.exerciseBookId = _.get(this.nodeData, 'origNode.exerciseBookId', 0)
  }

  private syncTypeCheck = () => {
    // 检查当前题目是否是综合题
    const nodeType = _.get(this.nodeData, 'origNode.jsonStr.nodeType', '')
    if (nodeType === 'synQuestion') {
      this.typeIdx = QuesType.synthetic
      this.score = 0
    }
  }

  private singleChoiceTypeCheck = () => {
    // 如果多选题只有一个选项时，将当前题目标记为单选题
    if (this.typeIdx === QuesType.multichoice) {
      const answer = _.get(this.nodeData, 'origNode.jsonStr.content.answer', '')
      const answerList = _.split(answer, ',')
      if (_.size(answerList) === 1) {
        this.typeIdx = QuesType.singlechoice
      }
    }
  }

  private buildRayQues = () => {
    // 将节点信息转换为ray的题目信息
    // 包含三种情况
    // 1、题目信息已保存到server(题目已保存)
    // 2、题目中含有公式(未保存)
    // 3、题目中不含有公式(未保存)
    if (this.isSaved) {
      // 1、已保存
      return
    }
    let answerDetails, question, choices, leftOpts, rightOpts
    if (_.size(this.formulaResList) > 0) {
      // 2、当前题目中含有公式，以下字段需要对公式进行替换
      this.formulaResMap = _.keyBy(this.formulaResList, r => _.get(r, 'formula', ''))

      answerDetails = replaceFormulaRes(this.getQuesItem('analysis', ''), this.formulaResMap)
      question = replaceFormulaRes(this.getQuesItem('body', ''), this.formulaResMap)
      choices = this.getQuesListItemByFormulaMap('choices', [], this.formulaResMap)
      leftOpts = this.getQuesListItemByFormulaMap('leftOpts', [], this.formulaResMap)
      rightOpts = this.getQuesListItemByFormulaMap('rightOpts', [], this.formulaResMap)
    } else {
      // 3、当前题目中不含公式
      answerDetails = this.getQuesItem('analysis', '')
      question = this.getQuesItem('body', '')
      choices = this.getQuesListItem('choices', [])
      leftOpts = this.getQuesListItem('leftOpts', [])
      rightOpts = this.getQuesListItem('rightOpts', [])
    }
    const point2 = this.getQuesItem('point', 0)
    const type = this.getQuesItem('type', '')
    const answer = this.getQuesItem('answer', '')
    const relatedSections = []
    const needPic = false
    const sort = 1

    this.question = { answer, answerDetails, choices, point2, question, leftOpts, rightOpts, type, relatedSections, needPic, sort }
  }

  private loadData = () => {
    // 加载知识点，目录信息，已保存的题目，转公式，试卷列表，综合题
    forkJoin([
      this.loadAllKps(),
      this.loadAllDirs(),
      this.loadQuestion(),
      this.doTransferFormula(),
      this.loadTestsByExBookId(),
      this.loadSyncQues(),
    ]).pipe(this.loadingService.withLoading())
      .subscribe(
        ([allKps, allDirsList, savedQues, formulaResList, currentTestList, syncList]) => {
          this.allKps = allKps
          this.allDirsList = allDirsList
          this.formulaResList = formulaResList
          this.currentTestList = currentTestList

          this.buildRayQues()
          const quesOption = this.buildCurQuesOption(savedQues, syncList)
          this.loadQuestionOption(quesOption)
        }, e => {
          this.notifyService.notify('error', '加载题目信息失败，请稍后再试')
          console.error('load ques info error: ', e)
        }
      )
  }

  private loadAllKps = () => this.oedExerciseService.getKps(this.subjectId, this.levelId)
  private loadAllDirs = () => this.oedExerciseService.getAllDirs(this.exerciseBookId)
  private loadTestsByExBookId = () => this.oedExerciseService.getTestsByExBookId(this.exerciseBookId)
  private loadSyncQues = () => this.oedExerciseService.getSynQuestions(this.exerciseBookId)

  private buildSavedQues = (savedQues: any) => {
    this.question = _.get(savedQues, 'question', {})
    this.currentNode = _.get(savedQues, 'node', {})
    this.kpList = _.get(savedQues, 'kps', [])
    this.currentTest = _.get(savedQues, 'test', {})

    const quesType = _.get(this.question, 'type', '')
    this.typeIdx = _.findIndex(this.quesTypeList, t => t.type === quesType)
    this.score = _.get(this.question, 'point2', 1)
    if (this.typeIdx === QuesType.synthetic) {
      this.score = 0
    }
    if (this.typeIdx == QuesType.fill) {
      this.initFillPoint()
    }
    this.parentNode = _.find(this.allDirsList, dir => _.get(dir, 'id', 0) === _.get(this.currentNode, 'parentId', 0))
  }

  private buildQuesByOrignNode = () => {
    // 直接加载上一个保存的目录，或者使用json文件的父子关系
    if (this.preNodeId) {
      this.parentNode = _.find(this.allDirsList, dir => _.get(dir, 'id', 0) === this.preNodeId)
    } else {
      this.parentNode = _.find(this.allDirsList, dir => _.get(dir, 'origId', 0) === _.get(this.nodeData, 'origNode.parentId', 0))
    }
    const quesType = this.getQuesItem('type', '')
    this.typeIdx = _.findIndex(this.quesTypeList, t => t.type === quesType)
    this.kpList = _.get(this.nodeData, 'origNode.jsonStr.knowledge', [])
    if (this.typeIdx === QuesType.fill) {
      // 只在未保存的题目中执行，已保存题目不需要
      this.buildFillAnswer()
    }

    this.replaceQuesBlank()
    this.syncTypeCheck()
    this.singleChoiceTypeCheck()
  }

  private replaceQuesBlank = () => {
    const question = _.get(this.question, 'question', '')
    if (!_.includes(question, 'data-label="blank"')) {
      return
    }
    const replaceQues = question.replace(/<span(.*?)<\/span>/g, "______")
    _.set(this.question, 'question', replaceQues)
  }

  private buildFillAnswer = () => {
    // 所有答案置空，正确答案放入解析中
    const answer = JSON.parse(_.get(this.question, 'answer', {}))
    const answerDetails = _.get(this.question, 'answerDetails', '')
    let answerToAnalyze = '<p>答案：'
    const answerAnswer = _.get(answer, 'answer', [])
    const answerTypes = _.get(answer, 'answerTypes', [])
    const emptyAnswer = _.map(answerAnswer, (a: any, index: number) => {
      if (_.size(a) > 0 && _.includes(a[0], '<p>')) {
        // 填空题的最后一个空格，都会是一个只含有一个参数带有p标签的数组的字符串数组，将字符串提取出来
        a = a[0].substring(3, a[0].length - 4)
      }
      answerToAnalyze += '&nbsp;&nbsp;' + a
      if (index === _.size(answerAnswer) - 1) {
        answerToAnalyze += '</p><br>'
      }
      return ''
    })
    const matchAnswerType = _.map(answerTypes, t => 'manual-score')
    _.set(answer, 'answer', emptyAnswer)
    _.set(answer, 'answerTypes', matchAnswerType)
    _.set(this.question, 'answer', JSON.stringify(answer))
    if (_.size(this.formulaResList) > 0) {
      answerToAnalyze = replaceFormulaRes(answerToAnalyze, this.formulaResMap)
    }
    _.set(this.question, 'answerDetails', (answerToAnalyze + answerDetails))
  }

  private checkIsChild = () => {
    const origNodeParentId = _.get(this.nodeData, 'origNode.parentId', 0)
    for (const sync of this.syncQuesList) {
      const origNodeId = _.get(sync, 'node.origId', 0)
      if (origNodeParentId === origNodeId) return true
    }
    return false
  }

  private buildCurQuesOption = (savedQues: any, syncList: any[]) => {
    this.syncQuesList = _.map(syncList, r => ({ ...r, name: _.get(r, 'question.question', '') }))
    this.isChild = this.checkIsChild()
    if (this.isChild) {
      this.buildSyncQuesInfo()
    }
    if (this.isSaved) {
      this.buildSavedQues(savedQues)
    } else {
      this.buildQuesByOrignNode()
    }

    return this.buildQuesOptions()
  }

  private buildSyncQuesInfo = () => {
    // 先用当前question的parentId找
    this.curSyncQues = _.find(this.syncQuesList, r => _.get(r, 'question.id', 0) == _.get(this.question, 'parentQuestionId', 0))
    if (!this.curSyncQues) {
      // 如果找不到就用origNodeId找
      const origNodeParentId = _.get(this.nodeData, 'origNode.parentId', 0)
      this.curSyncQues = _.find(this.syncQuesList, r => _.get(r, 'node.origId', 0) == origNodeParentId)
    }
    const syncName = _.get(this.curSyncQues, 'question.question', '')
    this.curSyncQuesName = syncName === '' ? '无' : syncName
    this.curSyncQuesName = _.size(this.syncQuesList) <= 0 ? '无' : syncName === '' ? '请选择' : syncName
  }

  private loadQuestion = () => {
    if (!this.isSaved) {
      return of({})
    }
    const nodeId = _.get(this.currentNode, 'id', 0)
    const withoutNode = nodeId <= 0
    if (withoutNode) {
      console.error('origNode is saved but without node; origNode: ', this.nodeData)
      return of({})
    }
    return this.oedExerciseService.getQuesInfoByNodeId(nodeId)
  }

  private doTransferFormula = () => {
    // 检查是否需要触发当前节点的转公式任务，需满足以下条件
    // 1、题目中包含公式
    // 2、当前节点未保存
    const jsonStr = JSON.stringify(_.get(this.nodeData, 'origNode.jsonStr', {}))
    const needTransfer = _.includes(jsonStr, '$$')
    if (!needTransfer || this.isSaved) {
      return of([])
    }
    console.log('request transfer formula')
    const origNode = _.get(this.nodeData, 'origNode.id', 0)
    return this.oedExerciseService.formulaByOrigNodeId(origNode).pipe(timeout(50000))
  }

  private getQuesItem = (name: string, defValue: any) => {
    return _.get(this.nodeData, `origNode.jsonStr.content.${name}`, defValue)
  }

  private getQuesListItem = (name: string, defValue: any) => {
    return _.map(this.getQuesItem(name, defValue), c => ({ content: c }))
  }

  private getQuesListItemByFormulaMap = (name: string, defValue: any, formulaResMap: any) => {
    return _.map(this.getQuesItem(name, defValue), c => ({ content: replaceFormulaRes(c, formulaResMap) }))
  }

  private buildQuesTypeList = () => {
    this.quesTypeList = [
      { type: 'singlechoice', text: '单选', isShow: true },
      { type: 'multichoice', text: '多选', isShow: true },
      { type: 'fill', text: '填空', isShow: true },
      { type: 'yesorno', text: '判断', isShow: true },
      { type: 'qa', text: '问答', isShow: true },
      { type: 'synthetic', text: '综合', isShow: !this.isChild },
      { type: 'connect', text: '匹配', isShow: true },
    ]
  }

  private showChoiceItemDialog = () => {
    if (_.size(this.allDirsList) <= 0) {
      this.notifyService.notify('info', '当前目录无数据')
      return
    }
    const treeData = dirListToTree(this.allDirsList)
    // 如果没有父级目录，则全部展开
    const defOpenMap = _.isEmpty(this.parentNode) ? _.keyBy(this.allDirsList, 'id') : buildDefOpenMap(this.allDirsList, [this.parentNode])
    this.oedDialogService.openModalWithComponent2(
      'ExChoiceItemDialogComponent',
      {
        title: '选择目录',
        treeData,
        maxSelectedCount: 1,
        selectItems: [this.parentNode],
        defOpenMap,
        selectOne: true,
      },
      { class: 'ex-dialog-common' }
    ).subscribe(res => {
      this.parentNode = res[0]
      this.parentNodeName = _.get(this.parentNode, 'name', '')
    })
  }

  private buidCurSelectedTest = () => {
    const id = _.get(this.currentTest, 'id', -1)
    const name = _.get(this.currentTest, 'name', '不加入试卷')
    return [{ ...this.currentTest, id, name, testId: id, testName: name }]
  }

  private openPaperListDialog = () => {
    const testData = _.map(this.currentTestList, r => ({ ...r, name: _.get(r, 'testName', ''), id: _.get(r, 'testId', '') }))
    const treeData = _.concat({ id: -1, testId: -1, name: '不加入试卷', testName: '不加入试卷' }, testData)
    const curSelectedTest = this.buidCurSelectedTest()

    const defOpenMap = buildDefOpenMap(testData, [this.currentTest])
    this.oedDialogService.openModalWithComponent2(
      'ExChoiceItemDialogComponent',
      {

        title: '选择试卷',
        treeData,
        maxSelectedCount: 1,
        maxLevel: 0,
        selectItems: this.buidCurSelectedTest(),
        defOpenMap,
        selectOne: true,
      },
      { class: 'ex-dialog-common' }
    ).subscribe(res => {
      delete res[0].name
      delete res[0].id
      this.currentTest = { ...res[0], id: res[0].testId, name: res[0].testName }
      this.currentTestName = _.get(this.currentTest, 'name', '')
    })
  }

  private initFillPoint = () => {
    // 填空题分值，初始化时，每个空为1分
    const answer = JSON.parse(_.get(this.question, 'answer', {}))
    const answerCount = _.size(answer.answerPoints)
    const fillScore = _.get(this.question, 'point2', 0) / answerCount
    answer.answerPoints = _.map(answer.answerPoints, p => 1)
    answer.answerPoints2 = _.map(answer.answerPoints2, p => 1)
    _.set(this.question, 'point', fillScore)
    _.set(this.question, 'point2', fillScore)
    _.set(this.question, 'answer', JSON.stringify(answer))
    this.score = _.get(this.question, 'point2', 1)
  }

  private setFillPoint = () => {
    // 填空题分值，更新时每个空等于当前分数乘以空的个数
    const answer = JSON.parse(_.get(this.question, 'answer', {}))
    const answerCount = _.size(answer.answerPoints)
    answer.answerPoints = _.map(answer.answerPoints, p => this.score)
    answer.answerPoints2 = _.map(answer.answerPoints2, p => this.score)
    _.set(this.question, 'point', (answerCount * this.score))
    _.set(this.question, 'point2', (answerCount * this.score))
    _.set(this.question, 'answer', JSON.stringify(answer))
  }

  private buildCreateQuesBody = () => {
    const origId = _.get(this.nodeData, 'origNode.id', 0)
    const parentNodeId = this.getPnIdAndbuildQues()
    if (this.typeIdx == QuesType.fill) {
      this.setFillPoint()
    }
    if (parentNodeId <= 0) return {}
    const body = {
      node: {
        origId,
        name: '',
        nodeLevel: 4,
        nodeType: 'question',
        parentId: parentNodeId,
        exerciseBookId: this.exerciseBookId,
      },
      question: this.question,
      kpIds: _.map(this.kpList, kp => _.get(kp, 'id', 0)),
    }
    this.addTestId(body)
    return body
  }

  private addTestId = (body: any) => {
    const testId = _.get(this.currentTest, 'id', 0)
    if (testId > 0) {
      body['testId'] = testId
    }
  }

  private showKpListDialog = () => {
    const treeKps = _.map(this.allKps, r => ({ ...r, parentId: r.pid, nodeLevel: r.deep }))
    const treeData = kpListToTree(treeKps)
    const defOpenMap = buildKpDefOpenMap(this.allKps, this.kpList)
    this.oedDialogService.openModalWithComponent2(
      'ExChoiceItemDialogComponent',
      {
        title: '选择知识点',
        treeData,
        selectItems: this.kpList,
        defOpenMap,
        selectChild: true,
      },
      { class: 'ex-dialog-common' }
    ).subscribe(selKps => {
      _.forEach(selKps, kp => {
        delete kp.parentId
        delete kp.nodeLevel
        delete kp.children
        delete kp.isExpand
        delete kp.isSelected
      })
      this.kpList = selKps
    })
  }

  private showQuesEditDialog = () => {
    _.set(this.question, 'point2', this.score)
    this.oedDialogService.openModalWithComponent2(
      'ExQuesEditDialogComponent',
      {
        question: this.question,
        syncQues: this.curSyncQues,
        score: this.score
      },
      { class: 'ex-dialog-common' }
    ).subscribe(ques => {
      if (!_.isEmpty(ques)) this.question = ques
    })
  }

  private doCreateAndSave = (body: any) => {
    let rTasksInfoList = buildTaskByQues(body)
    this.doResDownload(rTasksInfoList)
      .pipe(
        map(taskRes => {
          if (_.isEmpty(taskRes)) return body
          const downloaded = _.get(taskRes, 'downloaded', [])
          rTasksInfoList = _.map(rTasksInfoList, (info, idx) => ({ ...info, rayImgLabel: getRayImgLabelByTask(downloaded[idx]) }))
          const createQuesBody = buildCreateQuesBodyByTaskInfo(body, rTasksInfoList)
          return createQuesBody
        }),
        flatMap(body => this.oedExerciseService.createNodeAndQuestion(body)),
        flatMap(createRes => {
          this.currentNode = createRes
          const origNodeId = _.get(this.nodeData, 'origNode.id', 0)
          return this.oedExerciseService.saveOrigNode(origNodeId)
        }),
        this.loadingService.withLoading(),
      )
      .subscribe(() => {
        this.action.emit({
          node: this.currentNode,
          saved: true,
          quesOptions: this.buildQuesOptions(),
          nodeId: _.get(this.parentNode, 'id', 0)
        })
        this.bsModalRef.hide()
      }, e => {
        this.notifyService.notify('error', '保存题目失败，请稍后再试')
        console.error('create and save ques request error: ', e)
      })
  }

  private buildQuesOptions = () => {
    return {
      ischild: this.isChild,
      typeIdx: this.typeIdx,
      kps: this.kpList,
      score: this.score,
      parentNode: this.parentNode,
      parentQues: this.curSyncQues,
      test: this.currentTest,
    }
  }

  private getPnIdAndbuildQues = () => {
    let parentNodeId = _.get(this.parentNode, 'id', 0)
    if (!this.isChild && parentNodeId === 0) {
      this.notifyService.notify('error', '请先选择目录')
      return 0
    }
    const type = _.get(this.quesTypeList[this.typeIdx], 'type', '')
    if (type === '') {
      this.notifyService.notify('error', '题目类型错误，请稍后再试')
      return 0
    }
    let parentQuestionId = 0
    if (this.isChild) {
      parentQuestionId = _.get(this.curSyncQues, 'question.id', 0)
      parentNodeId = _.get(this.curSyncQues, 'node.id', 0)
      if (parentQuestionId <= 0) {
        this.notifyService.notify('error', '请选择一道综合题')
        return 0
      }
    }
    _.set(this.question, 'type', type)
    _.set(this.question, 'point2', this.score)
    _.set(this.question, 'parentQuestionId', parentQuestionId)
    return parentNodeId
  }

  private buildUpdateQuesBody = () => {
    const parentNodeId = this.getPnIdAndbuildQues()
    if (parentNodeId <= 0) return {}
    if (this.typeIdx == QuesType.fill) {
      this.setFillPoint()
    }
    const body = {
      parentNodeId,
      question: this.question,
      kpIds: _.map(this.kpList, kp => _.get(kp, 'id', 0)),
    }
    this.addTestId(body)
    return body
  }

  private checkRedDownload = (taskIds: number[]) => {
    // 检查资源下载结果
    // 2s一次，检查15次
    return this.oedExerciseService.resCheckTasks(taskIds)
      .pipe(
        repeatWhen(_ => interval(2000).pipe(take(15))),
        filter((data, idx) => {
          if (idx === 5) throw 'check task time out'
          if (_.get(data, 'status', '') === 'success') return true
          return false
        }),
        take(1),
      )
  }

  private doResDownload = (rTasksInfoList: any[]) => {
    const createTaskBody = _.map(rTasksInfoList, i => _.get(i, 'taskBody', {}))
    if (_.size(createTaskBody) <= 0) {
      return of({})
    }
    // 创建资源下载任务
    return this.oedExerciseService.resCreateTasks(createTaskBody)
      .pipe(flatMap((taskIds: number[]) => this.checkRedDownload(taskIds)))
  }

  private doUpdateQues = (body: any) => {
    const nodeId = _.get(this.currentNode, 'id', '')
    const parentId = _.get(this.currentNode, 'parentId', '')
    const questionId = _.get(this.currentNode, 'questionId', '')
    _.set(body, 'question.id', questionId)
    _.set(body, 'question.versionId', 9)
    this.oedExerciseService.updateQuestion(nodeId, questionId, body)
      .pipe(this.loadingService.withLoading())
      .subscribe((res) => {
        this.action.emit({
          node: this.currentNode,
          saved: true,
          quesOptions: this.buildQuesOptions(),
          nodeId: _.get(this.parentNode, 'id', 0)
        })
        this.bsModalRef.hide()
      }, e => {
        this.notifyService.notify('error', '更新题目失败，请稍后再试')
        console.error('create and save ques request error: ', e)
      })
  }

  private checkValid = (body: any) => {
    if (_.isEmpty(body)) return false
    const validResult = validateQuestion(body.question)
    if (!validResult.valid) {
      this.notifyService.notify('error', validResult.message)
      return false
    }
    return true
  }

  private doSave = () => {
    if (_.isEmpty(this.currentNode)) {
      const createQuesBody = this.buildCreateQuesBody()
      if (!this.checkValid(createQuesBody)) return
      this.doCreateAndSave(createQuesBody)
    } else {
      const updateQuesBody: any = this.buildUpdateQuesBody()
      if (!this.checkValid(updateQuesBody)) return
      this.doUpdateQues(updateQuesBody)
    }
  }

  private doIgnore = () => {
    const origNodeId = _.get(this.nodeData, 'origNode.id', 0)
    this.oedExerciseService.ignoreOrigNode(origNodeId)
      .pipe(this.loadingService.withLoading())
      .subscribe(() => {
        this.action.emit({
          ignored: true,
          nodeId: _.get(this.parentNode, 'id', 0)
        })
        this.bsModalRef.hide()
      }, e => console.error('ignore origNode request error: ', e))
  }

  private doSwitchBtn = () => {
    this.isChild = !this.isChild
    this.buildQuesTypeList()
    if (this.isChild && this.typeIdx === QuesType.synthetic) {
      // 切换成子题后，如果之前选择的是综合题，则默认选择为单选题
      this.typeIdx = QuesType.singlechoice
      this.score = 1
      _.set(this.question, 'type', 'singlechoice')
    }
    this.curSyncQues = {}
    this.curSyncQuesName = ''
    if (this.isChild) {
      this.buildSyncQuesInfo()
    }
  }

  private onSyncQuesBtn = (item: any) => {
    this.curSyncQues = item
    this.curSyncQuesName = _.get(this.curSyncQues, 'question.question', '')
  }

  private loadQuestionOption = (quesOption: any, isSamePreQues=false) => {
    this.kpList = _.get(quesOption, 'kps', [])
    this.score = _.get(quesOption, 'score', 1)
    this.parentNode = _.get(quesOption, 'parentNode', {})
    const pdName = _.get(this.parentNode, 'name', '')
    this.parentNodeName = pdName === '' ? '请选择' : pdName
    this.curSyncQues = _.get(quesOption, 'parentQues', {})
    this.curSyncQuesName = _.get(this.curSyncQues, 'question.question', '')
    this.currentTest = _.get(quesOption, 'test', {})
    const testName = _.get(this.currentTest, 'name', '')
    this.currentTestName = testName === '' ? '不加入试卷' : testName
    if (isSamePreQues) return
    this.isChild = _.get(quesOption, 'ischild', false)
    this.typeIdx = _.get(quesOption, 'typeIdx', 1)

    const type = _.get(this.quesTypeList[this.typeIdx], 'type', '')
    const parentQuestionId = _.get(this.curSyncQues, 'question.id', 0)
    _.set(this.question, 'type', type)
    _.set(this.question, 'parentQuestionId', parentQuestionId)
  }

  private doSamePreQues = () => {
    this.loadQuestionOption(this.preQuesOptions, true)
  }

  private toggleType = (index: number) => {
    if (this.typeIdx == index) return
    this.typeIdx = index
    const type = _.get(this.quesTypeList[this.typeIdx], 'type', '')
    _.set(this.question, 'type', type)
    _.set(this.question, 'answer', '')
    _.set(this.question, 'choices', [])
    _.set(this.question, 'leftOpts', [])
    _.set(this.question, 'rightOpts', [])
    this.score = (this.typeIdx === QuesType.synthetic) ? 0 : 1
  }

  private onCheckScore = () => {
    if (this.score <= 0) {
      this.notifyService.notify('error', '分数必须大于0')
      this.score = 1
    }
    if (this.score % 0.5 != 0) {
      this.notifyService.notify('error', '分数必须为0.5的倍数')
      this.score = _.floor(this.score)
    }
    _.set(this.question, 'point2', this.score)
  }

  private getChecked = (index: number) => this.typeIdx == index
  private getPreQuesDisable = () => _.isEmpty(this.preQuesOptions)
  private getSaveBtnDisable = () => {
    if (this.isChild) {
      return this.score <= 0 || _.isEmpty(this.curSyncQues)
    } else {
      return (this.typeIdx != 5 && this.score <= 0) || _.isEmpty(this.parentNode)
    }
  }

  private onSwitchBtn = () => this.doSwitchBtn()
  private onTypeBtn = (index: number) => this.toggleType(index)
  private onDelKp = (kp: any) => _.remove(this.kpList, item => item.id === kp.id)
  private onMenu = () => this.showChoiceItemDialog()
  private onPaper = () => this.openPaperListDialog()
  private onSamePreQues = () => this.doSamePreQues()
  private onIgnore = () => this.doIgnore()
  private onSave = () => this.doSave()
  private onKp = () => this.showKpListDialog()
  private onQuesEdit = () => this.showQuesEditDialog()
}
