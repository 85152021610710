import { Component, OnInit } from '@angular/core';
import { getHttpCDN } from 'app2/utils/getHttpCDN';

@Component({
  selector: 'app-production-download',
  template: require('./production-download.component.html'),
  styles: [require('./production-download.component.scss')]
})
export class ProductionDownloadComponent implements OnInit {
  productions: any = [];
  pLine1: any = [];
  pLine2: any = [];

  constructor() { }

  ngOnInit() {
    this.productions = [
      {
        link: getHttpCDN(`/download/raydesktop/win32/RayDesktopSetup.exe`),
        name: '智慧课堂',
        subName: '',
        support: 'win7 sp1 +',
        imageUrl: 'Assets/images2/oed_windows.png',
        show: true,
      },
      {
        link: getHttpCDN(`/download/raydesktop/cloud/RayDesktopSetup-cloud.exe`),
        name: '云课堂',
        subName: '',
        support: 'win7 sp1 +',
        imageUrl: 'Assets/images2/oed_windows.png',
        show: true,
      },
      {
        link: getHttpCDN(`/download/raydesktop/answer/RayDesktopSetup-answer.exe`),
        name: '答题器课堂',
        subName: '',
        support: 'win7 sp1 +',
        imageUrl: 'Assets/images2/oed_windows.png',
        show: true,
      },
      {
        link: getHttpCDN(`/download/raydesktop/paperPen/RayDesktopSetup-paperPen.exe`),
        name: '纸笔课堂',
        subName: '',
        support: 'win7 sp1 +',
        imageUrl: 'Assets/images2/oed_windows.png',
        show: true,
      },
      {
        link: getHttpCDN(`/download/OEdClassroomTeacher.apk`),
        name: '智慧课堂',
        subName: '教师pad端',
        support: 'android 5.0 +',
        imageUrl: 'Assets/images2/oed_pad_teacher.png',
        show: true,
      },
      {
        link: getHttpCDN(`/download/OEdClassroomStd.apk`),
        name: '智慧课堂',
        subName: '学生pad端',
        support: 'android 5.0 +',
        imageUrl: 'Assets/images2/oed_pad_student.png',
        show: true,
      },
      {
        link: 'https://apps.apple.com/cn/app/xiao-rui-zhu-jiao/id1494074501?mt=8',
        name: '小锐助教',
        subName: 'iOS版',
        support: 'iOS 11 +',
        imageUrl: 'Assets/images2/oed_mesh_ios.png',
        show: true,
      },
      {
        link: getHttpCDN(`/download/rayMesh.apk`),
        name: '小锐助教',
        subName: '安卓版',
        support: 'android 5.0 +',
        imageUrl: 'Assets/images2/oed_mesh_android.png',
        show: true,
      },
      {
        link: getHttpCDN(`/download/rayStudent.apk`),
        name: '小锐作业',
        subName: '安卓版',
        support: 'android 5.0 +',
        imageUrl: 'Assets/images2/android_mobile.png',
        show: true,
      },
      {
        link: 'https://itunes.apple.com/cn/app/xiao-rui-zuo-ye/id1111472397?mt=8',
        name: '小锐作业',
        subName: 'iOS版',
        support: 'iOS 11 +',
        imageUrl: 'Assets/images2/ios_mobile.png',
        show: true,
      },
    ]
    this.pLine1 = [];
    this.pLine2 = [];

    for (let i = 0; i < this.productions.length; i++) {
      if (i < 6) {
        this.pLine1.push(this.productions[i])
      } else {
        this.pLine2.push(this.productions[i])
      }
    }
  }

}
