import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { downgradeComponent,  downgradeInjectable, UpgradeModule } from '@angular/upgrade/static';

import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

import { CoreModule } from './app/core/core.module'
import { httpInterceptorProviders } from './app/http-interceptors'
import { LessonModule } from './app/lesson/lesson.module'
import { RuanyunModule } from './app/ruanyun/ruanyun.module'
import { SharedModule } from './app/shared/shared.module'

import { legacyAppServiceProvider } from './app/core/legacy-app.service';

import { CourseEditingModule } from 'app/course-editing/course-editing.module';
import { TalcloudModule } from 'app/talcloud/talcloud.module';
import { PreviewModule } from './app/preview/preview.module'
import {OnlineTrainModule} from 'app/online-train/online-train.module';
import { PostLoginModule } from 'app/post-login/post-login.module';
import { TutorialModule } from 'app/tutorial/tutorial.module';
import {OfficeEditorModule} from 'app/office-editor/office-editor.module';
import { ManageModule } from 'app/manage/manage.module';
import { ReviewModule } from 'app/review/review.module';
import { ErrorPageModule } from 'app/error-page/error-page.module';
import {CloudDriveModule} from 'app/cloud-drive/cloud-drive.module';
import {TreeModule} from 'angular-tree-component';
import { DragulaModule } from 'ng2-dragula';
import { ChartsModule } from 'app/charts/charts.module';
import { MenuModule } from 'app/menu/menu.module'
import { ExamModule } from 'app/exam/exam.module'
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import {CloudModule} from 'app/cloud/cloud.module';

@NgModule({
  imports: [
    BrowserModule,
    BsDropdownModule.forRoot(),
    DragulaModule.forRoot(),
    UpgradeModule,
    UIRouterUpgradeModule.forRoot({ states: [] }),
    CoreModule,
    CourseEditingModule,
    ChartsModule,
    ManageModule,
    ReviewModule,
    SharedModule,
    RuanyunModule,
    LessonModule,
    TalcloudModule,
    HttpClientModule,
    PreviewModule,
    OnlineTrainModule,
    PostLoginModule,
    OfficeEditorModule,
    CloudDriveModule,
    TooltipModule.forRoot(),
    TreeModule.forRoot(),
    PaginationModule.forRoot(),
    TutorialModule,
    OfficeEditorModule,
    ErrorPageModule,
    MenuModule,
    ExamModule,
    CloudModule,
  ],
  providers: [
    httpInterceptorProviders,
    legacyAppServiceProvider,
    { provide: '$scope', useExisting: '$rootScope' }
  ]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) { }
  public ngDoBootstrap() {
    console.log('angular app bootstrap')
    this.upgrade.bootstrap(document as any, ['oedApp'], { strictDi: true });
    $('#oed-splash').css('display', 'none');
  }
}
