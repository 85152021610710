(function() {
'use strict';
angular.module('app.utils.homeworkGroupUtils', [])
.factory('oedHomeworkGroupUtils', oedHomeworkGroupUtils);

oedHomeworkGroupUtils.$inject = ['notificationService', 'dialogs', '$q', 'oedTaskPresentResource', 'oedTaskTest']
function oedHomeworkGroupUtils(notificationService, dialogs, $q, oedTaskPresentResource, oedTaskTest) {
  var createGroup = {
    presentResource: createPreResGroup,
    subjectiveTest: createSubjectiveGroup,
    objectiveTest: cerateObjectiveGroup
  }
  return {
    removeGroup: removeGroup,
    modifyGroupType: modifyGroupType,
    createPreResGroup: createPreResGroup,
    createSubjectiveGroup: createSubjectiveGroup,
    cerateObjectiveGroup: cerateObjectiveGroup,
    getGroupName: getGroupName,
    getCreateGroupFuns: getCreateGroupFuns,
    getNextSort: getNextSort,
    addGroup: addGroup,
    filterUnSavedGroups: filterUnSavedGroups
  };

  function filterUnSavedGroups(groups) {
    return _.filter(groups, function(group) {
      return _.has(group, 'id');
    });
  }

  function addGroup(groups) {
    var lastGroup = _.maxBy(groups, function(o) {
      return o.sort;
    });
    var type = _.get(lastGroup, '$type', 'objectiveTest');
    if (!_.has(createGroup, type)) {
      type = 'objectiveTest';
    }
    return _.concat(groups, createGroup[type](getNextSort(groups)));
  }

  function getCreateGroupFuns() {
    return createGroup;
  }

  function removeGroup(groups, group) {
    if (!_.has(group, 'id')) {
      _.pullAt(groups, _.findIndex(groups, group));
      return;
    }
    var dlg = dialogs.confirm('确定删除？', '确定删除此试卷吗？');
    dlg.result.then(function(btn) {
      group.$delete().then(function(taskTest) {
        notificationService.notify('info', '删除成功')
        _.pullAt(groups, _.findIndex(groups, group));
      });
    }, function(btn) {});
  }

  function modifyGroupType(groups, group, type) {
    if (group.$type == type) {
      return;
    }

    var message = '修改试卷类型，将会丢失所有内容？';
    var dlg = dialogs.confirm('确定修改?', message);
    dlg.result.then(function() {
      modifyGroup(groups, group, type);
    });
  }

  function modifyGroup(groups, group, type) {
    var p;
    if (_.has(group, 'id')) {
      p = group.$delete();
    } else {
      p = $q.resolve(null);
    }
    p.then(function() {
      var index = _.findIndex(groups, group);
      groups.splice(index, 1);
      groups.splice(index, 0, createGroup[type](group.sort));
    });
  }

  function createPreResGroup(sort) {
    return new oedTaskPresentResource({
      $type: 'presentResource',
      $res: {
        name: '展示资源' + sort,
        items: [],
        $cloudRes: {}
      },
      $open: true,
      sort: sort
    });
  }

  function createSubjectiveGroup(sort) {
    return new oedTaskTest({
      $type: 'subjectiveTest',
      $test: {
        name: '主观活动' + sort,
        questions: [],
        $cloudTest: {}
      },
      $open: true,
      sort: sort
    });
  }

  function cerateObjectiveGroup(sort) {
    return new oedTaskTest({
      $type: 'objectiveTest',
      $test: {
        name: '客观试卷' + sort,
        questions: [],
        $cloudTest: {}
      },
      $open: true,
      sort: sort
    });
  }

  function getNextSort(groups) {
    var maxSort = _.maxBy(groups, 'sort');
    maxSort = maxSort || {
      sort: 0
    };
    return maxSort.sort + 1;
  }

  function getGroupName(group) {
    if (group.$type != 'presentResource') {
      return _.get(group, '$test.name', '');
    }

    return _.get(group, '$res.name', '');
  }
}
})();
