angular.module('app.examination.updateTestPaper',[])
  .config(['$stateProvider', ($stateProvider) => {
    $stateProvider.state('updateTestPaperToPath', {
      url: '/updateTestPaperToPath/:pathId?coursepathId',
      template: require('assets/templates/examination/editInfo.html'),
      controller: 'updateTestPaperCtrl',
      loadingCls: 'blueloading',
      bodyStyle: 'bged'
    });
  }])
  .controller('updateTestPaperCtrl', updateTestPaperCtrl);

updateTestPaperCtrl.$inject = ['$scope', '$state', '$uibModal', '$log', 'dialogs', 'userInfo', 'oedConfig',
  'oedCourse', 'oedLoading', 'oedSection', 'oedUnitItem', 'oedCourseUtils',
  'oedCoursePath', 'oedOem', 'oedExamination', '$stateParams', 'localStorageService',
  '$q', 'oedMisc', 'oedLog', '$window']
function updateTestPaperCtrl(
                          $scope, $state, $uibModal, $log, dialogs, userInfo, oedConfig,
                          oedCourse, oedLoading, oedSection, oedUnitItem, oedCourseUtils,
                          oedCoursePath, oedOem, oedExamination, $stateParams, localStorageService, $q,
                          oedMisc, oedLog, $window) {
  // 获取考试的参数
  $scope.id = $stateParams.pathId;
  $scope.coursepathId = $stateParams.coursepathId;
  $scope.adding = true;
  if ($scope.id) {
    const curCpId = localStorageService.get('lastCoursePathId');
    let curCpMap = localStorageService.get('lastCoursePathCourseMap', curCpId);
    if (!curCpMap) {
      curCpMap = {};
    }
    curCpMap[curCpId] = $scope.id;
    localStorageService.set('lastCoursePathCourseMap', curCpMap);
  }
  $scope.treeConfig = {
    core: {
      multiple: true,
      animation: true,
      error: (error) => {
        $log.error('treeCtrl: error from js tree - ' + angular.toJson(error));
      },
      check_callback: true,
      worker: true
    },
    types: {
      default: {
        icon: 'fa fa-book'
      },
      root: {
        icon: 'glyphicon glyphicon-cloud'
      }
    },
    version: 1,
    plugins: ['types', 'checkbox']
  };
  $scope.exam = oedExamination.getTestPaperDetail({id: $scope.id});
  $scope.treeLoading = $q.defer();
  $scope.sectionTree = {
    treeData: []
  };
  $scope.onSelChanged = () => {
    $scope.$apply(() => {
      const selectedNodes = $scope.treeInstance.jstree(true).get_selected();
      const ids = _(selectedNodes).map((node) => {
        return _.parseInt(node, 10);
      }).filter().value();
      $scope.loading = oedCourseUtils.loadSectionNamesById(ids).then((name) => {
        $scope.selectedSectionNames = name || '';
        if (_.isEmpty($scope.course.courseName)) {
          $scope.course.courseName = name;
        }
      });
    });
  };
  $scope.loading = userInfo.then((info) => {
    $scope.availableSections = oedUnitItem.queryAvailableBySchoolAndCoursePath({
      schoolId: info.schoolId,
      coursePathId: $scope.coursepathId
    });

    $scope.canStar = info.canStar;
    $scope.uid = info.uid;

    return $q.all([$scope.availableSections.$promise,
      $scope.exam.$promise,
      $scope.treeLoading.$promise
    ]).then((dataList) => {
      $scope.sectionIdMap = {};
      $scope.course = dataList[1].exam;

      function visitSection(sec) {
        if (sec.id) {
          $scope.sectionIdMap[sec.id] = sec;
        }
        if (sec.child) {
          sec.child = _.sortBy(sec.child, ['sort']);
          _.each(sec.child, visitSection);
        }
      }
      _.each($scope.availableSections, visitSection);

      $scope.sectionTree.treeData = oedMisc.utils.convertSectionsToJsTreeModel($scope.sectionIdMap,
        $scope.availableSections);
      _.each($scope.sectionTree.treeData, (data) => {
        data.state.opened = true;
      });
      $scope.treeConfig.version++;

    });
  });

  $scope.onTreeReady = () => {
    if ($scope.exam.relatedSections) {
      const sections = _.map($scope.exam.relatedSections, 'id');
      $scope.treeInstance.jstree(true).select_node(sections);
    }
    $scope.treeLoading.resolve();
  };

  $scope.shouldApplyModelChanges = () => true;

  $scope.preventDropdownDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  $scope.gotoNext = () => {
    $scope.showValidationError = true;
    const selectedNode = $scope.treeInstance.jstree(true).get_selected();
    $scope.course.relatedSections = _.map(selectedNode, (secId) => {
      return  _.parseInt(secId);
    });
    if (_.isEmpty($scope.course.relatedSections)) {
      dialogs.error('无法保存', '当前试卷没有关联章节, 请关联章节后再继续');
      return;
    }
    oedLog.addPageView({
      courseId: $scope.course.id,
      type: 'editCourseInfo',
      userAgent: $window.navigator.userAgent,
      viewDetails: JSON.stringify({
        sectionIndex: $scope.course.sectionIndex
      })
    });
    oedExamination.updateTestPaper({
      name: $scope.course.name,
      duration: $scope.course.duration,
      sectionIds: $scope.course.relatedSections,
      id: $scope.id
    }).$promise.then((res) => {
      $state.go('editObjectTestPaper', {
        id: res.exam.id,
        pathId: $stateParams.pathId,
        coursepathId: $stateParams.coursepathId
      }, {
        reload: true,
        location: 'replace'
      });
    });
  };
}
