import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import * as _ from 'lodash';
import { StateService } from '@uirouter/core'
import {OnlineTrainService} from 'app/online-train/online-train.service';

@Component({
  selector: 'app-train-dir-nav',
  template: require('./train-dir-nav.component.html'),
  styles: [require('./train-dir-nav.component.scss')]
})
export class TrainDirNavComponent implements OnInit {

  @Input() public navs
  @Input() public editable
  constructor(private state: StateService, private onlineTrainService: OnlineTrainService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'navs')) {
      this.navs = changes.navs.currentValue
    }
  }

  public goto(idx) {
    if (!this.navs[idx].id) {
      return
    }

    const nav0 = this.navs[0]
    let subNavs = []
    for (let i = 0; i <= idx; i++) {
      subNavs.push(this.navs[i])
    }

    subNavs = _.drop(subNavs, 1)

    const metaData = _.find(this.onlineTrainService.lvl1MetaDatas, (data) => data.id === nav0.id)

    this.state.go(`onlineTrain.${metaData.prefix}`, {
      parentId: nav0.id,
      editable: this.editable,
      dirs: null,
      prefix: metaData.prefix,
      subNavs
    });
  }

}
