/**
 * @fileOverview - 提供格式化时间的filter
 * @name timespanFilter.js
 * @author pangwa
 * @license
 */
(function() {
  angular.module('app.filters.timespan', []).filter('timespan', timespan);

  function timespan() {
    /**
     * 格式化时间
     * @param {number} timespan - 以毫秒计的时间
     * @param {} props
     * @returns {string}  - 格式化后的时间
     */
    return function(timespan, props) {
      if (!timespan) {
        return '0秒钟';
      }

      timespan = Math.floor(timespan / 1000); // 毫秒->秒

      if (timespan < 60) {
        return timespan + '秒钟';
      }

      var minutes = Math.floor(timespan / 60);
      var seconds = timespan % 60;
      var titleTmpl = _.template('<%= minutes %>分钟<%= seconds %>秒');
      return titleTmpl({
        minutes: minutes,
        seconds: seconds
      });
    };
  }

})();
