import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import * as _ from 'lodash';
import { loadTreeData } from 'app2/utils/materialUtils';

@Component({
  selector: 'app-new-pagination',
  template: require('./new-pagination.component.html'),
  styles: [require('./new-pagination.component.scss')]
})
export class NewPaginationComponent implements OnChanges {

  @Input() totalItems: number;
  @Input() curPage: number;
  @Input() maxSize: number;
  @Input() itemsPerPage: number;

  @Output() ngChange = new EventEmitter<{
    page: number;
  }>();

  numPages: number;

  pageNumberMap: any = {};

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (_.has(changes, 'totalItems')) {
      if (this.totalItems > 0) {
        // 每次查询后将页数重置
        this.ngChange.emit({page: 1})
        this.loadData()
      }
    }
    if (_.has(changes, 'curPage')) {
      if (this.curPage > 0) {
        this.reloadPagesList()
      }
    }
  }

  private reloadPagesList() {
    this.curPage = _.toNumber(this.curPage)
    this.pageNumberMap = {};
    if (this.curPage > 4) {
      _.set(this.pageNumberMap, 'displayLeftElli', true)
    } else {
      _.set(this.pageNumberMap, 'displayLeftElli', false)
    }
    if ((this.numPages - this.curPage) > 4) {
      _.set(this.pageNumberMap, 'displayRightElli', true)
    } else {
      _.set(this.pageNumberMap, 'displayRightElli', false)
    }
    _.set(this.pageNumberMap, 'leftArr', ['1'])
    _.set(this.pageNumberMap, 'rightArr', [`${this.numPages}`])

    const middleArr = []
    if (_.get(this.pageNumberMap, 'displayLeftElli', false) && _.get(this.pageNumberMap, 'displayRightElli', false)) {
      middleArr.push(`${this.curPage - 2}`)
      middleArr.push(`${this.curPage - 1}`)
      middleArr.push(`${this.curPage}`)
      middleArr.push(`${this.curPage + 1}`)
      middleArr.push(`${this.curPage + 2}`)
    } else if (!_.get(this.pageNumberMap, 'displayLeftElli', false) && !_.get(this.pageNumberMap, 'displayRightElli', false)) {
      for (let index = 1; index < this.numPages; index++) {
        if (index > 1 && index < this.numPages) {
          middleArr.push(`${index}`)
        }
      }
    } else if (!_.get(this.pageNumberMap, 'displayLeftElli', false) && _.get(this.pageNumberMap, 'displayRightElli', false)) {
      for (let index = 1; index <= (this.curPage + 1); index++) {
        if (index > 1 && index < this.numPages) {
          middleArr.push(`${index}`)
        }
      }
      while (middleArr.length < 4) {
        const nextPageInMidlle = middleArr.length + 2;
        middleArr.push(nextPageInMidlle)
      }
      const lastInMiddle = middleArr[middleArr.length - 1]
      if ((this.numPages - lastInMiddle) <= 1) {
        _.set(this.pageNumberMap, 'displayRightElli', false)
      }
    } else if (_.get(this.pageNumberMap, 'displayLeftElli', false) && !_.get(this.pageNumberMap, 'displayRightElli', false)) {
      for (let index = (this.curPage - 1); index <= this.numPages; index++) {
        if (index > 1 && index < this.numPages) {
          middleArr.push(`${index}`)
        }
      }

      while (middleArr.length < 4) {
        const headPageInMidlle = middleArr[0] - 1;
        middleArr.unshift(headPageInMidlle)
      }
      const firstInMiddle = middleArr[0]
      if (firstInMiddle <= 2) {
        _.set(this.pageNumberMap, 'displayLeftElli', false)
      }
    }
    _.set(this.pageNumberMap, 'middleArr', middleArr)
  }
  private loadData() {
    this.numPages = _.ceil(this.totalItems / this.itemsPerPage)
    this.reloadPagesList()
  }

  private isCurrentSelected(page) {
    return _.toNumber(page) === this.curPage
  }
  private onChoicePage(page) {
    this.ngChange.emit({page})
  }
  private isFirstDisable() {
    return this.curPage === 1
  }
  private isLastDisable() {
    return this.curPage === this.numPages
  }
  private onChoicePrevPage() {
    const np = _.toNumber(this.curPage) - 1
    this.ngChange.emit({page: np})
  }
  private onChoiceNextPage() {
    const np = _.toNumber(this.curPage) + 1
    this.ngChange.emit({page: np})
  }

}
