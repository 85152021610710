require('assets/styles/answer.scss');
import { isQuickWayToCreatePrepareCourse } from 'app2/prepareCourse/utils/prepareCourseUtils'

angular.module('app.review')
  .controller('testSessionReviewForStudentDialogCtrl', testSessionReviewForStudentDialogCtrl);

  testSessionReviewForStudentDialogCtrl.$inject = ['$scope', '$q', '$timeout', '$uibModalInstance', 'Lightbox',
    '$anchorScroll', '$location', 'oedTestSessionStatForReview', 'oedTestUtils', 'data',
    'oedTestAnswer', 'notify', 'oedUserInfoUtils', 'oedUserInfo', 'notificationService', 'oedObjectiveTest','$uibModal','dialogs']
  function testSessionReviewForStudentDialogCtrl($scope, $q, $timeout, $uibModalInstance, Lightbox,
    $anchorScroll, $location, oedTestSessionStatForReview, oedTestUtils, data,
    oedTestAnswer, notify, oedUserInfoUtils, oedUserInfo, notificationService, oedObjectiveTest,$uibModal,dialogs) {
    'use strict';

    $scope.isQuickPrePareTest = false
    $scope.extraInfo = ''
    $scope.byDevice = (_.get(data.student, 'uid') || _.get(data.student, 'stdId')) ? false : true
    $scope.data = data;
    $scope.isReviewDialog = true;
    $scope.selectDataArr = [{name:'该学生全对',type:1},{name:'该学生全错',type:0}];

    function loadTestSessionStatForReview() {
      return oedTestSessionStatForReview.queryByTestSessionAndStudentId({
        sessionId: data.sessionId,
        userId: $scope.studentId
      }).$promise.then(function(dataForReview) {
        $scope.dataForReview = dataForReview;
        if ($scope.dataForReview.test && isQuickWayToCreatePrepareCourse($scope.dataForReview.test)) {
          $scope.isQuickPrePareTest = isQuickWayToCreatePrepareCourse($scope.dataForReview.test)
           $scope.childQuestionList = $scope.dataForReview.test.questions[0].childQuestions
          $scope.imageRes = $scope.dataForReview.test.questions[0]['resourceUUIDs']
          $scope.quesReviewList = $scope.dataForReview.quesReviewDTOList
        }

        var labels = oedTestUtils.getTestStatLabel($scope.dataForReview.quesReviewDTOList);
        $scope.currentLabels = labels;
        _.forEach($scope.dataForReview.quesReviewDTOList, function(review) {
          parseAnswer(review.answer, review.question.type);
        });

        if ($scope.dataForReview.test.isTemp) {
          return oedObjectiveTest.getObjTestQuestionType({id: $scope.dataForReview.test.id}).$promise.then((r) => {
            if (r && r.testId && r.extraInfo === 'yesorno') {
              $scope.extraInfo = 'yesorno'
            } else {
              $scope.extraInfo = 'singlechoice'
            }
          })
        }
      });
    }

    function parseAnswer(answer, quesType) {
      if (!_.isEmpty(answer)) {
        if (!_.isEmpty(answer.answerDetails))
          answer.analysis = JSON.parse(answer.answerDetails);
        else
          answer.analysis = [];
        if (quesType == 'qa') {
          if (!_.isEmpty(answer.answer)) {
            if ( data.isDaoxuean) {
              const answerJson = JSON.parse(answer.answer);
              const arr = []
              _.each(answerJson.items, item=> {
                const commentData = _.filter(answerJson.comment, r => r.relatedResourceId == item.id);
                if (commentData.length > 0) {
                  arr.push(commentData[0]);
                } else {
                  arr.push(item);
                }
              });
              answerJson.items = arr;
              answer.answerItems = answerJson;
            } else {
              answer.answerItems = JSON.parse(answer.answer);
            }
          } else {
            answer.answerItems = {
              items: []
            };
          }
        } else if (quesType == 'fill') {
          if (!_.isEmpty(answer.answer)) {
            answer.answerFill = JSON.parse(answer.answer);
            _.forEach(answer.answerFill.images, image => {
              image.fileType = 'image'
            })
          }
        }
      }
    }


    if (!$scope.byDevice) {
      $scope.studentId = _.get(data.student, 'uid') ? _.get(data.student, 'uid') : _.get(data.student, 'stdId')

      $scope.stdInfo = oedUserInfo.getByUid({
        id: $scope.studentId
      });


      $scope.loading = $q.all([loadTestSessionStatForReview(), $scope.stdInfo.$promise]);

      $scope.getStdAvatarImgSrc = oedUserInfoUtils.getStdAvatarImgSrc;
      $scope.getAnswerText = oedTestUtils.getAnswerText;

      $scope.dirty = false;

      $scope.onQuesReviewed = function (event, args) {
        $scope.dirty = true;
      };

      $scope.$on('fillQuesReviewed', function (question, answer) {
        loadTestSessionStatForReview();
        $scope.dirty = true;
      });

      $scope.canRestart = function () {
        const now = Date.now()
        // 只有作业场景,作业未结束且已完成的学生可以点击重做
        return !$scope.data.fromClassSession &&
          _.get($scope.data.publishRecordOfSelectedClass, 'dueDate', now) > now &&
          data.stuList && data.stuList.length > 0
      }

      $scope.isSynthetic = function (index) {
        if (_.size($scope.currentLabels) > index)
          return _.indexOf($scope.currentLabels[index], '.') >= 0;
        return false;
      }

      $scope.isNeedBorderBottom = function (index) {
        const nextIndex = index + 1;
        return $scope.getParentIndex(index) !== $scope.getParentIndex(nextIndex);
      }

      $scope.restartTestSession = function () {
        const dlg = dialogs.confirm('确定打回重做?', '确定让这位学生打回重做吗?');
        dlg.result.then(function() {
          console.log(`testSessionId: ${data.sessionId} ,studentId: ${$scope.studentId}`)
          return oedObjectiveTest.restartTestSessionByTestSessionIdAndStudentId({
            testSessionId: data.sessionId,
            studentId: $scope.studentId
          }, {}).$promise.then((r) => {
            $scope.dirty = true
            $scope.close()
          })
        });
      }

      $scope.isFirstChild = function (index) {
        if (_.size($scope.currentLabels) > index)
          return _.endsWith($scope.currentLabels[index], '.1');
        return false;
      };
      $scope.currentLabels = [];


      $scope.getLabel = function (index) {
        if (_.size($scope.currentLabels) > index)
          return $scope.currentLabels[index];
        return '';
      };
      $scope.shouldShowQa = function (review) {
        if (!review.hasOwnProperty('answer')) { // eslint-disable-line

          return false;
        } else {
          return review.question.type == 'qa' && review.answer !== null && !_.isEmpty(review.answer.answer);
        }
      };
      $scope.reviewQa = function (review, correct) {
        if (_.isNil(review.answer)) {
          notificationService.notify('error', '该学生还未提交本题答案')
          return Promise.reject('unsumbit-answer');
        }
        var doreview = $scope.doReviewQa(review, correct);
        return doreview;
      };

      $scope.showFillSubmitImages = function (review) { // eslint-disable-line
        if (!review.hasOwnProperty('answer')) { // eslint-disable-line
          return false;
        } else {
          return review.question.type == 'fill' && _.get(review, 'answer.answerFill') &&
            _.get(review, 'answer.answerFill.images') &&
            _.size(_.get(review, 'answer.answerFill.images')) > 0
        }
      }
      $scope.checkAnswerStatus = (res, data) => {
        const wrongAnswerData = _.some(data, item => item.correct == 0 || item.correct == 2);
        if (!wrongAnswerData) {
          return false;
        } else {
          const arr = _.sortBy(data, item => -item.submitIndex);
          const maxSubmitIndex = arr[0].submitIndex;
          const curAnswerSubmitIndex = res.submitIndex;
          if (curAnswerSubmitIndex && curAnswerSubmitIndex == maxSubmitIndex && (res.correct == -1 || res.correct === undefined)) {
            return true;
          } else {
            return false;
          }
        }
      }
      $scope.doReviewQa = function (review, correct) {
        return oedTestAnswer.reviewQaAnswer({
          testSessionId: $scope.data.sessionId,
          studentId: $scope.studentId,
          questionId: review.question.id,
          correctDetails: correct
        }, {
          testSessionId: $scope.data.sessionId,
          studentId: $scope.studentId,
          questionId: review.question.id,
          correctDetails: correct
        }).$promise.then(function (answer) {
          loadTestSessionStatForReview();
          $scope.dirty = true;
        }).catch(function () {
          notificationService.notify('error', '评判失败')
        });
      }

      $scope.reviewQaClear = function (review) {
        $scope.loading = oedTestAnswer.clearQaReview({
          testSessionId: $scope.data.sessionId,
          studentId: $scope.studentId,
          questionId: review.question.id
        }, {
          testSessionId: $scope.data.sessionId,
          studentId: $scope.studentId,
          questionId: review.question.id
        }).$promise.then(function (answer) {
          loadTestSessionStatForReview();
          $scope.dirty = true;
        }).catch(function () {
          notificationService.notify('error', '清除评判失败')
        });
      };
      $scope.submitQaPoint = function (review) {
        var point = review.answer.point2;

        point = parseFloat(point);
        if (_.isNaN(point) || point < 0) {
          notificationService.notify('error', '分数非法')
          return Promise.reject(new Error('illegal score'));
        }

        if (point > review.question.point2) {
          notificationService.notify('error', '该题的最大分值为' + review.question.point2)
          return Promise.reject(new Error('illegal score'));
        }

        return oedTestAnswer.scoreQaAnswer({
          testSessionId: $scope.data.sessionId,
          studentId: $scope.studentId,
          questionId: review.question.id,
          point: point
        }, {
          testSessionId: $scope.data.sessionId,
          studentId: $scope.studentId,
          questionId: review.question.id,
          point: point
        }).$promise.then(function (answer) {
          loadTestSessionStatForReview();
          $scope.dirty = true;
          notificationService.notify('info', '打分成功')
        }).catch(function () {
          notificationService.notify('error', '打分失败')
          return Promise.reject(new Error('illegal score'));
        });
      };
      $scope.shouldShowAnalysis = function (review) {
        return _.chain(review).get('answer.analysis', []).size().value() > 0
      };

      $scope.previewContentResource = function (curRes, answer) {
        var index = _.findIndex(answer.analysis, curRes);
        var newRes = _.map(answer.analysis, function (res) {
          return {
            id: res.id,
            fileType: 'image',
            resourceName: res.id + '.jpg'
          };
        });
        $uibModal.open({
          controller: 'objStudentAnswerViewCtrl',
          resolve: {
            data: () => {
              return {
                index: index,
                allResources: newRes,
              };
            },
          },
          size: 'lg',
          template: require('assets/templates/review/objStudentAnswerView.html'),
          windowClass: 'modalCenter',
        });
      };
      $scope.changeSelect = (item) => {
        if (item.type === 1) {
          oedTestSessionStatForReview.batchUnReviewQuestin({
            testSessionId: data.sessionId,
            studentId: $scope.studentId, type: 1
          }, {}).$promise.then(res => {
            if (res && res.length != 0) {
              notificationService.notify('info', '评判成功');
              loadTestSessionStatForReview();
              $scope.dirty = true;
            } else {
              const dlg = dialogs.confirm('整体批阅', '所有的题目已阅,是否要继续将题目批为全对?');
              dlg.result.then(r => {
                oedTestSessionStatForReview.batchTest({
                  testSessionId: data.sessionId,
                  studentId: $scope.studentId, type: 1
                }, {}).$promise.then(r => {
                  notificationService.notify('info', '评判成功');
                  loadTestSessionStatForReview();
                  $scope.dirty = true;
                }).catch(() => {
                  notificationService.notify('error', '评判失败');
                })
              })
            }
          }).catch(() => {
            notificationService.notify('error', '评判失败');
          })
        } else {
          oedTestSessionStatForReview.batchUnReviewQuestin({
            testSessionId: data.sessionId,
            studentId: $scope.studentId, type: 0
          }, {}).$promise.then(res => {
            if (res && res.length != 0) {
              notificationService.notify('info', '评判成功');
              loadTestSessionStatForReview();
              $scope.dirty = true;
            } else {
              const dlg = dialogs.confirm('整体批阅', '所有的题目已阅,是否要继续将题目批为全错?');
              dlg.result.then(r => {
                oedTestSessionStatForReview.batchTest({
                  testSessionId: data.sessionId,
                  studentId: $scope.studentId, type: 0
                }, {}).$promise.then(r => {
                  notificationService.notify('info', '评判成功');
                  loadTestSessionStatForReview();
                  $scope.dirty = true;
                }).catch(() => {
                  notificationService.notify('error', '评判失败');
                })
              })
            }
          }).catch(() => {
            notificationService.notify('error', '评判失败');
          });
        }
      };

      $scope.previewQaAnswer = function (curRes, answer, titleIndex) {
        var idx = _.findIndex(answer.answerItems.items, curRes);
        const titleList = [];
        function handleTitleIndex(arr) {
          for (let index = 0; index < arr.length; index++) {
            if (arr[index].childQuestions) {
              const num = arr[index].childQuestions.length
              for (let index1 = 0; index1 < num; index1++) {
                const finaNum = (index + 1) + '.' + (index1 + 1)
                titleList.push(finaNum);
              }
            } else {
              titleList.push(index + 1);
            }
          }
        }
        if (data.stuList && data.stuList.length > 0 && data.isDaoxuean) {
          handleTitleIndex($scope.dataForReview.test.questions);
          const modalInstance = $uibModal.open({
            controller: 'testInteractDetailsViewerCtrl',
            resolve: {
              data: () => {
                return {
                  index: idx,
                  titleList: titleList,
                  titleIndex: titleIndex,
                  curStu: data.student,
                  questionId: answer.questionId,
                  sessionId: data.sessionId,
                  quesReviewDTOList: $scope.dataForReview.quesReviewDTOList,
                  answerItems: answer.answerItems.items,
                  answer: answer,
                  stuList: data.stuList ? data.stuList : []
                };
              },
            },
            size: 'fullwidth without-bg',
            template: require('assets/templates/review/testInteractDetailsViewer.html'),
            windowClass: 'oedmodal modal-without-bg modalCenter',
          });
          modalInstance.result.then(r => {
            loadTestSessionStatForReview();
            $scope.dirty = true;
          })
        } else {
          var index = _.findIndex(answer.answerItems.items, curRes);
          $uibModal.open({
            controller: 'objStudentAnswerViewCtrl',
            resolve: {
              data: () => {
                return {
                  index: index,
                  allResources: answer.answerItems.items,
                };
              },
            },
            size: 'lg',
            template: require('assets/templates/review/objStudentAnswerView.html'),
            windowClass: 'modalCenter',
          });
        }
      };

      $scope.previewFillSubmitImage = function (curRes, answer) {
        var idx = _.findIndex(answer.answerFill.images, curRes);
        Lightbox.openModal(answer.answerFill.images, idx);
      }
      $scope.getRelatedSectionNames = function (child) {
        var question = _.find($scope.dataForReview.test.questions, function (q) {
          return q.id == child.parentQuestionId;
        });
        if (_.isEmpty(question.relatedSections)) {
          return '无';
        }

        return _(question.relatedSections).map(function (sec) {
          return sec.sectionName;
        }).join(', ');
      };

      $scope.getChoiceName = oedTestUtils.getChoiceName;
      $scope.getParentIndex = function (index) {
        if (_.size($scope.currentLabels) > index) {
          var num = _.split($scope.currentLabels[index], '.', 1)[0] - 1;
          return num;
        }
      };
      $scope.$on('domLoaded', function (domLoadedEvent) {
        var toIndex = _.findIndex($scope.currentLabels, function (idx) { return idx == data.index; });
        $anchorScroll('question_' + toIndex);
      });
    } else {
      const deviceId = data.student.deviceUUID
      $scope.loading = oedTestSessionStatForReview.getStudentAnsersByDevice({ testSessionId: data.sessionId }, { deviceId }).$promise
        .then((result) => {
          const target = _.sortBy(result, 'questionId')
          if (data.objTest && isQuickWayToCreatePrepareCourse(data.objTest) && target) {
            $scope.isQuickPrePareTest = isQuickWayToCreatePrepareCourse(data.objTest)
            $scope.childQuestionList = data.objTest.questions[0].childQuestions
            $scope.imageRes = data.objTest.questions[0]['resourceUUIDs']
            $scope.quesReviewList = []
            _.forEach(target, r => {
              const obj = {
                answer: r
              }
              $scope.quesReviewList.push(obj)
            })
            // 未完成 未绑定学生的答题器答题
            if (_.size($scope.childQuestionList) > _.size(target)) {
              for (let i = 0; i < (_.size($scope.childQuestionList) - _.size(target)); i++) {
                const obj = {
                  answer: ''
                }
                $scope.quesReviewList.push(obj)
              }
            }
            if (data.objTest.isTemp) {
              return oedObjectiveTest.getObjTestQuestionType({ id: data.objTest.id }).$promise.then((r) => {
                if (r && r.testId && r.extraInfo === 'yesorno') {
                  $scope.extraInfo = 'yesorno'
                } else {
                  $scope.extraInfo = 'singlechoice'
                }
              })
            }
          }
        })
    }

    $scope.close = function() {
      $uibModalInstance.close($scope.dirty);
    };

  }
