/**
 * @fileOverview
 * @name questionAnswerDetailsCtrl.js
 * @author pangwa
 * @license
 */

'use strict';
import { isQuickWayToCreatePrepareCourse } from 'app2/prepareCourse/utils/prepareCourseUtils'
import * as _ from "lodash";

(function() {
  angular.module('app.review')
    .controller('questionAnswerDetailsCtrl', questionAnswerDetailsCtrl)
    .directive('quesStuList', quesStuList);

  questionAnswerDetailsCtrl.$inject = [
    '$scope',
    '$stateParams',
    '$uibModal',
    '$q',
    '$timeout',
    '$uibModalInstance',
    'oedTestSession',
    'data',
    '$state',
    'oedObjectiveTest',
    'oedTestQuestionStats'
  ]
  function questionAnswerDetailsCtrl(
    $scope,
    $stateParams,
    $uibModal,
    $q,
    $timeout,
    $uibModalInstance,
    oedTestSession,
    data,
    $state,
    oedObjectiveTest,
    oedTestQuestionStats
  ) {

    $scope.data = data;
    // 投票题不显示人数统计tab 快速备课下无投票题
    if(_.get($scope.data, 'questionType') === 'singlevote') {
      $scope.isSinglevote = true;
    } else if (!_.isEmpty(_.get($scope.data, 'objTest', {})) &&
        !isQuickWayToCreatePrepareCourse($scope.data.objTest) &&
        !_.isEmpty(_.get($scope.data.objTest, 'questions', []))
      ) {
        const question = _.find(_.get($scope.data.objTest, 'questions', []), (q) => q.id === data.questionId);
        $scope.isSinglevote = !_.isEmpty(question) ? (question.type === 'singlevote' || question.type === 'multivote') : false;
    } else {
        $scope.isSinglevote = false;
    }

    const choiceLabels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    if (data.sessionType === 1) {
      loadRemoteVotingQestionDetail()
    } else {
      loadNormalQuestionDetail()
    }

    function loadRemoteVotingQestionDetail () {
      const qSinglevote = false
      $scope.deviceStat = _.cloneDeep(data.deviceStat.questionStats)
      $scope.students = data.students

      $scope.remoteVotingDetails = oedTestSession.getRemoteVotingAnswerStat({
        testSessionId: data.sessionId,
        questionId: data.questionId
      })

      $scope.answerStat = oedTestSession.getRemoteVotingAnswerStatByOption({
        testSessionId: data.sessionId,
        questionId: data.questionId
      })

      function getUnAsnsweredValues() {
        return _.size($scope.choiceAnswerList[$scope.choiceAnswerList.length - 1]);
      }

      function buildMultibarChartDataFromArray(barData) {
        const allLabels = _.map(barData, function(bar) {
          return bar.label;
        });
        const data = _.map(barData, function(bar) {
          const values = _.map(allLabels, function(lb) {
            if (lb == bar.label) {
              return {
                label: bar.label,
                value: bar.value
              };
            }
            return {
              label: lb,
              value: 0
            };
          });
          return values;
        });
        return data;
      }
      $scope.optStatTypes = {
        yesorno: {
          numBars: function() {
            return 3;
          },
          prepareData: function(currentQuestion) {
            let yesColor = isQuickWayToCreatePrepareCourse($scope.data.objTest) ? (currentQuestion.question.answer == '0' ? '#469e24' : '#a70d0d') : (currentQuestion.question.answer == '1' ? '#469e24' : '#a70d0d')
            let noColor = isQuickWayToCreatePrepareCourse($scope.data.objTest) ? (currentQuestion.question.answer == '1' ? '#469e24' : '#a70d0d') : (currentQuestion.question.answer == '0' ? '#469e24' : '#a70d0d')
            // yesColor = qSinglevote ? '#469e24' : yesColor
            // noColor = qSinglevote ? '#469e24' : noColor
            if (currentQuestion.question.answer == '-1') {
              noColor = '#469e24'
              yesColor = '#469e24'
            }
            var data = [{
              'key': '是',
              values: [],
              color: yesColor
            }, {
              key: '否',
              color: noColor,
              values: []
            }, {
              key: '未做答',
              color: '#a70d0d',
              values: []
            }];
            var barData = [{
              label: '是',
              value: _.size($scope.choiceAnswerList[0])
            }, {
              label: '否',
              value: _.size($scope.choiceAnswerList[1])
            }, {
              label: '未做答',
              value: getUnAsnsweredValues()
            }];
            var values = buildMultibarChartDataFromArray(barData);
            _.each(data, function(d, idx) {
              d.values = values[idx];
            });
            return data;
          },
          getStudentListOfAnswer: function(details, data) {
            return $scope.choiceAnswerList[data.index];
          }
        },
        singlechoice: {
          numBars: function(currentQuestion) {
            return _.size(currentQuestion.question.choices) + 1;
          },
          prepareData: function(currentQuestion) {
            const data = _.map(currentQuestion.question.choices, function(c, idx) {
              var color = '#469e24';
              if (!qSinglevote && idx != currentQuestion.question.answer) {
                color = '#a70d0d';
              }
              return {
                key: choiceLabels[idx],
                color: color
              };
            });
            data.push({
              key: '未做答',
              color: '#a70d0d'
            });
            var bars = _.map(currentQuestion.question.choices, function(c, idx) {
              var lbl = choiceLabels[idx];
              var count = _.size($scope.choiceAnswerList[idx]);
              return {
                label: lbl,
                value: count
              };
            });
            bars.push({
              label: '未做答',
              value: getUnAsnsweredValues()
            });
            var values = buildMultibarChartDataFromArray(bars);
            _.each(data, function(d, idx) {
              d.values = values[idx];
            });
            return data;
          },
          getStudentListOfAnswer: function(details, data) {
            return $scope.choiceAnswerList[data.index];
          }
        },
        singlevote: {
          numBars: function(currentQuestion) {
            return _.size(currentQuestion.question.choices) + 1;
          },
          prepareData: function(currentQuestion) {
            const data = _.map(currentQuestion.question.choices, function(c, idx) {
              var color = '#469e24'; //绿色
              if (currentQuestion.question.type != 'singlevote' && idx != currentQuestion.question.answer) {
                color = '#a70d0d';
              }
              return {
                key: choiceLabels[idx],
                color: color
              };
            });
            data.push({
              key: '未做答',
              color: '#a70d0d'
            });
            var bars = _.map(currentQuestion.question.choices, function(c, idx) {
              var lbl = choiceLabels[idx];
              var count = _.size($scope.choiceAnswerList[idx]);
              return {
                label: lbl,
                value: count
              };
            });
            bars.push({
              label: '未做答',
              value: getUnAsnsweredValues()
            });
            var values = buildMultibarChartDataFromArray(bars);
            _.each(data, function(d, idx) {
              d.values = values[idx];
            });
            return data;
          },
          getStudentListOfAnswer: function(details, data) {
            return $scope.choiceAnswerList[data.index];
          }
        }
      };

      function selectedDataByType(details, type, currentQuestion) {
        const numBars = $scope.optStatTypes[type].numBars(currentQuestion);
        $scope.optDetails = $scope.optStatTypes[type].prepareData(currentQuestion);
        $scope.barChartOptions.chart.height = 100 + numBars * 60;
        const e = {};
        const headOptDetail = _.head($scope.optDetails);
        e.data = _.head(headOptDetail.values);
        e.index = 0;
        e.data.key = headOptDetail.key;
        selectBar(e, true);
      }

      $scope.loading = $scope.answerStat.$promise.then((res) => {
        if (res) {
          $scope.choiceAnswerList = res
        }
      }).then(() => {
        return $scope.remoteVotingDetails.$promise.then(function(details) {
          const idx = _.findIndex($scope.deviceStat, r => r.questionId === data.questionId)
          $scope.optionsType = _.get($scope.deviceStat[idx], 'question.type')
          $scope.currentQuestion = $scope.deviceStat[idx]
          $scope.hesitateOrCorrectAndWrong = details.reviseUsers
          $scope.details = details
          selectedDataByType(details, $scope.optionsType, $scope.currentQuestion)
        })
      })

      $scope.barChartOptions = {
        'chart': {
          type: 'multiBarHorizontalChart',
          height: 100,
          showControls: false,
          showValues: true,
          stacked: true,
          groupSpacing: 0.4,
          noData: '没有数据',
          valueFormat: d3.format('d'),
          'transitionDuration': 500,
          'xAxis': {
            'showMaxMin': false
          },
          x: function(d) {
            return d.label;
          },
          y: function(d) {
            return d.value;
          },
          yAxis: {
            axisLabel: '人数',
            tickFormat: function(d) {
              return d3.format('d')(d);
            }
          },
          multibar: {
            dispatch: {
              elementClick: function(e) {
                selectBar(e);
              }
            }
          }
        }
      };

      function selectBar(e, noApply) {
        const optName = e.data.label;
        const optCount = e.data.value;
        const optKey = e.data.key;
        let title = `选项"${optName}"的人数: ${optCount}'`;
        if (optName == '未做答') {
          title = `未做答的人数: ${optCount}`;
        }

        if (noApply) {
          updateSelectedBar(title, e, optKey);
        } else {
          $scope.$apply(function() {
            updateSelectedBar(title, e, optKey);
          });
        }
      }

      function updateSelectedBar(title, e, key) {
        $scope.selectedBarTitle = title;
        $scope.selectedStudents = $scope.optStatTypes[$scope.optionsType]
          .getStudentListOfAnswer($scope.details, e);
      }

      $scope.optDetails = [];
      $scope.triggerResize = function() {
        $timeout(function() {
          window.dispatchEvent(new Event('resize'));
        }, 0);
      };

      $scope.showStudentDetails = function(stu) {
        const modalInstance = $uibModal.open({
          template: require('assets/templates/review/testSessionReviewForStudentDialog.html'),
          controller: 'testSessionReviewForStudentDialogCtrl',
          windowClass: 'ques-detail-model modalCenter',
          size: 'lg',
          resolve: {
            data: function() {
              return {
                sessionId: $stateParams.sessionId || data.sessionId,
                student: stu,
                studentName: stu.name,
                index: data.index,
                objTest: data.objTest,
                stuList: data.studentsOfFinish,
                isDaoxuean:($scope.currentTest.testDisplayType && $scope.currentTest.testDisplayType == 1) ? true :false
              };
            }
          }
        })
      }
    }

    function loadNormalQuestionDetail () {
    let qSinglevote = false
    $scope.isFromTask = data.fromTask;
    $scope.details = oedTestQuestionStats.queryBySessoinAndQuestionId({
      sessionId: data.sessionId,
      quesId: data.questionId,
      isRevise: true
    });

    function getUnAsnsweredValues(details) {
      return _.size(details.unAnswered);
    }

    /**
     * @param {array} datas - 数组中的元素为每一条bar的值
     * @returns {array} - 用户multibar的数据结果, 其他项都补为0
     */
    function buildMultibarChartDataFromArray(barData) {
      var allLabels = _.map(barData, function(bar) {
        return bar.label;
      });

      var data = _.map(barData, function(bar) {
        var values = _.map(allLabels, function(lb) {
          if (lb == bar.label) {
            return {
              label: bar.label,
              value: bar.value
            };
          }
          return {
            label: lb,
            value: 0
          };
        });
        return values;
      });

      return data;
    }

    $scope.optStatTypes = {
      yesorno: {
        numBars: function(details) {
          return 3;
        },
        prepareData: function(details) {
          var yesColor = isQuickWayToCreatePrepareCourse($scope.details.test) ? (details.ques.answer == '0' ? '#469e24' : '#a70d0d') : (details.ques.answer == '1' ? '#469e24' : '#a70d0d')
          var noColor = isQuickWayToCreatePrepareCourse($scope.details.test) ? (details.ques.answer == '1' ? '#469e24' : '#a70d0d') : (details.ques.answer == '0' ? '#469e24' : '#a70d0d')

          // yesColor = qSinglevote ? '#469e24' : yesColor
          // noColor = qSinglevote ? '#469e24' : noColor

          if (details.ques.answer == '-1') {
            noColor = '#a70d0d'
            yesColor = '#a70d0d'
          }

          var data = [{
            'key': '是',
            values: [],
            color: yesColor //color - optional: choose your own line color.
          }, {
            key: '否',
            color: noColor,
            values: []
          }, {
            key: '未做答',
            color: '#a70d0d',
            values: []
          }];

          var barData = [{
            label: '是',
            value: isQuickWayToCreatePrepareCourse($scope.details.test) ? _.size(details.choiceAnswers['0']) : _.size(details.choiceAnswers['1'])
          }, {
            label: '否',
            value: isQuickWayToCreatePrepareCourse($scope.details.test) ? _.size(details.choiceAnswers['1']) : _.size(details.choiceAnswers['0'])
          }, {
            label: '未做答',
            value: getUnAsnsweredValues(details)
          }];

          var values = buildMultibarChartDataFromArray(barData);

          _.each(data, function(d, idx) {
            d.values = values[idx];
          });

          return data;
        },
        getStudentListOfAnswer: function(details, data) {
          //
          // 未做答的人数
          if (data.index >= 2) {
            return details.unAnswered;
          }
          if (data.index === 0) {
            return isQuickWayToCreatePrepareCourse($scope.details.test) ? details.choiceAnswers['0'] : details.choiceAnswers['1']
          }
          return isQuickWayToCreatePrepareCourse($scope.details.test) ? details.choiceAnswers['1'] : details.choiceAnswers['0']
        }
      },
      singlechoice: {
        numBars: function(details) {
          return _.size(details.ques.choices) + 1;
        },
        prepareData: function(details) {
          var data = _.map(details.ques.choices, function(c, idx) {
            var color = '#469e24'; //绿色
            if (idx != details.ques.answer && details.ques.answer !== '-1') {
              color = '#a70d0d';
            }

            return {
              key: choiceLabels[idx],
              color: color
            };
          });
          data.push({
            key: '未做答',
            color: '#a70d0d'
          });

          var bars = _.map(details.ques.choices, function(c, idx) {
            var lbl = choiceLabels[idx];
            var count = _.size(details.choiceAnswers[idx]);
            return {
              label: lbl,
              value: count
            };
          });

          bars.push({
            label: '未做答',
            value: getUnAsnsweredValues(details)
          });

          var values = buildMultibarChartDataFromArray(bars);
          _.each(data, function(d, idx) {
            d.values = values[idx];
          });

          return data;
        },
        getStudentListOfAnswer: function(details, data) {
          //
          // 未做答的人数
          if (data.data.label == '未做答') {
            return details.unAnswered;
          }
          return details.choiceAnswers[data.index];
        }
      },
      multichoice: {
        numBars: function(details) {
          return _.size(details.ques.choices) + 1;
        },
        prepareData: function(details) {
          var answers = _.split(details.ques.answer, ',');
          var data = _.map(details.ques.choices, function(c, idx) {
            var color = '#469e24'; //绿色
            if (_.indexOf(answers, String(idx)) < 0) {
              color = '#a70d0d';
            }
            return {
              key: choiceLabels[idx],
              color: color
            };
          });
          data.push({
            key: '未做答',
            color: '#a70d0d'
          });

          var bars = _.map(details.ques.choices, function(c, idx) {
            var lbl = choiceLabels[idx];
            var count = _.size(details.choiceAnswers[idx]);
            return {
              label: lbl,
              value: count
            };
          });

          bars.push({
            label: '未做答',
            value: getUnAsnsweredValues(details)
          });

          var values = buildMultibarChartDataFromArray(bars);
          _.each(data, function(d, idx) {
            d.values = values[idx];
          });

          return data;
        },
        getStudentListOfAnswer: function(details, data) {
          //
          // 未做答的人数
          if (data.data.label == '未做答') {
            return details.unAnswered;
          }
          return details.choiceAnswers[data.index];
        }
      },
      singlevote: {
        numBars: function(details) {
          return _.size(details.ques.choices) + 1;
        },
        prepareData: function(details) {
          var data = _.map(details.ques.choices, function(c, idx) {
            var color = '#469e24'; //绿色
            if (details.ques.type != 'singlevote' && idx != details.ques.answer) {
              color = '#a70d0d';
            }

            return {
              key: choiceLabels[idx],
              color: color
            };
          });
          data.push({
            key: '未做答',
            color: '#a70d0d'
          });

          var bars = _.map(details.ques.choices, function(c, idx) {
            var lbl = choiceLabels[idx];
            var count = _.size(details.choiceAnswers[idx]);
            return {
              label: lbl,
              value: count
            };
          });

          bars.push({
            label: '未做答',
            value: getUnAsnsweredValues(details)
          });

          var values = buildMultibarChartDataFromArray(bars);
          _.each(data, function(d, idx) {
            d.values = values[idx];
          });

          return data;
        },
        getStudentListOfAnswer: function(details, data) {
          //
          // 未做答的人数
          if (data.data.label == '未做答') {
            return details.unAnswered;
          }
          return details.choiceAnswers[data.index];
        }
      },
      multivote: {
        numBars: function(details) {
          return _.size(details.ques.choices) + 1;
        },
        prepareData: function(details) {
          var data = _.map(details.ques.choices, function(c, idx) {
            var color = '#469e24'; //绿色
            if (details.ques.type != 'multivote' && idx != details.ques.answer) {
              color = '#a70d0d';
            }

            return {
              key: choiceLabels[idx],
              color: color
            };
          });
          data.push({
            key: '未做答',
            color: '#a70d0d'
          });

          var bars = _.map(details.ques.choices, function(c, idx) {
            var lbl = choiceLabels[idx];
            var count = _.size(details.choiceAnswers[idx]);
            return {
              label: lbl,
              value: count
            };
          });

          bars.push({
            label: '未做答',
            value: getUnAsnsweredValues(details)
          });

          var values = buildMultibarChartDataFromArray(bars);
          _.each(data, function(d, idx) {
            d.values = values[idx];
          });

          return data;
        },
        getStudentListOfAnswer: function(details, data) {
          //
          // 未做答的人数
          if (data.data.label == '未做答') {
            return details.unAnswered;
          }
          return details.choiceAnswers[data.index];
        }
      },
      fill: {
        title: '按空统计',
        numBars: function(details) {
          const q = _.get(details, 'ques.question', '');
          return _.size(q.match(/_{2,}/g));
        },
        prepareData: function(details) {
          const numBars = this.numBars(details);
          /**
             * 构成每个学生每个空的状态
             * [
             // student 123
             [{ //空一
             uid: 123,
             correct: true,
             hesitate: true
             }, { //...空二
             }],
             // student ...
             ]
             **/
          const answerDetails = _.map(details.answerList, parseAnswerDetails);
          const historyAnswerDetails = details.testAnswerHistoryList ? _.map(details.testAnswerHistoryList, parseAnswerDetails) : [];

          /**
           * 转换成按空统计的信息
           * { //空一
           * correct: {
              uids: []
              count: 12 //正确数
           }
           * }
          **/
          const countBy = (arr, cond) => _.reduce(arr, (total, ar) => {
            return _.reduce(ar, (t, v, idx) => {
              if (idx >= numBars) {
                console.error('idx > numBars');
                return t;
              }
              if (cond(v, idx)) {
                const counter = t[idx];
                const newV = {
                  count: counter.count + 1,
                  uids: counter.uids.concat(v.uid)
                };

                return t.slice(0, idx).concat([newV]).concat(t.slice(idx + 1));
              }
              return t;
            }, total);
          }, _.fill(Array(numBars), {
            count: 0,
            uids: []
          }));

          let corrects = [];
          let hesitates = [];
          let wrongs = [];
          let revises = [];
          if (!_.size(historyAnswerDetails)) {
            corrects = countBy(answerDetails, (v) => v.correct && !v.hesitate);
            hesitates = countBy(answerDetails, (v) => v.correct && v.hesitate);
            wrongs = countBy(answerDetails, (v) => !v.correct);
          } else {
            corrects = countBy(answerDetails, (v, index) => v.correct && !v.hesitate && historyAnswerDetails[_.size(historyAnswerDetails) - 1][index].correct);
            hesitates = countBy(answerDetails, (v, index) => v.correct && v.hesitate);
            wrongs = countBy(answerDetails, (v, index) => !v.correct);
            revises = countBy(answerDetails, (v, index) => v.correct && !historyAnswerDetails[_.size(historyAnswerDetails) - 1][index].correct);
          }

          // TODO: 错误数量应当再加上没有回答的学生?
          const toDataValues = (arr) => _.map(arr, (v, idx) => ({
            label: '第' + (idx + 1) + '空',
            value: v.count,
            uids: v.uids
          }));

          const correctValues = toDataValues(corrects);
          const hesitateValues = toDataValues(hesitates);
          const wrongValues = toDataValues(wrongs);
          if (!_.size(historyAnswerDetails)) {
            return [{
              color: '#469e24',
              key: '正确',
              values: correctValues
            }, {
              color: '#f0c46e',
              key: '犹豫',
              values: hesitateValues
            },{
              color: '#a70d0d',
              key: '错误',
              values: wrongValues
            }];
          } else {
            const reviseValues = toDataValues(revises);
            return [{
              color: '#469e24',
              key: '正确',
              values: correctValues
            }, {
              color: '#f0c46e',
              key: '犹豫',
              values: hesitateValues
            }, {
              key: '订正',
              color: '#d56215',
              values: reviseValues
            }, {
              color: '#a70d0d',
              key: '错误',
              values: wrongValues
            }];
          }
        },
        getStudentListOfAnswer: function(details, data) {
          // 由于stats api返回了所有学生信息
          // 使用这些学生信息, 就不再加载了
          const allStds = _.concat(details.correctAndHesitateList,
                                 details.correctAndWrongList,
                                 details.correctNotHesitateList,
                                 details.pendingReviewList,
                                 details.wrongList,
                                 details.correctWithReviseList);
          const stdByKey = _.keyBy(allStds, 'uid');
          const uids = _.get(data, 'data.uids', []);

          return _.chain(uids).map((id) => _.get(stdByKey, id)).filter().value();
        }
      }
    };

    function selectedDataByType(details, type) {
      const numBars = $scope.optStatTypes[type].numBars(details);
      $scope.optDetails = $scope.optStatTypes[type].prepareData(details);
      $scope.barChartOptions.chart.height = 100 + numBars * 60;
      const e = {};
      const headOptDetail = _.head($scope.optDetails);
      e.data = _.head(headOptDetail.values);
      e.index = 0;
      e.data.key = headOptDetail.key;
      selectBar(e, true);
    }

    $scope.details.$promise.then(function(details) {
      $scope.currentDetail = details
      $scope.currentTest = oedObjectiveTest.get({
        id: details.test.id
      });
      if (details.correctWithReviseList) {
        _.each(details.correctWithReviseList, item => {
          item.isRevision = true;
        })
      }
      $scope.hesitateOrCorrectAndWrong = details.correctAndHesitateList.concat(details.correctAndWrongList).concat(details.correctWithReviseList);
      if (!$scope.optStatTypes[details.ques.type]) {
        return;
      }

      if (details.test.isTemp && isQuickWayToCreatePrepareCourse(details.test)) {
        return oedObjectiveTest.getObjTestQuestionType({id: $scope.details.test.id}).$promise.then((r) => {
          qSinglevote = true
          if (r && r.testId && r.extraInfo === 'yesorno') {
            selectedDataByType(details, 'yesorno')
          } else {
            selectedDataByType(details, 'singlechoice')
          }
        })
      } else {
        selectedDataByType(details, details.ques.type)
      }
    })

    $scope.loading = $scope.details.$promise;

    $scope.barChartOptions = {
      'chart': {
        type: 'multiBarHorizontalChart',
        height: 100,
        showControls: false,
        showValues: true,
        stacked: true,
        groupSpacing: 0.4,
        noData: '没有数据',
        valueFormat: d3.format('d'),
        'transitionDuration': 500,
        'xAxis': {
          'showMaxMin': false
        },
        x: function(d) {
          return d.label;
        },
        y: function(d) {
          return d.value;
        },
        yAxis: {
          axisLabel: '人数',
          tickFormat: function(d) {
            return d3.format('d')(d);
          }
        },
        multibar: {
          dispatch: {
            elementClick: function(e) {
              selectBar(e);
            }
          }
        }
      }
    };

    function selectBar(e, noApply) {
      const optName = e.data.label;
      const optCount = e.data.value;
      const optKey = e.data.key;
      let title = `选项"${optName}"的人数: ${optCount}'`;
      if (optName == '未做答') {
        title = `未做答的人数: ${optCount}`;
      }
      if ($scope.details.ques.type === 'fill') {
        title = `选项"${optName}"的"${optKey}"的人数: ${optCount}`;
      }
      if (noApply) {
        updateSelectedBar(title, e, optKey);
      } else {
        $scope.$apply(function() {
          updateSelectedBar(title, e, optKey);
        });
      }
    }

    $scope.fillRevision = 0;
    function updateSelectedBar(title, e, key) {
      $scope.selectedBarTitle = title;
      $scope.selectedStudents = $scope.optStatTypes[$scope.details.ques.type]
        .getStudentListOfAnswer($scope.details, e);
      if (key === '订正') {
        $scope.fillRevision = 1;
      } else {
        $scope.fillRevision = 2;
      }
    }

    $scope.optDetails = [];
    $scope.triggerResize = function() {
      //
      // tricky - 默认此tab没有显示, 帮没有大小, 所以在激活此tab时需要
      // 重新计算其layout
      $timeout(function() {
        window.dispatchEvent(new Event('resize'));
      }, 0);
    };

    $scope.showStudentDetails = function(stu) {
      if ($scope.isFromTask) {
        $uibModalInstance.dismiss('cancel');
        $state.go('testSessionReviewForStudent', {
          sessionId: $stateParams.sessionId || data.sessionId,
          userId: stu.uid,
          taskListId: data.taskListId,
          origTestId: data.origTestId,
          recordId: data.recordId,
          taskId: data.taskId,
          index: data.index
        }, {});
      } else {
        //var dlgs = $('.question-details-modal');
        //dlgs.css('display', 'none');
        var modalInstance = $uibModal.open({
          template: require('assets/templates/review/testSessionReviewForStudentDialog.html'),
          controller: 'testSessionReviewForStudentDialogCtrl',
          windowClass: 'ques-detail-model modalCenter',
          size: 'lg',
          resolve: {
            data: function() {
              return {
                sessionId: $stateParams.sessionId || data.sessionId,
                student: stu,
                studentName: stu.name,
                index: data.index,
                stuList: data.studentsOfFinish,
                isDaoxuean:($scope.currentTest.testDisplayType && $scope.currentTest.testDisplayType == 1) ? true :false
              };
            }
          }
        });
        /**
        modalInstance.result
          .then(function(dirty) {
            $uibModalInstance.close(dirty);
          })
          .finally(function() {
            dlgs.css('display', 'block');
          });**/
      }
    };

    /**
     * 处理学生回答情况
     */
    function parseAnswerDetails(stdAnswer) {
      const correctParts = _.chain(stdAnswer.correctDetails).split(',').filter().map(_.parseInt);
      const hesitateParts = _.chain(stdAnswer.hesitateDetails).split(',').filter().map(_.parseInt);
      return correctParts.zipWith(hesitateParts.value(), (cp, hp) => ({
        uid: stdAnswer.studentId,
        correct: !!cp,
        hesitate: !!hp
      })).value();
    }
  }

  $scope.close = function() {
    $uibModalInstance.dismiss('cancel');
  };
}

  function quesStuList() {
    return {
      restrict: 'A',
      template: require('assets/templates/review/quesStuList.html'),
      scope: {
        stuList: '=stuList',
        stuSmView: '=stuSmView',
        title: '@title',
        showStudentDetails: '=',
        limitMaxHeight: '=',
        fillRevision: '='
      },
      controller: quesStuListController
    };
  }
})();

quesStuListController.$inject = ['$scope']
function quesStuListController($scope) {
  $scope.getStdAvatarImgSrc = function(std) {
    if ('female' == std.gender) {
      if (std.uid % 2 == 1) {
        return 'Assets/images2/stu_avatar_f1.png';
      } else {
        return 'Assets/images2/stu_avatar_f2.png';
      }
    } else {
      if (std.uid % 2 == 1) {
        return 'Assets/images2/stu_avatar_m1.png';
      } else {
        return 'Assets/images2/stu_avatar_m2.png';
      }
    }
  };
}
