import * as _ from 'lodash';

const echarts = require('echarts');

angular.module('app.directives.smallData.sbjData', [])
  .component('sbjData', {
    bindings: {
      sbjData: '<',
    },
    controller: sbjDataCtrl,
    template: <string> require('./sbjData.html'),
  });

sbjDataCtrl.$inject = ['$window']
function sbjDataCtrl($window: any) {
  this.$onInit = function() {
    this.currentSbj = {};
    this.isPad = _.includes(window.navigator.userAgent.toLowerCase(), 'mqqbrowser');
  }

  this.$onChanges = function(changes: any) {
    this.sbjData = _.filter(changes.sbjData.currentValue, (s: any) => !s.isGroup);
    this.sbjSessions = this.sbjData;
    this.buildData();
  }

  this.config = {
    dataLoaded: true,
    height: 300,
    theme: 'light',
    width: $window.innerWidth * 0.25,
  };

  this.showSbj = function(sbj = {}) {
    this.currentSbj = sbj;
    if (this.showAvg()) {
      this.sbjSessions = this.sbjData;
    } else {
      this.sbjSessions = [sbj];
    }
    this.buildData();
  }

  this.showAvg = function() {
    return !_.isNumber(this.currentSbj.boardSessionId);
  }

  this.getItemStyle = (color: any) => {
    const style: any = {
      normal: {
        borderColor: '#e9ebf5',
        borderWidth: 3,
      },
    };
    if (!_.isEmpty(color)) {
      style.normal.color = color;
    }
    return style;
  }

  this.getSbjData = (data: Array<number>) => {
    const res: Array<any> = [];
    const color = ['#ffd04f', '#ffbd24', '#ffa74f', '#ff8000'];
    let idx = 0;
    _.forEach(data, (d: any) => {
      res.push({
        itemStyle: {
          normal: {
            color: color[idx],
          },
        },
        name: '',
        value: d,
      });
      idx++;
    });
    return res;
  }

  this.buildHot = function() {
    const views = _.meanBy(this.sbjSessions, (s: any) => s.views).toFixed(0);
    const comments = _.meanBy(this.sbjSessions, (s: any) => s.comments).toFixed(0);
    const bravos = _.meanBy(this.sbjSessions, (s: any) => s.bravos).toFixed(0);
    const resubmits = _.meanBy(this.sbjSessions, (s: any) => s.resubmits).toFixed(0);
    const hotData = this.getSbjData([views, bravos, comments, resubmits]);
    this.hotOption = {
      grid: {
        bottom: '20%',
        left: 50,
        right: 50,
      },
      series: [{
        barMaxWidth: '30',
        data: hotData,
        name: '',
        type: 'bar',
      }],
      title: {
        bottom: 0,
        left: 'center',
        text: '主观活动深度热度',
        textStyle: {
          color: '#000000',
          fontSize: 16,
          fontWeight: 'normal',
        },
      },
      xAxis: [{
        axisLine: {
          symbol: ['none', 'arrow'],
          symbolSize: [5, 10],
        },
        axisTick: {
          show: false,
        },
        minInterval: 1,
        name: '次数',
        nameLocation: 'end',
        splitLine: {
          show: false,
        },
        type: 'value',
      }],
      yAxis: [{
        axisLine: {
          symbol: ['none', 'arrow'],
          symbolSize: [5, 10],
        },
        axisTick: {
          show: false,
        },
        data: ['浏览', '点赞', '评论', '再提交'],
        splitLine: {
          show: false,
        },
        type: 'category',
      }],
    };
  }

  this.buildTime = function() {
    const analysis = _.meanBy(this.sbjSessions, (s: any) => s.analysisDuration / s.onlineStu);
    const timeData = [];
    if (analysis > 0) {
      timeData.push({
        itemStyle: this.getItemStyle('#d0d973'),
        name: '看解析',
        value: analysis,
      });
    }
    const submit = _.meanBy(this.sbjSessions, (s: any) => s.submitDuration / s.onlineStu);
    if (submit > 0) {
      timeData.push({
        itemStyle: this.getItemStyle('#bbc736'),
        name: '作答',
        value: submit,
      });
    }
    const social = _.meanBy(this.sbjSessions, (s: any) => s.socialDuration / s.onlineStu);
    if (social > 0) {
      timeData.push({
        itemStyle: this.getItemStyle('#8cba75'),
        name: '互动',
        value: social,
      });
    }
    this.timeOption = {
      grid: {
        bottom: 0,
        left: 50,
        right: 5,
      },
      labelLine: {
        normal: {
          length: 15,
          length2: 0,
          lineStyle: {
            color: '#999999',
            width: 2,
          },
        },
      },
      series: [{
        avoidLabelOverlap: true,
        data: timeData,
        hoverAnimation: false,
        label: {
          normal: {
            formatter: (params: any) => {
              const time = params.data.value;
              let label = '';
              const min = _.parseInt(String(time / 60000));
              if (min > 0) {
                label = min + '分';
              } else {
                const sec = _.parseInt(((time % 60000) / 1000).toFixed(0));
                if (sec > 0 || min === 0) {
                  label = label + sec + '秒';
                }
              }
              return '{black|' + params.name + '}\n{black|' + label + '}';
            },
            position: 'outside',
            rich: {
              black: {
                align: 'center',
                color: '#000000',
                fontSize: 12,
                padding: 2,
              },
            },
          },
        },
        minAngle: 30,
        name: '',
        radius: ['0%', '50%'],
        selectedOffset: 30,
        startAngle: 225,
        type: 'pie',
      }],
      title: {
        bottom: 0,
        left: 'center',
        text: '主观活动时间占比',
        textStyle: {
          color: '#000000',
          fontSize: 16,
          fontWeight: 'normal',
        },
      },
    };
  }

  this.buildData = function() {
    this.buildTime();
    this.buildHot();
  }
}
