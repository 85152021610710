const _ = require('lodash');

(function() {
  function supports_html5_storage() {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
  }

  function checkBrowser() {
    if (supports_html5_storage()) {
      const allowedBrowsers = ['webkit', 'gecko', 'chrome', 'firefox'];
      //华为M3青春版， 自带华为浏览器无法加载 lodash 模块。UA: Mozilla/5.0 (Linux; Android 7.0; CPN-W09Build/HUAWEICPN-W09)
      // AppleWebKit/534.30 (KHTML, like Gecko) Version/4.0 Mobile Safari/534.30
      if (!(_.findIndex instanceof Function)) {
        window.location.href = 'browserCheckError.html';
        return;
      }
      const supportedBrowserIdx = _.findIndex(allowedBrowsers, function(b) {
        return _.get(bowser, b, false);
      });

      if (supportedBrowserIdx < 0) {
        if (bowser.msie && bowser.version >= 9) {
          sessionStorage.setItem('continueURL', window.location.href);
          window.location.href = 'browserCheckWarn.html';
        } else {
          window.location.href = 'browserCheckError.html';
        }
      }
    } else {
      window.location.href = 'browserCheckError.html';
    }
  }

  if (sessionStorage.getItem('continueURL') === null) {
    checkBrowser();
  }

})();
