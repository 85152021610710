import { Component, OnInit, Input, OnChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-preview-tree-item',
  template: require('./preview-tree-item.component.html'),
  styles: [require('./preview-tree-item.component.scss')]
})

export class PreviewTreeItemComponent implements OnInit, OnChanges {
  @Input() private item: any
  @Input() private isShow: boolean
  @Input() private maxLevel: number
  @Input() private selectId: any
  @Input() private expandItems: any
  @Output() public selectItem = new EventEmitter<any>();

  private name: string
  private level: number
  private isExpand: boolean
  private isSelected: boolean
  private children: any[]
  private hasChild: boolean
  private showNextLevel: boolean
  private showExpand: boolean
  private showClose: boolean

  private showContent: boolean

  constructor() { }

  ngOnInit() {
    this.name = _.get(this.item, 'name', '')
    this.level = _.get(this.item, 'nodeLevel', 0)
    this.isExpand = _.get(this.item, 'isExpand', false)
    this.isSelected = _.get(this.item, 'isSelected', false)
    this.children = _.get(this.item, 'children', [])
    this.hasChild = _.size(this.children) > 0
    const isOpen = _.isEmpty(_.get(this.expandItems, `${_.get(this.item, 'id', '')}`, 0))
    this.isExpand = this.isExpand || isOpen
    this.checkIconAndNextShow()
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (_.has(changes, 'isShow')) {
      this.isExpand = _.get(this.item, 'isExpand', false)
      this.checkIconAndNextShow();
    }
  }

  private checkIconAndNextShow = () => {
    if (_.isNil(this.maxLevel)) {
      this.showNextLevel = this.hasChild
      this.showExpand = this.hasChild && !this.isExpand
      this.showClose = this.hasChild && this.isExpand
    } else {
      this.showNextLevel = this.hasChild && this.level < this.maxLevel
      this.showExpand = this.hasChild && this.level < this.maxLevel && !this.isExpand
      this.showClose = this.hasChild && this.level < this.maxLevel && this.isExpand
    }
  }

  private hideChild = (children: any[]) => {
    if (_.size(children) <= 0) return
    _.forEach(children, child => {
      _.set(child, 'isExpand', false)
      const children = _.get(child, 'children', [])
      this.hideChild(children)
    })
  }

  private onExpand = () => {
    this.isExpand = !this.isExpand;
    this.checkIconAndNextShow();
    _.set(this.item, 'isExpand', this.isExpand)
    if (!this.isExpand) {
      // 如果跨级关闭，则需关闭展开的所有child
      this.hideChild(this.children)
    }
  }

  choose() {
    this.selectItem.emit(this.item);  
  }

  private onSelected = () => {
    this.isSelected = !this.isSelected
    _.set(this.item, 'isSelected', this.isSelected)
  }

  selectItemChild(item) {
    this.selectItem.emit(item);
  }
}

