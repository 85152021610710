(function() {
  var moment = require('moment');
  const _ = require('lodash')
  const {questionHasTable} = require('app2/utils/testUtils2')
  const {openDialog} = require('app2/utils/dialogUtils')
  const {withParentDialogHidden} = require('app2/prepareCourse/cloud/utils/libraryUtils')

  angular.module('app.prepareCourse')
    .controller('cloudSubjectiveTestPreviewerCtrl', cloudSubjectiveTestPreviewerCtrl);

  cloudSubjectiveTestPreviewerCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'dialogs',
    'notificationService', 'oedConfig', 'oedSubjectiveTest', 'oedCloudSubjectiveTest', 'cloudTest', 'oedUserInfo',
    'resourceDef', '$uibModal']
  function cloudSubjectiveTestPreviewerCtrl($scope, $q, $uibModalInstance, dialogs,
    notificationService,
  oedConfig, oedSubjectiveTest, oedCloudSubjectiveTest, cloudTest, oedUserInfo,
  resourceDef, $uibModal) {
    $scope.cloudTest = cloudTest;
    $scope.cloudSbjTest = {};

    $scope.basePath = oedConfig.base();

    resourceDef = resourceDef || {};

    $scope.importBtnText = resourceDef.importBtnText || '导入';

    $scope.getTypeLabel = function(type) {
      if (type == 'subjective_blankboard')
        return '白板';
      return '!!!';
    };

    $scope.resourceDef = resourceDef;

    function getCloudTest() {
      //
      // 如果有__type 这个属性表示是一个普通资源
      if (_.has(cloudTest, '__type')) {
        return oedCloudSubjectiveTest.queryBySubjectiveTestId({
          id: cloudTest.id
        }).$promise;
      }

      return $q.when(cloudTest);
    }

    $scope.loading = getCloudTest().then(function(cloudRes) {
      $scope.cloudSbjTest = cloudRes;
      $scope.subjectiveTest = oedSubjectiveTest.getPreviewByCloudRes({
        res_id: cloudRes.id
      });

      $scope.user = oedUserInfo.getByCloudTestId({
        id: cloudRes.id
      });

      return $q.all([$scope.subjectiveTest.$promise, $scope.user.$promise]);
    });

    $scope.getRelatedSectionNames = function() {
      if (!$scope.subjectiveTest || _.isEmpty($scope.subjectiveTest.question)) {
        return '无';
      }

      return _($scope.subjectiveTest.question.relatedSections).map(function(sec) {
        return sec.sectionName;
      }).join(', ');
    };

    $scope.close = function() {
      $uibModalInstance.close({});
    };

    $scope.importResWrapper = () => {
      const hasTable = questionHasTable($scope.subjectiveTest.question)
      if (hasTable) {
        withParentDialogHidden(
          () => {
            return openDialog({
              component: 'dialogOne',
              size: 'common-dialog',
              title: '确定导入?',
              message: '您将导入的资源包括表格，在pad上的显示效果可能不理想，建议在导入后将表格截图转成图片',
              uibModal: $uibModal,
              callback: $scope.importRes,
              callbackParams: {},
              yesText: '确定',
            })
          }
        )
      } else {
        $scope.importRes()
      }
    }

    $scope.importRes = function() {
      var deferred = $q.defer();
      $scope.loading = deferred.promise;
      deferred.promise.then(function() {
        notificationService.notify('info', '资源导入成功')

        $uibModalInstance.close('close');
      }, null, function(update) {
        $scope.progressMsg = update;
      }).finally(function() {
        $scope.progressMsg = '';
      });

      resourceDef.doImport([cloudTest], deferred);
    };

    var titleTpl = _.template('<%=testName%> (<%=username%>)');
    $scope.getTitleText = function() {
      var username = _.get($scope.cloudTest, 'userName', '');
      username = username || _.get($scope.user, 'name');

      return titleTpl({
        testName: _.get($scope.subjectiveTest, 'name', ''),
        username: username
      });
    };

    var subTitleTpl = _.template('<%=lastUpdateDate%> 下载: <%=downloads%> 浏览: <%=views%>');
    $scope.getSubTitleText = function() {
      return subTitleTpl({
        lastUpdateDate: moment(_.get($scope.cloudTest, 'lastUpdatedDate')).format('YYYY-MM-DD'),
        downloads: _.get($scope.cloudTest, 'downloads', 0),
        views: _.get($scope.cloudTest, 'views', 0)
      });
    };

    $scope.hasAnswer = () => {
      return !_.isEmpty(_($scope).get('subjectiveTest.question.answerDetails', null))
    }

    $scope.toggleAnswer = () => {
      $scope.showAnswer = !$scope.showAnswer
    }
  }
})();
