(function() {
  angular.module('app.directives')
    .directive('documentHeight', documentHeight);
})();

documentHeight.$inject = ['$window']
function documentHeight($window) {
  return {
    restrict: 'A',
    link: function(scope, element, attrs) {
      element.height(document.documentElement.clientHeight - attrs.relativeHeightToDocument);
      function updateHeight() {
        element.height(document.documentElement.clientHeight - attrs.relativeHeightToDocument);
      }

      angular.element($window).on('resize', updateHeight);
      scope.$on('$destroy', function() {
        angular.element($window).off('resize', updateHeight);
      });

      updateHeight();
    },
  };
}
