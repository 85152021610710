import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { LessonService } from '../../../services/lesson.service'
import * as _ from 'lodash'
import { convertRuanyunQuestionToRayQ } from 'app2/integration/ruanyun/ruanyunQuesConvertUtils'
import * as echarts from 'echarts';

@Component({
  selector: 'app-intelligent-paper-panel',
  template: require('./intelligent-paper-panel.component.html'),
  styles: [require('./intelligent-paper-panel.component.scss')]
})
export class IntelligentPaperPanelComponent implements OnInit {
  @Input() public vendorGradeBookId: number
  @Input() public coursepathId: number
  @Output() public items = new EventEmitter<any>()
  @ViewChild('chartArea') chart: ElementRef
  private cp: any
  private ruanyunMapping: any
  private categorys: any
  private sections: any
  private choicedSectionId: number
  private questionCountFinal: number
  private choicedDiffculty = 3
  private foldIdSet: any[] = []
  private includedQuestionType: any[] = []

  constructor(
    private lessonService: LessonService
  ) { }

  ngOnInit() {
    console.log('this.coursepathId:' + this.coursepathId);
    console.log('this.courseMappingId:' + this.vendorGradeBookId);
    this.loadSections()
  }

  private loadSections() {
    this.lessonService.getCoursePath(this.coursepathId).toPromise()
      .then(coursepath => {
        this.cp = coursepath
        return this.lessonService.getMapping('ruanyun', coursepath.subjectId, coursepath.versionId, coursepath.gradeId).toPromise()
      })
      .then(mapping => {
        console.log('mapping:' + JSON.stringify(mapping))
        this.ruanyunMapping = mapping
        return this.lessonService.getChapterSection({ CourseMappingId: _.get(mapping, 'vendorGradeBookId') }).toPromise()
      })
      .then(sectionData => {
        this.sections = sectionData.Value;
        console.log('this.sections:' + JSON.stringify(this.sections));
        if (_.get(_.head(this.sections), 'NodeId')) {
          this.chooseSection(_.get(_.head(this.sections), 'NodeId'))
        }
        return this.lessonService.getQuestionCategory({ courseId: _.get(this.ruanyunMapping, 'vendorSubjectId') }).toPromise();
      })
      .then(categorys => {
        if (_.get(categorys, 'ApiResultType', 1) == 1) {
          this.categorys = categorys.Value;
          _.forEach(this.categorys, c => {
            _.set(c, 'count', 5)
            _.set(c, 'included', true)
          })
          this.loadEchart()
        } else { // eslint-disable-line

        }
      })
  }

  private loadEchart() {
    const allCount = _.sum(_.map(this.categorys, c => {
      if (_.get(c, 'included', true)) {
        return _.get(c, 'count', 0)
      }
      return 0
    }))
    const allTypeData = _.map(_.filter(this.categorys, c => _.get(c, 'included', false)), cc => {
      return {value: _.get(cc, 'count'), name: _.get(cc, 'QuestionCategoryName')}
    })
    const allTypeName = _.map(_.filter(this.categorys, c => _.get(c, 'included', false)), 'QuestionCategoryName')
    echarts.init(this.chart.nativeElement).setOption({
      title: {
        text: `共计${allCount}道题`, //  总分${allCount}分
        x: 'center'
      },
      tooltip: {
        trigger: 'item',
        formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      legend: {
        orient: 'vertical',
        top: '400',
        selectedMode: false,
        data: allTypeName,
        formatter: (name) => {
          const ob = _.find(this.categorys, c => _.get(c, 'QuestionCategoryName') === name)
          const per = (ob.count * 100 / allCount).toFixed(1)
          return name + '  ' + ob.count + '道  ' + per + '%';
        }
      },
      color: ['#5dc7ef','#ffc022', '#b1cc40', '#ff8a8a', '#b27900','#749f83',  '#ca8622', '#bda29a','#6e7074', '#546570', '#c4ccd3'],
      series: [
        {
          name: '题型',
          type: 'pie',
          radius: '55%',
          center: ['50%', '30%'],
          data: allTypeData,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    })
  }

  private chooseSection(id: number) {
    this.choicedSectionId = id;
  }

  private choicedSection(id: number) {
    return this.choicedSectionId === id;
  }

  private foldCurrentFolder(id: number, $event: any) {
    if (_.indexOf(this.foldIdSet, id) < 0) {
      this.foldIdSet.push(id)
    } else {
      _.remove(this.foldIdSet, ids => ids === id)
    }
    $event.stopPropagation();
    $event.preventDefault();
  }

  private currentFolderFolded(id: number) {
    return _.indexOf(this.foldIdSet, id) > -1
  }

  private choicedQuestionType(id: number) {
    const tc = _.find(this.categorys, o => _.get(o, 'QuestionCategoryId') === id);
    _.set(tc, 'included', !_.get(tc, 'included', false));
    this.loadEchart()
  }
  private isQuestionTypeChoiced(id: number) {
    const tc = _.find(this.categorys, o => _.get(o, 'QuestionCategoryId') === id);
    return _.get(tc, 'included', false)
  }

  private numberChanging(data, id) {
    const tc = _.find(this.categorys, o => _.get(o, 'QuestionCategoryId') === id);
    _.set(tc, 'count', _.get(data, 'count', 5));
    this.loadEchart()
  }

  private onDificulltyChoose(dif: number) {
    this.choicedDiffculty = dif
  }
  private getDiff() {
    return Number(String(this.choicedDiffculty / 5)).toFixed(1)
  }
  private showDiffIcon(dif: number) {
    return this.choicedDiffculty === dif;
  }
  private getDosColor(i: number) {
    let color: string = null;
    switch (i % 6) {
      case 0: color = '#a3db99'; break;
      case 1: color = '#5dc7ef'; break;
      case 2: color = '#ffc022'; break;
      case 3: color = '#b1cc40'; break;
      case 4: color = '#ff8a8a'; break;
      case 5: color = '#b27900'; break;
      default: color = '#b27900';
    }
    return { 'color': color };
  }

  private generatePaper() {
    const courseId = _.get(this.ruanyunMapping, 'vendorSubjectId');
    const userCode = 'teacher' + courseId
    const params = {
      UserCode: userCode,
      UserTrueName: 'teacher1',
      UserTypeId: 1,
      CourseId: courseId,
      gradeId: 0,
    }
    this.lessonService.getUserSession(params).toPromise()
      .then(res => {
        const ChapterIds = [{ CourseMappingId: this.ruanyunMapping.vendorGradeBookId, ChapterId: this.choicedSectionId }];
        const QuestionCounts = _.map(this.categorys, c => {
          if (c.included) {
            return {
              QuestionCategoryId: c.QuestionCategoryId,
              QuestionCount: c.count
            }
          }
        })
        const intelligentParams = {
          UserCode: userCode,
          ByCourseMapping: 1,
          LocationId: 0,
          ChapterIds,
          QuestionCounts,
          QuestionLevelId: this.choicedDiffculty
        }
        return this.lessonService.getQuestionByIntelligence(intelligentParams).toPromise();
      })
      .then(intelliQuestion => {
        const rayQ = _.map(intelliQuestion.Value, q => convertRuanyunQuestionToRayQ(q))
        // console.log('rayQ:' + _.size(rayQ));
        this.questionCountFinal = _.size(rayQ)
        // console.log('rayQ:' + JSON.stringify(rayQ));
        this.items.emit(rayQ)
      })
  }
}
