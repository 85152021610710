/**
 * @fileOverview
 * @name objectiveTestSessionsCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('objectiveTestSessions', {
        url: '/review/:classSessionId/course/:courseId/tm/:tmId/test/:testId',
        template: require('assets/templates/review/objectiveTestSessions.html'),
        controller: 'objectiveTestSessionsCtrl',
        bodyStyle: 'bged2'
      });
      $stateProvider.state('objectiveTestSessions.subSession', {
        url: '/review/:classSessionId/course/:courseId/tm/:tmId/test/:testId/ofSubSession/:subSessionId',
        template: require('assets/templates/review/objectiveTestSessions.html'),
        controller: 'objectiveTestSessionsCtrl',
        bodyStyle: 'bged2'
      });
    }])
    .controller('objectiveTestSessionsCtrl', objectiveTestSessionsCtrl);

  objectiveTestSessionsCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', '$animate', '$q',
    'dialogs', 'resize', 'userInfo', 'oedCoursePath', 'oedClassSession', 'oedCourse', 'oedTestSession',
    'oedObjectiveTest', 'oedTmSession', 'oedTeachingPattern']
  function objectiveTestSessionsCtrl($scope, $state, $stateParams, $uibModal, $animate, $q,
    dialogs, resize, userInfo, oedCoursePath, oedClassSession, oedCourse, oedTestSession,
    oedObjectiveTest, oedTmSession, oedTeachingPattern) {

    const isTempTestSession = $stateParams.testId === 'tempTestSessions'
    $scope.currentState = {};
    $scope.courseId = _.parseInt($stateParams.courseId);
    $scope.subSessionId = $stateParams.subSessionId;

    $scope.classSession = oedClassSession.get({
      id: $stateParams.classSessionId
    });

    $scope.shouldBeActive = function(obj, params) {
      return $state.includes(obj, params);
    };

    $scope.origTestSessions = oedTestSession.queryByTestIdExcludeTmSession({
      classSessionId: $stateParams.classSessionId,
      origTestId: isTempTestSession ? -1 : $stateParams.testId
    });


    var sessionId = $state.params.sessionId;

    const toSessionDetails = () => {
      if (angular.isDefined($scope.subSessionId)) {
        $state.go('objectiveTestSessions.session', {
          sessionId: $scope.subSessionId
        }, {
          location: 'replace'
        });
      } else if (!_.isEmpty($scope.testSessions) && !sessionId) {
        $state.go('objectiveTestSessions.session', {
          sessionId: $scope.testSessions[0].id
        }, {
          location: 'replace'
        });
      }
    }

    const getSessionDetails = (sessionDetails) => {
      const sessionByParentId = _.filter(sessionDetails, 'parentId', true);
      const sessionByParentIdGroup = _.groupBy(sessionByParentId, 'parentId');
      $q.all(_.map(sessionByParentId, (s) => {
        return oedObjectiveTest.get({
          id: s.testId
        }).$promise.then((test) => {
          s.test = test;
        });
      })).then(() => {
        _.map($scope.testSessions, (session) => {
          if (angular.isDefined(session) && _.hasIn(sessionByParentIdGroup, session.id)) {
            const index = _.findIndex($scope.testSessions, ['id', session.id]);
            const subSessions = sessionByParentIdGroup[session.id];
            session.subSessions = subSessions;
          }
        })
        toSessionDetails()
      })
    }

    $scope.loading = isTempTestSession ? $scope.classSession.$promise : $q.all([
      $scope.classSession.$promise,
      $scope.origTestSessions.$promise
    ])

    $scope.loading.then(() => {
        $scope.course = oedCourse.get({
          id: $stateParams.courseId
        })
        return $scope.course.$promise
      })
      .then(() => {
      if (isTempTestSession) {
        $scope.testSessions = []
        const tmp = _.map($scope.classSession.testSessions, (t) => {
          return oedObjectiveTest.get({id: t.testId}).$promise.then((r) => {
            if (r.isTemp && r.type === 'objective') {
              $scope.testSessions.push(t)
            }
          })
        })
        return $q.all(tmp).then(() => {
          return oedTeachingPattern.queryByClassSessionAndCourseId({
            classSessionId: $stateParams.classSessionId,
            courseId: $stateParams.courseId
          }).$promise
        }).then((patterns) => {
          $scope.testSessions = _.filter($scope.testSessions, r => {
            return _.includes(_.map(patterns, 'id'), r.teachingPatternId)
          })
          return getSessionDetails($scope.testSessions)
        })
      } else {
        $scope.testSessions = _.filter($scope.origTestSessions, (s) => {
          return _.isNil(s.parentId);
        })
        return getSessionDetails($scope.classSession.testSessions)
      }
    });
  }
})();
