(function() {
  angular.module('app.homework')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('homework.reviewStudentTaskDetails.res', {
        url: '/res',
        template: '<ui-view/>',
        abstract: true
      });
    }]);
})();
