import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ElementRef, ViewChild, OnChanges } from '@angular/core';
import * as Promise from 'bluebird';
import * as _ from 'lodash';

@Component({
  selector: 'app-cloud-res-box',
  template: require('./cloud-res-box.component.html'),
  styles: [require('./cloud-res-box.component.scss')]
})
export class CloudResBoxComponent implements OnInit, OnChanges {
  @Input() public countSuffix: number
  @Input() public items: any
  @Input() public itemToAdd: any
  @Output() public onAddItem = new EventEmitter<any>();
  @Output() public onPreview = new EventEmitter<any>();

  @ViewChild('resbody') body: ElementRef
  @ViewChild('box') box: ElementRef
  @ViewChild('counterEl') counterEl: ElementRef
  public placeHolderEl: ElementRef

  constructor(
    private render: Renderer2,
    private el: ElementRef,
  ) { }

  ngOnInit() {
    this.placeHolderEl = this.render.createElement('div')
    this.render.addClass(this.placeHolderEl, 'cl-res-placeholder-wrapper')
    this.render.appendChild(this.body.nativeElement, this.placeHolderEl);
  }

  ngOnChanges(changes): void {
    console.log('ngOnChanges:' + JSON.stringify(changes))
    if (!_.get(changes, 'itemToAdd.firstChange', true)) {
      this.doAddItemAnimated();
    }
  }

  private doPreview() {
    this.onPreview.emit({
      doPreview: true
    })
  }

  private doAddItemAnimated() {
    // TODO: 目前轨迹是card code
    const el = this.render.createElement('div')
    this.render.addClass(el, 'cl-res-placeholder')
    this.render.setStyle(el, 'top', 1100)
    this.render.setStyle(el, 'left', 500)
    this.render.appendChild(this.placeHolderEl, el)
    const velocity: any = require('velocity-animate')
    const promise = velocity({
      e: el,
      p: {
        left: 0,
        top: 800,
        // left: this.itemToAdd.x,
        // top: this.itemToAdd.y,
      },
      o: {
        duration: 0,
      }
    }).then(() => velocity({
      e: el,
      p: {
        left: this.counterEl.nativeElement.left,
        top: this.counterEl.nativeElement.top,
      },
      o: {
        duration: 300,
      },
    })).then(() => {
      el.remove()
    })

    Promise.resolve(promise)
    .then(() => {
      this.render.addClass(this.box.nativeElement, 'animated')
      this.render.addClass(this.box.nativeElement, 'pulse')

      setTimeout(() => {
        this.render.removeClass(this.box.nativeElement, 'animated')
        this.render.removeClass(this.box.nativeElement, 'pulse')
      }, 1000);
    }, () => {
      console.log('err')
    })
  }
}
