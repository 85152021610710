import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-common-btn',
  template: require('./common-btn.component.html'),
  styles: [require('./common-btn.component.scss')]
})
export class CommonBtnComponent implements OnInit {
  @Input() private text: string;
  @Input() private disable: boolean;
  @Output() private action = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  private onBtn = () => this.action.emit()
}
