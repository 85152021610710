import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subject, of } from 'rxjs';

import { RayApiService } from 'app/core/ray-api.service';
import { TalPaperDTO, TalQuestionDetailsDTO, TalTopicDetailsDTO } from 'app2/integration/talcloud/models';
import {
  DifficultType, PaperType, ProvinceDTO,
  QuestionType, TalApiResponse, TalPaperQueryDTO,
  TalQuestionQueryDTO, TalTopicQueryDTO, YearDTO } from '../models/talcloud';
import { questionTypes } from 'app2/integration/xuersi/xuersiUtils';
import { map } from 'rxjs/operators';

@Injectable()
export class TalApiService {

  constructor(
    private httpClient: HttpClient,
    private rayApi: RayApiService,
  ) { }

  public getPaperList(body: TalPaperQueryDTO): Observable<TalApiResponse<TalPaperDTO[]>> {
    return this.httpClient.get<TalApiResponse<TalPaperDTO[]>>
    (this.rayApi.apiA(`talresource/papers/list?${this.toUrlEncoded(body)}`))
  }

  public getQuestionList(body: TalQuestionQueryDTO): Observable<TalApiResponse<TalQuestionDetailsDTO[]>> {
    return this.httpClient.get<TalApiResponse<TalQuestionDetailsDTO[]>>
    (this.rayApi.apiA(`talresource/questions/list?${this.toUrlEncoded(body)}`))
  }

  public getTopicList(body: TalTopicQueryDTO): Observable<TalApiResponse<TalTopicDetailsDTO[]>> {
    return this.httpClient.get<TalApiResponse<TalTopicDetailsDTO[]>>(
      // tslint:disable-next-line:max-line-length
      this.rayApi.apiA(`talresource/topic/${body.stage}/${body.subjectId}/topics?${this.toUrlEncoded(_.omit(body, ['stage', 'subjectId']))}`)
    )
  }

  public getQuestionTypes(stage: number, subjectId: number): Observable<QuestionType[]> {
    return this.httpClient.get<QuestionType[]>(
      this.rayApi.apiA(`talresource/questions/${stage}/${subjectId}/types`)
    ).pipe(
      (map((value: QuestionType[]) => {
        const defauleType = [{
          sort: 0,
          type: '-1',
          typeName: '全部',
        }]
        const quesTypes = [
          ...defauleType,
          ...value,
        ]
        return _.map(quesTypes, (t: QuestionType, i: number) => {
          return {
            ...t,
            text: t.typeName,
            sort: i,
          }
        })
      }))
    )
  }

  public getPaperTypes(): Observable<PaperType[]> {
    return this.httpClient.get<PaperType[]>(
      this.rayApi.apiA(`talresource/papers/types`)
    ).pipe(
      (map((value: PaperType[]) => {
        const defaulePaper: PaperType = {
          typeId: '-1',
          text: '全部',
          sort: 0,
        }
        let papers: PaperType[] = _.map(value, (p: PaperType, i: number) => {
          return {
            ...p,
            text: p.typeName,
            sort: i + 1,
          }
        })
        papers = [
          ...papers,
          defaulePaper,
        ]
        return papers
      }))
    )
  }

  public getProvinces(): Observable<ProvinceDTO[]> {
    return this.httpClient.get<ProvinceDTO[]>(
      this.rayApi.apiA(`talresource/common/provinces`)
    ).pipe(
      (map((value: ProvinceDTO[]) => {
        const defauleProvince: ProvinceDTO = {
          id: '-1',
          text: '全部',
          sort: 0,
        }
        let provinces: ProvinceDTO[] = _.map(value, (p: ProvinceDTO, i: number) => {
          return {
            ...p,
            text: p.name,
            sort: i + 1,
          }
        })
        provinces = [
          ...provinces,
          defauleProvince,
        ]
        return provinces
      }))
    )
  }

  public getOedProvinces(): Observable<ProvinceDTO[]> {
    return this.httpClient.get<ProvinceDTO[]>(
      this.rayApi.apiB(`tag/province`)
    ).pipe(
      (map((value: ProvinceDTO[]) => {
        const defauleProvince: ProvinceDTO = {
          id: '-1',
          text: '全部',
          sort: 0,
        }
        let provinces: ProvinceDTO[] = _.map(value, (p: ProvinceDTO, i: number) => {
          return {
            ...p,
            text: p.name,
            sort: i + 1,
          }
        })
        provinces = [
          ...provinces,
          defauleProvince,
        ]
        return provinces
      }))
    )
  }

  public getTalAreaDocMaterialTypes(t): Observable<any> {
    return this.httpClient.get<any>(
      this.rayApi.apiB(`thirdParty/talAreaRes/areares/api/meta/getMetasByCodes?code=${t}`)
      ).pipe(
        (map((value: any) => {
          return (_.chain(value).get('data', []) as any).filter((v) => v.code === t).value()
        }))
      )
  }

  public getTalAreaPhaseSubjectQuesPatterns(phaseId, subjectId) {
    return this.httpClient.get<any>(this.rayApi.apiB(
      `thirdParty/talAreaRes/areares/api/meta/${phaseId}/${subjectId}/getPhaseSubjectQuestionPatterns`
    ))
  } 

  public getTalAreaGradeList(levelId): Observable<any>{
    return this.httpClient.get<any>(
      this.rayApi.apiB(`thirdParty/talAreaRes/areares/api/meta/getPhaseGrade`)
    ).pipe(
      (map((value: any) => {
        return (_.chain(value).get('data', []) as any).filter((v) => v.id === levelId).get('0.childs', []).value()
      }))
    )
  }

  public getTalAreaShengxueYears() {
    const years = _.range(new Date().getFullYear(), 2009)
    let yearsDTO: any = _.map(years, (y: number, i: number) => {
      return {
        id: y + '',
        sort: i + 1,
        text: y + '',
      }
    })
    yearsDTO = [
      ...yearsDTO,
      {
        sort: 0,
        text: '全部',
      }
    ]
    return yearsDTO
  }

  public getYears(): YearDTO[] {
    const years = _.range(new Date().getFullYear(), 2009)
    let yearsDTO = _.map(years, (y: number, i: number) => {
      return {
        id: y + '',
        sort: i + 1,
        text: y + '',
      }
    })
    yearsDTO = [
      ...yearsDTO,
      {
        id: '-1',
        sort: 0,
        text: '全部',
      }
    ]
    return yearsDTO
  }

  public getDifficults(): DifficultType[] {
    const difficults = ['全部', '容易', '较易', '普通', '较难', '困难']
    return _.map(difficults, (diff: string, index: number) => {
      return {
        difficult: diff === '全部' ? '-1' : index,
        text: diff,
        sort: index,
      }
    })
  }

  public getExamTypes() {
    const examTypes = ['全部', '真题', '常考题', '易错题', '好题', '压轴题', '听力题', '模拟题']
    return _.map(examTypes, (examTypeStr: string, index: number) => {
      return {
        examType: examTypeStr === '全部' ? '-1' : index,
        text: examTypeStr,
        sort: index,
      }
    })
  }

  public getDocumentTypes() {
    return [
      {
        text: '全部',
        type: '-1',
        sort: 0,
      },
      {
        text: '课件',
        type: 3,
        sort: 1,
      },
      {
        text: '教案',
        type: 8,
        sort: 2,
      },
      {
        text: '试卷',
        type: 7,
        sort: 3,
      },
      {
        text: '学案',
        type: 4,
        sort: 4,
      },
      {
        text: '资源包',
        type: 11,
        sort: 5,
      },
      {
        text: '素材',
        type: 6,
        sort: 6,
      },
      {
        text: '视频',
        type: 12,
        sort: 7,
      },
    ]
  }

  private toUrlEncoded(obj) {
    return _.chain(obj).keys().map((k) => {
      if (obj[k]) {
        return encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])
      }
    }).filter().join('&').value()
  }
}
