import { isQuickWayToCreatePrepareCourse } from 'app2/prepareCourse/utils/prepareCourseUtils'

angular.module('app.homework')
  .config(['$stateProvider', function($stateProvider) {
    $stateProvider.state('homework.taskListPubObjectiveTestSummary', {
      url: '/taskListPubDetailsSummary/:origTaskListId/tasklistrecord/' +
        ':recordId/task/:taskId/objectiveTest/:origTestId',
      template: require('assets/templates/homework/taskListPubObjectiveTestSummary.html'),
      controller: 'taskListPubObjectiveTestSummaryCtrl',
      bodyStyle: 'bged'
    });
  }])
  .controller('taskListPubObjectiveTestSummaryCtrl', taskListPubObjectiveTestSummaryCtrl)
  .directive('taskListPubObjectiveTestSummary', taskListPubObjectiveTestSummary);
  function taskListPubObjectiveTestSummary($document, $timeout) {
    return {
      restrict: 'E',
      scope: {
        origTaskListId: '<',
        recordId: '<',
        taskId: '<',
        origTestId: '<',
        isFromNewPrepareReport: '<',
        returnReportList: '&'
      },
      template: require('assets/templates/homework/taskListPubObjectiveTestSummary.html'),
      controller: taskListPubObjectiveTestSummaryCtrl
    };
  }

taskListPubObjectiveTestSummaryCtrl.$inject = ['$scope', '$state', '$stateParams', '$q',
  'userInfo', 'oedTestSession', 'oedObjectiveTest', 'oedTaskList', '$uibModal', 'oedTaskListPublishRecord', 'oedCloudTest']
function taskListPubObjectiveTestSummaryCtrl($scope, $state, $stateParams, $q,
  userInfo, oedTestSession, oedObjectiveTest, oedTaskList, $uibModal, oedTaskListPublishRecord, oedCloudTest) {

  $scope.extraInfo = ''
  var recordId = $scope.isFromNewPrepareReport ? $scope.recordId : _.parseInt($stateParams.recordId);
  var taskId = $scope.isFromNewPrepareReport ? $scope.taskId : _.parseInt($stateParams.taskId);
  var origTestId = $scope.isFromNewPrepareReport ? $scope.origTestId : _.parseInt($stateParams.origTestId);
  var origTaskListId = $scope.isFromNewPrepareReport ? $scope.origTaskListId : _.parseInt($stateParams.origTaskListId);
  $scope.origTaskListId = origTaskListId;
  $scope.origTestId = origTestId;
  $scope.recordId = recordId;
  $scope.taskId = taskId;
  $scope.activeTabIndex = 2;
  $scope.tabs = [{index: 0, text: '题目和解析'},
    {index: 1, text: '正确率'},
    {index: 2, text: '完成情况'},
    {index: 3, text: '统计表格'}];

  $scope.currentTaskList = oedTaskList.get({
    id: $scope.origTaskListId
  });
  $scope.correctRate = 0;
  $scope.taskId = taskId;
  $scope.currentTest = oedObjectiveTest.get({
    id: origTestId
  });

  $scope.publishInfo = oedTaskListPublishRecord.queryPublishInfo({
    id: recordId
  });

  $scope.publishRecords = oedTaskList.queryPublishInfo({
    id: $scope.origTaskListId
  });

  $scope.testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
    recordId: recordId,
    taskId: taskId,
    origTestId: origTestId
  });

  userInfo.then(function(info) {
    $scope.loading =  $q.all([$scope.testSession.$promise, $scope.publishInfo.$promise,
      $scope.publishRecords.$promise, $scope.currentTest.$promise]).then(() => {
        return getCloudTest($scope.currentTest.id)
    }).then(() => {
      if ($scope.currentTest.isTemp) {
        return oedObjectiveTest.getObjTestQuestionType({id: $scope.currentTest.id}).$promise.then((r) => {
          if (r && r.testId && r.extraInfo === 'yesorno') {
            $scope.extraInfo = 'yesorno'
          } else {
            $scope.extraInfo = 'singlechoice'
          }
        })
      }
    }).finally(function() {
      $scope.publishRecordOfSelectedClass = _.find($scope.publishRecords.publishRecords, function(record) {
        return record.classId === $scope.publishInfo.cls.id;
      });
      if ($scope.getTheWayWithCreateTest()) {
        $scope.childQuestionList = $scope.currentTest.questions[0].childQuestions
        $scope.imageRes = $scope.currentTest.questions[0]['resourceUUIDs']
      }

      $scope.loaded = true;
    });
  });

  $scope.getTheWayWithCreateTest = () => {
    return isQuickWayToCreatePrepareCourse($scope.currentTest)
  }

  $scope.showDetailsForStudent = function({std,finishStuList}) {
    var modalInstance = $uibModal.open({
      template: require('assets/templates/review/testSessionReviewForStudentDialog.html'),
      controller: 'testSessionReviewForStudentDialogCtrl',
      size: 'lg',
      windowClass: 'ques-detail-model modalCenter',
      resolve: {
        data: function() {
          return {
            sessionId: $scope.testSession.id,
            student: std,
            stuList: finishStuList,
            fromClassSession: false,
            publishRecordOfSelectedClass: $scope.publishRecordOfSelectedClass,
            isDaoxuean:($scope.currentTest.testDisplayType && $scope.currentTest.testDisplayType == 1) ? true :false
          };
        }
      }
    });

    modalInstance.result.then(function(dirty) {
      if (dirty) {
        $state.reload();
      }
    });
  };

  const getCloudTest = (testId) => {
    return oedObjectiveTest.get({id: testId}).$promise.then((test) => {
      if (_.get(test, 'origId', 0) === 0) {
        return oedCloudTest.queryByObjectiveTestId({id: test.id}).$promise.then((cloudTest) => {
          if (cloudTest && cloudTest.id && !test.isTemp) {
            //截屏推送不显示资源编号
            $scope.currentTest.$cloudTest = cloudTest
          }
        })
      } else {
        return getCloudTest(test.origId)
      }
    })
  }
}
