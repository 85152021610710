
var _ = require('lodash');
var questionPreviewer = require('./questionPreviewer.directive');

(function() {
  angular.module('app.directives.question.syntheticPreviewer', [
    'app.directives.question.questionPreviewer'
  ])
    .directive('syntheticPreviewer', syntheticPreviewer);

  function syntheticPreviewer() {
    return {
      restrict: 'EA',
      require: '^ngModel',
      scope: {
        ngModel: '=',
        qHidePoint: '<',
        qCloudRes: '=',
        qIndex: '=',
        qReadonly: '=',
        qHideStar: '<',
        scoreChanged: '&onScoreChanged',
        qShowAnswer: '=',
        qShowAnswerDetails: '=',
        qShowCloudResId: '=',
        qShowCloudTestLink: '=',
        onImportClicked: '&',
        onGoToTestClicked: '&',
        ruanyun: '<',
        currentTest: '=',
        isExercise: '=',
        exKnowledgePoint: '=',
        exDifficult: '=',
        exSynPonit: '=',
        isNewVersion: '=',
        starReadOnly: '='
      },
      template: require('assets/app2/directives/question/syntheticPreviewer.html'),
      controller: syntheticPreviewerCtrl,
    };
  }
  syntheticPreviewerCtrl.$inject = [
    '$scope', 'oedTestUtils',
    'oedCloudObjectiveQuestion',
    'oedCloudTest', 'oedCloudQuestionStats', 'cloudQuestionService']
  function syntheticPreviewerCtrl(
    $scope, oedTestUtils, oedCloudObjectiveQuestion, oedCloudTest, oedCloudQuestionStats, cloudQuestionService) {
    if ($scope.qShowCloudResId && _.has($scope, 'qCloudRes.id')) {
      cloudQuestionService.getStatByCloudId($scope.qCloudRes.id).then((stats) => $scope.stats = stats)
    }
    $scope.getRelatedSectionNames = function() {
      var secs = _.get($scope, 'ngModel.relatedSections', []);
      if (_.isEmpty(secs)) {
        return '无';
      }

      return _(secs).map(function(sec) {
        return sec.sectionName;
      }).join(', ');
    };
    $scope.getQuestionNumber = function() {
      if ($scope.qIndex || $scope.qIndex >= 0) {
        return $scope.qIndex + 1;
      }
      return undefined
    };
    if ($scope.qShowCloudResId && !$scope.cloudRes) {
      $scope.$watch('ngModel.id', function(newId) {
        $scope.cloudRes = null;
        if (newId) {
          $scope.cloudRes = oedCloudObjectiveQuestion.getByQuestionId({
            id: newId
          });
        }
      });
    }

    if ($scope.qShowCloudTestLink) {
      $scope.$watch('ngModel.id', function(newId) {
        $scope.cloudTest = null;
        if (newId) {
          $scope.cloudTest = oedCloudTest.queryByObjectiveQuestionId({
            id: newId
          });
        }
      });
    }
    $scope.$watch('qCloudRes', function(nqCloudRes) {
    });
  }
})();
