import {OfficeUploadTipComponent} from "../../../../src/app/office-editor/office-upload-tip/office-upload-tip.component";

(function() {
  const {importPresentResourceItems, uploadAnhuiResToAnhuiSpace} = require('app2/utils/resourceUtils')
  require('app2/prepareCourse/cloud/presentResource/presentResourceLibraryDialog.ctrl')
  const resourceUtils = require('app2/utils/resourceUtils')

  angular.module('app.prepareCourse.presentResource', [
    'app.prepareCourse2.cloud.presentResource.presentResourceLibraryDialog'
  ]).config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareCourse.presentResource', {
        url: '/presentResource/:resId',
        params: {
          resId: '',
        },
        template: require('assets/templates/prepareCourse/presentResource.html'),
        controller: 'presentResourceCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('presentResourceCtrl', presentResourceCtrl)
    .controller('addResourceCtrl', addResourceCtrl)
    .controller('editPresentResourceCtrl', editPresentResourceCtrl)
    .controller('uploadResourceCtrl', uploadResourceCtrl)
    .filter('fileSize', fileSize);

  presentResourceCtrl.$inject = [
    '$scope',
    '$rootScope',
    '$q',
    '$http',
    '$state',
    '$stateParams',
    '$uibModal',
    '$httpParamSerializer',
    'dialogs',
    'userInfo',
    'hlsResource',
    'oedPreResItem',
    'resize',
    'notify',
    'oedConfig',
    'oedCourse',
    'oedLoading',
    'oedPresentResource',
    'oedCloudPresentRes',
    'oedPresentResourceStat',
    'oedCloudPresentResItem',
    'oedPresentResourceItem',
    'oedUserInfo',
    'oedCourseUtils',
    'oedResDownloadTask',
    'notificationService',
    'anHuiResource',
    'talAreaResource'
  ]
  function presentResourceCtrl(
    $scope,
    $rootScope,
    $q,
    $http,
    $state,
    $stateParams,
    $uibModal,
    $httpParamSerializer,
    dialogs,
    userInfo,
    hlsResource,
    oedPreResItem,
    resize,
    notify,
    oedConfig,
    oedCourse,
    oedLoading,
    oedPresentResource,
    oedCloudPresentRes,
    oedPresentResourceStat,
    oedCloudPresentResItem,
    oedPresentResourceItem,
    oedUserInfo,
    oedCourseUtils,
    oedResDownloadTask,
    notificationService,
    anHuiResource,
    talAreaResource
  ) {
    $scope.currentState.title = '展示类资源';
    var courseId = _.parseInt($stateParams.courseId);
    var curResId = _.parseInt($stateParams.resId);
    $scope.selectedReses = [];
    userInfo.then(function(user) {
      $scope.userInfo = user;
    });
    $scope.$on('$destroy', function() {
      $scope.isDone = true;
    });
    /*$scope.setTemplateHeight = function() {
      var bodyHeight = document.documentElement.clientHeight - 55;
      return {
        'min-height': bodyHeight + 'px'
      };
    };*/

    function getResources() {
      $scope.resources = oedPresentResource.queryByCourseIncEmpty({
        course_id: courseId
      });

      $scope.loading = $q(function(resolve, reject) {
        $scope.resources.$promise.then(function() {
          var selected = null;
          if (_.size($scope.resources) > 0) {
            if (curResId) {
              selected = _.find($scope.resources, function(res) {
                return res.id == curResId;
              });
            }

            if (!selected) {
              selected = $scope.resources[0];
            }

            $scope.setCurrentRes(selected, true);
            return null;
          } else { //no resources
            var res = new oedPresentResource({
              name: '展示资源包1',
              courseId: courseId,
              isEmpty: true
            });
            return res.$save();
          }
        }).then(function(newres) {
          if (newres) {
            $state.go('prepareCourse.presentResource', {}, {
              reload: true
            });
          } else {
            // populate shared res map
            var sharedResources = oedCloudPresentRes.queryByResIds({
              resIds: _.map($scope.resources, 'id')
            });
            sharedResources.$promise.then(function(allSharedReses) {
              $scope.sharedResources = _.keyBy(allSharedReses, 'resourceId');
              if (!$scope.currentRes) {
                return;
              }
              $scope.refreshCurrentCloudResId();
            });
          }
          resolve();
        }).catch(reject);
      });
    }

    getResources();
    $scope.refreshCurrentCloudResId = function() {
      if ($scope.sharedResources[$scope.currentRes.id])
        $scope.sharedResourceId = $scope.sharedResources[$scope.currentRes.id].id;
      else
        $scope.sharedResourceId = null;
    };
    $scope.shouldShowShare = function(res) {
      return res && $scope.sharedResources && $scope.sharedResources[res.id];
    };
    $scope.setCurrentRes = function(t, replace) {
      if ($scope.isDone)
        return;

      if ($scope.currentRes == t) return;

      var opt = replace ? {
        location: 'replace'
      } : null;
      $scope.currentRes = t;
      $scope.selectedReses = [];

      if (!t) return;

      $state.go('prepareCourse.presentResource.editPresentRes', {
        resId: t.id
      }, opt);
    };

    $scope.basePath = oedConfig.base();

    $scope.addResource = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/addResourceDialog.html'),
        controller: 'addResourceCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          courseId: function() {
            return courseId;
          },
          existingRes: function() {
            return $scope.resources;
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r.promise) {
          r.promise.then(function() {
            $scope.resources.push(r.resource);
            $scope.setCurrentRes(r.resource);
          });
        }
      });
    };

    $scope.deleteRes = function($event, res) {
      $event.stopPropagation();
      $event.preventDefault();
      var dlg = dialogs.confirm('确定删除?', '确定要删除展示资源: "' + res.name + '"吗');
      dlg.result.then(function() {
        $scope.loading = $q(function(resolve, reject) {
          res.$delete(function() {
            if (res == $scope.currentRes)
              $state.go('.', {}, {
                reload: true
              });
            else {
              _.remove($scope.resources, res);
            }
            resolve();
          }, function() {
            reject();
          });
        });
      });
    };

    $scope.editRes = function($event, res) {
      $event.stopPropagation();
      $event.preventDefault();
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/editPresentResourceDialog.html'),
        controller: 'editPresentResourceCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          resource: function() {
            return res;
          },
          existingRes: function() {
            return $scope.resources;
          }
        }
      });
    };

    $scope.toggleShareRes = function($event, res) {
      $event.stopPropagation();
      $event.preventDefault();
      var dlg = null;
      if (res.id in $scope.sharedResources) {
        dlg = dialogs.confirm('确定取消共享?', '确定要取消共享该展示类资源吗？');
        dlg.result.then(function(btn) {
          $scope.sharedResources[res.id].$delete().then(result => {
            delete $scope.sharedResources[res.id];
            $scope.refreshCurrentCloudResId();
          });
        }, function(btn) {});
      } else {
        dlg = dialogs.confirm('确定共享?', '确定要共享该展示类资源吗？');
        dlg.result.then(function(btn) {
          var sharedRes = new oedCloudPresentRes({
            resourceId: res.id
          });
          sharedRes.$save().then(r => {
            $scope.sharedResources[res.id] = r;
            $scope.refreshCurrentCloudResId();
          });
        }, function(btn) {});
      }
    };
    function queryCloudPresentResItem(queryOpt) {
      var queryCountURL = oedConfig.url_b('cloudpresentresourceitem/count2');
      var countReq = $http({
        url: queryCountURL,
        method: 'GET',
        params: queryOpt
      });
      var loadItems = oedCloudPresentResItem.queryBySection(queryOpt).$promise.then(function(cloudItems) {
        //
        // 加载详细信息
        var loadDetails = _.map(cloudItems, loadCloudResItemDetails);
        return $q.all(loadDetails);
      });
      return [loadItems, countReq];
    }

    function loadCloudResItemDetails(item) {
      item.res = oedPresentResourceItem.get({
        id: item.resourceId
      });

      item.owner = oedUserInfo.getByCloudPresentResourceItemId({
        id: item.id
      });

      return $q.all([item.res.$promise, item.owner.$promise]).then(function() {
        return item;
      });
    }

    function importResources(deferred, res) {
      var curIdx = 0;
      var hasDuplicated = false;

      var doDownload = function() {
        if (curIdx >= _.size(res)) {
          return $q.when('done');
        }

        var curSize = _.size($scope.currentRes.items);

        var cloudResItemCount = res[curIdx].stats.itemCount;
        var idx = curIdx;
        deferred.notify('正在导入第' + (idx + 1) + '个资源');
        var promise = $scope.currentRes.$importItemsFromCloudRes({
          res_id: res[curIdx].id
        }).then(function(resp) {
          var targetSize = curSize + cloudResItemCount;
          if (!hasDuplicated && targetSize > _.size(resp.items)) {
            hasDuplicated = true;
          }

          return doDownload();
        });

        curIdx++;
        return promise;
      };

      $q.when(doDownload())
        .then(function(r) {
          $rootScope.$broadcast('presentResource.changed', {
            resId: $scope.currentRes.id
          });
          return $scope.currentRes.$get();
        }).then(function() {
          deferred.resolve(curIdx);
        }).catch(function(m) {
          deferred.reject(m);
        })
        .finally(function(r) {
          if (hasDuplicated) {
            notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
          }
        });
    }

    let downloadOpts = null
    //
    // TODO: refactor
    function importResourceItems(deferred, res) {
      const opts = {
        canceled: false,
        hlsResource,
        hasDuplicated: false,
        oedCloudPresentResItem,
        oedPreResItem, oedPresentResourceItem,
        oedPresentResource,
        oedResDownloadTask,
        uid: $scope.userInfo.uid,
        notify,
        notificationService,
        talAreaResource,
      }

      downloadOpts = opts

      importPresentResourceItems($scope.currentRes, res, deferred, opts).then(function(r) {
          $rootScope.$broadcast('presentResource.changed', {
            resId: $scope.currentRes.id
          });
          return $scope.currentRes.$get();
        }).then(function() {
          deferred.resolve();
        }).catch(function(m) {
          deferred.reject(m);
        }).finally(function(r) {
          downloadOpts = null
          if (opts.hasDuplicated) {
            notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
          }
          doUploadAnhuiResToAnhuiSpace(res);
        });
      }
      
    function doUploadAnhuiResToAnhuiSpace(res) {
      console.log('doUploadAnhuiResToAnhuiSpace A')
      let opt = {
        anHuiResource
      }
      userInfo.then(function(user) {
        if (_.get(user, 'profile.thirdParty', '') != 'anhui' || !_.get(user, 'profile.thirdPartyUid', '')) {
          return Promise.reject({msg: 'current user isnot belong to anhui platform'})
        }
        _.set(opt, 'user', user)
        return uploadAnhuiResToAnhuiSpace(res, opt);
      })
      .then(res => {
        console.log('uploadAnhuiResToAnhuiSpace res:', res)
      })
      .catch(error => console.error)
    }

    function downloadResourceAsFile(deferred, reses) {
      var idList = _.map(reses, function(res) {
        return res.id;
      }).join(',');
      deferred.notify('正在准备下载...');
      oedCloudPresentRes.validateDownload({
        idList: idList
      }).$promise.then(function(resp) {
        var downloadUrl = oedConfig.url('cloudpresentresource/downloadresources/' + idList);
        if (resp.success) {
          deferred.resolve();
          window.open(downloadUrl, window._link_open_type);
        } else {
          notificationService.notify('error', resp.message)
          deferred.reject(resp);
        }
      }).catch(deferred.reject);
    }

    function downloadResourceItemsAsFile(deferred, reses) {
      var idList = _.map(reses, 'id');
      var urlPart = $httpParamSerializer({
        resIds: idList
      });

      deferred.notify('正在准备下载...');
      var downloadUrl = oedConfig.url_b('cloudpresentresourceitem/download?' + urlPart);
      window.open(downloadUrl, window._link_open_type);
      deferred.resolve();
    }

    $scope.importFromLibrary = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/importFromLibraryDialog.html'),
        controller: 'importFromLibraryCtrl',
        size: 'lg',
        windowClass: 'shouldHideOnPreview cl-library-dialog',
        resolve: {
          resourceDef: function() {
            return {
              name: '展示资源',
              course: $scope.course,
              res: oedCloudPresentRes,
              canImportMultiple: true,
              canImportResBox: true,
              isPreRes: true,
              headerAddonUrl: 'Assets/templates/prepareCourse/cloudPresentResourceHeaderAddon.html',
              itemTemplateUrl: 'Assets/templates/prepareCourse/cloudPresentResourceItem.html',
              queryCountURL: oedConfig.url('cloudpresentresource/querybysection/count'),
              shouldDismissParent: false,
              viewType: 'resitem',
              currentRes: $scope.currentRes,
              doPreview: function(res, resDef) {
                var def = this;
                var modalInstance = $uibModal.open({
                  template: require('assets/templates/prepareCourse/cloudPresentResPreviewer.html'),
                  controller: 'cloudPresentResPreviewerCtrl',
                  size: 'lg',
                  resolve: {
                    cloudRes: function() {
                      return res;
                    },
                    currentRes: function() {
                      return $scope.currentRes;
                    },
                    resourceDef: function() {
                      return resDef || def;
                    }
                  },
                  windowClass: 'cl-library-preview'
                });

                return modalInstance.result;
              },
              doPreviewResBox: function(items) {
                var def = this;
                var modalInstance = $uibModal.open({
                  template: require('app2/prepareCourse/resourceItemsPreviewer.html'),
                  controller: 'resourceItemsPreviewerCtrl as ctrl',
                  size: 'lg',
                  resolve: {
                    data: function() {
                      return {
                        items: items
                      };
                    },
                    resourceDef: function() {
                      return def;
                    }
                  },
                  windowClass: 'cl-library-preview'
                });

                return modalInstance.result;
              },
              importFromLibraryByResId: function(vt, data) {
                var def = _.assign(this, {
                  viewType: vt
                });
                var modalInstance = $uibModal.open({
                  template: require('assets/templates/prepareCourse/importFromLibraryByResIdDialog.html'),
                  controller: 'importFromLibraryByResIdCtrl',
                  windowClass: 'shouldHideOnPreview cl-library-dialog cl-import-by-res-dialog',
                  size: 'lg',
                  resolve: {
                    resourceDef: function() {
                      return def;
                    },
                    queryData: function() {
                      return data;
                    }
                  }
                });
                return modalInstance.result;
              },
              queryRes: function(queryOpt, viewType) {
                if (viewType === 'resource') {
                  return oedCourseUtils.queryCloudPresentRes(queryOpt);
                }

                return queryCloudPresentResItem(queryOpt);
              },
              queryResById: function(queryOpt, viewType) {
                var resId = oedCourseUtils.getCloudResTypeAndId(_.trim(queryOpt.queryId));
                if (!resId) {
                  return $q.resolve(null);
                }

                var kind = _.get(resId, 'kind', '');
                if (kind !== 'presentResource') {
                  var res = oedCloudPresentResItem.get({
                    id: resId.id
                  });
                  return res.$promise.then(function(item) {
                    return loadCloudResItemDetails(item);
                  }).catch(function() {
                    return null;
                  });
                }

                queryOpt = _.assign({}, queryOpt, {
                  queryId: resId.id
                });

                var item = oedCloudPresentRes.queryByCloudTestOrPreRedId(queryOpt);
                return item.$promise.then(oedCourseUtils.loadCloudPresentResDetails);
              },
              doImport: doImportResources,
              doDownload: function(reses, deferred, viewType) {
                if (viewType == 'resource') {
                  downloadResourceAsFile(deferred, reses);
                }else {
                  downloadResourceItemsAsFile(deferred, reses);
                }
              }
            };
          }
        }
      });
    };
    $scope.sortableOptions = {
      axis: 'y',
      stop: function(event) {
        var newOrders = _.map($scope.resources, function(m, index) {
          return {
            id: m.id,
            sort: index
          };
        });
        $scope.loading = oedCourse.updatePresentResourceOrder(newOrders).$promise.then().catch(function() {
          notificationService.notify('error', '保存失败')
        });
      }
    };

    function doImportResources(res, deferred, viewType) {
      if (_.isEmpty(res)) {
        deferred.resolve(0);
      } else {
        if (viewType === 'resource') {
          importResources(deferred, res);
        } else {
          importResourceItems(deferred, res);
        }
      }

      return deferred.promise;
    }

    $scope.importFromLibrary2 = () => {
      var modalInstance = $uibModal.open({
        template: require('app2/prepareCourse/cloud/presentResource/presentResourceLibraryDialog.html'),
        controller: 'presentResourceLibraryDialogCtrl',
        controllerAs: '$ctrl',
        size: 'full-screen',
        windowClass: 'shouldHideOnPreview cl-library-dialog2',
        resolve: {
          data: () => ({
            cancelDownload,
            course: $scope.course,
            doImport: (res, deferred) => doImportResources(res, deferred),
          })
        },
      })
    }

    function cancelDownload() {
      if (downloadOpts) {
        downloadOpts.canceled = true
      }
    }
  }

  addResourceCtrl.$inject = ['$scope', '$q', '$uibModalInstance', 'oedPresentResource', 'courseId', 'existingRes']
  function addResourceCtrl($scope, $q, $uibModalInstance, oedPresentResource, courseId, existingRes) {
    $scope.res = {};
    $scope.existingNames = _.map(existingRes, 'name');
    $scope.maxSort = _.max(_.map(existingRes, 'sort'));
    if (isNaN($scope.maxSort)) {
      $scope.maxSort = 1;
    } else {
      $scope.maxSort = $scope.maxSort + 1;
    }
    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.addResForm.$invalid) {
        return;
      }

      var res = new oedPresentResource({
        name: $scope.res.name,
        courseId: courseId,
        sort: $scope.maxSort
      });
      var p = res.$save();
      $uibModalInstance.close({
        resource: res,
        promise: p
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

  }

  editPresentResourceCtrl.$inject = ['$scope', '$q', '$uibModalInstance',
    'oedPresentResource', 'resource', 'existingRes']
  function editPresentResourceCtrl($scope, $q, $uibModalInstance,
    oedPresentResource, resource, existingRes) {
    $scope.res = {
      name: resource.name
    };

    $scope.existingNames = _(existingRes).filter(function(r) {
      return r.id != resource.id;
    }).map('name').value();

    $scope.ok = function() {
      $scope.showValidationError = true;
      if ($scope.editResForm.$invalid) {
        return;
      }

      _.assign(resource, $scope.res);
      $scope.loading = oedPresentResource.save({
        id: resource.id,
        name: resource.name
      }).$promise;
      $scope.loading.finally(function() {
        $uibModalInstance.close({});
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };

  }

  uploadResourceCtrl.$inject = ['$scope', '$q', '$uibModalInstance', '$uibModal', 'Upload', 'notify',
    'oedConfig', 'oedPresentResource', 'oedFileUtils', 'resourceId', 'url', 'notificationService', 'dialogs', 'isPrepareLesson', 'moduleId', 'packageId', 'oedDialogService']
  function uploadResourceCtrl($scope, $q, $uibModalInstance, $uibModal, Upload, notify,
    oedConfig, oedPresentResource, oedFileUtils, resourceId, url, notificationService, dialogs, isPrepareLesson, moduleId, packageId, oedDialogService) {
    $scope.res = {};

    $scope.uploadCount = 0;
    $scope.uploading = 0;
    $scope.startUpload = function() {
      if (isPrepareLesson) {
        if (packageId) {
          $scope.addItemToPackage(packageId);
        } else {
          oedPresentResource.createResourcePackge({ moduleId: moduleId}, {}).$promise.then(r => {
            return r;
          }).then(data => {
            $scope.addItemToPackage(data.id)
          });
        }
      }else{
        $scope.upLoading();
      }
    };
    $scope.addItemToPackage = (id) => {
      $scope.packgeId = id;
      oedPresentResource.get({ id: id }).$promise.then(x => {
        url = oedConfig.url('presentresource/' + x.id + '/uploadresource');
        $scope.upLoading();
      })
    }
    $scope.upLoading = function() {
            //
      // 正在上传
      if ($scope.uploading > 0) {
        return;
      }
      var allFiles = $scope.handledFiles;
      if(oedFileUtils.hasBigFile(allFiles)){
        return dialogs.error('无法上传', '无法上传超过500M的文件，请检查。');
      }
      $scope.loadingMessage = '正在上传';

      var uploadFile = function(file) {
        $scope.uploading++;
        return $q(function(resolve, reject) {
          Upload.upload({
            url: url,
            file: file
          }).progress(function(evt) {
            var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
            file.progress = progressPercentage;
          }).success(function(data, status, headers, config) {
            file.progress = 110;
            $scope.uploading--;
            $scope.uploadCount++;
          }).error(function() {
            $scope.uploading--;
          }).finally(function() {
            resolve();
          });
        });
      };

      var filesToUpload = _(allFiles).filter(function(f) {
        var info = _.keyBy(f.validateResults, 'level');
        return !_.has(info, 'error');
      });

      if (filesToUpload.isEmpty()) {
        return notificationService.notify('info', '没有要上传的文件, 请选择文件后再点击上传')
      }

      function uploadAll(files, upload) {
        $scope.loading = Promise.mapSeries(files, function(f) {
          if (f.progress) return null;
          return uploadFile(f);
        }).then(function() {
          if (isPrepareLesson) {
            $uibModalInstance.close({
              uploaded: $scope.uploadCount,
              packgeId:$scope.packgeId
            });
          } else {
            $uibModalInstance.close({
              uploaded: $scope.uploadCount
            });
          }
        }).finally(function() {});
      }

      if (_.some(filesToUpload.value(), (f) => _.some(resourceUtils.readOnlySuffix, suffix => f.name.endsWith(suffix)))) {
        oedDialogService.openModalWithComponent('OfficeUploadTipComponent', {files: filesToUpload.value()}, (files) => {uploadAll(files, uploadFile)}, {class: 'office-editor-modal-center'})
      } else {
        uploadAll(filesToUpload.value(), uploadFile)
      }
    }
    $scope.close = function() {
      $uibModalInstance.dismiss();
    };

    $scope.handledFiles = [];

    $scope.$watch('files', function() {
      var unhandled = _.filter($scope.files, function(f) {
        return !_.find($scope.handledFiles, function(hf) {
          return hf.lastModified == f.lastModified &&
            hf.size == f.size &&
            hf.name == f.name;
        });
      });

      _.each(unhandled, function(f) {
        var validateResults = oedFileUtils.validateFileUpload(f, {
          fileType: 'all'
        });
        f.validateResults = validateResults;
      });

      var results = _(unhandled).map('validateResults').flatten().value();
      oedFileUtils.notifyValidationReporter(results);

      $scope.handledFiles = $scope.handledFiles.concat(unhandled);
    });

    $scope.getFileCheckClass = function(f) {
      //
      // result:
      // {
      //   classes: 'error', //error, warning
      //   message: '' //
      // }
      return _.map(f.validateResults, 'classes');
    };

    $scope.getTipAtLevel = function(f, level) {
      var info = _.find(f.validateResults, function(r) {
        return r.level === level;
      });
      return _.get(info, 'message');
    };

    $scope.hasValidateInfoAtLevel = function(file, level) {
      return _.findIndex(file.validateResults, function(r) {
        return r.level === level;
      }) >= 0;
    };

    $scope.$on('modal.closing', function($event) {
      //
      // 当有文件在上传时让上传对话框不对被关闭
      if ($scope.uploading > 0) {
        $event.preventDefault();
      }
    });
  }

  fileSize.$inject = ['$sce']
  function fileSize($sce) {
    return function(text, target, otherProp) {
      var thresh = 1024;
      var bytes = parseInt(text, 10);
      if (bytes < thresh) return bytes + ' B';
      var units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      var u = -1;
      do {
        bytes /= thresh;
        ++u;
      } while (bytes >= thresh);
      return bytes.toFixed(1) + ' ' + units[u];
    };
  }
})();
