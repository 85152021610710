import { Directive, ElementRef, HostListener, Input, OnDestroy, SimpleChange } from '@angular/core';
import * as $ from 'jquery'
import * as _ from 'lodash'
import { Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[appElemScrool]'
})
export class ElemScroolDirective implements OnDestroy {

  @Input() public selectedIndex: number
  @Input() public prefix: string
  @Input() public scrollContainerCls: string

  private scrollSubject = new Subject<any>()
  private scroll$ = this.scrollSubject.asObservable()
  private scrollSubscription = new Subscription()

  constructor(
    private el: ElementRef,
  ) {
    this.scrollSubscription = this.scroll$
    .subscribe(() => {
      setTimeout(() => {
        this.scroll()
      }, 0)
    })
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.scrollSubject.next(`${this.prefix}${this.selectedIndex}`)
  }

  public ngOnDestroy() {
    this.scrollSubscription.unsubscribe()
  }

  public ngOnChanges(changes: SimpleChange) {
    if (_.has(changes, 'selectedIndex')) {
      this.scrollSubject.next(`${this.prefix}${this.selectedIndex}`)
    }
  }

  public scroll() {
    const elem = $(`#${this.prefix}_${this.selectedIndex}`)[0]
    if (elem) {
      $(`.${this.scrollContainerCls}`).animate({
        scrollLeft: elem.offsetLeft
      }, 200,
      'swing');
    }
  }
}
