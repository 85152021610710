(function() {
  const confirmDialog = require('app2/common/confirmDialog.ctrl');
  angular.module('app.manage')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('manage.updateSchoolInfo', {
        url: '/updateSchoolInfo',
        template: require('assets/templates/manage/updateSchoolInfo.html'),
        controller: 'updateSchoolInfoCtrl',
        bodyStyle: 'bg_img'
      });
    }])
    .controller('updateSchoolInfoCtrl', updateSchoolInfoCtrl);

  updateSchoolInfoCtrl.$inject = ['$rootScope', '$scope', '$state', '$stateParams',
    '$http', 'notificationService', 'moment', 'oedConfig', 'oedSchool', 'oedActivateCode', 'userInfo', '$uibModal', '$q']
  function updateSchoolInfoCtrl($rootScope, $scope, $state, $stateParams,
    $http, notificationService, moment, oedConfig, oedSchool, oedActivateCode, userInfo, $uibModal, $q) {
    $scope.currentState.title = '学校管理';

    $scope.codesDisplayRadioValue = {category: 'all'};
    $scope.pageSize = 10;
    $scope.curPage = 1;

    userInfo.then(function(info) {
      $scope.schoolId = info.schoolId;
      $q.all([$scope.reloadSchoolInfo(), $scope.loadActivateCodes()]);
    });
    $scope.shareInProgress = false;
    $scope.reloadSchoolInfo = function() {
      $scope.schoolInfo = oedSchool.get({
        id: $scope.schoolId
      });
      return $scope.schoolInfo.$promise.then(function() {
        $scope.originalSchoolInfo = _.cloneDeep($scope.schoolInfo);
        if ($scope.schoolInfo.shareStatus === 'processing')
          $scope.shareInProgress = true;
        else
          $scope.shareInProgress = false;
      });
    };

    $scope.onCodeDisplayCategoryChange = function() {
      if ($scope.codesDisplayRadioValue.category === 'activated') {
        $scope.codesDisplay = $scope.codesActivated;
      } else if ($scope.codesDisplayRadioValue.category === 'unactivated') {
        $scope.codesDisplay = $scope.codesUnactivated;
      } else {
        $scope.codesDisplay = $scope.codes;
      }

      $scope.totalNums = _.size($scope.codesDisplay);
      $scope.curPage = 1;
      $scope.codesDisplayOfCurPage = _.slice($scope.codesDisplay,
        ($scope.curPage - 1) * $scope.pageSize,
        $scope.curPage * $scope.pageSize
      );
    }

    $scope.onPageChange = function(page) {
      $scope.curPage = page;
      $scope.codesDisplayOfCurPage = _.slice(
        $scope.codesDisplay,
        ($scope.curPage - 1) * $scope.pageSize,
        $scope.curPage * $scope.pageSize
      );
    };

    $scope.loadActivateCodes = function() {
      oedActivateCode.list({
        schoolId: $scope.schoolId
      }, {
        schoolId: $scope.schoolId
      }).$promise.then(function(codes) {
        $scope.codes = _.map(codes, code => Object.assign({
          activatedDateString: code.activatedDate ? moment(code.activatedDate).format('YYYY/MM/DD HH:mm') : ''
        }, code));
        $scope.codesActivated = _.filter($scope.codes, code => _.get(code, 'activated', false) === true);
        $scope.codesUnactivated = _.filter($scope.codes, code => _.get(code, 'activated', false) === false);
        $scope.onCodeDisplayCategoryChange();
      }).catch(function(e) {
        notificationService.notify('error', '获取激活码失败')
      });
    }

    $scope.schoolTypes = ['小学', '中学', '初中', '高中', '中小学', '中职', '其他'];
    $scope.doUpdate = function() {
      $scope.schoolInfo.approve = +$scope.schoolInfo.approve;
      $scope.schoolInfo.shareResource = $scope.schoolInfo.shareResource ? 1 : 0
      const tasks = [$scope.schoolInfo.$update()];
      const updateShare = $scope.originalSchoolInfo.shareResource != $scope.schoolInfo.shareResource;
      if (updateShare) {
        tasks.push(oedSchool.updateShare({
          id: $scope.schoolInfo.id,
          share: $scope.schoolInfo.shareResource ? 1 : 0
        }, {}).$promise);
      }

      $scope.loading = $q.all(tasks).then(function() {
        $rootScope.$broadcast('oedSchoolInfoChanged');
        userInfo.update();
        $scope.reloadSchoolInfo().then(function() {
          notificationService.notify('info', updateShare ? '资源共享更新操作正在后台进行，第二天生效，请稍后查看' : '学校信息更新成功')
        });
      });
    };
    $scope.updateSettings = function(info) {
      $scope.showFormError = $scope.updateForm.$invalid;
      if ($scope.updateForm.$invalid) {
        return;
      }

      $scope.doUpdate();
    };
  }
})();
