import { CourseEditingModule } from './../course-editing/course-editing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupEditorComponent } from './group-editor/group-editor.component';
import { StdAvatarComponent } from './std-avatar/std-avatar.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SharedModule } from 'app/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExerciseBookComponent } from './exercise-book/exercise-book.component';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { manageStates } from './manage-routing.state';
import { CreatExerciseBookDialogComponent } from './exercise-book/dialog/creat-exercise-book-dialog/creat-exercise-book-dialog.component';
import { FormsModule } from '@angular/forms';
import { ImportSchoolListComponent } from './exercise-book/import-school-list/import-school-list.component';
import { ImportSchoolDialogComponent } from './exercise-book/dialog/import-school-dialog/import-school-dialog.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ExerciseBookItemComponent } from './exercise-book/exercise-book-item/exercise-book-item.component';
import { ExerciseBookInResLib } from './exercise-book/exercise-book-in-res-lib/exercise-book-in-res-lib.component';
import { ExerciseBookSelector } from './exercise-book/exercise-book-in-res-lib/exercise-book-selector/exercise-book-selector.component';
import { MenuModule } from "app/menu/menu.module";
import { ImportJsonComponent } from './exercise-book/import-json/import-json.component';
import { EditJsonComponent } from './exercise-book/edit-json/edit-json.component';
import { PreviewJsonComponent } from './exercise-book/preview-json/preview-json.component'
import { UtilsModule } from 'app/utils/utils.module';
import { QuestionJsonComponent } from './exercise-book/question-json/question-json.component';
import { FileUploadModule } from 'ng2-file-upload';
import { CommonTitleComponent } from './exercise-book/dialog/common-title/common-title.component';
import { ExMarkDialogComponent } from './exercise-book/dialog/ex-mark-dialog/ex-mark-dialog.component';
import { CommonBtnComponent } from './exercise-book/dialog/common-btn/common-btn.component';
import { ExCreatePaperDialogComponent } from './exercise-book/dialog/ex-create-paper-dialog/ex-create-paper-dialog.component';
import { ExPaperListDialogComponent } from './exercise-book/dialog/ex-paper-list-dialog/ex-paper-list-dialog.component';
import { ExMarkEditDialogComponent } from './exercise-book/dialog/ex-mark-edit-dialog/ex-mark-edit-dialog.component';
import { ExQuesEditDialogComponent } from './exercise-book/dialog/ex-ques-edit-dialog/ex-ques-edit-dialog.component';
import { ExChoiceItemDialogComponent } from './exercise-book/dialog/ex-choice-item-dialog/ex-choice-item-dialog.component';
import { ExInfoDialogComponent } from './exercise-book/dialog/ex-info-dialog/ex-info-dialog.component';
import { CommonDelBtnComponent } from './exercise-book/dialog/common-del-btn/common-del-btn.component';
import { CommonRightArrowInputComponent } from './exercise-book/dialog/common-right-arrow-input/common-right-arrow-input.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ExTreeItemComponent } from './exercise-book/dialog/ex-tree-item/ex-tree-item.component';
import { PreviewPaperItemComponent } from './exercise-book/preview-papers-list/preview-paper-item/preview-paper-item.component';
import { PreviewPapersListComponent } from './exercise-book/preview-papers-list/preview-papers-list.component'
import { PreviewQuestionsListComponent } from './exercise-book/preview-questions-list/preview-questions-list.component'
import { QuestionDesignerlDirective } from 'app/course-editing/ques-design.component';
import { OedExerciseQuestionPreviewer } from './directives/oed-exercise-question-previewer.component';
import { OedExerciseSyntheticPreviewer } from './directives/oed-exercise-synthetic-previewer.component';
import { TestPaperContentComponent } from './exercise-book/dialog/test-paper-content/test-paper-content.component';
import { EmptyContentComponent } from './exercise-book/empty-content/empty-content.component';
import { CommonDropDownComponent } from './exercise-book/dialog/common-drop-down/common-drop-down.component';
import { PreviewTreeItemComponent } from './exercise-book/preview-tree-item/preview-tree-item.component';
import { ExerciseBookQueryHeader } from './exercise-book/exercise-book-in-res-lib/exercise-book-selector/exercise-book-query-header.component';
import { TalcloudModule } from 'app/talcloud/talcloud.module';
import { DragulaModule } from 'ng2-dragula';

@NgModule({
  declarations: [
    GroupEditorComponent,
    StdAvatarComponent,
    ExerciseBookComponent,
    CreatExerciseBookDialogComponent,
    ImportSchoolListComponent,
    ImportSchoolDialogComponent,
    ExerciseBookItemComponent,
    ExerciseBookInResLib,
    ExerciseBookSelector,
    ExerciseBookQueryHeader,
    ImportJsonComponent,
    EditJsonComponent,
    PreviewJsonComponent,
    QuestionJsonComponent,
    PreviewJsonComponent,
    CommonBtnComponent,
    CommonTitleComponent,
    ExMarkDialogComponent,
    ExCreatePaperDialogComponent,
    ExPaperListDialogComponent,
    ExMarkEditDialogComponent,
    ExQuesEditDialogComponent,
    ExChoiceItemDialogComponent,
    ExInfoDialogComponent,
    CommonDelBtnComponent,
    CommonRightArrowInputComponent,
    ExTreeItemComponent,
    PreviewPaperItemComponent,
    PreviewPapersListComponent,
    PreviewQuestionsListComponent,
    OedExerciseQuestionPreviewer,
    OedExerciseSyntheticPreviewer,
    QuestionDesignerlDirective,
    TestPaperContentComponent,
    EmptyContentComponent,
    CommonDropDownComponent,
    PreviewTreeItemComponent,
  ],
  entryComponents: [
    GroupEditorComponent,
    StdAvatarComponent,
    ExerciseBookComponent,
    CreatExerciseBookDialogComponent,
    ImportSchoolListComponent,
    ImportSchoolDialogComponent,
    ExerciseBookItemComponent,
    ExerciseBookInResLib,
    ImportJsonComponent,
    EditJsonComponent,
    PreviewJsonComponent,
    QuestionJsonComponent,
    ExMarkDialogComponent,
    ExCreatePaperDialogComponent,
    ExPaperListDialogComponent,
    ExMarkEditDialogComponent,
    ExQuesEditDialogComponent,
    ExChoiceItemDialogComponent,
    ExInfoDialogComponent,
    PreviewPaperItemComponent,
    PreviewPapersListComponent,
    PreviewQuestionsListComponent,
    TestPaperContentComponent,
    EmptyContentComponent,
    PreviewTreeItemComponent,
  ],
  imports: [
    CommonModule,
    DragDropModule,
    TooltipModule.forRoot(),
    SharedModule,
    UIRouterUpgradeModule.forChild({ states: manageStates, }),
    FormsModule,
    BsDropdownModule.forRoot(),
    MenuModule,
    UtilsModule,
    FileUploadModule,
    PaginationModule,
    CourseEditingModule,
    TalcloudModule,
    DragulaModule.forRoot(),
  ],
  exports: [ QuestionDesignerlDirective, ExerciseBookSelector, ExerciseBookQueryHeader ],
})
export class ManageModule { }
