import { isQuickWayToCreatePrepareCourse } from 'app2/prepareCourse/utils/prepareCourseUtils'

angular.module('app.directives.review.objective.votingChartSummary', [])
  .directive('votingChartSummary', votingChartSummary);

votingChartSummary.$inject = ['$document', '$timeout']
function votingChartSummary($document, $timeout) {
  return {
    restrict: 'E',
    scope: {
      testSession: '<',
      taskId: '<',
      recordId: '<',
      origTestId: '<',
      origTaskListId: '<',
      isQuickCreateObjTest: '<',
      objTest: '<',
      students: '<',
      classSessionId: '<',
      extraInfo: '<'
    },
    template: require('./votingChartSummary.html'),
    controller: votingChartSummaryCtrl
  };
}

votingChartSummaryCtrl.$inject = ['$q', '$scope', '$uibModal', 'oedTestUtils', 'oedTestSession', '$state']
function votingChartSummaryCtrl($q, $scope, $uibModal, oedTestUtils, oedTestSession, $state) {

  console.warn('extraInfo', $scope.extraInfo)

  $scope.data = [{
    'key': '正确',
    values: [],
    color: '#469e24' //color - optional: choose your own line color.
  }, {
    key: '犹豫/半对',
    color: '#f0c46e',
    values: []
  }, {
    key: '订正',
    color: '#d56215',
    values: []
  }, {
    key: '错误',
    color: '#a70d0d',
    values: []
  }];

  $scope.barChartOptions = {
    'chart': {
      type: 'multiBarHorizontalChart',
      height: 100,
      showControls: false,
      showValues: true,
      stacked: true,
      noData: '没有数据',
      groupSpacing: 0.4,
      margin: {
        'top': 0,
        'bottom': 0,
        left: 100
      },
      valueFormat: d3.format('d'),
      'transitionDuration': 500,
      'xAxis': {
        'showMaxMin': false
      },
      x: function(d) {
        return d.label;
      },
      y: function(d) {
        return d.value;
      },
      callback: function(chart) {
        // d3.selectAll('.nvd3 .nv-x.nv-axis g.tick:nth-child(1)').style('fill', "yellow");
        d3.selectAll('.obj-test-summary-rate svg').style('overflow', 'visible');
        d3.selectAll('.obj-test-summary-rate rect').style('cursor', 'pointer');
      },
      yAxis: {
        tickFormat: function(d) {
          return d < 1 ? 0 : d3.format('d')(d);
        }
      },
      multibar: {
        dispatch: {
          elementClick: function(e) {
            var idx = e.index; // 点击的题目的index
            var modalInstance = $uibModal.open({
              template: require('assets/templates/review/questionAnswerDetails.html'),
              controller: 'questionAnswerDetailsCtrl',
              size: 'lg',
              windowClass: 'question-details-modal modalCenter',
              resolve: {
                data: function() {
                  return {
                    sessionType: 1,
                    sessionId: $scope.testSession.id,
                    questionId: $scope.stats.questionStats[idx].questionId,
                    index: $scope.isQuickCreateObjTest ? _.parseInt(idx + 1) : $scope.currentLabels[idx],
                    objTest: $scope.objTest,
                    deviceStat: $scope.deviceStat,
                    students: $scope.students,
                    classSessionId: $scope.classSessionId,
                    isSinglevote: $scope.isSinglevote
                  };
                }
              }
            });

            modalInstance.result.then(function(dirty) {
              if (dirty) {
                $state.reload();
              }
            });
          }
        }
      }
    }
  }

  $scope.loading = loadTestSessionStats($scope.testSession);

  function loadFormResize(stats, signleVoteAnswer) {
    $scope.barChartOptions.chart.height = 30 + _.size(stats.questionStats) * 60;
    $scope.barChartOptions = _.clone($scope.barChartOptions)
    let maxChoiceCountForVote = 0;
    const labels = oedTestUtils.getTestStatLabel(stats.questionStats);
    $scope.currentLabels = labels;
    for (var k = 0; k < stats.questionStats.length; k++) {
      var type = stats.questionStats[k].question.type;
      if (type === 'singlevote' || type === 'multivote' || type === 'yesorno') {
        maxChoiceCountForVote = Math.max(maxChoiceCountForVote, stats.questionStats[k].question.choices.length);
      }
    }

    if ($scope.extraInfo === 'singlechoice') {
      for (var j = 0; j < maxChoiceCountForVote; j++) {
        const colorStr = (j % 2 == 1) ? '#469e24' : '#A5E14D';
        $scope.data.push({
          key: '选项' + String.fromCharCode(65 + j),
          color: colorStr,
          values: []
        });
      }
    } else {
      const yesColor = isQuickWayToCreatePrepareCourse($scope.objTest) ? '#469e24' : '#a70d0d'
      const noColor = isQuickWayToCreatePrepareCourse($scope.objTest) ?  '#a70d0d' : '#469e24'
      $scope.data.push({
        key: '是',
        color: yesColor,
        values: []
      });
      $scope.data.push({
        key: '否',
        color: noColor,
        values: []
      });
    }

    for (var idx = 0; idx < stats.questionStats.length; idx++) {
      const stat = stats.questionStats[idx];
      const ques = stat.question;
      const answered = stat.question.answer;
      const quesType = ques.type;

      if (_.parseInt(answered) < 0) {
        for (var i = 0; i < $scope.data.length; i++) {
          if (i - 4 < _.size(signleVoteAnswer[idx])) {
            let count = 0;
            if (_.size(signleVoteAnswer[idx][i - 4]) > 0) {
              count = _.size(signleVoteAnswer[idx][i - 4]);
            }
            $scope.data[i].values.push({
              label: _.parseInt(answered) < 0 ? '第' + _.parseInt(idx + 1) + '题（未设置答案）' : '第' + _.parseInt(idx + 1) + '题 ',
              value: count
            });
          } else {
            $scope.data[i].values.push({
              label: '第' + _.parseInt(idx + 1) + '题',
              value: 0
            });
          }
        }
      } else {
        $scope.data[0].values.push({
          label: getReviewStatus(stat) + '第' + _.parseInt(idx + 1) + '题（正确率' + getCorrectRate(stat) + '%）',
          value: stat.countAnsweredCorrectNotHesitate
        });

        $scope.data[1].values.push({
          label: getReviewStatus(stat) + '第' + _.parseInt(idx + 1) + '题（正确率' + getCorrectRate(stat) + '%）',
          value: stat.countAnsweredCorrectAndHesitate + stat.countAnsweredCorrectAndWrong
        });

        $scope.data[2].values.push({
          label: getReviewStatus(stat) + '第' + _.parseInt(idx + 1) + '题（正确率' + getCorrectRate(stat) + '%）',
          value: stat.countAnsweredPendingReview
        });

        $scope.data[3].values.push({
          label: getReviewStatus(stat) + '第' + _.parseInt(idx + 1) + '题（正确率' + getCorrectRate(stat) + '%）',
          value: stat.countAnsweredWrong
        });

        for (var ii = 4; ii < $scope.data.length; ii++) {
          $scope.data[ii].values.push({
            label: getReviewStatus(stat) + '第' + _.parseInt(idx + 1) + '题（正确率' + getCorrectRate(stat) + '%）',
            value: 0
          });
        }
      }
    }
  }

  function loadTestSessionStats(ts) {

    $scope.stats = oedTestSession.getRemoteVotingStat({
      testSessionId: ts.id,
    })

    var loadStats = $scope.stats.$promise.then(function(stats) {
      if (_.isEmpty(stats.questionStats)) {
        return;
      }
      $scope.deviceStat = stats
      $scope.signleVoteAnswer = []
        const obj = {}
        const queIds = _.map($scope.deviceStat.questionStats, 'questionId')
        const all = _.map(queIds, r => {
          return $scope.answerStat = oedTestSession.getRemoteVotingAnswerStatByOption({
            testSessionId: ts.id,
            questionId: r
          }).$promise.then((res) => {
            obj[r] = res
          })
        })
        $q.all(all).then(() => {
          _.map(queIds, r => {
            $scope.signleVoteAnswer.push(_.get(obj, r))
          })
          loadFormResize(stats, $scope.signleVoteAnswer)
        })
    });

    return loadStats;
  }

  function getCorrectRate(stat) {
    if (stat.countAnsweredTotal === 0) {
      return 0;
    }
    return (stat.countAnsweredCorrectNotHesitate * 100 / stat.countAnsweredTotal).toFixed(0);
  }

  function getReviewStatus(stat) {
    var isQa = stat.question.type === 'qa';
    var needReview = (stat.needReview || isQa) && stat.countAnsweredTotal > 0;
    if (needReview) {
      if (stat.anyBlankPendingReview || stat.qaAnyAnswerPendingReview) {
        return '(待批) ';
      }
    }

    return '';
  }
}
