import { Component, OnInit, ViewChild, Input, EventEmitter, Output, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { isEmpty } from 'lodash';

@Component({
  selector: 'app-inplace-editor',
  template: require('./inplace-editor.component.html'),
  styles: [require('./inplace-editor.component.scss')]
})
export class InplaceEditorComponent implements OnInit {
  @ViewChild('inputRef') input: ElementRef;

  @Input() ngModel: string;
  @Input() placeholder: string;
  @Input() displayText: string;
  @Input() disabled: boolean;
  @Output() ngModelChange = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<string>();
  @Output() done = new EventEmitter<string>();

  editing = false;
  canceled = false;
  editingValue = '';
  commitTimer: any;

  constructor() { }

  ngOnInit() {
  }

  isEmpty() {
    return _.isEmpty(this.ngModel)
  }

  getText() {
    if (this.displayText !== undefined) {
      return this.displayText
    }
    if (isEmpty()) {
      return this.placeholder || ''
    }
    return this.ngModel
  }

  edit($event) {
    if (this.disabled) {
      return
    }
    $event.preventDefault()
    if (this.editing) {
      return
    }
    this.editing = true
    this.canceled = false
    this.editingValue = this.ngModel
    setTimeout(() => {
      if (this.editing && this.input && this.input.nativeElement) {
        (this.input.nativeElement as any).focus()
      }
    }, 50) // 不加timeout值有可能得不到焦点
    return true
  }

  getPlaceHolder() {
    return this.placeholder || ''
  }

  onClick($event) {
  }

  onEnter($event) {
    this.commit()
  }

  onEscape($event) {
    this.canceled = true
    this.commit()
  }

  onFocus($event) {
  }

  onBlur($event) {
    this.commit()
  }

  commit() {
    if (!this.editing) {
      return
    }
    this.editing = false
    if (!this.canceled && this.editingValue !== this.ngModel) {
      this.ngModelChange.emit(this.editingValue)
    }
    if (this.canceled) {
      this.cancel.emit(this.editingValue)
    } else {
      this.done.emit(this.editingValue)
    }
    this.canceled = false
  }

  onKeyPress($event) {
  }
}
