import { Injectable } from '@angular/core';
import {RayApiService} from 'app/core/ray-api.service';
import {HttpClient, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  OnlineTrainingContentDTO, OnlineTrainingContentGoodCourseDTO, OnlineTrainingContentLinkDTO,
  OnlineTrainingContentQaDTO,
  OnlineTrainingDirectoryDTO, OnlineTrainingGoodCourseDTO, OnlineTrainingLevelDTO, OnlineTrainingQaDTO
} from 'app/online-train/models/online-train';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class OnlineTrainService {

  public ADD = 'add'
  public UPDATE = 'update'
  public DELETE = 'delete'
  public UP = 'up'
  public DOWN = 'down'
  public editType = [this.ADD, this.UPDATE, this.DELETE]

  public DIR = 'dir'
  public CONTENT1 = 'content1'
  public CONTENT2 = 'content2'
  public CONTENTLINK = 'content-link'
  public QA = 'qa'
  public CONTENTQA = 'contentQa'
  public GOOD_COURSE = 'goodCourse'
  public CONTENT_GOOD_COURSE_VIDEO = 'contentGoodCourseVideo'
  public CONTENT_GOOD_COURSE_OTHER = 'contentGoodCourseOther'
  public itemType = [this.DIR, this.CONTENT1, this.CONTENT2, this.CONTENTLINK, this.QA, this.CONTENTQA,
    this.GOOD_COURSE, this.CONTENT_GOOD_COURSE_VIDEO, this.CONTENT_GOOD_COURSE_OTHER]

  public lvl1MetaDatas = [
    {id: 2, prefix: 'concepts'},
    {id: 3, prefix: 'features'},
    {id: 4, prefix: 'scenarios'},
    {id: 5, prefix: 'excellent'},
    {id: 6, prefix: 'paradigms'},
    {id: 7, prefix: 'questions'}]

  public lvl1MetaDatasMap = _.keyBy(this.lvl1MetaDatas, (d) => d.id)

  public subjectsOfPrimary = ['语文', '英语', '数学', '科学', '美术', '音乐']
  public subjectsOfJunior = ['语文', '英语', '数学', '生物', '历史', '地理', '政治', '化学', '物理']
  public subjectsOfSenior = ['语文', '英语', '数学', '生物', '物理', '化学', '历史', '政治', '地理']

  constructor(private httpClient: HttpClient,
              private rayApi: RayApiService) { }
  public buildDirTree(allDirs) {
    const dirTree = _.reduce(allDirs, (r, dir) => {
      const subDirs = _.filter(allDirs, (d: OnlineTrainingDirectoryDTO) => d.id > 1 && d.dirId === dir.id)
      if (!_.isEmpty(subDirs)) {
        r[dir.id] = subDirs
      } else {
        r[dir.id] = []
      }

      return r
    }, {})

    return dirTree
  }

  public getWebUrl(path) {
    const hostname = window.location.hostname
    const port = window.location.port
    const idx = window.location.href.indexOf(hostname)
    const prefix = window.location.href.substring(0, idx)
    return prefix + hostname + ':' + port + path
  }

  public enhanceContent(contents, reses) {
    _.zipWith(contents, reses, (c: any, r: any) => {
      c.$$resource = r
      c.$$url = `/ray/resource/uuid/${c.$$resource.md5}/src`
      if (r.suffix === 'pdf') {
        c.$$webUrl = this.getWebUrl(c.$$url)
      } else if (r.suffix === 'docx' || r.suffix === 'pptx') {
        c.$$webUrl = this.getWebUrl(`/ray/resource/uuid/${c.$$resource.md5}/pdf`)
      }
    })
  }

  public swap(idx1, idx2, datas) {
    const temp = datas[idx1]
    datas[idx1] = datas[idx2]
    datas[idx2] = temp
  }

  public swapSort(item1, item2) {
    const temp = item1.sort
    item1.sort = item2.sort
    item2.sort = temp
  }

  public videoType(resource) {
    return resource && resource.fileType === 'video'
  }

  public imgTextType(resource) {
    return this.imgType(resource) || this.textType(resource)
  }

  public imgType(resource) {
    return resource && resource.fileType === 'image'
  }

  public textType(resource) {
    return resource && (resource.suffix === 'pdf' || resource.suffix === 'docx' || resource.suffix === 'pptx')
  }

  public getAllDirs(oem = ''): Observable<OnlineTrainingDirectoryDTO[]> {
    return this.httpClient.get<[OnlineTrainingDirectoryDTO]>(this.rayApi.apiB(`onlineTrain/directorys`), {params: {oem}})
  }

  public addDir(dir: OnlineTrainingDirectoryDTO): Observable<OnlineTrainingDirectoryDTO> {
    return  this.httpClient.post<OnlineTrainingDirectoryDTO>(this.rayApi.apiB(`onlineTrain/directory`),
      dir)
  }

  public updateDir(dir: OnlineTrainingDirectoryDTO): Observable<OnlineTrainingDirectoryDTO> {
    return  this.httpClient.post<OnlineTrainingDirectoryDTO>(this.rayApi.apiB(`onlineTrain/directory/${dir.id}`),
      dir)
  }

  public deleteDir(id: number): Observable<void> {
    return  this.httpClient.delete<void>(this.rayApi.apiB(`onlineTrain/directory/${id}`))
  }

  public getContentsByDirId(id: number): Observable<OnlineTrainingContentDTO[]> {
    return  this.httpClient.get<OnlineTrainingContentDTO[]>(this.rayApi.apiB(`onlineTrain/directory/${id}/contents`))
  }

  public addContent(content: OnlineTrainingContentDTO): Observable<OnlineTrainingContentDTO> {
    return  this.httpClient.post<OnlineTrainingContentDTO>(this.rayApi.apiB(`onlineTrain/content`),
      content)
  }

  public updateContent(content: OnlineTrainingContentDTO): Observable<OnlineTrainingContentDTO> {
    return  this.httpClient.post<OnlineTrainingContentDTO>(this.rayApi.apiB(`onlineTrain/content/${content.id}`),
      content)
  }

  public deleteContent(id): Observable<void> {
    return  this.httpClient.delete<void>(this.rayApi.apiB(`onlineTrain/content/${id}`))
  }

  public getContentLinksByDirId(id: number): Observable<OnlineTrainingContentLinkDTO[]> {
    return  this.httpClient.get<OnlineTrainingContentLinkDTO[]>(this.rayApi.apiB(`onlineTrain/directory/${id}/contentLinks`))
  }

  public addContentLink(content: OnlineTrainingContentLinkDTO): Observable<OnlineTrainingContentLinkDTO> {
    return  this.httpClient.post<OnlineTrainingContentLinkDTO>(this.rayApi.apiB(`onlineTrain/contentLink`),
      content)
  }
  public deleteContentLink(id): Observable<void> {
    return  this.httpClient.delete<void>(this.rayApi.apiB(`onlineTrain/contentLink/${id}`))
  }

  public getQasByDirId(id: number): Observable<OnlineTrainingQaDTO[]> {
    return  this.httpClient.get<OnlineTrainingQaDTO[]>(this.rayApi.apiB(`onlineTrain/directory/${id}/qas`))
  }

  public getContentsByQaId(id: number): Observable<OnlineTrainingContentQaDTO[]> {
    return  this.httpClient.get<OnlineTrainingContentQaDTO[]>(this.rayApi.apiB(`onlineTrain/qa/${id}/contents`))
  }

  public addQa(qa: OnlineTrainingQaDTO): Observable<OnlineTrainingQaDTO> {
    return  this.httpClient.post<OnlineTrainingQaDTO>(this.rayApi.apiB(`onlineTrain/qa`),
      qa)
  }

  public updateQa(qa: OnlineTrainingQaDTO): Observable<OnlineTrainingQaDTO> {
    return  this.httpClient.post<OnlineTrainingQaDTO>(this.rayApi.apiB(`onlineTrain/qa/${qa.id}`),
      qa)
  }

  public deleteQa(id: number): Observable<void> {
    return  this.httpClient.delete<void>(this.rayApi.apiB(`onlineTrain/qa/${id}`))
  }

  public addContentQa(content: OnlineTrainingContentQaDTO): Observable<OnlineTrainingContentQaDTO> {
    return  this.httpClient.post<OnlineTrainingContentQaDTO>(this.rayApi.apiB(`onlineTrain/contentQa`),
      content)
  }

  public updateContentQa(content: OnlineTrainingContentQaDTO): Observable<OnlineTrainingContentQaDTO> {
    return  this.httpClient.post<OnlineTrainingContentQaDTO>(this.rayApi.apiB(`onlineTrain/contentQa/${content.id}`),
      content)
  }

  public deleteContentQa(id: number): Observable<void> {
    return  this.httpClient.delete<void>(this.rayApi.apiB(`onlineTrain/contentQa/${id}`))
  }

  public getLevels(): Observable<OnlineTrainingLevelDTO[]> {
    return  this.httpClient.get<OnlineTrainingLevelDTO[]>(this.rayApi.apiB(`onlineTrain/levels`))
  }

  public getSubjects(): Observable<OnlineTrainingLevelDTO[]> {
    return  this.httpClient.get<OnlineTrainingLevelDTO[]>(this.rayApi.apiB(`onlineTrain/subjects`))
  }

  public getGrades(): Observable<OnlineTrainingLevelDTO[]> {
    return  this.httpClient.get<OnlineTrainingLevelDTO[]>(this.rayApi.apiB(`onlineTrain/grades`))
  }

  public getGoodCourseById(id: number): Observable<OnlineTrainingGoodCourseDTO> {
    return  this.httpClient.get<OnlineTrainingGoodCourseDTO>(this.rayApi.apiB(`onlineTrain/goodCourse/${id}`))
  }

  public queryGoodCourse(levelId: number, subjectId: number, gradeId: number): Observable<OnlineTrainingGoodCourseDTO[]> {
    return  this.httpClient.get<OnlineTrainingGoodCourseDTO[]>(this.rayApi.apiB(`onlineTrain/goodCourse/query`),
      {
        params: {
          levelId: '' + levelId,
          subjectId: '' + subjectId,
          gradeId: '' + gradeId
        }
      })
  }

  public addGoodCourse(gc: OnlineTrainingGoodCourseDTO): Observable<OnlineTrainingGoodCourseDTO> {
    return  this.httpClient.post<OnlineTrainingGoodCourseDTO>(this.rayApi.apiB(`onlineTrain/goodCourse`),
      gc)
  }

  public updateGoodCourse(gc: OnlineTrainingGoodCourseDTO): Observable<OnlineTrainingGoodCourseDTO> {
    return  this.httpClient.post<OnlineTrainingGoodCourseDTO>(this.rayApi.apiB(`onlineTrain/goodCourse/${gc.id}`),
      gc)
  }

  public deleteGoodCourse(id: number): Observable<void> {
    return  this.httpClient.delete<void>(this.rayApi.apiB(`onlineTrain/goodCourse/${id}`))
  }

  public getContentsByGoodCourseId(id: number): Observable<OnlineTrainingContentGoodCourseDTO[]> {
    return  this.httpClient.get<OnlineTrainingContentGoodCourseDTO[]>(this.rayApi.apiB(`onlineTrain/goodCourse/${id}/contents`))
  }

  public addContentGoodCourse(cgc: OnlineTrainingContentGoodCourseDTO): Observable<OnlineTrainingContentGoodCourseDTO> {
    return  this.httpClient.post<OnlineTrainingContentGoodCourseDTO>(this.rayApi.apiB(`onlineTrain/contentGoodCourse`),
      cgc)
  }

  public updateContentGoodCourse(cgc: OnlineTrainingContentGoodCourseDTO): Observable<OnlineTrainingContentGoodCourseDTO> {
    return  this.httpClient.post<OnlineTrainingContentGoodCourseDTO>(this.rayApi.apiB(`onlineTrain/contentGoodCourse/${cgc.id}`),
      cgc)
  }

  public deleteContentGoodCourse(id: number): Observable<void> {
    return  this.httpClient.delete<void>(this.rayApi.apiB(`onlineTrain/contentGoodCourse/${id}`))
  }

  public uploadFile(formData): Observable<any> {
    const req = new HttpRequest('POST', this.rayApi.apiB(`resource/create`), formData, {
      reportProgress: true,
    });
    return  this.httpClient.request<any>(req)
  }

  public getResource(id): Observable<any> {
    return this.httpClient.get<any>(this.rayApi.apiB(`resource/${id}`))
  }
}
