import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class NodeStyleService {
  public key = {
		ID_CONNECTOR : "-",
		ROOT_ID_NAME : "R",

		NODE_NAME : "nodeName",
		NODE_CHILDREN : "children",
		NODE_TYPE : "nodeType",
		NODE_LEVEL : "nodeLevel",
		NODE_CONTENT : "content",

		NODE_STYLE : "nodeStyle",
		CONTENT_STYLE: "contentStyle",
		CSS:"css",
		ATTACH_IMAGE: "attach_image",
		COLUMN:"column",
		BT_TYPE:"bt_type"

  };

  public _style_tools = [
		{
			"styleName":"对齐",
			"styleKey":"text-align",
			"toolType":"select",
			"styleVal":[
				{"ruleName":"居左","ruleVal":"left" },
				{"ruleName":"居中","ruleVal":"center" },
				{"ruleName":"居右","ruleVal":"right" }
			]
		},
		{
			"styleName":"字体",
			"styleKey":"font-family",
			"toolType":"select",
			"styleVal":[
				{"ruleName":"宋体","ruleVal":"宋体" },
				{"ruleName":"黑体","ruleVal":"黑体" }
			]
		},
		{
			"styleName":"字号",
			"styleKey":"font-size",
			"toolType":"select",
			"styleVal":[
				{"ruleName":"10","ruleVal":"10px" },
				{"ruleName":"11","ruleVal":"11px" },
				{"ruleName":"12","ruleVal":"12px" },
				{"ruleName":"14","ruleVal":"14px" },
				{"ruleName":"16","ruleVal":"16px" },
				{"ruleName":"18","ruleVal":"18px" },
				{"ruleName":"22","ruleVal":"22px" },
				{"ruleName":"24","ruleVal":"24px" },
				{"ruleName":"30","ruleVal":"30px" },
				{"ruleName":"36","ruleVal":"36px" }
			]
		},
  ];

  public _defaultCSS = {
		"chapter1": "font-size:36px;text-align:center;font-family:黑体;",
		"chapter2": "font-size:30px;text-align:left;font-family:宋体;",
		"chapter3": "font-size:24px;text-align:left;font-family:宋体;",
		"chapter4": "font-size:22px;text-align:left;font-family:宋体;",
		"chapter5": "font-size:18px;text-align:left;font-family:宋体;",
		"chapter6": "font-size:16px;text-align:left;font-family:宋体;",

		"paragraph":"",
		"question":""
	};

	public _defaultBtCss = {
		"bt1": "font-size:36px;text-align:center;font-family:黑体;",
		"bt2": "font-size:30px;text-align:center;font-family:宋体;",
		"bt3": "font-size:24px;text-align:center;font-family:宋体;",
		"bt4": "font-size:22px;text-align:left;font-family:宋体;",
		"bt5": "font-size:18px;text-align:left;font-family:宋体;",
		"bt6": "font-size:16px;text-align:left;font-family:宋体;",
		"bt7": "font-size:14px;text-align:left;font-family:宋体;",
		"bt8": "font-size:12px;text-align:left;font-family:宋体;"
	}

  constructor() { }

	// Current version.
	// this.VERSION = '1.9.1';
	// this.this.key = this.key;
	// this.style_tools = _style_tools;


	//==================== helper function

	// Get Node Object by Id.
	public getNodeById (originJSON, nodeId) {
		const list = nodeId.split(this.key.ID_CONNECTOR);
		let node = originJSON;
		for(let i=1;i<list.length;i++){
			const currentIndex = parseInt(list[i]);
			//console.log(node);
			if(i==1) node = node[currentIndex];
			else node = node[this.key.NODE_CHILDREN][currentIndex];
		}
		return node;
	}
	// Get(or calculate) Level by Id.
	public getLevelById (nodeId) {
		return nodeId.split(this.key.ID_CONNECTOR).length;
	}


	//==================== style helper
	// Get Default node style class.
	public getDefaultStyleClassByNode (node) {

		return  _.has(node, this.key.NODE_STYLE) ?
				node[this.key.NODE_STYLE] : // 如果有node_style， 则用原始值

				// 如果没有，则用根据type设定默认值
				( node[this.key.NODE_TYPE] == "chapter" ?
					// chapter的默认style会加上level
					node[this.key.NODE_TYPE] + node[this.key.NODE_LEVEL] :
					node[this.key.NODE_TYPE]);
	}

	// Get the default style of some default node_style class.
	public getDefaultCSSByClassName (className) {
		return _.has(this._defaultCSS, className) ?  this._defaultCSS[className] : "";
	}

	// Read the css value of node content
	public getCSSByNode (node) {
		if(node[this.key.NODE_TYPE] == "chapter"){
			if(_.has(node, "css")) return node["css"];
			else{
				const styleClass = _.has(node,this.key.NODE_STYLE) ? node[this.key.NODE_STYLE] : this.getDefaultStyleClassByNode(node);
				return this.getDefaultCSSByClassName(styleClass);
			}
		}else{
			return "";
		}
	}


	//====================== content style
	public hasContentStyle (node){return _.has(node, this.key.CONTENT_STYLE); }
	public getContentStyle (node){return node[this.key.CONTENT_STYLE];}


	//======================  CSS Object

	// 解析style_content中的CSS为可直接渲染的CSS String
	public resolveCSSfromObject = function(CSSObj){
		let str = "";
		_.map(CSSObj, function(cssVal,cssKey){
			str += cssKey + ":" + cssVal + ";";
		});
		return str;
	}

	// 转换CSS String为style_content中的CSS对象
	public parseCSSObject = function(str){
		const obj = {};

		const tempList = str.split(";");
		_.each(tempList, function(cssString, index, list){
			if(cssString.trim().length > 0){
				const tempCssList = cssString.trim().split(":");
				obj[tempCssList[0]] = tempCssList[1]; // 同名CSS，新的直接覆盖
			}
		});
		return obj;
	}


	// 将新cssString内容合并到旧cssString中
	public mergeCssString (originCss, newCss) {
		if(originCss == "") return newCss;
		if(newCss == "") return originCss;

		// @todo - 验证CSS合法

		const originCssObj = this.parseCSSObject(originCss);
		const newCssObj = this.parseCSSObject(newCss);

		_.map(newCssObj, function(cssVal,cssKey){
			if(cssVal ==""){ delete originCssObj[cssKey];}  // 空值删除
			else{ originCssObj[cssKey] = cssVal; }   // 同名CSS，新的直接覆盖
		});

		return this.resolveCSSfromObject(originCssObj);
	}






	// 获得某节点的CSS，如果没有就用默认的CSS
	public getCSSfromNode (node){
		let str = "";
		const hasCss = _.has(node, this.key.CONTENT_STYLE) &&
			_.has(node[this.key.CONTENT_STYLE], this.key.CSS);

		if(hasCss){
			str = this.resolveCSSfromObject(node[this.key.CONTENT_STYLE][this.key.CSS]);
		}

		if( str.length == 0 || !(hasCss)){
			let nodeStyle = ""
			if( !(_.has(node, this.key.NODE_STYLE)) || node[this.key.NODE_STYLE].length == 0){
				nodeStyle = this.getDefaultStyleClassByNode(node);
			}else{
				nodeStyle = node[this.key.NODE_STYLE];
			}
			str = this.getDefaultCSSByClassName(nodeStyle);
		}
		return str;
	}

	// ===================== attach_image - 节点配图

	// Check if node has attached image
	public hasAttachImage (node){
		return this.hasContentStyle(node) &&
			_.has(this.getContentStyle(node), this.key.ATTACH_IMAGE) &&
			((this.getContentStyle(node))[this.key.ATTACH_IMAGE]).length > 0;
	}

	// Return Attach Image List
	public getAttachImageList (node){
		if(this.hasAttachImage(node)) return node[this.key.CONTENT_STYLE][this.key.ATTACH_IMAGE];
		else return [];
	}

	public genImage (url,position){
		return {"url":url, "position":position};
	}


	// ===================== column - 分栏

	public getColumn = function(node){
		if(!this.hasContentStyle(node)) return 1;
		const obj = this.getContentStyle(node);
		return _.has(obj, this.key.COLUMN) ? obj[this.key.COLUMN] : 1;
  }

	public setColumn = function(node, columnCount){
		if(columnCount == "") return node;

		const val = parseInt(columnCount);
		if(val <= 1) return node;

		if(!this.hasContentStyle(node)) node[this.key.CONTENT_STYLE] = {};
		node[this.key.CONTENT_STYLE][this.key.COLUMN] = val > 0 ? val : 1;
		return node;
	}



	// ===================== bt - 标题
	public getBtType (node){
		const nodeType = node[this.key.NODE_TYPE];
		const btType = "";

		// 非chapter节点
		if(nodeType != "chapter"){
			if(!this.hasContentStyle(node)) return btType;
			return _.has(node, this.key.BT_TYPE) ? node[this.key.BT_TYPE] : btType;
		} else{
			const level = node[this.key.NODE_LEVEL];
      const DEFAULT_BT_TYPE = (level > 0 && level <= 8) ? "BT"+level : "";

			if(!this.hasContentStyle(node)) return DEFAULT_BT_TYPE;
			return _.has(node, this.key.BT_TYPE) ? node[this.key.BT_TYPE] : DEFAULT_BT_TYPE;
		}

		return btType;
	}
}
