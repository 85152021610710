import { Component, OnInit ,Input, SimpleChanges } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { ExerciseDTO } from '../../manage';
import { OedExerciseService } from '../oed-exercise.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-import-school-list',
  template: require('./import-school-list.component.html'),
  styles: [require('./import-school-list.component.scss')]
})
export class ImportSchoolListComponent implements OnInit {
  @Input() private selectExerciseBook: ExerciseDTO;
  bindSchoolList: any = [];

  constructor(private oedDialogService: OedDialogService,
    private oedExerciseService: OedExerciseService) { }

 ngOnChanges(changes: SimpleChanges): void {
   if(_.has(changes, 'selectExerciseBook')) {
    const exerciseId = _.get(this.selectExerciseBook, 'id');
      if(exerciseId) {
        this.oedExerciseService.getExeriseBookPositionList(exerciseId)
        .subscribe(data => {
          this.bindSchoolList = _.orderBy(data, 'position.createTime', 'desc');
        })
      }
   }

  }

  ngOnInit() {
  }


  public openImportSchoolModal (selectSchoolId = null) {
    this.oedDialogService.openModalWithComponent2('ImportSchoolDialogComponent', {
      selectExerciseBook: this.selectExerciseBook,
      selectSchoolId
    }, {
      class: 'modal-sm importExeciseBookSchool',
    }).subscribe((schoolInfo) => {
      const schoolId = _.get(schoolInfo, 'position.schoolId');
      const index = _.findIndex(this.bindSchoolList, item => _.get(item, 'position.schoolId') === schoolId);
      if(index == -1) {
        this.bindSchoolList.unshift(schoolInfo);
      } else {
        this.bindSchoolList.splice(index, 1, schoolInfo);
      }
    })
  }

  public deleteExerciseBook(item) {
    const schoolId = _.get(item, 'position.schoolId')
    this.oedDialogService.openModalWithComponent2('ExInfoDialogComponent', {
      title: '确认',
      info: '确认是否删除当前学校?',
      isConfirm: true,
      btnText: '确认',
    }, {
       class: 'ex-dialog-common' 
    }).subscribe(data => {
      if(data) {
        this.oedExerciseService.deleteExeriseBookSchool(_.get(item, 'position.id'))
        .subscribe(() => {
          const resultIndex = _.findIndex(this.bindSchoolList, ex => _.get(ex, 'position.schoolId') === schoolId);
          if(resultIndex !== -1) {
            this.bindSchoolList.splice(resultIndex, 1);
          }
        })
      }
    })
  }

}
