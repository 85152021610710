import 'app2/directives/homework/checkStudentAnswer.directive';

(function() {

  angular.module('app.review')
    .controller('interactDetailsViewerCtrl', interactDetailsViewerCtrl);

  interactDetailsViewerCtrl.$inject = ['$scope', '$uibModalInstance', '$timeout', 'notificationService', 'userInfo',
    'oedBoardContentComment', 'data', 'Lightbox', 'oedTestUtils',
    'dialogs', '$window', 'oedConfig', 'oedUrlBuilder']
  function interactDetailsViewerCtrl($scope, $uibModalInstance, $timeout, notificationService, userInfo,
                                     oedBoardContentComment, data, Lightbox, oedTestUtils,
                                     dialogs, $window, oedConfig, oedUrlBuilder) {

    var curIdx = data.index || 0;
    $scope.contents = data.interacts[curIdx].contentList;
    // 判断顶层窗口是否为iframe(配合桌面端使用)
    $scope.topWindowIsIframe = $window.top === $window.self;
    $scope.linkOpenType = $scope.topWindowIsIframe ? '_blank' : '_self';

    $scope.deleteComment = function(comment) {
      dialogs.confirm('删除', '确定要删除评论吗?').result.then(function() {
        $scope.loading = oedBoardContentComment.deleteComment({
          id: _.get(comment, 'value.id')
        }).$promise.then(() => {
          _.remove($scope.interact.commentsList, (c) => {
            return c.value.id === comment.value.id
          })
        })
      });
    }

    $scope.rebuildResource = function() {
      $scope.allRes = [];
      $scope.curIndex = 0;
      _.forEach($scope.contents, (c) => {
        //TODO 不明白什么case
        if (!_.isEmpty(c.boardContentThumbnail) || !!c.id && c.resourceUUID) {
          $scope.allRes.push({
            resourceUUID: c.contentUuid,
            fileType: 'image',
            contentId: c.id
          });
        }
        if (!_.isEmpty(c.contentBody)) {
          const contentBody = oedTestUtils.parseJson(c.contentBody, {});
          if (_.size(contentBody.resources) != 0) {
            $scope.allRes = _.concat($scope.allRes, contentBody.resources);
          }
          if (!_.isEmpty(contentBody.content)) {
            $scope.allRes.push({
              fileType: 'text',
              text: contentBody.content,
              textTrimed: _.truncate(contentBody.content, {
                length: 30
              })
            });
          }

        }
      });
    };
    $scope.rebuildResource();
    $scope.interact = data.interacts[curIdx];
    const boardSessionId = _.get($scope.contents, '0.boardSessionId');
    const content = _.get($scope.contents, '0.id');
    $scope.sortCommentList = function() {
      const commentsList = $scope.interact.commentsList;
      const teacherComments = _.filter(commentsList, isTeacherComment);
      _.remove(commentsList, isTeacherComment);
      $scope.interact.commentsList = _.concat(teacherComments, commentsList);
    };
    function isTeacherComment(comment) {
      return comment.left.roles === 'teacher' || _.has(_.keyBy(comment.left.roles), 'teacher');
    }
    $scope.isTeacherComment = function(comment) {
      return isTeacherComment(comment);
    };
    $scope.sortCommentList();

    var countInteract = {
      bravosCount: function(interact) {
        return _.size(interact.bravosList);
      },
      commentsCount: function(interact) {
        return _(interact.commentsList).filter($scope.isVisibleComment).size();
      },
      viewsCount: function(interact) {
        return _.size(interact.viewsList);
      },
      flagsCount: function(interact) {
        return _.size(interact.flagsList);
      },
      time: function(interact) {
        return interact.contentList[0].submitTime;
      }
    };

    $scope.getCountByType = function(interact, type) {
      return countInteract[type](interact);
    };

    $scope.getViewUsers = function(interact) {
      return _.map(interact.viewsList, function(v) {
        return v.left.name;
      }).join(',');
    };

    $scope.getBravoUsers = function(interact) {
      return _.map(interact.bravosList, function(v) {
        return v.left.name;
      }).join(',');
    };

    $scope.close = function() {
      $uibModalInstance.close($scope.interact);
    };

    $scope.getCommentContent = function(cmt) {
      if (!_.isEmpty(cmt.comment)) {
        return cmt.comment;
      }
      return _.get(cmt, 'commentBody.content');
    };

    userInfo.then(function(info) {
      $scope.user = info;
      $scope.toggleRank = function(rank) {
        var interact = $scope.interact;
        var curRank = _.get($scope.interact, 'student.rank');
        if (curRank === rank) {
          //
          // 取消当前评分
          rank = -1;
        }
        var commentBody = {
          rank: rank
        };

        var payload = {
          userId: info.uid,
          boardContentId: $scope.contents[0].id,
          commentBody: angular.toJson(commentBody)
        };

        $scope.loading = oedBoardContentComment.submitComment(payload).$promise.then(function() {
          interact.student.rank = rank == -1 ? null : rank;
        });
      };

      $scope.toggleBravo = function() {
        if (!boardSessionId) {
          return;
        }

        $scope.loading = oedBoardContentComment.toggleBravo({
          boardSessionId: boardSessionId,
          ownerId: $scope.interact.owner.uid,
          userId: info.uid
        }, {}).$promise.then(() => {
          var interactIdx = _.findIndex($scope.interact.bravosList, (bra) => {
            return bra.key.uid == info.uid;
          });
          if (interactIdx >= 0) {
            $scope.interact.bravosList.splice(interactIdx, 1);
          } else {
            $scope.interact.bravosList = $scope.interact.bravosList || [];
            const data = {
              boardContentId: content.id,
              userId: info.uid
            };
            $scope.interact.bravosList.push({
              key: info,
              left: info,
              value: data,
              right: data
            });
          }
        });
      };

      function updateInteracts() {
      }
    });

    $scope.nextStudent = function() {
      if (_.isEmpty(data.interacts)) {
        return;
      }

      var size = _.size(data.interacts);
      curIdx = (curIdx + size + 1) % size;
      setInteractIndex(curIdx);
    };

    $scope.prevStudent = function() {
      if (_.isEmpty(data.interacts)) {
        return;
      }

      var size = _.size(data.interacts);
      curIdx = (curIdx + size - 1) % size;
      setInteractIndex(curIdx);
    };

    function setInteractIndex(idx) {
      $scope.interact = data.interacts[idx];
      $scope.contents = data.interacts[idx].contentList;
      $scope.rebuildResource();
      $scope.sortCommentList();
    }

    $scope.isVisibleComment = function(c) {
      var cmt = c.value;
      return !_.isEmpty(cmt.comment) || !_.isEmpty(cmt.commentBody.content) ||
        !_.isEmpty(cmt.commentBody.resources) || !!cmt.imgResId || !!cmt.imgResUuid;
    };

    function parseComment(cmt) {
      try {
        return JSON.parse(cmt);
      }catch (ex) {
        return {};
      }
    }

    $scope.comment = {
    };

    const commentContentLimit = 50
    $scope.submitComment = function(cmt) {
      if (_.isEmpty($scope.comment.content) && _.isEmpty($scope.comment.resources)) {
        notificationService.notify('error', '内容为空, 请填写内容后再提交')
        return;
      }
      const commentSize = _.size(_.get($scope.comment, 'content', ''))
      if (commentSize > commentContentLimit) {
        notificationService.notify('error', `评论长度应在${commentContentLimit}字以内`)
        return
      }
      var commentBody = angular.toJson($scope.comment);
      var commentToAdd = new oedBoardContentComment({
        userId: $scope.user.uid,
        boardContentId: $scope.contents[0].id,
        commentBody: commentBody
      });

      $scope.loading = commentToAdd.$submitComment().then(function(c) {
        $scope.comment = {};
        $scope.interact.commentsList = $scope.interact.commentsList || [];
        c.commentBody = parseComment(c.commentBody);
        $scope.interact.commentsList.push({
          key: $scope.user,
          left: $scope.user,
          right: c,
          value: c
        });

        var visibleCount = _($scope.interact.commentsList).filter($scope.isVisibleComment).size();

        $scope.scrollAnchor = {
          relativeTo: 'table tbody',
          to: visibleCount,
          stamp: _.uniqueId('iscroll_')
        };

      }).finally(function() {
        $scope.showAddCommentPane = false;
        $scope.sortCommentList();
      });

      $scope.addCommentLoading = $scope.loading;
    };

    $scope.cancelEditor = function() {
      $scope.showAddCommentPane = false;
    };

    $scope.toggleAddCommentPanel = function() {
      $scope.showAddCommentPane = !$scope.showAddCommentPane;
    };
    $scope.flagsExist = function(flag) {
      return !_.isEmpty(flag);
    };
    // 下载
    $scope.downloadCurrBoard = () => {
    const downloadUrl = oedConfig.url_b('boardsession/' + boardSessionId + '/download');
    const url = oedUrlBuilder(downloadUrl, {
      studentsIds: $scope.interact.owner.uid
    });
    window.open(url, window._link_open_type);
  }
  $scope.previewItem = (items, idx) => {
    Lightbox.openModal(items, idx);
  };
 }
})();
