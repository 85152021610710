var bluebird = require('bluebird');
require('app2/utils/taskUtils');
require('app2/utils/homeworkGroupUtils');

angular.module('app.directives.homework.homeworkPreResEditor', [])
  .directive('homeworkPreResEditor', homeworkPreResEditor);

homeworkPreResEditor.$inject = ['$document', '$timeout']
function homeworkPreResEditor($document, $timeout) {
  return {
    restrict: 'E',
    scope: {
      group: '=',
      groups: '=',
      loading: '=',
      onSaveGroup: '&',
      createTasksFromLibrary: '&'
    },
    template: require('assets/app2/directives/homework/homeworkPreResEditor.html'),
    controller: homeworkPreResEditorCtrl
  };
}

homeworkPreResEditorCtrl.$inject = [
  '$scope', 'Lightbox', 'oedConfig', '$uibModal', 'oedPresentResource', '$q', 'oedPresentResourceItem', 'dialogs']
function homeworkPreResEditorCtrl($scope, Lightbox, oedConfig, $uibModal, oedPresentResource, $q,
  oedPresentResourceItem, dialogs) {
  $scope.selectedResources = {};

  $scope.hasPreresesSelected = function() {
    return !_.isEmpty($scope.selectedResources);
  };

  $scope.isSelected = function(item) {
    return _.has($scope.selectedResources, item.id);
  };

  $scope.toggleSelect = function(item, group) {
    if (_.has($scope.selectedResources, item.id)) {
      $scope.selectedResources = _.omit($scope.selectedResources, item.id);
    } else {
      $scope.selectedResources[item.id] = {
        groupId: group.$res.id,
        id: item.id,
        selected: true,
        type: group.$type
      };
    }
  };

  $scope.previewItem = function(items, idx) {
    Lightbox.openModal(items, idx);
  }

  var mapPreResEvent = {
    toggleResource: openToggleResourceDialog,
    addUrlItem: openAddUrlItemDialog,
    toggleUpload: openUploadDialog
  };

  $scope.doPreResEvent = function(group, key) {
    $scope.loading = createPresentResourceIfNeed(group).then(function(preRes) {
      group.$res.id = preRes.id;
      mapPreResEvent[key](group).then(function() {
        oedPresentResource.get({
          id: group.$res.id
        }).$promise.then(function(res) {
          group.$res.items = res.items;
          $scope.onSaveGroup();
        })
      });
    });
  }

  function createPresentResourceIfNeed(group) {
    if (group.$res.id) {
      return $q.resolve(group.$res);
    } else {
      var preRes = new oedPresentResource({
        name: group.$res.name
      });
      return preRes.$save();
    }
  }

  function openToggleResourceDialog(group) {
    var modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addMicroLessonRecordDialog.html'),
      controller: 'addMicroLessonRecordCtrl',
      size: 'lg',
      windowClass: 'new-lib-style',
      resolve: {
        data: function() {
          return {
            presentresourceId: group.$res.id
          };
        }
      }
    });

    return modalInstance.result;
  }

  function openUploadDialog(group) {

    var modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/resourceUploader.html'),
      controller: 'uploadResourceCtrl',
      size: 'md',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        resourceId: function() {
          return group.$res.id;
        },
        url: function() {
          return oedConfig.url('presentresource/' + group.$res.id + '/uploadresource');
        },
        isPrepareLesson: false,
        moduleId:null,
        packageId:null
      }
    });

    return modalInstance.result;
  }

  function openAddUrlItemDialog(group) {
    var modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addWebUrlItem.html'),
      controller: 'addWebUrlItemCtrl',
      size: 'md',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        data: function() {
          return {
            resourceId: group.$res.id
          };
        }
      }
    });

    return modalInstance.result;
  }

  $scope.deletePreresesSelected = function(group) {
    var message = '确定要删除展示资源吗（共' + _.size($scope.selectedResources) + '题）？';
    var dlg = dialogs.confirm('确定删除?', message);
    dlg.result.then(function() {
      var deleteAll = _.map($scope.selectedResources, deletePresentResourceItem);
      $scope.loading = $q.all(deleteAll).then(function() {
        group.$res.items = _.filter(group.$res.items, function(q) {
          return !$scope.isSelected(q);
        });
        $scope.selectedResources = {};
        if (_.isEmpty(group.$res.items)) {
          group.$res.$cloudRes.id = undefined
        }
      });
    });
  };

  function deletePresentResourceItem(item) {
    return oedPresentResourceItem.delete({
      id: item.id
    }).$promise;
  }

}
