import * as _ from 'lodash'
require('assets/app/controllers/recycleBinDialog/recycleBinDialog.scss')
require('assets/app/controllers/examination/examCardList/examCard/examCardCtrl')
require('assets/app/controllers/recycleBinDialog/reExamCard/reExamCardCtrl')
require('assets/app/controllers/recycleBinDialog/rePreCard/rePreCardCtrl')

angular.module('app.prepareCourse.recycleBinDialog', [
    'app.examination.examCardList.examCard',
    'app.recycle.reExamCard',
    'app.recycle.rePreCard',
  ])
  .controller('recycleBinDialogCtrl', recycleBinDialogCtrl)

export enum RecycleBinTab { PrepareLesson, HomeWork, Exam }
recycleBinDialogCtrl.$inject = ['$scope', '$uibModalInstance', 'oedCoursePath', 'oedCourse',
'oedExamination', 'userInfo', 'dialogs', 'notificationService', 'cpId']
function recycleBinDialogCtrl($scope, $uibModalInstance, oedCoursePath, oedCourse,
  oedExamination, userInfo, dialogs, notificationService, cpId) {
  $scope.currentTab = -1
  $scope.selectCpId = -1
  $scope.selectCpIdx = 0
  $scope.curCpsPage = 0
  $scope.swiper = {swiper: {}}
  $scope.swiperParams = {
    slidesPerView: 'auto',
    mousewheelControl: true,
  }

  $scope.loading = initRecycle()

  function initRecycle() {
    userInfo.then((info) => {
      const isResearcher = _.findIndex(_.get(info, 'roles', []), r => r === 'researcher') !== -1;
      if (isResearcher) {
        // 教研老师打开回收站默认切换到考试tab
        $scope.taggleTab(RecycleBinTab.Exam)
      } else {
        // 普通老师进入回收站默认切换到作业tab
        $scope.taggleTab(RecycleBinTab.PrepareLesson)
      }
    })
  }

  function clearAllCardData() {
    $scope.preCardDatas = []
    $scope.hwCardDatas = []
    $scope.examCardDatas = []
  }

  $scope.isShowEmpty = () => {
    switch ($scope.currentTab) {
      case RecycleBinTab.PrepareLesson:
        return _.size($scope.preCardDatas) <= 0
      case RecycleBinTab.HomeWork:
        return _.size($scope.hwCardDatas) <= 0
      case RecycleBinTab.Exam:
        return _.size($scope.examCardDatas) <= 0
    }
  }

  function loadPreCps() {
    $scope.loading = oedCoursePath.queryDeleteFilter().$promise
      .then((preCps: any) => {
        if (_.size(preCps) === 0) {
          $scope.preCardDatas = []
          return
        }
        buildCoursePaths(preCps)
      })
  }

  function loadHwCps() {
    // 作业暂无回收站
  }

  function loadExamCps() {
    $scope.loading = oedExamination.getCoursePathForRecycled().$promise
      .then((examCps: any) => {
        if (_.size(examCps) === 0) {
          $scope.examCardDatas = []
          return
        }
        buildCoursePaths(examCps)
      })
  }

  function buildCoursePaths(cps: any[]) {
    $scope.coursePaths = _.sortBy(cps, cp => _.get(cp, 'sort', 0))
    let selectedCp = _.find(cps, cp => _.get(cp, 'id', 0) == cpId)
    if (_.isEmpty(selectedCp)) {
      selectedCp = $scope.coursePaths[0]
    }
    $scope.doSelectCp(selectedCp)
    $scope.toTopCpsPage()
  }

  function loadCps() {
    switch ($scope.currentTab) {
      case RecycleBinTab.PrepareLesson:
        return loadPreCps()
      case RecycleBinTab.HomeWork:
        return loadHwCps()
      case RecycleBinTab.Exam:
        return loadExamCps()
    }
  }

  function loadPreCardData() {
    $scope.loading = oedCourse.queryDeleteCourseByPath({ id: $scope.selectCpId }).$promise
      .then((preCds: any) => {
        if (_.size(preCds) === 0) {
          loadPreCps()
          return
        }
        // 按照删除时间倒叙排列卡片
        $scope.preCardDatas = _.sortBy(preCds, card => -_.get(card, 'createDate', 0))
      })
  }

  function loadHwCardData() {
    // 作业暂无回收站
  }

  function loadExamCardData() {
    $scope.loading = oedExamination.getRecycledList({ coursepathId: $scope.selectCpId }).$promise
      .then((examCds: any) => {
        if (_.size(examCds) === 0) {
          loadExamCps()
          return
        }
        // 按照删除时间倒叙排列卡片
        $scope.examCardDatas = _.sortBy(examCds, card => -_.get(card, 'exam.lastUpdateTime', 0))
      })
  }

  $scope.loadCardData = () => {
    switch ($scope.currentTab) {
      case RecycleBinTab.PrepareLesson:
        return loadPreCardData()
      case RecycleBinTab.HomeWork:
        return loadHwCardData()
      case RecycleBinTab.Exam:
        return loadExamCardData()
    }
  }

  $scope.isCpSelcet = (cp: any) => {
    return $scope.selectCpId === _.get(cp, 'id', 0)
  }

  $scope.taggleTab = (tab: number) => {
    if ($scope.currentTab === tab) {
      return
    }
    $scope.currentTab = tab
    clearAllCardData()
    loadCps()
  }

  $scope.doSelectCp = (cp: any) => {
    $scope.selectCpId = _.get(cp, 'id', 0)
    $scope.selectCpIdx = _.findIndex($scope.coursePaths, cp => _.get(cp, 'id', 0) == $scope.selectCpId)
    $scope.loadCardData()
  }

  $scope.getMaxCpsPage = () => {
    const resSlides = $('#res-slides').width()
    // 120是列表左右的margin和，180为一个item的宽度
    return Math.ceil(((_.size($scope.coursePaths) * 180) -  (resSlides - 120)) / 180)
  }

  $scope.toPreCpsPage = () => {
    if ($scope.curCpsPage <= 0) {
      return
    }
    $scope.curCpsPage -= 1
    $scope.swiper.swiper.slideTo($scope.curCpsPage)
  }

  $scope.toNextCpsPage = () => {
    if ($scope.curCpsPage >= $scope.getMaxCpsPage()) {
      return
    }
    $scope.curCpsPage += 1
    $scope.swiper.swiper.slideTo($scope.curCpsPage)
  }

  $scope.toTopCpsPage = () => {
    $scope.curCpsPage = 0
    $scope.swiper.swiper.slideTo(0)
  }

  $scope.toBottomCpsPage = () => {
    $scope.curCpsPage = $scope.getMaxCpsPage()
    $scope.swiper.swiper.slideTo($scope.getMaxCpsPage())
  }

  $scope.cancel = () => {
    $uibModalInstance.close(true)
  };

  $scope.onDeleteExam = (data: any) => {
    const cardName = _.get(data, 'exam.name', '')
    const examId = _.get(data, 'exam.id', 0)
    const dlg = dialogs.confirm('确定彻底删除?', `确定要彻底删除试卷:${cardName}吗？`);
    dlg.result.then(() => {
      $scope.loading = oedExamination.deleteExamTestComplete({ examId }).$promise
        .then(() => {
          $scope.loadCardData()
          notificationService.notify('info', '删除成功')
        }
      )
    })
  }

  $scope.onRecoveryExam = (data: any) => {
    const cardName = _.get(data, 'exam.name', '')
    const examId = _.get(data, 'exam.id', 0)
    const dlg = dialogs.confirm('确定恢复?', `确定要恢复试卷${cardName}吗？`);
    dlg.result.then(() => {
      $scope.loading = oedExamination.restoreExamTest({ examId }, {}).$promise
        .then(() => {
          $scope.loadCardData()
          notificationService.notify('info', '恢复成功')
        }).catch((e) => {
        const message = _.get(e, 'data.message', '')
        if (message === 'exam/nameExists') {
          notificationService.notify('error', '已经创建的试卷里有同名试卷，请修改。', '', 3000)
          return
        }
        notificationService.notify('error', '恢复试卷出错，请稍后再试')
      })
    })
  }

  $scope.onDeletePre = (data: any) => {
    const name = _.get(data, 'courseName', '')
    const id = _.get(data, 'id', 0)
    const dlg = dialogs.confirm('确定彻底删除?', `确定要彻底删除课程:${name}吗？`);
    dlg.result.then(() => {
      $scope.loading = oedCourse.finalDeleteCourse({ id }).$promise
        .then(() => {
          $scope.loadCardData()
          notificationService.notify('info', '删除成功')
        }
      )
    })
  }

  $scope.onRecoveryPre = (data: any) => {
    const name = _.get(data, 'courseName', '')
    const id = _.get(data, 'id', 0)
    const dlg = dialogs.confirm('确定恢复?', `确定要恢复课程${name}吗？`);
    dlg.result.then(() => {
      $scope.loading = oedCourse.recoveryCourse({ id }).$promise
        .then(() => {
          $scope.loadCardData()
          notificationService.notify('info', '恢复成功')
        }
      )
    })
  }
}
