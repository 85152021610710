import * as _ from 'lodash';

import {defaultChildQuestionList, isQuickWayToCreatePrepareCourse} from 'app2/prepareCourse/utils/prepareCourseUtils';
import Promise = require('bluebird');
import * as moment from 'moment';
const boardUtils: any = require('app2/utils/boardUtils');
const resourceUtils: any = require('app2/utils/resourceUtils');
require('app2/prepareCourse/cloud/subjectiveTest/subjectiveTestLibraryDialog.ctrl')
const {confirmAndDownloadSubjectiveTest, importSubjectTestModel} = require('app2/utils/testUtils2')
require('./newPrepareCenter.scss')
const {importPresentResourceItems} = require('app2/utils/resourceUtils')
const quickPrepare = require('assets/app2/prepareCourse/utils/prepareCourseUtils')
const url: any = require('url');
const nobookHost = 'school.nobook.com';
const nobookUtils: any = require('app2/utils/nobookUtils');

export const newPrepareCenter = {
  bindings: {
    courseId: '<',
  },
  controller: newPrepareCenterCtrl,
  template: <string>require('./newPrepareCenter.html'),
}

angular.module('app.newPrepareCourse2.newPrepareCenter', [

]).controller('uploadResCtrl2', uploadResCtrl2)

  .component('newPrepareCenter', newPrepareCenter).controller('selectedModuleCtrl', selectedModuleCtrl);

newPrepareCenterCtrl.$inject = [
  '$scope',
  '$window',
  '$http',
  '$q',
  '$interval',
  '$sce',
  '$log',
  '$uibModal',
  '$state',
  '$timeout',
  'notify',
  'dialogs',
  'Upload',
  'Lightbox',
  'userInfo',
  'oedFileUtils',
  'oedConfig',
  'oedMisc',
  'oedUnitItem',
  'localStorageService',
  'notificationService',
  'communicateService',
  '$rootScope',
  'hlsResource',
  'oedPreResItem',
  'oedCourse',
  'oedPresentResource',
  'oedCloudPresentRes',
  'oedCloudPresentResItem',
  'oedPresentResourceItem',
  'oedResDownloadTask',
  'oedSubjectiveTest',
  'oedObjectiveTest',
  'oedNewPrepareCourse',
  'cloundApi',
  'oedResource',
  'oedObjectiveQuestion',
  'oedCloudQuestion',
  'oedCloudTest',
  'oedDialogService'
];
function newPrepareCenterCtrl(
  $scope,
  $window,
  $http,
  $q,
  $interval,
  $sce,
  $log,
  $uibModal,
  $state,
  $timeout,
  notify,
  dialogs,
  Upload,
  Lightbox,
  userInfo,
  oedFileUtils,
  oedConfig,
  oedMisc,
  oedUnitItem,
  localStorageService,
  notificationService,
  communicateService,
  $rootScope,
  hlsResource,
  oedPreResItem,
  oedCourse,
  oedPresentResource,
  oedCloudPresentRes,
  oedCloudPresentResItem,
  oedPresentResourceItem,
  oedResDownloadTask,
  oedSubjectiveTest,
  oedObjectiveTest,
  oedNewPrepareCourse,
  cloundApi,
  oedResource,
  oedObjectiveQuestion,
  oedCloudQuestion,
  oedCloudTest,
  oedDialogService
) {
  const ctrl = this;
  const downloadOpts = null;
  ctrl.resDetail = {};
  ctrl.resTitle = '';
  ctrl.content = null;
  ctrl.isFirst = false;
  ctrl.messageScription = null;
  ctrl.course = null;
  ctrl.defaultFontSize = 12;
  ctrl.isFullScreen = false;
  ctrl.isEffective = true;
  ctrl.showDXA = false;
  ctrl.selectBtnType = 'teaching';
  ctrl.topWindowIsIframe = $window.top === $window.self;
  ctrl.$onInit = () => {
    communicateService.sendSelectBtnTypeMessage({type: ctrl.selectBtnType});
    ctrl.newPrepareNeedPointOut = localStorageService.get('newPrepareNeedPointOut');

    if (ctrl.newPrepareNeedPointOut) {
      notificationService.newPrepareNotify('如果您做了修改，将会为您创建一份"自建课程"副本', '', 5000);
    }
  };

  userInfo.then((info) => {
    ctrl.userInfo = info;
    const showIds = ['4255596', '981491', '981952', '1'];
    if (_.includes(showIds, info.schoolId)) {
      ctrl.showDXA = true;
    } else {
      ctrl.showDXA = false;
    }
  });

  ctrl.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'courseId')) {
      ctrl.content = null;
      ctrl.isFirst = true;
      ctrl.course = oedCourse.get({
        id: ctrl.courseId
      })
    }
  };
  ctrl.selectResourceType = (type) => {
    if (ctrl.selectBtnType !== type) {
      ctrl.selectBtnType = type;
      ctrl.content = null;
      communicateService.sendSelectBtnTypeMessage({type: ctrl.selectBtnType});
    }
  }
  // ctrl.enlargeFontSize = () => {
  //   if (ctrl.defaultFontSize < 30) {
  //     ctrl.defaultFontSize += 2;
  //     localStorageService.set('defaultSize_' + ctrl.userInfo.uid, ctrl.defaultFontSize);
  //   }
  // }
  // ctrl.narrowFontSize = () => {
  //   if (ctrl.defaultFontSize > 12) {
  //     ctrl.defaultFontSize -= 2;
  //     localStorageService.set('defaultSize_' + ctrl.userInfo.uid, ctrl.defaultFontSize);
  //   }
  // }
  ctrl.setfullScreen = () => {
    ctrl.isFullScreen = !ctrl.isFullScreen;
    communicateService.sendExpandMessage({isFullScreen: ctrl.isFullScreen});
  }
  ctrl.$onDestroy = () => {
    ctrl.messageScription.unsubscribe();
  }
  ctrl.isExternalResource = (res: any) => {
    if (!res) {
      return;
    }
    return res.isExternal && _.isEmpty(res.resourceUuid)
  }

  ctrl.fullScreen = (id) => {
    communicateService.fullScreen(id);
  }
  if (!ctrl.isFirst) {
    communicateService.getTeachingPatternId().subscribe((res: any) => {
      ctrl.tpId = res.tpId;
    });
    communicateService.getSelectModuleMessage().subscribe((res: any) => {
      ctrl.currentModule = res.module;
    })
    ctrl.messageScription = communicateService.getMessage().subscribe((res: any) => {
      ctrl.content = null;
      if (!res.data) {
        if (res.sourceFrom === 'left') {
          ctrl.selectBtnType = 'teaching';
          communicateService.sendSelectBtnTypeMessage({type: ctrl.selectBtnType});
        }
        return;
      }
      ctrl.content = res.data;
      ctrl.leftIndex = localStorageService.get('memoryIndex');
      ctrl.isFirst = false;
      ctrl.sourceFrom = res.sourceFrom;
      ctrl.needClick = res.needClick;
      if (res.sourceFrom === 'left') {
        ctrl.memoryHandleId = res.data.id;
        ctrl.isTeaching = true;
      }
      if (ctrl.content.resoureType === 'presentPackage') {
        if (res.sourceFrom === 'left') {
          ctrl.selectBtnType = 'present';
          communicateService.sendSelectBtnTypeMessage({type: ctrl.selectBtnType});
        }
        ctrl.curResId = ctrl.content.resDetail.id;
        ctrl.currentRes = ctrl.content.resDetail;
        ctrl.resource = ctrl.content.resDetail;
        ctrl.resources = ctrl.content.resDetail.items;
      } else if (ctrl.content.resoureType === 'singleResource') {
        if (res.sourceFrom === 'left') {
          ctrl.selectBtnType = 'present';
          ctrl.showBackBtn = ctrl.content.resoureType === 'singleResource';
          communicateService.sendSelectBtnTypeMessage({type: ctrl.selectBtnType});
        } else {
          ctrl.showBackBtn = ctrl.content.resoureType === 'singleResource' && res.isTeaching;
        }
        if (ctrl.content.isWeiKe) {
          ctrl.singleResourceImage = ctrl.content.resDetail;
        } else {
          ctrl.singleResourceImages = ctrl.content.resDetail.items;
          ctrl.singleResourceIndex = ctrl.content.resIndex;
          ctrl.singleResourceImage = ctrl.singleResourceImages[ctrl.singleResourceIndex];
          oedCloudPresentResItem.getByResItemId({
            id: ctrl.singleResourceImage.id
          }).$promise.then((ci) => {
            ctrl.cloudRes = ci;
          })
        }
        if (ctrl.getResTypeClass(ctrl.singleResourceImage.flResource.suffix) === 'office') {
          oedNewPrepareCourse.getUuid({
            uuid: ctrl.singleResourceImage.resourceUuid
          }, {}).$promise.then((r: any) => {
            ctrl.transcoding = false;
          }, () => {
            ctrl.transcoding = true;
          });
        }
        ctrl.targetPath = null;
        if (ctrl.content.isWeiKe) {
          ctrl.resTitle = ctrl.singleResourceImage.resourceName;
          ctrl.singleResourceImageUrl = '/ray/resource/uuid/' + ctrl.singleResourceImage.resourceUuid + '/src';
        } else {
          updateImageInfo(ctrl.singleResourceImage);
        }
      } else if (ctrl.content.resoureType === 'objective') {
        ctrl.objectiveTest = null;
        if (res.sourceFrom === 'left') {
          ctrl.selectBtnType = 'objective';
          communicateService.sendSelectBtnTypeMessage({type: ctrl.selectBtnType});
        }
        // let setedFontSize = localStorageService.get('defaultSize_' + ctrl.userInfo.uid);
        // if (setedFontSize) {
        //   ctrl.defaultFontSize = setedFontSize;
        // }
        ctrl.cloudTest = ctrl.content.resDetail;
        ctrl.titleName = ctrl.cloudTest.name + ' - 预览';
        oedCloudTest.queryByTestIds({
          testIds: [ctrl.cloudTest.id]
        }).$promise.then((ct: any) => {
          ctrl.cloudTests = ct;
        });
        ctrl.loading = oedObjectiveTest.get({id: ctrl.cloudTest.id}).$promise.then((obj: any) => {
          if (obj.testDisplayType === 1) {
            ctrl.handleData(obj);
          }
          ctrl.objectiveTest = obj;
          if (ctrl.getTheWayWithCreateTest()) {
            ctrl.childQuestionList = ctrl.objectiveTest.questions[0].childQuestions;
            ctrl.imageRes = _.uniq(ctrl.objectiveTest.questions[0]['resourceUUIDs'])
          }
          if (ctrl.objectiveTest.isTemp) {
            return oedObjectiveTest.getObjTestQuestionType({id: ctrl.objectiveTest.id}).$promise.then((r) => {
              if (r && r.testId && r.extraInfo === 'yesorno') {
                ctrl.extraInfo = 'yesorno'
              } else {
                ctrl.extraInfo = 'singlechoice'
              }
            })
          }
        });
        if (ctrl.needClick) {
          ctrl.useResource(ctrl.content.resDetail, 'objective')
        }
      } else if (ctrl.content.resoureType === 'subjective') {
        ctrl.subjectiveTest = null;
        if (res.sourceFrom === 'left') {
          ctrl.selectBtnType = 'subjective';
          communicateService.sendSelectBtnTypeMessage({type: ctrl.selectBtnType});
        }
        ctrl.cloudTest = ctrl.content.resDetail;
        ctrl.titleName = ctrl.cloudTest.name + ' - 预览';
        ctrl.cloudTests = oedCloudTest.queryByTestIds({
          testIds: [ctrl.cloudTest.id]
        });
        oedSubjectiveTest.get({
          id: ctrl.cloudTest.id
        }).$promise.then((subj: any) => {
          ctrl.subjectiveTest = subj;
          ctrl.subjectiveTest.question.positionDetails = true;
          ctrl.deserializePosition();
        });
        if (ctrl.needClick) {
          ctrl.useResource(ctrl.content, 'subjective')
        }
      } else if (ctrl.content.resoureType === 'zhonglian') {
        if (res.sourceFrom === 'left') {
          ctrl.selectBtnType = 'present';
          communicateService.sendSelectBtnTypeMessage({type: ctrl.selectBtnType});
        }
        ctrl.resTitle = ctrl.content.title;
        ctrl.contentResType = ctrl.content.suffix;
        if (ctrl.getResTypeClass(ctrl.content.suffix) === 'office') {
          oedNewPrepareCourse.getUuid({
            uuid: ctrl.content.resourceUuid
          }, {}).$promise.then((r: any) => {
            ctrl.transcoding = false;
          }, () => {
            ctrl.transcoding = true;
          });
          ctrl.contentSrc = '/ray/resource/uuid/' + ctrl.content.resourceUuid + '/pdf'
        } else {
          ctrl.contentSrc = '/ray/resource/uuid/' + ctrl.content.resourceUuid + '/src';
        }
        ctrl.iframeUrl = $sce.trustAsResourceUrl(ctrl.contentSrc);
        if (ctrl.content.suffix === 'mp4') {
          ctrl.contentSrc = ctrl.contentSrc + '.mp4';
        }
      }
    });
  }

  ctrl.prevResource = () => {
    if (ctrl.singleResourceIndex === 0 || _.size(ctrl.singleResourceImages) === 1) {
      notificationService.notify('info', '已经是第一个资源');
      return;
    }
    const item = ctrl.singleResourceImages[ctrl.singleResourceIndex - 1];
    ctrl.memoryHandleId = item.id;
    if (ctrl.sourceFrom === 'right') {
      communicateService.sendDragMessageToRight({memoryHandleId: ctrl.memoryHandleId,
                                                 data: ctrl.content.resDetail, isChangeResource: true, resId: item.id});
    } else {
      communicateService.sendDragMessage({ needfresh: false, memoryHandleId: ctrl.memoryHandleId,
                                           isChangeResource: true, moduleId: ctrl.currentModule.id, packageId: ctrl.content.resDetail.id});
    }
  }
  ctrl.nextResource = () => {
    if (ctrl.singleResourceIndex === (_.size(ctrl.singleResourceImages) - 1)
      || _.size(ctrl.singleResourceImages) === 1) {
      notificationService.notify('info', '已经是最后一个资源');
      return;
    }
    const item = ctrl.singleResourceImages[ctrl.singleResourceIndex + 1];
    ctrl.memoryHandleId = item.id;
    if (ctrl.sourceFrom === 'right') {
      communicateService.sendDragMessageToRight({memoryHandleId: ctrl.memoryHandleId,
                                                 data: ctrl.content.resDetail, isChangeResource: true, resId: item.id});
    } else {
      communicateService.sendDragMessage({ needfresh: false, memoryHandleId: ctrl.memoryHandleId,
                                           isChangeResource: true, moduleId: ctrl.currentModule.id, packageId: ctrl.content.resDetail.id});
    }
  }

  ctrl.backToPresent = (id, from) => {
    ctrl.memoryHandleId = id;
    if (from === 'left') {
      communicateService.sendDragMessage({needfresh: false, memoryHandleId: ctrl.memoryHandleId,
                                          isReturnPresentPackage: true, moduleId: ctrl.currentModule.id});
    } else {
      communicateService.sendDragMessageToRight({memoryHandleId: ctrl.memoryHandleId, data: ctrl.content.resDetail,
                                                 isReturnPresentPackage: true});
    }
  }

  ctrl.showShowCloudTestNoObj = (currentTest) => {
    if (ctrl.cloudTests) {
      ctrl.currentCloudTestId =  _.get(ctrl.cloudTests[0], 'id');
    }
    return !ctrl.isObjEmptyResource(currentTest) && _.isNumber(ctrl.currentCloudTestId);
  };
  ctrl.isObjEmptyResource = (test) => {
    if (!test) {
      return true;
    }
    return _.get(test, 'isEmpty', false) || _.isEmpty(_.get(test, 'questions'));
  };

  ctrl.showShowCloudTestNo = (currentTest) => {
    if (ctrl.cloudTests) {
      ctrl.currentCloudTestId =  _.get(ctrl.cloudTests[0], 'id');
    }
    return !ctrl.isEmptyResource(currentTest) && _.isNumber(ctrl.currentCloudTestId);
  };

  ctrl.isEmptyResource = (res) => {
    if (!res) {
      return true;
    }

    return _.get(res, 'isEmpty', false) ||
      (_.isEmpty(_.get(res, 'question.question')) &&
        _.isEmpty(_.get(res, 'question.resources')));
  };
  ctrl.handleData = (obj) => {
    _.each(obj.questions, (item) => {
      _.each(item.resourceUUIDs, (item1) => {
        item.question += `<img src=
        '/ray/resource/uuid/${item1}/src'>`;
      })
    })
  }
  ctrl.downloadTest = (id) => {
    window.open(`/ray/resource/view/pageMarked/pdf/test/${id}/pdf/pageMarked`, '_blank');
  }
  ctrl.importFromLibrary2_pre = () => {
    const modalInstance = $uibModal.open({
      template: require('app2/prepareCourse/cloud/presentResource/presentResourceLibraryDialog.html'),
      controller: 'presentResourceLibraryDialogCtrl',
      controllerAs: '$ctrl',
      size: 'full-screen',
      windowClass: 'shouldHideOnPreview cl-library-dialog2',
      resolve: {
        data: () => ({
          course: ctrl.course,
          doImport: (res, deferred) => ctrl.doImportResources(res, deferred),
        })
      },
    })
  }

  ctrl.doImportResources = (res, deferred) => {
    ctrl.importResourceItems(deferred, res);
    return deferred.promise;
  }
  ctrl.importResourceItems = (deferred, res) => {
    if (res && res.length > 0) {
      ctrl.addItemForPackage(deferred, res);
    }
  }
  ctrl.addItemForPackage = (deferred, res) => {
    oedPresentResource.get({ id: ctrl.currentRes.id }).$promise.then((x: any) => {
      let downloadOpts = null;
      const opts = {
        canceled: false,
        hlsResource,
        hasDuplicated: false,
        oedCloudPresentResItem,
        oedPreResItem, oedPresentResourceItem,
        oedPresentResource,
        oedResDownloadTask,
        uid: ctrl.userInfo.uid,
        notify,
        notificationService
      }
      downloadOpts = opts
      importPresentResourceItems(x, res, deferred, opts).then((r) =>  {
        $rootScope.$broadcast('presentResource.changed', {
          resId:  x.id
        });
        return  x.$get();
      }).then(() => {
        deferred.resolve();
      }).catch((m) =>  {
        deferred.reject(m);
      }).finally((r) => {
        downloadOpts = null
        if (opts.hasDuplicated) {
          notificationService.notify('info', '导入资源与现有项有部分重复，将自动过滤掉重复项')
        }
        ctrl.memoryHandleId = x.id;
        communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
      });
    })
  }
  function cancelDownload() {
    if (downloadOpts) {
      downloadOpts.canceled = true
    }
  }

  function editObjectiveTest(data) {
    const modalInstance = $uibModal.open({
      template: require('assets/app2/newPrepareCourse/newObjectiveEditDialog/newObjectiveEditDialog.html'),
      controller: 'newObjectiveEditDialogCtrl',
      size: 'lg',
      windowClass: 'collectionModal',
      resolve: {
        data: () => ({
          currentObj: data,
          courseId: ctrl.courseId
        })
      }
    });
    modalInstance.result.then((r) => {
      if (r) {
        if (ctrl.sourceFrom === 'left') {
          ctrl.cloudTest = ctrl.content.resDetail = r;
          ctrl.titleName = ctrl.cloudTest.name + ' - 预览';
          ctrl.loading = oedObjectiveTest.get({id: ctrl.cloudTest.id}).$promise.then((obj: any) => {
            ctrl.objectiveTest = obj
            if (ctrl.getTheWayWithCreateTest()) {
              ctrl.childQuestionList = ctrl.objectiveTest.questions[0].childQuestions
              ctrl.imageRes = _.uniq(ctrl.objectiveTest.questions[0]['resourceUUIDs'])
            }
            if (ctrl.objectiveTest.isTemp) {
              return oedObjectiveTest.getObjTestQuestionType({id: ctrl.objectiveTest.id}).$promise.then((res: any) => {
                if (res && res.testId && res.extraInfo === 'yesorno') {
                  ctrl.extraInfo = 'yesorno'
                } else {
                  ctrl.extraInfo = 'singlechoice'
                }
              })
            }
          });
        }
        communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
      }
    });
  }

  function editSubjective(data) {
    const modalInstance = $uibModal.open({
      template: require('assets/app2/newPrepareCourse/newSubjectiveEditDialog/newSubjectiveEditDialog.html'),
      controller: 'newSubjectiveEditDialogCtrl',
      size: 'lg',
      windowClass: 'collectionModal',
      resolve: {
        data: () => ({
          currentSubj: data,
          courseId: ctrl.courseId
        })
      }
    });

    modalInstance.result.then((r) => {
      if (r) {
        if (ctrl.sourceFrom === 'left') {
          ctrl.cloudTest = ctrl.content.resDetail = r;
          ctrl.titleName = ctrl.cloudTest.name + ' - 预览';
          ctrl.subjectiveTest = oedSubjectiveTest.get({
            id: ctrl.cloudTest.id
          });
          ctrl.loading = ctrl.subjectiveTest.$promise.then(() => {
            ctrl.subjectiveTest.question.positionDetails = true;
            ctrl.deserializePosition();
          });
        }
        communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
      }
    });
  }

  ctrl.addSubjectiveInModule = () => {
    oedSubjectiveTest.createSubjPackge({moduleId: ctrl.currentModule ?
      ctrl.currentModule.id : -1,       tpId : ctrl.tpId }, {}).$promise.then((r: any) => {
      ctrl.memoryHandleId = r.id;
      communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
    }).catch(() => {
      communicateService.sendDragMessage({needfresh: true});
    });
  }
  // 添加客观试卷
  ctrl.addObj = () => {
    oedObjectiveTest.createObjPackge({moduleId: ctrl.currentModule ?
      ctrl.currentModule.id : -1,     tpId : ctrl.tpId}, {}).$promise.then((r: any) => {
      ctrl.memoryHandleId = r.id;
      communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
    })
  }
  ctrl.createPackage = () => {
    oedPresentResource.createResourcePackge({ moduleId: ctrl.currentModule ?
                ctrl.currentModule.id : -1,   tpId: ctrl.tpId }, {}).$promise.then((r: any) => {
      ctrl.memoryHandleId = r.id;
      communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId,
                                          isFromUseResource: true, presentPackageId: ctrl.memoryHandleId});
    })
  }
  // 导入试卷
  function validateWordFileType(files) {
    // 1. 单个文件 ['doc', 'docx', 'pdf']
    // 2. 多个文件 ['jpg', 'jpeg', 'png', 'gif', 'bmp']
    let validateResults = null
    if (files.length > 1) {
      validateResults = _(files).map(f => oedFileUtils.validateFileUpload(f, { fileType: 'image' }))
      .flatten().filter().value()
    } else {
      validateResults = oedFileUtils.validateFileUpload(files[0], {
        allowedSuffixes: ['doc', 'docx', 'pdf', 'jpg', 'jpeg', 'png', 'gif', 'bmp']
      });
    }
    files.validateResults = validateResults;
    files.progressOpacity = 0;
  }
  function quickCreateTest(imageReses: any) {
    function createSyntheticQuestion(test) {
      const relSections = _.map(ctrl.course.relatedSections, (sec) => {
        return _.pick(sec, ['sectionId', 'sectionName']);
      });

      const obj = {
        answer: '',
        question: '',
        type: 'synthetic',
        point2: 0,
        choices: [],
        leftOpts: [],
        rightOpts: [],
        resourceUUIDs: _.map(imageReses, (image: any) => {
          return _.get(image, 'md5')
        }),
        relatedSections: relSections,
        needPic: false,
        answerDetails: null
      }

      return oedObjectiveQuestion.createForTest({
        question: obj,
        testId: test.id
      }).$promise.then((q: any) => {
        q.childQuestions = defaultChildQuestionList
        return oedObjectiveQuestion.save(q).$promise.then((r) => {
          return communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
        })
      })
    }

    return createSyntheticQuestion(ctrl.test).then(() => {
      communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
    })
  }
  function convertAndCreatTest(converId, cancelInstance) {
    ctrl.loading = oedResource.pdf2jpg({id: converId}, {}).$promise.then((imageReses) => {
      if (!ctrl.nextStep) return
      if (imageReses && imageReses.length > 0) {
        $scope.progressMsg = '正在生成试卷…（4/4）'
        return quickCreateTest(imageReses).then(() => {
          ctrl.needClick = true;
          cancelInstance.close()
          communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
        })
      } else {
        cancelInstance.close();
        notificationService.notify('error', '文件转码失败，请检查文件格式')
      }
    })
  }

  ctrl.importTest = (files) => {
    ctrl.testFiles = null;
    validateWordFileType(files);
    if (!_.isEmpty(files.validateResults)) {
      notificationService.notify('error', '导入文件仅支持单个word文档、单个pdf文档、一张或多张图片', '导入失败', 5 * 1000)
      return
    }
    if (_.size(files) > 20) {
      notificationService.notify('error', '导入图片最多20张', '导入失败', 5 * 1000)
      return
    }
    oedObjectiveTest.createObjPackge({moduleId: ctrl.currentModule ?
       ctrl.currentModule.id : -1,    tpId : ctrl.tpId}, {}).$promise.then((r: any) => {
      ctrl.memoryHandleId = r.id;
      ctrl.testId = r.id;
      ctrl.test = r;
      ctrl.nextStep = true;
      let cancelInstance = null;
      return $q((resolve, reject) => {
        cancelInstance = $uibModal.open({
          template: require('app2/prepareCourse/promptDialog/promptDialog.html'),
          controller: 'promptDialogCtrl',
          size: 'qp-edit',
          windowClass: 'shouldHideOnPreview cl-library-dialog2',
          scope: $scope,
          resolve: {
            data: (() => {})
          }
        })
        cancelInstance.result.then((res) => {
          ctrl.nextStep = false
          if (res === 'cancel') {
            oedObjectiveTest.delete({id: ctrl.test.id}).$promise.then((data: any)  => {})
            ctrl.test = null;
          }
        })

        $scope.progressMsg = '正在上传文件... (1/4)'
        Promise.mapSeries(files, function (file) {
          if(!ctrl.nextStep) return Promise.resolve(null)
          return Upload.upload({ file, url: oedConfig.url_b('resource/create') })
        })
        .then(data => _.map(data, 'data'))
        .then(resList => resolve(resList))
        .catch(e => resolve([]))
      }).then((resList) => {
        if (!ctrl.nextStep) return
        $scope.progressMsg = '正在转码... (2/4)'
        const res = resList[0]
        if (res.suffix === 'pdf') {
          return convertAndCreatTest(res.id, cancelInstance)
        } else if(res.suffix === 'txt' || res.suffix === 'doc' || res.suffix === 'docx') {
          let cancel = false
          const ret = $interval(() => {
            const url = '/ray/api/b/resource/' + res.id
            if (!ctrl.nextStep) return
            return $http.get(url).then((res1) => {
              if (res1.data.transFlag !== 1) {
                return
              }
              if (!cancel) {
                $interval.cancel(ret)
                cancel = true
                if (!ctrl.nextStep) return
                let pdf2jpgSuccess = false;
                let retry = 3;
                $scope.progressMsg = '正在生成图片…（3/4）'
                const successCall = (imgReses) => {
                  pdf2jpgSuccess = true
                  if (!ctrl.nextStep) return
                  if (imgReses) {
                    $scope.progressMsg = '正在生成试卷…（4/4）'
                    return quickCreateTest(imgReses).then(() => {
                      ctrl.needClick = true;
                      cancelInstance.close();
                      communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
                    })
                  }
                }
                const failCall = () => {
                  retry--
                  if (retry > 0 && !pdf2jpgSuccess) {
                    ctrl.loading = oedResource.pdf2jpg({id: res.id}, {}).$promise.then(successCall, failCall)
                  }
                  if (retry <= 0 && !pdf2jpgSuccess) {
                    notificationService.notify('error', '试卷转码失败，请稍后重试', '', 5 * 1000)
                  }
                }
                ctrl.loading = oedResource.pdf2jpg({id: res.id}, {}).$promise.then(successCall, failCall)
              }
            }).catch(() => {
              return
            })
          }, 1500, 100)
          ret.then(() => {
            notificationService.notify('error', '生成试卷失败，请稍后重试', '', 5 * 1000)
            cancelInstance.close()
          })
        } else {
          $scope.progressMsg = '正在生成试卷…（4/4）'
          quickCreateTest(resList).then(() => {
            ctrl.needClick = true;
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
          })
        }
      })
    })
  }
  ctrl.showRules = () => {
    event.preventDefault();
    event.stopPropagation();
    const modalInstance1 = $uibModal.open({
      template: require('assets/app2/newPrepareCourse/objectiveDialogs/objectiveDialog.html'),
      controller: 'objectiveDialogCtrl',
      size: 'md',
      windowClass: 'oedmodal modalCenter',
      resolve: {
        data: () => {
          return {}
        }
      },
    });
  }
  ctrl.daoXueAnTest = (files) => {
    ctrl.daoXueAnTestFiles = null;
    const validateResults = oedFileUtils.validateFileUpload(files, {
      allowedSuffixes: ['doc', 'docx', 'pdf']
    });
    if (validateResults.length > 0) {
      return oedFileUtils.notifyValidationReporter(validateResults);
    }
    return $q((resolve, reject) => {
      ctrl.importInstance = $uibModal.open({
        component: 'importIngDialog',
        size: 'sm',
        windowClass: 'importIngDialog modalCenter',
        resolve: {
          data: () => {
            return {
            };
          }
        }
      });
      ctrl.importInstance.result.then((r: any) => {
        if (r) {
          ctrl.cancelUpload = true;
        }
      })
      Upload.upload({
        url: oedConfig.url_b('resource/create'),
        file: files
      }).progress((evt) => {
        if (ctrl.cancelUpload) {
          resolve(null);
        }
      }).success((data, status, headers, config) => {
        resolve(data);
      }).error(() => {
        resolve(null);
      })
    }).then((res) => {
      let cancel = false;
      const ret = $interval(() => {
        const url = '/ray/api/b/resource/' + res.id;
        return $http.get(url).then((res1) => {
          if (ctrl.cancelUpload) {
            return;
          }
          if (res1.data.transFlag !== 1) {
            return;
          }
          if (!cancel) {
            $interval.cancel(ret);
            cancel = true;
            return oedObjectiveTest.getGuidanceCasePdf({ resourceId: res.id }).$promise.then((r: any) => {
              if (r && !r.success) {
                ctrl.importInstance.close();
                return ctrl.importFailedFiledDialog = $uibModal.open({
                  template: require('assets/app2/newPrepareCourse/uploadFailedDialog/uploadFailedDialog.html'),
                  controller: 'uploadFailedDialogCtrl',
                  size: 'sm',
                  windowClass: 'uploadFailedDialog modalCenter',
                  resolve: {
                    data1: () => {
                      return {
                        moduleId: ctrl.currentModule ? ctrl.currentModule.id : -1
                      };
                    }
                  }
                });
              }
              return oedObjectiveTest.createGuidanceCaseTest({
                moduleId: ctrl.currentModule ? ctrl.currentModule.id : -1,
                resourceId: res.id,
                tpId: ctrl.tpId
              }, {}).$promise.then((r: any) => {
                ctrl.memoryTestId = r.id;
                ctrl.importInstance.close();
                const modalInstance = $uibModal.open({
                  component: 'dialogOne',
                  resolve: {
                    data: () => {
                      return {
                        msg: '',
                        title: '已成功导入word试卷',
                        yesText: '下载打印版本并查看试卷',
                        isImportTest: true
                      }
                    },
                  },
                  size: 'sm',
                  windowClass: 'commonDialog importTest',
                });
                modalInstance.result.then(res => {
                  if (res) {
                    window.open(`/ray/resource/view/pageMarked/pdf/
                    test/${ctrl.memoryTestId}/pdf/pageMarked`, '_blank');
                  }
                  communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryTestId});
                })
              });
            })
          }
        }).catch(() => {
          return
        })
      }, 1500, 100)
      ret.then(() => {
        notificationService.notify('error', '生成试卷失败，请稍后重试', '', 5 * 1000)
      })
    })
  }
  // 按word模板导入试卷
  ctrl.importTestByExample = () => {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/wordUploader.html'),
      controller: 'uploadResCtrl2',
      size: 'md',
      windowClass: 'modalCenter newPreWordUploader',
      resolve: {
        resourceNeeded: () => {
          return {
            moduleId: ctrl.currentModule ? ctrl.currentModule.id : -1,
            tpId: ctrl.tpId,
            showRules: () => {
              const modalInstance1 = $uibModal.open({
                template: require('assets/templates/prepareCourse/wordUploaderRulesDialog.html'),
                controller: 'wordUploaderRulesCtrl',
                size: 'md',
                resolve: {
                  test: () => {
                    return 'ab';
                  },
                  data: () => {
                    return {
                      isDaoxuean: false
                    }
                  }
                },
              });
              return modalInstance1.result;
            }
          };
        }
      }
    });
    modalInstance.result.then((r) => {
      const relSections = _.map(ctrl.course.relatedSections, (sec) =>  {
        return _.pick(sec, ['sectionId', 'sectionName']);
      });
      if (_.size(r.respondData) && r.test) {
        _.each(r.respondData, (docData) => {
          if (docData.questions && docData.questions.length > 0) {
            _.each(docData.questions, (question) => {
              question.relatedSections = relSections;
            });
            r.test.questions = _.concat(r.test.questions, docData.questions);
          }
        });
        return oedObjectiveTest.save(angular.copy(r.test)).$promise.then(() => {
          notificationService.notify('info', '试卷保存成功');
          ctrl.memoryHandleId = r.test.id;
          communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
        });
      }
    });
  }
  ctrl.sendMessageToLeft = () => {
    communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
  };
  ctrl.useResource = (data, type) => {
    event.stopPropagation();
    event.preventDefault();
    if (type === 'subjective') {
      oedNewPrepareCourse.copySubjective({
        courseId: parseFloat(ctrl.courseId),
        teachingModuleId: ctrl.currentModule ? ctrl.currentModule.id : -1,
        testId: data.id,
      }, {}).$promise.then((res: any) => {
        ctrl.memoryHandleId = res.subjectiveTestDTO.id;
        communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
      });
    } else if (type === 'objective') {
      oedNewPrepareCourse.copyObjectiveTest({
        courseId: ctrl.courseId,
        teachingModuleId: ctrl.currentModule ? ctrl.currentModule.id : -1,
        testId: data.id,
      }, {}).$promise.then((res: any) => {
        ctrl.memoryHandleId = res.testDTO.id;
        communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
      });
    } else if (type === 'singleResource' || type === 'zhonglian') {
        oedNewPrepareCourse.copySingleResource({
        courseId: parseFloat(ctrl.courseId),
        teachingModuleId: ctrl.currentModule ? ctrl.currentModule.id : -1,
        presentResourceId: -1,
        resourceId: type === 'zhonglian' ? data.flResourceId : data.flResource.id,
        resourceName: data.resourceName,
        createResource: false
      }).$promise.then((re: any) => {
        ctrl.memoryHandleId = re.presentResourceItemDTO.id;
        communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId,
                                            isFromUseResource: true, presentPackageId: re.presentResourceDTO.id});
      });
    }
  };

  // 添加链接
  ctrl.addUrlItem = () => {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/addWebUrlItem.html'),
      controller: 'addWebUrlItemCtrl',
      size: 'md',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        data: () => {
          return {
            resourceId: '',
            moduleId: ctrl.currentModule.id,
          };
        }
      }
    });
    modalInstance.result.then((r) => {
      if (r) {
        ctrl.memoryHandleId = r;
        communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
      }
    });
  };
  // 云盘上传入口
  ctrl.openCloudDrive = () => {
    const id = ctrl.currentModule.id;
    oedDialogService.openModalWithComponent('CloudDriveDialogComponent',
      {
        isPrepareLesson: true,
      }, (params)=> {
        if (params.type === 'myFile') {
          cloundApi.importCloudDriveItem({
            courseId: ctrl.courseId,
            moduleId: id,
            presentResourceId: -1}, params.res).$promise.then((r: any) => {
            notificationService.newPrepareNotify('已成功导入1个资源到 ' + ctrl.currentModule.name, '', 5000);
            ctrl.memoryHandleId = r.id;
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
          })
        } else {
          cloundApi.importMicroLessonItem({
            courseId: ctrl.courseId,
            moduleId: id,
            presentResourceId: -1}, params.res).$promise.then((r: any) => {
            notificationService.newPrepareNotify('已成功导入1个资源到 ' + ctrl.currentModule.name, '', 5000);
            ctrl.memoryHandleId = r.id;
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
          })
        }
      },
      {class: 'cl-library-dialog2 modal-cloud-drive'})
  }
  // 展示资源本地上传
  ctrl.toggleUpload = () => {
    const modalInstance = $uibModal.open({
      template: require('assets/templates/prepareCourse/resourceUploader.html'),
      controller: 'uploadResourceCtrl',
      size: 'md',
      windowClass: 'oed-common-modal modalCenter',
      resolve: {
        resourceId: () => {
          return '';
        },
        url: () => {
          return '';
        },
        isPrepareLesson: true,
        moduleId: ctrl.currentModule.id,
        packageId: null
      }
    });

    modalInstance.result.then((r) => {
      if (r.uploaded && r.uploaded > 0) {
        ctrl.memoryHandleId = r.packgeId;
        communicateService.sendDragMessage({needfresh: true, memoryHandleId: ctrl.memoryHandleId});
      }
    });
  };
  ctrl.preventDropdownDefault = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
  ctrl.getTypeLabel = (type) => {
    if (type === 'subjective_blankboard') {
      return '白板';
    }
    return '!!!';
  };
  ctrl.deserializePosition = () => {
    const tmpPosition = ctrl.subjectiveTest.question.resourcePosition;
    if (!_.isEmpty(tmpPosition) && !_.isArray(tmpPosition)) {
      try {
        ctrl.subjectiveTest.question.$resourcePosition = JSON.parse(tmpPosition);
        ctrl.subjectiveTest.question.positionDetails = false;
      } catch (e) {
        notificationService.notify('error', '加载位置信息失败, 转换为自动布局!')
        ctrl.subjectiveTest.question.$resourcePosition = null;
        ctrl.subjectiveTest.question.positionDetails = true;
      }
    }
  };

  ctrl.getTheWayWithCreateTest = () => {
    return ctrl.objectiveTest && ctrl.objectiveTest.questions &&
      ctrl.objectiveTest.questions.length === 1 &&
      ctrl.objectiveTest.questions[0]['type'] === 'synthetic' &&
      ctrl.objectiveTest.questions[0]['resourceUUIDs'] &&
      ctrl.objectiveTest.questions[0]['resourceUUIDs'].length > 0
  }

  const basePath = oedConfig.base();
  const imgSrcPathID = _.template(basePath + 'resource/view/img_src/<%=res.id%>');
  const imgDownloadPath = _.template(basePath + 'resource/view/img_src/<%=res.id %>/raw/<%=res.id+res.suffix%>');
  const boardContentPath = _.template(basePath + 'resource/board/<%=res.id %>/content');
  const boardCommentPath = _.template(basePath + 'resource/view/img_src/<%=res.id %>');
  function getResProp(r: any, name: any) {
    if (_.has(r, 'flResource')) {
      return _.get(r, 'flResource.' + name);
    }
    return _.get(r, '' + name);
  }
  ctrl.getResUUID = (res: any) => {
    return resourceUtils.extractResourceUUID(res) || resourceUtils.extractResourceUUID(res.flResource);
  }
  function getImgSrcPath(res: any, name: any) {
    const uuid = ctrl.getResUUID(res);
    if (_.isEmpty(uuid)) {
      const resId = _.get(res, 'flResourceId', res.id)
      return imgSrcPathID({
        res: {
          id: resId,
        },
      });
    }
    return '/ray/resource/uuid/' + uuid + '/raw64/' + name;
  }
  ctrl.getImageUrlForDownload = (res: any) => {
    const imageUrl = getImageUrl(res, true);
    $window.open(imageUrl, _.get($window, '_link_open_type'));
  }
  function getImageUrl(resItem: any, isDownload: any) {
    if (!resItem) {
      return null;
    }

    function b64EncodeUnicode(str: any) {
      return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match: any, p1: any) => {
        return String.fromCharCode(Number('0x' + p1));
      }));
    }

    let src = null;
    const prop = ctrl.isExternalResource(resItem) ? 'externalResourceType' : 'fileType';
    const fileType = getResProp(resItem, prop);
    if (fileType === 'boardContent') {
      return boardContentPath({
        res: resItem,
      });
    } else if (fileType === 'boardComment') {
      return boardCommentPath({
        res: resItem,
      });
    }

    if (isDownload) {

      if (ctrl.isExternalResource(resItem)) {
        return resItem.externalResourceUrl
      }

      if (resItem.webUrl && url.parse(resItem.webUrl).hostname === nobookHost) {
        return nobookUtils.buildRedirectUrl(resItem.webUrl, userInfo.userInfo.uid)
      }

      if (resItem.webUrl) {
        return resItem.webUrl;
      }

      let name = resItem.resourceName || '';
      const suffix = getResProp(resItem, 'suffix');
      if (suffix && !name.endsWith(suffix)) {
        name = name + '.' + suffix;
      }
      name = b64EncodeUnicode(encodeURIComponent(name));
      return getImgSrcPath(resItem, name);
    }

    if (fileType === 'image' || fileType === 'audio' || fileType === 'video') {
      if (ctrl.isExternalResource(resItem)) {
        return resItem.externalResourceUrl
      }
      src = getResProp(resItem, 'imageSrcHttpPath');
      if (!src) {
        return getImgSrcPath(resItem, '');
      }
      return src;
    }
    return getResProp(resItem, 'imageThumbHttpPath');
  }
  ctrl.getOpenImageUrl = (resItem: any) => {
    const fileType = getResProp(resItem, 'fileType');
    if (fileType === 'boardContent') {
      return boardContentPath({
        res: resItem,
      });
    } else if (fileType === 'boardComment') {
      return boardCommentPath({
        res: resItem,
      });
    }

    if (ctrl.isExternalResource(resItem)) {
      return resItem.externalResourceUrl
    }

    if (resItem.webUrl && url.parse(resItem.webUrl).hostname === nobookHost) {
      return nobookUtils.buildRedirectUrl(resItem.webUrl, userInfo.userInfo.uid)
    }

    if (resItem.webUrl) {
      return resItem.webUrl;
    }

    let src: any = getResProp(resItem, 'imageSrcHttpPath');
    if (!src) {
      src = imgDownloadPath({
        res: resItem,
      });
    }

    return src;
  };
  ctrl.getImageCaption = (res: any, cfg: any) => {
    if (!cfg) {
      return res.resourceName;
    }
    if (!res) {
      return '下载原文件';
    }

    if (cfg === 'download') {
      if (res.webUrl) {
        return '打开原网页';
      }
    }

    return '下载原文件';
  };
  ctrl.isVideo = (r: any) => {
    const prop = ctrl.isExternalResource(r) ? 'externalResourceType' : 'fileType';
    const resType = getResProp(r, prop);
    return resType === 'audio' || resType === 'video';
  };
  ctrl.isVideoOrAudio = (r: any) => {
    const prop = ctrl.isExternalResource(r) ? 'externalResourceType' : 'fileType';
    const resType = getResProp(r, prop);
    return resType === 'audio' || resType === 'video';
  };

  //
  // hack Lightbox方法
  // 如果第二个参数为true, 则返回是否文字资源
  ctrl.isSharedVideo = (r: any, opt: any) => {
    if (opt) {
      const prop = ctrl.isExternalResource(r) ? 'externalResourceType' : 'fileType';
      return getResProp(r, prop) === 'text';
    }
    //
    // 始终返回false, 不支持youtube之类的视频
    return false;
  };

  // ctrl.prevImage = () => {
  //   if (ctrl.singleResourceIndex !== 0) {
  //     ctrl.singleResourceIndex --;
  //   } else {
  //     ctrl.singleResourceIndex = ctrl.singleResourceImages.length - 1;
  //   }
  //   updateImageInfo();
  // }
  //
  // ctrl.nextImage = () => {
  //   if (ctrl.singleResourceIndex !== ctrl.singleResourceImages.length - 1) {
  //     ctrl.singleResourceIndex ++;
  //   } else {
  //     ctrl.singleResourceIndex = 0;
  //   }
  //   updateImageInfo();
  // }

  function updateImageInfo(singleResourceImage) {
    // ctrl.singleResourceImage = ctrl.singleResourceImages[ctrl.singleResourceIndex];
    ctrl.resTitle = singleResourceImage.resourceName;
    if (ctrl.getResTypeClass(singleResourceImage.flResource.suffix) === 'office') {
      ctrl.targetPath = '/ray/resource/uuid/' + singleResourceImage.resourceUuid + '/pdf';
      return;
    }
    ctrl.singleResourceImageUrl = getImageUrl(singleResourceImage, false);
    return
  }

  function editSingleResource(data) {
    oedDialogService.openModalWithComponent('OfficeEditorDialogComponent',
      {data: {res: data}},
      ()=> {
        ctrl.loading = $timeout(() => {
            communicateService.sendDragMessage({needfresh: true, memoryHandleId: data.id})
          }, 3000)
        },
      {class: 'modal-full-screen cl-library-dialog2 padding-lg'})
  }
  ctrl.singleResourceEdit = (res, event) => {
    event.stopPropagation();
    event.preventDefault();
    if (resourceUtils.editableSuffix.some((suffix: any) => res.flResource.suffix === suffix)) {
      editSingleResource(res);
    } else {
      oedDialogService.openModalWithComponent('OfficeEditorTipComponent', {}, ()=> {}, {class: 'office-editor-modal-center'})
    }
  }

  ctrl.singleResourceCanEdit = (r, opt, checkOffice, editButton) => {
    const officeSuffix = resourceUtils.officeSuffix;
    if (editButton) {
      if (r.flResource.source && r.flResource.source === 'zlwh') {
        return false;
      }
      /* ？？？？？？？？？？？？？？？？？？？？？ && !r.isExternal*/
      if (r.resourceName && officeSuffix.some((suf: any) => r.resourceName.endsWith(suf))) {
        return true
      } else {
        return false
      }
    }
    // 始终返回false, 不支持youtube之类的视频
    const suffixProp = ctrl.isExternalResource(r) ? 'externalResourceSuffix' : 'suffix';
    const suffix = getResProp(r, suffixProp)
    return suffix === 'm3u8';
  }
  ctrl.getResTypeClass = (type: any) => {
    let realExtension;
    switch (type ? type.toLowerCase() : type) {
      case 'ppt':
      case 'doc':
      case 'pptx':
      case 'docx':
      case 'pdf':
      case 'txt':
      case 'xls':
      case 'xlsx':
        realExtension = 'office'
        break;
      // case 'xls':
      // case 'xlsx':
      //   realExtension = 'excel'
      //   break;
      case 'mp4':
      case 'flv':
      case 'rmvb':
      case 'wmv':
      case 'rm':
      case 'avi':
      case 'mpg':
        realExtension = 'video'
        break;
      case 'mp3':
      case 'wma':
      case 'wav':
      case 'amr':
      case 'm4a':
        realExtension = 'audio'
        break;
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
      case 'bmp':
        realExtension = 'image'
        break;
      default:
        realExtension = 'unknown'
        break;
    }
    return realExtension;
  }
}
uploadResCtrl2.$inject = ['$scope', '$q', '$uibModalInstance', 'Upload', 'notify',
  'oedConfig', 'oedPresentResource', 'oedFileUtils', 'resourceNeeded', 'communicateService',
  'notificationService', 'oedObjectiveTest', '$rootScope', '$uibModal', '$interval', '$http']
function uploadResCtrl2($scope, $q, $uibModalInstance, Upload, notify,
                        oedConfig, oedPresentResource, oedFileUtils, resourceNeeded, communicateService,
                        notificationService, oedObjectiveTest, $rootScope, $uibModal, $interval, $http) {
  $scope.res = {};
  $scope.uploadCount = 0;
  $scope.uploading = 0;
  $scope.respondData = [];
  $scope.startUpload = () => {
    // 正在上传
    if ($scope.uploading > 0) {
      return;
    }
    if (_.isEmpty($scope.handledFiles)) {
      return;
    }
    oedObjectiveTest.createObjPackge({ moduleId: resourceNeeded.moduleId,
                                       tpId: resourceNeeded.tpId}, {}).$promise.then((res: any) => {
      $scope.test = res;
      const allFiles = $scope.handledFiles;
      $scope.loadingMessage = '正在上传';

      const uploadFile = (upfile) => {
        $scope.uploading++;
        return $q((resolve, reject) => {
          Upload.upload({
            url: oedConfig.url('objectivetest/' + res.id + '/uploadresource'),
            file: upfile
          }).progress((evt: any) => {
            if (upfile.progress && upfile.progress === 120) {
              return;
            }
            const progressPercentage = _.parseInt((100.0 * evt.loaded / evt.total) as any);
            upfile.progress = progressPercentage;
          }).success((data, status, headers, config) => {
            $scope.uploading--;
            $scope.uploadCount++;
            $scope.respondData.push(data);
            if (data.error.length > 0) {
              oedObjectiveTest.delete({id: $scope.test.id}).$promise.then((data: any)  => {})
              $scope.test = null;
              return notificationService.notify('error', data.error[0], '', 2000);
            }
          }).error(() => {
            oedObjectiveTest.delete({id: $scope.test.id}).$promise.then((data: any)  => {})
            $scope.test = null;
            $scope.uploading--;
            upfile.progress = 120;
            $scope.respondData.push({ error: ['上传失败'] });
          }).finally(() => {
            resolve();
          });
        });
      };

      const filesToUpload = _(allFiles).filter((f: any) => {
        const info = _.keyBy(f.validateResults, 'level');
        return !_.has(info, 'error');
      });

      if (filesToUpload.isEmpty()) {
        return notificationService.notify('info', '没有要上传的文件, 请选择文件后再点击上传')
      }

      $scope.uploadOnce = true;

      $scope.loading = Promise.map(filesToUpload.value(), (f) => {
        if (f.progress) {
          return null;
        }
        return uploadFile(f);
      }).then(() => {
        let failedFiles = [];
        failedFiles = _.filter($scope.respondData, (data: any) => {
          return data.error.length > 0;
        });
        if (failedFiles.length === 0) {
          $uibModalInstance.close({
            respondData: $scope.respondData,
            test: $scope.test
          });
        }
      }).finally(() => { });
    })
  };

  $scope.close = () => {
    $uibModalInstance.close({
      respondData: $scope.respondData,
      test: $scope.test
    });
  };

  $scope.closeWithoutData = () => {
    $uibModalInstance.close({
    });
  };
  $scope.showRules = () => {
      const result = resourceNeeded.showRules();
  };

  $scope.getFileCheckClass = (f) => {
    return _.map(f.validateResults, 'classes');
  };

  $scope.getTipAtLevel = (f, level) => {
    const info = _.find(f.validateResults, (r: any) => {
      return r.level === level;
    });
    return _.get(info, 'message');
  };

  $scope.hasValidateInfoAtLevel = (file, level) => {
    return _.findIndex(file.validateResults, (r: any) => {
      return r.level === level;
    }) >= 0;
  };
  $scope.handledFiles = [];
  $scope.$watch('files', () => {
    if ($scope.uploadOnce) {
      return;
    }
    if ($scope.files) {
      // $scope.handledFiles = [];
      const unhandled = _.filter($scope.files, (f: any) => {
        return !_.find($scope.handledFiles, (hf: any) => {
          return hf.lastModified === f.lastModified &&
            hf.size === f.size &&
            hf.name === f.name;
        });
      });
      _.each(unhandled, (f: any) => {
        const validateResults = oedFileUtils.validateFileUpload(f, {
          allowedSuffixes: ['doc', 'docx']
        });
        f.validateResults = validateResults;
        if (f.name && f.name.endsWith('doc')) {
          f.doc = true;
        }
        f.progressOpacity = 0;
      });
      const results = _(unhandled).map('validateResults').flatten().value();
      oedFileUtils.notifyValidationReporter(results);

      $scope.handledFiles = $scope.handledFiles.concat(unhandled);
    }
  });
  $scope.$on('modal.closing', ($event) => {
    if ($scope.uploading > 0) {
      $event.preventDefault();
    }
  });
}
selectedModuleCtrl.$inject = ['$scope', '$q', '$uibModalInstance',
  'oedPresentResourceItem', 'data', 'oedTeachingPattern', 'notificationService']
function selectedModuleCtrl($scope, $q, $uibModalInstance, oedPresentResourceItem,
                            data, oedTeachingPattern, notificationService) {
  $scope.index = data.index;
  $scope.courseId = data.courseId;
  if ($scope.index === 0) {
    $scope.teachingPattern = oedTeachingPattern.getByCourse2({
      courseId:  $scope.courseId
    });
  } else {
    $scope.teachingPattern = oedTeachingPattern.teachingPatternOfHomework({
      courseId:  $scope.courseId
    });
  }
  $scope.teachingPattern.$promise.then((r: any) => {
    $scope.modulesArr = r.modules;
    $scope.selected = { value: $scope.modulesArr[0] };
  })
  $scope.ok = () => {
    if (!$scope.selected.value) {
      return notificationService.notify('error', '左侧无环节');
    }
    $uibModalInstance.close($scope.selected.value.id);
  };
  $scope.changeModule = (item) => {
    $scope.selected = { value: item };
  }
  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
  };

}
