import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';
import { OedExerciseService } from '../../../exercise-book/oed-exercise.service';
import { LoadingService } from 'app/shared/loading/loading.service';

@Component({
  selector: 'app-test-paper-content',
  template: require('./test-paper-content.component.html'),
  styles: [require('./test-paper-content.component.scss')]
})
export class TestPaperContentComponent implements OnInit {
  @Input() private testPaper: string;
  @Input() private inLibary: boolean;
  @Input() private resboxHolder: any;
  @Output() action = new EventEmitter();
  questionsList: any;
  isEmpty: boolean

  constructor(private bsModalRef: BsModalRef,
    private oedExerciseService: OedExerciseService,
    private loadingService: LoadingService) { }

  ngOnInit() {
    this.getInitData(this.testPaper);
  }

  close() {
    this.bsModalRef.hide()
  }

  getInitData(item) {
    const id = _.get(item, 'test.id');
    this.oedExerciseService.getTestPaperQuestions(id)
    .pipe(this.loadingService.withLoading())
    .subscribe(data => {
      console.log(data);
      this.questionsList = _.get(data, 'questions');
      this.isEmpty = _.isEmpty(this.questionsList);
    })
  }

  private doDeleteQuestion = (params) => {
    this.action.emit({ type: 'delQues', params })
  }
  private doAddToResBox = (params) => {
    this.action.emit({ type: 'addQues', params })
  }
}
