import angular = require('angular');
import * as _ from 'lodash';

require('assets/styles/course.css');
const {weikyueyiValidVersionNames} = require('app2/prepareCourse/cloud/utils/libraryUtils')

angular.module('app.directives.course.materialSelectorTest', [])
  .component('materialSelectorTest', {
    bindings: {
      schoolId: '<',
      subjectId: '<',
      vendor: '<',
      enableSubject: '<',
      currentMaterial: '<',
      currentVendor: '<',
      onSelect: '&'
    },
    controller: materialSelectorTest,
    template: <any> require('./materialSelectorTest.html'),
  })

materialSelectorTest.$inject = ['$timeout', 'oedTeachingMaterialVersions', 'oedSubjects', '$q', 'notificationService', 'oedCoursePath']
function materialSelectorTest($timeout: any, oedTeachingMaterialVersions: any, oedSubjects: any, $q: any, notificationService: any, oedCoursePath: any) {
  const ctrl = this;
  ctrl.isOpen = false
  ctrl.xiaobenFilterSwitcher = false
  ctrl.dynamicPopover = {template: 'popoverTemplate'}

  this.$onChanges = (changeObj: any) => {
    if (ctrl.schoolId && ctrl.subjectId) {
      ctrl.vendor = _.get(ctrl.materialData, 'vendor', 'oed')
      if (ctrl.vendor === 'weike_yueyi') {
        ctrl.vendor = undefined
      }
      if (ctrl.currentVendor && ctrl.currentVendor.name === 'xiaoben') {
        ctrl.loadXiaobenCoursePaths()
      } else {
        ctrl.xiaobenFilterSwitcher = false
        ctrl.loadBasicData()
      }
    }
  };
  
  ctrl.loadBasicData = () => {
    ctrl.allVersions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
      schoolId: ctrl.schoolId,
      subjectId: ctrl.subjectId,
      vendor: ctrl.vendor,
    });
    
    ctrl.allSubjects = oedSubjects.queryBySchoolId({
      schoolId: ctrl.schoolId
    });

    ctrl.loading = $q.all([ctrl.allVersions.$promise, ctrl.allSubjects.$promise]).then((data: any) => {
      let versions = data[0]
      ctrl.sbjs = data[1]
      if (ctrl.xiaobenFilterSwitcher && ctrl.subjectFilter && !_.isEmpty(ctrl.subjectFilter)) {
        ctrl.sbjs = _.filter(data[1], s => _.indexOf(ctrl.subjectFilter, s.id) >= 0)
      }

      ctrl.currSbj = _.find(ctrl.sbjs, s => s.id === ctrl.subjectId)
      if (_.get(ctrl, 'vendor', 'oed') === 'weike_yueyi') {
        versions = _.filter(versions, (v: any) => _.find(weikyueyiValidVersionNames, (name) => name === v.versionName))
      }
      if (ctrl.xiaobenFilterSwitcher && ctrl.VerGradeFilter && !_.isEmpty(ctrl.VerGradeFilter)) {
        versions = _.filter(versions, oriv => ctrl.VerGradeFilter[`${oriv.versionId}_${oriv.gradeId}`])
      }

      ctrl.groups = _.groupBy(versions, 'versionName');
      ctrl.groupNames = _.keys(ctrl.groups);
      $timeout(() => {
        if (_.get(ctrl, 'currentMaterial.$isAll', false)) {
          ctrl.versionName = '全部教材'
        } else {
          ctrl.versionName = _.get(ctrl, 'currentMaterial.versionName', '');
          ctrl.currMaterial = _.get(ctrl, 'currentMaterial', {});
        }
        ctrl.materials = _.get(ctrl.groups, `${ctrl.versionName}`, []);
      });
    });
  }
  ctrl.loadXiaobenCoursePaths = () => {
    oedCoursePath.queryFilterInSchool().$promise.then(res => {
      ctrl.xiaobenFilterSwitcher = true
      ctrl.subjectFilter = _.uniq(_.map(res, 'subjectId'))
      ctrl.VerGradeFilter = _.keyBy(_.uniq(_.map(res, r => `${r.versionId}_${r.gradeId}`)))
      ctrl.loadBasicData()
    })
  }
  ctrl.confirm = () => {
    if (ctrl.getGradeName() === '请选择教材') {
      notificationService.notify('error', '请选择教材');
      return
    }
    if (ctrl.versionName === '全部教材') {
      ctrl.onSelect({version: {$isAll: true}, subject: ctrl.currSbj})
    } else {
      ctrl.onSelect({version: ctrl.currMaterial, subject: ctrl.currSbj})
    }
    ctrl.isOpen = false
  }
  ctrl.onSbjClicked = (s) => {
    ctrl.currSbj = s

    ctrl.allVersions = oedTeachingMaterialVersions.queryBySchoolAndSubjectId({
      schoolId: ctrl.schoolId,
      subjectId: ctrl.currSbj.id,
      vendor: ctrl.vendor,
    });
    ctrl.loading = ctrl.allVersions.$promise.then((versions: any) => {
      if (ctrl.xiaobenFilterSwitcher && ctrl.VerGradeFilter && !_.isEmpty(ctrl.VerGradeFilter)) {
        versions = _.filter(versions, oriv => ctrl.VerGradeFilter[`${oriv.versionId}_${oriv.gradeId}`])
      }
      ctrl.groups = _.groupBy(versions, 'versionName');
      ctrl.groupNames = _.keys(ctrl.groups);
      ctrl.versionName = '全部教材'
    })
  }
  ctrl.onLevelClicked = (l) => {
    if (ctrl.versionName !== l) {
      ctrl.versionName = l
      ctrl.materials = _.get(ctrl.groups, `${ctrl.versionName}`, []);
      ctrl.currMaterial = {}
    }
  }
  ctrl.onVersionClicked = (v) => {
    ctrl.currMaterial = v
  }
  ctrl.getGradeName = () => {
    const gradeName = _.get(ctrl, 'currMaterial.gradeName', '')
    return ctrl.versionName !== '全部教材' ? gradeName || '请选择教材' : '全部教材'
  }
  ctrl.close = () => {
    ctrl.isOpen = false
  }
  ctrl.tryToOpen = () => {
    if (!ctrl.isOpen) 
      ctrl.isOpen = true
    else 
      ctrl.isOpen = false
  }
}
