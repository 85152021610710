import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RayApiService } from './ray-api.service';
import { BatchApi } from 'app/utils/batch-api';
import * as _ from 'lodash'
import { ClassroomDTO } from 'app/models/classroom';
@Injectable({
  providedIn: 'root'
})
export class OedTaskService {
  private classroomBatchApi: BatchApi<number, ClassroomDTO>

  constructor(
    private http: HttpClient,
    private ray: RayApiService,
  ) {

    this.classroomBatchApi = new BatchApi({
      wait: 100,
      getAll: (ids: number[]) => {
        return http.get<ClassroomDTO[]>(ray.apiB('classroom/byIds'), {
          params: {
            ids: ids.map(_.toString)
          }
        }).toPromise().then(items => _.keyBy(items, 'id'))
      }
    })
  }


  /**
   * getClassroomById
   */
  public getClassroomById(id: number) {
    return this.classroomBatchApi.get(id)
  }
}
