import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'oed-exercise-question-previewer'
})
export class OedExerciseQuestionPreviewer extends UpgradeComponent {
  @Input() isExercise: true;
  @Input() qHideDifficult: true;
  @Input() questionModel: any;
  @Input() qIndex: any;
  @Input() qReadonly: true;
  @Input() qShowAnswer: true;
  @Input() qShowAnswerDetails: true;
  @Input() exKnowledgePoint: any;
  @Input() qHidePoint: boolean;
  @Input() exDifficult: any;
  @Input() isNewVersion: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('exerciseQuestionPreviewer', elementRef, injector);
  }
}
