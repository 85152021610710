(function() {
  angular.module('app.prepareCourse')
    .controller('selectStudentsCtrl', selectStudentsCtrl);

  selectStudentsCtrl.$inject = ['$scope', '$q', '$uibModal', '$uibModalInstance', 'userInfo', 'notificationService',
    'oedClassUser', 'data']
  function selectStudentsCtrl($scope, $q, $uibModal,
    $uibModalInstance, userInfo, notificationService,
    oedClassUser, data) {

    $scope.schoolClass = data.schoolClass;
    $scope.selectedClassStudents = new Set(data.selectedClassStudents);

    $scope.loading = oedClassUser.queryClassStudents({
            classId: $scope.schoolClass.id
          }).$promise.then(students => {
            $scope.submitedStudents = _.filter(data.submitedStudents,
              (s) => !_.isEmpty(_.find(students, (std) => std.uid === s)));
            var part = _.partition(students, function(s) {
              return _.includes($scope.submitedStudents, s.uid);
            });
            $scope.studentsOfStarted = part[0];
            $scope.studentsOfUnStarted = part[1];
            $scope.studentsOfStartedUid = _.map($scope.studentsOfStarted, 'uid');
            $scope.selectedClassStudents = new Set([...$scope.selectedClassStudents].filter(item => !_.includes($scope.studentsOfStartedUid, item)));
            // 默认全选(未答题的学生)
            if ($scope.selectedClassStudents.size === 0) {
              _.each($scope.studentsOfUnStarted, student => $scope.selectedClassStudents.add(student.uid));
            }
          });

    $scope.toggleSelectStudent = function(student) {
      if ($scope.selectedClassStudents.has(student.uid)) {
        $scope.selectedClassStudents.delete(student.uid);
      } else {
        $scope.selectedClassStudents.add(student.uid);
      }
    };

    $scope.toggleSelectAllStudents = function() {
      const allStudentCount = _.size($scope.studentsOfUnStarted);
      const selectedStudentCount = _.size($scope.selectedClassStudents);

      if (selectedStudentCount === allStudentCount) {
        // 已经选择全部，则全不选
        $scope.selectedClassStudents.clear();
      } else {
        // 部分选择或全不选，则全选
        _.each($scope.studentsOfUnStarted, student => $scope.selectedClassStudents.add(student.uid));
      }
    };

    $scope.getStdAvatarImgSrc = function(student) {
      if ('female' == student.gender) {
        if (student.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_f1.png';
        } else {
          return 'Assets/images2/stu_avatar_f2.png';
        }
      } else {
        if (student.uid % 2 == 1) {
          return 'Assets/images2/stu_avatar_m1.png';
        } else {
          return 'Assets/images2/stu_avatar_m2.png';
        }
      }
    };

    $scope.ok = function() {
      _.each($scope.submitedStudents, uid => $scope.selectedClassStudents.add(uid));
      if ($scope.selectedClassStudents.size === 0) {
        notificationService.notify('error', '请选择学生')
        return;
      }

      $uibModalInstance.close({
        schoolClass: $scope.schoolClass,
        selectedClassStudents: $scope.selectedClassStudents
      });
    };

    $scope.cancel = function() {
      $uibModalInstance.dismiss('cancel');
    };
  }
})();
