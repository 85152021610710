import { AfterViewInit, Component, ElementRef, EventEmitter,
         Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges,
       ViewChild } from '@angular/core';

import * as _ from 'lodash'

import { UEConfig } from './ueditor.d'

declare let UE: any;

@Component({
  selector: 'app-ueditor',
  template: require('./ueditor.component.html'),
  styles: [require('./ueditor.component.scss')]
})
export class UeditorComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input()
  get content() {
    return this.editorContent
  }

  set content(val) {
    if (this.editorContent === val && !this.forceUpdate) {
      return
    }
    const oldContent = this.editorContent
    this.editorContent = val

    if (this.editorReady) {
      if (val === this.viewContent && this.lastViewTime >= _.now() - UeditorComponent.maxUpdateThreshold) {
        return
      }
      if (!this.setFromEditor) {
        this.editor.setContent(val)
      }
      this.editorContent = this.editor.getContent()
      this.viewContent = this.editorContent
      this.lastViewTime = _.now()
    }
    const needEmit = this.editorContent !== oldContent
    if (needEmit) {
      this.contentChange.emit(this.editorContent)
    }
  }

  public static maxUpdateThreshold = 5 * 1000
  @Input() public config: UEConfig
  @Input() public ready: any
  @Input() public versionStamp: number
  @Input() public updateInterval: number;

  @ViewChild('ueEl') public ueEl: ElementRef

  @Output()
  public contentChange = new EventEmitter<string>();

  private editorContent: string

  private editorReady = false
  private updateTimer
  private editorId = ''
  private editor: any
  private setFromEditor = false
  private forceUpdate = false
  private viewContent = ''
  private lastViewTime = _.now()

  constructor(private el: ElementRef) { }

  public ngOnInit() {
  }

  public ngOnDestroy() {
    if (this.editorId && UE.delEditor) {
      try {
        UE.delEditor(this.editorId);
      } catch(e) {
        console.error(e)
      }
    }
    if (this.updateTimer) {
      clearInterval(this.updateTimer);
    }

    this.editor = null
    this.viewContent = null
    this.lastViewTime = 0
  }

  public ngAfterViewInit() {
    this.initEditor()
    this.scheduleUpdate()
  }

  public updateEditorContent() {
    this.forceUpdate = true
    try {
    const content = this.editorContent || ''
    this.content = content
    } finally {
      this.forceUpdate = false
    }
  }

  /**
   * 初始化编辑器
   * @return {[type]} [description]
   */
  public initEditor() {
    const editor = null;
    this.viewContent = ''
    if (typeof UE === 'undefined') {
      console.error('Please import the local resources of ueditor!');
      return;
    }
    const UeConfig = this.config ? this.config : {};
    this.editorId = '_editor' + (Date.now())
    this.ueEl.nativeElement.id = this.editorId;
    this.editor = new UE.ui.Editor(UeConfig);
    this.editor.render(this.editorId);
    return this.editor.ready(() => {
      this.editorReady = true;
      this.editor.addListener('contentChange', () => {
        this.updateFromEditor()
      });
      this.updateEditorContent();
      if (typeof this.ready === 'function') {
        this.ready(this.editor);
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'versionStamp')) {
      this.checkViewValue()
    }

    if (_.has(changes, 'updateInterval')) {
      this.scheduleUpdate()
    }
  }

  public checkViewValue() {
    if (!this.editorReady) {
      return
    }

    this.updateFromEditor()
  }

  public scheduleUpdate() {
    clearInterval(this.updateTimer)
    const updateInterval = this.updateInterval || 2000
    this.updateTimer = setInterval(() => {
      this.checkViewValue()
    }, updateInterval)
  }

  private updateFromEditor() {
    try {
      this.setFromEditor = true
      this.content = this.editor.getContent()
    } finally {
      this.setFromEditor = false
    }
  }
}
