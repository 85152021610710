
import Promise = require('bluebird')
import _ = require('lodash')

angular.module('app.prepareCourse2.cloud.core.ruanyunTopicSelector', [
])
  .component('ruanyunTopicSelector', {
    bindings: {
      onTopicSelected: '&',
      selectedTopic: '<',
    },
    controller: ruanyunTopicSelector,
    template:  <string> require('./ruanyunTopicSelector.html'),
  })

function ruanyunTopicSelector() {
  this.$onInit = () => {
      const selected = null
      this.materials = [{ name: '期中试卷' }, { name: '期末试卷' }, { name: '中考模拟题' }, { name: '中考真题' }, { name: '会考专题' }, { name: '高考模拟题' }, { name: '高考真题' }, { name: '其它专题' }]
      if (!selected) {
          this.selectedTopic = this.materials[0]
          this.onTopicSelected({
              topic: this.selectedTopic,
          })
      }
  }

//   const loadTopics = () => {
//     return oedCloudTag.getTagsByCategory({
//       name: 'topic',
//     }).$promise.then((topics: any) => {
//       this.materials = _.filter(topics, 'name')
//       let selected = null
//       if (this.selectedTopic) {
//         selected = _.find(this.materials, (m: any) => m.id === this.selectedTopic.id)
//       }
//       if (!selected) {
//         this.selectedTopic = this.materials[0]
//         this.onTopicSelected({
//           topic: this.selectedTopic,
//         })
//       }
//       return this.materials
//     })
//   }
//   this.onVersionSelected = (item: any) => {
//     this.selectedTopic = item
//     this.onTopicSelected({
//       topic: item,
//     })
//   }

  this.isTopicSelected = (topic: any) => {
    return _.get(this.selectedTopic, 'name') === topic.name
  }

  this.selectTopic = (topic: any) => {
    this.selectedTopic = topic
    this.onTopicSelected({
      topic,
    })
  }
}
