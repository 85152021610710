import {RuanyunZuJuanQuestionInfoModel, SplitQuestionInfo, SplitQuestionOption} from 'app2/integration/ruanyun/model'
import {ruanyunAbilityStructures, ruanyunDifficult, ruanyunMethod} from 'app2/integration/ruanyun/ruanyunDic';
// import {getQuesType} from 'app2/integration/xuersi/xuersiUtils'
// import Promise = require('bluebird')
import $ = require('jquery');
import _ = require('lodash');

const blank = '_____'
const circleNumMap = {
  '⓪': 0,
  '①': 1,
  '②': 2,
  '③': 3,
  '④': 4,
  '⑤': 5,
  '⑥': 6,
  '⑦': 7,
  '⑧': 8,
  '⑨': 9,
  '⑩': 10,
}

const audioCss = 'edui-upload-audio  vjs-default-skin audio-js'
const audioDefault = `controls="" preload="none" width="auto" data-setup="{}"`
// const difficultymap = {
//   1: '容易',
//   2: '普通',
//   3: '困难',
// }

// const aCode = 'a'.charCodeAt(0)
// function buildDefaultChoices(options: any) {
//   if (_.isEmpty(options)) {
//     return
//   }
//   const sortKeys = _(options).keys().sortBy().value()
//   return _.map(sortKeys, (key) => {
//     const value = _.get(options, key, '')
//     return {
//       content: `<p>${_.isEmpty(value) ? '' : value}</p>`,
//     }
//   })
// }

function parseChoiceAnswer(info: SplitQuestionInfo): number[] {
  return parseChoceAnswerImpl(info.Options, info.Answer)
}

function parseChoceAnswerImpl(Options, Answer) {
  const sortedOptions = _.sortBy(Options, o => _.toUpper(o.OptionId))
  return _.flatten(_.map(Answer, (a: string) => {
    let answerDetail = a
    try {
      answerDetail = JSON.parse(a)
      return _.map(answerDetail, (d) => _.findIndex(sortedOptions, (o: SplitQuestionOption) => o.OptionId === d))
    } catch {
      return _.findIndex(sortedOptions, (o: SplitQuestionOption) => o.OptionId === a)
    }
  }))
}

function parseChoiceOption(option: SplitQuestionOption) {
    return {
      content: `<p>${_.isEmpty(option.OptionText) ? '' : option.OptionText}</p>`,
    }
}

function choiceDataExtractor(type: string, q: RuanyunZuJuanQuestionInfoModel) {
  return choiceExtractorImpl(type, q, () => {
    return _.chain(q).get('SplitQuestionInfo').map(parseChoiceAnswer).join(',').value()
  })
}

function choiceExtractorImpl(type: string, q: RuanyunZuJuanQuestionInfoModel, getAnswerFuc) {
  if (_.isNil(q.SplitQuestionInfo) || _.isEmpty(q.SplitQuestionInfo)) {
    q.$valid = false
    return
  }
  // 选项数只能从答案中反推...
  if (typeof getAnswerFuc != 'function') {
    q.$valid = false
    return
  }
  const answer = getAnswerFuc()
  if (answer.indexOf('-1') != -1) {
    q.$valid = false
    return
  }
  const choices = (_.chain(q).get('SplitQuestionInfo.0.Options') as any)
    .sortBy((o) => _.toUpper(o.OptionId)).map(parseChoiceOption).value() // tslint:disable-line
  const question = _.get(q, 'SplitQuestionInfo.0.Stem', '') || ''
  const questionBody = getQuestionBody(q, question)

  if (_.isEmpty(answer) && _.includes(answer, '-1')) {
    q.$valid = false
    return
  }

  return {
    question: questionBody,
    answer,
    choices,
    type,
  }
}

function fillChoiceExtractor(q: RuanyunZuJuanQuestionInfoModel) {
  function parseData() {
    const answers = _.get(q, 'SplitQuestionInfo.0.Answer', [])
    const realAnswers = []
    const fillAnswers = []
    _.each(answers, (ans) => {
      const s = _.split(ans, '&nbsp;')
      if (s.length >= 1) {
        realAnswers.push(s[0])
      }
      for(let i = 1; i < s.length; i ++) {
        fillAnswers.push(s[i])
      }
    })
    const options = _.get(q, 'SplitQuestionInfo.0.Options', [])
    const fillBody = _.find(options, (o) => {
      const OptionText = _.get(o, 'OptionText', '')
      return _.includes(OptionText, 'data-ph')
    })
    const result = parseChoceAnswerImpl(options, realAnswers)
    const analysis = _.get(q, 'Analysis', '')
    return {
      quesType: _.size(answers) > 1 ? 'multichoice' : 'singlechoice',
      answer: _.join(result, ','),
      questionBody: _.get(q, 'SplitQuestionInfo.0.Stem', ''),
      fillBody: _.get(fillBody, 'OptionText', ''),
      analysis,
      fillAnswers,
    }
  }

  const {quesType, answer, fillBody, questionBody, analysis, fillAnswers} = parseData()

  function convertToFill() {
    const answerStr = JSON.stringify({
      answer: _.map(fillAnswers, (blank) => [blank]),
      answerPoints: _.map(fillAnswers, () => 1),
      answerTypes: _.map(fillAnswers, () => 'manual-score'),
    })
    return {
      answer: answerStr,
      point2: _.size(fillAnswers),
      question: fillBody,
      type: 'fill',
    }
  }

  console.log('fill question', convertToFill())

  if (_.isEmpty(fillBody)) {
    // 找不到填空题，转为单选题
    return choiceExtractorImpl(quesType, q, () => answer)
  } else {
    // 有填空题，转为综合题
    return {
      childQuestions: [choiceExtractorImpl(quesType, q, () => answer), convertToFill()],
      question: questionBody,
      point2: _.size(fillAnswers) + 1,
      type: 'synthetic',
      answerDetails: analysis,
    }
  }
}

// const fillQuesStemConvertToRay = (q: any) => {
//   let typeName = getQuesType(q)
//   if ('填空' === typeName) {
//     let stem = _.get(q, 'question.body.stem.body', '')
//     stem = _.replace(stem, /<u>(&nbsp;)*<\/u>/g, blank)
//     q.question.body.stem.body = stem
//   }
// }
function checkUseParentStem(q: RuanyunZuJuanQuestionInfoModel) {
  // 如果后续再增加需要显示父题题干的类型，直接再List加入题目类型的id
  const useParentStemIdList = [71, 40, 17]
  const quesCategoryId = _.get(q, 'QuestionCategoryId', 0)
  return _.findIndex(useParentStemIdList, id => id === quesCategoryId) >= 0
}

function fillQuestionExtractor(answerType: string, q: RuanyunZuJuanQuestionInfoModel, isExam: boolean) {
  // 考试的填空一律按照问答题处理
  if (isExam) {
    return qaDataExtractor(q)
  }
  let stem = _.get(q, 'SplitQuestionInfo.0.Stem', '') || ''
  const useParentStem = checkUseParentStem(q)
  if (useParentStem) {
    stem = _.get(q, 'Stem', '')
  }
  const pattern1 = /<u>(\s)*(&nbsp;)*(\s)*<\/u>/g
  const pattern2 = /___+/g
  const pattern3 = /_____+/g
  // const pattern4 = /<span( )*(data-ph( )*=.*)>(___*)<\/span>/g

  const content = _.chain(stem).replace(pattern1, blank).replace(pattern2, blank).value()
  const questionBody = getQuestionBody(q, content)

  const blankCount = content.match(pattern3)

  if (_.isEmpty(blankCount)) {
    q.$valid = false
    return
  }

  // 如果答案为空，则手动批改
  const answer = _.get(q, 'SplitQuestionInfo.0.Answer', [])
  if (_.isEmpty(answer)) {
    answerType = 'manual-score'
  }

  const answerStr = JSON.stringify({
    // tslint:disable-next-line:no-shadowed-variable
    answer: _.map(blankCount, (blank: any, index: number) => {
      const text = $(`<p>${_.get(answer, index, '')}</p>`).text()
      return [_.chain(text).split('').map(a => _.get(circleNumMap, a, a)).join('').value()]
    }),
    answerPoints: _.map(blankCount, () => 1),
    // tslint:disable-next-line:no-shadowed-variable
    answerTypes: _.map(blankCount, (blank: any, index: number) => {
      const text = _.get(answer, index, '')
      if ($(`<p>${text}</p>`).text() === '') {
        return 'manual-score'
      }
      return guessAnswerType(text)
    }),
  })
  return {
    answerDetails: _.get(q, 'Analysis', ''),
    answer: answerStr,
    point2: _.size(answer),
    question: questionBody,
    type: 'fill',
  }
}

function fillQuestionExtractor2(answerType: string, q: RuanyunZuJuanQuestionInfoModel, isExam: boolean) {
  // 考试的填空一律按照问答题处理
  if (isExam) {
    return qaDataExtractor(q)
  }
  let stem = _.get(q, 'SplitQuestionInfo.0.Stem', '') || ''
  const useParentStem = checkUseParentStem(q)
  if (useParentStem) {
    stem = _.get(q, 'Stem', '')
  }
  const pattern1 = /<u>( )*(&nbsp;)*( )*<\/u>/g
  const pattern2 = /___+/g
  const pattern3 = /_____+/g

  const content = _.chain(stem).replace(pattern1, blank).replace(pattern2, blank).value()
  const questionBody = getQuestionBody(q, content)
  // 如果答案为空，则手动批改
  const answer = _.get(q, 'SplitQuestionInfo.0.Answer', [])
  const options = _.get(q, 'SplitQuestionInfo.0.Options', []) as SplitQuestionOption[]
  if (_.isEmpty(answer)) {
    answerType = 'manual-score'
  }

  const answerStr = JSON.stringify({
    answer: _.map(answer, (OptionId: string, index: number) => {
      const Option = _.find(options, (op: SplitQuestionOption) => op.OptionId === OptionId, null) as SplitQuestionOption
      return [$(`<p>${_.get(Option, 'OptionText', '')}</p>`).text()]
    }),
    answerPoints: _.map(answer, () => 1),
    answerTypes: _.map(answer, (OptionId: any, index: number) => {
      const Option = _.find(options, (op: SplitQuestionOption) => op.OptionId === OptionId, null) as SplitQuestionOption
      const OptionText = _.get(Option, 'OptionText', '')
      if ($(`<p>${OptionText}</p>`).text() === '') {
        return 'manual-score'
      }
      return guessAnswerType(OptionText)
    }),
  })
  return {
    answerDetails: _.get(q, 'Analysis', ''),
    answer: answerStr,
    point2: _.size(answer),
    question: questionBody,
    type: 'fill',
  }
}

function guessAnswerType(answer: string) {
  if (answer.indexOf('&plusmn;') >= 0 || answer.indexOf('&times;') >= 0 || answer.indexOf('答案不唯一') >= 0
  || answer.indexOf('&#39;') || answer.indexOf('&shy;')) {
    return 'manual-score'
  }
  if ($('<p></p>').html(answer).find('img').length > 0 || $('<p></p>').html(answer).find('sup').length > 0) {
    return 'manual-score'
  }
  return 'exact-match'
}

function qaDataExtractor(q: RuanyunZuJuanQuestionInfoModel) {
  let question = _.get(q, 'SplitQuestionInfo.0.Stem', '') || ''
  const useParentStem = checkUseParentStem(q)
  if (useParentStem) {
    question = _.get(q, 'Stem', '')
  }
  const content = _.isEmpty(question) ? q.Stem : question
  const questionBody = getQuestionBody(q, content)
  const answer = _.get(q, 'SplitQuestionInfo.0.Answer', [])
  const answerStr = _.join(_.map(answer, (a: string, i: number) => {
    if (i === 0) {
      return $(`<div>${a}</div>`).html()
    }
    return $(`<div>&emsp;&emsp;&emsp;${a}</div>`).html()
  }), '<br>')
  let answerDetails = q.Analysis
  if (answerStr != '') {
    answerDetails = `答案：${answerStr}<br>解析：${q.Analysis}`
  }
  return {
    question: questionBody,
    answerDetails,
    answer: '-1',
    type: 'qa',
  }
}

function answerDetailsExtractor(q: RuanyunZuJuanQuestionInfoModel) {
  const answerDetails = _.get(q, 'Analysis', '')
  return {
    answerDetails,
  }
}

// function charCodeToInt(c: any) {
//   return c.toUpperCase().charCodeAt(0) - baseCharCode
// }

// function parseMultiChoiceAnswers(q: any) {
//   return _.chain(q).get('question.body.answer.items', '').values()
//   .map(charCodeToInt).join(',').value()
// }

// function multiChoiceQuestionExtractor(q: any) {
//   // 选项数只能从答案中反推...
//   const answer = parseMultiChoiceAnswers(q)

//   return {
//     answer,
//     choices: buildDefaultChoices(_.get(q, 'question.body.stem.options')),
//     type: 'multichoice',
//   }
// }

// function parseConnectAnswer(q: any) {
//   const answers = _.get(q, 'question.body.answer.items', '')
//   if (_.isEmpty(answers)) {
//     return ''
//   }
//   let answerStrs = []
//   _.forIn(answers, (v, k) => {
//     answerStrs.push(`${(parseInt(k, 10) - 1)}:${charCodeToInt(v)}`)
//   })
//   return _.join(answerStrs, ',')
// }

// function buildConnectOpts(options: any) {
//   const sortKeys = _(options).keys().sortBy().value()
//   return _.map(sortKeys, (key) => {
//     const value = _.get(options, key, '')
//     return {
//       content: `<p>${_.isEmpty(value) ? '' : value}</p>`,
//     }
//   })
// }

// function connectQuestionExtractor(q: any) {
//   const answer = parseConnectAnswer(q)

//   return {
//     answer,
//     leftOpts: buildConnectOpts(_.get(q, 'question.body.stem.options.left', '')),
//     rightOpts: buildConnectOpts(_.get(q, 'question.body.stem.options.right', '')),
//     type: 'connect',
//   }
// }

const rightAnswerFlag = 'TW对√&radic;'
function parseYesornoAnswer(q: any) {
  const answer = _.get(q, 'SplitQuestionInfo.0.Answer', '')
  return _.includes(rightAnswerFlag, answer) ? '1' : '0'
}

function yesOrNoQuestionExtractor(q: any) {
  const answer = parseYesornoAnswer(q)
  const content = _.get(q, 'SplitQuestionInfo.0.Stem', '') || ''
  const questionBody = getQuestionBody(q, content)
  return {
    question: questionBody,
    answer,
    type: 'yesorno',
  }
}

function syntheticQuestionExtractor(q: RuanyunZuJuanQuestionInfoModel, isExam) {
  const childQuestions = _.chain(q).get('$ChildQuestions', [])
  .map((cq) => {
    return convertRuanyunQuestionToRayQ(cq, null, isExam)
  }).filter('$valid').value()
  q.$valid = _.size(childQuestions) > 0
  return {
    childQuestions,
    type: 'synthetic',
  }
}

const simpleQaExtractor = [qaDataExtractor]
/* tslint:disable: object-literal-sort-keys */
// 短文改错
const convertConfigs = {
  '单选题': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'singlechoice')],
  '不定项选择题': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'multichoice')],
  '填空题': [_.partial(fillQuestionExtractor, 'exact-match')],
  '判断题': [answerDetailsExtractor, yesOrNoQuestionExtractor],
  '单词拼写': [_.partial(fillQuestionExtractor, 'exact-match')],
  '完形填空': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'singlechoice')],
  '选词填空': [_.partial(fillQuestionExtractor, 'exact-match')],
  '补全对话(填空)': [_.partial(fillQuestionExtractor, 'exact-match')],
  '书面表达': [answerDetailsExtractor, qaDataExtractor],
  '翻译': [answerDetailsExtractor, qaDataExtractor],
  '解答': simpleQaExtractor,
  'ray综合题': [answerDetailsExtractor, syntheticQuestionExtractor],
  '0': [_.partial(fillQuestionExtractor, 'exact-match')],
  '1': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'singlechoice')],
  '2': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'multichoice')],
  '5': [_.partial(fillQuestionExtractor, 'exact-match')],
  '6': [qaDataExtractor],
  '9': [answerDetailsExtractor, yesOrNoQuestionExtractor],
  '10': [_.partial(fillQuestionExtractor2, 'exact-match')],
  '14': [answerDetailsExtractor, fillChoiceExtractor],
  // 综合题子题的questionDisplayId, 如果为15，则已SplitQuestionInfo里的displayId为准，否则加10000
  '10008': [_.partial(fillQuestionExtractor, 'exact-match')],
  '10004': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'singlechoice')],
  '10003': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'singlechoice')],
  '10012': [answerDetailsExtractor, _.partial(choiceDataExtractor, 'multichoice')],
  '10013': [answerDetailsExtractor, yesOrNoQuestionExtractor],
}

const syntheticDisplayIds = [ 3, 4, 7, 8, 12, 13, 15, 17 ]

function getQuestionBody(q: RuanyunZuJuanQuestionInfoModel, content = '') {
  if (q.$isChildQues && q.$parentAudioUrl == q.QuestionAudioUrl) {
    return `<p>${content}</p>`
  }
  const audioTag = `<audio class='${audioCss}' ${audioDefault} src='${q.QuestionAudioUrl}'></audio>`
  const questionBody = _.isEmpty(q.QuestionAudioUrl) ? `<p>${content}</p>` : `<p>${audioTag}${content}</p>`
  return questionBody
}

function commonDataExtractor(q: RuanyunZuJuanQuestionInfoModel) {
  const fromThirdParty = 'ruanyun'
  const fromThirdPartyId = `${q.QuestionId}`
  const content = _.isEmpty(q.SplitQuestionStem) ? q.Stem : q.SplitQuestionStem
  const questionBody = getQuestionBody(q, content)

  const sort = _.get(q, 'SplitQuestionInfo.0.OrderIndex', 0)
  return {
    $sourceType: 'ruanyun',
    fromThirdParty,
    fromThirdPartyId,
    point2: 1,
    question: questionBody,
    $valid: true,
    sort,
  }
}

function commonDataExtractorForGenerating(q: RuanyunZuJuanQuestionInfoModel) {
  const fromThirdParty = 'ruanyun';
  const fromThirdPartyId = `${q.QuestionId}`;
  const content = _.isEmpty(q.SplitQuestionStem) ? q.Stem : q.SplitQuestionStem
  const questionBody = getQuestionBody(q, content)
  const sort = _.get(q, 'SplitQuestionInfo.0.OrderIndex', 0);
  return {
    $sourceType: 'ruanyun',
    fromThirdParty,
    fromThirdPartyId,
    point2: q.point,
    question: questionBody,
    $valid: true,
    sort,
  }
}

export function listeningHasntAudioUrl(q: RuanyunZuJuanQuestionInfoModel) {
  const quesType = q.QuestionCategoryName
  const isListening = _.includes(quesType, '听力')
  if (!isListening) {
    return false
  }
  const audioUrl = q.QuestionAudioUrl;
  if (audioUrl == null) {
    return true
  }
  return false
}

/**
 * 1. 根据 displayId 判断是否为复合题
 * 2，如果是复合题且displayId不为15
 *      则 子题的displayId为 10000 + 题干的displayId
 *      否则 子题的displayId为 SplitQuestionInfo的QuestionDisplayId
 * 3. 先根据 QuestionCategoryName 解析题目，如果不存在根据，QuestionDisplayId 解析题目， 如果还是不存在，转换为问答题
 */
export const convertRuanyunQuestionToRayQ = (q: RuanyunZuJuanQuestionInfoModel, knowledgePoints = null, isGeneratingPaper = false) => {
  let commQ;
  if (isGeneratingPaper) {
    commQ = commonDataExtractorForGenerating(q);
  } else {
    commQ = commonDataExtractor(q);
  }
  return convertRuanyunQuestionWithoutCommPart(q, knowledgePoints, commQ, isGeneratingPaper);
};

export function getAllData(q: RuanyunZuJuanQuestionInfoModel, isExam: boolean) {
  let conveters: any;

  const displayId = q.QuestionDisplayId;
  let typeName = q.QuestionCategoryName;
  if (displayId && _.includes(syntheticDisplayIds, displayId)) {
    typeName = 'ray综合题';
  }
  if (_.has(convertConfigs, typeName)) {
    conveters = _.flatten([_.get(convertConfigs, typeName)])
  } else if (_.has(convertConfigs, displayId)) {
    conveters = _.flatten([_.get(convertConfigs, _.toString(displayId))])
  } else {
    conveters = simpleQaExtractor
    console.warn(`question transformation for ${typeName} not implemented`)
  }

  const qData: any = _.map(conveters, (c: any) => c(q, isExam))
  return (<any>_.spread)(_.defaults, 1)({}, qData);
}

const convertRuanyunQuestionWithoutCommPart = (q: RuanyunZuJuanQuestionInfoModel, knowledgePoints = null, commQ, isGeneratingPaper = false) => {

  if (q.QuestionDisplayId === 11) {
    console.error('改错', q.QuestionDisplayId, q)
  }
  if (q.QuestionDisplayId === 14) {
    console.error('选择填充', q.QuestionDisplayId, q)
  }
  if (q.QuestionDisplayId === 16) {
    console.error('辨析改错', q.QuestionDisplayId, q)
  }

  q.$valid = true
  const dispalyId = q.QuestionDisplayId
  // 判断是否未综合题
  if (dispalyId && _.includes(syntheticDisplayIds, dispalyId)) {
    const SplitQuestionInfos = q.SplitQuestionInfo
    const qId = q.QuestionId
    q = _.omit(q, ['SplitQuestionInfo'])
    const copyQ = q
    q.$ChildQuestions = _.map(SplitQuestionInfos, (info: SplitQuestionInfo, index: number) => {
      return {
        ...copyQ,
        QuestionDisplayId: q.QuestionDisplayId === 15 ? info.QuestionDisplayId : 10000 + q.QuestionDisplayId,
        QuestionId: `${qId}_${index}`,
        SplitQuestionInfo: [info],
        Analysis: q.Analysis,
        $isChildQues: true,
        $parentAudioUrl: q.QuestionAudioUrl,
      }
    })
  }

  const allData = getAllData(q, isGeneratingPaper);

  const difficulty = _.find(ruanyunDifficult, (d: any) => d.type === q.QuestionLevel)
  let relatedSections = []
  if (!_.isEmpty(knowledgePoints) && !_.isEmpty(q.KnowledgePointIds)) {
    relatedSections = _(q.KnowledgePointIds).map((id) => knowledgePoints[id] || {}).filter((kp) => kp.name).value()
  }

  let kpName = ''
  let kpId = ''
  if (!_.isEmpty(relatedSections)) {
    kpName = _(relatedSections).map((x) => _.get(x, 'name')).join(', ')
    kpId = _(relatedSections).map((x) => _.get(x, 'id')).join(', ')
  }
  const ability = _.find(ruanyunAbilityStructures, (a: any) => '' + a.labelId === q.AbilityStructure)
  const method = _.find(ruanyunMethod, (m: any) => '' + m.labelId === q.AnalyticMethod)
  let rayQ = {
    ...commQ,
    ...allData,
    difficultName: difficulty ? difficulty.typeName : '',
    ability: ability ? ability.ability : '',
    method: method ? method.labelName : '',
    kpName,
    kpId,
    $valid: q.$valid,
  }
  if (isGeneratingPaper) {
    rayQ = {
      ...rayQ,
      point2: commQ.point2,
    };
  }
  return rayQ
}
