(function() {
  var boardDesigner = require('app2/directives/board/boardDesigner.directive');
  var resourceItemsPreviewer = require('app2/prepareCourse/resourceItemsPreviewer.ctrl');
  var resItemsPreviewerForMicroLesson = require('app2/prepareCourse/resItemsPreviewerForMicroLesson.ctrl');
  var resStarInfo = require('app2/directives/cloud/resStarInfo.directive');
  var importFromLibraryCtrl = require('./prepareCourse/importFromLibraryCtrl');
  var questionsPreviewer = require('app2/prepareCourse/questionsPreviewer.ctrl');

  require('assets/app/controllers/prepareCourse/designSubjectiveTestCtrl.js');
  require('assets/app/controllers/prepareCourse/presentResourceCtrl')
  require('assets/app/controllers/prepareCourse/teachingDesignCtrl')

  angular.module('app.prepareCourse', [
    'ui.router', 'ngCookies',
    'app.directives.board.boardDesigner',
    'app.directives.cloud.resStarInfo',
    'app.prepareCourse.importFromLibraryCtrl',
    'app.prepareCourse2.questionsPreviewer',
    'app.prepareCourse2.resourceItemsPreviewer',
    'app.prepareCourse2.resItemsPreviewerForMicroLesson',
    'app.prepareCourse.addMicroLessonRecordCtrl',
    'app.prepareCourse.presentResource',
    'app.directives.cloud.resItemPreview',
    'app.prepareCourse.designSubjectiveTest',
    'app.prepareCourse.teachingDesign',
  ])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareCourse', {
        url: '/prepareCourse/:courseId',
        template: require('assets/templates/prepareCourse.html'),
        controller: 'prepareCourseCtrl',
        loadingCls: 'blueloading',
        resolve: {
          curCourse: ['$stateParams', 'oedCourse', function($stateParams, oedCourse) {
            return oedCourse.get({
              id: $stateParams.courseId
            });
          }]
        }
      });
      $stateProvider.state('prepareCourse.ObjectiveQuestion', {
        url: '/objectiveQuestion',
        template: require('assets/templates/prepareCourse/objectiveQuestion.html'),
        controller: 'prepareCourse.ObjectiveQuestionCtrl',
        loadingCls: 'blueloading'
      });

    }])
    .controller('prepareCourseCtrl', prepareCourseCtrl)
    .controller('prepareCourse.ObjectiveQuestionCtrl', ObjectiveQuestionCtrl);

  prepareCourseCtrl.$inject = ['$scope', '$state', '$stateParams', 'oedCourse', 'localStorageService']
  function prepareCourseCtrl($scope, $state, $stateParams, oedCourse, localStorageService) {
    $scope.courseId = $stateParams.courseId;
    if ($scope.courseId) {
      var curCpId = localStorageService.get('lastCoursePathId');
      var curCpMap = localStorageService.get('lastCoursePathCourseMap', curCpId);
      if (!curCpMap) {
        curCpMap = {};
      }
      curCpMap[curCpId] = $scope.courseId;
      localStorageService.set('lastCoursePathCourseMap', curCpMap);
    }
    $scope.subTitle = {};
    $scope.course = oedCourse.get({
      id: $scope.courseId
    });

    $scope.currentState = {};
    $scope.shouldBeActive = function(states) {
      if (_.isArray(states)) {
        return _.findIndex(states, function(st) {
          return $state.includes(st);
        }) >= 0;
      }

      return $state.includes(states);
    };
  }

  ObjectiveQuestionCtrl.$inject = ['$scope']
  function ObjectiveQuestionCtrl($scope) {
    $scope.question = {
      question: 'test title',
      choices: [{
        name: 'A',
        content: 'this is choice 1'
      }, {
        name: 'B',
        content: 'this is choice 2'
      }, {
        name: 'C',
        content: 'this is choice 3'
      }, {
        name: 'D',
        content: 'this is choice 4'
      }]
    };
  }
})();
