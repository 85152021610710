import { Component, ElementRef, EventEmitter, HostListener, Input,
  OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { DifficultType, ExamType, QuestionType } from '../models/talcloud';

@Component({
  selector: 'app-filter-selector',
  template: require('./filter-selector.component.html'),
  styles: [require('./filter-selector.component.scss')]
})
export class FilterSelectorComponent implements OnInit, OnChanges {

  @Input() public types: QuestionType[] | ExamType[] | DifficultType[]
  @Input() public defaultQuesTypeIdx: number
  @Input() public cleanFilter: any
  @Output() public selectType = new EventEmitter<QuestionType | ExamType | DifficultType>()
  @ViewChild('itemContainer') public itemContainer: ElementRef
  private selectedType: QuestionType | ExamType | DifficultType
  private allTypes: QuestionType[] | ExamType[] | DifficultType[]
  private expandContainerWith = 55
  private hasMore = false
  private show = false
  private selectedIndex = 0

  private typesSubject = new Subject<any>()
  private types$ = this.typesSubject.asObservable()
  private typeSubscription = new Subscription()

  constructor() {
    this.typeSubscription = this.types$
    .subscribe(() => {
      setTimeout(() => {
        let allWidth = 0
        const clientWidth = this.itemContainer.nativeElement.clientWidth
        this.allTypes =
        _.map(this.types, (t: QuestionType | ExamType | DifficultType) => {
          const thisWidth = this.calcWith(t.text)
          allWidth = allWidth + thisWidth
          const visible = allWidth + this.expandContainerWith < clientWidth
          let newType = {
            ...t,
            width: thisWidth,
            allWidth,
            visible,
          }
          if (!visible) {
            newType = {
              ...newType,
              over: true,
            }
          }
          return newType
        }) as QuestionType[] | ExamType[] | DifficultType[]
        this.hasMore = _.some(this.allTypes, ['visible', false])
        this.selectedType = this.allTypes[0]
        this.show = false
      }, 0)
    })
  }

  public ngOnInit() {
  }

  public showOverflow() {
    this.show = !this.show
    const newAllTypes = _.map(this.allTypes, (t: QuestionType | ExamType | DifficultType) => {
      if (t.over) {
        t.visible = !t.visible
      }
      return {
        ...t,
      }
    }) as QuestionType[] | ExamType[] | DifficultType[]
    this.allTypes = [
      ...newAllTypes,
    ] as QuestionType[] | ExamType[] | DifficultType[]
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'defaultQuesTypeIdx') && this.defaultQuesTypeIdx > 0) {
      this.selectedIndex = this.defaultQuesTypeIdx
    }
    if (_.has(changes, 'types') && !_.isEmpty(this.types)) {
      this.types = _.sortBy(this.types, ['sort']) as QuestionType[] | ExamType[] | DifficultType[]
      this.typesSubject.next()
    }
    if (_.has(changes, 'cleanFilter')) {
      if (this.cleanFilter) {
        this.selectedIndex = 0
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    this.typesSubject.next()
  }

  public ngOnDestroy() {
    this.typeSubscription.unsubscribe()
  }

  private calcWith(text: string) {
    if (_.size(text) === 0) {
      return 0
    }
    if (!_.isNaN(_.toNumber(text))) {
      return _.size(text) * 7 + 20 + 2 + 5
    }
    return _.size(text) * 14 + 20 + 2 + 5
  }

  private itemChecked(index) {
    return this.selectedIndex === index
  }

  private chooseOneType(type, index) {
    this.selectedType = type
    this.selectedIndex = index
    this.selectType.emit(type)
  }

}
