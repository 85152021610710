(function() {
  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentHome', {
        url: '/studentHome',
        template: require('assets/templates/student/studentHome.html'),
        controller: 'studentHomeCtrl',
        loadingCls: 'homeloading',
        bodyStyle: 'newstyle'
      });
    }]).controller('studentHomeCtrl', studentHomeCtrl);

  studentHomeCtrl.$inject = ['$scope', '$timeout', 'userInfo', 'oedUserInfoUtils']
  function studentHomeCtrl($scope, $timeout, userInfo, oedUserInfoUtils) {
    $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl
    $scope.userInfo = userInfo.get()
    $('.newcontent').animate({
      'opacity': 1,
      'left': '0px'
    }, 400);
  }
})();
