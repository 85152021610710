(function() {
  angular.module('app.signin.findPassword', ['ui.router', 'ngCookies', 'timer'])
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('findPassword', {
        url: '/anno/findPassword',
        template: require('assets/templates/signin/findPassword.html'),
        controller: 'findPasswordCtrl',
        bodyStyle: 'newstyle'
      });
    }])
    .controller('findPasswordCtrl', findPasswordCtrl);

  findPasswordCtrl.$inject = ['$scope', '$state', 'userInfo', 'oedUserInfo', '$uibModal', 'notify', 'notificationService']
  function findPasswordCtrl($scope, $state, userInfo, oedUserInfo, $uibModal, notify, notificationService) {
    $scope.type = {
      name: 'email'
    };
    $scope.info = {};
    $scope.findPassword = function(info) {
      var req = oedUserInfo.findPassword(info);
      $scope.loading = req.$promise.then(function(resp) {
        if (!resp.success) {
          $scope.errorMessage = resp.message;
        } else if ($scope.type.name === 'email') {
          $scope.errorMessage = null;
          $scope.mailSent = true;
        } else if ($scope.type.name === 'mobile') {
          $scope.errorMessage = null;
          $scope.mobileSent = true;
        }
      });
    };

    $scope.$on('timer-stopped', function(event, data) {
      $scope.$apply(function() {
        $scope.enableCountDown = false;
        $scope.timerRunning = false;
      });
    });

    $scope.getVerifyCode = function() {
      if (_.isEmpty($scope.info.mobile)) {
        return notificationService.notify('error', '请先填写表单中的手机号')
      }

      var signup = oedUserInfo.signupVerifyCode({
        mobile: $scope.info.mobile,
        type: 'update'
      });

      $scope.loading = signup.$promise.then(function(resp) {
        if (resp.smsErrCode == -2) {
          notificationService.notify('error', '请求过于频繁，请稍后再试')
        } else if (resp.smsErrCode == -1) {
          notificationService.notify('error', '短信发送失败，请稍后重试')
        } else {
          notificationService.notify('error', '短信发送成功，请继续')
          if ((!$scope.timerRunning)) {
            $scope.$broadcast('timer-reset');
          }

          $scope.$broadcast('timer-start');
          $scope.timerRunning = true;
          $scope.enableCountDown = true;
        }
      });
      /**
      var modalInstance = $uibModal.open({
        template: require('assets/templates/getVerifyCode.html'),
        controller: 'getVerifyCodeCtrl',
        size: 'sm',
        windowClass: 'signupModal',
        resolve: {
          data: function() {
            return {
              type: 'update',
              mobile: $scope.info.mobile
            };
          }
        }
      });

      modalInstance.result.then(function(result) {
        if (result) {
          if ((!$scope.timerRunning)) {
            $scope.$broadcast('timer-reset');
          }

          $scope.$broadcast('timer-start');
          $scope.timerRunning = true;
          $scope.enableCountDown = true;
        }
      });**/
    };

  }
})();
