import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AppStateService } from './app-state.service'
import { OedUibmodalService } from './oed-uibmodal.service'
import { RayApiService } from './ray-api.service'
import { SchoolFeatureService } from './school-feature.service'
import { UserInfoService } from './user-info.service'
import {ResourceService} from 'app/core/resource.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [AppStateService, RayApiService, UserInfoService, SchoolFeatureService, OedUibmodalService, ResourceService]
})
export class CoreModule { }
