import _ = require('lodash');
import moment = require('moment');

/**
 * 计算学生查看某一个item的用时,将所有的log的duration相加求和
 * @param logListOfItem
 */
export function durationSpentOnItem(logListOfItem: any) {
  if (_.isUndefined(logListOfItem)) {
    return '0分';
  }

  let duration = _.chain(logListOfItem).groupBy('startTime').map((val, key) => {
    const max = _.maxBy(val, 'endTime');
    return max.endTime - max.startTime;
  }).sum().value();

  if (duration === 0) {
    return '0分';
  } else {
    duration = duration + 59999;
  }

  const format = 'm[分]';
  return moment.utc(
    moment.duration(duration, 'milliseconds').asMilliseconds(),
  ).format(format);
}

/**
 * 计算学生查看展示资源总共花费的时间，取head的startTime和last的endTime相减取差值
 * 不一定等于所有log的duration的和
 * @param logList
 */
export function durationSpentOnSession(logList: any) {
  if (_.isUndefined(_.head(logList))) {
    return '0秒';
  }
  const head: any = _.head(logList);
  const startTime: any = head.startTime;
  const last: any = _.last(logList);
  const endTime = last.endTime;
  let format = 's[秒]';
  const duration = endTime - startTime;
  if (duration > 60 * 1000) {
    format = 'm[分]s[秒]';
  }
  return moment.utc(
    moment.duration(duration, 'milliseconds').asMilliseconds(),
  ).format(format);
}

export function minutesOnSession(logList: any) {
  if (_.isUndefined(_.head(logList))) {
    return '0分';
  }
  const duration = _.chain(logList).groupBy('preResItemId').map((val, key) => {
    // 这里先修复下小锐作业上报时间的问题
    const sum = _.chain(val).groupBy('startTime').map((val1, key1) => {
      const max = _.maxBy(val1, 'endTime');
      return max.endTime - max.startTime;
    }).sum().value();
    return Math.ceil(sum / 60000) * 60000;
  }).sum().value();
  return Math.ceil(duration / 60000) + '分';
}

/**
 * 计算session的duration,endTime-startTime
 * @param preResSession
 */
export function timeDiff(preResSession: any) {
  let format = 's[秒]';
  const duration = preResSession.endTime - preResSession.startTime;
  if (duration > 60 * 1000) {
    format = 'm[分]s[秒]';
  }
  return moment.utc(
    moment.duration(duration, 'milliseconds').asMilliseconds(),
  ).format(format);
}
