(function() {
  angular.module('app.prepareCourse')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('addCourseToPath', {
        url: '/addCourseToPath/:pathId',
        template: require('assets/templates/prepareCourse/editInfo.html'),
        controller: 'addCourseCtrl',
        loadingCls: 'blueloading',
        bodyStyle: 'bged'
      });
    }])
    .controller('addCourseCtrl', addCourseCtrl);

  addCourseCtrl.$inject = ['$scope', '$state', '$stateParams', '$uibModal', '$log', 'dialogs',
    'userInfo', 'oedConfig', 'oedCourse', 'oedLoading', 'oedSection', 'oedUnitItem',
    'oedCourseUtils', 'oedCoursePath', 'oedOem']
  function addCourseCtrl($scope, $state, $stateParams, $uibModal, $log, dialogs,
    userInfo, oedConfig, oedCourse, oedLoading, oedSection, oedUnitItem,
    oedCourseUtils, oedCoursePath, oedOem) {
    $scope.course = {
      coursepathId: _.parseInt($stateParams.pathId),
      relatedSections: []
    };

    $scope.adding = true
    if ($scope.course.coursepathId) {
      oedCoursePath.get({id: $scope.course.coursepathId}).$promise.then(function(c) {
        $scope.coursePath = c;
      });
    }

    $scope.treeConfig = {
      core: {
        multiple: true,
        animation: true,
        error: function(error) {
          $log.error('treeCtrl: error from js tree - ' + angular.toJson(error));
        },
        check_callback: true,
        worker: true
      },
      types: {
        default: {
          icon: 'fa fa-book'
        },
        root: {
          icon: 'glyphicon glyphicon-cloud'
        }
      },
      version: 1,
      plugins: ['types', 'checkbox']
    };

    var convertToJsTreeModel = function(sections) {
      var allNodes = [];

      function addAllNodes(sec) {
        var id = sec.id || 0;
        var parentId = (_.has(sec, 'parentId') && sec.parentId !== null) ? sec.parentId : '0';
        if (!_.has($scope.sectionIdMap, parentId)) {
          parentId = '#';
        }

        var name = sec.name || sec.gradeName;

        allNodes.push({
          id: id,
          parent: parentId,
          text: name,
          state: {
            opened: true
          }
        });
        _.each(sec.child, addAllNodes);
      }

      if (_.size(sections) == 1) { //只有一个年级的情况, 忽略掉年级这个node{
        _.each(sections[0].child, addAllNodes);
      } else {
        _.each(sections, addAllNodes);
      }

      return allNodes;
    };

    $scope.sectionTree = {
      treeData: []
    };

    $scope.onSelChanged = function() {
      $scope.$apply(function() {
        var selectedNodes = $scope.treeInstance.jstree(true).get_selected();
        var ids = _(selectedNodes).map(function(node) {
          return _.parseInt(node, 10);
        }).filter().value();

        $scope.loading = oedCourseUtils.loadSectionNamesById(ids).then(function(name) {
          $scope.selectedSectionNames = name || '';
          if (_.isEmpty($scope.course.courseName)) {
            $scope.course.courseName = name;
          }
        });
      });
    };

    userInfo.then(function(info) {
      $scope.availableSections = oedUnitItem.queryAvailableBySchoolAndCoursePath({
        schoolId: info.schoolId,
        coursePathId: $scope.course.coursepathId
      });

      $scope.canStar = info.canStar;
      $scope.uid = info.uid;

      $scope.availableSections.$promise.then(function() {
        $scope.sectionIdMap = {};

        function visitSection(sec) {
          if (sec.id) $scope.sectionIdMap[sec.id] = sec;
          if (sec.child) {
            sec.child = _.sortBy(sec.child, ['sort']);
            _.each(sec.child, visitSection);
          }
        }
        _.each($scope.availableSections, visitSection);

        $scope.sectionTree.treeData = convertToJsTreeModel($scope.availableSections);
        $scope.treeConfig.version++;

      });
    })

    $scope.onTreeReady = function() {
      if ($scope.course.relatedSections) {
        var sections = _.map($scope.course.relatedSections, function(relSec) {
          return relSec.sectionId;
        });
        $scope.treeInstance.jstree(true).select_node(sections);
      }
    };

    $scope.shouldApplyModelChanges = function() {
      return true;
    };

    $scope.preventDropdownDefault = function(event) {
      event.preventDefault();
      event.stopPropagation();
    };

    function showCoursePlan() {
      return $scope.inEduGroup && oedOem.curOem() === 'futureSchool';
    }

    $scope.showSecNo = function() {
      return showCoursePlan()
    }

    $scope.gotoNext = function() {
      $scope.showValidationError = true;
      var selectedNode = $scope.treeInstance.jstree(true).get_selected();
      $scope.course.relatedSections = _.map(selectedNode, function(secId) {
        return {
          sectionId: _.parseInt(secId)
        };
      });

      if (_.isEmpty($scope.course.relatedSections)) {
        dialogs.error('无法保存', '当前课程没有关联章节, 请关联章节后再继续');
        return;
      }

      if (showCoursePlan() && $scope.course.relatedSections.length > 1) {
        dialogs.error('无法保存', '课程只能关联一个章节');
        return;
      }

      if (showCoursePlan() &&
        ($scope.course.sectionIndex < 1 || $scope.course.sectionIndex > 5)) {
        dialogs.error('无法保存', '课时只能设置1-5');
        return;
      }

      oedCourse.save($scope.course, function(cs) {
        //todo 可能会导致数据不一致
        if ($scope.coursePath.templateType && $scope.canStar) {
          var update = oedCoursePath.updateCourseOfTemplate({id: cs.id}).$promise;
        }
        if (showCoursePlan()) {
          $state.go('prepareCoursePlan.knowledgeGraph', {
            courseId: cs.id
          });
        } else {
          $state.go('prepareCourse.objectiveTest', {
            courseId: cs.id
          });
        }
      });
    };
  }
})();
