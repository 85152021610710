import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './safe-html.pipe';
import { ToUniqResIdPipe } from './to-uniq-res-id.pipe';
import {SafeUrlPipe} from 'app/utils/safe-url.pipe';

@NgModule({
  declarations: [SafeHtmlPipe, ToUniqResIdPipe, SafeUrlPipe],
  exports: [SafeHtmlPipe, ToUniqResIdPipe, SafeUrlPipe],
  imports: [
    CommonModule
  ]
})
export class UtilsModule { }
