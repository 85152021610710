import { Injectable, OnDestroy } from '@angular/core';
import * as _ from 'lodash'

export interface lastImportedTmData {
  moduleId: number;
  objectiveTestIds: number[];
  subjectiveTestIds: number[];
  presentResourceIds: number[];
  importTime: number;
}

const notifyThrottle = 30 * 1000 // 不显超过30秒的通知

@Injectable()
export class TeachingPatternDataService implements OnDestroy {
  private lastImportedTmData: lastImportedTmData = {
    moduleId: 0,
    objectiveTestIds: [],
    subjectiveTestIds: [],
    presentResourceIds: [],
    importTime: 0,
  }
  private highLightImport: lastImportedTmData

  constructor() {
    console.log('create teaching pattern data service')
  }

  ngOnDestroy() {
    console.log('destroy teaching pattern data service')
  }

  public setLastImportObjectiveTests(moduleId: number, objectiveTestIds: number[],
    subjectiveTestIds: number[], presentResourceIds: number[]) {
    this.lastImportedTmData = {
      moduleId,
      objectiveTestIds,
      subjectiveTestIds,
      presentResourceIds,
      importTime: _.now(),
    }
    this.highLightImport = this.lastImportedTmData
  }

  public clearLastImportedObjectiveTests() {
    this.lastImportedTmData = {
      ...this.lastImportedTmData,
      objectiveTestIds: [],
      subjectiveTestIds: [],
      presentResourceIds: []
    }
  }

  public clearHighLightImport() {
    this.highLightImport = {
      ...this.highLightImport,
      objectiveTestIds: [],
      subjectiveTestIds: [],
      presentResourceIds: []
    }
  }

  //
  // 判断一个环节里的test是不是新导入的
  public isTestNewImport(tmId: number, testId: number) {
    return  this.lastImportedTmData.moduleId === tmId &&
      (_.head(this.lastImportedTmData.objectiveTestIds) === testId ||
       _.head(this.lastImportedTmData.subjectiveTestIds) === testId ||
       _.head(this.lastImportedTmData.presentResourceIds) === testId) &&
       (_.now() - this.lastImportedTmData.importTime ) < notifyThrottle
  }

  public getNeedHighLight(tmId: number, testId: number) {
    return  this.lastImportedTmData.moduleId === tmId &&
      (_.includes(this.highLightImport.objectiveTestIds, testId) ||
       _.includes(this.highLightImport.subjectiveTestIds, testId) ||
       _.includes(this.highLightImport.presentResourceIds, testId)) &&
       (_.now() - this.lastImportedTmData.importTime ) < notifyThrottle
  }

  public getScrollId() {
    if (this.highLightImport){
      if (!_.isEmpty(this.highLightImport.objectiveTestIds)) {
        return  this.highLightImport.objectiveTestIds[0]
      }
      if (!_.isEmpty(this.highLightImport.subjectiveTestIds)) {
        return  this.highLightImport.subjectiveTestIds[0]
      }
      if (!_.isEmpty(this.highLightImport.presentResourceIds)) {
        return  this.highLightImport.presentResourceIds[0]
      }
    }
    return 0
  }
}
