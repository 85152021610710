
angular
  .module("app.examination.dialog.examQuesAnswer", [])
  .component("examQuesAnswer", {
    bindings: {
      modalInstance: '<',
      resolve: '<',
    },
    template: <string>require("./examQuesAnswer.html"),
    controller: examQuesAnswer,
  });

examQuesAnswer.$inject = ['$sce']
function examQuesAnswer($sce) {
  this.ok = () => {
    this.modalInstance.close(true);
  }

  this.$onChanges = () => {
    if (this.resolve.question) {
      const ques = this.resolve.question;
      let quesList = [ques]
      if (_.get(ques, 'type', '') === 'synthetic') {
        quesList = _.get(ques, 'childQuestions', [])
      }
      const needChildTitle = _.size(quesList) > 1
      const childTitle = (q) => needChildTitle? `第${q.indexBase + 1}小题<br><br>` : ''
      const answerDetails = _.chain(quesList).map(q => {
        const ad = _.get(q, 'answerDetails', '')
        if (_.isEmpty(ad)) {
          return null
        }
        return `${childTitle(q)}${ad}`
      }).filter().join('<br><br><br>').value()
      this.answerDetails = $sce.trustAsHtml(answerDetails)
    }
    if (this.resolve.indexLabel) {
      this.indexLabel = this.resolve.indexLabel
    }
  }

}
