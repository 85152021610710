import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core'
import { CreateTeachingModuleDTO, LessonDTO, TeachingModuleDTO,
   TeachingPatternDTO, TestOrPresentDTO, UpdatePatternModuleDTO, UserInfoDTO } from 'app/lesson/models/lesson.d'
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { LessonService } from 'app/lesson/services/lesson.service'
import { TeachingPatternService } from 'app/lesson/services/teaching-pattern.service';
import * as _ from 'lodash'
import { DragulaService } from 'ng2-dragula'
import { forkJoin, Observable, of, Subscription} from 'rxjs';
import { finalize, flatMap, mergeMap } from 'rxjs/operators'
import { TeachingPatternDataService } from 'app/lesson/teaching-pattern-data.service';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-teaching-pattern',
  template: require('./teaching-pattern.component.html'),
  styles: [require('./teaching-pattern.component.scss')],
})
export class TeachingPatternComponent implements OnInit, OnDestroy {
  @Input() public courseId: number
  @Input() public sectionId: number
  @Input() public isRecommend: boolean

  private patternDTO: TeachingPatternDTO
  private testDTOs: TestOrPresentDTO[]
  private modules: TeachingModuleDTO[]
  private subs = new Subscription()
  private subscription: Subscription
  private selectedIndex = 0
  private perViewCount = 0
  private selectedCourse: LessonDTO
  private modulesIsNull: boolean

  constructor(
    private lessonService: LessonService,
    private dragulaService: DragulaService,
    private lessonMessageService: LessonMessageService,
    private patternService: TeachingPatternService,
    private patternDataService: TeachingPatternDataService,
    private notifyService: NotificationService
    ) {
      const createModuleSub = this.lessonService.createModule$.pipe(
        flatMap((createDTO: CreateTeachingModuleDTO) => {
        const newCreateDTO: CreateTeachingModuleDTO = {
          ...createDTO,
          teachingPatternId: this.patternDTO.id,
        }
        return this.lessonService.createTeachingModule(newCreateDTO)
      })).subscribe((tmodule: TeachingModuleDTO) => {
        this.modules = [
          ...this.modules,
          tmodule,
        ]
        this.patternDTO.modules = {
          ...this.modules,
        }
      }, (error) => {
        console.log(error)
      })

      const reloadPatternSub = this.lessonMessageService.reloadPatternAction$.subscribe(() => {
        this.queryTeachingPatternByCourseId()
      })
      this.subs.add(createModuleSub)
      this.subs.add(reloadPatternSub)
    }

  public ngOnInit() {
    this.addListener()
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this.queryTeachingPatternByCourseId()
  }

  public addListener() {
    this.dragulaService.destroy(this.getDragulaId())
    this.dragulaService.createGroup(this.getDragulaId(), {
      direction: 'horizontal',
      moves: (el, source, handle) => {
        return handle.className.indexOf('group-handle') >= 0
      }
    })
    this.subs.add(this.dragulaService.dragend(this.getDragulaId())
    .subscribe(() => this.updateOrder())
    )
  }

  public updateOrder() {
    this.lessonMessageService.sendLoadingAction(true)
    const updateOrderDTO: UpdatePatternModuleDTO = {
      id: this.patternDTO.id,
      modules: _.map(this.modules, (m: TeachingModuleDTO) => {
        return {
          id: m.id
        }
      })
    }
    this.lessonService.updatePatternModuleOrder(updateOrderDTO).subscribe(() => {}, (error: any) => {
      console.log(error)
      this.notifyService.notify('info', '排序失败')
    }, () => this.lessonMessageService.sendLoadingAction(false))
  }

  public getDragulaId() {
    return `select_course_${this.courseId}`
  }

  public ngOnDestroy() {
    this.subs.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  public queryTeachingPatternByCourseId() {
    this.lessonMessageService.sendLoadingAction(true)
    this.subscription = this.getUserInfo().pipe(
      (flatMap((ui) => {
        return this.patternService.loadPatternDetails(_.toNumber(this.courseId), this.sectionId, ui.canStar)
      }))
    )
    .subscribe((data: {course: LessonDTO; pattern: TeachingPatternDTO; tests: TestOrPresentDTO[]}) => {
      this.selectedCourse = {
        ...data.course,
      }
      this.patternDTO = {
        ...data.pattern
      }
      this.modules = [
        ...data.pattern.modules,
      ]
      this.testDTOs = [
        ...data.tests,
      ]
      this.lessonMessageService.sendReloadCourseResesAction(this.testDTOs)
      this.getModulesIfNull()
      this.lessonMessageService.sendLoadingAction(false)
    },
    (error) => {
      console.error(error)
      this.doWithError()
    },
    () => this.doWithError())
  }

  public getUserInfo() {
    return this.lessonService.getUserInfo()
  }

  public getModulesIfNull() {
    this.modulesIsNull = true
    for (let i = 0; i < this.modules.length; i++) {
      const preIsNull = _.isEmpty(this.modules[i].presentResources) && !_.isEmpty(this.modules[i].tests)
      const testsIsNull = !_.isEmpty(this.modules[i].presentResources) && _.isEmpty(this.modules[i].tests)
      const preAndTestsIsNull = _.isEmpty(this.modules[i].presentResources) && !_.isEmpty(this.modules[i].tests)
      if (preIsNull || testsIsNull || preAndTestsIsNull) {
        const isPresentOfNull = this.modules[i].presentResources.length === 0
        const isSubAndObjOfNull = this.modules[i].tests.length === 0
        if (!isPresentOfNull || !isSubAndObjOfNull) {
          this.modulesIsNull = false
        }
      }
    }
    this.lessonMessageService.sendTeachingModulesIfNull(this.modulesIsNull)
  }

  public doWithError() {
    this.lessonMessageService.sendLoadingAction(false)
    if (this.subscription) {
      this.subscription.unsubscribe()
      this.subscription = null
    }
  }

  public next() {
    this.selectedIndex = this.selectedIndex + 1
    this.selectedIndex = _.min([this.selectedIndex, _.size(this.modules) - 1])
  }

  public prev() {
    this.selectedIndex = this.selectedIndex - 1
    this.selectedIndex = _.max([this.selectedIndex, 0])
  }

  public itemCountToFix(count: number) {
    this.perViewCount = count
    this.selectedIndex = _.size(this.modules) - this.perViewCount
  }

  public isPrevVisible() {
    return this.selectedIndex > 0
  }

  public isNextVisible() {
    return this.selectedIndex + this.perViewCount < _.size(this.modules)
  }

}
