/**
 * Created by ryoushikaoru on 2019/3/4.
 */
import * as _ from 'lodash';
require('./../cloud/objectiveTestLibrary.ctrl');
const {doImportQuestions} = require('app2/utils/testUtils2');
const {getResourceUrlByUUID} = require('app2/utils/resourceUtils');
import moment = require('moment');
require('./testList.scss')
import Promise = require('bluebird');
require('assets/styles/testimport.scss');

angular.module('app.prepareCourse2.testList', [])
    .component('testList', {
        bindings: {
            currentTest: '<',
            courseId: '<',
            course: '<',
            testId: '<',
            moduleId: '<',
            moduleName: '<',
            updateTest: '&',
            tests: '<',
            setCurrentTest: '&',
            status: '<',
            saveTest: '<',
            importedTests: '<',
            onImportTestChange: '&',
        },
        controller: testListCtrl,
        template: <string>require('./testList.html'),
    })
    .controller('addTestCtrl', addTestCtrl)

testListCtrl.$inject = [
  'oedObjectiveTest',
  'oedCourse',
  '$q',
  'notify',
  'oedCloudQuestion',
  'teachingModuleService',
  'notificationService'
]
function testListCtrl(
    oedObjectiveTest,
    oedCourse,
    $q,
    notify,
    oedCloudQuestion,
    teachingModuleService,
    notificationService
  ) {
    this.$onChanges = (changesObj: any) => {
        if (_.has(changesObj, 'testId')) {
            if (this.testId) {
                this.initCurrentTest(false);
            } else {
                this.initCurrentTest(true);
            }
        }

        if (_.has(changesObj, 'status')) {
            this.status = this.status || {}
        }

        if (_.has(changesObj, 'moduleId')) {
            if (this.moduleId) {
                this.loadModuleRes()
            }
        }
    };

    this.loadModuleRes = () => {
        this.status.loading = teachingModuleService.loadModuleDetails(this.moduleId).then((updateModuleDTO) => {
            const allModuleTestIds = _.map(updateModuleDTO.objectiveTests, 'id')
            this.moduleDetails = {
                ...this.moduleDetails,
                updateModuleDTO,
                allModuleTestIds,
                moduleName: this.moduleName,
            }
        })
    }

    this.reload = (updateModuleDTO, test) => {
      let newTestIds = this.importedTests
      if (updateModuleDTO.$$action === 'add') {
        newTestIds = [...newTestIds, test.id]
      } else {
        newTestIds = _.remove(newTestIds, test.id)
      }

      this.onImportTestChange({
        newTests: newTestIds,
      })

      this.status.loading = teachingModuleService.updateTeachingModule(updateModuleDTO).then(() => {
        return this.loadModuleRes()
      })
    }

    // this.isDone = true;
    this.currentState = {
        loading: null
    };
    const questionsOrderBeforeSort = [];
    // 新增试卷
    this.addTest = (existingTests) => {
        this.maxSort = _.max(_.map(existingTests, 'sort'));
        if (isNaN(this.maxSort)) {
            this.maxSort = 1;
        } else {
            this.maxSort = this.maxSort + 1;
        }
        this.status.loading = new oedObjectiveTest({
            name: '客观试卷' + this.maxSort,
            courseId: this.courseId,
            sort: this.maxSort
        }).$save().then((t) => {
            const newTest = t;
            this.tests.push(newTest);
            this.setCurrentTest({t: newTest, test: this.tests});
            notificationService.notify('info', '新建试卷成功')
        }).catch((error) => {
            const errCode = _.get(error, 'status', 500);
            if (errCode === 401 || errCode === 403) {
              return notificationService.notify('error', '您的登录状态已改变，请重新登录!')
            }
            notificationService.notify('error', '新建试卷失败，请联系锐学堂技术支持!')
        });

    };
    // 移动试卷列表
    this.sortableOptions = {
        axis: 'y',
        stop: (event) => {
            const newOrders = _.map(this.tests, (m, index) => {
                return {
                    id: m.id,
                    sort: index
                };
            });
            this.status.loading = oedCourse.updateTestOrder(newOrders).$promise.then().catch(() => {
              notificationService.notify('error', '保存失败')
            });
        }
    };
    // 选中当前试卷
    this.initCurrentTest = (isDefault) => {
        this.tests.$promise.then((item) => {
            if (item.length === 0) {
                return new oedObjectiveTest({
                    name: '客观试卷1',
                    courseId: this.courseId,
                    sort: 1
                }).$save();
            } else {
                return Promise.resolve(null);
            }
        }).then((data) => {
            if (data) {
                this.tests.push(data);
            }
            if (isDefault) {
                this.tests.$promise.then((item) => {
                    this.setCurrentTest({t: item[0], test: this.tests});
                })
            } else {
                this.tests.$promise.then((item) => {
                    const x = _.findIndex(item, (o: any) => {
                        return o.id === this.testId;
                    });
                    if(x > 0) {
                        this.setCurrentTest({t: item[x], test: this.tests});
                    } else {
                        this.setCurrentTest({t: item[0], test: this.tests});
                    }
                })
            }
        })
    };
    this.loadTestCloudQuestions = (questions) => {
        const ids = _(questions).map('id').filter().value();
        this.cloudQuestions = {};

        if (_.isEmpty(ids)) {
            return $q.resolve({});
        }

        return oedCloudQuestion.queryByQuestionIds({ids})
            .$promise.then((cqs) => {
                this.cloudQuestions = _.keyBy(cqs, 'resourceId');
                return this.cloudQuestions;
            });
    }
}

addTestCtrl.$inject = ['$scope', '$uibModalInstance', 'oedObjectiveTest', 'courseId', 'existingTests']
function addTestCtrl($scope, $uibModalInstance, oedObjectiveTest, courseId, existingTests) {
    $scope.exam = {};
    $scope.exsitingNames = _.map(existingTests, 'name');
    $scope.maxSort = _.max(_.map(existingTests, 'sort'));
    if (isNaN($scope.maxSort)) {
        $scope.maxSort = 1;
    } else {
        $scope.maxSort = $scope.maxSort + 1;
    }
    $scope.ok = () => {
        $scope.showValidationError = true;
        if ($scope.addTestForm.$invalid) {
            return;
        }

        const test = new oedObjectiveTest({
            name: $scope.exam.name,
            courseId,
            sort: $scope.maxSort
        });
        const p = test.$save();
        $uibModalInstance.close({
            test,
            promise: p
        });
    };

    $scope.cancel = () => {
        $uibModalInstance.dismiss('cancel');
    };
}
