import { Component, ElementRef, EventEmitter, HostListener, Input,
  OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import {DifficultType, ExamType, QuestionType, SelectedFilters} from '../models/talcloud';

@Component({
  selector: 'app-filter-question-type-selector',
  template: require('./filter-question-type.component.html'),
  styles: [require('./filter-question-type.component.scss')]
})
export class FilterQuestionTypeComponent implements OnInit, OnChanges {

  private questionTypes = {
    yesorno: '判断',
    singlechoice: '单选',
    multichoice: '多选',
    connect: '匹配',
    fill: '填空',
    vote: '投票',
    synthetic: '综合',
    qa: '问答'
  }
  @Input() public questionTypeChoosedindex = -1
  @Output() public onTypeChoosed = new EventEmitter()
  private objectKeys = Object.keys;

  constructor() {
  }

  public ngOnInit() {
  }

  public itemChecked(indexes) {
    if (indexes === this.questionTypeChoosedindex) {
      return true;
    } else {
      return false;
    }
  }

  public chooseOneType(index) {
    if (this.questionTypeChoosedindex === index) {
      return;
    }
    this.questionTypeChoosedindex = index;
    let backList = [];
    if (index < 0) {
      backList = Object.keys(this.questionTypes);
    } else {
      backList.push(Object.keys(this.questionTypes)[index]);
    }
    this.onTypeChoosed.emit(backList)
  }

  public ngOnChanges(changes: SimpleChanges) {
    // if (_.has(changes, 'types') && !_.isEmpty(this.types)) {
    // }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
  }

  public ngOnDestroy() {
  }

}
