import * as angular from 'angular';
import Promise = require('bluebird')
import * as _ from 'lodash';

angular.module('app.prepareCourse2.editrorWarningDialog',
[]).controller('editrorWarningDialogCtrl', editrorWarningDialogCtrl)

editrorWarningDialogCtrl.$inject = [
  '$scope',
  'data',
  '$uibModalInstance',
  '$q',
  '$uibModal',
  'notificationService'
]
function editrorWarningDialogCtrl(
  $scope,
  data: any,
  $uibModalInstance,
  $q,
  $uibModal,
  notificationService
) {

  $scope.list = data.childQuestionList

  $scope.turnBackEditor = () => {
    $uibModalInstance.close('edit');
  }

  $scope.confirmClick = () => {
    $uibModalInstance.close('save');
  }

  $scope.cancelClick = () => {
    $uibModalInstance.close('discarded');
  }
}
