import * as angular from 'angular';
import 'app2/directives/utils/expandableList.directive';
import * as _ from 'lodash';

angular.module('app.directives.cloud.courseSelector', [
  'app.directives.utils.expandableList',
])
  .component('courseSelector', {
    bindings: {
      courseFilter: '<',
      coursePaths: '<',
      onFilterChanged: '&',
    },
    controller: courseSelectorCtrl,
    template: <string> require('./courseSelector.html'),
  });

interface CoursePathItem {
  id: number;
  name: string;
}

courseSelectorCtrl.$inject = ['oedCourse']
function courseSelectorCtrl(oedCourse: any) {
  this.onSelectCoursePath = (cp: CoursePathItem) => {
    this.onFilterChanged({
      filter: {
        coursePathId: cp.id,
      },
    });
  };

  this.onSelectCourse = (cs: CoursePathItem) => {
    this.onFilterChanged({
      filter: _.assign({}, this.courseFilter, {
        courseId: cs.id,
      }),
    });
  };

  const updateSelectedCoursePath = () => {
    if (!this.courseFilter) {
      this.selectedItem = null;
      return;
    }

    //
    // 已经选中当前项
    if (!this.selectedItem || this.selectedItem.id !== this.courseFilter.coursePathId) {
      this.selectedItem =  _.find(this.coursePaths, (cp: any) => cp.id === this.courseFilter.coursePathId);
      if (this.selectedItem) {
        loadCourses(this.selectedItem.id);
      }
    }

    if (!this.selectedCourse || this.selectedCourse.id !== this.courseFilter.courseId) {
      this.selectedCourse = _.find(this.courses, (cs: CoursePathItem) => cs.id === this.courseFilter.courseId);
    }
  };

  const loadCourses = (cpId: number) => {
    this.loading = oedCourse.queryByPath({

      pathId: cpId,
    }).$promise.then((cses: any) => {
      this.courses = _.map(cses, (cs: any) => ({
        id: cs.id,
        name: cs.courseName,
      }));

      this.selectedCourse = _.find(this.courses, (cs: CoursePathItem) => cs.id === this.courseFilter.courseId);
      if (!this.selectedCourse) {
        let csId = -1;
        if (!_.isEmpty(this.courses)) {
          this.selectedCourse = this.courses[0];
          csId = this.selectedCourse.id;
        }

        this.onFilterChanged({
          filter: _.assign({}, this.courseFilter, {
            courseId: csId,
          }),
        });
      }

      return this.courseItems;
    });
  };

  this.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'courseFilter') || _.has(changesObj, 'coursePaths')) {
      updateSelectedCoursePath();
    }
  };
}
