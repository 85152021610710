angular.module('app.directives.templateMarkPopup', [])
  .component('templateMarkPopup', {
    template: require('./templateMarkPopup.html'),
    controller: templateMarkPopupCtrl,
    bindings: {
      curCp: '=',
      pageX: '=',
      curLeft: '=',
      setLeft: '&',
      onRadioSelect: '&',
      isRc: '<',
      setRecommendCourse: '&'
    }
  });

  templateMarkPopupCtrl.$inject = ['$element']
function templateMarkPopupCtrl($element) {
  var ctrl = this;

  ctrl.setRc = function() {
    let val = 0;

    if (ctrl.isRc) {
      val = 0;
      ctrl.isRc = false;
    } else {
      val = 1;
      ctrl.isRc = true;
    }
    ctrl.setRecommendCourse({value: val});
  };

  this.templateTypes = ['非课程模板', '课程模板', '教材课程模板', '学科课程模板'];

  function initLeft() {
    var offset1 = $element.parent().find('#slides-container')[0].offsetLeft;
    var offset2 = $element.parent().find('#gundong2')[0].offsetLeft;

    var slideWidth = 197;
    var start = offset1 + offset2;
    var offset = Math.floor((ctrl.pageX - start) / slideWidth);
    var left = start + offset * slideWidth;
    ctrl.curLeft = left;
    ctrl.setLeft({left: left});
  }

  ctrl.onRadioClick = function(type) {
    ctrl.onRadioSelect({ttype: type});
  };

  ctrl.getLeft = function() {
    return {
      left: ctrl.curLeft + 'px'
    }
  };

  this.$onInit = () => initLeft()
}
