
export class LegacyAppService {
  constructor(private notify: any, private misc: any, private oem: any,
              private localStorage: any,
              private rootScope: any,
              private uibModal: any,
              private dialogs: any,
              private courseUtils: any,
              private testUtils: any,
              private lightbox: any) {}

  public getNotify() {
    return this.notify
  }

  public getMisc() {
    return this.misc
  }

  public getLocalStorage() {
    return this.localStorage
  }

  public getRootScope() {
    return this.rootScope
  }

  public getUibModal() {
    return this.uibModal
  }

  public getDialogs() {
    return this.dialogs
  }

  public getCourseUtils() {
    return this.courseUtils
  }

  public getTestUtils() {
    return this.testUtils
  }

  public getOem() {
    return this.oem
  }

  public getLightBox() {
    return this.lightbox
  }
}

export function legacyAppServiceFactory(i: any) {
  const misc = i.get('oedMisc')
  const notify = i.get('notify')
  const localStorage = i.get('localStorageService')
  const rootScope = i.get('$rootScope')
  const uibModal = i.get('$uibModal')
  const dialogs = i.get('dialogs')
  const courseUtils = i.get('oedCourseUtils')
  const testUtils = i.get('oedTestUtils')
  const oem = i.get('oedOem')
  const lightbox = i.get('Lightbox')

  return new LegacyAppService(notify, misc, oem, localStorage, rootScope, uibModal, dialogs, courseUtils, testUtils, lightbox)
}

export const legacyAppServiceProvider = {
  provide: LegacyAppService,
  useFactory: legacyAppServiceFactory,
  deps: ['$injector']
}
