import * as _ from 'lodash'
import { ExamDataDTO, ExamPartDetailDTO } from 'app/exam/models/exam'
import * as BlueBird from 'bluebird'

export function getPartTypeText(parts: any, index: number) {
  if (_.isEmpty(parts)) {
    return ''
  }
  const firstrQuesType = _.get(parts, `${index}.questionDetails.0.question.type`)

  if (_.isEmpty(firstrQuesType)) {
    return ''
  }

  return getQuestionTypeText(firstrQuesType)
}

// 试卷一类题目总分
export function getExamQuesTotal(detail, index) {
  let score = 0
  if (_.size(detail) <= 0) {
    return score
  }
  const questionDetails = _.get(detail, `${index}.questionDetails`)
  _.forEach(questionDetails, qd => {
    const curQuesScore = _.get(qd, 'question.point2', 0)
    score += _.round(curQuesScore, 1)
  })
  return score
}
// 试卷总分数
export function getExamTestTotal(detail) {
  let num = 0
  if (_.size(detail) > 0) {
    _.forEach(detail, (el, idx) => {
      num = _.round(num, 1) + _.round(this.getExamQuesTotal(detail, idx), 1)
    })
  }
  return num;
}
// 试卷一类题目数量
export function getExamPrimaryNm(detail) {
  return _.size(detail)
}

// 试卷全部小题数量
export function getExamQuesNm(detail) {
  let num = 0
  if (_.size(detail) > 0) {
    _.map(detail, (r) => {
      num = num + _.size(_.get(r, 'questionDetails', []))
    })
  }
  return num;
}

export function getQuesNum(detail, partIdx, quesIdx) {
  return convertQuesNumber(detail, partIdx, quesIdx) + 1
}

// 转换题号
export function convertQuesNumber(detail, outerIndex, index) {
  let tmp = []
  let sum = 0
  const arr = _.map(detail, (r) => {
    return _.size(r.questionDetails)
  })

  if (outerIndex === 0) {
    return index
  }
  if (outerIndex > 0) {
    tmp = arr.slice(0, outerIndex)
    for (let i = 0; i < tmp.length; i++) {
      sum += tmp[i]
    }
    return sum + index
  }
  return 0;
}

export const percent = (a, b, l) => {
  if (a && b && parseFloat(b) !== 0) {
    const num = parseFloat(a) / parseFloat(b) * 100
    return num.toFixed(l)
  }
  return ''
}

export function getQuestionTypeText(type: string) {
  switch (type) {
    case 'singlechoice':
      return '单选';
    case 'multichoice':
      return '多选';
    case 'fill':
      return '填空';
    case 'connect':
      return '匹配';
    case 'yesorno':
      return '判断';
    case 'singlevote':
      return '投票';
    case 'multivote':
      return '投票';
    case 'synthetic':
      return '综合';
    case 'qa':
      return '问答';
  }
  return '';
}

export function checkExamHasUnSavedQuestion(examData: ExamDataDTO) {
  if (!_.get(examData, 'examWithTags.exam.id', null)) {
    return false
  }
  const allQues = flattenExamAllQues(examData)
  const unSaved = _.findIndex(allQues, (q) => {
    return _.get(q, 'id', null) == null
  })
  return unSaved != -1
}

function flattenExamAllQues(examData: ExamDataDTO) {
  const parts = _.get(examData, 'examPartDetails', [])
  const ques = _.flatMapDeep(parts, (p: ExamPartDetailDTO) => {
    return _.map(p.questionDetails, 'question')
  })
  return _.flatMapDeep(ques, (q) => {
    if (q.type != 'synthetic') {
      return q
    } else {
      return _.get(q, 'childQuestions', [])
    }
  })
}

export function checkExamQuesHasTable(examData: ExamDataDTO, $uibModal: any) {
  return new BlueBird((resolve, reject) => {
    try {
      const allQues = flattenExamAllQues(examData)
      for (const q of allQues) {
        const stem = _.get(q, 'question', '')
        if (/<table/.test(stem)) {
          return openHasTableInfoDialog($uibModal).then((data) => {
            resolve(data)
          }, (data) => {
            reject(data)
          })
        }
      }
      resolve('yes')
    } catch (error) {
      console.log('error', error)
      reject('no')
    }
  })
}

export function getExamQIndexMap(examData: ExamDataDTO) {
  const sortMap = {}
  const parts = _.get(examData, 'examPartDetails', [])
  const ques = _.flatMapDeep(parts, (p: ExamPartDetailDTO) => {
    return _.map(p.questionDetails, 'question')
  })
  _.each(ques, (q, index) => {
    sortMap[q.uuid] = `${index + 1}`
    sortMap[q.id] = `${index + 1}`
    if (q.type === 'synthetic') {
      const childs = _.get(q, 'childQuestions', [])
      return _.each(childs, (c, cindex) => {
        sortMap[c.uuid] = `${index + 1}.${cindex + 1}`
        sortMap[c.id] = `${index + 1}.${cindex + 1}`
      })
    }
  })
  return sortMap
}

export function checkExamIsSaved(examData: any) {
  if (_.isEmpty(examData)) {
    // exam data为空是当前选中试卷被删掉了
    return true
  }
  // // 不为空时，根据有无exam id来判断是否已经保存
  const examId = _.parseInt(_.get(examData, 'examWithTags.exam.id', -1))
  return examId != -1
  // return false;
}

export function sortExamPartDetails(details: any[]) {
  details.sort((a, b) => {
    const aSort = _.get(a, 'part.sort', '')
    const bSort = _.get(b, 'part.sort', '')
    return aSort - bSort
  })
  _.forEach(details, pd => {
    const questionDetails = _.get(pd, 'questionDetails', [])
    questionDetails.sort((a, b) => {
      const aSort = _.get(a, 'sort', '')
      const bSort = _.get(b, 'sort', '')
      return aSort - bSort
    })
  })
  return details
}

export function sortExamWhole(exam) {
  if (exam && exam.examPartDetails) {
    _.forEach(exam.examPartDetails, part => {
      part.questionDetails = _.sortBy(part.questionDetails, 'sort')
    })
  }
}


const sbjtypes = { fill: true, qa: true };
/*
排序并过滤掉客观题
并统计学生作答信息answeredStd. (question)
保存原始下标indexBase (part question chirldQuesiton)
及题目在整个试卷中的下标 inExamIndex(question)
*/
export function sortAndFilterExamWhole(exam, answerMap) {
  sortExamWhole(exam)
  const filterQues = (q) => _.get(sbjtypes, q.type)
  const getAnsweredStd = (q) => _.keys(_.get(answerMap, q.id, {}))
  if (exam && exam.examPartDetails) {
    let qIndexOut = 0 
    const examPartDetails = _.filter(_.map(exam.examPartDetails, (part, pIndex) => {
      const questionDetails = _.filter(_.map(part.questionDetails, (q, qIndex) => {
        if (q.question.type === 'synthetic'){
          const childQuestions = _.filter(_.map(q.question.childQuestions, (cq, cIndex) => filterQues(cq)? ({ ...cq, indexBase: cIndex }) : null ))
          if (_.size(childQuestions)) {
            const question = { ... q.question , childQuestions } 
            const answeredStd = getAnsweredStd(childQuestions[0])
            return { ...q, question, indexBase: qIndex, inExamIndex: qIndexOut + qIndex, answeredStd }
          }
        }else if (filterQues(q.question)) {
          const answeredStd = getAnsweredStd(q.question)
          return { ...q, indexBase: qIndex, inExamIndex: qIndexOut + qIndex, answeredStd }
        }
        return null
      }))
      qIndexOut += _.size(part.questionDetails)
      if (_.size(questionDetails)) {
        return {...part, questionDetails, indexBase: pIndex}
      }
      return null
    }))
    return { ...exam, examPartDetails }
  }
}

// 过滤掉没有学生作答的题目 保存新的下标到原始数据上indexFilter ( part , question )
export function filterNoAnswerAndSaveIndex(exam) {
  if (exam && exam.examPartDetails) {
    let indexFilterPart = 0
    const examPartDetails = _.filter(_.map(exam.examPartDetails, (part) => {
      let indexFilterQues = 0
      const questionDetails = _.filter(_.map(part.questionDetails, (q) => {
        if (_.size(q.answeredStd)) {
         q.indexFilter = indexFilterQues
         indexFilterQues++  
         return q
        }
        return null
      }))
      if (_.size(questionDetails)) {
        part.indexFilter = indexFilterPart
        indexFilterPart++
        return {...part, questionDetails}
      }
      return null
    }))
    return { ...exam, examPartDetails }
  }
}




export function addTagForExamWhole(exam: any) {
  if (_.isEmpty(exam.examPartDetails)) {
    return exam
  }
  const examPartDetails = _.map(exam.examPartDetails, (pd) => {
    _.map(pd.questionDetails, (qd) => {
      if (qd.question.type === 'synthetic') {
        _.map(qd.question.childQuestions, child => {
          child.isSbj = _.get(sbjtypes, child.type, false)
          return child
        })
        return qd
      }
      qd.question.isSbj = _.get(sbjtypes, qd.question.type, false)
      return qd
    })
    return pd
  })
  return { ...exam, examPartDetails }
}


// 综合题小题分数计算
export function geSyncChildPointArray(totalScore: number, num: number) {
  const pointArray = new Array(num);
  // 1为最小单位
  const averageScore = parseInt(String(totalScore / num));
  if (averageScore >= 1) {
    // 平均分值大于零
    for (let i = 0; i < num; i++) {
      if (i == 0) pointArray[i] = averageScore + _.round(totalScore % num)
      else pointArray[i] = averageScore
    }
  } else {
    // 平均分小于1理论上不应该出现这种情况，请不要传这种参数
    for (let i = 0; i < num; i++) {
      if (totalScore > 0) {
        pointArray[i] = 1
        totalScore--
      } else {
        pointArray[i] = 0
      }
    }
  }
  return pointArray;
}

export function openHasTableInfoDialog($uibModal) {
  return openInfoDialog($uibModal, '试题中包括表格，在pad上显示效果可能不理想，建议在导入后将表格截图转成图片。', '是否保存试卷', '保存并继续', '继续修改')
}

function openInfoDialog($uibModal, msg, title, yesText, noText) {
  const dialog = $uibModal.open({
    template: require('app2/common/examConfirmDialog.html'),
    controllerAs: '$ctrl',
    controller: 'oedConfirmDialogCtrl',
    resolve: {
      data: function() {
        return {
          message: msg,
          title,
          yesText,
          noText,
        };
      }
    },
    size: 'sm',
    windowClass: 'ex-dialog-container'
  });
  return dialog.result
}

export function openExamConfirmDialog($uibModal: any, message: string, title: string, yesText: string, noText: string, onYes?: any, onNo?: any) {
  const dialog = $uibModal.open({
    template: require('app2/common/examConfirmDialog.html'),
    controllerAs: '$ctrl',
    controller: 'oedConfirmDialogCtrl',
    resolve: {
      data: function() {
        return {
          message,
          title,
          yesText,
          noText,
        }
      }
    },
    size: 'sm',
    windowClass: 'ex-dialog-container'
  })
  dialog.result.then(() => {
    if (onYes) {
      return onYes()
    }
    return null
  }, (reject) => {
    if (reject === 'cancel') {
      return null
    }
    if (onNo) {
      return onNo()
    }
    return null
  })
}


export function parseAnswers(answers: Array<any>) {
  for (const answer of answers) {
    try {
      answer.answer = JSON.parse(answer.answer)
      answer.point =  _.round(answer.point, 1)
    } catch (error) {
      answer.answer = { items:[] }
    }
  }
}
