import { Component, Input, OnInit } from '@angular/core';
import { OedDialogService } from 'app/core/oed-dialog.service';
import { CreateModuleComponent } from 'app/lesson/lesson-design/create-module/create-module.component'
import { CreateTeachingModuleDTO, TeachingModuleDTO } from 'app/lesson/models/lesson';
import { LessonService } from 'app/lesson/services/lesson.service';
import { Observable } from 'rxjs';
import { LessonMessageService } from 'app/lesson/services/lesson-message.service';
import { NotificationService } from 'app/shared/notification/notification.service';

@Component({
  selector: 'app-design-separate',
  template: require('./design-separate.component.html'),
  styles: [require('./design-separate.component.scss')]
})
export class DesignSeparateComponent implements OnInit {
  @Input() public mode: string
  @Input() public disable: boolean

  constructor(
    private lessonService: LessonService,
    private oedDialogService: OedDialogService,
    private lessonMessageService: LessonMessageService,
    private notifyService: NotificationService
  ) { }

  public ngOnInit() {
  }

  public createModule() {
    if (this.disable) {
      this.lessonMessageService.sendSaveRecommendCourseAction()
      return
    }
    const action = this.oedDialogService
    .openModalWithComponent2('CreateModuleComponent') as Observable<CreateTeachingModuleDTO>
    action.subscribe((createDTO: CreateTeachingModuleDTO) => {
      this.lessonService.sendCreateTeachingModuleAction(createDTO)
    }, (error) => {
      console.log(error)
      this.notifyService.notify('info', '创建教学环节失败')
    })
  }

}
