/**
 * @fileOverview
 * @name testSessionReviewForStudentCtrl.js
 * @author pangwa
 * @license
 */
(function() {
  'use strict';

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('testSessionReviewForStudent', {
        url: '/review/record/:recordId/task/:taskId/taskList/:taskListId/test' +
          '/:origTestId/session/:sessionId/student/:userId/question/:index',
        template: require('assets/templates/review/testSessionReviewForStudent.html'),
        controller: 'testSessionReviewForStudentCtrl',
        bodyStyle: 'bged'
      });
    }]).controller('testSessionReviewForStudentCtrl', testSessionReviewForStudentCtrl);

  testSessionReviewForStudentCtrl.$inject = ['$scope', '$q', '$timeout', 'oedTaskListPublishRecord',
    'oedTestSessionStatForReview', 'oedTestUtils', 'userInfo', '$stateParams', 'oedTaskList', 'oedTestAnswer', 'notificationService',
    'oedObjectiveTest', 'oedUserInfo', 'Lightbox']
  function testSessionReviewForStudentCtrl($scope, $q, $timeout, oedTaskListPublishRecord,
    oedTestSessionStatForReview, oedTestUtils, userInfo, $stateParams, oedTaskList, oedTestAnswer, notificationService,
    oedObjectiveTest, oedUserInfo, Lightbox) {
    $scope.sessionId = _.parseInt($stateParams.sessionId);
    $scope.userId = _.parseInt($stateParams.userId);
    $scope.taskListId = _.parseInt($stateParams.taskListId);
    $scope.origTestId = _.parseInt($stateParams.origTestId);
    $scope.recordId = _.parseInt($stateParams.recordId);
    $scope.taskId = _.parseInt($stateParams.taskId);

    $scope.currentTaskList = oedTaskList.get({
      id: $scope.taskListId
    });

    $scope.currentTest = oedObjectiveTest.get({
      id: $scope.origTestId
    });

    $scope.student = oedUserInfo.getByUid({
      id: $scope.userId
    });
    $scope.currentLabels = [];
    $scope.getLabel = function(index) {
      if (_.size($scope.currentLabels) > index)
        return $scope.currentLabels[index];
      return '';
    };
    $scope.getParentIndex = function(index) {
      if (_.size($scope.currentLabels) > index) {
        var num = _.split($scope.currentLabels[index], '.', 1)[0] - 1;
        return num;
      }
    };
    $scope.getRelatedSectionNames = function(question) {
      if (_.isEmpty(question.relatedSections)) {
        return '无';
      }

      return _(question.relatedSections).map(function(sec) {
        return sec.sectionName;
      }).join(', ');
    };
    $scope.isFirstChild = function(index) {
      if (_.size($scope.currentLabels) > index)
        return _.endsWith($scope.currentLabels[index], '.1');
      return false;
    };
    $scope.getParent = function(index, child) {
      return _.find($scope.dataForReview.test.questions, function(question) {
        return question.id == child.question.parentQuestionId;
      });
    };

    $scope.dataForReview = oedTestSessionStatForReview.queryByTestSessionAndStudentId({
      sessionId: $scope.sessionId,
      userId: $scope.userId
    });

    $scope.shouldShowQa = function(review) {
      return review.question.type == 'qa' && review.answer !== null && !_.isEmpty(review.answer.answer);
    };
    $scope.shouldShowAnalysis = function(review) {
      return review.answer !== null && _.size(review.answer.analysis) > 0;
    };
    $scope.previewContentResource = function(curRes, answer) {
      var idx = _.findIndex(answer.analysis, curRes);
      var newRes = _.map(answer.analysis, function(res) {
        return {
          id: res.id,
          fileType: 'image',
          resourceName: res.id + '.jpg',
          md5: res.md5,
        };
      });
      Lightbox.openModal(newRes, idx);
    };
    $scope.previewQaAnswer = function(curRes, answer) {
      var idx = _.findIndex(answer.answerItems.items, curRes);
      Lightbox.openModal(answer.answerItems.items, idx);
    };
    $scope.allStudents = oedTaskListPublishRecord.queryStudentsInfo({
      id: $scope.recordId
    });
    $scope.loading = $q.all([$scope.allStudents.$promise, $scope.dataForReview.$promise]);
    $scope.allStudents.$promise.then(function() {
      var part = _.partition($scope.allStudents, function(s) {
        return s.submitProgress && s.submitProgress == '100';
      });
      var studentsOfFinish = part[0];
      var studentsOfUnfinish = part[1];

      var idx = _.findIndex(studentsOfFinish, function(t) {
        return t.uid == $scope.userId;
      });
      if (idx != -1) {
        if (idx > 0) {
          $scope.prevStudent = studentsOfFinish[idx - 1];
        }
        if (idx < _.size(studentsOfFinish) - 1) {
          $scope.nextStudent = studentsOfFinish[idx + 1];
        }
      } else {
        idx = _.findIndex(studentsOfUnfinish, function(t) {
          return t.uid == $scope.userId;
        });
        if (idx > 0) {
          $scope.prevStudent = studentsOfUnfinish[idx - 1];
        }
        if (idx < _.size(studentsOfUnfinish) - 1) {
          $scope.nextStudent = studentsOfUnfinish[idx + 1];
        }
      }
    });
    $scope.dataForReview.$promise.then(function(reviews) {
      _.forEach(reviews.quesReviewDTOList, function(review) {
        if (!_.isEmpty(review.answer)) {
          if (!_.isEmpty(review.answer.answerDetails))
            review.answer.analysis = JSON.parse(review.answer.answerDetails);
          else
            review.answer.analysis = [];
          if (review.question.type == 'qa') {
            if (!_.isEmpty(review.answer.answer)) {
              review.answer.answerItems = JSON.parse(review.answer.answer);
            } else {
              review.answer.answerItems = {
                items: []
              };
            }
          }
        }
      });
      $scope.currentLabels = oedTestUtils.getTestStatLabel(reviews.quesReviewDTOList);
    });
    $scope.$on('domLoaded', function(domLoadedEvent) {
      var toIndex = _.findIndex($scope.currentLabels, function(idx) { return idx == $stateParams.index; });
      var offset = $('#question_' + toIndex).offset();
      if (offset)
        $(window).scrollTop(offset.top);
    });

    function parseAnswer(answer, quesType) {
      if (!_.isEmpty(answer)) {
        if (!_.isEmpty(answer.answerDetails))
          answer.analysis = JSON.parse(answer.answerDetails);
        else
          answer.analysis = [];
        if (quesType == 'qa') {
          if (!_.isEmpty(answer.answer)) {
            answer.answerItems = JSON.parse(answer.answer);
          } else {
            answer.answerItems = {
              items: []
            };
          }
        }
      }
    }

    $scope.reviewQa = function(review, correct) {
      $scope.loading = oedTestAnswer.reviewQaAnswer({
        testSessionId: $scope.sessionId,
        studentId: $scope.userId,
        questionId: review.question.id,
        correctDetails: correct
      }, {
        testSessionId: $scope.sessionId,
        studentId: $scope.userId,
        questionId: review.question.id,
        correctDetails: correct
      }).$promise.then(function(answer) {
          parseAnswer(answer, review.question.type);
          review.answer = answer;
          $scope.dirty = true;
        }).catch(function() {
          notificationService.notify('error', '评判失败')
        });
    };

    $scope.reviewQaClear = function(review) {
      $scope.loading = oedTestAnswer.clearQaReview({
        testSessionId: $scope.sessionId,
        studentId: $scope.userId,
        questionId: review.question.id
      }, {
        testSessionId: $scope.sessionId,
        studentId: $scope.userId,
        questionId: review.question.id
      }).$promise.then(function(answer) {
          parseAnswer(answer, review.question.type);
          review.answer = answer;
          $scope.dirty = true;
        }).catch(function() {
          notificationService.notify('error', '清除评判失败')
        });
    };
    $scope.submitQaPoint = function(review) {
      var point = review.answer.point2;
      if (_.isEmpty(point)) {
        return;
      }

      point = parseFloat(point);
      if (_.isNaN(point) || point < 0) {
        notificationService.notify('error', '分数非法')
        return;
      }

      if (point > review.question.point2) {
        notificationService.notify('error', '该题的最大分值为' + review.question.point2)
        return;
      }

      $scope.loading = oedTestAnswer.scoreQaAnswer({
        testSessionId: $scope.sessionId,
        studentId: $scope.userId,
        questionId: review.question.id,
        point: point
      }, {
        testSessionId: $scope.sessionId,
        studentId: $scope.userId,
        questionId: review.question.id,
        point: point
      }).$promise.then(function(answer) {
          parseAnswer(answer, review.question.type);
          review.answer = answer;
          $scope.dirty = true;
          notificationService.notify('info', '打分成功')
        }).catch(function() {
          notificationService.notify('error', '打分失败')
        });
    };

    $scope.getChoiceName = oedTestUtils.getChoiceName;
    $scope.getAnswerText = oedTestUtils.getAnswerText;
  }
})();
