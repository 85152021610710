import { Component, OnInit, EventEmitter, Output, Input, SimpleChange } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ExamDataDTO, ExamPartDetailDTO } from '../models/exam';
import _ = require('lodash');
import { getExamQIndexMap } from 'assets/app/controllers/examination/examEdit/paper/paperUtils';

@Component({
  selector: 'app-adjust-score-component',
  template: require('./adjust-score-component.component.html'),
  styles: [require('./adjust-score-component.component.scss')]
})
export class AdjustScoreComponentComponent implements OnInit {

  @Output() public action = new EventEmitter<any>();
  @Output() public modelChanged = new EventEmitter<any>();
  @Input() examData: ExamDataDTO;

  partDetails: ExamPartDetailDTO[]
  sortMap: {}
  constructor(public bsModalRef: BsModalRef) { }

  public close() {
    this.bsModalRef.hide()
    this.action.emit()
  }

  ngOnInit() {
    this.partDetails = _.get(this.examData, 'examPartDetails', [])
    this.sortMap = getExamQIndexMap(this.examData)
  }

  handleModelChanged() {
    this.modelChanged.emit()
    this.partDetails = _.get(this.examData, 'examPartDetails', [])
    this.sortMap = getExamQIndexMap(this.examData)
  }

}
