(function() {
  $(document).ready(function() {
    var init = function() {
      document.addEventListener('touchstart', handler, true);
      document.addEventListener('touchmove', handler, true);
      document.addEventListener('touchend', handler, true);
      document.addEventListener('touchcancel', handler, true);
    };

    function handler(event) {
      var touch = event.changedTouches[0];
      var simulatedEvent = document.createEvent('MouseEvent');

      simulatedEvent.initMouseEvent({
          touchstart: 'mousedown',
          touchmove: 'mousemove',
          touchend: 'mouseup'
        }[event.type],
        true, true, window, 1,
        touch.screenX, touch.screenY, touch.clientX, touch.clientY,
        false, false, false, false, 0, null);

      touch.target.dispatchEvent(simulatedEvent);
    }

    init();
  });

})();
