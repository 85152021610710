import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {CountResult} from 'app/models/cloudDrive';
import {NotificationService} from 'app/shared/notification/notification.service';
import {CloudDriveService} from 'app/cloud-drive/cloud-drive.service';
import {ResourceService} from 'app/core/resource.service';
import {OedDialogService} from 'app/core/oed-dialog.service';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {catchError, concatMap, finalize, flatMap, take, toArray} from 'rxjs/operators';
import {forkJoin, from, of} from 'rxjs';
import * as _ from 'lodash';
import {RestoreDialogComponent} from 'app/cloud-drive/restore-dialog/restore-dialog.component';

@Component({
  selector: 'app-my-recycle-bin',
  template: require('./my-recycle-bin.component.html'),
  styles: [require('./my-recycle-bin.component.scss')]
})
export class MyRecycleBinComponent implements OnInit {
  @Output() loadingDrive = new EventEmitter<boolean>();
  @Output() refreshDriveSize = new EventEmitter<any>();
  
  checkAll = false;//全选状态
  isSelectMode = false;//是否是选择模式
  selectTotalNum = 0;//选择模式选择的文件个数
  fullPath = [];//文件路径
  page = 0;//我的文件page
  page_mirlesson = 0;//我的微课page
  myFileArr = [];
  myCourse = [];
  fileCount = 0;
  microLessonCount = 0;
  dataAll = [];//总数据  展示用
  sortDateAsc = false;
  sortSizeAsc = false;
  sortNameAsc = false;
  selectedSort = '';
  sortType = undefined
  canAppend = true
  pageNumFile = 0
  pageNumLesson = 0
  
  constructor(public bsModalRef: BsModalRef,
              public cloudDriveService: CloudDriveService,
              public resourceService: ResourceService,
              public oedDialogService: OedDialogService,
              public notifyService: NotificationService) { }

  ngOnInit() {
  }
  
  ngAfterViewInit() {
    this.loadingDrive.emit(true)
    forkJoin(this.cloudDriveService.getFileCountByRecycBin(), this.cloudDriveService.getMicroLessonCountByRecycBin()).pipe(
      flatMap((res) => {
        this.fileCount = res[0].count
        this.microLessonCount = res[1].count
        return this.getListData('')
      }),
      finalize(() => this.loadingDrive.emit(false))
    ).subscribe()
  }
  
  //清空回收站
  public cleanRecycleBin() {
    this.oedDialogService.openModalWithComponent2('ErrorDialogComponent', {
      title: '确认',
      msg: '确认清空回收站么？'
    }, {
      class: 'oedSelectModal error-modal-center',
    }).pipe(
      flatMap(() => {
        this.loadingDrive.emit(true)
        if(this.fileCount == 0  && this.microLessonCount == 0){
          return;
        }
        if(this.fileCount > 0){
          return this.cloudDriveService.clearRecycleBin()
        }
        if(this.microLessonCount > 0){
          return this.cloudDriveService.clearRecycleBinMicroLesson()
        }
      }),
      flatMap(() => {
        return this.getListData('')
      }),
      finalize(() => this.loadingDrive.emit(false))
    ).subscribe(() => {}, () => {
      this.notifyService.notify('error', ' 清空回收站失败');
    })
  }
  
  public getTypeUrl(type) {
    return this.cloudDriveService.getTypeUrl(type)
  }
  
  //全选操作
  public changeSelectAllStatus() {
    this.checkAll = !this.checkAll;
    if(this.checkAll){
      this.selectTotalNum = this.dataAll.length;
      this.isSelectMode = true;
    }else{
      this.isSelectMode = false;
      this.selectTotalNum = 0;
    }
    this.myFileArr.forEach(element => {
      element.$$checked =  this.checkAll;
    });
    this.myCourse.forEach(element => {
      element.$$checked =  this.checkAll;
    });
  }
  
  //文件选择操作并判断是否全选选中
  public changeSelectSingleStatus(item) {
    item.$$checked = !item.$$checked;
    const checked = _.filter(this.dataAll, (data: any) => {
      return data.$$checked;
    });
    if (checked.length > 0) {
      this.selectTotalNum = checked.length;
      this.isSelectMode = true;
    } else {
      this.isSelectMode = false;
      this.selectTotalNum = 0;
    }
    if (checked.length == this.myFileArr.length + this.myCourse.length) {
      this.checkAll = true;
    } else {
      this.checkAll = false;
    }
  }
  
  public onSortByDate() {
    this.selectedSort = 'date';
    this.sortDateAsc = !this.sortDateAsc;
    if (this.sortDateAsc) {
      this.sortType = 'dateDown'
    } else {
      this.sortType = 'dateUp'
    }
    
    this.loadingDrive.emit(true)
    this.getListData('').pipe(finalize(() => this.loadingDrive.emit(false))).subscribe()
  }
  
  public onSortBySize(source) {
    this.selectedSort = 'size';
    this.sortSizeAsc = !this.sortSizeAsc;
    if (this.sortSizeAsc) {
      this.sortType = 'sizeDown'
    } else {
      this.sortType = 'sizeUp'
    }
  
    this.loadingDrive.emit(true)
    this.getListData('').pipe(finalize(() => this.loadingDrive.emit(false))).subscribe()
  }
  
  public onSortByName() {
    this.selectedSort = 'name';
    this.sortSizeAsc = !this.sortSizeAsc;
    if (this.sortSizeAsc) {
      this.sortType = 'nameDown'
    } else {
      this.sortType = 'nameUp'
    }
  
    this.loadingDrive.emit(true)
    this.getListData('').pipe(finalize(() => this.loadingDrive.emit(false))).subscribe()
  }
  
  public sortByDate(source) {
    this.selectedSort = 'date';
    if(source != "refresh"){
      this.sortDateAsc = !this.sortDateAsc;
    }
    if(this.sortDateAsc){
      this.dataAll = _.sortBy(this.dataAll, function(item) {
        return -item.lastUpdatedTime;
      });
    }else{
      this.dataAll = _.sortBy(this.dataAll, function(item) {
        return item.lastUpdatedTime;
      });
    }
  }
  
  public sortBySize(source) {
    this.selectedSort = 'size';
    if(source != "refresh"){
      this.sortSizeAsc = !this.sortSizeAsc;
    }
    if(this.sortSizeAsc){
      this.dataAll = _.sortBy(this.dataAll, function(item) {
        return -item.originSize;
      });
    }else{
      this.dataAll = _.sortBy(this.dataAll, function(item) {
        return item.originSize;
      });
    }
  }
  
  public sortByName(source) {
    this.selectedSort = 'name';
    if(source != "refresh"){
      this.sortNameAsc = !this.sortNameAsc;
    }
    if(this.sortNameAsc){
      this.dataAll =  _.orderBy(this.dataAll, ['name'], ['asc']);
    }else{
      this.dataAll =  _.orderBy(this.dataAll, ['name'], ['desc']);
    }
  }
  
  //恢复文件
  public restore() {
    this.oedDialogService.openModalWithComponent2('ErrorDialogComponent', {
      title: '确认',
      msg: '确定恢复所选文件么？'
    }, {
      class: 'oedSelectModal error-modal-center',
    }).pipe(
      flatMap(() => {
        const courseIdList = _.filter(this.myCourse, (data: any) => {
          return data.$$checked;
        }).map(function(item){
          return item["id"];
        });
        
        if (_.isEmpty(courseIdList)) {
          return of({})
        }
        return this.cloudDriveService.restoreCourse(courseIdList)
      }),
      catchError(() => {
        this.notifyService.notify('error', '微课恢复失败');
        return of({})
      }),
      flatMap(() => {
        if (_.isEmpty(_.filter(this.myFileArr, (data: any) => data.$$checked))) {
          return of([])
        }
        const files = _.filter(this.myFileArr, (data: any) => data.$$checked)
        return this.restoreFiles(files)
      }),
      catchError((error) => {
        return of({})
      }),
      flatMap(() => {
        this.loadingDrive.emit(true)
        this.refreshDriveSize.emit(true)
        return this.getListData('').pipe(finalize(() => this.loadingDrive.emit(false)))
      })
    ).subscribe(()=> {}, (error) => {
      console.log(error)
    })
  }
  
  private restoreFiles(files) {
    return from(files).pipe(
      concatMap((file) => this.restoreFile(file)),
      toArray()
    )
  }
  
  private restoreFile(file) {
    return this.cloudDriveService.restoreFile(file.id).pipe(
      flatMap((r) => {
        if(r.status === 'cloudDrive/nameExist') {
          return this.oedDialogService.openModalWithComponent2('RestoreDialogComponent', {
            title: '恢复文件',
            msg: file.name,
          }, {
            class: 'modal-md restoreDialog',
          }).pipe(
            flatMap((msg) => {
              if (msg === 'ok') {
                file.name = r.newName
                return this.cloudDriveService.restoreAgain(file.id, file).pipe(catchError(() => {
                  this.notifyService.notify('error', file.name + '恢复失败');
                  return of({})
                }))
              }

              if (msg === 'jump') {
                return of({})
              }

              if (msg === 'cancel') {
                throw 'cancel'
              }
            }),
            take(1)
          )
        } else if (r.status === 'cloudDrive/exceedMaxCapacity') {
          this.notifyService.notify('error', file.name + '恢复失败，您的云盘剩余空间不足', '', 2 * 1000);
          return of({})
        } else {
          return of({})
        }
      })
    )
  }
  
  //彻底删除
  public deleteComplete() {
    this.oedDialogService.openModalWithComponent2('ErrorDialogComponent', {
      title: '确认',
      msg: '确认删除所选文件么？'
    }, {
      class: 'oedSelectModal error-modal-center',
    }).pipe(
      flatMap(() => {
        const fileIdList = _.filter(this.myFileArr, (data: any) => {
          return data.$$checked;
        }).map(function(item){
          return item["id"];
        });
        const courseIdList = _.filter(this.myCourse, (data: any) => {
          return data.$$checked;
        }).map(function(item){
          return item["id"];
        });
        
        return forkJoin(_.isEmpty(fileIdList) ? of({}) : this.cloudDriveService.deleteFilesComplete(fileIdList),
            _.isEmpty(courseIdList) ? of({}) : this.cloudDriveService.deleteCoursesComplete(courseIdList)
          )
      }),
      catchError(() => {
        this.notifyService.notify('error', '删除失败');
        return of({})
      }),
      flatMap(() => {
        this.loadingDrive.emit(true)
        return this.getListData('').pipe(finalize(() => this.loadingDrive.emit(false)))
      })
    ).subscribe()
  }
  
  public myPagingFunction() {
    this.pageNumFile = Math.ceil(this.fileCount/this.cloudDriveService.pageSize);
    this.pageNumLesson = Math.ceil(this.microLessonCount/this.cloudDriveService.pageSize);
    if(this.canAppend){
      if(this.page + 1 < this.pageNumFile){
        this.page++;
        this.canAppend = false;//防止请求异步导致多次append
      }
      if(this.page_mirlesson + 1 < this.pageNumLesson){
        this.page_mirlesson++;
        this.canAppend = false;//防止请求异步导致多次append
      }
  
      if (!this.canAppend) {
        this.loadingDrive.emit(true)
        this.getListData('').pipe(finalize(() => this.loadingDrive.emit(false))).subscribe();
      }
    }
  }
  
  private getListData(type) {
    if(type != "append") {
      this.page = 0;
      this.page_mirlesson = 0;
      this.checkAll = false;
      this.selectTotalNum = 0;
      this.isSelectMode = false;
    }
    
    return forkJoin(this.getRecycledFile(type), this.getRecycledMicLesson(type)).pipe(
      flatMap(() => {
        this.dataAll = this.myFileArr.concat(this.myCourse);
        if(this.selectedSort == "date"){
          this.sortByDate('refresh');
        }
        if(this.selectedSort == "size"){
          this.sortBySize('refresh');
        }
        this.selectTotalNum = this.dataAll.filter(r=>{return r.$$checked}).length;
        return of({})
      }),
      finalize(() => this.canAppend = true)
    )
  }
  
  private getRecycledFile(type) {
    return this.cloudDriveService.recycleBin(this.page, this.cloudDriveService.pageSize, this.sortType).pipe(
      flatMap((items: any) => {
        if(type == "append"){
          this.myFileArr =  this.myFileArr.concat(items);
        }else{
          this.myFileArr = items;
        }
  
        _.forEach(items,(item: any) => {
          if(type == "append" && this.checkAll){
            item.$$checked = true;
          }else{
            item.$$checked = false;
          }
          item.$$type="myFile";
          item.$$deleteDate= item.lastUpdatedTime;
          if(item.itemType == "dir"){
            item.originSize = 0;
          }
        });
  
        const needHandleArr = _.filter(items,(data: any) => {
          return data.itemType == "file";
        });
  
        if (_.isEmpty(needHandleArr)) {
          return of([{val: [], newDatas: items}])
        }
        return forkJoin(_.map(needHandleArr, (r: any) => {
          return this.resourceService.getResourceInfoByUuid(r.resourceUuid).pipe(
            flatMap((res) => {return of({item: r, res: res})})
          )
        })).pipe(flatMap((val) => {return of([{val: val, newDatas: items}])}))
      }),
      flatMap((data)=>{
        if (!_.isEmpty(data[0].val)) {
          _.each(data[0].val, (v) => {
            v.item.suffix = v.res.suffix
            v.item.size = this.cloudDriveService.bytesToSize(v.res.size)
            v.item.originSize = v.res.size
          })
        }
  
        return this.getPath(data[0].newDatas);
      })
    )
  }
  
  private getRecycledMicLesson(type) {
    return this.cloudDriveService.recycleBinMicroLesson(this.page_mirlesson, this.cloudDriveService.pageSize, this.sortType).pipe(
      flatMap((res) => {
        if(type == "append"){
          this.myCourse =   this.myCourse.concat(res);
        }else{
          this.myCourse = res;
        }
  
        _.forEach(res, (item: any) => {
          if(type == "append" && this.checkAll){
            item.$$checked = true;
          }else{
            item.$$checked = false;
          }
          item.$$type="myCourse";
          item.$$deleteDate= item.lastUpdatedTime;
          item.path = "我的微课";
          item.name = item.resourceName;
        });
        
        if (_.isEmpty(this.myCourse)) {
          return of([])
        }
        return forkJoin(_.map(this.myCourse, (course) => this.resourceService.getResourceById(course.resourceId)))
      }),
      flatMap(val => {
        if (!_.isEmpty(val)) {
          for (let index = 0; index < val.length; index++) {
            this.myCourse[index].size = this.cloudDriveService.bytesToSize(val[index].size);
            this.myCourse[index].suffix = val[index].suffix;
            this.myCourse[index].originSize = val[index].size;
          }
        }
        
        return of({})
      })
    )
  }
  
  private getPath(val){
    if (_.isEmpty(val)) {
      return of({})
    }
    
    return forkJoin(_.map(val, (x) => {
      return this.cloudDriveService.getFullPath(x.id)
    })).pipe(
      flatMap((y) => {
        for (let index = 0; index < y.length; index++) {
          let name = "";
          _.each(y[index].slice(1,-1), (v: any) => {
            name += v.name + '/';
          });
          val[index].path = name;
        }
        this.canAppend = true;
        return of({})
      })
    )
  }

}
