import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubjectSelectorComponent } from './subject-selector/subject-selector.component';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [SubjectSelectorComponent],
  entryComponents: [SubjectSelectorComponent],
  imports: [
    CommonModule,
    PopoverModule.forRoot(),
    TooltipModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot()
  ]
})
export class CloudModule { }
