/**
 * Created by ryoushikaoru on 2019/3/5.
 */
import * as _ from 'lodash';

import Promise = require('bluebird');
require('./../cloud/objectiveTestLibrary.ctrl');
const {doImportQuestions} = require('app2/utils/testUtils2');
const {confirmAndDownloadSubjectiveTest, importSubjectTestModel} = require('app2/utils/testUtils2');
require('./subjectiveTestDialog.scss')

subjectiveTestDialogCtrl.$inject = [
    'oedCourse',
    'oedCoursePath',
    '$q',
    'notificationService',
    'oedSubjectiveTest',
    'localStorageService',
    'teachingModuleService',
];
function subjectiveTestDialogCtrl(
    oedCourse,
    oedCoursePath,
    $q,
    notificationService,
    oedSubjectiveTest,
    localStorageService,
    teachingModuleService,
  ) {
    this.importedTests = []
    this.layoutType = {name: ''};
    this.status = {};
    this.$onChanges = (changesObj: any) => {
        if (_.has(changesObj, 'resolve')) {
            this.testId = this.resolve.data.testId;
            this.moduleId = this.resolve.data.moduleId;
            this.moduleName = this.resolve.data.moduleName;
            this.courseId = _.parseInt(this.resolve.data.courseId);
            if (this.courseId) {
                this.course = oedCourse.get({
                    id: this.courseId
                });
                this.tests = oedSubjectiveTest.queryByCourseIncEmpty({
                    course_id: this.courseId
                });
                this.tests.$promise.then((res: any) => {
                    if (!res.length) {
                        const test = new oedSubjectiveTest({
                            name: '主观活动1',
                            courseId: this.courseId,
                            sort: this.maxSort ? this.maxSort : 1
                        });
                        test.$save().then((r: any) => {
                          notificationService.notify('info', '新建活动成功')
                          this.testId = r.id;
                          this.tests = oedSubjectiveTest.queryByCourseIncEmpty({
                              course_id: this.courseId
                          });
                          this.load();
                        });
                    } else {
                        this.load();
                    }
                });
            }
        }
        if (_.has(changesObj, 'currentTest')) {
            this.currentTest.$promise.then((res: any) => {
                localStorageService.set('currentName', res.name);
            });
        }
    };

    this.setCurrentTest = (t, test) => {
        this.currentTest = oedSubjectiveTest.get({
            id: t.id
        });
        this.currentTest.$promise.then((res: any) => {
            localStorageService.set('currentName', res.name);
        });
        this.tests = test;
    };

    const getCourse = () => {
        if (!this.course) {
            return oedCourse.get({
                id: this.courseId,
            }).$promise
        } else {
            return Promise.resolve(this.course)
        }
    };
    this.load = () => {
        this.tests.$promise.then((tests) => {
            this.tests = tests;
            this.currentTestId = this.testId ? this.testId : this.tests[0].id;
            this.maxSort = _.max(_.map(this.tests, 'sort'));
            this.existingNames = _.map(this.tests, 'name');
            return getCourse().then((course) => {
                this.course = course;
                this.currentTest = oedSubjectiveTest.get({
                    id: this.currentTestId
                });
                this.currentTest.$promise.then((res: any) => {
                    localStorageService.set('currentName', res.name);
                });
                return $q.all([this.currentTest.$promise])
            });
        });
    };

    this.mclose = () => {
        this.doClose()
    }

    this.doClose = () => {
        this.close({
            $value: {
            importedTests: this.importedTests,
            }
        })
    }

    this.onImportTestChange = (tests: number[]) => {
        this.importedTests = tests
    }
}

export const subjectiveTestDialog =  {
    bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
    },
    controller: subjectiveTestDialogCtrl,
    template: <string>require('./subjectiveTestDialog.html'),
}

angular.module('app.prepareCourse2.subjectiveTestDialog', [])
    .component('subjectiveTestDialog', subjectiveTestDialog);
