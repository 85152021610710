import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TagInputModule } from 'ngx-chips';

import { SingleChoiceDesignerComponent } from './single-choice-designer/single-choice-designer.component';
import { EditingModule } from 'app/editing/editing.module';
import { MultiChoiceDesignerComponent } from './multi-choice-designer/multi-choice-designer.component';
import { ConnectDesignerComponent } from './connect-designer/connect-designer.component';
import { ConnectOptionPickerComponent } from './connect-option-picker/connect-option-picker.component';
import { FillDesignerComponent } from './fill-designer/fill-designer.component';
import { FillAnswerDesignerComponent } from './fill-answer-designer/fill-answer-designer.component';
import { FillAnswerItemComponent } from './fill-answer-item/fill-answer-item.component';
import { YesOrNoDesignerComponent } from './yes-or-no-designer/yes-or-no-designer.component';
import { VoteDesignerComponent } from './vote-designer/vote-designer.component';
import { QaDesignerComponent } from './qa-designer/qa-designer.component';
import { QuestionDesignerComponent } from './question-designer/question-designer.component';
import { UtilsModule } from 'app/utils/utils.module';
import { ToUniqResIdPipe } from 'app/utils/to-uniq-res-id.pipe';
import { SectionSelectDropdownComponent } from './section-select-dropdown/section-select-dropdown.component';
import { SectionNamesComponent } from './section-names/section-names.component';
import { SyntheticDesignerComponent } from './synthetic-designer/synthetic-designer.component';

@NgModule({
  declarations: [
    SingleChoiceDesignerComponent,
    MultiChoiceDesignerComponent,
    ConnectDesignerComponent,
    ConnectOptionPickerComponent,
    FillDesignerComponent,
    FillAnswerDesignerComponent,
    FillAnswerItemComponent,
    YesOrNoDesignerComponent,
    VoteDesignerComponent,
    QaDesignerComponent,
    QuestionDesignerComponent,
    SectionSelectDropdownComponent,
    SectionNamesComponent,
    SyntheticDesignerComponent,
  ],
  entryComponents: [
    SingleChoiceDesignerComponent,
    MultiChoiceDesignerComponent,
    ConnectDesignerComponent,
    FillDesignerComponent,
    FillAnswerDesignerComponent,
    FillAnswerItemComponent,
    YesOrNoDesignerComponent,
    VoteDesignerComponent,
    QaDesignerComponent,
    QuestionDesignerComponent,
    SyntheticDesignerComponent,
  ],
  exports: [
    SingleChoiceDesignerComponent,
    MultiChoiceDesignerComponent,
    ConnectDesignerComponent,
    FillDesignerComponent,
    FillAnswerDesignerComponent,
    YesOrNoDesignerComponent,
    VoteDesignerComponent,
    QaDesignerComponent,
    QuestionDesignerComponent,
    SyntheticDesignerComponent,
  ],
  imports: [
    NgSelectModule,
    BrowserAnimationsModule,
    BsDropdownModule,
    TooltipModule,
    FormsModule,
    CommonModule,
    EditingModule,
    TagInputModule,
    UtilsModule,
  ]
})
export class CourseEditingModule { }
