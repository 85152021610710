/**
 * @fileOverview
 * @name subjectiveTestSessionDetailsCtrl.js
 * @author pangwa
 * @license
 */
//
// var commentComposer = require('commentComposer');

(function() {
  'use strict';
  require('app2/directives/board/boardDesigner.directive');
  require('assets/styles/review.scss');
  require('assets/styles/preRes.scss');

  angular.module('app.review')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('preResSessions.session', {
        url: '/session/:sessionId/ofItem/:isItem',
        template: require('assets/templates/review/preResSessionDetails.html'),
        controller: 'preResSessionDetailsCtrl',
        bodyStyle: 'bg_img'
      });
      $stateProvider.state('tempItemSessions.session', {
        url: '/session/:sessionId/ofItem/:isItem',
        template: require('assets/templates/review/preResSessionDetails.html'),
        controller: 'preResSessionDetailsCtrl',
        bodyStyle: 'bg_img'
      });
      $stateProvider.state('tmSessions.preSession', {
        url: '/session/:sessionId/preRes/:preResId/ofItem/:isItem',
        template: require('assets/templates/review/preResSessionDetails.html'),
        controller: 'preResSessionDetailsCtrl',
        bodyStyle: 'bged2'
      });
    }])
    .controller('preResSessionDetailsCtrl', preResSessionDetailsCtrl);

  preResSessionDetailsCtrl.$inject =['$scope', '$state', '$stateParams', 'oedPreResSessionStudent',
    '$q', 'oedPresentResource', 'oedPreResSession', 'oedPreResItemSession', 'oedClassSession', '$uibModal']
  function preResSessionDetailsCtrl($scope, $state, $stateParams, oedPreResSessionStudent,
    $q, oedPresentResource, oedPreResSession, oedPreResItemSession, oedClassSession, $uibModal) {
    $scope.courseId = _.parseInt($stateParams.courseId);
    $scope.classSessionId = _.parseInt($stateParams.classSessionId);
    $scope.sessionId = _.parseInt($stateParams.sessionId);
    $scope.preResId = _.parseInt($stateParams.preResId);
    $scope.currentState.title = '展示资源';
    $scope.activeTabIndex = 1;
    $scope.isPreResItemSession = $stateParams.isItem === 'true';
    $scope.tabs = [{index: 0, text: '资源内容'},
      {index: 1, text: '查看情况'}];

    const getResource = () => {
      if (_.isNaN($scope.preResId)) {
        $scope.resource = _.assign({}, {
          name: '临时展示资源',
        })
        return Promise.resolve()
      } else {
        return oedPresentResource.get({
          id: $scope.preResId
        }).$promise.then(r => {
          $scope.resource = r
        })
      }
    }
    if ($stateParams.isItem === 'false') {
      $scope.session = oedPreResSession.get({
        id: $scope.sessionId
      });
    } else {
      $scope.session = oedPreResItemSession.get({
        id: $scope.sessionId
      });
    }
    $scope.classSession = oedClassSession.get({
      id: $stateParams.classSessionId
    });
    $scope.loading = $q.all([$scope.classSession.$promise,
      $scope.session.$promise, getResource()]).then(() => {
      $scope.classId = $scope.classSession.classInfo.id;
      $scope.loaded = true;
    });

    $scope.showItemSummary = function(itemIndex, items) {
      $uibModal.open({
        controller: 'preResItemSummaryCtrl',
        resolve: {
          data: () => {
            return {
              classId: $scope.classId,
              classSessionId: $scope.classSessionId,
              index: itemIndex,
              isPreresItemSession: $stateParams.isItem,
              items: items,
              preResSession: $scope.session,
              preResSessionId: $scope.session.id
            };
          },
        },
        size: 'lg',
        template: require('assets/app2/resource/preResItemSummary.html'),
        windowClass: 'oedmodal modalCenter',
      });
    }

  }
})();
