import * as _ from 'lodash'
import {listeningHasntAudioUrl, convertRuanyunQuestionToRayQ, getAllData} from "app2/integration/ruanyun/ruanyunQuesConvertUtils";
import {convertChaptersToRaySections} from "app2/integration/ruanyun/ruanyunUtils";
import {matchSection} from "app2/integration/sectionMatchUtils";
import { sortQuesTypeList } from 'assets/app/controllers/examination/examEdit/generating/subjectRankingUtils'
import { v4 as uuidv4 } from 'uuid'
import { generateLocalQuestion } from '../../examUtils';

require('assets/app/controllers/examination/examEdit/generating/examEditGenerating.scss');

angular.module('app.examination.examEdit.generating', ['rzSlider'])
  .component('examEditGenerating', {
    bindings: {
      sectionParentName: '<',
      coursePathId: '<',
      sectionId: '<',
      sectionRootId: '<',
      onSetRequestData: '&',
      toggleCurrentPage: '&',
      examName: '<',
      levelId: '<',
      relId: '<',
      isShowToPreviewBtn: '<',
      updateSelectSize: '&',
      onExamIsUpdate: '&',
    },
    controller: examEditGeneratingCtrl,
    template: <string>require('./examEditGenerating.html'),
  });

examEditGeneratingCtrl.$inject = ['$scope', 'oedExamination', 'ruanyunResource', 'userInfo', 'notificationService', '$state', 'dialogs', '$q', '$uibModal', 'examApi'];

function examEditGeneratingCtrl($scope, oedExamination, ruanyunResource, userInfo, notificationService, $state, dialogs, $q, $uibModal, examApi) {

  const apiLimit = 50;
  const ctrl = this;
  let generatPaperTip = ''
  // ctrl.emptyGeneratingData = false;

  ctrl.$onChanges = (changeObj: any) => {
    if (_.has(changeObj, 'coursePathId')) {
      const preCoursePathId = _.get(changeObj, 'coursePathId.previousValue', '')
      const curCoursePathId = _.get(changeObj, 'coursePathId.currentValue', '')
      if (preCoursePathId != curCoursePathId) {
        // 每次进入智能组卷，重置选择的题目的数量和试卷的更新状态
        ctrl.updateSelectSize({ size: 0 })
        ctrl.onExamIsUpdate({ update: false })
        loadCoursePath()
      }
      if (_.get(changeObj, 'relId')) {
        loadSections()
      }
    }
  };


  userInfo.then(function (user) {
    ctrl.teacherId = _.parseInt(user.uid);
    ctrl.isResearcher = _.includes(user.roles, 'researcher');
  });

  ctrl.difficulty = 0.6;
  ctrl.slider = {
    value: 2,
    options: {
      floor: 0,
      ceil: 4,
      step: 1,
      showTicks: true,
      onEnd: function (sliderId, modelValue) {
        ctrl.difficulty = ((modelValue + 1) * 0.2).toFixed(1);
      }
    }
  };

  ctrl.onChangeDifficulty = (difficulty) => {
    ctrl.difficulty = difficulty;
    const value = (difficulty * 5) - 1;
    ctrl.slider = {
      ...ctrl.slider,
      value: value,
    };
  };

  let submitQuesTypeList = [];
  let totalNum = 0;

  ctrl.onUpdateGeneratingSetting = (setting) => {
    console.log('setting', setting)
    submitQuesTypeList = _.filter(submitQuesTypeList, function (s) {
      return s.categoryId != setting.categoryId;
    });
    if (setting.isCheck) submitQuesTypeList.push(setting);

    ctrl.updateSelectSize({size: _.size(submitQuesTypeList)})
    if (submitQuesTypeList.length > 10) {
      notificationService.notify('warn', '试卷不能超过10种题型!');
      return
    }

    totalNum = 0;
    submitQuesTypeList.forEach(qt => {
      totalNum = totalNum + qt.num
    });
    const tip = getQuesNumValidTip()
    if (tip != '') {
      notificationService.notify('error', tip);
      return
    }
  };

  function getQuesNumValidTip() {
    let totalNum = 0
    for (let i = 0; i < submitQuesTypeList.length; i++) {
      const quesItemNum = _.get(submitQuesTypeList, `${i}.num`, 0)
      totalNum += quesItemNum
      const quesItemName = _.get(submitQuesTypeList, `${i}.categoryName`, 0)
      if (quesItemNum > 105) {
        return quesItemName + '不能超过105道题!'
      }
    }
    if (totalNum > 105) {
      return '试卷题目总数不能超过105道题!'
    }
    return ''
  }

  ctrl.onGeneratingConfirm = () => {

    if (submitQuesTypeList.length > 10) {
      notificationService.notify('warn', '试卷不能超过10种题型!');
      return;
    }

    const tip = getQuesNumValidTip()
    if (tip != '') {
      notificationService.notify('error', tip);
      return
    }

    if (_.isEmpty(submitQuesTypeList)) {
      notificationService.notify('warn', '组卷时至少需要设定一道大题!');
      return;
    }

    const unExceptQT = _.find(submitQuesTypeList, function (qt) {
      if (qt.isCheck == true) {
        if (qt.num == 0 || null == qt.num) {
          notificationService.notify('warn', '选中题型的数量需大于零!');
          return true
        } else if (qt.totalScore == 0 || null == qt.totalScore) {
          notificationService.notify('warn', '选中题型的总分需大于零!');
          return true
        } else if ((qt.num % 5 == 0 && qt.totalScore < qt.num * 0.5)
          || (qt.num % 5 != 0 && qt.totalScore < qt.num * 1)) {
          notificationService.notify('warn', qt.categoryName + '单题分数过低!');
          return true
        }
      }
      return false;
    });
    if (unExceptQT) return;

    const qtListArray = getGeneratingQuesTypeList(submitQuesTypeList);
    generatingQuestionOneByOne(qtListArray);
  };

  ctrl.onBack = () => {
    $state.go('resCenter');
  };


  function getGeneratingQuesTypeList(submitQuesTypeList) {

    let normalQTList = [];
    const bigQTList = [];
    // 过滤出大于50和不大于50的两个QuesType列表
    submitQuesTypeList.forEach((qt) => {
      if (qt.num > apiLimit) bigQTList.push(qt);
      else normalQTList.push(qt);
    });

    if (!_.isEmpty(bigQTList)) {
      // 将大于50的列表元素拆分成不大于五十的元素，再加到normalQTList上去
      bigQTList.forEach((qt) => {
        const howManyFifty = qt.num / apiLimit;
        let splitQtList;
        if (qt.num % apiLimit == 0) {
          qt.num = apiLimit;
          splitQtList = new Array(howManyFifty);
          for (let i = 0; i < splitQtList.length; i++) {
            splitQtList[i] = qt;
          }
        } else {
          splitQtList = new Array(parseInt(String(howManyFifty + 1)));

          for (let i = 0; i < splitQtList.length; i++) {
            const tempQt = {...qt};
            if (i == splitQtList.length - 1) {
              tempQt.num = qt.num % apiLimit;
              splitQtList[i] = tempQt;
            } else {
              tempQt.num = apiLimit;
              splitQtList[i] = tempQt;
            }
          }
        }
        normalQTList = normalQTList.concat(splitQtList);
      });
    }

    // 这个时候normalQTList和submitQuesTypeList只有顺序上的差别，不影响接口回调
    // 将normalQTList拆分成多个num总合不超过50的list
    let lastQtListTotalNum;
    let littleSubmitQuesTypeListList = [];
    normalQTList.forEach((qt) => {
      if (littleSubmitQuesTypeListList.length == 0) {
        littleSubmitQuesTypeListList.push([qt]);
        lastQtListTotalNum = qt.num
      } else if (lastQtListTotalNum + qt.num > apiLimit) {
        littleSubmitQuesTypeListList.push([qt]);
        lastQtListTotalNum = qt.num
      } else {
        const sumQtList = _.concat(_.takeRight(littleSubmitQuesTypeListList)[0], [qt]);
        littleSubmitQuesTypeListList = _.dropRight(littleSubmitQuesTypeListList);
        littleSubmitQuesTypeListList.push(sumQtList);
        lastQtListTotalNum = lastQtListTotalNum + qt.num;
      }
    });
    // 返回的list中，每一个qt的num都不超过50
    return littleSubmitQuesTypeListList;
  }

  function generatingQuestionOneByOne(qtListArray) {

    tempQuesTypeList = [];
    const promiseArray = qtListArray.map(qtList => {
      const intelligentParams = getParamsForGenerating(qtList);
      return generatingQuestion(intelligentParams)
    });

    let tempQList = [];
    $scope.loading = $q.all(promiseArray).then(() => {
      const ruanyunQData = _.groupBy(ruanyunQList, 'questionCategory.id')
      const rightNumQList = submitQuesTypeList.map(qt => {
        const qList = _.get(ruanyunQData, qt.id, []);
        return _.take(qList, qt.num);
      });
      tempQList = tempQList.concat(_.flatten(rightNumQList));
      ruanyunQList = tempQList;
      if (!_.isEmpty(ruanyunQList)) {
        convertQuestion(ruanyunQList)
      }
    });
  }

  function getParamsForGenerating(qtList, forTest = false) {

    let QuestionCounts;
    if (!forTest) {
      let haveZeroScore = false;
      QuestionCounts = qtList.map(qt => {
        if (qt.totalScore == 0) haveZeroScore = true;
        return {
          QuestionCategoryId: qt.id,
          QuestionCount: qt.num
        }
      });
      if (haveZeroScore) {
        notificationService.notify('warn', '试题分数需要大于零!');
        return
      }
    } else {
      QuestionCounts = qtList.map(qt => {
        return {
          QuestionCategoryId: qt.id,
          QuestionCount: 1
        }
      })
    }

    const ChapterIds = [
      {CourseMappingId: 0, ChapterId: parseInt(ctrl.sectionId)},
      {CourseMappingId: 0, ChapterId: parseInt(ctrl.sectionRootId)},
    ];
    return {
      ByCourseMapping: 1,
      ChapterIds,
      QuestionCounts,
      QuestionLevelId: ctrl.difficulty * 5,
    };
  }
  /**
   * [
   *  {
   *    knowledgePoints: []
   *    question: {}
   *    questionCategory: {}
   *    questionLevel: {}
   *  }
   * ]
   */
  let ruanyunQList = [];

  function generatingQuestion(intelligentParams, resolve = null, takeOne = false) {

    return examApi.getQuestionByIntelligence(intelligentParams).$promise.then((data) => {
      const result = _.map(data, (r) => {
        return {
          ...r,
          question: generateLocalQuestion(r.question),
          uuid: uuidv4(),
        }
      })

      if (_.isEmpty(result)) {
        showCannotGeneratingDialog();
        return
      }

      return checkQuesValidity(result, intelligentParams);
    }).then(() => {
      if (resolve) resolve();
    });
  }

  function checkQuesValidity(rowRYQList, intelligentParams, takeOne = false) {
    let isComplete = false;
    const checkEveryQPromise = rowRYQList.map(ruanyunQ => {
      return $q(function (resolve) {

        if (isComplete) return resolve();

        ruanyunQList = ruanyunQList.concat(ruanyunQ);
        if (takeOne) isComplete = true;
        resolve()
      });
    });
    return $q.all(checkEveryQPromise);
  }

  function getExamPartDetails(ruanyunQList) {
    let partSort = 0;
    const insufficientQTHint = '%s只有%i道题，';
    let hints = "";
    const cantDivisibleQuesList = []
    console.log('submitQuesTypeList', submitQuesTypeList, 'ruanyunQList', ruanyunQList)
    const examPartDetails = submitQuesTypeList.map(qt => {
      const currentRuanyunQList = ruanyunQList.filter(ruanyunQ => {
        if (ruanyunQ.questionCategory.categoryName == '单选题' && (qt.categoryName == '选择题' || qt.categoryName == '单项选择')) {
          return true;
        }
        return ruanyunQ.questionCategory.categoryName == qt.categoryName
      });

      if (qt.num > currentRuanyunQList.length) {
        hints = hints + insufficientQTHint.replace("%s", qt.categoryName).replace('%i', currentRuanyunQList.length)
      }

      const pointArray = getPointArray(qt.totalScore, currentRuanyunQList, cantDivisibleQuesList);
      const filterRuanyunQues = _.filter(currentRuanyunQList, q => !listeningHasntAudioUrl(q));
      const filterListenQuesCount = _.size(currentRuanyunQList) - _.size(filterRuanyunQues)
      if (filterListenQuesCount > 0) {
        const tip = `${qt.categoryName}过滤掉${filterListenQuesCount}道非法听力题目`
        generatPaperTip = generatPaperTip  + `${tip}，`
      }
      const questionDetails = filterRuanyunQues.map((ruanyunQ, index) => {

        const KPITags = ruanyunQ.knowledgePoints.map(kp => {
          return {
            tagName: "ruanyun.KnowledgePointId",
            tagType: "number",
            numVal: _.get(kp, 'id', 0)
          }
        });
        const otherTags = [
          {
            tagName: "ruanyun.QuestionLevel",
            tagType: "number",
            numVal: ruanyunQ.questionLevel.id
          },
          {
            tagName: "ruanyun.QuestionCategory",
            tagType: "number",
            numVal: ruanyunQ.questionCategory.id
          }
        ];

        const questionTags = otherTags.concat(KPITags);
        return {
          question: {
            ...ruanyunQ.question,
            point: Math.floor(pointArray[index]), // 后端的point为int,  防止出现400, 理论上用不到point
            point2: pointArray[index],
          },
          sort: index++,
          questionTags: questionTags,
          $questionCategoryId: ruanyunQ.questionCategory.id,
          $questionLevelId: ruanyunQ.questionLevel.id,
        };
      });

      return {
        part: {
          name: qt.categoryName,
          sort: partSort++,
        },
        questionDetails: questionDetails
      }
    });
    generatPaperTip = generatPaperTip + _.join(_.map(cantDivisibleQuesList, q => `${_.get(q, 'name', '')}的第一道题为${_.get(q, 'score', '')}分，`), '')
    return {hints, examPartDetails};
  }

  function convertQuestion(ruanyunQList) {
    function getExamWithTags() {
      let tags;
      if (ctrl.isResearcher) {
        tags = [{
          tagName: "recommend",
          tagType: "string"
        }]
      } else {
        tags = [];
      }
      return {
        exam: {
          name: ctrl.examName,
          coursepathId: _.parseInt(ctrl.coursePathId),
          teacherId: ctrl.teacherId,
          duration: 45,
          sectionMode: "section",
          sort: 1,
          relatedSections: [_.parseInt(ctrl.sectionId)]
        },
        tags: tags
      };
    }

    const {hints, examPartDetails} = getExamPartDetails(ruanyunQList);

    const examWithTags = getExamWithTags();

    const requestData = {
      examWithTags: examWithTags,
      examPartDetails: examPartDetails
    };

    if (!_.isEmpty(hints)) {
      const continueGenerating = dialogs.confirm('确定继续组卷', hints + '继续组卷吗？');
      continueGenerating.result.then(function (btn) {
        showCantDivisibleDialog(requestData)
      })
    } else {
      showCantDivisibleDialog(requestData)
    }
  }

  function getPointArray(totalScore: number, quesList: any[], cantDivisibleQuesList: any[]) {
    const num = _.size(quesList)
    const quesName = _.get(quesList, '0.questionCategory.categoryName', '')
    const pointArray = new Array(num);
    if (num % 5 == 0) {
      // 题数能被5整除,0.5为最小单位
      const averageScore = parseInt(String(totalScore * 10 / num));
      if (averageScore >= 5) {
        // 平均分值大于零
        for (let i = 0; i < num; i++) {
          const score = parseInt(String(totalScore * 2 / num)) / 2;
          if (i == 0) {
            pointArray[i] = totalScore - score * (num - 1);
            if ((totalScore - score * (num - 1)) != score) {
              cantDivisibleQuesList.push({name: quesName, score: pointArray[0]})
            }
          } else {
            pointArray[i] = score;
          }
        }
      } else {
        // 平均分小于零点五理论上不应该出现这种情况，请不要传这种参数
        for (let i = 0; i < num; i++) {
          pointArray[i] = 0;
        }
      }
    } else {
      // 题数不能被5整除, 1为最小单位
      const averageScore = parseInt(String(totalScore / num));
      if (averageScore >= 1) {
        // 平均分值大于零
        for (let i = 0; i < num; i++) {
          if (i == 0) {
            pointArray[i] = averageScore + totalScore % num;
            if ((averageScore + totalScore % num) != averageScore) {
              cantDivisibleQuesList.push({name: quesName, score: pointArray[0]})
            }
          } else {
            pointArray[i] = averageScore;
          }
        }
      } else {
        // 平均分小于1理论上不应该出现这种情况，请不要传这种参数
        for (let i = 0; i < num; i++) {
          pointArray[i] = 0;
        }
      }
    }
    return pointArray;
  }

  function updateQuesTypeUI() {
    let middleIndex;
    if (ctrl.rowQuesTypeList) {
      if (ctrl.rowQuesTypeList.length % 2 == 0) {
        middleIndex = ctrl.rowQuesTypeList.length / 2
      } else {
        middleIndex = ctrl.rowQuesTypeList.length / 2 + 1
      }
      const sortedQuesType = sortQuesTypeList(ctrl.coursepath, ctrl.rowQuesTypeList)
      ctrl.rowQuesTypeList = sortedQuesType
      ctrl.quesGroup1 = ctrl.rowQuesTypeList.slice(0, middleIndex);
      ctrl.quesGroup2 = ctrl.rowQuesTypeList.slice(middleIndex, ctrl.rowQuesTypeList.length);
    }
  }

  function showCannotGeneratingDialog() {

    const modalInstance = $uibModal.open({
      component: 'dialogOne',
      size: 'common-dialog',
      windowClass: 'oedSelectModal',
      resolve: {
        data: () => {
          return {
            title: '智能组卷',
            msg: ctrl.isShowToPreviewBtn ? '题库中暂时没有本章节的智能组卷题目，请返回重新选择其他章节' : '本章节未找到合适试卷，请使用推荐试卷',
          }
        }
      }
    });

    return modalInstance.result.then(() => {
      // 显示预览按钮的为教研老师版本
      if (ctrl.isShowToPreviewBtn) {
        return ctrl.onBack()
      }
      return ctrl.toggleCurrentPage({page: 1})
    })
  }

  function showCantDivisibleDialog(requestData: any) {
    if (generatPaperTip === '') {
      return ctrl.onSetRequestData({requestData})
    }
    const modalInstance = $uibModal.open({
      component: 'dialogOne',
      size: 'common-dialog',
      windowClass: 'oedSelectModal',
      resolve: {
        data: () => {
          return { title: '智能组卷', msg: generatPaperTip }
        }
      }
    })
    return modalInstance.result.then(() => ctrl.onSetRequestData({requestData}))
  }

  function getQuestionTypes() {
    return examApi.getCategorys({
      levelId: ctrl.levelId,
      subjectId: ctrl.coursepath.subjectId,
    }).$promise.then((categories) => {
      if (_.isEmpty(categories)) {
        showCannotGeneratingDialog();
        return
      }

      ctrl.rowQuesTypeList = _.sortBy(categories, 'id');
      updateQuesTypeUI();
    });
  }

  function loadCoursePath() {
    $scope.loading = oedExamination.getCoursePath({id: ctrl.coursePathId}).$promise.then((coursepath) => {
      if (_.isEmpty(coursepath) || !coursepath.gradeId || !coursepath.subjectId || !coursepath.versionId) {
        showCannotGeneratingDialog();
        return
      }
      ctrl.coursepath = coursepath
      return getQuestionTypes();
    }).catch((e) => {
      console.error('load coursepath and question categorys error', e)
      showCannotGeneratingDialog();
    })
  }

  function loadSections() {
    // $scope.loading = examApi.getChapters({
    //   id: ctrl.relId
    // }).$promise.then((chapters) => {
    //   if (_.isEmpty(chapters)) {
    //     showCannotGeneratingDialog();
    //     return
    //   }

    //   const cps = [{
    //     child: convertChaptersToRaySections(chapters, 0)
    //   }];

    //   let matchedSection = matchSection(_.get(cps, '0.child', {}), ctrl.sectionParentName, 'child');
    //   if (_.isNil(matchedSection)) {
    //     matchedSection = _.get(cps, '0.child.0', {})
    //   }
    //   ctrl.choicedSectionId = _.get(matchedSection, 'id', 0);
    // }).catch((e) => {
    //   console.error('load sections', e)
    //   showCannotGeneratingDialog();
    // })
  }

  let tempQuesTypeList = [];

  // function testGeneratingDataOneByOne(qtListArray) {
  //   tempQuesTypeList = [];
  //   const promiseArray = qtListArray.map(qtList => {
  //     const intelligentParams = getParamsForGenerating(qtList, true);
  //     return testGeneratingData(intelligentParams, true)
  //   });
  //   $scope.loading = $q.all(promiseArray).then(() => {
  //     ctrl.rowQuesTypeList = _.sortBy(tempQuesTypeList, 'QuestionCategoryId');
  //     updateQuesTypeUI();
  //   });
  // }

  // function testGeneratingData(intelligentParams, isOneByOne = false) {
  //   return ruanyunResource.getQuestionByIntelligence(intelligentParams).$promise.then((result) => {

  //     // ctrl.emptyGeneratingData = false;
  //     if (_.isEmpty(result.Value) || result.ApiResultType != 1) {
  //       // ctrl.emptyGeneratingData = true;
  //       showCannotGeneratingDialog()
  //       return
  //     }

  //     const questionCategoryIdList = result.Value.map(q => {
  //       return q.QuestionCategoryId
  //     });

  //     const quesTypeList = ctrl.rowQuesTypeList.filter(qt => {
  //         return _.includes(questionCategoryIdList, qt.QuestionCategoryId)
  //       }
  //     );
  //     if (isOneByOne) {
  //       tempQuesTypeList = tempQuesTypeList.concat(quesTypeList);
  //     } else {
  //       ctrl.rowQuesTypeList = quesTypeList;
  //     }
  //   });
  // }
}
