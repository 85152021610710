import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-question-json',
  template: require('./question-json.component.html'),
  styles: [require('./question-json.component.scss')]
})
export class QuestionJsonComponent implements OnInit {
  @Input() item;
  @Input() status;
  SingleChoice: any = 'singlechoice';
  MultiChoice: any = 'multichoice';
  YesOrNo: any = 'yesorno';
  Connect: any='connect';
  Fill: any='fill';
  Qa: any='qa';
  choicesArray: any=['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K'];

  constructor() { }

  ngOnInit() {
  }

  public getAnswer(item) {
    const type = _.get(item, 'origNode.jsonStr.content.type');
    const answerItem = _.get(item, 'origNode.jsonStr.content.answer');
    let answer = '';
    if(type === this.SingleChoice) {
      answer = this.choicesArray[Number(answerItem)];
    } else if (type === this.MultiChoice) {
      _.forEach(answerItem, item => {
        this.choicesArray[Number(item)];
        answer += this.choicesArray[Number(item)];
      })
    } else if (type === this.YesOrNo) {
      answer = answerItem === "1" ? '是' : '否';
    } else if (type === this.Connect) {
      const answerArray = answerItem.split(',');
      _.forEach(answerArray, (item, index)=> {
        let result = item.split(':');
        result = this.choicesArray[Number(result[0])] + ':' + (Number(result[1]) + 1);
        const segmentation = (index + 1) === answerArray.length ? '' : ',';
        answer = answer + result + segmentation;
      })
    } else if (type === this.Fill) {
      const answerArray = _.get(JSON.parse(answerItem), 'answer');
      _.forEach(answerArray, (item,index) => {
        const segmentation = (index + 1) === answerArray.length ? '' : '; ';
        answer += item[0] + segmentation;
      })
    } else if (type === this.Qa) {
      answer = answerItem;
    }
    return answer;
  }

  public nodeContentType(item) {
    return item['origNode']['jsonStr']['nodeType'];
  }

  public nodeContentName(item) {
    return item['origNode']['jsonStr']['nodeName'];
  }

  public nodeContentBody(item) {
    return item['origNode']['jsonStr']['content']['body'];
  }

  public nodeContentResource(item) {
    return item['origNode']['jsonStr']['content']['source'];
  }

  public nodeContentSerialNumber(item) {
    return item['origNode']['jsonStr']["content"]["serial_number"];
  }

  public nodeQuestionType(item) {
    return item['origNode']['jsonStr']["content"]["type"];
  }

  public isHasQuestionAnswer(item) {
    return item['origNode']['jsonStr']["content"]["answer"];
  }

  public nodeQuestionAnalysis(item) {
    return item['origNode']['jsonStr']["content"]["analysis"];
  }

  public nodeQuestionChoice (item) {
    return item['origNode']['jsonStr']["content"]["choices"];
  }

  public nodeQuestionConnectLeft(item) {
    return item['origNode']['jsonStr']["content"]["leftOpts"];
  }

  public nodeQuestionConnectRight(item) {
    return item['origNode']['jsonStr']["content"]["rightOpts"];
  }
}
