import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {NotificationService} from 'app/shared/notification/notification.service';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import * as _ from 'lodash';
import {forkJoin, of} from "rxjs";
import {flatMap} from "rxjs/operators";
import {TrainMassageService} from "app/online-train/train-massage.service";

@Component({
  selector: 'app-train-good-course-editor',
  template: require('./train-good-course-editor.component.html'),
  styles: [require('./train-good-course-editor.component.scss')]
})
export class TrainGoodCourseEditorComponent implements OnInit {

  @Input() public itemData
  @Input() public itemType
  @Input() public editType
  @Input() public levels
  @Input() public subjects
  @Input() public allSubjects
  @Input() public grades
  @Input() public lvlToSubjMap
  @Output() public action = new EventEmitter<any>()

  private title
  private dataClone
  private selectedLevel
  private selectedSubject
  private selectedGrade
  private smallGrades
  private middleGrades
  private highGrades
  constructor(public bsModalRef: BsModalRef,
              private notifyService: NotificationService,
              private onlineTrainService: OnlineTrainService,
              private trainMessage: TrainMassageService) { }

  ngOnInit() {
    this.middleGrades = [
      {id: 1, name: '七年级', deleted: false},
      {id: 2, name: '八年级', deleted: false},
      {id: 3, name: '九年级', deleted: false}
    ]

    this.highGrades = [
      {id: 1, name: '一年级', deleted: false},
      {id: 2, name: '二年级', deleted: false},
      {id: 3, name: '三年级', deleted: false}
    ]

    this.lvlToSubjMap = {
      1 : this.onlineTrainService.subjectsOfPrimary,
      2 : this.onlineTrainService.subjectsOfJunior,
      3 : this.onlineTrainService.subjectsOfSenior
    }
    this.selectedLevel = {id: 1, name: "小学", deleted: false, createTime: 1564036274000, lastUpdateTime: 1564036274000}
    this.selectedSubject = {id: 1, name: "语文", deleted: false, createTime: 1564036274000, lastUpdateTime: 1564036274000}
    this.selectedGrade = {id: 1, name: "一年级", deleted: false, createTime: 1564036274000, lastUpdateTime: 1564036274000}
    this.loadData()
    this.setTitle()
    this.setDataClone()
  }

  public selectLevel(level) {
    this.selectedLevel = level
    this.subjects = _.filter(this.allSubjects,
      (s: any) => _.includes(this.lvlToSubjMap[level.id], s.name))
    if (level.id === 1) {
      this.grades = this.smallGrades
    } else if (level.id === 2) {
      this.grades = this.middleGrades
    } else if (level.id === 3) {
      this.grades = this.highGrades
    }
    this.selectedSubject = this.subjects[0]
    this.selectedGrade = this.grades[0]
    this.dataClone.levelId = level.id

  }

  public selectSubject(subject) {
    this.selectedSubject = subject
    console.log('subject=========', subject)
    this.dataClone.subjectId = subject.id
  }

  public selectGrade(grade) {
    this.selectedGrade = grade
    console.log('grade=========', grade)
    this.dataClone.gradeId = grade.id
  }

  public close() {
    this.bsModalRef.hide()
  }

  public ok() {
    this.action.emit(this.dataClone)
    this.bsModalRef.hide()
  }

  private setTitle() {
    this.title = '编辑学段，学科，年级'
  }

  private setDataClone() {
    this.dataClone = _.clone(this.itemData)
  }

  private loadData() {
    this.trainMessage.sendLoadingAction(true)
    return forkJoin(
      this.onlineTrainService.getLevels(),
      this.onlineTrainService.getSubjects(),
      this.onlineTrainService.getGrades())
      .pipe(
        flatMap((v) => {
          this.levels = v[0]
          this.allSubjects = v[1]
          this.grades = _([]).concat(v[2]).value()
          this.smallGrades = this.grades
          console.log('this.grades1111', this.grades)
          this.subjects = _.filter(this.allSubjects,
            (s: any) => _.includes(this.lvlToSubjMap[this.levels[0].id], s.name))
          this.selectLevel(this.levels[0])
          this.selectSubject(this.subjects[0])
          this.selectGrade(this.grades[0])
          return of([])
        })
      ).subscribe(() => {
      }, (err) => {
      }, () => {
        this.trainMessage.sendLoadingAction(false)
      })
  }
}
