import { matchSection } from 'app2/integration/sectionMatchUtils'
import {SelectedLevelSubject, XuersiBooksDTO,
  XuersiLevel, XuersiSubject} from 'app2/integration/xuersi/models'
import Promise = require('bluebird')
import $ = require('jquery')
import _ = require('lodash')

export const questionTypes = [
  {
    type: 0,
    typeName: '全部',
    sort: 0,
  },
  {
    type: 1,
    typeName: '单选',
    sort: 1,
  },
  {
    type: 2,
    typeName: '多选',
    sort: 2,
  },
  {
    type: 3,
    typeName: '多选多',
    sort: 3,
  },
  {
    type: 4,
    typeName: '填空',
    sort: 4,
  },
  {
    type: 5,
    typeName: '判断',
    sort: 5,
  },
  {
    type: 6,
    typeName: '配对',
    sort: 6,
  },
  {
    type: 7,
    typeName: '排序',
    sort: 7,
  },
  {
    type: 8,
    typeName: '解答',
    sort: 8,
  },
  {
    type: 9,
    typeName: '复合',
    sort: 9,
  },
  {
    type: 10,
    typeName: '完形填空',
    sort: 10,
  },
  {
    type: 11,
    typeName: '语音跟读',
    sort: 11,
  },
  {
    type: 12,
    typeName: '口语题',
    sort: 12,
  },
  {
    type: 13,
    typeName: '连词成句',
    sort: 13,
  },
]

export const getQuesType = (q) => {
  const type = _.get(q, 'question[type]', 0)
  return _.chain(questionTypes).keyBy('type').value()[type]['typeName']
}

export const getSelectedLevelSubject = () => {
  const selectedLevelSubject = {
    grade_level_id: 1,
    grade_level_name: '小学',
    subject_id: 2,
    subject_name: '数学',
  }
  return Promise.resolve(selectedLevelSubject)
}

export const getQuestionTypes = (status) => {
  return Promise.resolve(questionTypes).then((qt) => {
    status.questionTypes = qt
    status.selectedQuestionType = qt[0]
  })
}

export const getMaterials = (xuersiResource, status, onMaterialSelected) => {
    return xuersiResource.getBooks({
      grade_level_id: _.get(status, 'selectedLevelSubject.grade_level_id', 1),
      subject_id: _.get(status, 'selectedLevelSubject.subject_id', 2),
    }).$promise.then((materials: XuersiBooksDTO) => {
      status.materials = materials.data
      const selectedMaterial = {
        selectedVersion: {
          tag_section_id: _.get(materials, 'data.0.tag_section_id'),
          versionName: _.get(materials, 'data.0.name'),
        },
        selectedBook: {
          section_node_id: _.get(materials, 'data.0.materials.0.section_node_id'),
          bookName: _.get(materials, 'data.0.materials.0.name'),
        },
      }
      onMaterialSelected(selectedMaterial)
    })
}

export const getChapters = (xuersiResource, status, onChapterSelected, chapterId, subjectId = 2, menuState) => {
  return xuersiResource.getChapters({
    chapter_id: chapterId,
    subject_id: subjectId,
  }).$promise.then((chapters: any) => {
    const cps = [{
      child: convertTalChaptersToRaySections(_.get(chapters, 'data.childs', []), 0)
    }]
    
    /**
     * 展开第一级目录
     * 题目菜单列表默认收起状态
     * menuState 默认值 true
     */

    if (cps && menuState) {
      _.forEach(cps[0].child, (c: any) => {
        c.$opened = false
      })
    }
    
    status.sections = cps
    if (status.sectionName) {
      const matchSectionName = matchSection(_.get(cps, '0.child', {}), status.sectionName, 'child')
      onChapterSelected(matchSectionName)
    } else {
      onChapterSelected(_.get(cps, '0.child.0', {}))
    }
  })
}

const convertTalChaptersToRaySections = (chpaters: any, depth: any) => {
  return _.map(chpaters, (c, idx) => {
   const child: any = convertTalChaptersToRaySections(c.childs, depth + 1)
   return {
     $opened: true,
     child,
     depth,
     id: c.section_node_id,
     item_id: c.section_node_id,
     name: c.name,
     parent_id: c.parent_node_id,
     sort: idx,
   }
 })
}

export const getQuestions = (
  xuersiResource, status, chapterId,
  onQuestionsLoaded, body, subjectId = 2,
  levelId = 1) => {
  const requestBody = {
    chapter_id: chapterId,
    subject_id: _.get(status, 'selectedLevelSubject.subject_id', 2),
    level_id: _.get(status, 'selectedLevelSubject.grade_level_id', 1),
    ...body,
  }
  return xuersiResource.getQuestions(requestBody).$promise.then((quesData: any) => {
    status.page = _.get(quesData, 'data.page')
    const questionList = _.get(quesData, 'data.question_list')
    onQuestionsLoaded(questionList)
  })
}
