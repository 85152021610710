import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import { TrainDesignComponent } from './train-design/train-design.component';
import {UIRouterUpgradeModule} from '@uirouter/angular-hybrid';
import {onlineTrainStates} from 'app/online-train/online-train.state';
import { TrainDirsComponent } from './train-design/train-dirs/train-dirs.component';
import {OnlineTrainService} from 'app/online-train/online-train.service';
import { TrainEditorComponent } from './train-design/train-editor/train-editor.component';
import { TrainDirEditorComponent } from './train-design/train-dir-editor/train-dir-editor.component';
import {FormsModule} from '@angular/forms';
import { TrainDirStyle5Component } from './train-design/train-dir-style5/train-dir-style5.component';
import { TrainDirStyle4Component } from './train-design/train-dir-style4/train-dir-style4.component';
import { TrainContentComponent } from './train-design/train-content/train-content.component';
import { TrainContentEditorComponentComponent } from './train-design/train-content-editor-component/train-content-editor-component.component';
import {UtilsModule} from 'app/utils/utils.module';
import { TrainContentLinkEditorComponent } from './train-design/train-content-link-editor/train-content-link-editor.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {EditingModule} from 'app/editing/editing.module';
import { TrainQAComponent } from './train-design/train-qa/train-qa.component';
import { TrainQAEditorComponent } from './train-design/train-qaeditor/train-qaeditor.component';
import { TrainContentQaEditorComponent } from './train-design/train-content-qa-editor/train-content-qa-editor.component';
import { TrainResourceViewerComponent } from './train-design/train-resource-viewer/train-resource-viewer.component';
import { TrainParadigmsComponent } from './train-design/train-paradigms/train-paradigms.component';
import {SharedModule} from 'app/shared/shared.module';
import { TrainExcellentComponent } from './train-design/train-excellent/train-excellent.component';
import { TrainLevelsComponent } from './train-design/train-levels/train-levels.component';
import { TrainGoodCourseContentComponent } from './train-design/train-good-course-content/train-good-course-content.component';
import { TrainResourceComponent } from './train-design/train-resource/train-resource.component';
import { TrainGoodCourseEditorComponent } from './train-design/train-good-course-editor/train-good-course-editor.component';
import { TrainGoodCourseItemComponent } from './train-design/train-good-course-item/train-good-course-item.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { TrainDirNavComponent } from './train-design/train-dir-nav/train-dir-nav.component';

@NgModule({
  declarations: [TrainDesignComponent, TrainDirsComponent, TrainEditorComponent, TrainDirEditorComponent,
    TrainDirStyle5Component, TrainDirStyle4Component, TrainContentComponent, TrainContentEditorComponentComponent,
    TrainContentLinkEditorComponent, TrainQAComponent, TrainQAEditorComponent, TrainContentQaEditorComponent,
    TrainResourceViewerComponent, TrainParadigmsComponent, TrainExcellentComponent, TrainLevelsComponent,
    TrainGoodCourseContentComponent, TrainResourceComponent, TrainGoodCourseEditorComponent,
    TrainGoodCourseItemComponent, TrainDirNavComponent],
  imports: [
    CommonModule,
    FormsModule,
    UtilsModule,
    EditingModule,
    SharedModule,
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    UIRouterUpgradeModule.forChild({ states: onlineTrainStates }),
  ],
  exports: [],
  entryComponents: [
    TrainDesignComponent,
    TrainDirEditorComponent,
    TrainContentEditorComponentComponent,
    TrainContentLinkEditorComponent,
    TrainQAEditorComponent,
    TrainGoodCourseEditorComponent,
    TrainResourceViewerComponent],
  providers: [OnlineTrainService]
})
export class OnlineTrainModule { }
