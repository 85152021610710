import { Component, OnInit, Input, AfterViewInit, SimpleChanges, OnChanges, OnDestroy, ViewChild, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash'

@Component({
  selector: 'app-ui-tree',
  template: require('./ui-tree.component.html'),
  styles: [require('./ui-tree.component.scss')]
})
export class UiTreeComponent implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  @Input() treeConfig
  @Input() treeData

  @ViewChild('tree') treeEl

  @Output() ready = new EventEmitter<void>()
  @Output() change = new EventEmitter<void>()

  private treeInited = false
  private curConfig
  private treeReady = false

  private selectedNodes: any[]
  private refreshing = false

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.reInit()
  }

  ngOnDestroy() {
    this.treeInited = false
    if (this.treeEl && this.treeEl.nativeElement) {
      console.log('destroy tree')
      $(this.treeEl.nativeElement).jstree('destroy')
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (_.has(changes, 'treeData')) {
      this.reInit()
    }
  }

  reInit() {
    this.curConfig = {
      ...this.treeConfig,
      data: this.treeData
    }
    if (!this.treeInited) {
      const instance = $(this.treeEl.nativeElement).jstree(this.curConfig)
      instance.on('changed.jstree', (...args) => {
        if (this.treeReady) {
          this.change.emit()
        }
        return true
      }).on('ready.jstree', () => {
        this.treeReady = true
        if (this.selectedNodes) {
          instance.select_node(this.selectedNodes, true, true);
          this.selectedNodes = null
        }
        this.ready.emit()
        return true
      }).on('refresh.jstree', () => {
        //
        // 当jsTree在刷新时更新其数据不工作
        // 需要在更新完成后再更新数据
        this.refreshing = false
        if (this.selectedNodes) {
          this.selectNodes(this.selectedNodes)
        }
      })
    } else {
      const instance = $(this.treeEl.nativeElement).jstree(true)
      instance.settings = {
        ...instance.settings,
        ...this.curConfig,
      }
      instance.settings.core.data = this.treeData
      instance.refresh()
      this.refreshing = true

    }
    this.treeInited = true
  }

  getSelected() {
    if (!this.treeInited) {
      return []
    }

    return $(this.treeEl.nativeElement).jstree(true).get_selected()
  }

  selectNodes(nodeIds: any[]) {
    if (!this.treeReady || this.refreshing) {
      this.selectedNodes = nodeIds
      return
    }

    return $(this.treeEl.nativeElement).jstree(true).select_node(nodeIds, true, true)
  }
}
