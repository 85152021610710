(function() {
  angular.module('app.prepareCourse')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('prepareCourse.subjectiveTest', {
        url: '/subjectiveTest/:testId',
        params: {
          testId: ''
        },
        template: require('assets/templates/prepareCourse/subjectiveTest.html'),
        controller: 'subjectiveTestCtrl',
        loadingCls: 'blueloading'
      });
    }])
    .controller('subjectiveTestCtrl', subjectiveTestCtrl);

  subjectiveTestCtrl.$inject = ['$scope', '$state', '$stateParams', '$q', '$uibModal', 'dialogs',
    'oedSubjectiveTest', 'oedCloudSubjectiveTest', 'notify']
  function subjectiveTestCtrl($scope, $state, $stateParams, $q, $uibModal, dialogs,
    oedSubjectiveTest, oedCloudSubjectiveTest, notify) {
    /*控制器通信*/
    $scope.mySaveTest = function() {
      if (!$scope.iAmDeleted) {
        $scope.$broadcast('saveObjectiveTest');
      }
    };

    $scope.$on('$destroy', function() {
      $scope.isDone = true;
    });

    $scope.currentState.title = '主观活动';
    var courseId = _.parseInt($stateParams.courseId);
    $scope.tests = oedSubjectiveTest.queryByCourseIncEmpty({
      course_id: courseId
    });
    var curTestId = $stateParams.testId;
    $scope.loading = $q(function(resolve, reject) {
      $q.all([$scope.tests.$promise, $scope.course]).then(function(data) {
        var tests = $scope.tests;
        if (!_.isEmpty(tests)) {
          var curTest = curTestId ? _.find(tests, function(t) {
            return t.id == curTestId;
          }) : tests[0];
          curTest = curTest || tests[0];
          $scope.setCurrentTest(curTest, true);
          return null;
        } else { // no tests, create one
          var relSections = [];
          if (_.size($scope.course.relatedSections) > 0) {
            relSections.push(angular.copy(_.pick($scope.course.relatedSections[0], ['sectionId'])));
          }

          var test = new oedSubjectiveTest({
            name: '活动探究1',
            courseId: courseId,
            isEmpty: true,
            question: {
              type: 'subjective_blankboard',
              evaluateType: 'point',
              point2: 5,
              relatedSections: relSections
            }
          });
          return test.$save();
        }
      }).then(function(newtest) {
        if (newtest && !$scope.isDone) {
          $state.go('prepareCourse.subjectiveTest', {}, {
            reload: true
          });
        } else {
          // populate shared test map
          var sharedTests = oedCloudSubjectiveTest.queryByTestIds({
            testIds: _.map($scope.tests, 'id')
          });
          sharedTests.$promise.then(function(allSharedTests) {
            $scope.sharedTests = _.keyBy(allSharedTests, 'testId');
            if (!$scope.currentTest) {
              return;
            }
            $scope.refreshCurrentCloudTestId();
          });
        }
        resolve();
      }).catch(reject);
    });

    $scope.refreshCurrentCloudTestId = function() {
      if ($scope.sharedTests[$scope.currentTest.id])
        $scope.currentCloudTestId = $scope.sharedTests[$scope.currentTest.id].id;
      else
        $scope.currentCloudTestId = null;
    };

    $scope.shouldShowShare = function(test) {
      return test && $scope.sharedTests && $scope.sharedTests[test.id];
    };
    $scope.setCurrentTest = function(t, replace) {
      if ($scope.isDone)
        return;

      if ($scope.currentTest == t) {
        return;
      }

      var opt = replace ? {
        location: 'replace'
      } : null;

      $scope.currentTest = t;
      $state.go('prepareCourse.subjectiveTest.designTest', {
        testId: t.id
      }, opt);
    };

    /*自动保存*/
    $scope.$on('$destroy', function() {
      $scope.mySaveTest();
    });

    $scope.addTest = function() {
      var modalInstance = $uibModal.open({
        template: require('assets/templates/prepareCourse/addSubjectiveTestDialog.html'),
        controller: 'addSubjectiveTestDialogCtrl',
        size: 'sm',
        windowClass: 'oedmodal modalCenter',
        resolve: {
          courseId: function() {
            return courseId;
          },
          existingTests: function() {
            return $scope.tests;
          }
        }
      });

      modalInstance.result.then(function(r) {
        if (r.promise) {
          r.promise.then(function() {
            $scope.tests.push(r.test);
            $scope.setCurrentTest(r.test);
          });
        }
      });

    };

    $scope.deleteTest = function($event, test) {
      $event.stopPropagation();
      $event.preventDefault();
      var dlg = dialogs.confirm('确定删除?', '确定要删除主观活动: "' + test.name + '"吗');
      dlg.result.then(function(btn) {
        $scope.loading = $q(function(resolve, reject) {
          test.$delete(function() {
            if ($scope.isDone) return;

            if (test == $scope.currentTest) {
              $scope.iAmDeleted = true;
              $state.go('prepareCourse.subjectiveTest', {}, {
                reload: true
              });
            } else {
              _.remove($scope.tests, test);
              if (_.isEmpty($scope.tests)) {
                $state.go('prepareCourse.subjectiveTest', {}, {
                  reload: true
                });
              }
            }
            resolve();
          }, function() {
            reject();
          });
        });
      }, function(btn) {});
    };

    $scope.toggleShareTest = function($event, test) {
      $event.stopPropagation();
      $event.preventDefault();
      var dlg = null;
      if (test.id in $scope.sharedTests) {
        dlg = dialogs.confirm('确定取消共享?', '确定要取消共享该主观活动吗？');
        dlg.result.then(function(btn) {
          $scope.sharedTests[test.id].$delete().then(result => {
            delete $scope.sharedTests[test.id];
            $scope.refreshCurrentCloudTestId();
          });
        }, function(btn) {});
      } else {
        dlg = dialogs.confirm('确定共享?', '确定要共享该主观活动吗？');
        dlg.result.then(function(btn) {
          var sharedTest = new oedCloudSubjectiveTest({
            testId: test.id
          });
          sharedTest.$save().then(t => {
            $scope.sharedTests[test.id] = t;
            $scope.refreshCurrentCloudTestId();
          });
        }, function(btn) {});
      }
    };
  }
})();
