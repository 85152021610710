import * as angular from 'angular';
import 'app2/utils/fileUtils';
import _ = require('lodash');
const resourceUtils: any = require('app2/utils/resourceUtils');
const nobookUtils: any = require('app2/utils/nobookUtils');
const url: any = require('url');
const nobookHost = 'school.nobook.com';

angular.module('app.directives.resource.downResource', [])
.component('downResource', {
  bindings: {
    image: '<',
  },
  controller: downResourceCtrl,
  template: <string> require('./downResource.html'),
});

downResourceCtrl.$inject = ['oedConfig', 'userInfo', '$window']
function downResourceCtrl(oedConfig: any, userInfo: any, $window: any) {
  const ctrl = this;
  // 判断顶层窗口是否为iframe(配合桌面端使用)
  ctrl.topWindowIsIframe = $window.top === $window.self;
  ctrl.linkOpenType = ctrl.topWindowIsIframe ? '_blank' : '_self';
  const isExternalResource = (res: any) => {
    return res.isExternal && _.isEmpty(res.resourceUUID)
  }

  const imageBaseUrl = 'http://s-pic.rayclass.com';
  const videoBaseUrl = 'http://s-v.rayclass.com';

  this.$onInit = () => {
    updateImageInfo();

    const basePath = oedConfig.base();
    const imgSrcPathID = _.template(basePath + 'resource/view/img_src/<%=res.id%>');
    const imgDownloadPath = _.template(basePath + 'resource/view/img_src/<%=res.id %>/raw/<%=res.id+res.suffix%>');
    const boardContentPath = _.template(basePath + 'resource/board/<%=res.id %>/content');
    const boardCommentPath = _.template(basePath + 'resource/view/img_src/<%=res.id %>');

    function getResProp(r: any, name: any) {
      if (_.has(r, 'flResource')) {
        return _.get(r, 'flResource.' + name);
      }
      return _.get(r, name);
    }

    function getResUUID(res: any) {
      return resourceUtils.extractResourceUUID(res) || resourceUtils.extractResourceUUID(res.flResource);
    }

    function getImgSrcPath(res: any, name: any) {
      const uuid = getResUUID(res);
      if (_.isEmpty(uuid)) {
        const resId = _.get(res, 'flResourceId', res.id)
        return imgSrcPathID({
          res: {
            id: resId,
          },
        });
      }
      return '/ray/resource/uuid/' + uuid + '/raw64/' + name;
    }

    ctrl.getImageUrlForDownload = (res: any) => {
      const imageUrl = getImageUrl(res, true);
      $window.open(imageUrl, _.get($window, '_link_open_type'));
    }

    function getImageUrl(resItem: any, isDownload: any) {
      if (!resItem) {
        return null;
      }

      function b64EncodeUnicode(str: any) {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match: any, p1: any) => {
          return String.fromCharCode(Number('0x' + p1));
        }));
      }

      let src = null;
      const prop = isExternalResource(resItem) ? 'externalResourceType' : 'fileType';
      const fileType = getResProp(resItem, prop);
      if (fileType === 'boardContent') {
        return boardContentPath({
          res: resItem,
        });
      } else if (fileType === 'boardComment') {
        return boardCommentPath({
          res: resItem,
        });
      }

      if (isDownload) {

        if (isExternalResource(resItem)) {
          return resItem.externalResourceUrl
        }

        if (resItem.webUrl && url.parse(resItem.webUrl).hostname === nobookHost) {
          return nobookUtils.buildRedirectUrl(resItem.webUrl, userInfo.userInfo.uid)
        }

        if (resItem.webUrl) {
          return resItem.webUrl;
        }

        let name = resItem.resourceName || '';
        const suffix = getResProp(resItem, 'suffix');
        if (suffix && !name.endsWith(suffix)) {
          name = name + '.' + suffix;
        }
        name = b64EncodeUnicode(encodeURIComponent(name));
        return getImgSrcPath(resItem, name);
      }

      if (fileType === 'image' || fileType === 'audio' || fileType === 'video') {
        if (isExternalResource(resItem)) {
          return resItem.externalResourceUrl
        }
        src = getResProp(resItem, 'imageSrcHttpPath');
        if (!src) {
          return getImgSrcPath(resItem, '');
        }
        return src;
      }
      return getResProp(resItem, 'imageThumbHttpPath');
    }

    function updateImageInfo() {
      ctrl.imageUrl = getImageUrl(ctrl.image, false);
      if (process.env.NAME !== 'beta') {
        const fp = _.get(ctrl.image, 'flResource.fileType');
        if (fp === 'video' || fp === 'audio') {
          ctrl.imageUrl = videoBaseUrl + getImageUrl(ctrl.image, false);
        }
        if (fp === 'image') {
          ctrl.imageUrl = imageBaseUrl + getImageUrl(ctrl.image, false);
        }
      }
    }
  }
}
