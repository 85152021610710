import { Directive, ElementRef, EventEmitter, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
  selector: 'app-exam-parts-editor'
})
export class OEdExamPartsEditor extends UpgradeComponent {
  @Input() public part: any;

  constructor(elementRef: ElementRef, injector: Injector) {
    super('examPartsEditor', elementRef, injector);
  }
}
