import { NgModule } from "@angular/core";
import { ImagePreviewerComponent } from "./image-previewer/image-previewer.component";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [ImagePreviewerComponent],
    entryComponents: [ImagePreviewerComponent],
    exports: [ImagePreviewerComponent],
    imports: [
        CommonModule
    ],
})
export class PreviewModule { }
