import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-info',
  template: require('./user-info.component.html'),
  styles: [require('./user-info.component.scss')]
})
export class UserInfoComponent implements OnInit {
  public config = {
    serverUrl: 'test'
  }

  public test = 'hello world!'
  public showEditor = true

  public config2 = {
    focus: true
  }

  constructor() { }

  public ngOnInit() {
  }

  public onLog() {
    console.log(this.test)
  }

  public toggleEditor() {
    this.showEditor = !this.showEditor
  }

  public update() {
    this.test = this.test + '+1 '
  }
}
