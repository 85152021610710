(function() {
  'use strict';

  angular.module('app.student')
    .config(['$stateProvider', function($stateProvider) {
      $stateProvider.state('studentTaskDetails.res.subjectiveTest', {
        url: '/subjectiveTest/:origTestId',
        template: require('assets/templates/student/studentTaskDetailsSubjectiveTest.html'),
        controller: 'studentTaskDetailsSubjectiveTestCtrl',
        bodyStyle: 'bg_img'
      });
    }]).controller('studentTaskDetailsSubjectiveTestCtrl', studentTaskDetailsSubjectiveTestCtrl);

  studentTaskDetailsSubjectiveTestCtrl.$inject = ['$rootScope', '$scope', '$state', '$stateParams', '$q',
    'dialogs', 'notificationService', 'Lightbox', 'userInfo', 'oedConfig', 'oedCoursePath', 'oedTaskList', 'oedTask',
    'oedTaskListPublishRecord', 'oedTaskTest', 'oedSubjectiveTest', 'oedTestSession', 'oedTestSessionStudent',
    'oedBoardSession', 'oedBoardContent', 'oedBoardContentComment', 'oedSubjectiveTestSessionStats', 'oedUserInfo',
    'oedBoardSessionStudent', 'oedUserInfoUtils']
  function studentTaskDetailsSubjectiveTestCtrl($rootScope, $scope, $state, $stateParams, $q,
    dialogs, notificationService, Lightbox, userInfo, oedConfig, oedCoursePath, oedTaskList, oedTask,
    oedTaskListPublishRecord, oedTaskTest, oedSubjectiveTest, oedTestSession, oedTestSessionStudent,
    oedBoardSession, oedBoardContent, oedBoardContentComment, oedSubjectiveTestSessionStats, oedUserInfo,
    oedBoardSessionStudent, oedUserInfoUtils) {

    $scope.basePath = oedConfig.base();

    var recordId = _.parseInt($stateParams.recordId);
    var taskId = _.parseInt($stateParams.taskId);
    var origTestId = _.parseInt($stateParams.origTestId);

    $scope.taskId = taskId;

    $scope.userInfoMap = {};
    $scope.boardComments = [];

    $scope.answer = {
      answerContent: {},
      commentContent: {}
    };

    var startTime = moment();

    $scope.testSession = oedTestSession.getTestSessionByTaskListRecordAndOrigTestId({
      recordId: recordId,
      taskId: taskId,
      origTestId: origTestId
    });

    function joinTestSessionIfNecessary(uid) {
      var tsStd = oedTestSessionStudent.queryByTestSessionAndStudentId({
        sessionId: $scope.testSession.id,
        studentId: uid
      });

      return tsStd.$promise.catch(function(err) {
        if (err.status == 404) {
          return oedTestSessionStudent.save({
            testSessionId: $scope.testSession.id,
            studentId: uid
          }).$promise;
        }
        return err;
      });
    }

    function readContent(content) {
      var c = _.omit(content, 'contentBody');
      var body = angular.fromJson(content.contentBody);
      c.body = body;
      var resItems = [];

      if (c.body.content) {
        resItems = [{
          fileType: 'text',
          text: c.body.content,
          textTrimed: _.truncate(c.body.content, {
            length: 30
          })
        }];
      }

      if (_.size(body.resources) > 0) {
        resItems = resItems.concat(body.resources);
      }

      c.resItems = resItems;

      return c;
    }

    $scope.getContentItemClass = function(item) {
      var width = _.size(item.resItems) * 166;

      return {
        width: width + 'px'
      };
    };

    function readComment(cmt) {
      var c = _.omit(cmt, 'commentBody');
      var body = angular.fromJson(cmt.commentBody);
      c.body = body;
      if (!_.has($scope.userInfoMap, cmt.uid)) {
        $scope.userInfoMap[cmt.userId] = oedUserInfo.getByUid({
          id: cmt.userId
        });
      }
      return c;
    }

    function updateStats() {
      $scope.stats = oedSubjectiveTestSessionStats.queryBySessionAndUserId({
        boardSessionId: $scope.boardSession.id,
        userId: $scope.uid,
        includeBoardContent: false,
        includeViewedBoards: false,
        includeBravoedBoards: false,
        includeCommentedBoards: false,
        includeBoardViews: true,
        includeBoardBravos: true,
        sortBy: 'bravosCount'
      });

      return $scope.stats.$promise.then(function(stats) {
        if (!stats.board) {
          return;
        }
        $scope.selfBravoed = _.findIndex(stats.board.bravosList, function(bravo) {
          return bravo.key.uid == $scope.uid;
        }) >= 0;
      });
    }

    userInfo.then(function(info) {
      $scope.userInfoMap[info.uid] = info;
      var uid = _.parseInt(info.uid);
      $scope.uid = uid;

      var loadTestSession = $scope.testSession.$promise.then(function(ts) {
        return oedBoardSession.queryByTestSessionId({
          sessionId: ts.id
        }).$promise.then(function(boardSessions) {
          if (_.isEmpty(boardSessions)) {
            console.error('no board sessions for board sessoin');
            return null;
          }
          $scope.boardSession = boardSessions[0];
          var boardContents = oedBoardContent.queryByBoardSessionAndStudentId({
            sessionId: $scope.boardSession.id,
            studentId: uid
          }).$promise.then(function(contents) {
            if (!_.isEmpty(contents)) {
              $scope.isDetailsCollapsed = true;
            }
            $scope.boardContents = _.map(contents, readContent);
          });

          var boardComments = oedBoardContentComment.queryByBoardSessionAndStudentId({
            sessionId: $scope.boardSession.id,
            studentId: uid
          }).$promise.then(function(comments) {
            $scope.boardComments = _.map(comments, readComment).reverse();
          });

          $scope.boardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
            sessionId: $scope.boardSession.id,
            studentId: uid
          }).$promise.catch(function() {
            //TODO: 不应当使用404而用返回null来判断是否已读
          });

          var loadStats = updateStats();

          return $q.all([boardContents, boardComments, $scope.boardStudent.$promise, loadStats]);
        });
      });

      $scope.loading = $q.all([loadTestSession, $scope.record.$promise]).then(function() {
        $scope.isTaskListCompleted = false;
        var dueDate = $scope.record.dueDate;
        if (dueDate && dueDate - Date.now() <= 0) {
          $scope.isTaskListCompleted = true;
        }

        return oedTestSessionStudent.queryByTestSessionAndStudentId({
          sessionId: $scope.testSession.id,
          studentId: uid
        }).$promise.catch(function() {
          return {
            submitTime: 0
          };
        });
      }).then(function(tsStd) {
        $scope.test = oedSubjectiveTest.get({
          id: $scope.testSession.testId
        });
        $scope.test.$promise.then(function(test) {
          $scope.currentState.subTitle = $scope.test.name;
        });
        return $scope.test.$promise;
      }).finally(function() {
        $scope.loaded = true;
      });
    });

    $scope.loading = $scope.testSession.$promise;

    function getAllContentRes() {
      var allContents = _.reduce($scope.boardContents, function(total, c) {
        return total.concat(c.resItems);
      }, []);

      return allContents;
    }

    $scope.previewContentResource = function(curRes) {
      var allRes = getAllContentRes();
      var idx = _.findIndex(allRes, curRes);

      Lightbox.openModal(allRes, idx);
    };

    $scope.previewResource = function(reses, idx) {
      Lightbox.openModal(reses, idx);
    };

    $scope.openAddAnswerPane = function() {
      $scope.showAddAnswerPane = !$scope.showAddAnswerPane;
    };

    $scope.getUserAvatarUrl = oedUserInfoUtils.getUserAvatarUrl;

    $scope.submitAnswer = function(content) {
      if (!$scope.boardSession) {
        return;
      }

      $scope.isTaskListCompleted = false;
      var dueDate = $scope.record.dueDate;
      if (dueDate && dueDate - Date.now() <= 0) {
        $scope.isTaskListCompleted = true;
        dialogs.error('作业已结束', '此作业已经结束， 无法提交试卷');
        return;
      }

      if (!content.content && _.isEmpty(content.resources)) {
        return notificationService.notify('error', '内容为空, 请填写内容后再提交')
      }

      var contentStr = angular.toJson(content);
      var submitBoardContentDTO = {
        boardSessionId: $scope.boardSession.id,
        studentId: $scope.uid,
        contentBody: contentStr
      };

      $scope.loading = oedBoardContent.submitBoardContent(submitBoardContentDTO).$promise.then(function(bc) {
        $scope.answer.answerContent = {};
        $scope.boardContents.push(readContent(bc));
        $scope.showAddAnswerPane = false;
        $rootScope.$broadcast('readObjectTest', {
          taskId: taskId,
          objId: origTestId
        });

        return $q.resolve();
      });
    };

    $scope.cancelEditor = function() {
      $scope.showAddAnswerPane = false;
      $scope.showAddCommentPane = false;
    };

    $scope.openAddAnswerPanel = function() {
      $scope.showAddAnswerPane = true;
      $scope.showAddCommentPane = false;
    };

    $scope.openAddCommentPanel = function() {
      $scope.showAddAnswerPane = false;
      $scope.showAddCommentPane = true;
    };

    $scope.submitComment = function(comment) {
      if (_.isEmpty($scope.boardContents)) {
        return notificationService.notify('error', '还没有提交作品, 不能添加评论')
      }

      var c = _.pick(comment, ['content', 'resources']);
      if (_.isEmpty(c.content) && _.isEmpty(c.resources)) {
        return notificationService.notify('error', '请填写评论内容')
      }

      var body = angular.toJson(c);

      var cmt = {
        boardContentId: $scope.boardContents[0].id,
        userId: $scope.uid,
        commentBody: body,
        createTime: moment().valueOf()
      };

      var cmtToAdd = oedBoardContentComment.save(cmt);
      $scope.loading = cmtToAdd.$promise.then(function(cmt) {
        $scope.answer.commentContent = {};
        $scope.showAddCommentPane = false;
        $scope.boardComments.unshift(readComment(cmt));

        $scope.boardStudent = oedBoardSessionStudent.queryByBoardSessionAndStudentId({
          sessionId: $scope.boardSession.id,
          studentId: $scope.uid
        });

        return $scope.boardStudent.$promise.then(updateStats);
      });
    };

    $scope.toggleBravo = function() {
      $scope.loading = oedBoardContentComment.toggleBravo({
        boardSessionId: $scope.boardSession.id,
        ownerId: $scope.uid,
        userId: $scope.uid
      }, {}).$promise.then(updateStats);
    };
  }
})();
