
import { LessonDesignComponent } from './lesson-design/lesson-design.component';
import { PatternTableComponent } from './lesson-design/pattern-table/pattern-table.component';
import { TeachingPatternComponent } from './lesson-design/teaching-pattern/teaching-pattern.component';
import { SectionExamsComponent } from './lesson-design/section-exams/section-exams.component';
import { SectionListComponent } from './section-list/section-list.component';

/**
 * This state allows the user to set their application preferences
 */
function resolveSectionId($transition$: any) {
  return $transition$.params().sectionId
}

function resolveName($transition$: any) {
  return $transition$.params().sectionName
}

function resolveCoursePathId($transition$: any) {
  return $transition$.params().coursepathId
}

function resolveCourseId($transition$: any) {
  return $transition$.params().courseId
}

function resolveIsRecommend($transition$: any) {
  return true
}

function resolveIsMine($transition$: any) {
  return false
}

resolveSectionId.$inject = ['$transition$']
resolveName.$inject = ['$transition$']
resolveCoursePathId.$inject = ['$transition$']
resolveCourseId.$inject = ['$transition$']
resolveIsRecommend.$inject = ['$transition$']
resolveIsMine.$inject = ['$transition$']

export const lessonStates = [
  {
    name: 'lessonDesign',
    url: '/lessonDesign/coursepath/:coursepathId/section/:sectionId',
    component: LessonDesignComponent,
    resolve: {
      sectionId: resolveSectionId,
      coursepathId: resolveCoursePathId,
    }
  },
  {
    name: 'lessonDesign.recommendTeachingPattern',
    url: '/recommend/course/:courseId',
    component: TeachingPatternComponent,
    resolve: {
      sectionId: resolveSectionId,
      coursepathId: resolveCoursePathId,
      courseId: resolveCourseId,
      isRecommend: resolveIsRecommend,
    }
  },
  {
    name: 'lessonDesign.mineTeachingPattern',
    url: '/mine/course/:courseId',
    component: TeachingPatternComponent,
    resolve: {
      sectionId: resolveSectionId,
      coursepathId: resolveCoursePathId,
      courseId: resolveCourseId,
      isRecommend: resolveIsMine,
    }
  },
  {
    name: 'lessonDesign.recommendTableTeachingPattern',
    url: '/recommend/table/course/:courseId',
    component: PatternTableComponent,
    resolve: {
      sectionId: resolveSectionId,
      coursepathId: resolveCoursePathId,
      courseId: resolveCourseId,
      isRecommend: resolveIsRecommend,
    }
  },
  {
    name: 'lessonDesign.mineTableTeachingPattern',
    url: '/mine/table/course/:courseId',
    component: PatternTableComponent,
    resolve: {
      sectionId: resolveSectionId,
      coursepathId: resolveCoursePathId,
      courseId: resolveCourseId,
      isRecommend: resolveIsMine,
    }
  },
  {
    name: 'prepareLesson2.lessonList',
    url: '/:coursepathId',
    component: SectionListComponent,
    resolve: {
      coursePathId: resolveCoursePathId,
    },
    bodyStyle: 'bged2',
  },
  {
    name: 'sectionExams',
    url: '/lessonDesign/coursepath/:coursepathId/section/:sectionId/exams/:sectionName',
    component: SectionExamsComponent,
    resolve: {
      sectionName: resolveName,
      sectionId: resolveSectionId,
      coursepathId: resolveCoursePathId,
    }
  },
]
