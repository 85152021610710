import { setStdData, deleteStdData, getStdData, getStdPages, destroy, initDrawPlay, seekTo, timeFtt, play, pause } from './drawPlayManager.js'
import _ = require('lodash')

angular.module('app.directives.drawPlay.drawPlaySelector', [])
  .component('oedDrawPlaySelector', {
    bindings: {
      drawId: '<',
      drawData: '<',
      drawHeight: '<',
      drawPageIndex: '<',
    },
    controller: oedDrawPlaySelector,
    template: <string>require('./drawPlaySelector.html'),
  })

function oedDrawPlaySelector() {

  this.isPlaying = false
  this.auto = true
  this.holder = {}
  this.progress = 0

  this.$onChanges = (changesObj: any) => {
    if (_.has(changesObj, 'drawData') && !_.isNil(this.drawData)) {
      this.initCanvas()
    }
  }

  this.initCanvas = () => {
    setStdData(this.drawId, this.drawData)
    const canvas = document.getElementById(`draw_writing_${this.drawId}`) as HTMLImageElement
    canvas.style.width = `${this.drawHeight * 0.7}px`
    canvas.height = this.drawHeight
    canvas.width = this.drawHeight * 0.7
    this.setRefCanvas(canvas)
  }

  this.setRefCanvas = (ref: any) => {
    destroy(this.holder);
    if (!ref) return;
    const ctx = ref.getContext('2d');
    const pages = getStdPages(this.drawId)
    this.drawData = getStdData(this.drawId, pages[this.drawPageIndex])
    this.holder = initDrawPlay({
      data: this.drawData,
      ctx: ctx,
      onChange: () => this.refreshUi(),
      width: this.drawHeight * 0.7,
      height: this.drawHeight,
    });
    this.holder.data = this.drawData
    this.holder.ctx = ctx
    this.holder.onChange = () => this.refreshUi()

    if (this.auto) {
      seekTo(this.holder, 100);
    }
    this.progress = this.holder.progress
  }

  this.refreshUi = () => {
    this.progress = this.holder.progress
    this.isPlaying = this.holder.isPlaying()
    this.refreshTime()
  }

  this.refreshTime = () => {
    const cTime = _.get(this.holder, 'cTime', '')
    const startTS = _.get(this.holder, 'startTS', '')
    this.currentTime = timeFtt(cTime - startTS);
  }

  this.onSeekChange = () => {
    seekTo(this.holder, this.progress);
  }

  this.replay = () => {
    seekTo(this.holder, 0);
    this.play()
  }

  this.play = () => {
    play(this.holder)
    this.isPlaying = this.holder.isPlaying()
    this.refreshUi()
  }

  this.pause = () => {
    pause(this.holder)
    this.isPlaying = this.holder.isPlaying()
    this.refreshUi()
  }
}
