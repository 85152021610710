import _ = require('lodash')

export const convertTalAreaChaptersToRaySections = (chpaters, depth = 0) => {
  return _.map(chpaters, (c, idx) => {
   const child: any = convertTalAreaChaptersToRaySections(c.childs, depth + 1)
   return {
     $opened: true,
     child,
     depth,
     id: c.id,
     item_id: c.id,
     name: c.name,
     parent_id: c.pid,
     sort: idx,
   }
 })
}
